import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useDidMount } from '../../../../hooks/life-cycle';
import { CallDBActions } from '../../../../redux/call-db/call-db.action';
import { Button } from '../../../ui/button/button';

export const CallDB = () => {
  const dispatch = useDispatch();

  const onClick = useCallback(() => {
    dispatch(CallDBActions.api.callDb.get({
      param: {},
    }));
  }, []);

  useDidMount(() => {
    onClick();
  });

  return (
    <>
      <div>CallDataBase</div>
      <div>
        <Button
          size="md"
          color="secondary"
          className="ml_10"
          onClick={onClick}
        >
          Call
        </Button>
      </div>
    </>
  );
};
