import { IconButton } from '../components/ui/button/icon-button/icon-button';

/**
 * 改行コード変換
 * */
export const ConvertLineFeed = (props:
  {
    text: string[];
    className?: string;
    linkUrl?: string;
    urlIndex?: number;
  }) => {
  const {
    text, className, linkUrl, urlIndex,
  } = props;
  return (
    <>
      {text.map((v, i) => (
        <div key={i} className={className}>
          {v === '' ? <br /> : v}
          {(linkUrl && urlIndex !== undefined && i === urlIndex)
            && (
            <IconButton
              fontAwesomeClass="fas fa-external-link-alt"
              className="sns"
              size="sm"
              color="white"
              onClick={() => {
                if (!(linkUrl && urlIndex !== undefined)) return;
                window.open(linkUrl);
              }}
            />
            )}
        </div>
      ))}
    </>
  );
};

export const LineFeedConversion = (text: string[]) => text.map((v, i) => (
  <div key={i}>{v === '' ? <br /> : v}</div>
));
