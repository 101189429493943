import { put, takeEvery, takeLatest } from 'redux-saga/effects';
import lodash from 'lodash';
import { ProjectActions } from './project.action';
import { ApiProjectPost, ApiProjectGetList, ApiProjectGet } from './api/project/api-project';
import { DialogActions } from '../dialog/dialog.action';
import { Store } from '../store';
import {
  deleteHandle, getError, getHandle, getListHandle, postHandle,
} from '../root.saga';
import { ApiProject } from '../root.type';
import { ApiProjectIdDelete, ApiProjectIdGet } from './api/id/api-project-id';

function* tryProjectGet(action: ReturnType<typeof ProjectActions.api.project.get>) {
  const { param, callback, noLoad } = action.payload;
  if (!param.id) {
    yield getError('案件');
    return;
  }
  const api = new ApiProjectGet(param);
  yield getHandle<ApiProject.Response.Get>({
    noLoad,
    api,
    onSuccess: (result) => {
      Store.dispatch(ProjectActions.setProject(result));
      if (callback && result) callback(lodash.cloneDeep(result));
    },
    * onError() {
      yield getError('案件');
    },
  });
}

function* tryProjectGetList(action: ReturnType<typeof ProjectActions.api.project.getList>) {
  const {
    param, onSuccess, isLoad, isMapLoad, isRoute,
  } = action.payload;
  yield getListHandle<ApiProject.Response.List>({
    api: new ApiProjectGetList(param),
    noLoad: !isLoad,
    noDelay: true,
    mapLoad: isMapLoad,
    * onSuccess(result, count) {
      if (onSuccess && result) {
        onSuccess({
          data: result,
          count,
        });
      }
      if (isRoute) {
        yield put(ProjectActions.setRouteList(result));
        return;
      }
      yield put(ProjectActions.setList(result));
      yield put(ProjectActions.setListCount(count));
    },
  });
}

function* tryProjectGetCallbackList(action: ReturnType<
  typeof ProjectActions.api.project.getCallbackList>) {
  const { param, onSuccess, noLoad } = action.payload;
  yield getListHandle<ApiProject.Response.List>({
    api: new ApiProjectGetList(param),
    noDelay: true,
    noLoad: true,
    onSuccess,
  });
}

function* tryProjectPost(action: ReturnType<typeof ProjectActions.api.project.post>) {
  const {
    param, onSuccess, onError, update,
  } = action.payload;
  const api = new ApiProjectPost(param);
  yield postHandle({
    title: '案件情報',
    api,
    update,
    onSuccess: () => {
      Store.dispatch(DialogActions.pop());
      // Store.dispatch(ProjectActions.api.project.getList({ param: {} }));
      if (onSuccess)onSuccess();
    },
    onError: () => {
      if (onError)onError();
    },
  });
}

function* tryIdGet(action: ReturnType<typeof ProjectActions.api.id.get>) {
  const { callback, id } = action.payload;
  yield getHandle<{id: number}>({
    api: new ApiProjectIdGet({ id }),
    * onSuccess(result) {
      if (result) yield callback(result);
    },
  });
}

function* tryIdDelete(action: ReturnType<typeof ProjectActions.api.id.delete>) {
  yield deleteHandle({
    api: new ApiProjectIdDelete(action.payload),
    noMessage: true,
  });
}

export function* ProjectSaga() {
  yield takeLatest(ProjectActions.api.project.get, tryProjectGet);
  yield takeLatest(ProjectActions.api.project.post, tryProjectPost);
  yield takeLatest(ProjectActions.api.project.getList, tryProjectGetList);
  yield takeLatest(ProjectActions.api.project.getCallbackList, tryProjectGetCallbackList);
  yield takeLatest(ProjectActions.api.id.get, tryIdGet);
  yield takeLatest(ProjectActions.api.id.delete, tryIdDelete);
}
