import { useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { Table } from '../../../../../ui/table/table';
import { CustomerCollection } from '../../../../../../collection/customer/customer.collection';
import { DialogActions } from '../../../../../../redux/dialog/dialog.action';
import { LeftIconButton } from '../../../../../ui/button/left-icon-button/left-icon-button';
import { SupportHistoryEditPC } from '../../../support-history/edit/support-history-edit.pc';
import { State } from '../../../../../../redux/root.reducer';
import Deposite from '../../../../../../asset/images/icon/deposite.svg';
import { CustomerDetailActions } from '../../../../../../redux/customer-detail/customer-detail.action';
import { SupportHistorySortState, SupportHistoryListType } from '../../../../../../type/support-history/support-history.type';
import { SupportHistoryActions } from '../../../../../../redux/support-history/support-history.action';
import { DateFormatter } from '../../../../../../utilities/date-formatter';
import { RefindSupportHistoryTable } from './refind-support-history-table/refind-support-history-table';
import { SupportHistoryModel } from '../../../../../../model/support-history/support-history.model';
import { useAppSelector } from '../../../../../../hooks/use-redux';

export const SupportHistoryTable = () => {
  const dispatch = useDispatch();

  /* Hooks */
  const sort = useAppSelector((v) => v.customerDetail.supportSort);
  const list = useAppSelector((v) => v.customerDetail.supportList);
  const customer = useAppSelector((v) => v.customer.customer);
  const user = useAppSelector((v) => v.user);

  /* Callback */
  const [selected, setSelected] = useState<number[]>([]);

  /* 一覧取得 */
  const getList = useCallback((data?: SupportHistorySortState) => {
    const sortState = data || sort;

    if (customer?.id) {
      dispatch(SupportHistoryActions.api.supportHistory.getList({
        noLoad: true,
        param: SupportHistoryModel.listParamInCustomer({ sortState, customer_id: customer.id }),
        onSuccess: (v) => {
          dispatch(CustomerDetailActions.setSupportList(v));
        },
      }));
    }
  }, [customer?.id, sort]);

  const handleClickEdit = useCallback((v?:SupportHistoryListType) => {
    if (!customer) return;
    dispatch(DialogActions.push({
      title: '対応履歴新規登録',
      className: 'support_history',
      element: <SupportHistoryEditPC
        id={v?.id}
        customer={v ? undefined : customer}
        callback={getList}
      />,
    }));
  },
  [customer, getList]);

  const handleClickRow = useCallback((v:SupportHistoryListType) => {
    if (!list) return;
    const findIndex = list.findIndex((v2) => v2.id === v.id);
    if (findIndex !== -1) {
      setSelected([findIndex]);
    }
  }, [list]);

  const handleDbClickRow = useCallback((v:SupportHistoryListType) => {
    if (!list) return;
    const findIndex = list.findIndex((v2) => v2.id === v.id);
    if (findIndex !== -1) {
      setSelected([findIndex]);
    }
    handleClickEdit(v);
  }, [list, handleClickEdit]);

  const handleClickHeader = useCallback((highlow:0|1, sort_by:number) => {
    dispatch(CustomerDetailActions.setSupportSort({ highlow, sort_by }));
  }, []);

  useEffect(() => {
    getList();
  }, [customer?.id, sort.highlow, sort.sort_by]);

  const handleClickSortDialog = useCallback(() => {
    dispatch(DialogActions.push({
      title: '絞込み',
      element: <RefindSupportHistoryTable
        callback={(v) => {
          dispatch(CustomerDetailActions.setSupportList(null));
          getList(v);
        }}
      />,
    }));
  }, [getList, sort]);

  return (
    <div className="detail_table_area">
      <div className="btn_box">
        <LeftIconButton
          label="新規対応履歴登録"
          fontAwesomeClass="fas fa-edit"
          className="btn_search for_detail"
          size="sm"
          color="primary"
          onClick={() => handleClickEdit()}
        />
        <LeftIconButton
          label="絞込み"
          fontAwesomeClass="fas fa-filter"
          className="btn_search for_detail"
          size="sm"
          color="secondary"
          onClick={handleClickSortDialog}
        />
      </div>
      <div className="table_responsive">
        <Table
          className="table_selectable table_sortable table_sticky table_cell_change"
          header={CustomerCollection.supportHistoryHeader}
          selectedTr={selected}
          sort={{ onClick: handleClickHeader }}
          rowDataList={list || []}
          onClickRow={handleClickRow}
          onDbClick={handleDbClickRow}
          lists={list ? list.map((v) => (
            [
              v.support_flag ? <img src={Deposite} alt="対応済" title="対応済" className="icon" /> : '',
              v.project_name,
              DateFormatter.date2str(v.contact_dt, 'YYYYMMDD_HHmm'),
              v.support_name,
              v.title,
              v.support_employee_name,
              DateFormatter.date2str(v.support_dt, 'YYYYMMDD'),
            ]
          )) : null}
          option={{
            stringWidth: [
              { index: 0, width: 70 }, // 対応済
              // { index: 1, width: 100 }, // 案件名
              // { index: 2, width: 100 }, // 日付日時
              // { index: 3, width: 50 }, //  カテゴリ
              // { index: 4, width: 50 }, // 件名
              // { index: 5, width: 150 }, // 対応者
              // { index: 6, width: 100 }, // 対応日
            ],
            tdAlign: [
              { index: 0, align: 'center' },
              { index: 1, align: 'left' },
              { index: 2, align: 'center' },
              { index: 3, align: 'left' },
              { index: 4, align: 'left' },
              { index: 5, align: 'left' },
              { index: 6, align: 'center' },
            ],
          }}
        />
      </div>
    </div>

  );
};
