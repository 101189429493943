import * as lodash from 'lodash';
import { EstimateCalcModelPC } from '../../collection/estimate/estimate-calc.model.pc';
import { EstimateMeisaiModel } from './estimate-meisai.model';

export type FolderType = { isOpen: boolean } & {
  id: string;
  index: number;
  isOpen: boolean;
  title: string;
  percent: number;
};

type MenuType = {
  isOpen: boolean;
  title: string;
  percent: number;
  daibunrui: {
    id: string;
    index: number;
    isOpen: boolean;
    title: string;
    percent: number;
    chubunrui: FolderType[];
  }[];
};

export class EstimateMeisaiTreeModel {
  public data: MenuType;

  constructor(_data: EstimateMeisaiModel) {
    this.data = {
      title: '全て',
      percent: 0,
      isOpen: true,
      daibunrui: [],
    };

    /* 大分類割当 */
    this.data.daibunrui = this.createDaibunruiList(_data);

    /* 中分類割当 */
    this.data.daibunrui.forEach((v, i) => {
      const tyuBunruiList = this.createTyubunruiList(_data, v);
      const daibunrui = this.data.daibunrui[i];
      daibunrui.chubunrui = tyuBunruiList;
    });

    /* 粗利計算 */
    this.setArariPercent(_data);
  }

  /* 大分類リストの作成 */
  createDaibunruiList(data: EstimateMeisaiModel):MenuType['daibunrui'] {
    const styDaibunrui:string[] = [];
    return data.list
      .filter((v) => {
        const result = !styDaibunrui.includes(v.$.daibunrui_id);
        styDaibunrui.push(v.$.daibunrui_id);
        return result;
      })
      .map((v, i) => ({
        index: i + 1,
        id: v.$.daibunrui_id,
        isOpen: true,
        title: v.$.daibunrui_name_out,
        percent: 0,
        chubunrui: [],
      }));
  }

  /* 中分類リストの生成 */
  private createTyubunruiList(data: EstimateMeisaiModel, daibunrui: MenuType['daibunrui'][0]) {
    const styTyubunrui: string[] = [];
    return data.list
      .filter((v) => {
        const result = v.$.daibunrui_id === daibunrui.id
      && !styTyubunrui.includes(v.$.tyubunrui_id);
        styTyubunrui.push(v.$.tyubunrui_id);
        return result;
      })
      .map((v2, i) => ({
        index: i + 1,
        id: v2.$.tyubunrui_id,
        isOpen: false,
        title: v2.$.tyubunrui_name_out,
        percent: 0,
        meisaiList: [],
      }));
  }

  /* フォルダ横のパーセント適用 */
  setArariPercent(_data: EstimateMeisaiModel) {
    const model = EstimateCalcModelPC;
    // 大分類の合計
    let sDaikingaku = 0;
    let sDaiGenkaKei = 0;

    this.data.daibunrui.forEach((dv, di) => {
      // 中分類の合計
      let sTyukingaku = 0;
      let sTyuGenkaKei = 0;

      dv.chubunrui.forEach((tv, ti) => {
        const { tyuKingaku, tyuGenka_kei } = _data.list.filter((
          f,
        ) => f.$.daibunrui_id === dv.id
          && f.$.tyubunrui_id === tv.id)
          .map((v) => ({
            tyuKingaku: v.$.kingaku, tyuGenka_kei: v.$.genka_kei,
          }))
          .reduce((sum, next) => ({
            tyuKingaku: Number(sum.tyuKingaku) + Number(next.tyuKingaku),
            tyuGenka_kei: Number(sum.tyuGenka_kei) + Number(next.tyuGenka_kei),
          }), { tyuKingaku: 0, tyuGenka_kei: 0 });

        // 中分類粗利率
        this.data.daibunrui[di].chubunrui[ti].percent = model.arariRitsuCal2(
          tyuKingaku, tyuGenka_kei,
        );

        sTyukingaku += tyuKingaku;
        sTyuGenkaKei += tyuGenka_kei;
      });

      sDaikingaku += sTyukingaku;
      sDaiGenkaKei += sTyuGenkaKei;

      // 大分類粗利率
      this.data.daibunrui[di].percent = model.arariRitsuCal2(sTyukingaku, sTyuGenkaKei);
    });

    // 全ての粗利率
    this.data.percent = model.arariRitsuCal2(sDaikingaku, sDaiGenkaKei);
  }

  /* フォルダ開く処理 */
  changeOpen(param:{isAll?:boolean, daibunrui?:string}) {
    const { isAll, daibunrui } = param;
    if (isAll) {
      this.data.isOpen = !this.data.isOpen;
      return;
    }

    if (daibunrui !== undefined) {
      const findDaibunrui = this.data.daibunrui.find((v) => v.id === String(daibunrui));
      if (findDaibunrui) {
        findDaibunrui.isOpen = !findDaibunrui.isOpen;
      }
    }
  }

  /* 印刷名称変更 */
  changeName(param: { daibunrui: string, chuBunrui?: string; }, name:string) {
    const { daibunrui, chuBunrui } = param;
    const index = this.data.daibunrui.findIndex((v) => v.id === daibunrui);
    const daibunruiData = this.data.daibunrui[index];
    if (chuBunrui) {
      const chununruiList = daibunruiData.chubunrui;
      chununruiList.forEach((_, i) => {
        this.data.daibunrui[index].chubunrui[i].title = name;
      });
    } else {
      daibunruiData.title = name;
    }
  }

  /* フォルダを動かす */
  moveFolder(param:{daibunrui?:string, chuBunrui?:string, isUp: boolean}) {
    const { daibunrui, chuBunrui, isUp } = param;

    if (daibunrui === undefined) return;
    const daibunruiIndex = this.data.daibunrui.findIndex((v) => v.id === String(daibunrui));
    const chubunruiIndex = this.data.daibunrui[daibunruiIndex].chubunrui
      .findIndex((v) => v.id === chuBunrui);

    if (daibunrui !== undefined && chuBunrui !== undefined) {
      const stay = lodash.cloneDeep(
        this.data.daibunrui[daibunruiIndex].chubunrui[chubunruiIndex],
      );
      const chubunruiList = this.data.daibunrui[daibunruiIndex].chubunrui;

      const moveChubunrui = () => {
        const val = isUp ? -1 : +1;
        chubunruiList[chubunruiIndex] = lodash.cloneDeep(
          chubunruiList[chubunruiIndex + val],
        );
        chubunruiList[chubunruiIndex + val] = lodash.cloneDeep(stay);
      };

      if (isUp && chubunruiIndex !== 0) {
        moveChubunrui();
      }
      if (!isUp && chubunruiIndex !== chubunruiList.length - 1) {
        moveChubunrui();
      }
      return;
    }

    if (daibunrui !== undefined) {
      const stay = lodash.cloneDeep(
        this.data.daibunrui[daibunruiIndex],
      );
      const daibunruiList = this.data.daibunrui;

      const move = () => {
        const val = isUp ? -1 : +1;
        daibunruiList[daibunruiIndex] = lodash.cloneDeep(
          daibunruiList[daibunruiIndex + val],
        );
        daibunruiList[daibunruiIndex + val] = lodash.cloneDeep(stay);
      };
      if (isUp && daibunruiIndex !== 0) {
        move();
      }
      if (!isUp && daibunruiIndex !== daibunruiList.length - 1) {
        move();
      }
    }
  }
}
