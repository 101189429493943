import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { goBack, push } from 'connected-react-router';
import { cloneDeep, isEqual } from 'lodash';
import { DisplayElements } from '../../../../../type/display-elements.type';
import { Table } from '../../../../ui/table/table';
import { RightLabelCheckbox } from '../../../../ui/checkbox/right-label-checkbox/right-label-checkbox';
import { LeftIconButton } from '../../../../ui/button/left-icon-button/left-icon-button';
import { Button } from '../../../../ui/button/button';
import { TableOption } from '../../../../../type/table.type';
import { MasterActions } from '../../../../../redux/master/master.action';
import { MasterIndexType } from '../../../../../redux/master/api/api-master-index';
import { DialogActions } from '../../../../../redux/dialog/dialog.action';
import { ChangeCompanyActions } from '../../../../../redux/changecompany/changecompany-action';
import { ApiContractedChangeCompanyPostParam } from '../../../../../redux/changecompany/api-contracted-changecompany.type';
import { Checkbox } from '../../../../ui/checkbox/checkbox';
import { RoutingPath } from '../../../../../routes/routing-pass';
import { UserActions } from '../../../../../redux/user/user.action';
import { TopLabelInputField } from '../../../../ui/input-field/top-label-input-field/top-label-input-field';
import { Input } from '../../../../ui/input/input';
import { ApiMasterContractedCompanyGetListParam } from '../../../../../redux/master/api/master-contracted-company/api-master-contracted-company.type';

export type MasterGetListParam = {
  highlow: number;
  sort_by: number;
}

type Props = {
  header: { key: string; label: string;}[];
  rowDataList: any[];
  list: DisplayElements[][];
  callbackGetList: (v: ApiMasterContractedCompanyGetListParam) => void;
  callbackIsSort?: (v: boolean) => void;
  callbackIsMuko?: (v: boolean) => void;
  callbackCompanyName?: (v: string) => void;
  sortIndex?: MasterIndexType;
  defaultOrder: number;
  tableOption?: TableOption;
  isNoEdit?: boolean;
  isLoad?: boolean,
};

export const ChangeCompanyBody = (props: Props) => {
  const {
    rowDataList,
    header,
    callbackGetList,
    callbackIsSort,
    callbackIsMuko,
    callbackCompanyName,
    sortIndex,
    list,
    tableOption,
    defaultOrder,
    isNoEdit,
    isLoad,
  } = props;

  const dispatch = useDispatch();

  /* State */
  const [isSort, setIsSort] = useState(false);
  const [showList, setShowList] = useState<DisplayElements[][]>([]);
  const [rowList, setRowList] = useState<any[]>([]);
  const [selected, setSelected] = useState(NaN);
  const [selectedCompanyId, setSelectedCompanyId] = useState(NaN);
  const [isMuko, setIsMuko] = useState(false);
  const [companyName, setCompanyName] = useState('');
  const [dispMuko, setDispMuko] = useState(false);
  const [orderSort, setOrderSort] = useState<ApiMasterContractedCompanyGetListParam>({
    highlow: defaultOrder,
    sort_by: 0,
  });

  const sendRowList = useMemo(() => {
    const li = rowDataList.filter((v) => ((dispMuko || isSort) ? true : v.valid_flag));
    return li;
  }, [rowDataList, dispMuko, isSort, companyName]);

  /* Callback */
  const handleClickRow = useCallback(
    (row: any) => {
      if (row.employee_status < 2) {
        return;
      }
      setSelected(cloneDeep(sendRowList.findIndex((v2) => v2.id === row.id)));
      setSelectedCompanyId(row.id);
    }, [sendRowList],
  );

  const getList = useCallback(() => {
    callbackGetList({
      ...orderSort,
      company_name: companyName,
    });
  },
  [callbackGetList, orderSort, isMuko, companyName]);

  const sortStart = useCallback(() => {
    setSelected(NaN);
    setIsSort(true);
    callbackIsSort?.(true);
  }, []);

  const sortEnd = useCallback((isRegister: boolean = false) => {
    setSelected(NaN);
    setIsSort(false);
    callbackIsSort?.(false);
    if (!sortIndex) return;
    if (isRegister) {
      const _v = rowList.map((f) => (f.id));
      const _list = rowDataList.map((f) => (f.id));
      if (!isEqual(_v, _list)) {
        dispatch(MasterActions.api.index({
          param: {
            type: sortIndex,
            ids: _v.map((f) => f),
          },
          onSuccess: () => {
            callbackGetList(orderSort);
          },
          onError: () => {
            setIsSort(true);
          },
        }));
      }
    }
  }, [showList, rowDataList, orderSort]);

  const handleClickHeader = useCallback((highlow, sort_by) => {
    setOrderSort({ highlow, sort_by });
  }, []);

  const sortCancel = useCallback(() => {
    sortEnd();
    setShowList(cloneDeep(list));
    setRowList(cloneDeep(rowDataList));
  }, [list, rowDataList]);

  const onMove = useCallback((_showList: DisplayElements[][], _rowList: any[]) => {
    _showList.forEach((v) => {
      v.shift();
    });
    setShowList(cloneDeep(_showList));
    setRowList(cloneDeep(_rowList));
  }, []);

  const onClickSearch = useCallback(() => {
    setDispMuko(isMuko);
    callbackIsMuko?.(isMuko);
    callbackCompanyName?.(companyName);
    getList();
  }, [isMuko, rowDataList, companyName]);

  const handleClickChangeCompany = useCallback(() => {
    console.log(rowDataList);
    const find = rowDataList.find((v) => v.id === selectedCompanyId);
    if (!find) return;
    dispatch(DialogActions.pushMessage({
      title: '確認',
      message: ['参照会社を', `${find.name}`, 'に変更します。よろしいですか？'],
      label: '変更',
      isCancel: true,
      callback: () => {
        dispatch(ChangeCompanyActions.api.contractedChangeCompany.post({
          param: {
            chg_company_id: selectedCompanyId,
          },
          onSuccess: () => {
            dispatch(push(RoutingPath.customer));
            window.location.reload();
          },
        }));
      },
    }));
  }, [dispatch, selectedCompanyId, rowDataList]);

  /* Effect */
  useEffect(() => {
    getList();
  }, [orderSort]);

  useEffect(() => {
    setShowList(list);
  }, [list]);

  useEffect(() => {
    setRowList(cloneDeep(rowDataList));
  }, [rowDataList]);

  const sendList = useMemo(() => showList.map((row: any, i) => [
    <input
      type="radio"
      onChange={() => {}}
      checked={row[0] === selectedCompanyId}
      value={row[0]}
    />,
    ...row,
  ]), [showList, rowDataList, isSort, selectedCompanyId, sendRowList]);

  return (
    <div className="editPc_wrap">
      <div className="editPc_body">
        <div className="search_area only_simple ">
          <div className="item_wrap">
            <div className="item_box">
              <div className="item_head">契約会社名</div>
              <Input
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
                className="width_large"
              />
            </div>
            <div className="item_box">
              <RightLabelCheckbox
                checked={isMuko}
                label="無効情報も含む"
                onChange={() => setIsMuko(!isMuko)}
              />
            </div>
            <LeftIconButton
              label="絞込み"
              fontAwesomeClass="fas fa-filter"
              className="btn_search for_simple"
              size="sm"
              color="secondary"
              onClick={onClickSearch}
            />
          </div>
        </div>
        <div className="option_area table_sort">
          <div className="left">
            <div className="count">総件数： &nbsp;<span>{list.length}</span> 件</div>
          </div>
          <div className="right" style={{ display: 'flex' }}>
            {isSort
              ? (
                <>
                  <Button
                    color="dark"
                    size="sm"
                    onClick={sortCancel}
                  >
                    キャンセル
                  </Button>
                  <LeftIconButton
                    label="並び替え登録"
                    size="sm"
                    fontAwesomeClass="fas fa-edit"
                    className="btn_search for_detail"
                    color="primary"
                    onClick={() => sortEnd(true)}
                  />
                </>
              )
              : (
                <>
                  {!isNoEdit && (
                  <LeftIconButton
                    label="並び替え"
                    fontAwesomeClass="fas fa-sort"
                    className=""
                    size="sm"
                    color="secondary"
                    onClick={sortStart}
                  />
                  )}
                </>
              )}
          </div>
        </div>
        <section className="result_area list_area">
          <div className="inner">
            <div className="table_responsive">
              {/* <Table /> */}
              <Table
                className={`table_selectable table_sortable table_sticky table_cell_change ${isSort ? 'sort_mode' : ''}`}
                header={header.map((v) => v.label)}
                onClickRow={handleClickRow}
                selectedTr={[selected]}
                rowDataList={sendRowList}
                isMukoData={[
                  {
                    key: 'employee_status',
                    value: 2,
                    comparison: (v, v2) => v < v2,
                  },
                  {
                    key: 'mail_address',
                    value: null,
                    comparison: (v, v2) => v !== v2,
                  },
                ]}
              // ids={cloneList.map((v)=>(v.idx))}
              // sort={{ onClick: (highlow, sort_by) => setOrderSort({ highlow, sort_by }) }}
                sort={{ onClick: handleClickHeader }}
                lists={isLoad ? sendList : null}
                callbackMoveList={onMove}
                option={tableOption}
                isDnD={isSort}
                isMukoDisp
              />
            </div>
          </div>
        </section>
      </div>
      <div className="editPc_footer base_footer">
        <Button
          onClick={handleClickChangeCompany}
          color="primary"
          disabled={Number.isNaN(selectedCompanyId)}
        >
          変更
        </Button>
        <Button
          onClick={() => {
            dispatch(DialogActions.pop());
          }}
          color="dark"
        >
          キャンセル
        </Button>
      </div>
    </div>
  );
};
