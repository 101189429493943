import { goBack, push } from 'connected-react-router';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { DialogActions } from '../../../../redux/dialog/dialog.action';
import { State } from '../../../../redux/root.reducer';
import { SupportHistoryActions } from '../../../../redux/support-history/support-history.action';
import { LeftIconButton } from '../../../ui/button/left-icon-button/left-icon-button';
import { RectLabel } from '../../../ui/label/rect-label/rect-label';
import { BasePageSP } from '../base.page.sp';
import { SupportHistoryEditSP } from '../support-history/edit/support-history-edit.sp';
import './support-history-detail.sp.scss';
import { DateFormatter } from '../../../../utilities/date-formatter';
import { useWillUnMount } from '../../../../hooks/life-cycle';
import { SearchBoxDialogTitle } from '../../layout/search-box/search-box.type.sp';
import { SearchBoxSupportHistorySP } from '../support-history/serch-box/support-history-search-box.sp';
import { RoutingPath } from '../../../../routes/routing-pass';
import { LoadingFileImg } from '../../../ui/file-img/loading-file-img';

export const SupportHistoryDetailSP = () => {
  /* Hooks */
  const { id } = useParams<{ id: string; }>();
  const supportHistoryInfo = useSelector((state: State) => state.supportHistory.supportHistory);
  const dispatch = useDispatch();

  /* Callback */
  /* 1件取得 */
  const getData = useCallback(() => {
    dispatch(SupportHistoryActions.api.supportHistory.get({
      param: { id: Number(id) },
    }));
  }, [id]);

  const handleClickEdit = useCallback(() => {
    dispatch(DialogActions.push({
      title: '対応履歴編集',
      element: <SupportHistoryEditSP
        id={Number(id)}
        callback={getData}
      />,
    }));
  }, [id]);

  /* Effect */
  useEffect(() => {
    getData();
  }, [id]);

  useWillUnMount(() => {
    dispatch(SupportHistoryActions.setSupportHistory(null));
  });

  return (
    <BasePageSP
      className="support_history_detail_sp"
      searchBoxDialog={{
        title: SearchBoxDialogTitle,
        element: <SearchBoxSupportHistorySP callback={() => {
          dispatch(DialogActions.pop());
          dispatch(push(RoutingPath.supportHistory));
        }}
        />,
      }}
      searchCallback={(v) => {
        dispatch(SupportHistoryActions.setSort({ keyword: v }));
        dispatch(push(RoutingPath.supportHistory));
      }}
    >
      <div className="detail_wrap">

        <div className="detail_header">
          <div
            className="detail_header_inner"
            onClick={() => { dispatch(goBack()); }}
          >
            <div
              className="detail_header_inner__back_btn"
            >
              <i className="fas fa-chevron-circle-left" />
            </div>
            対応履歴詳細
          </div>

          <div className="detail_header_buttons">
            <LeftIconButton
              color="secondary"
              size="md"
              onClick={handleClickEdit}
              fontAwesomeClass="fa fa-edit"
              label="対応履歴編集"
            />
          </div>
        </div>

        <div className="detail_body support_history_detail_sp__body">
          <div className="row_table_style support_history_detail_sp__body__table">
            <div className="col_2">
              <div>
                <div className="t_row">
                  <div className="t_header">顧客名</div>
                  <div className="t_body">{supportHistoryInfo?.customer_name}</div>
                </div>
                <div className="t_row">
                  <div className="t_header">案件名</div>
                  <div className="t_body">{supportHistoryInfo?.project_name || '---'}</div>
                </div>
                <div className="t_row">
                  <div className="t_header">登録日時</div>
                  <div className="t_body">{
                  supportHistoryInfo
                    ? DateFormatter.date2str(supportHistoryInfo?.contact_dt, 'YYYYMMDD_HHmm')
                    : '---'
                }
                  </div>
                </div>
                <div className="t_row">
                  <div className="t_header">登録者</div>
                  <div className="t_body">{supportHistoryInfo?.created_employee_name || '---'}</div>
                </div>
              </div>
              <div>
                <div className="thumb google ml_5">
                  <LoadingFileImg
                    id={supportHistoryInfo?.icon_thumbnail_id}
                    format={supportHistoryInfo?.icon_thumbnail_format}
                    fileName=""
                  />
                </div>
                {supportHistoryInfo?.support_flag
                  ? (
                    <div>
                      <RectLabel
                        label={`${'対応済'}`}
                        bgColor={`${'#1451a1'}`}
                      />
                    </div>
                  ) : (
                    <div>
                      <RectLabel
                        label={`${'未対応'}`}
                        bgColor={`${'#9f3a38'}`}
                      />
                    </div>
                  )}
              </div>
            </div>
            <div className="t_row">
              <div className="t_header">媒体</div>
              <div className="t_body">{supportHistoryInfo?.support_name || '---'}</div>
            </div>
            <div className="t_row">
              <div className="t_header">カテゴリ</div>
              <div className="t_body">{supportHistoryInfo?.source_name || '---'}</div>
            </div>
            <div className="t_row">
              <div className="t_header">対応履歴名</div>
              <div className="t_body">{supportHistoryInfo?.title || '---'}</div>
            </div>
            <div className="t_row">
              <div className="t_header">対応内容</div>
              <div className="t_body">{supportHistoryInfo?.comment || '---'}</div>
            </div>
            <div className="t_row">
              <div className="t_header">対応者</div>
              <div className="t_body">{supportHistoryInfo?.support_employee_name || '---'}</div>
            </div>
            <div className="t_row">
              <div className="t_header">対応日</div>
              <div className="t_body">{
              DateFormatter.date2str(supportHistoryInfo?.support_dt, 'YYYYMMDD') || '---'
              }
              </div>
            </div>
            <div className="t_row">
              <div className="t_header">更新日</div>
              <div className="t_body">{DateFormatter.date2str(supportHistoryInfo?.updated_at, 'YYYYMMDD_HHmm') || '---'}</div>
            </div>
            <div className="t_row">
              <div className="t_header">更新者</div>
              <div className="t_body">{supportHistoryInfo?.updated_employee_name || '---'}</div>
            </div>
            <div className="info_wrap_bottom" />
          </div>
        </div>
      </div>
    </BasePageSP>
  );
};
