import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';
import { useWillUnMount, useDidMount } from '../../../../../../hooks/life-cycle';
import { useAppSelector } from '../../../../../../hooks/use-redux';
import { ProjectActions } from '../../../../../../redux/project/project.action';
import { ProjectListType, ProjectSortState } from '../../../../../../type/project/project.type';
import { DialogActions } from '../../../../../../redux/dialog/dialog.action';
import { ProjectModel } from '../../../../../../model/project/project.model';
import { ProjectSearchBoxSP } from '../../../../pages/project/search-box/project-search-box.sp';
import { ProjectListSP } from '../../../body/list/project-list/project-list';
import { Customer, CustomerListType } from '../../../../../../type/customer/customer.type';
import { TableSort } from '../../../../../ui/table/table-sort/table-sort';
import { ProjectCollection } from '../../../../../../collection/project/project.collection';
import { SupportHistoryEditState } from '../../../../../../type/support-history/support-history.type';
import { SystemActions } from '../../../../../../redux/system/system.action';

type Props = {
  type?: 'estimate' | 'order' | 'maintenance' | 'support' | 'file';
  customerData?: Customer | CustomerListType,
  callback: (v: ProjectListType) => void;
  supportData?: SupportHistoryEditState;
  freeAuth?: boolean;
  onSearch?: () => void;
  isOrder?: boolean;
  isNoStore?: boolean;
  callbackIsSearch?: (v: boolean) => void;
  initIsSearch?:boolean;
}

export const ProjectSearchSP = (props: Props) => {
  const {
    type, callback, customerData, supportData, freeAuth,
    onSearch, isOrder, isNoStore, callbackIsSearch, initIsSearch,
  } = props;

  const dispatch = useDispatch();
  const user = useAppSelector((v) => (v.user));
  const [list, setList] = useState<ProjectListType[] | null>(null);
  const [hitCount, setHitCount] = useState(0);
  const [isSearch, setIsSearch] = useState(initIsSearch || false);
  const [sort, setSort] = useState(ProjectCollection._initialSortState(user));

  const getList = useCallback((v?: ProjectSortState) => {
    setList(null);
    const sortData = v || sort;
    dispatch(SystemActions.isLoading(true));

    dispatch(ProjectActions.api.project.getList({
      param: {
        ...ProjectModel.listParam({
          sortState: v || sort,
          type,
          customerID: customerData?.id,
        }),
        project_employee_id: (user.authority1 || freeAuth)
          ? sortData.project_employee_id : user.id,
        project_store_id: (user.authority1 || freeAuth)
          ? sortData.project_store_id : user.store_id,
      },
      onSuccess: (data, hit) => {
        dispatch(SystemActions.isLoading(false));
        if (!data) return;
        const _list = data.data;
        if (!_list.length) {
          dispatch(DialogActions.pushMessage({
            title: '',
            message: ['該当する結果が見つかりません'],
          }));
          return;
        }
        setIsSearch(true);
        setHitCount(data.count || 0);
        setList(data.data);
      },
      onError: () => dispatch(SystemActions.isLoading(false)),
    }));
  }, [sort, type, customerData]);

  const handleCardClick = useCallback((v: ProjectListType) => {
    callback(v);
  }, []);

  useEffect(() => {
    if (!isSearch) return;
    getList(sort);
  }, [
    sort.offset,
  ]);

  useWillUnMount(() => {
    dispatch(ProjectActions.setInitSort(user));
  });

  useDidMount(() => {
    if (supportData) {
      console.log(supportData.customer_id, supportData.customer_name);
      setSort({
        ...cloneDeep(sort),
        customer_id: supportData.customer_id || NaN,
        customer_name: supportData.customer_name || '',
      });
    }
  });

  useEffect(() => {
    callbackIsSearch?.(isSearch);
  }, [isSearch]);

  useEffect(() => {
    console.log('koko');
    setIsSearch(!!initIsSearch);
  }, [initIsSearch]);

  return (
    !isSearch
      ? (
        <ProjectSearchBoxSP
          noAuthority={freeAuth ? false : !user.authority1}
          isSearch
          initialSort={isNoStore ? sort : undefined}
          customerData={customerData}
          getList={(v) => {
            getList(v);
            setSort(cloneDeep(v));
            onSearch?.();
          }}
          type={type}
        />
      )
      : (
        <>
          <TableSort
            isSp
            page={sort.offset ?? 0}
            limit={100}
            hitCount={hitCount || 0}
            callback={(v) => {
              dispatch(ProjectActions.setSort({ offset: v }));
            }}
            className="mt_5 mb_0"
          />
          <hr className="hr_style hr_search_result" />
          <ProjectListSP data={list} handleCardClick={handleCardClick} isOrder={isOrder} />
        </>
      )
  );
};
