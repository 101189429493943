import { cloneDeep } from 'lodash';

export const adjustString = (v: string, adjustCount: number, halfSize: number = 0.5): string => {
  let count = 0;
  let str = '';
  v.split('').forEach((v2) => {
    if (count <= (adjustCount)) {
      if (v2.match(/[ -~ｦ-ﾟ]/) || v2 === ' ') {
        count += halfSize;
      } else {
        count += 1;
      }
      str += v2;
    }
  });
  return str;
};

export const countString = (v: string, halfSize: number = 0.5): number => {
  let count = 0;
  (v || '').split('').forEach((v2) => {
    if (v2.match(/[ -~ｦ-ﾟ]/) || v2 === ' ') {
      count += halfSize;
    } else {
      count += 1;
    }
  });
  return count;
};

export const adjustStringAry = (
  v: string, adjustCount: number, halfSize: number = 0.5,
): string[] => {
  let count = 0;
  const arr: string[] = [''];
  let index = 0;
  v.split('').forEach((v2) => {
    if (count <= (adjustCount)) {
      if (v2.match(/[ -~ｦ-ﾟ]/) || v2 === ' ') {
        count += halfSize;
      } else {
        count += 1;
      }
      arr[index] += v2;
    } else {
      index += 1;
      if (v2.match(/[ -~ｦ-ﾟ]/) || v2 === ' ') {
        count = halfSize;
      } else {
        count = 1;
      }
      arr.push(v2);
    }
  });
  return arr;
};

type DetailParam = {
  count: number;
  small: number;
  big: number;
  total: number;
}

export const countStringDetail = (v: string, halfSize: number = 0.5): DetailParam => {
  let count = 0;
  let small = 0;
  let big = 0;

  (v || '').split('').forEach((v2) => {
    if (v2.match(/[ -~ｦ-ﾟ]/) || v2 === ' ') {
      count += halfSize;
      small += 1;
    } else {
      count += 1;
      big += 1;
    }
  });

  return {
    count, small, big, total: small + big,
  };
};

export const someLineString = (
  v: string, adjustCount: number, joinLimit: number, halfSize: number = 0.5,
): string => {
  const ary = adjustStringAry(v, adjustCount - 1, halfSize);
  if (ary.length < 2) return v;
  let str = '';
  ary.forEach((v2, i) => {
    if (i + 1 <= joinLimit) {
      str += i !== 0 ? '\n' : '';
      str += v2;
    }
  });
  return str;
};
