import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DummyImg from '../../../../../asset/images/ei-picture.svg';
import { State } from '../../../../../redux/root.reducer';
import '../../info-tab-contents.sp.scss';
import { DateFormatter } from '../../../../../utilities/date-formatter';
import { splitStr } from '../../../../../utilities/split-str';

import { IconButton } from '../../../button/icon-button/icon-button';
import { openTel } from '../../../../../utilities/open-tel';
import { DialogActions } from '../../../../../redux/dialog/dialog.action';
import { SupportHistoryEditDialogTitle } from '../../../../sp/pages/support-history/edit/support-history-edit.type';
import { SupportHistoryEditSP } from '../../../../sp/pages/support-history/edit/support-history-edit.sp';
import { Project } from '../../../../../type/project/project.type';
import { DisplayElements } from '../../../../../type/display-elements.type';
import { MathHelper } from '../../../../../utilities/math-helper';
import { useTelContact } from '../../../../../hooks/support-history/use-support-history';
import { Button } from '../../../button/button';
import { isMobilePhone } from '../../../../../utilities/check-mobile-phone';

const Item = ({ title, value, address }: {
  title: string; value: DisplayElements; address?: boolean;
}) => (
  <div className="t_row">
    <div className="t_header">{title}</div>
    <div className={`t_body${address ? ' address' : ''}`}>{value}</div>
  </div>
);

export const ProjectInfoTabContentsSP = () => {
  /* Hook */
  const { project } = useSelector((state:State) => state.project);
  const dispatch = useDispatch();
  const telContact = useTelContact();

  const dummyImg = useMemo(() => DummyImg, [DummyImg]);
  const handleClickTel = useCallback((tel: string | undefined) => {
    telContact({
      type: 'project',
      data: project,
      isPushDialog: true,
    });
  }, [project]);

  const handleClickSMS = useCallback(() => {
    telContact({
      type: 'project',
      data: project,
      isSMS: true,
      isPushDialog: true,
    });
  }, [project]);

  /* TODO front 画像の有無判定お願いします。 */
  const imgSrc = '';
  return (
    <div className="detail_info_table row_table_style">
      {imgSrc && (
      <div style={{ height: '200px' }}>
        <img src={dummyImg} alt="" style={{ width: '100%', maxHeight: '200px' }} />
      </div>
      )}
      <Item title="案件名" value={project?.name || '---'} />
      <Item title="案件ID" value={project?.internal_id || '---'} />
      <Item title="顧客名" value={project?.customer_name ? `${project?.customer_name} 様` : '---'} />
      <Item title="顧客ID" value={project?.customer_internal_id || '---'} />
      <Item
        title="顧客住所"
        address
        value={(
          <>
            {`${project?.post_no ? `〒${project.post_no}` : '---'}`}<br />
            {project?.customer_place || '---'}
          </>
        )}
      />
      <Item title="案件見込みランク" value={project?.project_rank_name || '---'} />
      {/* FIXME */}
      <Item title="現場名称" value={'' || '---'} />
      <Item
        title="現場住所"
        address
        value={(
          <>
            {project?.field_post_no ? `〒${project?.field_post_no}` : '---'}<br />
            {project?.field_place}
            {project?.field_building_name}
          </>
        )}
      />
      <div className="t_row">
        <div className="t_header">現場電話</div>
        <div className="t_body">{project?.field_tel_no || '---'}</div>
        <div>
          <IconButton
            fontAwesomeClass="fas fa-phone"
            onClick={() => handleClickTel(project?.field_tel_no)}
            size="md"
            color="secondary"
            disabled={!project?.field_tel_no}
          />
          <IconButton
            color="secondary"
            size="md"
            onClick={handleClickSMS}
            disabled={!project?.field_tel_no || !isMobilePhone(project?.field_tel_no)}
            fontAwesomeClass="fas fa-sms"
            className="ml_5"
          />
        </div>
      </div>
      <Item title="現場FAX" value={project?.field_fax_no || '---'} />
      <Item title="案件担当店舗" value={project?.project_store_name || '---'} />
      <Item title="案件担当者" value={project?.project_employee_name || '---'} />
      <Item title="発生源" value={project?.source_name || '---'} />
      <Item title="工事部位" value={splitStr(project?.construction_part_names) || '---'} />
      <Item title="見込み金額" value={project?.mitumori_kin ? MathHelper.localStr(project.mitumori_kin) : '---'} />
      <Item title="契約番号" value={project?.contract_no || '---'} />
      <Item title="契約日" value={project?.contract_date ? DateFormatter.date2str(project?.contract_date) : '---'} />
      <Item title="受注工期（開始）" value={project?.construction_period_start ? DateFormatter.date2str(project?.construction_period_start) : '---'} />
      <Item title="受注工期（終了）" value={project?.construction_period_end ? DateFormatter.date2str(project?.construction_period_end) : '---'} />
      <Item title="着工予定日" value={project?.construction_start_date ? DateFormatter.date2str(project?.construction_start_date) : '---'} />
      <Item title="完工予定日" value={project?.completion_end_date ? DateFormatter.date2str(project?.completion_end_date) : '---'} />
      <Item title="着工日" value={project?.construction_date ? DateFormatter.date2str(project?.construction_date) : '---'} />
      <Item title="完工日" value={project?.completion_date ? DateFormatter.date2str(project?.completion_date) : '---'} />
      <Item title="失注日" value={project?.failure_date ? DateFormatter.date2str(project?.failure_date) : '---'} />
      <Item title="失注理由" value={project?.failure_cause_name || '---'} />
      <Item title="備考" value={project?.failure_remarks || '---'} />
      <Item title="キャンセル日" value={project?.cancel_date ? DateFormatter.date2str(project?.cancel_date) : '---'} />
      <Item title="キャンセル理由" value={project?.cancel_reason || '---'} />
      <Item title="登録日" value={DateFormatter.date2str(project?.created_at, 'YYYYMMDD_HHmm') || '---'} />
      <Item title="登録者" value={project?.created_employee_name || '---'} />
      <Item title="更新日" value={DateFormatter.date2str(project?.updated_at, 'YYYYMMDD_HHmm') || '---'} />
      <Item title="更新者" value={project?.updated_employee_name || '---'} />

    </div>
  );
};
