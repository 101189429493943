import * as DateFns from 'date-fns';
import { DateFormatter } from './date-formatter';

export class DateHelper {
  /**
   * 年計算
   * @static
   * @param {(string | Date | null | undefined)} _date 比較日付
   * @param {number} year 計算する年
   * @param {('add' | 'sub')} [mode='add'] 加減
   * @returns {(Date | undefined)}
   */
  static calcYear(
    _date: string | Date | null | undefined,
    year: number,
    mode: 'add' | 'sub' = 'add',
  ): Date | undefined {
    if (!_date) return undefined;
    const date = typeof _date === 'string' ? new Date(_date) : _date;
    if (mode === 'add') return DateFns.addYears(date, year);
    return DateFns.subYears(date, year);
  }

  /**
   * 月計算
   * @static
   * @param {(string | Date | null | undefined)} _date 比較日付
   * @param {number} year 計算する月
   * @param {('add' | 'sub')} [mode='add'] 加減
   * @returns {(Date | undefined)}
   */
  static calcMonth(
    _date: string | Date | null | undefined,
    month: number,
    mode: 'add' | 'sub' = 'add',
  ): Date | undefined {
    if (!_date) return undefined;
    const date = typeof _date === 'string' ? new Date(_date) : _date;
    if (mode === 'add') return DateFns.addMonths(date, month);
    return DateFns.subMonths(date, month);
  }

  /**
   * 日計算
   * @static
   * @param {(string | Date | null | undefined)} _date 比較日付
   * @param {number} year 計算する日
   * @param {('add' | 'sub')} [mode='add'] 加減
   * @returns {(Date | undefined)}
   */
  static calcDay(
    _date: string | Date | null | undefined,
    day: number,
    mode: 'add' | 'sub' = 'add',
  ): Date | undefined {
    if (!_date) return undefined;
    const date = typeof _date === 'string' ? new Date(_date) : _date;
    if (mode === 'add') return DateFns.addDays(date, day);
    return DateFns.subDays(date, day);
  }

  static calcDate2Str(
    type: 'year' | 'month' | 'day',
    _date: string | Date | null | undefined,
    num: number,
    mode: 'add' | 'sub' = 'add',
  ) {
    let resDate: Date | undefined;

    if (type === 'year') {
      resDate = DateHelper.calcYear(_date, num, mode);
    } else if (type === 'month') {
      resDate = DateHelper.calcMonth(_date, num, mode);
    } else {
      resDate = DateHelper.calcDay(_date, num, mode);
    }

    return DateFormatter.date2str(resDate);
  }
}
