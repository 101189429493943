import { useCallback, useMemo, useState } from 'react';
import './change-password-dialog.sp.scss';
import { useDispatch } from 'react-redux';
import { TopLabelInputField } from '../../../../ui/input-field/top-label-input-field/top-label-input-field';
import { Button } from '../../../../ui/button/button';
import { useAppSelector } from '../../../../../hooks/use-redux';
import { MasterActions } from '../../../../../redux/master/master.action';
import { ValidationPassword } from '../../../../../model/validation/validation-password';
import { ValidationPasswordShort } from '../../../../../model/validation/validation-password-short';
import { PassWordSendValidation } from '../../../../../model/validation/password/password-send.validation';
import { DialogActions } from '../../../../../redux/dialog/dialog.action';
import { Message } from '../../../../../collection/message.collection';

export const ChangePasswordDialogSP = () => {
  const dispatch = useDispatch();

  const user = useAppSelector((state) => state.user);

  const [password, setPassword] = useState('');
  const [rePassword, setRePassword] = useState('');

  const disabled = useMemo(() => !(
    password
    && rePassword
    && password === rePassword
  ), [password, rePassword]);

  const handleClickChange = useCallback(() => {
    if (PassWordSendValidation(
      password,
      rePassword,
    )) {
      dispatch(DialogActions.pushMessage({
        title: 'パスワード変更',
        message: Message.postError,
      }));
      return;
    }

    dispatch(MasterActions.api.employee.post({
      param: {
        data: {
          new_password: password,
          confirm_password: rePassword,
          store_id: user.store_id,
          name: user.name,
          short_name: user.short_name,
          furigana: user.furigana,
          job_title: user.job_title,
          mail_address: user.mail_address,
          sales_target: user.sales_target,
          is_valid: 1,
          authority1: user.authority1,
          authority4: user.authority4,
          access_11: user.access_11,
          company_id: user.company_id,
        },
        id: user.id,
      },
    }));
  },
  [dispatch, password, rePassword, user]);

  return (
    <>
      <div className="base_dialog_content_inner_body change_password_dialog_sp">
        <TopLabelInputField
          label="新しいパスワード"
          value={password}
          type="password"
          errorPosBottom
          validationList={ValidationPasswordShort}
          onChange={(e) => setPassword(e.target.value)}
        />
        <TopLabelInputField
          label="パスワード再入力"
          value={rePassword}
          validationList={ValidationPasswordShort}
          type="password"
          onChange={(e) => setRePassword(e.target.value)}
        />
        <div>
          半角の数字、英字(小)、英字(大)の８文字以上で入力してください。
        </div>
      </div>
      <div className="base_dialog_content_inner_footer">
        <Button
          disabled={disabled}
          onClick={handleClickChange}
          color="primary"
          size="md"
        >変更
        </Button>
      </div>
    </>
  );
};
