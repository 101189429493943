import { isEqual } from 'lodash';
import {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DialogActions } from '../../../../redux/dialog/dialog.action';
import { State } from '../../../../redux/root.reducer';
import { SupportHistoryActions } from '../../../../redux/support-history/support-history.action';
import { SetSelectedClass } from '../../../../utilities/set-selected-class';
import { BottomBorderButton } from '../../../ui/button/bottom-border/bottom-border-button';
import { LeftIconButton } from '../../../ui/button/left-icon-button/left-icon-button';
import { SupportHistoryListSP } from '../../layout/body/list/support-history/support-history-list';
import { SearchBoxDialogTitle } from '../../layout/search-box/search-box.type.sp';
import { BasePageSP } from '../base.page.sp';
import { SupportHistoryEditSP } from './edit/support-history-edit.sp';
import { SupportHistoryEditDialogTitle } from './edit/support-history-edit.type';
import { SearchBoxSupportHistorySP } from './serch-box/support-history-search-box.sp';
import './support-history.sp.scss';
import { SupportHistoryModel } from '../../../../model/support-history/support-history.model';
import { useWillUnMount, useDidMount } from '../../../../hooks/life-cycle';
import { useAppSelector } from '../../../../hooks/use-redux';
import { SupportHistorySortState } from '../../../../type/support-history/support-history.type';
import { TableSort } from '../../../ui/table/table-sort/table-sort';

export const SupportHistorySP = () => {
  const dispatch = useDispatch();
  const [supportHistoryShowType, setSupportHistoryShowType] = useState<0 | 1 | 2>(0);
  const user = useAppSelector((v) => v.user);
  const sortState = useSelector((state: State) => (state.supportHistory.sort), isEqual);
  const [hitCount, setHitCount] = useState(0);

  const buttonsParentEle = useRef<HTMLDivElement>(null);
  const footerEle = useRef<HTMLElement>(null);

  /* 一覧取得 */
  const getList = useCallback((data?: SupportHistorySortState) => {
    dispatch(SupportHistoryActions.setList(null));
    dispatch(SupportHistoryActions.api.supportHistory.getList({
      param: SupportHistoryModel.listParam({ sortState: data || sortState }),
      onSuccess: (v, hit) => {
        dispatch(SupportHistoryActions.setList(v));
        setHitCount(hit || 0);
      },
    }));
  }, [sortState]);

  /* 新規登録 */
  const add = useCallback(() => {
    dispatch(DialogActions.push({
      title: SupportHistoryEditDialogTitle.add,
      element: <SupportHistoryEditSP
        callback={() => {
          getList();
        }}
      />,
    }));
  }, []);

  /* 一覧取得 */
  useEffect(() => {
    getList();
  }, [
    supportHistoryShowType,
    sortState.limit,
  ]);

  // useWillUnMount(() => dispatch(SupportHistoryActions.setInitSort(user)));

  return (
    <BasePageSP
      className="support_history_sp"
      searchBoxDialog={{
        title: SearchBoxDialogTitle,
        element: <SearchBoxSupportHistorySP callback={(v) => {
          dispatch(DialogActions.pop());
          dispatch(SupportHistoryActions.setSort({ offset: 0 }));
          getList({ ...v, offset: 0 });
        }}
        />,
      }}
      searchValue={sortState.keyword}
      searchCallback={(v) => {
        dispatch(SupportHistoryActions.setSort({ keyword: v, offset: 0 }));
        getList({ ...sortState, keyword: v, offset: 0 });
      }}
    >
      <div className="map_list_header support_history_sp__header">
        <span>対応履歴一覧</span>
        <div className="support_history_sp__header__buttons" ref={buttonsParentEle}>
          <BottomBorderButton
            label="すべて"
            onClick={(e) => {
              dispatch(SupportHistoryActions.setSort({ support_flag: 2 }));
              setSupportHistoryShowType(2);
              SetSelectedClass(e.currentTarget, buttonsParentEle.current);
            }}
            selected={sortState.support_flag === 2}
          />
          <BottomBorderButton
            label="未対応"
            onClick={(e) => {
              dispatch(SupportHistoryActions.setSort({ support_flag: 0 }));
              setSupportHistoryShowType(0);
              SetSelectedClass(e.currentTarget, buttonsParentEle.current);
            }}
            selected={sortState.support_flag === 0}
          />
          <BottomBorderButton
            label="対応済"
            onClick={(e) => {
              dispatch(SupportHistoryActions.setSort({ support_flag: 1 }));
              setSupportHistoryShowType(1);
              SetSelectedClass(e.currentTarget, buttonsParentEle.current);
            }}
            selected={sortState.support_flag === 1}
          />
        </div>
      </div>
      <TableSort
        isSp
        page={sortState.offset ?? 0}
        limit={100}
        hitCount={hitCount || 0}
        callback={(v) => {
          dispatch(SupportHistoryActions.setSort({ offset: v }));
          getList({ ...sortState, offset: v });
        }}
      />
      {/* <SupportHistoryListSP  /> */}
      <div className="map_list_body">
        <SupportHistoryListSP />
      </div>
      <footer
        className="page_body_footer support_history_sp__footer"
        ref={footerEle}
      >
        <LeftIconButton
          label="対応履歴新規登録"
          fontAwesomeClass="far fa-edit"
          onClick={add}
          size="md"
          color="primary"
        />
      </footer>
    </BasePageSP>
  );
};
