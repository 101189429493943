import { useDispatch, useSelector } from 'react-redux';
import { cloneDeep, isEqual, isEmpty } from 'lodash';
import { useCallback, useState } from 'react';
import { Input as SInput } from 'semantic-ui-react';
import { Button } from '../../../../../../ui/button/button';
import { DialogActions } from '../../../../../../../redux/dialog/dialog.action';
import { DatePicker } from '../../../../../../ui/date-picker/date-picker';
import { FileSortState } from '../../../../../../../type/file/file.type';
import { State } from '../../../../../../../redux/root.reducer';
import { CustomerDetailActions } from '../../../../../../../redux/customer-detail/customer-detail.action';
import { useAppSelector } from '../../../../../../../hooks/use-redux';
import { Input } from '../../../../../../ui/input/input';
import { Select } from '../../../../../../ui/select/select';
import { pulldown } from '../../../../../../../utilities/pulldown';
import { useDidMount } from '../../../../../../../hooks/life-cycle';
import { MasterActions } from '../../../../../../../redux/master/master.action';
import { RightLabelInputField } from '../../../../../../ui/input-field/right-label-input-field/right-label-input-field';
import { useDateValidation } from '../../../../../../../hooks/use-date-validation';
import { DateTermValidation } from '../../../../../../../model/validation/date/term-date.validation';

type Props = {
  formatList: string[];
  callback: (sort: FileSortState) => void;
}

export const RefindFileTable = (props: Props) => {
  const { callback, formatList } = props;

  /* Hook */
  const dispatch = useDispatch();
  const sort = useAppSelector((v) => v.customerDetail.fileSort);
  const employeeList = useAppSelector((v) => v.master.employeeList);

  const [sortState, setSortState] = useState(cloneDeep(sort));

  const termDate = useDateValidation([{
    start: sortState.upload_date_start, end: sortState.upload_date_end,
  }]);

  /* Callback */
  const handleClickSearch = useCallback(() => {
    if (termDate.messages.length) {
      termDate.check();
      return;
    }
    dispatch(DialogActions.pop());
    dispatch(CustomerDetailActions.setFileSort(cloneDeep(sortState)));
    callback(sortState);
  }, [callback, sortState, termDate]);

  const setState = useCallback((v: FileSortState) => {
    setSortState(cloneDeep(v));
  }, [dispatch]);

  useDidMount(() => {
    dispatch(MasterActions.api.employee.getList({}));
  });

  return (
    <div className="refind_wrap" onKeyPress={(e) => { if (e.key === 'Enter') handleClickSearch(); }}>
      <div className="refind_body">
        <div className="item_wrap">
          <div className="item_box">
            <div className="item_head">No</div>
            <SInput
              className="align_right"
              value={sortState.internal_id}
              onChange={(e) => {
                setState({
                  ...sortState,
                  internal_id: e.target.value,
                });
              }}
              type="number"
              inputMode="numeric"
            />
          </div>
          <div className="item_box">
            <div className="item_head">案件名</div>
            <Input
              className=""
              value={sortState.project_name}
              onChange={(e) => setState({ ...sortState, project_name: e.target.value })}
            />
          </div>
        </div>
        <div className="item_wrap">
          <div className="item_box">
            <div className="item_head">ファイル名</div>
            <Input
              className=""
              value={sortState.file_name}
              onChange={(e) => setState(
                { ...sortState, file_name: e.target.value },
              )}
            />
          </div>
          <div className="item_box">
            <div className="item_head">形式</div>
            <Select
              value={sortState.format}
              onChange={(v) => setState({ ...sortState, format: String(v) })}
              defaultLabel="全て"
              options={formatList.map((v) => ({ text: v, value: v }))}
            />
          </div>
        </div>
        <div className="item_wrap">
          <div className="item_box">
            <div className="item_head">アップロード日</div>
            <DatePicker
              date={sortState.upload_date_start}
              onChange={(v) => setState({ ...sortState, upload_date_start: v })}
              term={DateTermValidation([{
                start: sortState.upload_date_start, end: sortState.upload_date_end,
              }])}
            />
            <label className="ml_10">〜</label>
            <DatePicker
              date={sortState.upload_date_end}
              onChange={(v) => setState({ ...sortState, upload_date_end: v })}
              term={DateTermValidation([{
                start: sortState.upload_date_start, end: sortState.upload_date_end,
              }])}

            />
          </div>
        </div>
        <div className="item_wrap">
          <div className="item_box">
            <div className="item_head">サイズ</div>
            <RightLabelInputField
              className="small text_right"
              type="number"
              label="MB"
              value={sortState.min_size}
              onChange={(e) => setState(
                { ...sortState, min_size: e.target.value },
              )}
            />
            <label className="ml_10">〜</label>
            <RightLabelInputField
              className="small text_right"
              type="number"
              label="MB"
              value={sortState.max_size}
              onChange={(e) => setState(
                { ...sortState, max_size: e.target.value },
              )}
            />
          </div>
        </div>
        <div className="item_wrap">
          <div className="item_box">
            <div className="item_head">更新者</div>
            <Select
              value={sortState.updater}
              onChange={(v) => setState({ ...sortState, updater: Number(v) })}
              defaultLabel="全て"
              options={pulldown(employeeList)}
            />
          </div>
          <div className="item_box">
            <div className="item_head">コメント</div>
            <Input
              className=""
              value={sortState.comment}
              onChange={(e) => setState({
                ...sortState, comment: e.target.value,
              })}
            />
          </div>
        </div>
      </div>
      <footer className="base_footer refind_footer">
        <Button
          size="md"
          color="primary"
          onClick={handleClickSearch}
        >絞込み
        </Button>
        <Button
          size="md"
          color="dark"
          onClick={() => dispatch(DialogActions.pop())}
        >キャンセル
        </Button>
      </footer>
    </div>
  );
};
