import { ValidationLengthUnder500 } from '../validation-length-under';

type Param = {
  fieldName: string,
  projectName: string,
  price: number,
  payment: number,
  priceTaxIn: number,
  remarks: string,
  billing_date: string,
  payment_due_date: string,
  billing_items_id: number,
  payment_method: string
}

/* TODO 請求バリデーション */
export const BillValidation = (data: Param) => {
  const {
    fieldName,
    projectName,
    price,
    payment,
    priceTaxIn,
    remarks,
    billing_date,
    payment_due_date,
    billing_items_id,
    payment_method,
  } = data;

  window.console.log();

  return (
  // 必須
    !billing_items_id
    || !billing_date
    || !payment_due_date

    // バリデーション
    || ValidationLengthUnder500.filter((v) => !v.run(String(data.payment_method || ''))).length
    || ValidationLengthUnder500.filter((v) => !v.run(String(data.remarks || ''))).length
  );
};
