import './maintenance.pc.scss';
import { useDispatch } from 'react-redux';
import { goBack, replace } from 'connected-react-router';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { BasePagePC } from '../base.page.pc';
import { DialogActions } from '../../../../redux/dialog/dialog.action';
import { RoutingPath } from '../../../../routes/routing-pass';
import { MaintenanceEditPC } from './edit/maintenance-edit.pc';
import { MaintenanceSearchBoxPC } from '../../layout/search-box/maintenance/maintenance-search-box.pc';
import { MaintenanceListPC } from '../../layout/body/list/maintenance-list/maintenance-list.pc';
import { LeftIconButton } from '../../../ui/button/left-icon-button/left-icon-button';
import { useWillUnMount } from '../../../../hooks/life-cycle';
import { MaintenanceActions } from '../../../../redux/maintenance/maintenance.action';
import { useQuery } from '../../../../hooks/use-query';
import { MaintenanceMapListPC } from '../../layout/body/map/map-list/maintenance/maintenance-map-list';
import { MaintenanceSortState, MaintenanceList } from '../../../../type/maintenance/maintenance.type';
import { MapActions } from '../../../../redux/map/map.action';
import { useAppSelector } from '../../../../hooks/use-redux';
import { MaintenanceModel } from '../../../../model/maintenance/maintenance.model';
import { Limit, TableSort } from '../../../ui/table/table-sort/table-sort';
import { Message } from '../../../../collection/message.collection';
import { CsvActions } from '../../../../redux/csv/csv.action';
import { ProjectSortState } from '../../../../type/project/project.type';
import { MapAreaPosition } from '../../../../type/map/map.type';
import { MapCollection } from '../../../../collection/map/map.collection';
import { Store } from '../../../../redux/store';

export const MaintenancePC = () => {
  const { id } = useParams<{id:string}>();
  /* Hooks */
  const shoType = useQuery('type');
  const dispatch = useDispatch();

  const sortState = useAppSelector((v) => v.maintenance.sort);
  const list = useAppSelector((v) => v.maintenance.list);
  const listHitCount = useAppSelector((v) => (v.project.listHitCount));
  const areaBtnDisabled = useAppSelector((v) => (v.map.areaBtnDisabled));
  // const humanPos = useAppSelector((v) => (v.map.humanPos));
  // const mapAreaPos = useAppSelector((v) => (v.map.mapAreaPos));
  const gpsStatus = useAppSelector((v) => (v.map.gpsStatus));
  const user = useAppSelector((v) => v.user);
  // const zoom = useAppSelector((v) => v.map.zoomLevel);
  const dialogLen = useAppSelector((v) => (v.dialog.dialogs.length));
  const map = useAppSelector((v) => v.map.map);
  const flickLoad = useAppSelector((v) => v.map.flickLoad);
  const [init, setInit] = useState<boolean>(true);

  /* State */
  const [maintenanceShowType, setMaintenanceShowType] = useState<0 | 1 | 2>(0);
  const [showType, setShowType] = useState<'map' | 'list'>('map');
  const [selectId, setSelectId] = useState<number | undefined>(undefined);
  const [hitCount, setHitCount] = useState(0);

  /* MapList用変数 */
  const [mapHitCount, setMapHitCount] = useState(0);
  const [page, setPage] = useState(0);
  const [mapList, setMapList] = useState<MaintenanceList[] | null>([]);
  const [isSearch, setIsSearch] = useState(true);
  const [supportedKubun, setSupportedKubun] = useState(0);

  /* エリア検索 */
  const [zoomSearch, setZoomSearch] = useState(false);
  /* カードクリック検索 */
  const [cardSearch, setCardSearch] = useState<boolean>(false);

  const [select, setSelect] = useState<number[]>([]);
  const [listList, setListList] = useState<MaintenanceList[] | null | undefined>(null);

  /* Callback */
  /* 新規作成 */

  /* カードリストの一覧取得のみ */
  const getListCardList = useCallback((param: {
    data?: MaintenanceSortState;
    offset?: number;
  }) => {
    const { data, offset } = param;
    setPage(offset || 0);
    setMapList(null);
    dispatch(MaintenanceActions.api.maintenance.getCallbackList({
      param: {
        ...MaintenanceModel.listParm({
          sortData: data || sortState,
          showType,
          searchBox: true,
          isMapList: true,
        }),
        limit: 100,
        offset: offset || sortState.offset,
      },
      onSuccess: (v, hit) => {
        if (!v) return;
        setMapList(v);
        setMapHitCount(hit);
      },
    }));
  }, [sortState]);

  /* マップ一覧取得 */
  const getListMapList = useCallback((param: {
    data?: MaintenanceSortState;
    mapPos?: MapAreaPosition;
    searchBox?: boolean;
  }) => {
    const { data, mapPos, searchBox } = param;
    const isMap = showType === 'map';
    dispatch(MaintenanceActions.api.maintenance.getList({
      isLoad: isMap,
      isMapLoad: true,
      param: MaintenanceModel.listParm({
        showType,
        sortData: data || sortState,
        mapAreaPos: mapPos || Store.getState().map.mapAreaPos,
      }),
      callback: (v, hit) => {
        if (!v) return;
        // if (flickLoad) setMapList(v);
        dispatch(MapActions.setAreaBtnDisabled(true));
        dispatch(MaintenanceActions.setList(v));
        setHitCount(hit);
        if (v.length || showType !== 'map' || flickLoad) return;
        dispatch(DialogActions.pushMessage({
          title: '検索',
          message: ['領域内に該当データがありません'],
        }));
      },
    }));
  }, [showType, flickLoad, sortState]);

  const add = useCallback(() => {
    dispatch(DialogActions.push({
      title: 'メンテナンス情報入力',
      className: 'maintenance',
      element: <MaintenanceEditPC callback={() => {
        if (!flickLoad) {
          getListCardList({});
        }
        getListMapList({});
      }}
      />,
    }));
  }, [dispatch, getListCardList, getListMapList, flickLoad]);

  /* 検索後のカードクリックアフターアクション */
  const centerChanged = useCallback((pos: MapAreaPosition) => {
    if (!cardSearch) return;
    setCardSearch(false);
    getListMapList({ mapPos: pos });
    // getList(undefined, false, undefined, pos);
  }, [cardSearch, getListMapList]);

  /* マップ⇔リスト */
  const changeMap = useCallback(() => {
    const path = `${RoutingPath.maintenance}?type=${showType === 'map' ? 'list' : 'map'}`;
    dispatch(replace(path));
    setShowType(showType === 'map' ? 'list' : 'map');
  }, [showType]);

  /* カードクリック挙動 */
  const hClickCard = useCallback(() => {
    if (flickLoad) return;
    if (
      Store.getState().map.zoomLevel >= MapCollection.searchZoomLevel
      && Store.getState().map.mapAreaPos
    ) {
      setCardSearch(true);
    } else {
      setZoomSearch(true);
    }
  }, [flickLoad]);

  /* リストページャーソート */
  const pagerSort = useCallback((offset: number, limit: Limit) => {
    dispatch(MaintenanceActions.setList(null));
    dispatch(MaintenanceActions.setSort({ offset, limit }));
    getListMapList({
      data: { ...sortState, offset, limit },
    });
    // getList({ ...sortState, offset, limit });
  }, [sortState, getListMapList]);

  /* TableHeaderのソート */
  const headerSort = useCallback((highlow: number, sort_by: number) => {
    dispatch(MaintenanceActions.setSort({ highlow, sort_by }));
  }, []);

  /* ズーム終了時アクション */
  const zoomEnd = useCallback((pos: MapAreaPosition) => {
    if (!zoomSearch) {
      dispatch(MapActions.setAreaBtnDisabled(false));
      return;
    }
    setZoomSearch(false);
    // if (!flickLoad) { getListCardList({}); }
    getListMapList({ mapPos: pos });
    // getList(undefined, false, undefined, pos);
  }, [zoomSearch, getListCardList, getListMapList, flickLoad]);

  /* エリア検索ボタン押下 */
  const hClickSearchArea = useCallback((isInit?: boolean, v?: MaintenanceSortState) => {
    // setIsSearch(true);
    if (!isInit) {
      dispatch(MapActions.setAreaBtnDisabled(true));
    }
    if (flickLoad) setMapList(null);
    if (
      Store.getState().map.zoomLevel >= MapCollection.searchZoomLevel
      && Store.getState().map.mapAreaPos
    ) {
      getListMapList({ data: v });
    } else {
      setZoomSearch(true);
      dispatch(MapActions.setZoomLevel(MapCollection.searchZoomLevel));
    }
  }, [getListMapList, flickLoad]);

  /* 検索ボタン押下処理 */
  const hClickSearchBtn = useCallback((v: MaintenanceSortState, isInit?: boolean) => {
    if (showType !== 'map') { dispatch(MaintenanceActions.setList(null)); }
    setSelect([]);
    // setZoomSearch(true);
    setIsSearch(true);
    setMapList(null);
    dispatch(MapActions.setFlickLoad(false));
    dispatch(MaintenanceActions.setSort({ offset: 0 }));
    if (showType === 'map') {
      hClickSearchArea(isInit, v);
    } else {
      getListMapList({ data: { ...v } });
    }
    getListCardList({ data: { ...v, offset: 0 }, offset: 0 });
  }, [shoType, getListCardList, getListMapList, showType, hClickSearchArea]);

  /* MapList内部ページネーション */
  const hClickPagination = useCallback((offset: number) => {
    setMapList(null);
    getListCardList({ offset });
    // getList(undefined, undefined, offset);
  }, [getListMapList]);

  /* リスト・マップ変更ボタン押下 */
  const hClickShowType = useCallback(() => {
    dispatch(MapActions.setAreaBtnDisabled(false));
    if (!cardSearch && !zoomSearch) {
      setMapList(showType === 'map' ? null : []);
      setInit(true);
      dispatch(MaintenanceActions.setList(null));
    }
    if (!flickLoad && Store.getState().map.mapAreaPos) {
      getListCardList({});
    }
    changeMap();
  }, [changeMap, flickLoad, hClickSearchArea, showType, zoomSearch]);

  const initMapSearch = useCallback((onGps: boolean) => {
    const promise = () => new Promise<void>((resolve, reject) => {
      if (onGps) {
        if (!Store.getState().map.mapAreaPos || !Store.getState().map.humanPos) {
          reject();
        } else {
          resolve();
        }
      } else if (!Store.getState().map.mapAreaPos) {
        reject();
      } else {
        resolve();
      }
    });
    const run = () => {
      setInit(false);
      if (onGps) {
        hClickSearchBtn(sortState, init);
        dispatch(MapActions.setFlickLoad(false));
      } else {
        dispatch(MapActions.setFlickLoad(false));
        hClickSearchBtn(sortState, init);
      }
    };
    promise()
      .then(() => {
        run();
      })
      .catch(() => {
        window.setTimeout(() => {
          initMapSearch(onGps);
        }, 200);
      });
  }, [sortState, init]);

  /* SP詳細からのURLコピー */
  useEffect(() => {
    if (!selectId) return;
    dispatch(DialogActions.push({
      title: 'メンテナンス情報入力',
      className: 'maintenance',
      element: <MaintenanceEditPC
        id={Number(selectId)}
        callback={() => {
          if (!flickLoad) {
            getListCardList({});
          }
          getListMapList({});
        }}
      />,
      onCloseClick: () => {
        dispatch(replace(`${RoutingPath.maintenance}?type=list`));
      },
    }));
  }, [selectId, showType, getListCardList, flickLoad, getListMapList, user]);

  useEffect(() => {
    setSelectId(id ? Number(id) : undefined);
  }, [id]);

  useEffect(() => {
    if (id) {
      dispatch(replace(`${RoutingPath.maintenanceDetail}/${id}?type=list`));
      setShowType('list');
      return;
    }
    const mapType = (shoType || 'list');
    const path = `${RoutingPath.maintenance}?type=`;
    dispatch(replace(path + mapType));
    setShowType(mapType === 'map' ? 'map' : 'list');
  }, [shoType, id]);

  useEffect(() => {
    if (dialogLen || (showType === 'map')) return;
    getListMapList({});
  }, [
    sortState.highlow,
    sortState.sort_by,
    showType,
  ]);

  // useEffect(() => {
  //   if (init && humanPos && mapAreaPos) {
  //     dispatch(MapActions.setFlickLoad(false));
  //     hClickSearchBtn(sortState, init);
  //     setInit(false);
  //   } else if (init && mapAreaPos) {
  //     dispatch(MapActions.setFlickLoad(false));
  //     hClickSearchBtn(sortState, init);
  //     setInit(false);
  //   }
  // }, [humanPos, mapAreaPos]);

  useWillUnMount(() => {
    dispatch(MaintenanceActions.setList(null));
    dispatch(MapActions.setFlickLoad(true));
  });

  return (
    <BasePagePC className="MaintenancePC">
      <div id="maintenance" className="cnt_area">
        <div className="inner">
          <MaintenanceSearchBoxPC
            callback={hClickSearchBtn}
            supportedKubun={supportedKubun}
          />
          {!areaBtnDisabled && showType === 'map'
          && (
          <LeftIconButton
            label="このエリアを検索"
            size="md"
            fontAwesomeClass="fas fa-search"
            className="for_area"
            color="secondary"
            onClick={() => hClickSearchArea()}
            disabled={areaBtnDisabled}
          />
          )}
          {showType === 'map'
            ? (
              <MaintenanceMapListPC
                list={mapList}
                page={page}
                isSearch={isSearch}
                hitCount={mapHitCount}
                type={maintenanceShowType}
                callback={(type) => setMaintenanceShowType(type)}
                callbackGetList={() => {
                  if (!flickLoad) {
                    getListCardList({});
                  }
                  getListMapList({});
                }}
                onClickCard={hClickCard}
                callbackType={(v) => {
                  setSupportedKubun(v);
                  dispatch(MaintenanceActions.setSort({
                    ...sortState,
                    supported_kubun: v,
                  }));
                  hClickSearchBtn({
                    ...sortState,
                    supported_kubun: v,
                  });
                }}
                callbackPagination={hClickPagination}
                callbackZoomEnd={zoomEnd}
                callbackCenterChanged={centerChanged}
                onGetGps={initMapSearch}
              />
            )
            : (
              <>
                <TableSort
                  page={sortState.offset ?? 0}
                  limit={sortState.limit as Limit}
                  hitCount={hitCount}
                  callback={pagerSort}
                />
                <MaintenanceListPC
                  selectId={selectId}
                  getList={() => {
                    if (!flickLoad) {
                      getListCardList({});
                    }
                    getListMapList({});
                  }}
                  parentSelect={select}
                  callbackList={setListList}
                  callbackSelect={setSelect}
                />
              </>
            )}
        </div>
      </div>
      <footer className="btn_area">
        <div className="left_box">
          <LeftIconButton
            label="新規登録"
            size="md"
            fontAwesomeClass="fas fa-edit"
            className="btn_search for_detail"
            color="primary"
            onClick={add}
          />
          <LeftIconButton
            label="CSV出力"
            size="md"
            fontAwesomeClass="fas fa-file-csv"
            className="btn_search for_detail"
            color="primary"
            onClick={() => {
              const isMap = shoType === 'map';
              if (!isMap) {
                if (!listList || !listList.length) return;
                const csvParam = select.length
                  ? {
                    ids: select.map((v) => listList[v].id),
                  } : {
                    ...MaintenanceModel.listParm({ sortData: sortState }),
                  };
                if (!csvParam) return;
                Object.assign(csvParam, {
                  highlow: sortState.highlow,
                });
                dispatch(CsvActions.api.maintenance.download(csvParam));
              } else {
                if (!mapList || !mapList.length) return;
                const csvParam = {
                  ids: mapList.map((v) => v.id),
                };
                dispatch(CsvActions.api.maintenance.download(csvParam));
              }
            }}
          />
        </div>
        <div className="right_box">
          <LeftIconButton
            fontAwesomeClass={`${showType === 'map' ? 'fas fa-list' : 'fas fa-map'}`}
            label={`${showType === 'map' ? 'リスト表示' : '地図表示'}`}
            size="md"
            className="btn_search for_detail"
            color="primary"
            onClick={hClickShowType}
          />
          <LeftIconButton
            label="戻る"
            fontAwesomeClass="fas fa-arrow-left"
            size="md"
            color="dark"
            onClick={() => dispatch(goBack())}
          />
        </div>
      </footer>
    </BasePagePC>
  );
};
