import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as lodash from 'lodash';
import { cloneDeep, isEqual } from 'lodash';
import { ProjectListType, ProjectSortState } from '../../../../../../type/project/project.type';
import { Button } from '../../../../../ui/button/button';
import { DialogActions } from '../../../../../../redux/dialog/dialog.action';
import { ProjectSearchBox } from '../project-search-box';
import { useDidMount, useWillUnMount } from '../../../../../../hooks/life-cycle';
import { ProjectActions } from '../../../../../../redux/project/project.action';
import { State } from '../../../../../../redux/root.reducer';
import { Table } from '../../../../../ui/table/table';
import { ProjectCollection } from '../../../../../../collection/project/project.collection';
import Ordered from '../../../../../../asset/images/icon/ordered.svg';
import Alert from '../../../../../../asset/images/icon/alert.svg';
import { Limit, TableSort } from '../../../../../ui/table/table-sort/table-sort';
import { DateFormatter } from '../../../../../../utilities/date-formatter';
import { Customer, CustomerListType } from '../../../../../../type/customer/customer.type';
import { ProjectModel } from '../../../../../../model/project/project.model';
import { useAppSelector } from '../../../../../../hooks/use-redux';
import { MasterActions } from '../../../../../../redux/master/master.action';
import { SupportHistoryEditState } from '../../../../../../type/support-history/support-history.type';
import { ProjectState } from '../../../../../../redux/project/project.reducer';
import { useOpenWindow } from '../../../../../../hooks/use-open-window';
import { RoutingPath } from '../../../../../../routes/routing-pass';

type Props = {
  customerData?: Customer,
  callback: (v:ProjectListType) => void;
  onClose?: () => void;
  type?: 'estimate' | 'order' | 'maintenance' | 'support' | 'file';
  supportData?: SupportHistoryEditState;
  freeAuth?: boolean;
}

export const ProjectSearch = (props: Props) => {
  const {
    type, customerData, callback, onClose, supportData, freeAuth,
  } = props;

  const customerRankList = useAppSelector((v) => v.master.customerRankList);
  const projectRankList = useAppSelector((v) => v.master.projectRankList);
  const user = useAppSelector((v) => v.user);
  const windowOpen = useOpenWindow();

  /* Hooks */
  const dispatch = useDispatch();
  const authority1 = useSelector((state: State) => (state.user.authority1), isEqual);

  /* State */
  const [list, setList] = useState<ProjectListType[] | null>(null);
  const [count, setCount] = useState<number>(0);
  const [project, setProject] = useState<ProjectListType | null>(null);
  const [selected, setSelected] = useState<number[]>([]);
  const [searchIsOpen, setSearchIsOpen] = useState<boolean>(false);
  const [isFirst, setIsFirst] = useState(true);
  const [sort, setSort] = useState(ProjectCollection._initialSortState(user));
  const [isInit, setIsInit] = useState(true);

  /* Callback */
  const getList = useCallback((v?: ProjectSortState, init?: boolean) => {
    const initId = isFirst ? supportData?.customer_id : customerData?.id;
    const sortData = v || sort;
    const setEmployeeId = (): number | undefined => {
      if (
        user.view_data
        && user.view_data.company_id !== 1
        && sortData.project_employee_id === user.id
      ) {
        return NaN;
      }
      return sortData.project_employee_id;
    };
    const setStoreId = (): number | undefined => {
      if (
        user.view_data
        && user.view_data.company_id !== 1
        && sortData.project_store_id === user.store_id
      ) {
        return NaN;
      }
      return sortData.project_store_id;
    };
    dispatch(ProjectActions.api.project.getList({
      param: {
        ...ProjectModel.listParam({
          sortState: v || sort,
          customerID: supportData ? initId : customerData?.id,
          type,
          isInit: init,
        }),
        project_employee_id: setEmployeeId(),
        project_store_id: setStoreId(),
      },
      onSuccess: (data, hit) => {
        if (!data) return;
        const _list = data.data;
        setList(_list);
        setCount(data.count);
        setIsFirst(false);
      },
    }));
  }, [sort, customerData, type, supportData, isFirst, freeAuth, user]);

  const handleClickSelect = useCallback(() => {
    dispatch(DialogActions.pop());
    if (project) { callback(lodash.cloneDeep(project)); }
  },
  [project, callback]);

  const handleClickRow = useCallback((v:ProjectListType) => {
    if (!list) return;
    setProject(cloneDeep(v));
    setSelected([list.findIndex((v2) => v2.id === v.id)]);
  }, [list]);

  const handleDbClickRow = useCallback((
    v: ProjectListType,
    e?: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
  ) => {
    if (!e) return;
    setProject(cloneDeep(v));
    windowOpen({
      url: `${RoutingPath.projectDetail}/${v.id}`,
      e,
      callback: () => {
        dispatch(DialogActions.pop());
        callback(cloneDeep(v));
      },
    });
  }, [callback]);

  const handleClickHeader = useCallback((highlow, sort_by) => {
    setSort({
      ...sort,
      highlow,
      sort_by,
    });
  }, [sort]);

  const handleChangePagination = useCallback((offset: number, limit: Limit) => {
    // dispatch(ProjectActions.setSort({ offset, limit }));
    setList(null);
    setSort({ ...sort, offset, limit });
    getList({ ...sort, offset, limit });
  }, [sort]);

  useEffect(() => {
    getList(undefined, isInit);
    setIsInit(false);
  }, [sort.sort_by, sort.highlow]);

  useDidMount(() => {
    dispatch(ProjectActions.setInitSort(user));
  });

  useDidMount(() => {
    dispatch(MasterActions.api.customerRank.getList({}));
    dispatch(MasterActions.api.projectRank.getList({}));
  });

  return (
    <div className={`editPc_wrap ${searchIsOpen ? 'detail_on' : ''}`}>
      <div className="editPc_body show_all">
        <ProjectSearchBox
          type={type}
          customerData={customerData}
          isSeach
          initialSort={(type !== 'support')
            ? ProjectCollection._initialSortState(user)
            : {
              ...ProjectCollection._initialSortState(user),
              customer_id: supportData?.customer_id || NaN,
              customer_name: supportData?.customer_name || '',
            }}
          openCallback={setSearchIsOpen}
          callback={(v) => {
            setSort({
              ...cloneDeep(v),
              limit: sort.limit,
            });
            setList(null);
            getList({
              ...cloneDeep(v),
              limit: sort.limit,
            });
          }}
          noAuthority={freeAuth ? undefined : !authority1}
        />
        <TableSort
          page={sort.offset ?? 0}
          limit={sort.limit as Limit}
          hitCount={count}
          callback={handleChangePagination}
        />
        <section className="result_area list_area">
          <div className="inner">
            <div className="table_responsive">
              <Table
                className="table_selectable table_sortable table_sticky table_cell_change"
                header={ProjectCollection.header}
                selectedTr={selected}
                rowDataList={list || []}
                onClickRow={handleClickRow}
                onDbClick={(v, i, e) => handleDbClickRow(v, e)}
                sort={{ onClick: handleClickHeader }}
                onClickAlt={(v, e) => {
                  if (e && v) {
                    windowOpen({
                      url: `${RoutingPath.projectDetail}/${v.id}`,
                      e,
                      callback: () => {},
                    });
                  }
                }}
                lists={list ? list.map((v) => (
                  [
                    v.order_flag
                      ? <img src={Ordered} alt="受注された案件" title="受注された案件" className="icon" />
                      : '',
                    v.alert_flag
                      ? <img src={Alert} alt="案件作成から1週間以上経過" title="案件作成から1週間以上経過" className="icon" />
                      : '',
                    v.internal_id,
                    v.field_name,
                    (() => {
                      const rank = customerRankList.find((
                        v2,
                      ) => String(v2.id) === String(v.customer_rank));
                      return <div className="rank_label" style={{ backgroundColor: rank?.background_color, color: rank?.text_color }}>{`${rank?.abbreviation || ''}${rank?.abbreviation2 || ''}`}</div>;
                    })(),
                    (() => {
                      const rank = projectRankList.find((
                        v2,
                      ) => Number(v2.id) === Number(v.customer_estimated_rank));
                      return <div className="rank_label" style={{ backgroundColor: rank?.background_color, color: rank?.text_color }}>{rank?.abbreviation}</div>;
                    })(),
                    v.name,
                    v.field_tel_no,
                    v.field_place,
                    DateFormatter.date2str(v.construction_start_date),
                    DateFormatter.date2str(v.completion_end_date),
                    DateFormatter.date2str(v.construction_date),
                    DateFormatter.date2str(v.completion_date),
                    v.contract_no,
                    v.source_name,
                    v.remarks,
                    v.project_employee_name,
                    DateFormatter.date2str(v.contract_date),
                  ]
                )) : null}
                option={{
                  stringWidth: [
                    { index: 0, width: 50 }, // 受注された案件
                    { index: 1, width: 50 }, // 案件作成から1週間以上経過
                    // { index: 2, width: 50 }, // 案件ID
                    // { index: 3, width: 50 }, // 現場名称
                    // { index: 4, width: 50 }, // 顧客ランク
                    // { index: 5, width: 100 }, // 見込みランク
                    // { index: 6, width: 50 }, // 案件名
                    // { index: 7, width: 50 }, // 現場電話番号
                    // { index: 8, width: 50 }, // 現場住所
                    // { index: 9, width: 50 }, // 着工予定日
                    // { index: 10, width: 50 }, // 完工予定日
                    // { index: 11, width: 50 }, // 着工日
                    // { index: 12, width: 50 }, // 完工日
                    // { index: 13, width: 50 }, // 契約番号
                    // { index: 14, width: 50 }, // 発生源
                    // { index: 15, width: 50 }, // 備考
                    // { index: 16, width: 50 }, // 担当名
                    // { index: 17, width: 50 }, // 契約日
                  ],
                  tdAlign: [
                    { index: 0, align: 'center' },
                    { index: 1, align: 'center' },
                    { index: 2, align: 'center' },
                    { index: 3, align: 'left' },
                    { index: 4, align: 'center' },
                    { index: 5, align: 'center' },
                    { index: 6, align: 'left' },
                    { index: 7, align: 'left' },
                    { index: 8, align: 'left' },
                    { index: 9, align: 'center' },
                    { index: 10, align: 'center' },
                    { index: 11, align: 'center' },
                    { index: 12, align: 'center' },
                    { index: 13, align: 'center' },
                    { index: 14, align: 'left' },
                    { index: 15, align: 'left' },
                    { index: 16, align: 'left' },
                    { index: 17, align: 'left' },
                  ],
                }}
              />
            </div>
          </div>
        </section>
        <TableSort
          className="bottom"
          page={sort.offset ?? 0}
          limit={sort.limit as Limit}
          hitCount={count}
          callback={handleChangePagination}
        />
      </div>
      <div className="editPc_footer base_footer">
        <Button size="md" color="primary" disabled={!project} onClick={handleClickSelect}>
          選択
        </Button>
        <Button
          size="md"
          color="dark"
          onClick={() => {
            dispatch(DialogActions.pop());
            if (onClose) onClose();
          }}
        >
          閉じる
        </Button>
      </div>
    </div>
  );
};
