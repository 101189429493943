import { cloneDeep } from 'lodash';
import { BillEditState, BillSortState } from '../../type/bill/bill.type';

export class BillCollection {
  public static header = [
    '',
    '請求項目',
    '請求日',
    '入金予定日',
    '請求金額',
    '入金方法',
    '備考',
  ]

  public static itemTitles = [
    '契約金',
    '着工金',
    '中間金',
    '中間金',
    '完成金',
  ]

  public static itemList = [
    { text: '契約金', value: 1 },
    { text: '着工金', value: 2 },
    { text: '中間金', value: 3 },
    { text: '中間金', value: 4 },
    { text: '完成金', value: 5 },
  ]

  public static getInitialState():BillEditState {
    return (cloneDeep({
    }));
  }

  public static getPrintInitialState():BillEditState {
    return (cloneDeep({
    }));
  }

  public static editInitialState():BillEditState {
    return (cloneDeep({
    }));
  }

  public static sortInitialState(): BillSortState {
    return (cloneDeep({
    }));
  }
}
