import { ApiBase } from '../../../../service/api-base';

export type ApiLoginParam = {
  mail_address: string;
  password: string;
};

export type ApiLoginResponse = ApiLoginParam;

export class ApiLogin extends ApiBase<ApiLoginResponse> {
  constructor(param: ApiLoginParam) {
    // const csrfToken = getCookie('XSRF-TOKEN');
    super({

      // FIXME あとでomitに変える
      credentials: 'include',
      httpMethod: 'POST',
      url: '/api/auth/login',
      param,
    });
  }
}
