import { cloneDeep, isEqual } from 'lodash';
import { XmlParser } from '../../parser/xml-parser';
import { MathHelper as MH } from '../../utilities/math-helper';
import { deleteComma } from '../../utilities/delete-comma';
import { dummy } from './dummy';
import { MeisaiListXML, MeisaiXML, AddMeisaiData } from './estimate-meisai.type';
import { MasterMeisai } from '../../type/master/master-meisai.type';
import { EstimateCalcModelPC } from '../../collection/estimate/estimate-calc.model.pc';

type SortOption = {
  daibunrui: number;
  tyubunrui: number;
}
export class EstimateMeisaiModel {
  public list: MeisaiListXML[] = [];

  public sortPption: SortOption = {
    daibunrui: NaN,
    tyubunrui: NaN,
  }

  constructor(data?: MeisaiXML) {
    if (!data) return;
    this.list = cloneDeep(data.meisai.mei);
    this.format();
  }

  setDataXML() {

  }

  format(): Promise<void> {
    return new Promise((resolve) => {
      this.list.forEach((val, i) => {
        const v = val.$;
        const row = this.list[i].$;
        row.id = String(i + 1);
        row.row = String(i + 1);
        row.identity_id = String(1);
        row.kingaku = String(MH.rounding(deleteComma(v.kingaku), 0));
        row.genka_kei = String(MH.rounding(deleteComma(v.genka_kei), 0));
        row.filename = v.filename || '';
        row.server_filename = v.server_filename || '';

        const tempMitsu = Number(v.kingaku); // 見積計
        const tempGenka = Number(v.genka_kei); // 原価計
        const tempArari = Number(v.kingaku) - Number(v.genka_kei);

        if (!tempArari || Number.isNaN(MH.rounding((1 - (tempGenka / tempMitsu)) * 100))) {
          row.arari_ritu = '0';
        } else if (!Number.isFinite(
          Math.round((1 - (tempGenka / tempMitsu)) * 100),
        )) { // 値が有限大で無い場合 見積金が０の場合
          row.arari_ritu = '-';
        } else if (
          !Number.isNaN(Math.round((1 - (tempGenka / tempMitsu)) * 100))
        && Number.isFinite(Math.round((1 - (tempGenka / tempMitsu)) * 100))
        && (tempArari < 0)) {
          row.arari_ritu = String(Math.abs(Math.round((1 - (tempGenka / tempMitsu)) * 100)) * (-1));
        } else if (!Number.isNaN(Math.round((1 - (tempGenka / tempMitsu)) * 100))
        && Number.isFinite(Math.round((1 - (tempGenka / tempMitsu)) * 100))
        && (tempArari > 0)) {
          row.arari_ritu = String(Math.abs(Math.round((1 - (tempGenka / tempMitsu)) * 100)) || 0);
        // row.arari_ritu = '100';
        }
      // console.log(this.list);
      // wkKingaku += Number( this._nh.cancelFigureSeparate(meisaiList[i].@kingaku));
      });

      const searchArr:ReadonlyArray<keyof MeisaiListXML['$']> = [
        'daibunrui_name',
        'tyubunrui_name',
        'shohin_kubun_name',
        'name',
        'tani_name',
        'kikaku',
        'suryou',
        'genka',
        'shikiri_kakaku',
        'daibunrui_id',
        'tyubunrui_id',
        'shohin_kubun',
        'shohin_cd',
        'tani_id',
        'valid_flag',
        'filename',
        'server_filename',
        'genka_kei',
        'daibunrui_name_out',
        'tyubunrui_name_out',
        'print_name',
      ];

      for (let j = 0; j < this.list.length; j += 1) {
        let currentCount = 1;
        for (let k = j; k < this.list.length; k += 1) {
        // インデックスが同じ場合はチェック対象外
          if (j !== k) {
            let currentFlag = true;

            // ID毎に中身チェック
            for (let l = 0; l < searchArr.length; l += 1) {
              if ((this.list[j].$[searchArr[l]] || '').toString()
              !== (this.list[k].$[searchArr[l]] || '').toString()) {
                currentFlag = false;
                break;
              }
            }
            // jの明細とkの明細が同じだったら、IDふる。
            if (currentFlag) {
              currentCount += 1;
              this.list[j].$.identity_id = String(currentCount);
            }
          }
        }
      }
      resolve();
    });
  }

  calcArari(data:AddMeisaiData) {
    const temp = cloneDeep(data);
    const tempMitsu = Number(data.kingaku); // 見積計
    const tempGenka = Number(data.genka_kei); // 原価計
    const tempArari = Number(data.kingaku) - Number(data.genka);
    temp.arari_ritu = String(EstimateCalcModelPC.arariRitsuCal2(tempMitsu, tempGenka, true));
    return temp;

    // temp.daibunrui_name_out = this.list.find((
    //   v,
    // ) => v.$.daibunrui_id === temp.daibunrui_id)?.$.daibunrui_name_out || temp.daibunrui_name;

    // temp.tyubunrui_name_out = this.list.find((
    //   v,
    // ) => v.$.tyubunrui_id === temp.tyubunrui_id
    // && v.$.daibunrui_id === temp.daibunrui_id)?.$.tyubunrui_name_out || temp.tyubunrui_name;

    // if (!tempArari || Number.isNaN(MH.rounding((1 - (tempGenka / tempMitsu)) * 100))) {
    //   temp.arari_ritu = '0';
    // } else if (!Number.isFinite(
    //   Math.round((1 - (tempGenka / tempMitsu)) * 100),
    // )) { // 値が有限大で無い場合 見積金が０の場合
    //   temp.arari_ritu = '-';
    // } else if (
    //   !Number.isNaN(Math.round((1 - (tempGenka / tempMitsu)) * 100))
    //   && Number.isFinite(Math.round((1 - (tempGenka / tempMitsu)) * 100))
    //   && (tempArari < 0)) {
    //   temp.arari_ritu = String(Math.abs(Math.round((1 - (tempGenka / tempMitsu)) * 100)) * (-1));
    // } else if (!Number.isNaN(Math.round((1 - (tempGenka / tempMitsu)) * 100))
    //   && Number.isFinite(Math.round((1 - (tempGenka / tempMitsu)) * 100))
    //   && (tempArari > 0)) {
    //   temp.arari_ritu = String(Math.abs(Math.round((1 - (tempGenka / tempMitsu)) * 100)));
    // }
    // return temp;
  }

  addMaster(data: MeisaiListXML[]) {
    for (let i = 0; i < data.length; i += 1) {
      let v = cloneDeep({
        ...data[i],
        $: {
          ...data[i].$,
          kingaku: String(MH.rounding(Number(data[i].$.suryou)
            * Number(data[i].$.shikiri_kakaku), 0)),
          genka_kei: String(MH.rounding(Number(data[i].$.suryou) * Number(data[i].$.genka), 0)),
        },
      });
      v = cloneDeep({
        ...v,
        ...this.calcArari(v.$),
      });
      v = cloneDeep({
        ...v,
      });

      let addFlag = false;
      const daibunruiIndex = this.list.findIndex((d) => d.$.daibunrui_id === v.$.daibunrui_id);
      if (daibunruiIndex === -1) {
        this.list.push(v);
        addFlag = true;
      }
      if (!addFlag) {
        const daibunruiListLen = this.list.filter((
          d,
        ) => d.$.daibunrui_id === v.$.daibunrui_id).length;

        const tyubunruiIndex = this.list.findIndex((d) => d.$.tyubunrui_id === v.$.tyubunrui_id
                && d.$.daibunrui_id === v.$.daibunrui_id);
        if (tyubunruiIndex === -1 && !addFlag) {
          const find = this.list.find((d) => (d.$.daibunrui_id === v.$.daibunrui_id));
          if (find) {
            v.$.daibunrui_name_out = find.$.daibunrui_name_out || v.$.daibunrui_name;
          }
          this.list.splice(daibunruiIndex + daibunruiListLen, 0, v);
          addFlag = true;
        }

        if (!addFlag) {
          const tyubunruiListLen = this.list.filter((
            d,
          ) => (d.$.tyubunrui_id === v.$.tyubunrui_id
                  && d.$.daibunrui_id === v.$.daibunrui_id
          )).length;
          if (tyubunruiListLen) {
            const find = this.list.find((d) => (d.$.tyubunrui_id === v.$.tyubunrui_id
                && d.$.daibunrui_id === v.$.daibunrui_id
            ));
            if (find) {
              v.$.daibunrui_name_out = find.$.daibunrui_name_out || v.$.daibunrui_name;
              v.$.tyubunrui_name_out = find.$.tyubunrui_name_out || v.$.tyubunrui_name_out;
            }
            this.list.splice(tyubunruiIndex + tyubunruiListLen, 0, v);
          }
        }
      }
    }
    this.format();
  }

  add(data: AddMeisaiData, isEdit?:boolean,
    isPrintName?: boolean, copyData?: MeisaiListXML): Promise<void> {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve) => {
      const temp = data;
      let index = 0;

      /* 挿入する大分類のIndex */
      const daibunruiIndex = this.list.findIndex((v) => v.$.daibunrui_id === temp.daibunrui_id);
      // console.log(daibunruiIndex);
      /* 挿入する中分類位置のIndex */
      const tyubunruiIndex = this.list.findIndex((v) => v.$.daibunrui_id === temp.daibunrui_id
      && v.$.tyubunrui_id === temp.tyubunrui_id);
      // console.log(tyubunruiIndex);

      /* name_outの設定 */
      if (daibunruiIndex !== -1) {
        temp.daibunrui_name_out = this.list[daibunruiIndex].$.daibunrui_name_out;
      }
      if (daibunruiIndex !== -1 && tyubunruiIndex !== -1) {
        temp.tyubunrui_name_out = this.list[tyubunruiIndex].$.tyubunrui_name_out;
      }

      /* -1の時0に変換 */
      // tyubunruiIndex = tyubunruiIndex === -1 ? 0 : tyubunruiIndex;
      // daibunruiIndex = daibunruiIndex === -1 ? 0 : daibunruiIndex;

      /* 中分類の長さ */
      const tyubunruiLen = this.list.filter((v) => v.$.daibunrui_id === temp.daibunrui_id
      && v.$.tyubunrui_id === temp.tyubunrui_id).length || 0;

      if (daibunruiIndex === -1) {
      /* 大分類が現在のListにない場合最後尾 */
        index = !this.list.length ? 0 : this.list.length;
      // console.log(1, index);
      } else if (tyubunruiIndex === -1) {
        const tyuLen = this.list.filter((v) => v.$.daibunrui_id === data.daibunrui_id).length;
        /* 中分類が現在のListにない場合大分類の最後尾 */
        index = daibunruiIndex + tyuLen;
      } else {
        index = tyubunruiLen + tyubunruiIndex;
      }

      if (copyData) {
        const findIndex = this.list.findIndex((v) => v === copyData);
        if (findIndex > -1) index = findIndex + 1;
      }

      const print_name = isPrintName
        ? temp.print_name ?? ''
        : temp.name || '';

      this.list.splice(index, 0, cloneDeep({
        $: {
          ...temp,
          id: String(index + 1),
          row: String(index + 1),
          identity_id: String(index + 1),
          filename: temp.filename || '',
          shohin_cd: temp.shohin_cd || '',
          shohizeigaku: temp.shohizeigaku || '',
          server_filename: temp.server_filename || '',
          shiiresaki_name: temp.shiiresaki_name || '',
          shiiresaki_id: temp.shiiresaki_id || '',
          valid_flag: true,
          print_name,
          daibunrui_name_out: temp.daibunrui_name_out || temp.daibunrui_name,
          tyubunrui_name_out: temp.tyubunrui_name_out || temp.tyubunrui_name,
          kingaku: String(MH.rounding(Number(temp.suryou) * Number(temp.shikiri_kakaku), 0)),
          genka_kei: String(MH.rounding(Number(temp.suryou) * Number(temp.genka), 0)),
          tyubunrui_id: temp.tyubunrui_id || '',
          daibunrui_id: temp.daibunrui_id || '',
          meisai_remark: temp.meisai_remark || '',
        },
      }));
      this.calcArari(temp);
      await this.format();
      resolve();
    });
  }

  remove(selectData: MeisaiListXML[]) {
    selectData.forEach((v) => {
      const index = this.list.findIndex((v2) => v2.$.id === v.$.id);
      if (index !== -1) this.list.splice(index, 1);
    });
  }

  // eslint-disable-next-line
  changeName(
    mode: 'tree-daibunrui' | 'tree-tyubunrui' | 'list',
    data: MeisaiListXML['$'],
  ) {
    if (mode === 'tree-daibunrui' || mode === 'tree-tyubunrui') {
      const list = this.list.filter((v) => {
        if (mode === 'tree-daibunrui') {
          return v.$.daibunrui_id === data.daibunrui_id;
        }
        return (v.$.daibunrui_id === data.daibunrui_id)
          && (v.$.tyubunrui_id === data.tyubunrui_id);
      });
      list.forEach((v) => {
        this.edit({ ...v.$, print_name: data.print_name });
      });
      return;
    }
    this.edit(data);
  }

  // eslint-disable-next-line
  moveTree(mode: 'up' | 'down', daibunrui_id: string, tyubunrui_id?: string) {
    const isUp = mode === 'up';
    const moveList = this.list.filter((v) => (
      tyubunrui_id ? v.$.tyubunrui_id === tyubunrui_id
      && v.$.daibunrui_id === daibunrui_id
        : v.$.daibunrui_id === daibunrui_id
    ));
    const moveStartIndex = this.list.findIndex((v) => (
      tyubunrui_id ? v.$.tyubunrui_id === tyubunrui_id
      && v.$.daibunrui_id === daibunrui_id
        : v.$.daibunrui_id === daibunrui_id
    ));
    const loopStart = moveStartIndex + (isUp ? -1 : moveList.length);
    const loopEnd = isUp ? 0 : this.list.length;
    let hitFlag = false;

    if (mode === 'up') {
      if (!moveStartIndex) return false; // 先頭データが含まれてる場合、処理しない
      if (tyubunrui_id) {
        // 異なる大分類にまたがる移動の場合、処理しない
        if (this.list[moveStartIndex].$.daibunrui_id
          !== this.list[moveStartIndex - 1].$.daibunrui_id) {
          return false;
        }
      }

      for (let i = loopStart; i > loopEnd; i -= 1) {
        if (this.list[i].$.daibunrui_id !== this.list[i - 1].$.daibunrui_id
          || (tyubunrui_id != null
            && this.list[i].$.tyubunrui_id !== this.list[i - 1].$.tyubunrui_id)) {
          for (let j = 0; j < moveList.length; j += 1) {
            this.list.splice(i - 1, 0, cloneDeep(moveList[j]));
            hitFlag = true;
          }
          break;
        }
        if (hitFlag) break;
      }
      if (!hitFlag) {
        for (let j = 0; j < moveList.length; j += 1) {
          this.list.splice(j, 0, cloneDeep(moveList[j]));
          hitFlag = true;
        }
      }
      this.list.splice(moveStartIndex + moveList.length, moveList.length);
    } else {
      if (this.list.length <= (moveStartIndex + moveList.length)) return false;
      if (tyubunrui_id) {
        // 異なる大分類、中分類にまたがる移動の場合、処理しない
        if (this.list[moveStartIndex + moveList.length - 1].$.daibunrui_id
          !== this.list[moveStartIndex + moveList.length].$.daibunrui_id) {
          return false;
        }
      }

      for (let i = loopStart; i < loopEnd - 1; i += 1) {
        if (this.list[i].$.daibunrui_id !== this.list[i + 1].$.daibunrui_id
          || (tyubunrui_id && this.list[i].$.tyubunrui_id
            !== this.list[i + 1].$.tyubunrui_id)) {
          for (let j = moveList.length - 1; j > -1; j -= 1) {
            this.list.splice(i + 1, 0, cloneDeep(moveList[j]));
            hitFlag = true;
          }
          break;
        }
        if (hitFlag) break;
      }

      if (!hitFlag) {
        for (let j = 0; j < moveList.length; j += 1) {
          this.list.splice(loopEnd + j, 0, cloneDeep(moveList[j]));
          hitFlag = true;
        }
      }
      this.list.splice(moveStartIndex, moveList.length);
    }

    return hitFlag;
  }

  // eslint-disable-next-line
  move(mode: 'up' | 'down', selectData:MeisaiListXML[], isTree?:boolean) {
    const cloneList = cloneDeep(this.list);
    const selectList: number[] = [];

    selectData.forEach((v) => {
      selectList.push(cloneList.findIndex((data) => data.$.id === v.$.id));
    });
    const diffIdx = mode === 'up' ? -1 : 1;
    let isChanged = false;
    /** index を昇順で並び替え */
    selectList.sort((a, b) => (mode === 'up' ? a - b : b - a));
    selectList.forEach((v, i) => {
      /** 選択中のレコードで移動不可かつ一つ上のもの、一つ上のレコードの中分類が異なるものがないかの精査 */
      const check = isTree ? selectList.findIndex((data) => (v + diffIdx === data)) === -1
        : selectList.findIndex((data) => (v + diffIdx === data)) === -1
        && cloneList[v]?.$.tyubunrui_id === cloneList[v + diffIdx]?.$.tyubunrui_id;
      // console.log(cloneList[v]?.$.tyubunrui_id, cloneList[v + diffIdx]?.$.tyubunrui_id);
      // console.log(cloneList[v], cloneList[v + diffIdx]);
      if (check) {
        /** リストの入れ替えと index の更新 */
        const temp = cloneDeep(cloneList[v + diffIdx]);

        cloneList[v + diffIdx] = cloneDeep(cloneList[v]);
        cloneList[v] = temp;
        selectList[i] = v + diffIdx;
        isChanged = true;
      }
    });
    this.list = cloneDeep(cloneList);
    return isChanged;
  }

  edit(_data: AddMeisaiData, preData?: MeisaiListXML, isSuryo?:boolean) {
    const data = cloneDeep(_data);
    if (preData) {
      if (preData.$.name !== _data.name) {
        data.print_name = _data.name;
      }

      if ((data.daibunrui_id !== preData.$.daibunrui_id
        || data.tyubunrui_id !== preData.$.tyubunrui_id)) {
        const index = this.list.findIndex((v) => v.$.id === preData.$.id);
        this.list.splice(index, 1);

        this.add(cloneDeep(
          {
            ...data,
            daibunrui_name_out: data.daibunrui_id !== preData.$.daibunrui_id
              ? ''
              : preData.$.daibunrui_name_out,
            tyubunrui_name_out: (data.daibunrui_id !== preData.$.daibunrui_id
            || data.tyubunrui_id !== preData.$.tyubunrui_id)
              ? ''
              : preData.$.tyubunrui_name_out,
          },
        ),
        true);
      } else if (isSuryo) {
        const index = this.list.findIndex((v) => v.$.id === data.id);
        let val = cloneDeep({
          ...cloneDeep(this.list[index].$),
          ...cloneDeep(data),
          kingaku: String(MH.rounding(Number(data.suryou) * Number(data.shikiri_kakaku), 0)),
          genka_kei: String(MH.rounding(Number(data.suryou) * Number(data.genka), 0)),
        });
        val = cloneDeep({
          ...cloneDeep(val),
          ...cloneDeep(this.calcArari(val)),
        });
        this.list.splice(index, 1, cloneDeep({ $: val }));
      } else {
        const _temp:AddMeisaiData = cloneDeep({
          ...data,
          kingaku: String(MH.rounding(Number(data.suryou) * Number(data.shikiri_kakaku), 0)),
          genka_kei: String(MH.rounding(Number(data.suryou) * Number(data.genka), 0)),
        });
        const temp = this.calcArari(_temp);

        const index = this.list.findIndex((v) => v.$.id === data.id);
        this.list.splice(index, 1, cloneDeep({
          $: {
            ...this.list[index].$,
            ...cloneDeep(temp),
            // print_name: temp.name,
          },
        }));
      }
    } else {
      const temp = this.calcArari(data);
      const index = this.list.findIndex((v) => v.$.id === data.id);
      this.list[index].$ = cloneDeep({
        ...this.list[index].$,
        ...cloneDeep(temp),
      });
    }
  }

  multiEdit(selectData: MeisaiListXML[], addData: Partial<AddMeisaiData>) {
    const select = cloneDeep(selectData);
    this.remove(select);
    select.forEach((v) => {
      this.add(cloneDeep({ ...v.$, ...addData }), false, true);
    });
  }

  obj2Xml() {
    return new XmlParser().build<{meisai: { mei: MeisaiListXML }[] }>({
      meisai: this.list.map((v) => ({
        mei: { $: v.$ },
      })),
    });
  }

  copyMeisai(copyList: MeisaiListXML[]): Promise<this> {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve) => {
      const originList: MeisaiListXML[] = [];

      await new Promise<void>((_resolve) => {
        copyList.forEach((v) => {
          const findIndex = this.list.findIndex((v2) => isEqual(v, v2));
          if (findIndex > -1 && this.list[findIndex]) originList.push(this.list[findIndex]);
        });
        _resolve();
      });
      for (const v of originList) {
        await this.add(v.$, false, false, v);
      }
      resolve(this);
    });
  }

  static addMasterFormat(meisaiList: MasterMeisai[]): { meisai: { mei:MeisaiListXML}[] } {
    const list = meisaiList.sort((a, b) => (a.category_id > b.category_id ? 1 : -1));
    return ({
      meisai: cloneDeep(list.map((v) => ({
        mei: {
          $: {
            arari_ritu: '',
            daibunrui_id: String(v.category_id),
            daibunrui_name: v.category_name,
            daibunrui_name_out: v.category_name,
            filename: '',
            genka: String(v.prime_cost),
            genka_kei: String(v.prime_cost),
            id: String(v.id),
            identity_id: '1',
            kikaku: v.standard,
            kingaku: String(v.quote_unit_price),
            name: v.name,
            print_name: v.name,
            row: '',
            server_filename: '',
            shiiresaki_id: '',
            shiiresaki_name: '',
            shikiri_kakaku: String(v.quote_unit_price),
            shohin_cd: String(v.shohin_cd),
            shohin_kubun: String(v.product_kubun),
            shohin_kubun_name: '',
            shohizeigaku: '',
            suryou: String(v.quantity),
            tani_id: String(v.credit_id),
            tani_name: v.credit_name,
            tyubunrui_id: String(v.subcategory_id),
            tyubunrui_name: v.subcategory_name,
            tyubunrui_name_out: v.subcategory_name,
            valid_flag: true,
            meisai_remark: '',
          },
        },
      }))),
    });
  }

  static setNumber(v: string, callback:(val: number)=>string) {
    if (!v) { return '0'; }
    const val = Number(v || 0);
    if (!val) { return '0'; }
    return callback(val);
  }

  /* 数量変更用 */
  static setSuryo(v: string) {
    if (!v) { return '0'; }
    const val = Number(v || 0);
    if (!val) { return '0'; }
    return MH.rounding2Str(val, 2, 'round', true);
  }
}
