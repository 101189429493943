import actionCreatorFactory from 'typescript-fsa';
import { ApiBase } from '../../../../service/api-base';
import { CsvProjectListType } from '../../../../type/csv/csv.type';

/* Param */
export type ApiCsvProjectGetListParam = Partial<{
  /** 案件担当（店舗）*/
  project_store_id:number;
  /** 案件担当（担当者）*/
  project_employee_id:number;
  /** 案件名*/
  name:string;
  /** 現場名称*/
  field_name:string;
  /** 現場電話番号*/
  field_tel_no:string;
  /** 顧客名*/
  customer_name:string;
  /** 工事状況*/
  construction_status:number[];
  /** 有効フラグ*/
  valid_flag: number;
  /** 現場都道府県 */
  field_prefectures: number;
  /** 現場住所 */
  field_address: string;
  /** 案件ランク（見込みランク） */
  project_rank: number;
  /** 案件ランクフィルタ */
  project_rank_filter: number;
  /** 工事部位 */
  construction_parts: number[];
  /** 発生源 */
  source_ids: number[];
  /** 見込み金額 */
  mitumori_kin: number;
  /** 見込金額－範囲開始 */
  mitumori_kin_min: number;
  /** 見込金額ー範囲終了 */
  mitumori_kin_max: number;
  /** 契約日-期間開始 */
  contract_date_from: string;
  /** 契約日-期間終了 */
  contract_date_to: string;
  /** 受注工期（開始）-期間開始 */
  construction_period_start_from: string;
  /** 受注工期（開始）-期間終了 */
  construction_period_start_to: string;
  /** 受注工期（終了）-期間開始 */
  construction_period_end_from: string;
  /** 受注工期（終了）-期間終了 */
  construction_period_end_to: string;
  /** 着工予定日-期間開始 */
  construction_start_date_from: string;
  /** 着工予定日-期間終了 */
  construction_start_date_to: string;
  /** 着工日-期間開始 */
  construction_date_from: string;
  /** 着工日-期間終了 */
  construction_date_to: string;
  /** 完工予定日-期間開始 */
  completion_end_date_from: string;
  /** 完工予定日-期間終了 */
  completion_end_date_to: string;
  /** 完工日-期間開始 */
  completion_date_from: string;
  /** 完工日-期間終了 */
  completion_date_to: string;
  /** 着工式-期間開始 */
  construction_execution_date_from: string;
  /** 着工式-期間終了 */
  construction_execution_date_to: string;
  /** 完工式-期間開始 */
  completion_execution_date_from: string;
  /** 完工式-期間終了 */
  completion_execution_date_to: string;
  /** 完了日-期間開始 */
  complete_date_from: string;
  /** 完了日-期間終了 */
  complete_date_to: string;
  /** 失注日-期間開始 */
  failure_date_from: string;
  /** 失注日-期間終了 */
  failure_date_to: string;
  /** キャンセル日-期間開始 */
  cancel_date_from: string;
  /** キャンセル日-期間終了 */
  cancel_date_to: string;
  /** 受注金額（契約金額） */
  order_price: number;
  /** 受注金額（契約金額）-範囲開始 */
  order_price_min: number;
  /** 受注金額（契約金額）-範囲終了 */
  order_price_max: number;
  /** 緯度 */
  north_lat: string;
  /** 経度 */
  north_lng: string;
  /** 緯度2 */
  south_lat: string;
  /** 経度2 */
  south_lng: string;
  /** 登録店舗 */
  created_store_id: number;
  /** 登録社員 */
  created_employee_id: number;
  /** 更新店舗 */
  updated_store_id: number;
  /** 更新社員 */
  updated_employee_id: number;
  /** 登録日－期間開始 */
  created_at_start: string;
  /** 登録日－期間終了 */
  created_at_end: string;
  /** 更新日－期間開 */
  updated_at_start: string;
  /** 更新日－期間終了 */
  updated_at_end: string;
  /** オフセット */
  offset: number;
  /** 1ページ内の件数 */
  limit: number;
  /** 並替基準列 */
  sort_by: number;
  /** 並替方法 */
  highlow: number;

  /** 指定選択用(これを仕様する際は他のparamを送らない) */
  ids: number[];

}>;

export type ApiCsvProjectDownloadParam = any;

/* Response */
export type ApiCsvProjectGetListResponse = CsvProjectListType;

export const ActionCreator = actionCreatorFactory('csv/api/project');

export const apiCsvProject = {
  getList: ActionCreator<ApiCsvProjectGetListParam>('get/list'),
  download: ActionCreator<ApiCsvProjectDownloadParam>('download'),
};

export class ApiCsvProjectGetList extends ApiBase<
  ApiCsvProjectGetListResponse
> {
  constructor(param: ApiCsvProjectGetListParam) {
    super({
      httpMethod: 'GET',
      param,
      url: '/api/csv/project',
    });
  }
}

export class ApiCsvProjectDownload extends ApiBase {
  constructor(param: ApiCsvProjectDownloadParam) {
    super({
      httpMethod: 'GET',
      contents: 'BLOB',
      param,
      url: '/api/csv/download/project',
    });
  }
}
