import {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { goBack } from 'connected-react-router';
import { Button } from '../../../../../ui/button/button';
import { DisplayElements } from '../../../../../../type/display-elements.type';
import { Table } from '../../../../../ui/table/table';
import { LeftIconButton } from '../../../../../ui/button/left-icon-button/left-icon-button';
import { TableOption } from '../../../../../../type/table.type';
import { Input } from '../../../../../ui/input/input';

export type MasterDirectInputGetListParam = {
  order: number;
  sort: number;
}

type Props = {
  header: { key: string; label: string;}[];
  rowDataList: any[];
  list: DisplayElements[][];
  callbackPost: () => void;
  callbackGetList: () => void;
  defaultOrder: number;
  onClickCell?: (i: number, j: number) => void;
  tableOption?: TableOption;
  disabledTr?: number[],
};

export const MasterBodyDirectInput = (props: Props) => {
  const dispatch = useDispatch();

  const {
    rowDataList,
    header,
    callbackGetList,
    callbackPost,
    onClickCell,
    list,
    tableOption,
    defaultOrder,
    disabledTr,
  } = props;

  /* State */
  const [selected, setSelected] = useState(NaN);

  /* Callback */
  const handleClickRow = useCallback(
    (row: any) => {
      setSelected(rowDataList.findIndex((v2) => v2.id === row.id));
    },
    [rowDataList],
  );

  const getList = useCallback(
    () => {
      callbackGetList();
    },
    [callbackGetList],
  );

  const handleClickCell = useCallback((i: number, j: number) => {
    onClickCell?.(i, j);
  }, [rowDataList]);

  /* Effect */
  useEffect(() => {
    getList();
  }, []);

  return (
    <div className="main_cnt">
      <div className="search_area only_simple ">
        <div className="item_wrap">
          <div className="item_box" />
          <Button
            className="btn_search for_simple"
            size="sm"
            color="secondary"
            onClick={callbackPost}
          >
            更新
          </Button>

        </div>
      </div>
      <section className="result_area list_area">
        <div className="inner">
          <div className="table_responsive">
            <Table
              className="table_selectable table_sortable table_sticky table_cell_change"
              header={header.map((v) => v.label)}
              onClickRow={handleClickRow}
              selectedTr={[selected]}
              rowDataList={rowDataList}
              disabledHeader
              sort={{
                index: [],
                onClick: (order, sort) => {},
              }}
              lists={list.map((v:any) => ([
                ...v,
              ]))}
              disabledTr={disabledTr}
              option={tableOption}
              onClickCell={handleClickCell}
            />
          </div>
        </div>
      </section>
      <footer className="btn_area">
        <div className="left_box" />
        <div className="right_box">
          <LeftIconButton
            label="戻る"
            fontAwesomeClass="fas fa-arrow-left"
            size="md"
            color="dark"
            onClick={() => dispatch(goBack())}
          />
        </div>
      </footer>
    </div>
  );
};
