import { cloneDeep } from 'lodash';
import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { CustomerCollection } from '../../../../collection/customer/customer.collection';
import { MapCollection } from '../../../../collection/map/map.collection';
import { ProjectCollection } from '../../../../collection/project/project.collection';
import { useDidMount, useWillUnMount } from '../../../../hooks/life-cycle';
import { useAppSelector } from '../../../../hooks/use-redux';
import { CustomerModel } from '../../../../model/customer/customer-model';
import { ProjectModel } from '../../../../model/project/project.model';
import { CustomerActions } from '../../../../redux/customer/customer.action';
import { DialogActions } from '../../../../redux/dialog/dialog.action';
import { MapActions } from '../../../../redux/map/map.action';
import { ProjectActions } from '../../../../redux/project/project.action';
import { CustomerListType } from '../../../../type/customer/customer.type';
import { MaintenanceList } from '../../../../type/maintenance/maintenance.type';
import { Address, MapAreaPosition } from '../../../../type/map/map.type';
import { ProjectListType } from '../../../../type/project/project.type';
import { noPinch } from '../../../../utilities/no-pinch';
import { LeftIconButton } from '../../button/left-icon-button/left-icon-button';
import { MapBase } from '../map-base';
import './registration-address-map-dialog.scss';

export type Props = {
  type?: 'customer' | 'project' | 'maintenance'
  callbackData?: (data:CustomerListType | MaintenanceList | ProjectListType) => void;
  callback?: (address: Address | null) => void;
  label?: string;
  isNoSearch?: boolean;
};

export const RegistrationAddressMapDialog = (props:Props) => {
  const {
    callback, type, callbackData, label, isNoSearch,
  } = props;

  const ele = useRef<HTMLDivElement>(null);

  /* Hooks */
  const dispatch = useDispatch();
  const cList = useAppSelector((v) => v.customer.routeList);
  const pList = useAppSelector((v) => v.project.routeList);
  const routeMapAreaPos = useAppSelector((v) => (v.map.routeMapAreaPos));
  const zoom = useAppSelector((v) => v.map.routeZoomLevel);
  const humanPos = useAppSelector((v) => v.map.humanPos);
  const areaDisabled = useAppSelector((v) => v.map.routeAreaBtnDisabled);
  const routeSearchName = useAppSelector((v) => v.map.routeSearchName);

  const [zoomSearch, setZoomSearch] = useState(false);
  const [isInit, setIsInit] = useState(true);
  const [humanInit, setHumanInit] = useState(true);

  const mapType = useMemo(() => {
    let _mapType: 'customer' | 'project' | undefined;
    if (type === 'customer') _mapType = 'customer';
    if (type === 'project') _mapType = 'project';
    return _mapType;
  }, [type]);

  const mapLoading = useMemo(() => {
    let list: CustomerListType[] | ProjectListType[] | null = null;
    if (type === 'customer') list = cloneDeep(cList);
    else if (type === 'project') list = cloneDeep(pList);
    else list = [];
    return list;
  }, [cList, pList, type]);

  /* Callback */
  const mapHeight = window.innerHeight * 0.83;

  // const mapHeight = window.innerHeight * 0.79;
  const searchOption = useMemo(() => {
    switch (type) {
      case 'customer':
        return ({
          customerOption: {
            type: 0,
            callbackMapRegist: callbackData,
          },
          label: label || '登録',
        });
      case 'project':
        return ({
          projectOption: {
            type: 0,
            callbackMapRegist: callbackData,
          },
          label: label || '登録',
        });
      case 'maintenance':
        return ({
          maintenanceOption: {
            type: 0,
            callbackMapRegist: callbackData,
          },
          label: label || '登録',
        });
      default:
        return ({
          searchOption: {
            isRegistrationAddress: {
              callback: (address: Address | null) => {
                if (callback) callback(address);
                dispatch(DialogActions.pop());
              },
              label: label || '登録',
            },
          },
        });
    }
  }, [type, callbackData, label]);

  const getList = useCallback((v?: MapAreaPosition, searchName: string = routeSearchName) => {
    if (!type) return;
    if (type === 'customer') {
      dispatch(CustomerActions.setRouteList(null));
      dispatch(CustomerActions.api.customer.getList(
        {
          param: CustomerModel.listParam({
            sortState: {
              ...CustomerCollection.customerSortInitialState,
              name: searchName || '',
            },
            isMap: true,
            mapAreaPos: v || routeMapAreaPos,
          }),
          isRoute: true,
          onSuccess: (data) => {
            dispatch(CustomerActions.setRouteList(cloneDeep(data?.data || [])));
            if (!data?.data.length) {
              dispatch(DialogActions.pushMessage({
                title: '検索',
                message: ['領域内に該当データがありません'],
              }));
            }
          },
        },
      ));
    }

    if (type === 'project') {
      dispatch(ProjectActions.setRouteList(cloneDeep(null)));
      dispatch(ProjectActions.api.project.getList(
        {
          param: ProjectModel.listParam({
            sortState: {
              ...ProjectCollection.initialSortState(),
              name: searchName || '',
            },
            isMap: true,
            mapAreaPos: v || routeMapAreaPos,
          }),
          isRoute: true,
          onSuccess: (data) => {
            dispatch(ProjectActions.setRouteList(cloneDeep(data?.data || [])));
            if (!data?.data.length) {
              dispatch(DialogActions.pushMessage({
                title: '検索',
                message: ['領域内に該当データがありません'],
              }));
            }
          },
        },
      ));
    }
    dispatch(MapActions.setRouteAreaBtnDisabled(true));
  }, [routeMapAreaPos, type, routeSearchName]);

  /* Effect */
  useEffect(() => () => {
    dispatch(MapActions.setSearchPos(null));
    dispatch(MapActions.setSearchAddress(null));
  }, []);

  useEffect(() => {
    const pinchCallback = noPinch(ele.current);
    return pinchCallback;
  }, [ele]);

  const onClickAreaSearch = useCallback((v?: MapAreaPosition, name?: string) => {
    if (name !== undefined) dispatch(MapActions.setRouteSearchName(name));
    setZoomSearch(true);
    if (zoom >= MapCollection.searchRouteZoomLevel && routeMapAreaPos) {
      getList(v, name);
      setZoomSearch(false);
    } else {
      dispatch(MapActions.setRouteZoomLevel(cloneDeep(MapCollection.searchRouteZoomLevel)));
    }
  }, [getList, zoom, routeMapAreaPos, zoomSearch]);

  useEffect(() => {
    if (isInit && routeMapAreaPos) {
      onClickAreaSearch(routeMapAreaPos);
      setIsInit(false);
    }
  }, [routeMapAreaPos]);

  useWillUnMount(() => {
    dispatch(MapActions.setRouteZoomLevel(null));
    dispatch(MapActions.setRouteMapAreaPosition(null));
    dispatch(MapActions.setRouteAreaBtnDisabled(true));
  });

  return (
    <>
      <div className="registrationAddressMapDialog" style={{ width: '100%', height: mapHeight }} ref={ele}>
        <MapBase
          {...searchOption}
          noGps
          isRoute
          mapLoad={!mapLoading}
          type={mapType}
          isNoSearch={isNoSearch}
          callbackZoomEnd={(v) => {
            if (!zoomSearch) {
              dispatch(MapActions.setRouteAreaBtnDisabled(false));
              return;
            } if (zoom >= MapCollection.searchRouteZoomLevel) {
              getList(v);
              setZoomSearch(false);
            }
          }}
          callbackRouteSearchName={(v) => {
            onClickAreaSearch(undefined, v);
          }}
        />
      </div>
      {(!areaDisabled && !!type) && (
      <LeftIconButton
        label="このエリアを検索"
        size="md"
        fontAwesomeClass="fas fa-search"
        className="for_area for_registration"
        color="secondary"
        onClick={() => {
          dispatch(MapActions.setRouteAreaBtnDisabled(true));
          onClickAreaSearch();
        }}
      />
      )}
    </>
  );
};
