import { EstimateListType } from '../../../type/estimate/estimate.type';
import { OrderEditState } from '../../../type/order/order.type';
import { ValidationDatePicker } from '../validation-date-picker';
import { ValidationLengthUnder500 } from '../validation-length-under';

/* 参考
  ProjectManager.as
  function saveProjectOrder
*/

type BillList = {
  billing_item_id_array: number[];
  /** 請求項目名称 */
  billing_item_name_array: string[];
  /** 請求日 */
  billing_date_array: string[];
  /** 請求金額 */
  billing_amount_array: number[];
  /** 入金予定日 */
  payment_due_date_array: string[];
  /** 請求ID */
  billing_id_array: number[];
}

export const OrderValidation = (
  data: OrderEditState,
  billList: BillList,
  estimateList: EstimateListType[] | null,
  isAuthority: boolean,
  // seikyu?: any,
) => {
  const errorList: string[] = [];

  /* 紐づいた見積情報があるか */
  if (isAuthority && (!estimateList || !estimateList.length)) {
    return ['最終見積が存在しません'];
  }

  console.log(data.project_id);
  console.log(data.contract_date);
  console.log(data.construction_start_date);
  console.log(data.completion_end_date);
  if (
    // 必須
    (!data.project_id
      || !data.contract_date
      || !data.construction_start_date
      || !data.completion_end_date

      // バリデーション
      || ValidationDatePicker.filter((v) => !v.run(String(data.contract_date || ''))).length
      || ValidationDatePicker.filter((v) => !v.run(String(data.construction_start_date || ''))).length
      || ValidationDatePicker.filter((v) => !v.run(String(data.completion_end_date || ''))).length
      || ValidationDatePicker.filter((v) => !v.run(String(data.contract_billing_date || ''))).length
      || ValidationDatePicker.filter((v) => !v.run(String(data.contract_expected_date || ''))).length
      || ValidationDatePicker.filter((v) => !v.run(String(data.start_construction_billing_date || ''))).length
      || ValidationDatePicker.filter((v) => !v.run(String(data.start_construction_expected_date || ''))).length
      || ValidationDatePicker.filter((v) => !v.run(String(data.intermediate1_billing_date || ''))).length
      || ValidationDatePicker.filter((v) => !v.run(String(data.intermediate1_expected_date || ''))).length
      || ValidationDatePicker.filter((v) => !v.run(String(data.intermediate2_billing_date || ''))).length
      || ValidationDatePicker.filter((v) => !v.run(String(data.intermediate2_expected_date || ''))).length
      || ValidationDatePicker.filter((v) => !v.run(String(data.completion_billing_date || ''))).length
      || ValidationDatePicker.filter((v) => !v.run(String(data.completion_expected_date || ''))).length
      || ValidationLengthUnder500.filter((v) => !v.run(String(data.remarks || ''))).length)
  ) {
    errorList.push('未入力／入力不備項目があります');
  }

  /* いずれかの請求金額がマイナスであれば弾く */
  billList.billing_amount_array.forEach((v, i) => {
    if (v < 0) {
      errorList.push(`${billList.billing_item_name_array[i]}がマイナスでは登録できません。`);
    }
  });

  /* 請求一覧合計金額 */
  const aLis = Number(billList.billing_amount_array.reduce((a, b) => {
    const _a = Number.isNaN(a) ? 0 : a;
    const _b = Number.isNaN(b) ? 0 : b;
    return _a + _b;
  }));
  /* 受注金と請求合計金額が同じか */
  if (aLis !== Number(data.order_price)) {
    errorList.push('請求金額と受注金額が一致していません');
  }

  /* 請求情報 */
  // if (!seikyu) {
  //   errorList.push('請求項目が取得できていません');
  // }

  /**
   * 各請求金に対して請求日・入金予定日の両方の有無を確認
   */

  const billingDateAry = billList.billing_date_array;
  const paymentDateAry = billList.payment_due_date_array;
  const priceAry = billList.billing_amount_array;

  for (let i = 0; i < priceAry.length; i += 1) {
    if (priceAry[i]) {
      if (!billingDateAry[i] || !paymentDateAry[i]) {
        errorList.push('請求金に対しての請求日、又は入金予定日が未入力です。');
        return errorList;
      }
    }
  }

  return errorList;
};
