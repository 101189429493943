import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DialogActions } from '../../../../../../../redux/dialog/dialog.action';
import { MasterEditDialogProps } from '../../../master.type';
import { Input } from '../../../../../../ui/input/input';
import { Required } from '../../../../../../ui/required/required';
import { Checkbox } from '../../../../../../ui/checkbox/checkbox';
import './relevant-tag-master-edit-dialog.scss';
import { MasterRelevantTagValidation } from '../../../../../../../model/validation/master/master-relevant-tag.validation';
import { MasterActions } from '../../../../../../../redux/master/master.action';
import { EditPC } from '../../../../../../dialogs/edit/edit.pc';
import { useDidMount } from '../../../../../../../hooks/life-cycle';
import { State } from '../../../../../../../redux/root.reducer';
import { Message } from '../../../../../../../collection/message.collection';
import { ValidationLengthUnder255 } from '../../../../../../../model/validation';

export const RelevantTagMasterEditDialog = (props: MasterEditDialogProps) => {
  const { id, callback } = props;

  const dispatch = useDispatch();

  const list = useSelector((state:State) => state.master.relevantTagList);

  const [name, setName] = useState('');
  const [validFlag, setValidFlag] = useState(true);

  const [touch, setTouch] = useState(false);

  const handleClickPost = useCallback(() => {
    if (MasterRelevantTagValidation(
      name,
    )) {
      dispatch(DialogActions.pushMessage({
        title: '関連タグ 編集／追加',
        message: Message.postError,
        callback: () => setTouch(true),
      }));
      return;
    }

    dispatch(MasterActions.api.relevantTag.post({
      param: {
        data: {
          name,
          // is_input: textInputFlag ? 1 : 0,
          is_valid: validFlag ? 1 : 0,
        },
        id,
      },
      onSuccess: () => {
        callback();
        dispatch(DialogActions.pop());
      },
      onError: () => {
        setTouch(true);
      },
    }));
  }, [
    name,
    // textInputFlag,
    validFlag,
    callback,
  ]);

  useDidMount(() => {
    if (id) {
      const data = list.find((f) => Number(f.id) === id);
      if (!data) return;
      setName(data.name);
      // setTextInputFlag(data.input_flag);
      setValidFlag(data.valid_flag);

      // dispatch(MasterActions.api.relevantTag.get({
      //   param: { id },
      //   onSuccess: (data) => {
      //     if (!data) return;
      //     setName(data.name);
      //     setTextInputFlag(data.input_flag);
      //     setValidFlag(data.valid_flag);
      //   },
      // }));
    }
  });

  return (
    <EditPC mode="dialog" callback={handleClickPost} label={id ? '更新' : '登録'}>
      <div className="edit_pc_body_inner edit_master">
        <div>
          <div className="item_wrap">
            <div className="item_box">
              <div className="item_head">関連タグ名称<Required /></div>
              <Input
                className=""
                errorPosBottom
                require
                value={name}
                onChange={(e) => setName(e.target.value)}
                validationList={ValidationLengthUnder255}
                touch={touch}
              />
            </div>
          </div>
          <div className="item_wrap">
            <div className="item_box">
              <div className="item_head">有効フラグ</div>
              <Checkbox
                label=""
                className="is_valid"
                checked={!!validFlag}
                onChange={() => setValidFlag(!validFlag)}
              />
            </div>
          </div>
        </div>
      </div>
    </EditPC>
  );
};
// <div className="item_wrap">
//   <div className="item_box">
//     <div className="item_head">テキスト入力有無</div>
//     <Checkbox
//       label=""
//       className="is_input"
//       checked={!!textInputFlag}
//       onChange={() => setTextInputFlag(!textInputFlag)}
//     />
//   </div>
// </div>
