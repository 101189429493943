// import { useState } from 'react';
// import { Header as SHeader, Segment } from 'semantic-ui-react';
import './header.pc.scss';
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import isEqual from 'lodash/isEqual';
import {
  Divider, ListItemIcon, ListItemText, Menu, MenuItem,
} from '@material-ui/core';
import { RoutingPath } from '../../../../routes/routing-pass';
import { IconButton } from '../../../ui/button/icon-button/icon-button';
import { DialogActions } from '../../../../redux/dialog/dialog.action';
import { OrderPC } from '../../pages/order/order.pc';
import { AuthActions } from '../../../../redux/auth/auth.action';
import { State } from '../../../../redux/root.reducer';
import { Config } from '../../../../config/config';
import { Profile } from '../../../ui/profile/profile';
import { ChangePasswordDialogPC } from './change-password-dialog/change-password-dialog.pc';
import { CsvActions } from '../../../../redux/csv/csv.action';
import { useEstimateAuthority } from '../../../../hooks/use-authority';
import { RightLabelCheckbox } from '../../../ui/checkbox/right-label-checkbox/right-label-checkbox';
import { UserActions } from '../../../../redux/user/user.action';
import Logo from '../../../../asset/images/reformcloud_horizontal.png';
import { Select } from '../../../ui/select/select';
import { LeftIconButton } from '../../../ui/button/left-icon-button/left-icon-button';
import { MasterContractedChangeCompanyBody } from './change-company-dialog/change-company-dialog';

type Props = {
  onClickOrder?: (isOpen: boolean) => void;
}

export const HeaderPC = (props: Props) => {
  const { onClickOrder } = props;
  const location = useLocation();
  const path = location.pathname;
  const activeMenu = path; // path が複雑になる場合は要調整
  const user = useSelector((state: State) => state.user, isEqual);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const estimateAuth = useEstimateAuthority();
  // useCallback
  const dispatch = useDispatch();
  const handleClick = useCallback(
    (routingPath: string) => {
      if (routingPath === '/export-csv') {
        dispatch(CsvActions.resetPageType());
      }
      dispatch(push(routingPath));
    },
    [],
  );

  const changePassword = useCallback(() => {
    setAnchorEl(null);
    dispatch(DialogActions.push({
      title: 'パスワード変更',
      element: <ChangePasswordDialogPC />,
    }));
  }, []);

  const logout = useCallback(() => {
    setAnchorEl(null);
    dispatch(DialogActions.pushMessage({
      title: 'ログアウト',
      message: ['ログアウトしますか'],
      isCancel: true,
      callback: () => {
        dispatch(AuthActions.api.logout());
      },
    }));
  }, []);

  const handleClickChangeCompany = useCallback(() => {
    dispatch(DialogActions.push({
      title: '参照する契約会社の変更',
      className: 'max_height_dialog max_width_dialog search_dialog',
      element: <MasterContractedChangeCompanyBody />,
    }));
  },
  []);

  return (
    <header className="headerPC">
      <div className="headerPC__top">
        <h1>
          <Link to={RoutingPath.customer}>
            <img src={Logo} alt="リフォームクラウド" />
            {Config.mode === 'local' ? '-- Local --' : ''}
          </Link>
        </h1>
        {user.access_11 === 1 && (
        <>
          <div className="select_company_box flex_no_wrap_box">
            <div>
              現在参照中の契約会社：<strong>{user?.view_data?.company_name ?? (user?.company_name ?? '')}</strong>
            </div>
            <LeftIconButton
              onClick={handleClickChangeCompany}
              size="md"
              color="primary"
              fontAwesomeClass="far fa-building"
              label="参照会社変更"
            />
          </div>
        </>
        )}
        <div className="account_box">
          {/* FIXME 店舗名 */}
          ようこそ！{user?.store_name || ''}&nbsp;{user?.name}&nbsp;さん
          <IconButton
            title="設定"
            fontAwesomeClass="fas fa-cog"
            size="md"
            className="default logout"
            onClick={(e:any) => setAnchorEl(e.currentTarget)}
          />
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={() => { setAnchorEl(null); }}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <Profile
              user={user}
              logout={logout}
              changePass={changePassword}
              className="profile"
            />
          </Menu>
        </div>
      </div>

      <div className="headerPC__bottom">
        <div
          className={`headerPC__bottom__menu ${activeMenu === RoutingPath.customer || activeMenu.match(`${RoutingPath.customer}/detail`) ? 'active' : ''}`}
          onClick={() => handleClick(RoutingPath.customer)}
        >
          顧客
        </div>
        <div
          className={`headerPC__bottom__menu ${activeMenu === RoutingPath.project || activeMenu.match(`${RoutingPath.project}/detail`) ? 'active' : ''}`}
          onClick={() => handleClick(RoutingPath.project)}
        >
          案件
        </div>
        {estimateAuth && (
        <div
          className={`headerPC__bottom__menu ${activeMenu === RoutingPath.estimate || activeMenu.match(`${RoutingPath.estimate}/detail`) ? 'active' : ''}`}
          onClick={() => handleClick(RoutingPath.estimate)}
        >
          見積
        </div>
        )}
        <div
          className={`headerPC__bottom__menu ${activeMenu === RoutingPath.order ? 'active' : ''}`}
          onClick={() => {
            onClickOrder?.(true);
            dispatch(DialogActions.push({
              title: '受注登録',
              className: 'max_height_dialog max_width_dialog order_dialog',
              element: <OrderPC isDisplaySearch onClose={() => onClickOrder?.(false)} />,
              onCloseClick: () => onClickOrder?.(false),
              onClose: () => onClickOrder?.(false),
            }));
          }}
        >
          受注
        </div>
        <div
          className={`headerPC__bottom__menu ${activeMenu === RoutingPath.maintenance ? 'active' : ''}`}
          onClick={() => handleClick(RoutingPath.maintenance)}
        >
          メンテナンス
        </div>
        <div
          className={`headerPC__bottom__menu ${activeMenu === RoutingPath.file ? 'active' : ''}`}
          onClick={() => handleClick(RoutingPath.file)}
        >
          ファイル
        </div>
        <div
          className={`headerPC__bottom__menu ${activeMenu === RoutingPath.supportHistory ? 'active' : ''}`}
          onClick={() => handleClick(RoutingPath.supportHistory)}
        >
          対応履歴
        </div>
        <div
          className={`headerPC__bottom__menu ${activeMenu === RoutingPath.exportCsv ? 'active' : ''}`}
          onClick={() => handleClick(RoutingPath.exportCsv)}
        >
          CSV出力
        </div>
        {Boolean(user.authority4) && (
        <div
          className={`headerPC__bottom__menu ${activeMenu === RoutingPath.master ? 'active' : ''}`}
          onClick={() => handleClick(RoutingPath.master)}
        >
          マスタ管理
        </div>
        )}
      </div>
    </header>
  );
};
