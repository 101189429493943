import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { EditPC } from '../../../../../../dialogs/edit/edit.pc';
import { Input } from '../../../../../../ui/input/input';
import { Required } from '../../../../../../ui/required/required';
import { ValidationLengthUnder2 } from '../../../../../../../model/validation';
import { RightLabelCheckbox } from '../../../../../../ui/checkbox/right-label-checkbox/right-label-checkbox';
import { ColorPicker } from '../../../../../../ui/color-picker/color-picker';
import { MasterEditDialogProps } from '../../../master.type';
import { useAppSelector } from '../../../../../../../hooks/use-redux';
import { useDidMount } from '../../../../../../../hooks/life-cycle';
import { MasterActions } from '../../../../../../../redux/master/master.action';
import { MasterProjectRankValidation } from '../../../../../../../model/validation/master/master-project-rank.validation';
import { DialogActions } from '../../../../../../../redux/dialog/dialog.action';
import { ValidationLengthUnder10 } from '../../../../../../../model/validation/_validation-length-under-10';
import { Message } from '../../../../../../../collection/message.collection';
import { ValidationEisuzi } from '../../../../../../../model/validation/validation-eisuzi';

export const ProjectRankMasterEditDialog = (props: MasterEditDialogProps) => {
  const { id, callback } = props;
  const list = useAppSelector((state) => state.master.projectRankList);
  const dispatch = useDispatch();

  /* State */
  const [name, setName] = useState('');
  const [abbreviation, setAbbreviation] = useState('');
  const [backGround, setBackGround] = useState('');
  const [textColor, setTextColor] = useState('');
  const [validFlag, setValidFlag] = useState(true);

  const [touch, setTouch] = useState(false);

  const handleClickPost = useCallback(() => {
    if (MasterProjectRankValidation(
      {
        name,
        abbreviation,
        backGround,
        textColor,
      },
    )) {
      dispatch(DialogActions.pushMessage({
        title: '案件見込みランク 編集／追加',
        message: Message.postError,
        callback: () => setTouch(true),
      }));
      return;
    }

    dispatch(MasterActions.api.projectRank.post({
      param: {
        data: {
          name,
          abbreviation,
          background_color: backGround,
          text_color: textColor,
          is_valid: validFlag ? 1 : 0,
        },
        id,
      },
      onSuccess: () => callback(),
      onError: () => setTouch(true),
    }));
  }, [
    name,
    abbreviation,
    backGround,
    textColor,
    validFlag,
  ]);

  useDidMount(() => {
    if (id) {
      const data = list.find((f) => Number(f.id) === id);
      if (!data) return;
      setName(data.name);
      setAbbreviation(data.abbreviation);
      setValidFlag(data.valid_flag);
      setTextColor(data.text_color);
      setBackGround(data.background_color);
    }
  });

  return (
    <EditPC mode="dialog" callback={handleClickPost} label={id ? '更新' : '登録'}>
      <div className="edit_pc_body_inner edit_master project_rank_master">
        <div>
          <div className="item_wrap">
            <div className="item_box">
              <div className="item_head">案件見込みランク名<Required /></div>
              <Input
                className=""
                value={name}
                onChange={(e) => setName(e.target.value)}
                errorPosBottom
                require
                touch={touch}
                maxLength={10}
              />
            </div>
          </div>
          <div className="item_wrap">
            <div className="item_box">
              <div className="item_head">略式表示</div>
              <Input
                className=""
                value={abbreviation}
                onChange={(e) => setAbbreviation(e.target.value)}
                validationList={ValidationEisuzi}
                touch={touch}
                maxLength={2}
              />
            </div>
          </div>
          <div className="item_wrap">
            <div className="item_box">
              <div className="item_head">背景色<Required /></div>
              <ColorPicker
                title="カラーピッカー"
                color={backGround}
                fontAwesomeClass="fas fa-eye-dropper"
                className="secondary"
                callbackColor={setBackGround}
                require
                touch={touch}
              />
              {/* datepickerと同じようにinput内にアイコンの形にする  */}
              {/*
              <IconButton
                title="カラーピッカー"
                fontAwesomeClass="fas fa-eye-dropper"
                className="secondary"
                disabled
                onClick={() => {}}
              />
              */}
            </div>
          </div>
          <div className="item_wrap">
            <div className="item_box">
              <div className="item_head">文字色<Required /></div>
              <ColorPicker
                title="カラーピッカー"
                color={textColor}
                fontAwesomeClass="fas fa-eye-dropper"
                className="secondary"
                callbackColor={setTextColor}
                require
                touch={touch}
              />
            </div>
          </div>
          <div className="item_wrap">
            <div className="item_box">
              <div className="item_head">有効フラグ</div>
              <RightLabelCheckbox
                label=""
                className="is_valid"
                checked={validFlag}
                onChange={() => setValidFlag(!validFlag)}
              />
            </div>
          </div>
        </div>
      </div>
    </EditPC>
  );
};
