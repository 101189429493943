import { Validation } from './validation';

export const ValidationPasswordShort: Validation[] = [
  new Validation(
    (v) => (
      // new RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?\d)[A-Za-z\d]{8,16}$/).test(v) || !v.length
      new RegExp(/[A-Za-z\d]{8,}$/).test(v)
    ),
    '形式が違います',
  ),
];
