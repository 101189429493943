import { takeEvery, takeLatest } from 'redux-saga/effects';
import * as lodash from 'lodash';
import cloneDeep from 'lodash/cloneDeep';
import { MaintenanceActions } from './maintenance.action';
import { Store } from '../store';
import { ApiMaintenanceGet, ApiMaintenanceGetList, ApiMaintenancePost } from './api/maintenance/api-maintenance';
import {
  getListHandle, postHandle, getHandle, getError,
} from '../root.saga';
import { ApiMaintenanceGetListResponse, ApiMaintenanceGetResponse } from './api/maintenance/api-maintenance.type';
import { DialogActions } from '../dialog/dialog.action';
import { ApiMasterAfterMaintenanceGetListResponse } from '../master/api/master-after-maintenance/api-master-after-maintenance.type';

function* tryMaintenanceGet(action: ReturnType<typeof MaintenanceActions.api.maintenance.get>) {
  const { param, callback } = action.payload;
  if (!param.id) {
    yield getError('メンテナンス');
    return;
  }
  const api = new ApiMaintenanceGet(param);
  yield getHandle<ApiMaintenanceGetResponse>({
    api,
    onSuccess: (res) => {
      Store.dispatch(MaintenanceActions.setMaintenance(res));
      if (callback && res) callback(lodash.cloneDeep(res));
    },
    * onError() {
      yield getError('メンテナンス');
    },
  });
}

function* tryMaintenanceGetList(
  action: ReturnType<typeof MaintenanceActions.api.maintenance.getList>,
) {
  const {
    param, callback, noLoad, isLoad, isMapLoad,
  } = action.payload;
  yield getListHandle<ApiMaintenanceGetListResponse>({
    noLoad: !isLoad,
    noDelay: true,
    mapLoad: isMapLoad,
    api: new ApiMaintenanceGetList(param),
    onSuccess: (res, hitCount) => {
      if (callback) {
        callback(cloneDeep(res), hitCount);
        return;
      }
      Store.dispatch(MaintenanceActions.setList(res));
    },
  });
}

function* tryMaintenanceGetCallbackList(action: ReturnType<
  typeof MaintenanceActions.api.maintenance.getCallbackList>) {
  const { param, onSuccess, noLoad } = action.payload;
  yield getListHandle<ApiMaintenanceGetListResponse>({
    api: new ApiMaintenanceGetList(param),
    noDelay: true,
    noLoad: true,
    onSuccess,
  });
}

function* tryMaintenancePost(action: ReturnType<typeof MaintenanceActions.api.maintenance.post>) {
  const { param, onSuccess, update } = action.payload;
  yield postHandle({
    title: 'メンテナンス情報',
    api: new ApiMaintenancePost(param),
    update,
    onSuccess: () => {
      Store.dispatch(DialogActions.pop());
      if (onSuccess) onSuccess();
    },
  });
}

export function* MaintenanceSaga() {
  yield takeLatest(MaintenanceActions.api.maintenance.get, tryMaintenanceGet);
  yield takeLatest(MaintenanceActions.api.maintenance.post, tryMaintenancePost);
  yield takeLatest(MaintenanceActions.api.maintenance.getList, tryMaintenanceGetList);
  yield takeLatest(MaintenanceActions.api.maintenance.getCallbackList,
    tryMaintenanceGetCallbackList);
}
