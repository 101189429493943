import { actionCreatorFactory } from 'typescript-fsa';
import { ApiJisx0401GetListParam } from './api/api-jisx0401';
import { Jisx0401ListType, Jisx0402ListType, ZipCodeListType } from '../../type/zip-code/zip-code.type';
import { ApiJisx0402GetListParam } from './api/api-jisx0402';
import { ApiZipCodeGetListParam } from './api/api-zipcode';

const ActionCreator = actionCreatorFactory('zip-code');
export const ZipCodeActions = {
  api: {
    jisx0401: {
      getList: ActionCreator<ApiJisx0401GetListParam>('jisx0401/get/list'),
    },
    jisx0402: {
      getList: ActionCreator<ApiJisx0402GetListParam>('jisx0402/get/list'),
    },
    zipCode: {
      getList: ActionCreator<{
        param: ApiZipCodeGetListParam;
        onSuccess:(v: ZipCodeListType[]) => void;
          }>('zip/code/get/list'),
    },
  },
  setJisx0401List: ActionCreator<Jisx0401ListType[]>('set/jisx0401/list'),
  setJisx0402List: ActionCreator<Jisx0402ListType[] | null>('set/jisx0402/list'),
  setZipCodeList: ActionCreator<ZipCodeListType[] | null>('set/zip/code/list'),
  setConcatZipCodeList: ActionCreator<ZipCodeListType[] | null>('set/concat/zip/code/list'),
};
