import {
  all, put, takeEvery,
} from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { AuthActions } from '../auth/auth.action';
import { ResultType } from '../../type/api.type';
import { UserActions } from './user.action';
import { RoutingPath } from '../../routes/routing-pass';
import { DialogActions } from '../dialog/dialog.action';
import { ApiBase } from '../../service/api-base';
import { Store } from '../store';
import { User } from '../../type/auth/user.typs';
import { ApiUser } from './api/api-user';
import { SupportHistoryActions } from '../support-history/support-history.action';
import { CustomerActions } from '../customer/customer.action';
import { ProjectActions } from '../project/project.action';
import { MaintenanceActions } from '../maintenance/maintenance.action';
import { EstimateActions } from '../estimate/estimate.action';
import { MapCollection } from '../../collection/map/map.collection';
import { MapActions } from '../map/map.action';
import { SystemActions } from '../system/system.action';

const customUser = (res: ResultType<User>): User => {
  const _user = res.body.data[0];
  const isViewCompany = res.body.data[0].view_data
    ? res.body.data[0].view_data.company_id !== res.body.data[0].company_id
    : false;
  return !isViewCompany ? { ..._user } : {
    ..._user,
    company_authority1: _user.view_data.company_authority1,
    company_authority2: _user.view_data.company_authority2,
    company_authority3: _user.view_data.company_authority3,
    company_authority4: _user.view_data.company_authority4,
    company_authority5: _user.view_data.company_authority5,
    company_authority6: _user.view_data.company_authority6,
    store_lat: _user.view_data.store_lat,
    store_lng: _user.view_data.store_lng,
  };
};

function* setSortUser(user: User) {
  yield all([
    put(SupportHistoryActions.setInitSort({
      ...user,
      store_id: !user.view_data || user.view_data.company_id === 1 ? user.store_id : NaN,
      id: !user.view_data || user.view_data.company_id === 1 ? user.id : NaN,
    })),
    put(CustomerActions.setInitSort({
      ...user,
      store_id: !user.view_data || user.view_data.company_id === 1 ? user.store_id : NaN,
      id: !user.view_data || user.view_data.company_id === 1 ? user.id : NaN,
    })),
    put(ProjectActions.setInitSort({
      ...user,
      store_id: !user.view_data || user.view_data.company_id === 1 ? user.store_id : NaN,
      id: !user.view_data || user.view_data.company_id === 1 ? user.id : NaN,
    })),
    put(MaintenanceActions.setInitSort({
      ...user,
      store_id: !user.view_data || user.view_data.company_id === 1 ? user.store_id : NaN,
      id: !user.view_data || user.view_data.company_id === 1 ? user.id : NaN,
    })),
    put(EstimateActions.setInitSort({
      ...user,
      store_id: !user.view_data || user.view_data.company_id === 1 ? user.store_id : NaN,
      id: !user.view_data || user.view_data.company_id === 1 ? user.id : NaN,
    })),
    put(EstimateActions.setEstimateSearchInitSort({
      ...user,
      store_id: !user.view_data || user.view_data.company_id === 1 ? user.store_id : NaN,
      id: !user.view_data || user.view_data.company_id === 1 ? user.id : NaN,
    })),
    put(MapActions.setCenterPos({
      lat: Number(user.store_lat) || 35.703559,
      lng: Number(user.store_lng) || 139.749636,
    })),
    console.log({
      lat: Number(user.store_lat) || 35.703559,
      lng: Number(user.store_lng) || 139.749636,
    }),
  ]);
}

function* tryGetUser(action: ReturnType<typeof UserActions.getUser>) {
  const api = new ApiUser();

  function* funcDialog(res?: ResultType<User>) {
    const callback = () => {
      Store.dispatch(push(RoutingPath.login));
    };
    yield put(DialogActions.pushMessage({
      title: '',
      message: res?.header.messages || ['ログインし直してください'],
      callback,
      callbackClose: callback,
    }));
  }

  try {
    const res: ResultType<User> = yield api.run();
    if (res.header.status_code === 401) {
      if (localStorage.getItem('isLogin') === 'true') {
        yield put(DialogActions.setIs404(true));
      }
      yield put(push(RoutingPath.login));
      return;
    }

    if (ApiBase.isSuccess(res)) {
      const user = customUser(res);
      yield put(UserActions.setUser(user));
      yield put(AuthActions.setToken(api.token));
      yield setSortUser(user);
      localStorage.setItem('isLogin', 'true');
      action.payload.callback(user);
    } else {
      yield put(push(RoutingPath.login));
      action.payload.callback(null);
    }
  } catch {
    action.payload.callback(null);
    yield funcDialog();
  }
}

export function* UserSaga() {
  yield takeEvery(UserActions.getUser, tryGetUser);
}
