import { ApiBase } from '../../../service/api-base';
import { ZipCodeListType } from '../../../type/zip-code/zip-code.type';

export type ApiZipCodeGetListResponse = ZipCodeListType;

export type ApiZipCodeGetListParam = Partial<{
  /** 郵便番号 */
  zipcode: string;
  /** 都道府県 */
  pref: string;
  /** 都道府県以下の住所 */
  city_town: string;
  /** JISX0402コード */
  code: string;
  /** オフセット */
  offset: number;
  /** 1ページ内の件数 */
  limit: number;
}>;

export class ApiZipCodeGetList extends ApiBase<ApiZipCodeGetListResponse> {
  constructor(param: ApiZipCodeGetListParam) {
    super({
      credentials: 'include',
      httpMethod: 'GET',
      url: '/api/zipcode',
      param,
    });
  }
}
