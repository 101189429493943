/* eslint-disable max-len */
import { EditPriceAreaState, EstimateEditState } from '../../../type/estimate/estimate.type';
import {
  ValidationLengthUnder500,
} from '..';
import { ValidationDatePicker } from '../validation-date-picker';
import { EstimateMeisaiModel } from '../../estimate/estimate-meisai.model';
import { Message } from '../../../collection/message.collection';

export const EstimateValidation = (
  data: EstimateEditState, meisai: EstimateMeisaiModel | null, priceModel: EditPriceAreaState,
): string[] => {
  /* 条件ごとのエラーメッセージを追加 */
  const errorMessages: string[] = [];

  /* 必須項目とバリデーション */
  if (
  // 必須
    !data.project_id
      || !data.estimate_dt
      || !data.estimate_kouki_start_dt
      || !data.estimate_kouki_end_dt
      || !data.yukokigen
      || !data.jyutyu_yotei_dt
      || !meisai?.list.length

  // バリデーション
  // || ValidationDatePicker.filter((v) => !v.run(String(data.estimate_dt || ''))).length
  // || ValidationDatePicker.filter((v) => !v.run(String(data.estimate_kouki_start_dt || ''))).length
  // || ValidationDatePicker.filter((v) => !v.run(String(data.estimate_kouki_end_dt || ''))).length
  // || ValidationDatePicker.filter((v) => !v.run(String(data.yukokigen || ''))).length
  // || ValidationDatePicker.filter((v) => !v.run(String(data.jyutyu_yotei_dt || ''))).length
  // || ValidationLengthUnder500.filter((v) => !v.run(String(data.remarks || ''))).length
  // || meisai?.list.every((v) => ValidationLengthUnder255.filter((v2) => !v2.run(String(v.$.kikaku || ''))).length)
  // || meisai?.list.every((v) => ValidationMax100Million.filter((v2) => !v2.run(String(v.$.suryou || ''))).length)
  // || meisai?.list.every((v) => ValidationLengthUnder500.filter((v2) => !v2.run(String(v.$.meisai_remark || ''))).length)
  // || ValidationMax10Billion.filter((v) => !v.run(String(priceModel.tyosei_kin || ''))).length
  // || ValidationMax100Thousand.filter((v) => !v.run(String(priceModel.reserve1_estimate_percent || ''))).length
  // || ValidationMax100Thousand.filter((v) => !v.run(String(priceModel.reserve1_genka_percent || ''))).length
  // || ValidationMax100Thousand.filter((v) => !v.run(String(priceModel.reserve2_estimate_percent || ''))).length
  // || ValidationMax100Thousand.filter((v) => !v.run(String(priceModel.reserve2_genka_percent || ''))).length
  ) {
    errorMessages.push(Message.postError[0]);
  }
  /* 工期の日付が「新 → 古」となっている場合 */
  if (Number(data.estimate_kouki_end_dt) < Number(data.estimate_kouki_start_dt)) {
    errorMessages.push('工期を正しく入力してください');
  }
  /* 見積合計がマイナスの場合 */
  if (priceModel.gokei_zeinuki_kin < 0) {
    errorMessages.push('見積合計がマイナスでは登録できません');
  }
  /* 原価合計がマイナスの場合 */
  if (priceModel.gokei_zeinuki_genka_kin < 0) {
    errorMessages.push('原価合計がマイナスでは登録できません');
  }

  return errorMessages;
};

export const EstimateInfoValidation = (data: EstimateEditState) => {
  window.console.log();
  return (
  // 必須
    !data.project_id
    || !data.estimate_dt
    || !data.estimate_kouki_start_dt
    || !data.estimate_kouki_end_dt
    || !data.yukokigen
    || !data.jyutyu_yotei_dt

    // バリデーション
    || ValidationLengthUnder500.filter((v) => !v.run(String(data.remarks || ''))).length
    || ValidationDatePicker.filter((v) => !v.run(String(data.estimate_dt || ''))).length
    || ValidationDatePicker.filter((v) => !v.run(String(data.estimate_kouki_start_dt || ''))).length
    || ValidationDatePicker.filter((v) => !v.run(String(data.estimate_kouki_end_dt || ''))).length
    || ValidationDatePicker.filter((v) => !v.run(String(data.yukokigen || ''))).length
    || ValidationDatePicker.filter((v) => !v.run(String(data.jyutyu_yotei_dt || ''))).length
    // || ValidationNumberLengthUnder20.
    // filter((v) => !v.run(String(data.adjustment_amount || ''))).length
    // || ValidationNumberLengthUnder5.filter((v) =>
    //  !v.run(String(data.field_cost_quote || ''))).length
    // || ValidationNumberLengthUnder5.filter((v) =>
    //  !v.run(String(data.field_cost || ''))).length
    // || ValidationNumberLengthUnder5.filter((v) =>
    //  !v.run(String(data.call_cost_quote || ''))).length
    // || ValidationNumberLengthUnder5.filter((v) =>
    //  !v.run(String(data.call_cost || ''))).length
  );
};
