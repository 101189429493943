import { useCallback, useState } from 'react';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import { Table } from '../../../../../ui/table/table';
import './csv-list-maintenance.scss';
import { ExportCsvCollection } from '../../../../../../collection/export-csv/export-csv.collection';
import { CsvActions } from '../../../../../../redux/csv/csv.action';
import { CsvMaintenanceType } from '../../../../../../type/csv/csv.type';
import { useAppSelector } from '../../../../../../hooks/use-redux';
import { RoutingPath } from '../../../../../../routes/routing-pass';
import { DateFormatter as DF } from '../../../../../../utilities/date-formatter';

export const CsvListMaintenance = () => {
  /* Hooks */
  const list = useAppSelector((v) => v.csv.maintenanceList);
  const dispatch = useDispatch();

  /* State */
  const [selected, setSelected] = useState<number[]>([]);

  /* Callback */
  const handleClick = useCallback((row: CsvMaintenanceType) => {
    if (!list) return;
    setSelected([list.findIndex((v) => v.id === row.id)]);
  }, [list]);

  const handleClickHeader = useCallback((highlow:0 | 1, sort_by: number) => {
    dispatch(CsvActions.setMaintenanceSort({ highlow, sort_by }));
  }, []);

  const handleDbClick = useCallback((row:CsvMaintenanceType) => {
    if (!list) return;
    dispatch(push(`${RoutingPath.maintenanceDetail}/${row.id}`));
    setSelected([list.findIndex((v) => v.id === row.id)]);
  }, [list]);

  return (
    <section className="result_area list_area">
      <div className="inner">
        <div className="table_responsive">
          <Table
            className="table_selectable table_sortable table_sticky table_cell_change"
            header={ExportCsvCollection.maintenanceHeader}
            onClickRow={handleClick}
            onDbClick={handleDbClick}
            option={ExportCsvCollection.getTableOption('maintenance')}
            sort={{ onClick: handleClickHeader }}
            rowDataList={list || []}
            selectedTr={selected}
            lists={list
              ? list.map((v) => (
                [
                  v.internal_id,
                  v.supported_kubun ? <i className="far fa-circle" /> : '',
                  DF.date2str(v.maintenance_date, 'YYYYMMDD', '/'),
                  v.title,
                  DF.date2str(v.supported_date, 'YYYYMMDD', '/'),
                  DF.date2str(v.completion_date, 'YYYYMMDD', '/'),
                  v.customer_name,
                  v.project_name,
                  v.project_employee_name,
                ]
              ))
              : null}
          />
        </div>
      </div>
    </section>
  );
};
