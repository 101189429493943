import React, {
  useCallback, useMemo, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { cloneDeep } from 'lodash';
import { DialogActions } from '../../../../../redux/dialog/dialog.action';
import { Button } from '../../../../ui/button/button';
import { DatePicker } from '../../../../ui/date-picker/date-picker';
import { Select } from '../../../../ui/select/select';
import './support-history-edit.pc.scss';
import { SupportHistoryEditState, SupportHistory } from '../../../../../type/support-history/support-history.type';
import { SupportHistoryCollection } from '../../../../../collection/support-history/support-history.collection';
import { Input } from '../../../../ui/input/input';
import { SupportHistoryActions } from '../../../../../redux/support-history/support-history.action';
import { useDidMount } from '../../../../../hooks/life-cycle';
import { LeftLabelCheckbox } from '../../../../ui/checkbox/left-label-checkbox/left-label-checkbox';
import { EditPC } from '../../../../dialogs/edit/edit.pc';
import { Customer, CustomerListType } from '../../../../../type/customer/customer.type';
import { Project, ProjectListType } from '../../../../../type/project/project.type';
import { CustomerSearch } from '../../../layout/search-box/customer/customer-search/customer-search';
import { ProjectSearch } from '../../../layout/search-box/project/project-search/project-search';
import { Required } from '../../../../ui/required/required';
import { RegistrationAddressMapDialogPC } from '../../../../ui/map/registration-address-map-dialog/pc/registration-address-map-dialog.pc';
import {
  ValidationLengthUnder40,
  ValidationLengthUnder500,
  ValidationLengthUnder60,
} from '../../../../../model/validation';
import { SupportHistoryValidation } from '../../../../../model/validation/support-history/support-history.validation';
import { TextArea } from '../../../../ui/text-area/text-area';
import { MasterActions } from '../../../../../redux/master/master.action';
import { FileUploadButton } from '../../../../ui/file-upload/file-upload-button';
import { ValidationDatePicker } from '../../../../../model/validation/validation-date-picker';
import { hours, minutes } from '../../../../../collection/time';
import { getNowDate } from '../../../../../utilities/get-now-time';
import { FilesType, SupportHistoryModel as SM } from '../../../../../model/support-history/support-history.model';
import { useAppSelector } from '../../../../../hooks/use-redux';
import { pulldown } from '../../../../../utilities/pulldown';
import { FileActions } from '../../../../../redux/file/file.action';
import { Message } from '../../../../../collection/message.collection';
import { CommonCollection } from '../../../../../collection/common/common.collection';
import { InfoButton } from '../../../../ui/button/info-button/info-button';
import { useRegistrationMapCustomer, useRegistrationMapProject } from '../../../../../hooks/map/use-map';
import { DateFormatter } from '../../../../../utilities/date-formatter';

type Props = {
  id?: number;
  customer?: Customer | CustomerListType;
  project?: Project | ProjectListType;
  callback?: () => void;
  callbackPostEnd?: () => void;
}

export const SupportHistoryEditPC = (props: Props) => {
  const {
    id, customer, project, callback, callbackPostEnd,
  } = props;

  /* Hooks */
  const dispatch = useDispatch();
  const regiMapCustomer = useRegistrationMapCustomer();
  const regiMapProject = useRegistrationMapProject();

  /* State */
  const storeList = useAppSelector((v) => (v.master.storeList));
  const employeeList = useAppSelector((v) => (v.master.employeeList));
  const originList = useAppSelector((v) => (v.master.originList));
  const supportHistoryList = useAppSelector((v) => (v.master.supportHistoryList));
  const user = useAppSelector((v) => (v.user));

  /* State */
  const [editState, setEditState] = useState(SupportHistoryCollection._editInitialState({
    ...user,
    store_id: !user.view_data || user.view_data.company_id === 1 ? user.store_id : NaN,
    id: !user.view_data || user.view_data.company_id === 1 ? user.id : NaN,
  }));
  const [preFiles, setPreFiles] = useState<FilesType[]>([]);
  const [files, setFiles] = useState<FilesType[]>([]);
  const [formats, setFormats] = useState<string[]>([]);
  const [images, setImages] = useState<string[]>([]);
  const [touch, setTouch] = useState(false);
  const [info, setInfo] = useState<null | SupportHistory>(null);

  const contactEmployeeList = useMemo(() => SM.setEditEmployeeList({
    editState, employeeList, type: 'contract',
  }),
  [editState.contact_store_id, employeeList]);

  const supportedEmployeeList = useMemo(() => SM.setEditEmployeeList({
    editState, employeeList, type: 'support',
  }),
  [editState.support_store_id, employeeList]);

  /* Callback */
  const setState = useCallback((v: Partial<SupportHistoryEditState>) => {
    setEditState({ ...cloneDeep(editState), ...cloneDeep(v) });
  }, [editState]);

  /* 保存 */
  const post = useCallback(() => {
    console.log('post', editState);
    if (SupportHistoryValidation(editState)) {
      dispatch(DialogActions.pushMessage({
        title: `対応履歴${id ? '更新' : '新規登録'}`,
        message: Message.postError,
        callback: () => setTouch(true),
      }));
      return;
    }
    console.log(Boolean(callback));
    dispatch(SupportHistoryActions.api.supportHistory.post({
      param: {
        id,
        data: SM.postParam({
          files, preFiles, id, editState,
        }),
      },
      update: !!id,
      onSuccess: () => {
        dispatch(DialogActions.pop());
        callback?.();
        callbackPostEnd?.();
      },
    }));
  },
  [editState, callback, files, preFiles, id, callbackPostEnd]);

  /* 顧客検索 */
  const customerSearch = useCallback((initData?: Partial<SupportHistoryEditState>) => {
    dispatch(DialogActions.push({
      title: '顧客検索',
      className: 'max_height_dialog',
      element: <CustomerSearch
        callback={(data) => {
          setState({
            ...SM.setCustomer({ editState, customer: data }),
            ...initData,
          });
        }}
        freeAuth
      />,
    }));
  }, [editState]);

  /* 地図から登録 */
  const mapSearch = useCallback((type: 'customer' | 'project') => {
    if (type === 'customer') {
      regiMapCustomer((v) => {
        dispatch(DialogActions.pop());
        setState(SM.setCustomer({ customer: v, editState }));
      });
      return;
    }
    regiMapProject((v) => {
      dispatch(DialogActions.pop());
      setState(SM.setProject({ project: v }));
    });
  }, [editState]);

  /* 案件・顧客情報のクリア */
  const handleClickClear = useCallback(() => setState(SM.setClear()), []);

  /* 案件検索 */
  const projectSearch = useCallback(() => {
    dispatch(DialogActions.push({
      title: '案件検索',
      className: 'max_height_dialog',
      element: <ProjectSearch
        type="support"
        callback={(data) => setState(SM.setProject({ project: data }))}
        supportData={editState}
      />,
    }));
  }, [editState]);

  /* ファイルの読み込み */
  const onFileInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = e.currentTarget.files;
    if (!fileList?.length) return;
    const res = SM.setInputFileList({ fileList, files });
    setFiles(res);
  }, [files]);

  /* ファイル削除 */
  const deleteFile = useCallback((index: number) => {
    files.splice(index, 1);
    setFiles(cloneDeep(files));
  }, [files]);

  /* ファイルダウンロード */
  const downloadFile = useCallback((fileId: number, name:string) => {
    dispatch(FileActions.api.file.download({
      param: { file_id: fileId },
      fileName: name || '',
    }));
  }, []);

  /* Effect */
  useDidMount(() => {
    dispatch(MasterActions.api.store.getList({}));
    dispatch(MasterActions.api.employee.getList({}));
    dispatch(MasterActions.api.supportHistory.getList({}));
    dispatch(MasterActions.api.largeCategory.getList({}));
    dispatch(MasterActions.api.origin.getList({}));

    const { date, hour, min } = getNowDate();

    /* 今日の日付 */
    const d: Partial<SupportHistoryEditState> = {
      contact_dt: date,
      contact_hour: hour,
      contact_minutes: min,
    };

    /* 顧客から登録 */
    if (customer) {
      setState({ ...SM.setCustomer({ customer, editState }), ...d });
      return;
    }

    /* 案件から登録 */
    if (project) {
      setState({ ...SM.setProject({ project }), ...d });
      return;
    }

    /* 編集 */
    if (id !== undefined) {
      dispatch(SupportHistoryActions.api.supportHistory.get({
        param: { id },
        callback: (data) => {
          setInfo(data);
          const _files = () => data.file_list.map((v) => ({
            id: v.file_id,
            name: v.file_name,
            file: null,
            format: v.format,
          }));
          setPreFiles(_files());
          setFiles(_files());
          setState(SM.setGetData({ data }));
        },
      }));
      return;
    }

    /* 新規 */
    customerSearch(d);
  });

  return (
  /* 保存 */
    <EditPC mode="dialog" callback={post} label={id ? '更新' : undefined}>
      <div className="">
        <div className="item_wrap">
          <div className="item_box">
            <div className="item_head">登録日時<Required /></div>
            <DatePicker
              date={editState.contact_dt}
              onChange={(v) => setState(SM.setContactDt({ contact_dt: v }))}
              require
              touch={touch}
              errorPosBottom
            />
          </div>
          <div className="item_box">
            <Select
              value={editState.contact_hour}
              onChange={(v) => setState({ contact_hour: Number(v) })}
              defaultLabel="指定無し"
              options={pulldown(hours)}
            /><label>時</label>
          </div>
          <div className="item_box">
            <Select
              className=""
              value={editState.contact_minutes}
              onChange={(v) => setState({ contact_minutes: Number(v) })}
              defaultLabel="指定無し"
              options={pulldown(minutes)}
            /><label>分</label>
          </div>
        </div>
        <div className="item_wrap">
          <div className="item_box">
            <div className="item_head">登録担当<Required /></div>
            <Select
              className="add_text_left"
              label="店舗"
              value={editState?.contact_store_id}
              defaultLabel="指定無し"
              options={pulldown(storeList)}
              onChange={(v) => setState({ contact_store_id: Number(v) })}
              require
            />
          </div>
          <div className="item_box">
            <Select
              className="add_text_left"
              label="担当者"
              value={editState?.contact_employee_id}
              defaultLabel="指定無し"
              options={pulldown(contactEmployeeList)}
              onChange={(v) => setState({ contact_employee_id: Number(v) })}
              require
            />
          </div>
        </div>
        <div className="item_wrap">
          <div className="item_box large">
            <div className="item_head">対応履歴名</div>
            <Input
              className="large"
              value={editState?.title}
              onChange={(e) => setState({ title: e.target.value })}
              validationList={ValidationLengthUnder60}
            />
          </div>
        </div>
        <div className="item_wrap">
          <div className="item_box">
            <div className="item_head">媒体</div>
            <Select
              value={editState?.support_category_id}
              defaultLabel="指定無し"
              options={supportHistoryList.map((v) => ({
                text: v.supported, value: v.id,
              }))}
              onChange={(v) => setState({ support_category_id: Number(v) })}
            />
          </div>
        </div>
        <div className="item_wrap">
          <div className="item_box">
            <div className="item_head">カテゴリ</div>
            <Select
              value={editState?.source_id}
              defaultLabel="指定無し"
              options={pulldown(originList)}
              onChange={(v) => setState({ source_id: Number(v) })}
            />
          </div>
        </div>
        <div className="item_wrap">
          <div className="item_box">
            <div className="item_head">顧客名<Required /></div>
            <Input
              value={editState.customer_name}
              disabled
              require
              touch={touch}
            />
            <Button
              className="ml_10"
              size="sm"
              color="secondary"
              onClick={() => customerSearch()}
            >
              顧客検索
            </Button>
            <Button
              className="ml_10"
              size="sm"
              color="secondary"
              onClick={() => mapSearch('customer')}
            >
              地図から検索
            </Button>
          </div>
        </div>
        <div className="item_wrap">
          <div className="item_box">
            <div className="item_head">案件名</div>
            <Input
              disabled
              value={editState.project_name}
            />
            <Button
              className="ml_10"
              size="sm"
              color="secondary"
              onClick={projectSearch}
            >
              案件検索
            </Button>

            <Button
              className="ml_10"
              size="sm"
              color="secondary"
              onClick={() => mapSearch('project')}
            >
              地図から検索
            </Button>

            <Button
              className="ml_10"
              size="sm"
              color="dark"
              onClick={handleClickClear}
            >
              クリア
            </Button>
          </div>

        </div>
        <div className="item_wrap">
          <div className="item_box">
            <div className="item_head">ファイル</div>
            <div>
              {Boolean(files.length) && (
                files.map((v, i) => (
                  <div style={{ display: 'flex' }} className="mb_5" key={i}>
                    <Input
                      className="mr_10"
                      value={v.name}
                      onChange={(e) => {
                        files[i].name = e.target.value;
                        setFiles(cloneDeep(files));
                      }}
                    />
                    <div>{files[i].format}</div>
                    {id && Boolean(v.id)
                      && (
                      <Button
                        className="ml_10"
                        size="sm"
                        color="secondary"
                        onClick={() => downloadFile(v.id, v.name)}
                      >
                        ダウンロード
                      </Button>
                      )}
                    <Button
                      className="ml_10"
                      size="sm"
                      color="dark"
                      onClick={() => deleteFile(i)}
                    >
                      削除
                    </Button>
                  </div>
                ))
              )}
              <div className="flex_no_wrap_box">
                <FileUploadButton
                  onChange={onFileInputChange}
                  accept={CommonCollection.acceptFile}
                  multiple
                />
                <InfoButton title={Message.fileInfo} />
              </div>
            </div>
          </div>
        </div>
        <div className="item_wrap">
          <div className="item_box large">
            <div className="item_head">対応内容</div>
            <TextArea
              className="large"
              rows={7}
              value={editState?.support_comment}
              onChange={(e) => setState({ support_comment: e.target.value })}
              validationList={ValidationLengthUnder500}
            />
          </div>
        </div>
        <div className="item_wrap">
          <div className="item_box">
            <div className="item_head">対応担当</div>
            <Select
              style={{ minWidth: '10em' }}
              className="add_text_left"
              label="店舗"
              value={editState?.support_store_id}
              defaultLabel="指定無し"
              options={pulldown(storeList)}
              onChange={(v) => setState({ support_store_id: Number(v) })}
            />
            <Select
              className="add_text_left"
              label="担当者"
              value={editState?.support_employee_id}
              defaultLabel="指定無し"
              options={pulldown(supportedEmployeeList)}
              onChange={(v) => setState({ support_employee_id: Number(v) })}
            />
          </div>
        </div>
        <div className="item_wrap">
          <div className="item_box">
            <div className="item_head">対応日</div>
            <DatePicker
              date={editState.support_dt}
              onChange={(v) => setState({ support_dt: v })}
            />
          </div>
        </div>
        <div className="item_wrap">
          <div className="item_box">
            <div className="item_head">対応済みフラグ</div>
            <LeftLabelCheckbox
              checked={Boolean(editState?.support_flag)}
              label=""
              onChange={() => {
                setState({
                  support_flag: editState?.support_flag ? 0 : 1,
                });
              }}
            />
          </div>
        </div>
        <div className="item_wrap">
          <div className="item_box">
            <div className="item_head">更新日</div>
            <Input
              value={DateFormatter.date2str(info?.updated_at, 'YYYYMMDD_HHmm')}
              className="size_datepicker add_time"
              disabled
            />
          </div>
          <div className="item_box">
            <div className="item_head">更新者</div>
            <Input
              value={info?.updated_employee_name || ''}
              className=""
              disabled
            />
          </div>
        </div>
      </div>
    </EditPC>
  );
};
