import { reducerWithInitialState } from 'typescript-fsa-reducers';
import * as lodash from 'lodash';
import { cloneDeep } from 'lodash';
import { DialogActions } from './dialog.action';
import { DialogProps } from '../../type/dialog.type';

export type DialogState = { dialogs: DialogProps[], is404: boolean};

const initialState: DialogState = { dialogs: [], is404: false };

export const DialogReducer = reducerWithInitialState<DialogState>(initialState)
  .case(DialogActions.push, (state, payload) => ({
    ...state,
    dialogs: [...state.dialogs, lodash.cloneDeep(payload)],
  }))
  .case(DialogActions.pop, (state) => {
    const { dialogs } = state;
    const arr = lodash.cloneDeep(dialogs);
    if (state.dialogs.length > 0) {
      arr.splice(state.dialogs.length - 1, 1);
    }
    return {
      ...state,
      dialogs: arr,
    };
  })
  .case(DialogActions.changeTitle, (state, payload) => {
    const { index, title } = payload;
    if (!state.dialogs[index]) return state;
    return ({
      ...state,
      dialogs: state.dialogs.map((v, i) => {
        if (i === index) {
          return {
            ...v,
            title,
          };
        }
        return v;
      }),
    });
  })
  .case(DialogActions.clear, (state) => ({
    ...state,
    dialogs: [],
  }))
  .case(DialogActions.setIs404, (state, payload) => ({
    ...state,
    is404: cloneDeep(payload),
  }))
  .case(DialogActions.resetState, () => initialState)
  .default((state) => state);
