import { cloneDeep } from 'lodash';

type SortParam = {
  employeeList: any[];
  storeId: number;
  storeKey: string;
}

export const sortEmployee = (param: SortParam): any[] => {
  const {
    employeeList,
    storeId,
    storeKey,
  } = cloneDeep(param);
  return storeId ? employeeList.filter(
    (v) => v[storeKey] === storeId,
  )
    : employeeList;
};

type ChangeParam<T> = {
  employeeKey: keyof T;
  returnStoreKey: keyof T;
} & SortParam;

export const changeStore = <T>(param: ChangeParam<T>) => {
  const {
    employeeList,
    storeId,
    storeKey,
    employeeKey,
    returnStoreKey,
  } = cloneDeep(param);
  const filter = sortEmployee({
    employeeList,
    storeId,
    storeKey,
  });

  if (storeId && filter.findIndex((v) => v[storeKey] === storeId)) {
    // console.log({ [employeeKey]: NaN, [returnStoreKey]: storeId });
    return { [employeeKey]: NaN, [returnStoreKey]: storeId };
  }
  // console.log({ [returnStoreKey]: storeId });
  return { [returnStoreKey]: storeId };
};
