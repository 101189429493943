/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { put, takeEvery } from 'redux-saga/effects';
import lodash, { cloneDeep } from 'lodash';
import { Store } from '../store';
import { ApiMasterStoreGetList, ApiMasterStoreLogoGet, ApiMasterStorePost } from './api/master-store/api-master-store';
import { MasterActions } from './master.action';
import { ApiBase } from '../../service/api-base';
import { ApiMasterEmployeeGetList, ApiMasterEmployeePost } from './api/master-employee/api-master-employee';
import { ApiMasterSupportHistoryGetList, ApiMasterSupportHistoryPost } from './api/master-support-history/api-master-support-history';
import { ApiMasterAreaGetList, ApiMasterAreaPost } from './api/master-area/api-master-area';
import { ApiMasterBuildingCategoryGetList, ApiMasterBuildingCategoryPost } from './api/master-building-category/api-master-building-category';
import { ApiMasterGetTaxSoon, ApiMasterTaxGetList, ApiMasterTaxPost } from './api/master-tax/api-master-tax';
import { ApiMasterContractedCompanyGetList, ApiMasterContractedCompanyPost } from './api/master-contracted-company/api-master-contracted-company';
import { ApiMasterMeisaiGetList, ApiMasterMeisaiPost } from './api/master-meisai/api-master-meisai';
import { ApiMasterInquiryGetList, ApiMasterInquiryPost } from './api/master-inquiry/api-master-inquiry';
import { ApiMasterLargeCategoryGetList, ApiMasterLargeCategoryPost } from './api/master-large-category/api-master-large-category';
import { ApiMasterMadoriGetList, ApiMasterMadoriPost } from './api/master-madori/api-master-madori';
import { ApiMasterMiddleCategoryGetList, ApiMasterMiddleCategoryPost } from './api/master-middle-category/api-master-middle-category';
import { ApiMasterOriginGetList, ApiMasterOriginPost } from './api/master-origin/api-master-origin';
import { ApiMasterSignatureGetList, ApiMasterSignaturePost } from './api/master-signature/api-master-signature';
import { ApiMasterUnitGetList, ApiMasterUnitPost } from './api/master-unit/api-master-unit';
import { ApiMasterAfterMaintenanceGetList, ApiMasterAfterMaintenancePost } from './api/master-after-maintenance/api-master-after-maintenance';
import { ApiMasterCustomerInformationImport } from './api/master-customer-information-import/api-master-customer-information-import';
import { ApiMasterMyCarTypeGetList, ApiMasterMyCarTypePost } from './api/tag/master-my-car-type/ap-master-my-car-type';
import { ApiMasterRelevantTagGetList, ApiMasterRelevantTagPost } from './api/tag/master-relevant-tag/api-master-relevant-tag';
import {
  getListHandle, postHandle, getHandle, GetParam, downloadHandle,
} from '../root.saga';
import { ApiMasterProspectPartPost, ApiMasterProspectPartGetList } from './api/tag/master-prospect-part/ap-master-prospect-part';
import { ApiMasterPartGetList, ApiMasterPartPost } from './api/tag/master-part/api-master-part';
import { ApiMasterConstructionPartGetList, ApiMasterConstructionPartPost } from './api/tag/master-construction-part/ap-master-construction-part';
import { ApiMaster } from '../root.type';
import { ResFileType, ResponseHeader, ResultType } from '../../type/api.type';
import { ApiMasterCustomerKojiRankGetList, ApiMasterCustomerRankPost } from './api/master-custoemer-rank/api-master-customer-rank';
import { ApiMasterProjectRankGetList, ApiMasterProjectRankPost } from './api/master-project-rank/api-master-project-rank';
import { ApiMasterLostOrderGetList, ApiMasterLostOrderPost } from './api/master-lost-order/api-master-lost-order';
import { ApiMasterQuoteFixedGetList, ApiMasterQuoteFixedPost } from './api/master-quote-fixed/api-master-quote-fixed';
import { ApiMasterCustomerExpectedRankGetList, ApiMasterCustomerExpectedRankPost } from './api/master-customer-expected-rank/api-master-customer-expected-rank';
import { ApiMasterCustomerRankUpdateGetList, ApiMasterCustomerRankUpdateGet } from './api/master-custoemer-rank-update/api-master-customer-rank-update';
import { SystemActions } from '../system/system.action';
import { DialogActions } from '../dialog/dialog.action';
import { ApiMasterIndex } from './api/api-master-index';
import { ApiMasterCustomerRankLastGetList, ApiMasterCustomerRankLastPost } from './api/master-customer-rank-final-complete-date/api-master-customer-rank-final-complete-date';
import { ApiMasterTaxGetListResponse } from './api/master-tax/api-master-tax.type';
import { ApiMasterCustomerRankGetList } from './api/master-customer-rank/api-master-customer-rank';
import { MasterStoreLogo } from '../../type/master/master-store.type';
import {
  ApiCustomerTemplate, ApiCustomerImport, ApiImportUploadCSV, ApiImportPost,
} from './api/customer-import/api-customer-import';
import { ApiUser } from '../user/api/api-user';
import { AuthActions } from '../auth/auth.action';
import { ImportFileUploadResult } from './api/customer-import/api-customer-import.type';
import { MasterCollection } from '../../components/pc/pages/master/master.collection';
import { MasterContractedEmployee } from '../../type/master/master-contracted-employee.type';
import { ApiMasterContractedEmployeeGetList, ApiMasterContractedEmployeePost } from './api/master-contracted-employee/api-master-contracted-employee';

function* postTemp(api: ApiBase, callback?: () => void, title?: string) {
  yield postHandle({
    api,
    update: !!api.param.id,
    title,
    isAllClear: true,
    onSuccess: () => {
      if (callback)callback();
    },
  });
}

function* fileGetHandle<T = any>(param:GetParam<T> & {onError: () => void}) {
  const {
    api, onSuccess, onError, noLoad,
  } = param;
  if (!noLoad) {
    yield put(SystemActions.isLoading(true));
  }
  try {
    const result: ResultType<T> = yield api.run();
    if (ApiBase.isSuccess(result)) {
      yield onSuccess(lodash.cloneDeep(result.body.data ? result.body.data[0] : null));
    } else {
      yield onError();
    }
  } catch (e) {
    yield onError();
  } finally {
    if (!noLoad) {
      yield put(SystemActions.isLoading(false));
    }
  }
}

function* tryStoreGetList(action: ReturnType<typeof MasterActions.api.store.getList>) {
  yield getListHandle<ApiMaster.Store.Response.List>({
    api: new ApiMasterStoreGetList(action.payload),
    noLoad: true,
    * onSuccess(result) {
      // const _result = result.map((v) => {
      //   if (!v) return;
      //   if (v.logo) {
      //     const byteString = atob(v.logo as string);
      //     const content = new Uint8Array(byteString.length);
      //     for (let i = 0, l = byteString.length; i < l; i += 1) {
      //       content[i] = byteString.charCodeAt(i);
      //     }
      //     const file = new File([content], 'test')
      //   }
      // })
      yield put(MasterActions.setStoreList(result));
    },
  });
}

function* tryStorePost(action: ReturnType<typeof MasterActions.api.store.post>) {
  const { param, onSuccess, onError } = action.payload;
  yield postHandle({
    title: '店舗情報',
    api: new ApiMasterStorePost(param),
    update: !!param.id,
    onSuccess: () => {
      if (onSuccess) onSuccess();
      Store.dispatch(DialogActions.pop());
    },
    onError: () => {
      if (onError)onError();
    },
  });
}

function* tryStoreLogoGet(action: ReturnType<typeof MasterActions.api.store.getLogo>) {
  const { param, onSuccess, onError } = action.payload;
  yield fileGetHandle<MasterStoreLogo>({
    api: new ApiMasterStoreLogoGet(param.id),
    * onSuccess(result) {
      yield onSuccess?.(result!);
    },
    * onError() {
      yield onError?.();
    },
  });
}

function* tryEmployeeGetList(action: ReturnType<typeof MasterActions.api.employee.getList>) {
  yield getListHandle<ApiMaster.Employee.Response.List>({
    api: new ApiMasterEmployeeGetList(action.payload),
    noLoad: true,
    * onSuccess(result) {
      yield put(MasterActions.setEmployeeList(result));
    },
  });
}

function* tryEmployeePost(action: ReturnType<typeof MasterActions.api.employee.post>) {
  const { param, onSuccess, onError } = action.payload;
  yield postHandle({
    title: '社員情報 ',
    api: new ApiMasterEmployeePost(param),
    update: !!param.id,
    onSuccess: () => {
      if (onSuccess) onSuccess();
      Store.dispatch(DialogActions.pop());
    },
    onError: () => {
      if (onError)onError();
    },
  });
}

function* tryTaxGetList(action:
  ReturnType<typeof MasterActions.api.tax.getList>) {
  yield getListHandle<ApiMaster.Tax.Response.List>({
    api: new ApiMasterTaxGetList(action.payload),
    noLoad: true,
    * onSuccess(result) {
      yield put(MasterActions.setTaxList(result));
    },
  });
}

function* tryTaxSoonGet(action:
  ReturnType<typeof MasterActions.api.tax.getSoon>) {
  const { param, onSuccess, onError } = action.payload;
  if (param.start_date) {
    yield getHandle<ApiMasterTaxGetListResponse>({
      api: new ApiMasterGetTaxSoon(param),
      * onSuccess(result) {
        yield put(MasterActions.setTaxSoon(result));
        if (result && onSuccess) onSuccess(result);
      },
      * onError() {
        yield put(MasterActions.setTaxSoon(MasterCollection.initTax));
        if (onSuccess) {
          onSuccess(MasterCollection.initTax);
        }
      },
    });
  }
}

function* tryTaxPost(action: ReturnType<typeof MasterActions.api.tax.post>) {
  const { param, onSuccess, onError } = action.payload;
  yield postHandle({
    title: '消費税情報 ',
    api: new ApiMasterTaxPost(param),
    update: !!param.id,
    onSuccess: () => {
      if (onSuccess) onSuccess();
      Store.dispatch(DialogActions.pop());
    },
    onError: () => {
      if (onError)onError();
    },
  });
}

function* tryLargeCategoryGetList(action:
  ReturnType<typeof MasterActions.api.largeCategory.getList>) {
  yield getListHandle<ApiMaster.LargeCategory.Response.List>({
    noLoad: true,
    api: new ApiMasterLargeCategoryGetList(action.payload),
    * onSuccess(result) {
      yield put(MasterActions.setLargeCategoryList(result));
    },
  });
}

function* tryLargeCategoryPost(action: ReturnType<typeof MasterActions.api.largeCategory.post>) {
  const { param, onSuccess, onError } = action.payload;
  yield postHandle({
    title: '大分類情報 ',
    api: new ApiMasterLargeCategoryPost(param),
    update: !!param.id,
    onSuccess: () => {
      if (onSuccess) onSuccess();
      Store.dispatch(DialogActions.pop());
    },
    onError: () => {
      if (onError)onError();
    },
  });
}

function* tryMiddleCategoryGetList(action:
  ReturnType<typeof MasterActions.api.middleCategory.getList>) {
  yield getListHandle<ApiMaster.MiddleCategory.Response.List>({
    api: new ApiMasterMiddleCategoryGetList(action.payload),
    noLoad: true,
    * onSuccess(result) {
      yield put(MasterActions.setMiddleCategoryList(result));
    },
  });
}

function* tryMiddleCategoryPost(action: ReturnType<typeof MasterActions.api.middleCategory.post>) {
  const { param, onSuccess, onError } = action.payload;
  yield postHandle({
    title: '中分類情報 ',
    api: new ApiMasterMiddleCategoryPost(param),
    update: !!param.id,
    onSuccess: () => {
      if (onSuccess) onSuccess();
      Store.dispatch(DialogActions.pop());
    },
    onError: () => {
      if (onError)onError();
    },
  });
}

function* tryMeisaiGetList(action: ReturnType<typeof MasterActions.api.meisai.getList>) {
  yield getListHandle<ApiMaster.Meisai.Response.List>({
    api: new ApiMasterMeisaiGetList(action.payload),
    noLoad: true,
    * onSuccess(result) {
      yield put(MasterActions.setMeisaiList(result));
    },
  });
}
function* tryMeisaiGetCallbackList(action:
  ReturnType<typeof MasterActions.api.meisai.getCallbackList>) {
  yield getListHandle<ApiMaster.Meisai.Response.List>({
    api: new ApiMasterMeisaiGetList(action.payload.param),
    noLoad: true,
    * onSuccess(result) {
      yield action.payload.callback(result);
    },
  });
}

function* tryMeisaiPost(action: ReturnType<typeof MasterActions.api.meisai.post>) {
  const { param, onSuccess, onError } = action.payload;
  yield postHandle({
    title: '明細情報 ',
    api: new ApiMasterMeisaiPost(param),
    update: !!param.id,
    onSuccess: () => {
      if (onSuccess) onSuccess();
      Store.dispatch(DialogActions.pop());
    },
    onError: () => {
      if (onError)onError();
    },
  });
}

function* tryUnitGetList(action: ReturnType<typeof MasterActions.api.unit.getList>) {
  yield getListHandle<ApiMaster.Unit.Response.List>({
    api: new ApiMasterUnitGetList(action.payload),
    noLoad: true,
    * onSuccess(result) {
      yield put(MasterActions.setUnitList(result));
    },
  });
}

function* tryUnitPost(action: ReturnType<typeof MasterActions.api.unit.post>) {
  const { param, onSuccess, onError } = action.payload;
  yield postHandle({
    title: '単位情報 ',
    update: !!param.id,
    api: new ApiMasterUnitPost(param),
    onSuccess: () => {
      if (onSuccess) onSuccess();
      Store.dispatch(DialogActions.pop());
    },
    onError: () => {
      if (onError)onError();
    },
  });
}

function* tryOriginGetList(action: ReturnType<typeof MasterActions.api.origin.getList>) {
  yield getListHandle<ApiMaster.Origin.Response.List>({
    noLoad: true,
    api: new ApiMasterOriginGetList(action.payload),
    * onSuccess(result) {
      yield put(MasterActions.setOriginList(
        result,
      ));
    },
  });
}

function* tryOriginPost(action: ReturnType<typeof MasterActions.api.origin.post>) {
  const { param, onSuccess, onError } = action.payload;
  yield postHandle({
    title: '発生源情報 ',
    api: new ApiMasterOriginPost(param),
    update: !!param.id,
    onSuccess: () => {
      if (onSuccess) onSuccess();
      Store.dispatch(DialogActions.pop());
    },
    onError: () => {
      if (onError)onError();
    },
  });
}

function* tryBuildingCategoryGetList(action:
  ReturnType<typeof MasterActions.api.buildingCategory.getList>) {
  yield getListHandle<ApiMaster.BuildingCategory.Response.List>({
    api: new ApiMasterBuildingCategoryGetList(action.payload),
    noLoad: true,
    * onSuccess(result) {
      yield put(MasterActions.setBuildingCategoryList(
        result,
      ));
    },
  });
}

function* tryBuildingCategoryPost(
  action: ReturnType<typeof MasterActions.api.buildingCategory.post>,
) {
  const { param, onSuccess, onError } = action.payload;
  yield postHandle({
    title: '建物分類情報 ',
    api: new ApiMasterBuildingCategoryPost(param),
    update: !!param.id,
    onSuccess: () => {
      if (onSuccess) onSuccess();
      Store.dispatch(DialogActions.pop());
    },
    onError: () => {
      if (onError)onError();
    },
  });
}

function* tryAreaGetList(action: ReturnType<typeof MasterActions.api.area.getList>) {
  yield getListHandle<ApiMaster.Area.Response.List>({
    api: new ApiMasterAreaGetList(action.payload),
    noLoad: true,
    * onSuccess(result) {
      yield put(MasterActions.setAreaList(
        result,
      ));
    },
  });
}

function* tryAreaPost(action: ReturnType<typeof MasterActions.api.area.post>) {
  const { param, onSuccess, onError } = action.payload;
  yield postHandle({
    title: 'エリア情報 ',
    api: new ApiMasterAreaPost(param),
    update: !!param.id,
    onSuccess: () => {
      if (onSuccess) onSuccess();
      Store.dispatch(DialogActions.pop());
    },
    onError: () => {
      if (onError)onError();
    },
  });
}

function* tryMadoriGetList(action: ReturnType<typeof MasterActions.api.madori.getList>) {
  yield getListHandle<ApiMaster.Madori.Response.List>({
    api: new ApiMasterMadoriGetList(action.payload),
    noLoad: true,
    * onSuccess(result) {
      yield put(MasterActions.setMadoriList(
        result,
      ));
    },
  });
}

function* tryMadoriPost(action: ReturnType<typeof MasterActions.api.madori.post>) {
  const { param, onSuccess, onError } = action.payload;
  yield postHandle({
    title: '間取り情報 ',
    api: new ApiMasterMadoriPost(param),
    update: !!param.id,
    onSuccess: () => {
      if (onSuccess) onSuccess();
      Store.dispatch(DialogActions.pop());
    },
    onError: () => {
      if (onError)onError();
    },
  });
}

function* tryInquiryGetList(action: ReturnType<typeof MasterActions.api.inquiry.getList>) {
  yield getListHandle<ApiMaster.Inquiry.Response.List>({
    api: new ApiMasterInquiryGetList(action.payload),
    noLoad: true,
    * onSuccess(result) {
      yield put(MasterActions.setInquiryList(result));
    },
  });
}

function* tryInquiryPost(action: ReturnType<typeof MasterActions.api.inquiry.post>) {
  const { param, onSuccess, onError } = action.payload;
  yield postHandle({
    title: '問合せ情報 ',
    api: new ApiMasterInquiryPost(param),
    update: !!param.id,
    onSuccess: () => {
      if (onSuccess) onSuccess();
      Store.dispatch(DialogActions.pop());
    },
    onError: () => {
      if (onError)onError();
    },
  });
}

function* tryLostOrderGetList(action:
  ReturnType<typeof MasterActions.api.lostOrder.getList>) {
  yield getListHandle<ApiMaster.LostOrder.Response.List>({
    api: new ApiMasterLostOrderGetList(action.payload),
    noLoad: true,
    * onSuccess(result) {
      yield put(MasterActions.setLostOrderList(
        result,
      ));
    },
  });
}

function* tryLostOrderPost(action: ReturnType<typeof MasterActions.api.lostOrder.post>) {
  const { param, onSuccess, onError } = action.payload;
  yield postHandle({
    title: '失注理由情報 ',
    api: new ApiMasterLostOrderPost(param),
    update: !!param.id,
    onSuccess: () => {
      if (onSuccess) onSuccess();
      Store.dispatch(DialogActions.pop());
    },
    onError: () => {
      if (onError)onError();
    },
  });
}

function* trySupportHistoryGetList(action:
  ReturnType<typeof MasterActions.api.supportHistory.getList>) {
  yield getListHandle<ApiMaster.SupportHistory.Response.List>({
    api: new ApiMasterSupportHistoryGetList(action.payload),
    noLoad: true,
    * onSuccess(result) {
      yield put(MasterActions.setSupportHistoryList(result));
    },
  });
}
function* trySupportHistoryCallbackGetList(action:
  ReturnType<typeof MasterActions.api.supportHistory.getListCallback>) {
  const { param, onSuccess } = action.payload;
  yield getListHandle<ApiMaster.SupportHistory.Response.List>({
    api: new ApiMasterSupportHistoryGetList(param),
    noLoad: true,
    * onSuccess(result) {
      yield onSuccess?.(result);
    },
  });
}

function* trySupportHistoryPost(action: ReturnType<typeof MasterActions.api.supportHistory.post>) {
  const { param, onSuccess, onError } = action.payload;
  yield postHandle({
    title: '対応履歴情報 ',
    api: new ApiMasterSupportHistoryPost(param),
    update: !!param.id,
    onSuccess: () => {
      if (onSuccess) onSuccess();
      Store.dispatch(DialogActions.pop());
    },
    onError: () => {
      if (onError)onError();
    },
  });
}

function* tryAfterMaintenanceGetList(action:
  ReturnType<typeof MasterActions.api.afterMaintenance.getList>) {
  yield getListHandle<ApiMaster.AfterMaintenance.Response.List>({
    api: new ApiMasterAfterMaintenanceGetList(action.payload),
    noLoad: true,
    * onSuccess(result) {
      yield put(MasterActions.setAfterMaintenanceList(result));
    },
  });
}

function* tryAfterMaintenancePost(
  action: ReturnType<typeof MasterActions.api.afterMaintenance.post>,
) {
  const { param, onSuccess, onError } = action.payload;
  yield postHandle({
    title: 'アフターメンテナンス情報 ',
    api: new ApiMasterAfterMaintenancePost(param),
    update: !!param.id,
    onSuccess: () => {
      if (onSuccess) onSuccess();
      Store.dispatch(DialogActions.pop());
    },
    onError: () => {
      if (onError)onError();
    },
  });
}

function* trySignatureGetList(action: ReturnType<typeof MasterActions.api.signature.getList>) {
  yield getListHandle<ApiMaster.Signature.Response.List>({
    api: new ApiMasterSignatureGetList(action.payload),
    noLoad: true,
    * onSuccess(result) {
      yield put(MasterActions.setSignatureList(result));
    },
  });
}

function* trySignaturePost(action: ReturnType<typeof MasterActions.api.signature.post>) {
  yield postTemp(new ApiMasterSignaturePost(action.payload.param), action.payload.callback);
}

function* tryQuoteFixedGetList(action:
  ReturnType<typeof MasterActions.api.quoteFixed.getList>) {
  yield getListHandle<ApiMaster.QuoteFixed.Response.List>({
    api: new ApiMasterQuoteFixedGetList(action.payload.param),
    noLoad: true,
    * onSuccess(result) {
      yield put(MasterActions.setQuoteFixedList(result));
      action.payload.callback(result[0]);
    },
  });
}

function* tryQuoteFixedPost(action: ReturnType<typeof MasterActions.api.quoteFixed.post>) {
  const { param, callback } = action.payload;
  yield postHandle({
    title: '見積定型情報 ',
    api: new ApiMasterQuoteFixedPost(param),
    update: true,
    onSuccess: () => {
      if (callback) callback();
      Store.dispatch(DialogActions.pop());
    },
  });
}

function* tryCustomerKojiRankGetList(action:
ReturnType<typeof MasterActions.api.customerKojiRank.getList>) {
  yield getListHandle<any>({
    api: new ApiMasterCustomerKojiRankGetList(action.payload),
    noLoad: true,
    * onSuccess(result) {
      yield put(MasterActions.setCustomerKojiRankList(result));
    },
  });
}

function* tryCustomerKojiRankPost(
  action: ReturnType<typeof MasterActions.api.customerKojiRank.post>,
) {
  const { param, onSuccess, onError } = action.payload;
  yield postHandle({
    title: '顧客ランク(最終完工日)情報 ',
    api: new ApiMasterCustomerRankPost(param),
    update: !!param.id,
    onSuccess: () => {
      if (onSuccess) onSuccess();
      Store.dispatch(DialogActions.pop());
    },
    onError: () => {
      if (onError)onError();
    },
  });
}

function* tryCustomerRankGetList(action:
  ReturnType<typeof MasterActions.api.customerRank.getList>) {
  const { callback } = action.payload;
  yield getListHandle<any>({
    api: new ApiMasterCustomerRankGetList(),
    noLoad: true,
    * onSuccess(result) {
      yield put(MasterActions.setCustomerRankList(result));
      if (callback) callback(result);
    },
  });
}

function* tryCustomerRankLastGetList(action:
  ReturnType<typeof MasterActions.api.customerRankLast.getList>) {
  yield getListHandle<any>({
    api: new ApiMasterCustomerRankLastGetList(action.payload),
    noLoad: true,
    * onSuccess(result) {
      yield put(MasterActions.setCustomerRankLastList(result));
    },
  });
}

function* tryCustomerRankLastPost(
  action: ReturnType<typeof MasterActions.api.customerRankLast.post>,
) {
  const { param, onSuccess, onError } = action.payload;
  yield postHandle({
    title: '顧客ランク(最終完工日)情報 ',
    api: new ApiMasterCustomerRankLastPost(param),
    update: !!param.id,
    onSuccess: () => {
      if (onSuccess) onSuccess();
      Store.dispatch(DialogActions.pop());
    },
    onError: () => {
      if (onError)onError();
    },
  });
}

function* tryProjectRankGetList(action:
ReturnType<typeof MasterActions.api.projectRank.getList>) {
  yield getListHandle<any>({
    api: new ApiMasterProjectRankGetList(action.payload),
    noLoad: true,
    * onSuccess(result) {
      yield put(MasterActions.setProjectRankList(result));
    },
  });
}

function* tryProjectRankLastPost(
  action: ReturnType<typeof MasterActions.api.projectRank.post>,
) {
  const { param, onSuccess, onError } = action.payload;
  yield postHandle({
    title: '案件ランク情報 ',
    api: new ApiMasterProjectRankPost(param),
    update: !!param.id,
    onSuccess: () => {
      if (onSuccess) onSuccess();
      Store.dispatch(DialogActions.pop());
    },
    onError: () => {
      if (onError)onError();
    },
  });
}

function* tryCustomerExpectedRankGetList(action:
ReturnType<typeof MasterActions.api.customerExpectedRank.getList>) {
  yield getListHandle<any>({
    api: new ApiMasterCustomerExpectedRankGetList(action.payload),
    noLoad: true,
    * onSuccess(result) {
      yield put(MasterActions.setCustomerExpectedRankList(
        result,
      ));
    },
  });
}

function* tryCustomerExpectedRankPost(
  action: ReturnType<typeof MasterActions.api.customerExpectedRank.post>,
) {
  const { param, onSuccess, onError } = action.payload;
  yield postHandle({
    title: '案件ランク情報 ',
    api: new ApiMasterCustomerExpectedRankPost(param),
    update: !!param.id,
    onSuccess: () => {
      if (onSuccess) onSuccess();
      Store.dispatch(DialogActions.pop());
    },
    onError: () => {
      if (onError)onError();
    },
  });
}

function* tryCustomerRankUpdatePost(
  action: ReturnType<typeof MasterActions.api.customerRankUpdate.post>,
) {
  const { callback } = action.payload;
  yield postHandle({
    title: '顧客ランク',
    api: new ApiMasterCustomerRankUpdateGet(),
    update: true,
    onSuccess: () => {
      callback();
      Store.dispatch(DialogActions.pop());
    },
  });
}

function* tryCustomerInformationImport(action:
  ReturnType<typeof MasterActions.api.customerInformationImport>) {
  yield postTemp(
    new ApiMasterCustomerInformationImport(action.payload.param), action.payload.callback,
  );
}

function* tryRelevantTagGetList(action: ReturnType<typeof MasterActions.api.relevantTag.getList>) {
  yield getListHandle<ApiMaster.RelevantTag.Response.List>({
    api: new ApiMasterRelevantTagGetList(action.payload),
    noLoad: true,
    * onSuccess(result) {
      yield put(MasterActions.setRelevantTagList(result));
    },
  });
}

function* tryRelevantTagPost(action: ReturnType<typeof MasterActions.api.relevantTag.post>) {
  const { param, onSuccess, onError } = action.payload;
  yield postHandle({
    title: '関連タグ情報 ',
    api: new ApiMasterRelevantTagPost(param),
    update: !!param.id,
    onSuccess: () => {
      if (onSuccess) onSuccess();
      Store.dispatch(DialogActions.pop());
    },
    onError: () => {
      if (onError)onError();
    },
  });
}

function* tryPartGetList(action:
  ReturnType<typeof MasterActions.api.part.getList>) {
  yield getListHandle<ApiMaster.Part.Response.List>({
    api: new ApiMasterPartGetList(action.payload),
    noLoad: true,
    * onSuccess(result) {
      yield put(MasterActions.setPartList(result));
    },
  });
}

function* tryPartPost(action: ReturnType<typeof MasterActions.api.part.post>) {
  const { param, onSuccess, onError } = action.payload;
  yield postHandle({
    title: '部位情報 ',
    api: new ApiMasterPartPost(param),
    update: !!param.id,
    onSuccess: () => {
      if (onSuccess) onSuccess();
      Store.dispatch(DialogActions.pop());
    },
    onError: () => {
      if (onError)onError();
    },
  });
}

function* tryMyCarTypeGetList(action: ReturnType<typeof MasterActions.api.myCarType.getList>) {
  yield getListHandle<ApiMaster.MyCarType.Response.List>({
    api: new ApiMasterMyCarTypeGetList(action.payload),
    noLoad: true,
    * onSuccess(result) {
      yield put(MasterActions.setMyCarTypeList(result));
    },
  });
}

function* tryMyCarTypePost(action: ReturnType<typeof MasterActions.api.myCarType.post>) {
  const { param, onSuccess, onError } = action.payload;
  yield postHandle({
    title: 'マイカー種別情報 ',
    api: new ApiMasterMyCarTypePost(param),
    update: !!param.id,
    onSuccess: () => {
      if (onSuccess) onSuccess();
      Store.dispatch(DialogActions.pop());
    },
    onError: () => {
      if (onError)onError();
    },
  });
}

function* tryContractedCompanyGetList(action:
  ReturnType<typeof MasterActions.api.contractedCompany.getList>) {
  yield getListHandle<ApiMaster.ContractedCompany.Response.List>({
    api: new ApiMasterContractedCompanyGetList(action.payload.param),
    noLoad: true,
    * onSuccess(result) {
      yield put(MasterActions.setContractedCompanyList(result));
      action.payload.onSuccess?.(result);
    },
  });
}

function* tryContractedEmployeeGetList(action:
  ReturnType<typeof MasterActions.api.contractedEmployee.getList>) {
  yield getListHandle<MasterContractedEmployee>({
    api: new ApiMasterContractedEmployeeGetList(action.payload),
    noLoad: true,
    * onSuccess(result, hit) {
      yield put(MasterActions.setContractedEmployeeList(result));
      yield put(MasterActions.setContractedEmployeeHitCount(hit));
    },
  });
}

function* tryContractedEmployeePost(
  action: ReturnType<typeof MasterActions.api.contractedEmployee.post>,
) {
  const { param, onSuccess, onError } = action.payload;
  yield postHandle({
    title: '契約会社情報 ',
    api: new ApiMasterContractedEmployeePost(param),
    update: !!param.id,
    onSuccess: () => {
      if (onSuccess) onSuccess();
      Store.dispatch(DialogActions.pop());
    },
    onError: () => {
      if (onError)onError();
    },
  });
}

function* tryContractedCompanyPost(
  action: ReturnType<typeof MasterActions.api.contractedCompany.post>,
) {
  const { param, onSuccess, onError } = action.payload;
  yield postHandle({
    title: '契約会社情報 ',
    api: new ApiMasterContractedCompanyPost(param),
    update: !!param.id,
    onSuccess: () => {
      if (onSuccess) onSuccess();
      Store.dispatch(DialogActions.pop());
    },
    onError: () => {
      if (onError)onError();
    },
  });
}

function* tryProspectPartGetList(action:
  ReturnType<typeof MasterActions.api.prospectPart.getList>) {
  yield getListHandle<ApiMaster.ProspectPart.Response.List>({
    api: new ApiMasterProspectPartGetList(action.payload),
    noLoad: true,
    * onSuccess(result) {
      yield put(MasterActions.setProspectPartList(result));
    },
  });
}

function* tryProspectPartPost(action:ReturnType<typeof MasterActions.api.prospectPart.post>) {
  yield postTemp(new ApiMasterProspectPartPost(action.payload.param), action.payload.callback);
}

function* tryConstructionPartGetList(action:
  ReturnType<typeof MasterActions.api.constructionPart.getList>) {
  yield getListHandle<ApiMaster.ConstructionPart.Response.List>({
    noLoad: true,
    api: new ApiMasterConstructionPartGetList(action.payload),
    * onSuccess(result) {
      yield put(MasterActions.setConstructionPartList(result));
    },
  });
}

function* tryConstructionPartPost(
  action: ReturnType<typeof MasterActions.api.constructionPart.post>,
) {
  yield postTemp(new ApiMasterConstructionPartPost(action.payload.param), action.payload.callback);
}

function* tryTemplateDownload() {
  const api = new ApiCustomerTemplate();
  yield downloadHandle({ api, fileName: 'Customer_Import_Template.csv' });
}

function* tryImportPost(action:ReturnType<typeof MasterActions.api.customerImport.post>) {
  const {
    param: params, onSuccess, onError, onApi, token,
  } = action.payload;
  for (let i = 0; i < params.length; i += 1) {
    const api = new ApiImportPost(params[i]);
    onApi(api);
    try {
      api.header = {
        ...api.header,
        'X-CSRF-TOKEN': token,
      };

      const result: {header: ResponseHeader} = yield api.run();

      if (ApiBase.isSuccess(result)) {
        onSuccess();
      } else {
        onError(i);
      }
    } catch (e) {
      onError(i);
      yield put(SystemActions.connectionError({}));
    }
  }
}

function* tryUploadCSV(action:ReturnType<typeof MasterActions.api.customerImport.uploadCsv>) {
  yield put(SystemActions.isLoading(true));
  const { param, onSuccess } = action.payload;
  const api = new ApiImportUploadCSV(param);
  try {
    const me = new ApiUser();
    yield me.run();
    yield put(AuthActions.setToken(me.token));
    api.header = {
      ...api.header,
      'X-CSRF-TOKEN': me.token,
    };

    const result: {
      header: ResponseHeader;
      body: ImportFileUploadResult;
    } = yield api.run();

    if (ApiBase.isSuccess(result)) {
      onSuccess(cloneDeep(result.body));
    } else {
      yield put(DialogActions.pushMessage({
        title: '顧客情報インポート',
        message: result.header.messages || [],
      }));
    }
  } catch (e) {
    yield put(SystemActions.connectionError({}));
  }
  yield put(SystemActions.isLoading(false));
}

function* tryMasterSort(action: ReturnType<typeof MasterActions.api.index>) {
  const { param, onSuccess, onError } = action.payload;
  yield postHandle({
    title: '並び替え',
    api: new ApiMasterIndex(param),
    onSuccess: () => {
      if (onSuccess) onSuccess();
    },
    onError: () => {
      if (onError) onError();
    },
  });
}

export function* MasterSaga() {
  yield takeEvery(MasterActions.api.store.getList, tryStoreGetList);
  yield takeEvery(MasterActions.api.store.getLogo, tryStoreLogoGet);
  yield takeEvery(MasterActions.api.store.post, tryStorePost);
  yield takeEvery(MasterActions.api.employee.getList, tryEmployeeGetList);
  yield takeEvery(MasterActions.api.employee.post, tryEmployeePost);
  yield takeEvery(MasterActions.api.tax.getList, tryTaxGetList);
  yield takeEvery(MasterActions.api.tax.getSoon, tryTaxSoonGet);
  yield takeEvery(MasterActions.api.tax.post, tryTaxPost);
  yield takeEvery(MasterActions.api.largeCategory.getList, tryLargeCategoryGetList);
  yield takeEvery(MasterActions.api.largeCategory.post, tryLargeCategoryPost);
  yield takeEvery(MasterActions.api.middleCategory.getList, tryMiddleCategoryGetList);
  yield takeEvery(MasterActions.api.middleCategory.post, tryMiddleCategoryPost);
  yield takeEvery(MasterActions.api.meisai.getList, tryMeisaiGetList);
  yield takeEvery(MasterActions.api.meisai.getCallbackList, tryMeisaiGetCallbackList);
  yield takeEvery(MasterActions.api.meisai.post, tryMeisaiPost);
  yield takeEvery(MasterActions.api.unit.getList, tryUnitGetList);
  yield takeEvery(MasterActions.api.unit.post, tryUnitPost);
  yield takeEvery(MasterActions.api.origin.getList, tryOriginGetList);
  yield takeEvery(MasterActions.api.origin.post, tryOriginPost);
  yield takeEvery(MasterActions.api.buildingCategory.getList, tryBuildingCategoryGetList);
  yield takeEvery(MasterActions.api.buildingCategory.post, tryBuildingCategoryPost);
  yield takeEvery(MasterActions.api.area.getList, tryAreaGetList);
  yield takeEvery(MasterActions.api.area.post, tryAreaPost);
  yield takeEvery(MasterActions.api.lostOrder.getList, tryLostOrderGetList);
  yield takeEvery(MasterActions.api.lostOrder.post, tryLostOrderPost);
  yield takeEvery(MasterActions.api.madori.getList, tryMadoriGetList);
  yield takeEvery(MasterActions.api.madori.post, tryMadoriPost);
  yield takeEvery(MasterActions.api.inquiry.getList, tryInquiryGetList);
  yield takeEvery(MasterActions.api.inquiry.post, tryInquiryPost);
  yield takeEvery(MasterActions.api.supportHistory.getList, trySupportHistoryGetList);
  yield takeEvery(
    MasterActions.api.supportHistory.getListCallback,
    trySupportHistoryCallbackGetList,
  );
  yield takeEvery(MasterActions.api.supportHistory.post, trySupportHistoryPost);
  yield takeEvery(MasterActions.api.afterMaintenance.getList, tryAfterMaintenanceGetList);
  yield takeEvery(MasterActions.api.afterMaintenance.post, tryAfterMaintenancePost);
  yield takeEvery(MasterActions.api.signature.getList, trySignatureGetList);
  yield takeEvery(MasterActions.api.signature.post, trySignaturePost);
  yield takeEvery(MasterActions.api.quoteFixed.getList, tryQuoteFixedGetList);
  yield takeEvery(MasterActions.api.quoteFixed.post, tryQuoteFixedPost);
  yield takeEvery(MasterActions.api.customerRank.getList, tryCustomerRankGetList);
  yield takeEvery(MasterActions.api.customerKojiRank.getList, tryCustomerKojiRankGetList);
  yield takeEvery(MasterActions.api.customerKojiRank.post, tryCustomerKojiRankPost);
  yield takeEvery(MasterActions.api.customerRankUpdate.post, tryCustomerRankUpdatePost);
  yield takeEvery(MasterActions.api.projectRank.getList, tryProjectRankGetList);
  yield takeEvery(MasterActions.api.projectRank.post, tryProjectRankLastPost);
  yield takeEvery(MasterActions.api.customerExpectedRank.getList, tryCustomerExpectedRankGetList);
  yield takeEvery(MasterActions.api.customerExpectedRank.post, tryCustomerExpectedRankPost);
  yield takeEvery(MasterActions.api.customerRankLast.getList, tryCustomerRankLastGetList);
  yield takeEvery(MasterActions.api.customerRankLast.post, tryCustomerRankLastPost);
  yield takeEvery(MasterActions.api.customerInformationImport, tryCustomerInformationImport);
  yield takeEvery(MasterActions.api.relevantTag.getList, tryRelevantTagGetList);
  yield takeEvery(MasterActions.api.relevantTag.post, tryRelevantTagPost);
  yield takeEvery(MasterActions.api.myCarType.getList, tryMyCarTypeGetList);
  yield takeEvery(MasterActions.api.myCarType.post, tryMyCarTypePost);
  yield takeEvery(MasterActions.api.contractedCompany.getList, tryContractedCompanyGetList);
  yield takeEvery(MasterActions.api.contractedEmployee.getList, tryContractedEmployeeGetList);
  yield takeEvery(MasterActions.api.contractedEmployee.post, tryContractedEmployeePost);
  yield takeEvery(MasterActions.api.contractedCompany.post, tryContractedCompanyPost);
  yield takeEvery(MasterActions.api.customerImport.getTemplate, tryTemplateDownload);
  yield takeEvery(MasterActions.api.customerImport.uploadCsv, tryUploadCSV);
  yield takeEvery(MasterActions.api.customerImport.post, tryImportPost);
  yield takeEvery(MasterActions.api.prospectPart.getList, tryProspectPartGetList);
  yield takeEvery(MasterActions.api.prospectPart.post, tryProspectPartPost);
  yield takeEvery(MasterActions.api.part.getList, tryPartGetList);
  yield takeEvery(MasterActions.api.part.post, tryPartPost);
  yield takeEvery(MasterActions.api.constructionPart.getList, tryConstructionPartGetList);
  yield takeEvery(MasterActions.api.constructionPart.post, tryConstructionPartPost);
  yield takeEvery(MasterActions.api.index, tryMasterSort);
}
