import {
  useCallback, useState, memo, useEffect,
} from 'react';
import './estimate-detail-search-second-card.scss';
import { MeisaiListXML } from '../../../../model/estimate/estimate-meisai.type';
import { MathHelper } from '../../../../utilities/math-helper';

type Props = {
  className?: string,
  data: MeisaiListXML,
  callback: (v: MeisaiListXML) => void;
  isAllCheck: boolean[];
}

export const EstimateDetailSearchSecondCard = memo((props: Props) => {
  const {
    data, className, callback, isAllCheck,
  } = props;

  /* State */
  const [isSelect, setIsSelect] = useState(true);

  const handleClickList = useCallback((v:MeisaiListXML) => {
    setIsSelect(!isSelect);
    callback(v);
  }, [callback, isSelect]);

  useEffect(() => {
    setIsSelect(isAllCheck[0]);
  }, [isAllCheck]);

  return (
    <div
      className={`estimate_detail_secondary_card card_base ${className || ''} ${isSelect ? 'is_select' : ''}`}
      onClick={() => handleClickList(data)}
    >
      <div className="estimate_detail_secondary_card__row1">{data.$.name}</div>
      <div className="estimate_detail_secondary_card__row2">
        <div className="estimate_detail_secondary_card__row2__col1">
          &yen;&nbsp;
          {MathHelper.rounding2Str(data.$.shikiri_kakaku ? Number(data.$.shikiri_kakaku) : 0, 0, 'round', true)}
            &nbsp;×&nbsp;
          {MathHelper.rounding2Str(data.$.suryou ? Number(data.$.suryou) : 0, 2, 'round', true)}
          {data.$.tani_name}
        </div>
        <div className="estimate_detail_secondary_card__row2__col2">
          &yen;&nbsp;
          {MathHelper.rounding2Str(data.$.kingaku
            ? MathHelper.times(Number(data.$.kingaku), Number(data.$.suryou)) : 0, 0, 'round', true)}
        </div>
      </div>
    </div>
  );
});
