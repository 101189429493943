import actionCreatorFactory from 'typescript-fsa';
import { ApiBase } from '../../service/api-base';
import { RequestBaseCollection } from '../../service/api.collection';
import { CallbackRequest, RequestBaseParam } from '../../type/api.type';
import { ApiContractedChangeCompanyPostParam } from './api-contracted-changecompany.type';

export const ActionCreator = actionCreatorFactory('master/api/contracted-changecompany');

export const apiContractedChangeCompany = {
  post: ActionCreator<CallbackRequest<ApiContractedChangeCompanyPostParam> & {ignorePop?: boolean}>('post'),
};

export class ApiContractedChangeCompany<T = any> extends ApiBase<T> {
  constructor(param: RequestBaseParam) {
    super({
      ...RequestBaseCollection(),
      ...param,
      url: '/api/master/contract/chgcompany',
    });
  }
}

export class ApiContractedChangeCompanyPost extends ApiContractedChangeCompany {
  constructor(param: ApiContractedChangeCompanyPostParam) {
    super({
      httpMethod: 'POST',
      param,
    });
  }
}
