import { reducerWithInitialState } from 'typescript-fsa-reducers';
import * as lodash from 'lodash';
import { cloneDeep } from 'lodash';
import { CsvActions } from './csv.action';
import {
  CsvBirthdaySort,
  CsvCustomerRankSort,
  CsvCustomerSort,
  CsvLostOrderSort,
  CsvMaintenanceSort,
  CsvNonOrderSort,
  CsvOrderSort,
  CsvProjectSort,
  CsvSupportHistorySort,
  CsvWeddingSort,
} from '../../type/csv/csv-sort.type';
import {
  CsvCustomerListType,
  CsvBirthdayListType,
  CsvWeddingAnniversaryListType,
  CsvProjectListType,
  CsvLostOrderType,
  CsvNotOrderType,
  CsvOrderListType,
  CsvMaintenanceType,
  CsvSupportHistoryType,
  CsvCustomerRankType,
} from '../../type/csv/csv.type';
import { ExportCsvSortCollection } from '../../collection/export-csv/export-csv-sort.collection';

const sort = ExportCsvSortCollection;

export type CsvState = {
  customerSort: CsvCustomerSort;
  customerCount: number;
  customerList: CsvCustomerListType[] | null;
  birthdaySort: CsvBirthdaySort;
  birthdayCount: number;
  birthdayList: CsvBirthdayListType[] | null;
  weddingSort: CsvWeddingSort;
  weddingCount: number;
  weddingList: CsvWeddingAnniversaryListType[] | null;
  projectSort: CsvProjectSort;
  projectCount: number;
  projectList: CsvProjectListType[] | null;
  orderSort: CsvOrderSort;
  orderCount: number;
  orderList: CsvOrderListType[] | null;
  nonOrderSort: CsvNonOrderSort;
  nonOrderCount: number;
  nonOrderList: CsvNotOrderType[] | null;
  lostOrderSort: CsvLostOrderSort;
  lostOrderCount: number;
  lostOrderList: CsvLostOrderType[] | null;
  maintenanceSort: CsvMaintenanceSort;
  maintenanceCount: number;
  maintenanceList: CsvMaintenanceType[] | null;
  customerRankSort: CsvCustomerRankSort;
  customerRankCount: number;
  customerRankList: CsvCustomerRankType[] | null;
  supportHistorySort: CsvSupportHistorySort;
  supportHistoryCount: number;
  supportHistoryList: CsvSupportHistoryType[] | null;
  pageType: number;
};

const initialState: CsvState = {
  customerSort: sort.customer(),
  customerCount: 0,
  customerList: null,
  birthdaySort: sort.birthday(),
  birthdayCount: 0,
  birthdayList: null,
  weddingSort: sort.wedding(),
  weddingCount: 0,
  weddingList: null,
  projectSort: sort.project(),
  projectCount: 0,
  projectList: null,
  orderSort: sort.order(),
  orderCount: 0,
  orderList: null,
  nonOrderSort: sort.nonOrder(),
  nonOrderCount: 0,
  nonOrderList: null,
  lostOrderSort: sort.lostOrder(),
  lostOrderCount: 0,
  lostOrderList: null,
  maintenanceSort: sort.maintenance(),
  maintenanceCount: 0,
  maintenanceList: null,
  customerRankSort: sort.customerRank(),
  customerRankCount: 0,
  customerRankList: null,
  supportHistorySort: sort.supportHistory(),
  supportHistoryCount: 0,
  supportHistoryList: null,
  pageType: 0,
};

export const CsvReducer = reducerWithInitialState<CsvState>(
  initialState,
)
  .case(CsvActions.setCustomerList, (state, payload) => ({
    ...state,
    customerList: lodash.cloneDeep(payload),
  }))
  .case(CsvActions.setBirthdayList, (state, payload) => ({
    ...state,
    birthdayList: lodash.cloneDeep(payload),
  }))
  .case(CsvActions.setWeddingList, (state, payload) => ({
    ...state,
    weddingList: lodash.cloneDeep(payload),
  }))
  .case(CsvActions.setProjectList, (state, payload) => ({
    ...state,
    projectList: lodash.cloneDeep(payload),
  }))
  .case(CsvActions.setLostOrderList, (state, payload) => ({
    ...state,
    lostOrderList: lodash.cloneDeep(payload),
  }))
  .case(CsvActions.setNonOrderList, (state, payload) => ({
    ...state,
    nonOrderList: lodash.cloneDeep(payload),
  }))
  .case(CsvActions.setOrderList, (state, payload) => ({
    ...state,
    orderList: lodash.cloneDeep(payload),
  }))
  .case(CsvActions.setMaintenanceList, (state, payload) => ({
    ...state,
    maintenanceList: lodash.cloneDeep(payload),
  }))
  .case(CsvActions.setSupportHistoryList, (state, payload) => ({
    ...state,
    supportHistoryList: lodash.cloneDeep(payload),
  }))
  .case(CsvActions.setCustomerRankList, (state, payload) => ({
    ...state,
    customerRankList: lodash.cloneDeep(payload),
  }))
  .case(CsvActions.setCustomerSort, (state, payload) => ({
    ...state,
    customerSort: payload
      ? lodash.cloneDeep({
        ...state.customerSort,
        ...payload,
      })
      : sort.customer(),
  }))
  .case(CsvActions.setBirthdaySort, (state, payload) => ({
    ...state,
    birthdaySort: payload
      ? lodash.cloneDeep({
        ...state.birthdaySort,
        ...payload,
      })
      : sort.birthday(),
  }))
  .case(CsvActions.setWeddingSort, (state, payload) => ({
    ...state,
    weddingSort: payload
      ? lodash.cloneDeep({
        ...state.weddingSort,
        ...payload,
      })
      : sort.wedding(),
  }))
  .case(CsvActions.setProjectSort, (state, payload) => ({
    ...state,
    projectSort: payload
      ? lodash.cloneDeep({
        ...state.projectSort,
        ...payload,
      })
      : sort.project(),
  }))
  .case(CsvActions.setLostOrderSort, (state, payload) => ({
    ...state,
    lostOrderSort: payload
      ? lodash.cloneDeep({
        ...state.lostOrderSort,
        ...payload,
      })
      : sort.lostOrder(),
  }))
  .case(CsvActions.setNonOrderSort, (state, payload) => ({
    ...state,
    nonOrderSort: payload
      ? lodash.cloneDeep({
        ...state.nonOrderSort,
        ...payload,
      })
      : sort.nonOrder(),
  }))
  .case(CsvActions.setOrderSort, (state, payload) => ({
    ...state,
    orderSort: payload
      ? lodash.cloneDeep({
        ...state.orderSort,
        ...payload,
      })
      : sort.order(),
  }))
  .case(CsvActions.setMaintenanceSort, (state, payload) => ({
    ...state,
    maintenanceSort: payload
      ? lodash.cloneDeep({
        ...state.maintenanceSort,
        ...payload,
      })
      : sort.maintenance(),
  }))
  .case(CsvActions.setSupportHistorySort, (state, payload) => ({
    ...state,
    supportHistorySort: payload
      ? lodash.cloneDeep({
        ...state.supportHistorySort,
        ...payload,
      })
      : sort.supportHistory(),
  }))
  .case(CsvActions.setCustomerRankSort, (state, payload) => ({
    ...state,
    customerRankSort: payload
      ? lodash.cloneDeep({
        ...state.customerRankSort,
        ...payload,
      })
      : sort.customerRank(),
  }))
  .case(CsvActions.setPageType, (state, payload) => ({
    ...state,
    pageType: cloneDeep(payload),
  }))
  .case(CsvActions.resetState, (state) => ({
    ...initialState,
    pageType: cloneDeep(state.pageType),
  }))
  .case(CsvActions.setCustomerCount, (state, payload) => ({
    ...state,
    customerCount: payload,
  }))
  .case(CsvActions.setBirthdayCount, (state, payload) => ({
    ...state,
    birthdayCount: payload,
  }))
  .case(CsvActions.setWeddingCount, (state, payload) => ({
    ...state,
    weddingCount: payload,
  }))
  .case(CsvActions.setProjectCount, (state, payload) => ({
    ...state,
    projectCount: payload,
  }))
  .case(CsvActions.setOrderCount, (state, payload) => ({
    ...state,
    orderCount: payload,
  }))
  .case(CsvActions.setNonOrderCount, (state, payload) => ({
    ...state,
    nonOrderCount: payload,
  }))
  .case(CsvActions.setLostOrderCount, (state, payload) => ({
    ...state,
    lostOrderCount: payload,
  }))
  .case(CsvActions.setMaintenanceCount, (state, payload) => ({
    ...state,
    maintenanceCount: payload,
  }))
  .case(CsvActions.setCustomerRankCount, (state, payload) => ({
    ...state,
    customerRankCount: payload,
  }))
  .case(CsvActions.setSupportHistoryCount, (state, payload) => ({
    ...state,
    supportHistoryCount: payload,
  }))
  .case(CsvActions.resetPageType, () => initialState)
  .default((state) => state);
