/* eslint-disable no-irregular-whitespace */
import {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import CopyToClipboard from 'react-copy-to-clipboard';
import { CustomerListType } from '../../../../../type/customer/customer.type';
import { IconButton } from '../../../button/icon-button/icon-button';
import { Button } from '../../../button/button';
import { noPinch } from '../../../../../utilities/no-pinch';
import { MapActions } from '../../../../../redux/map/map.action';
import { RectLabel } from '../../../label/rect-label/rect-label';
import { ShowTypeLabel } from '../../../label/show-type/show-type-label';
import obIcon from '../../../../../asset/images/icon_customer_ob.svg';
import mikomiIcon from '../../../../../asset/images/icon_customer_uncontracted.svg';
import { joinStr } from '../../../../../utilities/join-str';
import { DialogActions } from '../../../../../redux/dialog/dialog.action';
import { SupportHistoryEditDialogTitle } from '../../../../sp/pages/support-history/edit/support-history-edit.type';
import { SupportHistoryEditSP } from '../../../../sp/pages/support-history/edit/support-history-edit.sp';
import { RoutingPath } from '../../../../../routes/routing-pass';
import { CustomerEditDialogTitle } from '../../../../sp/pages/customer/edit/customer-edit.type';
import { CustomerEditSP } from '../../../../sp/pages/customer/edit/customer-edit.sp';
import { openLink } from '../../../../../utilities/open-link';
import { StreetViewImg } from '../../../street-view-img/street-view-img';
import { RouteDialog } from '../../../../dialogs/route-dialog/route-dialog';
import { useAppSelector } from '../../../../../hooks/use-redux';
import { useMailContact, useTelContact, useSNSContact } from '../../../../../hooks/support-history/use-support-history';
import { useCustomerView } from '../../../../../hooks/use-authority';
import { isMobilePhone } from '../../../../../utilities/check-mobile-phone';

type CustomerInfoWindowProps = {
  customer: CustomerListType;
  callBack: () => void;
  callbackRegist?: (v: CustomerListType) => void;
  index: number;
  label?: string;
};

export const CustomerInfoWindowSP = (props: CustomerInfoWindowProps) => {
  const {
    customer, callBack, callbackRegist, index, label,
  } = props;
  const [detailFlag, setDetailFlag] = useState(false);
  const customerEstimatedRankList = useAppSelector((v) => v.master.customerEstimatedRankList);
  const routeAuth = useAppSelector((v) => v.user.company_authority3);

  const ele = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const telContact = useTelContact();
  const mailContact = useMailContact();
  const contactSNS = useSNSContact();
  const streetViewAuth = useCustomerView();

  const handleClickArrow = useCallback(() => {
    if (detailFlag) {
      setDetailFlag(false);
      // callBack();
    } else {
      setDetailFlag(true);
    }
  }, [detailFlag]);

  const handleClickTel = useCallback(() => {
    telContact({
      type: 'customer',
      data: customer,
      isPushDialog: true,
    });
  }, [customer]);

  const handleClickSMS = useCallback(() => {
    telContact({
      type: 'customer',
      data: customer,
      isSMS: true,
      isPushDialog: true,
    });
  }, [customer]);

  const handleClickMail = useCallback(() => {
    mailContact({
      type: 'customer',
      data: customer,
      isPushDialog: true,
      mailAddress: customer.mail_address,
    });
  }, [customer]);

  const handleClickSupportHistory = useCallback(() => {
    if (callbackRegist) {
      callbackRegist(customer);
      return;
    }
    dispatch(DialogActions.push({
      title: SupportHistoryEditDialogTitle.add,
      element: <SupportHistoryEditSP
        customer={customer}
      />,
    }));
  }, [dispatch, callbackRegist, customer]);

  const handleClickLine = useCallback(() => {
    if (!customer || !customer.line_id) return;
    contactSNS({
      mode: 'LINE',
      name: customer.name,
      customerId: customer.id,
      id: customer.line_id,
    });
  }, [customer]);

  const handleClickHere = useCallback(() => {
    if (!routeAuth) return;
    dispatch(MapActions.setGpsStatus('out'));
    dispatch(DialogActions.push({
      title: 'ルート設定',
      className: 'max_height_dialog for_route',
      element: <RouteDialog
        type="customer"
        destination={`${customer.prefecture_name}${customer.city}${customer.address}`}
        callback={() => { }}
      />,
    }));
  }, [customer, routeAuth]);

  useEffect(() => {
    const pinchCallback = noPinch(ele.current);
    return pinchCallback;
  }, [ele]);

  const rankData = useMemo(() => (
    customerEstimatedRankList.find((v) => Number(v.id) === Number(customer.estimated_rank))
  ), [customerEstimatedRankList, customer.estimated_rank]);

  return (
    <div className={`CustomerInfoWindow info_window ${callbackRegist ? 'in_dialog' : ''}`} ref={ele}>
      <>
        <div className="info_window_arrow_btn" onClick={handleClickArrow}>
          <i className={`fas fa-angle-double-${!detailFlag ? 'up' : 'down'}`} />
        </div>
        <IconButton
          title="閉じる"
          fontAwesomeClass="fas fa-times"
          className="default info_window_close_btn"
          onClick={callBack}
        />
      </>
      {detailFlag ? (
        /* ===================== 詳細表示 ===================== */
        <div className="info_window_info detail">

          <div className="info_window_info_row row1">
            <div className="row1_col1">

              <div className="important">
                {customer.name} {/* `(${customer.furigana || '---'})`*/}
              </div>

              <div>
                <div>
                  〒{joinStr(customer.post_no, 3, '-')}<br />
                  {`${customer.prefecture_name || ' '}${customer.city || ''}${customer.address || ''} ${customer.building_name || ''}`}
                </div>
              </div>

              <div className="row_table_style">

                <div className="t_row">
                  <div className="t_header"><i className="fas fa-phone" title="TEL" /></div>
                  <div className="t_body">{customer.tel_no ?? '---'}</div>
                  {/*
                  <div>
                    <IconButton
                      color="secondary"
                      size="md"
                      onClick={handleClickTel}
                      fontAwesomeClass="fa fa-phone"
                      disabled={!customer.tel_no}
                    />
                    <IconButton
                      color="secondary"
                      size="md"
                      onClick={handleClickSMS}
                      fontAwesomeClass="fas fa-sms"
                      disabled={!customer.tel_no || !isMobilePhone(customer.tel_no)}
                    />
                  </div>
                  */}
                </div>

                <div className="t_row">
                  <div className="t_header">
                    <i className="fas fa-envelope" title="メールアドレス" />
                  </div>
                  <div className="t_body e_mail">
                    <div>
                      {customer.mail_address ?? '---'}
                    </div>
                    {/*
                    <div>
                      <IconButton
                        color="secondary"
                        size="sm"
                        onClick={handleClickMail}
                        fontAwesomeClass="fas fa-envelope"
                        disabled={Boolean(!customer.mail_address)}
                      />
                    </div>
                    */}
                  </div>
                </div>

                <div className="t_row">
                  <div className="t_header"><i className="fab fa-line" title="LINE" /></div>
                  <div className="t_body">{customer.line_id ?? '---'}</div>
                </div>

                <div className="t_row">
                  <div className="t_header"><i className="fab fa-facebook-square" title="facebook" /></div>
                  <div className="t_body">{customer.facebook_id
                    ? (
                      <a href={`https://www.facebook.com/${customer.facebook_id}`} target="_blank" rel="noopener noreferrer">
                        {customer.facebook_id}
                      </a>
                    )
                    : '---'}
                  </div>
                </div>

                <div className="t_row">
                  <div className="t_header"><i className="fas fa-map-marked-alt" title="エリア" /></div>
                  <div className="t_body">{customer.area_name || '---'}</div>
                </div>

                <div className="t_row">
                  <div className="t_header"><i className="far fa-building" title="担当店舗" /></div>
                  <div className="t_body">{customer.store_name ?? '---'}</div>
                </div>

                <div className="t_row">
                  <div className="t_header"><i className="fas fa-user-circle" title="担当者" /></div>
                  <div className="t_body">{customer.employee_name ?? '---'}</div>
                </div>

              </div>
            </div>

            <div className="row1_col2">
              <div className="thumb google">
                <StreetViewImg
                  isShow={streetViewAuth(index)}
                  lat={customer.lat}
                  lng={customer.lng}
                />
              </div>

              <ShowTypeLabel
                label={`${customer.ob_flag === 1 ? 'OB' : '見込み'}`}
                showTypeImg={`${customer.ob_flag === 1 ? obIcon : mikomiIcon}`}
                className={`${customer.ob_flag === 1 ? 'ob' : 'mikomi'}`}
              />
              <RectLabel
                label={rankData?.abbreviation || 'ランクなし'}
                bgColor={rankData?.background_color}
                color={rankData?.text_color}
              />
            </div>
          </div>
          {/*
          <div className="info_window_info_row row2">
            <Button
              color="secondary"
              size="md"
              onClick={() => {
                dispatch(push(`${RoutingPath.customerDetail}/${customer.id}`));
              }}
            >顧客詳細
            </Button>

            <Button
              color="secondary"
              size="md"
              onClick={() => dispatch(DialogActions.push({
                title: CustomerEditDialogTitle.update,
                element: <CustomerEditSP id={customer.id} callback={() => {}} />,
              }))}
            >顧客編集
            </Button>
          </div>
            */}

        </div>
      ) : (
        /* ===================== 簡易表示 ===================== */
        <div className="info_window_info simple" style={callbackRegist && { paddingTop: 0 }}>
          <div className="info_window_info_row row1">
            <div className="row1_col1" style={callbackRegist && { paddingTop: '0.75rem' }}>

              <div className="important">
                {customer.name} {/* `(${customer.furigana || '---'})`*/}
              </div>

              <div>
                〒{joinStr(customer.post_no, 3, '-')}<br />
                {`${customer.prefecture_name || ' '}${customer.city || ''}${customer.address || ''} ${customer.building_name || ''}`}
              </div>

              <div className="row_table_style">
                <div className="t_row">
                  <div className="t_header"><i className="fas fa-phone" title="TEL" /></div>
                  <div className="t_body">{customer.tel_no ?? '---'}</div>
                  {/*
                  <div>
                    <IconButton
                      color="secondary"
                      size="md"
                      onClick={handleClickTel}
                      fontAwesomeClass="fa fa-phone"
                      disabled={!customer.tel_no}
                    />
                    */}
                  {/*
                    <IconButton
                      color="secondary"
                      size="md"
                      onClick={handleClickSMS}
                      fontAwesomeClass="fas fa-sms"
                      disabled={!customer.tel_no || !isMobilePhone(customer.tel_no)}
                      className="ml_10"
                    />
                    */}
                </div>

                <div className="t_row">
                  <div className="t_header"><i className="fas fa-envelope" title="メールアドレス" /></div>
                  <div className="t_body e_mail">
                    <div>
                      {customer.mail_address ?? '---'}
                    </div>
                    {/*
                    <div>
                      <IconButton
                        color="secondary"
                        size="sm"
                        onClick={handleClickMail}
                        fontAwesomeClass="fas fa-envelope"
                        disabled={Boolean(!customer.mail_address)}
                      />
                    </div>
                    */}
                  </div>
                </div>

                <div className="t_row">
                  <div className="t_header"><i className="fas fa-user-circle" title="担当者" /></div>
                  <div className="t_body">{customer.employee_name || '---'}</div>
                </div>
              </div>
            </div>

            {/* </div> */}

            <div className="row1_col2" style={callbackRegist && { paddingTop: '0.75rem' }}>
              <div className="thumb google">
                <StreetViewImg
                  isShow={streetViewAuth(index)}
                  lat={customer.lat}
                  lng={customer.lng}
                />
              </div>
              <ShowTypeLabel
                label={`${customer.ob_flag === 1 ? 'OB' : '見込み'}`}
                showTypeImg={`${customer.ob_flag === 1 ? obIcon : mikomiIcon}`}
                className={`${customer.ob_flag === 1 ? 'ob' : 'mikomi'}`}
              />

              <RectLabel
                label={rankData?.abbreviation || 'ランクなし'}
                bgColor={rankData?.background_color}
                color={rankData?.text_color}
              />
            </div>
          </div>
          {/*        !callbackRegist
            && (
            <div className="info_window_info_row row2">
              <Button
                color="secondary"
                size="md"
                onClick={() => {
                  dispatch(push(`${RoutingPath.customerDetail}/${customer.id}`));
                }}
              >顧客詳細
              </Button>

              <Button
                color="secondary"
                size="md"
                onClick={() => dispatch(DialogActions.push({
                  title: CustomerEditDialogTitle.update,
                  element: <CustomerEditSP id={customer.id} callback={() => {}} />,
                }))}
              >顧客編集
              </Button>
            </div>
            )}
              */}
        </div>
      )}
      <div className="info_window_footer scroll">
        {!callbackRegist
        && (
        <>
          <Button
            color="secondary"
            size="md"
            onClick={() => {
              dispatch(push(`${RoutingPath.customerDetail}/${customer.id}`));
            }}
          >顧客詳細
          </Button>
          <Button
            color="secondary"
            size="md"
            onClick={() => dispatch(DialogActions.push({
              title: CustomerEditDialogTitle.update,
              element: <CustomerEditSP id={customer.id} callback={() => {}} />,
            }))}
          >顧客編集
          </Button>
          <Button className="icon_btn" color="primary" size="sm" onClick={handleClickTel} disabled={!customer.tel_no}>
            <i className="fas fa-phone" />
          </Button>
          <Button className="icon_btn" color="primary" size="sm" onClick={handleClickSMS} disabled={!customer.tel_no || !isMobilePhone(customer.tel_no)}>
            <i className="fas fa-sms" />
          </Button>
          <Button className="icon_btn" color="primary" size="sm" onClick={handleClickMail} disabled={Boolean(!customer.mail_address)}>
            <i className="fas fa-envelope" />
          </Button>
          <Button color="primary" size="md" onClick={handleClickSupportHistory}>対応登録</Button>
          <CopyToClipboard text={customer.line_id || ''}>
            <Button className="icon_btn" color="primary" size="sm" onClick={handleClickLine} disabled={!customer.line_id}>
              <i className="fab fa-line" />
            </Button>
          </CopyToClipboard>
          <Button color="primary" size="md" onClick={handleClickHere} disabled={!routeAuth}>ここへ行く</Button>
        </>
        )}
        {callbackRegist
          && <Button color="primary" size="md" onClick={handleClickSupportHistory}>{label || '対応登録'}</Button>}
      </div>
    </div>
  );
};
