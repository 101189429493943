import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';
import { DialogActions } from '../redux/dialog/dialog.action';
import { DateTermValidation, TermParam } from '../model/validation/date/term-date.validation';

type Param = {
  check: (v?: string[]) => void;
  messages: string[];
}

export const useDateValidation = (props: TermParam[]): Param => {
  const dispatch = useDispatch();

  const messages = useMemo(() => DateTermValidation(cloneDeep(props)), [props]);

  const openDialog = useCallback((v?: string[]) => {
    const message = v
      ? [...v] : [];
    dispatch(DialogActions.pushMessage({
      title: 'お知らせ',
      message: [...message, '正しい日付範囲を入力してください'],
    }));
  }, []);

  return { check: openDialog, messages };
};
