import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';
import lodash, { isEqual } from 'lodash';
import { SearchBoxPC } from '../../../../layout/search-box/search-box.pc';
import { Select } from '../../../../../ui/select/select';
import { LeftIconButton } from '../../../../../ui/button/left-icon-button/left-icon-button';
import { prefectures } from '../../../../../../collection/prefectures';
import { CsvCustomerSort } from '../../../../../../type/csv/csv-sort.type';
import { CsvActions } from '../../../../../../redux/csv/csv.action';
import { State } from '../../../../../../redux/root.reducer';
import { RightLabelCheckbox } from '../../../../../ui/checkbox/right-label-checkbox/right-label-checkbox';
import { CommonCollection } from '../../../../../../collection/common/common.collection';
import { Input } from '../../../../../ui/input/input';
import { useDidMount } from '../../../../../../hooks/life-cycle';
import { MasterActions } from '../../../../../../redux/master/master.action';
import { TagActions } from '../../../../../../redux/tag/tag.action';
import { TagModel } from '../../../../../../model/tag/tag';
import { ProjectCollection } from '../../../../../../collection/project/project.collection';
import { RightLabelInputField } from '../../../../../ui/input-field/right-label-input-field/right-label-input-field';
import { Checkbox } from '../../../../../ui/checkbox/checkbox';
import { TextArea } from '../../../../../ui/text-area/text-area';
import { LeftLabelInputField } from '../../../../../ui/input-field/left-label-input-field/left-label-input-field';
import { ValidationNumberLengthUnder3 } from '../../../../../../model/validation/validation-number-length-under';
import { DatePicker } from '../../../../../ui/date-picker/date-picker';
import { DateFormatter } from '../../../../../../utilities/date-formatter';
import { InputTel } from '../../../../../ui/input/input-tel';
import { useDateValidation } from '../../../../../../hooks/use-date-validation';
import { DateTermValidation } from '../../../../../../model/validation/date/term-date.validation';
import { pulldown } from '../../../../../../utilities/pulldown';
import { useEstimateAuthority } from '../../../../../../hooks/use-authority';
import { InfoButton } from '../../../../../ui/button/info-button/info-button';
import { Message } from '../../../../../../collection/message.collection';

type Props = {
  openCallback: (v: boolean) => void;
  callback: (v: CsvCustomerSort) => void;
}

export const CsvSearchBoxCustomer = (props: Props) => {
  const { openCallback, callback } = props;

  /* Hooks */
  const dispatch = useDispatch();
  const sortState = useSelector((state: State) => state.csv.customerSort);
  const {
    storeList,
    employeeList,
    areaList,
    customerRankList,
    customerEstimatedRankList,
    buildingCategoryList,
    madoriList,
  } = useSelector((state: State) => (state.master), isEqual);
  const {
    partList,
    relevantTagList,
  } = useSelector((state: State) => (state.tag), isEqual);
  const estimateAuth = useEstimateAuthority();

  /* State */
  const [sort, setSort] = useState<CsvCustomerSort>(cloneDeep(sortState));

  const _employeeList = useMemo(() => employeeList.filter((
    v,
  ) => (Number.isNaN(sort.store_id) || sort.store_id === null
    ? true
    : (v.store_id === sort.store_id))),
  [sort.store_id, employeeList]);

  const termDate = useDateValidation([
    { start: sort.completion_date_start || null, end: sort.completion_date_end || null },
    { start: sort.last_completion_date_start || null, end: sort.last_completion_date_end || null },
    { start: sort.created_at_start || null, end: sort.created_at_end || null },
    { start: sort.updated_at_start || null, end: sort.updated_at_end || null },
  ]);

  /* Callback */
  const setState = useCallback((v: Partial<CsvCustomerSort>) => {
    setSort({
      ...cloneDeep(sort),
      ...cloneDeep(v),
    });
  }, [sort]);

  const handleClickSearch = useCallback(
    () => {
      if (termDate.messages.length) {
        termDate.check();
        return;
      }
      callback({
        ...cloneDeep(sort),
        limit: sortState.limit,
      });
      dispatch(CsvActions.setCustomerSort({
        ...cloneDeep(sort),
        limit: sortState.limit,
      }));
    }, [callback, sort, termDate, sortState],
  );

  const handleClickInputCompFlag = useCallback((id: number) => {
    if (!sort.input_comp_flag) return;
    if (id === 1) {
      sort.input_comp_flag.changeFlag(1);
      sort.input_comp_flag.data[1].flag = sort.input_comp_flag?.data[0].flag;
      sort.input_comp_flag.data[2].flag = sort.input_comp_flag?.data[0].flag;
      setState({ input_comp_flag: cloneDeep(sort.input_comp_flag) });
    } else {
      sort.input_comp_flag.changeFlag(id === 2 ? 2 : 3);
      sort.input_comp_flag.data[0].flag = (
        sort.input_comp_flag.data[1].flag && sort.input_comp_flag.data[2].flag
      );
      setState({ input_comp_flag: cloneDeep(sort.input_comp_flag) });
    }
  }, [sort, sortState]);

  /* Master */
  useDidMount(() => {
    dispatch(MasterActions.api.store.getList({}));
    dispatch(MasterActions.api.employee.getList({}));
    dispatch(MasterActions.api.area.getList({}));
    dispatch(MasterActions.api.customerExpectedRank.getList({}));
    dispatch(MasterActions.api.customerRank.getList({}));
    dispatch(MasterActions.api.buildingCategory.getList({}));
    dispatch(MasterActions.api.madori.getList({}));
    dispatch(TagActions.api.relevantTag.getList());
    dispatch(TagActions.api.part.getList());
    setState({
      ...lodash.cloneDeep(sort),
      construction_status: sort.construction_status
        || new TagModel(ProjectCollection.constructionStatusList),
      tags: sort.tags || new TagModel(relevantTagList),
      parts: sort.parts || new TagModel(partList),
    });
  });

  useEffect(() => {
    if (!sort?.tags?.data.length && relevantTagList.length) {
      setState({ tags: new TagModel(relevantTagList) });
    }
  }, [relevantTagList]);

  useEffect(() => {
    if (!sort?.parts?.data.length && partList.length) {
      setState({ parts: new TagModel(partList) });
    }
  }, [partList]);

  return (
    <>
      <SearchBoxPC
        openCallback={openCallback}
        isDetail
      >
        <div id="csv_customer">
          <div className="item_wrap">
            <div className="item_box">
              <div className="item_head">営業担当</div>
              <Select
                className="add_text_left"
                label="店舗"
                value={sort?.store_id}
                onChange={(data) => setState({ store_id: Number(data) })}
                defaultLabel="指定無し"
                options={storeList.map((v) => ({
                  text: v.name, value: v.id,
                }))}
              />
              <Select
                className="add_text_left"
                label="担当者"
                value={sort?.employee_id}
                onChange={(data) => setState({ employee_id: Number(data) })}
                defaultLabel="指定無し"
                options={_employeeList.map((v) => ({
                  text: v.name, value: v.id,
                }))}
              />
            </div>
          </div>
          <div className="item_wrap">
            <div className="item_box">
              <div className="item_head">顧客名</div>
              <Input
                className=""
                value={sort?.name}
                onChange={(e) => setState({ name: e.target.value })}
              />
            </div>
            <div className="item_box">
              <div className="item_head">顧客名フリガナ</div>
              <Input
                className=""
                value={sort?.furigana}
                onChange={(e) => setState({ furigana: e.target.value })}
              />
            </div>
            <div className="item_box">
              <div className="item_head">顧客電話番号</div>
              <InputTel
                value={sort?.tel_no}
                onChange={(v) => setState({ tel_no: v })}
              />
            </div>
            <div className="item_box">
              <div className="item_head">エリア</div>
              <Select
                className=""
                value={sort?.area_id}
                onChange={(data) => setState({ area_id: Number(data) })}
                defaultLabel="指定無し"
                options={areaList.map((v) => ({
                  text: v.name, value: v.id,
                }))}
              />
            </div>
            <div className="item_wrap">
              <div className="item_box">
                <div className="item_head">顧客ランク</div>
                <Select
                  className=""
                  value={sort?.rank}
                  onChange={(data) => setState({ rank: String(data) })}
                  defaultLabel="指定無し"
                  options={customerRankList.map((v) => ({
                    text: v.name, value: v.id,
                  }))}
                />
                <Select
                  className=""
                  value={sort?.rank_filter}
                  disabled={!sort?.rank}
                  onChange={(v) => setState({ rank_filter: Number(v) })}
                  options={[
                    { text: 'のみ', value: 1 },
                    { text: '以上', value: 2 },
                    { text: '以下', value: 3 },
                  ]}
                />
              </div>
              <div className="item_box">
                <div className="item_head">顧客見込みランク</div>
                <Select
                  className=""
                  value={sort?.estimated_rank}
                  onChange={(data) => setState({ estimated_rank: Number(data) })}
                  defaultLabel="指定無し"
                  options={customerEstimatedRankList.map((v) => ({
                    text: v.name, value: v.id,
                  }))}
                />
                <Select
                  className=""
                  value={sort?.estimated_rank_filter}
                  onChange={(v) => setState({ estimated_rank_filter: Number(v) })}
                  disabled={!sort?.estimated_rank}
                  options={[
                    { text: 'のみ', value: 1 },
                    { text: '以上', value: 2 },
                    { text: '以下', value: 3 },
                  ]}
                />
              </div>
              <div className="item_box">
                <div className="item_head">顧客分類</div>
                <Select
                  className=""
                  value={sort?.ob_flag}
                  onChange={(v) => setState({ ob_flag: Number(v) })}
                  options={[
                    { text: '全て', value: 0 },
                    { text: 'OB', value: 1 },
                    { text: '見込み', value: 2 },
                  ]}
                />
              </div>
            </div>
            <div className="item_wrap">
              <div className="item_box">
                <div className="item_head">工事状況</div>
                <div className="flex_wrap_box">
                  {sort?.construction_status?.data
                    .filter((v) => (estimateAuth ? true : v.id !== 2))
                    .map((v, i) => (
                      <div key={`rTag${i}`}>
                        <RightLabelCheckbox
                          className=""
                          key={v.id}
                          label={v.label}
                          checked={v.flag}
                          onChange={() => {
                            sort.construction_status?.changeFlag(v.id);
                            setState({
                              construction_status: lodash.cloneDeep(sort.construction_status),
                            });
                          }}
                        />
                      </div>
                    ))}
                </div>
              </div>
            </div>

            <LeftIconButton
              label="検索"
              fontAwesomeClass="fas fa-search"
              className="btn_search for_simple"
              size="sm"
              color="secondary"
              onClick={handleClickSearch}
            />
          </div>

          <div className="search_detail">
            <div className="item_wrap">
              <div className="item_box flex_grow_1">
                <div className="item_head">メールアドレス</div>
                <Input
                  className="large"
                  value={sort?.mail_address}
                  onChange={(e) => setState({ mail_address: e.target.value })}
                />
              </div>
              {/* <div className="item_box">
                <div className="item_head">緊急連絡先</div>
                <Input
                  className=""
                  type="number"
                  value={sort?.mail_address}
                  onChange={(e) => setState({ mail_address: e.target.value })}
                />
              </div> */}
            </div>

            <div className="item_wrap">
              <div className="item_box">
                <div className="item_head">住所</div>
                <LeftLabelInputField
                  labelPlace="left"
                  className="postal_code_1"
                  label="〒"
                  type="number"
                  value={sort?.post_no1}
                  onChange={(e) => { setState({ post_no1: e.target.value }); }}
                  maxLength={3}
                />
                <LeftLabelInputField
                  labelPlace="left"
                  className="postal_code_2"
                  label="-"
                  type="number"
                  value={sort?.post_no2}
                  onChange={(e) => { setState({ post_no2: e.target.value }); }}
                  maxLength={4}
                />
              </div>
              <div className="item_box">
                <Select
                  className="add_text_left"
                  label="顧客都道府県"
                  value={sort?.prefecture}
                  onChange={(v) => setState({ prefecture: Number(v) })}
                  options={[
                    { text: '全て', value: NaN },
                    ...prefectures.map((v) => ({
                      text: v.label, value: v.value,
                    })),
                  ]}
                />
              </div>
              <div className="item_box flex_grow_1">
                <LeftLabelInputField
                  labelPlace="left"
                  className="large"
                  label="顧客住所"
                  value={sort?.address}
                  onChange={(e) => setState({ address: e.target.value })}
                />
              </div>
            </div>
            <div className="item_wrap">
              <div className="item_box">
                <div className="item_head">建物分類</div>
                <Select
                  className=""
                  defaultLabel="指定無し"
                  value={sort?.building_category_id}
                  onChange={(v) => setState({ building_category_id: Number(v) })}
                  options={buildingCategoryList.map((v) => ({
                    text: v.name, value: v.id,
                  }))}
                />
              </div>
              <div className="item_box">
                <div className="item_head">間取り</div>
                <Select
                  className=""
                  value={sort?.madori_id}
                  onChange={(data) => setState({ madori_id: Number(data) })}
                  defaultLabel="指定無し"
                  options={madoriList.map((v) => ({
                    text: v.name, value: v.id,
                  }))}
                />
              </div>
              <div className="item_box">
                <div className="item_head">築年数</div>
                <RightLabelInputField
                  className="small"
                  label="年"
                  value={sort?.building_age_start}
                  onChange={(e) => setState({
                    building_age_start: Number(e.target.value),
                  })}
                  validationList={ValidationNumberLengthUnder3}
                  maxLength={3}
                />
                <label>〜</label>
                <RightLabelInputField
                  className="small"
                  label="年"
                  value={sort?.building_age_end}
                  onChange={(e) => setState({
                    building_age_end: Number(e.target.value),
                  })}
                  validationList={ValidationNumberLengthUnder3}
                  maxLength={3}
                />
                {/*
                <Input
                  className="small"
                  type="number"
                  value={sort?.building_age}
                  onChange={(e) => setState({ building_age: Number(e.target.value) })}
                />
                <Select
                  label=""
                  className="ml_10"
                  value={sort?.building_age_filter}
                  onChange={(v) => setState({ building_age_filter: Number(v) })}
                  options={[
                    { text: 'のみ', value: 1 },
                    { text: '以上', value: 2 },
                    { text: '以下', value: 3 },
                  ]}
                />
                */}
              </div>
            </div>
            <div className="item_wrap">
              <div className="item_box">
                <div className="item_head">完工時期</div>
                <DatePicker
                  date={sort.completion_date_start || null}
                  onChange={(v) => setState({
                    completion_date_start: v || undefined,
                  })}
                  type="YYYY/MM"
                  term={DateTermValidation([
                    {
                      start: sort.completion_date_start || null,
                      end: sort.completion_date_end || null,
                    },
                  ])}
                />
                <label className="ml_10">～</label>
                <DatePicker
                  date={sort.completion_date_end || null}
                  onChange={(v) => setState({
                    completion_date_end: v || undefined,
                  })}
                  type="YYYY/MM"
                  term={DateTermValidation([
                    {
                      start: sort.completion_date_start || null,
                      end: sort.completion_date_end || null,
                    },
                  ])}
                />
              </div>
              <div className="item_box">
                <div className="item_head">最終完工時期</div>
                <DatePicker
                  date={sort.last_completion_date_start || null}
                  onChange={(v) => setState({
                    last_completion_date_start: v || undefined,
                  })}
                  type="YYYY/MM"
                  term={DateTermValidation([
                    {
                      start: sort.last_completion_date_start || null,
                      end: sort.last_completion_date_end || null,
                    },
                  ])}
                />
                <label className="ml_10">～</label>
                <DatePicker
                  date={sort.last_completion_date_end || null}
                  onChange={(v) => setState({
                    last_completion_date_end: v || undefined,
                  })}
                  type="YYYY/MM"
                  term={DateTermValidation([
                    {
                      start: sort.last_completion_date_start || null,
                      end: sort.last_completion_date_end || null,
                    },
                  ])}
                />
                {/*
                <Select
                  className="add_text_right"
                  value={sort?.last_completion_start_year}
                  onChange={(v) => setState({ last_completion_start_year: String(v) })}
                  defaultLabel="全て"
                  options={CommonCollection.year}
                />
                <label>年</label>
                <Select
                  className="add_text_right"
                  value={sort?.last_completion_start_month}
                  onChange={(v) => setState({ last_completion_start_month: String(v) })}
                  defaultLabel="全て"
                  options={CommonCollection.month}
                />
                <label>月</label>
                <label>～</label>
                <Select
                  className="add_text_right"
                  value={sort?.last_completion_end_year}
                  onChange={(v) => setState({ last_completion_end_year: String(v) })}
                  defaultLabel="全て"
                  options={CommonCollection.year}
                />
                <label>年</label>
                <Select
                  className="add_text_right"
                  value={sort?.last_completion_end_month}
                  onChange={(v) => setState({ last_completion_end_month: String(v) })}
                  defaultLabel="全て"
                  options={CommonCollection.month}
                />
                <label>月</label>
                */}
              </div>
            </div>
            <div className="item_wrap">
              <div className="item_box">
                <div className="item_head">総工事金額</div>
                <RightLabelInputField
                  className="small"
                  label="万円"
                  type="number"
                  value={sort?.total_work_price_min}
                  onChange={(e) => setState({ total_work_price_min: e.target.value })}
                />
                <label className="ml_10">〜</label>
                <RightLabelInputField
                  className="small"
                  label="万円"
                  type="number"
                  value={sort?.total_work_price_max}
                  onChange={(e) => setState({ total_work_price_max: e.target.value })}
                />
              </div>
              <div className="item_box">
                <div className="item_head">工事回数</div>
                <RightLabelInputField
                  className="small"
                  type="number"
                  label="回"
                  value={sort?.work_times_min}
                  onChange={(e) => setState({ work_times_min: String(e.target.value) })}
                />
                <label className="ml_10">〜</label>
                <RightLabelInputField
                  className="small"
                  type="number"
                  label="回"
                  value={sort?.work_times_max}
                  onChange={(e) => setState({ work_times_max: String(e.target.value) })}
                />
              </div>
            </div>

            <div className="item_wrap">
              <div className="item_box">
                <div className="item_head">関連タグ</div>
                <div className="flex_wrap_box">
                  {sort?.tags?.data.map((v, i) => (
                    <div key={`tag${i}`}>
                      <RightLabelCheckbox
                        className="customerPC__body__inner__checkbox"
                        key={v.id}
                        label={v.label}
                        checked={v.flag}
                        onChange={() => {
                          sort.tags?.changeFlag(v.id);
                          setState({
                            tags: lodash.cloneDeep(sort.tags),
                          });
                        }}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="item_wrap">
              <div className="item_box">
                <div className="item_head">部位</div>
                <div className="flex_wrap_box">
                  {sort?.parts?.data.map((v, i) => (
                    <div key={`tag${i}`}>
                      <RightLabelCheckbox
                        className="customerPC__body__inner__checkbox"
                        key={v.id}
                        label={v.label}
                        checked={v.flag}
                        onChange={() => {
                          sort.parts?.changeFlag(v.id);
                          setState({
                            parts: lodash.cloneDeep(sort.parts),
                          });
                        }}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="item_wrap flex_no_wrap_box">
              <div className="item_box flex_grow_1">
                <div className="item_head">備考</div>
                <div className="item_body flex_grow_1">
                  <Input
                    className="large"
                    label="備考"
                    value={sort?.remarks}
                    onChange={(e) => setState({ remarks: e.target.value })}
                  />
                </div>
              </div>
            </div>
            <div className="item_wrap">
              <div className="item_box">
                <div className="item_head">不備情報</div>
                {/* <Checkbox
                  checked={!!sort?.valid_flag}
                  onChange={() => {
                    setState({ valid_flag: (sort?.valid_flag ? 1 : 0) });
                  }}
                /> */}
                {sort?.input_comp_flag?.data.map((v, i) => (
                  <div key={`tag${i}`}>
                    <RightLabelCheckbox
                      className="customerPC__body__inner__checkbox"
                      key={v.id}
                      label={v.label}
                      checked={v.flag}
                      onChange={() => {
                        // sort.input_comp_flag?.changeFlag(v.id);
                        // setState({
                        //   tags: lodash.cloneDeep(sort.tags),
                        // });
                        handleClickInputCompFlag(v.id);
                      }}
                    />
                  </div>
                ))}
                <InfoButton title={Message.csv.incompleteInfo} />
              </div>
            </div>
            <div className="item_wrap">
              <div className="item_box">
                <div className="item_head">登録者</div>
                <Select
                  defaultLabel="全て"
                  className="fixed"
                  value={sort.created_employee_id}
                  onChange={(v) => setState({ created_employee_id: Number(v) })}
                  options={pulldown(employeeList)}
                />
              </div>
              <div className="item_box">
                <div className="item_head">登録日</div>
                <DatePicker
                  date={sort.created_at_start || null}
                  onChange={(v) => setState({ created_at_start: v })}
                  type="YYYY/MM/DD"
                  term={DateTermValidation([
                    { start: sort.created_at_start || null, end: sort.created_at_end || null },
                  ])}
                />
                <label className="ml_10">～</label>
                <DatePicker
                  date={sort.created_at_end || null}
                  onChange={(v) => setState({ created_at_end: v })}
                  type="YYYY/MM/DD"
                  term={DateTermValidation([
                    { start: sort.created_at_start || null, end: sort.created_at_end || null },
                  ])}
                />
              </div>
              <div className="item_box">
                <div className="item_head">更新者</div>
                <Select
                  defaultLabel="全て"
                  className="fixed"
                  value={sort.updated_employee_id}
                  onChange={(v) => setState({ updated_employee_id: Number(v) })}
                  options={pulldown(employeeList)}
                />
              </div>
              <div className="item_box">
                <div className="item_head">更新日</div>
                <DatePicker
                  date={sort.updated_at_start || null}
                  onChange={(v) => setState({ updated_at_start: v })}
                  type="YYYY/MM/DD"
                  term={DateTermValidation([
                    { start: sort.updated_at_start || null, end: sort.updated_at_end || null },
                  ])}
                />
                <label className="ml_10">～</label>
                <DatePicker
                  date={sort.updated_at_end || null}
                  onChange={(v) => setState({ updated_at_end: v })}
                  type="YYYY/MM/DD"
                  term={DateTermValidation([
                    { start: sort.updated_at_start || null, end: sort.updated_at_end || null },
                  ])}
                />
              </div>
              <LeftIconButton
                label="検索"
                fontAwesomeClass="fas fa-search"
                className="btn_search for_detail"
                size="sm"
                color="secondary"
                onClick={handleClickSearch}
              />
            </div>
          </div>
        </div>
      </SearchBoxPC>
    </>
  );
};
