import React, {
  useCallback, useEffect, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { Table } from '../../../../../ui/table/table';
import './csv-list-project.scss';
import { State } from '../../../../../../redux/root.reducer';
import { ExportCsvCollection } from '../../../../../../collection/export-csv/export-csv.collection';
import { CsvActions } from '../../../../../../redux/csv/csv.action';
import { CsvProjectListType } from '../../../../../../type/csv/csv.type';
import { useAppSelector } from '../../../../../../hooks/use-redux';
import { CustomerRank } from '../../../master/master-body/customer-import/another-window/customer-rank';
import { DateFormatter as DF } from '../../../../../../utilities/date-formatter';
import { MathHelper as MH } from '../../../../../../utilities/math-helper';
import { RoutingPath } from '../../../../../../routes/routing-pass';
import { useOpenWindow } from '../../../../../../hooks/use-open-window';
import { useEstimateAuthority } from '../../../../../../hooks/use-authority';

type Props = {
  callbackSelected: (selected: number[]) => void;
}

export const CsvListProject = (props: Props) => {
  const { callbackSelected } = props;

  /* Hooks */
  const list = useSelector((state: State) => state.csv.projectList);
  const rankList = useAppSelector((v) => v.master.customerRankList);
  const dispatch = useDispatch();
  const windowOpen = useOpenWindow();
  const estimateAuth = useEstimateAuthority();

  /* State */
  const [selected, setSelected] = useState<number[]>([]);

  /* Callback */
  const handleClick = useCallback((row: CsvProjectListType) => {
    if (!list) return;
    setSelected([list.findIndex((v) => v.id === row.id)]);
  }, [list]);

  const handleClickHeader = useCallback(
    (highlow:0 | 1, sort_by: number) => {
      dispatch(CsvActions.setProjectSort({ highlow, sort_by }));
    }, [],
  );

  const handleDbClick = useCallback((
    row: CsvProjectListType,
    e?: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
  ) => {
    if (!list || !e) return;
    setSelected([list.findIndex((v) => v.id === row.id)]);
    windowOpen({
      url: `${RoutingPath.projectDetail}/${row.id}`,
      e,
    });
  },
  [list]);

  useEffect(() => {
    callbackSelected(selected);
  }, [selected]);

  return (
    <section className="result_area list_area">
      <div className="inner">
        <div className="table_responsive">
          <Table
            className="table_selectable table_sortable table_sticky table_cell_change"
            header={ExportCsvCollection.projectHeader
              .filter((_, i) => (estimateAuth ? true : i !== 14))}
            onClickRow={handleClick}
            onDbClick={(v, i, e) => handleDbClick(v, e)}
            sort={{ onClick: handleClickHeader }}
            option={ExportCsvCollection.getTableOption('project')}
            onClickAlt={(v, e) => {
              if (e && v) {
                windowOpen({
                  url: `${RoutingPath.projectDetail}/${v.id}`,
                  e,
                  callback: () => {},
                });
              }
            }}
            onClickMulti={(v: CsvProjectListType[]) => {
              if (!list) return;
              setSelected(v.map((v2) => list.findIndex((v3) => v3.id === v2.id)));
            }}
            rowDataList={list || []}
            selectedTr={selected}
            lists={list ? list.map((v) => {
              console.log();
              const rank = rankList.find((v2) => v2.id === v.customer_rank);
              const rankName = rank ? rank.abbreviation + rank.abbreviation2 : '';
              const li = [
                v.internal_id,
                v.name,
                v.customer_name,
                v.field_place,
                v.field_tel_no,
                <div className="rank_label" style={{ backgroundColor: rank?.background_color, color: rank?.text_color }}>{rankName}</div>,
                v.project_employee_name,
                DF.date2str(v.construction_start_date, 'YYYYMMDD', '/'),
                DF.date2str(v.construction_date, 'YYYYMMDD', '/'),
                DF.date2str(v.completion_end_date, 'YYYYMMDD', '/'),
                DF.date2str(v.completion_date, 'YYYYMMDD', '/'),
                DF.date2str(v.contract_date, 'YYYYMMDD', '/'),
                DF.date2str(v.failure_date, 'YYYYMMDD', '/'),
                DF.date2str(v.cancel_date, 'YYYYMMDD', '/'),
                MH.localStr(v.estimate_kin),
              ];
              if (!estimateAuth) li.pop();
              return li;
            }) : null}
          />
        </div>
      </div>
    </section>
  );
};
