import { ValidationLengthUnder255 } from '..';

export const MasterLostOrderValidation = (lostReason: string) => {
  window.console.log();
  return (
  // 必須
    !lostReason

  // バリデーション
  || ValidationLengthUnder255.filter((v) => !v.run(String(lostReason || ''))).length
  );
};
