import React, {
  useCallback, useState,
} from 'react';
import { cloneDeep } from 'lodash';
import { useDispatch } from 'react-redux';
import { FileCollection } from '../../../../../collection/file/file.collection';
import { useDidMount } from '../../../../../hooks/life-cycle';
import { FileValidation } from '../../../../../model/validation/file/file.validation';
import { DialogActions } from '../../../../../redux/dialog/dialog.action';
import { FileActions } from '../../../../../redux/file/file.action';
import { FileEditState, FileType } from '../../../../../type/file/file.type';
import { EditSP } from '../../../../dialogs/edit/edit.sp';
import { Button } from '../../../../ui/button/button';
import { Input } from '../../../../ui/input/input';
import { Required } from '../../../../ui/required/required';
import './file-edit.sp.scss';
import {
  ValidationLengthUnder40,
  ValidationLengthUnder50,
  ValidationLengthUnder500,
  ValidationLengthUnder60,
} from '../../../../../model/validation';
import { TextArea } from '../../../../ui/text-area/text-area';
import { Customer } from '../../../../../type/customer/customer.type';
import { Project, ProjectListType } from '../../../../../type/project/project.type';
import { FileUploadButton } from '../../../../ui/file-upload/file-upload-button';
import { CommonCollection } from '../../../../../collection/common/common.collection';
import { CustomerSearchSP } from '../../../layout/search-box/customer/customer-search/customer-search.sp';
import { FileModel } from '../../../../../model/file/file.model';
import { ProjectSearchSP } from '../../../layout/search-box/project/project-search/project-search.sp';
import { Message } from '../../../../../collection/message.collection';
import { InfoButton } from '../../../../ui/button/info-button/info-button';
import { useRegistrationMapCustomer, useRegistrationMapProject } from '../../../../../hooks/map/use-map';
import { ProjectDetailActions } from '../../../../../redux/project-detail/project-detail.action';

const { extname, basename } = require('path');

export const createFileName = (target: string | undefined, fileType: string | undefined) => {
  if (!target || !fileType) return '';
  const isMatch = target.match(`.${fileType}`);
  if (isMatch) return target;
  return `${target}.${fileType}`;
};

type Props = {
  id?: number;
  customer?: Customer;
  project?: Project | ProjectListType;
  callback?: () => void;
  isOrder?: boolean;
}

export const FileEditSP = (props: Props) => {
  const {
    customer, id, project, callback, isOrder,
  } = props;

  /* Hooks */
  const dispatch = useDispatch();
  const regiMapCustomer = useRegistrationMapCustomer('sp');
  const regiMapProject = useRegistrationMapProject('sp');

  /* State */
  const [customerName, setCustomerName] = useState('');
  const [projectName, setProjectName] = useState('');
  const [file, setFile] = useState<FileEditState>(FileCollection.editInitialState);
  const [formats, setFormats] = useState<string[]>([]);
  const [touch, setTouch] = useState(false);
  const [info, setInfo] = useState<null | FileType>(null);

  /* Callback */
  const setState = useCallback((v: Partial<FileEditState>) => {
    setFile({ ...cloneDeep(file), ...cloneDeep(v) });
  }, [file]);

  /** 保存 */
  const post = useCallback(() => {
    if (!file.file_name.length) {
      dispatch(DialogActions.pushMessage({
        title: 'ファイル情報入力',
        message: ['ファイルを選択してください'],
      }));
      return;
    }
    if (FileValidation(file, customerName, projectName, id)) {
      dispatch(DialogActions.pushMessage({
        title: 'ファイル情報入力',
        message: Message.postError,
        callback: () => setTouch(true),
      }));
      return;
    }
    dispatch(FileActions.api.file.post({
      param: {
        id,
        data: {
          ...FileModel.postParam(file, id),
          // order_file: isOrder || (!!info?.order_file),
        },
      },
      onSuccess: () => {
        callback?.();
        dispatch(ProjectDetailActions.setStopLoadFile(false));
      },
    }));
  },
  [id, file, callback]);

  /* 顧客検索 */
  const customerSearch = useCallback(() => {
    dispatch(ProjectDetailActions.setStopLoadFile(true));
    dispatch(DialogActions.push({
      title: '顧客検索',
      element: <CustomerSearchSP callback={(data) => {
        const isSame = data.id === file.customer_id;
        setFile({
          ...file,
          customer_id: data.id,
          project_id: !isSame ? NaN : file.project_id,
        });
        setCustomerName(data.name);
        setProjectName(!isSame ? '' : projectName);
      }}
      />,
    }));
  }, [file, projectName]);

  /* 案件検索 */
  const projectSearch = useCallback(() => {
    dispatch(DialogActions.push({
      title: '案件検索',
      element: <ProjectSearchSP
        type="file"
        callback={(data) => {
          setFile({
            ...file,
            customer_id: data.customer_id,
            project_id: data.id,
          });
          setCustomerName(data.customer_name);
          setProjectName(data.name);
        }}
      />,
    }));
  }, [file]);

  /* ファイルの読み込み */
  const onFileInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files;
    if (!fileList?.length) return;
    let files = file.file;
    const fileNames = cloneDeep(file.file_name);
    const _formats = cloneDeep(formats);
    if (id) {
      const extension = extname(fileList[0].name);
      if (files.length) {
        files = [];
      }
      files.push(fileList[0]);
      fileNames[0] = basename(fileList[0].name, extension);
      _formats[0] = (extension);
    } else {
      for (let i = 0; i < fileList.length; i += 1) {
        const extension = extname(fileList[i].name);
        files.push(fileList[i]);
        fileNames.push(basename(fileList[i].name, extension));
        _formats.push(extension);
      }
    }
    setState({ file: files, file_name: fileNames });
    setFormats(cloneDeep(_formats));
  }, [file, formats, id]);

  const mapSearch = useCallback((type: 'customer' | 'project') => {
    if (type === 'customer') {
      regiMapCustomer((v) => {
        dispatch(DialogActions.pop());
        const isSame = v.id === file.customer_id;
        setCustomerName(v.name);
        setProjectName(!isSame ? '' : projectName);
        setState({
          customer_id: v.id,
          project_id: !isSame ? NaN : file.project_id,
        });
      });
      return;
    }
    regiMapProject((v) => {
      dispatch(DialogActions.pop());
      setCustomerName(v.customer_name);
      setProjectName(v.name);
      setState({
        customer_id: v.customer_id,
        project_id: v.id,
      });
    });
  }, [file, projectName]);

  /* ファイルのダウンロード */
  const downloadFile = useCallback(() => {
    dispatch(FileActions.api.file.download({
      param: { file_id: Number(id) },
      fileName: file.file_name[0] || '',
    }));
  }, [id]);

  /* ファイルの削除 */
  const deleteFile = useCallback(() => {
    if (!id) return;
    dispatch(DialogActions.pushMessage({
      title: 'ファイル削除',
      message: ['削除しますか'],
      isCancel: true,
      callback: () => {
        dispatch(FileActions.api.file.delete({
          param: { id: Number(id) },
          callback: () => callback?.(),
        }));
      },
    }));
  }, [callback, id]);

  /* ファイルの削除 */
  const removeFile = useCallback((index: number) => {
    file.file.splice(index, 1);
    file.file_name.splice(index, 1);
    formats.splice(index, 1);
    setState({
      file: cloneDeep(file.file),
      file_name: cloneDeep(file.file_name),
    });
    setFormats(cloneDeep(formats));
  }, [file, formats]);

  useDidMount(() => {
    /* 案件データの当て込み */
    if (project) {
      setState({
        customer_id: project.customer_id,
        project_id: project.id,
      });
      setCustomerName(project.customer_name);
      setProjectName(project.name);
      return;
    }

    /* 顧客データの当て込み */
    if (customer) {
      setState({ customer_id: customer.id });
      setCustomerName(customer.name);
      return;
    }
    if (!id) {
      customerSearch();
      return;
    }

    /* ファイル1けん取得 */
    if (id !== undefined) {
      dispatch(FileActions.api.file.get({
        param: { id },
        callback: (data) => {
          setState(FileModel.setGetData(data));
          setFormats([data.format || '']);
          setCustomerName(data.customer_name);
          setProjectName(data.project_name);
          setInfo(cloneDeep(data));
        },
      }));
    }
  });

  return (
    <EditSP
      mode={!id ? 'add' : 'update'}
      callback={post}
      onClickCancel={() => dispatch(FileActions.setStopLoad(false))}
    >
      <div className="edit_sp_body_inner file_edit_sp">
        <div className="category_wrap">

          <div className="item_wrap">
            <div className="item_label">
              顧客名
              {!isOrder && (
                <>
                  <Required />
                  <Button
                    size="md"
                    color="secondary"
                    onClick={customerSearch}
                  >
                    顧客検索
                  </Button>
                  <Button
                    className="ml_10"
                    size="sm"
                    color="secondary"
                    onClick={() => {
                      mapSearch('customer');
                    }}
                  >
                    地図から検索
                  </Button>
                </>
              )}
            </div>
            <div className="item_body">
              <Input
                value={customerName}
                disabled
                require
                touch={touch}
              />
            </div>
          </div>

          <div className="item_wrap">
            <div className="item_label">
              案件名
              {!isOrder && (
              <>
                <Button
                  size="md"
                  color="secondary"
                  onClick={projectSearch}
                >
                  案件検索
                </Button>
                <Button
                  className="ml_10"
                  size="sm"
                  color="secondary"
                  onClick={() => {
                    mapSearch('project');
                  }}
                >
                  地図から検索
                </Button>
              </>
              )}
            </div>
            <div className="item_body">
              <Input
                value={projectName}
                disabled
              />
            </div>
          </div>
        </div>

        <div className="category_wrap">
          <div className="item_wrap">
            <div className="item_label">
              ファイル名<Required />
            </div>
            <div className="item_body item_file_upload">
              <div>
                {Boolean(file.file_name.length) && (
                  file.file_name.map((v, i) => (
                    <div className="flex_box flex_space_between file_box mb_5">
                      <Input
                        value={v}
                        id="input"
                        className=""
                        onChange={(e) => {
                          file.file_name[i] = e.target.value;
                          setState({
                            file_name: cloneDeep(file.file_name),
                          });
                        }}
                        require
                        validationList={ValidationLengthUnder40}
                        touch={touch}
                      />
                      <div className="extension">{formats[i]}</div>
                      {!id && (
                      <Button
                        size="sm"
                        color="dark"
                        className="ml_10"
                        onClick={() => removeFile(i)}
                      >
                        削除
                      </Button>
                      )}
                    </div>
                  ))
                )}
              </div>
              {!id
              && (
              <div className="item_file_upload__buttons">
                <FileUploadButton
                  onChange={onFileInputChange}
                  accept={CommonCollection.acceptFile}
                  multiple
                />
                <InfoButton title={Message.fileInfo} />
              </div>
              )}
            </div>
          </div>

          <div className="item_wrap">
            <div className="item_label">コメント</div>
            <div className="item_body">
              <TextArea
                rows={7}
                value={file?.comment}
                onChange={(e) => setState(
                  { ...file, comment: e.target.value },
                )}
                validationList={ValidationLengthUnder500}
              />
            </div>
          </div>
        </div>
      </div>
    </EditSP>
  );
};
