import CopyToClipboard from 'react-copy-to-clipboard';
import { DisplayElements } from '../../../../type/display-elements.type';
import { isMobilePhone } from '../../../../utilities/check-mobile-phone';
import { Button } from '../../button/button';
import { IconButton } from '../../button/icon-button/icon-button';

export const Item = ({ title, value, className }: {
  title: DisplayElements; value: DisplayElements; className?: string;
}) => (
  <div className="t_row">
    <div className="t_header">{title}</div>
    <div className={`t_body ${className || ''}`}>
      {typeof value === 'string' ? value || '---' : value}
    </div>
  </div>
);

export const TelItem = ({
  title, value, callback, callbackSMS,
}: {
  title: DisplayElements;
  value: string | undefined;
  callback: (v: string | undefined) => void;
  callbackSMS?: (v: string | undefined) => void;
}) => (
  <div className="t_row">
    <div className="t_header">{title}</div>
    <div className="t_body">{value || '---'}</div>
    <div>
      <IconButton
        fontAwesomeClass="fas fa-phone"
        onClick={() => callback(value)}
        size="md"
        color="secondary"
        disabled={!value}
      />
      {callbackSMS && (
      <IconButton
        fontAwesomeClass="fas fa-sms"
        color="secondary"
        size="md"
        onClick={() => callbackSMS?.(value)}
        disabled={!value || !isMobilePhone(value)}
        className="ml_5"
      />
      )}

    </div>
  </div>
);

export const SnsItem = ({ title, value, callback }: {
  title: string; value: string | undefined; callback: (v: string) => void;
}) => (
  <div className="t_row">
    <div className="t_header">{title}</div>
    <div className="t_body flex_box flex_space_between flex_align_center">
      {value || '---'}
      {value
        && (
          <CopyToClipboard text={value || ''}>
            <IconButton
              className="ml_10"
              fontAwesomeClass="fas fa-external-link-alt"
              size="sm"
              color="white"
              onClick={() => callback(value)}
              disabled={!value}
            />
          </CopyToClipboard>
        )}
    </div>
  </div>
);
