import { useDispatch, useSelector } from 'react-redux';
import React, {
  ChangeEvent,
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { cloneDeep } from 'lodash';
import { MasterActions } from '../../../../../redux/master/master.action';
import { State } from '../../../../../redux/root.reducer';
import { MasterCollection } from '../master.collection';
import { MasterBodyDirectInput, MasterDirectInputGetListParam } from './body/master-body-direct-input';
import { XmlParser } from '../../../../../parser/xml-parser';
import { Input } from '../../../../ui/input/input';
import { DisplayElements } from '../../../../../type/display-elements.type';

type InnerItems = {
  name: string,
  disp_name: string,
  value: string,
}

type ListItem = {
  $: InnerItems;
}
type XMLType = {
    list: {
      item: ListItem[]
    }
}

export const MasterSignatureBody = () => {
  /* Hook */
  const list = useSelector((state:State) => state.master.signatureList);
  const dispatch = useDispatch();

  /* State */
  const [XMLList, setXmlList] = useState<XMLType>(null!);
  const [tableI, setTableI] = useState(NaN);
  const [tableJ, setTableJ] = useState(NaN);
  const [dispNameList, setDispNameList] = useState<string[]>([]);
  const [isKeyPressEnter, setIsKeyPressEnter] = useState(false);
  const [listLength, setListLength] = useState(0);

  /* Callback */
  const getList = useCallback(() => {
    dispatch(MasterActions.api.signature.getList({}));
  }, []);

  const handleClickCell = useCallback((i: number, j: number) => {
    setTableI(i);
    setTableJ(j);
  }, []);

  const handleEnterKeyPress = useCallback((e: React.KeyboardEvent<HTMLDivElement>) => {
    setIsKeyPressEnter(true);
    const xml = cloneDeep(XMLList);
    const itemList = xml.list.item.map((v, _i) => ({
      $: {
        ...v.$,
        disp_name: dispNameList[_i],
      },
    }));
    xml.list.item = itemList;
    setXmlList(xml);

    if (!e.shiftKey && listLength - 1 > tableI) {
      setTableI(tableI + 1);
    } else {
      setIsKeyPressEnter(false);
    }
    if (e.shiftKey && tableI) {
      setTableI(tableI - 1);
    } else {
      setIsKeyPressEnter(false);
    }
  }, [listLength, tableI, tableJ, isKeyPressEnter, dispNameList, XMLList]);

  const postList = useCallback(() => {
    dispatch(MasterActions.api.signature.post({
      param: {
        data: {
          xml_format: new XmlParser().build(XMLList),
          valid_flag: 1,
        },
      },
      callback: () => getList(),
    }));
  }, [XMLList, list]);

  useEffect(() => {
    if (!list.length) return;
    new XmlParser().parse(list[0].xml_format)
      .then((res: XMLType) => {
        setXmlList(res);
      });
  }, [list]);

  const setXMLState = useCallback((key: keyof InnerItems) => {
    const xml = cloneDeep(XMLList);
    const itemList = xml.list.item.map((v, _i) => ({
      $: {
        ...v.$,
        [key]: dispNameList[_i],
      },
    }));
    xml.list.item = itemList;
    setXmlList(xml);
    setTableI(isKeyPressEnter ? tableI : NaN);
    setTableJ(isKeyPressEnter ? tableJ : NaN);
  }, [XMLList, dispNameList, isKeyPressEnter, tableI, tableJ]);

  const onChangeInput = useCallback((e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const li = cloneDeep(dispNameList);
    li[index] = e.target.value;
    setDispNameList(li);
  }, [dispNameList]);

  const tableList = useMemo(() => {
    const lis = (XMLList ? cloneDeep(XMLList).list.item.map((f, i) => ([
      f.$.disp_name,
      (i === tableI && tableJ === 1)
        ? (
          <Input
            value={dispNameList[i]}
            onChange={(e) => onChangeInput(e, i)}
            onBlur={() => setXMLState('disp_name')}
            callBackEnterKeyPress={(e) => handleEnterKeyPress(e)}
            onFocus={() => setIsKeyPressEnter(false)}
            firstFocus={i === tableI && tableJ === 1}
          />
        )
        : f.$.value,
    ]))
      : []
    );
    setListLength(lis.length);
    return lis;
  },
  [XMLList, tableI, tableJ, dispNameList, isKeyPressEnter]);

  useEffect(() => {
    setDispNameList(
      XMLList ? cloneDeep(XMLList).list.item.map((f) => (
        f.$.disp_name
      )) : [],
    );
  }, [XMLList]);

  return (
    <MasterBodyDirectInput
      header={MasterCollection.signatureMasterHeader}
      rowDataList={XMLList?.list.item}
      list={tableList}
      callbackPost={postList}
      callbackGetList={getList}
      defaultOrder={0}
      onClickCell={handleClickCell}
    />
  );
};
