import { memo, useMemo } from 'react';
import { UserAgent } from '../../../utilities/user-agent';
import { Required } from '../required/required';
import { Input, InputProps } from './input';
import { InputMail } from './input-mail';
import { InputTel } from './input-tel';
import { DisplayElements } from '../../../type/display-elements.type';

const Inputs = memo((props: Props) => {
  const {
    type, value, disabled, onChange, children, pos,
    require, touch, noValidation,
  } = props;

  const baseClassName = `${pos}LabelInputField`;
  const defaultClass = UserAgent === 'sp' ? `${baseClassName}_sp` : `${baseClassName}_pc`;
  const _className = `${defaultClass}__field`;
  return (
    <>
      {/* Text */}
      {type === 'text' && (
      <Input
        {...props}
        className={_className}
        onChange={(e) => onChange?.(e.target.value)}
        require={require}
        touch={touch}
      />
      )}

      {/* Email */}
      {type === 'email' && onChange && (
      <InputMail
        className={_className}
        value={value}
        disabled={disabled}
        onChange={onChange}
        require={require}
        touch={touch}
        noValidation={noValidation}
      />
      )}

      {/* Tel */}
      {type === 'tel' && onChange && (
        <InputTel
          require={require}
          touch={touch}
          className={_className}
          value={value}
          disabled={disabled}
          onChange={onChange}
        />
      )}

      {/* 直接ReactNodeを渡す */}
      {children && (<>{children}</>)}
    </>
  );
});

type Props = {
  pos: 'Top' | 'Right' | 'Left';
  type?: 'text' | 'email' | 'tel'
  onChange?: (v: string) => void;
  /** type = numberの場合のみ */
  children?: globalThis.React.ReactNode;
  require?: boolean;
  touch?: boolean;
  noValidation?: boolean;
  withIcon?: DisplayElements;
} & Omit<InputProps, 'onChange'>;

export const LabelInput = memo((props: Props) => {
  const {
    noRequireLabel, className, label, require, pos, touch,
    noValidation, withIcon,
  } = props;

  const baseClassName = `${pos}LabelInputField`;
  const defaultClass = UserAgent === 'sp' ? `${baseClassName}_sp` : `${baseClassName}_pc`;

  return (
    <>
      {pos === 'Top' && (
      <div className={`${defaultClass} ${className || ''}`}>
        <span className={`${defaultClass}__label`}>
          {label}
          {require && !noRequireLabel && <Required />}
          {withIcon}
        </span>
        <Inputs {...props} />
      </div>
      )}

      {pos === 'Right' && (
      <div style={{ display: 'flex' }} className={`${defaultClass} ${className || ''}`}>
        <Inputs {...props} />
        <label className={`${defaultClass}__label`}>{label}</label>
      </div>
      )}

      {pos === 'Left' && (
      <div style={{ display: 'flex' }} className={`${defaultClass} ${className || ''}`}>
        <label className={`${defaultClass}__label`}>{label}{require && !noRequireLabel && <Required />}</label>
        <Inputs {...props} />
      </div>
      )}
    </>
  );
});
