import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { cloneDeep } from 'lodash';
import { CustomerDetailActions } from './customer-detail.action';
import { MaintenanceSortState, MaintenanceList } from '../../type/maintenance/maintenance.type';
import { ProjectSortState, ProjectListType } from '../../type/project/project.type';
import { FileSortState, FileListType } from '../../type/file/file.type';
import { SupportHistorySortState, SupportHistoryListType } from '../../type/support-history/support-history.type';
import { ProjectCollection } from '../../collection/project/project.collection';
import { MaintenanceCollection } from '../../collection/maintenance/maintenance.collection';
import { FileCollection } from '../../collection/file/file.collection';
import { SupportHistoryCollection } from '../../collection/support-history/support-history.collection';
import { concatList } from '../root.reducer';

export type CustomerDetailState = {
  projectSort: ProjectSortState;
  maintenanceSort: MaintenanceSortState;
  fileSort: FileSortState;
  supportSort: SupportHistorySortState;
  projectList: ProjectListType[] | null;
  fileList: FileListType[] | null;
  maintenanceList: MaintenanceList[] | null;
  supportList: SupportHistoryListType[] | null;
  stopLoadFile: boolean;
};

const initialState: CustomerDetailState = {
  projectSort: {
    ...ProjectCollection.initialSortState(),
    sort_by: -1,
  },
  maintenanceSort: { ...MaintenanceCollection.initialSortState, supported_kubun: NaN },
  fileSort: FileCollection.initialSortState(),
  supportSort: { ...SupportHistoryCollection.initialDetailSortState() },
  projectList: null,
  supportList: null,
  fileList: null,
  maintenanceList: null,
  stopLoadFile: false,
};

export const CustomerDetailReducer = reducerWithInitialState<CustomerDetailState>(initialState)
  .case(CustomerDetailActions.setProjectSort, (state, payload) => ({
    ...state,
    projectSort: payload ? cloneDeep({
      ...state.projectSort,
      ...payload,
    }) : {
      ...ProjectCollection.initialSortState(),
      sort_by: -1,
    },
  }))
  .case(CustomerDetailActions.setMaintenanceSort, (state, payload) => ({
    ...state,
    maintenanceSort: payload ? cloneDeep({
      ...state.maintenanceSort,
      ...payload,
    }) : MaintenanceCollection.initialSortState,
  }))
  .case(CustomerDetailActions.setFileSort, (state, payload) => ({
    ...state,
    fileSort: payload ? cloneDeep({
      ...state.fileSort,
      ...payload,
    }) : FileCollection.initialSortState(),
  }))
  .case(CustomerDetailActions.setSupportSort, (state, payload) => ({
    ...state,
    supportSort: payload ? cloneDeep({
      ...state.supportSort,
      ...payload,
    }) : { ...SupportHistoryCollection.initialDetailSortState() },
  }))
  .case(CustomerDetailActions.setProjectList, (state, payload) => ({
    ...state,
    projectList: cloneDeep(payload),
  }))
  .case(CustomerDetailActions.setConcatProjectList, (state, payload) => ({
    ...state,
    projectList: cloneDeep(concatList(state.projectList, payload)),
  }))
  .case(CustomerDetailActions.setMaintenanceList, (state, payload) => ({
    ...state,
    maintenanceList: cloneDeep(payload),
  }))
  .case(CustomerDetailActions.setConcatMaintenanceList, (state, payload) => ({
    ...state,
    maintenanceList: cloneDeep(concatList(state.maintenanceList, payload)),
  }))
  .case(CustomerDetailActions.setFileList, (state, payload) => ({
    ...state,
    fileList: cloneDeep(payload),
  }))
  .case(CustomerDetailActions.setConcatFileList, (state, payload) => ({
    ...state,
    fileList: cloneDeep(concatList(state.fileList, payload)),
  }))
  .case(CustomerDetailActions.setSupportList, (state, payload) => ({
    ...state,
    supportList: cloneDeep(payload),
  }))
  .case(CustomerDetailActions.setConcatSupportList, (state, payload) => ({
    ...state,
    supportList: cloneDeep(concatList(state.supportList, payload)),
  }))
  .case(CustomerDetailActions.setStopLoadFile, (state, payload) => ({
    ...state,
    stopLoadFile: cloneDeep(payload),
  }))
  .case(CustomerDetailActions.resetState, () => initialState)
  .default((state) => state);
