import lodash, { cloneDeep } from 'lodash';
import {
  put, takeLatest,
} from 'redux-saga/effects';
import { EstimateActions } from './estimate.action';
import { ApiEstimatePost, ApiEstimateGetList, ApiEstimateGet } from './api/estimate/api-estiamte';
import { DialogActions } from '../dialog/dialog.action';
import { Store } from '../store';
import {
  getError,
  getHandle, getListHandle, postHandle,
} from '../root.saga';
import { ApiEstimate } from '../root.type';
import { ApiEstimateMeisaiSideMenuListResponse } from './api/meisai/api-estimate-meisai.type';
import {
  ApiEstimateMeisaiPostList,
  ApiEstimateMeisaiPost,
  ApiEstimateMeisaiSideMenuGetList,
} from './api/meisai/api-estimate-meisai';
import { ApiEstimatePrint } from './api/print/api-estimate-print';
import { ResponseHeader } from '../../type/api.type';
import { ApiBase } from '../../service/api-base';
import { SystemActions } from '../system/system.action';
import { errorHandle } from '../system/system.saga';
import { AuthActions } from '../auth/auth.action';
import { ApiUser } from '../user/api/api-user';

function* tryEstimateGet(action: ReturnType<typeof EstimateActions.api.estimate.get>) {
  const { param, callback } = action.payload;
  if (!param.id) {
    yield getError('見積');
    return;
  }
  const api = new ApiEstimateGet(param);
  yield getHandle<ApiEstimate.Estimate.Response.Get>({
    api,
    onSuccess: (result) => {
      if (callback && result) {
        callback(lodash.cloneDeep(result));
        return;
      }
      Store.dispatch(EstimateActions.setEstimate(result));
    },
    * onError() {
      yield getError('見積');
    },
  });
}

function* tryEstimateGetList(action: ReturnType<typeof EstimateActions.api.estimate.getList>) {
  const { param, callback, noLoad } = action.payload;
  yield getListHandle<ApiEstimate.Estimate.Response.List>({
    noLoad: true,
    noDelay: true,
    api: new ApiEstimateGetList(param),
    onSuccess: (result, hitCount) => {
      if (callback) {
        callback(cloneDeep(result), hitCount);
        return;
      }
      Store.dispatch(EstimateActions.setList(result));

      // yield put(EstimateActions.setList(result));
      // yield put(EstimateActions.setListCount(hitCount));
    },
  });
}

function* tryEstimatePost(action: ReturnType<typeof EstimateActions.api.estimate.post>) {
  const {
    param, onSuccess, onError, update,
  } = action.payload;
  const api = new ApiEstimatePost(param);
  yield put(SystemActions.isLoading(true));

  try {
    const me = new ApiUser();
    yield me.run();
    yield put(AuthActions.setToken(me.token));
    api.header = {
      ...api.header,
      'X-CSRF-TOKEN': me.token,
    };
    const result: {
      header: ResponseHeader;
      body: {
        internal_id: number;
        id: number;
      }
     } = yield api.run();
    if (ApiBase.isSuccess(result)) {
      yield put(SystemActions.isLoading(false));
      yield put(DialogActions.pushMessage({
        title: `見積${update ? '更新' : '登録'}完了`,
        message: [`${update ? '更新' : '登録'}が完了しました。`],
        callback: () => { if (result.body) onSuccess?.(result.body); },
        callbackClose: () => { if (result.body) onSuccess?.(result.body); },
      }));
    } else {
      yield put(SystemActions.isLoading(false));

      yield put(DialogActions.pushMessage({
        title: 'お知らせ',
        message: result.header.messages || [],
        callback: onError,
      }));
    }
  } catch (error) {
    yield put(SystemActions.connectionError({
      message: ['インターネット接続の確認後に再度「登録／更新」を行ってください。'],
    }));
  }
  yield put(SystemActions.isLoading(false));
}

function* tryMeisaiPost(action: ReturnType<typeof EstimateActions.api.meisai.post>) {
  const { param, onSuccess, onError } = action.payload;
  const api = new ApiEstimateMeisaiPost(param);
  yield postHandle({
    title: '見積明細情報',
    api,
    onSuccess: () => {
      Store.dispatch(DialogActions.pop());
      if (onSuccess)onSuccess();
    },
    onError: () => {
      if (onError)onError();
    },
  });
}

function* tryMeisaiPostList(action: ReturnType<typeof EstimateActions.api.meisai.postList>) {
  const { param, onSuccess } = action.payload;
  const api = new ApiEstimateMeisaiPostList(param);
  yield postHandle({
    noLoad: true,
    api,
    onSuccess: () => {
      // Store.dispatch(DialogActions.pop());
      if (onSuccess)onSuccess();
    },
    noMessage: true,
  });
}

function* tryMeisaiSideMenuGetList(
  action: ReturnType<typeof EstimateActions.api.meisai.getSideMenuList>,
) {
  const { param, onSuccess } = action.payload;
  const api = new ApiEstimateMeisaiSideMenuGetList(param);
  yield put(SystemActions.isLoading(true));
  try {
    const result: {body:ApiEstimateMeisaiSideMenuListResponse} = yield api.run();
    if (ApiBase.isSuccess(result)) {
      if (onSuccess) {
        onSuccess(cloneDeep(result.body));
        return;
      }
      yield put(EstimateActions.setMeisaiSideMenu(result.body));
    } else {
      yield errorHandle(result as any);
    }
  } catch (e) {
    yield put(SystemActions.connectionError({}));
  } finally {
    yield put(SystemActions.isLoading(false));
  }
}

function* tryPrint(action: ReturnType<typeof EstimateActions.api.print>) {
  const { file_name, type } = action.payload.data;

  yield put(SystemActions.isLoading(true));
  const api = new ApiEstimatePrint(action.payload);

  try {
    const me = new ApiUser();
    yield me.run();
    yield put(AuthActions.setToken(me.token));
    api.header = {
      ...api.header,
      'X-CSRF-TOKEN': me.token,
    };
    const result: {file: Blob, status: number} = yield api.run();
    if (ApiBase.isSuccess(result)) {
      const filePath = URL.createObjectURL(result.file);
      const a = document.createElement('a');
      if (type === 'download') {
        a.download = file_name;
      }
      a.href = filePath;
      a.target = 'blank';
      // a.href = result.file;
      a.click();

      URL.revokeObjectURL(filePath);

      // window.open(
      //   `./pdf-viewer/viewer.html?file=${encodeURIComponent(
      //     URL.createObjectURL(file),
      //   )}`,
      //   'null',
      // );

      // const filePath = encodeURIComponent(URL.createObjectURL(result.file)).split('blob%3A')[1];
      // const filePath = encodeURIComponent('http://www.africau.edu/images/default/sample.pdf');
      // const filePath = './compressed.tracemonkey-pldi-09.pdf';
      // const width = 1000;
      // const height = 1000;
      // window.open(
      //   `./pdf-viewer/viewer.html?file=${filePath}`,
      //   // filePath,
      //   'modal',
      //   `width=${width},height=${height}`,
      // );
    }
  } catch (e) {
    yield put(SystemActions.connectionError({}));
  }
  yield put(SystemActions.isLoading(false));
}

export function* EstimateSaga() {
  yield takeLatest(EstimateActions.api.estimate.get, tryEstimateGet);
  yield takeLatest(EstimateActions.api.estimate.post, tryEstimatePost);
  yield takeLatest(EstimateActions.api.estimate.getList, tryEstimateGetList);
  yield takeLatest(EstimateActions.api.print, tryPrint);
  yield takeLatest(EstimateActions.api.meisai.getSideMenuList, tryMeisaiSideMenuGetList);
  yield takeLatest(EstimateActions.api.meisai.post, tryMeisaiPost);
  yield takeLatest(EstimateActions.api.meisai.postList, tryMeisaiPostList);
}
