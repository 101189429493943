import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import maintenance_completed from '../../../../asset/images/icon_maintenance_completed.svg';
import maintenance_started from '../../../../asset/images/icon_maintenance_started.svg';
import { MapActions } from '../../../../redux/map/map.action';
import { MaintenanceList } from '../../../../type/maintenance/maintenance.type';
import { joinStr } from '../../../../utilities/join-str';
import { UserAgent } from '../../../../utilities/user-agent';
import { RectLabel } from '../../label/rect-label/rect-label';
import { ShowTypeLabel } from '../../label/show-type/show-type-label';
import './maintenance-card.scss';
import { DateFormatter as DF } from '../../../../utilities/date-formatter';

type Props = {
  maintenanceData: MaintenanceList,
  className?: string,
  onClick?: (id: number) => void;
  isInCustomerDetail?: boolean;
}

export const MaintenanceCard = (props: Props) => {
  // const dispatch = useDispatch();

  const {
    maintenanceData, className, onClick, isInCustomerDetail,
  } = props;

  const dispatch = useDispatch();

  const validDate = useMemo(() => {
    const today = new Date();
    const mDate = DF.str2date(maintenanceData.maintenance_date);
    if (!mDate) return ' alert';
    if (today.getFullYear() === mDate.getFullYear()
    && today.getMonth() === mDate.getMonth()
    && today.getDate() === mDate.getDate()
    && !maintenanceData.supported_kubun) {
      return ' alert';
    }

    if (maintenanceData.maintenance_past_flag && !maintenanceData.supported_kubun) {
      return ' alert';
    }
    return '';
  }, [maintenanceData]);

  /* callback */
  const handleClickMaintenanceDetail = useCallback(() => {
    onClick?.(maintenanceData.id);
    if (maintenanceData.lat !== null && maintenanceData.lng !== null) {
      dispatch(MapActions.setGpsStatus('out'));
      dispatch(MapActions.setZoomLevel(20));
    }
  }, [onClick, maintenanceData.id, maintenanceData.lat, maintenanceData.lng]);

  return (
    <>
      {UserAgent === 'sp'
        ? (
          <div
            id="maintenance_card"
            className={`maintenance_card 
            ${UserAgent} card_base 
            ${className || ''} 
            ${!maintenanceData.is_valid ? 'invalid' : ''}`}
            onClick={handleClickMaintenanceDetail}
          >
            <div className="card_base_row">
              <div className="card_base_row__col_left">
                <div className="card_info_item">
                  <div className="card_info_item__head">
                    ﾒﾝﾃ日
                  </div>
                  <div className={`card_info_item__text ${maintenanceData.maintenance_past_flag ? 'require strong' : ''}`}>
                    {maintenanceData.maintenance_date || '---'}{maintenanceData.maintenance_past_flag ? <span>（期限日超過）</span> : ''}
                  </div>
                </div>
                {!isInCustomerDetail && (
                <div className="card_info_item">
                  <div className="card_info_item__head">
                    顧客名
                  </div>
                  <div className="card_info_item__text emphasis">
                    {maintenanceData.customer_name || '---'}
                  </div>
                </div>
                )}

                <div className="card_info_item">
                  <div className="card_info_item__head">
                    ﾀｲﾄﾙ
                  </div>
                  <div className="card_info_item__text important">
                    {maintenanceData.title || '---'}
                  </div>
                </div>

                <div className="card_info_item">
                  <div className="card_info_item__head">
                    住所
                  </div>
                  <div className="card_info_item__text">
                    {`〒${joinStr(maintenanceData.post_no, 3, '-') || '---'}`}<br />
                    {maintenanceData.field_prefecture_name || ''}
                    {maintenanceData.field_city || ''}
                    {maintenanceData.field_address || ''}<br />
                    {maintenanceData.field_building_name || ''}
                  </div>
                </div>

                <div className="card_info_item">
                  <div className="card_info_item__head">
                    TEL
                  </div>
                  <div className="card_info_item__text">
                    {maintenanceData.tel_no || '---'}
                  </div>
                </div>

                <div className="card_info_item">
                  <div className="card_info_item__head">
                    担当者
                  </div>
                  <div className="card_info_item__text emphasis">
                    {maintenanceData.project_employee_name || '---'}
                  </div>
                </div>

              </div>
              <div className="card_base_row__col_right">
                <div>
                  <ShowTypeLabel
                    label={!maintenanceData.supported_kubun ? '未対応' : '対応済'}
                    showTypeImg={!maintenanceData.supported_kubun
                      ? maintenance_started : maintenance_completed}
                    className={!maintenanceData.supported_kubun
                      ? 'maintenance_started' : 'maintenance_completed'}
                  />

                  <RectLabel
                    label={maintenanceData.rank_name || 'ランクなし'}
                    bgColor={maintenanceData.background_color || 'gray'}
                    color={maintenanceData.text_color || undefined}
                  />
                </div>
                {/* <div className={maintenanceData.fixed_flag ? '' : 'invisible'}>
            <RectLabel label="対応済" />
          </div> */}
              </div>
              {/*
         <div className="row1_col3">
          <div className="thumb google" />
        </div>
        */}
            </div>
          </div>
        )
        : (
          <div
            id="maintenance_card"
            className={`maintenance_card ${UserAgent} card_base ${className || ''}`}
            onClick={handleClickMaintenanceDetail}
          >
            <div className="important">{`${maintenanceData.title || '---'}`}</div>
            <div className="row1 card_base_row">
              <div className="row1_col1 card_base_col">
                <div className="emphasis">顧客名：{`${maintenanceData.customer_name || '---'}`/* （${maintenanceData.furigana}）*/}</div>
                <div>
                  {`〒${joinStr(maintenanceData.post_no, 3, '-') || '---'}`}<br />
                  {maintenanceData.field_prefecture_name || ''}
                  {maintenanceData.field_city || ''}
                  {maintenanceData.field_address || ''}<br />
                  {maintenanceData.field_building_name || ''}
                </div>
                <div className="tel_no">TEL：{maintenanceData.tel_no || '---'}</div>
                <div className="">担当者：{maintenanceData.project_employee_name || '---'}</div>
              </div>
              <div className="row1_col2">
                <ShowTypeLabel
                  label={maintenanceData.supported_kubun === false ? '未対応' : '対応済'}
                  showTypeImg={
                maintenanceData.supported_kubun
                === false ? maintenance_started : maintenance_completed
              }
                  className={
                  maintenanceData.supported_kubun === false ? 'maintenance_started' : 'maintenance_completed'
                }
                />
                <RectLabel
                  label={maintenanceData.rank_name || 'ランクなし'}
                  bgColor={maintenanceData.background_color || 'gray'}
                  color={maintenanceData.text_color || undefined}
                />
                {/* <div className={maintenanceData.fixed_flag ? '' : 'invisible'}>
                    <RectLabel label="対応済" />
                </div> */}
                <table className={`maintenance_date mt_5 ${validDate} mb_0`}><tr><th>ﾒﾝﾃﾅﾝｽ日</th></tr><tr><td className="px_5">{DF.date2str(maintenanceData.maintenance_date) || '---'}</td></tr></table>
              </div>
            </div>
          </div>
        )}
    </>
  );
};
