import { memo } from 'react';
import { DisplayElements } from '../../../type/display-elements.type';

type Props = {
  title: DisplayElements;
  children: globalThis.JSX.Element | HTMLElement
  classNameBox?: string;
}

export const ItemWrap = memo((props: Props) => {
  const { title, children, classNameBox } = props;
  return (
    <div className="item_wrap">
      <div className={`item_box ${classNameBox || ''}`}>
        <div className="item_head">{title}</div>
        {children}
      </div>
    </div>
  );
});
