import { goBack } from 'connected-react-router';
import { cloneDeep } from 'lodash';
import isEqual from 'lodash/isEqual';
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { EstimateCalcModelPC } from '../../../../collection/estimate/estimate-calc.model.pc';
import { EstimateCollection } from '../../../../collection/estimate/estimate.collection';
import { DialogActions } from '../../../../redux/dialog/dialog.action';
import { EstimateActions } from '../../../../redux/estimate/estimate.action';
import { GetTax } from '../../../../redux/master/api/master-tax/api-master-tax';
import { ProjectActions } from '../../../../redux/project/project.action';
import { State } from '../../../../redux/root.reducer';
import { EditPriceAreaState, Estimate } from '../../../../type/estimate/estimate.type';
import { DateFormatter } from '../../../../utilities/date-formatter';
import { getTermDay } from '../../../../utilities/get-term-day';
import { MathHelper } from '../../../../utilities/math-helper';
import { openEmail } from '../../../../utilities/open-email';
import { Button } from '../../../ui/button/button';
import { IconButton } from '../../../ui/button/icon-button/icon-button';
import { LeftIconButton } from '../../../ui/button/left-icon-button/left-icon-button';
import { EstimateDetailListSP } from '../../layout/body/list/estimate/estimate-detail-list';
import { OrderSP } from '../../layout/order/order.sp';
import { BasePageSP } from '../base.page.sp';
import { EstimateEditSP } from '../estimate/edit/estimate-edit.sp';
import { EstimateEditDialogTitle } from '../estimate/edit/estimate-edit.type.sp';
import { EstimateSearchBoxSP } from '../estimate/serch-box/estimate-search-box.sp';
import { DetailPriceEditSP } from '../estimate-detail/edit/detail-price-edit.sp';
// import { EstimateDetailEditSP } from './edit/estimate-detail-edit.sp';
import { EstimateDetailEditSP } from '../estimate-detail/edit/estimate-detail-edit.sp';
import '../estimate-detail/estimate-detail.sp.scss';
import { XmlParser } from '../../../../parser/xml-parser';
import { EstimateMeisaiModel } from '../../../../model/estimate/estimate-meisai.model';
import { MeisaiXML } from '../../../../model/estimate/estimate-meisai.type';
import { dummy } from '../../../../model/estimate/dummy';
import { SampleList } from './sample-list';
import { Input } from '../../../ui/input/input';
import { Store } from '../../../../redux/store';
import { useDidMount } from '../../../../hooks/life-cycle';
import { InputPostNoMulti } from '../../../ui/input/input-post-no-multi';

// // Parse XML → Object
// useEffect(() => {
//   new XmlParser().parse<MeisaiXML>(dummy).then((res) => {
//     setMeisaiModel(new EstimateMeisaiModel(cloneDeep(res)));
//   });
// }, [estimate?.id]);

export const TestPageSP = () => {
  const dispatch = useDispatch();

  const [text, setText] = useState('');
  const [text1, setText2] = useState('');
  useDidMount(() => {
    console.log('aaaaaaaaaaa');
  });
  return (
    <div>
      <div className="item_body item_postal">
        <InputPostNoMulti
          value={text}
          onChange={(v) => setText(v)}
          value2={text1}
          onChange2={(v) => setText2(v)}
          isSp
        />
        <button
          onClick={() => {
            dispatch(DialogActions.pushMessage({
              title: '',
              message: [],
            }));
          }}
        >aaaa
        </button>
      </div>
    </div>
  );
};
