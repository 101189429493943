/* eslint-disable no-irregular-whitespace */
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import * as lodash from 'lodash';
import { cloneDeep, isEqual } from 'lodash';
import { Table } from '../../../../../ui/table/table';
import './estimate-meisai-list.pc.scss';
import { EstimateCollection } from '../../../../../../collection/estimate/estimate.collection';
import { Button } from '../../../../../ui/button/button';
import { DialogActions } from '../../../../../../redux/dialog/dialog.action';
import { ChangePrintNameDialogPC } from '../../change-print-name-dialog/change-print-name-dialog.pc';
import { MathHelper, MathHelper as MH } from '../../../../../../utilities/math-helper';
import { Input } from '../../../../../ui/input/input';
import { EstimateMeisaiModel } from '../../../../../../model/estimate/estimate-meisai.model';
import { MeisaiListXML } from '../../../../../../model/estimate/estimate-meisai.type';
import { AddMasterMeisai } from '../../add-master/add-master-meisai';
import { ValidationMax100Million } from '../../../../../../model/validation/validation-max-100-million';
import { EditMeisaiDialogPC } from '../edit/edit-meisai-dialog.pc';
import { SelectTreeData } from '../estimate-explore.pc';
import { InputNum } from '../../../../../ui/input/input-num';
import { EstimateCalcModelPC } from '../../../../../../collection/estimate/estimate-calc.model.pc';
import { TdAlign } from '../../../../../../type/table.type';
import { MultiEditMeisaiDialogPC } from '../multi-edit/multi-edit-meisai-dialog.pc';
import { Checkbox } from '../../../../../ui/checkbox/checkbox';
import { DisplayElements } from '../../../../../../type/display-elements.type';
import { SystemActions } from '../../../../../../redux/system/system.action';

export type SelectData = { daibunrui: string; chubunrui: string; }

const tdAlign: {index: number, align: TdAlign }[] = [
  { index: 0, align: 'center' },
  { index: 1, align: 'left' },
  { index: 2, align: 'left' },
  { index: 6, align: 'right' },
  { index: 7, align: 'right' },
  { index: 8, align: 'right' },
  { index: 9, align: 'right' },
  { index: 10, align: 'right' },
  { index: 11, align: 'right' },
];

type Props = {
  model: EstimateMeisaiModel | null;
  treeData: Partial<SelectTreeData> | null,
  callback: (v: MeisaiListXML) => void;
  callbackModel: (v: EstimateMeisaiModel) => void;
  masterAdd?: boolean;
  allowEdit?: 0 | 1 | 2;
}

type InputTableElementProps = {
  value: string,
  callbackBlur: (v: string | number) => void,
  isNumber?: boolean,
  alignRight?: boolean;
  disabled: boolean;
  isRemark?: boolean;
};

const InputTableElement = (props: InputTableElementProps) => {
  // const dispatch = useDispatch();
  const {
    value: _value,
    isNumber,
    callbackBlur,
    alignRight,
    disabled,
    isRemark,
  } = props;
  const [value, setValue] = useState(_value);
  const handlerChangeValue = useCallback((v) => {
    setValue(v);
  }, []);
  const handlerBlur = useCallback(() => {
    callbackBlur(value);
    if (isNumber && !value) {
      setValue('0');
    }
  }, [value, isNumber, callbackBlur]);

  useEffect(() => {
    setValue(_value);
  }, [_value]);

  return (
    isNumber
      ? (
        <>
          <InputNum
            value={String(value)}
            onBlur={handlerBlur}
            onChange={handlerChangeValue}
            decimalLen={2}
            intLen={8}
            minus
            disabled={disabled}
            className="auto_width"
          />
        </>
      )
      : (
        <Input
          value={value}
          alignRight={alignRight}
          onChange={(e) => handlerChangeValue(e.target.value)}
          disabled={disabled}
          onBlur={handlerBlur}
          className="auto_width"
        />
      )
  );
};

type SuryoInputProps = {
  value: string,
  disabled: boolean;
  onBlur: (v: string | number) => void,
}

const SuryoInput = (props: SuryoInputProps) => {
  const {
    value: _value,
    disabled,
    onBlur: _onBlur,
  } = props;

  const [value, setValue] = useState(_value);

  const onBlur = useCallback((v) => {
    const values = EstimateCalcModelPC.setNumber(
      v,
      (val) => MathHelper.rounding2Str(val, 2, 'round', true),
    );

    setValue(values === '0' ? '0.00' : v);
    _onBlur(values === '0' ? '0.00' : v);
  }, [_onBlur]);

  useEffect(() => {
    setValue(
      MathHelper.rounding2Str(Number(_value), 2, 'round', true),
    );
  }, [_value]);

  return (
    <InputNum
      value={value}
      onBlur={onBlur}
      onChange={setValue}
      decimalLen={2}
      intLen={8}
      minus
      disabled={disabled}
      className="auto_width"
    />
  );
};

export const EstimateMeisaiListPC = (props: Props) => {
  const {
    model, callback, masterAdd, callbackModel, treeData, allowEdit,
  } = props;

  /* Hooks */
  const dispatch = useDispatch();

  /* State */
  const [selected, setSelected] = useState<number[]>(cloneDeep([]));
  const [selectData, setSelectData] = useState<MeisaiListXML[]>([]);

  const list = useMemo(() => {
    if (!model) return null;
    return model.list.filter((v) => {
      const { daibunrui_id, tyubunrui_id } = v.$;
      if (treeData?.tyubunrui_id) {
        return daibunrui_id === treeData.daibunrui_id
          && tyubunrui_id === treeData.tyubunrui_id;
        // && identity_id === '1';
      }
      if (treeData?.daibunrui_id) {
        return daibunrui_id === treeData.daibunrui_id;
        // && identity_id === '1';
      }
      return true;
    });
  }, [model?.list, treeData]);

  const moveDisabled = useMemo(() => !selectData.length || [...selectData].filter(
    (v) => v.$.tyubunrui_id === selectData[0].$.tyubunrui_id,
  ).length !== selectData.length, [selectData]);

  /* Callback */
  const handleClickRow = useCallback((v: MeisaiListXML) => {
    if (!list) return;
    if (!model || allowEdit !== 1) return;
    if (!Object.keys(v).length) {
      setSelectData([]);
      setSelected([]);
      return;
    }
    setSelectData([cloneDeep(v)]);
    callback(cloneDeep(v));
    const findIndex = list.findIndex((v2) => v2.$.id === v.$.id);
    if (findIndex || findIndex === 0 || findIndex !== -1) {
      setSelected([findIndex]);
      return;
    }
    setSelectData([]);
    setSelected([]);
  },
  [callback, list, model, allowEdit]);

  const handleClickMove = useCallback((type: 'up' | 'down') => {
    if (!list) return;
    if (!model || allowEdit !== 1) return;
    const isChanged = model.move(type, selectData);
    if (isChanged) {
      const data:number[] = [];
      selectData.forEach((v) => {
        const index = list.findIndex((v2) => v2.$.id === v.$.id);
        data.push(index + (type === 'up' ? -1 : 1));
      });
      setSelected(cloneDeep(data));
      callbackModel(cloneDeep(model));
    }
  }, [selectData, model, list, allowEdit]);

  const handleClickChangePrintName = useCallback((selectId?: string) => {
    if ((!selectId && selectData.length !== 1) || (!model || allowEdit !== 1)) return;

    const index = selectId
      ? model.list.findIndex((v) => v.$.id === selectId)
      : model.list.findIndex((v) => v.$.id === selectData[0].$.id);
    const { $: value } = model.list[index];
    dispatch(DialogActions.push({
      title: '見積印刷名称設定',
      element: <ChangePrintNameDialogPC
        name={value.name}
        printName={value.print_name}
        callback={(_, p) => {
          if (!model) return;
          value.print_name = p;
          callbackModel(cloneDeep(model));
          dispatch(DialogActions.pop());
        }}
      />,
    }));
  }, [selectData, model, allowEdit]);

  /* 見積明細 新規作成・編集 */
  const handleClickMeisaiEdit = useCallback((mode: 'add' | 'edit' | 'multi', rowData?: MeisaiListXML) => {
    if (!model) return;
    let data: MeisaiListXML | undefined;

    if (mode === 'edit') {
      const findIndex = model?.list.findIndex((v2) => v2.$.id === selectData[0].$.id);
      if (rowData || selectData.length === 1) {
        data = lodash.cloneDeep(rowData || model?.list[findIndex]);
      }
    }
    console.log(selectData);

    dispatch(DialogActions.push({
      title: '見積明細作成',
      element: <EditMeisaiDialogPC
        data={data}
        callback={(v) => {
          dispatch(DialogActions.pop());
          if (!data) {
            model.add(cloneDeep(v));
          } else {
            model.edit(cloneDeep(v), data);
          }
          callbackModel(cloneDeep(model));
          setSelectData([]);
          setSelected([]);
        }}
      />,
    }));
  },
  [selectData, model, treeData]);

  const handleClickMultiEdit = useCallback(() => {
    if (selectData.length < 1 || !model) return;
    dispatch(DialogActions.push({
      title: '見積一括編集',
      element: <MultiEditMeisaiDialogPC
        callback={(v) => {
          model.multiEdit(selectData, v);
          callbackModel(cloneDeep(model));
          setSelectData([]);
          setSelected([]);
        }}
      />,
    }));
  }, [selectData]);

  const handleClickCopy = useCallback(() => {
    if (!model) return;

    // eslint-disable-next-line no-async-promise-executor
    const copy = (): Promise<void> => new Promise<void>(async (resolve) => {
      model?.copyMeisai(selectData)
        .then((v) => {
          callbackModel(cloneDeep(v));
          setSelectData([]);
          setSelected([]);
          resolve();
        });
    });

    dispatch(DialogActions.pushMessage({
      title: '明細の複写',
      message: ['選択した明細を複写します。', 'よろしいですか？'],
      isCancel: true,
      cancelRight: true,
      callback: () => {
        if (selectData.length > 30) {
          dispatch(SystemActions.isLoading(true));
          setTimeout(() => {
            copy().then(() => {
              dispatch(SystemActions.isLoading(false));
            });
          }, 300);
        } else {
          copy();
        }
      },
    }));
  }, [selectData]);

  /* ダブルクリックハンドラ */
  const handleDbClickRow = useCallback(
    (v: MeisaiListXML) => {
      if (allowEdit !== 1) return;
      handleClickRow(cloneDeep(v));
      handleClickMeisaiEdit('edit', cloneDeep(v));
    },
    [handleClickRow, handleClickMeisaiEdit, allowEdit],
  );

  /* 明細削除 */
  const handleClickDelete = useCallback(
    () => {
      if (!selectData.length || !model) return;
      dispatch(DialogActions.pushMessage({
        title: '見積明細削除',
        message: ['削除しますか'],
        isCancel: true,
        callback: () => {
          model.remove(selectData);
          setSelectData([]);
          setSelected([]);
          callbackModel(cloneDeep(model));
          console.log('削除後', model.list);
        },
      }));
    },
    [selectData, treeData, model, allowEdit],
  );

  /* マスタから明細登録 */
  const handleClickAddMasterMeisai = useCallback(() => {
    if (!model || allowEdit !== 1) return;

    dispatch(DialogActions.push({
      title: '見積明細登録',
      className: 'max_height_dialog max_width_dialog',
      element: <AddMasterMeisai
        callback={(data) => {
          dispatch(DialogActions.pop());
          model.addMaster(data);
          callbackModel(cloneDeep(model));
          setSelectData([]);
          setSelected([]);
        }}
      />,
    }));
  },
  [model, allowEdit]);

  const onClickMulti = useCallback((v: MeisaiListXML[]) => {
    if (!list) return;
    if (!model || allowEdit !== 1) return;
    setSelected(v.map((v2) => list.findIndex((v3) => v3.$.id === v2.$.id)));
    const sList:MeisaiListXML[] = [];
    v.forEach((v2) => {
      const findData = list.find((v3) => v2.$.id === v3.$.id);
      if (findData) {
        sList.push(findData);
      }
    });
    setSelectData(sList);
  }, [model, list, allowEdit]);

  const onBlurTableInput = (
    type: 'kikaku' | 'meisai_remark' | 'suryou',
    v: string | number,
    id: string,
  ) => {
    if (!model || allowEdit !== 1) return;
    const index = model.list.findIndex((val) => val.$.id === id);

    if (type !== 'suryou') {
      model.list[index].$[type] = v ? String(v) : '';
    }
    if (type === 'suryou') {
      model.list[index].$.suryou = v ? String(v) : '0.00';
      model.edit(model.list[index].$, cloneDeep(model.list[index]), type === 'suryou');
    }

    if (model) callbackModel(cloneDeep(model));
  };

  const onClickAllCheck = useCallback(() => {
    if (!list) return;
    if (list.length === selected.length) {
      setSelected([]);
      setSelectData([]);
    } else {
      setSelected(list.map((v, i) => i));
      setSelectData(cloneDeep(list));
    }
  }, [list, selected]);

  const onClickCheck = useCallback((index: number, info: MeisaiListXML) => {
    if (selected.includes(index)) {
      setSelected(cloneDeep(selected.filter((v) => v !== index)));
      setSelectData(cloneDeep(selectData.filter((v) => !isEqual(v, info))));
    } else {
      selected.push(index);
      selectData.push(cloneDeep(info));
      setSelected(cloneDeep(selected));
      setSelectData(cloneDeep(selectData));
    }
  }, [selected, selectData]);

  /* Effect */
  useEffect(() => {
    setSelectData([]);
    setSelected([]);
  }, [treeData]);

  const borderSpan = useMemo(() => (list ? [...list || []]
    .map((v, i) => (v.$.tyubunrui_id !== list[i + 1]?.$.tyubunrui_id ? i : false))
    .filter((v) => !!v || v === 0) as number[] : []), [list, model?.list]);

  return (
    <section className="result_area list_area">
      <div className="inner">
        <div className="table_responsive">
          <Table
            className="table_selectable table_sortable table_sticky table_cell_change"
            header={([<Checkbox
              checked={!!(list && list.length === selected.length)}
            />] as DisplayElements[])
              .concat(EstimateCollection.estimateMeisaiHeader)}
            selectedTr={selected}
            rowDataList={list || []}
            onDbClick={handleDbClickRow}
            onClickMulti={onClickMulti}
            onClickRow={handleClickRow}
            sort={{
              notSortIndex: [0],
              index: [0],
              onClick: () => onClickAllCheck(),
            }}
            unDispNoData
            disabledTr={[0]}
            lists={list ? list.map((v, i) => (
              [
                <Checkbox
                  checked={selected.includes(i)}
                  onClick={() => onClickCheck(i, v)}
                />,
                v.$.name,
                <>
                  <Button
                    size="sm"
                    color="secondary"
                    className="mr_5"
                    disabled={allowEdit !== 1}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleClickChangePrintName(v.$.id);
                    }}
                  >
                    変更
                  </Button>{v.$.print_name}
                </>,
                <InputTableElement
                  key={`kikaku${i}`}
                  value={v.$.kikaku}
                  callbackBlur={(val) => onBlurTableInput('kikaku', val, v.$.id)}
                  disabled={allowEdit !== 1}
                />,
                <SuryoInput
                  key={`suryo${v.$.id}${i}`}
                  value={v.$.suryou ? String(v.$.suryou) : '0.00'}
                  onBlur={(val) => onBlurTableInput('suryou', val, v.$.id)}
                  disabled={allowEdit !== 1}
                />,
                v.$.tani_name,
                Number(v.$.shikiri_kakaku).toLocaleString(),
                Number(v.$.kingaku).toLocaleString(),
                Number(v.$.genka).toLocaleString(),
                Number(v.$.genka_kei).toLocaleString(),
                Number(Number(v.$.kingaku) - Number(v.$.genka_kei)).toLocaleString(),
                `${v.$.arari_ritu !== '-' ? Number(v.$.arari_ritu).toLocaleString() : '-'}%`,
                <InputTableElement
                  key={`remark${i}`}
                  value={v.$.meisai_remark}
                  disabled={allowEdit !== 1}
                  callbackBlur={(val) => onBlurTableInput('meisai_remark', val, v.$.id)}
                  isRemark
                />,
              ]
            )) : null}
            option={{ borderSpan, tdAlign }}
          />
        </div>
      </div>
      {(!masterAdd && allowEdit === 1)
        && (
        <div className="btn_box">
          <Button size="sm" color="secondary" onClick={() => handleClickMove('up')} disabled={moveDisabled}>移動 ▲</Button>
          <Button size="sm" color="secondary" onClick={() => handleClickMove('down')} disabled={moveDisabled}>移動 ▼</Button>
          <Button size="sm" color="secondary" onClick={() => handleClickChangePrintName()} disabled={selectData.length !== 1}>印刷名称変更</Button>
          <Button size="sm" color="secondary" onClick={() => handleClickMeisaiEdit('add')}>直接登録</Button>
          <Button size="sm" color="secondary" onClick={() => handleClickCopy()} disabled={!selectData.length}>複写</Button>
          <Button size="sm" color="secondary" onClick={handleClickAddMasterMeisai}>マスタから登録</Button>
          <Button
            size="sm"
            color="secondary"
            onClick={() => (selectData.length === 1
              ? handleClickMeisaiEdit('edit') : handleClickMultiEdit())}
            disabled={!selectData.length}
          >編集
          </Button>
          <Button size="sm" color="dark" onClick={handleClickDelete} disabled={!selectData.length}>削除</Button>
        </div>
        )}
    </section>
  );
};
