import lodash from 'lodash';
import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';
import { DialogActions } from '../../../../../redux/dialog/dialog.action';
import { FileActions } from '../../../../../redux/file/file.action';
import { State } from '../../../../../redux/root.reducer';
import { Store } from '../../../../../redux/store';
import { FileListType, FileSortState } from '../../../../../type/file/file.type';
import { DateFormatter } from '../../../../../utilities/date-formatter';
import { DatePicker } from '../../../../ui/date-picker/date-picker';
import { Input } from '../../../../ui/input/input';
import { SearchBox } from '../../../layout/search-box/search-box.sp';
import { Button } from '../../../../ui/button/button';
import { FileCollection } from '../../../../../collection/file/file.collection';
import { useDateValidation } from '../../../../../hooks/use-date-validation';
import { DateTermValidation } from '../../../../../model/validation/date/term-date.validation';
import { TopLabelInputField } from '../../../../ui/input-field/top-label-input-field/top-label-input-field';
import { Select } from '../../../../ui/select/select';
import { pulldown } from '../../../../../utilities/pulldown';
import { useDidMount } from '../../../../../hooks/life-cycle';
import { MasterActions } from '../../../../../redux/master/master.action';

type Props = {
  callback: (data: FileSortState) => void,
  isSearch?: boolean;
}

export const FileSearchBoxSP = (props:Props) => {
  const { callback, isSearch } = props;
  /* Hooks */
  const { sortState, employeeList } = useSelector((state: State) => ({
    sortState: state.file.sort,
    employeeList: state.master.employeeList,
  }));
  const dispatch = useDispatch();

  /* State */
  const [sort, setSort] = useState(cloneDeep(sortState));

  const termDate = useDateValidation([
    { start: sort.upload_date_start, end: sort.upload_date_end },
    { start: sort.created_at_start, end: sort.created_at_end },
    { start: sort.updated_at_start, end: sort.updated_at_end },
  ]);

  /* Callback */
  const setState = useCallback((v: Partial<FileSortState>) => {
    const data = {
      ...cloneDeep(sort),
      ...cloneDeep(v),
    };
    setSort(data);
  }, [sort, isSearch]);

  const handleClickSearch = useCallback(() => {
    if (termDate.messages.length) {
      termDate.check();
      return;
    }
    callback(sort);
    if (!isSearch) {
      dispatch(FileActions.setSort(sort));
    }
  }, [sort, isSearch, termDate]);

  useDidMount(() => {
    dispatch(MasterActions.api.employee.getList({}));
  });

  return (
    <SearchBox
      callback={handleClickSearch}
      callbackClear={() => setState(FileCollection.initialSortState())}
    >
      <div className="search_box_sp_body_inner">
        <div className="category_wrap">
          <div className="item_wrap">
            <div className="item_label">顧客名</div>
            <div className="item_body">
              <Input
                value={sort?.customer_name}
                onChange={(e) => setState({ customer_name: e.target.value })}
              />
            </div>
          </div>

          <div className="item_wrap">
            <div className="item_label">案件名</div>
            <div className="item_body">
              <Input
                value={sort?.project_name}
                onChange={(e) => setState({ project_name: e.target.value })}
              />
            </div>
          </div>

          <div className="item_wrap">
            <div className="item_label">ファイル名</div>
            <div className="item_body">
              <Input
                value={sort?.file_name}
                onChange={(e) => setState({ file_name: e.target.value })}
              />
            </div>
          </div>

          <div className="item_wrap">
            <div className="item_label">アップロード日</div>
            <div className="item_body item_schedule wrap">
              <div className="item_schedule__form">
                <DatePicker
                  date={sort?.upload_date_start}
                  onChange={(v) => setState({ upload_date_start: v })}
                  term={DateTermValidation([{
                    start: sort.upload_date_start, end: sort.upload_date_end,
                  }])}
                />
              </div>
              <div className="item_schedule__form flex_box flex_align_center mt_10">
                <div className="item_schedule__tilde">〜</div>
                <DatePicker
                  date={sort?.upload_date_end}
                  onChange={(v) => setState({ upload_date_end: v })}
                  term={DateTermValidation([{
                    start: sort.upload_date_start, end: sort.upload_date_end,
                  }])}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="category_wrap">
          <div className="item_wrap">
            <div className="item_label">登録者</div>
            <div className="item_body item_madori">
              <Select
                defaultLabel="全て"
                className="fixed"
                value={sort.created_employee_id}
                onChange={(v) => setState({ created_employee_id: Number(v) })}
                options={pulldown(employeeList)}
              />
            </div>
          </div>
          <div className="item_wrap">
            <div className="item_label">登録日</div>
            <div className="item_body item_schedule wrap">
              <div className="item_schedule__form">
                <DatePicker
                  date={sort.created_at_start}
                  onChange={(v) => setState({ created_at_start: v })}
                  type="YYYY/MM/DD"
                  term={DateTermValidation([
                    { start: sort.created_at_start, end: sort.created_at_end },
                  ])}
                />
              </div>
              <div className="item_schedule__form flex_box flex_align_center mt_10">
                <div className="item_schedule__tilde">〜</div>
                <DatePicker
                  date={sort.created_at_end}
                  onChange={(v) => setState({ created_at_end: v })}
                  type="YYYY/MM/DD"
                  term={DateTermValidation([
                    { start: sort.created_at_start, end: sort.created_at_end },
                  ])}
                />
              </div>
            </div>
          </div>
          <div className="item_wrap">
            <div className="item_label">更新者</div>
            <div className="item_body item_madori">
              <Select
                defaultLabel="全て"
                className="fixed"
                value={sort.updated_employee_id}
                onChange={(v) => setState({ updated_employee_id: Number(v) })}
                options={pulldown(employeeList)}
              />
            </div>
          </div>
          <div className="item_wrap">
            <div className="item_label">更新日</div>
            <div className="item_body item_schedule wrap">
              <div className="item_schedule__form">
                <DatePicker
                  date={sort.updated_at_start}
                  onChange={(v) => setState({ updated_at_start: v })}
                  type="YYYY/MM/DD"
                  term={DateTermValidation([
                    { start: sort.updated_at_start, end: sort.updated_at_end },
                  ])}
                />
              </div>
              <div className="item_schedule__form flex_box flex_align_center mt_10">
                <div className="item_schedule__tilde">〜</div>
                <DatePicker
                  date={sort.updated_at_end}
                  onChange={(v) => setState({ updated_at_end: v })}
                  type="YYYY/MM/DD"
                  term={DateTermValidation([
                    { start: sort.updated_at_start, end: sort.updated_at_end },
                  ])}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </SearchBox>
  );
};
