import { push } from 'connected-react-router';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Config } from '../config/config';

type Props = {
  url: string;
  e: React.MouseEvent<HTMLTableRowElement, MouseEvent>
  callback?: () => void;
}

export const useOpenWindow = () => {
  const dispatch = useDispatch();
  return useCallback((props: Props) => {
    if (Config.isMac ? props.e.altKey : props.e.ctrlKey) {
      // window.open(`${Config.host}/#${props.url}`);
      const a = document.createElement('a');
      a.href = `${Config.host}/#${props.url}`;
      a.target = '_blank';
      a.rel = 'noopener noreferrer';
      document.body.appendChild(a);
      a.click();
      a.remove();
    } else if (!props.callback) {
      dispatch(push(props.url));
    } else {
      props.callback();
    }
  }, []);
};
