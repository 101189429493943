import { cloneDeep, isEqual } from 'lodash';
import { useState, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Select } from '../../../../../ui/select/select';
import { RightLabelInputField } from '../../../../../ui/input-field/right-label-input-field/right-label-input-field';
import { LeftIconButton } from '../../../../../ui/button/left-icon-button/left-icon-button';
import { SearchBoxPC } from '../../../../layout/search-box/search-box.pc';
import { CsvActions } from '../../../../../../redux/csv/csv.action';
import { State } from '../../../../../../redux/root.reducer';
import { CsvWeddingSort } from '../../../../../../type/csv/csv-sort.type';
import { useDidMount } from '../../../../../../hooks/life-cycle';
import { MasterActions } from '../../../../../../redux/master/master.action';
import { Input } from '../../../../../ui/input/input';
import { CommonCollection } from '../../../../../../collection/common/common.collection';
import { DialogActions } from '../../../../../../redux/dialog/dialog.action';
import { ApiCsvWeddingGetListParam } from '../../../../../../redux/csv/api/wedding/api-csv-wedding';

type Props = {
  callback: (v: CsvWeddingSort) => void;
}

export const CsvSearchBoxWedding = (props: Props) => {
  const { callback } = props;

  /* Hooks */
  const dispatch = useDispatch();
  const sortState = useSelector((state: State) => state.csv.weddingSort);
  const {
    storeList,
    employeeList,
  } = useSelector((state: State) => (state.master), isEqual);

  /* State */
  const [sort, setSort] = useState(cloneDeep(sortState));
  const [isDateValid, setIsDateValid] = useState(false);

  const _employeeList = useMemo(() => employeeList.filter((
    v,
  ) => (Number.isNaN(sort.store_id) || sort.store_id === null
    ? true
    : (v.store_id === sort.store_id))),
  [sort.store_id, employeeList]);

  const checkDate = (v: ApiCsvWeddingGetListParam) => {
    const start = Number(v.wedding_anniversary_start_year);
    const end = Number(v.wedding_anniversary_end_year);
    if (!end || !start) return true;
    if (start > end) return false;
    if (start === end) {
      const start_m = Number(v.wedding_anniversary_start_month);
      const end_m = Number(v.wedding_anniversary_end_month);
      if (!start_m || !end_m) return true;
      if (start_m > end_m) return false;
    }
    return true;
  };

  const weddingValidate = useMemo(() => checkDate(sort), [sort]);
  const onlyMonth = useMemo(() => (
    !!(!sort.wedding_anniversary_start_year && sort.wedding_anniversary_start_month)
    || !!(!sort.wedding_anniversary_end_year && sort.wedding_anniversary_end_month)
  ), [sort]);

  const errorMessage = useMemo(() => {
    if (isDateValid) return ['正しい日付範囲で入力してください。'];
    if (onlyMonth) return ['年を入力してください。'];
    return undefined;
  }, [isDateValid, onlyMonth]);

  /* Callback */
  const setState = useCallback((v: CsvWeddingSort, isSelect?: boolean) => {
    setSort(cloneDeep(v));
    if (isSelect) setIsDateValid(!checkDate(v));
  }, [sort, checkDate]);

  const handleClickSearch = useCallback(() => {
    if (errorMessage) {
      dispatch(DialogActions.pushMessage({
        title: 'お知らせ',
        message: ['入力された検索項目は無効です。'],
      }));
      return;
    }

    callback({
      ...cloneDeep(sort),
      limit: sortState.limit,
    });
    dispatch(CsvActions.setWeddingSort(cloneDeep({
      ...cloneDeep(sort),
      limit: sortState.limit,
    })));
  }, [callback, sort, weddingValidate, sortState, errorMessage]);

  /* Master */
  useDidMount(() => {
    dispatch(MasterActions.api.store.getList({}));
    dispatch(MasterActions.api.employee.getList({}));
  });

  return (
    <>
      <SearchBoxPC
        isDetail={false}
        openCallback={() => {}}
      >
        <div id="csv_wedding">
          <div className="item_wrap">
            <div className="item_box">
              <div className="item_head">営業担当</div>
              <Select
                className="add_text_left"
                label="店舗"
                value={sort?.store_id}
                onChange={(data) => setSort({ ...sort, store_id: Number(data) })}
                defaultLabel="指定無し"
                options={storeList.map((v) => ({
                  text: v.name, value: v.id,
                }))}
              />
              <Select
                className="add_text_left"
                label="担当者"
                value={sort?.employee_id}
                onChange={(data) => setSort({ ...sort, employee_id: Number(data) })}
                defaultLabel="指定無し"
                options={_employeeList.map((v) => ({
                  text: v.name, value: v.id,
                }))}
              />
            </div>
          </div>
          <div className="item_wrap">
            <div className="item_box">
              <div className="item_head">顧客名</div>
              <Input
                className=""
                value={sort?.name}
                onChange={(e) => setState({ ...sort, name: e.target.value })}
              />
            </div>

            <div className="item_box">
              <div className="item_head">結婚記念日</div>
              <RightLabelInputField
                className="small"
                label="年"
                type="number"
                value={sort?.wedding_anniversary_start_year}
                onChange={(e) => {
                  setState({ ...sort, wedding_anniversary_start_year: e.target.value });
                }}
                onBlur={() => setIsDateValid(!weddingValidate)}
                errorMessage={errorMessage}
              />
              <Select
                className="add_text_left"
                value={sort?.wedding_anniversary_start_month}
                onChange={(v) => setState(
                  { ...sort, wedding_anniversary_start_month: Number(v) },
                  true,
                )}
                options={[
                  { text: '全て', value: 0 },
                  ...CommonCollection.month.map((v) => ({
                    text: v.text, value: v.value,
                  })),
                ]}
                // onBlur={() => setIsDateValid(!weddingValidate)}
                errorMessage={errorMessage}
              />月 〜
              <RightLabelInputField
                className="small"
                label="年"
                type="number"
                value={sort?.wedding_anniversary_end_year}
                onChange={(e) => {
                  setState({ ...sort, wedding_anniversary_end_year: e.target.value });
                }}
                onBlur={() => setIsDateValid(!weddingValidate)}
                errorMessage={errorMessage}
              />
              <Select
                className="add_text_left"
                value={sort?.wedding_anniversary_end_month}
                onChange={(v) => setState({
                  ...sort, wedding_anniversary_end_month: Number(v),
                }, true)}
                options={[
                  { text: '全て', value: 0 },
                  ...CommonCollection.month.map((v) => ({
                    text: v.text, value: v.value,
                  })),
                ]}
                // onBlur={() => setIsDateValid(!weddingValidate)}
                errorMessage={errorMessage}
              />月
            </div>
            <LeftIconButton
              label="検索"
              fontAwesomeClass="fas fa-search"
              className="btn_search for_detail"
              size="sm"
              color="secondary"
              onClick={handleClickSearch}
            />
          </div>
        </div>
      </SearchBoxPC>
    </>
  );
};
