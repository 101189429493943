import actionCreatorFactory from 'typescript-fsa';
import { ApiBase } from '../../../service/api-base';
import { RequestBaseCollection } from '../../../service/api.collection';

export const ActionCreator = actionCreatorFactory('call-db/api');

export const apiCallDB = {
  get: ActionCreator<{
    param: any;
      }>('get'),
};

class ApiCallDB<T = any> extends ApiBase<T> {
  constructor(param: any) {
    super({
      ...RequestBaseCollection(),
      ...param,
      url: '/api/common/test',
    });
  }
}

export class ApiCallDBGet extends ApiCallDB<any> {
  constructor(param: any) {
    super({
      httpMethod: 'GET',
      param,
    });
  }
}
