/* eslint-disable no-nested-ternary */
import { cloneDeep, isEqual } from 'lodash';
import { ApiProjectGetListParam, ApiProjectPostParam } from '../../redux/project/api/project/api-project.type';
import { Address, MapAreaPosition } from '../../type/map/map.type';
import { ProjectEditState, ProjectSortState, Project } from '../../type/project/project.type';
import { DateFormatter } from '../../utilities/date-formatter';
import { AddressSearch } from '../../type/map/address-search.type';
import { TagType } from '../../type/tag/tag.type';
import { TagModel } from '../tag/tag';
import { MapAddressModel } from '../map/map-address.model';
import { CustomerListType, Customer } from '../../type/customer/customer.type';
import { deleteCommaStr } from '../../utilities/delete-comma';
import { minus0, plus0 } from '../../components/ui/search-post-address-dialog/search-post-address-dialog';
import { User } from '../../type/auth/user.typs';
import { ProjectCollection } from '../../collection/project/project.collection';

const df = (v: Date | null | undefined) => DateFormatter.date2str(v, 'YYYYMMDD', '-');

export type ProjectSearchMode = 'All' | 'No' | 'Construction' | 'Complete';

export class ProjectModel {
  /** APIGet */
  static setEditData(param: {
    project: ProjectEditState;
    data: Project;
    partList: TagType[];
  }):ProjectEditState {
    const { project, data, partList } = param;
    return ({
      ...project,
      ...data,
      field_post_no: [data.field_post_no.slice(0, 3), data.field_post_no.slice(3, 7)],
      construction_parts: new TagModel(partList, data.construction_parts) || new TagModel(partList),
      construction_date: data.construction_date ? new Date(data.construction_date) : null,
      completion_date: data.completion_date ? new Date(data.completion_date) : null,
      complete_date: data.complete_date ? new Date(data.complete_date) : null,
      failure_date: data.failure_date ? new Date(data.failure_date) : null,
      cancel_date: data.cancel_date ? new Date(data.cancel_date) : null,
      contract_date: data.contract_date ? new Date(data.contract_date) : null,
      construction_period_start: data.construction_period_start
        ? new Date(data.construction_period_start) : null,
      construction_period_end: data.construction_period_end
        ? new Date(data.construction_period_end) : null,
      construction_start_date: data.construction_start_date
        ? new Date(data.construction_start_date) : null,
      completion_end_date: data.completion_end_date
        ? new Date(data.completion_end_date) : null,
      valid_flag: 1,
      field_prefecture: data.field_prefecture
        ? data.field_prefecture
        : data.field_jisx0401_code
          ? Number(minus0(data.field_jisx0401_code))
          : NaN,
    });
  }

  /** 顧客詳細內一覧取得 */
  static listParamInCustomer(param: {
    customerID: number;
    sortState: ProjectSortState;
  }): ApiProjectGetListParam {
    const {
      sortState, customerID,
    } = param;

    let sort_by = 2;
    if (sortState.sort_by < 0) sort_by = 2;
    if (!sortState.sort_by)sort_by = 6;
    if (sortState.sort_by === 1)sort_by = 18;
    if (sortState.sort_by === 2)sort_by = 16;
    if (sortState.sort_by === 3)sort_by = 19;
    if (sortState.sort_by === 4)sort_by = 11;
    if (sortState.sort_by === 5)sort_by = 12;
    if (sortState.sort_by === 6)sort_by = 20;
    if (sortState.sort_by === 7)sort_by = 14;
    if (sortState.sort_by === 8)sort_by = 17;
    if (sortState.sort_by === 9)sort_by = 21;
    if (sortState.sort_by === 10)sort_by = 22;
    if (sortState.sort_by === 11) sort_by = 15;

    return {
      customer_id: customerID,
      name: sortState.name,
      project_employee_id: sortState.project_employee_id,
      project_store_id: sortState.project_store_id,
      entry_dt: df(sortState.entry_dt),
      construction_date: df(sortState.construction_date),
      completion_date: df(sortState.completion_date),
      complete_date: df(sortState.complete_date),
      contract_date: df(sortState.contract_date),
      failure_date: df(sortState.failure_date),
      cancel_date: df(sortState.cancel_date),
      source_id: sortState.source_id,
      remarks: sortState.remarks,
      sort_by,
      highlow: sortState.highlow,
      offset: sortState.offset,
      limit: 9999,
      valid_flag: 1,
      mitumori_kin: deleteCommaStr(sortState.mitumori_kin),
      mitumori_kin_min: deleteCommaStr(sortState.mitumori_kin_min),
      mitumori_kin_max: deleteCommaStr(sortState.mitumori_kin_max),
      order_price: deleteCommaStr(sortState.order_price),
      order_price_min: deleteCommaStr(sortState.order_price_min),
      order_price_max: deleteCommaStr(sortState.order_price_max),
    };
  }

  /** 一覧取得 */
  static listParam(param: {
    sortState: ProjectSortState;
    mapAreaPos?: MapAreaPosition | null;
    showType?: 'map' | 'list';
    isMap?: boolean;
    isSp?: boolean;
    customerID?: number;
    isDetail?: boolean;
  type?: 'estimate' | 'order' | 'maintenance' | 'support' | 'file';
  searchBox?: boolean;
    isMapList?: boolean;
    isInit?: boolean;
  }): ApiProjectGetListParam {
    const {
      sortState, showType, mapAreaPos,
      isSp, customerID, isDetail, isMap, type, searchBox, isMapList,
    } = param;
    let status: number[] = [];
    if (type === 'estimate' || type === 'order') status = [1, 2, 3];
    if (type === 'maintenance' && param.isInit) status = [3, 4, 6];
    const construction_status = sortState.construction_status?.getSendData() ?? [];
    const findIndex = construction_status.findIndex((v) => v === 10);
    if (findIndex > -1) {
      construction_status.splice(findIndex, 1);
      construction_status.unshift(1, 2);
    }
    return {
      ...sortState,
      limit: isDetail || showType === 'map' ? 99999 : sortState.limit,
      valid_flag: 1,
      customer_id: customerID,
      name: sortState.name,
      source_ids: sortState.source_ids?.getSendData(),
      construction_parts: sortState.construction_parts?.getSendData(),
      construction_status: status.length ? status : construction_status,
      contract_date_from: DateFormatter.date2str(sortState.contract_date_from, 'YYYYMMDD', '-'),
      contract_date_to: DateFormatter.date2str(sortState.contract_date_to, 'YYYYMMDD', '-'),
      construction_period_start_from: DateFormatter.date2str(sortState.construction_period_start_from, 'YYYYMMDD', '-'),
      construction_period_start_to: DateFormatter.date2str(sortState.construction_period_start_to, 'YYYYMMDD', '-'),
      construction_period_end_from: DateFormatter.date2str(sortState.construction_period_end_from, 'YYYYMMDD', '-'),
      construction_period_end_to: DateFormatter.date2str(sortState.construction_period_end_to, 'YYYYMMDD', '-'),
      construction_start_date_from: DateFormatter.date2str(sortState.construction_start_date_from, 'YYYYMMDD', '-'),
      construction_start_date_to: DateFormatter.date2str(sortState.construction_start_date_to, 'YYYYMMDD', '-'),
      construction_date_from: DateFormatter.date2str(sortState.construction_date_from, 'YYYYMMDD', '-'),
      construction_date_to: DateFormatter.date2str(sortState.construction_date_to, 'YYYYMMDD', '-'),
      completion_end_date_from: DateFormatter.date2str(sortState.completion_end_date_from, 'YYYYMMDD', '-'),
      completion_end_date_to: DateFormatter.date2str(sortState.completion_end_date_to, 'YYYYMMDD', '-'),
      completion_date_from: DateFormatter.date2str(sortState.completion_date_from, 'YYYYMMDD', '-'),
      completion_date_to: DateFormatter.date2str(sortState.completion_date_to, 'YYYYMMDD', '-'),
      construction_execution_date_from: DateFormatter.date2str(sortState.construction_execution_date_from, 'YYYYMMDD', '-'),
      construction_execution_date_to: DateFormatter.date2str(sortState.construction_execution_date_to, 'YYYYMMDD', '-'),
      completion_execution_date_from: DateFormatter.date2str(sortState.completion_execution_date_from, 'YYYYMMDD', '-'),
      completion_execution_date_to: DateFormatter.date2str(sortState.completion_execution_date_to, 'YYYYMMDD', '-'),
      complete_date_from: DateFormatter.date2str(sortState.complete_date_from, 'YYYYMMDD', '-'),
      complete_date_to: DateFormatter.date2str(sortState.complete_date_to, 'YYYYMMDD', '-'),
      failure_date_from: DateFormatter.date2str(sortState.failure_date_from, 'YYYYMMDD', '-'),
      failure_date_to: DateFormatter.date2str(sortState.failure_date_to, 'YYYYMMDD', '-'),
      cancel_date_from: DateFormatter.date2str(sortState.cancel_date_from, 'YYYYMMDD', '-'),
      cancel_date_to: DateFormatter.date2str(sortState.cancel_date_to, 'YYYYMMDD', '-'),
      entry_dt: DateFormatter.date2str(sortState.entry_dt, 'YYYYMMDD', '-'),
      construction_date: DateFormatter.date2str(sortState.construction_date, 'YYYYMMDD', '-'),
      completion_date: DateFormatter.date2str(sortState.completion_date, 'YYYYMMDD', '-'),
      complete_date: DateFormatter.date2str(sortState.complete_date, 'YYYYMMDD', '-'),
      contract_date: DateFormatter.date2str(sortState.contract_date, 'YYYYMMDD', '-'),
      failure_date: DateFormatter.date2str(sortState.failure_date, 'YYYYMMDD', '-'),
      cancel_date: DateFormatter.date2str(sortState.cancel_date, 'YYYYMMDD', '-'),
      north_lat: isMap && !isMapList && mapAreaPos?.n.lat ? String(mapAreaPos?.n.lat) : undefined,
      north_lng: isMap && !isMapList && mapAreaPos?.n.lng ? String(mapAreaPos?.n.lng) : undefined,
      south_lat: isMap && !isMapList && mapAreaPos?.s.lat ? String(mapAreaPos?.s.lat) : undefined,
      south_lng: isMap && !isMapList && mapAreaPos?.s.lng ? String(mapAreaPos?.s.lng) : undefined,
      mitumori_kin: deleteCommaStr(sortState.mitumori_kin),
      mitumori_kin_min: deleteCommaStr(sortState.mitumori_kin_min),
      mitumori_kin_max: deleteCommaStr(sortState.mitumori_kin_max),
      order_price: deleteCommaStr(sortState.order_price),
      order_price_min: deleteCommaStr(sortState.order_price_min),
      order_price_max: deleteCommaStr(sortState.order_price_max),
      created_at_start: DateFormatter.date2str(sortState.created_at_start, 'YYYYMMDD', '-'),
      created_at_end: DateFormatter.date2str(sortState.created_at_end, 'YYYYMMDD', '-'),
      updated_at_start: DateFormatter.date2str(sortState.updated_at_start, 'YYYYMMDD', '-'),
      updated_at_end: DateFormatter.date2str(sortState.updated_at_end, 'YYYYMMDD', '-'),
    };
  }

  /** 顧客情報 */
  static setCustomerData(data:CustomerListType | Customer, user?: User): Partial<ProjectEditState> {
    return ({
      customer_id: data.id,
      project_store_id: Number(user ? user.store_id : data.store_id) || NaN,
      project_employee_id: Number(user ? user.id : data.employee_id) || NaN,
      field_name: `${data.name ? `${data.name}様` : ''}`,
      field_post_no: [data.post_no.slice(0, 3), data.post_no.slice(3, 7)],
      field_prefecture: Number(data.prefecture) || NaN,
      field_city: data.city || '',
      field_address: data.address || '',
      field_building_name: data.building_name || '',
      field_tel_no: data.tel_no || '',
    });
  }

  /** 地図から入力 */
  static registMap(components: MapAddressModel):Partial<ProjectEditState> {
    return ({
      field_post_no: [components.postal1, components.postal2],
      field_prefecture: components.prefecture,
      field_city: components.city,
      field_address: components.address,
      field_building_name: components.bill,
    });
  }

  /** 住所入力 */
  static setAddress(address: AddressSearch):Partial<ProjectEditState> {
    return ({
      field_prefecture: Number(address.prefcode) || NaN,
      field_city: address.ja.address1 || '',
      field_address: address.ja.address2 || '',
    });
  }

  /** 保存 */
  static postParam(param: {
    project: ProjectEditState;
    data: {
      position: globalThis.google.maps.LatLngLiteral;
      address: string;
      constructionParts?: TagModel | null;
    },
  }):ApiProjectPostParam['data'] {
    const { project, data } = param;
    return ({
      ...project,
      failure_cause: project.failure_cause || '',
      project_source_id: project.source_id,
      field_post_no: `${project.field_post_no[0]}${project.field_post_no[1]}`,
      contract_date: df(project.contract_date),
      construction_parts: data.constructionParts
        ? data.constructionParts.getSendData()
        : project.construction_parts?.getSendData(),
      construction_date: df(project.construction_date),
      completion_end_date: df(project.completion_end_date),
      construction_start_date: df(project.construction_start_date),
      construction_period_start: df(project.construction_period_start),
      construction_period_end: df(project.construction_period_end),
      completion_date: df(project.completion_date),
      complete_date: df(project.complete_date),
      failure_date: df(project.failure_date),
      cancel_date: df(project.cancel_date),
      lat: String(data.position.lat),
      lng: String(data.position.lng),
      valid_flag: 1,
      field_jisx0401_code: project.field_prefecture
        ? plus0(String(project.field_prefecture))
        : undefined,
    });
  }

  static registrationMap(param:{project:ProjectEditState, address: Address}) {
    const { project, address } = param;
    const { components } = address;
  }

  static searchAddress(param: {
    project: ProjectEditState, address: AddressSearch;
  }) {
    const { project, address } = param;
  }

  static changeMode(param: {
    mode: ProjectSearchMode,
  }): TagModel | null {
    const { mode } = param;
    const model = new TagModel(ProjectCollection.constructionStatusList);
    if (!model) {
      console.log('------');
      return null;
    }
    /** 案件化 id1 */
    /** 見積中 id2 */
    /** 工事中 id3 */
    /** 完工 id4 */
    /** 完了 id6 */
    /** 失注 id7 */
    /** キャンセル id8 */

    switch (mode) {
      case 'All':
        break;
      case 'No':
        model.changeFlag(1);
        model.changeFlag(2);
        break;
      case 'Construction':
        model.changeFlag(3);
        break;
      case 'Complete':
        model.changeFlag(4);
        model.changeFlag(6);
        break;
      default:
        break;
    }
    return cloneDeep(model);
  }

  static CheckMode(mode: ProjectSearchMode, model: TagModel | null) {
    if (!model) {
      if (mode === 'All') return true;
      return false;
    }
    const data = model.getSendData();

    switch (mode) {
      case 'All':
        if (isEqual(data, []) || isEqual(data, [1, 2, 3, 4, 6, 7, 8])) {
          return true;
        }
        break;
      case 'No':
        if (isEqual(data, [1, 2])) return true;
        break;
      case 'Construction':
        if (isEqual(data, [3])) return true;
        break;
      case 'Complete':
        if (isEqual(data, [4, 6])) return true;
        break;
      default:
        return false;
    }
    return false;
  }
}
