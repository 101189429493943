import {
  useCallback, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cloneDeep, isEqual } from 'lodash';
import * as DateFns from 'date-fns';
import { DialogActions } from '../../../../../redux/dialog/dialog.action';
import { Button } from '../../../../ui/button/button';
import { RightLabelCheckbox } from '../../../../ui/checkbox/right-label-checkbox/right-label-checkbox';
import { DatePicker } from '../../../../ui/date-picker/date-picker';
import './maintenance-edit.pc.scss';
import { useDidMount } from '../../../../../hooks/life-cycle';
import { MaintenanceCollection } from '../../../../../collection/maintenance/maintenance.collection';
import { MaintenanceActions } from '../../../../../redux/maintenance/maintenance.action';
import { EditPC } from '../../../../dialogs/edit/edit.pc';
import { Project, ProjectListType } from '../../../../../type/project/project.type';
import { Input } from '../../../../ui/input/input';
import { DateFormatter } from '../../../../../utilities/date-formatter';
import { ProjectSearch } from '../../../layout/search-box/project/project-search/project-search';
import { Required } from '../../../../ui/required/required';
import { State } from '../../../../../redux/root.reducer';
import { TextArea } from '../../../../ui/text-area/text-area';
import { Checkbox } from '../../../../ui/checkbox/checkbox';
import { ValidationLengthUnder255, ValidationLengthUnder500, ValidationLengthUnder60 } from '../../../../../model/validation';
import { MaintenanceValidation } from '../../../../../model/validation/maintenance/maintenance.validation';
import { ValidationDatePicker } from '../../../../../model/validation/validation-date-picker';
import { Customer } from '../../../../../type/customer/customer.type';
import { MaintenanceModel } from '../../../../../model/maintenance/maintenance.model';
import { Message } from '../../../../../collection/message.collection';
import { ProjectActions } from '../../../../../redux/project/project.action';
import { useEditAuthMaintenance } from '../../../../../hooks/use-authority';
import { Maintenance } from '../../../../../type/maintenance/maintenance.type';

type Props = {
  id?: number;
  projectData?: Project;
  customerData?: Customer;
  callback?: () => void;
  callbackGetList?: () => void;
}

export const MaintenanceEditPC = (props: Props) => {
  const {
    id, projectData, customerData, callback, callbackGetList,
  } = props;

  const sortState = useSelector((state: State) => (state.maintenance.sort), isEqual);
  const user = useSelector((state: State) => state.user);

  /* Hooks */
  const dispatch = useDispatch();

  /* State */
  const [maintenance, setMaintenance] = useState(MaintenanceCollection.editInitialState);
  const [projectName, setProjectName] = useState('');
  const [customerName, setCustomerName] = useState('');
  const [customerRank, setCustomerRank] = useState('');
  const [fieldName, setFieldName] = useState('');
  const [responsibleStore, setResponsibleStore] = useState('');
  const [responsibleEmployee, setResponsibleEmployee] = useState('');
  const [constructionDate, setConstructionDate] = useState('');
  const [contractedDate, setContractedDate] = useState('');
  const [completionDate, setCompletionDate] = useState('');
  const [touch, setTouch] = useState(false);
  // const [disabled, setDisabled] = useState(false);
  const [info, setInfo] = useState<Maintenance | null>(null);

  const disabled = !useEditAuthMaintenance(info);
  console.log(disabled);

  /* Memo */
  const today = useMemo(() => new Date(), []);

  /* Callback */
  const setProjectData = useCallback((v: Project | ProjectListType) => {
    setProjectName(v.name);
    setCustomerName(v.customer_name);
    setFieldName(v.field_name);
    // setCustomerRank();
    setContractedDate(DateFormatter.date2str(v.contract_date));
    setConstructionDate(DateFormatter.date2str(v.construction_date));
    setCompletionDate(DateFormatter.date2str(v.completion_date));
    setContractedDate(DateFormatter.date2str(v.contract_date));
    setMaintenance({
      ...cloneDeep(maintenance),
      customer_id: v.customer_id,
      project_id: v.id,
    });
  }, [maintenance]);

  const dateCalc = useCallback((ym:'y' | 'm', after:number) => {
    let afterDate = cloneDeep(today);
    if (ym === 'm') {
      afterDate = DateFns.addMonths(afterDate, after);
    } else {
      afterDate = DateFns.addYears(afterDate, after);
    }
    setMaintenance({
      ...maintenance,
      title: `${customerName}様<${projectName}> ${after}${ym === 'y' ? '年' : 'ヶ月'}後メンテ`,
      maintenance_date: afterDate,
    });
  }, [today, maintenance, customerName, projectName]);

  const handleClickPost = useCallback(() => {
    if (MaintenanceValidation(maintenance)) {
      dispatch(DialogActions.pushMessage({
        title: 'メンテナンス情報入力',
        message: Message.postError,
        callback: () => setTouch(true),
      }));
      return;
    }
    /* 保存API */
    dispatch(MaintenanceActions.api.maintenance.post({
      param: {
        data: MaintenanceModel.postParam({ maintenance }),
        id,
      },
      update: !!id,
      onSuccess: () => {
        callback?.();
        callbackGetList?.();
      },
    }));
  },
  [maintenance, id, sortState, callback]);

  const handleClickProjectSearch = useCallback((customer?: Customer) => {
    dispatch(DialogActions.push({
      title: '案件検索',
      className: 'max_height_dialog max_width_dialog search_dialog',
      onCloseClick: () => dispatch(DialogActions.pop()),
      element: <ProjectSearch
        type="maintenance"
        callback={(v) => {
          dispatch(ProjectActions.api.project.get({
            param: {
              id: v.id,
            },
            callback: ((data) => {
              setProjectData(cloneDeep(data));
              setResponsibleEmployee(data.project_employee_name);
              setResponsibleStore(data.project_store_name);
            }),
          }));
        }}
        customerData={customer}
      />,
    }));
  }, [setProjectData]);

  /* Effect */
  useDidMount(() => {
    /* 案件から新規登録 */
    if (!id && projectData) {
      setProjectData(projectData);
      return;
    }

    /* 顧客から新規登録 */
    if (!id && customerData) {
      handleClickProjectSearch(customerData);
      return;
    }

    /* 新規登録 */
    if (!id) {
      handleClickProjectSearch();
      return;
    }

    /* 編集 */
    if (id !== undefined) {
      dispatch(MaintenanceActions.api.maintenance.get({
        param: { id },
        callback: (v) => {
          setInfo(cloneDeep(v));
          setConstructionDate(v.construction_date || '');
          setCompletionDate(v.completion_date || '');
          setContractedDate(v.contract_date || '');
          setProjectName(v.project_name);
          setCustomerName(v.customer_name);
          setMaintenance({
            ...v,
            maintenance_date: v.maintenance_date ? new Date(v.maintenance_date) : null,
            supported_date: v.supported_date ? new Date(v.supported_date) : null,
            supported_kubun: v.supported_kubun ? 1 : 0,
            is_muko: v.is_valid ? 0 : 1,
          });
          setCustomerRank(v.rank_name);
          setFieldName(v.project_field_name);
          setResponsibleStore(v.project_store_name);
          setResponsibleEmployee(v.project_employee_name);
        },
      }));
    }
  });

  return (
    <EditPC mode="dialog" disabled={disabled} callback={handleClickPost} label={id ? '更新' : undefined}>
      <div className="">
        <div className="item_wrap">
          <div className="item_box flex_grow_1">
            <div className="item_head">案件名<Required /></div>
            <Input
              className="large"
              value={projectName}
              require
              disabled
            />
            {!id
            && (
            <Button
              size="sm"
              color="secondary"
              className="ml_10 py_0"
              onClick={() => handleClickProjectSearch()}
            >
              案件参照
            </Button>
            )}
          </div>
        </div>

        <div className="item_wrap">
          <div className="item_box">
            <div className="item_head">顧客名<Required /></div>
            <Input
              className="large"
              value={customerName}
              require
              disabled
            />
          </div>
        </div>
        <div className="item_wrap">
          <div className="item_box">
            <div className="item_head">顧客ランク</div>
            <Input
              className="large"
              value={customerRank}
              disabled
            />
          </div>
        </div>
        <div className="item_wrap">
          <div className="item_box flex_grow_1">
            <div className="item_head">現場名称</div>
            <Input
              className="large"
              value={fieldName}
              disabled
            />
          </div>
        </div>
        <div className="item_wrap">
          <div className="item_box">
            <div className="item_head">契約日</div>
            <Input value={DateFormatter.date2str(contractedDate)} disabled />
          </div>
        </div>
        <div className="item_wrap">
          <div className="item_box">
            <div className="item_head">着工日</div>
            <Input value={DateFormatter.date2str(constructionDate)} disabled />
            <label className="ml_10">〜</label>
            <div className="item_head">完工日</div>
            <Input value={DateFormatter.date2str(completionDate)} disabled />
          </div>
        </div>
        <div className="item_wrap">
          <div className="item_box">
            <div className="item_head">担当店舗</div>
            <Input
              className="large"
              value={responsibleStore}
              require
              disabled
            />
          </div>
        </div>
        <div className="item_wrap">
          <div className="item_box">
            <div className="item_head">担当者</div>
            <Input
              className="large"
              value={responsibleEmployee}
              disabled
            />
          </div>
        </div>
        <div className="item_wrap">
          <div className="item_box item_maintenance">
            <div className="item_head">メンテナンス日<Required /></div>
            <DatePicker
              date={maintenance.maintenance_date}
              onChange={(v) => setMaintenance(
                { ...maintenance, maintenance_date: v },
              )}
              require
              touch={touch}
              disabled={disabled}
            />
            <Button
              size="sm"
              color="secondary"
              className="ml_10"
              onClick={() => dateCalc('m', 1)}
              disabled={disabled}
            >
              1ヶ月
            </Button>
            <Button
              size="sm"
              color="secondary"
              className="ml_10"
              onClick={() => dateCalc('m', 3)}
              disabled={disabled}
            >
              3ヶ月
            </Button>
            <Button
              size="sm"
              color="secondary"
              className="ml_10"
              onClick={() => dateCalc('m', 6)}
              disabled={disabled}
            >
              6ヶ月
            </Button>
            <Button
              size="sm"
              color="secondary"
              className="ml_10"
              onClick={() => dateCalc('y', 1)}
              disabled={disabled}
            >
              1年
            </Button>
            <Button
              size="sm"
              color="secondary"
              className="ml_10"
              onClick={() => dateCalc('y', 2)}
              disabled={disabled}
            >
              2年
            </Button>
            <Button
              size="sm"
              color="secondary"
              className="ml_10"
              onClick={() => dateCalc('y', 5)}
              disabled={disabled}
            >
              5年
            </Button>
          </div>
        </div>
        <div className="item_wrap">
          <div className="item_box large">
            <div className="item_head">タイトル<Required /></div>
            <Input
              className="large"
              value={maintenance?.title}
              onChange={(e) => setMaintenance({
                ...maintenance,
                title: e.target.value,
              })}
              require
              validationList={ValidationLengthUnder60}
              touch={touch}
              disabled={disabled}
            />
          </div>
        </div>
        <div className="item_wrap">
          <div className="item_box large">
            <div className="item_head">詳細内容</div>
            <TextArea
              className="large"
              rows={7}
              value={maintenance?.detail}
              onChange={(e) => setMaintenance({
                ...maintenance,
                detail: e.target.value,
              })}
              validationList={ValidationLengthUnder500}
              touch={touch}
              disabled={disabled}
            />
          </div>
        </div>
        <div className="item_wrap">
          <div className="item_box">
            <div className="item_head">対応日</div>
            <DatePicker
              date={maintenance?.supported_date || null}
              onChange={(v) => setMaintenance({
                ...maintenance,
                supported_date: v,
              })}
              disabled={disabled}
            />
          </div>
        </div>
        <div className="item_wrap">
          <div className="item_box large">
            <div className="item_head">対応内容</div>
            <TextArea
              className="large"
              rows={7}
              value={maintenance?.supported_content}
              onChange={(e) => setMaintenance({
                ...maintenance,
                supported_content: e.target.value,
              })}
              validationList={ValidationLengthUnder500}
              touch={touch}
              disabled={disabled}
            />
          </div>
        </div>
        <div className="item_wrap">
          <div className="item_box large">
            <div className="item_head">対応済みフラグ</div>
            <RightLabelCheckbox
              checked={Boolean(maintenance?.supported_kubun)}
              label=""
              onChange={() => setMaintenance({
                ...maintenance,
                supported_kubun: maintenance.supported_kubun ? 0 : 1,
              })}
              disabled={disabled}
            />
          </div>
        </div>
        <div className="item_wrap">
          <div className="item_box large">
            <div className="item_head">無効フラグ</div>
            <Checkbox
              checked={Boolean(maintenance?.is_muko)}
              onChange={() => setMaintenance({
                ...maintenance,
                is_muko: maintenance.is_muko ? 0 : 1,
              })}
              disabled={disabled}
            />
          </div>
        </div>
        <div className="item_wrap">
          <div className="item_box">
            <div className="item_head">登録日</div>
            <Input
              value={DateFormatter.date2str(info?.created_at, 'YYYYMMDD_HHmm')}
              className="size_datepicker add_time"
              disabled
            />
          </div>
          <div className="item_box">
            <div className="item_head">登録者</div>
            <Input
              value={info?.created_employee_name || ''}
              className=""
              disabled
            />
          </div>
        </div>
        <div className="item_wrap">
          <div className="item_box">
            <div className="item_head">更新日</div>
            <Input
              value={DateFormatter.date2str(info?.updated_at, 'YYYYMMDD_HHmm')}
              className="size_datepicker add_time"
              disabled
            />
          </div>
          <div className="item_box">
            <div className="item_head">更新者</div>
            <Input
              value={info?.updated_employee_name || ''}
              className=""
              disabled
            />
          </div>
        </div>
      </div>
    </EditPC>
  );
};
