import { useSelector } from 'react-redux';
import './loading.scss';
import { Dimmer, Loader } from 'semantic-ui-react';
import { useLocation } from 'react-router-dom';
import { State } from '../../../redux/root.reducer';

type LoadingProps = {
  inverted?: boolean,
  dimmer?: boolean,
  content?: string,
}

export const Loading = (props: LoadingProps) => {
  const { dimmer = true, inverted = false, content = 'Loading' } = props;
  const isLoading = useSelector((state: State) => state.system.isLoadingCount);
  const dialogsLength = useSelector((state: State) => state.dialog.dialogs).length;
  const path = useLocation().search;
  return isLoading > 0 && (!path.match('map') || (path.match('map') && dialogsLength)) ? (
    <Dimmer active inverted={inverted} style={{ zIndex: 10000000 }}>
      <Loader size="massive" inverted={inverted} content={content} />
    </Dimmer>
  ) : (
    <></>
  );
};
