import { isEqual } from 'lodash';
import {
  memo,
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';
import { State } from '../../../../../redux/root.reducer';
import { useDidMount, useWillUnMount } from '../../../../../hooks/life-cycle';
import { MasterActions } from '../../../../../redux/master/master.action';
import { MeisaiListXML } from '../../../../../model/estimate/estimate-meisai.type';
import { EstimateMeisaiModel } from '../../../../../model/estimate/estimate-meisai.model';
import { Select } from '../../../../ui/select/select';
import { EstimateCollection } from '../../../../../collection/estimate/estimate.collection';
import { Table } from '../../../../ui/table/table';
import { tableSort } from '../../../../../utilities/table-sort';
import { useAppSelector } from '../../../../../hooks/use-redux';
import { MathHelper } from '../../../../../utilities/math-helper';
import { MasterLargeCategory } from '../../../../../type/master/master-large-category.type';

const sortKey: (keyof MeisaiListXML['$'])[] = [
  'daibunrui_name',
  'tyubunrui_name',
  'name',
  'kingaku',
  'id', // FIXME
  'print_name',
  'kikaku',
  'suryou',
  'tani_name',
  'id', // FIXME
  'id', // FIXME
];

type Props = {
  meisaiModel: EstimateMeisaiModel | null;
  callback: (v: MeisaiListXML[]) => void;
  callbackList: (v: MeisaiListXML[]) => void;
}

export const MeisaiCopyList = memo((props: Props) => {
  const { meisaiModel, callback, callbackList } = props;

  const dispatch = useDispatch();
  const _daibunruiList = useAppSelector((v) => v.master.largeCategoryList);
  const _tyubunruiList = useAppSelector((v) => v.master.middleCategoryList);

  const [resetKey, setResetKey] = useState(0);
  const [list, setList] = useState<MeisaiListXML[]>([]);
  const [selectList, setSelectList] = useState<MeisaiListXML[]>([]);
  const [selected, setSelected] = useState<number[]>([]);

  const [daibunrui, setDaibunrui] = useState('');
  const [tyubunrui, setTyubunrui] = useState('');

  const selectIndex = useMemo(() => {
    const sList: number[] = [];
    list.forEach((v, i) => {
      const filter = selectList.filter((v2) => isEqual(v, v2));
      if (filter.length) {
        sList.push(i);
      }
    });

    return sList;
  }, [list, selectList]);

  const daibunruiList = useMemo(() => {
    const baseList = (
      !list.length
        ? []
        : _daibunruiList.filter((v) => meisaiModel?.list.find((
          v2,
        ) => String(v2.$.daibunrui_id) === String(v.id))));
    return baseList.map((v) => {
      const find = meisaiModel?.list.find((v2) => String(v.id) === String(v2.$.daibunrui_id));
      if (!find) return v;
      return { ...v, name: find.$.daibunrui_name };
    });
  },
  [meisaiModel, _daibunruiList, list]);

  const tyubunruiList = useMemo(() => {
    const baseList = (
      !list || !daibunrui
        ? []
        : _tyubunruiList.filter((v) => meisaiModel?.list.find((
          v2,
        ) => Number(v2.$.tyubunrui_id) === Number(v.id))));
    return baseList.map((v) => {
      const find = meisaiModel?.list.find((v2) => String(v.id) === String(v2.$.tyubunrui_id));
      if (!find) return v;
      return { ...v, name: find.$.tyubunrui_name };
    });
  },
  [meisaiModel, _tyubunruiList, daibunrui, list]);

  const onChangeDaibunrui = useCallback((v: string | number) => {
    setDaibunrui(String(v));
    if (v) {
      dispatch(MasterActions.api.middleCategory.getList({
        category_id: Number(v),
        is_muko: 1,
      }));
      setTyubunrui('');
      setResetKey((v2) => v2 + 1);
      setList(cloneDeep(meisaiModel?.list.filter(
        (v2) => Number(v2.$.daibunrui_id) === Number(v),
      ) || []));
    } else {
      dispatch(MasterActions.setMiddleCategoryList([]));
      setResetKey((v2) => v2 + 1);
      setList(cloneDeep(meisaiModel?.list || []));
    }
  },
  [daibunrui, meisaiModel]);

  const onChangeTyubunrui = useCallback((v: string | number) => {
    if (!meisaiModel) return;
    setTyubunrui(String(v));
    if (v) {
      setResetKey((v2) => v2 + 1);
      setList(cloneDeep(meisaiModel?.list.filter(
        (v2) => Number(v2.$.daibunrui_id) === Number(daibunrui)
          && Number(v2.$.tyubunrui_id) === Number(v),
      ) || []));
    } else {
      setResetKey((v2) => v2 + 1);
      setList(daibunrui ? cloneDeep(meisaiModel.list.filter(
        (v2) => Number(v2.$.daibunrui_id) === Number(daibunrui),
      )) : cloneDeep(meisaiModel.list));
    }
  },
  [daibunrui, meisaiModel]);

  const onClickRow = useCallback((param: MeisaiListXML) => {
    const findIndex = list.findIndex((v) => v.$.id === param.$.id);
    if (findIndex !== -1) {
      callback(cloneDeep([param]));
      setSelected([findIndex]);
      setSelectList([param]);
    }
  }, [list]);

  const onClickMulti = useCallback((v: MeisaiListXML[]) => {
    const _selected = v.map((v2) => list.findIndex((v3) => v3.$.id === v2.$.id));

    setSelected(cloneDeep(_selected));
    setSelectList(v);
    callback(cloneDeep(_selected.map((v2) => list[v2])));
  }, [meisaiModel, list]);

  const onSort = useCallback((order: 0 | 1, index: number) => {
    if (!list.length) return;
    const resList = tableSort(
      list.map((v2) => v2.$),
      !order ? 'asc' : 'desc',
      sortKey[index],
    );
    setList(resList.map((v3) => ({ $: { ...v3 } })));
  }, [list]);

  useEffect(() => {
    callbackList(cloneDeep(list));
  }, [list]);

  useEffect(() => {
    setResetKey((v2) => v2 + 1);
    setDaibunrui('');
    setTyubunrui('');
    if (!meisaiModel) {
      setList([]);
      setSelected([]);
      return;
    }
    setSelected(meisaiModel.list.map((_, i) => i));
    setSelectList(cloneDeep(meisaiModel.list));
    callback(cloneDeep(meisaiModel.list));
    setList(cloneDeep(meisaiModel.list));
  }, [meisaiModel]);

  useDidMount(() => {
    dispatch(MasterActions.api.largeCategory.getList({ is_muko: 1 }));
  });

  useWillUnMount(() => {
    dispatch(MasterActions.setMiddleCategoryList([]));
    dispatch(MasterActions.setLargeCategoryList([]));
  });

  return (
    <section className="right_box">
      <>
        <strong style={{ marginBottom: '5px' }}>見積明細一覧</strong>
        <div className="search_area">
          <div className="item_wrap">
            <div className="item_box">
              <div className="item_head">大分類</div>
              <Select
                value={daibunrui}
                onChange={onChangeDaibunrui}
                defaultLabel="全て"
                options={daibunruiList.map((v) => ({
                  text: v.name, value: v.id,
                }))}
              />
            </div>
          </div>
          <div className="item_wrap">
            <div className="item_box">
              <div className="item_head">中分類</div>
              <Select
                value={tyubunrui}
                onChange={onChangeTyubunrui}
                defaultLabel="全て"
                options={tyubunruiList.map((v) => ({
                  text: v.name, value: v.id,
                }))}
              />
            </div>
          </div>
        </div>
        <div className="result_area list_area">
          <div className="inner">
            <div className="table_responsive">
              <Table
                headerReset={resetKey}
                className="table_selectable table_sortable table_sticky table_cell_change"
                header={EstimateCollection.estimateSearchHeader}
                onClickRow={onClickRow}
                onClickMulti={onClickMulti}
                sort={{ onClick: onSort }}
                rowDataList={list}
                selectedTr={selectIndex}
                lists={list.map((v) => ([
                  v.$.daibunrui_name,
                  v.$.tyubunrui_name,
                  v.$.name,
                  MathHelper.rounding2Str(Number(v.$.kingaku), 0, 'round', true),
                  MathHelper.rounding2Str(Number(v.$.genka_kei), 0, 'round', true),
                  v.$.print_name,
                  v.$.kikaku,
                  MathHelper.rounding2Str(Number(v.$.suryou), 2, 'round', true),
                  v.$.tani_name,
                  MathHelper.rounding2Str(Number(v.$.shikiri_kakaku), 0, 'round', true),
                  MathHelper.rounding2Str(Number(v.$.genka), 0, 'round', true),
                ]))}
                option={{
                  stringWidth: [
                    // { index: 0, width: 50 }, // 大分類
                    // { index: 1, width: 100 }, // 中分類
                    // { index: 2, width: 50 }, // 工事・部材名称
                    // { index: 3, width: 50 }, // 金額
                    // { index: 4, width: 50 }, // 原価金額
                    // { index: 5, width: 50 }, // 印刷名称
                    // { index: 6, width: 50 }, // 規格
                    // { index: 7, width: 50 }, // 数量
                    // { index: 8, width: 50 }, // 単位
                    // { index: 9, width: 50 }, // 見積単価
                    // { index: 10, width: 50 }, // 原価単価
                  ],
                  tdAlign: [
                    { index: 0, align: 'center' },
                    { index: 1, align: 'center' },
                    { index: 2, align: 'left' },
                    { index: 3, align: 'right' },
                    { index: 4, align: 'right' },
                    { index: 5, align: 'left' },
                    { index: 6, align: 'center' },
                    { index: 7, align: 'right' },
                    { index: 8, align: 'center' },
                    { index: 9, align: 'right' },
                    { index: 10, align: 'right' },
                  ],
                }}
              />
            </div>
          </div>
        </div>
      </>
    </section>
  );
});
