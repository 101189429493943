import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';
import { ProjectCollection } from '../../../../../collection/project/project.collection';
import { ProjectSearchBoxSP } from '../../../pages/project/search-box/project-search-box.sp';
import { useAppSelector } from '../../../../../hooks/use-redux';
import { Button } from '../../../../ui/button/button';
import { ProjectSearchSP } from '../../search-box/project/project-search/project-search.sp';
import { Project, ProjectListType } from '../../../../../type/project/project.type';
import { EstimateActions } from '../../../../../redux/estimate/estimate.action';
import { EstimateListType } from '../../../../../type/estimate/estimate.type';
import { SystemActions } from '../../../../../redux/system/system.action';
import { EstimateListSP } from '../../body/list/estimate/estimate-list';
import { OrderSP } from '../order.sp';
import { useEstimateAuthority } from '../../../../../hooks/use-authority';
import { useDidMount, useWillUnMount } from '../../../../../hooks/life-cycle';

type Mode = 'projectSearch' | 'estimateList' | 'order'

type Props = {
 changeTitle: (v: string) => void;
 initialMode?: Mode;
 initProject?: Project | ProjectListType,
 willUnMount?: () => void;
 isProject?: boolean;
}

export const DirectOrderSP = (props: Props) => {
  const {
    changeTitle, initialMode, initProject, willUnMount, isProject,
  } = props;
  const dispatch = useDispatch();
  const estimateAuth = useEstimateAuthority();
  const [mode, setMode] = useState<Mode>(initialMode || 'projectSearch');
  const [selectProject, setSelectProject] = useState<ProjectListType | Project | null>(null);
  const [estimateList, setEstimateList] = useState<EstimateListType[] | null>(null);
  const [selectEstimate, setSelectEstimate] = useState<EstimateListType | null>(null);
  const [isSearch, setIsSearch] = useState(false);

  const changeMode = useCallback((title: string, _mode: Mode = mode) => {
    setMode(_mode);
    changeTitle(title);
  }, [mode]);

  const handleClickProjectCard = useCallback((v: ProjectListType | Project) => {
    setIsSearch(false);
    setSelectProject(cloneDeep(v));
    if (estimateAuth) {
      dispatch(SystemActions.isLoading(true));
      dispatch(EstimateActions.api.estimate.getList({
        param: {
          project_id: v.id,
          valid_flag: 1,
        },
        callback: (data) => {
          dispatch(SystemActions.isLoading(false));
          changeMode('見積選択', 'estimateList');
          setEstimateList(cloneDeep(data));
        },
      }));
    } else {
      setEstimateList(null);
      changeMode('受注登録', 'order');
    }
  }, []);

  const handleClickEstimateCard = useCallback((v: EstimateListType) => {
    setSelectEstimate(cloneDeep(v));
    changeMode('受注登録', 'order');
  }, []);

  useDidMount(() => {
    if (initProject) {
      handleClickProjectCard(cloneDeep(initProject));
    }
  });

  useWillUnMount(() => {
    willUnMount?.();
  });

  return (
    <>
      {mode === 'projectSearch' && (
        <>
          <ProjectSearchSP
            type="order"
            callback={(v) => handleClickProjectCard(v)}
            onSearch={() => {
              changeTitle('案件選択');
            }}
            isOrder
            isNoStore
            callbackIsSearch={(v) => setIsSearch(v)}
            initIsSearch={isSearch}
          />
          {isSearch
          && (
          <>
            <div className="search_box_footer base_dialog_content_inner_footer">
              <Button color="dark" onClick={() => setIsSearch(false)}>戻る</Button>
            </div>
          </>
          )}
        </>
      )}
      {(mode === 'estimateList') && (
        <>
          <EstimateListSP
            data={estimateList}
            handleCardClick={(v) => handleClickEstimateCard(v)}
          />
          {!isProject && (
          <div className="search_box_footer base_dialog_content_inner_footer">
            <Button color="dark" onClick={() => changeMode('案件検索', 'projectSearch')}>戻る</Button>
          </div>
          )}
        </>
      )}
      {(mode === 'order' && selectProject) && (
        <OrderSP
          project={selectProject}
          estimateId={selectEstimate?.id}
          onBack={() => {
            if (estimateAuth) {
              changeMode('見積選択', 'estimateList');
            } else {
              changeMode('案件検索', 'projectSearch');
            }
          }}
          isDirect
        />
      )}
    </>
  );
};
