import 'semantic-ui-css/semantic.min.css';
import './App.scss';
import { useEffect, useMemo, useRef } from 'react';
import BodyClassName from 'react-body-classname';
import { useDispatch } from 'react-redux';
import { Route, useLocation } from 'react-router-dom';
import { Routes } from './routes/routes';
import { Loading } from './components/ui/loading/loading';
import { Dialog } from './components/dialogs/dialog';
import { noPinch } from './utilities/no-pinch';
import { UserAgent } from './utilities/user-agent';
import { useDidMount } from './hooks/life-cycle';
import { useInitialize } from './hooks/use-initialize';
import { DialogActions } from './redux/dialog/dialog.action';
import { Config } from './config/config';
import { WebFontLoader } from './utilities/web-font-loader';
import { SystemActions } from './redux/system/system.action';
import { RoutingPath } from './routes/routing-pass';

const App = () => {
  const ref = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const location = useLocation();

  const bodyClass = useMemo(() => (location.pathname === RoutingPath.login ? ' ship_login' : ''), [location]);

  useEffect(() => {
    const pinchCallback = noPinch(ref.current);
    return pinchCallback;
  }, [ref]);

  useDidMount(() => {
    if (Config.mode === 'prod') { window.console.log = () => {}; }
    const body = document.getElementById('body');
    if (body) {
      body.className = UserAgent;
    }
    // サーバーフォント読み込み
    dispatch(SystemActions.setIsLoading(true));
    const cssModel = new WebFontLoader({
      css: `${Config.host}${Config.cssPath}`,
    });
    cssModel.loadAsync({
      familyName: Config.svgFont,
    })
      .then(() => {
        dispatch(SystemActions.setIsLoading(false));
      })
      .catch(() => {
        dispatch(SystemActions.setIsLoading(false));
      });
    // FIXME Debug 用
    if (Config.mode === 'local') {
      window.addEventListener('keydown', ({ key }) => {
        if (key === 'Escape') {
          dispatch(DialogActions.pop());
        }
      });
    }
  });

  useInitialize();

  return (
    <BodyClassName className={`${UserAgent}${bodyClass}`}>
      <div
        className="App"
        ref={ref}
        // style={{ height: window.innerHeight }}
      >
        <Routes />
        <Dialog />
        <Loading />
      </div>
    </BodyClassName>
  );
};

export default App;
