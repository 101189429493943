/* eslint-disable  */
import './master.scss';
import { useState, useCallback, useMemo, useEffect } from 'react';
import { BasePagePC } from '../base.page.pc';
import { MasterCollection } from './master.collection';
import { SideMenu } from './side-menu/side-menu';
import { getMasterBody } from './get-master-body';
import { useDispatch } from 'react-redux';
import { DialogActions } from '../../../../redux/dialog/dialog.action';
import { MasterActions } from '../../../../redux/master/master.action';
import { useAppSelector } from '../../../../hooks/use-redux';
import { push } from 'connected-react-router';
import { RoutingPath } from '../../../../routes/routing-pass';
import { useDidMount } from '../../../../hooks/life-cycle';

const Body = (props:{detailType:number}) => getMasterBody(props.detailType);

export const Master = () => {
  const dispatch = useDispatch();
  const user = useAppSelector((state) => state.user)
  /* State */
  const [menuType, setMenuType] = useState(0);
  const [detailType, setDetailType] = useState(0);

  /* Memo */
  const detailMenu = useMemo(() => MasterCollection.sideMenu.find(
    (v) => v.type === menuType,
  )?.child || [], [menuType]);

  const viewContracted = useMemo(() => {
    return (user.company_id === 1 && !user.view_data)
    || (user.company_id === 1 && user.view_data.company_id === 1);
  },[user]);

  const showSideMenu = useMemo(() => MasterCollection
      .sideMenu.filter((v) => viewContracted
        ? true
        : v.type === 7
          ? false
          : true
      )
  , [user, viewContracted])

  /* Callback */
  const handleClickSideMenu = useCallback(
    (type: number) => {
      setMenuType(type);
      setDetailType(MasterCollection.sideMenu[type].child[0].type ?? 0);
    }, [],
  );

  const handleClickDetailMenu = useCallback((type: number) => {
    if (type !== 20) {
      setDetailType(type);
    } else {
      dispatch(DialogActions.pushMessage({
        title: '顧客ランク更新',
        message: ['顧客ランクを更新します。','よろしいですか？'],
        isCancel: true,
        callback: () => {
          dispatch(MasterActions.api.customerRankUpdate.post({
            param: {},
            callback: () => {DialogActions.pop()}
          }))
        },
      }))
   }
  }, [])

  useEffect(() => {
    if (!user) return;
    if (!user.authority4) {
      dispatch(push(RoutingPath.customer))
    }
  }, [user])

  return (
    <BasePagePC>
      <div id="master" className="cnt_area">
        <div className="inner">
          <SideMenu
            activeId={menuType}
            list={showSideMenu}
            callback={handleClickSideMenu}
          />
          <SideMenu
            activeId={detailType}
            list={detailMenu}
            callback={handleClickDetailMenu}
          />
        <Body detailType={detailType} />
        </div>
      </div>
    </BasePagePC>
  );
};
