import { ApiFilePostParam, ApiFileGetListParam } from '../../redux/file/api/file/api-file.type';
import {
  FileEditState, FileType, FileSortState, FileListType,
} from '../../type/file/file.type';
import { DateFormatter } from '../../utilities/date-formatter';
import { UserAgent } from '../../utilities/user-agent';

const df = (v: Date | null | undefined) => DateFormatter.date2str(v, 'YYYYMMDD', '-');
export class FileModel {
  /** 案件詳細內一覧取得用パラメータ */
  static listParamInProject(sortState: FileSortState, project_id: number): ApiFileGetListParam {
    let { sort_by } = sortState;
    if (sortState.sort_by === 6)sort_by = 8;
    return ({
      ...sortState,
      project_id,
      upload_date_start: df(sortState.upload_date_start),
      upload_date_end: df(sortState.upload_date_end),
      sort_by,
      created_at_start: DateFormatter.date2str(sortState.created_at_start, 'YYYYMMDD', '-'),
      created_at_end: DateFormatter.date2str(sortState.created_at_end, 'YYYYMMDD', '-'),
      updated_at_start: DateFormatter.date2str(sortState.updated_at_start, 'YYYYMMDD', '-'),
      updated_at_end: DateFormatter.date2str(sortState.updated_at_end, 'YYYYMMDD', '-'),
    });
  }

  /** 顧客詳細內一覧取得用パラメータ */
  static listParamInCustomer(sortState: FileSortState, customer_id: number): ApiFileGetListParam {
    let { sort_by } = sortState;
    if (sortState.sort_by === 6)sort_by = 8;
    return ({
      ...sortState,
      customer_id,
      upload_date_start: df(sortState.upload_date_start),
      upload_date_end: df(sortState.upload_date_end),
      sort_by,
      created_at_start: DateFormatter.date2str(sortState.created_at_start, 'YYYYMMDD', '-'),
      created_at_end: DateFormatter.date2str(sortState.created_at_end, 'YYYYMMDD', '-'),
      updated_at_start: DateFormatter.date2str(sortState.updated_at_start, 'YYYYMMDD', '-'),
      updated_at_end: DateFormatter.date2str(sortState.updated_at_end, 'YYYYMMDD', '-'),
    });
  }

  /** sort_byの値変更 */

  /** 一覧取得用パラメータ */
  static listParam(param: FileSortState): ApiFileGetListParam {
    return ({
      ...param,
      upload_date_start: df(param.upload_date_start),
      upload_date_end: df(param.upload_date_end),
      limit: UserAgent === 'sp' ? 100 : param.limit,
      created_at_start: DateFormatter.date2str(param.created_at_start, 'YYYYMMDD', '-'),
      created_at_end: DateFormatter.date2str(param.created_at_end, 'YYYYMMDD', '-'),
      updated_at_start: DateFormatter.date2str(param.updated_at_start, 'YYYYMMDD', '-'),
      updated_at_end: DateFormatter.date2str(param.updated_at_end, 'YYYYMMDD', '-'),
    });
  }

  /** 保存用パラメータ */
  static postParam(param: FileEditState, id: number | undefined): ApiFilePostParam['data'] {
    console.log(param.file);

    return id
      ? ({
        customer_id: param.customer_id,
        project_id: param.project_id,
        file_name: [param.file_name[0]],
        comment: param.comment,
        file: param.file.length ? [param.file[0]] : undefined,
      })
      : param;
  }

  /* 1件取得データのセット */
  static setGetData(data: FileType): Partial<FileEditState> {
    return ({
      customer_id: data.customer_id || NaN,
      project_id: data.project_id || NaN,
      file_name: [data.file_name || ''],
      comment: data.comment || '',
    });
  }

  /* 形式リストの作成 */
  static createFormatList(param: FileListType[]):string[] {
    return param
      .filter((v, _, ary) => ary.find((v2) => v2.format !== v.format))
      .map((v3) => (v3.format));
  }
}
