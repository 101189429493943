import { memo } from 'react';
import { useDispatch } from 'react-redux';
import { SubWindow, formatList } from './sub-window';
import { useDidMount } from '../../../../../../../hooks/life-cycle';
import { DialogActions } from '../../../../../../../redux/dialog/dialog.action';
import { MasterActions } from '../../../../../../../redux/master/master.action';
import { useAppSelector } from '../../../../../../../hooks/use-redux';

type Props = {
  open: boolean;
  callbackClose: () => void;
}

const master = 'customerExpectedRank';
const listName = 'customerEstimatedRankList';

export const EstimatedRank = memo((props: Props) => {
  const { open, callbackClose } = props;

  const dispatch = useDispatch();
  const list = useAppSelector((v) => v.master[listName]);

  useDidMount(() => dispatch(MasterActions.api[master].getList({})));

  return (
    <SubWindow
      open={open}
      title="見込客ランク"
      header={['短縮コード', '名前']}
      lists={list
        .sort((a, b) => (a.id < b.id ? -1 : 1))
        .map((v) => ([v.abbreviation, v.name]))}
      callbackClose={callbackClose}
    />
  );
});
