import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { cloneDeep } from 'lodash';
import '../search-box.pc.scss';
import { Select } from '../../../../ui/select/select';
import { useDidMount } from '../../../../../hooks/life-cycle';
import { MasterActions } from '../../../../../redux/master/master.action';
import { SupportHistorySortState } from '../../../../../type/support-history/support-history.type';
import { DatePicker } from '../../../../ui/date-picker/date-picker';
import { LeftIconButton } from '../../../../ui/button/left-icon-button/left-icon-button';
import { SearchBoxPC } from '../search-box.pc';
import { Radio } from '../../../../ui/radio/radio';
import { SupportHistoryActions } from '../../../../../redux/support-history/support-history.action';
import { Input } from '../../../../ui/input/input';
import { useAppSelector } from '../../../../../hooks/use-redux';
import { pulldown } from '../../../../../utilities/pulldown';
import { useEmployeeList } from '../../../../../hooks/common/use-common';
import { useDateValidation } from '../../../../../hooks/use-date-validation';
import { DateTermValidation } from '../../../../../model/validation/date/term-date.validation';

type Props = {
  callback: (v: SupportHistorySortState) => void;
  openCallback: (v: boolean) => void;
  isSearch?: boolean;
}

export const SupportHistorySearchBox = (props: Props) => {
  const { callback, openCallback, isSearch } = props;

  /* Hooks */
  const dispatch = useDispatch();
  const sortState = useAppSelector((v) => (v.supportHistory.sort));
  const supportHistoryList = useAppSelector((v) => v.master.supportHistoryList);
  const employeeList = useAppSelector((v) => v.master.employeeList);
  const storeList = useAppSelector((v) => v.master.storeList);
  const originList = useAppSelector((v) => (v.master.originList));

  /* State */
  const [sort, setSort] = useState({ ...cloneDeep(sortState) });
  /* 顧客従業員リスト */
  const customerEmployeeList = useEmployeeList(sort.customer_store_id, employeeList);
  /* 受付担当従業員リスト */
  const contactEmployeeList = useEmployeeList(sort.contact_store_id, employeeList);
  /* 対応担当従業員リスト */
  const supportedEmployeeList = useEmployeeList(sort.contact_store_id, employeeList);

  const termDate = useDateValidation([
    { start: sort.contact_dt_start, end: sort.contact_dt_end },
    { start: sort.created_at_start, end: sort.created_at_end },
    { start: sort.updated_at_start, end: sort.updated_at_end },
  ]);

  /* Callback */
  const setState = useCallback((v: Partial <SupportHistorySortState>) => {
    const data = {
      ...cloneDeep(sort),
      ...cloneDeep(v),
    };
    setSort(data);
  }, [sort, isSearch]);

  const handleClickSearch = useCallback(() => {
    if (termDate.messages.length) {
      termDate.check();
      return;
    }

    if (!isSearch) {
      dispatch(SupportHistoryActions.setSort(cloneDeep(sort)));
    }
    callback(cloneDeep(sort));
  }, [callback, sortState, isSearch, termDate, sort]);

  /* Effect */
  useDidMount(() => {
    dispatch(MasterActions.api.store.getList({}));
    dispatch(MasterActions.api.employee.getList({}));
    dispatch(MasterActions.api.supportHistory.getList({}));
    dispatch(MasterActions.api.origin.getList({}));
  });

  return (
    <SearchBoxPC
      openCallback={openCallback}
      isDetail
      defaultOpen
    >
      <div onKeyPress={(e) => { if (e.key === 'Enter') handleClickSearch(); }}>
        <div className="item_wrap">
          <div className="item_box">
            <div className="item_head">登録日</div>
            <DatePicker
              date={sort?.contact_dt_start}
              onChange={(v) => setState({ contact_dt_start: v })}
              term={DateTermValidation([
                { start: sort.contact_dt_start, end: sort.contact_dt_end },
              ])}
            />
            <label className="ml_10">〜</label>
            <DatePicker
              date={sort?.contact_dt_end}
              onChange={(v) => setState({ contact_dt_end: v })}
              term={DateTermValidation([
                { start: sort.contact_dt_start, end: sort.contact_dt_end },
              ])}
            />
          </div>
          <div className="item_box">
            <div className="item_head">登録者</div>
            <Select
              className="add_text_left"
              label="店舗"
              value={sort?.contact_store_id}
              defaultLabel="全て"
              onChange={(data) => setState({
                contact_store_id: Number(data),
                contact_employee_id: NaN,
              })}
              options={[{ text: '指定なし', value: -1 }]
                .concat(storeList.map((v) => ({
                  text: v.name, value: v.id,
                })))}
            />
            <Select
              className="add_text_left"
              label="担当者"
              defaultLabel="全て"
              value={sort?.contact_employee_id}
              onChange={(data) => setState({ contact_employee_id: Number(data) })}
              options={[{ text: '指定なし', value: -1 }]
                .concat(pulldown(contactEmployeeList))}
            />
          </div>
          <div className="item_box">
            <div className="item_head">媒体</div>
            <Select
              value={sort?.support_id}
              onChange={(data) => setState({ support_id: Number(data) })}
              defaultLabel="全て"
              options={supportHistoryList.map((v) => ({
                text: v.supported, value: v.id,
              }))}
            />
          </div>
          <div className="item_box">
            <div className="item_head">カテゴリ</div>
            <Select
              value={sort?.source_id}
              onChange={(data) => setState({ source_id: Number(data) })}
              defaultLabel="全て"
              options={pulldown(originList)}
            />
          </div>
        </div>
        <div className="item_wrap">
          <div className="item_box">
            <div className="item_head">対応区分</div>
            <Radio
              label="未対応"
              name="kubun"
              value="kubun0"
              checked={sort.support_flag === 0}
              onChange={() => setState({ support_flag: 0 })}
            />
            <Radio
              label="すべて"
              name="kubun"
              value="kubun1"
              checked={sort.support_flag === 2}
              onChange={() => { setState({ support_flag: 2 }); }}
            />

          </div>
          <div className="item_box">
            <div className="item_head">文字列検索</div>
            <Input
              className="mr_10"
              value={sort?.word}
              onChange={(e) => setState({ word: e.target.value })}
            />
            <span className="comment">※検索対象項目：対応履歴名、対応内容</span>
          </div>
          <LeftIconButton
            label="検索"
            fontAwesomeClass="fas fa-search"
            className="btn_search for_simple"
            size="sm"
            color="secondary"
            onClick={handleClickSearch}
          />
        </div>

        <div className="search_detail">
          <div className="item_wrap">
            <div className="item_box">
              <div className="item_head">顧客名</div>
              <Input
                value={sort?.customer_name}
                onChange={(e) => setState({ customer_name: e.target.value })}
              />
            </div>
            <div className="item_box">
              <div className="item_head">顧客担当</div>
              <Select
                className="add_text_left"
                label="店舗"
                value={sort?.customer_store_id}
                onChange={(data) => setState({
                  customer_store_id: Number(data),
                  customer_employee_id: NaN,
                })}
                defaultLabel="全て"
                options={pulldown(storeList)}
              />
              <Select
                className="add_text_left"
                label="担当者"
                value={sort?.customer_employee_id}
                onChange={(data) => setState({ customer_employee_id: Number(data) })}
                defaultLabel="全て"
                options={pulldown(customerEmployeeList)}
              />
            </div>
          </div>
          <div className="item_wrap">
            <div className="item_box">
              <div className="item_head">対応日</div>
              <DatePicker
                date={sort?.support_dt || null}
                onChange={(v) => setState({ support_dt: v })}
              />
            </div>
            <div className="item_box">
              <div className="item_head">対応担当</div>
              <Select
                className="add_text_left"
                label="店舗"
                value={sort?.support_store_id}
                defaultLabel="全て"
                onChange={(data) => setState({
                  support_store_id: Number(data),
                  support_employee_id: NaN,
                })}
                options={[{ text: '指定なし', value: -1 }]
                  .concat(storeList.map((v) => ({
                    text: v.name, value: v.id,
                  })))}
              />
              <Select
                className="add_text_left"
                label="担当者"
                defaultLabel="全て"
                value={sort?.support_employee_id}
                onChange={(data) => setState({ support_employee_id: Number(data) })}
                options={[{ text: '指定なし', value: -1 }]
                  .concat(pulldown(supportedEmployeeList))}
              />
            </div>
          </div>
          <div className="item_wrap">
            <div className="item_box">
              <div className="item_head">登録日</div>
              <DatePicker
                date={sort.created_at_start}
                onChange={(v) => setState({ created_at_start: v })}
                type="YYYY/MM/DD"
                term={DateTermValidation([
                  { start: sort.created_at_start, end: sort.created_at_end },
                ])}
              />
              <label className="ml_10">～</label>
              <DatePicker
                date={sort.created_at_end}
                onChange={(v) => setState({ created_at_end: v })}
                type="YYYY/MM/DD"
                term={DateTermValidation([
                  { start: sort.created_at_start, end: sort.created_at_end },
                ])}
              />
            </div>
            <div className="item_box">
              <div className="item_head">更新者</div>
              <Select
                defaultLabel="全て"
                className="fixed"
                value={sort.updated_employee_id}
                onChange={(v) => setState({ updated_employee_id: Number(v) })}
                options={pulldown(employeeList)}
              />
            </div>
            <div className="item_box">
              <div className="item_head">更新日</div>
              <DatePicker
                date={sort.updated_at_start}
                onChange={(v) => setState({ updated_at_start: v })}
                type="YYYY/MM/DD"
                term={DateTermValidation([
                  { start: sort.updated_at_start, end: sort.updated_at_end },
                ])}
              />
              <label className="ml_10">～</label>
              <DatePicker
                date={sort.updated_at_end}
                onChange={(v) => setState({ updated_at_end: v })}
                type="YYYY/MM/DD"
                term={DateTermValidation([
                  { start: sort.updated_at_start, end: sort.updated_at_end },
                ])}
              />
            </div>
            <LeftIconButton
              label="検索"
              fontAwesomeClass="fas fa-search"
              className="btn_search for_detail"
              size="sm"
              color="secondary"
              onClick={handleClickSearch}
            />
          </div>
        </div>
      </div>
    </SearchBoxPC>
  );
};
