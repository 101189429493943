import { cloneDeep } from 'lodash';
import isEqual from 'lodash/isEqual';
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDidMount } from '../../../../../hooks/life-cycle';
import { CustomerDetailActions } from '../../../../../redux/customer-detail/customer-detail.action';
import { DialogActions } from '../../../../../redux/dialog/dialog.action';
import { MasterActions } from '../../../../../redux/master/master.action';
import { State } from '../../../../../redux/root.reducer';
import { ProjectSortState } from '../../../../../type/project/project.type';
import { DatePicker } from '../../../../ui/date-picker/date-picker';
import { Input } from '../../../../ui/input/input';
import { InputNum } from '../../../../ui/input/input-num';
import { Select } from '../../../../ui/select/select';
import { RefindSP } from './refind.sp';
import { changeStore, sortEmployee } from '../../../../../utilities/sort-employee';
import { pulldown } from '../../../../../utilities/pulldown';

type Props = {
  callback: (sort: ProjectSortState) => void;
  callbackSNS?: () => void;
}

export const RefindProjectSP = (props: Props) => {
  const { callback, callbackSNS } = props;

  /* Hook */
  const dispatch = useDispatch();
  const sort = useSelector((state: State) => state.customerDetail.projectSort, isEqual);

  /* State */
  const [sortState, setSortState] = useState(cloneDeep(sort));
  const {
    originList,
    employeeList,
    storeList,
  } = useSelector((state: State) => state.master, isEqual);

  /* Callback */
  const handleClickSearch = useCallback(() => {
    dispatch(DialogActions.pop());
    callback(sortState);
  }, [callback, sort]);

  const setState = useCallback((v: Partial<ProjectSortState>) => {
    const data = {
      ...cloneDeep(sortState),
      ...cloneDeep(v),
    };
    setSortState(data);
    dispatch(CustomerDetailActions.setProjectSort(data));
  }, [dispatch, sortState]);

  /* Master */
  useDidMount(() => {
    dispatch(MasterActions.api.origin.getList({}));
  });

  return (
    <RefindSP callback={handleClickSearch}>
      <>
        <div className="category_wrap">
          <div className="item_wrap">
            <div className="item_label">案件名</div>
            <Input
              className=""
              value={sortState.name}
              onChange={(e) => setState({ name: e.target.value })}
            />
          </div>
          <div className="item_wrap">
            <div className="item_label">案件担当店舗</div>
            <Select
              value={sortState.project_store_id}
              onChange={(v) => {
                setState(
                  changeStore({
                    employeeList,
                    storeId: Number(v),
                    storeKey: 'store_id',
                    returnStoreKey: 'project_store_id',
                    employeeKey: 'project_employee_id',
                  }),
                );
              }}
              defaultLabel="全て"
              options={pulldown(storeList)}
            />
          </div>
          <div className="item_wrap">
            <div className="item_label">案件担当者</div>
            <Select
              value={sortState.project_employee_id}
              onChange={(v) => setState({ project_employee_id: Number(v) })}
              defaultLabel="全て"
              options={pulldown(sortEmployee({
                employeeList,
                storeId: sortState.project_store_id,
                storeKey: 'store_id',
              }))}
            />
          </div>
          <div className="item_wrap">
            <div className="item_label">登録日</div>
            <DatePicker
              date={sortState.entry_dt}
              onChange={(v) => setState({ entry_dt: v })}
            />
          </div>
        </div>
        <div className="category_wrap">
          <div className="item_wrap">
            <div className="item_label">受注金額（契約金額）</div>
            <InputNum
              value={sortState.order_price}
              onChange={(v) => { setState({ order_price: v }); }}
              onBlur={(v) => setState({
                order_price: v ? Number(v).toLocaleString() : '',
              })}
              minus
            />
          </div>
        </div>
        <div className="category_wrap">
          <div className="item_wrap">
            <div className="item_label">着工日</div>
            <DatePicker
              date={sortState.construction_date}
              onChange={(v) => setState({ construction_date: v })}
            />
          </div>
          <div className="item_wrap">
            <div className="item_label">完工日</div>
            <DatePicker
              date={sortState.completion_date}
              onChange={(v) => setState({ completion_date: v })}
            />
          </div>
          <div className="item_wrap">
            <div className="item_label">完了日</div>
            <DatePicker
              date={sortState.complete_date}
              onChange={(v) => setState({ complete_date: v })}
            />
          </div>
        </div>
        <div className="category_wrap">
          <div className="item_wrap">
            <div className="item_label">契約日</div>
            <DatePicker
              date={sortState.contract_date}
              onChange={(v) => setState({ contract_date: v })}
            />
          </div>
          <div className="item_wrap">
            <div className="item_label">失注日</div>
            <DatePicker
              date={sortState.failure_date}
              onChange={(v) => setState({ failure_date: v })}
            />
          </div>
          <div className="item_wrap">
            <div className="item_label">キャンセル日</div>
            <DatePicker
              date={sortState.cancel_date}
              onChange={(v) => setState({ cancel_date: v })}
            />
          </div>
        </div>
        <div className="category_wrap">
          <div className="item_wrap">
            <div className="item_label">発生源</div>
            <Select
              value={sortState.source_id}
              defaultLabel="指定無し"
              options={originList.map((v) => ({
                text: v.name, value: v.id,
              }))}
              onChange={(v) => setState({ source_id: Number(v) })}
            />
          </div>
          <div className="item_wrap">
            <div className="item_label">備考</div>
            <textarea
              value={sortState.remarks}
              onChange={(e) => setState({
                remarks: e.target.value,
              })}
              rows={4}
            />
          </div>
        </div>
      </>
    </RefindSP>
  );
};
