import { useCallback, useState } from 'react';
import { ValidationPostNum1, ValidationPostNum2 } from '../../../model/validation';
import { LeftLabelInputField } from '../input-field/left-label-input-field/left-label-input-field';
import { UserAgent } from '../../../utilities/user-agent';

type Props = {
  zip_code1: [string, (v:string)=>void];
  zip_code2: [string, (v:string)=>void];
  require?: boolean;
  touch?: boolean;
  onEnter?: () => void;
}

export const InputPostal = (props: Props) => {
  const {
    zip_code1: [zipCode1, setZipCode1],
    zip_code2: [zipCode2, setZipCode2],
    require,
    touch,
    onEnter,
  } = props;
  const isPc = UserAgent === 'pc';
  const [isFocus, setFocus] = useState(false);

  const onChange = useCallback((v: string, index: 0 | 1) => {
    if (v.match(/[^0-9]+/g)) return;
    if (index) {
      setZipCode2(v);
      return;
    }
    setZipCode1(v);
  }, [setZipCode1, setZipCode2]);

  return (
    <>
      <LeftLabelInputField
        onEnterKeyPress={onEnter}
        className={isPc ? 'postal_code_1' : ''}
        label="〒"
        type="text"
        value={zipCode1}
        onChange={(e) => {
          onChange(e.target.value, 0);
          if (e.target.value.length === 3) setFocus(true);
          console.log('koko');
        }}
        inputMode="numeric"
        maxLength={3}
      />
      <LeftLabelInputField
        onEnterKeyPress={onEnter}
        className={isPc ? 'postal_code_2' : ''}
        label="-"
        type="text"
        inputMode="numeric"
        onFocus={() => setFocus(false)}
        value={zipCode2}
        onChange={(e) => onChange(e.target.value, 1)}
        isFocus={isFocus}
        maxLength={4}
      />
    </>
  );
};
