import CopyToClipboard from 'react-copy-to-clipboard';
import lodash, { cloneDeep } from 'lodash';
import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { Segment } from 'semantic-ui-react';
import * as AutoKana from 'vanilla-autokana';
import { CustomerCollection as CC } from '../../../../../collection/customer/customer.collection';
import { prefectures } from '../../../../../collection/prefectures';
import { useDidMount } from '../../../../../hooks/life-cycle';
import { TagModel } from '../../../../../model/tag/tag';
import {
  ValidationLengthUnder10,
  ValidationLengthUnder20,
  ValidationLengthUnder255,
  ValidationLengthUnder60,
  ValidationLineId,
  ValidationTwitterId,
  ValidationTel,
  ValidationLengthUnder500,
} from '../../../../../model/validation';
import { CustomerValidation } from '../../../../../model/validation/customer/customer.validation';
import { ValidationDatePicker } from '../../../../../model/validation/validation-date-picker';
import { ValidationFacebookId } from '../../../../../model/validation/validation-facebook-id';
import { ValidationInstagramId } from '../../../../../model/validation/validation-instagram-id';
import { CustomerActions } from '../../../../../redux/customer/customer.action';
import { DialogActions } from '../../../../../redux/dialog/dialog.action';
import { MapActions } from '../../../../../redux/map/map.action';
import { MasterActions } from '../../../../../redux/master/master.action';
import { State } from '../../../../../redux/root.reducer';
import { TagActions } from '../../../../../redux/tag/tag.action';
import { Customer, CustomerEditState } from '../../../../../type/customer/customer.type';
import { autoKana } from '../../../../../utilities/auto-kana';
import { EditSP } from '../../../../dialogs/edit/edit.sp';
import { Button } from '../../../../ui/button/button';
import { RightLabelCheckbox } from '../../../../ui/checkbox/right-label-checkbox/right-label-checkbox';
import { DatePicker } from '../../../../ui/date-picker/date-picker';
import { TopLabelInputField } from '../../../../ui/input-field/top-label-input-field/top-label-input-field';
import { Input } from '../../../../ui/input/input';
import { RegistrationAddressMapDialog } from '../../../../ui/map/registration-address-map-dialog/registration-address-map-dialog';
import { Required } from '../../../../ui/required/required';
import { Select } from '../../../../ui/select/select';
import { TextArea } from '../../../../ui/text-area/text-area';
import { FamilyInfoListSP } from '../../../layout/body/list/family-info/family-info-list';
import { PetInfoListSP } from '../../../layout/body/list/pet-info/pet-info-list';
import './customer-edit.sp.scss';
import { FamilyEditDialog } from './family/family-edit-dialog.sp';
import { PetEditDialog } from './pet/pet-edit-dialog.sp';
import { FamilyListModel } from '../../../../../model/customer/family-list.model';
import { PetListModel } from '../../../../../model/customer/pet-list.model';
import { pulldown, pulldownCustomerRank, pulldownRank } from '../../../../../utilities/pulldown';
import { useAppSelector } from '../../../../../hooks/use-redux';
import { CustomerModel as CM } from '../../../../../model/customer/customer-model';
import { LabelInput } from '../../../../ui/input/label-input';
import { Tikunengetsu } from '../../../../ui/tikunengetsu/tikunengetsu';
import { SearchPostAddressDialog, plus0, minus0 } from '../../../../ui/search-post-address-dialog/search-post-address-dialog';
import { Message } from '../../../../../collection/message.collection';
import { InputPostNo } from '../../../../ui/input/input-post-no';
import { useRegistrationMap } from '../../../../../hooks/map/use-map';
import { useEditAuthCustomer } from '../../../../../hooks/use-authority';
import { ValidationLengthUnderHankaku60 } from '../../../../../model/validation/validation-length-under-hankaku60';
import { IconButton } from '../../../../ui/button/icon-button/icon-button';
import { useSNSContact } from '../../../../../hooks/support-history/use-support-history';
import { InputPostNoMulti } from '../../../../ui/input/input-post-no-multi';
import { InputSelect } from '../../../../ui/input-select/input-select';

type Props = {
  id?: number;
  callback: () => void;
  callbackPostEnd?: () => void;
  callSNS?: () => void;
}

const masterSelector = (state: State) => state.master;
const tagSelector = (state: State) => state.tag;

export const CustomerEditSP = (props: Props) => {
  const {
    id, callback, callbackPostEnd, callSNS,
  } = props;

  const dispatch = useDispatch();

  const snsContact = useSNSContact();

  const user = useAppSelector((v) => v.user);
  const sortState = useAppSelector((v) => v.customer.sort);
  const { partList, masterMyCarTypeList, relevantTagList } = useAppSelector(tagSelector);
  const {
    buildingCategoryList,
    madoriList,
    areaList,
    storeList,
    employeeList,
    originList,
    customerRankList,
    customerEstimatedRankList,
  } = useAppSelector(masterSelector);
  const registrationMap = useRegistrationMap('sp', undefined, undefined, true);

  /* State */
  const [customer, setCustomer] = useState<CustomerEditState>(CC.customerEditInitialState(user));
  const [touch, setTouch] = useState(false);
  const [familyId, setFamilyId] = useState(NaN);
  const [petId, setPetId] = useState(NaN);
  const [autoFurigana, setAutoFurigana] = useState<AutoKana.AutoKana | null>();
  const [familyListModel, setFamilyListModel] = useState<FamilyListModel>(new FamilyListModel());
  const [famEditCount, setFamEditCount] = useState(0);
  const [petListModel, setPetListModel] = useState<PetListModel>(new PetListModel());
  const [petEditCount, setPetEditCount] = useState(0);
  const [internalId, setInternalId] = useState('');
  const [info, setInfo] = useState<Customer | null>(null);
  const editAuth = useEditAuthCustomer(info?.employee_id);

  const _employeeList = useMemo(() => employeeList.filter((
    v,
  ) => (Number.isNaN(customer.store_id) || customer.store_id === null
    ? true
    : (v.store_id === customer.store_id))),
  [customer.store_id, employeeList]);

  /* Callback */
  const setState = useCallback((v: Partial<CustomerEditState>) => {
    setCustomer({ ...cloneDeep(customer), ...cloneDeep(v) });
  }, [customer]);

  const setCustomerName = useCallback((v:CustomerEditState) => {
    setCustomer(autoFurigana ? { ...v, furigana: autoFurigana.getFurigana() } : v);
  }, [customer, autoFurigana]);

  const handleClickPost = useCallback(() => {
    if (!editAuth) return;
    if (CustomerValidation(customer)) {
      dispatch(DialogActions.pushMessage({
        title: `顧客情報${id ? '更新' : '登録'}`,
        message: Message.postError,
        callback: () => setTouch(true),
      }));
      return;
    }
    const prefe = prefectures.find((v) => v.value === customer.prefecture)?.label;

    const post = (data?:{
      position: globalThis.google.maps.LatLngLiteral;
      address: string;
  }) => {
      dispatch(CustomerActions.api.customer.post({
        param: CM.postParam({
          petListModel, familyListModel, customer, id, data,
        }),
        onSuccess: callback,
        onError: () => setTouch(true),
        update: !!id,
      }));
    };
    const confirm = () => {
      /* 保存API */
      if (info && (
        info.prefecture === customer.prefecture
      && info.city === customer.city
      && info.address === customer.address
      && !!info.lat
      && !!info.lng
      )) {
        post();
        return;
      }
      dispatch(MapActions.api.geocoder({
        isRegist: true,
        param: {
          param: { address: `${prefe || ''}${customer.city}${customer.address}` },
          noMessage: true,
        },
        callback: post,
      }));
    };
    /* 保存API */
    if (id) {
      dispatch(DialogActions.pushMessage({
        title: `${id ? '更新' : '登録'}確認`,
        message: id ? Message.postUpdateConfirm : Message.postConfirm,
        isCancel: true,
        callback: confirm,
      }));
      return;
    }
    confirm();
  },
  [customer, id, prefectures, callback, sortState, info, editAuth, familyListModel, petListModel]);

  const handleClickRegistrationMap = useCallback(() => {
    registrationMap((address) => {
      if (!address) return;
      setState(CM.registrationMap({ address }));
    });
  }, [customer]);

  const handleClickSearchAddress = useCallback(() => {
    dispatch(MapActions.api.addressSearch({
      param: {
        zipcode1: String(customer.post_no[0]),
        zipcode2: String(customer.post_no[1]),
      },
      callback: (address) => setState(CM.searchAddress({ customer, address })),
    }));
  }, [customer]);

  const searchPostNo = useCallback(() => {
    if (!editAuth) return;
    dispatch(DialogActions.push({
      title: '住所検索',
      className: 'max_height_dialog',
      element: <SearchPostAddressDialog
        zipcode1={customer.post_no[0]}
        zipcode2={customer.post_no[1]}
        jisx0402={customer.jisx0402_code || ''}
        pref={customer.prefecture}
        city={customer.city}
        town={customer.address}
        calback={(v) => {
          setState({
            post_no: [
              v.zipcode1,
              v.zipcode2,
            ],
            prefecture: v.pref,
            city: v.city,
            jisx0402_code: v.jisx0402,
            address: v.town,
          });
        }}
      />,
    }));
  }, [editAuth, customer]);

  const confirmActivate = useCallback((title: string) => {
    dispatch(DialogActions.pushMessage({
      title,
      message: ['顧客情報の更新ボタンを押下後反映されます'],
    }));
  }, []);

  const editFamily = useCallback((isEdit?: 'edit') => {
    if (isEdit && Number.isNaN(familyId)) return;
    const data = isEdit ? familyListModel.list.find((v) => v.index === familyId) : undefined;
    dispatch(DialogActions.push({
      title: `ご家族情報${isEdit ? '編集' : '登録'}`,
      className: 'auto_height_dialog',
      element: <FamilyEditDialog
        id={1}
        isEdit={!!isEdit}
        data={isEdit ? data : undefined}
        callbackPost={(res) => {
          if (isEdit) {
            familyListModel.edit(res);
          } else {
            familyListModel.add(res);
            setFamEditCount(famEditCount + 1);
            setFamilyId(NaN);
          }
          dispatch(DialogActions.pop());
          setFamilyListModel(cloneDeep(familyListModel));
          confirmActivate(`ご家族情報${isEdit ? '編集' : '登録'}`);
        }}
      />,
    }));
  }, [familyId, familyListModel, famEditCount]);

  const editPet = useCallback((isEdit?: 'edit') => {
    if (isEdit && Number.isNaN(petId)) return;
    const data = isEdit ? petListModel.list.find((v) => v.index === petId) : undefined;
    dispatch(DialogActions.push({
      title: `ペット情報${isEdit ? '編集' : '登録'}`,
      className: 'auto_height_dialog',
      element: <PetEditDialog
        id={1}
        isEdit={!!isEdit}
        data={isEdit ? data : undefined}
        callbackPost={(res) => {
          if (isEdit) {
            petListModel.edit(res);
          } else {
            petListModel.add(res);
            setPetId(NaN);
            setPetEditCount(petEditCount + 1);
          }
          dispatch(DialogActions.pop());
          setPetListModel(cloneDeep(petListModel));
          confirmActivate(`ペット情報${isEdit ? '編集' : '登録'}`);
        }}
      />,
    }));
  }, [petId, petListModel, petEditCount]);

  const removeFamily = useCallback(() => {
    const data = familyListModel.list.find((v) => v.index === familyId);
    if (!data) return;
    dispatch(DialogActions.pushMessage({
      title: '家族情報削除',
      message: [`${data.name}さんの情報を削除しますか`],
      isCancel: true,
      callback: () => {
        setFamilyId(NaN);
        familyListModel.remove(data);
        setFamilyListModel(cloneDeep(familyListModel));
      },
    }));
  }, [familyId]);

  const removePet = useCallback(() => {
    const data = petListModel.list.find((v) => v.index === petId);
    if (!data) return;
    dispatch(DialogActions.pushMessage({
      title: 'ペット情報削除',
      message: [`${data.name}の情報を削除しますか`],
      isCancel: true,
      callback: () => {
        setPetId(NaN);
        petListModel.remove(data);
        setPetListModel(cloneDeep(petListModel));
      },
    }));
  }, [petId]);

  const familySort = useCallback((order:number, sort: number) => {
    familyListModel.sortHeader(order ? 'desc' : 'asc', sort);
    setFamilyListModel(cloneDeep(familyListModel));
  }, [familyListModel]);

  const petSort = useCallback((order:number, sort: number) => {
    petListModel.sortHeader(order ? 'desc' : 'asc', sort);
    setPetListModel(cloneDeep(petListModel));
  }, [petListModel]);

  /* Effect */
  useDidMount(() => {
    dispatch(TagActions.api.part.getList());
    dispatch(TagActions.api.masterMyCarType.getList());
    dispatch(TagActions.api.relevantTag.getList());
    dispatch(MasterActions.api.buildingCategory.getList({}));
    dispatch(MasterActions.api.madori.getList({}));
    dispatch(MasterActions.api.area.getList({}));
    dispatch(MasterActions.api.store.getList({}));
    dispatch(MasterActions.api.employee.getList({}));
    dispatch(MasterActions.api.area.getList({}));
    dispatch(MasterActions.api.origin.getList({}));
    dispatch(MasterActions.api.customerKojiRank.getList({}));
    dispatch(MasterActions.api.customerExpectedRank.getList({}));
    dispatch(MasterActions.api.customerRank.getList({}));
    if (!id) {
      setAutoFurigana(autoKana('name', 'furigana', true));
    }
  });

  useEffect(() => {
    if (id === undefined) return;
    dispatch(CustomerActions.api.customer.get({
      param: { id },
      callback: setInfo,
    }));
  }, [id]);

  useEffect(() => {
    if (!info) return;
    setInternalId(String(info.id));
    setState(CM.setEditData({
      customer, data: info, partList, masterMyCarTypeList, relevantTagList,
    }));
    setFamilyListModel(new FamilyListModel(info.familylist));
    setPetListModel(new PetListModel(info.petlist));
  }, [partList, masterMyCarTypeList, relevantTagList, info]);

  /* タグの設定 */
  useEffect(() => {
    if (info) return;
    setState({
      part_list: new TagModel(cloneDeep(partList)),
      expected_part_list: new TagModel(cloneDeep(partList)),
      tag_list: new TagModel(relevantTagList),
      my_car_type: new TagModel(masterMyCarTypeList),
    });
  }, [partList, info, relevantTagList, masterMyCarTypeList]);

  return (
    <EditSP mode={id ? 'update' : 'add'} callback={handleClickPost} isShowSwitch>
      <div className="edit_sp_body_inner customerEditSP">
        <div className="category_wrap">
          <div className="item_wrap">
            <div className="item_body item_customer">
              <TopLabelInputField
                label="顧客名"
                value={customer?.name || ''}
                onChange={(e) => setCustomerName({ ...customer, name: e.target.value })}
                require
                id="name"
                validationList={ValidationLengthUnder60}
                touch={touch}
                disabled={!editAuth}
              />
              <TopLabelInputField
                label="敬称"
                value={customer?.keisho}
                onChange={(e) => setState({ keisho: e.target.value })}
                validationList={ValidationLengthUnder10}
                disabled={!editAuth}
              />
            </div>

          </div>
          <div className="item_wrap">
            <TopLabelInputField
              id="furigana"
              className="full_width"
              label="顧客名（フリガナ）"
              value={customer?.furigana}
              onChange={(e) => setState({ furigana: e.target.value })}
              validationList={ValidationLengthUnder60}
              disabled={!editAuth}
            />
          </div>
        </div>

        <div className="category_wrap">
          <div className="item_wrap flex_box flex_space_between">
            <Button
              size="md"
              color="secondary"
              className="flex_1"
              onClick={() => {
                handleClickRegistrationMap();
              }}
              disabled={!editAuth}
            >
              地図から登録
            </Button>
            <Button
              size="md"
              color="secondary"
              className="ml_10 flex_1"
              disabled={!editAuth}
              onClick={searchPostNo}
            >詳細住所入力
            </Button>
          </div>
          <div className="item_wrap">
            <div className="item_label">郵便番号<Required /></div>
            <div className="item_body item_postal">
              <InputPostNoMulti
                value={customer?.post_no[0]}
                value2={customer?.post_no[1]}
                require
                require2
                disabled={!editAuth}
                disabled2={!editAuth}
                touch={touch}
                touch2={touch}
                onChange={(v) => setState({
                  post_no: [
                    v,
                    customer.post_no[1],
                  ],
                })}
                onChange2={(v) => setState({
                  post_no: [
                    customer.post_no[0],
                    v,
                  ],
                })}
                isSp
              />

              <Button
                size="md"
                color="secondary"
                className="ml_10 flex_1"
                disabled={!editAuth}
                onClick={handleClickSearchAddress}
              >住所入力
              </Button>
            </div>
          </div>
          <div className="item_wrap">
            <div className="item_label">顧客都道府県<Required /></div>
            <div className="item_body item_select full_width">
              <Select
                value={customer?.prefecture}
                onChange={(v) => {
                  setState({
                    prefecture: Number(v),
                    jisx0401_code: v ? plus0(String(v)) : '',
                  });
                }}
                defaultLabel="指定無し"
                options={[
                  ...prefectures.map((v) => ({
                    text: v.label, value: v.value,
                  })),
                ]}
                require
                touch={touch}
                disabled={!editAuth}
              />
            </div>
          </div>
          <div className="item_wrap">
            <TopLabelInputField
              label="顧客住所（市区町村）"
              value={customer?.city}
              onChange={(e) => setState({
                city: e.target.value,
                jisx0402_code: undefined,
              })}
              validationList={ValidationLengthUnder255}
              className="full_width"
              require
              touch={touch}
              disabled={!editAuth}
            />
          </div>
          <div className="item_wrap">
            <TopLabelInputField
              label="顧客住所（地名番地）"
              value={customer?.address}
              onChange={(e) => setState({ address: e.target.value })}
              validationList={ValidationLengthUnder255}
              className="full_width"
              require
              touch={touch}
              disabled={!editAuth}
            />
          </div>
          <div className="item_wrap">
            <TopLabelInputField
              label="建物名等"
              value={customer?.building_name}
              onChange={(e) => setState({ building_name: e.target.value })}
              validationList={ValidationLengthUnder255}
              className="full_width"
              disabled={!editAuth}
            />
          </div>
        </div>

        <div className="category_wrap">
          <div className="item_wrap">
            <LabelInput
              pos="Top"
              label="電話番号"
              type="tel"
              value={customer?.tel_no}
              onChange={(v) => setState({ tel_no: v })}
              className="full_width"
              disabled={!editAuth}
            />
          </div>
          <div className="item_wrap">
            <LabelInput
              pos="Top"
              label="電話番号２"
              type="tel"
              value={customer?.tel_no2}
              onChange={(v) => setState({ tel_no2: v })}
              className="full_width"
              disabled={!editAuth}
            />
          </div>
          <div className="item_wrap">
            <LabelInput
              pos="Top"
              label="電話番号３"
              type="tel"
              value={customer?.tel_no3}
              onChange={(v) => setState({ tel_no3: v })}
              className="full_width"
              disabled={!editAuth}
            />
          </div>
          <div className="item_wrap">
            <LabelInput
              pos="Top"
              label="Email"
              type="email"
              value={customer?.mail_address}
              onChange={(v) => setState({ mail_address: v })}
              className="full_width"
              disabled={!editAuth}
            />
          </div>
          <div className="item_wrap">
            <LabelInput
              pos="Top"
              label="Email2"
              type="email"
              value={customer?.mail_address2}
              onChange={(v) => setState({ mail_address2: v })}
              className="full_width"
              disabled={!editAuth}
            />
          </div>
          <div className="item_wrap">
            <LabelInput
              pos="Top"
              label="Email3"
              type="email"
              value={customer?.mail_address3}
              onChange={(v) => setState({ mail_address3: v })}
              className="full_width"
              disabled={!editAuth}
            />
          </div>
          <div className="item_wrap">
            <LabelInput
              pos="Top"
              label="FAX"
              type="tel"
              value={customer?.fax_no}
              onChange={(v) => setState({ fax_no: v })}
              className="full_width"
              disabled={!editAuth}
            />
          </div>
          <div className="item_wrap">
            <LabelInput
              pos="Top"
              label="LINE ID"
              type="text"
              value={customer?.line_id}
              onChange={(v) => setState({ line_id: v })}
              validationList={ValidationLineId}
              className="full_width"
              disabled={!editAuth}
              withIcon={(
                <CopyToClipboard text={customer?.line_id || ''}>
                  <IconButton
                    className="ml_10"
                    fontAwesomeClass="fas fa-external-link-alt"
                    size="sm"
                    color="white"
                    onClick={() => {
                      if (!customer.line_id) return;
                      snsContact({
                        mode: 'LINE',
                        name: customer.name,
                        customerId: id,
                        id: customer.line_id,
                        callbackPostEnd: () => {
                          callSNS?.();
                          callbackPostEnd?.();
                        },
                      });
                    }}
                    disabled={!customer?.line_id}
                  />
                </CopyToClipboard>
                  )}
            />
          </div>
          <div className="item_wrap">
            <LabelInput
              pos="Top"
              label="Facebook ID"
              type="text"
              value={customer?.facebook_id}
              onChange={(v) => setState({ facebook_id: v })}
              validationList={ValidationFacebookId}
              className="full_width"
              disabled={!editAuth}
              withIcon={(
                <CopyToClipboard text={customer?.facebook_id || ''}>
                  <IconButton
                    className="ml_10"
                    fontAwesomeClass="fas fa-external-link-alt"
                    size="sm"
                    color="white"
                    onClick={() => {
                      if (!customer.facebook_id) return;
                      snsContact({
                        mode: 'Facebook',
                        name: customer.name,
                        customerId: id,
                        id: customer.facebook_id,
                        callbackPostEnd: () => {
                          callSNS?.();
                          callbackPostEnd?.();
                        },
                      });
                    }}
                    disabled={!customer?.facebook_id}
                  />
                </CopyToClipboard>
                )}

            />
          </div>
          <div className="item_wrap">
            <LabelInput
              pos="Top"
              label="Twitter ID"
              type="text"
              value={customer?.twitter_id || ''}
              onChange={(v) => setState({ twitter_id: v })}
              validationList={ValidationTwitterId}
              className="full_width"
              disabled={!editAuth}
              withIcon={(
                <CopyToClipboard text={customer?.twitter_id || ''}>
                  <IconButton
                    className="ml_10"
                    fontAwesomeClass="fas fa-external-link-alt"
                    size="sm"
                    color="white"
                    onClick={() => {
                      if (!customer.twitter_id) return;
                      snsContact({
                        mode: 'Twitter',
                        name: customer.name,
                        customerId: id,
                        id: customer.twitter_id,
                        callbackPostEnd: () => {
                          callSNS?.();
                          callbackPostEnd?.();
                        },
                      });
                    }}
                    disabled={!customer?.twitter_id}
                  />
                </CopyToClipboard>
                )}
            />
          </div>
          <div className="item_wrap">
            <LabelInput
              pos="Top"
              label="Instagram ID"
              type="text"
              value={customer?.instagram_id || ''}
              onChange={(v) => setState({ instagram_id: v })}
              validationList={ValidationInstagramId}
              className="full_width"
              withIcon={(
                <CopyToClipboard text={customer?.instagram_id || ''}>
                  <IconButton
                    className="ml_10"
                    fontAwesomeClass="fas fa-external-link-alt"
                    size="sm"
                    color="white"
                    onClick={() => {
                      if (!customer.instagram_id) return;
                      snsContact({
                        mode: 'Instagram',
                        name: customer.name,
                        customerId: id,
                        id: customer.instagram_id,
                        callbackPostEnd: () => {
                          callSNS?.();
                          callbackPostEnd?.();
                        },
                      });
                    }}
                    disabled={!customer?.instagram_id}
                  />
                </CopyToClipboard>
                )}
              disabled={!editAuth}
            />
          </div>
        </div>

        <div className="category_wrap">
          <div className="item_wrap">
            <div className="item_label">エリア</div>
            <div className="item_body item_area">
              {/* <Select
                value={customer?.area_id}
                defaultLabel="指定無し"
                options={pulldown(areaList)}
                onChange={(v) => setState({ area_id: Number(v) })}
                disabled={!editAuth}
              /> */}
              <InputSelect
                value={customer?.area_id || NaN}
                defaultValue="指定無し"
                options={areaList.map((v) => ({ id: v.id, label: v.name }))}
                onChange={(v) => setState({ area_id: Number(v) })}
                disabled={!editAuth}
                className="react_select"

              />
            </div>
          </div>
          <div className="item_wrap">
            <div className="item_label">営業担当 店舗</div>
            <div className="item_body item_shop">
              <Select
                value={customer?.store_id}
                defaultLabel="指定無し"
                options={pulldown(storeList)}
                onChange={(v) => setState({ store_id: Number(v), employee_id: NaN })}
                disabled={!editAuth}
              />
            </div>
          </div>
          <div className="item_wrap">
            <div className="item_label">営業担当 担当者<Required /></div>
            <div className="item_body item_supporter">
              <Select
                value={customer?.employee_id}
                defaultLabel="指定無し"
                options={pulldown(_employeeList)}
                onChange={(v) => setState({ employee_id: Number(v) })}
                require
                touch={touch}
                disabled={!editAuth}
              />
            </div>
          </div>

          <div className="hidden_category offset">
            <div className="item_wrap">
              <div className="item_label">顧客見込みランク</div>
              <div className="item_body item_customerRank">
                <Select
                  value={customer?.estimated_rank}
                  defaultLabel="指定無し"
                  options={pulldown(customerEstimatedRankList)}
                  onChange={(v) => setState({ estimated_rank: Number(v) })}
                  disabled={!editAuth}
                />
              </div>
            </div>
            <div className="item_wrap">
              <div className="item_label">顧客ランク</div>
              <div className="item_body item_customerRank">
                <Select
                  value={customer?.rank || ''}
                  disabled={!editAuth || user.company_authority4}
                  defaultLabel="指定無し"
                  options={pulldownCustomerRank(customerRankList)}
                  onChange={(v) => {
                    if (!editAuth || user.company_authority4) return;
                    setState({ rank: String(v) });
                  }}
                />
              </div>
            </div>
            <div className="item_wrap">
              <div className="item_label">発生源</div>
              <div className="item_body item_source">
                <Select
                  value={customer?.source_id}
                  defaultLabel="指定無し"
                  options={pulldown(originList)}
                  onChange={(v) => setState({ source_id: Number(v) })}
                  disabled={!editAuth}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="hidden_category">
          <div className="category_wrap">
            <div className="item_wrap tags_form">
              <div className="item_label">見込み部位</div>
              <div className="item_body item_checkbox">
                {customer?.expected_part_list?.data.map((v, i) => (
                  <div
                    key={`eTag${i}`}
                  >
                    <RightLabelCheckbox
                      disabled={!editAuth}
                      checked={v.flag}
                      label={v.label}
                      onChange={() => {
                        customer.expected_part_list?.changeFlag(v.id);
                        setState({

                          expected_part_list: lodash.cloneDeep(customer.expected_part_list),
                        });
                      }}
                    />
                  </div>
                ))}

              </div>
            </div>
            <div className="item_wrap tags_form">
              <div className="item_label">部位</div>
              <div className="item_body item_checkbox">
                {customer?.part_list?.data.map((v, i) => (
                  <div
                    key={`pTag${i}`}
                  >
                    <RightLabelCheckbox
                      disabled={!editAuth}
                      checked={v.flag}
                      label={v.label}
                      onChange={() => {
                        customer.part_list?.changeFlag(v.id);
                        setState({ part_list: cloneDeep(customer.part_list) });
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="category_wrap">
            <div className="item_wrap">
              <div className="item_label">建物分類</div>
              <div className="item_body full_width">
                <Select
                  value={customer?.building_category_id}
                  defaultLabel="指定無し"
                  options={pulldown(buildingCategoryList)}
                  onChange={(v) => setState({ building_category_id: Number(v) })}
                  disabled={!editAuth}
                />
              </div>
            </div>
            <div className="item_wrap">
              <div className="item_label">間取り</div>
              <div className="item_body item_madori">
                <Select
                  value={customer?.madori_id}
                  defaultLabel="指定無し"
                  options={pulldown(madoriList)}
                  onChange={(v) => setState({ madori_id: Number(v) })}
                  disabled={!editAuth}
                />
                <div className="dummy" />
              </div>
            </div>
            <div className="item_wrap">
              <div className="item_label">築年月</div>
              <div className="item_body item_years　/*flex_box flex_align_center*/">
                <DatePicker
                  date={customer.construction_info}
                  onChange={(v) => setState({ construction_info: v })}
                  type="YYYY/MM"
                  disabled={!editAuth}
                />
                <div className="mt_5">
                  <Tikunengetsu date={customer.construction_info} />
                </div>
              </div>
            </div>
          </div>
          <div className="category_wrap">
            <div className="item_wrap">
              <div className="item_label">社内メモ1</div>
              <div className="item_body full_width">
                <TextArea
                  disabled={!editAuth}
                  rows={7}
                  value={customer?.memo1}
                  onChange={(e) => setState({ memo1: e.target.value })}
                  validationList={ValidationLengthUnder255}
                />
                {/*
                <TopLabelInputField
                  label="社内メモ1"
                  value={customer?.memo1}
                  onChange={(e) => setState({ memo1: e.target.value })}
                  validationList={ValidationLengthUnder255}
                  className="full_width"
                  disabled={!editAuth}
                />
                  */}
              </div>
            </div>
            <div className="item_wrap">
              <div className="item_label">社内メモ2</div>
              <div className="item_body full_width">
                <TextArea
                  disabled={!editAuth}
                  rows={7}
                  value={customer?.memo2}
                  onChange={(e) => setState({ memo2: e.target.value })}
                  validationList={ValidationLengthUnder255}
                />
                {/*
                <TopLabelInputField
                  label="社内メモ2"
                  value={customer?.memo2}
                  onChange={(e) => setState({ memo2: e.target.value })}
                  validationList={ValidationLengthUnder255}
                  className="full_width"
                  disabled={!editAuth}
                />
                  */}
              </div>
            </div>
          </div>
          <div className="category_wrap">
            <div className="item_wrap tags_form">
              <div className="item_label">マイカー種別</div>
              <div className="item_body item_checkbox">
                {customer?.my_car_type?.data.map((v, i) => (
                  v.id !== 100
                    ? (
                      <div
                        key={`mTag${i}`}
                      >
                        <RightLabelCheckbox
                          checked={v.flag}
                          label={v.label}
                          disabled={!editAuth}
                          onChange={() => {
                            customer.my_car_type?.changeFlag(v.id);
                            setState(
                              { my_car_type: lodash.cloneDeep(customer.my_car_type) },
                            );
                          }}
                        />
                      </div>
                    )
                    : (
                      <div className="single_column width_inputField" key={`other${i}`}>
                        <RightLabelCheckbox
                          label={v.label}
                          checked={v.flag}
                          disabled={!editAuth}
                          onChange={() => {
                            customer.my_car_type?.changeFlag(v.id);
                            setState(
                              { my_car_type: lodash.cloneDeep(customer.my_car_type) },
                            );
                          }}
                        />
                        <div className="inputField">
                          <span>（</span>
                          <Input
                            value={customer?.my_car_type_other}
                            type="text"
                            disabled={!editAuth}
                            onChange={(e) => v.flag && setState(
                              { my_car_type_other: e.target.value },
                            )}
                            validationList={ValidationLengthUnder20}
                          /><span>）</span>
                        </div>
                      </div>
                    )
                ))}
              </div>
            </div>
          </div>

          <div className="category_wrap">
            <div className="item_wrap tags_form">
              <div className="item_label">関連タグ</div>
              <div className="item_body item_checkbox">
                {customer?.tag_list?.data.map((v, i) => (
                  v.id !== 100
                    ? (
                      <div
                        key={`rTag${i}`}
                      >
                        <RightLabelCheckbox
                          checked={v.flag}
                          label={v.label}
                          disabled={!editAuth}
                          onChange={() => {
                            customer.tag_list?.changeFlag(v.id);
                            setState(
                              { tag_list: lodash.cloneDeep(customer.tag_list) },
                            );
                          }}
                        />
                      </div>
                    )
                    : (
                      <div className="single_column width_inputField" key={`other${i}`}>
                        <RightLabelCheckbox
                          label={v.label}
                          checked={v.flag}
                          disabled={!editAuth}
                          onChange={() => {
                            customer.tag_list?.changeFlag(v.id);
                            setState(
                              { tag_list: lodash.cloneDeep(customer.tag_list) },
                            );
                          }}
                        />
                        <div className="inputField">
                          <span>（</span>
                          <Input
                            value={customer?.tag_list_other}
                            type="text"
                            disabled={!editAuth}
                            onChange={(e) => v.flag && setState(
                              { tag_list_other: e.target.value },
                            )}
                            validationList={ValidationLengthUnder20}
                          /><span>）</span>
                        </div>
                      </div>
                    )
                ))}
              </div>
            </div>
          </div>

          {/* <div className="category_wrap">
            <div className="item_wrap tags_form">
              <div className="item_label">関連タグ</div>
              <div className="item_body item_checkbox">
                {customer?.tag_list?.data.map((v, i) => (
                  <div key={`rTag${i}`}>
                    <RightLabelCheckbox
                      disabled={!editAuth}
                      checked={v.flag}
                      label={v.label}
                      onChange={() => {
                        customer.tag_list?.changeFlag(v.id);
                        setState({ tag_list: cloneDeep(customer.tag_list) });
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div> */}

          <div className="category_wrap">
            <div className="item_wrap">
              <div className="item_label">備考</div>
              <div className="item_body full_width item_remarks">
                <TextArea
                  disabled={!editAuth}
                  rows={7}
                  value={customer?.remarks}
                  onChange={(e) => setState({ remarks: e.target.value })}
                  validationList={ValidationLengthUnder500}
                />
              </div>
            </div>
          </div>

          <div className="category_wrap">
            <div className="item_wrap">
              <TopLabelInputField
                label="紹介者"
                disabled={!editAuth}
                value={customer?.introducer}
                onChange={(e) => setState({ introducer: e.target.value })}
                validationList={ValidationLengthUnder60}
                className="full_width"
              />
            </div>
            <div className="item_wrap">
              <div className="item_label">結婚記念日</div>
              <DatePicker
                date={customer.wedding_anniversary}
                disabled={!editAuth}
                onChange={(v) => setState({ wedding_anniversary: v })}
                validationList={ValidationDatePicker}
              />
            </div>

            <div className="item_wrap">
              <div className="item_label">ご家族情報</div>
              <div className="item_body full_width item_cardList">
                <div className="body">
                  <Segment>
                    <FamilyInfoListSP
                      key={`fam${famEditCount}`}
                      callback={(v) => {
                        if (!editAuth) return;
                        setFamilyId(v);
                      }}
                      callbackSort={familySort}
                      list={familyListModel.list}
                    />
                  </Segment>
                </div>
                <div className="footer">
                  <Button
                    size="md"
                    color="secondary"
                    disabled={!editAuth}
                    onClick={() => editFamily()}
                  >登録
                  </Button>
                  <Button
                    size="md"
                    color="secondary"
                    disabled={!editAuth || (!familyId && familyId !== 0)}
                    onClick={() => editFamily('edit')}
                  >編集
                  </Button>
                  <Button
                    size="md"
                    color="dark"
                    disabled={!editAuth || (!familyId && familyId !== 0)}
                    onClick={removeFamily}
                  >削除
                  </Button>
                </div>
              </div>
            </div>

            <div className="item_wrap">
              <div className="item_label">ペット情報</div>
              <div className="item_body full_width item_cardList">
                <div className="body">
                  <Segment><PetInfoListSP
                    key={`pet${petEditCount}`}
                    list={petListModel?.list}
                    callback={(v) => {
                      if (!editAuth) return;
                      setPetId(v);
                    }}
                    callbackSort={petSort}
                  />
                  </Segment>
                </div>
                <div className="footer">
                  <Button
                    size="md"
                    color="secondary"
                    disabled={!editAuth}
                    onClick={() => editPet()}
                  >登録
                  </Button>
                  <Button
                    size="md"
                    color="secondary"
                    disabled={!editAuth || (!petId && petId !== 0)}
                    onClick={() => editPet('edit')}
                  >編集
                  </Button>
                  <Button
                    size="md"
                    color="dark"
                    disabled={!editAuth || (!petId && petId !== 0)}
                    onClick={removePet}
                  >削除
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </EditSP>
  );
};
