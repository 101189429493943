import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { goBack } from 'connected-react-router';
import { cloneDeep, isEqual } from 'lodash';
import { DisplayElements } from '../../../../../../type/display-elements.type';
import { Table } from '../../../../../ui/table/table';
import { RightLabelCheckbox } from '../../../../../ui/checkbox/right-label-checkbox/right-label-checkbox';
import { LeftIconButton } from '../../../../../ui/button/left-icon-button/left-icon-button';
import { Button } from '../../../../../ui/button/button';
import { TableOption } from '../../../../../../type/table.type';
import { MasterActions } from '../../../../../../redux/master/master.action';
import { MasterIndexType } from '../../../../../../redux/master/api/api-master-index';

export type MasterGetListParam = {
  highlow: number;
  sort_by: number;
}

type Props = {
  header: { key: string; label: string;}[];
  rowDataList: any[];
  list: DisplayElements[][];
  callbackEdit: (v?: any) => void;
  callbackGetList: (v: MasterGetListParam) => void;
  callbackIsSort?: (v: boolean) => void;
  callbackIsMuko?: (v: boolean) => void;
  sortIndex?: MasterIndexType;
  defaultOrder: number;
  tableOption?: TableOption;
  isNoEdit?: boolean;
};

export const MasterBody = (props: Props) => {
  const {
    rowDataList,
    header,
    callbackGetList,
    callbackEdit,
    callbackIsSort,
    callbackIsMuko,
    sortIndex,
    list,
    tableOption,
    defaultOrder,
    isNoEdit,
  } = props;

  const dispatch = useDispatch();

  /* State */
  const [isSort, setIsSort] = useState(false);
  const [showList, setShowList] = useState<DisplayElements[][]>([]);
  const [rowList, setRowList] = useState<any[]>([]);
  const [selected, setSelected] = useState(NaN);
  const [isMuko, setIsMuko] = useState(false);
  const [dispMuko, setDispMuko] = useState(false);
  const [orderSort, setOrderSort] = useState<MasterGetListParam>({
    highlow: defaultOrder,
    sort_by: 0,
  });

  const sendRowList = useMemo(() => {
    const li = rowDataList.filter((v) => ((dispMuko || isSort) ? true : v.valid_flag));
    return li;
  }, [rowDataList, dispMuko, isSort]);

  /* Callback */
  const handleClickRow = useCallback(
    (row: any) => {
      setSelected(cloneDeep(sendRowList.findIndex((v2) => v2.id === row.id)));
    }, [sendRowList],
  );
  const handleDbClickRow = useCallback((row: any) => {
    if (isSort) return;
    callbackEdit(row.id);
  },

  [callbackEdit, rowDataList, isMuko, isSort, sendRowList]);

  const getList = useCallback(() => {
    callbackGetList(orderSort);
  },
  [callbackGetList, orderSort, isMuko]);

  const sortStart = useCallback(() => {
    setSelected(NaN);
    setIsSort(true);
    callbackIsSort?.(true);
  }, []);

  const sortEnd = useCallback((isRegister: boolean = false) => {
    setSelected(NaN);
    setIsSort(false);
    callbackIsSort?.(false);
    if (!sortIndex) return;
    if (isRegister) {
      const _v = rowList.map((f) => (f.id));
      const _list = rowDataList.map((f) => (f.id));
      if (!isEqual(_v, _list)) {
        dispatch(MasterActions.api.index({
          param: {
            type: sortIndex,
            ids: _v.map((f) => f),
          },
          onSuccess: () => {
            callbackGetList(orderSort);
          },
          onError: () => {
            setIsSort(true);
          },
        }));
      }
    }
  }, [showList, rowDataList, orderSort]);

  const handleClickHeader = useCallback((highlow, sort_by) => {
    setOrderSort({ highlow, sort_by });
  }, []);

  const sortCancel = useCallback(() => {
    sortEnd();
    setShowList(cloneDeep(list));
    setRowList(cloneDeep(rowDataList));
  }, [list, rowDataList]);

  const onMove = useCallback((_showList: DisplayElements[][], _rowList: any[]) => {
    _showList.forEach((v) => {
      v.shift();
    });
    setShowList(cloneDeep(_showList));
    setRowList(cloneDeep(_rowList));
  }, []);

  const onClickSearch = useCallback(() => {
    setDispMuko(isMuko);
    callbackIsMuko?.(isMuko);
  }, [isMuko]);

  /* Effect */
  useEffect(() => {
    getList();
  }, [orderSort]);

  useEffect(() => {
    setShowList(list);
  }, [list]);

  useEffect(() => {
    setRowList(cloneDeep(rowDataList));
  }, [rowDataList]);

  const sendList = useMemo(() => showList.map((v: any, i) => ([
    !isSort ? (
      <Button
        color="secondary"
        size="md"
        onClick={(e) => {
          e.stopPropagation();
          callbackEdit(rowDataList.filter((v2) => (!isMuko ? v2.valid_flag : true))[i].id);
        }}
      >
        編集
      </Button>
    ) : <i className="fas fa-bars" />,
    ...v,
  ])), [showList, rowDataList, isSort]);

  return (
    <div className="main_cnt">
      <div className="search_area only_simple ">
        <div className="item_wrap">
          <div className="item_box">
            <RightLabelCheckbox
              checked={isMuko}
              label="無効情報も含む"
              onChange={() => setIsMuko(!isMuko)}
            />
          </div>
          <LeftIconButton
            label="絞込み"
            fontAwesomeClass="fas fa-filter"
            className="btn_search for_simple"
            size="sm"
            color="secondary"
            onClick={onClickSearch}
          />
        </div>
      </div>
      <div className="option_area table_sort">
        <div className="left">
          <div className="count">総件数： &nbsp;<span>{list.length}</span> 件</div>
        </div>
        <div className="right" style={{ display: 'flex' }}>
          {isSort
            ? (
              <>
                <Button
                  color="dark"
                  size="sm"
                  onClick={sortCancel}
                >
                  キャンセル
                </Button>
                <LeftIconButton
                  label="並び替え登録"
                  size="sm"
                  fontAwesomeClass="fas fa-edit"
                  className="btn_search for_detail"
                  color="primary"
                  onClick={() => sortEnd(true)}
                />
              </>
            )
            : (
              <>
                {!isNoEdit && (
                <LeftIconButton
                  label="並び替え"
                  fontAwesomeClass="fas fa-sort"
                  className=""
                  size="sm"
                  color="secondary"
                  onClick={sortStart}
                />
                )}
                <LeftIconButton
                  label="新規登録"
                  size="sm"
                  fontAwesomeClass="fas fa-edit"
                  className="btn_search for_detail"
                  color="primary"
                  onClick={() => callbackEdit()}
                />
              </>
            )}
        </div>
      </div>
      <section className="result_area list_area">
        <div className="inner">
          <div className="table_responsive">
            {/* <Table /> */}
            <Table
              className={`table_selectable table_sortable table_sticky table_cell_change ${isSort ? 'sort_mode' : ''}`}
              header={header.map((v) => v.label)}
              onClickRow={handleClickRow}
              onDbClick={(e) => handleDbClickRow(e)}
              selectedTr={[selected]}
              rowDataList={sendRowList}
              disabledTh={[0]}
              // ids={cloneList.map((v)=>(v.idx))}
              // sort={{ onClick: (highlow, sort_by) => setOrderSort({ highlow, sort_by }) }}
              sort={{ onClick: handleClickHeader }}
              lists={sendList}
              callbackMoveList={onMove}
              option={tableOption}
              isDnD={isSort}
              isMukoDisp
            />
          </div>
        </div>
      </section>
      <footer className="btn_area">
        <div className="left_box" />
        <div className="right_box">
          <LeftIconButton
            label="戻る"
            fontAwesomeClass="fas fa-arrow-left"
            size="sm"
            color="dark"
            onClick={() => dispatch(goBack())}
          />
        </div>
      </footer>
    </div>
  );
};
