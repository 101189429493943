/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useCallback } from 'react';
import { DisplayElements } from '../../../../type/display-elements.type';
import './table-td.scss';

type TableTdProps = {
  tdList: DisplayElements[],
  selectable?: boolean,
  tdOption?: {
    tdAlign?: {
      index: number,
      align: string,
    }[],
    ellipsisLine?: number[],
  },
  rowSpanOption?: {
    line: number,
    item: number,
    length: number,
  } | null,
  onClickCell?:(i: number, e: React.MouseEvent<HTMLTableCellElement>) => void,
  onDoubleClick?: (i: number, e: React.MouseEvent<HTMLTableCellElement>) => void;
};

export const TableTd = (props: TableTdProps) => {
  const {
    tdList, tdOption, selectable, rowSpanOption, onClickCell, onDoubleClick,
  } = props;

  const handleOnClick = useCallback((i: number,
    e: React.MouseEvent<HTMLTableCellElement>) => {
    onClickCell?.(i, e);
  }, []);

  return (
    <>
      {
        tdList.map((data, i) => (
          <td
            key={i}
            className={
              `
              ${selectable ? (' selectable') : ('')}
              ${tdOption?.ellipsisLine ? tdOption?.ellipsisLine.map((v) => (
                i === v ? (' isEllipsis ') : ('')
              ))
                : ('')}
                `
              }
            style={
              tdOption?.tdAlign?.find((v) => i === v.index) ? {
                textAlign: `${tdOption?.tdAlign?.find((v) => v.index === i)?.align}` as any,
              } : { textAlign: 'center' }
            }
            rowSpan={rowSpanOption?.item === i ? rowSpanOption.length : 1}
            onClick={(e) => handleOnClick(i, e)}
            onDoubleClick={(e) => onDoubleClick?.(i, e)}
          >
            {data}
          </td>
        ))
      }
    </>
  );
};
