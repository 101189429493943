import React, {
  useCallback, useState,
} from 'react';
import { cloneDeep } from 'lodash';
import { useDispatch } from 'react-redux';
import { Button } from '../../../../ui/button/button';
import './file-edit.pc.scss';
import { useDidMount } from '../../../../../hooks/life-cycle';
import { EditPC } from '../../../../dialogs/edit/edit.pc';
import { FileEditState, FileType } from '../../../../../type/file/file.type';
import { FileCollection } from '../../../../../collection/file/file.collection';
import { FileActions } from '../../../../../redux/file/file.action';
import { DialogActions } from '../../../../../redux/dialog/dialog.action';
import { CustomerSearch } from '../../../layout/search-box/customer/customer-search/customer-search';
import { Customer, CustomerListType } from '../../../../../type/customer/customer.type';
import { Project, ProjectListType } from '../../../../../type/project/project.type';
import { Input } from '../../../../ui/input/input';
import { Required } from '../../../../ui/required/required';
import { TextArea } from '../../../../ui/text-area/text-area';
import { ProjectSearch } from '../../../layout/search-box/project/project-search/project-search';
import { FileValidation } from '../../../../../model/validation/file/file.validation';
import { FileUploadButton } from '../../../../ui/file-upload/file-upload-button';
import { ValidationLengthUnder40, ValidationLengthUnder500, ValidationLengthUnder60 } from '../../../../../model/validation';
import { CommonCollection } from '../../../../../collection/common/common.collection';
import { FileModel } from '../../../../../model/file/file.model';
import { Message } from '../../../../../collection/message.collection';
import { InfoButton } from '../../../../ui/button/info-button/info-button';
import { useRegistrationMapCustomer, useRegistrationMapProject } from '../../../../../hooks/map/use-map';
import { DateFormatter } from '../../../../../utilities/date-formatter';

const { extname, basename } = require('path');

type Props = {
  id?: number;
  isOrder?: boolean;
  customer?: Customer;
  project?: Project | ProjectListType;
  callback: () => void;
  callbackClose?: (v: boolean) => void;
}

export const FileEditPC = (props: Props) => {
  const {
    customer, id, project, callback, callbackClose, isOrder,
  } = props;

  /* Hooks */
  const dispatch = useDispatch();
  const regiMapCustomer = useRegistrationMapCustomer();
  const regiMapProject = useRegistrationMapProject();

  /* State */
  const [customerName, setCustomerName] = useState('');
  const [projectName, setProjectName] = useState('');
  const [file, setFile] = useState<FileEditState>(FileCollection.editInitialState);
  const [formats, setFormats] = useState<string[]>([]);
  const [touch, setTouch] = useState(false);
  const [info, setInfo] = useState<FileType | null>(null);

  /* Callback */
  const setState = useCallback((v: Partial<FileEditState>) => {
    setFile({ ...cloneDeep(file), ...cloneDeep(v) });
  }, [file]);

  /** 保存 */
  const post = useCallback(() => {
    if (!file.file_name.length) {
      dispatch(DialogActions.pushMessage({
        title: 'ファイル情報入力',
        message: ['ファイルを選択してください'],
      }));
      return;
    }
    if (FileValidation(file, customerName, projectName, id)) {
      dispatch(DialogActions.pushMessage({
        title: 'ファイル情報入力',
        message: Message.postError,
        callback: () => setTouch(true),
      }));
      return;
    }
    dispatch(FileActions.api.file.post({
      param: {
        id,
        data: {
          ...FileModel.postParam(file, id),
          // eslint-disable-next-line no-nested-ternary
          order_file: isOrder ? 1 : info?.order_file ? 1 : 0,
        },
      },
      onSuccess: callback,
    }));
  }, [id, file, callback, customerName, projectName, isOrder, info]);

  /* 顧客検索 */
  const customerSearch = useCallback(() => {
    dispatch(DialogActions.push({
      title: '顧客検索',
      className: 'max_height_dialog',
      onCloseClick: () => callbackClose?.(false),
      element: <CustomerSearch
        callback={(data) => {
          const isSame = data.id === file.customer_id;
          setState({
            customer_id: data.id,
            project_id: !isSame ? NaN : file.project_id,
          });
          setCustomerName(data.name);
          setProjectName(!isSame ? '' : projectName);
        }}
        freeAuth
      />,
    }));
  }, [file, projectName]);

  /* 案件検索 */
  const projectSearch = useCallback(() => {
    dispatch(DialogActions.push({
      title: '案件検索',
      className: 'max_height_dialog',
      element: <ProjectSearch
        type="file"
        callback={(data) => {
          setFile({
            ...file,
            customer_id: data.customer_id,
            project_id: data.id,
          });
          setCustomerName(data.customer_name);
          setProjectName(data.name);
        }}
      />,
    }));
  }, [file]);

  /* ファイルの読み込み */
  const onFileInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files;
    if (!fileList?.length) return;
    let files = file.file;
    const fileNames = cloneDeep(file.file_name);
    const _formats = cloneDeep(formats);

    if (id) {
      const extension = extname(fileList[0].name);
      if (files.length) {
        files = [];
      }
      files.push(fileList[0]);
      fileNames[0] = basename(fileList[0].name, extension);
      _formats[0] = (extension);
    } else {
      for (let i = 0; i < fileList.length; i += 1) {
        const extension = extname(fileList[i].name);
        files.push(fileList[i]);
        fileNames.push(basename(fileList[i].name, extension));
        _formats.push(extension);
      }
    }
    setState(cloneDeep({ file: files, file_name: fileNames }));
    setFormats(cloneDeep(_formats));
  }, [file, formats, id]);

  /* 地図から検索 */
  const mapSearch = useCallback((type: 'customer' | 'project') => {
    if (type === 'customer') {
      regiMapCustomer((v) => {
        dispatch(DialogActions.pop());
        const isSame = v.id === file.customer_id;
        setCustomerName(v.name);
        setProjectName(!isSame ? '' : projectName);
        setState({
          customer_id: v.id,
          project_id: !isSame ? NaN : file.project_id,
        });
      });
      return;
    }
    regiMapProject((v) => {
      dispatch(DialogActions.pop());
      setCustomerName(v.customer_name);
      setProjectName(v.name);
      setState({
        customer_id: v.customer_id,
        project_id: v.id,
      });
    });
  }, [file, projectName]);

  /* ファイルのダウンロード */
  const downloadFile = useCallback(() => {
    dispatch(FileActions.api.file.download({
      param: { file_id: Number(id) },
      fileName: file.file_name[0] || '',
    }));
  }, [id, file]);

  /* ファイルの削除 */
  const deleteFile = useCallback(() => {
    if (!id) return;
    dispatch(DialogActions.pushMessage({
      title: 'ファイル削除',
      message: ['削除しますか'],
      isCancel: true,
      callback: () => {
        dispatch(FileActions.api.file.delete({
          param: { id: Number(id) },
          callback,
        }));
      },
    }));
  }, [callback, id]);

  /* ファイルの削除 */
  const removeFile = useCallback((index: number) => {
    file.file.splice(index, 1);
    file.file_name.splice(index, 1);
    formats.splice(index, 1);
    setState({
      file: cloneDeep(file.file),
      file_name: cloneDeep(file.file_name),
    });
    setFormats(cloneDeep(formats));
  }, [file, formats]);

  useDidMount(() => {
    /* 案件データの当て込み */
    if (project) {
      setState({
        customer_id: project.customer_id,
        project_id: project.id,
      });
      setCustomerName(project.customer_name);
      setProjectName(project.name);
      return;
    }

    /* 顧客データの当て込み */
    if (customer) {
      setState({ customer_id: customer.id });
      setCustomerName(customer.name);
      return;
    }

    if (!id && !isOrder) {
      customerSearch();
      return;
    }

    /* ファイル1件取得 */
    if (id !== undefined) {
      dispatch(FileActions.api.file.get({
        param: { id },
        callback: (data) => {
          setInfo(cloneDeep(data));
          setState(FileModel.setGetData(data));
          setFormats([data.format || '']);
          setCustomerName(data.customer_name);
          setProjectName(data.project_name);
        },
      }));
    }
  });

  return (
    <EditPC
      mode="dialog"
      callback={post}
      buttonArea={id ? (
        <>
          <Button
            size="md"
            color="primary"
            onClick={post}
          >
            更新
          </Button>
          <Button
            size="md"
            color="secondary"
            onClick={downloadFile}
          >
            ダウンロード
          </Button>
          <Button
            size="md"
            color="dark"
            onClick={deleteFile}
          >
            削除
          </Button>
        </>
      ) : undefined}
    >
      {/* edit_sp_body_inner は各画面の共通用 */}
      <div className="">
        <div className="item_wrap">
          <div className="item_box">
            <div className="item_head">顧客名<Required /></div>
            <Input
              value={customerName}
              disabled
              errorPosBottom
              require
              touch={touch}
            />
            {!isOrder && (
            <>
              <Button
                size="sm"
                color="secondary"
                className="ml_10"
                onClick={customerSearch}
              >
                顧客参照
              </Button>
              <Button
                className="ml_10"
                size="sm"
                color="secondary"
                onClick={() => {
                  mapSearch('customer');
                }}
              >
                地図から検索
              </Button>
            </>
            )}
          </div>
        </div>
        <div className="item_wrap">
          <div className="item_box">
            <div className="item_head">案件名</div>
            <Input
              value={projectName}
              disabled
            />
            {!isOrder && (
            <>
              <Button
                size="sm"
                color="secondary"
                className="ml_10"
                onClick={projectSearch}
              >
                案件参照
              </Button>
              <Button
                className="ml_10"
                size="sm"
                color="secondary"
                onClick={() => {
                  mapSearch('project');
                }}
              >
                地図から検索
              </Button>
            </>
            )}
          </div>
        </div>
        <div className="item_wrap">
          <div className="item_box">
            <div className="item_head">ファイル名<Required /></div>
            <div>
              {Boolean(file.file_name.length) && (
                file.file_name.map((v, i) => (
                  <div style={{ display: 'flex' }} className="mb_5">
                    <Input
                      className="mr_10"
                      value={v}
                      onChange={(e) => {
                        file.file_name[i] = e.target.value;
                        setState({
                          file_name: cloneDeep(file.file_name),
                        });
                      }}
                      require
                      validationList={ValidationLengthUnder40}
                      touch={touch}
                    />
                    <div>{formats[i]}</div>
                    {!id
                      && (
                        <Button
                          size="sm"
                          color="dark"
                          className="ml_10"
                          onClick={() => removeFile(i)}
                        >
                          削除
                        </Button>
                      )}
                  </div>
                ))
              )}
              <div className="flex_no_wrap_box">
                <FileUploadButton
                  onChange={onFileInputChange}
                  accept={CommonCollection.acceptFile}
                  multiple={Boolean(!id)}
                />
                <InfoButton title={Message.fileInfo} />
              </div>
            </div>
          </div>
        </div>
        <div className="item_wrap">
          <div className="item_box large">
            <div className="item_head">コメント</div>
            <TextArea
              rows={7}
              className="large"
              value={file?.comment}
              onChange={(e) => setState({ comment: e.target.value })}
              validationList={ValidationLengthUnder500}
            />
          </div>
        </div>
        <div className="item_wrap">
          <div className="item_box">
            <div className="item_head">登録日</div>
            <Input
              value={DateFormatter.date2str(info?.created_at, 'YYYYMMDD_HHmm')}
              className="size_datepicker add_time"
              disabled
            />
          </div>
          <div className="item_box">
            <div className="item_head">登録者</div>
            <Input
              value={info?.created_employee_name || ''}
              className=""
              disabled
            />
          </div>
        </div>
        <div className="item_wrap">
          <div className="item_box">
            <div className="item_head">更新日</div>
            <Input
              value={DateFormatter.date2str(info?.updated_at, 'YYYYMMDD_HHmm')}
              className="size_datepicker add_time"
              disabled
            />
          </div>
          <div className="item_box">
            <div className="item_head">更新者</div>
            <Input
              value={info?.updated_employee_name || ''}
              className=""
              disabled
            />
          </div>
        </div>
      </div>
    </EditPC>
  );
};
