import { put, takeEvery, delay } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { ResultType } from '../../type/api.type';
import { AuthActions } from './auth.action';
import { RoutingPath } from '../../routes/routing-pass';
import { SystemActions } from '../system/system.action';
import { ApiLogout } from './api/logout/logout';
import { DialogActions } from '../dialog/dialog.action';
import { ApiBase } from '../../service/api-base';
import { ApiIsLogin } from './api/is-login/is-login';
import {
  ApiPasswordMailSend, ApiPasswordCheckDate, ApiPasswordChangeIn,
  ApiPasswordChangeOut, ApiUserActivate, ApiActivateMailAgain,
} from './api/password/api-password';
import { Store } from '../store';
import { ApiLogin } from './api/login/login';
import { postHandle } from '../root.saga';
import { ApiUser } from '../user/api/api-user';

function* tryApiLogin(action: ReturnType<typeof AuthActions.api.login>) {
  yield put(SystemActions.isLoading(true));
  const { param, onError } = action.payload;
  const api = new ApiLogin(param);
  try {
    // FIXME ログアウト後で消す 応急処置
    yield new ApiLogout().run();
    const res: ResultType = yield api.run();
    if (ApiBase.isSuccess(res)) {
      // yield put(AuthActions.api.user());
      yield put(push(RoutingPath.customer));
    } else {
      Store.dispatch(DialogActions.pushMessage({
        title: 'ログイン',
        message: res.header.messages || [],
      }));
      if (onError) onError(['']);
    }
  } catch (e) {
    yield put(SystemActions.connectionError({}));
  }
  yield put(SystemActions.isLoading(false));
}

function* tryApiLogout() {
  const request = new ApiLogout();
  try {
    yield request.run();
  } catch (e) {
    yield;
  } finally {
    localStorage.setItem('isLogin', 'false');
    yield put(DialogActions.pushMessage({
      title: 'ログアウト',
      message: ['ログアウトしました'],
      callback: () => {
        Store.dispatch(push(RoutingPath.login));
        Store.dispatch(SystemActions.allReset());
      },
      callbackClose: () => {
        Store.dispatch(push(RoutingPath.login));
        Store.dispatch(SystemActions.allReset());
      },
    }));
  }
}

function* tryChangePassword(
  action: ReturnType<typeof AuthActions.api.password.change>,
) {
  const request = new ApiPasswordChangeOut(action.payload);
  try {
    const result: ResultType = yield request.run();
    if (ApiBase.isSuccess(result)) {
      yield put(push(RoutingPath.changePasswordComplete));
    } else {
      yield put(SystemActions.errorHandle({ result, title: '' }));
    }
  } catch (e) {
    yield put(SystemActions.connectionError({}));
  }
}

function* tryActivatePassword(
  action: ReturnType<typeof AuthActions.api.password.activate>,
) {
  const request = new ApiUserActivate(action.payload);
  try {
    const result: ResultType = yield request.run();
    if (ApiBase.isSuccess(result)) {
      yield put(push(RoutingPath.userActivateComplete));
    } else {
      yield put(SystemActions.errorHandle({ result, title: '' }));
    }
  } catch (e) {
    yield put(SystemActions.connectionError({}));
  }
}

function* tryDialogChangePassword(
  action: ReturnType<typeof AuthActions.api.password.dialogChange>,
) {
  const request = new ApiPasswordChangeIn(action.payload);

  try {
    const result: ResultType = yield request.run();
    if (ApiBase.isSuccess(result)) {
      yield put(DialogActions.pushMessage({
        title: '',
        message: ['変更しました'],
        callback: () => {
          Store.dispatch(DialogActions.pop());
          Store.dispatch(DialogActions.pop());
        },
      }));
    } else {
      yield put(SystemActions.errorHandle({ result, title: '' }));
    }
  } catch (error) {
    yield put(SystemActions.connectionError({}));
  }

  /* TODO Responseが来てから帰る */
  // yield postHandle({
  //   api,
  //   onSuccess: () => {

  //   },
  // });
}

function* tryPasswordEmailSend(
  action: ReturnType<typeof AuthActions.api.password.send>,
) {
  const { param, onSuccess } = action.payload;
  const request = new ApiPasswordMailSend(param);
  try {
    const result: ResultType = yield request.run();
    if (ApiBase.isSuccess(result)) {
      onSuccess();
      yield put(push(RoutingPath.sendPasswordComplete));
    } else {
      yield put(DialogActions.pushMessage({
        title: '',
        message: result.header.messages || [],
      }));
    }
  } catch (e) {
    yield put(SystemActions.connectionError({}));
  }
}

function* tryPasswordEmailSendActivate(
  action: ReturnType<typeof AuthActions.api.password.sendActivate>,
) {
  yield put(SystemActions.isLoading(true));

  const { param, onSuccess } = action.payload;
  const request = new ApiActivateMailAgain(param);
  const me = new ApiUser();
  try {
    yield me.run();
    yield put(AuthActions.setToken(me.token));
    yield delay(200);
    console.log(me);
    request.header = {
      ...request.header,
      'X-CSRF-TOKEN': me.token,
    };

    const result: ResultType = yield request.run();
    if (ApiBase.isSuccess(result)) {
      yield put(DialogActions.pushMessage({
        title: '招待メール再送信',
        message: ['招待メールを再送信しました'],
        callback: () => onSuccess(),
        callbackClose: () => onSuccess(),
      }));
    } else {
      yield put(DialogActions.pushMessage({
        title: '招待メール再送信',
        message: result.header.messages || ['招待メールの再送信に失敗しました'],
      }));
      yield put(SystemActions.errorHandle({ result, title: '' }));
    }
  } catch (e) {
    yield put(SystemActions.connectionError({}));
  }
  yield put(SystemActions.isLoading(false));
}

function* tryCheckDate(
  action: ReturnType<typeof AuthActions.api.password.checkDate>,
) {
  const { param, onSuccess, onError } = action.payload;
  const request = new ApiPasswordCheckDate(param);
  try {
    const result: ResultType = yield request.run();
    if (ApiBase.isSuccess(result)) {
      onSuccess();
    } else {
      onError();
    }
  } catch (e) {
    yield put(SystemActions.connectionError({}));
  }
}

function* tryIsLogin() {
  const request = new ApiIsLogin();
  try {
    const result: ResultType = yield request.run();
    if (!ApiBase.isSuccess(result)) {
      yield put(SystemActions.errorHandle({ result, title: '' }));
    }
  } catch (e) {
    yield put(SystemActions.connectionError({}));
  }
}

export function* AuthSaga() {
  yield takeEvery(AuthActions.api.login, tryApiLogin);
  yield takeEvery(AuthActions.api.logout, tryApiLogout);
  yield takeEvery(AuthActions.api.password.dialogChange, tryDialogChangePassword);
  yield takeEvery(AuthActions.api.password.change, tryChangePassword);
  yield takeEvery(AuthActions.api.password.activate, tryActivatePassword);
  yield takeEvery(AuthActions.api.password.send, tryPasswordEmailSend);
  yield takeEvery(AuthActions.api.password.sendActivate, tryPasswordEmailSendActivate);
  yield takeEvery(AuthActions.api.password.checkDate, tryCheckDate);
  yield takeEvery(AuthActions.api.isLogin, tryIsLogin);
}
