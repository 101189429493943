/* eslint-disable no-undef */
import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cloneDeep } from 'lodash';
import { push } from 'connected-react-router';
import { DialogActions } from '../../../../../redux/dialog/dialog.action';
import { Button } from '../../../../ui/button/button';
import { Input } from '../../../../ui/input/input';
import { Required } from '../../../../ui/required/required';
import { prefectures, pulldownPrefecture } from '../../../../../collection/prefectures';
import { TagActions } from '../../../../../redux/tag/tag.action';
import { Select } from '../../../../ui/select/select';
import { RegistrationAddressMapDialog } from '../../../../ui/map/registration-address-map-dialog/registration-address-map-dialog';
import { LeftLabelInputField } from '../../../../ui/input-field/left-label-input-field/left-label-input-field';
import { ProjectActions } from '../../../../../redux/project/project.action';
import { ProjectCollection } from '../../../../../collection/project/project.collection';
import { ProjectEditState, Project } from '../../../../../type/project/project.type';
import { DatePicker } from '../../../../ui/date-picker/date-picker';
import { EditPC } from '../../../../dialogs/edit/edit.pc';
import { DateFormatter } from '../../../../../utilities/date-formatter';
import { TextArea } from '../../../../ui/text-area/text-area';
import { MapActions } from '../../../../../redux/map/map.action';
import { CustomerSearch } from '../../../layout/search-box/customer/customer-search/customer-search';
import {
  ValidationLengthUnder255,
  ValidationLengthUnder40,
  ValidationLengthUnder500,
  ValidationLengthUnder60,
  ValidationPostNum1,
  ValidationPostNum2,
} from '../../../../../model/validation';
import { ProjectValidation } from '../../../../../model/validation/project/project.validation';
import { MasterActions } from '../../../../../redux/master/master.action';
import { ValidationDatePicker } from '../../../../../model/validation/validation-date-picker';
import { OrderPC } from '../../order/order.pc';
import { ProjectModel } from '../../../../../model/project/project.model';
import { ItemWrap } from '../../../../ui/item/item-wrap';
import { pulldown } from '../../../../../utilities/pulldown';
import { useAppSelector } from '../../../../../hooks/use-redux';
import { InputTel } from '../../../../ui/input/input-tel';
import { TagCheckBoxex } from '../../../../ui/tag-check-boxex/tag-check-boxex';
import { useDidMount } from '../../../../../hooks/life-cycle';
import { TagModel } from '../../../../../model/tag/tag';
import { EstimateEditPC } from '../../estimate/edit/estimate-edit.pc';
import { EstimateActions } from '../../../../../redux/estimate/estimate.action';
import { EstimateModel } from '../../../../../model/estimate/estimate-model';
import { ProjectDetailActions } from '../../../../../redux/project-detail/project-detail.action';
import { Message } from '../../../../../collection/message.collection';
import { MathHelper } from '../../../../../utilities/math-helper';
import { useRegistrationMap, usePostSearch } from '../../../../../hooks/common/use-common';
import { useEditAuthProject, useEstimateAuthority } from '../../../../../hooks/use-authority';
import { SearchPostAddressDialog } from '../../../../ui/search-post-address-dialog/search-post-address-dialog';
import { EstimateSortStateInProject } from '../../../../../redux/estimate/api/estimate/api-estimate.type';
import { EstimateListType } from '../../../../../type/estimate/estimate.type';
import { IconButton } from '../../../../ui/button/icon-button/icon-button';
import { Config } from '../../../../../config/config';
import { RoutingPath } from '../../../../../routes/routing-pass';
import { BillActions } from '../../../../../redux/bill/bill.action';
import { InputPostNoMulti } from '../../../../ui/input/input-post-no-multi';
import { useQuery } from '../../../../../hooks/use-query';
import { FileActions } from '../../../../../redux/file/file.action';
import { FileModel } from '../../../../../model/file/file.model';
import { FileSortState } from '../../../../../type/file/file.type';

export type FileTabProps = {
  getList: (data?: FileSortState) => void;
  fileSize: { capacity: number, total: number; };
}

type Props = {
  id?: number,
  callback: () => void;
  estimateSort?: EstimateSortStateInProject;
  callbackList?: (v: EstimateListType[]) => void;
  file?: FileTabProps;
}

export const ProjectEdit = (props: Props) => {
  const {
    id, callback, estimateSort, callbackList, file,
  } = props;

  /* Hooks */
  const query = useQuery('p');
  const searchPostNoo = usePostSearch();
  const sortState = useAppSelector((v) => v.project.sort);
  const sort = useAppSelector((state) => state.projectDetail.estimateSort);
  const dispatch = useDispatch();
  const user = useAppSelector((v) => (v.user));
  const partList = useAppSelector((v) => (v.tag.partList));
  const {
    originList,
    employeeList,
    lostOrderList,
    storeList,
    projectRankList,
  } = useAppSelector((v) => (v.master));
  const estimateAuth = useEstimateAuthority();
  const registrationMap = useRegistrationMap();

  /* State */
  const [info, setInfo] = useState<Project | null>(null);
  const [project, setProject] = useState<ProjectEditState>(ProjectCollection.initialEditState);
  const [customerName, setCustomerName] = useState('');
  const [customerID, setCustomerID] = useState(NaN);
  const [customerAddress, setCustomerAddress] = useState('');
  const [touch, setTouch] = useState(false);
  const [postNoErrorMessage, setPostNoErrorMessage] = useState<string[]>([]);
  const editAuth = useEditAuthProject(info);

  const _employeeList = useMemo(() => employeeList.filter((
    v,
  ) => (Number.isNaN(project.project_store_id) || project.project_store_id === null
    ? true
    : (v.store_id === project.project_store_id))),
  [project.project_store_id, employeeList]);

  /* Callback */
  const setState = useCallback((v: Partial<ProjectEditState>) => {
    setProject({ ...cloneDeep(project), ...cloneDeep(v) });
  }, [setProject, project]);

  const handleClickPost = useCallback(() => {
    if (!editAuth) return;
    if (ProjectValidation(project)) {
      dispatch(DialogActions.pushMessage({
        title: '案件情報登録',
        message: Message.postError,
        callback: () => setTouch(true),
      }));
      return;
    }
    /* 保存API */
    const prefe = prefectures.find((v) => v.value === project.field_prefecture)?.label;
    dispatch(MapActions.api.geocoder({
      isRegist: true,
      isPost: true,
      param: {
        param: {
          address: `${prefe || ''}${project.field_city}${project.field_address}`,
        },
        noMessage: true,
      },
      callback: (data) => {
        dispatch(ProjectActions.api.project.post({
          param: {
            data: ProjectModel.postParam({ project, data }),
            id,
          },
          update: !!id,
          onSuccess: () => {
            dispatch(ProjectActions.api.project.get({
              param: { id }, callback: setInfo,
            }));
          },
          onError: () => setTouch(true),
        }));
      },
    }));
  },
  [project, id, sortState, editAuth]);

  const handleClickRegistrationMap = useCallback(() => {
    if (!editAuth) return;
    registrationMap((address) => {
      if (!address) return;
      setState(ProjectModel.registMap(address.components));
    });
  }, [editAuth, setState]);

  const handleClickCustomerSearch = useCallback(() => {
    if (!editAuth) return;
    dispatch(DialogActions.push({
      title: '顧客検索',
      className: 'max_height_dialog max_width_dialog search_dialog',
      element: <CustomerSearch callback={(data) => {
        setState(ProjectModel.setCustomerData(data, user.authority1 ? undefined : user));
        setCustomerName(data.name || '');
        setCustomerID(data.customer_internal_id || NaN);
        setCustomerAddress(
          `${data.prefecture_name}${data.address || ''}${data.building_name || ''}`,
        );
      }}
      />,
    }));
  }, [project, editAuth]);

  const handleClickSearchAddress = useCallback(() => {
    if (!editAuth) return;
    dispatch(MapActions.api.addressSearch({
      param: {
        zipcode1: String(project.field_post_no[0]),
        zipcode2: String(project.field_post_no[1]),
      },
      callback: (address) => setState(ProjectModel.setAddress(address)),
    }));
  },
  [project, editAuth]);

  const handleClickOrder = useCallback(() => {
    if (!info || !editAuth) return;
    dispatch(DialogActions.push({
      title: '受注登録',
      className: 'max_height_dialog max_width_dialog order_dialog',
      element: <OrderPC
        projectData={info}
        willUnMount={() => {
          if (!file) return;
          file.getList();
        }}
        callbackInProject={() => {
          if (!estimateSort || id === undefined) return;
          dispatch(EstimateActions.api.estimate.getList(
            {
              param: { ...EstimateModel.getListInProject(estimateSort, id) },
              callback: (v) => {
                dispatch(ProjectActions.api.project.get({
                  param: { id }, callback: setInfo,
                }));
                dispatch(EstimateActions.setList(cloneDeep(v)));
                callbackList?.(v);
                if (!id) return;
                dispatch(BillActions.api.bill.getList({
                  noLoad: true,
                  param: {
                    project_id: id,
                  },
                }));
              },

            },
          ));
        }}
      />,
      callback: () => {},
    }));
  }, [info, editAuth, file]);

  const searchPostNo = useCallback(() => {
    if (!editAuth) return;
    searchPostNoo({
      zipcode1: project.field_post_no[0],
      zipcode2: project.field_post_no[1],
      jisx0402: project.field_jisx0402_code || '',
      pref: project.field_prefecture,
      city: project.field_city,
      town: project.field_address,
      calback: (v) => {
        setState({
          field_post_no: [
            v.zipcode1,
            v.zipcode2,
          ],
          field_prefecture: v.pref,
          field_city: v.city,
          field_jisx0402_code: v.jisx0402,
          field_address: v.town,
        });
      },
    });
  }, [editAuth, project]);

  /* Effect */
  useDidMount(() => {
    dispatch(TagActions.api.part.getList());
    dispatch(MasterActions.api.origin.getList({}));
    dispatch(MasterActions.api.store.getList({}));
    dispatch(MasterActions.api.employee.getList({}));
    dispatch(MasterActions.api.lostOrder.getList({}));
    dispatch(MasterActions.api.projectRank.getList({}));
    if (id === undefined) return;
    dispatch(ProjectActions.api.project.get({
      param: { id }, callback: setInfo,
    }));
  });

  useEffect(() => {
    if (!info) return;
    setCustomerName(info.customer_name || '');
    setCustomerID(info.customer_internal_id || NaN);
    setCustomerAddress(info.customer_place || '');
    setState(ProjectModel.setEditData({ project, data: info, partList }));
  }, [partList, info]);

  useEffect(() => {
    if (info) return;
    setState({ construction_parts: new TagModel(partList) });
  }, [partList, info]);

  return (
    <EditPC
      buttonArea={(
        <>
          <Button
            size="md"
            color="primary"
            disabled={!editAuth}
            onClick={handleClickPost}
          >
            更新
          </Button>
          {estimateAuth && (
          <Button
            size="md"
            color="secondary"
            disabled={!editAuth || !!info?.complete_date}
            onClick={() => {
              if (!info) return;
              const getList = () => {
                dispatch(EstimateActions.setList(null));
                dispatch(EstimateActions.api.estimate.getList({
                  noLoad: true,
                  param: {
                    ...EstimateModel.getListInProject(sort, info.id),
                    valid_flag: 1,
                  },
                  callback: (v) => {
                    dispatch(ProjectDetailActions.setEstimateList(v));
                  },
                }));
              };
              dispatch(DialogActions.push({
                title: '見積新規登録',
                className: 'estimate max_height_dialog max_width_dialog',
                onCloseClick: getList,
                element: <EstimateEditPC
                  callback={getList}
                  project={info}
                />,
              }));
            }}
          >
            見積登録
          </Button>
          )}
          <Button
            size="md"
            color="secondary"
            disabled={!editAuth || !!info?.complete_date || !!info?.completion_date}
            onClick={handleClickOrder}
          >
            受注登録
          </Button>
        </>
        )}
      mode={!id ? 'dialog' : 'detail'}
    >
      <div className="edit_pc_body_inner edit_project">
        <div className="left_box">
          <div className="item_wrap">
            <div className="item_box">
              <div className="item_head">顧客ID<Required /></div>
              <div className="flex_wrap_box">
                <Input
                  className="small"
                  value={customerID || ''}
                  disabled
                  require
                />
              </div>
            </div>
            <div className="item_box">
              <div className="item_head">顧客名</div>
              <Input
                value={customerName}
                disabled
              />
              {!id
                ? (
                  <Button
                    size="sm"
                    color="secondary"
                    className="ml_10"
                    onClick={handleClickCustomerSearch}
                    disabled={!editAuth}
                  >
                    顧客検索
                  </Button>
                ) : (
                  <IconButton
                    fontAwesomeClass="fas fa-external-link-alt"
                    className="sns"
                    size="sm"
                    color="white"
                    onClick={() => {
                      if (!id) return;
                      /* 別タブ */
                      window.open(`${Config.host}/#${RoutingPath.customerDetail}/${project.customer_id}`);
                      // dispatch(push(`${RoutingPath.customerDetail}/${project.customer_id}`));
                    }}
                  />
                )}
            </div>
          </div>
          <ItemWrap classNameBox="large" title="顧客住所">
            <Input
              value={customerAddress}
              className="large"
              disabled
            />
          </ItemWrap>
          <ItemWrap classNameBox="large" title={<>案件名（工事）<Required /></>}>
            <Input
              value={project.name}
              onChange={(e) => { setState({ name: e.target.value }); }}
              className="large"
              require
              validationList={ValidationLengthUnder40}
              disabled={!editAuth}
              touch={touch}
            />
          </ItemWrap>
          <ItemWrap title="発生源">
            <Select
              value={project.source_id}
              onChange={(v) => setState({ project_source_id: Number(v), source_id: Number(v) })}
              defaultLabel="指定無し"
              disabled={!editAuth}
              options={pulldown(originList)}
            />
          </ItemWrap>
          <ItemWrap title="案件見込みランク">
            <Select
              value={project.project_rank}
              onChange={(v) => setState({ project_rank: Number(v) })}
              defaultLabel="指定無し"
              disabled={!editAuth}
              options={pulldown(projectRankList)}
            />
          </ItemWrap>
          <div className="item_wrap">
            <div className="item_box">
              <div className="item_head">案件担当</div>
              <Select
                className="add_text_left"
                label="店舗"
                value={project.project_store_id}
                onChange={(v) => setState({
                  project_store_id: Number(v), project_employee_id: NaN,
                })}
                defaultLabel="指定無し"
                disabled={!editAuth}
                options={pulldown(storeList)}
              />
              <Select
                className="add_text_left"
                label="担当者"
                value={project.project_employee_id}
                onChange={(v) => setState({ project_employee_id: Number(v) })}
                defaultLabel="指定無し"
                options={pulldown(_employeeList)}
                touch={touch}
                disabled={!editAuth}
                require
              />
            </div>
          </div>
          <div className="frame">
            <ItemWrap classNameBox="large" title={<>現場名称<Required /></>}>
              <Input
                value={project.field_name}
                onChange={(e) => { setState({ field_name: e.target.value }); }}
                validationList={ValidationLengthUnder60}
                className="large"
                require
                touch={touch}
                disabled={!editAuth}
              />
            </ItemWrap>
            <div className="item_wrap">
              <div className="item_box">
                <div className="item_head">郵便番号<Required /></div>
                <div className="item_postal">
                  <InputPostNoMulti
                    value={project.field_post_no[0]}
                    touch={touch}
                    require
                    onChange={(v) => {
                      setState({
                        field_post_no: [
                          v,
                          project.field_post_no[1],
                        ],
                      });
                    }}
                    disabled={!editAuth}
                    value2={project?.field_post_no[1]}
                    touch2={touch}
                    require2
                    onChange2={(v) => setState({
                      field_post_no: [
                        project.field_post_no[0],
                        v,
                      ],
                    })}
                    disabled2={!editAuth}
                  />

                </div>
                <Button
                  size="sm"
                  color="secondary"
                  className="ml_10"
                  onClick={handleClickSearchAddress}
                  disabled={!editAuth}
                >
                  住所入力
                </Button>
                <Button
                  size="sm"
                  color="secondary"
                  className="ml_10"
                  onClick={searchPostNo}
                  disabled={!editAuth}
                >
                  詳細住所入力
                </Button>
                <Button
                  size="sm"
                  color="secondary"
                  className="ml_10"
                  onClick={handleClickRegistrationMap}
                  disabled={!editAuth}
                >
                  地図から入力
                </Button>

              </div>
              <div style={{ color: 'red' }}>
                {postNoErrorMessage.map((v, i) => (
                  <span key={v + i} style={{ marginRight: '10px' }}>
                    {v},
                  </span>
                ))}
              </div>
            </div>
            <div className="item_wrap">
              <div className="item_box max_width">
                <div className="item_head">住所</div>
                <div className="item_adress">
                  <Select
                    className="add_text_left"
                    label="都道府県"
                    value={project.field_prefecture}
                    defaultLabel="-"
                    onChange={(v) => setState({ field_prefecture: Number(v) })}
                    options={pulldownPrefecture}
                    require
                    disabled={!editAuth}
                    touch={touch}
                  />
                  <LeftLabelInputField
                    label="市区町村"
                    value={project.field_city}
                    onChange={(e) => setState({
                      field_city: e.target.value,
                      field_jisx0402_code: undefined,
                    })}
                    validationList={ValidationLengthUnder255}
                    className="large"
                    require
                    disabled={!editAuth}
                    touch={touch}
                  />
                  <LeftLabelInputField
                    label="地名・番地"
                    value={project.field_address}
                    onChange={(e) => setState({ field_address: e.target.value })}
                    validationList={ValidationLengthUnder255}
                    className="large"
                    require
                    disabled={!editAuth}
                    touch={touch}
                  />
                  <LeftLabelInputField
                    label="建物名"
                    value={project.field_building_name}
                    onChange={(e) => {
                      setState({ field_building_name: e.target.value });
                    }}
                    validationList={ValidationLengthUnder255}
                    className="large"
                    disabled={!editAuth}
                    touch={touch}
                  />
                </div>
              </div>
            </div>
            <ItemWrap title="現場電話">
              <InputTel
                className="large"
                value={project.field_tel_no}
                onChange={(v) => { setState({ field_tel_no: v }); }}
                disabled={!editAuth}
              />
            </ItemWrap>
            <ItemWrap title="現場FAX">
              <InputTel
                className="large"
                value={project.field_fax_no}
                onChange={(v) => { setState({ field_fax_no: v }); }}
                disabled={!editAuth}
              />
            </ItemWrap>
          </div>
          <div className="item_wrap">
            <TagCheckBoxex
              head="工事部位"
              keys="`pTag"
              model={project?.construction_parts}
              disabled={!editAuth}
              onChange={(v) => setState({ construction_parts: v })}
            />
          </div>
        </div>
        <div className="right_box">
          <ItemWrap title="見込み金額">
            <Input
              value={MathHelper.localStr(project?.mitumori_kin || 0)}
              className="large"
              disabled
              alignRight
            />
          </ItemWrap>
          <div className="item_wrap">
            <div className="item_box">
              <div className="item_head">契約番号</div>
              <Input
                value={project?.contract_no}
                className="size_datepicker"
                disabled
              />
            </div>
            <div className="item_box">
              <div className="item_head">契約日</div>
              <Input
                value={DateFormatter.date2str(project?.contract_date)}
                className="size_datepicker"
                disabled
              />
            </div>
          </div>
          <div className="item_wrap">
            <div className="item_box">
              <div className="item_head">受注工期</div>
              <Input
                value={DateFormatter.date2str(project?.construction_period_start)}
                className="construction_period_start size_datepicker"
                disabled
              />
              <label className="ml_5 mr_5">〜</label>
              <Input
                label="〜"
                value={DateFormatter.date2str(project?.construction_period_end)}
                className="construction_period_end size_datepicker"
                disabled
              />
            </div>
          </div>
          <div className="item_wrap">
            <div className="item_box">
              <div className="item_head">着工予定日</div>
              <Input
                value={DateFormatter.date2str(project?.construction_start_date)}
                className="size_datepicker"
                disabled
              />
            </div>
            <div className="item_box">
              <div className="item_head">完工予定日</div>
              <Input
                value={DateFormatter.date2str(project?.completion_end_date)}
                className="size_datepicker"
                disabled
              />
            </div>
          </div>
          <div className="item_wrap">
            <div className="item_box">
              <div className="item_head">着工日</div>
              <DatePicker
                date={project.construction_date}
                onChange={(v) => setState({ construction_date: v })}
                disabled={!editAuth || !info?.contract_date}
              />
            </div>
            <div className="item_box">
              <div className="item_head">完工日</div>
              <DatePicker
                date={project.completion_date}
                onChange={(v) => setState({ completion_date: v })}
                disabled={!editAuth || !info?.contract_date}
              />
            </div>
          </div>
          <ItemWrap title="完了日">
            <DatePicker
              date={project.complete_date}
              onChange={(v) => setState({ complete_date: v })}
              disabled={!editAuth}
            />
          </ItemWrap>
          <ItemWrap title="備考" classNameBox="large">
            {/* バリデーションの追加 */}
            <TextArea
              rows={3}
              value={project.remarks}
              className="large"
              disabled={!editAuth}
              onChange={(e) => setState({ remarks: e.target.value })}
              validationList={ValidationLengthUnder500}
            />
          </ItemWrap>

          <div className="item_wrap">
            <div className="item_box">
              <div className="item_head">登録日</div>
              <Input
                value={DateFormatter.date2str(info?.created_at, 'YYYYMMDD_HHmm')}
                className="construction_period_start size_datepicker add_time"
                disabled
              />
            </div>
            <div className="item_box">
              <div className="item_head">登録者</div>
              <Input
                value={info?.created_employee_name || ''}
                className=""
                disabled
              />
            </div>
          </div>
          <div className="item_wrap">
            <div className="item_box">
              <div className="item_head">更新日</div>
              <Input
                value={DateFormatter.date2str(info?.updated_at, 'YYYYMMDD_HHmm')}
                className="construction_period_start size_datepicker add_time"
                disabled
              />
            </div>
            <div className="item_box">
              <div className="item_head">更新者</div>
              <Input
                value={info?.updated_employee_name || ''}
                className=""
                disabled
              />
            </div>
          </div>
          <div className="item_wrap">
            <div className="item_box">
              <div className="item_head">失注日</div>
              <DatePicker
                date={project.failure_date}
                disabled={!editAuth}
                onChange={(v) => setState(
                  { failure_date: v },
                )}
              />
            </div>
            <div className="item_box">
              <div className="item_head">失注理由</div>
              <Select
                value={project.failure_cause}
                onChange={(v) => setState({ failure_cause: Number(v) })}
                defaultLabel="指定無し"
                disabled={!editAuth}
                options={
                    lostOrderList.map((v) => (
                      { text: v.lost_reason, value: v.id }
                    ))
                  }
              />
            </div>
          </div>
          <div className="item_wrap">
            <div className="item_box large">
              <div className="item_head">失注備考</div>
              {/* バリデーションの追加 */}
              <TextArea
                rows={3}
                value={project?.failure_remarks}
                className="large"
                disabled={!editAuth}
                onChange={(e) => setState({ failure_remarks: e.target.value })}
                validationList={ValidationLengthUnder500}
              />
            </div>
          </div>
          <div className="item_wrap">
            <div className="item_box">
              <div className="item_head">キャンセル日</div>
              <DatePicker
                date={project.cancel_date}
                onChange={(v) => setState({ cancel_date: v })}
                disabled={!editAuth}
              />
            </div>
          </div>
          <div className="item_wrap">
            <div className="item_box large">
              <div className="item_head">キャンセル理由</div>
              {/* バリデーションの追加 */}
              <TextArea
                rows={3}
                value={project?.cancel_reason}
                className="large"
                disabled={!editAuth}
                onChange={(e) => { setState({ cancel_reason: e.target.value }); }}
                validationList={ValidationLengthUnder255}
              />
            </div>
          </div>
        </div>
      </div>
    </EditPC>
  );
};
