import { push } from 'connected-react-router';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FileModel } from '../../../../model/file/file.model';
import { SupportHistoryModel } from '../../../../model/support-history/support-history.model';
import { CustomerDetailActions } from '../../../../redux/customer-detail/customer-detail.action';
import { DialogActions } from '../../../../redux/dialog/dialog.action';
import { FileActions } from '../../../../redux/file/file.action';
import { State } from '../../../../redux/root.reducer';
import { SupportHistoryActions } from '../../../../redux/support-history/support-history.action';
import { openTel } from '../../../../utilities/open-tel';
import { webShareApi } from '../../../../utilities/web-share';
import { Button } from '../../../ui/button/button';
import { IconButton } from '../../../ui/button/icon-button/icon-button';
import { FileEditSP } from '../../pages/file/edit/file-edit.sp';
import { FileEditDialogTitle } from '../../pages/file/edit/file-edit.type';
import { SupportHistoryEditSP } from '../../pages/support-history/edit/support-history-edit.sp';
import { SupportHistoryEditDialogTitle } from '../../pages/support-history/edit/support-history-edit.type';
import './detail-page-footer.sp.scss';
import { ProjectDetailActions } from '../../../../redux/project-detail/project-detail.action';
import { Project, ProjectListType } from '../../../../type/project/project.type';
import { Customer, CustomerListType } from '../../../../type/customer/customer.type';
import { RoutingPath } from '../../../../routes/routing-pass';
import { ApiSupportHistoryPostParam } from '../../../../redux/support-history/api/api-support-history/api-support-history.type';
import { DateFormatter } from '../../../../utilities/date-formatter';
import { useTel } from '../../../../hooks/common/use-common';
import { useTelContact } from '../../../../hooks/support-history/use-support-history';
import { DirectOrderSP } from '../order/direct-order/direct-order.sp';
import { FileSortState } from '../../../../type/file/file.type';
import { FileTabProps } from '../../../pc/pages/project/edit/project-edit';

type Props = {
  tel?: string
  url?: string;
  type: 'customer' | 'project';
  data?: Customer | CustomerListType | ProjectListType | Project | null;
  fileData?: FileTabProps;
}

export const DetailPageFooterSP = (props: Props) => {
  const {
    tel, url, type, data, fileData,
  } = props;
  const dispatch = useDispatch();
  const { project } = useSelector((state: State) => state.project);
  const customer = useSelector((state:State) => state.customer.customer);
  const user = useSelector((state:State) => state.user);
  const cFileSort = useSelector((state:State) => state.customerDetail.fileSort);
  const cSupportSort = useSelector((state:State) => state.customerDetail.supportSort);
  const pFileSort = useSelector((state:State) => state.projectDetail.fileSort);
  const pSupportSort = useSelector((state:State) => state.projectDetail.supportSort);

  const callTell = useTel();
  const contact = useTelContact();

  /* Callback */
  const handleClickShare = useCallback(() => {
    webShareApi({
      url: url || 'https://www.marietta.co.jp/',
      callbackError: () => {
        dispatch(DialogActions.pushMessage({
          title: '',
          message: ['お使いのブラウザは対応しておりません'],
        }));
      },
    });
  },
  [webShareApi, dispatch]);

  const handleClickTel = useCallback(() => {
    if (!data) return;
    contact({
      type,
      data,
      isPushDialog: true,
    });
  }, [type, data]);

  const handleClickUpload = useCallback(() => {
    if (type === 'customer' && customer) {
      dispatch(DialogActions.push({
        title: FileEditDialogTitle.add,
        element: <FileEditSP
          customer={customer}
          callback={() => {
            dispatch(FileActions.api.file.getList({
              noLoad: true,
              param: FileModel.listParamInCustomer(cFileSort, Number(customer.id)),
              onSuccess: (v) => {
                dispatch(CustomerDetailActions.setFileList(v));
              },
            }));
          }}
        />,
      }));
      return;
    }
    if (type === 'project' && project) {
      dispatch(DialogActions.push({
        title: FileEditDialogTitle.add,
        element: <FileEditSP
          project={project}
          callback={() => {
            dispatch(FileActions.api.file.getList({
              noLoad: true,
              param: FileModel.listParamInProject(pFileSort, Number(project.id)),
              onSuccess: (v) => {
                dispatch(ProjectDetailActions.setFileList(v));
              },
            }));
          }}
        />,
      }));
    }
  }, [dispatch, customer, project, type]);

  const handleRegistrationSupport = useCallback(() => {
    if (type === 'customer' && customer) {
      dispatch(DialogActions.push({
        title: SupportHistoryEditDialogTitle.add,
        element: <SupportHistoryEditSP
          customer={customer}
          callback={() => {
            dispatch(SupportHistoryActions.api.supportHistory.getList({
              noLoad: true,
              param: SupportHistoryModel.listParamInCustomer({
                sortState: cSupportSort, customer_id: Number(customer.id),
              }),
              onSuccess: (v) => {
                dispatch(CustomerDetailActions.setSupportList(v));
              },
            }));
          }}
        />,
      }));
      return;
    }
    if (type === 'project' && project) {
      dispatch(DialogActions.push({
        title: SupportHistoryEditDialogTitle.add,
        element: <SupportHistoryEditSP
          project={project}
          callback={() => {
            dispatch(DialogActions.pop());
            dispatch(SupportHistoryActions.api.supportHistory.getList({
              noLoad: true,
              param: SupportHistoryModel.listParamInProject({
                sortState: pSupportSort, project_id: Number(project.id),
              }),
              onSuccess: (v) => {
                dispatch(ProjectDetailActions.setSupportList(v));
              },
            }));
          }}
        />,
      }));
    }
  }, [dispatch, customer, project, type, pSupportSort, cSupportSort]);

  const handleClickOrder = useCallback(() => {
    console.log(data);
    if (type !== 'project' || !data) return;

    dispatch(DialogActions.push({
      title: '案件検索',
      element: <DirectOrderSP
        changeTitle={
          (v) => dispatch(DialogActions.changeTitle({ index: 0, title: v }))
        }
        isProject
        initProject={data as Project | ProjectListType}
        willUnMount={() => fileData?.getList()}
      />,
    }));
  }, [type, data, fileData]);

  return (
    <div className="detail_page_footer page_body_footer">
      <div className="detail_page_footer_col">
        <IconButton
          fontAwesomeClass="fas fa-share-alt"
          onClick={handleClickShare}
          size="md"
          color="primary"
        />
        <IconButton
          fontAwesomeClass="fas fa-phone"
          onClick={handleClickTel}
          size="md"
          color="primary"
          disabled={!tel}
        />
        <IconButton
          fontAwesomeClass="fas fa-file-upload"
          onClick={handleClickUpload}
          size="md"
          color="primary"
        />
      </div>
      <div className="detail_page_footer_col">
        {type === 'project' && (
        <Button
          onClick={() => handleClickOrder()}
          color="primary"
          size="md"
        >
          受注
        </Button>
        )}
        <Button
          onClick={handleRegistrationSupport}
          size="md"
          color="primary"
        >対応登録
        </Button>
      </div>
    </div>
  );
};
