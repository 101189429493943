import actionCreatorFactory from 'typescript-fsa';
import { ApiLoginParam } from './api/login/login';
import { apiPassword } from './api/password/api-password';

export const ActionCreator = actionCreatorFactory('auth');

export const AuthActions = {
  api: {
    login: ActionCreator<{ param: ApiLoginParam; onError:(str: string[]) => void; }>('login'),
    logout: ActionCreator('logout'),
    password: apiPassword,
    isLogin: ActionCreator('is/login'),
  },
  setToken: ActionCreator<string>('set/token'),
  resetState: ActionCreator('reset/state'),
};
