import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'lodash';
import { DialogActions } from '../../../../../redux/dialog/dialog.action';
import { BillListType, BillSortState } from '../../../../../type/bill/bill.type';
import { Table } from '../../../../ui/table/table';
import { State } from '../../../../../redux/root.reducer';
import { LeftIconButton } from '../../../../ui/button/left-icon-button/left-icon-button';
import { BillActions } from '../../../../../redux/bill/bill.action';
/* import { RefindBillTable } from './refind-bill-table/refind-bill-table';*/
import { ProjectDetailActions } from '../../../../../redux/project-detail/project-detail.action';
import { BillEdit } from '../../../../dialogs/bill-edit/bill-edit';
import { BillCollection } from '../../../../../collection/bill/bill.collection';
import Printer from '../../../../../asset/images/icon/printer.svg';
import { MathHelper } from '../../../../../utilities/math-helper';
import { PrintPreview, PrintType } from '../../../../dialogs/print-preview/print-preview';
import { DateFormatter } from '../../../../../utilities/date-formatter';
import { MasterActions } from '../../../../../redux/master/master.action';
import { MasterTax } from '../../../../../type/master/master-tax.type';
import { Button } from '../../../../ui/button/button';
import { useEstimateAuthority } from '../../../../../hooks/use-authority';

type Props = {
  disabled?: boolean;
}

export const BillTable = (props: Props) => {
  const { disabled } = props;
  /* Hook */
  const dispatch = useDispatch();
  const sort = useSelector((state: State) => state.projectDetail.billSort, isEqual);
  const list = useSelector((state: State) => state.bill.list, isEqual);
  const project = useSelector((state: State) => state.project.project);
  const estimateAuth = useEstimateAuthority();

  /* State */
  const [selected, setSelected] = useState<number[]>([]);
  const [selectBill, setSelectBill] = useState<BillListType | null>(null);

  const [sortby, setSortBy] = useState(0);
  const [hl, setHl] = useState(0);

  /* memo */
  const sendList = useMemo(() => list?.filter((v) => (v.billing_amount > 0)),
    [list]);

  const validPrint = useMemo(() => {
    let isValid = true;
    sendList?.forEach((v) => {
      if (v.billing_items_id === 5 && v.billing_amount > 0) {
        isValid = false;
      }
    });
    return isValid;
  }, [sendList]);

  /* Callback */
  const getList = useCallback((data?: BillSortState) => {
    // eslint-disable-next-line
    const sortData = data || sort;
    if (project?.id) {
      dispatch(BillActions.api.bill.getList({
        noLoad: true,
        param: {
          project_id: project.id, ...sortData,
        },
      }));
    }
  }, [project?.id, sort]);

  const handleClickEdit = useCallback((mode: 'add' | 'edit', v?: BillListType) => {
    if (!project || disabled) return;
    dispatch(DialogActions.push({
      title: `請求書${mode === 'edit' ? '編集' : '登録'}`,
      element: <BillEdit
        mode={mode}
        data={v}
        id={mode === 'edit' ? selectBill?.id : undefined}
        callback={() => getList()}
      />,
    }));
  },
  [project, getList, disabled, selectBill?.id]);

  const handleClickRow = useCallback((v: BillListType) => {
    if (!sendList) return;
    const findIndex = sendList.findIndex((v2) => v2.id === v.id);
    if (findIndex !== -1) {
      setSelected([findIndex]);
      setSelectBill(v);
    }
  }, [sendList]);

  const handleDbClickRow = useCallback((v: BillListType) => {
    if (!sendList) return;
    const findIndex = sendList.findIndex((v2) => v2.id === v.id);
    if (findIndex !== -1) {
      setSelected([findIndex]);
      setSelectBill(v);
    }
    handleClickEdit('edit', v);
    // dispatch(push(`${RoutingPath.projectDetail}/${v.id}`));
  }, [sendList, handleClickEdit]);

  const displayList = useMemo(() => (sendList ? sendList.map((v) => (
    [
      v.print_dt ? <img src={Printer} alt="印刷済み" title="印刷済み" className="icon" /> : '',
      v.billing_items,
      v.billing_date ? DateFormatter.date2str(v.billing_date) : '',
      v.payment_due_date ? DateFormatter.date2str(v.payment_due_date) : '',
      MathHelper.localStr(Number(v.billing_amount)) ?? '',
      v.payment_method ?? '',
      v.remarks ?? '',
    ]
  )) : null),
  // [
  // list.map((v) => (
  // [
  // <img src={Printer} alt="印刷済み" title="印刷済み" className="icon" />,
  // '契約金TEST',
  // '2021/9/03',
  // '2021/10/03',
  // (324000).toLocaleString(),
  // '入金方法が入る',
  // '',
  // ],
  // )),
  // ]
  [list]);

  useEffect(() => {
    getList();
  }, [project?.id, sort.highlow, sort.filter_by]);

  const handleClickHeader = useCallback((highlow: 0 | 1, filter_by: number) => {
    dispatch(ProjectDetailActions.setBillSort({
      highlow, filter_by,
    }));
  }, []);

  const getTaxSoon = useCallback((callback: (v: MasterTax) => void) => {
    if (!selectBill) return;
    dispatch(MasterActions.api.tax.getSoon({
      param: {
        start_date: selectBill.billing_date,
      },
      onSuccess: (res) => {
        callback(res);
      },
    }));
  }, [selectBill]);

  const handleClickPrint = (type: PrintType) => {
    if (!selectBill) return;
    dispatch(BillActions.api.bill.getPrint({
      param: {
        data: {
          project_id: selectBill.project_id,
          type,
        },
        id: selectBill.id,
      },
      onSuccess: (res) => {
        getTaxSoon((res2) => {
          dispatch(DialogActions.push({
            title: type === 'claimIn' ? '請求明細書印刷プレビュー' : '請求書印刷プレビュー',
            className: 'auto_height_dialog',
            element: <PrintPreview
              type={type}
              claimPrintData={res}
              claimListData={selectBill}
              taxData={res2}
            />,
          }));
        });
      },
    }));
  };

  // useEffect(() => {
  //   console.log(list);
  //   console.log(displayList);
  // }, [list, displayList]);

  /*
  const handleClickSortDialog = useCallback(() => {
    dispatch(DialogActions.push({
      title: '絞込み',
      element: <RefindBillTable
        callback={(sortStates) => {
          getList(sortStates);
        }}
      />,
    }));
  }, [getList, sort]);
  */

  return (
    <div className="detail_table_area">
      <div className="btn_box right">
        <LeftIconButton
          label="新規請求登録"
          fontAwesomeClass="fas fa-edit"
          className="btn_search for_detail"
          size="sm"
          color="primary"
          disabled={disabled}
          onClick={() => handleClickEdit('add')}
        />
        <LeftIconButton
          label="請求書印刷（簡易版）"
          fontAwesomeClass="fas fa-file-invoice"
          size="md"
          color="secondary"
          /* disabled= {!estimate} */
          onClick={() => handleClickPrint('claim')}
          disabled={Boolean(!selectBill) || validPrint}
        />
        {estimateAuth && (
        <LeftIconButton
          label="請求書印刷（明細付）"
          fontAwesomeClass="fas fa-file-invoice"
          size="md"
          color="secondary"
          /* disabled={!estimate}*/
          onClick={() => handleClickPrint('claimIn')}
          disabled={Boolean(!selectBill) || validPrint}
        />
        )}
      </div>
      <div className="table_responsive">
        <Table
          className="table_selectable table_sortable table_sticky table_cell_change"
          header={BillCollection.header}
          onClickRow={handleClickRow}
          onDbClick={handleDbClickRow}
          sort={{ onClick: handleClickHeader }}
          selectedTr={selected}
          rowDataList={sendList}
          disabledHeader
          lists={displayList}
          option={{
            stringWidth: [
              { index: 0, width: 50 }, // 請求書印刷有無
              // { index: 1, width: 150 }, // 請求項目
              // { index: 2, width: 150 }, // 請求日
              // { index: 3, width: 150 }, // 入金予定日
              // { index: 4, width: 150 }, // 請求金額
              // { index: 5, width: 300 }, // 入金方法
              // { index: 6, width: 300 }, // （備考）
            ],
            tdAlign: [
              { index: 0, align: 'center' },
              { index: 1, align: 'center' },
              { index: 2, align: 'center' },
              { index: 3, align: 'center' },
              { index: 4, align: 'right' },
              { index: 5, align: 'left' },
              // { index: 6, align: 'left' },
            ],
          }}
        />
      </div>
    </div>
  );
};
