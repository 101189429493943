import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { isEqual, cloneDeep } from 'lodash';
import { Table } from '../../../../ui/table/table';
import { DialogActions } from '../../../../../redux/dialog/dialog.action';
import { ProjectCollection } from '../../../../../collection/project/project.collection';
import './estimate-table.scss';
import { State } from '../../../../../redux/root.reducer';
import { EstimateEditPC } from '../../estimate/edit/estimate-edit.pc';
import { RoutingPath } from '../../../../../routes/routing-pass';
import { LeftIconButton } from '../../../../ui/button/left-icon-button/left-icon-button';
import Ordered from '../../../../../asset/images/icon/ordered.svg';
import { EstimateActions } from '../../../../../redux/estimate/estimate.action';
import { ProjectDetailActions } from '../../../../../redux/project-detail/project-detail.action';
import { EstimateSortState, EstimateListType } from '../../../../../type/estimate/estimate.type';
import { RefindEstimateTable } from './refind-estimate-table/refind-estimate-table';
import { DateFormatter } from '../../../../../utilities/date-formatter';
import { EstimateModel } from '../../../../../model/estimate/estimate-model';
import { EstimateSortStateInProject } from '../../../../../redux/estimate/api/estimate/api-estimate.type';
import { MathHelper } from '../../../../../utilities/math-helper';
import { useAppSelector } from '../../../../../hooks/use-redux';
import { PrintPreview, PrintType } from '../../../../dialogs/print-preview/print-preview';

type Props = {
  disabled?: boolean;
  callbackSort?: (v: EstimateSortStateInProject) => void;
  parentList?: EstimateListType[];
}

export const EstimateTable = (props: Props) => {
  const { disabled, callbackSort, parentList } = props;
  /* Hooks */
  const dispatch = useDispatch();
  const sort = useSelector((state: State) => state.projectDetail.estimateSort, isEqual);
  const project = useSelector((state: State) => state.project.project, isEqual);
  const estimateList = useAppSelector((v) => v.estimate.list);

  const [list, setList] = useState<EstimateListType[] | null>(null);
  const [estimate, setEstimate] = useState<EstimateListType | null>(null);

  /* State */
  const [selected, setSelected] = useState<number[]>([]);

  /* Callback */
  const getList = useCallback((data?: EstimateSortStateInProject) => {
    const sortData = data || sort;
    if (!project?.id) return;
    dispatch(EstimateActions.api.estimate.getList({
      noLoad: true,
      param: {
        ...EstimateModel.getListInProject(sortData, project.id),
        valid_flag: 1,
      },
      callback: (v) => {
        dispatch(ProjectDetailActions.setEstimateList(v));
        setList(cloneDeep(v));
      },
    }));
  }, [project?.id, sort]);

  const handleClickNew = useCallback(() => {
    if (!project) return;
    dispatch(DialogActions.push({
      title: '見積新規登録',
      className: 'estimate max_height_dialog max_width_dialog',
      onCloseClick: getList,
      element: <EstimateEditPC
        callback={getList}
        project={project}
      />,
    }));
  }, [dispatch, getList, project]);

  const handleClickRow = useCallback((v:EstimateListType) => {
    if (!list) return;
    const findIndex = list.findIndex((v2) => v2.id === v.id);
    if (findIndex !== -1) {
      setSelected([findIndex]);
      setEstimate(cloneDeep(v));
    }
  }, [list]);

  const handleDbClickRow = useCallback((v:EstimateListType) => {
    if (!list) return;
    const findIndex = list.findIndex((v2) => v2.id === v.id);
    if (findIndex !== -1) {
      setSelected([findIndex]);
      setEstimate(cloneDeep(v));
    }
    dispatch(push(`${RoutingPath.estimateDetail}/${v.id}`));
  }, [list]);

  const handleClickSortDialog = useCallback(() => {
    dispatch(DialogActions.push({
      title: '絞込み',
      element: <RefindEstimateTable
        callback={(v) => {
          dispatch(ProjectDetailActions.setEstimateList(null));
          getList(v);
          callbackSort?.(v);
        }}
      />,
    }));
  }, [getList, sort]);

  const handleClickHeader = useCallback((highlow: 0 | 1, sort_by: number) => {
    dispatch(ProjectDetailActions.setEstimateSort({ highlow, sort_by }));
  }, []);

  const handleClickPrint = (type: PrintType) => {
    if (!estimate) return;
    dispatch(EstimateActions.api.estimate.get({
      param: { id: estimate.id },
      callback: (v) => {
        dispatch(DialogActions.push({
          title: type === 'estimateIn' ? '見積書印刷プレビュー(社内用)' : '見積書印刷プレビュー',
          className: 'auto_height_dialog',
          element: <PrintPreview
            type={type}
            estimate={v}
            estimateListData={estimate}
          />,
        }));
      },
    }));
  };

  useEffect(() => {
    getList();
  }, [project?.id, sort.highlow, sort.sort_by]);

  useEffect(() => {
    if (!parentList) return;
    setList(cloneDeep(parentList));
  }, [parentList]);

  return (
    <div className="detail_table_area">
      <div className="btn_box right">
        <LeftIconButton
          label="新規見積作成"
          fontAwesomeClass="fas fa-edit"
          className="btn_search for_detail"
          size="sm"
          color="primary"
          disabled={disabled || Boolean(project?.complete_date)}
          onClick={handleClickNew}
        />
        <LeftIconButton
          label="見積書印刷"
          fontAwesomeClass="fas fa-calculator"
          disabled={!selected.length}
          size="md"
          color="secondary"
          onClick={() => handleClickPrint('estimate')}
        />
        <LeftIconButton
          label="見積書印刷（社内用）"
          fontAwesomeClass="fas fa-calculator"
          disabled={!selected.length}
          size="md"
          color="secondary"
          onClick={() => handleClickPrint('estimateIn')}
        />
        <LeftIconButton
          label="絞込み"
          fontAwesomeClass="fas fa-filter"
          className="btn_search for_detail"
          size="sm"
          color="secondary"
          onClick={handleClickSortDialog}
        />
      </div>
      <div className="table_responsive">
        <Table
          className="table_selectable table_sortable table_sticky table_cell_change"
          header={ProjectCollection.estimateListHeader}
          onClickRow={handleClickRow}
          onDbClick={handleDbClickRow}
          selectedTr={selected}
          sort={{
            // index: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
            onClick: handleClickHeader,
          }}
          rowDataList={list || []}
          lists={
            list
              ? list.map((v) => (
                [
                  v.order_flag ? <img src={Ordered} alt="受注" title="受注" className="icon" /> : '',
                  v.estimate_no,
                  DateFormatter.date2str(v.estimate_dt),
                  v.created_employee_name,
                  MathHelper.localStr(v.gokei_zeinuki_kin),
                  MathHelper.localStr(v.shohizei_kin),
                  MathHelper.localStr(v.gokei_kin),
                  MathHelper.localStr(v.gokei_zeinuki_genka_kin),
                  MathHelper.localStr(v.genka_shohizei_kin),
                  MathHelper.localStr(v.gokei_genka_kin),
                  MathHelper.localStr(v.tyosei_kin),
                  DateFormatter.date2str(v.estimate_kouki_start_dt),
                  DateFormatter.date2str(v.estimate_kouki_end_dt),
                ]
              )) : null
        }
          option={{
            stringWidth: [
              { index: 0, width: 50 }, // 受注
              // { index: 1, width: 100 }, // 見積番号
              // { index: 2, width: 80 }, // 作成日
              // { index: 3, width: 50 }, // 見積作成者
              // { index: 4, width: 150 }, // 見積金額
              // { index: 5, width: 100 }, // 消費税額
              // { index: 6, width: 150 }, // 税込合計見積
              // { index: 7, width: 100 }, // 原価合計
              // { index: 8, width: 100 }, // 消費税額
              // { index: 9, width: 150 }, // 税税込合計原価
              // { index: 10, width: 100 }, // 調整額
              // { index: 11, width: 150 }, // 見積工期_開始
              // { index: 12, width: 150 }, // 見積工期_終了
            ],
            tdAlign: [
              { index: 0, align: 'center' },
              { index: 1, align: 'center' },
              { index: 2, align: 'center' },
              { index: 3, align: 'center' },
              { index: 4, align: 'right' },
              { index: 5, align: 'right' },
              { index: 6, align: 'right' },
              { index: 7, align: 'right' },
              { index: 8, align: 'right' },
              { index: 9, align: 'right' },
              { index: 10, align: 'right' },
              { index: 11, align: 'center' },
              { index: 12, align: 'center' },
            ],
          }}
        />
      </div>
    </div>
  );
};
