import actionCreatorFactory from 'typescript-fsa';
import { ApiBase } from '../../../../service/api-base';
import { CsvOrderListType } from '../../../../type/csv/csv.type';

/* Param */
export type ApiCsvOrderGetListParam = Partial<{
  /** 完工日（開始年） */
  completion_date_from_year: number;
  /** 完工日（開始月） */
  completion_date_from_month: number;
  /** 完工日（終了年） */
  completion_date_to_year: number;
  /** 完工日（終了月） */
  completion_date_to_month: number;
  /** 受注金額（最小値） */
  order_price_min: number;
  /** 受注金額（最大値） */
  order_price_max: number;
  /** 粗利 */
  margin_ritu: string;
  /** 粗利フィルタ */
  margin_ritu_filter: number;
  /** 部位 */
  construction_parts: number[];
  /** 入金 */
  payment: number;
  /** 契約フラグ */
  contract_flag: number;
  /** 案件担当（店舗） */
  project_store_id: number;
  /** 案件担当（担当者） */
  project_employee_id: number;
  /** 顧客名 */
  customer_name: string;
  /** 案件名 */
  name: string;
  /** 現場名称 */
  field_name: string;
  /** 現場電話番号 */
  field_tel_no: string;
  /** オフセット */
  offset: number;
  /** 1ページ内の件数 */
  limit: number;
  /** 並替基準列 */
  sort_by: number;
  /** 並替方法 */
  highlow: number;
  /** 登録店舗 */
  created_store_id:number;
  /** 登録社員 */
  created_employee_id:number;
  /** 更新店舗 */
  updated_store_id:number;
  /** 更新社員 */
  updated_employee_id:number;
  /** 登録日－期間開始 */
  created_at_start:string;
  /** 登録日－期間終了 */
  created_at_end:string;
  /** 更新日－期間開始 */
  updated_at_start:string;
  /** 更新日－期間終了 */
  updated_at_end:string;
  }>;

export type ApiCsvOrderDownloadParam = any;

/* Response */
export type ApiCsvOrderGetListResponse = CsvOrderListType;

export const ActionCreator = actionCreatorFactory('csv/api/order');

export const apiCsvOrder = {
  getList: ActionCreator<ApiCsvOrderGetListParam>('get/list'),
  download: ActionCreator<ApiCsvOrderDownloadParam>('download'),
};

export class ApiCsvOrderGetList extends ApiBase<ApiCsvOrderGetListResponse> {
  constructor(param: ApiCsvOrderGetListParam) {
    super({
      httpMethod: 'GET',
      param,
      url: '/api/csv/order',
    });
  }
}

export class ApiCsvOrderDownload extends ApiBase {
  constructor(param: ApiCsvOrderDownloadParam) {
    super({
      httpMethod: 'GET',
      contents: 'BLOB',
      param,
      url: '/api/csv/download/order',
    });
  }
}
