/* eslint-disable no-irregular-whitespace */
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { DummyLogo } from '../../../../ui/dummy-logo/dummy-logo';
import { Button } from '../../../../ui/button/button';
import { LoginSP } from '../../login/login.sp';
import './change-password-complete.sp.scss';
import { RoutingPath } from '../../../../../routes/routing-pass';

type Props = {
  isActivate?: boolean
}

export const ChangePasswordCompleteSP = (props: Props) => {
  const { isActivate } = props;
  const message = `${isActivate ? '' : '再'}設定`;

  const dispatch = useDispatch();
  window.console.log('');

  return (
    <div className="changePasswordCompleteSP">
      <div>
        <DummyLogo />
        <div className="changePasswordCompleteSP__title">パスワード{message}完了</div>
        <div className="changePasswordCompleteSP__body">
          <div className="changePasswordCompleteSP__body__inner">
            <div className="changePasswordCompleteSP__body__inner__text">
              パスワードの{message}が完了しました。<br />
              設定したパスワードにて、ログインをお願いします。<br />
            </div>
            <div className="changePasswordCompleteSP__body__inner__text">
              ※ご不明点等ございましたら、<br />
              システム管理者へお問い合わせください。
            </div>
            <div className="changePasswordCompleteSP__body__inner__btn">
              <Button
                type="submit"
                size="md"
                color="secondary"
                onClick={() => {
                  dispatch(push(RoutingPath.login));
                }}
              >
                ログイン画面へ
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
