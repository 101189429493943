import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { goBack } from 'connected-react-router';
import { isEqual } from 'lodash';
import { DisplayElements } from '../../../../../../type/display-elements.type';
import { Table } from '../../../../../ui/table/table';
import { RightLabelCheckbox } from '../../../../../ui/checkbox/right-label-checkbox/right-label-checkbox';
import { LeftIconButton } from '../../../../../ui/button/left-icon-button/left-icon-button';
import { Button } from '../../../../../ui/button/button';
import { State } from '../../../../../../redux/root.reducer';
import { Select } from '../../../../../ui/select/select';
import { TableOption } from '../../../../../../type/table.type';
import { MasterIndexType } from '../../../../../../redux/master/api/api-master-index';
import { ApiMasterEmployeeGetListParam } from '../../../../../../redux/master/api/master-employee/api-master-employee.type';
import { MasterGetListParam } from './master-body';

type Props = {
  header: { key: string; label: string;}[];
  rowDataList: any[];
  list: DisplayElements[][];
  callbackEdit: (v?: any) => void;
  callbackGetList: (v: ApiMasterEmployeeGetListParam) => void;
  sortIndex?: MasterIndexType;
  defaultOrder: number;
  tableOption?: TableOption;
};

export const MasterBodyForEmployee = (props: Props) => {
  const {
    rowDataList,
    header,
    callbackGetList,
    callbackEdit,
    sortIndex,
    list,
    tableOption,
    defaultOrder,
  } = props;

  const dispatch = useDispatch();
  const { storeList } = useSelector((state: State) => (state.master), isEqual);

  /* State */
  const [selected, setSelected] = useState(NaN);
  const [store, setStore] = useState(NaN);
  const [isMuko, setIsMuko] = useState(false);
  const [orderSort, setOrderSort] = useState<ApiMasterEmployeeGetListParam>({
    highlow: defaultOrder,
    sort_by: 1,
  });

  /* Callback */
  const getList = useCallback(() => {
    callbackGetList({
      ...orderSort,
      is_muko: isMuko ? 1 : 0,
      store_id: store,
    });
  },
  [callbackGetList, orderSort, isMuko, store]);

  const handleClickRow = useCallback(
    (row: any) => setSelected(rowDataList.findIndex((v2) => v2.id === row.id)),
    [rowDataList],
  );
  const handleDbClickRow = useCallback(
    (row: any) => callbackEdit(row.id),
    [callbackEdit],
  );

  /* Effect */
  useEffect(() => {
    getList();
  }, [orderSort]);

  return (
    <div className="main_cnt">
      <div className="search_area only_simple ">
        <div className="item_wrap">
          <div className="item_box">
            <div className="item_head">店舗</div>
            <Select
              className="add_text_left"
              value={store}
              onChange={(v) => setStore(Number(v))}
              defaultLabel="指定無し"
              options={storeList.map((v) => ({
                text: v.name, value: v.id,
              }))}
            />
          </div>
          <div className="item_box">
            <RightLabelCheckbox
              checked={isMuko}
              label="無効情報も含む"
              onChange={() => setIsMuko(!isMuko)}
            />
          </div>
          <LeftIconButton
            label="絞込み"
            fontAwesomeClass="fas fa-filter"
            className="btn_search for_simple"
            size="sm"
            color="secondary"
            onClick={getList}
          />
        </div>
      </div>
      <div className="option_area table_sort">
        <div className="left">
          <div className="count">総件数： &nbsp;<span>{list.length}</span> 件</div>
        </div>
        <div className="right">
          <LeftIconButton
            label="新規登録"
            size="md"
            fontAwesomeClass="fas fa-edit"
            className="btn_search for_detail"
            color="primary"
            onClick={() => callbackEdit()}
          />
        </div>
      </div>
      <section className="result_area list_area">
        <div className="inner">
          <div className="table_responsive">
            <Table
              className="table_selectable table_sortable table_sticky table_cell_change"
              header={header.map((v) => v.label)}
              onClickRow={handleClickRow}
              disabledTh={[0]}
              onDbClick={handleDbClickRow}
              selectedTr={[selected]}
              rowDataList={rowDataList}
              isMukoDisp
              sort={{
                index: [],
                onClick: (highlow, sort_by) => setOrderSort({ highlow, sort_by }),
              }}
              lists={list.map((v:any, i) => ([
                <Button
                  color="secondary"
                  size="md"
                  onClick={(e) => {
                    e.stopPropagation();
                    callbackEdit(rowDataList[i].id);
                  }}
                >
                  編集
                </Button>,
                ...v,
              ]))}
              option={tableOption}
            />
          </div>
        </div>
      </section>
      <footer className="btn_area">
        <div className="left_box" />
        <div className="right_box">
          <LeftIconButton
            label="戻る"
            fontAwesomeClass="fas fa-arrow-left"
            size="md"
            color="dark"
            onClick={() => dispatch(goBack())}
          />
        </div>
      </footer>
    </div>
  );
};
