import * as DateFns from 'date-fns';
/**
 * ２つの日付の差分が何日間か返す
 * @returns 何日間
 */
export const getTermDay = (start: Date | null, end: Date | null) => {
  if (start && end) {
    const diff = Math.abs(end.getTime() - start.getTime());
    return Math.ceil(diff / (1000 * 3600 * 24) + 1);
  }
  return undefined;
};

/**
 * 日付範囲が正しいかどうかチェック(どちらにも入力がない場合は正)
 */
export const getTimeTermValidation = (start: Date | null, end: Date | null) => {
  if (start && end) {
    return (end.getTime() - start.getTime()) >= 0;
  }
  return true;
};

/**
 * ２つの日付の差分
 * @returns 差分オブジェクト
 */
export const getTermDate = (start: Date | null, end: Date | null) => {
  if (start && end) {
    const timeTillNow = end.getTime() - start.getTime();
    const daysTillNow = timeTillNow / (1000 * 3600 * 24);
    const DAYS_PER_MONTH = 365 / 12;
    const termYear = Math.floor(daysTillNow / 365);
    const termMonth = Math.floor((daysTillNow - 365 * termYear) / DAYS_PER_MONTH);
    const termDay = Math.floor((daysTillNow - 365 * termYear - DAYS_PER_MONTH * termMonth));
    return { day: termDay, month: termMonth, year: termYear };
  }
  return undefined;
};
