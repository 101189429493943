import { User } from '../../type/auth/user.typs';

export class MapCollection {
  // private static _apiKey = 'AIzaSyDd4kZOB96LZ9GMxmDUiHcwriQEb1KA9-w';

  private static _clusterImg = 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m'

  private static _clusterMaxZoom = 19;

  /** 検索時のZoom倍率 */
  static readonly searchZoomLevel = 16;

  static readonly searchRouteZoomLevel = 16;

  static _testCenter = {
    lat: 35.703559,
    lng: 139.749636,
  };

  static get clusterMaxZoom() {
    return MapCollection._clusterMaxZoom;
  }

  static get clusterImg() {
    return MapCollection._clusterImg;
  }

  static get testCenter() {
    return MapCollection._testCenter;
  }

  static readonly unSearchPrefectures: string[] = [
    '北海道',
    '千葉県',
    '埼玉県',
    '東京都',
    '神奈川県',
    '静岡県',
    '愛知県',
    '兵庫県',
    '大阪府',
    '福岡県',
  ]
}
