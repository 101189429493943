/* eslint-disable no-nested-ternary */
import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual, cloneDeep } from 'lodash';
import { EditPC } from '../../../../../dialogs/edit/edit.pc';
import { Select } from '../../../../../ui/select/select';
import { Required } from '../../../../../ui/required/required';
import { useDidMount } from '../../../../../../hooks/life-cycle';
import { Input } from '../../../../../ui/input/input';
import { MasterActions } from '../../../../../../redux/master/master.action';
import { ValidationMax100Million } from '../../../../../../model/validation/validation-max-100-million';
import { ValidationMax10Billion } from '../../../../../../model/validation/validation-max-10-billion';
import { CommonCollection } from '../../../../../../collection/common/common.collection';
import { State } from '../../../../../../redux/root.reducer';
import { AddMeisaiData, MeisaiListXML } from '../../../../../../model/estimate/estimate-meisai.type';
import { cancelFigureSeparate } from '../../../../../../utilities/format-num';
import { pulldown } from '../../../../../../utilities/pulldown';
import { EstimateMeisaiValidation } from '../../../../../../model/validation/estimate/estimate-meisai.validation';
import { DialogActions } from '../../../../../../redux/dialog/dialog.action';
import { InputNum } from '../../../../../ui/input/input-num';
import { MathHelper } from '../../../../../../utilities/math-helper';
import { EstimateMeisaiModel } from '../../../../../../model/estimate/estimate-meisai.model';
import { Message } from '../../../../../../collection/message.collection';
import { MasterMiddleCategory } from '../../../../../../type/master/master-middle-category.type';
import { ValidationLengthUnder255 } from '../../../../../../model/validation';

type Props = {
  data?: MeisaiListXML;
  callback: (v:AddMeisaiData) => void;
}

/* FIXME
  shohin_cdによって
  資材工事名がSelectになり
  自動入力になる
*/

export const EditMeisaiDialogPC = (props: Props) => {
  const { data, callback } = props;
  console.log(data);
  const dispatch = useDispatch();
  const daibunruiList = useSelector((state:State) => state.master.largeCategoryList, isEqual);
  const middleCategoryList = useSelector((state:State) => state.master.middleCategoryList, isEqual);
  const meisaiList = useSelector((state:State) => state.master.meisaiList, isEqual);
  const taniList = useSelector((state:State) => state.master.unitList, isEqual);

  /* State */
  const [shohinCd, setShohinCd] = useState(data?.$.shohin_cd ?? '');
  const [kubun, setKubun] = useState(data?.$.shohin_kubun ?? '1');
  const [daibunrui, setDaibunrui] = useState(data?.$.daibunrui_id ?? '');
  const [tyubunrui, setTyubunrui] = useState(data?.$.tyubunrui_id ?? '');
  const [name, setName] = useState(data?.$.name ?? '');
  const [kikaku, setKikaku] = useState(data?.$.kikaku ?? '');
  const [suryo, setSuryo] = useState(data?.$.suryou
    ? MathHelper.rounding2Str(Number(data.$.suryou), 2, 'round', true)
    : '0');
  const [tani, setTani] = useState(data?.$.tani_id ?? '');
  const [shikirikakaku, setShikiriKakaku] = useState(
    data?.$.shikiri_kakaku
      ? MathHelper.rounding2Str(Number(data.$.shikiri_kakaku), 0, 'round', true)
      : '0',
  );
  const [genka, setGenka] = useState(
    data?.$.genka
      ? MathHelper.rounding2Str(Number(data.$.genka), 0, 'round', true)
      : '0',
  );
  const [touch, setTouch] = useState(false);
  const [_tyubunruiList, set_tyubunruiList] = useState<MasterMiddleCategory[]>([]);

  /* Callback */
  const handleClickSave = useCallback(() => {
    if (EstimateMeisaiValidation(
      {
        shohin_kubun: kubun,
        daibunrui_id: daibunrui,
        tyubunrui_id: tyubunrui,
        name,
        suryou: suryo,
        tani_id: tani,
        shikiri_kakaku: shikirikakaku,
        genka,
      },
    )) {
      dispatch(DialogActions.pushMessage({
        title: '見積明細作成',
        message: Message.postError,
        callback: () => setTouch(true),
      }));
      return;
    }
    const daibunrui_name = daibunruiList.find((v) => String(v.id) === daibunrui)?.name || '';
    const tyubunrui_name = middleCategoryList.find((v) => String(v.id) === tyubunrui)?.name || '';
    const tani_name = taniList.find((v) => String(v.id) === tani)?.name || '';
    const shohin_kubun_name = CommonCollection.shohinKubunList.find((v) => String(v.value) === kubun)?.text || '';
    const sendData = {
      shohin_cd: shohinCd,
      daibunrui_name,
      tyubunrui_name,
      shohin_kubun_name,
      shiiresaki_name: '',
      tani_name,
      kikaku,
      suryou: cancelFigureSeparate(suryo),
      genka: cancelFigureSeparate(genka),
      shikiri_kakaku: cancelFigureSeparate(shikirikakaku),
      daibunrui_id: daibunrui,
      tyubunrui_id: tyubunrui,
      shohin_kubun: kubun,
      name,
      tani_id: tani,
      kingaku: shikirikakaku,
      arari_ritu: '0',
    };
    callback(data ? { ...data.$, ...sendData } : sendData);
  }, [
    shohinCd,
    data,
    kubun,
    daibunrui,
    tyubunrui,
    name,
    kikaku,
    suryo,
    tani,
    shikirikakaku,
    genka,
    data,
    taniList,
    daibunruiList,
    middleCategoryList,
    callback,
  ]);

  const _meisaiList = useMemo(() => meisaiList.filter((v) => (
    !daibunrui
      ? true
      : !tyubunrui
        ? String(v.category_id) === daibunrui
        : String(v.category_id) === daibunrui
        && String(v.subcategory_id) === tyubunrui
  )), [meisaiList, daibunrui, tyubunrui]);

  const changeDaibunrui = useCallback((val: string | number) => {
    const v = String(val);
    if (v === daibunrui) return;
    if (!v) {
      setDaibunrui('');
      setTyubunrui('');
      setShohinCd('');
      set_tyubunruiList(cloneDeep(middleCategoryList));
      return;
    }
    setDaibunrui(String(v));
    setTyubunrui('');
    set_tyubunruiList(middleCategoryList.filter((t) => (String(t.category_id) === v)));
  }, [daibunrui, middleCategoryList]);

  const changeTyubunrui = useCallback((val: string | number) => {
    const v = String(val);
    if (v === tyubunrui) return;
    if (!v) {
      setTyubunrui('');
      setShohinCd('');
      return;
    }
    setTyubunrui(String(v));
    setShohinCd('');
  }, [tyubunrui]);

  const changeShohinCd = useCallback((cd: string) => {
    const find = meisaiList.find((v) => v.shohin_cd === cd);
    setShohinCd(find ? String(cd) : '');
    setKikaku(find ? String(find.standard) : '');
    setTani(find ? String(find.credit_id) : '');
    setName(find ? String(find.name) : '');
    setShikiriKakaku(find ? MathHelper.rounding2Str(Number(find.quote_unit_price), 0, 'round', true) : '');
    setGenka(find ? MathHelper.rounding2Str(Number(find.prime_cost), 0, 'round', true) : '');
    setSuryo(find ? MathHelper.rounding2Str(Number(find.quantity), 2, 'round', true) : '');
  }, [meisaiList]);

  /* Master */
  useDidMount(() => {
    dispatch(MasterActions.api.largeCategory.getList({}));
    dispatch(MasterActions.api.middleCategory.getList({}));
    dispatch(MasterActions.api.unit.getList({}));
    dispatch(MasterActions.api.meisai.getList({}));
  });

  useEffect(() => {
    if (middleCategoryList.length) {
      set_tyubunruiList(middleCategoryList.filter((
        v,
      ) => (!data?.$.daibunrui_id
        ? true
        : String(v.category_id) === data?.$.daibunrui_id
      )));
    }
  }, [middleCategoryList]);

  return (
    <EditPC
      mode="dialog"
      callback={handleClickSave}
    >
      <div className="EditMeisaiDialogPC">
        <div className="item_wrap">
          <div className="item_box">
            <div className="item_head">商品区分</div>
            <Select
              value={kubun}
              onChange={(v) => setKubun(String(v))}
              defaultLabel="指定無し"
              options={CommonCollection.shohinKubunList}
            />
          </div>
        </div>
        <div className="item_wrap">
          <div className="item_box">
            <div className="item_head">大分類<Required /></div>
            <Select
              value={daibunrui}
              onChange={changeDaibunrui}
              defaultLabel="指定無し"
              options={pulldown(daibunruiList)}
              require
              touch={touch}
            />
          </div>
        </div>
        <div className="item_wrap">
          <div className="item_box">
            <div className="item_head">中分類<Required /></div>
            <Select
              value={tyubunrui}
              onChange={changeTyubunrui}
              defaultLabel="指定無し"
              options={pulldown(_tyubunruiList)}
              require
              touch={touch}
            />
          </div>
        </div>
        <div className="item_wrap">
          <div className="item_box">
            <div className="item_head">工事・資材名<Required /></div>
            {data?.$.shohin_cd
              ? (
                <Select
                  value={shohinCd}
                  onChange={(v) => changeShohinCd(String(v))}
                  defaultLabel="指定無し"
                  options={_meisaiList.map((v) => ({
                    text: v.name, value: v.shohin_cd,
                  }))}
                />
              )
              : (
                <Input
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  require
                  validationList={ValidationLengthUnder255}
                  touch={touch}
                />
              )}
          </div>
        </div>
        <div className="item_wrap">
          <div className="item_box">
            <div className="item_head">規格</div>
            <Input
              value={kikaku}
              onChange={(e) => setKikaku(e.target.value)}
              validationList={ValidationLengthUnder255}
            />
          </div>
        </div>
        <div className="item_wrap">
          <div className="item_box">
            <div className="item_head">数量</div>
            <InputNum
              value={suryo}
              onBlur={(v) => setSuryo(EstimateMeisaiModel.setSuryo(v))}
              onChange={(v) => setSuryo(String(v))}
              intLen={8}
              decimalLen={2}
              validationList={ValidationMax100Million}
              minus
            />
          </div>
        </div>
        <div className="item_wrap">
          <div className="item_box">
            <div className="item_head">単位<Required /></div>
            <Select
              value={tani}
              onChange={(v) => setTani(String(v))}
              defaultLabel="指定無し"
              options={pulldown(taniList)}
              require
              touch={touch}
            />
          </div>
        </div>
        <div className="item_wrap">
          <div className="item_box">
            <div className="item_head">見積単価</div>
            <InputNum
              value={shikirikakaku}
              onBlur={(v) => setShikiriKakaku(
                EstimateMeisaiModel.setNumber(
                  v,
                  (val) => MathHelper.rounding2Str(val, 0, 'round', true),
                ),
              )}
              onChange={(v) => setShikiriKakaku(String(v))}
              intLen={10}
              validationList={ValidationMax10Billion}
              minus
            />
          </div>
        </div>
        <div className="item_wrap">
          <div className="item_box">
            <div className="item_head">原価単価</div>
            <InputNum
              value={genka}
              onBlur={(v) => setGenka(
                EstimateMeisaiModel.setNumber(
                  v,
                  (val) => MathHelper.rounding2Str(val, 0, 'round', true),
                ),
              )}
              onChange={(v) => setGenka(String(v))}
              intLen={10}
              validationList={ValidationMax10Billion}
              minus
            />
          </div>
        </div>
      </div>
    </EditPC>
  );
};
