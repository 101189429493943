import {
  DetailedHTMLProps, HTMLAttributes, useRef, useMemo, useState, useEffect,
} from 'react';
import {
  DropTargetMonitor, useDrag, useDrop, XYCoord,
} from 'react-dnd';

const style = {
  border: '1px dashed gray',
  padding: '0.5rem 1rem',
  marginBottom: '.5rem',
  backgroundColor: 'white',
  cursor: 'move',
};

type DragItem = {
  index: number
  id: number
  type: string
}

export type Props = {
  isDnD?: boolean;
  id?: number;
  index: number;
  targeIndex: number;
  onHover?: (draggedId: number, id: number) => void;
  onDrop?: (draggedId: number, id: number) => void;
  refs?: globalThis.React.Ref<any>
} & Pick<
  DetailedHTMLProps<HTMLAttributes<HTMLTableRowElement>, HTMLTableRowElement>,
  'className' | 'onClick' | 'onDoubleClick' | 'style' | 'children'
>

export const TableTr = ({
  className,
  children,
  onClick,
  onDoubleClick,
  style: _style,
  id,
  onHover,
  index,
  onDrop,
  targeIndex,
  isDnD,
  refs: _ref,
}: Props) => {
  const ref = useRef<HTMLTableRowElement>(null);

  /* Drag */
  const [, drag] = useDrag({
    type: 'tr',
    item: { id, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      draggingId: monitor.didDrop(),
    }),
    end: () => {
      if (onDrop) onDrop(NaN, NaN);
    },
  });

  /* Drop */
  const [handlerId, drop] = useDrop({
    accept: 'tr',
    collect: (monitor) => monitor.getHandlerId(),
    hover: (item: DragItem, monitor: DropTargetMonitor) => {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if ((dragIndex === undefined || hoverIndex === undefined)
        || (dragIndex === hoverIndex)) return;

      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      if ((dragIndex < hoverIndex && hoverClientY < hoverMiddleY)
        || (dragIndex > hoverIndex && hoverClientY > hoverMiddleY)) return;

      if (onHover) onHover(dragIndex, hoverIndex);
      // eslint-disable-next-line no-param-reassign
      item.index = hoverIndex;
    },
    drop(item: DragItem, monitor: DropTargetMonitor) {
      const dragIndex = item.index;
      const hoverIndex = index;
      if (onDrop) onDrop(dragIndex, hoverIndex);
    },
  });

  useEffect(() => {
    drag(drop(isDnD ? ref : null));
  }, [isDnD]);

  const opacity = useMemo(() => (targeIndex === index ? 0 : 1), [targeIndex, index]);
  const containerStyle = useMemo(() => ({ ...style, ..._style, opacity }), [
    opacity, _style, style,
  ]);

  return (
    <tr
      onClick={onClick}
      onDoubleClick={onDoubleClick}
      style={isDnD ? containerStyle : _style}
      className={`${className}`}
      ref={isDnD ? ref : _ref}
      data-handler-id={handlerId}
    >
      {children}
    </tr>
  );
};
