/* eslint-disable no-unused-expressions */
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';
import { CustomerSearchBox } from '../customer-search-box';
import { CustomerListType, CustomerSortState } from '../../../../../../type/customer/customer.type';
import { Button } from '../../../../../ui/button/button';
import { DialogActions } from '../../../../../../redux/dialog/dialog.action';
import { Limit, TableSort } from '../../../../../ui/table/table-sort/table-sort';
import { useWillUnMount } from '../../../../../../hooks/life-cycle';
import { CustomerActions } from '../../../../../../redux/customer/customer.action';
import { joinStr } from '../../../../../../utilities/join-str';
import { Table } from '../../../../../ui/table/table';
import { CustomerCollection } from '../../../../../../collection/customer/customer.collection';
import { CustomerModel } from '../../../../../../model/customer/customer-model';
import { useAppSelector } from '../../../../../../hooks/use-redux';
import Alert from '../../../../../../asset/images/icon/alert.svg';
import { useOpenWindow } from '../../../../../../hooks/use-open-window';
import { RoutingPath } from '../../../../../../routes/routing-pass';
import { Tikunengetsu } from '../../../../../ui/tikunengetsu/tikunengetsu';

type Props = {
  callback: (v: CustomerListType) => void;
  freeAuth?: boolean;
}

export const CustomerSearch = (props: Props) => {
  const { callback, freeAuth } = props;

  const customerRankList = useAppSelector((v) => v.master.customerRankList);
  const customerEstimatedRankList = useAppSelector((v) => v.master.customerEstimatedRankList);
  const user = useAppSelector((v) => v.user);
  const windowOpen = useOpenWindow();

  /* Hooks */
  const dispatch = useDispatch();
  const authority1 = useAppSelector((v) => (v.user.authority1));

  /* State */
  const [list, setList] = useState<CustomerListType[] | null>();
  const [hitCount, sethitCount] = useState<number>(0);
  const [customer, setCustomer] = useState<CustomerListType | null>(null);
  const [selected, setSelected] = useState<number[]>([]);
  const [searchIsOpen, setSearchIsOpen] = useState<boolean>(false);
  const [sort, setSort] = useState(CustomerCollection._customerSortInitialState(user));
  const [type, setType] = useState<0 | 1 | 2>(0);

  const _list = useMemo(() => {
    if (!list) return null;
    return !type ? list : list?.filter((v) => (
      v.ob_flag === type
    ));
  }, [list, type, callback]);

  /* Callback */
  const handleClickRow = useCallback((v:CustomerListType) => {
    if (!_list) return;
    setCustomer(cloneDeep(v));
    setSelected([_list.findIndex((v2) => v2.id === v.id)]);
  }, [_list]);

  const handleDbClickRow = useCallback((
    v: CustomerListType,
    e?: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
  ) => {
    if (!_list) return;
    if (!e) return;
    windowOpen({
      url: `${RoutingPath.customerDetail}/${v.id}`,
      e,
      callback: () => {
        dispatch(DialogActions.pop());
        callback(cloneDeep(v));
        setCustomer(cloneDeep(v));
        setSelected([_list.findIndex((v2) => v2.id === v.id)]);
      },
    });
  }, [callback, _list]);

  const handleClickSelect = useCallback(() => {
    dispatch(DialogActions.pop());

    if (customer) {
      callback(cloneDeep(
        customer,
      ));
    }
  },
  [customer, callback]);

  const getList = useCallback((v?: CustomerSortState) => {
    const sortData = v || sort;
    const setEmployeeId = (): number | undefined => {
      if (!user.view_data || user.view_data.company_id === 1) {
        if (user.authority1 || freeAuth) {
          return sortData.employee_id;
        }
        return user.id;
      }
      return NaN;
    };
    const setStoreId = (): number | undefined => {
      if (!user.view_data || user.view_data.company_id === 1) {
        if (user.authority1 || freeAuth) {
          return sortData.store_id;
        }
        return user.store_id;
      }
      return NaN;
    };
    dispatch(CustomerActions.api.customer.getCallbackList({
      param: {
        ...CustomerModel.listParam({ sortState: v || sort, isDialog: true }),
        store_id: setStoreId(),
        employee_id: setEmployeeId(),
        limit: sort.limit,
      },
      onSuccess: (data, hit) => {
        setList(data);
        sethitCount(hit || 0);
      },
    }));
  }, [sort]);

  const handleClickHeader = useCallback((highlow, sort_by) => {
    setSort({
      ...sort,
      highlow,
      sort_by,
    });
  }, [sort]);

  const handleChangePagination = useCallback((offset: number, limit: Limit) => {
    setList(null);
    setSort({ ...sort, offset, limit });
    getList({ ...sort, offset, limit });
  }, [sort]);

  useEffect(() => {
    getList();
  }, [
    sort.highlow,
    sort.sort_by,
  ]);

  return (
    <div className={`editPc_wrap ${searchIsOpen ? 'detail_on' : ''}`}>
      <div className="editPc_body show_all">
        <CustomerSearchBox
          noAuthority={freeAuth ? false : !authority1}
          initialSort={CustomerCollection._customerSortInitialState(user)}
          openCallback={setSearchIsOpen}
          isSearch
          callbackGetList={(v) => {
            setSort({
              ...cloneDeep(v),
              limit: sort.limit,
            });
            setList(null);
            getList({ ...v, offset: 0, limit: sort.limit });
          }}
          salesSet
          type={type}
          callbackType={(v) => setType(v)}
        />
        <TableSort
          page={sort.offset as number}
          limit={sort.limit as Limit}
          hitCount={hitCount}
          callback={handleChangePagination}
        />
        <section className="result_area list_area">
          <div className="inner">
            <div className="table_responsive">
              <Table
                className="table_selectable table_sortable table_sticky table_cell_change"
                header={CustomerCollection.customerHeader}
                onDbClick={(v, i, e) => handleDbClickRow(v, e)}
                selectedTr={selected}
                rowDataList={_list || []}
                onClickAlt={(v, e) => {
                  if (e && v) {
                    windowOpen({
                      url: `${RoutingPath.customerDetail}/${v.id}`,
                      e,
                      callback: () => {},
                    });
                  }
                }}
                onClickRow={handleClickRow}
                sort={{ onClick: (..._sort) => handleClickHeader(..._sort) }}
                lists={_list ? _list.map((v) => (
                  [
                    v.ob_flag === 1 ? 'OB' : '見込',
                    v.employee_name,
                    v.internal_id,
                    v.name,
                    v.furigana,
                    joinStr(v.post_no, 3, '-'),
                    v.prefecture_name,
                    v.city + v.address,
                    v.tel_no,
                    (() => {
                      const rank = customerEstimatedRankList.find((
                        v2,
                      ) => Number(v2.id) === Number(v.estimated_rank));
                      return <div className="rank_label" style={{ backgroundColor: rank?.background_color, color: rank?.text_color, textAlign: 'center' }}>{rank ? rank?.abbreviation : ''}</div>;
                    })(),
                    (() => {
                      const rank = customerRankList.find((
                        v2,
                      ) => v2.id === v.rank);
                      return <div className="rank_label" style={{ backgroundColor: rank?.background_color, color: rank?.text_color, textAlign: 'center' }}>{`${rank ? `${rank?.abbreviation}${rank?.abbreviation2}` : ''}`}</div>;
                    })(),
                    v.area_name,
                    v.building_category_name,
                    v.madori_name,
                    <Tikunengetsu
                      date={
                        v.construction_info
                          ? new Date(v.construction_info)
                          : null
                      }
                      isList
                    />,
                    v.remarks,
                  ]
                )) : null}
                option={{
                  stringWidth: [
                    { index: 0, width: 80 }, // 入力不備
                    // { index: 0, width: 100 }, // OBフラグ
                    // { index: 1, width: 50 }, // 顧客営業担当
                    // { index: 2, width: 50 }, //  顧客ID
                    // { index: 3, width: 50 }, // 顧客名
                    // { index: 4, width: 100 }, // フリガナ
                    // { index: 5, width: 50 }, // 郵便番号
                    // { index: 6, width: 50 }, // 都道府県
                    // { index: 7, width: 50 }, // 住所
                    // { index: 8, width: 50 }, // TEL
                    // { index: 9, width: 50 }, // 見込みランク
                    // { index: 10, width: 50 }, // 顧客ランク
                    // { index: 11, width: 50 }, // エリア
                    // { index: 12, width: 50 }, // 建物分類
                    // { index: 13, width: 50 }, // 間取り
                    // { index: 14, width: 50 }, // 築年数
                    // { index: 15, width: 50 }, // 備考
                  ],
                  tdAlign: [
                    // { index: 0, align: 'center' },
                    { index: 0, align: 'center' },
                    { index: 1, align: 'left' },
                    { index: 2, align: 'left' },
                    { index: 3, align: 'left' },
                    { index: 4, align: 'left' },
                    { index: 5, align: 'left' },
                    { index: 6, align: 'left' },
                    { index: 7, align: 'left' },
                    { index: 8, align: 'left' },
                    { index: 9, align: 'center' },
                    { index: 10, align: 'center' },
                    { index: 11, align: 'center' },
                    { index: 12, align: 'center' },
                    { index: 13, align: 'center' },
                    { index: 14, align: 'center' },
                    { index: 15, align: 'left' },
                  ],
                }}
              />
            </div>
          </div>
        </section>
        <TableSort
          className="bottom"
          page={sort.offset as number}
          limit={sort.limit as Limit}
          hitCount={hitCount}
          callback={handleChangePagination}
        />
      </div>
      <div className="editPc_footer base_footer">
        <Button size="md" color="primary" disabled={!customer} onClick={handleClickSelect}>
          選択
        </Button>
        <Button size="md" color="dark" onClick={() => dispatch(DialogActions.clear())}>
          閉じる
        </Button>
      </div>
    </div>
  );
};
