/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { push } from 'connected-react-router';
import { RoutingPath } from '../../../../routes/routing-pass';
import { AuthActions } from '../../../../redux/auth/auth.action';
import { LineFeedConversion } from '../../../../utilities/line-feed-conversion';
import { Button } from '../../../ui/button/button';
import { RightLabelCheckbox } from '../../../ui/checkbox/right-label-checkbox/right-label-checkbox';
import { TopLabelInputField } from '../../../ui/input-field/top-label-input-field/top-label-input-field';
import './login.pc.scss';
import { useDidMount } from '../../../../hooks/life-cycle';
import { SystemActions } from '../../../../redux/system/system.action';
import { Debug } from '../../../debug';
import { useAppSelector } from '../../../../hooks/use-redux';
import { DialogActions } from '../../../../redux/dialog/dialog.action';
import { Message } from '../../../../collection/message.collection';
import Logo from '../../../../asset/images/reformcloud_vertical.png';
// import '../../../../asset/ship/style.css';
import Logo2 from '../../../../asset/ship/img/logo.svg';
import ship_title from '../../../../asset/ship/img/title.png';
import ship_character from '../../../../asset/ship/img/character.png';
import ship_icon_movie from '../../../../asset/ship/img/icon-movie.svg';
import ship_icon_chomei from '../../../../asset/ship/img/icon-chomei.svg';
import { Input } from '../../../ui/input/input';

type Props = {
  message: string[] | null;
}

const ErrorMessageElement = (props:Props) => {
  const { message } = props;
  return message
    ? (
      <div className="error_wrap">
        {LineFeedConversion(message)}
      </div>
    )
    : <></>;
};

export const LoginPC = () => {
  const dispatch = useDispatch();

  const is404 = useAppSelector(((v) => v.dialog.is404));

  /* state */
  const [id, setId] = useState('');
  const [pass, setPass] = useState('');
  const [remember, setRemember] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string[] | null>(null);
  const [error, setError] = useState(false);
  const [isInit, setIsInit] = useState(false);

  const handleClickLogin = useCallback(() => {
    console.log(id);
    console.log(pass);
    dispatch(AuthActions.api.login({
      param: {
        mail_address: id,
        password: pass,
      },
      onError: (str) => {
        setErrorMessage(str);
        setError(true);
      },
    }));
  }, [id, pass, remember]);

  useEffect(() => {
    if (isInit && is404) {
      dispatch(DialogActions.pushMessage({
        title: 'お知らせ',
        message: Message.session,
        callback: () => {
          dispatch(DialogActions.setIs404(false));
          dispatch(push(RoutingPath.login));
        },
        isInvisibleClose: true,
      }));
    }
  }, [isInit]);

  useDidMount(() => {
    dispatch(SystemActions.allReset());
    setIsInit(true);
  });

  return (
    <>
      {/* -- Header --*/}
      <div id="header">
        <div className="inner">
          <h1><img src={ship_title} alt="蓄積情報の運用パートナー リフォームクラウド" /></h1>
          <p><img src={ship_character} alt="リフォーム業者のキャラクター" /></p>
        </div>
        <div className="flex">
          <a
            href="https://service.reformcloud.com/manual/"
            className="btn-movie"
            target="_blank"
            rel="noreferrer"
          >
            {/* <span className="btn-movie"> */}
            <span>
              <img onClick={() => dispatch(push('/'))} src={ship_icon_movie} alt="ユーチューブアイコン" />
            </span>
            マニュアル動画
            {/* </span> */}
          </a>
          <a
            href="https://service.reformcloud.com/request/"
            className="btn-shinkitoroku"
            target="_blank"
            rel="noreferrer"
          >
            {/* <span className="btn-shinkitoroku"> */}
            <span>
              <img onClick={() => dispatch(push('/'))} src={ship_icon_chomei} alt="登録アイコン" />
            </span>
            無料登録
            {/* </span> */}
          </a>
        </div>
      </div>
      {/* -- /Header --*/}
      {/* -- contents --*/}
      <div id="contents">
        {/* <form action="" method=""> */}
        <div id="form">
          <p><img src={Logo2} alt="リフォームクラウドロゴ" className="logo" /></p>
          <h2>リフォームクラウド ログイン画面</h2>
          <div className="inner">
            <label htmlFor="email">メールアドレス</label>
            {/* <input
              type="mail"
              id="email"
              name="email"
              placeholder="メールアドレス"
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleClickLogin();
                }
              }}
              // onChange={(e) => }
            /> */}
            <Input
              // className="loginPC__body__inner__input-field"
              // placeholder="メールアドレス"
              id="email"
              type="email"
              value={id}
              error={error}
              onEnterKeyPress={handleClickLogin}
              onChange={(e) => setId(e.target.value)}
              isLogin
            />

          </div>
          <div className="inner">
            <label htmlFor="password">パスワード</label>
            {/* <input type="pass" id="password" name="password" /> */}
            <Input
              // className="loginPC__body__inner__input-field"
              // placeholder="パスワード"
              id="password"
              type="password"
              value={pass}
              error={error}
              onEnterKeyPress={handleClickLogin}
              onChange={(e) => setPass(e.target.value)}
              isLogin
            />

          </div>
          <ErrorMessageElement message={errorMessage} />
          <Button
            className="submit"
            type="button"
            color="primary"
            onClick={handleClickLogin}
            disabled={!(id && pass)}
          >
            ログイン
          </Button>
        </div>
        <Link className="btn-password" to={RoutingPath.sendPassword}>パスワードを忘れた方</Link>
        <Debug callback={(_id, _pass) => {
          setId(_id);
          setPass(_pass);
        }}
        />

      </div>
      {/* -- /contents --*/}
      {/* -- FOOTER --*/}
      <div id="footer">
        <div className="flex">
          <p className="flex-box title">リフォームクラウド</p>
          <div className="flex-box">
            {/* <a href="https://www.shipinc.co.jp/" target="_blank" className="link" rel="noreferrer">運営会社</a> */}
            <div
              className="link"
              onClick={() => {
                window.location.href = 'https://www.shipinc.co.jp/';
              }}
            >
              運営会社
            </div>
            <p
              className="copyright"
            >Copyright ©reformcloud All Rights Reserved.
            </p>
          </div>
        </div>
      </div>

      {/* ここから下もとソース *}
      {/*
      <div className="loginPC">
*/}
      {/*
        <div className="loginPC__body">
          <div className="loginPC__logo">
            <img src={Logo} alt="リフォームクラウド" />
          </div>
          <div className="loginPC__body__inner">
            <TopLabelInputField
              className="loginPC__body__inner__input-field"
              placeholder="メールアドレス"
              type="email"
              value={id}
              error={error}
              onEnterKeyPress={handleClickLogin}
              onChange={(e) => setId(e.target.value)}
              isLogin
            />
            <TopLabelInputField
              className="loginPC__body__inner__input-field"
              placeholder="パスワード"
              type="password"
              value={pass}
              error={error}
              onEnterKeyPress={handleClickLogin}
              onChange={(e) => setPass(e.target.value)}
              isLogin
            />
*/}
      {/*
          <RightLabelCheckbox
            label="ログイン状態を保存する"
            name="remember"
            checked={remember}
            onChange={() => setRemember(!remember)}
          />
          */}
      {/*
            <div className="link_box">
              <Link to={RoutingPath.sendPassword}>パスワードを忘れた方</Link>
            </div>
            <ErrorMessageElement message={errorMessage} />
            <Button
              type="button"
              color="primary"
              onClick={handleClickLogin}
              disabled={!(id && pass)}
            >
              ログイン
            </Button>
          </div>
*/}
      {/*
          <Debug callback={(_id, _pass) => {
            setId(_id);
            setPass(_pass);
          }}
          />
        </div>
      </div>*/}
    </>
  );
};

// import React, { useCallback, useEffect, useState } from 'react';
// import { useDispatch } from 'react-redux';
// import { Link } from 'react-router-dom';
// import { push } from 'connected-react-router';
// import { RoutingPath } from '../../../../routes/routing-pass';
// import { AuthActions } from '../../../../redux/auth/auth.action';
// import { LineFeedConversion } from '../../../../utilities/line-feed-conversion';
// import { Button } from '../../../ui/button/button';
// import { RightLabelCheckbox } from '..
// /../../ui/checkbox/right-label-checkbox/right-label-checkbox';
// import { TopLabelInputField } from '../../..
// /ui/input-field/top-label-input-field/top-label-input-field';
// import './login.pc.scss';
// import { useDidMount } from '../../../../hooks/life-cycle';
// import { SystemActions } from '../../../../redux/system/system.action';
// import { Debug } from '../../../debug';
// import { useAppSelector } from '../../../../hooks/use-redux';
// import { DialogActions } from '../../../../redux/dialog/dialog.action';
// import { Message } from '../../../../collection/message.collection';
// import Logo from '../../../../asset/images/reformcloud_vertical.png';
// import '../../../../asset/ship/style.css';
// import Logo2 from '../../../../asset/ship/img/logo.svg';
// import ship_title from '../../../../asset/ship/img/title.png';
// import ship_character from '../../../../asset/ship/img/character.png';
// import ship_icon_movie from '../../../../asset/ship/img/icon-movie.svg';
// import ship_icon_chomei from '../../../../asset/ship/img/icon-chomei.svg';
// import { Input } from '../../../ui/input/input';

// type Props = {
//   message: string[] | null;
// }

// const ErrorMessageElement = (props:Props) => {
//   const { message } = props;
//   return message
//     ? (
//       <div className="error_wrap">
//         {LineFeedConversion(message)}
//       </div>
//     )
//     : <></>;
// };

// export const LoginPC = () => {
//   const dispatch = useDispatch();

//   const is404 = useAppSelector(((v) => v.dialog.is404));

//   /* state */
//   const [id, setId] = useState('');
//   const [pass, setPass] = useState('');
//   const [remember, setRemember] = useState(false);
//   const [errorMessage, setErrorMessage] = useState<string[] | null>(null);
//   const [error, setError] = useState(false);
//   const [isInit, setIsInit] = useState(false);

//   const handleClickLogin = useCallback(() => {
//     console.log(id);
//     console.log(pass);
//     dispatch(AuthActions.api.login({
//       param: {
//         mail_address: id,
//         password: pass,
//       },
//       onError: (str) => {
//         setErrorMessage(str);
//         setError(true);
//       },
//     }));
//   }, [id, pass, remember]);

//   useEffect(() => {
//     if (isInit && is404) {
//       dispatch(DialogActions.pushMessage({
//         title: 'お知らせ',
//         message: Message.session,
//         callback: () => {
//           dispatch(DialogActions.setIs404(false));
//           dispatch(push(RoutingPath.login));
//         },
//         isInvisibleClose: true,
//       }));
//     }
//   }, [isInit]);

//   useDidMount(() => {
//     dispatch(SystemActions.allReset());
//     setIsInit(true);
//   });

//   return (
//     <>
//       {/* -- Header --*/}
//       <div id="header">
//         <div className="inner">
//           <h1><img src={ship_title} alt="蓄積情報の運用パートナー リフォームクラウド" /></h1>
//           <p><img src={ship_character} alt="リフォーム業者のキャラクター" /></p>
//         </div>
//         <div className="flex">
//           {/* <a
//             // href="#"
//             className="btn-movie"
//             target="_blank"
//             rel="noreferrer"
//           > */}
//           <span>
//             <img onClick={() => dispatch(push('/'))} src={ship_icon_movie} alt="ユーチューブアイコン" />
//           </span>マニュアル動画
//           {/* </a> */}
//           {/* <a href="#" className="btn-shinkitoroku"> */}
//          <span>
//            <img onClick={() => dispatch(push('/'))} src={ship_icon_chomei} alt="登録アイコン" />
//          </span>無料登録
//           {/* </a> */}
//         </div>
//       </div>
//       {/* -- /Header --*/}
//       {/* -- contents --*/}
//       <div id="contents">
//         {/* <form action="" method=""> */}
//         <div>
//           <p><img src={Logo2} alt="リフォームクラウドロゴ" className="logo" /></p>
//           <h2>リフォームクラウド ログイン画面</h2>
//           <div className="inner">
//             <label htmlFor="email">メールアドレス</label>
//             {/* <input
//               type="mail"
//               id="email"
//               name="email"
//               placeholder="メールアドレス"
//               onKeyPress={(e) => {
//                 if (e.key === 'Enter') {
//                   handleClickLogin();
//                 }
//               }}
//               // onChange={(e) => }
//             /> */}
//             <Input
//               // className="loginPC__body__inner__input-field"
//               // placeholder="メールアドレス"
//               type="email"
//               value={id}
//               error={error}
//               onEnterKeyPress={handleClickLogin}
//               onChange={(e) => setId(e.target.value)}
//               isLogin
//             />

//           </div>
//           <div className="inner">
//             <label htmlFor="password">パスワード</label>
//             {/* <input type="pass" id="password" name="password" /> */}
//             <Input
//               // className="loginPC__body__inner__input-field"
//               // placeholder="パスワード"
//               type="password"
//               value={pass}
//               error={error}
//               onEnterKeyPress={handleClickLogin}
//               onChange={(e) => setPass(e.target.value)}
//               isLogin
//             />

//           </div>
//           <ErrorMessageElement message={errorMessage} />
//           <Button
//             type="button"
//             color="primary"
//             onClick={handleClickLogin}
//             disabled={!(id && pass)}
//           >
//             ログイン
//           </Button>
//         </div>
//         <Link to={RoutingPath.sendPassword}>パスワードを忘れた方</Link>
//       </div>
//       {/* -- /contents --*/}
//       {/* -- FOOTER --*/}
//       <div id="footer">
//         <div className="flex">
//           <p className="flex-box title">リフォームクラウド</p>
//           <div className="flex-box">
//             {/* <a href="https://www.shipinc.co.jp/" target="_blank" className="link" rel="noreferrer">運営会社</a> */}
//             <p
//               onClick={() => {
//                 window.location.href = 'https://www.shipinc.co.jp/';
//               }}
//               className="copyright"
//             >Copyright ©reformcloud All Rights Reserved.
//             </p>
//           </div>
//         </div>
//       </div>
