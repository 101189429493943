import { EditPC } from '../../../../../../dialogs/edit/edit.pc';

export const CustomerRankUpdateMasterEditDialog = () => {
  window.console.log();

  return (
    <EditPC mode="dialog">
      <div>顧客ランク更新</div>
    </EditPC>
  );
};
