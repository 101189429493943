import { memo } from 'react';
import { useDispatch } from 'react-redux';
import { SubWindow, formatList } from './sub-window';
import { useDidMount } from '../../../../../../../hooks/life-cycle';
import { DialogActions } from '../../../../../../../redux/dialog/dialog.action';
import { MasterActions } from '../../../../../../../redux/master/master.action';
import { useAppSelector } from '../../../../../../../hooks/use-redux';

type Props = {
  open: boolean;
  callbackClose: () => void;
}

const master = 'customerRank';
const listName = 'customerRankList';

export const CustomerRank = memo((props: Props) => {
  const { open, callbackClose } = props;

  const dispatch = useDispatch();
  const list = useAppSelector((v) => v.master[listName]);

  useDidMount(() => dispatch(MasterActions.api[master].getList({})));

  return (
    <SubWindow
      open={open}
      title="顧客ランク"
      header={['短縮コード', '名前']}
      lists={list
        .sort((a, b) => (
          a.abbreviation + a.abbreviation2 < b.abbreviation + a.abbreviation2 ? -1 : 1
        ))
        .map((v) => ([v.abbreviation + v.abbreviation2, v.name]))}
      callbackClose={callbackClose}
    />
  );
});
