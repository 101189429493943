import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { isNumber } from 'lodash';
import { useDispatch } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';
import { OrderCollection } from '../../../../collection/order/order.collection';
import { DialogActions } from '../../../../redux/dialog/dialog.action';
import { OrderActions } from '../../../../redux/order/order.action';
import { OrderEditState, Order } from '../../../../type/order/order.type';
import { DateFormatter as DF, DateFormatter } from '../../../../utilities/date-formatter';
import { Button } from '../../../ui/button/button';
import { DatePicker } from '../../../ui/date-picker/date-picker';
import { OrderListPC } from '../../layout/body/list/order-list/order-list.pc';
import { Input } from '../../../ui/input/input';
import './order.pc.scss';
import { useDidMount, useWillUnMount } from '../../../../hooks/life-cycle';
import { ProjectSearch } from '../../layout/search-box/project/project-search/project-search';
import { EstimateListType } from '../../../../type/estimate/estimate.type';
import { OrderValidation } from '../../../../model/validation/order/order.validation';
import { ValidationLengthUnder40, ValidationLengthUnder500, ValidationLengthUnder60 } from '../../../../model/validation';
import { TextArea } from '../../../ui/text-area/text-area';
import { ValidationNumberLengthUnder10, ValidationNumberLengthUnder8 } from '../../../../model/validation/validation-number-length-under';
import { ValidationDatePicker } from '../../../../model/validation/validation-date-picker';
import { Project, ProjectListType } from '../../../../type/project/project.type';
import { LeftIconButton } from '../../../ui/button/left-icon-button/left-icon-button';
import { OrderModel } from '../../../../model/order/order.model';
import { EstimateModel } from '../../../../model/estimate/estimate-model';
import { ResultType } from '../../../../type/api.type';
import { BillListType } from '../../../../type/bill/bill.type';
import { ApiBillGetList } from '../../../../redux/bill/api/bill/api-bill';
import { ApiEstimateGetList } from '../../../../redux/estimate/api/estimate/api-estiamte';
import { ApiProjectGet } from '../../../../redux/project/api/project/api-project';
import { InputNum } from '../../../ui/input/input-num';
import { MathHelper } from '../../../../utilities/math-helper';
import { useOrderPostAfterGetList } from '../../../../hooks/use-order';
import { ProjectActions } from '../../../../redux/project/project.action';
import { useAppSelector } from '../../../../hooks/use-redux';
import { FileListPC, FileSizeData } from '../../layout/body/list/file-list/file-list.pc';
import { FileListType } from '../../../../type/file/file.type';
import { FileActions } from '../../../../redux/file/file.action';
import { getListData } from '../../../../utilities/get-list-data';
import { CustomerActions } from '../../../../redux/customer/customer.action';
import { Customer } from '../../../../type/customer/customer.type';
import { useEstimateAuthority } from '../../../../hooks/use-authority';
import { TableSort } from '../../../ui/table/table-sort/table-sort';

type Props = {
  projectData?: Project;
  callback?: () => void;
  isDisplaySearch?: boolean
  callbackInProject?: () => void;
  onClose?: (v: boolean) => void;
  willUnMount?: () => void;
}

type SortParam = {
  highlow: number;
  sort_by: number;
}
type PagerParam = {
  limit: number;
  offset: number;
}

type FileGetListParam = {
  project?: ProjectListType | Project | null;
  sort?: SortParam;
  page?: PagerParam;
}

export const OrderPC = (props: Props) => {
  const {
    projectData, isDisplaySearch, callback, callbackInProject, onClose, willUnMount,
  } = props;
  /* Hooks */
  const dispatch = useDispatch();
  const user = useAppSelector((v) => v.user);
  const estimateAuth = useEstimateAuthority();
  /* State */
  const [filedName, setFiledName] = useState('');
  const [projectName, setProjectName] = useState('');
  // eslint-disable-next-line
  const [touch, setTouch] = useState(false);
  const [fileData, setFileData] = useState<FileListType[] | null>(null);

  const getList = useOrderPostAfterGetList();

  const [order, setOrder] = useState<OrderEditState>(OrderCollection.editInitialState);
  const [estimateList, setEstimateList] = useState<EstimateListType[] | null>(null);
  const [selectEstimate, setSelectEstimate] = useState<EstimateListType | null>(null);
  const [stayEstimate, setStayEstimate] = useState<EstimateListType | null>(null);
  const [orderSort, setOrderSort] = useState({ sort_by: 1, highlow: 0 });
  const [billListModel, setBillListModel] = useState(new OrderModel());
  const [project, setProject] = useState<Project | ProjectListType | null>(null);
  const [isUpdate, setIsUpdate] = useState(false);
  const [isFile, setIsFile] = useState(!estimateAuth);
  const [customerData, setCustomerData] = useState<Customer | null>(null);
  const [fileSize, setFileSize] = useState<FileSizeData>({ capacity: 0, total: 0 });
  const [fileSort, setFileSort] = useState<SortParam>({ sort_by: 0, highlow: 0 });
  const [fileHit, setFileHit] = useState(0);
  const [fileStop, setFileStop] = useState(false);
  const [pagerSort, setPagerSort] = useState<PagerParam>(
    { limit: 100, offset: 0 },
  );

  const miwariatekin = useMemo(() => billListModel.calcMiwariatekin(order),
    [billListModel, order]);

  /* 請求金額表示 */
  const showPriceList = useMemo<string[]>(() => {
    const list = ['', '', '', '', '', ''];
    if (!billListModel.billList.billing_amount_array.length) return list;
    return billListModel.billList.billing_amount_array.map((v) => (Number.isNaN(v) ? '' : v.toLocaleString()));
  }, [billListModel]);

  /* Callback */
  const setState = useCallback((v: Partial<OrderEditState>) => {
    const data = {
      ...cloneDeep(order),
      ...cloneDeep(v),
    };
    setOrder(data);
  }, [order]);
  /* ファイル一覧GetList */
  const getListFile = useCallback((v: FileGetListParam) => {
    const { project: _project, sort, page } = v;
    const p = _project || project;
    const s = sort || fileSort;
    const pa = page || pagerSort;
    console.log(project, s);
    if (sort) setFileSort(cloneDeep(sort));
    if (page) setPagerSort(cloneDeep(page));
    if (!p) return;
    setFileStop(true);
    dispatch(FileActions.api.file.getList({
      param: {
        project_id: p?.id,
        customer_id: p?.customer_id,
        sort_by: s.sort_by,
        highlow: s.highlow,
        limit: pa.limit,
        offset: pa.offset,
        order_file: 1,
      },
      onSuccess: (data, file, hit) => {
        setFileStop(false);
        setFileData(cloneDeep(data));
        setFileSize(cloneDeep(file));
        if (isNumber(hit)) setFileHit(hit);
      },
    }));
  }, [project, fileSort, pagerSort]);

  /* 受注工期から工期から取得 */
  const getOrderConstructionDate = useCallback(() => {
    if (!estimateAuth) {
      setState({
        construction_start_date: DF.str2date(order?.construction_period_start),
        completion_end_date: DF.str2date(order?.construction_period_end),
      });
      return;
    }
    if (!selectEstimate) return;
    setState(OrderModel.getJutyuKoki({ selectEstimate, order }));
  }, [order, selectEstimate]);

  /* 見積一覧取得 */
  const getEstimateList = useCallback((
    id: number,
  ):Promise<ResultType<EstimateListType>> => new ApiEstimateGetList(EstimateModel
    .listParmInOrder(
      { project_id: id, orderSort },
    )).run(),
  [orderSort]);

  /* 請求一覧取得 */
  const getBillList = useCallback((
    project_id: number,
  ):Promise<ResultType<BillListType>> => new ApiBillGetList({ project_id }).run(),
  []);

  /* 案件取得 */
  const getProject = useCallback((
    id: number,
  ):Promise<ResultType<Project>> => new ApiProjectGet({ id }).run(),
  []);

  /* 案件検索 */
  const projectListSearch = useCallback((isNew?: boolean) => {
    dispatch(DialogActions.push({
      title: '案件検索',
      className: 'max_height_dialog max_width_dialog search_dialog',
      onCloseClick: () => {
        if (isNew) dispatch(DialogActions.pop());
        onClose?.(false);
      },
      element: <ProjectSearch
        type="order"
        callback={(v) => setProject(v)}
        onClose={() => {
          if (isNew) dispatch(DialogActions.pop());
          onClose?.(false);
        }}
      />,
      onClose: () => onClose?.(false),
    }));
  }, [order]);

  /* 未割当金を完工金に含める */
  const handleClickInMoney = useCallback(() => {
    setBillListModel(billListModel.mergeMiwariatekinInKankokin(cloneDeep(miwariatekin)));
  }, [billListModel, miwariatekin]);

  /* 保存 */
  const handleClickPost = useCallback(() => {
    const billList = cloneDeep(billListModel);
    if (OrderValidation(order, billList.billList, estimateList, estimateAuth).length) {
      dispatch(DialogActions.pushMessage({
        title: '受注 編集／登録',
        message: OrderValidation(order, billList.billList, estimateList, estimateAuth),
        callback: () => setTouch(true),
      }));
      return;
    }
    dispatch(OrderActions.api.order.post({
      param: OrderModel.postParam({ order, billListModel }),
      update: isUpdate,
      onSuccess: () => {
        getList();
        callback?.();
        callbackInProject?.();
      },
    }));
  }, [order, callback, billListModel, estimateList, isUpdate]);

  /* 見積選択変更 */
  const handleClickChangeEstimateSelect = useCallback(() => {
    if (stayEstimate) {
      setState(OrderModel.setEstimateData(stayEstimate));
      setSelectEstimate(cloneDeep(stayEstimate));
    }
  }, [stayEstimate]);

  /* Effect */
  /* 案件参照 */
  useDidMount(() => {
    if (!projectData) {
      projectListSearch(true);
      return;
    }
    setProject(cloneDeep(projectData));
  });

  /* 見積一覧取得 */
  useEffect(() => {
    if (!project) return;
    setFiledName(project.field_name);
    setProjectName(project.name);

    dispatch(CustomerActions.api.customer.get({
      param: {
        id: project.customer_id,
      },
      callback: (customerInfo) => {
        setCustomerData(cloneDeep(customerInfo));

        (async () => Promise.all([
          getBillList(project.id),
          getEstimateList(project.id),
          getProject(project.id),
        ]))().then(([b, e, p]) => {
          if (!b || !e || !p) return;
          const _bill = b.body.data;
          const _estimate = e.body.data;
          const _project = p.body.data[0];
          const _e = OrderModel.setLatestEstimateData(e.body.data);
          if (_estimate) {
            _estimate.forEach((v) => {
              if (v.order_flag) setIsUpdate(true);
            });
          }
          setBillListModel(new OrderModel(cloneDeep(_bill), _e?.gokei_kin || 0));
          setEstimateList(cloneDeep(_estimate || null));
          setSelectEstimate(cloneDeep(_e || null));
          if (!estimateAuth) {
            getListFile({ project });
          }
          if (estimateAuth) {
            if (_project.contract_date && (_estimate.findIndex((find) => find.saisyu_f) < 0)) {
              dispatch(DialogActions.pushMessage({
                title: 'お知らせ',
                message: ['見積と紐付きのない受注情報があります。', '見積を選択していない状態での更新は行えません。'],
              }));
              setSelectEstimate(null);
              setState({
                ...OrderModel.setProjectData(_project, estimateAuth),
              });
              return;
            }

            setState({
              ...OrderModel.setProjectData(_project),
              ...OrderModel.setEstimateData(_e),
            });
          } else {
            console.log(_estimate);
            if (_project.contract_date && _estimate.findIndex((find) => find.saisyu_f) > -1) {
              dispatch(DialogActions.pushMessage({
                title: 'お知らせ',
                message: ['見積と紐づいた受注情報があります。', '更新を行うと見積との紐付きが解除されます。'],
              }));
            }
            setState({
              ...OrderModel.setProjectData(_project, !estimateAuth),
            });
          }
        });
      },
    }));
  }, [project, estimateAuth]);

  useWillUnMount(() => {
    dispatch(ProjectActions.setInitSort(user));
    willUnMount?.();
  });

  return (
    <>
      <div id="order" className="editPc_wrap">
        <div className="editPc_body ">
          <div className="item_wrap">
            <div className="item_box">
              <div className="item_head">現場名称</div>
              <Input
                value={filedName}
                disabled
                validationList={ValidationLengthUnder60}
              />
            </div>
            <div className="item_box">
              <div className="item_head">案件名</div>
              <Input
                value={projectName}
                disabled
                validationList={ValidationLengthUnder40}
              />
            </div>
            <div>
              {isDisplaySearch && (
              <Button
                size="sm"
                color="secondary"
                className="ml_10"
                onClick={() => projectListSearch()}
              >
                案件参照
              </Button>
              )}
            </div>
            <div className="ml_auto">
              {estimateAuth && (
              <LeftIconButton
                size="sm"
                label={!isFile ? '関連ファイル一覧' : '見積一覧'}
                color="primary"
                fontAwesomeClass={!isFile ? 'fas fa-file' : 'fas fa-file-alt'}
                onClick={() => {
                  if (!project) return;
                  if (!isFile) {
                    getListFile({});
                  }
                  setIsFile(!isFile);
                }}
              />
              )}
            </div>
          </div>

          {!isFile && estimateAuth
            ? (
              <OrderListPC
                list={estimateList}
                selectEstimate={selectEstimate}
                callbackSort={setOrderSort}
                callback={setStayEstimate}
              />
            )
            : (
              <>
                <div id="file" className="cnt_area">
                  <FileListPC
                    orderData={{
                      customer: customerData,
                      project,
                      limit: pagerSort.limit,
                      offset: pagerSort.offset,
                      hitCount: fileHit,
                      pagerCallback: (limit, offset) => getListFile({ page: { limit, offset } }),
                    }}
                    stopLoad={fileStop}
                    fileSize={fileSize}
                    callbackStop={setFileStop}
                    callbackGetList={() => getListFile({})}
                    sort={(sort) => getListFile({
                      sort,
                    })}
                    data={fileData}
                  />
                </div>
              </>
            )}

          <div className="order_area">
            {(estimateAuth && (
            <div className="item_wrap flex_no_wrap_box">
              <div className="item_box">
                <div className="item_head">見積番号</div>
                <Input
                  value={selectEstimate?.estimate_no}
                  disabled
                  validationList={ValidationNumberLengthUnder10}
                />
              </div>
              <div className="item_box">
                <div className="item_head">見積金額</div>
                <InputNum
                  value={MathHelper.localStr(selectEstimate?.gokei_kin || 0)}
                  disabled
                  onChange={() => {}}
                  validationList={ValidationNumberLengthUnder10}
                />
              </div>
              <div className="item_box">
                <div className="item_head">見積原価</div>
                <InputNum
                  value={MathHelper.localStr(selectEstimate?.gokei_genka_kin || 0)}
                  disabled
                  onChange={() => {}}
                  validationList={ValidationNumberLengthUnder8}
                />
              </div>
              <div className="item_box">
                <div className="item_head">見積工期</div>
                <Input
                  value={DF.date2str(selectEstimate?.estimate_kouki_start_dt)}
                  disabled
                />
                <label className="ml_5 mr_5">〜</label>
                <Input
                  value={DF.date2str(selectEstimate?.estimate_kouki_end_dt)}
                  disabled
                />
              </div>
              {!isFile && (
              <div className="item_box right_side">
                <LeftIconButton
                  fontAwesomeClass="fas fa-retweet"
                  label="見積選択変更"
                  size="sm"
                  color="secondary"
                  className=""
                  onClick={handleClickChangeEstimateSelect}
                />
              </div>
              )}
            </div>
            )
            )}
            <div className="item_wrap flex_no_wrap_box">
              <div className="item_box">
                <div className="item_head">契約日</div>
                <DatePicker
                  date={order?.contract_date || null}
                  onChange={(v) => setState(
                    { ...order, contract_date: v },
                  )}
                  touch={touch}
                  validationList={ValidationDatePicker}
                  require
                />
              </div>
              <div className="item_box">
                <div className="item_head">受注金額</div>
                <InputNum
                  value={MathHelper.localStr(order?.order_price || 0)}
                  // value={selectEstimate?.order_price.toLocaleString()}
                  disabled={estimateAuth}
                  onChange={(v) => {
                    if (estimateAuth) return;
                    setState({ order_price: MathHelper.localStrToNum(v) });
                  }}
                  // validationList={ValidationNumberLengthUnder10}
                />
              </div>
              <div className="item_box">
                <div className="item_head">受注原価</div>
                <InputNum
                  value={MathHelper.localStr(order?.jyutyu_genka || 0)}
                  onChange={(v) => {
                    if (estimateAuth) return;
                    setState({ jyutyu_genka: MathHelper.localStrToNum(v) });
                  }}
                  disabled={estimateAuth}
                  // validationList={ValidationNumberLengthUnder8}
                />
              </div>
              <div className="item_box">
                <div className="item_head">受注工期</div>
                {estimateAuth ? (
                  <>
                    <Input
                      value={DateFormatter.date2str(order?.construction_period_start)}
                  // value={DF.date2str(selectEstimate?.order_construction_start)}
                      disabled
                    />
                    <label className="ml_5 mr_5">〜</label>
                    <Input
                  // value={DF.date2str(selectEstimate?.order_construction_end)}
                      value={DateFormatter.date2str(order?.construction_period_end)}
                      disabled
                    />
                  </>
                )
                  : (
                    <>
                      <DatePicker
                        date={DF.str2date(order?.construction_period_start)}
                        onChange={(v) => setState(
                          { construction_period_start: DF.date2str(v) },
                        )}
                        validationList={ValidationDatePicker}
                        // touch={touch}
                        // require
                      />
                      <label className="ml_5 mr_5">〜</label>
                      <DatePicker
                        date={DF.str2date(order?.construction_period_end)}
                        onChange={(v) => setState(
                          { construction_period_end: DF.date2str(v) },
                        )}
                        validationList={ValidationDatePicker}
                        // touch={touch}
                        // require
                      />

                    </>
                  )}
              </div>
            </div>
            <div className="item_wrap flex_no_wrap_box">
              <div className="item_box">
                <div className="item_head">着工予定日</div>
                <DatePicker
                  date={order?.construction_start_date}
                  onChange={(v) => setState(
                    { construction_start_date: v },
                  )}
                  validationList={ValidationDatePicker}
                  touch={touch}
                  require
                />
              </div>
              <div className="item_box">
                <div className="item_head">完工予定日</div>
                <DatePicker
                  date={order?.completion_end_date}
                  onChange={(v) => setState(
                    { completion_end_date: v },
                  )}
                  validationList={ValidationDatePicker}
                  touch={touch}
                  require
                />
                {estimateAuth && (
                <Button
                  size="sm"
                  color="secondary"
                  className="ml_10"
                  onClick={getOrderConstructionDate}
                >
                  受注工期から取得
                </Button>
                )}
              </div>
            </div>
            <div className="bottom">
              <div className="price_box">
                <div className="item_wrap flex_no_wrap_box">
                  <div className="item_box">
                    <div className="item_head">契約金</div>
                    <InputNum
                      value={showPriceList[0]}
                      onChange={(v) => {
                        const _bill = cloneDeep(billListModel);
                        _bill.billList.billing_amount_array[0] = MathHelper.localStrToNum(v);
                        setBillListModel(_bill);
                      }}
                      intLen={11}
                    />
                  </div>
                  <div className="item_box">
                    <div className="item_head">請求日</div>
                    <DatePicker
                      date={
                        billListModel
                          ? DateFormatter.str2date(billListModel.billList.billing_date_array[0])
                          : new Date()
                      }
                      onChange={(v) => {
                        const _bill = cloneDeep(billListModel);
                        _bill.billList.billing_date_array[0] = DateFormatter.date2str(v);
                        setBillListModel(_bill);
                      }}
                    />
                  </div>
                  <div className="item_box">
                    <div className="item_head">入金予定日</div>
                    <DatePicker
                      date={
                        billListModel
                          ? DateFormatter.str2date(billListModel.billList.payment_due_date_array[0])
                          : new Date()
                      }
                      onChange={(v) => {
                        const _bill = cloneDeep(billListModel);
                        _bill.billList.payment_due_date_array[0] = DateFormatter.date2str(v);
                        setBillListModel(_bill);
                      }}
                    />
                  </div>
                </div>
                <div className="item_wrap flex_no_wrap_box">
                  <div className="item_box">
                    <div className="item_head">着工金</div>
                    <InputNum
                      value={showPriceList[1]}
                      onChange={(v) => {
                        const _bill = cloneDeep(billListModel);
                        _bill.billList.billing_amount_array[1] = MathHelper.localStrToNum(v);
                        setBillListModel(_bill);
                      }}
                      intLen={11}
                    />
                  </div>
                  <div className="item_box">
                    <div className="item_head">請求日</div>
                    <DatePicker
                      date={
                        billListModel
                          ? DateFormatter.str2date(billListModel.billList.billing_date_array[1])
                          : new Date()
                      }
                      onChange={(v) => {
                        const _bill = cloneDeep(billListModel);
                        _bill.billList.billing_date_array[1] = DateFormatter.date2str(v);
                        setBillListModel(_bill);
                      }}
                    />
                  </div>
                  <div className="item_box">
                    <div className="item_head">入金予定日</div>
                    <DatePicker
                      date={
                        billListModel
                          ? DateFormatter.str2date(billListModel.billList.payment_due_date_array[1])
                          : new Date()
                      }
                      onChange={(v) => {
                        const _bill = cloneDeep(billListModel);
                        _bill.billList.payment_due_date_array[1] = DateFormatter.date2str(v);
                        setBillListModel(_bill);
                      }}
                    />
                  </div>
                </div>
                <div className="item_wrap flex_no_wrap_box">
                  <div className="item_box">
                    <div className="item_head">中間金</div>
                    <InputNum
                      value={showPriceList[2]}
                      onChange={(v) => {
                        const _bill = cloneDeep(billListModel);
                        _bill.billList.billing_amount_array[2] = MathHelper.localStrToNum(v);
                        setBillListModel(_bill);
                      }}
                      intLen={11}
                    />
                  </div>
                  <div className="item_box">
                    <div className="item_head">請求日</div>
                    <DatePicker
                      date={
                        billListModel
                          ? DateFormatter.str2date(billListModel.billList.billing_date_array[2])
                          : new Date()
                      }
                      onChange={(v) => {
                        const _bill = cloneDeep(billListModel);
                        _bill.billList.billing_date_array[2] = DateFormatter.date2str(v);
                        setBillListModel(_bill);
                      }}
                    />
                  </div>
                  <div className="item_box">
                    <div className="item_head">入金予定日</div>
                    <DatePicker
                      date={
                        billListModel
                          ? DateFormatter.str2date(billListModel.billList.payment_due_date_array[2])
                          : new Date()
                      }
                      onChange={(v) => {
                        const _bill = cloneDeep(billListModel);
                        _bill.billList.payment_due_date_array[2] = DateFormatter.date2str(v);
                        setBillListModel(_bill);
                      }}
                    />
                  </div>
                </div>
                <div className="item_wrap flex_no_wrap_box">
                  <div className="item_box">
                    <div className="item_head">中間金</div>
                    <InputNum
                      value={showPriceList[3]}
                      onChange={(v) => {
                        const _bill = cloneDeep(billListModel);
                        _bill.billList.billing_amount_array[3] = MathHelper.localStrToNum(v);
                        setBillListModel(_bill);
                      }}
                      intLen={11}
                    />
                  </div>
                  <div className="item_box">
                    <div className="item_head">請求日</div>
                    <DatePicker
                      date={
                        billListModel
                          ? DateFormatter.str2date(billListModel.billList.billing_date_array[3])
                          : new Date()
                      }
                      onChange={(v) => {
                        const _bill = cloneDeep(billListModel);
                        _bill.billList.billing_date_array[3] = DateFormatter.date2str(v);
                        setBillListModel(_bill);
                      }}
                    />
                  </div>
                  <div className="item_box">
                    <div className="item_head">入金予定日</div>
                    <DatePicker
                      date={
                        billListModel
                          ? DateFormatter.str2date(billListModel.billList.payment_due_date_array[3])
                          : new Date()
                      }
                      onChange={(v) => {
                        const _bill = cloneDeep(billListModel);
                        _bill.billList.payment_due_date_array[3] = DateFormatter.date2str(v);
                        setBillListModel(_bill);
                      }}
                    />
                  </div>
                </div>
                <div className="item_wrap flex_no_wrap_box">
                  <div className="item_box">
                    <div className="item_head">完工金</div>
                    <InputNum
                      value={showPriceList[4]}
                      onChange={(v) => {
                        const _bill = cloneDeep(billListModel);
                        _bill.billList.billing_amount_array[4] = MathHelper.localStrToNum(v);
                        setBillListModel(_bill);
                      }}
                      intLen={11}
                    />
                  </div>
                  <div className="item_box">
                    <div className="item_head">請求日</div>
                    <DatePicker
                      date={
                        billListModel
                          ? DateFormatter.str2date(billListModel.billList.billing_date_array[4])
                          : new Date()
                      }
                      onChange={(v) => {
                        const _bill = cloneDeep(billListModel);
                        _bill.billList.billing_date_array[4] = DateFormatter.date2str(v);
                        setBillListModel(_bill);
                      }}
                    />
                  </div>
                  <div className="item_box">
                    <div className="item_head">入金予定日</div>
                    <DatePicker
                      date={
                        billListModel
                          ? DateFormatter.str2date(billListModel.billList.payment_due_date_array[4])
                          : new Date()
                      }
                      onChange={(v) => {
                        const _bill = cloneDeep(billListModel);
                        _bill.billList.payment_due_date_array[4] = DateFormatter.date2str(v);
                        setBillListModel(_bill);
                      }}
                    />
                  </div>
                </div>
                <div className="item_wrap flex_no_wrap_box">
                  <div className="item_box">
                    <div className="item_head">未割当金</div>
                    <InputNum
                      value={MathHelper.localStr(miwariatekin)}
                      onChange={() => {}}
                      disabled
                    />
                    <Button
                      size="sm"
                      color="secondary"
                      className="ml_10"
                      onClick={handleClickInMoney}
                    >
                      未割当金を完工金に含める
                    </Button>
                  </div>
                </div>
              </div>
              <div className="remarks_box">
                <div className="item_wrap">
                  <div className="item_box mr_0">
                    <div className="item_head">備考</div>
                    <TextArea
                      className="large"
                      rows={10}
                      value={order?.remarks}
                      onChange={(e) => setState(
                        { ...order, remarks: e.target.value },
                      )}
                      validationList={ValidationLengthUnder500}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <footer className="editPc_footer base_footer">
          <div className="center_box">
            <Button size="md" color="primary" onClick={handleClickPost}>{isUpdate ? '更新' : '登録'}</Button>
            <Button size="md" color="dark" onClick={() => dispatch(DialogActions.pop())}>キャンセル</Button>
          </div>
        </footer>
      </div>
      {/* </BasePagePC> */}
    </>
  );
};
