import './change-password.pc.scss';
import { Dimmer, Loader } from 'semantic-ui-react';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { TopLabelInputField } from '../../../../ui/input-field/top-label-input-field/top-label-input-field';
import { AuthActions } from '../../../../../redux/auth/auth.action';
import { useQuery } from '../../../../../hooks/use-query';
import { Button } from '../../../../ui/button/button';
import { RoutingPath } from '../../../../../routes/routing-pass';
import { ValidationPassword } from '../../../../../model/validation/validation-password';
import { PassWordSendValidation } from '../../../../../model/validation/password/password-send.validation';
import { PassWordChangeValidation } from '../../../../../model/validation/password/password-change.validation';
import { DialogActions } from '../../../../../redux/dialog/dialog.action';
import Logo from '../../../../../asset/images/reformcloud_vertical.png';

type Props = {
  isActivate?: boolean;
}

export const ChangePasswordPC = (props: Props) => {
  const { isActivate } = props;

  /* Hooks */
  const dispatch = useDispatch();
  const token = useQuery('token');
  const email = useQuery('email');

  /* State */
  const [auth, setAuth] = useState(false);
  const [pass, setPass] = useState('');
  const [rePass, setRePass] = useState('');

  const disabled = !pass || !rePass;

  /* Callback */
  const handleClickSubmit = useCallback(
    () => {
      const valid = PassWordChangeValidation(pass, rePass);
      if (valid.length) {
        dispatch(DialogActions.pushMessage({
          title: 'お知らせ',
          message: valid,
        }));
        return;
      }
      if (!isActivate) {
        dispatch(AuthActions.api.password.change({
          password: pass,
          password_confirmation: rePass,
          mail_address: email || '',
          token: token || '',
        }));
      } else {
        dispatch(AuthActions.api.password.activate({
          password: pass,
          password_confirmation: rePass,
          mail_address: email || '',
          token: token || '',
        }));
      }
    },
    [pass, rePass, token, email],
  );

  /* Effect */
  useEffect(() => {
    dispatch(AuthActions.api.password.checkDate({
      param: { token: token || '', mail_address: email || '' },
      onSuccess: () => {
        setAuth(true);
      },
      onError: () => {
        setAuth(false);
        dispatch(push(
          !isActivate
            ? RoutingPath.changePasswordError
            : RoutingPath.userActivateError,
        ));
      },
    }));
  }, []);

  return (
    <div className="loginPC">
      {auth ? (
        <div className="loginPC__body">
          <div className="loginPC__logo">
            <img src={Logo} alt="リフォームクラウド" />
          </div>
          <div className="loginPC__body__inner">
            <div className="loginPC__title">パスワード{!!isActivate || '再'}設定</div>
            <div className="text_box">
              新しいパスワードを入力してください。<br />
              パスワードは半角英数字の8文字以上で設定してください。
            </div>
            <TopLabelInputField
              className="loginPC__body__inner__input-field"
              placeholder="パスワード"
              type="password"
              value={pass}
              validationList={ValidationPassword}
              onChange={(e) => {
                setPass(e.target.value);
              }}
            />
            <TopLabelInputField
              className="loginPC__body__inner__input-field"
              placeholder="パスワード再入力"
              onEnterKeyPress={handleClickSubmit}
              type="password"
              value={rePass}
              validationList={ValidationPassword}
              onChange={(e) => {
                setRePass(e.target.value);
              }}
            />
            <div style={{ margin: '0 0 30px' }}>
              <Button
                type="submit"
                color="primary"
                onClick={handleClickSubmit}
                disabled={disabled}
              >送信
              </Button>
            </div>
          </div>
        </div>
      )
        : (
          <>
            <Dimmer active inverted>
              <Loader size="massive">
                認証中
              </Loader>
            </Dimmer>
          </>
        )}
    </div>
  );
};
