import { isEqual } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useWillUnMount } from '../../../../../../hooks/life-cycle';
import { CsvActions } from '../../../../../../redux/csv/csv.action';
import { State } from '../../../../../../redux/root.reducer';
import { Limit, TableSort } from '../../../../../ui/table/table-sort/table-sort';
import { CsvListOrder } from '../../list/order/csv-list-order';
import { CsvSearchBoxOrder } from '../../search-box/order/csv-search-box-order';
import { CsvOrderSort } from '../../../../../../type/csv/csv-sort.type';
import { CsvModel } from '../../../../../../model/csv/csv-model';
import { useAppSelector } from '../../../../../../hooks/use-redux';

type Props = {
  callback: (v: () => void) => void;
}

export const CsvOrder = (props:Props) => {
  const { callback } = props;

  /* Hooks */
  const dispatch = useDispatch();
  const sortState = useSelector((state: State) => (state.csv.orderSort), isEqual);
  const list = useSelector((state: State) => (state.csv.orderList), isEqual);
  const hitCount = useAppSelector((v) => (v.csv.orderCount));

  /* State */
  const [selected, setSelected] = useState<number[]>([]);
  // eslint-disable-next-line
  const [searchIsOpen, setSearchIsOpen] = useState<boolean>(false);

  /* Callback */
  const getList = useCallback((v?: CsvOrderSort) => {
    const sortData = v || sortState;
    dispatch(CsvActions.api.order.getList(CsvModel.orderEx(sortData)));
    setSelected([]);
  },
  [sortState]);

  const changePagination = useCallback((offset:number, limit:Limit) => {
    dispatch(CsvActions.setOrderList(null));
    dispatch(CsvActions.setOrderSort({ offset, limit }));
    getList({ ...sortState, offset, limit });
  }, [sortState]);

  /* Effect */
  useEffect(() => {
    getList();
  }, [
    sortState.highlow,
    sortState.sort_by,
  ]);

  return (
    <>
      <CsvSearchBoxOrder
        openCallback={setSearchIsOpen}
        callback={(v) => {
          dispatch(CsvActions.setOrderList(null));
          getList(v);
        }}
      />
      <TableSort
        page={sortState.offset ?? 0}
        limit={sortState.limit as Limit}
        hitCount={hitCount || 0}
        callback={changePagination}
      />
      <CsvListOrder
        callbackSelected={setSelected}
      />
      <TableSort
        className="bottom"
        page={sortState.offset ?? 0}
        limit={sortState.limit as Limit}
        hitCount={hitCount || 0}
        callback={changePagination}
      />
    </>
  );
};
