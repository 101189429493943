import './file.pc.scss';
import { useDispatch } from 'react-redux';
import { goBack, replace } from 'connected-react-router';
import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { BasePagePC } from '../base.page.pc';
import { DialogActions } from '../../../../redux/dialog/dialog.action';
import { LeftIconButton } from '../../../ui/button/left-icon-button/left-icon-button';
import { RoutingPath } from '../../../../routes/routing-pass';
import { FileEditPC } from './edit/file-edit.pc';
import { FileSearchBoxPC } from '../../layout/search-box/file/file-search-box.pc';
import { FileListPC } from '../../layout/body/list/file-list/file-list.pc';
import { FileActions } from '../../../../redux/file/file.action';
import { useWillUnMount } from '../../../../hooks/life-cycle';
import { useAppSelector } from '../../../../hooks/use-redux';
import { FileModel } from '../../../../model/file/file.model';
import { Limit, TableSort } from '../../../ui/table/table-sort/table-sort';
import { FileSortState } from '../../../../type/file/file.type';

export const FilePC = () => {
  /* Hooks */
  const { id } = useParams<{id:string}>();
  const sortState = useAppSelector((v) => (v.file.sort));
  const dispatch = useDispatch();

  /* State */
  const [selectId, setSelectId] = useState<number | undefined>(undefined);
  const [hitCount, setHitCount] = useState(0);
  const [stopLoad, setStopLoad] = useState(false);

  const [fileSize, setFileSize] = useState<
  { capacity: number, total: number; }>({ capacity: 0, total: 0 });

  /* Callback */
  /* 一覧取得 */
  const getList = useCallback((v?: FileSortState) => {
    dispatch(FileActions.api.file.getList({
      param: FileModel.listParam(v || sortState),
      onSuccess: ((res, file, hit) => {
        setStopLoad(false);
        setFileSize({ ...file });
        dispatch(FileActions.setList(res));
        setHitCount(hit as number);
      }),
    }));
  }, [sortState]);

  /* 編集 */
  const edit = useCallback(() => {
    setStopLoad(true);
    dispatch(DialogActions.push({
      title: 'ファイル情報入力',
      className: 'file auto_height_dialog',
      element: <FileEditPC
        id={selectId ? Number(selectId) : undefined}
        callback={() => {
          dispatch(FileActions.setList(null));
          getList();
        }}
      />,
      onCloseClick: () => {
        if (selectId) {
          dispatch(replace(RoutingPath.file));
          setStopLoad(false);
        }
      },
    }));
  },
  [selectId]);

  const handleChangePagination = useCallback((offset: number, limit: Limit) => {
    dispatch(FileActions.setList(null));
    dispatch(FileActions.setSort({ offset, limit }));
    getList({ ...sortState, offset, limit });
  }, [sortState.limit, sortState]);

  /* Effect */
  useEffect(() => {
    getList();
  }, [sortState.highlow, sortState.sort_by]);

  useWillUnMount(() => {
  });

  /* ファイル詳細SPのURLを叩かれたときの処理 */
  useEffect(() => {
    if (!selectId) return;
    edit();
  }, [selectId]);

  useEffect(() => {
    setSelectId(id ? Number(id) : undefined);
  }, [id]);

  return (
    <BasePagePC
      className="FilePC"
      onClickOrder={(v) => setStopLoad(v)}
    >
      <div id="file" className="cnt_area">
        <div className="inner">
          <FileSearchBoxPC callback={(v) => {
            setStopLoad(true);
            dispatch(FileActions.setList(null));
            dispatch(FileActions.setSort({ offset: 0 }));
            getList({ ...v, offset: 0 });
          }}
          />
          <TableSort
            page={sortState.offset ?? 0}
            limit={sortState.limit !== (100 || 200 || 300 || 400 || 500 || 9999)
              ? 100 : sortState.limit as Limit}
            hitCount={hitCount}
            callback={handleChangePagination}
          />
          <FileListPC
            callbackGetList={getList}
            selectId={selectId}
            fileSize={fileSize}
            stopLoad={stopLoad}
            callbackStop={(v) => setStopLoad(v)}
          />
        </div>
      </div>
      <footer className="btn_area">
        <div className="left_box">
          <LeftIconButton
            label="新規登録"
            size="md"
            fontAwesomeClass="fas fa-edit"
            className="btn_search for_detail"
            color="primary"
            onClick={edit}
          />
        </div>
        <div className="right_box">
          <LeftIconButton
            label="戻る"
            fontAwesomeClass="fas fa-arrow-left"
            size="md"
            color="dark"
            onClick={() => dispatch(goBack())}
          />
        </div>
      </footer>
    </BasePagePC>
  );
};
