/* eslint-disable no-nested-ternary */
import cloneDeep from 'lodash/cloneDeep';
import { ApiCustomerGetListParam, ApiCustomerPostParam } from '../../redux/customer/api/customer/api-customer.type';
import { Address, MapAreaPosition } from '../../type/map/map.type';
import { CustomerEditState, CustomerSortState, Customer } from '../../type/customer/customer.type';
import { DateFormatter } from '../../utilities/date-formatter';
import { AddressSearch } from '../../type/map/address-search.type';
import { deleteHyphen } from '../../utilities/delete-str';
import { changeString } from '../../utilities/change-string';
import { TagModel } from '../tag/tag';
import { TagType } from '../../type/tag/tag.type';
import { PetListModel } from './pet-list.model';
import { FamilyListModel } from './family-list.model';
import { deleteCommaStr } from '../../utilities/delete-comma';
import { minus0, plus0 } from '../../components/ui/search-post-address-dialog/search-post-address-dialog';

export class CustomerModel {
  /** APIGet */
  static setEditData(param: {
    customer: CustomerEditState,
    data: Customer;
    partList: TagType[];
    masterMyCarTypeList: TagType[];
    relevantTagList: TagType[];
  }): CustomerEditState {
    const {
      customer, data, partList, masterMyCarTypeList, relevantTagList,
    } = param;
    return ({
      ...customer,
      ...data,
      fax_no: deleteHyphen(data.fax_no),
      post_no: [changeString(data.post_no, '').slice(0, 3),
        changeString(data.post_no, '').slice(3, 7)],
      tel_no: data.tel_no,
      tel_no2: data.tel_no2,
      tel_no3: data.tel_no3,
      prefecture: data.prefecture
        ? data.prefecture
        : data.jisx0401_code
          ? Number(minus0(data.jisx0401_code))
          : NaN,
      wedding_anniversary: data.wedding_anniversary ? new Date(data.wedding_anniversary) : null,
      construction_info: data.construction_info ? new Date(data.construction_info) : null,
      expected_part_list: new TagModel(partList, data.expected_part_list),
      part_list: new TagModel(partList, data.part_list),
      my_car_type: new TagModel(masterMyCarTypeList, data.my_car_type),
      tag_list: new TagModel(relevantTagList, data.tag_list),
      valid_flag: data.valid_flag ? 1 : 0,
      ob_flag: data.ob_flag === 1,
      rank: data.rank,
    });
  }

  /** 一覧取得 */
  static listParam(param: {
    sortState: CustomerSortState;
    isMap?: boolean;
    mapAreaPos?: MapAreaPosition | null;
    isSp?: boolean;
    searchBox?: boolean;
    isMapList?: boolean;
    isDialog?: boolean;
  }): ApiCustomerGetListParam {
    const {
      sortState, isMap, mapAreaPos, isSp, searchBox, isMapList,
    } = param;
    const sort = cloneDeep(sortState);
    const weddingStart = sort.wedding_anniversary_start
      ? DateFormatter.date2str(sort.wedding_anniversary_start, 'YYYYMM', '-').split('-')
      : null;
    const weddingEnd = sort.wedding_anniversary_end
      ? DateFormatter.date2str(sort.wedding_anniversary_end, 'YYYYMM', '-').split('-')
      : null;

    const buildingStart = cloneDeep(sort.building_age_start);
    const buildingEnd = cloneDeep(sort.building_age_end);

    sort.building_age_start = null;
    sort.building_age_end = null;
    sort.wedding_anniversary_start = null;
    sort.wedding_anniversary_end = null;
    let sort_by = sort.sort_by + 1;
    if (sort_by === 15) sort_by = 21;

    return {
      ...sort,
      rank: sort.rank ? String(sort.rank) : undefined,
      post_no: `${sort.post_no[0]}${sort.post_no[1]}`,
      wedding_anniversary_start_year: weddingStart?.[0],
      wedding_anniversary_start_month: weddingStart?.[1],
      wedding_anniversary_end_year: weddingEnd?.[0],
      wedding_anniversary_end_month: weddingEnd?.[1],
      construction_info_start: DateFormatter.date2str(buildingStart, 'YYYYMM', '-'),
      construction_info_end: DateFormatter.date2str(buildingEnd, 'YYYYMM', '-'),
      tags: sort?.tags?.getSendData(),
      parts: sort?.parts?.getSendData(),
      input_comp_flag: sort?.input_comp_flag ? 0 : undefined,
      limit: isMap ? 99999 : sort.limit,
      north_lat: isMap && !isMapList && mapAreaPos?.n.lat ? String(mapAreaPos?.n.lat) : undefined,
      north_lng: isMap && !isMapList && mapAreaPos?.n.lng ? String(mapAreaPos?.n.lng) : undefined,
      south_lat: isMap && !isMapList && mapAreaPos?.s.lat ? String(mapAreaPos?.s.lat) : undefined,
      south_lng: isMap && !isMapList && mapAreaPos?.s.lng ? String(mapAreaPos?.s.lng) : undefined,
      completion_date_start: DateFormatter.date2str(sort.completion_date_start, 'YYYYMM', '-'),
      completion_date_end: DateFormatter.date2str(sort.completion_date_end, 'YYYYMM', '-'),
      last_completion_date_start: DateFormatter.date2str(sort.last_completion_date_start, 'YYYYMM', '-'),
      last_completion_date_end: DateFormatter.date2str(sort.last_completion_date_end, 'YYYYMM', '-'),
      total_work_price_min: String(deleteCommaStr(sort.total_work_price_min)),
      total_work_price_max: String(deleteCommaStr(sort.total_work_price_max)),
      work_times_min: String(deleteCommaStr(sort.work_times_min)),
      work_times_max: String(deleteCommaStr(sort.work_times_max)),
      created_at_start: DateFormatter.date2str(sort.created_at_start, 'YYYYMMDD', '-'),
      created_at_end: DateFormatter.date2str(sort.created_at_end, 'YYYYMMDD', '-'),
      updated_at_start: DateFormatter.date2str(sort.updated_at_start, 'YYYYMMDD', '-'),
      updated_at_end: DateFormatter.date2str(sort.updated_at_end, 'YYYYMMDD', '-'),
      valid_flag: 1,
      sort_by,
    };
  }

  /** 保存 */
  static postParam(param: {
    customer: CustomerEditState;
    id?: number;
    data?: {
      position: globalThis.google.maps.LatLngLiteral;
      address: string;
    },
    petListModel: PetListModel;
    familyListModel: FamilyListModel;
  }): ApiCustomerPostParam {
    const {
      customer: c, id, data, petListModel, familyListModel,
    } = param;
    let carFlag = false;
    let tagFlag = false;

    c.tag_list?.data.forEach((v) => {
      if (v.id === 100 && v.flag) tagFlag = true;
    });
    c.my_car_type?.data.forEach((v) => {
      if (v.id === 100 && v.flag) carFlag = true;
    });
    console.log(c.my_car_type?.getSendData());

    console.log(['[]']);

    return {
      data: {
        name: c.name,
        keisho: c.keisho,
        name_short: c.name_short,
        furigana: c.furigana,
        tel_no: c.tel_no,
        tel_no2: c.tel_no2,
        tel_no3: c.tel_no3,
        fax_no: c.fax_no,
        jisx0402_code: c.jisx0402_code,
        prefecture: c.prefecture,
        jisx0401_code: c.prefecture
          ? plus0(String(c.prefecture))
          : undefined,
        city: c.city,
        address: c.address,
        building_name: c.building_name,
        mail_address: c.mail_address,
        mail_address2: c.mail_address2,
        mail_address3: c.mail_address3,
        area_id: c.area_id,
        store_id: c.store_id,
        employee_id: c.employee_id,
        building_category_id: c.building_category_id,
        madori_id: c.madori_id,
        estimated_rank: c.estimated_rank,
        remarks: c.remarks,
        upd_employee_name: c.upd_employee_name,
        introducer: c.introducer,
        source_id: c.source_id,
        line_id: c.line_id,
        facebook_id: c.facebook_id,
        twitter_id: c.twitter_id,
        instagram_id: c.instagram_id,
        memo1: c.memo1,
        memo2: c.memo2,
        ...petListModel.format(),
        ...familyListModel.format(),
        ob_flag: c.ob_flag ? 1 : 2,
        rank: c.rank,
        post_no: `${c.post_no[0]}${c.post_no[1]}`,
        tag_list: c?.tag_list?.getSendData().length ? c?.tag_list?.getSendData() : [''],
        tag_list_other: tagFlag ? c?.tag_list_other : '',
        part_list: c?.part_list?.getSendData(),
        my_car_type: c?.my_car_type?.getSendData().length ? c?.my_car_type?.getSendData() : [''],
        expected_part_list: c?.expected_part_list?.getSendData(),
        building_age: c.building_age || 0,
        wedding_anniversary: DateFormatter.date2str(c.wedding_anniversary, 'YYYYMMDD', '-'),
        lat: data?.position.lat ? String(data?.position.lat) : c.lat,
        lng: data?.position.lng ? String(data?.position.lng) : c.lng,
        construction_info: DateFormatter.date2str(c.construction_info, 'YYYYMM', '-'),
        my_car_type_other: carFlag ? c.my_car_type_other : '',
        valid_flag: 1,
      },
      id,
    };
  }

  static registrationMap(
    param: { address: Address; },
  ): Partial<CustomerEditState> {
    const { address } = param;
    const { components } = address;
    return ({
      post_no: [components.postal1, components.postal2],
      prefecture: components.prefecture,
      city: components.city,
      address: components.address,
      building_name: components.bill,
    });
  }

  static searchAddress(param: {
    customer: CustomerEditState, address: AddressSearch;
  }): CustomerEditState {
    const { customer, address } = param;
    return ({
      ...customer,
      prefecture: Number(address.prefcode),
      city: address.ja.address1,
      address: address.ja.address2,
      building_name: '',
    });
  }
}
