export class MasterSortByModel {
  static store = (clientSort?: number) => {
    if ((clientSort ?? 0) < 2) return (clientSort ?? 0);
    return (clientSort ?? 0) + 1;
  }

  static employee = (clientSort?: number) => {
    const serverSort = [2, 3, 5, 6, 8, 9, 10];
    const tmp = clientSort ?? 0;
    const find = serverSort[tmp - 1];
    if (find) return find;
    return tmp;
  }
}
