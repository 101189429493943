import actionCreatorFactory from 'typescript-fsa';
import { ApiBase } from '../../../../service/api-base';
import { MasterCustomerRank } from '../../../../type/master/master-customer-rank.type';

export const ActionCreator = actionCreatorFactory('master/api/customer-rank');

export const apiMasterCustomerRank = {
  getList: ActionCreator<{callback?:(param: MasterCustomerRank[])=>void}>('customer/rank'),
};
export class ApiMasterCustomerRankGetList extends ApiBase {
  constructor() {
    super({
      httpMethod: 'GET',
      url: '/api/master/rank/customer',
    });
  }
}
