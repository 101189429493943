import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { isEqual } from 'lodash';
import { CustomerListType } from '../../../../../type/customer/customer.type';
import './customer-info-window.pc.scss';
import { IconButton } from '../../../button/icon-button/icon-button';
import { Button } from '../../../button/button';
import { RoutingPath } from '../../../../../routes/routing-pass';
import { DialogActions } from '../../../../../redux/dialog/dialog.action';
import { MapActions } from '../../../../../redux/map/map.action';
import { RectLabel } from '../../../label/rect-label/rect-label';
import obIcon from '../../../../../asset/images/icon_customer_ob.svg';
import mikomiIcon from '../../../../../asset/images/icon_customer_uncontracted.svg';
import { ShowTypeLabel } from '../../../label/show-type/show-type-label';
import { joinStr } from '../../../../../utilities/join-str';
import { ProjectAdd } from '../../../../pc/pages/project/add/project-add';
import { StreetViewImg } from '../../../street-view-img/street-view-img';
import { SupportHistoryEditPC } from '../../../../pc/pages/support-history/edit/support-history-edit.pc';
import { RouteDialog } from '../../../../dialogs/route-dialog/route-dialog';
import { State } from '../../../../../redux/root.reducer';
import { useAppSelector } from '../../../../../hooks/use-redux';
import { useMailContact, useTelContact } from '../../../../../hooks/support-history/use-support-history';
import { useCustomerView } from '../../../../../hooks/use-authority';
import { Config } from '../../../../../config/config';

type CustomerInfoWindowProps = {
  customer: CustomerListType;
  callbackRegist?: (v: CustomerListType) => void;
  callbackClose: () => void;
  /* FIXME 仮 */
  index: number;
  label?: string;
  isRoute?: boolean,
};

export const CustomerInfoWindowPC = (props: CustomerInfoWindowProps) => {
  const {
    customer, callbackRegist, callbackClose, index, label, isRoute,
  } = props;
  const {
    authority1: _authority1,
    store_id,
    id: employee_id,
  } = useSelector((state: State) => (state.user), isEqual);
  const routeAuth = useAppSelector((v) => v.user.company_authority3);
  const humanPos = useAppSelector((v) => v.map.humanPos);
  const user = useAppSelector((v) => v.user);
  const authority1 = useMemo(() => Boolean(_authority1) || (
    employee_id === customer.employee_id),
  // String(store_id) === customer.sales_shop
  // && String(employee_id) === customer.employee_id),
  [_authority1, store_id, employee_id, customer.employee_id]);

  const customerEstimatedRankList = useAppSelector((v) => v.master.customerEstimatedRankList);
  const streetViewAuth = useCustomerView();

  const dispatch = useDispatch();
  const telContact = useTelContact();
  const emailContact = useMailContact();

  const handleClickMail = useCallback(() => {
    if (!customer) return;
    emailContact({
      type: 'customer',
      data: customer,
      isPushDialog: true,
      mailAddress: customer.mail_address,
    });
  }, [customer]);

  const handleClickDetail = useCallback((e:
    React.MouseEvent<HTMLButtonElement, MouseEvent>, isDouble?: boolean) => {
    const url = `${RoutingPath.customerDetail}/${customer.id}`;
    if (e.ctrlKey) {
      if (isDouble) {
        window.open(`${Config.host}/#${url}`);
      }
    } else if (Config.isMac && e.altKey) {
      window.open(`${Config.host}/#${url}`);
    } else {
      dispatch(push(url));
    }
  }, [customer.id]);

  const handleClickProject = useCallback(() => {
    dispatch(DialogActions.push({
      title: '案件登録',
      className: 'max_height_dialog',
      element: <ProjectAdd customerData={customer} />,
    }));
  }, [customer]);

  const handleClickSupportHistory = useCallback(() => {
    if (callbackRegist) {
      callbackRegist(customer);
      return;
    }
    dispatch(DialogActions.push({
      title: '対応履歴新規登録',
      className: 'support_history',
      element: <SupportHistoryEditPC
        customer={customer}
      />,
    }));
  }, [callbackRegist, customer]);

  const handleClickHere = useCallback(() => {
    if (!routeAuth) return;
    dispatch(MapActions.setGpsStatus('out'));
    dispatch(DialogActions.push({
      title: 'ルート設定',
      element: <RouteDialog
        type="customer"
        destination={`${customer.prefecture_name}${customer.city}${customer.address}`}
        callback={() => { }}
      />,
    }));
  }, [customer, routeAuth]);

  const typeName = customer.ob_flag && (customer.ob_flag === 1 ? 'OB' : '見込み');

  const rankData = useMemo(() => (
    customerEstimatedRankList.find((v) => Number(v.id) === Number(customer.estimated_rank))
  ), [customerEstimatedRankList, customer.estimated_rank]);

  return (
    <div className="card">
      <div className="name">
        {customer.name}{/* <span>（{customer.furigana || '---'}）</span>*/}
      </div>
      <div className="card_base_row">
        <div className="col">
          <div className="address_box">
            <i className="fas fa-map-marker-alt" title="住所" />
            <div>
              <div className="post_no">
                〒{joinStr(customer.post_no, 3, '-')}
                {!isRoute && (
                <IconButton
                  title="ルートを表示する"
                  fontAwesomeClass="fas fa-route"
                  className="secondary"
                  disabled={!routeAuth}
                  onClick={handleClickHere}
                />
                )}
              </div>
              <div className="address">{customer.prefecture_name || ''}{customer.city || ''}{customer.address || ''}</div>
            </div>
          </div>
          <div className="tell"><i className="fas fa-phone" title="TEL" />{customer.tel_no || '---'}</div>
          <div className="mail_address"><i className="fas fa-envelope" title="メールアドレス" /><span> {customer.mail_address || '---'}</span>
            <IconButton
              title="メールを送る"
              fontAwesomeClass="fas fa-envelope"
              className="secondary"
              onClick={handleClickMail}
              disabled={
                Boolean(!customer.mail_address)
                || (user.view_data && user.view_data.company_id !== 1)
              }
            />
          </div>
          <div className="sales_contact"><i className="fas fa-user-circle" title="担当者" />{customer.employee_name || '---'}</div>
        </div>
        <div className="label_box">
          <ShowTypeLabel
            label={customer.ob_flag === 1 ? 'OB' : '見込み'}
            showTypeImg={typeName === 'OB' ? obIcon : mikomiIcon}
            className={typeName === 'OB' ? 'ob' : 'mikomi'}
          />
          <RectLabel
            label={customer.estimated_rank_name || 'ランクなし'}
            bgColor={rankData?.background_color}
            color={rankData?.text_color}
          />
          <div className="google">
            <StreetViewImg
              isShow={streetViewAuth(index)}
              lat={customer.lat}
              lng={customer.lng}
            />
          </div>
        </div>
      </div>
      <div className="btn_box">
        {!callbackRegist
          && (
            <>
              <Button
                className="sm primary"
                onClick={(e) => handleClickDetail(e)}
                onDoubleClick={(e) => handleClickDetail(e, true)}
              >顧客詳細
              </Button>
              <Button
                className="sm primary"
                onClick={() => handleClickProject()}
                disabled={!authority1}
              >案件登録
              </Button>
            </>
          )}
        <Button
          className="sm primary"
          onClick={handleClickSupportHistory}
        >{label || '対応登録'}
        </Button>
      </div>
      <IconButton
        title="閉じる"
        fontAwesomeClass="fas fa-times"
        className="default close"
        onClick={callbackClose}
      />
    </div>
  );
};
