/* eslint-disable no-nested-ternary */
import { useCallback, useState } from 'react';
// import { useSelector } from 'react-redux';
// import { State } from '../../../../../../redux/root.reducer';
// import { PetInfoCard } from '../../../../../ui/card/family-info/pet-info-card';
import { Table } from '../../../../../ui/table/table';
import { CustomerCollection } from '../../../../../../collection/customer/customer.collection';

type Props = {
  list: any[];
  callback: (id: number) => void;
  callbackSort?: (order: number, index: number) => void;
  // isInDialog?: boolean;
}

export const PetInfoListSP = (props: Props) => {
  const {
    list: petList,
    callback,
    callbackSort,
    // isInDialog,
  } = props;

  /* Hooks */
  // const { petList } = useSelector((state: State) => ({
  //   petList: state.customer.petList,
  // }));

  const [isActive, setIsActive] = useState(NaN);

  const handleClickCard = useCallback(
    (petId: number) => {
      callback(petId);
      setIsActive(petId);
      // console.log(isInDialog);
    },
    [petList],
  );

  return (
    // <div className="list_base family_info_list_sp">
    //   {petList.map((v, i) => (
    //     <div
    //       key={`pcard${i}`}
    //       className="list_base_card_wrap"
    //       onClick={() => {
    //         if (isInDialog) {
    //           handleClickCard(v.pet_id);
    //         }
    //       }}
    //     >
    //       <PetInfoCard
    //         petInfo={v}
    //         isActive={v.pet_id === isActive}
    //       />
    //     </div>
    //   ))}
    // </div>
    <section className="list_area">
      <div className="inner">
        <div className="table_responsive">
          <Table
            className="table_selectable table_sticky"
            header={CustomerCollection.petHeader}
            rowDataList={petList}
            selectedTr={[isActive]}
            onClickRow={(v) => { handleClickCard(Number(v.index)); }}
            sort={{
              index: [],
              onClick: (order, index) => {
                if (callbackSort)callbackSort(order, index);
              },
            }}
            lists={petList.map((v) => (
              [
                v.name,
                v.type,
                v.sex !== 1
                  ? v.sex === 2
                    ? 'オス'
                    : 'メス'
                  : '指定なし',
                v.age || v.age === 0 ? v.age : '',
              ]
            ))}
            option={{
              stringWidth: [
                { index: 2, width: 50 }, // 続柄
                { index: 3, width: 50 }, // 生年月日
              ],
              tdAlign: [
                { index: 0, align: 'center' },
                { index: 1, align: 'center' },
                { index: 2, align: 'center' },
                { index: 3, align: 'center' },
              ],
            }}
          />
        </div>
      </div>
    </section>

  );
};
