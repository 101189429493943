import { PdfModule } from '../pdf-module';
import template from '../template/print_estimate_1.svg';
import { HAGIWARA_LOGO_PNG } from '../template/hagiwara_logo';

const replaceData = [
  { from: '%%printEstimate1_1_%%', to: 'name' },
  { from: '%%printEstimate1_2_%%', to: 'no' },
  { from: '%%printEstimate1_3_%%', to: 'date' },
  { from: '%%printEstimate1_4_%%', to: 'price' },
  { from: '%%printEstimate1_5_%%', to: 'excludingTax' },
  { from: '%%printEstimate1_6_%%', to: 'consumptionTax' },
  { from: '%%printEstimate1_7_%%', to: 'projectName' },
  { from: '%%printEstimate1_8_%%', to: 'field1' },
  { from: '%%printEstimate1_9_%%', to: 'field2' },
  { from: '%%printEstimate1_10_%%', to: 'customerTel' },
  { from: '%%printEstimate1_11_%%', to: 'expirationDate' },
  { from: '%%printEstimate1_12_%%', to: 'remarks' },
  { from: '%%printEstimate1_30_%%', to: 'icon' },
  { from: '%%printEstimate1_31_%%', to: 'company' },
  { from: '%%printEstimate1_32_%%', to: 'company2' },
  { from: '%%printEstimate1_33_%%', to: 'post' },
  { from: '%%printEstimate1_34_%%', to: 'address' },
  { from: '%%printEstimate1_35_%%', to: 'tel' },
  { from: '%%printEstimate1_36_%%', to: 'fax' },
  { from: '%%printEstimate1_37_%%', to: 'freeDial' },
  { from: '%%printEstimate1_38_%%', to: 'directorName' },
  { from: '%%printEstimate1_39_%%', to: 'nameCharge' },
  { from: '%%printEstimate1_40_%%', to: 'iconTransform' },
  { from: '%%printEstimate1_41_%%', to: 'field3' },
  { from: '%%printEstimate1_42_%%', to: 'adjustName' },
  { from: '%%printEstimate1_43_%%', to: 'adjustProjectName' },
];

export type EstimateParam = {
  // 顧客名
  name: string,
  // 顧客名サイズ調整
  adjustName?: number,
  // No.
  no: string,
  // 日付
  date: string,
  // 金額
  price: string,
  // 税抜き請求金額
  excludingTax: string,
  // 消費税額
  consumptionTax: string,
  // 工事名称
  projectName: string,
  // 工事場所1
  field1: string,
  // 工事場所2
  field2: string,
  // 備考
  remarks: string,
  // 工事電話番号
  customerTel: string,
  // 見積有効期限
  expirationDate: string,
  // アイコン
  icon: string,
  /** ロゴ画像の縦幅 */
  iconHeight: number,
  /** ロゴ画像の横幅 */
  iconWidth: number,
  /** ロゴ画像のスケール、位置調整 */
  iconTransform: string,
  // 会社名
  company: string,
  // 会社名2
  company2: string,
  // 郵便番号
  post: string,
  // 住所
  address: string,
  // 電話番号
  tel: string,
  // FAX
  fax: string,
  // フリーダイヤル
  freeDial: string,
  // 代表取締役名
  directorName: string,
  // 担当者名
  nameCharge: string,
};

const defaultProps = {
  // 顧客名
  name: '東京 新橋',
  // 顧客名サイズ調整
  adjustName: undefined,
  // No.
  no: '0000000',
  // 日付
  date: '2021年3月12日',
  // 金額
  price: '200000',
  // 税抜き請求金額
  excludingTax: '180000',
  // 消費税額
  consumptionTax: '20000',
  // 工事名称
  projectName: '東京都戸建建設',
  // 工事場所1
  field1: '東京都江東区',
  // 工事場所2
  field2: '墨田区東京町2000-1',
  // 備考
  remarks: 'テストにつき無効データ',
  // 工事電話番号
  customerTel: '000-0000-0000',
  // 見積有効期限
  expirationDate: '2021年3月12日',
  // アイコン
  icon: HAGIWARA_LOGO_PNG,
  /** ロゴ画像の縦幅 */
  iconHeight: 0,
  /** ロゴ画像の横幅 */
  iconWidth: 0,
  /** ロゴ画像のスケール、位置調整 */
  iconTransform: '',
  // 会社名
  company: '東京新橋株会社',
  // 会社名2
  company2: '東京支店',
  // 郵便番号
  post: '000-0000',
  // 住所
  address: '東京都江東区',
  // 電話番号
  tel: '墨田区東京町2000-1',
  // FAX
  fax: '000-0000-0000',
  // フリーダイヤル
  freeDial: '000-0000-0000',
  // 代表取締役名
  directorName: '東京 新橋',
  // 担当者名
  nameCharge: '東京 新橋',
  field3: 'TEST',
};
/**
 * 見積書
 */
export class EstimatePdfModule extends PdfModule<EstimateParam> {
  constructor() {
    super(
      [
        template,
      ],
      replaceData,
    );
  }

  createSvg(
    _param: EstimateParam,
    templateSvgStrList: string[] = this.templateSvgStrCollection,
  ): string[] {
    // const maxPage = Math.ceil(param.buildingItems.length / maxLength);
    const { iconHeight, iconWidth } = _param;
    const param:EstimateParam = {
      ..._param,
      iconTransform: this.getLogoTransform({ height: iconHeight, width: iconWidth }),
    };
    // 1ページ分しか出力しない
    const maxPage = 1;
    const templateSvgStr = templateSvgStrList[0] || '';
    const replacedTemplateSvgStrList = [...new Array(maxPage)].fill(templateSvgStr)
      .map((page: string) => page);
    return super.createSvg(param, replacedTemplateSvgStrList);
  }

  async createSvgList(param: EstimateParam = defaultProps): Promise<string[]> {
    super.createSvgList(param).then((v) => console.log(v));
    return super.createSvgList(param);
  }
}
