import React, {
  useCallback, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as lodash from 'lodash';
import { cloneDeep } from 'lodash';
import { useInView } from 'react-intersection-observer';
import { State } from '../../../../../../../redux/root.reducer';
import { Button } from '../../../../../../ui/button/button';
import { LeftIconButton } from '../../../../../../ui/button/left-icon-button/left-icon-button';
import { MapBase } from '../../../../../../ui/map/map-base';
import { MapActions } from '../../../../../../../redux/map/map.action';
import { DialogActions } from '../../../../../../../redux/dialog/dialog.action';
import { ProjectListType } from '../../../../../../../type/project/project.type';
import { ProjectCard } from '../../../../../../ui/card/project/project-card';
import { InputField } from '../../../../../../ui/input-field/input-field';
import './project-map-list.scss';
import { ProjectAdd } from '../../../../../pages/project/add/project-add';
import { useDidMount, useWillUnMount } from '../../../../../../../hooks/life-cycle';
import { RouteDialog } from '../../../../../../dialogs/route-dialog/route-dialog';
import { useAppSelector } from '../../../../../../../hooks/use-redux';
import { CardList } from '../../../../../../ui/card/card-list/card-list';
import { Limit, TableSort } from '../../../../../../ui/table/table-sort/table-sort';
import { MapAreaPosition } from '../../../../../../../type/map/map.type';
import { MapCollection } from '../../../../../../../collection/map/map.collection';
import { ProjectSearchMode, ProjectModel } from '../../../../../../../model/project/project.model';

type Props = {
  type: 0 | 1 | 2 | 3;
  list: ProjectListType[] | null;
  page: number;
  isSearch: boolean;
  hitCount: number;
  onClickCard: () => void;
  // callback: (type: 0 | 1 | 2 | 3) => void;
  callback: (mode: ProjectSearchMode) => void;
  callbackGetList: () => void;
  callbackPagination: (offset: number) => void;
  callbackZoomEnd: (mapPos: MapAreaPosition) => void;
  callbackCenterChanged: (mapPos: MapAreaPosition) => void;
  onGetGps?: (onGps: boolean) => void;
}

export const ProjectMapListPC = (props: Props) => {
  const {
    type,
    hitCount,
    isSearch,
    list,
    page,
    onClickCard,
    callback,
    callbackGetList,
    callbackPagination,
    callbackZoomEnd,
    callbackCenterChanged,
    onGetGps,
  } = props;

  const { ref, inView } = useInView({
    // オプション
    rootMargin: '-50px', // ref要素が現れてから50px過ぎたら
    triggerOnce: true, // 最初の一度だけ実行
  });
  /* Hooks */
  const routeAuth = useAppSelector((v) => v.user.company_authority3);
  const humanPos = useSelector((state: State) => state.map.humanPos);
  const routeInfo = useSelector((state: State) => state.map.routeInfo);
  const sortState = useAppSelector((v) => v.project.sort);
  const dispatch = useDispatch();

  /* State */
  const [project, setProject] = useState<ProjectListType | null>(null);
  const [projectIndex, setProjectIndex] = useState(NaN);
  const [searchValue, setSearchValue] = useState('');
  const [searchValue2, setSearchValue2] = useState('');
  const [active, setActive] = useState(NaN);

  /* List */
  const projectList = !type ? list : list?.filter(
    (v) => v.construction_flag === type,
  );

  /* Callback */
  const handleClickCard = useCallback((v: ProjectListType) => {
    if (v.lat !== null && v.lng !== null) {
      dispatch(MapActions.setCenterPos({ lat: Number(v.lat), lng: Number(v.lng) }));
      dispatch(MapActions.setZoomLevel(MapCollection.clusterMaxZoom + 1));
      onClickCard();
      setActive(v.id);
    }
    setProject(lodash.cloneDeep(v));
  }, [onClickCard]);

  const handleClickSearch = useCallback(
    (inMap?: boolean) => {
      dispatch(MapActions.setGpsStatus('out'));
      dispatch(MapActions.api.geocoder({
        param: { param: { address: inMap ? searchValue2 : searchValue } },
        callback: () => {
          dispatch(DialogActions.pop());
          dispatch(MapActions.setZoomLevel(null));
        },
      }));
    },
    [searchValue, searchValue2],
  );

  const handleClickRouteSearch = useCallback(() => {
    if (!routeAuth) return;
    dispatch(MapActions.setGpsStatus('out'));
    dispatch(DialogActions.push({
      title: 'ルート設定',
      element: <RouteDialog
        type="project"
        destination={searchValue2}
        callback={() => { }}
      />,
    }));
  }, [searchValue2, routeAuth]);

  const handleClickRegistration = useCallback(() => {
    dispatch(DialogActions.push({
      title: '案件登録',
      className: 'max_height_dialog',
      element: <ProjectAdd
        callback={callbackGetList}
      />,
    }));
  }, [callbackGetList]);

  const checkMode = useCallback((mode: ProjectSearchMode) => ProjectModel.CheckMode(
    mode, sortState.construction_status,
  ), [sortState.construction_status]);
  /* Effect */
  useDidMount(() => {
    if (humanPos) {
      dispatch(MapActions.setCenterPos(cloneDeep(humanPos)));
    }
  });

  useWillUnMount(() => {
    dispatch(MapActions.setRouteInfo(null));
  });

  return (
    <section className="result_area">
      <div className="inner">
        <div className="list_wrap">
          {/* リスト側の場所検索は使用しないため非表示 */}
          <div className="search_box item_wrap display_none">
            <InputField
              onEnterKeyPress={handleClickSearch}
              labelPlace="left"
              className="item_box"
              label="住所または場所を検索"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
            <LeftIconButton
              label="住所検索"
              fontAwesomeClass="fas fa-search"
              className="btn_search for_detail"
              size="sm"
              color="secondary"
              onClick={() => handleClickSearch()}
            />
          </div>
          <div className="list_box_sort">
            <Button
              className={`md primary project_all ${checkMode('All') ? 'active' : ''}`}
              onClick={() => callback('All')}
            >すべて
            </Button>
            <Button
              className={`md primary project_uncontracted ${checkMode('No') ? 'active' : ''}`}
              onClick={() => callback('No')}
            >未契約
            </Button>
            <Button
              className={`"md primary project_construction ${checkMode('Construction') ? 'active' : ''}`}
              onClick={() => callback('Construction')}
            >工事中
            </Button>
            <Button
              className={`md primary project_completion ${checkMode('Complete') ? 'active' : ''}`}
              onClick={() => callback('Complete')}
            >完工
            </Button>
            <Button
              className="md primary edit"
              onClick={handleClickRegistration}
            ><>案件<br />新規登録</>
            </Button>
          </div>
          {isSearch
          && (
          <TableSort
            isSp
            page={page}
            limit={100}
            hitCount={hitCount}
            callback={callbackPagination}
          />
          )}
          <CardList isLoading={inView} list={projectList}>
            {projectList?.map((v, i) => (
              <div
                key={`card${i}`}
                className={`card_wrapper ${active === v.id ? 'active' : ''}`}
                ref={projectList.length - 1 ? ref : undefined}
              >
                <ProjectCard
                  className="list_card"
                  projectData={v}
                  onClick={() => {
                    handleClickCard(v);
                    setProjectIndex(i);
                  }}
                  index={i}
                />
              </div>
            ))}
          </CardList>
        </div>
        <div className="map_area">
          <MapBase
            projectOption={{
              type,
              selectInfo: project,
              selectIndex: projectIndex,
              callbackActiveMarker: setActive,
            }}
            searchOption={{}}
            isNowPoint
            callbackZoomEnd={callbackZoomEnd}
            callbackChangePosEnd={callbackCenterChanged}
            callbackGetGps={onGetGps}
          />
          <div className="map_search_box">
            <div className="search_box item_wrap">
              <InputField
                onEnterKeyPress={() => handleClickSearch(true)}
                // labelPlace="left"
                // label="場所を検索"
                className="item_box"
                placeholder="住所または場所を検索"
                value={searchValue2}
                onChange={(e) => setSearchValue2(e.target.value)}
              />
              <LeftIconButton
                label="住所検索"
                fontAwesomeClass="fas fa-search"
                className="btn_search"
                size="sm"
                color="secondary"
                onClick={() => handleClickSearch(true)}
              />
              <div className="root_btn_box">
                <LeftIconButton
                  label="ルート検索"
                  fontAwesomeClass="fas fa-route"
                  className="btn_search"
                  size="sm"
                  color="secondary"
                  onClick={handleClickRouteSearch}
                  disabled={!routeAuth}
                />
              </div>
              {routeInfo && (
                <Button
                  size="sm"
                  color="dark"
                  className="btn_search"
                  onClick={() => dispatch(MapActions.setRouteInfo(null))}
                >
                  ルート終了
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>

  );
};
