import './emplyee-master-edit-dialog.scss';
import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'lodash';
import { DialogActions } from '../../../../../../../redux/dialog/dialog.action';
import { MasterEmployeeEditDialogProps } from '../../../master.type';
import { EditPC } from '../../../../../../dialogs/edit/edit.pc';
import { MasterActions } from '../../../../../../../redux/master/master.action';
import { Input } from '../../../../../../ui/input/input';
import { RightLabelCheckbox } from '../../../../../../ui/checkbox/right-label-checkbox/right-label-checkbox';
import { Required } from '../../../../../../ui/required/required';
import { LeftLabelInputField } from '../../../../../../ui/input-field/left-label-input-field/left-label-input-field';
import { useDidMount, useWillUnMount } from '../../../../../../../hooks/life-cycle';
import { ValidationMailAddress } from '../../../../../../../model/validation/validation-mail-address';
import { ValidationNumberLengthUnder14 } from '../../../../../../../model/validation/validation-number-length-under';
import { MasterEmployeeValidation } from '../../../../../../../model/validation/master/master-employee.validation';
import { Select } from '../../../../../../ui/select/select';
import { State } from '../../../../../../../redux/root.reducer';
import { Message } from '../../../../../../../collection/message.collection';
import { InfoButton } from '../../../../../../ui/button/info-button/info-button';
import { UserActions } from '../../../../../../../redux/user/user.action';
import { ValidationPassword } from '../../../../../../../model/validation/validation-password';
import { InputNum } from '../../../../../../ui/input/input-num';
import { MathHelper } from '../../../../../../../utilities/math-helper';
import { ValidationLengthUnder255 } from '../../../../../../../model/validation';
import { useAppSelector } from '../../../../../../../hooks/use-redux';

export const EmployeeMasterEditDialog = (props: MasterEmployeeEditDialogProps) => {
  const {
    id, callback, companyId, isListClear,
  } = props;

  const dispatch = useDispatch();
  const storeList = useSelector((state:State) => state.master.storeList, isEqual);
  const dataList = useSelector((state: State) => (
    companyId
      ? state.master.contractedEmployeeList
      : state.master.employeeList
  ), isEqual);
  const user = useAppSelector((v) => v.user);

  const [newPassword, setNewPassword] = useState('');
  const [passwordCheck, setPasswordCheck] = useState('');
  const [store, setStore] = useState(NaN);
  const [name, setName] = useState('');
  const [shortName, setShortName] = useState('');
  const [furigana, setFurigana] = useState('');
  const [jobTitle, setJobTitle] = useState('');
  const [mailAddress, setMailAddress] = useState('');
  const [salesTarget, setSalesTarget] = useState(NaN);
  const [deleteFlag, setDeleteFlag] = useState(false);
  const [authority1, setAuthority1] = useState(false);
  const [authority4, setAuthority4] = useState(false);
  const [access_11, setAccess_11] = useState(false);
  const [role, setRole] = useState(false);

  const isEdit = useMemo(() => {
    if (id) {
      const data = dataList.find((v) => v.id === id);
      if (!data) return false;
      if (data.status !== 1) {
        return true;
      }
      return false;
    }
    return false;
  }, []);

  const [touch, setTouch] = useState(false);

  const handleClickPost = useCallback(() => {
    const message = MasterEmployeeValidation(
      newPassword,
      passwordCheck,
      store,
      name,
      shortName,
      furigana,
      jobTitle,
      mailAddress,
      String(salesTarget || ''),
    );
    const setCompanyId = (): number => {
      if (!user.view_data || user.view_data.company_id === 1) {
        return user.company_id;
      }
      return user.view_data.company_id;
    };

    if (message) {
      dispatch(DialogActions.pushMessage({
        title: '社員 編集／追加',
        message,
        callback: () => setTouch(true),
      }));
      return;
    }

    dispatch(!companyId
      ? MasterActions.api.employee.post({
        param: {
          data: {
            new_password: newPassword || undefined,
            confirm_password: passwordCheck || undefined,
            store_id: store,
            name,
            short_name: shortName,
            furigana,
            job_title: jobTitle,
            mail_address: mailAddress,
            sales_target: String(Number.isNaN(salesTarget) ? '' : salesTarget),
            is_valid: deleteFlag ? 0 : 1,
            authority1: role || authority1 ? 1 : 0,
            authority4: role || authority4 ? 1 : 0,
            access_11: (role && ((companyId ?? user.company_id) === 1)) || access_11 ? 1 : 0,
            company_id: setCompanyId(),
          },
          id,
        },
        onSuccess: () => {
          callback();
          dispatch(UserActions.getUser({
            callback: () => { },
          }));
        },
        onError: () => {
          setTouch(true);
        },
      })
      : MasterActions.api.contractedEmployee.post({
        param: {
          data: {
            new_password: newPassword || undefined,
            confirm_password: passwordCheck || undefined,
            store_id: store,
            name,
            short_name: shortName,
            furigana,
            job_title: jobTitle,
            mail_address: mailAddress,
            sales_target: String(Number.isNaN(salesTarget) ? '' : salesTarget),
            is_valid: deleteFlag ? 0 : 1,
            authority1: role || authority1 ? 1 : 0,
            authority4: role || authority4 ? 1 : 0,
            access_11: (role && ((companyId ?? user.company_id) === 1)) || access_11 ? 1 : 0,
            company_id: companyId,
          },
          id,
        },
        onSuccess: () => {
          callback();
          dispatch(UserActions.getUser({
            callback: () => {},
          }));
        },
        onError: () => {
          setTouch(true);
        },
      }));
  }, [
    newPassword,
    passwordCheck,
    store,
    name,
    shortName,
    user,
    companyId,
    furigana,
    jobTitle,
    mailAddress,
    salesTarget,
    deleteFlag,
    authority1,
    authority4,
    access_11,
    callback,
  ]);

  useDidMount(() => {
    if (id) {
      const data = dataList.find((v) => v.id === id);
      if (!data) return;
      setStore(data.store_id);
      setName(data.name);
      setShortName(data.short_name);
      setFurigana(data.furigana);
      setJobTitle(data.job_title);
      setMailAddress(data.mail_address);
      setSalesTarget(Number(data.sales_target || NaN));
      setDeleteFlag(!data.valid_flag);
      setAuthority1(data.authority1);
      setAuthority4(data.authority4);
      setAccess_11(data.access_11);
      setRole(data.role);
    }
    if (isListClear) {
      dispatch(MasterActions.api.store.getList({ company_id: companyId }));
    }
  });

  useWillUnMount(() => {
    if (isListClear) {
      dispatch(MasterActions.setStoreList([]));
    }
  });

  return (
    <EditPC mode="dialog" callback={handleClickPost} label={id ? '更新' : '登録'}>
      <div className="edit_pc_body_inner edit_master">
        <div className="left_box">
          {/*
          <div className="item_wrap">
            <div className="item_box">
              <div className="item_head">社員CD</div>
              <Input
                className=""
                errorPosBottom
                value={employeeCode}
                onChange={(e) => setEmployeeCode(e.target.value)}
                validationList={[
                  ...ValidationLengthUnder8,
                  ...ValidationEisuzi,
                ]}
                touch={touch}
              />
            </div>
          </div>
          */}
          <div className="item_wrap">
            <div className="item_box">
              <div className="item_head">店舗<Required /></div>
              <Select
                className=""
                value={store}
                onChange={(v) => setStore(Number(v))}
                defaultLabel="指定無し"
                options={storeList.map((v) => ({
                  text: v.name, value: v.id,
                }))}
                require
                touch={touch}
                errorPosBottom
              />
            </div>
          </div>
          <div className="item_wrap">
            <div className="item_box">
              <div className="item_head">社員_名称<Required /></div>
              <Input
                className=""
                value={name}
                onChange={(e) => setName(e.target.value)}
                validationList={ValidationLengthUnder255}
                require
                touch={touch}
              />
              {role ? <label className="strong base_color">（管理者）</label> : null}
            </div>
          </div>
          {/*
          <div className="item_wrap">
            <div className="item_box">
              <div className="item_head">社員_略称</div>
              <Input
                className=""
                value={shortName}
                onChange={(e) => setShortName(e.target.value)}
                validationList={ValidationLengthUnder255}
              />
            </div>
          </div>
          */}
          <div className="item_wrap">
            <div className="item_box">
              <div className="item_head">社員_フリガナ</div>
              <Input
                className=""
                value={furigana}
                onChange={(e) => setFurigana(e.target.value)}
                validationList={ValidationLengthUnder255}
              />
            </div>
          </div>
          <div className="item_wrap">
            <div className="item_box">
              <div className="item_head">役職</div>
              <Input
                className=""
                value={jobTitle}
                onChange={(e) => setJobTitle(e.target.value)}
                validationList={ValidationLengthUnder255}
              />
            </div>
          </div>
          <div className="item_wrap">
            <div className="item_box max_width">
              <div className="item_head">メールアドレス<Required /></div>
              <Input
                className="large"
                value={mailAddress}
                type="email"
                onChange={(e) => setMailAddress(e.target.value)}
                validationList={ValidationMailAddress}
                require
                touch={touch}
              />
            </div>
          </div>
          {isEdit && (
          <>
            <div className="item_wrap">
              <div className="item_box">
                <div className="item_head">パスワード</div>
                <div className="password">
                  パスワードを変更する場合、入力してください。
                  <LeftLabelInputField
                    label="新しいパスワード"
                    type="password"
                    className="mt_10"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    validationList={ValidationPassword}
                  />
                  <LeftLabelInputField
                    label="パスワード確認"
                    type="password"
                    className="mt_10"
                    value={passwordCheck}
                    onChange={(e) => setPasswordCheck(e.target.value)}
                    validationList={ValidationPassword}
                  />
                </div>
              </div>
            </div>
          </>
          )}
          {/*
          <div className="item_wrap">
            <div className="item_box">
              <div className="item_head">売上目標</div>
              <InputNum
                className=""
                value={MathHelper.localStr(salesTarget)}
                onChange={(v) => setSalesTarget(MathHelper.localStrToNum(v))}
                validationList={ValidationNumberLengthUnder14}
                maxLength={14}
              />
            </div>
          </div>
          */}
          <div className="item_wrap">
            <div className="item_box">
              <div className="item_head">削除フラグ</div>
              <RightLabelCheckbox
                label=""
                className=""
                checked={deleteFlag}
                disabled={role || !isEdit}
                onChange={() => setDeleteFlag(!deleteFlag)}
              />
            </div>
          </div>
        </div>
        <div className="right_box" style={{ minWidth: '260px' }}>
          <div className="frame">
            <div className="item_wrap">
              <RightLabelCheckbox
                label="担当外情報操作権限"
                className=""
                checked={authority1}
                disabled={role}
                onChange={() => setAuthority1(!authority1)}
              />
              <InfoButton title={Message.master.authKanri} />
            </div>
            {}
            <div className="item_wrap">
              <RightLabelCheckbox
                label="マスタ管理操作権限"
                className=""
                checked={authority4}
                disabled={role}
                onChange={() => setAuthority4(!authority4)}
              />
              <InfoButton title={Message.master.editKanri} />
            </div>
            {(user.company_id === 1 && (!user?.view_data || user?.view_data.company_id === 1)
            && (!companyId || (companyId === 1))) && (
              <div className="item_wrap">
                <RightLabelCheckbox
                  label="契約会社操作権限"
                  className=""
                  checked={access_11}
                  disabled={role}
                  onChange={() => setAccess_11(!access_11)}
                />
                <InfoButton title={Message.master.editKanri} />
              </div>
            )}
            {role ? (
              <div className="item_wrap alert mt_5 mb_0">
                管理者は変更できません
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </EditPC>
  );
};
