import { cloneDeep, isEqual } from 'lodash';
import { useState, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Select } from '../../../../../ui/select/select';
import { SearchBoxPC } from '../../../../layout/search-box/search-box.pc';
import { DatePicker } from '../../../../../ui/date-picker/date-picker';
import { RightLabelCheckbox } from '../../../../../ui/checkbox/right-label-checkbox/right-label-checkbox';
import { Radio } from '../../../../../ui/radio/radio';
import { LeftIconButton } from '../../../../../ui/button/left-icon-button/left-icon-button';
import { CsvActions } from '../../../../../../redux/csv/csv.action';
import { State } from '../../../../../../redux/root.reducer';
import { CsvMaintenanceSort } from '../../../../../../type/csv/csv-sort.type';
import { useDidMount } from '../../../../../../hooks/life-cycle';
import { MasterActions } from '../../../../../../redux/master/master.action';
import { Input } from '../../../../../ui/input/input';
import { pulldown } from '../../../../../../utilities/pulldown';
import { useDateValidation } from '../../../../../../hooks/use-date-validation';
import { DateTermValidation } from '../../../../../../model/validation/date/term-date.validation';

type Props = {
  callback: (v: CsvMaintenanceSort) => void;
}

export const CsvSearchBoxMaintenance = (props: Props) => {
  const { callback } = props;

  /* Hooks */
  const dispatch = useDispatch();
  const sortState = useSelector((state: State) => state.csv.maintenanceSort);
  const {
    storeList,
    employeeList,
  } = useSelector((state: State) => (state.master), isEqual);

  /* State */
  const [sort, setSort] = useState(cloneDeep(sortState));

  const _employeeList = useMemo(() => employeeList.filter((
    v,
  ) => (Number.isNaN(sort.project_store_id) || sort.project_store_id === null
    ? true
    : (v.store_id === sort.project_store_id))),
  [sort.project_store_id, employeeList]);

  const termDate = useDateValidation([
    {
      start: sort.completion_date_start || null,
      end: sort.completion_date_end || null,
    },
    {
      start: sort.maintenance_date_start || null,
      end: sort.maintenance_date_end || null,
    },
    { start: sort.created_at_start || null, end: sort.created_at_end || null },
    { start: sort.updated_at_start || null, end: sort.updated_at_end || null },

  ]);

  /* Callback */
  const setState = useCallback((v: Partial<CsvMaintenanceSort>) => {
    const data = cloneDeep({
      ...sort,
      ...v,
    });
    setSort(data);
  }, [sort]);

  const handleClickSearch = useCallback(() => {
    if (termDate.messages.length) {
      termDate.check();
      return;
    }
    dispatch(CsvActions.setMaintenanceSort({
      ...cloneDeep(sort),
      limit: sortState.limit,
    }));
    callback({
      ...cloneDeep(sort),
      limit: sortState.limit,
    });
  }, [callback, sort, termDate, sortState]);

  /* Master */
  useDidMount(() => {
    dispatch(MasterActions.api.store.getList({}));
    dispatch(MasterActions.api.employee.getList({}));
  });

  return (
    <>
      <SearchBoxPC openCallback={() => {}}>
        <div id="csv_maintenance">
          <div className="item_wrap">
            <div className="item_box">
              <div className="item_head">メンテナンス日</div>
              <DatePicker
                date={sort.maintenance_date_start}
                onChange={(v) => setState({ maintenance_date_start: v })}
                term={DateTermValidation([
                  {
                    start: sort.maintenance_date_start || null,
                    end: sort.maintenance_date_end || null,
                  },
                ])}
              />
              <label className="ml_10">〜</label>
              <DatePicker
                date={sort.maintenance_date_end}
                onChange={(v) => setState({ maintenance_date_end: v })}
                term={DateTermValidation([
                  {
                    start: sort.maintenance_date_start || null,
                    end: sort.maintenance_date_end || null,
                  },
                ])}
              />
            </div>
            <div className="item_box">
              <div className="item_head">案件営業担当</div>
              <Select
                className="add_text_left"
                label="店舗"
                value={sort?.project_store_id}
                onChange={(data) => setState({ project_store_id: Number(data) })}
                defaultLabel="指定無し"
                options={pulldown(storeList)}
              />
              <Select
                className="add_text_left"
                label="担当者"
                value={sort?.project_employee_id}
                onChange={(data) => setState({ project_employee_id: Number(data) })}
                defaultLabel="指定無し"
                options={pulldown(_employeeList)}
              />
            </div>
          </div>
          <div className="item_wrap">
            <div className="item_box">
              <div className="item_head">完工日</div>
              <DatePicker
                date={sort.completion_date_start}
                onChange={(v) => setState({ completion_date_start: v })}
                term={DateTermValidation([
                  {
                    start: sort.completion_date_start || null,
                    end: sort.completion_date_end || null,
                  },
                ])}
              />
              <label className="ml_10">〜</label>
              <DatePicker
                date={sort.completion_date_end}
                onChange={(v) => setState({ completion_date_end: v })}
                term={DateTermValidation([
                  {
                    start: sort.completion_date_start || null,
                    end: sort.completion_date_end || null,
                  },
                ])}
              />
            </div>
            <div className="item_box">
              <div className="item_head">案件名</div>
              <Input
                className=""
                value={sort?.project_name}
                onChange={(e) => setState({ project_name: e.target.value })}
              />
            </div>
            <div className="item_box">
              <RightLabelCheckbox
                checked={Boolean(sort?.is_muko)}
                label="無効情報も含む"
                onChange={() => setState({ is_muko: sort?.is_muko ? 0 : 1 })}
              />
            </div>
          </div>
          <div className="item_wrap">
            <div className="item_box">
              <div className="item_head">対応区分</div>
              <Radio
                name="kubun"
                label="全て"
                value="kubun3"
                checked={sort?.supported_kubun === 2}
                onChange={() => setState({ supported_kubun: 2 })}
              />
              <Radio
                name="kubun"
                label="未対応"
                value="kubun1"
                checked={sort?.supported_kubun === 0}
                onChange={() => setState({ supported_kubun: 0 })}
              />
              <Radio
                name="kubun"
                label="対応済"
                value="kubun2"
                checked={sort?.supported_kubun === 1}
                onChange={() => setState({ supported_kubun: 1 })}
              />
            </div>
            <div className="item_box">
              <div className="item_head">文字列検索</div>
              <Input
                className="mr_10"
                value={sort?.word}
                onChange={(e) => setState({ word: e.target.value })}
              />
              <span className="comment">&nbsp;※検索対象項目：タイトル、詳細内容、対応内容</span>
            </div>
          </div>
          <div className="item_wrap">
            <div className="item_box">
              <div className="item_head">登録者</div>
              <Select
                defaultLabel="全て"
                className="fixed"
                value={sort.created_employee_id}
                onChange={(v) => setState({ created_employee_id: Number(v) })}
                options={pulldown(employeeList)}
              />
            </div>
            <div className="item_box">
              <div className="item_head">登録日</div>
              <DatePicker
                date={sort.created_at_start || null}
                onChange={(v) => setState({ created_at_start: v })}
                type="YYYY/MM/DD"
                term={DateTermValidation([
                  { start: sort.created_at_start || null, end: sort.created_at_end || null },
                ])}
              />
              <label className="ml_10">～</label>
              <DatePicker
                date={sort.created_at_end || null}
                onChange={(v) => setState({ created_at_end: v })}
                type="YYYY/MM/DD"
                term={DateTermValidation([
                  { start: sort.created_at_start || null, end: sort.created_at_end || null },
                ])}
              />
            </div>
            <div className="item_box">
              <div className="item_head">更新者</div>
              <Select
                defaultLabel="全て"
                className="fixed"
                value={sort.updated_employee_id}
                onChange={(v) => setState({ updated_employee_id: Number(v) })}
                options={pulldown(employeeList)}
              />
            </div>
            <div className="item_box">
              <div className="item_head">更新日</div>
              <DatePicker
                date={sort.updated_at_start || null}
                onChange={(v) => setState({ updated_at_start: v })}
                type="YYYY/MM/DD"
                term={DateTermValidation([
                  { start: sort.updated_at_start || null, end: sort.updated_at_end || null },
                ])}
              />
              <label className="ml_10">～</label>
              <DatePicker
                date={sort.updated_at_end || null}
                onChange={(v) => setState({ updated_at_end: v })}
                type="YYYY/MM/DD"
                term={DateTermValidation([
                  { start: sort.updated_at_start || null, end: sort.updated_at_end || null },
                ])}
              />
            </div>
            <LeftIconButton
              label="検索"
              fontAwesomeClass="fas fa-search"
              className="btn_search for_detail"
              size="sm"
              color="secondary"
              onClick={handleClickSearch}
            />
          </div>
        </div>
      </SearchBoxPC>
    </>
  );
};
