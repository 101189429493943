import React, {
  useCallback, useEffect, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { Table } from '../../../../../ui/table/table';
import './csv-list-lost-order.scss';
import { State } from '../../../../../../redux/root.reducer';
import { ExportCsvCollection } from '../../../../../../collection/export-csv/export-csv.collection';
import { CsvLostOrderType } from '../../../../../../type/csv/csv.type';
import { CsvActions } from '../../../../../../redux/csv/csv.action';
import { ApiCsvLostOrderGetListResponse } from '../../../../../../redux/csv/api/lost-order/api-csv-lost-order';
import { useAppSelector } from '../../../../../../hooks/use-redux';
import { RoutingPath } from '../../../../../../routes/routing-pass';
import { useOpenWindow } from '../../../../../../hooks/use-open-window';
import { useEstimateAuthority } from '../../../../../../hooks/use-authority';

type Props = {
  callbackSelected: (selected: number[]) => void;
}
export const CsvListLostOrder = (props: Props) => {
  const { callbackSelected } = props;

  /* Hooks */
  const list = useSelector((state: State) => state.csv.lostOrderList);
  const estimateAuth = useEstimateAuthority();
  const rankList = useAppSelector((v) => v.master.customerRankList);
  const dispatch = useDispatch();
  const windowOpen = useOpenWindow();

  /* State */
  const [selected, setSelected] = useState<number[]>([]);

  /* Callback */
  const handleClick = useCallback((row: ApiCsvLostOrderGetListResponse) => {
    if (!list) return;
    setSelected([list.findIndex((v) => v.id === row.id)]);
  }, [list]);

  const handleClickHeader = useCallback(
    (highlow:0 | 1, sort_by: number) => {
      dispatch(CsvActions.setLostOrderSort({ highlow, sort_by }));
    }, [],
  );

  const handleDbClick = useCallback((
    row: ApiCsvLostOrderGetListResponse,
    e?: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
  ) => {
    if (!list || !e) return;
    setSelected([list.findIndex((v) => v.id === row.id)]);
    windowOpen({
      url: `${RoutingPath.projectDetail}/${row.id}`,
      e,
    });
  },
  [list]);

  useEffect(() => {
    callbackSelected(selected);
  }, [selected]);

  return (
    <section className="result_area list_area">
      <div className="inner">
        <div className="table_responsive">
          <Table
            className="table_selectable table_sortable table_sticky table_cell_change"
            header={ExportCsvCollection.lostOrderHeader
              .filter((_, i) => (estimateAuth ? true : i !== 9))}
            onClickRow={handleClick}
            onDbClick={(v, i, e) => handleDbClick(v, e)}
            option={ExportCsvCollection.getTableOption('lostOrder')}
            sort={{ onClick: handleClickHeader }}
            onClickAlt={(v, e) => {
              if (e && v) {
                windowOpen({
                  url: `${RoutingPath.projectDetail}/${v.id}`,
                  e,
                  callback: () => {},
                });
              }
            }}
            onClickMulti={(v: CsvLostOrderType[]) => {
              if (!list) return;
              setSelected(v.map((v2) => list.findIndex((v3) => v3.id === v2.id)));
            }}
            rowDataList={list || []}
            selectedTr={selected}
            lists={list ? list.map((v) => {
              const rank = rankList.find((v2) => v2.id === v.customer_rank);
              const rankName = rank ? rank.abbreviation + rank.abbreviation2 : '';
              const li = (
                [
                  v.internal_id,
                  v.name,
                  v.customer_name,
                  v.field_place,
                  v.field_tel_no,
                  <div className="rank_label" style={{ backgroundColor: rank?.background_color, color: rank?.text_color }}>{rankName}</div>,
                  v.project_employee_name,
                  v.failure_cause,
                  v.failure_cause_name,
                  Number(v.estimate_kin).toLocaleString(),
                ]
              );
              if (!estimateAuth) {
                li.pop();
              }
              return li;
            }) : null}
          />
        </div>
      </div>
    </section>
  );
};
