import { ApiBase } from '../../../../service/api-base';

export type ApiFileGetImgParam = {
  id:number
}

export class ApiFileGetImg extends ApiBase<any> {
  constructor(param: ApiFileGetImgParam) {
    super({
      httpMethod: 'GET',
      contents: 'BLOB',
      url: `/api/file/${param.id}/display`,
    });
  }
}
