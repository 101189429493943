import { useCallback } from 'react';
import { SupportHistoryListType } from '../../../../type/support-history/support-history.type';
import { RectLabel } from '../../label/rect-label/rect-label';
import './support-history-card.scss';
import { UserAgent } from '../../../../utilities/user-agent';
import { LoadingFileImg } from '../../file-img/loading-file-img';
import { DateFormatter } from '../../../../utilities/date-formatter';

type Props = {
  onClick: (id:number) => void,
  supportHistoryData: SupportHistoryListType,
  className?: string,
  isInCustomerDetail?: boolean;
}

export const SupportHistoryCard = (props: Props) => {
  const {
    supportHistoryData, className, onClick, isInCustomerDetail,
  } = props;

  /* callback */
  const handleClickSupportHistoryDetail = useCallback(
    () => {
      if (onClick) {
        onClick(supportHistoryData?.id);
      }
    }, [onClick],
  );

  return (
    <div
      className={`support_history_card ${UserAgent} card_base ${className || ''}`}
      onClick={handleClickSupportHistoryDetail}
    >
      <div className="card_base_row">
        <div className="card_base_row__col_left">
          <div className="card_info_item">
            <div className="card_info_item__head">
              案件名
            </div>
            <div className="card_info_item__text important">
              {`${supportHistoryData.project_name || '---'}`}
            </div>
          </div>

          <div className="card_info_item">
            <div className="card_info_item__head">
              登録日時
            </div>
            <div className="card_info_item__text">
              {`${DateFormatter.date2str(supportHistoryData.contact_dt, 'YYYYMMDD_HHmm') || '---'}`}
            </div>
          </div>

          <div className="card_info_item">
            <div className="card_info_item__head">
              対応履歴名
            </div>
            <div className="card_info_item__text">
              {`${supportHistoryData.title || '---'}`}
            </div>
          </div>

          {!isInCustomerDetail && (
          <div className="card_info_item">
            <div className="card_info_item__head">
              顧客名
            </div>
            <div className="card_info_item__text">
              {`${supportHistoryData.customer_name}`}
            </div>
          </div>
          )}

          <div className="card_info_item">
            <div className="card_info_item__head">
              対応担当者
            </div>
            <div className="card_info_item__text">
              {`${supportHistoryData.support_employee_name || '---'}`}
            </div>
          </div>

        </div>
        <div className="card_base_row__col_right">
          {/* <div className="date">{supportHistoryData.reception_time
            ? changeString(supportHistoryData.reception_time, '/') : '---'}</div> */}
          <div className="thumb google mb_5">
            <LoadingFileImg
              id={supportHistoryData.icon_thumbnail_id}
              format={supportHistoryData.icon_thumbnail_format}
              fileName={supportHistoryData.icon_thumbnail_file_name}
            />
          </div>
          {/* <div className={`${!supportHistoryData.fixed_flag ? 'invisible' : ''}`}> */}
          <RectLabel
            label={supportHistoryData.support_flag ? '対応済' : '未対応'}
            bgColor={supportHistoryData.support_flag ? '#1451a1' : '#9f3a38'}
          />
          {/* </div> */}
        </div>
      </div>
    </div>
  );
};
