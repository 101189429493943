import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useMemo, useState } from 'react';
import { MasterActions } from '../../../../../redux/master/master.action';
import { DialogActions } from '../../../../../redux/dialog/dialog.action';
import { State } from '../../../../../redux/root.reducer';
import { MasterCollection } from '../master.collection';
import { EmployeeMasterEditDialog } from '../edit-dialogs/company/emplyee-master-edit-dialog/emplyee-master-edit-dialog';
import { MasterBodyForEmployee } from './body/master-body-for-employee';
import { ApiMasterEmployeeGetListParam } from '../../../../../redux/master/api/master-employee/api-master-employee.type';
import { Button } from '../../../../ui/button/button';
import { AuthActions } from '../../../../../redux/auth/auth.action';
import { MasterBodyForContractedEmployee } from './body/master-body-for-contracted-employee';
import { useAppSelector } from '../../../../../hooks/use-redux';
import { useDidMount } from '../../../../../hooks/life-cycle';
import { ApiMasterContractedEmployeeGetListParam } from '../../../../../redux/master/api/master-contracted-employee/api-master-contracted-employee.type';
import { MasterContractedEmployee } from '../../../../../type/master/master-contracted-employee.type';

export const MasterContractedCompanyEmployeeBody = () => {
  /* Hook */
  const list = useSelector((state: State) => state.master.contractedEmployeeList);
  const dispatch = useDispatch();

  /* State */
  const [getParam, setGetParam] = useState<ApiMasterContractedEmployeeGetListParam>({
    company_id: NaN,
  });
  const [companyId, setCompanyId] = useState(NaN);

  /* Callback */
  const getList = useCallback((e: ApiMasterContractedEmployeeGetListParam) => {
    setGetParam(e);
    setCompanyId(e.company_id);
    dispatch(MasterActions.api.contractedEmployee.getList(e));
  }, [getParam]);

  const openEditDialog = useCallback((row?: MasterContractedEmployee, company_id?: number) => {
    let company = company_id ?? companyId;
    if (row) company = row.company_id;
    if (company < 0) return;
    dispatch(DialogActions.push({
      title: '社員 編集／追加',
      element:
  <EmployeeMasterEditDialog
    id={row?.id}
    callback={() => getList(getParam)}
    companyId={company}
    isListClear={companyId < 0}
  />,
    }));
  }, [getList, companyId, getParam]);

  const onClickRetransmission = useCallback((mail_address: string, company_id: number) => {
    dispatch(AuthActions.api.password.sendActivate({
      param: {
        mail_address,
        mode: 'company',
        company_id,
      },
      onSuccess: () => {},
    }));
  }, [companyId]);

  const tableList = useMemo(() => list.map((v) => {
    const isValid = v.valid_flag ? <i className="far fa-circle" /> : '';

    const invitation = v.status < 2 ? (
      <div><label className="invitation_label">招待中</label>&nbsp;
        <Button
          color="secondary"
          size="md"
          onClick={() => onClickRetransmission(v.mail_address, v.company_id)}
        >
          再送信
        </Button>
      </div>
    ) : isValid;
    return ([
      // v.employee_cd,
      v.company_name || '',
      v.store_name,
      // `${v.name || '未設定'}${v.role ? '(管理者)' : ''}`,
      v.name ? <>{v.name}<strong className="strong base_color">{v.role ? '（管理者）' : ''}</strong></> : <><span className="alert">※未設定</span><strong className="strong base_color">{v.role ? '（管理者）' : ''}</strong></>,
      // v.short_name,
      v.furigana || '',
      v.mail_address || '',
      v.job_title || '',
      // v.sales_target,
      invitation,
      v.authority1 ? <i className="far fa-circle" /> : '',
      v.authority4 ? <i className="far fa-circle" /> : '',
    ]);
  }),
  [list]);

  useDidMount(() => {
    dispatch(MasterActions.api.contractedCompany.getList({ param: {} }));
  });

  return (
    <MasterBodyForContractedEmployee
      header={MasterCollection.contractedEmployeeMasterHeader}
      rowDataList={list}
      list={tableList}
      callbackEdit={openEditDialog}
      callbackGetList={getList}
      defaultOrder={0}
      tableOption={{
        stringWidth: [
          { index: 0, width: 50 }, // 編集
          // { index: 1, width: 50 }, // 店舗名
          // { index: 2, width: 50 }, // 社員_名称
          // { index: 3, width: 50 }, // 社員_略称（削除）
          // { index: 3, width: 50 }, // 社員_フリガナ
          // { index: 4, width: 50 }, // 役職名
          // { index: 5, width: 50 }, // 売上目標（削除）
          // { index: 6, width: 100 }, // 有効フラグ
          // { index: 7, width: 50 }, // 権限1
          // { index: 8, width: 50 }, // 権限2
        ],
        tdAlign: [
          { index: 0, align: 'center' },
          { index: 1, align: 'left' },
          { index: 2, align: 'left' },
          { index: 3, align: 'left' },
          // { index: 3, align: 'left' },
          { index: 4, align: 'left' },
          { index: 5, align: 'center' },
          // { index: 5, align: 'right' },
          { index: 6, align: 'center' },
          { index: 7, align: 'center' },
          { index: 8, align: 'center' },
        ],
      }}
    />
  );
};
