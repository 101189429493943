import React, {
  useCallback, useEffect, useState,
} from 'react';
import { push } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';
import { Table } from '../../../../../ui/table/table';
import './csv-list-birthday.scss';
import { State } from '../../../../../../redux/root.reducer';
import { CsvBirthdayListType } from '../../../../../../type/csv/csv.type';
import { CsvActions } from '../../../../../../redux/csv/csv.action';
import { ExportCsvCollection } from '../../../../../../collection/export-csv/export-csv.collection';
import { RoutingPath } from '../../../../../../routes/routing-pass';
import { DateFormatter as DF } from '../../../../../../utilities/date-formatter';
import { useOpenWindow } from '../../../../../../hooks/use-open-window';

type Props = {
}

export const CsvListBirthday = (props: Props) => {
  /* Hooks */
  const list = useSelector((state: State) => state.csv.birthdayList);

  const dispatch = useDispatch();
  const windowOpen = useOpenWindow();

  /* State */
  const [selected, setSelected] = useState<number[]>([]);

  /* Callback */
  const handleClick = useCallback((row: CsvBirthdayListType, i:number) => {
    if (!list) return;
    setSelected([i]);
  }, [list]);

  const handleClickHeader = useCallback((highlow: 0 | 1, sort_by: number) => {
    dispatch(CsvActions.setBirthdaySort({ highlow, sort_by }));
  }, []);

  const handleDbClick = useCallback((
    row: CsvBirthdayListType,
    e?: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
  ) => {
    if (!list) return;
    if (!e) return;
    windowOpen({
      url: `${RoutingPath.customerDetail}/${row.customer_id}`,
      e,
    });
    // dispatch(push(`${RoutingPath.customerDetail}/${row.customer_id}`));
  }, [list]);

  return (
    <section className="result_area list_area">
      <div className="inner">
        <div className="table_responsive">
          <Table
            className="table_selectable table_sortable table_sticky table_cell_change"
            header={ExportCsvCollection.birthdayHeader}
            onClickRow={handleClick}
            onDbClick={(v, i, e) => handleDbClick(v, e)}
            sort={{ onClick: handleClickHeader }}
            onClickMulti={(v: CsvBirthdayListType[]) => {
              if (!list) return;
              setSelected(v.map((v2) => list.findIndex((v3) => v3.customer_id === v2.customer_id)));
            }}
            onClickAlt={(v, e) => {
              if (e && v) {
                windowOpen({
                  url: `${RoutingPath.customerDetail}/${v.customer_id}`,
                  e,
                  callback: () => {},
                });
              }
            }}
            rowDataList={list || []}
            selectedTr={selected}
            option={ExportCsvCollection.getTableOption('birthday')}
            lists={list
              ? list.map((v) => (
                [
                  v.internal_id,
                  v.customer_name,
                  v.family_name,
                  DF.date2str(v.birth_date, 'YYYYMMDD', '/'),
                  v.relationship,
                  v.mobile_phone,
                  `${v.post_no.slice(0, 3)}-${v.post_no.slice(3, 8)}`,
                  v.prefecture,
                  `${v.address || ''}`,
                  v.tel_no,
                  v.employee_name,
                ]
              ))
              : null}
          />
        </div>
      </div>
    </section>
  );
};
