import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';
import { MasterLabel } from '../master.type';
import { useAppSelector } from '../../../../../hooks/use-redux';

type Props = {
  callback: (type: number) => void,
  list: MasterLabel[],
  activeId: number
  disabled?: boolean;
}

export const SideMenu = (props: Props) => {
  const {
    callback, list, activeId, disabled,
  } = props;

  const path = useLocation().pathname;

  const autoRankUpAuth = useAppSelector((v) => v.user.company_authority4);
  const rankLogAuth = useAppSelector((v) => v.user.company_authority5);

  return (
    <div className="side_menu">
      {list.map((v, i) => (
        <div key={`sideMenu ${i}`}>
          {v.type === undefined
            ? <label>{v.label}</label>
            : (
              <button
                disabled={!!(path.match('master') && !autoRankUpAuth && v.type === 20)
                  || !!(path.match('csv') && !rankLogAuth && v.type === 8)}
                onClick={() => {
                  if (!!(path.match('master') && !autoRankUpAuth && v.type === 20)
                  || !!(path.match('csv') && !rankLogAuth && v.type === 8)) return;
                  callback(v.type ?? 0);
                }}
                className={v.type === activeId ? 'active' : ''}
              >
                <i className="fas fa-chevron-right" />{v.label}
              </button>
            )}
        </div>
      ))}
    </div>
  );
};
