import actionCreatorFactory from 'typescript-fsa';
import {
  FileListType, FileType, FileSortState, FileSizeType,
} from '../../type/file/file.type';
import { ApiFileDownloadParam } from './api/download/api-file-download';
import { apiFile } from './api/file/api-file';

const ActionCreator = actionCreatorFactory('file');

export const FileActions = {
  api: {
    file: {
      ...apiFile,
      download: ActionCreator<{
        param: ApiFileDownloadParam,
        fileName: string,
        isPreview?: boolean;
        callback?:(url: string) => void;
          }>('download'),
    },
  },
  setFile: ActionCreator<FileType | null>('set/file'),
  setList: ActionCreator<FileListType[] | null>('set/list'),
  setFileSize: ActionCreator<FileSizeType>('set/size'),
  setConcatList: ActionCreator<FileListType[] | null>('set/concat/list'),
  setSort: ActionCreator<Partial<FileSortState> | null>('set/sort'),
  resetState: ActionCreator('reset/state'),
  setStopLoad: ActionCreator<boolean>('stop-load'),
  setIsDelay: ActionCreator<boolean>('delay'),
};
