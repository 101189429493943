/* eslint-disable no-nested-ternary */
import lodash, { cloneDeep } from 'lodash';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { ZipCodeActions } from './zip-code.action';
import { Jisx0401ListType, Jisx0402ListType, ZipCodeListType } from '../../type/zip-code/zip-code.type';
import { concatList } from '../root.reducer';

export type ZipCodeState = {
  jisx0401List: Jisx0401ListType[] | null;
  jisx0402List: Jisx0402ListType[] | null;
  zipCodeList: ZipCodeListType[] | null;
}

const initialState: ZipCodeState = {
  jisx0401List: [],
  jisx0402List: null,
  zipCodeList: null,
};

export const ZipCodeReducer = reducerWithInitialState<ZipCodeState>(initialState)
  .case(ZipCodeActions.setJisx0401List, (state, payload) => ({
    ...state,
    jisx0401List: lodash.cloneDeep(payload),
  }))
  .case(ZipCodeActions.setJisx0402List, (state, payload) => ({
    ...state,
    jisx0402List: lodash.cloneDeep(payload),
  }))
  .case(ZipCodeActions.setZipCodeList, (state, payload) => ({
    ...state,
    zipCodeList: cloneDeep(payload),
  }))
  .case(ZipCodeActions.setConcatZipCodeList, (state, payload) => ({
    ...state,
    zipCodeList: cloneDeep(concatList(state.zipCodeList, payload)),
  }))
  .default((state) => state);
