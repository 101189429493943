import lodash from 'lodash';
import { Config } from '../../config/config';
import {
  CustomerSortState,
  CustomerEditState,
} from '../../type/customer/customer.type';
import { MapCollection } from '../map/map.collection';
import { User } from '../../type/auth/user.typs';

export const DummyCustomerImg = `https://maps.googleapis.com/maps/api/streetview?size=${100}x${100}&location=${35.69968628738344},${139.78094970307592}&fov=80&heading=70&pitch=0&key=${
  Config.apiKey
}`;

export class CustomerCollection {
  private static _customerHeader = [
    // '入力不備',
    'OBフラグ',
    '顧客営業担当',
    '顧客ID',
    '顧客名',
    'フリガナ',
    '郵便番号',
    '都道府県',
    '住所',
    'TEL',
    '見込みランク',
    '顧客ランク',
    'エリア',
    '建物分類',
    '間取り',
    '築年数',
    '備考',
  ];

  private static _familyHeader = ['氏名', '続柄', '携帯電話', '生年月日'];

  private static _petHeader = ['名前', '種別', '性別', '才'];

  private static _projectInfoHeader = [
    '案件名',
    '受注金額（契約金額）',
    '案件担当者',
    '登録日',
    '着工日',
    '完工日',
    '完了日',
    '発生源',
    '契約日',
    '失注日',
    'キャンセル日',
    '備考',
  ];

  private static _supportHistoryHeader = [
    '対応済',
    '案件名',
    '登録日時',
    'カテゴリ',
    '件名',
    '対応者',
    '対応日',
  ];

  private static _fileHeader = [
    'No.',
    'ファイル名',
    '形式',
    'サイズ（KB）',
    'アップロード日時',
    '更新者',
    'コメント',
    '',
    '',
  ];

  private static _maintenanceHeader = [
    '',
    '対応済',
    '案件名',
    '着工日',
    '完工日',
    'メンテナンス日',
    'タイトル',
    '対応日',
  ];

   static _customerSortInitialState = (user?:User): CustomerSortState => ({
     /** 郵便番号 */
     post_no: ['', ''],
     /** 営業担当（店舗） */
     store_id: user?.store_id ?? NaN,
     /** 営業担当（担当者） */
     employee_id: NaN,
     /** 顧客名 */
     name: '',
     /** 顧客名フリガナ */
     furigana: '',
     /** 顧客TEL */
     tel_no: '',
     /** 不備情報のみ */
     input_comp_flag: false,
     /** 有効フラグ */
     valid_flag: NaN,
     /** 都道府県 */
     prefecture: NaN,
     /** 顧客住所 */
     address: '',
     /** 顧客ランク */
     rank: '',
     /** 顧客ランクフィルタ */
     rank_filter: 1,
     /** 顧客見込みランク */
     estimated_rank: NaN,
     /** 顧客見込みランクフィルタ */
     estimated_rank_filter: 1,
     /** エリア */
     area_id: NaN,
     /** 建物分類 */
     building_category_id: NaN,
     /** 間取り */
     madori_id: NaN,
     /** 築年数－範囲開始 */
     building_age_start: null,
     /** 築年数－範囲終了 */
     building_age_end: null,
     /** 関連タグ */
     tags: null,
     /** 部位 */
     parts: null,
     /** 社内メモ */
     memo: '',
     /** メールアドレス */
     mail_address: '',
     /** LINEID */
     line_id: '',
     /** FaceBookID */
     facebook_id: '',
     /** TwitterID */
     twitter_id: '',
     /** InstagramID */
     instagram_id: '',
     /** 発生源 */
     source_id: NaN,
     /** 紹介者 */
     introducer: '',
     /** 結婚記念日(範囲開始) */
     wedding_anniversary_start: null,
     /** 結婚記念日(範囲終了) */
     wedding_anniversary_end: null,
     /** 備考 */
     remarks: '',
     /** 緯度 */
     north_lat: '',
     /** 経度 */
     north_lng: '',
     /** 緯度2 */
     south_lat: '',
     /** 経度2 */
     south_lng: '',
     /** キーワード */
     keyword: '',
     /** オフセット */
     offset: 0,
     /** 1ページ内の件数 */
     limit: 100,
     /** 並替基準列 */
     sort_by: 2,
     /** 並替方法 */
     highlow: 1,
     /** 完工時期－範囲終了 */
     completion_date_start: null,
     /** 完工時期－範囲終了 */
     completion_date_end: null,
     /** 最終完工時期－範囲開始 */
     last_completion_date_start: null,
     /** 最終完工時期－範囲終了 */
     last_completion_date_end: null,
     /** 総工事金額（最小値） */
     total_work_price_min: '',
     /** 総工事金額（最大値） */
     total_work_price_max: '',
     /** 工事回数（最小値） */
     work_times_min: '',
     /** 工事回数（最大値） */
     work_times_max: '',
     /** 顧客分類 */
     classification: 0,
     /** 登録店舗 */
     created_store_id: NaN,
     /** 登録社員 */
     created_employee_id: NaN,
     /** 更新店舗 */
     updated_store_id: NaN,
     /** 更新社員 */
     updated_employee_id: NaN,
     /** 登録日－期間開始 */
     created_at_start: null,
     /** 登録日－期間終了 */
     created_at_end: null,
     /** 更新日－期間開始 */
     updated_at_start: null,
     /** 更新日－期間終了 */
     updated_at_end: null,
     /** OBフラグ */
     ob_flag: 0,
   });

   static _customerEditInitialState = (user?: User): CustomerEditState => ({
     /** 顧客名 */
     name: '',
     /** 敬称 */
     keisho: '様',
     /** 略称 */
     name_short: '',
     /** 顧客名フリガナ */
     furigana: '',
     /** 顧客TEL */
     tel_no: '',
     /** 電話番号2 */
     tel_no2: '',
     /** 電話番号3 */
     tel_no3: '',
     /** FAX番号 */
     fax_no: '',
     /** 緊急連絡先 */
     kinkyu_tel: '',
     /** 郵便番号 */
     post_no: ['', ''],
     /** JISX0401コード */
     jisx0401_code: '',
     /** JISX0402コード */
     jisx0402_code: '',
     /** 都道府県 */
     prefecture: user?.prefecture_id ?? NaN,
     /** 市区町村 */
     city: '',
     /** 地名、番地 */
     address: '',
     /** 建物名等 */
     building_name: '',
     /** メールアドレス */
     mail_address: '',
     /** メールアドレス2 */
     mail_address2: '',
     /** メールアドレス3 */
     mail_address3: '',
     /** エリア */
     area_id: NaN,
     /** 振込先 */
     bank_account: '',
     /** 営業担当（店舗） */
     store_id: user?.store_id ?? NaN,
     /** 営業担当（担当者） */
     employee_id: user?.id ?? NaN,
     /** 建物分類 */
     building_category_id: NaN,
     /** 間取り */
     madori_id: NaN,
     /** 結婚記念日 */
     wedding_anniversary: null,
     /** 築年数 */
     building_age: NaN,
     /** 顧客ランク */
     rank: '',
     /** 顧客見込みランク */
     estimated_rank: NaN,
     /** 備考 */
     remarks: '',
     /** 最終更新者 */
     upd_employee_name: '',
     /** 紹介者 */
     introducer: '',
     /** マイカー種別 */
     my_car_type: null,
     /** マイカー種別_その他 */
     my_car_type_other: '',
     /** 有効フラグ */
     valid_flag: 1,
     /** 発生源 */
     source_id: NaN,
     /** OBフラグ */
     ob_flag: false,
     /** 部位リスト */
     part_list: null,
     /** 見込み部位リスト */
     expected_part_list: null,
     /** ご家族氏名リスト */
     family_name_array: [],
     /** ご家族続柄リスト */
     family_relation_array: [],
     /** ご家族メールアドレスリスト */
     family_email_array: [],
     /** ご家族生年月日リスト */
     family_birthday_array: [],
     /** ご家族携帯電話リスト */
     family_mobile_tel_array: [],
     /** ペット名前リスト */
     pet_name_array: [],
     /** ペット性別リスト */
     pet_sex_array: [],
     /** ペット種別リスト */
     pet_type_array: [],
     /** ペット才リスト */
     pet_age_array: [],
     /** line_id */
     line_id: '',
     /** facebook_id */
     facebook_id: '',
     /** twitter_id */
     twitter_id: '',
     /** instagram_id */
     instagram_id: '',
     /** 社内メモ1 */
     memo1: '',
     /** 社内メモ2 */
     memo2: '',
     /** 関連タグリスト */
     tag_list: null,
     /** 関連タグ_その他 */
     tag_list_other: '',
     /** 緯度 */
     lat: '',
     /** 経度 */
     lng: '',
     /** 築年月 */
     construction_info: null,
   });

  private static _petSexualOption = [
    { name: '指定なし', id: 1 },
    { name: 'オス', id: 2 },
    { name: 'メス', id: 3 },
  ];

  static get customerHeader() {
    return CustomerCollection._customerHeader;
  }

  static get familyHeader() {
    return CustomerCollection._familyHeader;
  }

  static get petHeader() {
    return CustomerCollection._petHeader;
  }

  static get projectInfoHeader() {
    return CustomerCollection._projectInfoHeader;
  }

  static get supportHistoryHeader() {
    return CustomerCollection._supportHistoryHeader;
  }

  static get fileHeader() {
    return CustomerCollection._fileHeader;
  }

  static get maintenanceHeader() {
    return CustomerCollection._maintenanceHeader;
  }

  static get customerEditInitialState() {
    return CustomerCollection._customerEditInitialState;
  }

  static get customerSortInitialState() {
    return CustomerCollection._customerSortInitialState();
  }

  static get petSexualOption() {
    return CustomerCollection._petSexualOption;
  }
}
