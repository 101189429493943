import { useCallback, useMemo } from 'react';
import { Project } from '../type/project/project.type';
import { useAppSelector } from './use-redux';
import { Estimate } from '../type/estimate/estimate.type';
import { Maintenance } from '../type/maintenance/maintenance.type';

/** 担当外情報操作権限 顧客 */
export const useEditAuthCustomer = (employeeId: number | undefined) => {
  const user = useAppSelector((v) => v.user);

  return useMemo(() => {
    if (!employeeId) return true;
    if (user.authority1) return true;
    if (user.id === employeeId) return true;
    return false;
  }, [user, employeeId]);
};

/** 担当外情報操作権限 案件 */
export const useEditAuthProject = (project: Project | undefined | null) => {
  const user = useAppSelector((v) => v.user);

  return useMemo(() => {
    if (!project?.project_employee_id) return true;
    if (user.authority1) return true;
    if (user.id === project?.project_employee_id) return true;
    return false;
  }, [user, project?.project_employee_id]);
};

/** 担当外情報操作権限 見積もり */
export const useEditAuthEstimate = (estimate: Estimate | undefined | null) => {
  const user = useAppSelector((v) => v.user);

  return useMemo(() => {
    if (!estimate?.created_employee_id) return true;
    if (user.authority1) return true;
    if (user.id === estimate?.created_employee_id) return true;
    return false;
  }, [user, estimate?.created_employee_id]);
};

/** 担当外情報操作権限 メンテナンス */
export const useEditAuthMaintenance = (maintenance: Maintenance | undefined | null) => {
  const user = useAppSelector((v) => v.user);

  return useMemo(() => {
    if (!maintenance) return true;
    if (!maintenance?.project_employee_id) return true;
    if (user.authority1) return true;
    if (user.id === maintenance?.project_employee_id) return true;
    return false;
  }, [user, maintenance?.project_employee_id]);
};

/** ストリートView表示権限 顧客 */
export const useCustomerView = () => {
  const yuryoAuth = useAppSelector((v) => v.user.company_status);
  const customerViewAuth = useAppSelector((v) => v.user.company_authority1);

  return useCallback((index: number) => {
    if (index < 3) return true;
    if (yuryoAuth === 1 && customerViewAuth) return true;
    return false;
  }, [yuryoAuth, customerViewAuth]);
};

/** 有償機能 見積権限 */
export const useEstimateAuthority = () => {
  const user = useAppSelector((v) => v.user);
  return useMemo(() => user.company_authority6, [user]);
};

/** ストリートView表示権限 案件 */
export const useProjectView = () => {
  const yuryoAuth = useAppSelector((v) => v.user.company_status);
  const projectViewAuth = useAppSelector((v) => v.user.company_authority2);

  return useCallback((index: number) => {
    if (index < 3) return true;
    if (yuryoAuth === 1 && projectViewAuth) return true;
    return false;
  }, [yuryoAuth, projectViewAuth]);
};
