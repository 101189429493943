import { isEqual } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useWillUnMount } from '../../../../../../hooks/life-cycle';
import { CsvActions } from '../../../../../../redux/csv/csv.action';
import { State } from '../../../../../../redux/root.reducer';
import { Limit, TableSort } from '../../../../../ui/table/table-sort/table-sort';
import { CsvListBirthday } from '../../list/birthday/csv-list-birthday';
import { CsvSearchBoxBirthday } from '../../search-box/birthday/csv-search-box-birthday';
import { CsvBirthdaySort } from '../../../../../../type/csv/csv-sort.type';
import { useAppSelector } from '../../../../../../hooks/use-redux';

type Props = {
  callback: (v: () => void) => void;
}

export const CsvBirthday = (props:Props) => {
  const { callback } = props;

  /* Hooks */
  const dispatch = useDispatch();
  const sortState = useSelector((state: State) => (state.csv.birthdaySort), isEqual);
  const list = useSelector((state: State) => (state.csv.birthdayList), isEqual);
  const hitCount = useAppSelector((v) => (v.csv.birthdayCount));

  /* State */
  const [selected, setSelected] = useState<number[]>([]);

  /* Callback */
  const getList = useCallback((v?: CsvBirthdaySort) => {
    setSelected([]);
    const sortData = v || sortState;
    dispatch(CsvActions.api.birthday.getList(sortData));
  }, [sortState]);

  const changePagination = useCallback((offset:number, limit:Limit) => {
    dispatch(CsvActions.setBirthdayList(null));
    dispatch(CsvActions.setBirthdaySort({ offset, limit }));
    getList({ ...sortState, offset, limit });
  }, [sortState]);

  /* Effect */
  useEffect(() => {
    getList();
  }, [
    sortState.sort_by,
    sortState.highlow,
  ]);

  /* Master */
  useWillUnMount(() => {
    dispatch(CsvActions.setBirthdaySort(null));
  });

  return (
    <>
      <CsvSearchBoxBirthday
        callback={(v) => {
          dispatch(CsvActions.setBirthdayList(null));
          getList(v);
        }}
      />
      <TableSort
        page={sortState.offset ?? 0}
        limit={sortState.limit as Limit}
        hitCount={hitCount || 0}
        callback={changePagination}
      />
      <CsvListBirthday />
      <TableSort
        className="bottom"
        page={sortState.offset ?? 0}
        limit={sortState.limit as Limit}
        hitCount={hitCount || 0}
        callback={changePagination}
      />
    </>
  );
};
