import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { Input } from 'semantic-ui-react';
import { MapActions } from '../../../../redux/map/map.action';
import { DialogActions } from '../../../../redux/dialog/dialog.action';
import { Button } from '../../button/button';
import { noPinch } from '../../../../utilities/no-pinch';
import { useAppSelector } from '../../../../hooks/use-redux';
import { RouteDialog } from '../../../dialogs/route-dialog/route-dialog';
import { LeftIconButton } from '../../button/left-icon-button/left-icon-button';

type Props = {
  isRoute?: boolean;
  type?: 'customer' | 'project';
  callbackSearch?: (v: string) => void;
}

export const SearchNameDialog = (props: Props) => {
  const { isRoute, type, callbackSearch } = props;
  const dispatch = useDispatch();
  const searchName = useAppSelector((v) => v.map.routeSearchName);
  const [searchValue, setSearchValue] = useState(searchName);
  const searchRef = useRef<any | null>(null);
  const ele = useRef<HTMLDivElement>(null);

  const label = useMemo(() => {
    let _label = '';
    if (type === 'customer') _label = '顧客';
    if (type === 'project') _label = '案件';
    return _label;
  }, [type]);

  const handleClickSearch = useCallback(
    () => {
      console.log(searchValue);
      callbackSearch?.(searchValue);
      dispatch(DialogActions.pop());
    },
    [searchValue, callbackSearch],
  );

  useEffect(() => searchRef?.current.focus(), [searchRef]);

  return (
    <div className="googlg_map_search" style={{ textAlign: 'center', padding: '10px 20px' }} ref={ele}>
      <div style={{ marginTop: '40px' }}>
        <div style={{ marginBottom: '10px' }}>{label}名を入力してください</div>
        <div onKeyPress={(e) => {
          e.stopPropagation();
          if (e.key === 'Enter') handleClickSearch();
        }}
        >
          <Input
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            ref={searchRef}
          />
        </div>
      </div>

      <div className="base_dialog_content_inner_footer" style={{ marginTop: '30px' }}>
        <LeftIconButton
          label="検索"
          fontAwesomeClass="fas fa-search"
          size="md"
          color="primary"
          onClick={handleClickSearch}
        />
      </div>
    </div>
  );
};
