import {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { cloneDeep } from 'lodash';
import { CircularProgress } from '@material-ui/core';
import { prefectures } from '../../../collection/prefectures';
import { UserAgent } from '../../../utilities/user-agent';
import { Button } from '../button/button';
import { Input } from '../input/input';
import { Select } from '../select/select';
import { Table } from '../table/table';
import { InputPostal } from '../input/input-postal';
import { joinStr } from '../../../utilities/join-str';
import { ItemWrap } from '../item/item-wrap';
import { ZipCodeActions } from '../../../redux/zip-code/zip-code.action';
import { useAppSelector } from '../../../hooks/use-redux';
import { ZipCodeListType } from '../../../type/zip-code/zip-code.type';
import { DialogActions } from '../../../redux/dialog/dialog.action';
import { useWillUnMount } from '../../../hooks/life-cycle';

export const minus0 = (v: string) => (v[0] === '0' ? v[1] : v);
export const plus0 = (v: string) => (String(v).length === 1 ? `0${v}` : v);

export type SearchPostType = {
  zipcode1: string;
  zipcode2: string;
  city: string;
  town: string;
  pref: number
  jisx0402: string
}

export type SearchPostAddressDialogProps = {
  calback: (v: SearchPostType) => void;
} & SearchPostType

export const SearchPostAddressDialog = (props: SearchPostAddressDialogProps) => {
  const {
    zipcode1: _zipcode1,
    zipcode2: _zipcode2,
    city: _city,
    town: _town,
    pref: _jisx0401,
    jisx0402: _jisx0402,
    calback,
  } = props;

  const {
    jisx0402List,
    zipCodeList,
  } = useAppSelector((v) => v.zipCode);

  const className = `search_postaddress_dialog ${UserAgent}`;
  const dispatch = useDispatch();

  /* State */
  const [resetKey, setResetKey] = useState(0);
  const [zipcode1, setZipCode1] = useState(_zipcode1);
  const [zipcode2, setZipCode2] = useState(_zipcode2);
  const [town, setTown] = useState(_town || '');
  const [jisx0401, setJisx0401] = useState(_jisx0401
    ? plus0(String(_jisx0401))
    : '');
  const [jisx0402, setJisx0402] = useState(_jisx0402 || '');
  const [city, setCity] = useState(_city || '');

  const [isSearch, setIsSearch] = useState(false);
  const [selectIndex, setSelectIndex] = useState(NaN);
  const [selectData, setSelectData] = useState<ZipCodeListType | null>(null);
  const [offsetCount, setOffsetCount] = useState(0);
  const [getMode, setGetMode] = useState<'address' | 'zip' | ''>('');

  const reset = () => {
    setResetKey(resetKey + 1);
    setSelectData(null);
    setSelectIndex(NaN);
  };

  /* Callback */
  const post = useCallback(() => {
    dispatch(DialogActions.pop());
    calback({
      zipcode1,
      zipcode2,
      town,
      city,
      pref: Number(jisx0401),
      jisx0402,
    });
  }, [zipcode1, zipcode2, town, city, jisx0401, jisx0402, calback]);

  /* データのセット */
  const settingData = useCallback((row?: ZipCodeListType) => {
    reset();
    dispatch(ZipCodeActions.setZipCodeList(null));
    setIsSearch(false);
    const data = row || selectData;
    if (!data) return;
    setJisx0401(data.code[0] + data.code[1]);
    setJisx0402(data.code);
    setCity(data.city);
    setZipCode1(data.zipcode[0] + data.zipcode[1] + data.zipcode[2]);
    setZipCode2(
      data.zipcode[3]
      + data.zipcode[4]
      + data.zipcode[5]
      + data.zipcode[6],
    );
    setTown(data.town);
  }, [selectData]);

  /* 検索 */
  const getZipCode = useCallback((mode: 'zip' | 'address') => {
    reset();
    dispatch(ZipCodeActions.api.zipCode.getList({
      param: {
        code: mode === 'zip' ? '' : jisx0402 || jisx0401,
        zipcode: mode === 'zip' ? zipcode1 + zipcode2 : '',
        offset: offsetCount,
        limit: 100,
      },
      onSuccess: (v) => {
        if (offsetCount) {
          dispatch(ZipCodeActions.setConcatZipCodeList(v));
          return;
        }
        dispatch(ZipCodeActions.setZipCodeList(v));
      },
    }));
  }, [jisx0401, jisx0402, zipcode1, zipcode2, offsetCount]);

  /* 市区町村リスト取得 */
  const getListJix0402 = useCallback((code: string) => {
    dispatch(ZipCodeActions.api.jisx0402.getList({
      code,
      offset: 0,
      limit: 99999999,
    }));
  }, []);

  /** 都道府県の変更 */
  const hChangeJisx0401 = useCallback((v: string | number) => {
    dispatch(ZipCodeActions.setJisx0402List(null));
    if (!v) {
      setJisx0401('');
      setJisx0402('');
      setCity('');
      return;
    }
    setJisx0401(String(v));
    getListJix0402(String(v));
  }, []);

  /* 郵便番号検索ボタン押下 */
  const hClickPostSearch = useCallback(() => {
    setIsSearch(true);
    setOffsetCount(0);
    getZipCode('zip');
    setGetMode('zip');
    dispatch(ZipCodeActions.setZipCodeList(null));
  }, [getZipCode, jisx0401]);

  /* 住所検索ボタン押下 */
  const hClickAddressSearch = useCallback(() => {
    setIsSearch(true);
    setGetMode('address');
    setOffsetCount(0);
    getZipCode('address');
    dispatch(ZipCodeActions.setZipCodeList(null));
  }, [getZipCode]);

  /* テーブルクリック */
  const clickRow = useCallback((row: ZipCodeListType) => {
    if (!zipCodeList) return;
    const findIndex = zipCodeList.findIndex((v) => (
      v.city === row.city && v.town === row.town
    ));
    console.log(row);
    setSelectIndex(findIndex);
    setSelectData(cloneDeep(row));
  }, [zipCodeList]);

  /* テーブルダブルクリック */
  const dbClickRow = useCallback((row: ZipCodeListType) => {
    settingData(row);
  }, []);

  /* 0402リストの取得 */
  useEffect(() => {
    if (jisx0401) getListJix0402(jisx0401);
  }, [jisx0401]);

  /* スクロールリスト取得 */
  useEffect(() => {
    if (!getMode) return;
    getZipCode(getMode);
  }, [offsetCount]);

  /* WillUnmount */
  useWillUnMount(() => {
    dispatch(ZipCodeActions.setJisx0402List(null));
    dispatch(ZipCodeActions.setZipCodeList(null));
  });

  return (UserAgent === 'pc'
    ? (
      <div className={`editPc_wrap ${className}`}>
        <div className="editPc_body" style={{ flexDirection: 'row' }}>
          <section>
            <ItemWrap title="郵便番号">
              <div className="item_postal">
                <InputPostal
                  zip_code1={[zipcode1, setZipCode1]}
                  zip_code2={[zipcode2, setZipCode2]}
                  onEnter={hClickPostSearch}
                />
                <Button
                  size="sm"
                  color="secondary"
                  className="ml_10"
                  disabled={!zipcode1}
                  onClick={hClickPostSearch}
                >
                  郵便番号から住所検索
                </Button>
              </div>
            </ItemWrap>
            <hr className="hr_style" />
            <ItemWrap title="都道府県">
              <div className="item_postal">
                <Select
                  value={jisx0401}
                  defaultLabel="指定無し"
                  options={prefectures.map((v) => ({
                    text: v.label, value: plus0(String(v.value)),
                  }))}
                  onChange={(v) => hChangeJisx0401(String(v))}
                />
                <Button
                  size="sm"
                  color="secondary"
                  className="ml_10"
                  disabled={!jisx0401}
                  onClick={hClickAddressSearch}
                >
                  住所から郵便番号検索
                </Button>
              </div>
            </ItemWrap>
            <ItemWrap title="市区町村">
              <>
                <Select
                  disabled={!jisx0402List}
                  value={jisx0402}
                  defaultLabel="指定無し"
                  options={jisx0402List
                    ? jisx0402List.map((v) => ({ text: v.pref + v.city, value: v.code }))
                    : []}
                  onChange={(v, text) => {
                    setJisx0402(String(v));
                    setCity(text);
                  }}
                />
                {!jisx0402List && jisx0401
                && <CircularProgress />}
              </>
            </ItemWrap>
            <ItemWrap title="町名">
              <Input
                value={town}
                onChange={({ target }) => setTown(target.value)}
              />
            </ItemWrap>
            <div className="flex_box">
              <Button
                size="sm"
                color="primary"
                className="ml_auto"
                onClick={post}
              >
                登録
              </Button>
              <Button
                size="sm"
                color="dark"
                className="ml_10 "
                onClick={() => dispatch(DialogActions.pop())}
              >
                キャンセル
              </Button>
            </div>
          </section>
          {Boolean(isSearch)
          && (
          <section className="ml_15 address_list_box">
            <div className="table_box">
              <div className="table_responsive">
                <Table
                  className="table_selectable table_sortable table_sticky"
                  header={['〒', '住所']}
                  onClickRow={clickRow}
                  onDbClick={dbClickRow}
                  selectedTr={[selectIndex]}
                  rowDataList={zipCodeList || []}
                  lists={zipCodeList
                    ? zipCodeList.map((v) => ([
                      joinStr(v.zipcode, 3, '-'),
                      `${v.pref}${v.city}${v.town}`,
                    ]))
                    : null}
                  callbackScrollEnd={() => setOffsetCount((v) => v + 1)}
                  option={{
                    stringWidth: [
                      { index: 0, width: 50 }, // 〒
                      // { index: 1, width: 100 }, // 住所
                    ],
                    tdAlign: [
                      { index: 0, align: 'center' },
                      { index: 1, align: 'left' },
                    ],
                  }}
                />
              </div>
            </div>
            <div className="flex_box">
              <Button
                size="sm"
                color="primary"
                className="ml_auto"
                disabled={!selectData}
                onClick={() => settingData()}
              >
                選択
              </Button>
              <Button
                size="sm"
                color="dark"
                className="ml_10 "
                // disabled=""
                onClick={() => dispatch(DialogActions.pop())}
              >
                キャンセル
              </Button>
            </div>
          </section>
          )}
        </div>
      </div>
    )
    : (
      <div className={`editSp_wrap ${className}`}>
        <div className="edit_sp_body" style={{ flexDirection: 'column' }}>
          <div className="edit_sp_body_inner customerEditSP">
            <div className="category_wrap">
              <section>
                <div className="item_wrap">
                  <div className="item_label">郵便番号
                    <Button
                      size="xs"
                      color="secondary"
                      className="ml_10"
                      disabled={!zipcode1}
                      onClick={hClickPostSearch}
                    >
                      郵便番号から住所検索
                    </Button>
                  </div>
                  <div className="item_body item_postal">
                    <InputPostal
                      zip_code1={[zipcode1, setZipCode1]}
                      zip_code2={[zipcode2, setZipCode2]}
                      onEnter={hClickPostSearch}
                    />
                  </div>
                </div>
                <hr className="hr_style mt_15 mb_15" />
                <ItemWrap title="都道府県">
                  <div className="item_body item_postal">
                    <Select
                      value={jisx0401}
                      defaultLabel="指定無し"
                      options={prefectures.map((v) => ({
                        text: v.label, value: plus0(String(v.value)),
                      }))}
                      onChange={(v) => setJisx0401(String(v))}
                    />
                    <Button
                      size="sm"
                      color="secondary"
                      className="ml_10"
                      disabled={!jisx0401}
                      onClick={hClickAddressSearch}
                    >
                      住所から郵便番号検索
                    </Button>
                  </div>
                </ItemWrap>
                <ItemWrap title="市区町村">
                  {/* <div /> */}
                  <>
                    <Select
                      value={jisx0402}
                      defaultLabel="指定無し"
                      options={jisx0402List
                        ? jisx0402List.map((v) => ({ text: v.pref + v.city, value: v.code }))
                        : []}
                      onChange={(v, text) => {
                        setJisx0402(String(v));
                        setCity(text);
                      }}
                      disabled={!jisx0402List}
                    />
                    {!jisx0402List && jisx0401
                && <div className="progress_wrap"><CircularProgress /></div> }
                  </>
                </ItemWrap>
                <ItemWrap title="町名">
                  <Input
                    value={town}
                    onChange={({ target }) => setTown(target.value)}
                  />
                </ItemWrap>
                <div className="flex_box flex_space_between mt_15 mb_15">
                  <Button
                    size="sm"
                    color="primary"
                    className="flex_1"
                    onClick={post}
                  >
                    登録
                  </Button>
                  <Button
                    size="sm"
                    color="dark"
                    className="flex_1 ml_10"
                    // disabled=""
                    onClick={() => dispatch(DialogActions.pop())}
                  >
                    キャンセル
                  </Button>
                </div>
              </section>
              {Boolean(isSearch)
          && (
          <>
            <hr className="hr_style mt_15 mb_15" />
            <section className="address_list_box mt_15 mb_15">
              <div className="inner">
                <div className="table_responsive">
                  <Table
                    key={resetKey}
                    className="table_selectable table_sortable table_sticky"
                    header={['〒', '住所']}
                    onClickRow={clickRow}
                    onDbClick={dbClickRow}
                    selectedTr={[selectIndex]}
                    rowDataList={zipCodeList || []}
                    lists={zipCodeList
                      ? zipCodeList.map((v) => ([
                        joinStr(v.zipcode, 3, '-'),
                        `${v.pref}${v.city}${v.town}`,
                      ]))
                      : null}
                    option={{
                      stringWidth: [
                        { index: 0, width: 50 }, // 〒
                      // { index: 1, width: 100 }, // 住所
                      ],
                      tdAlign: [
                        { index: 0, align: 'center' },
                        { index: 1, align: 'left' },
                      ],
                    }}
                    callbackScrollEnd={() => setOffsetCount((v) => v + 1)}
                  />
                </div>
              </div>
              <div className="flex_box flex_space_between mt_15">
                <Button
                  size="sm"
                  color="primary"
                  className="flex_1"
                  disabled={!selectData}
                  onClick={() => settingData()}
                >
                  選択
                </Button>
                <Button
                  size="sm"
                  color="dark"
                  className="flex_1 ml_10 "
                  onClick={() => dispatch(DialogActions.pop())}
                >
                  キャンセル
                </Button>
              </div>
            </section>
          </>
          )}
            </div>
          </div>
        </div>
      </div>
    ));
};
