import {
  useCallback, useState,
} from 'react';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import { Table } from '../../../../../ui/table/table';
import './csv-list-support-history.scss';
import { ExportCsvCollection } from '../../../../../../collection/export-csv/export-csv.collection';
import { CsvActions } from '../../../../../../redux/csv/csv.action';
import { CsvSupportHistoryType } from '../../../../../../type/csv/csv.type';
import { RoutingPath } from '../../../../../../routes/routing-pass';
import { useAppSelector } from '../../../../../../hooks/use-redux';
import { DateFormatter as DF, DateFormatter as df } from '../../../../../../utilities/date-formatter';

export const CsvListSupportHistory = () => {
  /* Hooks */
  const dispatch = useDispatch();

  const list = useAppSelector((v) => v.csv.supportHistoryList);

  /* State */
  const [selected, setSelected] = useState<number[]>([]);

  /* Callback */
  const handleClick = useCallback((row: CsvSupportHistoryType, i: number) => {
    if (!list) return;
    setSelected([i]);
  }, [list]);

  const handleClickHeader = useCallback((highlow:0 | 1, sort_by: number) => {
    dispatch(CsvActions.setSupportHistorySort({ highlow, sort_by }));
  }, []);

  const handleDbClick = useCallback((row:CsvSupportHistoryType) => {
    if (!list) return;
    dispatch(push(`${RoutingPath.supportHistoryDetail}/${row.id}`));
  },
  [list]);

  return (
    <section className="result_area list_area">
      <div className="inner">
        <div className="table_responsive">
          <Table
            className="table_selectable table_sortable table_sticky table_cell_change"
            header={ExportCsvCollection.supportHistoryHeader}
            onClickRow={handleClick}
            onDbClick={handleDbClick}
            sort={{ onClick: handleClickHeader }}
            rowDataList={list || []}
            option={ExportCsvCollection.getTableOption('supportHistory')}
            selectedTr={selected}
            lists={list ? list.map((v) => (
              [
                DF.date2str(v.contact_dt, 'YYYYMMDD_HHmm', '/'),
                v.title,
                v.support_name,
                v.source_name,
                v.customer_name,
                v.customer_employee_name,
                v.project_employee_name,
                v.support_employee_name,
                df.date2str(v.support_dt, 'YYYYMMDD', '/'),
              ]
            )) : null}
          />
        </div>
      </div>
    </section>
  );
};
