import {
  useCallback, useEffect, useState, memo,
} from 'react';
import { useDispatch } from 'react-redux';
import { pulldown } from '../../../../../../utilities/pulldown';
import { LabelInput } from '../../../../../ui/input/label-input';
import { SearchBox } from '../../../../layout/search-box/search-box.sp';
import { ApiMasterMeisaiGetListParam } from '../../../../../../redux/master/api/master-meisai/api-master-meisai.type';
import { useDidMount } from '../../../../../../hooks/life-cycle';
import { MasterActions } from '../../../../../../redux/master/master.action';
import { useAppSelector } from '../../../../../../hooks/use-redux';
import { Select } from '../../../../../ui/select/select';

type Props = {
  callback: (v: ApiMasterMeisaiGetListParam) => void;
}

export const SearchMeisaiSP = memo((props: Props) => {
  const { callback } = props;

  const dispatch = useDispatch();

  const largeCategoryList = useAppSelector((v) => v.master.largeCategoryList);
  const middleCategoryList = useAppSelector((v) => v.master.middleCategoryList);

  /* State */
  const [daibunrui, setDaibunrui] = useState(NaN);
  const [tyubunrui, setTyubunrui] = useState(NaN);
  const [meisai, setMeisai] = useState('');

  /* Callback */
  const getList = useCallback(() => {
    callback({
      /** 大分類 */
      category_id: daibunrui,
      /** 中分類 */
      subcategory_id: tyubunrui,
      /** 文字列検索 名称、規格、対象での検索用文字列 */
      word: meisai,
      /** 無効フラグ 1:無効 0:有効 */
      is_muko: 0,
      /** 並替基準列 */
      sort_by: 0,
      /** 並替方法 */
      highlow: 1,
    });
  }, [daibunrui, tyubunrui, meisai]);

  const onChangeDaibunrui = useCallback((v: string | number) => {
    setDaibunrui(Number(v));
    if (v && daibunrui !== v) {
      dispatch(MasterActions.api.middleCategory.getList({
        category_id: Number(v),
      }));
      setTyubunrui(NaN);
    } else if (!v) {
      dispatch(MasterActions.api.middleCategory.getList({}));
      setTyubunrui(NaN);
    }
  }, [daibunrui]);

  const onChangeTyubunrui = useCallback((v: string | number) => {
    setTyubunrui(Number(v));
    const daibunruiId = middleCategoryList.find((f) => f.id === v)?.category_id;
    if (daibunruiId) {
      setDaibunrui(Number(daibunruiId));
      return;
    }
    setDaibunrui(NaN);
  }, [daibunrui, middleCategoryList]);

  /* Effect */
  useDidMount(() => {
    dispatch(MasterActions.api.largeCategory.getList({}));
    dispatch(MasterActions.api.middleCategory.getList({}));
  });

  return (
    <SearchBox callback={getList}>
      <div className="search_box_sp_body_inner">
        <div className="category_wrap">
          <div className="item_wrap">
            <div className="item_label">大分類</div>
            <div className="item_body item_select full_width">
              <Select
                value={daibunrui}
                defaultLabel="指定無し"
                options={pulldown(largeCategoryList)}
                onChange={(v) => onChangeDaibunrui(Number(v))}
              />
            </div>
          </div>
          <div className="item_wrap">
            <div className="item_label">中分類</div>
            <div className="item_body item_select full_width">
              <Select
                value={tyubunrui}
                defaultLabel="指定無し"
                options={pulldown(middleCategoryList)}
                onChange={(v) => onChangeTyubunrui(Number(v))}
              />
            </div>
          </div>
          <div className="item_wrap">
            <LabelInput
              pos="Top"
              className="full_width"
              label="明細"
              type="text"
              value={meisai}
              onChange={(v) => setMeisai(v)}
            />
          </div>
        </div>
      </div>
    </SearchBox>
  );
});
