import { cloneDeep } from 'lodash';
import { SupportHistorySortState, SupportHistoryEditState, SupportHistory } from '../../type/support-history/support-history.type';
import { ApiSupportHistoryGetListParam, ApiSupportHistoryPostParam } from '../../redux/support-history/api/api-support-history/api-support-history.type';
import { DateFormatter } from '../../utilities/date-formatter';
import { User } from '../../type/auth/user.typs';
import { Customer, CustomerListType } from '../../type/customer/customer.type';
import { ProjectListType, Project } from '../../type/project/project.type';
import { MasterEmployee } from '../../type/master/master-employee.type';

const { extname, basename } = require('path');

type SortState = SupportHistorySortState;
type EditState = SupportHistoryEditState;
type ResSortState = ApiSupportHistoryGetListParam;
type ResPostState = ApiSupportHistoryPostParam['data'];
export type FilesType = {
  format: string;
  name: string;
  file: File | null;
  id: number;
}
const df = (v: Date | null | undefined) => DateFormatter.date2str(v, 'YYYYMMDD', '-');

export class SupportHistoryModel {
  /** 案件詳細內一覧取得用パラメータ */
  static listParamInProject(param: {
    sortState: SortState,
    project_id: number;
  }): ResSortState {
    const { sortState, project_id } = param;
    let sort_by = 1;
    if (sortState.sort_by === 0)sort_by = 0;
    if (sortState.sort_by === 1)sort_by = 4;
    if (sortState.sort_by === 2)sort_by = 1;
    if (sortState.sort_by === 3)sort_by = 2;
    if (sortState.sort_by === 4)sort_by = 8;
    if (sortState.sort_by === 5)sort_by = 6;
    if (sortState.sort_by === 6)sort_by = 7;
    let supportFlag = 0;
    if (Number.isNaN(sortState.support_flag))supportFlag = 2;
    if (sortState.support_flag === 0)supportFlag = 2;
    if (sortState.support_flag === 1)supportFlag = 0;
    if (sortState.support_flag === 2) supportFlag = 1;

    return ({
      ...sortState,
      project_id,
      contact_dt: df(sortState.contact_dt),
      support_dt: df(sortState.support_dt),
      support_dt_start: df(sortState.support_dt_start),
      support_dt_end: df(sortState.support_dt_end),
      contact_dt_start: df(sortState.contact_dt_start),
      contact_dt_end: df(sortState.contact_dt_end),
      created_at_start: df(sortState.created_at_start),
      created_at_end: df(sortState.created_at_end),
      updated_at_start: df(sortState.updated_at_start),
      updated_at_end: df(sortState.updated_at_end),
      support_flag: supportFlag,
      sort_by,
    });
  }

  /** 顧客詳細內一覧取得用パラメータ */
  static listParamInCustomer(param: {
    sortState: SortState;
    customer_id: number;
  }): ResSortState {
    const { sortState, customer_id } = param;
    let sort_by = 1;
    if (sortState.sort_by === 0)sort_by = 0;
    if (sortState.sort_by === 1)sort_by = 4;
    if (sortState.sort_by === 2)sort_by = 1;
    if (sortState.sort_by === 3)sort_by = 2;
    if (sortState.sort_by === 4)sort_by = 8;
    if (sortState.sort_by === 5)sort_by = 6;
    if (sortState.sort_by === 6)sort_by = 7;

    let supportFlag = 0;
    if (!sortState.support_flag)supportFlag = 2;
    if (sortState.support_flag === 1)supportFlag = 0;
    if (sortState.support_flag === 2) supportFlag = 1;

    return ({
      ...sortState,
      customer_id,
      contact_dt: df(sortState.contact_dt),
      support_dt: df(sortState.support_dt),
      support_dt_start: df(sortState.support_dt_start),
      support_dt_end: df(sortState.support_dt_end),
      contact_dt_start: df(sortState.contact_dt_start),
      contact_dt_end: df(sortState.contact_dt_end),
      created_at_start: df(sortState.created_at_start),
      created_at_end: df(sortState.created_at_end),
      updated_at_start: df(sortState.updated_at_start),
      updated_at_end: df(sortState.updated_at_end),
      support_flag: supportFlag,
      sort_by,
    });
  }

  /* 従業員リストのセット */
  static setEditEmployeeList(param: {
    editState: EditState;
    employeeList: MasterEmployee[];
    type: 'contract' | 'support';
    }): MasterEmployee[] {
    const { editState, employeeList, type } = param;
    const target = type === 'contract'
      ? editState.contact_store_id
      : editState.support_store_id;
    return (employeeList.filter((v) => (
      Number.isNaN(target) || target === null
        ? true
        : (v.store_id === target)
    )));
  }

  /** 一覧取得用パラメータ */
  static listParam(param: {
    sortState: SortState;
  }): ResSortState {
    const { sortState } = param;
    const sorts = [0, 15, 1, 8, 2, 11, 3, 14, 5, 6, 7];
    let sort_by = Number.isNaN(sortState.sort_by)
      ? 1
      : sortState.sort_by;
    for (let i = 0; i < sorts.length; i += 1) {
      if (sortState.sort_by === i) {
        sort_by = sorts[i];
      }
    }

    return ({
      ...sortState,
      contact_dt: df(sortState.contact_dt),
      support_dt: df(sortState.support_dt),
      support_dt_start: df(sortState.support_dt_start),
      support_dt_end: df(sortState.support_dt_end),
      contact_dt_start: df(sortState.contact_dt_start),
      contact_dt_end: df(sortState.contact_dt_end),
      created_at_start: df(sortState.created_at_start),
      created_at_end: df(sortState.created_at_end),
      updated_at_start: df(sortState.updated_at_start),
      updated_at_end: df(sortState.updated_at_end),
      support_flag: sortState.support_flag,
      sort_by,
    });
  }

  /** 保存用パラメータ */
  static postParam(param: {
    id: number | undefined;
    editState: EditState,
    files: FilesType[],
    preFiles: FilesType[],
  }): ResPostState {
    const {
      id, editState, files, preFiles,
    } = param;

    const fileNames: string[] = [];
    const fileLists: File[] = [];
    const updateFileIds: number[] = [];
    const updateFileNames: string[] = [];
    let deleteFileIds: number[] = [];

    /* 新規ファイルアップ */
    files.forEach((v) => {
      if (!v.id) {
        fileNames.push(v.name);
        if (v.file) { fileLists.push(v.file); }
      }
    });

    if (id) {
      if (!files.length) {
        deleteFileIds = preFiles.map((v) => v.id);
      } else {
        preFiles.forEach((v) => {
          let isDelete = true;
          for (let i = 0; i < files.length; i += 1) {
            if (files[i].id === v.id) {
              isDelete = false;
              if (files[i].name !== v.name) {
                updateFileIds.push(files[i].id);
                updateFileNames.push(files[i].name);
              }
              break;
            }
          }
          if (isDelete) deleteFileIds.push(v.id);
        });
      }
    }

    return ({
      customer_id: editState.customer_id,
      project_id: editState.project_id,
      contact_hour: editState.contact_hour,
      contact_minutes: editState.contact_minutes,
      contact_store_id: editState.contact_store_id,
      contact_employee_id: editState.contact_employee_id,
      title: editState.title,
      support_category_id: editState.support_category_id,
      source_id: editState.source_id,
      support_comment: editState.support_comment,
      support_store_id: editState.support_store_id,
      support_employee_id: editState.support_employee_id,
      support_flag: editState.support_flag,
      contact_dt: df(editState.contact_dt),
      support_dt: df(editState.support_dt),
      file_name: fileNames,
      file: fileLists,
      update_file_id: updateFileIds,
      update_file_name: updateFileNames,
      delete_file_id: deleteFileIds,
    });
  }

  /* 1件取得データのセット */
  static setGetData(param: {
    data: SupportHistory;
  }): Partial<EditState> {
    const { data } = param;
    return ({
      ...data,
      support_category_id: data.support_id,
      contact_dt: DateFormatter.str2date(data.contact_date),
      support_dt: DateFormatter.str2date(data.support_dt),
      support_flag: data.support_flag ? 1 : 0,
      support_comment: data.comment,
    });
  }

  /** 登録日時のセット */
  static setContactDt(param: {
    contact_dt: Date | null;
  }): Partial<EditState> {
    const { contact_dt } = param;
    return ({
      contact_dt,
      contact_hour: 0,
      contact_minutes: 0,
    });
  }

  /** 顧客情報のセット */
  static setCustomer(param: {
    editState: EditState;
    customer: Customer | CustomerListType;
  }): Partial<EditState> {
    const { editState, customer } = param;
    const isSame = customer.id === editState.customer_id;
    return ({
      customer_name: customer.name,
      customer_id: customer.id,
      project_name: !isSame ? '' : editState.project_name,
      project_id: !isSame ? NaN : editState.project_id,
    });
  }

  /** 顧客情報のセット */
  static setProject(param: {
    project: Project | ProjectListType;
  }): Partial<EditState> {
    const { project } = param;
    return ({
      customer_id: project.customer_id,
      customer_name: project.customer_name,
      project_id: project.id,
      project_name: project.name,
    });
  }

  /** クリア */
  static setClear(): Partial<EditState> {
    return ({
      customer_id: NaN,
      customer_name: '',
      project_id: NaN,
      project_name: '',
    });
  }

  /* ファイル読み込み */
  static setInputFileList(param: {
    fileList: FileList;
    files: FilesType[];
  }):FilesType[] {
    const { fileList, files: _files } = param;
    const files:FilesType[] = cloneDeep(_files);

    for (let i = 0; i < fileList.length; i += 1) {
      const extension = extname(fileList[i].name);
      files.push({
        name: basename(fileList[i].name, extension),
        format: extension,
        file: fileList[i],
        id: 0,
      });
    }
    return (cloneDeep(files));
  }
}
