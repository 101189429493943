import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useMemo, useState } from 'react';
import { MasterBody, MasterGetListParam } from './body/master-body';
import { MasterActions } from '../../../../../redux/master/master.action';
import { DialogActions } from '../../../../../redux/dialog/dialog.action';
import { State } from '../../../../../redux/root.reducer';
import { MasterCollection } from '../master.collection';
import { ContractedCompanyMasterEditDialog } from '../edit-dialogs/contracted-company/contracted-company-master-edit-dialog';
import { Button } from '../../../../ui/button/button';
import { AuthActions } from '../../../../../redux/auth/auth.action';

export const MasterContractedCompanyBody = () => {
  /* Hook */
  const list = useSelector((state:State) => state.master.contractedCompanyList);
  const dispatch = useDispatch();

  /* State */
  const [orderSort, setOrderSort] = useState<MasterGetListParam>({ highlow: 0, sort_by: 0 });
  const [isMuko, setIsMuko] = useState(false);

  /* Callback */
  const getList = useCallback((v: MasterGetListParam) => {
    const sort_by = (() => {
      if (v.sort_by === 12) return 13;
      return v.sort_by ? v.sort_by - 1 : 0;
    })();

    dispatch(MasterActions.api.contractedCompany.getList({
      param: {
        sort_by,
        highlow: v.highlow,
        is_muko: 1,
      },
    }));
    setOrderSort(v);
  }, [isMuko]);

  // eslint-disable-next-line
  const openEditDialog = useCallback((id?: number) => {
    dispatch(DialogActions.push({
      title: '契約会社 編集／追加',
      element: <ContractedCompanyMasterEditDialog id={id} callback={() => getList(orderSort)} />,
    }));
  }, [getList, orderSort, isMuko]);

  const onClickRetransmission = useCallback((mail_address: string) => {
    dispatch(AuthActions.api.password.sendActivate({
      param: {
        mail_address,
        mode: 'company',
      },
      onSuccess: () => {},
    }));
  }, []);

  const tableList = useMemo(() => {
    const filter = isMuko ? list : list.filter((v) => v.valid_flag);
    return filter.map((v) => {
      const isValid = v.valid_flag ? <i className="far fa-circle" /> : '';
      const invitation = v.employee_status < 2 ? (
        <div><label className="invitation_label">招待中</label>&nbsp;
          <Button
            color="secondary"
            size="md"
            onClick={() => onClickRetransmission(v.mail_address)}
          >
            再送信
          </Button>
        </div>
      ) : isValid;
      return ([
        v.id,
        v.name,
        v.mail_address,
        v.tel_no,
        v.post_no,
        v.prefecture,
        v.city,
        v.address,
        v.building_name,
        v.status === 1 ? '有償' : '無償',
        v.accounts,
        invitation,
      ]);
    });
  }, [list, isMuko]);

  return (
    <MasterBody
      header={MasterCollection.contractedCompanyMasterHeader}
      rowDataList={list}
      list={tableList}
      callbackEdit={openEditDialog}
      callbackGetList={getList}
      defaultOrder={0}
      isNoEdit
      callbackIsMuko={setIsMuko}
      tableOption={{
        stringWidth: [
          { index: 0, width: 50 }, // 編集
          // { index: 1, width: 50 }, // ID
          // { index: 1, width: 100 }, // 会社_名称
          // { index: 2, width: 50 }, // 会社_メールアドレス（アドミン用）
          // { index: 3, width: 50 }, // 会社_パスワード（アドミン用）
          // { index: 4, width: 50 }, // 電話番号
          // { index: 5, width: 100 }, // 住所_郵便番号
          // { index: 6, width: 50 }, // 住所_都道府県
          // { index: 7, width: 50 }, // 住所_市区町村
          // { index: 8, width: 50 }, // 住所_地名・番地
          // { index: 9, width: 50 }, // 住所_建築名等
          // { index: 10, width: 50 }, // ステータス（有償・無償）
          // { index: 11, width: 50 }, // アカウント数
          // { index: 12, width: 50 }, // 有効フラグ
        ],
        tdAlign: [
          { index: 0, align: 'center' },
          { index: 1, align: 'center' },
          { index: 2, align: 'left' },
          { index: 3, align: 'left' },
          { index: 4, align: 'left' },
          { index: 5, align: 'left' },
          { index: 6, align: 'left' },
          { index: 7, align: 'left' },
          { index: 8, align: 'left' },
          { index: 9, align: 'left' },
          { index: 10, align: 'left' },
          { index: 11, align: 'center' },
          { index: 12, align: 'center' },
        ],
      }}
    />
  );
};
