import './base.page.pc.scss';
import { ReactElement } from 'react';
import { HeaderPC } from '../layout/header/header.pc';
import { Config } from '../../../config/config';

export type BaseProps = {
  // title: string,
  className?: string,
  children?: ReactElement | ReactElement[],
  onClickOrder?: (isOpen: boolean) => void,
};

export const BasePagePC = (props: BaseProps) => {
  const {
    children,
    onClickOrder,
  } = props;

  return (
    <>
      {/* - header - */}
      <HeaderPC onClickOrder={onClickOrder} />
      {/* - body - */}
      <div className="base_page_body">
        {children}
      </div>
      <footer>
        Copyright&nbsp;&copy;&nbsp;2021 SHIP Inc All Rights Reserved.<span>v{Config.version}</span>
      </footer>
    </>
  );
};
