import React, { ChangeEvent, useCallback } from 'react';
import { ValidationPostNum1, ValidationPostNum2 } from '../../../model/validation';
import { Input } from './input';
import { ValidationNotEmpty } from '../../../model/validation/validation-not-empty';

type Props = {
  value: string | undefined;
  len: 3 | 4;
  touch?: boolean;
  require?: boolean;
  onChange: (v: string) => void;
  onChangeEvent?: (e: ChangeEvent<HTMLInputElement>) => void,
  onCompositionStart?: () => void;
  onCompositionEnd?: () => void;
  onEnterKeyPress?: () => void;
  onBlur?: () => void;
  onFocus?: () => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLDivElement>) => void;
  anotherTouch?: boolean;
  disabled?: boolean;
  isFocus?: boolean;
  onClick?: () => void;
}

export const InputPostNo = (props: Props) => {
  const {
    value,
    len,
    onChange: _onChange,
    touch,
    require,
    disabled,
    isFocus,
    onFocus,
    onBlur,
    onChangeEvent,
    onCompositionStart,
    onCompositionEnd,
    onEnterKeyPress,
    anotherTouch,
    onKeyDown,
    onClick,
  } = props;

  const onChange = useCallback((v: string) => {
    if (v.match(/[^0-9]+/g)) return;
    _onChange(v);
  }, [_onChange]);

  return (
    <Input
      className={len === 3 ? 'postal_code_1' : 'postal_code_2'}
      inputMode="numeric"
      value={value}
      maxLength={len}
      type="number"
      touch={touch}
      require={require}
      onFocus={onFocus}
      onClick={() => onClick?.()}
      onCompositionStart={() => onCompositionStart?.()}
      onCompositionEnd={() => onCompositionEnd?.()}
      onEnterKeyPress={() => onEnterKeyPress?.()}
      anotherMessage={['半角数字で入力してください']}
      anotherTouch={anotherTouch}
      onKeyDown={(e) => onKeyDown?.(e)}
      onChange={(e) => {
        onChange(e.target.value);
        onChangeEvent?.(e);
      }}
      onBlur={() => onBlur?.()}
      validationList={[
        ...(len === 3
          ? [...ValidationPostNum1]
          : [...ValidationPostNum2]),
      ]}
      disabled={disabled}
      isFocus={isFocus}
    />
  );
};
