import { ValidationDatePicker } from '../validation-date-picker';
import { ValidationMax100Million } from '../validation-max-100-million';

export const MasterTaxValidation = (startDay: Date | null, taxRate: string) => {
  window.console.log();
  return (
  // 必須
    !startDay
  || !taxRate

  // バリデーション
  || ValidationDatePicker.filter((v) => !v.run(String(startDay || ''))).length
  || ValidationMax100Million.filter((v) => !v.run(String(taxRate || ''))).length
  );
};
