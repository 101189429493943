/* eslint-disable no-nested-ternary */
import React, {
  useEffect, useState, useCallback, useMemo,
} from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import { Dimmer, Loader } from 'semantic-ui-react';
import { useInView } from 'react-intersection-observer';
import { TableTh } from './table-th/table-th';
import { TableTd } from './table-td/table-td';
import { DisplayElements } from '../../../type/display-elements.type';
import './table.scss';
import { KeyEventListener, KeyEventType } from '../../../utilities/key-event-listener';
import { TableTr } from './table-tr/table-tr';
import { TableOption, ThClick } from '../../../type/table.type';
import { NoDataGuidance } from '../no-data-guidance/no-data-guidance';
import { IntersectionObserverFactory } from '../../../modules/inter-section-observer/inter-section-observer-factory';

type TableProps = {
  /** ヘッダー部分の表示要素 */
  header: DisplayElements[];
  /** 各項目部分の表示要素 */
  lists: DisplayElements[][] | null;
  idList?: number[];
  /** セルをclick可能かどうか */
  clickable?: boolean;
  rowDataList?: any[];
  /** click時のハンドラ */
  onClickRow?: (v: any, i: number, j?: number,
    e?: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => void;
  onClickCell?: (i: number, j: number) => void;
  onDbClick?: (v: any, i?:number, e?: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => void;
  /** @deprecated 複数Shift・cmdクリック */
  onClickMulti?: (v: any[]) => void;
  onClickAlt?: (data?: any, e?: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => void,
  disabledHeader?: boolean;
  isMukoData?: {
    key: string;
    value: any;
    comparison?: (v: any, v2: any) => boolean;
  }[];
  option?: TableOption;
  /** rowSpan */
  rowSpanOptionArr?: {
    line: number;
    item: number;
    length: number;
  }[];
  /** 選択したセルのindex */
  selectedTr?: number[];
  /** ソート */
  sort?: {
    index?: number[];
    onClick: ThClick;
    notSortIndex?: number[];
  };
  className?: string;
  isDnD?: boolean;
  headerReset?: number;
  callbackMoveList?: (showList: DisplayElements[][], dataList: any[]) => void;
  isMukoDisp?: boolean;
  callbackScrollEnd?: () => void;
  unDispNoData?: boolean;
  disabledTh?: number[];
  disabledTr?: number[],
};

export const Table = (props: TableProps) => {
  const {
    header,
    lists,
    clickable,
    option,
    onClickRow,
    rowSpanOptionArr,
    selectedTr,
    sort,
    rowDataList,
    idList,
    onClickMulti,
    onDbClick,
    className,
    isDnD,
    callbackMoveList,
    headerReset,
    onClickCell,
    disabledHeader,
    isMukoDisp,
    callbackScrollEnd,
    unDispNoData,
    disabledTh,
    onClickAlt,
    disabledTr,
    isMukoData,
  } = props;

  /* State */
  /* sort */
  const [tableLists, setTableLists] = useState<{data: DisplayElements[], id:number}[]>([]);
  const [rowList, setRowList] = useState<{data: any, id:number}[]>([]);
  // eslint-disable-next-line
  const [pressKey, setPressKey] = useState<KeyEventType>('');
  const [multiIndex, setMultiIndex] = useState<number[]>([]);
  const [ctrlSelects, setCtrlSelects] = useState<number[]>([]);
  const [selectRow, setSelectRow] = useState<number>(NaN);
  const [borderSpan, setBoderSpan] = useState(option?.borderSpan);

  const { ref, inView } = useInView({
    // オプション
    rootMargin: '-50px', // ref要素が現れてから50px過ぎたら
    triggerOnce: true, // 最初の一度だけ実行
  });

  useEffect(() => {
    if (!inView) return;
    callbackScrollEnd?.();
  }, [inView]);

  // Descending:降順, Ascending:昇順
  const [sortOrder, setSortOrder] = useState<'Descending' | 'Ascending'>(
    'Descending',
  );

  /* Callback */
  const handlerClickTr = useCallback(
    (e: any, i: number) => {
      const isSame = selectRow === i;
      /* Ctrl + Click */
      if (pressKey === 'ctrl' && onClickMulti) {
        setMultiIndex([]);
        setSelectRow(NaN);
        const aryIdx = multiIndex.length ? cloneDeep(multiIndex) : cloneDeep(ctrlSelects);

        if (!Number.isNaN(selectRow) && !aryIdx.length) {
          aryIdx.push(selectRow);
        }

        const findIdx = aryIdx.findIndex((v) => v === i);
        if (findIdx !== -1) {
          aryIdx.splice(findIdx, 1);
        } else if (rowDataList) {
          aryIdx.push(i);
        }
        setCtrlSelects(cloneDeep(aryIdx));
        if (onClickMulti && rowDataList) {
          onClickMulti(cloneDeep(aryIdx.map((v) => rowDataList[v])));
        }
      }

      /* Shift + Click */
      if (pressKey === 'shift' && onClickMulti) {
        if (isSame || (Number.isNaN(selectRow) && !ctrlSelects.length)) return;

        let startIndex = NaN;
        let lastIndex = NaN;
        const rowdata = Number.isNaN(selectRow) ? ctrlSelects[ctrlSelects.length - 1] : selectRow;

        if (rowdata < i) {
          startIndex = rowdata;
          lastIndex = i;
        } else if (rowdata > i) {
          startIndex = i;
          lastIndex = rowdata;
        }

        const ary: number[] = [];
        const dataAry = [];
        for (let idx = startIndex; idx <= lastIndex; idx += 1) {
          ary.push(idx);
          if (rowDataList) {
            dataAry.push(rowDataList[idx]);
          }
        }
        setMultiIndex(cloneDeep(ary));

        if (onClickMulti && rowDataList) {
          onClickMulti(cloneDeep(dataAry));
        }
      }

      if (e.altKey && rowDataList) {
        const find = rowDataList.find((v, _i) => _i === i);
        if (!find) return;
        onClickAlt?.(cloneDeep(find), e);
        return;
      }

      /* 何もなしクリック */
      if (!pressKey || !onClickMulti) {
        setMultiIndex([]);
        setCtrlSelects([]);
        setSelectRow(i);
        if (onClickRow && rowDataList) {
          onClickRow(cloneDeep(rowDataList[i]), i, undefined, e);
        }
      }
    },
    [
      lists,
      idList,
      onClickRow,
      rowDataList,
      onClickMulti,
      setMultiIndex,
      multiIndex,
      selectRow,
      pressKey,
      ctrlSelects,
      selectedTr,
    ],
  );

  const handleClickCell = useCallback((
    i: number, j: number, e: React.MouseEvent<HTMLTableCellElement>,
  ) => {
    if (disabledTr?.includes(j)) {
      e.stopPropagation();
      onClickCell?.(i, j);
    }
  }, [
    lists,
    idList,
    onClickRow,
    rowDataList,
    onClickMulti,
    setMultiIndex,
    multiIndex,
    selectRow,
    pressKey,
    ctrlSelects,
    selectedTr,
  ]);

  const handlerDoubleClickTr = useCallback(
    (i, e: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
      if (onDbClick && rowDataList) {
        onDbClick(cloneDeep(rowDataList[i]), i, e);
        setSelectRow(i);
      }
    },
    [handlerClickTr, rowDataList, onDbClick],
  );

  const sortClickAction = useCallback((index: number) => {
    if (sortOrder === 'Descending') {
      setSortOrder('Ascending');
    } else {
      setSortOrder('Descending');
    }
    if (sort?.onClick) {
      sort.onClick(sortOrder === 'Descending' ? 1 : 0, index);
    }
  }, [sort, sortOrder]);

  /* option */
  const thOptions = {
    stringWidth: option?.stringWidth,
    ellipsisLine: option?.ellipsisLine,
  };

  const tdOptions = {
    tdAlign: option?.tdAlign,
    ellipsisLine: option?.ellipsisLine,
  };

  const sortOption = sort
    ? {
      index: sort?.index || [],
      onClickAction: sortClickAction,
      notSortIndex: sort.notSortIndex,
      sortOrder,
    }
    : undefined;

  const [targetIndex, setTargetIndex] = useState(NaN);

  const validFlagList = useMemo(() => {
    if (!rowDataList) return [];
    if (!rowDataList.length) return [];
    if (isMukoData?.length) {
      const li = rowDataList.map((v) => {
        const results: boolean[] = [];
        isMukoData.forEach((v2) => {
          const comp = v2.comparison ?? ((target: any, value: any) => target === value);
          if (v[v2.key as any]) {
            results.push(!comp(v[v2.key as any], v2.value));
          } else {
            results.push(false);
          }
        });
        return results.includes(true);
      });
      return li;
    }
    const li = rowDataList.map((v) => {
      if (v.valid_flag === undefined
        && v.is_valid === undefined) return false;
      const result = v.valid_flag ?? v.is_valid;
      return Boolean(result);
    });
    return li;
  }, [rowDataList, isMukoData]);

  const onHover = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      setTargetIndex(hoverIndex);
      const dragCard = tableLists[dragIndex];
      const _dragCard = rowList[dragIndex];
      setTableLists(
        update(tableLists, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard],
          ],
        }),
      );
      setRowList(
        update(rowList, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, _dragCard],
          ],
        }),
      );
    },
    [tableLists, rowList],
  );

  const onDrop = useCallback(() => {
    setTargetIndex(NaN);
    if (callbackMoveList) {
      callbackMoveList(tableLists.map((v) => v.data), rowList.map((v) => v.data));
    }
  }, [tableLists, rowList]);

  /* Effect */
  useEffect(() => {
    if (lists) {
      setTableLists(lists.map((v, i) => ({
        data: v,
        id: i + 1,
      })));
    }
  }, [lists]);

  useEffect(() => {
    if (!rowDataList) return;
    setRowList(rowDataList.map((v, i) => ({
      data: v,
      id: i + 1,
    })));
  }, [rowDataList]);

  useEffect(() => {
    setBoderSpan(option?.borderSpan);
  }, [option?.borderSpan, tableLists]);

  const border = useCallback((i: number) => (
    option?.borderSpan
      && option.borderSpan.findIndex((b) => (b === i && i !== tableLists.length - 1)) !== -1
      ? { borderBottom: '3px solid #CCCCCC' }
      : {}), [option?.borderSpan, tableLists]);

  return (
    <>
      <KeyEventListener callback={setPressKey}>
        {/* <div theme={option?.tableWidth}> */}
        <table className={`${className} tables`}>
          <thead>
            <tr>
              <TableTh
                key={`table_th${headerReset || 0}`}
                thList={header}
                thOption={thOptions}
                sort={isDnD ? undefined : sortOption}
                disabledHeader={disabledHeader}
                disabledTr={disabledTh}
              />
            </tr>
          </thead>
          <DndProvider backend={HTML5Backend}>
            {lists
              ? (lists.length > 0)
                ? (
                  <tbody>
                    {tableLists?.map((list, i) => (
                      <TableTr
                        key={i}
                        className={`
                    table-tr 
                    ${clickable ? ' isSelectable' : ''}
                    ${!isDnD && selectedTr && selectedTr?.findIndex((v) => v === i) !== -1 ? 'selected' : ''}
                    ${option?.isSingleColor ? ' isSingleColor' : ''}
                    ${isMukoDisp && !validFlagList[i] ? 'invalid' : ''}
                  `}
                        onClick={(e) => handlerClickTr(e, i)}
                        onDoubleClick={(e) => handlerDoubleClickTr(i, e)}
                        style={border(i)}
                        isDnD={isDnD}
                        id={list.id}
                        onDrop={onDrop}
                        targeIndex={targetIndex}
                        onHover={onHover}
                        index={i}
                        refs={tableLists.length - 1 ? ref : undefined}
                      >
                        <TableTd
                          tdList={list.data}
                          selectable={!!clickable}
                          tdOption={tdOptions}
                          rowSpanOption={rowSpanOptionArr?.find((obj) => obj.line === i)}
                          onClickCell={(_i, e) => handleClickCell(i, _i, e)}
                          onDoubleClick={(_i, e) => disabledTr?.includes(_i) && e.stopPropagation()}
                        />
                      </TableTr>
                    ))}
                  </tbody>
                ) : (!unDispNoData)
                  ? (
                    <NoDataGuidance />
                  )
                  : (<></>)

              : (
                <Dimmer inverted active style={{ zIndex: 0 }}>
                  <Loader size="medium" />
                </Dimmer>
              )}
          </DndProvider>
        </table>
      </KeyEventListener>
    </>
  );
};
