import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { Button } from '../../ui/button/button';
import { DialogActions } from '../../../redux/dialog/dialog.action';
import { EstimateActions } from '../../../redux/estimate/estimate.action';
import { Estimate, EstimateListType } from '../../../type/estimate/estimate.type';
import { EstimatePdfModule } from '../../../modules/pdf/mitsumori/mitsumori.pdf-module';
import { BillPdfModule } from '../../../modules/pdf/seikyu/bill.pdf-module';
import { EstimateShanaiPdfModule } from '../../../modules/pdf/mitsumori-shanai/mitsumori.pdf-module';
import { BillLitePdfModule } from '../../../modules/pdf/seikyu-kanni/bill-lite.pdf-module';
import { MultiPrintPdfModule } from '../../../modules/pdf/multi-print.pdf-module';
import { MitsumoriKoujiPdfModule } from '../../../modules/pdf/mitsumori/mitsumori-kouji.pdf-module';
import { MitsumoriUtiwake1PdfModule } from '../../../modules/pdf/mitsumori/mitsumori-utiwake1.pdf-module';
import { MitsumoriUtiwake2PdfModule } from '../../../modules/pdf/mitsumori/mitsumori-utiwake2.pdf-module';

import { MitsumoriShanaiKoujiPdfModule } from '../../../modules/pdf/mitsumori-shanai/mitsumori-shanai-kouji.pdf-module';
import { MitsumoriShanaiUtiwake1PdfModule } from '../../../modules/pdf/mitsumori-shanai/mitsumori-shanai-utiwake1.pdf-module';
import { MitsumoriShanaiUtiwake2PdfModule } from '../../../modules/pdf/mitsumori-shanai/mitsumori-shanai-utiwake2.pdf-module';

import { SeikyuKoujiPdfModule } from '../../../modules/pdf/seikyu/seikyu-kouji.pdf-module';
import { SeikyuUtiwake1PdfModule } from '../../../modules/pdf/seikyu/seikyu-utiwake1.pdf-module';
import { SeikyuUtiwake2PdfModule } from '../../../modules/pdf/seikyu/seikyu-utiwake2.pdf-module';
import { ApiEstimateGet } from '../../../redux/estimate/api/estimate/api-estiamte';
import { EstimateModel } from '../../../model/estimate/estimate-model';
import { EstimateMeisaiModel } from '../../../model/estimate/estimate-meisai.model';
import { XmlParser } from '../../../parser/xml-parser';
import { MeisaiListXML } from '../../../model/estimate/estimate-meisai.type';
import { BillListType, BillPrintInfoType } from '../../../type/bill/bill.type';
import { MasterTax } from '../../../type/master/master-tax.type';
import { useDidMount } from '../../../hooks/life-cycle';
import { UserAgent } from '../../../utilities/user-agent';
import { Radio } from '../../ui/radio/radio';

export type PrintType =
  'estimate'
  | 'estimateIn'
  | 'claim'
  | 'claimIn'

type Props = {
  type: PrintType;
  estimate?: Estimate;
  estimateListData?: EstimateListType;
  claimPrintData?: BillPrintInfoType;
  claimListData?: BillListType
  taxData?: MasterTax;
}

/**
 * デフォルトのPDFファイル名を決定するメソッド
 * @param type 生成する資料の種類
 * @param customerName お客様名
 * @param id 見積ID 又は 契約ID
 * @param billingItem 請求項目(請求書の場合必須)。契約金、完成金など
 * @returns
 */
const getFileName = (
  type: PrintType,
  customerName: string,
  id: number | string,
  billingItem?: string,
): string => {
  switch (type) {
    case 'estimate':
      return `御見積書_${customerName}様_${id}.pdf`;
    case 'estimateIn':
      return `御見積書（社内用）_${customerName}様_${id}.pdf`;
    case 'claimIn':
      return `請求明細書_${customerName}様_${id}_${billingItem || ''}.pdf`;
    case 'claim':
      return `請求書_${customerName}様_${id}_${billingItem || ''}.pdf`;
    default:
      throw Error(`想定外の type です。処理を見直してください。[type : ${type}]`);
  }
};

export const PrintPreview = (props: Props) => {
  const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars,no-unused-vars
    type: __type, estimate, estimateListData, claimPrintData, claimListData, taxData,
  } = props;
  /* Hooke */
  const dispatch = useDispatch();
  const isSp = UserAgent === 'sp';
  /* State */
  const [index, setIndex] = useState(0);
  const [templateList, setTemplateList] = useState<string[]>([]);
  const [select, setSelect] = useState<PrintType>('estimate');

  const type: PrintType = useMemo(() => (isSp ? select : __type), [select, __type]);
  console.log(type);

  const handleClickPrint = useCallback((mode: 'preview' | 'download') => {
    const base64SvgList = templateList.map(
      (svg) => window.btoa(window.unescape(encodeURIComponent(svg))),
    );

    const _type = !isSp ? type : select;

    const isClaim = _type.indexOf('claim') >= 0;
    const fileName = isClaim
      ? getFileName(_type, claimPrintData?.customer_name || '', claimListData?.id || '', claimListData?.billing_items || '')
      : getFileName(_type, estimate?.customer_name || '', estimate?.id || '');
    dispatch(EstimateActions.api.print({
      id: 1,
      data: {
        type: mode,
        // 「SHIP_CUSTOMER-1088 PDFの出力ファイル名」の最終コメント参照
        file_name: fileName,
        // 横出の印刷しか想定しない
        preset_type: _type === 'claim' ? 1 : 2,
        svg_file: base64SvgList,
      },
    }));
    // dispatch(DialogActions.pop());
  },
  [type, templateList, claimPrintData, estimate, claimListData, isSp, select]);

  /* effect */
  useEffect(() => {
    (async () => {
      /** 見積 */
      if (type === 'estimate') {
        const mod = new MultiPrintPdfModule(
          new EstimatePdfModule(),
          new MitsumoriKoujiPdfModule(),
          new MitsumoriUtiwake1PdfModule(),
          new MitsumoriUtiwake2PdfModule(),
        );
        const meisaiData = await (async () => {
          if (!(estimate?.meisai)) return undefined;
          const meisaiXML = estimate.meisai;
          const parser = new XmlParser();
          return new EstimateMeisaiModel(await parser.parse(meisaiXML));
        })();
        const svgData = estimate && meisaiData
          ? EstimateModel.setSVGDataEstimate(type, estimate, meisaiData)
          : undefined;
        mod.createSvgList([
          svgData?.estimate,
          svgData?.meisai,
          svgData?.detail,
          svgData?.detail2,
        // ], 13).then((v) => {
        ]).then((v) => {
          setTemplateList(v);
        });
      }
      /** 見積 (社内) */
      if (type === 'estimateIn') {
        const mod = new MultiPrintPdfModule(
          new EstimateShanaiPdfModule(),
          new MitsumoriShanaiKoujiPdfModule(),
          new MitsumoriShanaiUtiwake1PdfModule(),
          new MitsumoriShanaiUtiwake2PdfModule(),
        );
        const meisaiData = await (async () => {
          if (!(estimate?.meisai)) return undefined;
          const meisaiXML = estimate.meisai;
          const parser = new XmlParser();
          return new EstimateMeisaiModel(await parser.parse(meisaiXML));
        })();
        const svgData = estimate && meisaiData
          ? EstimateModel.setSVGDataEstimate(type, estimate, meisaiData)
          : undefined;
        /** データのセット */
        mod.createSvgList([
          svgData?.estimate,
          svgData?.meisai,
          svgData?.detail,
          svgData?.detail2,
        ]).then((v) => {
          setTemplateList(v);
        });
      }
      /** 請求 */
      if (type === 'claimIn') {
        const mod = new MultiPrintPdfModule(
          new BillPdfModule(),
          new SeikyuKoujiPdfModule(),
          new SeikyuUtiwake1PdfModule(),
          new SeikyuUtiwake2PdfModule(),
        );
        const meisaiData = await (async () => {
          if (!(claimPrintData?.seikyu_meisai)) return undefined;
          const meisaiXML = claimPrintData.seikyu_meisai;
          const parser = new XmlParser();
          return new EstimateMeisaiModel(await parser.parse(meisaiXML));
        })();
        const svgData = claimPrintData && meisaiData && claimListData
          ? EstimateModel.setSVGDataClaim(
            claimPrintData,
            meisaiData,
            claimListData,
            taxData,
          )
          : undefined;
        /** データのセット */
        mod.createSvgList([
          svgData?.claim,
          svgData?.meisai,
          svgData?.detail,
          svgData?.detail2,
        ], 13).then((v) => {
          setTemplateList(v);
        });
      }
      /** 請求 (簡易) */
      if (type === 'claim') {
        const mod = new BillLitePdfModule();
        const svgData = claimPrintData && claimListData && taxData
          ? EstimateModel.setSVGDataClaimIn(
            claimPrintData,
            claimListData,
            taxData,
          ) : undefined;
        mod.createSvgList(
          svgData?.claim,
        ).then((v) => {
          setTemplateList(v);
        });
      }
      setIndex(0);
    })();
  }, [type, claimPrintData, estimate, claimListData]);
  // useDidMount(() => {
  //   if (taxData) {
  //     console.log(taxData);
  //   }
  // });
  return (
    <>
      {!isSp ? (
        <>
          <div
            style={{
              maxHeight: '800px',
            }}
            className="PrintPreview"
          >
            <div
              className="preview_area"
              dangerouslySetInnerHTML={{ __html: (templateList.length && templateList[index]) || '' }}
            />
            <div className="base_footer">
              <div style={{ display: 'flex' }}>
                <div>
                  <Button
                    size="md"
                    color="primary"
                    onClick={() => handleClickPrint('preview')}
                  >
                    プレビュー
                  </Button>
                  <Button
                    size="md"
                    color="primary"
                    onClick={() => handleClickPrint('download')}
                  >
                    ダウンロード
                  </Button>
                  <Button
                    size="md"
                    color="dark"
                    onClick={() => dispatch(DialogActions.pop())}
                  >
                    キャンセル
                  </Button>
                </div>
                <div>
                  <Button
                    disabled={!(index >= 1)}
                    size="md"
                    color="secondary"
                    onClick={() => setIndex(index - 1)}
                  >
                    前へ
                  </Button>
                  <Button
                    disabled={!(index <= templateList.length - 2)}
                    size="md"
                    color="secondary"
                    onClick={() => setIndex(index + 1)}
                  >
                    次へ
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </>
      )
        : (
          <>
            <div className="edit_sp_body">
              <div className="edit_sp_body_inner">
                <div className="category_wrap">
                  <div className="item_wrap">
                    <Radio
                      label="見積書"
                      name="estimate"
                      checked={select === 'estimate'}
                      onChange={(e) => setSelect(e.target.name)}
                    />
                  </div>

                  <div className="item_wrap">
                    <Radio
                      label="見積書(社内用)"
                      name="estimateIn"
                      checked={select === 'estimateIn'}
                      onChange={(e) => setSelect(e.target.name)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="edit_sp_footer">
              <Button
                color="secondary"
                onClick={() => handleClickPrint('download')}
              >
                ダウンロード
              </Button>
            </div>
          </>
        )}
    </>
  );
};
