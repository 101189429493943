import { cloneDeep, isEqual } from 'lodash';
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EstimateCollection } from '../../../../../collection/estimate/estimate.collection';
import { useDidMount } from '../../../../../hooks/life-cycle';
import { EstimateActions } from '../../../../../redux/estimate/estimate.action';
import { MasterActions } from '../../../../../redux/master/master.action';
import { State } from '../../../../../redux/root.reducer';
import { LeftLabelCheckbox } from '../../../../ui/checkbox/left-label-checkbox/left-label-checkbox';
import { Input } from '../../../../ui/input/input';
import { Select } from '../../../../ui/select/select';
import { SearchBox } from '../../../layout/search-box/search-box.sp';
import './estimate-search-box.sp.scss';
import { EstimateSortState } from '../../../../../type/estimate/estimate.type';
import { useAppSelector } from '../../../../../hooks/use-redux';
import { pulldown } from '../../../../../utilities/pulldown';
import { TagCheckBoxex } from '../../../../ui/tag-check-boxex/tag-check-boxex';
import { TagActions } from '../../../../../redux/tag/tag.action';
import { TagModel } from '../../../../../model/tag/tag';

type Props = {
  sort: EstimateSortState;
  callback: (v:EstimateSortState) => void;
  callbackClear: (v:EstimateSortState) => void;
}

export const EstimateCopySearchBoxSP = (props: Props) => {
  const {
    sort: _sort,
    callback,
    callbackClear,
  } = props;

  /* Hooks */
  const dispatch = useDispatch();
  const user = useAppSelector((v) => v.user);
  const employeeList = useAppSelector((v) => v.master.employeeList);
  const constructionPart = useSelector((state: State) => state.tag.partList);

  /* State */
  const [sort, setSort] = useState(cloneDeep(_sort));

  /* Callback */
  const setState = useCallback((v: Partial<EstimateSortState>) => {
    const data = { ...cloneDeep(sort), ...cloneDeep(v) };
    setSort(data);
  }, [sort]);

  const handleClickSearch = useCallback(() => {
    console.log(sort);
    callback(cloneDeep(sort));
  }, [callback, sort]);

  /* Effect */
  useDidMount(() => {
    dispatch(MasterActions.api.employee.getList({}));
    dispatch(TagActions.api.part.getList());
  });

  useEffect(() => {
    let construction_parts = sort.construction_parts || new TagModel(constructionPart);
    if (!sort.construction_parts?.data.length) {
      construction_parts = new TagModel(cloneDeep(constructionPart));
    }
    setSort({
      ...cloneDeep(sort),
      construction_parts,
    });
  }, [constructionPart]);

  return (
    <SearchBox
      callback={handleClickSearch}
      callbackClear={() => {
        const resData:EstimateSortState = {
          ...EstimateCollection._sortCopyInitialState(user),
          construction_parts: new TagModel(constructionPart),
        };
        callbackClear(cloneDeep(resData));
        setState(cloneDeep(resData));
      }}
    >
      <div className="search_box_sp_body_inner">
        <div className="category_wrap">
          <div className="item_wrap">
            <div className="item_label">案件名</div>
            <Input
              value={sort.project_name}
              onChange={(e) => setState({ project_name: e.target.value })}
            />
          </div>
          <div className="item_wrap">
            <div className="item_label">顧客名</div>
            <Input
              value={sort.customer_name}
              onChange={(e) => setState({ customer_name: e.target.value })}
            />
          </div>
          <div className="item_wrap">
            <div className="item_label">明細</div>
            <Input
              value={sort.meisai}
              onChange={(e) => setState({ meisai: e.target.value })}
            />
          </div>
          <div className="item_wrap">
            <div className="item_label">見積作成者</div>
            <Select
              className="add_text_left"
              defaultLabel="全て"
              value={sort?.created_employee_id}
              onChange={(v) => setState({ created_employee_id: Number(v) })}
              options={pulldown(employeeList)}
            />
          </div>
          <div className="item_wrap tags_form">
            <TagCheckBoxex
              head="工事部位"
              keys="cTag"
              model={sort?.construction_parts}
              onChange={(v) => setState({ construction_parts: v })}
            />
          </div>
        </div>
      </div>
    </SearchBox>
  );
};
