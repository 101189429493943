import { cloneDeep } from 'lodash';
import { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SearchBoxPC } from '../../../../layout/search-box/search-box.pc';
import { DatePicker } from '../../../../../ui/date-picker/date-picker';
import { LeftIconButton } from '../../../../../ui/button/left-icon-button/left-icon-button';
import { CsvActions } from '../../../../../../redux/csv/csv.action';
import { State } from '../../../../../../redux/root.reducer';
import { CsvCustomerRankSort } from '../../../../../../type/csv/csv-sort.type';
import { useDidMount } from '../../../../../../hooks/life-cycle';
import { MasterActions } from '../../../../../../redux/master/master.action';
import { useDateValidation } from '../../../../../../hooks/use-date-validation';
import { DateTermValidation } from '../../../../../../model/validation/date/term-date.validation';

type Props = {
  callback: (v: CsvCustomerRankSort) => void;
}

export const CsvSearchBoxCustomerRank = (props: Props) => {
  const { callback } = props;

  /* Hooks */
  // eslint-disable-next-line
  const dispatch = useDispatch();
  const sortState = useSelector((state: State) => state.csv.customerRankSort);

  /* State */
  const [sort, setSort] = useState(cloneDeep(sortState));

  const termDate = useDateValidation([
    { start: sort.updated_date_start, end: sort.updated_date_end },
  ]);

  /* Callback */
  const setState = useCallback((v: Partial<CsvCustomerRankSort>) => {
    const data = cloneDeep({
      ...sort,
      ...v,
    });
    setSort(data);
    dispatch(CsvActions.setCustomerRankSort(data));
  }, [sort]);

  const handleClickSearch = useCallback(() => {
    if (termDate.messages.length) {
      termDate.check();
      return;
    }
    callback({
      ...cloneDeep(sort),
      limit: sortState.limit,
    });
    dispatch(CsvActions.setCustomerRankSort({
      ...cloneDeep(sort),
      limit: sortState.limit,
    }));
  }, [callback, sort, sortState, termDate]);

  useDidMount(() => {
    dispatch(MasterActions.api.customerRank.getList({}));
  });

  return (
    <>
      <SearchBoxPC openCallback={() => {}}>
        <div id="csv_customer_rank">
          <div className="item_wrap flex_no_wrap_box">
            <div className="item_box">
              <div className="item_head">更新日</div>
              <DatePicker
                date={sort.updated_date_start}
                errorPosBottom
                onChange={(v) => setState({ updated_date_start: v })}
                term={DateTermValidation([
                  {
                    start: sort.updated_date_start,
                    end: sort.updated_date_end,
                  },
                ])}
              />
              <label className="ml_10">〜</label>
              <DatePicker
                date={sort.updated_date_end}
                errorPosBottom
                onChange={(v) => setState({ updated_date_end: v })}
                term={DateTermValidation([
                  {
                    start: sort.updated_date_start,
                    end: sort.updated_date_end,
                  },
                ])}
              />
            </div>
            <LeftIconButton
              label="検索"
              fontAwesomeClass="fas fa-search"
              className="btn_search for_detail"
              size="sm"
              color="secondary"
              onClick={handleClickSearch}
            />
          </div>
        </div>
      </SearchBoxPC>
    </>
  );
};
