import {
  ValidationLengthUnder255,
  ValidationPostNum1,
  ValidationPostNum2,
  ValidationTel,
} from '..';
import { Banks } from '../../../components/pc/pages/master/edit-dialogs/company/store-master-edit-dialog/store-master-edit-dialog';
import { ValidationNumberLengthUnder7 } from '../validation-number-length-under';

export const MasterStoreValidation = (
  name: string,
  furigana: string,
  shortName: string,
  phoneNum: string,
  faxNum: string,
  freeDial: string,
  postalCode1: string,
  postalCode2: string,
  city: string,
  address: string,
  buildingName: string,
  jobTitle: string,
  jobTitlePerson: string,
  holder: string,
  banks: Banks,
) => {
  window.console.log(banks);

  return (

  // バリデーション
    ValidationLengthUnder255.filter((v) => !v.run(String(name || ''))).length
  || ValidationLengthUnder255.filter((v) => !v.run(String(furigana || ''))).length
  || ValidationLengthUnder255.filter((v) => !v.run(String(shortName || ''))).length
  || ValidationTel.filter((v) => !v.run(String(phoneNum || ''))).length
  || ValidationTel.filter((v) => !v.run(String(faxNum || ''))).length
  || ValidationTel.filter((v) => !v.run(String(freeDial || ''))).length
  || ValidationPostNum1.filter((v) => !v.run(String(postalCode1 || ''))).length
  || ValidationPostNum2.filter((v) => !v.run(String(postalCode2 || ''))).length
  || ValidationLengthUnder255.filter((v) => !v.run(String(city || ''))).length
  || ValidationLengthUnder255.filter((v) => !v.run(String(address || ''))).length
  || ValidationLengthUnder255.filter((v) => !v.run(String(buildingName || ''))).length
  || ValidationLengthUnder255.filter((v) => !v.run(String(jobTitle || ''))).length
  || ValidationLengthUnder255.filter((v) => !v.run(String(jobTitlePerson || ''))).length
  || ValidationLengthUnder255.filter((v) => !v.run(String(holder || ''))).length
  || banks.some((bank) => (
    ValidationLengthUnder255.filter((v) => !v.run(String(bank.bank_name || ''))).length
      || ValidationLengthUnder255.filter((v) => !v.run(String(bank.bank_store_name || ''))).length
      || ValidationNumberLengthUnder7.filter((v) => !v.run(String(bank.bank_account_no || ''))).length))
  );
};
