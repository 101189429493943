import { cloneDeep } from 'lodash';
import { MathHelper } from './math-helper';

type CalcData = {
  /** 計算前金額 */
  amount: number;
  /* 税率 */
  taxRate: number;
}

/** 税込→税抜き変換(端数切り上げ) */
export const CalcExTax = (data: CalcData): number => {
  const { amount, taxRate } = data;
  const _amount = cloneDeep(amount);
  return MathHelper.rounding(
    MathHelper.div(
      _amount, MathHelper.plus(taxRate, 1),
    ),
    0,
    'ceil',
  );
};

/* 税抜き→税込計算(端数切捨て) */
export const CalcTaxIn = (data: CalcData): number => {
  const { amount, taxRate } = data;
  const _amount = cloneDeep(amount);
  return MathHelper.rounding(
    MathHelper.times(_amount, taxRate), 0, 'floor',
  );
};
