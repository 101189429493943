/* eslint-disable no-undef */
import { useCallback, useEffect, useState } from 'react';
import { cloneDeep } from 'lodash';
import { useDispatch } from 'react-redux';
import { DialogActions } from '../../../../../redux/dialog/dialog.action';
import { EditSP } from '../../../../dialogs/edit/edit.sp';
import { Button } from '../../../../ui/button/button';
import { TopLabelInputField } from '../../../../ui/input-field/top-label-input-field/top-label-input-field';
import { Input } from '../../../../ui/input/input';
import { Required } from '../../../../ui/required/required';
import './project-edit.sp.scss';
import { Project, ProjectEditState } from '../../../../../type/project/project.type';
import { ProjectCollection } from '../../../../../collection/project/project.collection';
import { ProjectActions } from '../../../../../redux/project/project.action';
import { prefectures, pulldownPrefecture } from '../../../../../collection/prefectures';
import { TagActions } from '../../../../../redux/tag/tag.action';
import { ValidationLengthUnder60 } from '../../../../../model/validation/_validation-length-under-60';
import { Select } from '../../../../ui/select/select';
import { RegistrationAddressMapDialog } from '../../../../ui/map/registration-address-map-dialog/registration-address-map-dialog';
import {
  ValidationLengthUnder100,
  ValidationLengthUnder255,
  ValidationLengthUnder40,
  ValidationLengthUnder50,
  ValidationLengthUnder500,
} from '../../../../../model/validation/validation-length-under';
import { ValidationPostNum1 } from '../../../../../model/validation/validation-post-num1';
import { ValidationPostNum2 } from '../../../../../model/validation/validation-post-num2';
import { ValidationTel } from '../../../../../model/validation/validation-tel';
import { ProjectValidation } from '../../../../../model/validation/project/project.validation';
import { MapActions } from '../../../../../redux/map/map.action';
import { Customer } from '../../../../../type/customer/customer.type';
import { MasterActions } from '../../../../../redux/master/master.action';
import { DatePicker } from '../../../../ui/date-picker/date-picker';
import { TextArea } from '../../../../ui/text-area/text-area';
import { ValidationDatePicker } from '../../../../../model/validation/validation-date-picker';
import { ProjectModel } from '../../../../../model/project/project.model';
import { CustomerSearchSP } from '../../../layout/search-box/customer/customer-search/customer-search.sp';
import { useAppSelector } from '../../../../../hooks/use-redux';
import { useDidMount } from '../../../../../hooks/life-cycle';
import { TagCheckBoxex } from '../../../../ui/tag-check-boxex/tag-check-boxex';
import { pulldown } from '../../../../../utilities/pulldown';
import { TagModel } from '../../../../../model/tag/tag';
import { Message } from '../../../../../collection/message.collection';
import { useRegistrationMap } from '../../../../../hooks/map/use-map';
import { useEditAuthProject } from '../../../../../hooks/use-authority';
import { SearchPostAddressDialog } from '../../../../ui/search-post-address-dialog/search-post-address-dialog';
import { LabelInput } from '../../../../ui/input/label-input';
import { usePostSearch } from '../../../../../hooks/common/use-common';
import { InputPostNoMulti } from '../../../../ui/input/input-post-no-multi';

type Props = {
  id?: number,
  customerData?: Customer;
  callback?: () => void;
}

export const ProjectEditSP = (props: Props) => {
  const {
    id, customerData, callback,
  } = props;

  const user = useAppSelector((v) => (v.user));
  const searchPost = usePostSearch();
  const registrationMap = useRegistrationMap('sp', undefined, undefined, true);

  /* Hooks */
  const dispatch = useDispatch();
  const sortState = useAppSelector((v) => v.project.sort);
  const partList = useAppSelector((v) => (v.tag.partList));
  const [constructionParts, setConstructionParts] = useState<TagModel | null>(null);
  const {
    employeeList,
    originList,
    lostOrderList,
    storeList,
    projectRankList,
  } = useAppSelector((v) => (v.master));

  /* State */
  const [projectInfo, setProjectInfo] = useState<Project | null>(null);
  const [project, setProject] = useState<ProjectEditState>(ProjectCollection.initialEditState);
  const [customerName, setCustomerName] = useState('');
  const [customerAddress, setCustomerAddress] = useState('');
  const [touch, setTouch] = useState(false);
  const editAuth = useEditAuthProject(projectInfo);

  /* Callback */
  const setState = useCallback((v: Partial<ProjectEditState>) => {
    setProject({ ...cloneDeep(project), ...cloneDeep(v) });
  }, [setProject, project]);

  const handleClickPost = useCallback(() => {
    if (!editAuth) return;
    /* 保存API */
    if (ProjectValidation(project)) {
      dispatch(DialogActions.pushMessage({
        title: '案件情報登録',
        message: Message.postError,
        callback: () => setTouch(true),
      }));
      return;
    }
    const prefe = prefectures.find((v) => v.value === project.field_prefecture)?.label;
    dispatch(MapActions.api.geocoder({
      isRegist: true,
      param: {
        param: {
          address: `${prefe || ''}${project.field_city}${project.field_address}`,
        },
        noMessage: true,
      },
      callback: (data) => {
        dispatch(ProjectActions.api.project.post({
          param: {
            data: ProjectModel.postParam({
              project,
              data: {
                ...data,
                constructionParts,
              },
            }),
            id,
          },
          update: !!id,
          onSuccess: callback,
          onError: () => setTouch(true),
        }));
      },
    }));
  },
  [project, sortState, editAuth, constructionParts]);

  const handleClickRegistrationMap = useCallback(() => {
    if (!editAuth) return;
    registrationMap((address) => {
      if (!address) return;
      setState(ProjectModel.registMap(address.components));
    });
  }, [project, editAuth]);

  const handleClickCustomerSearch = useCallback((isAdd?: boolean) => {
    if (!editAuth) return;
    dispatch(DialogActions.push({
      title: '顧客検索',
      onCloseClick: () => {
        // if (!isAdd) return;
        // dispatch(DialogActions.pop());
      },
      element: <CustomerSearchSP callback={(data) => {
        setState(ProjectModel.setCustomerData(data));
        setCustomerName(data.name || '');
        setCustomerAddress(
          `${data.prefecture_name}${data.address || ''}${data.building_name || ''}`,
        );
      }}
      />,
    }));
  }, [project, editAuth]);

  const handleClickSearchAddress = useCallback(() => {
    if (!editAuth) return;
    dispatch(MapActions.api.addressSearch({
      param: {
        zipcode1: String(project.field_post_no[0]),
        zipcode2: String(project.field_post_no[1]),
      },
      callback: (address) => setState(ProjectModel.setAddress(address)),
    }));
  },
  [project, editAuth]);

  const searchPostNo = useCallback(() => {
    if (!editAuth) return;
    searchPost({
      zipcode1: project.field_post_no[0],
      zipcode2: project.field_post_no[1],
      jisx0402: project.field_jisx0402_code || '',
      pref: project.field_prefecture,
      city: project.field_city,
      town: project.field_address,
      calback: (v) => {
        setState({
          field_post_no: [
            v.zipcode1,
            v.zipcode2,
          ],
          field_prefecture: v.pref,
          field_city: v.city,
          field_jisx0402_code: v.jisx0402,
          field_address: v.town,
        });
      },
    });
  }, [editAuth, project]);

  useEffect(() => {
    if (customerData) {
      setState({
        ...ProjectModel.setCustomerData(customerData),
      });
      setConstructionParts(new TagModel(partList));
      // setCustomerID(customerData.id);
      setCustomerName(customerData.name);
      const place = `〒${customerData.post_no.slice(0, 3)}-${customerData.post_no.slice(3, 7)} ${customerData.prefecture_name}${customerData.city}${customerData.address}${customerData.building_name || ''}`;
      setCustomerAddress(place);
      return;
    }
    if (id !== undefined) {
      dispatch(ProjectActions.api.project.get({
        param: { id },
        callback: (data) => {
          setProjectInfo(cloneDeep(data));
          setCustomerName(data.customer_name || '');
          setCustomerAddress(data.customer_place || '');
          setState(ProjectModel.setEditData({ project, data, partList }));
        },
      }));
    }

    if (!id) {
      setConstructionParts(new TagModel(partList));
    }
  }, [id, partList]);

  useDidMount(() => {
    dispatch(TagActions.api.part.getList());
    dispatch(MasterActions.api.origin.getList({}));
    dispatch(MasterActions.api.store.getList({}));
    dispatch(MasterActions.api.employee.getList({}));
    dispatch(MasterActions.api.lostOrder.getList({}));
    dispatch(MasterActions.api.projectRank.getList({}));
    if (id || customerData) return;
    handleClickCustomerSearch(true);
  });

  useEffect(() => {
    if (!projectInfo || !partList.length) return;
    setConstructionParts(cloneDeep(
      new TagModel(partList, projectInfo.construction_parts) || new TagModel(partList),
    ));
  }, [projectInfo, partList]);

  return (
    <EditSP
      mode={id ? 'update' : 'add'}
      disabled={!editAuth}
      callback={handleClickPost}
    >
      {/* edit_sp_body_inner は各画面の共通用 */}
      <div className="edit_sp_body_inner projectEditSP">
        <div className="category_wrap">
          <div className="item_wrap">
            <div className="item_label">顧客ID<Required /></div>
            <div className="item_body item_customerID">
              <Input
                type="number"
                value={project.customer_id}
                disabled
                require
                touch={touch}
              />
              <Button
                size="md"
                color="secondary"
                disabled={!editAuth}
                onClick={() => handleClickCustomerSearch()}
              >
                顧客検索
              </Button>
            </div>
          </div>
          <div className="item_wrap">
            <TopLabelInputField
              label="顧客名"
              value={customerName}
              className="full_width"
              disabled
            />
          </div>
          <div className="item_wrap">
            <TopLabelInputField
              label="顧客住所"
              value={customerAddress}
              className="full_width"
              disabled
            />
          </div>
        </div>

        <div className="category_wrap">
          <div className="item_wrap">
            <TopLabelInputField
              label="案件名"
              value={project.name}
              onChange={(e) => setState({ name: e.target.value })}
              className="full_width"
              disabled={!editAuth}
              require
              touch={touch}
              validationList={ValidationLengthUnder40}
            />
          </div>
          <div className="item_wrap">
            <div className="item_label">発生源</div>
            <div className="item_body item_select full_width">
              <Select
                value={project.source_id}
                onChange={(v) => setState({ project_source_id: Number(v), source_id: Number(v) })}
                defaultLabel="指定なし"
                options={pulldown(originList)}
                disabled={!editAuth}
              />
            </div>
          </div>
          <div className="item_wrap">
            <div className="item_label">案件見込みランク</div>
            <div className="item_body item_select full_width">
              <Select
                value={project.project_rank}
                onChange={(v) => setState({ project_rank: Number(v) })}
                defaultLabel="指定なし"
                options={pulldown(projectRankList)}
                disabled={!editAuth}
              />
            </div>
          </div>
          <div className="item_wrap">
            <div className="item_label">案件担当 店舗</div>
            <div className="item_select">
              <Select
                value={project.project_store_id}
                onChange={(v) => setState({ project_store_id: Number(v) })}
                defaultLabel="指定なし"
                options={pulldown(storeList)}
                disabled={!editAuth || !user.authority1}
              />
            </div>
          </div>
          <div className="item_wrap">
            <div className="item_label">案件担当 担当者<Required /></div>
            <div className="item_select">
              <Select
                value={project.project_employee_id}
                onChange={(v) => setState({ project_employee_id: Number(v) })}
                defaultLabel="指定なし"
                options={pulldown(employeeList)}
                require
                touch={touch}
                disabled={!editAuth || !user.authority1}
              />
            </div>
          </div>
        </div>

        <div className="category_wrap">
          <div className="item_wrap ">
            <div className="item_label">
              現場名称
            </div>
          </div>
          <div className="item_body">
            <Input
              value={project.field_name}
              onChange={(e) => setState({ field_name: e.target.value })}
              validationList={ValidationLengthUnder60}
              className="full_width"
              require
              touch={touch}
              disabled={!editAuth}
            />
          </div>
        </div>
        <div className="category_wrap">
          <div className="item_wrap flex_box flex_space_between">
            <Button
              size="md"
              color="secondary"
              className="flex_1"
              onClick={handleClickRegistrationMap}
              disabled={!editAuth}
            >
              地図から登録
            </Button>

            <Button
              size="md"
              color="secondary"
              onClick={searchPostNo}
              className="ml_10 flex_1"
              disabled={!editAuth}
            >
              詳細住所入力
            </Button>
          </div>
          <div className="item_wrap">
            <div className="item_label">郵便番号<Required /></div>
            <div className="item_body item_postal">
              <InputPostNoMulti
                value={project.field_post_no[0]}
                value2={project.field_post_no[1]}
                require
                require2
                disabled={!editAuth}
                disabled2={!editAuth}
                touch={touch}
                touch2={touch}
                onChange={(v) => {
                  setState({
                    field_post_no: [
                      v,
                      project.field_post_no[1],
                    ],
                  });
                }}
                onChange2={(v) => setState({
                  field_post_no: [
                    project.field_post_no[0],
                    v,
                  ],
                })}
                isSp
              />
              <Button
                size="md"
                color="secondary"
                onClick={handleClickSearchAddress}
                className="ml_10 flex_1"
                disabled={!editAuth}
              >
                住所入力
              </Button>
            </div>
          </div>
          <div className="item_wrap">
            <div className="item_label">顧客都道府県<Required /></div>
            <div className="item_body item_select">
              <Select
                value={project.field_prefecture}
                defaultLabel="-"
                onChange={(v) => setState({ field_prefecture: Number(v) })}
                options={pulldownPrefecture}
                require
                touch={touch}
                disabled={!editAuth}
              />
            </div>
          </div>
          <div className="item_wrap">
            <TopLabelInputField
              label="市区町村"
              value={project.field_city}
              onChange={(e) => setState({
                field_city: e.target.value,
                field_jisx0402_code: undefined,
              })}
              validationList={ValidationLengthUnder255}
              className="full_width"
              require
              touch={touch}
              disabled={!editAuth}
            />
          </div>
          <div className="item_wrap">
            <TopLabelInputField
              label="地名・番地"
              value={project.field_address}
              onChange={(e) => setState({ field_address: e.target.value })}
              validationList={ValidationLengthUnder255}
              className="full_width"
              require
              touch={touch}
              disabled={!editAuth}
            />
          </div>
          <div className="item_wrap">
            <TopLabelInputField
              label="建物名"
              value={project.field_building_name}
              onChange={(e) => setState({ field_building_name: e.target.value })}
              validationList={ValidationLengthUnder255}
              className="full_width"
              disabled={!editAuth}
            />
          </div>
          <div className="item_wrap">
            <LabelInput
              pos="Top"
              label="現場電話"
              type="tel"
              value={project.field_tel_no}
              onChange={(v) => setState({ field_tel_no: v })}
              className="full_width"
              disabled={!editAuth}
            />
          </div>
          <div className="item_wrap">
            <LabelInput
              pos="Top"
              label="現場FAX"
              type="tel"
              value={project.field_fax_no}
              onChange={(v) => setState({ field_fax_no: v })}
              className="full_width"
              disabled={!editAuth}
            />
          </div>
        </div>
        <div className="category_wrap">
          <div className="item_wrap tags_form">
            <TagCheckBoxex
              head="工事部位"
              keys="pTag"
              model={constructionParts}
              onChange={(v) => setConstructionParts(v)}
              disabled={!editAuth}
            />
          </div>
        </div>
        {id && (
        <div className="category_wrap">
          <div className="item_wrap">
            <div className="item_label">着工日</div>
            <div className="item_body">
              <DatePicker
                date={project.construction_date}
                onChange={(v) => setState({ construction_date: v })}
                disabled={!editAuth || !projectInfo?.contract_date}
              />
            </div>
          </div>

          <div className="item_wrap">
            <div className="item_label">完工日</div>
            <div className="item_body">
              <DatePicker
                date={project.completion_date}
                onChange={(v) => setState({ completion_date: v })}
                disabled={!editAuth || !projectInfo?.contract_date}
              />
            </div>
          </div>

          <div className="item_wrap">
            <div className="item_label">完了日</div>
            <div className="item_body">
              <DatePicker
                date={project.complete_date}
                onChange={(v) => setState({ complete_date: v })}
                disabled={!editAuth}
              />
            </div>
          </div>

          <div className="item_wrap">
            <div className="item_label">失注日</div>
            <div className="item_body">
              <DatePicker
                date={project.failure_date}
                onChange={(v) => setState({ failure_date: v })}
                disabled={!editAuth}
              />
            </div>
          </div>

          <div className="item_wrap">
            <div className="item_label">失注理由</div>
            <div className="item_body">
              <Select
                value={project.failure_cause}
                onChange={(v) => setState({ failure_cause: Number(v) })}
                defaultLabel="指定無し"
                options={lostOrderList.map((v) => (
                  { text: v.lost_reason, value: v.id }
                ))}
                disabled={!editAuth}
              />
            </div>
          </div>

          <div className="item_wrap">
            <div className="item_label">備考</div>
            <div className="item_body">
              <TextArea
                rows={7}
                value={project?.failure_remarks}
                className="large"
                onChange={(e) => setState({ failure_remarks: e.target.value })}
                disabled={!editAuth}
                validationList={ValidationLengthUnder500}
              />
            </div>
          </div>

          <div className="item_wrap">
            <div className="item_label">キャンセル日</div>
            <div className="item_body">
              <DatePicker
                date={project.cancel_date}
                onChange={(v) => setState({ cancel_date: v })}
                disabled={!editAuth}
              />
            </div>
          </div>

          <div className="item_wrap">
            <div className="item_label">キャンセル理由</div>
            <div className="item_body">
              <TextArea
                rows={7}
                value={project?.cancel_reason}
                onChange={(e) => setState({ cancel_reason: e.target.value })}
                validationList={ValidationLengthUnder255}
                disabled={!editAuth}
              />
            </div>
          </div>
        </div>
        )}
      </div>

    </EditSP>
  );
};
