import actionCreatorFactory from 'typescript-fsa';
import { User } from '../../type/auth/user.typs';
import { SupportHistory, SupportHistorySortState, SupportHistoryListType } from '../../type/support-history/support-history.type';
import { apiSupportHistory } from './api/api-support-history/api-support-history';

const ActionCreator = actionCreatorFactory('support/history');

export const SupportHistoryActions = {
  api: {
    supportHistory: apiSupportHistory,
  },
  setSupportHistory: ActionCreator<SupportHistory | null>('set/support-history'),
  setList: ActionCreator<SupportHistoryListType[] | null>('set/list'),
  setConcatList: ActionCreator<SupportHistoryListType[] | null>('set/concat/list'),
  setInitSort: ActionCreator<User>('set/init/sort'),
  setSort: ActionCreator<Partial<SupportHistorySortState> | null>('set/sort'),
  resetState: ActionCreator('reset/state'),
};
