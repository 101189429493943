/* eslint-disable no-nested-ternary */
import { push } from 'connected-react-router';
import { cloneDeep } from 'lodash';
import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useInView } from 'react-intersection-observer';
import { State } from '../../../../../../redux/root.reducer';
import { RoutingPath } from '../../../../../../routes/routing-pass';
import { EstimateListType } from '../../../../../../type/estimate/estimate.type';
// import { File } from '../../../../../../type/file/file';
import { EstimateCard } from '../../../../../ui/card/estimate/estimate-card';
import { MeisaiListXML } from '../../../../../../model/estimate/estimate-meisai.type';
import { CardList } from '../../../../../ui/card/card-list/card-list';
import { useWillUnMount } from '../../../../../../hooks/life-cycle';

type Props = {
  isCopy?: boolean;
  data?: EstimateListType[] | null;
  handleCardClick?: (estimate: EstimateListType) => void;
}

export const EstimateListSP = (props:Props) => {
  const {
    data, handleCardClick, isCopy,
  } = props;

  /* Hooks */
  const dispatch = useDispatch();
  const estimateList = useSelector((state: State) => state.estimate.list);
  const dataList = useMemo(() => data || estimateList, [estimateList, data]);

  /* State */
  // eslint-disable-next-line
  const [selectRow, setSelectRow] = useState<EstimateListType | null>(null);

  const { ref, inView } = useInView({
    // オプション
    rootMargin: '-50px', // ref要素が現れてから50px過ぎたら
    triggerOnce: true, // 最初の一度だけ実行
  });
  /* Callback */
  const handleClickCard = useCallback((row:EstimateListType) => {
    if (handleCardClick) {
      handleCardClick(cloneDeep(row));
      setSelectRow(cloneDeep(row));
      return;
    }
    dispatch(push(`${RoutingPath.estimateDetail}/${row.id}`));
  }, [dataList, handleCardClick]);

  return (
    <CardList isLoading={inView} list={dataList}>
      {/* {data ? data.map((v, i) => (
        <div
          key={`card${i}`}
          className={`list_base_card_wrap${selectRow?.id === v.id ? ' active' : ''}`}
          ref={data.length - 1 ? ref : undefined}
        >
          <EstimateCard
            data={v}
            callback={handleClickCard}
            isCopy={isCopy}
          />
        </div>
      )) : dataList ? dataList.map((v, i) => ( */}
      {dataList ? dataList.map((v, i) => (
        <div
          key={`card${i}`}
          className={`list_base_card_wrap ${selectRow?.id === v.id ? 'active' : ''}`}
          ref={dataList.length - 1 ? ref : undefined}
        >
          <EstimateCard
            data={v}
            callback={handleClickCard}
            isCopy={isCopy}
          />
        </div>
      )) : null}
    </CardList>
  );
};
