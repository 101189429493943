import lodash, { cloneDeep, isEqual } from 'lodash';
import { useState, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SearchBoxPC } from '../../../../layout/search-box/search-box.pc';
import { Select } from '../../../../../ui/select/select';
import { LeftIconButton } from '../../../../../ui/button/left-icon-button/left-icon-button';
import { CsvActions } from '../../../../../../redux/csv/csv.action';
import { State } from '../../../../../../redux/root.reducer';
import { CsvOrderSort } from '../../../../../../type/csv/csv-sort.type';
import { CommonCollection } from '../../../../../../collection/common/common.collection';
import { RightLabelCheckbox } from '../../../../../ui/checkbox/right-label-checkbox/right-label-checkbox';
import { Input } from '../../../../../ui/input/input';
import { useDidMount } from '../../../../../../hooks/life-cycle';
import { MasterActions } from '../../../../../../redux/master/master.action';
import { TagActions } from '../../../../../../redux/tag/tag.action';
import { TagModel } from '../../../../../../model/tag/tag';
import { LabelInput } from '../../../../../ui/input/label-input';
import { InputTel } from '../../../../../ui/input/input-tel';
import { InputNum, InputNumByNum } from '../../../../../ui/input/input-num';
import { MathHelper as mh, MathHelper as MH } from '../../../../../../utilities/math-helper';
import { DatePicker } from '../../../../../ui/date-picker/date-picker';
import { useDateValidation } from '../../../../../../hooks/use-date-validation';
import { pulldown } from '../../../../../../utilities/pulldown';
import { DateTermValidation } from '../../../../../../model/validation/date/term-date.validation';
import { DialogActions } from '../../../../../../redux/dialog/dialog.action';

type Props = {
  openCallback: (v: boolean) => void;
  callback: (v: CsvOrderSort) => void;
}

export const CsvSearchBoxOrder = (props: Props) => {
  const { openCallback, callback } = props;

  /* Hooks */
  const dispatch = useDispatch();
  const sortState = useSelector((state: State) => state.csv.orderSort);
  const {
    // eslint-disable-next-line
    storeList,
    // eslint-disable-next-line
    employeeList,
  } = useSelector((state: State) => (state.master), isEqual);
  const {
    partList,
  } = useSelector((state: State) => (state.tag), isEqual);

  /* State */
  const [sort, setSort] = useState(cloneDeep(sortState));
  const [moneyValid, setMoneyValid] = useState(false);

  const setState = useCallback((v: CsvOrderSort) => {
    setSort({ ...cloneDeep(sort), ...cloneDeep(v) });
  }, [sort]);

  const _employeeList = useMemo(() => {
    const li = employeeList.filter((
      v,
    ) => (Number.isNaN(sort.project_store_id) || sort.project_store_id === undefined
      ? true
      : (v.store_id === sort.project_store_id)));
    if (!li.find((v) => v.id === sort.project_employee_id)) setState({ project_employee_id: NaN });
    return li;
  },
  [sort.project_employee_id, employeeList, sort.project_store_id]);

  const moneyValidate = useMemo(() => {
    if (sort.order_price_min === undefined
        || sort.order_price_max === undefined) return false;
    if (sort.order_price_min <= sort.order_price_max) return false;
    return true;
  }, [sort]);

  const moneyMessage = useMemo(() => (moneyValid ? ['正しい金額範囲を入力してください。'] : undefined), [moneyValid]);

  const termDate = useDateValidation([
    { start: sort.created_at_start || null, end: sort.created_at_end || null },
    { start: sort.updated_at_start || null, end: sort.updated_at_end || null },
  ]);

  /* Callback */

  const handleClickSearch = useCallback(() => {
    if (termDate.messages.length) {
      termDate.check(moneyMessage);
      return;
    } if (moneyMessage) {
      dispatch(DialogActions.pushMessage({
        title: 'お知らせ',
        message: moneyMessage,
      }));
      return;
    }

    callback({
      ...cloneDeep(sort),
      limit: sortState.limit,
    });
    dispatch(CsvActions.setOrderSort({
      ...cloneDeep(sort),
      limit: sortState.limit,
    }));
  }, [callback, sort, termDate, sortState]);

  /* Master */
  useDidMount(() => {
    dispatch(MasterActions.api.store.getList({}));
    dispatch(MasterActions.api.employee.getList({}));
    dispatch(MasterActions.api.customerRank.getList({}));
    dispatch(TagActions.api.part.getList());
    if (!sort.construction_parts) {
      setState({
        ...lodash.cloneDeep(sort),
        construction_parts: new TagModel(partList),
      });
    } else {
      setState({
        ...lodash.cloneDeep(sort),
      });
    }
  });

  return (
    <>
      <SearchBoxPC
        openCallback={openCallback}
        isDetail
      >
        <div id="csv_order">
          <div className="item_wrap">
            <div className="item_box">
              <div className="item_head">完工時期</div>
              <Select
                className="add_text_right"
                value={sort?.completion_date_from_year}
                onChange={(v) => setState({ ...sort, completion_date_from_year: Number(v) })}
                defaultLabel="全て"
                options={CommonCollection.year}
              />
              <label>年</label>

              <Select
                className="add_text_right"
                defaultLabel="全て"
                value={sort?.completion_date_from_month}
                onChange={(v) => setState({ ...sort, completion_date_from_month: Number(v) })}
                options={CommonCollection.month}
              />
              <label>月</label>
              <label>～</label>
              <Select
                className="add_text_right"
                defaultLabel="全て"
                value={sort?.completion_date_to_year}
                onChange={(v) => setState({ ...sort, completion_date_to_year: Number(v) })}
                options={CommonCollection.year}
              />
              <label>年</label>
              <Select
                className="add_text_right"
                defaultLabel="全て"
                value={sort?.completion_date_to_month}
                onChange={(v) => setState({ ...sort, completion_date_to_month: Number(v) })}
                options={CommonCollection.month}
              />
              <label>月</label>
            </div>
          </div>

          <div className="item_wrap">
            <div className="item_box">
              <div className="item_head">受注金額</div>
              <LabelInput pos="Right" label="円">
                <InputNumByNum
                  className=""
                  value={sort?.order_price_min !== undefined
                    ? MH.localStr(sort?.order_price_min)
                    : undefined}
                  onChange={(v) => setState({ ...sort, order_price_min: v === '' ? undefined : MH.localStrToNum(v) })}
                  onBlur={() => setMoneyValid(moneyValidate)}
                  errorMessage={moneyMessage}
                />
              </LabelInput>
              <label className="">〜</label>
              <LabelInput pos="Right" label="円">
                <InputNumByNum
                  className=""
                  value={sort?.order_price_max !== undefined
                    ? MH.localStr(sort?.order_price_max)
                    : undefined}
                  onChange={(v) => setState({ ...sort, order_price_max: v === '' ? undefined : MH.localStrToNum(v) })}
                  onBlur={() => setMoneyValid(moneyValidate)}
                  errorMessage={moneyMessage}
                />
              </LabelInput>
            </div>
            <div className="item_box">
              <div className="item_head">粗利(%)</div>
              <InputNumByNum
                className=""
                value={
                  sort.margin_ritu !== undefined
                    ? MH.localStr(sort.margin_ritu)
                    : undefined
                  }
                onChange={(v) => setState({
                  ...sort,
                  margin_ritu: v === '' ? undefined : v,
                })}
                intLen={5}
              />
              <Select
                className="add_text_left"
                value={sort?.margin_ritu_filter}
                onChange={(v) => setState({ ...sort, margin_ritu_filter: Number(v) })}
                options={[
                  { text: '以上', value: 1 },
                  { text: '以下', value: 2 },
                ]}
              />
            </div>
          </div>
          <div className="item_wrap">
            <div className="item_box">
              <div className="item_head">部位</div>
              <div className="flex_wrap_box">
                {sort?.construction_parts?.data.map((v, i) => (
                  <div key={`tag${i}`}>
                    <RightLabelCheckbox
                      className="customerPC__body__inner__checkbox"
                      key={v.id}
                      label={v.label}
                      checked={v.flag}
                      onChange={() => {
                        sort.construction_parts?.changeFlag(v.id);
                        setState({
                          ...sort, construction_parts: lodash.cloneDeep(sort.construction_parts),
                        });
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
            <LeftIconButton
              label="検索"
              fontAwesomeClass="fas fa-search"
              className="btn_search for_simple"
              size="sm"
              color="secondary"
              onClick={handleClickSearch}
            />
          </div>
          <div className="search_detail">
            <div className="item_wrap">
              <div className="item_box">
                <div className="item_head">営業担当</div>
                <Select
                  className="add_text_left"
                  label="店舗"
                  value={sort?.project_store_id}
                  onChange={(data) => setSort({ ...sort, project_store_id: Number(data) })}
                  defaultLabel="全て"
                  options={storeList.map((v) => ({
                    text: v.name, value: v.id,
                  }))}
                />
                <Select
                  className="add_text_left"
                  label="担当者"
                  value={sort?.project_employee_id}
                  onChange={(data) => setSort({ ...sort, project_employee_id: Number(data) })}
                  defaultLabel="全て"
                  options={_employeeList.map((v) => ({
                    text: v.name, value: v.id,
                  }))}
                />
              </div>
            </div>
            <div className="item_wrap">
              <div className="item_box">
                <div className="item_head">顧客名</div>
                <Input
                  className=""
                  value={sort?.customer_name}
                  onChange={(e) => setState({ ...sort, customer_name: e.target.value })}
                />
              </div>
              <div className="item_box">
                <div className="item_head">案件名</div>
                <Input
                  className=""
                  value={sort?.name}
                  onChange={(e) => setState({ ...sort, name: e.target.value })}
                />
              </div>
            </div>
            <div className="item_wrap">
              <div className="item_box">
                <div className="item_head">現場名称</div>
                <Input
                  className=""
                  value={sort?.field_name}
                  onChange={(e) => setState({ ...sort, field_name: e.target.value })}
                />
              </div>
              <div className="item_box">
                <div className="item_head">現場電話番号</div>
                <InputTel
                  value={sort?.field_tel_no}
                  onChange={(v) => setState({ ...sort, field_tel_no: v })}
                />
              </div>
            </div>
            <div className="item_wrap">
              <div className="item_box">
                <div className="item_head">登録者</div>
                <Select
                  defaultLabel="全て"
                  className="fixed"
                  value={sort.created_employee_id}
                  onChange={(v) => setState({ created_employee_id: Number(v) })}
                  options={pulldown(employeeList)}
                />
              </div>
              <div className="item_box">
                <div className="item_head">登録日</div>
                <DatePicker
                  date={sort.created_at_start || null}
                  onChange={(v) => setState({ created_at_start: v })}
                  type="YYYY/MM/DD"
                  term={DateTermValidation([
                    { start: sort.created_at_start || null, end: sort.created_at_end || null },
                  ])}
                />
                <label className="ml_10">～</label>
                <DatePicker
                  date={sort.created_at_end || null}
                  onChange={(v) => setState({ created_at_end: v })}
                  type="YYYY/MM/DD"
                  term={DateTermValidation([
                    { start: sort.created_at_start || null, end: sort.created_at_end || null },
                  ])}
                />
              </div>
              <div className="item_box">
                <div className="item_head">更新者</div>
                <Select
                  defaultLabel="全て"
                  className="fixed"
                  value={sort.updated_employee_id}
                  onChange={(v) => setState({ updated_employee_id: Number(v) })}
                  options={pulldown(employeeList)}
                />
              </div>
              <div className="item_box">
                <div className="item_head">更新日</div>
                <DatePicker
                  date={sort.updated_at_start || null}
                  onChange={(v) => setState({ updated_at_start: v })}
                  type="YYYY/MM/DD"
                  term={DateTermValidation([
                    { start: sort.updated_at_start || null, end: sort.updated_at_end || null },
                  ])}
                />
                <label className="ml_10">～</label>
                <DatePicker
                  date={sort.updated_at_end || null}
                  onChange={(v) => setState({ updated_at_end: v })}
                  type="YYYY/MM/DD"
                  term={DateTermValidation([
                    { start: sort.updated_at_start || null, end: sort.updated_at_end || null },
                  ])}
                />
              </div>
              <LeftIconButton
                label="検索"
                fontAwesomeClass="fas fa-search"
                className="btn_search for_detail"
                size="sm"
                color="secondary"
                onClick={handleClickSearch}
              />
            </div>
          </div>
        </div>
      </SearchBoxPC>
    </>
  );
};
