import { ValidationLengthUnder255 } from '..';

export const MasterRelevantTagValidation = (name: string) => {
  window.console.log();
  return (
  // 必須
    !name

  // バリデーション
  || ValidationLengthUnder255.filter((v) => !v.run(String(name || ''))).length
  );
};
