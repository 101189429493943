import { useDispatch } from 'react-redux';
import {
  memo, useCallback, useEffect, useState,
} from 'react';
import { UserAgent } from '../../../../utilities/user-agent';
import { Button } from '../../../ui/button/button';
import { DialogActions } from '../../../../redux/dialog/dialog.action';
import { StepCard } from './step-card/step-card';
import './step-preview-dialog.scss';
import { LeftIconButton } from '../../../ui/button/left-icon-button/left-icon-button';

type Props = {
  data: globalThis.google.maps.DirectionsLeg;
  legIndex?: number;
  startValue?: string;
  endValue?: string;
  img?: string;
}

export const StepPreviewDialog = ((props: Props) => {
  // eslint-disable-next-line
  const { data, legIndex, startValue, endValue, img, } = props;

  const dispatch = useDispatch();
  const [isPrint, setIsPrint] = useState(false);

  const handleClickPrint = useCallback(() => setIsPrint(true), [isPrint]);

  useEffect(() => {
    if (!isPrint) return;
    window.print();
    setIsPrint(false);
  }, [isPrint]);

  return (
    <div className={`step_preview_dialog route_dialog base_dialog ${UserAgent}`}>
      <div className="route_dialog__body">

        <div className="step_preview_dialog__start">
          {/* <div>出発地</div> */}
          {/* <div>
            {startValue}
          </div> */}
          <div className="important">
            {data.start_address}
          </div>
        </div>

        <div className="step_preview_dialog__via">
          {data.steps.map((v, i) => (
            <StepCard
              key={v.instructions + i}
              img={(v as any).maneuver}
              text={v.instructions}
              m={v.distance?.text || ''}
            />
          ))}
        </div>

        <div className="step_preview_dialog__goal">
          {/* <div className="item_head">目的地</div>
          <div>
            <div>
              {endValue}
            </div>
          </div> */}
          <div className="important">
            {data.end_address}
          </div>
        </div>
      </div>
      {!isPrint && (
      <div className={`route_dialog__footer ${UserAgent === 'pc' ? 'base_footer' : ''}`}>
        <Button
          color="dark"
          onClick={() => dispatch(DialogActions.pop())}
          size={UserAgent === 'pc' ? 'sm' : 'md'}
        >戻る
        </Button>
        {UserAgent === 'pc'
          && (
            <LeftIconButton
              label="印刷"
              fontAwesomeClass="fas fa-print"
              color="secondary"
              size={UserAgent === 'pc' ? 'sm' : 'md'}
              onClick={() => handleClickPrint()}
            />
          )}
      </div>
      )}
    </div>
  );
});
