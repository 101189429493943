import DragHandleIcon from '@mui/icons-material/DragHandle';
import { UserAgent } from '../../../../../utilities/user-agent';
import './ghost.scss';

type Props = {
  className: string;
  tab: string;
  label: string;
}

export const AccordionPreview = ({
  className,
  tab,
  label,
}: Props) => (
  <div className={`accordion ${UserAgent} ${className} `}>
    <div
      key="childAccordion"
      style={{ display: 'flex' }}
      className={`accordion__label ${className || ''}`}
    >
      {tab || ''}
      <div className="accordion__label__dnd">
        <DragHandleIcon />&nbsp;{label}
      </div>
    </div>
  </div>
);
