import { replace } from 'connected-react-router';
import {
  useEffect, useRef, useState, useCallback,
} from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { cloneDeep } from 'lodash';
import { MapCollection } from '../../../../collection/map/map.collection';
import { Message } from '../../../../collection/message.collection';
import { useDidMount, useWillUnMount } from '../../../../hooks/life-cycle';
import { useQuery } from '../../../../hooks/use-query';
import { useAppSelector } from '../../../../hooks/use-redux';
import { ProjectModel, ProjectSearchMode } from '../../../../model/project/project.model';
import { DialogActions } from '../../../../redux/dialog/dialog.action';
import { MapActions } from '../../../../redux/map/map.action';
import { MasterActions } from '../../../../redux/master/master.action';
import { ProjectActions } from '../../../../redux/project/project.action';
import { RoutingPath } from '../../../../routes/routing-pass';
import { MapAreaPosition } from '../../../../type/map/map.type';
import { ProjectShowType, ProjectSortState, ProjectListType } from '../../../../type/project/project.type';
import { SetSelectedClass } from '../../../../utilities/set-selected-class';
import { BottomBorderButton } from '../../../ui/button/bottom-border/bottom-border-button';
import { LeftIconButton } from '../../../ui/button/left-icon-button/left-icon-button';
import { MapListToggleButton } from '../../../ui/button/map-list-toggle/map-list-toggle-button';
import { MapBase } from '../../../ui/map/map-base';
import { TableSort } from '../../../ui/table/table-sort/table-sort';
import { ProjectListSP } from '../../layout/body/list/project-list/project-list';
import { SearchBoxDialogTitle } from '../../layout/search-box/search-box.type.sp';
import { BasePageSP } from '../base.page.sp';
import { ProjectEditSP } from './edit/project-edit.sp';
import { ProjectEditDialogTitle } from './edit/project-edit.type.sp';
import { ProjectSearchBoxSP } from './search-box/project-search-box.sp';

export const ProjectSP = () => {
  const shoType = useQuery('type');
  const dispatch = useDispatch();
  const sortState = useAppSelector((v) => v.project.sort);
  const areaBtnDisabled = useAppSelector((v) => (v.map.areaBtnDisabled));
  const zoom = useAppSelector((v) => v.map.zoomLevel);
  const mapAreaPos = useAppSelector((v) => (v.map.mapAreaPos));
  const gpsStatus = useAppSelector((v) => (v.map.gpsStatus));
  const user = useAppSelector((v) => v.user);
  const dialogLen = useAppSelector((v) => (v.dialog.dialogs.length));
  const map = useAppSelector((v) => v.map.map);
  const humanPos = useAppSelector((v) => (v.map.humanPos));
  const flickLoad = useAppSelector((v) => v.map.flickLoad);
  const [showType, setShowType] = useState<'map' | 'list'>('map');
  const [projectShowType, setProjectShowType] = useState<ProjectShowType>(0);
  const [mapList, setMapList] = useState<ProjectListType[] | null>(null);
  const [hitCount, setHitCount] = useState(0);
  const [isSearchDialog, setIsSearchDialog] = useState<boolean>(false);
  const footerEle = useRef<HTMLDivElement>(null);
  const headerEle = useRef<HTMLDivElement>(null);
  const listEle = useRef<HTMLDivElement>(null);
  const [isSearch, setIsSearch] = useState(false);
  const [init, setInit] = useState<boolean>(true);
  /* エリア検索 */
  const [zoomSearch, setZoomSearch] = useState(false);

  /* 一覧取得 - リスト */
  const getListInList = useCallback((param: {
    data?: ProjectSortState;
    offset?: number;
  }) => {
    const { data, offset } = param;
    setMapList(null);
    dispatch(ProjectActions.api.project.getCallbackList({
      param: {
        ...ProjectModel.listParam({
          sortState: data || sortState,
          isMap: showType === 'map',
          searchBox: true,
          isMapList: true,
        }),
        limit: 100,
        offset: offset || sortState.offset,
      },
      onSuccess: (v, hit) => {
        if (!v) return;
        setHitCount(hit);
        setMapList(v);
      },
    }));
  }, [sortState, showType]);

  /* 一覧取得 - map */
  const getListInMap = useCallback((param: {
    data?: ProjectSortState;
    searchBox?: boolean;
    mapPos?: MapAreaPosition;
  }) => {
    const { data, searchBox, mapPos } = param;
    const isMap = showType === 'map';
    dispatch(ProjectActions.api.project.getList({
      isLoad: isMap,
      isMapLoad: true,
      param: ProjectModel.listParam({
        sortState: data || sortState,
        isMap,
        mapAreaPos: mapPos || mapAreaPos,
        isSp: true,
      }),
      onSuccess: (v) => {
        if (!v) return;
        if (flickLoad) setMapList(v.data);
        if (isMap) {
          dispatch(ProjectActions.setList(v.data));
        } else {
          dispatch(ProjectActions.setList(v.data));
        }
        dispatch(ProjectActions.setListCount(v.count));
        if (!v.data.length && isMap) {
          dispatch(DialogActions.pushMessage({
            title: '検索',
            message: ['領域内に該当データがありません'],
          }));
        }
      },
    }));
  }, [showType, sortState, mapAreaPos, flickLoad]);

  const changeMap = useCallback(() => {
    if (flickLoad) setMapList(null);
    setInit(true);
    const path = `${RoutingPath.project}?type=${showType === 'map' ? 'list' : 'map'}`;
    dispatch(ProjectActions.setList(null));
    dispatch(replace(path));
    setShowType(showType === 'map' ? 'list' : 'map');
  }, [showType, flickLoad]);

  /* エリア検索ボタン押下 */
  const hClickSearchArea = useCallback((data?: ProjectSortState) => {
    dispatch(MapActions.setAreaBtnDisabled(true));
    if (flickLoad) setMapList(null);
    if (zoom >= MapCollection.searchZoomLevel && mapAreaPos) {
      getListInMap({ data });
    } else {
      setZoomSearch(true);
      dispatch(MapActions.setZoomLevel(MapCollection.searchZoomLevel));
    }
  }, [zoom, mapAreaPos, getListInMap, flickLoad]);

  const handleClickRegistration = useCallback(() => {
    dispatch(DialogActions.push({
      title: ProjectEditDialogTitle.add,
      element: <ProjectEditSP callback={() => {
        getListInList({});
        getListInMap({});
      }}
      />,
    }));
  }, [getListInList, getListInMap]);

  /* ズーム終了時アクション */
  const zoomEnd = useCallback((pos: MapAreaPosition) => {
    if (!zoomSearch) {
      dispatch(MapActions.setAreaBtnDisabled(false));
      return;
    }
    setZoomSearch(false);
    getListInMap({ mapPos: pos });
  }, [zoomSearch, getListInMap, flickLoad]);

  useEffect(() => {
    if (dialogLen || (showType === 'map' && !mapAreaPos)) return;
    getListInList({});
  }, [
    showType,
  ]);

  useEffect(() => {
    if (init && humanPos && mapAreaPos) {
      setInit(false);
      hClickSearchArea();
    }
  }, [humanPos, mapAreaPos]);

  useEffect(() => {
    const mapType = (shoType || 'map');
    const path = `${RoutingPath.project}?type=`;
    dispatch(replace(path + mapType));
    setShowType(mapType === 'map' ? 'map' : 'list');
  }, [shoType]);

  useWillUnMount(() => {
    dispatch(ProjectActions.setList(null));
    dispatch(MapActions.setMapAreaPosition(null));
  });

  useEffect(() => {
    listEle.current?.scrollTo(0, -10000);
  }, [showType]);

  useDidMount(() => {
    dispatch(MasterActions.api.projectRank.getList({}));
    dispatch(MapActions.setFlickLoad(true));
  });

  /* 検索ボタン押下処理 */
  const hClickSearchBtn = useCallback((v: ProjectSortState) => {
    dispatch(DialogActions.pop());
    if (showType !== 'map') { dispatch(ProjectActions.setList(null)); }
    setIsSearch(true);
    setMapList(null);
    dispatch(MapActions.setFlickLoad(false));
    dispatch(ProjectActions.setSort({ offset: 0 }));
    if (showType === 'map') { hClickSearchArea(v); }
    getListInList({ data: { ...v, offset: 0 }, offset: 0 });
  }, [shoType, getListInList, showType, hClickSearchArea]);

  /* フリーワード検索処理 */
  const hBlurFree = useCallback((v: string) => {
    if (showType !== 'map') { dispatch(ProjectActions.setList(null)); }
    setIsSearchDialog(true);
    dispatch(ProjectActions.setSort({ keyword: v, offset: 0 }));
    if (showType === 'map') { hClickSearchArea({ ...sortState, keyword: v }); }
    getListInList({ data: { ...sortState, keyword: v }, offset: 0 });
  }, [shoType, getListInList, showType, hClickSearchArea, sortState]);

  /* ページネーション */
  const hClickPagination = useCallback((offset: number) => {
    dispatch(ProjectActions.setSort({ offset }));
    getListInList({ ...sortState, offset });
  }, [sortState, getListInList]);

  const changeMode = useCallback((mode: ProjectSearchMode) => {
    const sortData: ProjectSortState = {
      ...sortState,
      construction_status: ProjectModel.changeMode({
        mode,
      }),
    };
    dispatch(ProjectActions.setSort(cloneDeep(sortData)));
    hClickSearchBtn(cloneDeep(sortData));
  }, [sortState, hClickSearchBtn]);

  return (
    <BasePageSP
      className="project_sp"
      searchBoxDialog={{
        title: SearchBoxDialogTitle,
        element: <ProjectSearchBoxSP
          getList={hClickSearchBtn}
        />,
      }}
      searchValue={sortState.keyword}
      searchCallback={hBlurFree}
    >
      <div
        className="map_list_header"
        onClick={(e) => { e.preventDefault(); }}
        ref={headerEle}
      >
        <BottomBorderButton
          label="すべて"
          className="selected"
          onClick={(e) => {
            setProjectShowType(0);
            changeMode('All');
          }}
          selected={ProjectModel.CheckMode('All', sortState.construction_status)}
        />
        <BottomBorderButton
          label="未契約"
          className="selected"
          onClick={(e) => {
            setProjectShowType(1);
            changeMode('No');
          }}
          selected={ProjectModel.CheckMode('No', sortState.construction_status)}
        />
        <BottomBorderButton
          label="工事中"
          onClick={(e) => {
            setProjectShowType(2);
            changeMode('Construction');
          }}
          selected={ProjectModel.CheckMode('Construction', sortState.construction_status)}
        />
        <BottomBorderButton
          label="完工"
          onClick={(e) => {
            setProjectShowType(3);
            SetSelectedClass(e.currentTarget, headerEle.current);
            changeMode('Complete');
          }}
          selected={ProjectModel.CheckMode('Complete', sortState.construction_status)}
        />
      </div>
      {showType === 'map'
        ? (
          <>
          </>
        )
        : (
          <>
            <TableSort
              isSp
              page={sortState.offset ?? 0}
              limit={100}
              hitCount={hitCount || 0}
              callback={hClickPagination}
            />
          </>
        )}
      <div className="map_list_body" ref={listEle}>
        {!areaBtnDisabled && showType === 'map'
          && (
          <LeftIconButton
            label="このエリアを検索"
            size="md"
            fontAwesomeClass="fas fa-search"
            className="for_area"
            color="secondary"
            onClick={() => hClickSearchArea()}
            disabled={areaBtnDisabled}
          />
          )}
        {showType === 'map'
          ? (
            <MapBase
              projectOption={{ type: projectShowType }}
              isNowPoint
              searchOption={{}}
              callbackZoomEnd={zoomEnd}
              type="project"
            />
          )
          : (
            <>
              {/*
              <TableSort
                isSp
                page={sortState.offset ?? 0}
                limit={100}
                hitCount={hitCount || 0}
                callback={hClickPagination}
              />
              */}
              <ProjectListSP data={mapList} type={projectShowType} />
            </>
          )}
      </div>

      <div
        className="page_body_footer space_between"
        ref={footerEle}
      >
        <LeftIconButton
          label="案件新規登録"
          fontAwesomeClass="far fa-edit"
          onClick={handleClickRegistration}
          size="md"
          color="primary"
        />
        <MapListToggleButton
          showType={showType}
          onClick={changeMap}
        />
      </div>
    </BasePageSP>
  );
};
