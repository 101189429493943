import './estimate.pc.scss';
import { useDispatch } from 'react-redux';
import { goBack, push } from 'connected-react-router';
import { useCallback, useEffect, useState } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { BasePagePC } from '../base.page.pc';
import { DialogActions } from '../../../../redux/dialog/dialog.action';
import { EstimateListPC } from '../../layout/body/list/estimate-list/estimate-list.pc';
import { EstimateSearchBox } from '../../layout/search-box/estimate/estimate-search-box';
import { PrintType, PrintPreview } from '../../../dialogs/print-preview/print-preview';
import { EstimateEditPC } from './edit/estimate-edit.pc';
import { LeftIconButton } from '../../../ui/button/left-icon-button/left-icon-button';
import { EstimateActions } from '../../../../redux/estimate/estimate.action';
import { useWillUnMount, useDidMount } from '../../../../hooks/life-cycle';
import { EstimateListType, EstimateSortState } from '../../../../type/estimate/estimate.type';
import { BillEdit } from '../../../dialogs/bill-edit/bill-edit';
import { EstimateModel } from '../../../../model/estimate/estimate-model';
import { useAppSelector } from '../../../../hooks/use-redux';
import { TableSort, Limit } from '../../../ui/table/table-sort/table-sort';

export const EstimatePC = () => {
  /* Hooks */
  const dispatch = useDispatch();
  const sortState = useAppSelector((v) => v.estimate.sort);
  const user = useAppSelector((v) => v.user);

  const [estimate, setEstimate] = useState<EstimateListType | null>(null);
  const [hitCount, setHitCount] = useState(0);

  /* Callback */
  const getList = useCallback((v?: EstimateSortState, isLoad?: boolean) => {
    if (isLoad) {
      dispatch(EstimateActions.setList(null));
    }
    dispatch(EstimateActions.api.estimate.getList({
      param: { ...EstimateModel.listParam(v || sortState) },
      callback: (res, hit) => {
        setHitCount(hit);
        dispatch(EstimateActions.setList(res));
      },
    }));
  }, [sortState]);

  const handleClickNew = useCallback(() => {
    dispatch(DialogActions.push({
      title: '見積新規登録',
      className: 'estimate max_height_dialog max_width_dialog',
      onCloseClick: getList,
      element: <EstimateEditPC callback={getList} />,
      isCheck: true,
      callback: () => getList(),
    }));
  }, [getList]);

  const handleClickPrint = (type: PrintType) => {
    if (!estimate) return;
    dispatch(EstimateActions.api.estimate.get({
      param: { id: estimate.id },
      callback: (v) => {
        dispatch(DialogActions.push({
          title: type === 'estimateIn' ? '見積書印刷プレビュー(社内用)' : '見積書印刷プレビュー',
          className: 'auto_height_dialog',
          element: <PrintPreview
            type={type}
            estimate={v}
            estimateListData={estimate}
          />,
        }));
      },
    }));
  };

  const handleClickBillRegist = useCallback(() => {
    if (!estimate) return;
    dispatch(DialogActions.push({
      title: '請求登録',
      // className: 'auto_height_dialog',
      element: <BillEdit
        estimate={estimate}
        mode="add"
        callback={() => { }}
      />,
    }));
  }, [estimate]);

  const onClickRow = useCallback((v: EstimateListType | null) => {
    setEstimate(cloneDeep(v));
  }, []);

  const handleChangePagination = useCallback((offset: number, limit: Limit) => {
    dispatch(EstimateActions.setList(null));
    dispatch(EstimateActions.setSort({ offset, limit }));
    getList({ ...sortState, offset, limit });
  }, [sortState]);

  useEffect(() => {
    getList(undefined, true);
  }, [
    sortState.highlow,
    sortState.sort_by,
  ]);

  useWillUnMount(() => {
  });

  useDidMount(() => {
    if (!user.company_authority6) {
      dispatch(push('/'));
    }
  });

  return (
    <BasePagePC className="EstimatePC">
      <div id="estimate" className="cnt_area">
        <div className="inner">
          <EstimateSearchBox callback={(v) => {
            dispatch(EstimateActions.setList(null));
            dispatch(EstimateActions.setSort({ offset: 0 }));
            getList({ ...v, offset: 0 });
          }}
          />
          <TableSort
            page={sortState.offset ?? 0}
            limit={sortState.limit as Limit}
            hitCount={hitCount}
            callback={handleChangePagination}
          />
          <EstimateListPC handleCardClick={onClickRow} />
        </div>
      </div>
      <footer className="btn_area">
        <div className="left_box">
          <LeftIconButton
            label="見積新規作成"
            size="md"
            fontAwesomeClass="fas fa-edit"
            className="btn_search for_detail"
            color="primary"
            onClick={handleClickNew}
          />
          <LeftIconButton
            label="見積書印刷"
            fontAwesomeClass="fas fa-calculator"
            size="md"
            color="secondary"
            disabled={!estimate}
            onClick={() => handleClickPrint('estimate')}
          />
          <LeftIconButton
            label="見積書印刷（社内用）"
            fontAwesomeClass="fas fa-calculator"
            size="md"
            color="secondary"
            disabled={!estimate}
            onClick={() => handleClickPrint('estimateIn')}
          />
        </div>
        <div className="right_box">
          <LeftIconButton
            label="戻る"
            fontAwesomeClass="fas fa-arrow-left"
            size="md"
            color="dark"
            onClick={() => dispatch(goBack())}
          />
        </div>
      </footer>
    </BasePagePC>
  );
};
