import React, {
  useState, useCallback, useMemo, useEffect,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as AutoKana from 'vanilla-autokana';
import { cloneDeep } from 'lodash';
import { prefectures } from '../../../../../../../collection/prefectures';
import { DialogActions } from '../../../../../../../redux/dialog/dialog.action';
import { EditPC } from '../../../../../../dialogs/edit/edit.pc';
import { MasterEditDialogProps } from '../../../master.type';
import './store-master-edit-dialog.scss';
import { Radio } from '../../../../../../ui/radio/radio';
import { MasterActions } from '../../../../../../../redux/master/master.action';
import { Select } from '../../../../../../ui/select/select';
import { useDidMount } from '../../../../../../../hooks/life-cycle';
import { Input } from '../../../../../../ui/input/input';
import { LeftLabelInputField } from '../../../../../../ui/input-field/left-label-input-field/left-label-input-field';
import { RightLabelCheckbox } from '../../../../../../ui/checkbox/right-label-checkbox/right-label-checkbox';
import { Button } from '../../../../../../ui/button/button';
import {
  ValidationLengthUnder255,
  ValidationPostNum1,
  ValidationPostNum2,
  ValidationTel,
} from '../../../../../../../model/validation';
import { MasterStoreValidation } from '../../../../../../../model/validation/master/master-store.validation';
import { MapActions } from '../../../../../../../redux/map/map.action';
import { FileUploadButton } from '../../../../../../ui/file-upload/file-upload-button';
import { changeString } from '../../../../../../../utilities/change-string';
import { State } from '../../../../../../../redux/root.reducer';
import { autoKana } from '../../../../../../../utilities/auto-kana';
import { InputTel } from '../../../../../../ui/input/input-tel';
import { base64toBlob } from '../../../../../../../utilities/base64toblob';
import { Message } from '../../../../../../../collection/message.collection';
import { ApiMasterStoreLogoGet } from '../../../../../../../redux/master/api/master-store/api-master-store';
import { InfoButton } from '../../../../../../ui/button/info-button/info-button';
import { ValidationNumberLengthUnder7 } from '../../../../../../../model/validation/validation-number-length-under';
import { usePostSearch } from '../../../../../../../hooks/common/use-common';
import { plus0 } from '../../../../../../ui/search-post-address-dialog/search-post-address-dialog';
import { UserActions } from '../../../../../../../redux/user/user.action';
import { InputPostNoMulti } from '../../../../../../ui/input/input-post-no-multi';

export type Bank = {
  bank_name: string;
  bank_store_name: string;
  bank_account_no: string;
  account: number;
}
export type Banks = [Bank, Bank, Bank, Bank, Bank, Bank]
const bankInitial = ():Bank => ({
  bank_name: '',
  bank_store_name: '',
  bank_account_no: '',
  account: 1,
});

const accept = '.jpg, .png, .jpeg, .gif, .svg';

export const StoreMasterEditDialog = (props: MasterEditDialogProps) => {
  const { id, callback } = props;
  const searchPost = usePostSearch();
  const dataList = useSelector((state:State) => state.master.storeList);
  const dispatch = useDispatch();
  const [autoFurigana, setAutoFurigana] = useState<AutoKana.AutoKana | null>();
  const [name, setName] = useState('');
  const [furigana, setFurigana] = useState('');
  const [shortName, setShortName] = useState('');
  const [phoneNum, setPhoneNum] = useState('');
  const [faxNum, setFaxNum] = useState('');
  const [freeDial, setFreeDial] = useState('');
  const [postalCode1, setPostalCode1] = useState('');
  const [postalCode2, setPostalCode2] = useState('');
  const [prefecture, setPrefecture] = useState('');
  const [prefectureNum, setPrefectureNum] = useState(NaN);
  const [city, setCity] = useState('');
  const [address, setAddress] = useState('');
  const [buildingName, setBuildingName] = useState('');
  const [bankName, setBankName] = useState('');
  const [bankAccountNum, setBankAccountNum] = useState('');
  const [holder, setHolder] = useState('');
  const [jisx0402, setJisx0402] = useState('');
  const [bankAccount, setBankAccount] = useState(1);
  const [validFlag, setValidFlag] = useState(true);
  const [logo, setLogo] = useState<Blob | null>(null);
  const [touch, setTouch] = useState(false);
  const [banks, setBanks] = useState<Banks>([
    bankInitial(), bankInitial(), bankInitial(),
    bankInitial(), bankInitial(), bankInitial(),
  ]);
  const [jobTitle, setJobTitle] = useState('');
  const [jobTitlePerson, setJobTitlePerson] = useState('');

  const [file, setFile] = useState<Blob | null>(null);
  const [url, setUrl] = useState('');

  const handleClickSearchAddress = useCallback(() => {
    dispatch(MapActions.api.addressSearch({
      param: {
        zipcode1: String(postalCode1),
        zipcode2: String(postalCode2),
      },
      callback: (searchedAddress) => {
        const prefCode = Number(searchedAddress.prefcode);
        const pref = prefectures.find((v) => v.value === prefCode)?.label;
        setPrefectureNum(prefCode);
        setPrefecture(pref || '');
        setCity(searchedAddress.ja.address1);
        setAddress(searchedAddress.ja.address2);
        setBuildingName('');
      },
    }));
  },
  [postalCode1, postalCode2]);

  const searchPostNo = useCallback(() => {
    searchPost({
      zipcode1: postalCode1 ? String(postalCode1) : '',
      zipcode2: postalCode2 ? String(postalCode2) : '',
      jisx0402: jisx0402 || '',
      pref: prefectureNum ? Number(prefectureNum) : NaN,
      city,
      town: address,
      calback: (v) => {
        const pref = prefectures.find((val) => val.value === v.pref)?.label;
        setPrefecture(pref || '');
        setPostalCode1(v.zipcode1);
        setPostalCode2(v.zipcode2);
        setJisx0402(v.jisx0402);
        setPrefectureNum(v.pref ? (v.pref) : NaN);
        setCity(v.city);
        setAddress(v.town);
      },
    });
  }, [postalCode1, postalCode2, jisx0402, prefectureNum, city, address, prefecture]);

  const handleClickPost = useCallback(() => {
    if (MasterStoreValidation(
      name,
      furigana,
      shortName,
      phoneNum,
      faxNum,
      freeDial,
      postalCode1,
      postalCode2,
      city,
      address,
      buildingName,
      jobTitle,
      jobTitlePerson,
      holder,
      banks,
    )) {
      dispatch(DialogActions.pushMessage({
        title: '店舗 編集／追加',
        message: Message.postError,
        callback: () => setTouch(true),
      }));
      return;
    }

    const sendBank = banks.map((v, _i) => {
      const i = _i ? _i + 1 : '';
      return {
        [`bank_name${i}`]: v.bank_name,
        [`bank_store_name${i}`]: v.bank_store_name,
        [`bank_account_no${i}`]: v.bank_account_no,
        [`account${i}`]: v.account,
      };
    });

    dispatch(MasterActions.api.store.post({
      param: {
        data: {
          name,
          furigana,
          short_name: shortName,
          tel_no: phoneNum,
          fax_no: faxNum,
          free_dial: freeDial,
          post_no: postalCode1 && postalCode2 ? `${postalCode1}${postalCode2}` : '',
          prefecture,
          city,
          address,
          building_name: buildingName,
          ...sendBank[0],
          ...sendBank[1],
          ...sendBank[2],
          ...sendBank[3],
          ...sendBank[4],
          ...sendBank[5],
          holder,
          is_valid: validFlag ? 1 : 0,
          logo: file || '',
          job_title: jobTitle,
          job_title_person: jobTitlePerson,
          jisx0402_code: jisx0402,
          jisx0401_code: prefectureNum
            ? plus0(String(prefectureNum))
            : undefined,
        },
        id,
      },
      onSuccess: () => {
        callback();
        dispatch(DialogActions.pop());
        dispatch(UserActions.getUser({
          callback: () => {},
        }));
      },
      onError: () => {
        setTouch(true);
      },
    }));
  }, [
    name,
    furigana,
    shortName,
    phoneNum,
    faxNum,
    freeDial,
    postalCode1,
    postalCode2,
    prefecture,
    prefectureNum,
    city,
    address,
    buildingName,
    bankName,
    bankAccountNum,
    holder,
    bankAccount,
    validFlag,
    logo,
    banks,
    file,
    callback,
    jobTitle,
    jobTitlePerson,
    jisx0402,
  ]);

  const onChangeName = useCallback(
    (v: string) => {
      if (autoFurigana) {
        setName(v);
        setFurigana(autoFurigana.getFurigana());
      } else {
        setName(v);
      }
    }, [autoFurigana],
  );
  const onFileInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    const image = e.target.files[0];
    setFile(image);
    const reader = new FileReader();
    reader.readAsDataURL(image);
    reader.onload = (res) => {
      const result = res.target?.result || '';
      if (typeof result !== 'string') return;
      setUrl(result);
    };
  }, []);

  const handleClickImgDelete = useCallback(() => {
    dispatch(DialogActions.pushMessage({
      title: 'ファイル削除',
      message: ['ロゴ画像を削除しますか'],
      isCancel: true,
      callback: () => {
        setFile(null);
        setUrl('');
      },
    }));
  }, []);

  const onChangeBanks = useCallback(
    (i: number, key: keyof Bank, v: string | number) => {
      if (key === 'account') {
        banks[i].account = Number(v);
      } else {
        banks[i][key] = String(v);
      }
      setBanks(cloneDeep(banks));
    }, [banks],
  );

  useDidMount(() => {
    setAutoFurigana(autoKana('name', 'furigana', true));
    if (id) {
      const data = dataList.find((v) => v.id === id);
      if (!data) return;
      setName(data.name);
      setFurigana(data.furigana);
      setShortName(data.short_name);
      setPhoneNum((data.tel_no));
      setFaxNum(data.fax_no);
      setFreeDial(data.free_dial);
      setPostalCode1(changeString(data.post_no, '').slice(0, 3));
      setPostalCode2(changeString(data.post_no, '').slice(3, 7));
      setPrefecture(data.prefecture);
      setPrefectureNum(data.prefecture_id);
      setCity(data.city);
      setAddress(data.address);
      setBuildingName(data.building_name);
      setBankName(data.bank_name);
      setBankAccountNum(data.bank_account_no);
      setHolder(data.holder);
      setBankAccount(data.account);
      setValidFlag(data.valid_flag);
      setJobTitle(data.job_title);
      setJobTitlePerson(data.job_title_person);
      setJisx0402(data.jisx0402_code);
      console.log('data.jisx0402_code', data.jisx0402_code);

      const bank: Banks = [
        {
          bank_name: data.bank_name,
          bank_store_name: data.bank_store_name,
          bank_account_no: data.bank_account_no,
          account: data.account,
        },
        {
          bank_name: data.bank_name2,
          bank_store_name: data.bank_store_name2,
          bank_account_no: data.bank_account_no2,
          account: data.account2,
        },
        {
          bank_name: data.bank_name3,
          bank_store_name: data.bank_store_name3,
          bank_account_no: data.bank_account_no3,
          account: data.account3,
        },
        {
          bank_name: data.bank_name4,
          bank_store_name: data.bank_store_name4,
          bank_account_no: data.bank_account_no4,
          account: data.account4,
        },
        {
          bank_name: data.bank_name5,
          bank_store_name: data.bank_store_name5,
          bank_account_no: data.bank_account_no5,
          account: data.account5,
        },
        {
          bank_name: data.bank_name6,
          bank_store_name: data.bank_store_name6,
          bank_account_no: data.bank_account_no6,
          account: data.account6,
        },
      ];

      setBanks(bank);

      const l = new ApiMasterStoreLogoGet(data.id);
      l.run().then((res) => {
        const dataId = res.body.data.id;
        if (!dataId) return;
        setFile(base64toBlob(dataId, data.format));
        const blob = base64toBlob(dataId, data.format);
        const reader = new FileReader();
        if (!blob) return;
        reader.readAsDataURL(blob);
        reader.onload = (() => {
          if (typeof reader.result !== 'string') return;
          setUrl(reader.result);
        });
      });

      // dispatch(MasterActions.api.store.getLogo({
      //   param: { id: data.id },
      //   onSuccess: (res) => {
      //     console.log(res);
      //   },
      // }));

      // setUrl(data.logo);
      // setUrl(_url);
      // setFile(
      //   base64toBlob(_url),
      // );
      //   const byte = atob(data.logo);
      //   const content = new Uint8Array(byte.length);
      //   console.log(content);

      //   for (let i = 0, l = byte.length; i < l; i += 1) {
      //     content[i] = byte.charCodeAt(i);
      //   }

    //   console.log((new Blob([content])));
    }
  });

  return (
    <EditPC mode="dialog" callback={handleClickPost} label={id ? '更新' : '登録'}>
      <div className="edit_pc_body_inner edit_master">
        <div className="left_box">
          <div className="item_wrap">
            <div className="item_box">
              <div className="item_head">名称</div>
              <Input
                id="name"
                className="name"
                value={name}
                onChange={(e) => onChangeName(e.target.value)}
                errorPosBottom
                validationList={ValidationLengthUnder255}
              />
            </div>
          </div>
          <div className="item_wrap">
            <div className="item_box">
              <div className="item_head">フリガナ</div>
              <Input
                id="furigana"
                className="furigana"
                value={furigana}
                onChange={(e) => setFurigana(e.target.value)}
                validationList={ValidationLengthUnder255}
              />
            </div>
          </div>
          {/*
          <div className="item_wrap">
            <div className="item_box">
              <div className="item_head">略称</div>
              <Input
                className="short_name"
                value={shortName}
                onChange={(e) => setShortName(e.target.value)}
                validationList={ValidationLengthUnder255}
              />
            </div>
          </div>
          */}
          <div className="item_wrap">
            <div className="item_box">

              <div className="item_head">電話番号</div>
              <InputTel
                className="tel_no"
                value={phoneNum}
                onChange={setPhoneNum}
              />
            </div>
          </div>
          <div className="item_wrap">
            <div className="item_box">

              <div className="item_head">FAX番号</div>
              <InputTel
                className="fax_no"
                value={faxNum}
                onChange={setFaxNum}
              />
            </div>
          </div>
          <div className="item_wrap">
            <div className="item_box">

              <div className="item_head">フリーダイヤル</div>
              <InputTel
                className="free_dial"
                value={freeDial}
                onChange={setFreeDial}
              />
            </div>
          </div>
          <div className="item_wrap">
            <div className="item_box max_width">
              <div className="item_head">住所</div>
              <div>
                <div className="item_postal">
                  <InputPostNoMulti
                    value={postalCode1}
                    touch={touch}
                    require
                    onChange={(v) => setPostalCode1(v)}
                    value2={postalCode2}
                    touch2={touch}
                    require2
                    onChange2={(v) => setPostalCode2(v)}
                  />

                  <Button
                    size="sm"
                    color="secondary"
                    className="ml_10"
                    onClick={handleClickSearchAddress}
                  >住所入力
                  </Button>
                  <Button
                    size="sm"
                    color="secondary"
                    className="ml_10"
                    onClick={searchPostNo}
                  >
                    詳細住所入力
                  </Button>
                </div>
                <div className="item_adress">
                  <Select
                    className="add_text_left mt_10"
                    label="都道府県"
                    value={prefectureNum ?? NaN}
                    onChange={(v) => {
                      const pre = prefectures.find((_v) => _v.value === Number(v))?.label;
                      setPrefecture(pre || '');
                      setPrefectureNum(Number(v));
                    }}
                    defaultLabel="指定無し"
                    options={prefectures.map((v) => ({
                      text: v.label, value: v.value,
                    }))}
                  />
                  <LeftLabelInputField
                    className="city"
                    label="市区町村"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                    validationList={ValidationLengthUnder255}
                  />
                  <LeftLabelInputField
                    className="address"
                    label="地名・番地"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    validationList={ValidationLengthUnder255}
                  />
                  <LeftLabelInputField
                    className="building_name"
                    label="建物名"
                    value={buildingName}
                    onChange={(e) => setBuildingName(e.target.value)}
                    validationList={ValidationLengthUnder255}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="item_wrap">
            <div className="item_box">
              <div className="item_head">PDF用役職名</div>
              <Input
                className=""
                value={jobTitle}
                onChange={(e) => setJobTitle(e.target.value)}
                validationList={ValidationLengthUnder255}
              />
            </div>
          </div>
          <div className="item_wrap">
            <div className="item_box">
              <div className="item_head">PDF用役職者名</div>
              <Input
                className=""
                value={jobTitlePerson}
                onChange={(e) => setJobTitlePerson(e.target.value)}
                validationList={ValidationLengthUnder255}
              />
            </div>
          </div>
          <div className="item_wrap">
            <div className="item_box">
              <div className="item_head">PDF用ロゴ画像</div>
              <div>
                {url
                && (
                <div className="img_box mb_10">
                  <img src={url} alt="img" />
                </div>
                )}
                <div className="flex_no_wrap_box">
                  <FileUploadButton
                    value=""
                    onChange={onFileInputChange}
                    accept={accept}
                  />
                  {url
                  && (
                  <Button
                    size="sm"
                    color="dark"
                    className="mr_10"
                    onClick={handleClickImgDelete}
                  >削除
                  </Button>
                  )}
                  <InfoButton title={Message.master.logo} />
                </div>
              </div>
            </div>
          </div>
          <div className="item_wrap">
            <div className="item_box">
              <div className="item_head">有効フラグ</div>
              <RightLabelCheckbox
                label=""
                className="is_valid"
                checked={validFlag}
                onChange={() => setValidFlag(!validFlag)}
              />
            </div>
          </div>
        </div>
        <div className="right_box">
          <div className="item_wrap">
            <div className="item_box">

              <div className="item_head">口座名義</div>
              <Input
                className="bank_account_name"
                value={holder}
                onChange={(e) => setHolder(e.target.value)}
                validationList={ValidationLengthUnder255}
                errorPosBottom
              />

            </div>
            <div className="frame bank_info">
              {banks.map((v, i) => (
                <div className="item_wrap" key={`bank${i}`}>
                  <div className="item_box">
                    <div className="item_head">口座{i + 1}</div>
                    <div className="">
                      <LeftLabelInputField
                        className="bank_name"
                        label="銀行名"
                        value={v.bank_name}
                        onChange={({ target }) => onChangeBanks(i, 'bank_name', target.value)}
                        validationList={ValidationLengthUnder255}
                      />
                      <LeftLabelInputField
                        className="bank_store_name mt_10"
                        label="店舗名"
                        value={v.bank_store_name}
                        onChange={({ target }) => onChangeBanks(i, 'bank_store_name', target.value)}
                        validationList={ValidationLengthUnder255}
                      />
                      <div className="flex_no_wrap_box mt_10">
                        <label className="for_radio">口座</label>
                        <Radio
                          label="普通"
                          name="bank_account"
                          checked={v.account === 1}
                          onChange={() => onChangeBanks(i, 'account', 1)}
                        />
                        <Radio
                          label="当座"
                          name="bank_account"
                          checked={v.account === 2}
                          onChange={() => onChangeBanks(i, 'account', 2)}
                        />
                      </div>
                      <LeftLabelInputField
                        className="bank_account_number mt_10"
                        label="口座番号"
                        type="number"
                        value={v.bank_account_no}
                        onChange={({ target }) => onChangeBanks(i, 'bank_account_no', target.value)}
                        maxLength={7}
                        validationList={ValidationNumberLengthUnder7}
                      />
                    </div>
                  </div>
                </div>
              ))}
              {/* <div className="item_wrap">
                <div className="item_box">
                  <div className="item_head">口座1</div>
                  <div className="">
                    <LeftLabelInputField
                      className="bank_name"
                      label="銀行名"
                      value={bankName}
                      onChange={(e) => setBankName(e.target.value)}
                    />
                    <LeftLabelInputField
                      className="bank_store_name mt_10"
                      label="店舗名"
                      value={bankName}
                      onChange={(e) => setBankName(e.target.value)}
                    />
                    <div className="flex_no_wrap_box mt_10">
                      <label className="for_radio">口座</label>
                      <Radio
                        label="普通"
                        name="bank_account"
                        checked={bankAccount === 1}
                        onChange={() => { setBankAccount(bankAccount === 1 ? 2 : 1); }}
                      />
                      <Radio
                        label="当座"
                        name="bank_account"
                        checked={bankAccount === 2}
                        onChange={() => { setBankAccount(bankAccount === 1 ? 2 : 1); }}
                      />
                    </div>
                    <LeftLabelInputField
                      className="bank_account_number mt_10"
                      label="口座番号"
                      type="number"
                      value={bankAccountNum}
                      onChange={(e) => setBankAccountNum(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="item_wrap">
                <div className="item_box">
                  <div className="item_head">口座2</div>
                  <div className="">
                    <LeftLabelInputField
                      className="bank_name"
                      label="銀行名"
                      value={bankName}
                      onChange={(e) => setBankName(e.target.value)}
                    />
                    <LeftLabelInputField
                      className="bank_store_name mt_10"
                      label="店舗名"
                      value={bankName}
                      onChange={(e) => setBankName(e.target.value)}
                    />
                    <div className="flex_no_wrap_box mt_10">
                      <label className="for_radio">口座</label>
                      <Radio
                        label="普通"
                        name="bank_account"
                        checked={bankAccount === 1}
                        onChange={() => { setBankAccount(bankAccount === 1 ? 2 : 1); }}
                      />
                      <Radio
                        label="当座"
                        name="bank_account"
                        checked={bankAccount === 2}
                        onChange={() => { setBankAccount(bankAccount === 1 ? 2 : 1); }}
                      />
                    </div>
                    <LeftLabelInputField
                      className="bank_account_number mt_10"
                      label="口座番号"
                      type="number"
                      value={bankAccountNum}
                      onChange={(e) => setBankAccountNum(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="item_wrap">
                <div className="item_box">
                  <div className="item_head">口座3</div>
                  <div className="">
                    <LeftLabelInputField
                      className="bank_name"
                      label="銀行名"
                      value={bankName}
                      onChange={(e) => setBankName(e.target.value)}
                    />
                    <LeftLabelInputField
                      className="bank_store_name mt_10"
                      label="店舗名"
                      value={bankName}
                      onChange={(e) => setBankName(e.target.value)}
                    />
                    <div className="flex_no_wrap_box mt_10">
                      <label className="for_radio">口座</label>
                      <Radio
                        label="普通"
                        name="bank_account"
                        checked={bankAccount === 1}
                        onChange={() => { setBankAccount(bankAccount === 1 ? 2 : 1); }}
                      />
                      <Radio
                        label="当座"
                        name="bank_account"
                        checked={bankAccount === 2}
                        onChange={() => { setBankAccount(bankAccount === 1 ? 2 : 1); }}
                      />
                    </div>
                    <LeftLabelInputField
                      className="bank_account_number mt_10"
                      label="口座番号"
                      type="number"
                      value={bankAccountNum}
                      onChange={(e) => setBankAccountNum(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="item_wrap">
                <div className="item_box">
                  <div className="item_head">口座4</div>
                  <div className="">
                    <LeftLabelInputField
                      className="bank_name"
                      label="銀行名"
                      value={bankName}
                      onChange={(e) => setBankName(e.target.value)}
                    />
                    <LeftLabelInputField
                      className="bank_store_name mt_10"
                      label="店舗名"
                      value={bankName}
                      onChange={(e) => setBankName(e.target.value)}
                    />
                    <div className="flex_no_wrap_box mt_10">
                      <label className="for_radio">口座</label>
                      <Radio
                        label="普通"
                        name="bank_account"
                        checked={bankAccount === 1}
                        onChange={() => { setBankAccount(bankAccount === 1 ? 2 : 1); }}
                      />
                      <Radio
                        label="当座"
                        name="bank_account"
                        checked={bankAccount === 2}
                        onChange={() => { setBankAccount(bankAccount === 1 ? 2 : 1); }}
                      />
                    </div>
                    <LeftLabelInputField
                      className="bank_account_number mt_10"
                      label="口座番号"
                      type="number"
                      value={bankAccountNum}
                      onChange={(e) => setBankAccountNum(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="item_wrap">
                <div className="item_box">
                  <div className="item_head">口座5</div>
                  <div className="">
                    <LeftLabelInputField
                      className="bank_name"
                      label="銀行名"
                      value={bankName}
                      onChange={(e) => setBankName(e.target.value)}
                    />
                    <LeftLabelInputField
                      className="bank_store_name mt_10"
                      label="店舗名"
                      value={bankName}
                      onChange={(e) => setBankName(e.target.value)}
                    />
                    <div className="flex_no_wrap_box mt_10">
                      <label className="for_radio">口座</label>
                      <Radio
                        label="普通"
                        name="bank_account"
                        checked={bankAccount === 1}
                        onChange={() => { setBankAccount(bankAccount === 1 ? 2 : 1); }}
                      />
                      <Radio
                        label="当座"
                        name="bank_account"
                        checked={bankAccount === 2}
                        onChange={() => { setBankAccount(bankAccount === 1 ? 2 : 1); }}
                      />
                    </div>
                    <LeftLabelInputField
                      className="bank_account_number mt_10"
                      label="口座番号"
                      type="number"
                      value={bankAccountNum}
                      onChange={(e) => setBankAccountNum(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="item_wrap">
                <div className="item_box">
                  <div className="item_head">口座6</div>
                  <div className="">
                    <LeftLabelInputField
                      className="bank_name"
                      label="銀行名"
                      value={bankName}
                      onChange={(e) => setBankName(e.target.value)}
                    />
                    <LeftLabelInputField
                      className="bank_store_name mt_10"
                      label="店舗名"
                      value={bankName}
                      onChange={(e) => setBankName(e.target.value)}
                    />
                    <div className="flex_no_wrap_box mt_10">
                      <label className="for_radio">口座</label>
                      <Radio
                        label="普通"
                        name="bank_account"
                        checked={bankAccount === 1}
                        onChange={() => { setBankAccount(bankAccount === 1 ? 2 : 1); }}
                      />
                      <Radio
                        label="当座"
                        name="bank_account"
                        checked={bankAccount === 2}
                        onChange={() => { setBankAccount(bankAccount === 1 ? 2 : 1); }}
                      />
                    </div>
                    <LeftLabelInputField
                      className="bank_account_number mt_10"
                      label="口座番号"
                      type="number"
                      value={bankAccountNum}
                      onChange={(e) => setBankAccountNum(e.target.value)}
                    />
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </EditPC>
  );
};
