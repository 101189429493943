/* eslint-disable no-nested-ternary */
import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { cloneDeep } from 'lodash';
import { DialogActions } from '../../../../../redux/dialog/dialog.action';
import { EditSP } from '../../../../dialogs/edit/edit.sp';
import { Button } from '../../../../ui/button/button';
import { TopLabelInputField } from '../../../../ui/input-field/top-label-input-field/top-label-input-field';
import './estimate-detail-multi-edit.sp.scss';
import { useDidMount } from '../../../../../hooks/life-cycle';
import { Required } from '../../../../ui/required/required';
import { ValidationMax100Million } from '../../../../../model/validation/validation-max-100-million';
import { ValidationLengthUnder255, ValidationLengthUnder500 } from '../../../../../model/validation';
import { ValidationMax10Billion } from '../../../../../model/validation/validation-max-10-billion';
import { Select } from '../../../../ui/select/select';
import { MasterActions } from '../../../../../redux/master/master.action';
import { CommonCollection } from '../../../../../collection/common/common.collection';
import { AddMeisaiData, MeisaiListXML } from '../../../../../model/estimate/estimate-meisai.type';
import { useAppSelector } from '../../../../../hooks/use-redux';
import { pulldown } from '../../../../../utilities/pulldown';
import { LabelInput } from '../../../../ui/input/label-input';
import { cancelFigureSeparate } from '../../../../../utilities/format-num';
import { AddMasterMeisaiSP } from '../../estimate/meisai/add-master/add-master-meisai.sp';
import { InputNum } from '../../../../ui/input/input-num';
import { EstimateMeisaiModel } from '../../../../../model/estimate/estimate-meisai.model';
import { MathHelper } from '../../../../../utilities/math-helper';
import { EstimateMeisaiValidation } from '../../../../../model/validation/estimate/estimate-meisai.validation';
import { Message } from '../../../../../collection/message.collection';
import { MasterMiddleCategory } from '../../../../../type/master/master-middle-category.type';
import { Checkbox } from '../../../../ui/checkbox/checkbox';

type Props = {
  callback: (v: Partial<AddMeisaiData>) => void;
  disabled?: boolean;
  isCheck?: boolean;
};

type EditType = keyof AddMeisaiData;

export const EstimateDetailMultiEditSP = (props: Props) => {
  const {
    callback, disabled, isCheck,
  } = props;

  /* Hook */
  const dispatch = useDispatch();
  const daibunruiList = useAppSelector((v) => v.master.largeCategoryList);
  const middleCategoryList = useAppSelector((v) => v.master.middleCategoryList);
  const taniList = useAppSelector((v) => v.master.unitList);

  /* State */
  const [shohinCd, setShohinCd] = useState('');
  const [kubun, setKubun] = useState('1');
  const [daibunrui, setDaibunrui] = useState('');
  const [printName, setPrintName] = useState('');
  const [tyubunrui, setTyubunrui] = useState('');
  const [name, setName] = useState('');
  const [kikaku, setKikaku] = useState('');
  const [suryo, setSuryo] = useState('0');
  const [tani, setTani] = useState('');
  const [shikirikakaku, setShikiriKakaku] = useState('0');
  const [genka, setGenka] = useState('0');
  const [remarks, setRemarks] = useState('');
  const [touch, setTouch] = useState(false);
  const [_tyubunruiList, set_tyubunruiList] = useState<MasterMiddleCategory[]>([]);
  const [checkList, setCheckList] = useState<EditType[]>([]);

  /* Callback */
  const handleClickSave = useCallback(() => {
    const daibunrui_name = daibunruiList.find((v) => String(v.id) === daibunrui)?.name || '';
    const tyubunrui_name = middleCategoryList.find((v) => String(v.id) === tyubunrui)?.name || '';
    const tani_name = taniList.find((v) => String(v.id) === tani)?.name || '';
    const shohin_kubun_name = CommonCollection.shohinKubunList.find((v) => String(v.value) === kubun)?.text || '';
    const sendData: Partial<AddMeisaiData> = {
      shohin_cd: '',
      arari_ritu: '0',
      shiiresaki_name: '',
    };
    console.log(checkList.includes('daibunrui_id'));
    console.log(daibunrui);
    console.log(tyubunrui);
    console.log(checkList);
    if (checkList.includes('daibunrui_id') && daibunrui !== '' && tyubunrui === '') {
      dispatch(DialogActions.pushMessage({
        title: 'お知らせ',
        message: Message.postError,
      }));
      return;
    }

    checkList.forEach((v) => {
      if (v === 'daibunrui_id' && daibunrui !== '') {
        sendData.daibunrui_id = daibunrui;
        sendData.daibunrui_name = daibunrui_name;
        sendData.daibunrui_name_out = daibunrui_name;
        sendData.tyubunrui_id = tyubunrui;
        sendData.tyubunrui_name = tyubunrui_name;
        sendData.tyubunrui_name_out = tyubunrui_name;
      }
      if (v === 'shohin_kubun' && kubun !== '') {
        sendData.shohin_kubun = kubun;
        sendData.shohin_kubun_name = shohin_kubun_name;
      }
      if (v === 'name' && name !== '') sendData.name = name;
      if (v === 'kikaku') sendData.kikaku = kikaku;
      if (v === 'suryou') sendData.suryou = cancelFigureSeparate(suryo);
      if (v === 'shikiri_kakaku') {
        sendData.shikiri_kakaku = cancelFigureSeparate(shikirikakaku);
        sendData.kingaku = cancelFigureSeparate(shikirikakaku);
      }
      if (v === 'tani_id' && !!Number(tani)) {
        sendData.tani_id = tani;
        sendData.tani_name = tani_name;
      }
      if (v === 'genka') sendData.genka = cancelFigureSeparate(genka);
      if (v === 'meisai_remark') sendData.meisai_remark = remarks;
      if (v === 'print_name') sendData.print_name = printName;
    });

    dispatch(DialogActions.pushMessage({
      title: '見積明細一括更新確認',
      message: ['一括更新を行います。', 'よろしいですか？'],
      callback: () => {
        callback(cloneDeep(sendData));
        dispatch(DialogActions.pop());
      },
      isCancel: true,
      cancelRight: true,
    }));
  }, [
    shohinCd,
    kubun,
    daibunrui,
    tyubunrui,
    name,
    kikaku,
    suryo,
    tani,
    shikirikakaku,
    genka,
    taniList,
    daibunruiList,
    middleCategoryList,
    checkList,
    printName,
    callback,
    remarks,
  ]);

  const setCheck = useCallback((v: EditType) => {
    if (checkList.includes(v)) {
      setCheckList(cloneDeep(
        checkList.filter((v2) => v !== v2),
      ));
    } else {
      setCheckList(cloneDeep(
        checkList.concat([v]),
      ));
      if (v === 'print_name' && checkList.includes('name') && name !== '') {
        setPrintName(name);
      }
    }
  }, [checkList, name, printName]);

  const viewPlaceholder = (key: EditType, value: string, label?: string): string => (
    checkList.includes(key) ? value : (label || '変更なし')
  );

  const viewValue = (key: EditType, value: string): string => (
    checkList.includes(key) ? value : ''
  );

  const changeDaibunrui = useCallback((val: string | number) => {
    const v = String(val);
    if (v === daibunrui) return;
    if (!v) {
      setDaibunrui('');
      setTyubunrui('');
      setShohinCd('');
      set_tyubunruiList(cloneDeep(middleCategoryList));
      return;
    }
    setDaibunrui(String(v));
    setTyubunrui('');
    set_tyubunruiList(middleCategoryList.filter((t) => (String(t.category_id) === v)));
  }, [daibunrui, middleCategoryList]);

  const changeTyubunrui = useCallback((val: string | number) => {
    const v = String(val);
    if (v === tyubunrui) return;
    if (!v) {
      setTyubunrui('');
      setShohinCd('');
      return;
    }
    setTyubunrui(String(v));
    setShohinCd('');
  }, [tyubunrui]);

  /* Master */
  useDidMount(() => {
    dispatch(MasterActions.api.largeCategory.getList({}));
    dispatch(MasterActions.api.middleCategory.getList({}));
    dispatch(MasterActions.api.unit.getList({}));
  });

  useEffect(() => {
    if (middleCategoryList.length) {
      set_tyubunruiList(middleCategoryList.filter((
        v,
      ) => (!daibunrui
        ? true
        : String(v.category_id) === daibunrui
      )));
    }
  }, [middleCategoryList]);

  return (
    <EditSP mode="update" callback={handleClickSave} isCheck isCancel>
      <div className="edit_sp_body_inner estimate_detail_multi_edit_sp">
        <div className="category_wrap">
          <div className="item_wrap flex_box">
            <div className="checkbox_wrap">
              <Checkbox
                checked={checkList.includes('shohin_kubun')}
                onChange={() => setCheck('shohin_kubun')}
              />
            </div>
            <div>
              <div className="item_label">
                <span>商品区分</span>
              </div>
              <div className="item_body item_select full_width">
                <Select
                  value={kubun}
                  onChange={(v) => setKubun(String(v))}
                  defaultLabel="変更なし"
                  disabled={!checkList.includes('shohin_kubun')}
                  options={CommonCollection.shohinKubunList}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="category_wrap">
          <div className="item_wrap flex_box">
            <div className="checkbox_wrap">
              <Checkbox
                checked={checkList.includes('daibunrui_id')}
                onChange={() => setCheck('daibunrui_id')}
              />
            </div>
            <div>
              <div className="item_label">
                大分類<Required />
              </div>
              <div className="item_body item_select full_width">
                <Select
                  value={checkList.includes('daibunrui_id') ? daibunrui : undefined}
                  onChange={changeDaibunrui}
                  disabled={!checkList.includes('daibunrui_id')}
                  defaultLabel="変更なし"
                  options={pulldown(daibunruiList)}
                  touch={touch}
                />
              </div>
            </div>
          </div>
          <div className="item_wrap flex_box">
            <div className="checkbox_wrap" />
            <div>
              <div className="item_label">中分類<Required /></div>
              <div className="item_body item_select full_width">
                <Select
                  value={checkList.includes('daibunrui_id') ? tyubunrui : undefined}
                  onChange={changeTyubunrui}
                  disabled={!checkList.includes('daibunrui_id')
                || (checkList.includes('daibunrui_id') && daibunrui === '')}
                  require={checkList.includes('daibunrui_id') && daibunrui !== ''}
                  changeRequire
                  defaultLabel="変更なし"
                  options={pulldown(_tyubunruiList)}
                  touch={touch}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="category_wrap">
          <div className="item_wrap flex_box">
            <div className="checkbox_wrap">
              <Checkbox
                checked={checkList.includes('name')}
                onChange={() => setCheck('name')}
              />
            </div>
            <div>
              <TopLabelInputField
                label="工事・資材名"
                className="full_width"
                value={viewValue('name', name)}
                onChange={(e) => setName(e.target.value)}
                placeholder="変更なし"
                disabled={!checkList.includes('name')}
                validationList={ValidationLengthUnder255}
                touch={touch}
              />
            </div>
          </div>
          <div className="item_wrap flex_box">
            <div className="checkbox_wrap">
              <Checkbox
                checked={checkList.includes('print_name')}
                onChange={() => setCheck('print_name')}
              />
            </div>
            <div>
              <TopLabelInputField
                label="印刷名称"
                disabled={!checkList.includes('print_name')}
                value={viewValue('print_name', printName)}
                placeholder={viewPlaceholder('print_name', printName)}
                onChange={(e) => setPrintName(e.target.value)}
                className="full_width"
              />
            </div>
          </div>
          <div className="item_wrap flex_box">
            <div className="checkbox_wrap">
              <Checkbox
                checked={checkList.includes('kikaku')}
                onChange={() => setCheck('kikaku')}
              />
            </div>
            <div>
              <TopLabelInputField
                label="規格"
                value={viewValue('kikaku', kikaku)}
                onChange={(e) => setKikaku(e.target.value)}
                placeholder={viewPlaceholder('kikaku', kikaku)}
                disabled={!checkList.includes('kikaku')}
                validationList={ValidationLengthUnder255}
                className="full_width"
              />
            </div>
          </div>
          <div className="item_wrap flex_box">
            <div className="checkbox_wrap">
              <Checkbox
                checked={checkList.includes('suryou')}
                onChange={() => setCheck('suryou')}
              />
            </div>
            <div>
              <LabelInput pos="Top" label="数量">
                <InputNum
                  value={viewValue('suryou', suryo)}
                  onBlur={(v) => setSuryo(EstimateMeisaiModel.setSuryo(v))}
                  onChange={(v) => setSuryo(String(v))}
                  placeholder={viewPlaceholder('suryou', suryo)}
                  alignRight={checkList.includes('suryou')}
                  disabled={!checkList.includes('suryou')}
                  intLen={8}
                  decimalLen={2}
                  validationList={ValidationMax100Million}
                  minus
                />
              </LabelInput>
            </div>
          </div>
          <div className="item_wrap flex_box">
            <div className="checkbox_wrap">
              <Checkbox
                checked={checkList.includes('tani_id')}
                onChange={() => setCheck('tani_id')}
              />
            </div>
            <div>
              <div className="item_label">単位<Required /></div>
              <div className="item_body item_select full_width">
                <Select
                  value={tani}
                  onChange={(v) => setTani(String(v))}
                  disabled={!checkList.includes('tani_id')}
                  defaultLabel="変更なし"
                  options={pulldown(taniList)}
                  touch={touch}
                />
              </div>
            </div>
          </div>
          <div className="item_wrap flex_box">
            <div className="checkbox_wrap">
              <Checkbox
                checked={checkList.includes('shikiri_kakaku')}
                onChange={() => setCheck('shikiri_kakaku')}
              />
            </div>
            <div>
              <LabelInput pos="Top" label="見積単価">
                <InputNum
                  value={viewValue('shikiri_kakaku', shikirikakaku)}
                  placeholder={viewPlaceholder('shikiri_kakaku', shikirikakaku)}
                  alignRight={checkList.includes('shikiri_kakaku')}
                  disabled={!checkList.includes('shikiri_kakaku')}
                  onBlur={(v) => setShikiriKakaku(
                    EstimateMeisaiModel.setNumber(
                      v,
                      (val) => MathHelper.rounding2Str(val, 0, 'round', true),
                    ),
                  )}
                  onChange={(v) => setShikiriKakaku(String(v))}
                  intLen={10}
                  validationList={ValidationMax10Billion}
                  minus
                />
              </LabelInput>
            </div>
          </div>
          <div className="item_wrap flex_box">
            <div className="checkbox_wrap">
              <Checkbox
                checked={checkList.includes('genka')}
                onChange={() => setCheck('genka')}
              />
            </div>
            <div>
              <LabelInput pos="Top" label="原価単価">
                <InputNum
                  value={viewValue('genka', genka)}
                  placeholder={viewPlaceholder('genka', genka)}
                  alignRight={checkList.includes('genka')}
                  disabled={!checkList.includes('genka')}
                  onBlur={(v) => setGenka(
                    EstimateMeisaiModel.setNumber(
                      v,
                      (val) => MathHelper.rounding2Str(val, 0, 'round', true),
                    ),
                  )}
                  onChange={(v) => setGenka(String(v))}
                  intLen={10}
                  validationList={ValidationMax10Billion}
                  minus
                />
              </LabelInput>
            </div>
          </div>
          <div className="item_wrap flex_box">
            <div className="checkbox_wrap">
              <Checkbox
                checked={checkList.includes('meisai_remark')}
                onChange={() => setCheck('meisai_remark')}
              />
            </div>
            <div>
              <LabelInput
                pos="Top"
                label="備考"
                type="text"
                placeholder={viewPlaceholder('meisai_remark', remarks)}
                value={viewValue('meisai_remark', remarks)}
                disabled={!checkList.includes('meisai_remark')}
                onChange={(v) => setRemarks(v)}
                className="full_width"
                validationList={ValidationLengthUnder500}
              />
            </div>
          </div>
        </div>
      </div>
    </EditSP>
  );
};
