export const constructionPeriodCalculation = (
  startDate: Date | null, endDate: Date | null, termDay: number, isBack?: boolean,
) => {
  if (!isBack) {
    if (!termDay || !startDate) return endDate;

    const day = termDay - 1;
    const millisecondsPerDay = 1000 * 60 * 60 * 24;
    return new Date(startDate.getTime() + day * millisecondsPerDay);
  }
  if (!termDay || !endDate) return startDate;

  const day = (termDay - 1) * -1;
  const millisecondsPerDay = 1000 * 60 * 60 * 24;
  return new Date(endDate.getTime() + day * millisecondsPerDay);
};
