import React, {
  useCallback, useEffect, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import isEqual from 'lodash/isEqual';
import { Table } from '../../../../../ui/table/table';
import './csv-list-wedding.scss';
import { State } from '../../../../../../redux/root.reducer';
import { CsvActions } from '../../../../../../redux/csv/csv.action';
import { CsvWeddingAnniversaryListType } from '../../../../../../type/csv/csv.type';
import { ExportCsvCollection } from '../../../../../../collection/export-csv/export-csv.collection';
import { ApiCsvWeddingGetListResponse } from '../../../../../../redux/csv/api/wedding/api-csv-wedding';
import { DateFormatter as DF } from '../../../../../../utilities/date-formatter';
import { RoutingPath } from '../../../../../../routes/routing-pass';
import { useOpenWindow } from '../../../../../../hooks/use-open-window';

type Props = {
  callbackSelected: (selected: number[]) => void;
}
export const CsvListWedding = (props: Props) => {
  const { callbackSelected } = props;

  /* Hooks */
  const list = useSelector((state: State) => state.csv.weddingList);
  const dispatch = useDispatch();
  const windowOpen = useOpenWindow();

  /* State */
  const [selected, setSelected] = useState<number[]>([]);

  /* Callback */
  const handleClick = useCallback((row: ApiCsvWeddingGetListResponse, i) => {
    if (!list) return;
    setSelected([i]);
  }, [list]);

  const handleClickHeader = useCallback(
    (highlow: 0 | 1, sort_by: number) => {
      dispatch(CsvActions.setWeddingSort({ highlow, sort_by }));
    }, [],
  );

  const handleDbClick = useCallback((
    row: ApiCsvWeddingGetListResponse,
    e?: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
  ) => {
    if (!list || !e) return;
    windowOpen({
      url: `${RoutingPath.customerDetail}/${row.id}`,
      e,
    });
    setSelected([list.findIndex((v) => isEqual(v, row))]);
  },
  [list]);

  useEffect(() => {
    callbackSelected(selected);
  }, [selected]);

  return (
    <section className="result_area list_area">
      <div className="inner">
        <div className="table_responsive">
          <Table
            className="table_selectable table_sortable table_sticky table_cell_change"
            header={ExportCsvCollection.weddingHeader}
            onClickRow={handleClick}
            onDbClick={(v, i, e) => handleDbClick(v, e)}
            sort={{ onClick: handleClickHeader }}
            option={ExportCsvCollection.getTableOption('wedding')}
            onClickMulti={(v: CsvWeddingAnniversaryListType[]) => {
              if (!list) return;
              setSelected(v.map((v2) => list.findIndex((v3) => v3.customer_id === v2.customer_id)));
            }}
            rowDataList={list || []}
            selectedTr={selected}
            lists={list
              ? list.map((v) => (
                [
                  v.internal_id,
                  v.name,
                  DF.date2str(v.wedding_anniversary, 'YYYYMMDD', '/'),
                  `${v.post_no.slice(0, 3)}-${v.post_no.slice(3, 8)}`,
                  v.prefecture_name,
                  `${v.city || ''}${v.address || ''}${v.building_name || ''}`,
                  v.tel_no,
                  v.employee_name,
                ]
              ))
              : null}
          />
        </div>
      </div>
    </section>
  );
};
