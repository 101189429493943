import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './maintenance-search-box.pc.scss';
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
import { Select } from '../../../../ui/select/select';
import { MaintenanceSortState } from '../../../../../type/maintenance/maintenance.type';
import { MaintenanceActions } from '../../../../../redux/maintenance/maintenance.action';
import { State } from '../../../../../redux/root.reducer';
import { DatePicker } from '../../../../ui/date-picker/date-picker';
import { RightLabelCheckbox } from '../../../../ui/checkbox/right-label-checkbox/right-label-checkbox';
import { Radio } from '../../../../ui/radio/radio';
import { LeftIconButton } from '../../../../ui/button/left-icon-button/left-icon-button';
import { SearchBoxPC } from '../search-box.pc';
import { useDidMount } from '../../../../../hooks/life-cycle';
import { MasterActions } from '../../../../../redux/master/master.action';
import { Input } from '../../../../ui/input/input';
import { MapActions } from '../../../../../redux/map/map.action';
import { pulldown } from '../../../../../utilities/pulldown';
import { useAppSelector } from '../../../../../hooks/use-redux';
import { UserAgent } from '../../../../../utilities/user-agent';
import { useDateValidation } from '../../../../../hooks/use-date-validation';
import { DateTermValidation } from '../../../../../model/validation/date/term-date.validation';

type Props = {
    /** 権限がない時 */
  noAuthority?: boolean;
  callback: (v: MaintenanceSortState) => void;
  supportedKubun?: number;
  isSearch?: boolean;
}

export const MaintenanceSearchBoxPC = (props: Props) => {
  const {
    noAuthority,
    callback,
    isSearch,
    supportedKubun,
  } = props;

  /* Hooks */
  const user = useAppSelector((v) => v.user);
  const storeList = useAppSelector((v) => v.master.storeList);
  const employeeList = useAppSelector((v) => v.master.employeeList);
  const sortState = useAppSelector((v) => (v.maintenance.sort));
  const dispatch = useDispatch();

  /* State */
  const [sort, setSort] = useState(cloneDeep(sortState));
  const _employeeList = useMemo(() => employeeList.filter((
    v,
  ) => (Number.isNaN(sort.project_store_id) || sort.project_store_id === null
    ? true
    : (v.store_id === sort.project_store_id))),
  [sort.project_store_id, employeeList]);

  const termDate = useDateValidation([
    { start: sort.maintenance_date_start, end: sort.maintenance_date_end },
    { start: sort.completion_date_start, end: sort.completion_date_end },
    { start: sort.created_at_start, end: sort.created_at_end },
    { start: sort.updated_at_start, end: sort.updated_at_end },
  ]);

  /* Callback */
  const setState = useCallback((v: Partial<MaintenanceSortState>) => {
    const data = {
      ...cloneDeep(sort),
      ...cloneDeep(v),
    };
    setSort(data);
  }, [sort, isSearch]);

  const handleClickSearch = useCallback(() => {
    if (termDate.messages.length) {
      termDate.check();
      return;
    }

    dispatch(MapActions.setGpsStatus('out'));
    callback({
      ...cloneDeep(sort),
      limit: sortState.limit,
    });
    if (!isSearch) {
      dispatch(MaintenanceActions.setSort({
        ...cloneDeep(sort),
        limit: sortState.limit,
      }));
    }
  }, [callback, sort, isSearch, termDate, sortState]);

  useEffect(() => {
    if (supportedKubun === undefined) return;
    setState({
      ...cloneDeep(sort),
      supported_kubun: supportedKubun,
    });
  }, [supportedKubun]);

  useDidMount(() => {
    dispatch(MasterActions.api.store.getList({}));
    dispatch(MasterActions.api.employee.getList({}));
  });

  // useEffect(() => {
  //   setState({
  //     project_store_id: UserAgent === 'sp' ? (sortState.project_store_id || user.store_id) : NaN,
  //     project_employee_id: sortState.project_employee_id || user.id,
  //   });
  // }, [user]);

  return (
    <SearchBoxPC
      openCallback={() => { }}
    >
      <div onKeyPress={(e) => { if (e.key === 'Enter') callback(sort); }}>
        <div className="item_wrap">
          <div className="item_box">
            <div className="item_head">メンテナンス日</div>
            <DatePicker
              date={sort?.maintenance_date_start}
              onChange={(v) => setState({ maintenance_date_start: v })}
              term={DateTermValidation([
                { start: sort.maintenance_date_start, end: sort.maintenance_date_end },
              ])}
            />
            <label className="ml_10">〜</label>
            <DatePicker
              date={sort?.maintenance_date_end}
              onChange={(v) => setState({ maintenance_date_end: v })}
              term={DateTermValidation([
                { start: sort.maintenance_date_start, end: sort.maintenance_date_end },
              ])}
            />
          </div>
          <div className="item_box">
            <div className="item_head">案件営業担当</div>
            <Select
              className="add_text_left"
              label="店舗"
              value={sort?.project_store_id}
              defaultLabel="全て"
              onChange={(v) => setState({
                project_store_id: Number(v),
                project_employee_id: Number.isNaN(Number(v))
                || Number(v) === user.store_id
                  ? user.id
                  : NaN,
              })}
              options={pulldown(storeList)}
            />
            <Select
              className="add_text_left"
              defaultLabel="全て"
              label="案件担当者"
              value={sort?.project_employee_id}
              onChange={(data) => setState({ project_employee_id: Number(data) })}
              options={pulldown(_employeeList)}
            />
          </div>
        </div>

        <div className="item_wrap">
          <div className="item_box">
            <div className="item_head">完工日</div>
            <DatePicker
              date={sort?.completion_date_start}
              onChange={(v) => setState({ completion_date_start: v })}
              term={DateTermValidation([
                { start: sort.completion_date_start, end: sort.completion_date_end },
              ])}
            />
            <label className="ml_10">〜</label>
            <DatePicker
              date={sort?.completion_date_end}
              onChange={(v) => setState({ completion_date_end: v })}
              term={DateTermValidation([
                { start: sort.completion_date_start, end: sort.completion_date_end },
              ])}
            />
          </div>
          <div className="item_box">
            <div className="item_head">案件名</div>
            <Input
              value={sort?.project_name}
              onChange={(e) => setState({ project_name: e.target.value })}
            />
          </div>
          <div className="item_box">
            <div className="item_head">顧客名</div>
            <Input
              value={sort?.customer_name}
              onChange={(e) => setState({ customer_name: e.target.value })}
            />
          </div>
          <div className="item_box">
            <RightLabelCheckbox
              checked={Boolean(sort?.is_muko)}
              label="無効情報も含む"
              onChange={() => setState({ is_muko: sort?.is_muko ? 0 : 1 })}
            />
          </div>
        </div>

        <div className="item_wrap">
          <div className="item_box">
            <div className="item_head">対応区分</div>
            <Radio
              label="全て"
              name="supported_kubun"
              checked={sort?.supported_kubun === 2}
              onChange={() => setState({ supported_kubun: 2 })}
            />
            <Radio
              label="未対応"
              name="supported_kubun"
              checked={sort?.supported_kubun === 0}
              onChange={() => setState({ supported_kubun: 0 })}
            />
            <Radio
              label="対応済"
              name="supported_kubun"
              checked={sort?.supported_kubun === 1}
              onChange={() => setState({ supported_kubun: 1 })}
            />
          </div>
          <div className="item_box">
            <div className="item_head">文字列検索</div>
            <Input
              className="mr_10"
              value={sort?.word}
              onChange={(e) => setState({ word: e.target.value })}
            />
            <span className="comment">※検索対象項目：タイトル、詳細内容、対応内容</span>
          </div>
        </div>
        <div className="item_wrap">
          <div className="item_box">
            <div className="item_head">登録者</div>
            <Select
              defaultLabel="全て"
              className="fixed"
              value={sort.created_employee_id}
              onChange={(v) => setState({ created_employee_id: Number(v) })}
              options={pulldown(employeeList)}
            />
          </div>
          <div className="item_box">
            <div className="item_head">登録日</div>
            <DatePicker
              date={sort.created_at_start}
              onChange={(v) => setState({ created_at_start: v })}
              type="YYYY/MM/DD"
              term={DateTermValidation([
                { start: sort.created_at_start, end: sort.created_at_end },
              ])}
            />
            <label className="ml_10">～</label>
            <DatePicker
              date={sort.created_at_end}
              onChange={(v) => setState({ created_at_end: v })}
              type="YYYY/MM/DD"
              term={DateTermValidation([
                { start: sort.created_at_start, end: sort.created_at_end },
              ])}
            />
          </div>
          <div className="item_box">
            <div className="item_head">更新者</div>
            <Select
              defaultLabel="全て"
              className="fixed"
              value={sort.updated_employee_id}
              onChange={(v) => setState({ updated_employee_id: Number(v) })}
              options={pulldown(employeeList)}
            />
          </div>
          <div className="item_box">
            <div className="item_head">更新日</div>
            <DatePicker
              date={sort.updated_at_start}
              onChange={(v) => setState({ updated_at_start: v })}
              type="YYYY/MM/DD"
              term={DateTermValidation([
                { start: sort.updated_at_start, end: sort.updated_at_end },
              ])}
            />
            <label className="ml_10">～</label>
            <DatePicker
              date={sort.updated_at_end}
              onChange={(v) => setState({ updated_at_end: v })}
              type="YYYY/MM/DD"
              term={DateTermValidation([
                { start: sort.updated_at_start, end: sort.updated_at_end },
              ])}
            />
          </div>
          <LeftIconButton
            label="検索"
            fontAwesomeClass="fas fa-search"
            className="btn_search for_detail"
            size="sm"
            color="secondary"
            onClick={handleClickSearch}
          />
        </div>
      </div>
    </SearchBoxPC>
  );
};
