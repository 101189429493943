import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { cloneDeep } from 'lodash';
import { useAppSelector } from '../../../../../../hooks/use-redux';
import { EstimateModel } from '../../../../../../model/estimate/estimate-model';
import { EstimateActions } from '../../../../../../redux/estimate/estimate.action';
import { EstimateListType, EstimateSortState } from '../../../../../../type/estimate/estimate.type';
import { DialogActions } from '../../../../../../redux/dialog/dialog.action';
import { EstimateListSP } from '../../../body/list/estimate/estimate-list';
import { XmlParser } from '../../../../../../parser/xml-parser';
import { MeisaiXML, MeisaiListXML } from '../../../../../../model/estimate/estimate-meisai.type';
import { Button } from '../../../../../ui/button/button';
import { TableSort } from '../../../../../ui/table/table-sort/table-sort';
import { EstimateCollection } from '../../../../../../collection/estimate/estimate.collection';
import { EstimateCopySearchBoxSP } from '../../../../pages/estimate/serch-box/estimate-copy-search-box.sp';

type Props = {
  callback: (v:MeisaiListXML[]) => void;
}

/* 過去見積もり明細コピー */
export const EstimateSearchSP = (props: Props) => {
  const { callback } = props;

  const dispatch = useDispatch();
  const user = useAppSelector((v) => v.user);
  const [list, setList] = useState<EstimateListType[] | null>(null);
  const [hitCount, setHitCount] = useState(0);
  const [isSearch, setIsSearch] = useState(false);
  const [selected, setSelected] = useState<EstimateListType | null>(null);
  const [sortState, setSortState] = useState(EstimateCollection._sortCopyInitialState(user));

  const getList = useCallback((v: EstimateSortState) => {
    setIsSearch(true);
    setList(null);
    dispatch(EstimateActions.api.estimate.getList({
      param: {
        ...EstimateModel.listParamInCopy(v),
      },
      callback: (data, hit) => {
        if (!data.length) {
          dispatch(DialogActions.pushMessage({
            title: '',
            message: ['該当する結果が見つかりません'],
          }));
          return;
        }
        setList(data);
        setHitCount(hit || 0);
      },
    }));
  }, [sortState]);

  const post = useCallback(() => {
    if (!selected) return;
    new XmlParser().parse<MeisaiXML>(selected.meisai).then((res) => {
      callback(res.meisai.mei);
    });
  }, [selected]);

  useEffect(() => {
    if (!isSearch) return;
    getList(sortState);
  }, [
    sortState.offset,
  ]);

  return (
    !isSearch
      ? (
        <EstimateCopySearchBoxSP
          sort={sortState}
          callback={(v) => {
            setSortState(cloneDeep(v));
            getList(v);
          }}
          callbackClear={(v) => {
            setSortState(cloneDeep(v));
          }}
        />
      )
      : (
        <>
          <TableSort
            isSp
            page={sortState.offset ?? 0}
            limit={100}
            hitCount={hitCount || 0}
            callback={(v) => {
              setSortState({ ...sortState, offset: v });
            }}
            className="mt_5 mb_0"
          />
          <hr className="hr_style hr_search_result" />
          <EstimateListSP data={list} handleCardClick={setSelected} isCopy />
          <div className="base_dialog_content_inner_footer">
            <Button
              size="md"
              color="secondary"
              className="item_btn_right"
              disabled={!selected}
              onClick={post}
            >
              登録
            </Button>
            <Button
              size="md"
              color="dark"
              className="item_btn_right"
              onClick={() => {
                setSelected(null);
                setList(null);
                setIsSearch(false);
              }}
            >
              戻る
            </Button>
          </div>
        </>
      )
  );
};
