import { prefectures } from '../collection/prefectures';

export const getPrefectureByStr = (label: string) => {
  const find = prefectures.find((v) => v.label === label);
  return find ? find.value : NaN;
};

export const getPrefectureByNumber = (value: number) => {
  const find = prefectures.find((v) => v.value === value);
  console.log(find?.label);
  return find ? find.label : '';
};
