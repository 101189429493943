import { useDispatch } from 'react-redux';
import { useCallback, useMemo, useState } from 'react';
import { cloneDeep } from 'lodash';
import { useDidMount } from '../../../../../hooks/life-cycle';
import { EstimateModel } from '../../../../../model/estimate/estimate-model';
import { MasterActions } from '../../../../../redux/master/master.action';
import { Select } from '../../../../ui/select/select';
import { useAppSelector } from '../../../../../hooks/use-redux';
import { pulldown } from '../../../../../utilities/pulldown';
import { Button } from '../../../../ui/button/button';
import { DialogActions } from '../../../../../redux/dialog/dialog.action';
import { EstimateMeisaiModel } from '../../../../../model/estimate/estimate-meisai.model';
import { MasterLargeCategory } from '../../../../../type/master/master-large-category.type';
import { MasterMiddleCategory } from '../../../../../type/master/master-middle-category.type';

type Props = {
    model: EstimateMeisaiModel | null;
    callback?: (v: EstimateMeisaiModel) => void;
}

export const MeisaiSortDialog = (props: Props) => {
  const { model, callback } = props;
  const dispatch = useDispatch();
  const _largeList = useAppSelector((v) => v.master.largeCategoryList);
  const middleList = useAppSelector((v) => v.master.middleCategoryList);

  const largeList = useMemo(() => {
    if (!model) return [];
    const base: MasterLargeCategory[] = [];
    model.list.forEach((v) => {
      const find = _largeList.find((v2) => Number(v2.id) === Number(v.$.daibunrui_id));
      if (find && base.findIndex((v2) => v2.id === Number(find.id)) < 0) {
        base.push({
          ...find,
          name: v.$.daibunrui_name,
        });
      }
    });
    return base;
  },
  [model, _largeList]);

  const [largeId, setLargeId] = useState(NaN);
  const [middleId, setMiddleId] = useState(NaN);

  const sortMiddleList = useMemo(() => {
    if (!model) return [];
    const baseList: MasterMiddleCategory[] = [];
    model.list.forEach((v) => {
      const find = middleList.find((v2) => Number(v2.id) === Number(v.$.tyubunrui_id));
      if (
        find
        && baseList.findIndex((v2) => v2.id === Number(v.$.tyubunrui_id)) < 0
        && Number(find.category_id) === Number(largeId)
      ) {
        baseList.push({
          ...find,
          name: v.$.tyubunrui_name,
        });
      }
    });
    if (baseList.findIndex((v) => v.id === middleId) < 0) setMiddleId(NaN);
    return baseList;
  }, [middleList, largeId, middleId, largeList, model]);

  const handleClickSort = useCallback(() => {
    if (!model) return;
    model.sortPption = {
      daibunrui: largeId,
      tyubunrui: middleId,
    };
    callback?.(cloneDeep(model));
  }, [model, largeId, middleId, callback]);

  useDidMount(() => {
    dispatch(MasterActions.api.largeCategory.getList({ is_muko: 1 }));
    dispatch(MasterActions.api.middleCategory.getList({ is_muko: 1 }));
  });

  return (
    <>
      <div className="edit_sp_body">
        <div className="edit_sp_body_inner">
          <div className="category_wrap">
            <div className="item_wrap">
              <div className="item_label">大分類</div>
              <Select
                value={largeId}
                defaultLabel="指定なし"
                onChange={(v) => setLargeId(Number(v))}
                options={pulldown(largeList)}
              />
            </div>
            <div className="item_wrap">
              <div className="item_label">中分類</div>
              <Select
                value={middleId}
                defaultLabel="指定なし"
                onChange={(v) => setMiddleId(Number(v))}
                options={pulldown(sortMiddleList)}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="edit_sp_footer">
        <Button onClick={() => {
          dispatch(DialogActions.pop());
        }}
        >戻る
        </Button>
        <Button
          color="secondary"
          onClick={() => {
            handleClickSort();
            dispatch(DialogActions.pop());
          }}
        >絞り込み
        </Button>
      </div>
    </>
  );
};
