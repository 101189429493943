import lodash from 'lodash';

export const tableSort = <T = any>(list:T[], order:'asc' | 'desc', key: keyof T) => {
  const cloneList = lodash.cloneDeep(list);
  let nullValue: string | number = 0;
  cloneList.sort((a, b) => {
    if (typeof a[key] === 'string') {
      nullValue = '';
    }
    if (order === 'asc') {
      return (a[key] || nullValue) > (b[key] || nullValue) ? 1 : -1;
    }
    return (a[key] || nullValue) < (b[key] || nullValue) ? 1 : -1;
  });
  return cloneList;
};
