import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { Segment } from 'semantic-ui-react';
import * as AutoKana from 'vanilla-autokana';
import cloneDeep from 'lodash/cloneDeep';
import CopyToClipboard from 'react-copy-to-clipboard';
import { DialogActions } from '../../../../../redux/dialog/dialog.action';
import { Button } from '../../../../ui/button/button';
import { IconButton } from '../../../../ui/button/icon-button/icon-button';
import { RightLabelCheckbox } from '../../../../ui/checkbox/right-label-checkbox/right-label-checkbox';
import { Input } from '../../../../ui/input/input';
import { Required } from '../../../../ui/required/required';
import './customer-edit.scss';
import { CustomerActions } from '../../../../../redux/customer/customer.action';
import { prefectures } from '../../../../../collection/prefectures';
import { TagModel } from '../../../../../model/tag/tag';
import { State } from '../../../../../redux/root.reducer';
import { TagActions } from '../../../../../redux/tag/tag.action';
import { CustomerCollection as CC } from '../../../../../collection/customer/customer.collection';
import { DatePicker } from '../../../../ui/date-picker/date-picker';
import { Select } from '../../../../ui/select/select';
import { FamilyEditDialog } from './family/family-edit-dialog';
import { PetEditDialog } from './pet/pet-edit-dialog';
import { autoKana } from '../../../../../utilities/auto-kana';
import { MapActions } from '../../../../../redux/map/map.action';
import { EditPC } from '../../../../dialogs/edit/edit.pc';
import { PetInfoList } from '../../../layout/body/list/pet-info/pet-info-list.pc';
import { FamilyInfoList } from '../../../layout/body/list/family-info/family-info-list.pc';
import { MasterActions } from '../../../../../redux/master/master.action';
import { TextArea } from '../../../../ui/text-area/text-area';
import { CustomerValidation } from '../../../../../model/validation/customer/customer.validation';
import { LeftLabelInputField } from '../../../../ui/input-field/left-label-input-field/left-label-input-field';
import { useDidMount, useWillUnMount } from '../../../../../hooks/life-cycle';
import {
  ValidationFacebookId,
  ValidationInstagramId,
  ValidationLengthUnder10,
  ValidationLengthUnder20,
  ValidationLengthUnder255,
  ValidationLengthUnder500,
  ValidationLengthUnder60,
  ValidationLineId,
  ValidationTwitterId,
} from '../../../../../model/validation';
import { ValidationDatePicker } from '../../../../../model/validation/validation-date-picker';
import { PetListModel } from '../../../../../model/customer/pet-list.model';
import { FamilyListModel } from '../../../../../model/customer/family-list.model';
import { CustomerModel as CM } from '../../../../../model/customer/customer-model';
import { Customer, CustomerEditState } from '../../../../../type/customer/customer.type';
import { useAppSelector } from '../../../../../hooks/use-redux';
import { TagCheckBoxex } from '../../../../ui/tag-check-boxex/tag-check-boxex';
import { InputMail } from '../../../../ui/input/input-mail';
import { InputTel } from '../../../../ui/input/input-tel';
import { ItemWrap } from '../../../../ui/item/item-wrap';
import { pulldown, pulldownCustomerRank } from '../../../../../utilities/pulldown';
import { Tikunengetsu } from '../../../../ui/tikunengetsu/tikunengetsu';
import { SearchPostAddressDialog, minus0 } from '../../../../ui/search-post-address-dialog/search-post-address-dialog';
import { Message } from '../../../../../collection/message.collection';
import { LabelInput } from '../../../../ui/input/label-input';
import { InputPostNo } from '../../../../ui/input/input-post-no';
import { useMailContact, useSNSContact } from '../../../../../hooks/support-history/use-support-history';
import { useRegistrationMap } from '../../../../../hooks/common/use-common';
import { useEditAuthCustomer } from '../../../../../hooks/use-authority';
import { ValidationLengthUnderHankaku60 } from '../../../../../model/validation/validation-length-under-hankaku60';
import { InputPostNoMulti } from '../../../../ui/input/input-post-no-multi';
import { DateFormatter as DF } from '../../../../../utilities/date-formatter';
import { InputSelect } from '../../../../ui/input-select/input-select';

type Props = {
  id?: number;
  callback: () => void;
  callbackSNS?:() => void
  callbackSNSEnd?: () => void;
}

const masterSelector = (state: State) => state.master;
const tagSelector = (state: State) => state.tag;

export const CustomerEdit = (props: Props) => {
  const {
    id, callback, callbackSNS, callbackSNSEnd,
  } = props;

  /* Hooks */
  const dispatch = useDispatch();
  const contactSNS = useSNSContact();
  const registrationMap = useRegistrationMap();

  const user = useAppSelector((v) => v.user);
  const sortState = useAppSelector((v) => v.customer.sort);
  const { partList, masterMyCarTypeList, relevantTagList } = useAppSelector(tagSelector);
  const {
    buildingCategoryList,
    madoriList,
    areaList,
    storeList,
    employeeList,
    originList,
    customerRankList,
    customerEstimatedRankList,
  } = useAppSelector(masterSelector);
  const mailContact = useMailContact();

  /* State */
  const [info, setInfo] = useState<Customer | null>(null);
  const [customer, setCustomer] = useState<CustomerEditState>(CC.customerEditInitialState({
    ...user,
    store_id: !user.view_data || user.view_data.company_id === 1 ? user.store_id : NaN,
    id: !user.view_data || user.view_data.company_id === 1 ? user.id : NaN,
    prefecture_id: !user.view_data || user.view_data.company_id === 1 ? user.prefecture_id : NaN,
  }));
  const [touch, setTouch] = useState(false);
  const [familyId, setFamilyId] = useState(NaN);
  const [petId, setPetId] = useState(NaN);
  const [autoFurigana, setAutoFurigana] = useState<AutoKana.AutoKana | null>();
  const [familyListModel, setFamilyListModel] = useState<FamilyListModel>(new FamilyListModel());
  const [famEditCount, setFamEditCount] = useState(0);
  const [petListModel, setPetListModel] = useState<PetListModel>(new PetListModel());
  const [petEditCount, setPetEditCount] = useState(0);
  const [customerId, setCustomerId] = useState('');
  const editAuth = useEditAuthCustomer(info?.employee_id);

  const _employeeList = useMemo(() => {
    console.log(customer.store_id);
    return employeeList.filter((
      v,
    ) => (Number.isNaN(customer.store_id) || customer.store_id === null
      ? true
      : (v.store_id === customer.store_id)));
  }, [customer.store_id, employeeList]);

  /* Memo */
  const ob = useMemo(() => {
    if (id === undefined) return '';
    return customer.ob_flag ? 'OB' : '見込み';
  }, [id]);

  /* Callback */
  const setState = useCallback((v: Partial<CustomerEditState>) => {
    setCustomer({ ...cloneDeep(customer), ...cloneDeep(v) });
  }, [customer]);

  const setCustomerName = useCallback((v: CustomerEditState) => {
    setCustomer(autoFurigana ? { ...v, furigana: autoFurigana.getFurigana() } : v);
  }, [customer, autoFurigana]);

  const handleClickPost = useCallback(() => {
    if (!editAuth) return;
    if (CustomerValidation(customer)) {
      dispatch(DialogActions.pushMessage({
        title: `顧客情報${id ? '更新' : '登録'}`,
        message: Message.postError,
        callback: () => setTouch(true),
      }));
      return;
    }

    const prefe = prefectures.find((v) => v.value === customer.prefecture)?.label;

    const post = (data?: {
      position: globalThis.google.maps.LatLngLiteral;
      address: string;
    }) => {
      dispatch(CustomerActions.api.customer.post({
        param: CM.postParam({
          petListModel, familyListModel, customer, id, data,
        }),
        onSuccess: () => {
          if (id) {
            dispatch(CustomerActions.api.customer.get({
              param: { id },
              callback: (v) => {
                setCustomerId(String(v.internal_id));
                setInfo(v);
              },
            }));
          } else {
            callback?.();
          }
        },
        onError: () => setTouch(true),
        update: !!id,
      }));
    };

    const confirm = () => {
      if (info && (
        info.prefecture === customer.prefecture
        && info.city === customer.city
        && info.address === customer.address
        && !!info.lat
        && !!info.lng
      )) {
        post();
        return;
      }
      dispatch(MapActions.api.geocoder({
        isRegist: true,
        param: {
          param: { address: `${prefe || ''}${customer.city}${customer.address}` },
          noMessage: true,
        },
        callback: post,
      }));
    };

    /* 保存API */
    if (id) {
      dispatch(DialogActions.pushMessage({
        title: `${id ? '更新' : '登録'}確認`,
        message: id ? Message.postUpdateConfirm : Message.postConfirm,
        isCancel: true,
        callback: confirm,
      }));
      return;
    }
    confirm();
  }, [
    customer, id, prefectures, callback, sortState, info, editAuth, familyListModel, petListModel,
  ]);

  const searchPostNo = useCallback(() => {
    if (!editAuth) return;
    dispatch(DialogActions.push({
      title: '住所検索',
      className: 'auto_height_dialog',
      element: <SearchPostAddressDialog
        zipcode1={customer.post_no[0]}
        zipcode2={customer.post_no[1]}
        jisx0402={customer.jisx0402_code || ''}
        pref={customer.prefecture}
        city={customer.city}
        town={customer.address}
        calback={(v) => {
          setState({
            post_no: [
              v.zipcode1,
              v.zipcode2,
            ],
            prefecture: v.pref,
            city: v.city,
            jisx0402_code: v.jisx0402,
            address: v.town,
          });
        }}
      />,
    }));
  }, [editAuth, customer]);

  const handleClickRegistrationMap = useCallback(() => {
    if (!editAuth) return;
    registrationMap((address) => {
      if (!address) return;
      setState(CM.registrationMap({ address }));
    });
  }, [customer]);

  const handleClickSearchAddress = useCallback(() => {
    if (!editAuth) return;
    dispatch(MapActions.api.addressSearch({
      param: {
        zipcode1: String(customer.post_no[0]),
        zipcode2: String(customer.post_no[1]),
      },
      callback: (address) => setState(CM.searchAddress({ customer, address })),
    }));
  }, [customer, editAuth]);

  const confirmActivate = useCallback((title: string) => {
    dispatch(DialogActions.pushMessage({
      title,
      message: ['顧客情報の更新ボタンを押下後反映されます'],
    }));
  }, []);

  const editFamily = useCallback((isEdit?: 'edit') => {
    if (!editAuth) return;
    if (isEdit && Number.isNaN(familyId)) return;
    const data = isEdit ? familyListModel.list.find((v) => v.index === familyId) : undefined;
    dispatch(DialogActions.push({
      title: `ご家族情報${isEdit ? '編集' : '登録'}`,
      className: 'auto_height_dialog',
      element: <FamilyEditDialog
        id={1}
        isEdit={!!isEdit}
        data={isEdit ? data : undefined}
        callbackPost={(res) => {
          if (isEdit) {
            familyListModel.edit(res);
          } else {
            familyListModel.add(res);
            setFamEditCount(famEditCount + 1);
            setFamilyId(NaN);
          }
          dispatch(DialogActions.pop());
          confirmActivate(`ご家族情報${isEdit ? '編集' : '登録'}`);
          setFamilyListModel(cloneDeep(familyListModel));
        }}
      />,
    }));
  }, [familyId, familyListModel, famEditCount, editAuth]);

  const editPet = useCallback((isEdit?: 'edit') => {
    if (!editAuth) return;
    if (isEdit && Number.isNaN(petId)) return;
    const data = isEdit ? petListModel.list.find((v) => v.index === petId) : undefined;
    dispatch(DialogActions.push({
      title: `ペット情報${isEdit ? '編集' : '登録'}`,
      className: 'auto_height_dialog',
      element: <PetEditDialog
        id={1}
        isEdit={!!isEdit}
        data={isEdit ? data : undefined}
        callbackPost={(res) => {
          if (isEdit) {
            petListModel.edit(res);
          } else {
            petListModel.add(res);
            setPetId(NaN);
            setPetEditCount(petEditCount + 1);
          }
          dispatch(DialogActions.pop());
          setPetListModel(cloneDeep(petListModel));
          confirmActivate(`ペット情報${isEdit ? '編集' : '登録'}`);
        }}
      />,
    }));
  }, [petId, petListModel, petEditCount, editAuth]);

  const removeFamily = useCallback(() => {
    if (!editAuth) return;
    const data = familyListModel.list.find((v) => v.index === familyId);
    if (!data) return;
    dispatch(DialogActions.pushMessage({
      title: '家族情報削除',
      message: [`${data.name}さんの情報を削除しますか`],
      isCancel: true,
      callback: () => {
        setFamilyId(NaN);
        familyListModel.remove(data);
        setFamilyListModel(cloneDeep(familyListModel));
      },
    }));
  }, [familyId, editAuth]);

  const removePet = useCallback(() => {
    if (!editAuth) return;
    const data = petListModel.list.find((v) => v.index === petId);
    if (!data) return;
    dispatch(DialogActions.pushMessage({
      title: 'ペット情報削除',
      message: [`${data.name}の情報を削除しますか`],
      isCancel: true,
      callback: () => {
        setPetId(NaN);
        petListModel.remove(data);
        setPetListModel(cloneDeep(petListModel));
      },
    }));
  }, [petId, editAuth]);

  const familySort = useCallback((order: number, sort: number) => {
    familyListModel.sortHeader(order ? 'desc' : 'asc', sort);
    setFamilyListModel(cloneDeep(familyListModel));
  }, [familyListModel]);

  const petSort = useCallback((order: number, sort: number) => {
    petListModel.sortHeader(order ? 'desc' : 'asc', sort);
    setPetListModel(cloneDeep(petListModel));
  }, [petListModel]);

  const sendMail = useCallback((address: string | undefined) => {
    if (!info) return;
    callbackSNS?.();
    mailContact({
      type: 'customer',
      data: info,
      mailAddress: address,
      isPushDialog: true,
      callbackPostEnd: () => callbackSNSEnd?.(),
    });
  }, [info, callbackSNS, callbackSNSEnd]);

  /* Effect */
  useDidMount(() => {
    dispatch(TagActions.api.masterMyCarType.getList());
    dispatch(TagActions.api.relevantTag.getList());
    dispatch(TagActions.api.part.getList());
    dispatch(MasterActions.api.buildingCategory.getList({}));
    dispatch(MasterActions.api.madori.getList({}));
    dispatch(MasterActions.api.area.getList({}));
    dispatch(MasterActions.api.store.getList({}));
    dispatch(MasterActions.api.employee.getList({}));
    dispatch(MasterActions.api.area.getList({}));
    dispatch(MasterActions.api.origin.getList({}));
    dispatch(MasterActions.api.customerRank.getList({}));
    dispatch(MasterActions.api.customerExpectedRank.getList({}));
    if (!id) {
      setAutoFurigana(autoKana('name', 'furigana', true));
    }
  });

  /* Get */
  useEffect(() => {
    if (id === undefined) return;
    dispatch(CustomerActions.api.customer.get({
      param: { id },
      callback: (v) => {
        setCustomerId(String(v.internal_id));
        setInfo(v);
      },
    }));
  }, [id]);

  /* 離脱処理 */
  useWillUnMount(() => {
    dispatch(CustomerActions.setCustomer(null));
    dispatch(CustomerActions.setFamilyList([]));
    dispatch(CustomerActions.setPetList([]));
  });

  /* 詳細情報のセット */
  useEffect(() => {
    if (!info) return;
    setState(CM.setEditData({
      customer, data: info, partList, masterMyCarTypeList, relevantTagList,
    }));
    setFamilyListModel(new FamilyListModel(info.familylist));
    setPetListModel(new PetListModel(info.petlist));
  }, [partList, masterMyCarTypeList, relevantTagList, info]);

  useEffect(() => {
    if (info) return;
    setState({
      part_list: new TagModel(cloneDeep(partList)),
      expected_part_list: new TagModel(cloneDeep(partList)),
      tag_list: new TagModel(relevantTagList),
      my_car_type: new TagModel(masterMyCarTypeList),
    });
  }, [partList, info, relevantTagList, masterMyCarTypeList]);

  return (
    (
      <EditPC mode={!id ? 'dialog' : 'detail'} callback={handleClickPost} disabled={!editAuth}>
        {/* editPC_body_inner は各画面の共通用 */}
        <div className="edit_pc_body_inner edit_customer">
          <div className="left_box">
            <ItemWrap title="顧客ID">
              <div className="flex_wrap_box">
                <Input
                  className="mr_10"
                  label="顧客ID"
                  value={customerId}
                  disabled
                />
                <Input
                  className="small"
                  value={ob}
                  disabled
                />
              </div>
            </ItemWrap>
            <div className="item_wrap">
              <div className="item_box">
                <div className="item_head">名称<Required /></div>
                <Input
                  value={customer?.name}
                  disabled={!editAuth}
                  onChange={(e) => setCustomerName({ ...customer, name: e.target.value })}
                  require
                  id="name"
                  validationList={ValidationLengthUnder60}
                  touch={touch}
                />
              </div>
              <div className="item_box">
                <div className="item_head">敬称</div>
                <Input
                  className="small"
                  value={customer?.keisho}
                  disabled={!editAuth}
                  onChange={(e) => setState({ keisho: e.target.value })}
                  validationList={ValidationLengthUnder10}
                />
              </div>
            </div>
            <ItemWrap title="フリガナ">
              <Input
                id="furigana"
                className="full_width"
                label="フリガナ"
                value={customer?.furigana}
                disabled={!editAuth}
                onChange={(e) => setState({ furigana: e.target.value })}
                validationList={ValidationLengthUnder60}
              />
            </ItemWrap>
            <ItemWrap title={<>郵便番号<Required /></>}>
              <div className="item_postal">
                <InputPostNoMulti
                  value={customer?.post_no[0]}
                  touch={touch}
                  require
                  onChange={(v) => {
                    setState({
                      post_no: [
                        v,
                        customer.post_no[1],
                      ],
                    });
                  }}
                  disabled={!editAuth}
                  value2={customer?.post_no[1]}
                  touch2={touch}
                  require2
                  onChange2={(v) => setState({
                    post_no: [
                      customer.post_no[0],
                      v,
                    ],
                  })}
                  disabled2={!editAuth}
                />
                {/* <LabelInput
                  pos="Left"
                  label="〒"
                >
                  <InputPostNo
                    value={customer?.post_no[0]}
                    len={3}
                    touch={touch}
                    require
                    onChange={(v) => {
                      if (v.length > 2) {
                        setPostFocus(true);
                      }
                      setState({
                        post_no: [
                          v,
                          customer.post_no[1],
                        ],
                      });
                    }}
                    disabled={!editAuth}
                  />
                </LabelInput>
                <LabelInput
                  pos="Left"
                  label="-"
                >
                  <InputPostNo
                    value={customer?.post_no[1]}
                    len={4}
                    touch={touch}
                    isFocus={postFocus}
                    onFocus={() => setPostFocus(false)}
                    require
                    onChange={(v) => setState({
                      post_no: [
                        customer.post_no[0],
                        v,
                      ],
                    })}
                    disabled={!editAuth}
                  />
                </LabelInput> */}
                <Button
                  size="sm"
                  color="secondary"
                  className="ml_10"
                  disabled={!editAuth}
                  onClick={handleClickSearchAddress}
                >住所入力
                </Button>
                <Button
                  size="sm"
                  color="secondary"
                  className="ml_10"
                  disabled={!editAuth}
                  onClick={searchPostNo}
                >詳細住所入力
                </Button>
                <Button
                  size="sm"
                  className="ml_10"
                  color="secondary"
                  disabled={!editAuth}
                  onClick={handleClickRegistrationMap}
                >地図から入力
                </Button>
              </div>
            </ItemWrap>
            <div className="item_wrap">
              <div className="item_box max_width">
                <div className="item_head">住所<Required /></div>
                <div className="item_adress">
                  <Select
                    className="add_text_left"
                    label="都道府県"
                    value={customer?.prefecture || NaN}
                    disabled={!editAuth}
                    onChange={(v) => setState({ prefecture: Number(v) })}
                    defaultLabel="指定無し"
                    options={[
                      ...prefectures.map((v) => ({
                        text: v.label, value: v.value,
                      })),
                    ]}
                    require
                    touch={touch}
                  />
                  <LeftLabelInputField
                    label="市区町村"
                    value={customer?.city || NaN}
                    disabled={!editAuth}
                    onChange={(e) => setState({
                      city: e.target.value,
                      jisx0402_code: undefined,
                    })}
                    validationList={ValidationLengthUnder255}
                    className="large"
                    require
                    touch={touch}
                  />
                  <LeftLabelInputField
                    label="地名・番地"
                    value={customer?.address}
                    disabled={!editAuth}
                    onChange={(e) => setState({ address: e.target.value })}
                    validationList={ValidationLengthUnder255}
                    className="large"
                    require
                    touch={touch}
                  />
                  <LeftLabelInputField
                    label="建物名等"
                    value={customer?.building_name}
                    disabled={!editAuth}
                    onChange={(e) => setState({ building_name: e.target.value })}
                    validationList={ValidationLengthUnder255}
                    className="large"
                  />
                </div>
              </div>
            </div>
            <ItemWrap title="Email" classNameBox="max_width">
              <>
                <InputMail
                  value={customer?.mail_address}
                  disabled={!editAuth}
                  onChange={(v) => {
                    console.log('atteru');
                    setState({ mail_address: v });
                  }}
                  className="large email"
                />
                {info
                && (
                <IconButton
                  fontAwesomeClass="fas fa-envelope"
                  className="sns"
                  size="sm"
                  color="white"
                  disabled={
                    !customer.mail_address || (user.view_data && user.view_data.company_id !== 1)
                  }
                  onClick={() => sendMail(customer.mail_address)}
                />
                )}
              </>
            </ItemWrap>
            <div className="item_wrap">
              <div className="item_box">
                <div className="item_head">電話</div>
                <InputTel
                  value={customer?.tel_no}
                  disabled={!editAuth}
                  onChange={(v) => setState({ tel_no: v })}
                  className="full_width"
                />
              </div>
              <div className="item_box">
                <div className="item_head">FAX</div>
                <InputTel
                  value={customer?.fax_no}
                  disabled={!editAuth}
                  onChange={(v) => setState({ fax_no: v })}
                  className="full_width"
                />
              </div>
            </div>
            <div className="item_wrap">
              <div className="item_box">
                <div className="item_head">電話番号２</div>
                <InputTel
                  value={customer?.tel_no2}
                  disabled={!editAuth}
                  onChange={(v) => setState({ tel_no2: v })}
                  className="full_width"
                />
              </div>
              <div className="item_box">
                <div className="item_head">電話番号３</div>
                <InputTel
                  value={customer?.tel_no3}
                  disabled={!editAuth}
                  onChange={(v) => setState({ tel_no3: v })}
                  className="full_width"
                />
              </div>
            </div>
            <ItemWrap title="Email2" classNameBox="max_width">
              <>
                <InputMail
                  value={customer?.mail_address2}
                  disabled={!editAuth}
                  onChange={(v) => setState({ mail_address2: v })}
                  className="large email"
                />
                {info
                && (
                <IconButton
                  fontAwesomeClass="fas fa-envelope"
                  className="sns"
                  size="sm"
                  color="white"
                  disabled={
                    !customer.mail_address2 || (user.view_data && user.view_data.company_id !== 1)
                  }
                  onClick={() => sendMail(customer.mail_address2)}
                />
                )}
              </>
            </ItemWrap>
            <ItemWrap title="Email3" classNameBox="max_width">
              <>
                <InputMail
                  value={customer?.mail_address3}
                  disabled={!editAuth}
                  onChange={(v) => setState({ mail_address3: v })}
                  className="large email"
                />
                {info
                && (
                <IconButton
                  fontAwesomeClass="fas fa-envelope"
                  className="sns"
                  size="sm"
                  color="white"
                  disabled={
                    !customer.mail_address3 || (user.view_data && user.view_data.company_id !== 1)
                  }
                  onClick={() => sendMail(customer.mail_address3)}
                />
                )}
              </>
            </ItemWrap>
            <ItemWrap title="エリア">
              <div className="item_body item_area">
                {/* <Select
                  value={customer?.area_id}
                  disabled={!editAuth}
                  defaultLabel="指定無し"
                  options={pulldown(areaList)}
                  onChange={(v) => setState({ area_id: Number(v) })}
                /> */}
                <InputSelect
                  value={customer?.area_id || 0}
                  disabled={!editAuth}
                  defaultValue="指定無し"
                  options={areaList.map((v) => ({
                    id: v.id,
                    label: v.name,
                  }))}
                  onChange={(v) => setState({ area_id: Number(v) })}
                  className="react_select"
                />
              </div>
            </ItemWrap>
            <ItemWrap title="営業担当">
              <>
                <Select
                  className="add_text_left"
                  label="店舗"
                  value={customer?.store_id || NaN}
                  disabled={!editAuth}
                  defaultLabel="指定無し"
                  options={pulldown(storeList)}
                  onChange={(v) => setState({ store_id: Number(v), employee_id: NaN })}
                />
                <Select
                  className="add_text_left"
                  label="担当者"
                  value={customer?.employee_id || NaN}
                  disabled={!editAuth}
                  defaultLabel="指定無し"
                  options={pulldown(_employeeList)}
                  onChange={(v) => {
                    setState({ employee_id: Number(v) });
                  }}
                  require
                  touch={touch}
                />
              </>
            </ItemWrap>
            <div className="item_wrap">
              <div className="item_box">
                <div className="item_head">顧客見込みランク</div>
                <div className="item_body item_customerRank">
                  <Select
                    value={customer?.estimated_rank || NaN}
                    defaultLabel="指定無し"
                    options={customerEstimatedRankList.map((v) => ({
                      text: v.name, value: v.id,
                    }))}
                    disabled={!editAuth}
                    onChange={(v) => { setState({ estimated_rank: Number(v) }); }}
                  />
                </div>
              </div>
              <div className="item_box">
                <div className="item_head">顧客ランク</div>
                <div className="item_body item_customerRank">
                  <Select
                    value={customer?.rank || ''}
                    defaultLabel="指定無し"
                    options={pulldownCustomerRank(customerRankList)}
                    disabled={!editAuth || user.company_authority4}
                    onChange={(v) => {
                      if (!editAuth || user.company_authority4) return;
                      setState({ rank: String(v) });
                    }}
                  />
                </div>
              </div>
            </div>
            <ItemWrap title="発生源">
              <div className="item_body item_source">
                <Select
                  value={customer?.source_id || NaN}
                  disabled={!editAuth}
                  defaultLabel="指定無し"
                  options={originList.map((v) => ({
                    text: v.name, value: v.id,
                  }))}
                  onChange={(v) => setState({ source_id: Number(v) })}
                />
              </div>
            </ItemWrap>
            <div className="item_wrap tags_form">
              <TagCheckBoxex
                head="見込み部位"
                keys="eTag"
                model={customer?.expected_part_list}
                disabled={!editAuth}
                onChange={(v) => setState({ expected_part_list: v })}
              />
            </div>
            <div className="item_wrap tags_form">
              <TagCheckBoxex
                head="部位"
                keys="pTag"
                model={customer?.part_list}
                disabled={!editAuth}
                onChange={(v) => setState({ part_list: v })}
              />
            </div>
            <ItemWrap title="LINE ID">
              <>
                <Input
                  value={customer?.line_id || ''}
                  disabled={!editAuth}
                  onChange={(e) => setState({ line_id: e.target.value })}
                  validationList={ValidationLineId}
                  className="full_width"
                />
                <CopyToClipboard text={customer?.line_id || ''}>
                  <IconButton
                    fontAwesomeClass="fas fa-copy"
                    className="sns"
                    size="sm"
                    color="white"
                    onClick={() => {
                      if (!customer.line_id) return;
                      contactSNS({
                        mode: 'LINE',
                        name: customer.name,
                        customerId: info?.id,
                        id: customer.line_id,
                        callbackPostEnd: () => callbackSNSEnd?.(),
                      });
                      callbackSNS?.();
                    }}
                    disabled={
                      !customer?.line_id || (user.view_data && user.view_data.company_id !== 1)
                    }
                  />
                </CopyToClipboard>
              </>
            </ItemWrap>
            <ItemWrap title="Facebook ID">
              <>
                <Input
                  value={customer?.facebook_id || ''}
                  disabled={!editAuth}
                  onChange={(e) => setState({ facebook_id: e.target.value })}
                  validationList={ValidationFacebookId}
                  className="full_width"
                />
                <IconButton
                  fontAwesomeClass="fas fa-external-link-alt"
                  className="sns"
                  size="sm"
                  color="white"
                  onClick={() => {
                    if (!customer.facebook_id) return;
                    contactSNS({
                      mode: 'Facebook',
                      name: customer.name,
                      customerId: info?.id,
                      id: customer.facebook_id,
                      callbackPostEnd: () => callbackSNSEnd?.(),
                    });
                    callbackSNS?.();
                  }}
                  disabled={
                    !customer?.facebook_id || (user.view_data && user.view_data.company_id !== 1)
                  }
                />
              </>
            </ItemWrap>
            <ItemWrap title="Twitter ID">
              <>
                <Input
                  value={customer?.twitter_id || ''}
                  disabled={!editAuth}
                  onChange={(e) => setState({ twitter_id: e.target.value })}
                  validationList={ValidationTwitterId}
                  className="full_width"
                />
                <IconButton
                  fontAwesomeClass="fas fa-external-link-alt"
                  className="sns"
                  size="sm"
                  color="white"
                  onClick={() => {
                    if (!customer.twitter_id) return;
                    contactSNS({
                      mode: 'Twitter',
                      name: customer.name,
                      customerId: info?.id,
                      id: customer.twitter_id,
                      callbackPostEnd: () => callbackSNSEnd?.(),
                    });
                    callbackSNS?.();
                  }}
                  disabled={
                    !customer?.twitter_id || (user.view_data && user.view_data.company_id !== 1)
                  }
                />
              </>
            </ItemWrap>
            <ItemWrap title="Instagram ID">
              <>
                <Input
                  value={customer?.instagram_id || ''}
                  disabled={!editAuth}
                  onChange={(e) => setState({ instagram_id: e.target.value })}
                  validationList={ValidationInstagramId}
                  className="full_width"
                />
                <IconButton
                  fontAwesomeClass="fas fa-external-link-alt"
                  className="sns"
                  size="sm"
                  color="white"
                  onClick={() => {
                    if (!customer.instagram_id) return;
                    contactSNS({
                      mode: 'Instagram',
                      name: customer.name,
                      customerId: info?.id,
                      id: customer.instagram_id,
                      callbackPostEnd: () => callbackSNSEnd?.(),
                    });
                    callbackSNS?.();
                  }}
                  disabled={
                    !customer?.instagram_id || (user.view_data && user.view_data.company_id !== 1)
                  }
                />
              </>
            </ItemWrap>
            {id
            && (
            <>
              <div className="item_wrap">
                <div className="item_box">
                  <div className="item_head">登録日</div>
                  <div className="item_body">
                    <Input
                      value={DF.date2str(info?.created_at, 'YYYYMMDD_HHmm', '/')}
                      disabled
                      onChange={() => {}}
                      className=""
                    />
                  </div>
                </div>
                <div className="item_box">
                  <div className="item_head">登録者</div>
                  <div className="item_body">
                    <Input
                      value={info?.created_employee_name}
                      disabled
                      onChange={() => {}}
                      className=""
                    />
                  </div>
                </div>
              </div>
              <div className="item_wrap">
                <div className="item_box">
                  <div className="item_head">更新日</div>
                  <div className="item_body">
                    <Input
                      value={DF.date2str(info?.updated_at, 'YYYYMMDD_HHmm', '/')}
                      disabled
                      onChange={() => {}}
                      className=""
                    />
                  </div>
                </div>
                <div className="item_box">
                  <div className="item_head">最終更新者</div>
                  <div className="item_body">
                    <Input
                      value={info?.updated_employee_name}
                      disabled
                      onChange={() => {}}
                      className=""
                    />
                  </div>
                </div>
              </div>
            </>
            )}
          </div>
          <div className="right_box">
            <div className="item_wrap">
              <div className="item_box">
                <div className="item_head">建物分類</div>
                <Select
                  value={customer?.building_category_id || NaN}
                  disabled={!editAuth}
                  defaultLabel="指定無し"
                  options={pulldown(buildingCategoryList)}
                  onChange={(v) => setState({ building_category_id: Number(v) })}
                />
              </div>
              <div className="item_box">
                <div className="item_head">間取り</div>
                <Select
                  value={customer?.madori_id || NaN}
                  disabled={!editAuth}
                  defaultLabel="指定無し"
                  options={pulldown(madoriList)}
                  onChange={(v) => setState({ madori_id: Number(v) })}
                />
                <div className="dummy" />
              </div>
              <div className="item_box">
                <div className="item_head">築年月</div>
                <DatePicker
                  date={customer.construction_info}
                  onChange={(v) => setState({ construction_info: v })}
                  disabled={!editAuth}
                  type="YYYY/MM"
                />
                <Tikunengetsu date={customer.construction_info} />
              </div>
            </div>
            <ItemWrap title="備考" classNameBox="max_width">
              <TextArea
                className="large"
                rows={10}
                value={customer?.remarks}
                disabled={!editAuth}
                onChange={(e) => setState({ remarks: e.target.value })}
                validationList={ValidationLengthUnder500}
              />
            </ItemWrap>
            <ItemWrap title="社内メモ1" classNameBox="max_width">
              <TextArea
                className="large"
                rows={5}
                value={customer?.memo1}
                disabled={!editAuth}
                onChange={(e) => setState({ memo1: e.target.value })}
                validationList={ValidationLengthUnder255}
              />
            </ItemWrap>
            <ItemWrap title="社内メモ2" classNameBox="max_width">
              <TextArea
                className="large"
                rows={5}
                value={customer?.memo2}
                disabled={!editAuth}
                onChange={(e) => setState({ memo2: e.target.value })}
                validationList={ValidationLengthUnder255}
              />
            </ItemWrap>
            <div className="item_wrap tags_form">
              <div className="item_box">
                <div className="item_head">マイカー種別</div>
                <div className="flex_wrap_box">
                  {customer?.my_car_type?.data.map((v, i) => (
                    v.id !== 100
                      ? (
                        <div className="same_width_checkbox" key={`mTag${i}`}>
                          <RightLabelCheckbox
                            checked={v.flag}
                            label={v.label}
                            disabled={!editAuth}
                            onChange={() => {
                              customer.my_car_type?.changeFlag(v.id);
                              setState({ my_car_type: customer.my_car_type });
                            }}
                          />
                        </div>
                      )
                      : (
                        <div className="single_column" key={`myCarOther${i}`}>
                          <RightLabelCheckbox
                            label={v.label}
                            checked={v.flag}
                            onChange={() => {
                              customer.my_car_type?.changeFlag(v.id);
                              setState({ my_car_type: customer.my_car_type });
                            }}
                          />
                          <span>（&nbsp;</span>
                          <Input
                            className="other"
                            value={customer?.my_car_type_other}
                            type="text"
                            disabled={!editAuth || !v.flag}
                            onChange={(e) => v.flag && setState(
                              { my_car_type_other: e.target.value },
                            )}
                            validationList={ValidationLengthUnder20}
                          /><span>&nbsp;）</span>
                        </div>
                      )
                  ))}
                </div>
              </div>
            </div>
            <div className="item_wrap tags_form">
              {/* <TagCheckBoxex
                head="関連タグ"
                keys="rTag"
                model={customer?.tag_list}
                                  disabled={!editAuth}
                onChange={(v) => setState({ tag_list: v })}
              /> */}
              <div className="item_box">
                <div className="item_head">関連タグ</div>
                <div className="flex_wrap_box">
                  {customer?.tag_list?.data.map((v, i) => (
                    v.id !== 100
                      ? (
                        <div className="same_width_checkbox" key={`mTag${i}`}>
                          <RightLabelCheckbox
                            checked={v.flag}
                            label={v.label}
                            disabled={!editAuth}
                            onChange={() => {
                              customer.tag_list?.changeFlag(v.id);
                              setState({ tag_list: customer.tag_list });
                            }}
                          />
                        </div>
                      )
                      : (
                        <div className="single_column" key={`myCarOther${i}`}>
                          <RightLabelCheckbox
                            label={v.label}
                            checked={v.flag}
                            onChange={() => {
                              customer.tag_list?.changeFlag(v.id);
                              setState({ tag_list: customer.tag_list });
                            }}
                          />
                          <span>（&nbsp;</span>
                          <Input
                            className="other"
                            value={customer?.tag_list_other}
                            type="text"
                            disabled={!editAuth || !v.flag}
                            onChange={(e) => v.flag && setState(
                              { tag_list_other: e.target.value },
                            )}
                            validationList={ValidationLengthUnder20}
                          /><span>&nbsp;）</span>
                        </div>
                      )
                  ))}
                </div>
              </div>
            </div>
            <ItemWrap title="紹介者">
              <Input
                value={customer?.introducer}
                disabled={!editAuth}
                onChange={(e) => setState({ introducer: e.target.value })}
                validationList={ValidationLengthUnder60}
                className="full_width"
              />
            </ItemWrap>
            <ItemWrap title="結婚記念日">
              <div className="wedding_anniversary">
                <DatePicker
                  date={customer.wedding_anniversary || null}
                  errorPosBottom
                  disabled={!editAuth}
                  onChange={(v) => setState({ wedding_anniversary: v })}
                  validationList={ValidationDatePicker}
                />
              </div>
            </ItemWrap>
            <ItemWrap title="ご家族情報" classNameBox="max_width">
              <div className="segment_wrap">
                <Segment>
                  <FamilyInfoList
                    key={`fam${famEditCount}`}
                    list={familyListModel.list}
                    callback={(v) => setFamilyId(v)}
                    callbackSort={familySort}
                  />
                </Segment>
                <div className="btn_box">
                  <Button
                    size="md"
                    color="secondary"
                    disabled={!editAuth}
                    onClick={() => editFamily()}
                  >登録
                  </Button>
                  <Button
                    size="md"
                    color="secondary"
                    disabled={!editAuth || (!familyId && familyId !== 0)}
                    onClick={() => editFamily('edit')}
                  >編集
                  </Button>
                  <Button
                    size="md"
                    color="dark"
                    disabled={!editAuth || (!familyId && familyId !== 0)}
                    onClick={removeFamily}
                  >削除
                  </Button>
                </div>
              </div>
            </ItemWrap>
            <ItemWrap title="ペット情報" classNameBox="max_width">
              <div className="segment_wrap">
                <Segment>
                  <PetInfoList
                    key={`pet${petEditCount}`}
                    list={petListModel?.list || []}
                    callback={(v) => setPetId(v)}
                    callbackSort={petSort}
                  />
                </Segment>
                <div className="btn_box">
                  <Button
                    size="md"
                    color="secondary"
                    disabled={!editAuth}
                    onClick={() => editPet()}
                  >登録
                  </Button>
                  <Button
                    size="md"
                    color="secondary"
                    disabled={!editAuth || (!petId && petId !== 0)}
                    onClick={() => editPet('edit')}
                  >編集
                  </Button>
                  <Button
                    size="md"
                    color="dark"
                    disabled={!editAuth || (!petId && petId !== 0)}
                    onClick={removePet}
                  >削除
                  </Button>
                </div>
              </div>
            </ItemWrap>
          </div>
        </div>
      </EditPC>
    )
  );
};
