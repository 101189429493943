import {
  ValidationLengthUnder255, ValidationPostNum1, ValidationPostNum2, ValidationTel,
} from '..';
import { ValidationMailAddress } from '../validation-mail-address';
import { ValidationPassword } from '../validation-password';
import { ValidationLengthUnder3 } from '../_validation-length-under-3';

export const MasterContractedCompanyValidation = (
  name: string,
  mailAddress: string,
  password: string,
  confirmPassword: string,
  telNo: string,
  postalCode1: string,
  postalCode2: string,
  city: string,
  address: string,
  buildingName: string,
  accounts: string,
  capacity: string,
) => {
  window.console.log();
  return (
  // 必須
    !name
  || !mailAddress

  // バリデーション
  || ValidationLengthUnder255.filter((v) => !v.run(String(name || ''))).length
  || ValidationMailAddress.filter((v) => !v.run(String(mailAddress || ''))).length
  || (password && ValidationPassword.filter((v) => !v.run(String(password || ''))).length)
  || (confirmPassword && ValidationPassword.filter((v) => !v.run(String(confirmPassword || ''))).length)
  || ValidationTel.filter((v) => !v.run(String(telNo || ''))).length
  || ValidationPostNum1.filter((v) => !v.run(String(postalCode1 || ''))).length
  || ValidationPostNum2.filter((v) => !v.run(String(postalCode2 || ''))).length
  || ValidationLengthUnder255.filter((v) => !v.run(String(city || ''))).length
  || ValidationLengthUnder255.filter((v) => !v.run(String(address || ''))).length
  || ValidationLengthUnder255.filter((v) => !v.run(String(buildingName || ''))).length
  || ValidationLengthUnder3.filter((v) => !v.run(String(accounts || ''))).length
  || ValidationLengthUnder3.filter((v) => !v.run(String(capacity || ''))).length
  );
};
