/* eslint-disable no-nested-ternary */
import { ApiMaintenanceGetListParam, ApiMaintenancePostParam } from '../../redux/maintenance/api/maintenance/api-maintenance.type';
import { MaintenanceSortState, MaintenanceEditState } from '../../type/maintenance/maintenance.type';
import { MapAreaPosition } from '../../type/map/map.type';
import { DateFormatter } from '../../utilities/date-formatter';

const df = (v: Date | null | undefined) => DateFormatter.date2str(v, 'YYYYMMDD', '-') || undefined;

export class MaintenanceModel {
  /**
   * 案件詳細內一覧取得
   * @static
   */
  static listParmInProject(param:{
    sortData: MaintenanceSortState;
    project_id: number;
  }):ApiMaintenanceGetListParam {
    const { sortData, project_id } = param;

    let sort_by = 0;
    if (sortData.sort_by === 0)sort_by = 1;
    if (sortData.sort_by === 1)sort_by = 0;
    if (sortData.sort_by === 2)sort_by = 2;
    if (sortData.sort_by === 3)sort_by = 3;
    if (sortData.sort_by === 4)sort_by = 4;
    if (sortData.sort_by === 5) sort_by = 5;

    return ({
      project_id,
      maintenance_date: df(sortData.maintenance_date),
      supported_date: df(sortData.supported_date),
      title: sortData.title,
      supported_kubun: !sortData.supported_kubun
        ? 2
        : sortData.supported_kubun === 1
          ? 0
          : 1,
      sort_by: sortData.sort_by,
      highlow: sortData.highlow,
      offset: sortData.offset,
      limit: 9999,
    });
  }

  /**
   * 顧客詳細內一覧取得
   * @static
   */
  static listParmInCustomer(param:{
    sortData: MaintenanceSortState;
    customer_id: number;
  }):ApiMaintenanceGetListParam {
    const { sortData, customer_id } = param;

    let sort_by = 2;
    if (sortData.sort_by === 0)sort_by = 1;
    if (sortData.sort_by === 1)sort_by = 2;
    if (sortData.sort_by === 2)sort_by = 2;
    if (sortData.sort_by === 3)sort_by = 8;
    if (sortData.sort_by === 4)sort_by = 10;
    if (sortData.sort_by === 5)sort_by = 6;
    if (sortData.sort_by === 6)sort_by = 9;
    if (sortData.sort_by === 7)sort_by = 3;
    if (sortData.sort_by === 8)sort_by = 4;
    if (sortData.sort_by === 9)sort_by = 5;
    return ({
      customer_id,
      project_name: sortData.project_name,
      construction_date: df(sortData.construction_date),
      completion_date: df(sortData.completion_date),
      project_store_id: sortData.project_store_id,
      project_employee_id: sortData.project_employee_id,
      maintenance_date: df(sortData.maintenance_date),
      supported_date: df(sortData.supported_date),
      supported_kubun: !sortData.supported_kubun
        ? 2
        : sortData.supported_kubun === 1
          ? 1
          : 0,
      sort_by: sortData.sort_by,
      title: sortData.title,
      highlow: sortData.highlow,
      offset: sortData.offset,
      limit: 9999,
    });
  }

  /**
   * 一覧取得
   * @static
   */
  static listParm(param:{
    sortData: MaintenanceSortState;
    showType?: 'map' | 'list' | 'date';
    mapAreaPos?: MapAreaPosition | null;
    searchBox?: boolean;
    selectDay?: Date;
    isMapList?: boolean;
  }):ApiMaintenanceGetListParam {
    const {
      sortData, showType, mapAreaPos, searchBox, selectDay, isMapList,
    } = param;
    return ({
      ...sortData,
      sort_by: sortData.sort_by + 1,
      maintenance_date: showType === 'date' ? df(selectDay) : df(sortData?.maintenance_date),
      maintenance_date_start: df(sortData?.maintenance_date_start),
      maintenance_date_end: df(sortData?.maintenance_date_end),
      completion_date_start: df(sortData?.completion_date_start),
      completion_date_end: df(sortData?.completion_date_end),
      construction_date: df(sortData?.construction_date),
      completion_date: df(sortData?.completion_date),
      supported_date: df(sortData?.supported_date),
      north_lat: showType === 'map' && !isMapList && mapAreaPos?.n.lat ? String(mapAreaPos?.n.lat) : undefined,
      north_lng: showType === 'map' && !isMapList && mapAreaPos?.n.lng ? String(mapAreaPos?.n.lng) : undefined,
      south_lat: showType === 'map' && !isMapList && mapAreaPos?.s.lat ? String(mapAreaPos?.s.lat) : undefined,
      south_lng: showType === 'map' && !isMapList && mapAreaPos?.s.lng ? String(mapAreaPos?.s.lng) : undefined,
      limit: showType === 'list' ? sortData.limit : 9999,
      created_at_start: DateFormatter.date2str(sortData.created_at_start, 'YYYYMMDD', '-'),
      created_at_end: DateFormatter.date2str(sortData.created_at_end, 'YYYYMMDD', '-'),
      updated_at_start: DateFormatter.date2str(sortData.updated_at_start, 'YYYYMMDD', '-'),
      updated_at_end: DateFormatter.date2str(sortData.updated_at_end, 'YYYYMMDD', '-'),
    });
  }

  /**
   * 保存
   * @static
   */
  static postParam(param:{
    maintenance: MaintenanceEditState;
  }):ApiMaintenancePostParam['data'] {
    const { maintenance } = param;
    return ({
      customer_id: maintenance.customer_id,
      project_id: maintenance.project_id,
      title: maintenance.title,
      detail: maintenance.detail,
      supported_content: maintenance.supported_content,
      maintenance_date: df(maintenance.maintenance_date),
      supported_date: df(maintenance.supported_date),
      supported_kubun: maintenance.supported_kubun ? 1 : 0,
      is_muko: maintenance.is_muko ? 1 : 0,
    });
  }
}
