/* eslint-disable no-nested-ternary */
import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual, cloneDeep } from 'lodash';
import { EditPC } from '../../../../../dialogs/edit/edit.pc';
import { Select } from '../../../../../ui/select/select';
import { Required } from '../../../../../ui/required/required';
import { useDidMount } from '../../../../../../hooks/life-cycle';
import { Input } from '../../../../../ui/input/input';
import { MasterActions } from '../../../../../../redux/master/master.action';
import { ValidationMax100Million } from '../../../../../../model/validation/validation-max-100-million';
import { ValidationMax10Billion } from '../../../../../../model/validation/validation-max-10-billion';
import { CommonCollection } from '../../../../../../collection/common/common.collection';
import { State } from '../../../../../../redux/root.reducer';
import { AddMeisaiData, MeisaiListXML } from '../../../../../../model/estimate/estimate-meisai.type';
import { cancelFigureSeparate } from '../../../../../../utilities/format-num';
import { pulldown } from '../../../../../../utilities/pulldown';
import { EstimateMeisaiValidation } from '../../../../../../model/validation/estimate/estimate-meisai.validation';
import { DialogActions } from '../../../../../../redux/dialog/dialog.action';
import { InputNum } from '../../../../../ui/input/input-num';
import { MathHelper } from '../../../../../../utilities/math-helper';
import { EstimateMeisaiModel } from '../../../../../../model/estimate/estimate-meisai.model';
import { Message } from '../../../../../../collection/message.collection';
import { MasterMiddleCategory } from '../../../../../../type/master/master-middle-category.type';
import { ValidationLengthUnder255 } from '../../../../../../model/validation';
import { Checkbox } from '../../../../../ui/checkbox/checkbox';
import { ValidationLengthUnder500 } from '../../../../../../model/validation/validation-length-under';
import './multi-edit-meisai-dialog.pc.scss';

type Props = {
  callback: (v:Partial<AddMeisaiData>) => void;
}

/* FIXME
  shohin_cdによって
  資材工事名がSelectになり
  自動入力になる
*/
type EditType = keyof AddMeisaiData;

export const MultiEditMeisaiDialogPC = (props: Props) => {
  const { callback } = props;
  const dispatch = useDispatch();
  const daibunruiList = useSelector((state:State) => state.master.largeCategoryList, isEqual);
  const middleCategoryList = useSelector((state:State) => state.master.middleCategoryList, isEqual);
  const meisaiList = useSelector((state:State) => state.master.meisaiList, isEqual);
  const taniList = useSelector((state:State) => state.master.unitList, isEqual);

  /* State */
  const [shohinCd, setShohinCd] = useState('');
  const [kubun, setKubun] = useState('');
  const [daibunrui, setDaibunrui] = useState('');
  const [tyubunrui, setTyubunrui] = useState('');
  const [name, setName] = useState('');
  const [kikaku, setKikaku] = useState('');
  const [suryo, setSuryo] = useState('0');
  const [tani, setTani] = useState('0');
  const [shikirikakaku, setShikiriKakaku] = useState('0');
  const [genka, setGenka] = useState('0');
  const [remark, setRemark] = useState('');
  const [printName, setPrintName] = useState('');
  const [touch, setTouch] = useState(false);
  const [_tyubunruiList, set_tyubunruiList] = useState<MasterMiddleCategory[]>([]);
  const [checkList, setCheckList] = useState<EditType[]>([]);

  /* Callback */
  const setCheck = useCallback((v: EditType) => {
    if (checkList.includes(v)) {
      setCheckList(cloneDeep(
        checkList.filter((v2) => v !== v2),
      ));
    } else {
      setCheckList(cloneDeep(
        checkList.concat([v]),
      ));
      if (v === 'print_name' && checkList.includes('name') && name !== '') {
        setPrintName(name);
      }
    }
  }, [checkList, name]);

  const viewPlaceholder = (key: EditType, value: string): string => (
    checkList.includes(key) ? value : '変更なし'
  );

  const viewValue = (key: EditType, value: string): string => (
    checkList.includes(key) ? value : ''
  );

  const handleClickSave = useCallback(() => {
    const daibunrui_name = daibunruiList.find((v) => String(v.id) === daibunrui)?.name || '';
    const tyubunrui_name = middleCategoryList.find((v) => String(v.id) === tyubunrui)?.name || '';
    const tani_name = taniList.find((v) => String(v.id) === tani)?.name || '';
    const shohin_kubun_name = CommonCollection.shohinKubunList.find((v) => String(v.value) === kubun)?.text || '';
    const sendData: Partial<AddMeisaiData> = {
      shohin_cd: '',
      arari_ritu: '0',
      shiiresaki_name: '',
    };
    if (checkList.includes('daibunrui_id') && daibunrui !== '' && tyubunrui === '') {
      dispatch(DialogActions.pushMessage({
        title: 'お知らせ',
        message: Message.postError,
      }));
      return;
    }

    checkList.forEach((v) => {
      if (v === 'daibunrui_id' && daibunrui !== '') {
        sendData.daibunrui_id = daibunrui;
        sendData.daibunrui_name = daibunrui_name;
        sendData.daibunrui_name_out = daibunrui_name;
        sendData.tyubunrui_id = tyubunrui;
        sendData.tyubunrui_name = tyubunrui_name;
        sendData.tyubunrui_name_out = tyubunrui_name;
      }
      if (v === 'shohin_kubun' && kubun !== '') {
        sendData.shohin_kubun = kubun;
        sendData.shohin_kubun_name = shohin_kubun_name;
      }
      if (v === 'name' && name !== '') sendData.name = name;
      if (v === 'kikaku') sendData.kikaku = kikaku;
      if (v === 'suryou') sendData.suryou = cancelFigureSeparate(suryo);
      if (v === 'shikiri_kakaku') {
        sendData.shikiri_kakaku = cancelFigureSeparate(shikirikakaku);
        sendData.kingaku = cancelFigureSeparate(shikirikakaku);
      }
      if (v === 'tani_id' && !!Number(tani)) {
        sendData.tani_id = tani;
        sendData.tani_name = tani_name;
      }
      if (v === 'genka') sendData.genka = cancelFigureSeparate(genka);
      if (v === 'meisai_remark') sendData.meisai_remark = remark;
      if (v === 'print_name') sendData.print_name = printName;
    });

    dispatch(DialogActions.pushMessage({
      title: '見積明細一括更新確認',
      message: ['一括更新を行います。', 'よろしいですか？'],
      callback: () => {
        callback(cloneDeep(sendData));
        dispatch(DialogActions.pop());
      },
      isCancel: true,
      cancelRight: true,
    }));
  }, [
    shohinCd,
    kubun,
    daibunrui,
    tyubunrui,
    name,
    kikaku,
    suryo,
    tani,
    remark,
    printName,
    shikirikakaku,
    genka,
    taniList,
    daibunruiList,
    middleCategoryList,
    checkList,
    callback,
  ]);

  const changeDaibunrui = useCallback((val: string | number) => {
    const v = String(val);
    if (v === daibunrui) return;
    if (!v) {
      setDaibunrui('');
      setTyubunrui('');
      setShohinCd('');
      set_tyubunruiList(cloneDeep(middleCategoryList));
      return;
    }
    setDaibunrui(String(v));
    setTyubunrui('');
    set_tyubunruiList(middleCategoryList.filter((t) => (String(t.category_id) === v)));
  }, [daibunrui, middleCategoryList]);

  const changeTyubunrui = useCallback((val: string | number) => {
    const v = String(val);
    if (v === tyubunrui) return;
    if (!v) {
      setTyubunrui('');
      setShohinCd('');
      return;
    }
    setTyubunrui(String(v));
    setShohinCd('');
  }, [tyubunrui]);

  /* Master */
  useDidMount(() => {
    dispatch(MasterActions.api.largeCategory.getList({}));
    dispatch(MasterActions.api.middleCategory.getList({}));
    dispatch(MasterActions.api.unit.getList({}));
    dispatch(MasterActions.api.meisai.getList({}));
  });

  useEffect(() => {
    if (middleCategoryList.length) {
      set_tyubunruiList(middleCategoryList.filter((
        v,
      ) => (!daibunrui ? true
        : String(v.category_id) === daibunrui
      )));
    }
  }, [middleCategoryList]);

  return (
    <EditPC
      mode="dialog"
      label="一括更新"
      callback={handleClickSave}
    >
      <div className="EditMeisaiDialogPC multi_edit_meisai_dialog">
        <div className="item_wrap">
          <div className="item_box">
            <div className="checkbox_wrap">
              <Checkbox
                checked={checkList.includes('shohin_kubun')}
                onChange={() => setCheck('shohin_kubun')}
              />
            </div>
            <div className="item_head">商品区分</div>
            <Select
              value={kubun}
              onChange={(v) => setKubun(String(v))}
              defaultLabel="変更なし"
              disabled={!checkList.includes('shohin_kubun')}
              options={CommonCollection.shohinKubunList}
            />
          </div>
        </div>
        <div className="item_wrap">
          <div className="item_box">
            <div className="checkbox_wrap">
              <Checkbox
                checked={checkList.includes('daibunrui_id')}
                onChange={() => setCheck('daibunrui_id')}
              />
            </div>
            <div className="item_head">大分類<Required /></div>
            <Select
              value={checkList.includes('daibunrui_id') ? daibunrui : undefined}
              disabled={!checkList.includes('daibunrui_id')}
              defaultLabel="変更なし"
              onChange={changeDaibunrui}
              options={pulldown(daibunruiList)}
              touch={touch}
            />
          </div>
        </div>
        <div className="item_wrap set_ml">
          <div className="item_box">
            <div className="checkbox_wrap" />
            <div className="item_head">中分類<Required /></div>
            <Select
              value={checkList.includes('daibunrui_id') ? tyubunrui : undefined}
              onChange={changeTyubunrui}
              disabled={!checkList.includes('daibunrui_id')
                || (checkList.includes('daibunrui_id') && daibunrui === '')}
              require={checkList.includes('daibunrui_id') && daibunrui !== ''}
              changeRequire
              defaultLabel="変更なし"
              options={pulldown(_tyubunruiList)}
              touch={touch}
            />
          </div>
        </div>
        <div className="item_wrap">
          <div className="item_box">
            <div className="checkbox_wrap">
              <Checkbox
                checked={checkList.includes('name')}
                onChange={() => setCheck('name')}
              />
            </div>
            <div className="item_head">工事・資材名<Required /></div>
            <Input
              value={viewValue('name', name)}
              placeholder="変更なし"
              disabled={!checkList.includes('name')}
              onChange={(e) => setName(e.target.value)}
              validationList={ValidationLengthUnder255}
              touch={touch}
            />
          </div>
        </div>
        <div className="item_wrap">
          <div className="item_box">
            <div className="checkbox_wrap">
              <Checkbox
                checked={checkList.includes('print_name')}
                onChange={() => setCheck('print_name')}
              />
            </div>
            <div className="item_head">印刷名称</div>
            <Input
              value={viewValue('print_name', printName)}
              placeholder={viewPlaceholder('print_name', printName)}
              disabled={!checkList.includes('print_name')}
              onChange={(e) => setPrintName(e.target.value)}
              touch={touch}
            />
          </div>
        </div>
        <div className="item_wrap">
          <div className="item_box">
            <div className="checkbox_wrap">
              <Checkbox
                checked={checkList.includes('kikaku')}
                onChange={() => setCheck('kikaku')}
              />
            </div>
            <div className="item_head">規格</div>
            <Input
              value={viewValue('kikaku', kikaku)}
              onChange={(e) => setKikaku(e.target.value)}
              placeholder={viewPlaceholder('kikaku', kikaku)}
              disabled={!checkList.includes('kikaku')}
              validationList={ValidationLengthUnder255}
            />
          </div>
        </div>
        <div className="item_wrap">
          <div className="item_box">
            <div className="checkbox_wrap">
              <Checkbox
                checked={checkList.includes('suryou')}
                onChange={() => setCheck('suryou')}
              />
            </div>
            <div className="item_head">数量</div>
            <InputNum
              value={viewValue('suryou', suryo)}
              placeholder={viewPlaceholder('suryou', suryo)}
              alignRight={checkList.includes('suryou')}
              disabled={!checkList.includes('suryou')}
              onBlur={(v) => setSuryo(EstimateMeisaiModel.setSuryo(v))}
              onChange={(v) => setSuryo(String(v))}
              intLen={8}
              decimalLen={2}
              validationList={ValidationMax100Million}
              minus
            />
          </div>
        </div>
        <div className="item_wrap">
          <div className="item_box">
            <div className="checkbox_wrap">
              <Checkbox
                checked={checkList.includes('tani_id')}
                onChange={() => setCheck('tani_id')}
              />
            </div>
            <div className="item_head">単位<Required /></div>
            <Select
              value={tani}
              onChange={(v) => setTani(String(v))}
              disabled={!checkList.includes('tani_id')}
              defaultLabel="変更なし"
              options={pulldown(taniList)}
              touch={touch}
            />
          </div>
        </div>
        <div className="item_wrap">
          <div className="item_box">
            <div className="checkbox_wrap">
              <Checkbox
                checked={checkList.includes('shikiri_kakaku')}
                onChange={() => setCheck('shikiri_kakaku')}
              />
            </div>
            <div className="item_head">見積単価</div>
            <InputNum
              value={viewValue('shikiri_kakaku', shikirikakaku)}
              placeholder={viewPlaceholder('shikiri_kakaku', shikirikakaku)}
              alignRight={checkList.includes('shikiri_kakaku')}
              disabled={!checkList.includes('shikiri_kakaku')}
              onBlur={(v) => setShikiriKakaku(
                EstimateMeisaiModel.setNumber(
                  v,
                  (val) => MathHelper.rounding2Str(val, 0, 'round', true),
                ),
              )}
              onChange={(v) => setShikiriKakaku(String(v))}
              intLen={10}
              validationList={ValidationMax10Billion}
              minus
            />
          </div>
        </div>
        <div className="item_wrap">
          <div className="item_box">
            <div className="checkbox_wrap">
              <Checkbox
                checked={checkList.includes('genka')}
                onChange={() => setCheck('genka')}
              />
            </div>
            <div className="item_head">原価単価</div>
            <InputNum
              value={viewValue('genka', genka)}
              placeholder={viewPlaceholder('genka', genka)}
              alignRight={checkList.includes('genka')}
              disabled={!checkList.includes('genka')}
              onBlur={(v) => setGenka(
                EstimateMeisaiModel.setNumber(
                  v,
                  (val) => MathHelper.rounding2Str(val, 0, 'round', true),
                ),
              )}
              onChange={(v) => setGenka(String(v))}
              intLen={10}
              validationList={ValidationMax10Billion}
              minus
            />
          </div>
        </div>
        <div className="item_wrap">
          <div className="item_box">
            <div className="checkbox_wrap">
              <Checkbox
                checked={checkList.includes('meisai_remark')}
                onChange={() => setCheck('meisai_remark')}
              />
            </div>
            <div className="item_head">備考</div>
            <Input
              value={viewValue('meisai_remark', remark)}
              onChange={(e) => setRemark(e.target.value)}
              placeholder={viewPlaceholder('meisai_remark', remark)}
              disabled={!checkList.includes('meisai_remark')}
              validationList={ValidationLengthUnder500}
            />
          </div>
        </div>
      </div>
    </EditPC>
  );
};
