import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import './file-detail.sp.scss';
import { useDispatch, useSelector } from 'react-redux';
import { push, goBack } from 'connected-react-router';
import { useParams } from 'react-router-dom';
import { cloneDeep } from 'lodash';
import { BasePageSP } from '../base.page.sp';
import { DialogActions } from '../../../../redux/dialog/dialog.action';
import { Button } from '../../../ui/button/button';
import { RoutingPath } from '../../../../routes/routing-pass';
import { State } from '../../../../redux/root.reducer';
import { FileActions } from '../../../../redux/file/file.action';
import { FileEditDialogTitle } from '../file/edit/file-edit.type';
import { FileEditSP } from '../file/edit/file-edit.sp';
import { LeftIconButton } from '../../../ui/button/left-icon-button/left-icon-button';
import { DateFormatter } from '../../../../utilities/date-formatter';
import { FileSearchBoxSP } from '../file/search-box/file-search-box.sp';
import { LoadingFileImg } from '../../../ui/file-img/loading-file-img';
import { FileCollection } from '../../../../collection/file/file.collection';
import { MathHelper as mh } from '../../../../utilities/math-helper';
import { FileType } from '../../../../type/file/file.type';
import { Customer, CustomerListType } from '../../../../type/customer/customer.type';
import { Project, ProjectListType } from '../../../../type/project/project.type';

type Props = {
  id?: number;
  isOrder?: boolean;
  isDialog?: boolean;
  onBack?: () => void;
}

export const FileDetailSP = (props: Props) => {
  /* Hooks */
  const {
    id: outId, isOrder, isDialog, onBack,
  } = props;
  const { id: _id } = useParams<{ id: string; }>();
  // const _fileInfo = useSelector((state: State) => state.file.file);
  const dispatch = useDispatch();
  const [fileInfo, setFileInfo] = useState<FileType | null>(null);

  const id = useMemo(() => {
    if (isOrder) return outId!;
    return _id;
  }, [outId, isOrder, _id]);

  /* Callback */
  const getData = useCallback(() => {
    dispatch(FileActions.api.file.get({
      param: { id: Number(id) },
    }));
  }, [id]);

  const fileSize = useMemo(() => {
    if (!fileInfo) return 0;
    if (Number.isNaN(fileInfo.size)) return 0;
    return mh.rounding(
      mh.div(fileInfo.size, 1024), 2, 'ceil',
    );
  }, [fileInfo]);

  const handleClickEdit = useCallback(() => {
    dispatch(DialogActions.push({
      title: FileEditDialogTitle.update,
      element: <FileEditSP
        id={Number(id)}
        callback={getData}
        isOrder={isOrder}
      />,
    }));
  }, [id, isOrder]);

  const handleClickDelete = useCallback(() => {
    if (!fileInfo) return;
    dispatch(DialogActions.pushMessage({
      title: 'ファイル削除',
      message: ['削除しますか'],
      isCancel: true,
      callback: () => {
        dispatch(FileActions.api.file.delete({
          param: { id: fileInfo.id },
          callback: () => dispatch(goBack()),
        }));
      },
    }));
  }, [fileInfo]);

  const handleClickDownload = useCallback(() => {
    if (!fileInfo) return;
    dispatch(FileActions.api.file.download({
      param: { file_id: fileInfo.id },
      fileName: fileInfo.file_name || '',
    }));
  }, [fileInfo]);

  /* Effect */
  useEffect(() => {
    dispatch(FileActions.api.file.get({
      param: { id: Number(id) },
      callback: (v) => setFileInfo(cloneDeep(v)),
    }));
  }, [id]);

  return (
    <>
      {!isDialog ? (
        <BasePageSP
          className="file_detail_sp"
          searchBoxDialog={{
            title: '詳細検索',
            element: <FileSearchBoxSP callback={() => {
              dispatch(DialogActions.pop());
              dispatch(push(RoutingPath.file));
            }}
            />,
          }}
          searchCallback={(v) => {
            dispatch(FileActions.setSort({ word: v }));
            dispatch(push(RoutingPath.file));
          }}
        >
          <div className="detail_wrap">
            <div className="detail_header">
              <div
                className="detail_header_inner"
                onClick={() => {
                  if (onBack) {
                    onBack();
                  } else {
                    dispatch(goBack());
                  }
                }}
              >
                <div
                  className="detail_header_inner__back_btn"
                >
                  <i className="fas fa-chevron-circle-left" />
                </div>
                ファイル詳細
              </div>
              <div className="detail_header_buttons">
                <LeftIconButton
                  color="secondary"
                  size="sm"
                  onClick={handleClickEdit}
                  fontAwesomeClass="fa fa-edit"
                  label="ファイル編集"
                />
              </div>
            </div>

            <div className="detail_body file_detail_sp__body">
              <div className="row_table_style file_detail_sp__body__table">
                <div className="col_2">
                  <div>
                    <div className="t_row">
                      <div className="t_header">ファイルID</div>
                      <div className="t_body">{fileInfo?.internal_id}</div>
                    </div>
                    <div className="t_row">
                      <div className="t_header">ファイル名</div>
                      <div className="t_body">{fileInfo?.file_name}</div>
                    </div>
                    <div className="t_row">
                      <div className="t_header">ﾌｧｲﾙｻｲｽﾞ</div>
                      <div className="t_body">{fileSize}KB</div>
                    </div>
                    <div className="t_row">
                      <div className="t_header">顧客名</div>
                      <div className="t_body">{fileInfo?.customer_name || '---'}様</div>
                    </div>
                  </div>
                  <div className="thumb google ml_5">
                    {console.log(fileInfo?.format)}
                    <LoadingFileImg
                      id={fileInfo?.id}
                      format={fileInfo?.format}
                      fileName={fileInfo?.file_name || ''}
                    />
                    <div>
                      <span className={`file_${fileInfo?.format}_color file_name_box`}>
                        {fileInfo?.format}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="t_row">
                  <div className="t_header">案件名</div>
                  <div className="t_body">{fileInfo?.project_name || '---'}</div>
                </div>
                <div className="t_row">
                  <div className="t_header">ｱｯﾌﾟﾛｰﾄﾞ日時</div>
                  <div className="t_body">{DateFormatter.date2str(fileInfo?.upload_date, 'YYYYMMDD_HHmmSS') || '---'}</div>
                </div>
                <div className="t_row">
                  <div className="t_header">登録者</div>
                  <div className="t_body">{fileInfo?.created_employee_name || '---'}</div>
                </div>
                <div className="t_row">
                  <div className="t_header">更新日</div>
                  <div className="t_body">{DateFormatter.date2str(fileInfo?.updated_at, 'YYYYMMDD_HHmm') || '---'}</div>
                </div>
                <div className="t_row">
                  <div className="t_header">更新者</div>
                  <div className="t_body">{fileInfo?.updated_employee_name || '---'}</div>
                </div>
                <div className="t_row">
                  <div className="t_header">コメント</div>
                  <div className="t_body">{fileInfo?.comment || '---'}</div>
                </div>
              </div>
            </div>

            <div className="page_body_footer file_detail_sp__footer">
              <LeftIconButton
                fontAwesomeClass="fas fa-file-download"
                label="ダウンロード"
                color="primary"
                size="md"
                disabled={
              fileInfo
                ? FileCollection.disabledDownloadSP.includes(fileInfo.format.toLowerCase()) : true
            }
                onClick={handleClickDownload}
              />
              <Button
                color="dark"
                size="md"
                onClick={handleClickDelete}
              >削除
              </Button>
            </div>
          </div>
        </BasePageSP>
      )
        : (
          <div className="detail_wrap for_order">
            <div className="detail_header">
              <div
                className="detail_header_inner"
                onClick={() => {
                  if (onBack) {
                    onBack();
                  } else {
                    dispatch(goBack());
                  }
                }}
              >
                <div
                  className="detail_header_inner__back_btn"
                >
                  <i className="fas fa-chevron-circle-left" />
                </div>
                ファイル詳細
              </div>
              <div className="detail_header_buttons">
                <LeftIconButton
                  color="secondary"
                  size="sm"
                  onClick={handleClickEdit}
                  fontAwesomeClass="fa fa-edit"
                  label="ファイル編集"
                />
              </div>
            </div>

            <div className="detail_body file_detail_sp__body">
              <div className="row_table_style file_detail_sp__body__table">
                <div className="col_2">
                  <div>
                    <div className="t_row">
                      <div className="t_header">ファイルID</div>
                      <div className="t_body">{fileInfo?.internal_id}</div>
                    </div>
                    <div className="t_row">
                      <div className="t_header">ファイル名</div>
                      <div className="t_body">{fileInfo?.file_name}</div>
                    </div>
                    <div className="t_row">
                      <div className="t_header">ﾌｧｲﾙｻｲｽﾞ</div>
                      <div className="t_body">{fileSize}KB</div>
                    </div>
                    <div className="t_row">
                      <div className="t_header">顧客名</div>
                      <div className="t_body">{fileInfo?.customer_name || '---'}様</div>
                    </div>
                  </div>
                  <div className="thumb google ml_5">
                    {console.log(fileInfo?.format)}
                    <LoadingFileImg
                      id={fileInfo?.id}
                      format={fileInfo?.format}
                      fileName={fileInfo?.file_name || ''}
                    />
                    <div>
                      <span className={`file_${fileInfo?.format}_color file_name_box`}>
                        {fileInfo?.format}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="t_row">
                  <div className="t_header">案件名</div>
                  <div className="t_body">{fileInfo?.project_name || '---'}</div>
                </div>
                <div className="t_row">
                  <div className="t_header">ｱｯﾌﾟﾛｰﾄﾞ日時</div>
                  <div className="t_body">{DateFormatter.date2str(fileInfo?.upload_date, 'YYYYMMDD_HHmmSS') || '---'}</div>
                </div>
                <div className="t_row">
                  <div className="t_header">登録者</div>
                  <div className="t_body">{fileInfo?.created_employee_name || '---'}</div>
                </div>
                <div className="t_row">
                  <div className="t_header">更新日</div>
                  <div className="t_body">{DateFormatter.date2str(fileInfo?.updated_at, 'YYYYMMDD_HHmm') || '---'}</div>
                </div>
                <div className="t_row">
                  <div className="t_header">更新者</div>
                  <div className="t_body">{fileInfo?.updated_employee_name || '---'}</div>
                </div>
                <div className="t_row">
                  <div className="t_header">コメント</div>
                  <div className="t_body">{fileInfo?.comment || '---'}</div>
                </div>
              </div>
            </div>

            <div className="page_body_footer file_detail_sp__footer for_order">
              <LeftIconButton
                fontAwesomeClass="fas fa-file-download"
                label="ダウンロード"
                color="primary"
                size="md"
                disabled={
            fileInfo
              ? FileCollection.disabledDownloadSP.includes(fileInfo.format.toLowerCase()) : true
          }
                onClick={handleClickDownload}
              />
              <Button
                color="dark"
                size="md"
                onClick={handleClickDelete}
              >削除
              </Button>
            </div>
          </div>

        )}
    </>

  );
};
