import './support-history.pc.scss';
import { useDispatch } from 'react-redux';
import { goBack, replace } from 'connected-react-router';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { BasePagePC } from '../base.page.pc';
import { DialogActions } from '../../../../redux/dialog/dialog.action';
import { SupportHistoryListPC } from '../../layout/body/list/support-history-list/support-history-list.pc';
import { SupportHistorySearchBox } from '../../layout/search-box/support-history/support-history-search-box';
import { SupportHistoryEditPC } from './edit/support-history-edit.pc';
import { LeftIconButton } from '../../../ui/button/left-icon-button/left-icon-button';
import { SupportHistoryActions } from '../../../../redux/support-history/support-history.action';
import { useWillUnMount, useDidMount } from '../../../../hooks/life-cycle';
import { SupportHistoryModel } from '../../../../model/support-history/support-history.model';
import { RoutingPath } from '../../../../routes/routing-pass';
import { useAppSelector } from '../../../../hooks/use-redux';
import { Limit, TableSort } from '../../../ui/table/table-sort/table-sort';
import { SupportHistorySortState, SupportHistoryListType } from '../../../../type/support-history/support-history.type';
import { CsvActions } from '../../../../redux/csv/csv.action';

export const SupportHistoryPC = () => {
  /* Hooks */
  const { id } = useParams<{id:string}>();
  const dispatch = useDispatch();
  const user = useAppSelector((v) => v.user);
  const sortState = useAppSelector((v) => (v.supportHistory.sort));

  /* State */
  const [selectId, setSelectId] = useState<number | undefined>(undefined);
  const [searchIsOpen, setSearchIsOpen] = useState<boolean>(false);
  const [hitCount, setHitCount] = useState(0);

  const [select, setSelect] = useState<number[]>([]);
  const [listList, setListList] = useState<SupportHistoryListType[] | null | undefined>(null);
  const [stopLoad, setStopLoad] = useState(false);

  /* Callback */
  /* 一覧取得 */
  const getList = useCallback((v?: SupportHistorySortState) => {
    dispatch(SupportHistoryActions.api.supportHistory.getList({
      param: SupportHistoryModel.listParam({ sortState: v || sortState }),
      onSuccess: (res, hit) => {
        setStopLoad(false);
        dispatch(SupportHistoryActions.setList(res));
        setHitCount(hit || 0);
      },
    }));
  }, [sortState]);

  /* 編集 */
  const edit = useCallback(() => {
    setStopLoad(true);
    dispatch(DialogActions.push({
      title: selectId ? '対応履歴編集' : '対応履歴新規登録',
      className: 'support_history',
      onCloseClick: () => {
        setStopLoad(false);
        if (selectId) { dispatch(replace(RoutingPath.supportHistory)); }
      },
      element: <SupportHistoryEditPC
        id={selectId ? Number(selectId) : undefined}
        callback={() => {
          dispatch(SupportHistoryActions.setList(null));
          getList();
        }}
      />,
    }));
  }, [selectId, getList]);

  const handleChangePagination = useCallback((offset: number, limit: Limit) => {
    dispatch(SupportHistoryActions.setList(null));
    dispatch(SupportHistoryActions.setSort({ offset, limit }));
    getList({ ...sortState, offset, limit });
  }, [sortState.limit, sortState]);

  /* 一覧取得 */
  useEffect(() => {
    getList();
  }, [sortState.highlow, sortState.sort_by]);

  /* 対応履歴詳細SPのURLを叩かれたときの処理 */
  useEffect(() => {
    if (!selectId) return;
    edit();
  }, [selectId]);

  useEffect(() => {
    setSelectId(id ? Number(id) : undefined);
  }, [id]);

  // useWillUnMount(() => dispatch(SupportHistoryActions.setInitSort(user)));

  return (
    <BasePagePC className="SupportHistoryPC">
      <div id="support-history" className={`cnt_area ${searchIsOpen ? 'detail_on' : ''}`}>
        <div className="inner">
          <SupportHistorySearchBox
            openCallback={setSearchIsOpen}
            callback={(v) => {
              setStopLoad(true);
              dispatch(SupportHistoryActions.setList(null));
              dispatch(SupportHistoryActions.setSort({ offset: 0 }));
              getList({ ...v, offset: 0 });
            }}
          />
          <TableSort
            page={sortState.offset ?? 0}
            limit={sortState.limit !== (100 || 200 || 300 || 400 || 500 || 9999)
              ? 100 : sortState.limit as Limit}
            hitCount={hitCount}
            callback={handleChangePagination}
          />
          <SupportHistoryListPC
            // callbackSelected={setSelected}
            selectId={selectId}
            callbackSelect={setSelect}
            callbackList={setListList}
            parentSelect={select}
            getList={getList}
            callbackStopLoad={setStopLoad}
            stopLoad={stopLoad}
          />
        </div>
      </div>
      <footer className="btn_area">
        <div className="left_box">
          <LeftIconButton
            label="対応履歴新規登録"
            size="md"
            fontAwesomeClass="fas fa-edit"
            className="btn_search for_detail"
            color="primary"
            onClick={edit}
          />
          <LeftIconButton
            label="CSV出力"
            size="md"
            fontAwesomeClass="fas fa-file-csv"
            className="btn_search for_detail"
            color="primary"
            onClick={() => {
              if (!listList) return;
              if (!listList.length) return;
              const csvParam = select.length
                ? {
                  ids: select.map((v) => listList[v].id),
                } : {
                  ...SupportHistoryModel.listParam({ sortState }),
                };
              if (!csvParam) return;
              Object.assign(csvParam, {
                highlow: sortState.highlow,
              });
              dispatch(CsvActions.api.supportHistory.download(csvParam));
            }}
          />
        </div>
        <div className="right_box">
          <LeftIconButton
            label="戻る"
            fontAwesomeClass="fas fa-arrow-left"
            size="md"
            color="dark"
            onClick={() => dispatch(goBack())}
          />
        </div>
      </footer>
    </BasePagePC>
  );
};
