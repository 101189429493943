import { useDispatch } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import { cloneDeep } from 'lodash';
import { LeftIconButton } from '../../../../../ui/button/left-icon-button/left-icon-button';
import { MasterActions } from '../../../../../../redux/master/master.action';
import { EigyoTantou } from './another-window/eigyo-tantou';
import { Area } from './another-window/area';
import { CustomerRank } from './another-window/customer-rank';
import { EstimatedRank } from './another-window/estimated-rank';
import { RelevantTag } from './another-window/relevant-tag';
import { BuildingCategory } from './another-window/building-category';
import { Madori } from './another-window/mafdori';
import { Origin } from './another-window/origin';
import { Part } from './another-window/part';
import { FileUploadButton } from '../../../../../ui/file-upload/file-upload-button';
import { ImportFileUploadResult, UploadErrorListType } from '../../../../../../redux/master/api/customer-import/api-customer-import.type';
import { SystemActions } from '../../../../../../redux/system/system.action';
import { DialogActions } from '../../../../../../redux/dialog/dialog.action';
import { MasterCustomerInfoImportCollection } from './master-customer-info-import.collection';
import { useAppSelector } from '../../../../../../hooks/use-redux';
import { ApiUser } from '../../../../../../redux/user/api/api-user';
import { UserActions } from '../../../../../../redux/user/user.action';
import { AuthActions } from '../../../../../../redux/auth/auth.action';

const init = () => [false, false, false, false, false, false, false, false, false];

export const CustomerInfoImport = () => {
  const dispatch = useDispatch();

  const isLoading = useAppSelector((v) => v.system.isLoading);

  const [opens, setOpens] = useState(init());
  const [isUpload, setIsUpload] = useState(false);
  const [uploadCount, setUploadCount] = useState(0);
  const [info, setInfo] = useState<ImportFileUploadResult | null>(null);
  const [isShowList, setIsShowList] = useState(false);
  const [uploadFile, setUploadFile] = useState<File | null>(null);

  const [successList, setSuccessList] = useState<ImportFileUploadResult['data']>([]);
  const [errorList, setErrorList] = useState<ImportFileUploadResult['error_messages']>([]);
  const [uploadErrorList, setUploadErrorList] = useState<ImportFileUploadResult['data']>([]);

  const clear = () => {
    setIsUpload(false);
    setErrorList([]);
    setSuccessList([]);
    setUploadErrorList([]);
    setIsShowList(false);
    setUploadFile(null);
    setInfo(null);
    setUploadCount(0);
  };

  const setOpensData = useCallback((index: number, isOpen: boolean) => {
    opens[index] = isOpen;
    setOpens(cloneDeep(opens));
  }, [opens]);

  /* 顧客情報保存 */
  const post = useCallback((postValue:ImportFileUploadResult['data'][0][], token: string) => {
    if (!postValue) return;
    dispatch(MasterActions.api.customerImport.post({
      param: postValue.map((v) => MasterCustomerInfoImportCollection.postParam(v)),
      token,
      onSuccess: () => {
        setUploadCount((v) => v - 1);
      },
      onError: (i) => {
        setUploadCount((v) => v - 1);
        uploadErrorList.push(cloneDeep(postValue[i]));
        setUploadErrorList(cloneDeep(uploadErrorList));
      },
      onApi: () => {},
    }));
  }, [uploadErrorList]);

  /* ファイルアップロード */
  const upload = useCallback(() => {
    if (!uploadFile) return;
    dispatch(MasterActions.api.customerImport.uploadCsv({
      param: { filedata: uploadFile },
      onSuccess: (v) => {
        setIsShowList(true);
        setInfo(v);
        setSuccessList(v.data);
        setErrorList(v.error_messages);
        setUploadCount(v.data.length);
      },
    }));
  }, [uploadFile]);

  /* ファイルチェンジ */
  const onChangeFile = useCallback((e:globalThis.React.ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files;
    if (!fileList?.length) return;
    clear();
    setUploadFile(fileList[0]);
  }, []);

  /* 確認画面へボタン押下処理 */
  const hClickConfirm = useCallback(() => {
    if (!uploadFile) return;
    setErrorList([]);
    setSuccessList([]);
    setUploadErrorList([]);
    setIsShowList(false);
    setInfo(null);
    upload();
  }, [uploadFile]);

  /* キャンセルボタン押下処理 */
  const hClickCancel = useCallback(() => {
    clear();
  }, []);

  /* 登録ボタン押下処理 */
  const hClickRegist = useCallback(() => {
    if (!successList.length) return;
    const me = new ApiUser();
    me.run()
      .then(() => dispatch(AuthActions.setToken(me.token)))
      .then(() => {
        dispatch(SystemActions.isLoading(true));
        post(successList, me.token);
        setIsUpload(true);
      });
  }, [successList]);

  useEffect(() => {
    if (uploadCount <= 0 && isLoading) {
      dispatch(DialogActions.pushMessage({
        title: '顧客情報インポート',
        message: ['インポートが終了しました'],
      }));
      setSuccessList(cloneDeep(uploadErrorList));
      setUploadErrorList([]);
      dispatch(SystemActions.isLoading(false));
    }
  }, [uploadCount]);

  return (
    <div className="main_cnt">
      <div className="search_area" style={{ padding: '15px', maxHeight: 'inherit' }}>
        <div className="item_wrap">
          <div className="item_box">
            <LeftIconButton
              label="営業担当"
              fontAwesomeClass="fas fa-th-list"
              className="mr_5"
              size="sm"
              color="secondary"
              onClick={() => setOpensData(0, !opens[0])}
            />
            <EigyoTantou
              open={opens[0]}
              callbackClose={() => setOpensData(0, false)}
            />
          </div>
          <div className="item_box">
            <LeftIconButton
              label="エリア"
              fontAwesomeClass="fas fa-th-list"
              className="mr_5"
              size="sm"
              color="secondary"
              onClick={() => setOpensData(1, !opens[1])}
            />
            <Area open={opens[1]} callbackClose={() => setOpensData(1, false)} />
          </div>
          <div className="item_box">
            <LeftIconButton
              label="顧客ランク"
              fontAwesomeClass="fas fa-th-list"
              className="mr_5"
              size="sm"
              color="secondary"
              onClick={() => setOpensData(2, !opens[2])}
            />
            <CustomerRank open={opens[2]} callbackClose={() => setOpensData(2, false)} />
          </div>
          <div className="item_box">
            <LeftIconButton
              label="見込客ランク"
              fontAwesomeClass="fas fa-th-list"
              className="mr_5"
              size="sm"
              color="secondary"
              onClick={() => setOpensData(3, !opens[3])}
            />
            <EstimatedRank open={opens[3]} callbackClose={() => setOpensData(3, false)} />
          </div>
          <div className="item_box">
            <LeftIconButton
              label="関連タグ"
              fontAwesomeClass="fas fa-th-list"
              className="mr_5"
              size="sm"
              color="secondary"
              onClick={() => setOpensData(4, !opens[4])}
            />
            <RelevantTag open={opens[4]} callbackClose={() => setOpensData(4, false)} />
          </div>
          <div className="item_box">
            <LeftIconButton
              label="建物分類"
              fontAwesomeClass="fas fa-th-list"
              className="mr_5"
              size="sm"
              color="secondary"
              onClick={() => setOpensData(5, !opens[5])}
            />
            <BuildingCategory open={opens[5]} callbackClose={() => setOpensData(5, false)} />
          </div>
          <div className="item_box">
            <LeftIconButton
              label="間取り"
              fontAwesomeClass="fas fa-th-list"
              className="mr_5"
              size="sm"
              color="secondary"
              onClick={() => setOpensData(6, !opens[6])}
            />
            <Madori open={opens[6]} callbackClose={() => setOpensData(6, false)} />
          </div>
          <div className="item_box">
            <LeftIconButton
              label="発生源"
              fontAwesomeClass="fas fa-th-list"
              className="mr_5"
              size="sm"
              color="secondary"
              onClick={() => setOpensData(7, !opens[7])}
            />
            <Origin open={opens[7]} callbackClose={() => setOpensData(7, false)} />
          </div>
          <div className="item_box">
            <LeftIconButton
              label="部位"
              fontAwesomeClass="fas fa-th-list"
              className="mr_5"
              size="sm"
              color="secondary"
              onClick={() => setOpensData(8, !opens[8])}
            />
            <Part open={opens[8]} callbackClose={() => setOpensData(8, false)} />
          </div>
        </div>
        <div className="item_wrap">
          <div className="item_box mt_5 mb_10">
            <LeftIconButton
              label="テンプレートダウンロード"
              fontAwesomeClass="fas fa-download"
              className="mr_5 template_download"
              size="sm"
              color="primary"
              onClick={() => dispatch(MasterActions.api.customerImport.getTemplate())}
            />
            <div>CSV上の<span className="require"> ∗（アスタリスク）</span>の付いた項目は、必須項目となります。</div>
          </div>
        </div>
        <div className="item_wrap">
          <div className="require mb_10" style={{ lineHeight: '1.5em', marginLeft: '0px' }}>
            ※）顧客情報の重複がないよう住所など確認の上、インポートしてください。<br />
            ※）電話番号が重複しているものはインポートできません。<br />
            ※）『関連タグ』『部位』など複数設定する場合は『/（半角スラッシュ）』で区切りをいれてください。<br />
          </div>
        </div>
        <div className="frame item_wrap" style={{ padding: '15px', marginBottom: '0' }}>
          <div className="item_box" style={{ marginBottom: '0', alignItems: 'center' }}>
            <div className="item_head">CSVファイル</div>
            <FileUploadButton
              onChange={onChangeFile}
              accept=".csv"
              className="csv_file"
            />
          </div>
          <div className="item_box mr_15">{uploadFile?.name}</div>
          {/* <Input
                className="mr_5"
                value={uploadFile?.name}
                onChange={() => {}}
              /> */}
          <div className="item_box">
            <div className="comment">一度にアップロードできる上限数は<span className="require">1,000件</span>となります。</div>
          </div>
          <LeftIconButton
            label="確認画面へ"
            fontAwesomeClass="fas fa-arrow-circle-right"
            className="btn_search for_detail"
            size="sm"
            color="primary"
            onClick={hClickConfirm}
            disabled={!uploadFile}
          />
        </div>
      </div>
      {isShowList
      && (
      <div className="search_area" style={{ padding: '15px' }}>
        <div className="item_wrap flex_align_center">
          登録可能データ {info?.total_count || 0}件中 {info?.validate_ok_count || 0}件<br />
          登録不可データ {info?.validate_ng_count || 0}件
          <div className="ml_auto" style={{ textAlign: 'right' }}>
            <div className="flex_box">
              <LeftIconButton
                label="キャンセル"
                fontAwesomeClass="fas fa-arrow-circle-left"
                className="btn_search for_detail"
                size="sm"
                color="dark"
                onClick={hClickCancel}
              />
              <LeftIconButton
                label="登録"
                fontAwesomeClass="fas fa-user-plus"
                className="btn_search for_detail ml_5"
                size="sm"
                color="primary"
                disabled={!successList.length}
                onClick={hClickRegist}
              />
            </div>
          </div>
        </div>
      </div>
      )}

      {isShowList
      && (
      <section className="result_area list_area wrap_for_import">
        <div className="" style={{ padding: '15px' }}>
          <h2>{isUpload && uploadCount <= 0 ? '登録に失敗した' : '正常に登録可能な'}データ</h2>
          <div>
            <div className="table_responsive mb_10">
              <table className="table_selectable table_sortable table_sticky">
                <thead>
                  <tr>
                    {MasterCustomerInfoImportCollection.successListHeader.map(
                      (v) => (<th>{v}</th>),
                    )}
                  </tr>
                </thead>
                <tbody>
                  {successList.map((v, i) => (
                    <tr key={i}>
                      <td>{v.line_no}</td>
                      <td>{v.name}</td>
                      <td>{v.post_no}</td>
                      <td>{v.prefecture}</td>
                      <td>{v.city}</td>
                      <td>{v.address}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div>
            <h2>エラーを出力したデータ</h2>
            <span className="item_wrap alert mb_5">
              ※下記のエラーを出力したデータは登録することができません。キャンセルボタンを押して、再度CSVをアップロードすることをお勧めします。
            </span>
          </div>
          <div>
            <div className="table_responsive">
              <table className="table_selectable table_sortable table_sticky">
                <thead>
                  <tr>
                    <th>行番号</th>
                    <th>名前</th>
                    <th>エラーメッセージ</th>
                  </tr>
                </thead>
                <tbody>
                  {errorList.map((v, i) => (
                    <tr key={i}>
                      <td>{v.line_no}</td>
                      <td>{v.name}</td>
                      <td>{v.message}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
      )}
    </div>
  );
};
