import { push } from 'connected-react-router';
import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { ApiSupportHistoryPostParam } from '../../redux/support-history/api/api-support-history/api-support-history.type';
import { SupportHistoryActions } from '../../redux/support-history/support-history.action';
import { RoutingPath } from '../../routes/routing-pass';
import { useTel, useMail } from '../common/use-common';
import { Project, ProjectListType } from '../../type/project/project.type';
import { Customer, CustomerListType } from '../../type/customer/customer.type';
import { DateFormatter } from '../../utilities/date-formatter';
import { useAppSelector } from '../use-redux';
import { DialogActions } from '../../redux/dialog/dialog.action';
import { UserAgent } from '../../utilities/user-agent';
import {
  openLink, openFacebook, openInstagram, openTwitter,
} from '../../utilities/open-link';
import { getNowDate } from '../../utilities/get-now-time';
import { SupportHistoryEditPC } from '../../components/pc/pages/support-history/edit/support-history-edit.pc';
import { SupportHistoryEditSP } from '../../components/sp/pages/support-history/edit/support-history-edit.sp';
import { MasterActions } from '../../redux/master/master.action';
import { MasterSupportHistory } from '../../type/master/master-support-history.type';
import { Maintenance, MaintenanceList } from '../../type/maintenance/maintenance.type';

const useContactPost = () => {
  const { store_id, id } = useAppSelector((v) => v.user);

  const type = (_type : 'tel' | 'sms' | 'mail' | 'Twitter' | 'Instagram' | 'LINE' | 'Facebook') => {
    switch (_type) {
      case 'tel':
        return 94;
      case 'mail':
        return 95;
      case 'sms':
        return 96;
      case 'LINE':
        return 97;
      case 'Facebook':
        return 98;
      case 'Twitter':
        return 99;
      case 'Instagram':
        return 100;
      default: return 95;
    }
  };

  const dispatch = useDispatch();

  return useCallback((param: {
    customer_id: number;
    project_id?: number;
    title: string;
    support_comment: string;
    isPushDialog?: boolean;
    _type?: 'tel' | 'sms' | 'mail' | 'Twitter' | 'Instagram' | 'LINE' | 'Facebook';
    callbackPostEnd?: () => void;
  }) => {
    const {
      customer_id, project_id, title, support_comment, isPushDialog, _type, callbackPostEnd,
    } = param;
    const { date, hour, min } = getNowDate();
    const datas: ApiSupportHistoryPostParam['data'] = {
      contact_dt: DateFormatter.date2str(date, 'YYYYMMDD', '-'),
      contact_hour: hour,
      contact_minutes: min,
      contact_store_id: store_id,
      contact_employee_id: id,
      support_employee_id: id,
      support_store_id: store_id,
      customer_id,
      project_id,
      title,
      support_category_id: type(_type || 'tel'),
      support_comment,
    };
    dispatch(MasterActions.api.supportHistory.getListCallback({
      param: {},
      onSuccess: (res1) => {
        if (!res1 || !res1.length || !_type) return;
        const typeId = res1.find((v: MasterSupportHistory) => v.internal_id === type(_type));
        if (!typeId) return;
        dispatch(SupportHistoryActions.api.supportHistory.post({
          param: {
            data: {
              ...datas,
              support_flag: 1,
              support_category_id: typeId.id,
            },
          },
          noMessage: true,
          onSuccess: (res) => {
            callbackPostEnd?.();
            if (!res) return;
            if (isPushDialog) {
              dispatch(DialogActions.push({
                title: '対応履歴編集',
                callback: () => callbackPostEnd?.(),
                element: UserAgent === 'pc'
                  ? <SupportHistoryEditPC id={res.id} callbackPostEnd={() => callbackPostEnd?.()} />
                  : (
                    <SupportHistoryEditSP
                      id={res.id}
                      callbackPostEnd={() => callbackPostEnd?.()}
                    />
                  ),
              }));
              return;
            }
            dispatch(push(`${RoutingPath.supportHistoryDetail}/${res.id}`));
          },
        }));
      },
    }));
  }, [dispatch, push, store_id, id]);
};

export const useTelContact = () => {
  const { store_id, id } = useAppSelector((v) => v.user);
  const dispatch = useDispatch();
  const callTel = useTel();
  const post = useContactPost();

  return useCallback((param: {
    type: 'customer' | 'project' | 'maintenance',
    data: Project
    | ProjectListType
    | Customer
    | CustomerListType
    | Maintenance
    |MaintenanceList
    | null
    | undefined;
    tel?: string,
    isPushDialog?: boolean;
    isSMS?: boolean;
    callbackPostEnd?: () => void;
  }) => {
    const {
      type, data, isPushDialog, tel: telStr, isSMS, callbackPostEnd,
    } = param;
    let name = '';
    let tel = telStr || '';
    if (type === 'project') {
      const value = data as Project | ProjectListType;
      name = value.customer_name;
      tel = tel || value.field_tel_no;
    } else if (type === 'customer') {
      const value = data as Customer | CustomerListType;
      name = value.name;
      tel = tel || value.tel_no;
    } else {
      const value = data as Maintenance | MaintenanceList;
      name = value.customer_name;
      tel = tel || value.tel_no;
    }

    callTel({
      name,
      tel,
      isSMS,
      callback: () => {
        // dispatch(MasterActions.api.supportHistory.getList({}))
        if (type === 'customer') {
          const value = data as Customer | CustomerListType;
          post({
            _type: isSMS ? 'sms' : 'tel',
            customer_id: value.id,
            title: `${isSMS ? 'SMS' : '電話'}タップ`,
            support_comment: `システムから${isSMS ? 'SMS' : '電話'}タップ\n${tel || ''}`,
            isPushDialog,
            callbackPostEnd,
          });
        } else {
          const value = data as Project | ProjectListType;
          post({
            _type: isSMS ? 'sms' : 'tel',
            customer_id: value.customer_id,
            project_id: value.id,
            title: `${isSMS ? 'SMS' : '電話'}タップ`,
            support_comment: `システムから${isSMS ? 'SMS' : '電話'}タップ\n${tel || ''}`,
            isPushDialog,
            callbackPostEnd,
          });
        }
      },
    });
  }, [store_id, id]);
};

export const useMailContact = () => {
  const { store_id, id } = useAppSelector((v) => v.user);
  const user = useAppSelector((v) => v.user);
  const sendEmail = useMail();
  const post = useContactPost();

  return useCallback((param: {
    type: 'customer' | 'project',
    data: Project
    | ProjectListType
    | Customer
    | CustomerListType
    | null
    | undefined;
    mailAddress?: string,
    isPushDialog?: boolean,
    callbackPostEnd?: () => void;
  }) => {
    const {
      type, data, mailAddress, isPushDialog, callbackPostEnd,
    } = param;
    let name = '';
    let tel = '';
    if (type === 'project') {
      const value = data as Project | ProjectListType;
      name = value.customer_name;
      tel = tel || value.field_tel_no;
    } else {
      const value = data as Customer | CustomerListType;
      name = value.name;
      tel = tel || value.tel_no;
    }

    sendEmail({
      name,
      email: (() => {
        if (type === 'customer') {
          const val = data as Customer | CustomerListType;
          return ({
            emailAddress: mailAddress || val.mail_address,
            name: val.name,
            companyName: user.company_name,
            postNo: user.store_post_no ? `${user.store_post_no.slice(0, 3)}-${(user.store_post_no.slice(3, 7))}` : '',
            storeName: user.store_name,
            fax: user.store_fax_no,
            tel: user.store_tel_no,
            message: ['', '', ''],
            address: `${user.prefecture || ''}${user.store_city || ''}${user.store_address || ''}${user.store_building_name || ''}`,
          });
        }
        const val = data as Project | ProjectListType;
        return ({
          emailAddress: mailAddress || /* val.mail */'',
          name: val.name ?? '',
          companyName: user.company_name || '',
          storeName: user.store_name ?? '',
          postNo: user.store_post_no ? `${user.store_post_no.slice(0, 3)}-${(user.store_post_no.slice(3, 7))}` : '',
          fax: user.store_fax_no || '',
          tel: user.store_tel_no || '',
          message: ['', '', ''],
          address: `${user.prefecture || ''}${user.store_city || ''}${user.store_address || ''}${user.store_building_name || ''}`,
        });
      })(),
      callback: () => {
        if (type === 'customer') {
          const value = data as Customer | CustomerListType;
          post({
            _type: 'mail',
            customer_id: value.id,
            title: 'メール送信',
            support_comment: `システムからメール送信\n${mailAddress || ''}`,
            isPushDialog,
            callbackPostEnd,
          });
        } else {
          const value = data as Project | ProjectListType;
          post({
            _type: 'mail',
            customer_id: value.customer_id,
            project_id: value.id,
            title: 'メール送信',
            support_comment: `システムからメール送信\n${mailAddress || ''}`,
            isPushDialog,
            callbackPostEnd,
          });
        }
      },
    });
  }, [store_id, id]);
};

type SNSMode = 'LINE' | 'Facebook' | 'Instagram' | 'Twitter';
export const useSNSContact = () => {
  const dispatch = useDispatch();
  const post = useContactPost();

  const createTitle = useCallback((mode: SNSMode) => {
    if (mode === 'LINE' && UserAgent === 'pc') {
      return 'LINE ID コピー';
    }
    return mode;
  }, []);

  const createSupportComment = useCallback((mode: SNSMode) => {
    if (mode === 'LINE' && UserAgent === 'pc') {
      return 'LINE IDのコピー';
    }
    return `システムから${mode}閲覧`;
  }, []);

  const open = useCallback((mode: SNSMode, id: string) => {
    if (mode === 'LINE' && UserAgent === 'sp') {
      openLink('https://line.me/R/nv/addFriends');
    } else if (mode === 'Facebook') {
      openFacebook(id);
    } else if (mode === 'Instagram') {
      openInstagram(id);
    } else if (mode === 'Twitter') {
      openTwitter(id);
    }
  }, []);

  return useCallback((param: {
    mode: SNSMode;
    name: string;
    id: string;
    customerId?: number;
    projectId?: number;
    callbackPostEnd?: () => void,
  }) => {
    const {
      name, mode, id, customerId, projectId, callbackPostEnd,
    } = param;

    let message = `${name}様の${mode}を開きますか`;
    if (mode === 'LINE' && UserAgent === 'pc')message = `${name}様のLINE IDをコピーしますか`;
    if (mode === 'LINE' && UserAgent === 'sp')message = `${name}様のLINE IDをコピーしてLINEを開きますか`;
    if (customerId || (mode === 'LINE' && UserAgent === 'pc')) {
      dispatch(DialogActions.pushMessage({
        title: mode,
        message: [message],
        label: 'はい',
        isCancel: true,
        cancelLabel: 'いいえ',
        callback: () => {
          if (customerId) {
            open(mode, id);
            post({
              _type: mode,
              customer_id: customerId,
              project_id: projectId,
              title: createTitle(mode),
              support_comment: createSupportComment(mode),
              isPushDialog: true,
              callbackPostEnd: () => callbackPostEnd?.(),
            });
          }
        },
      }));
    } else {
      open(mode, id);
    }
  },
  [createTitle, createSupportComment, dispatch, open]);
};
