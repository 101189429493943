import * as lodash from 'lodash';
import { User } from '../../type/auth/user.typs';
import {
  SupportHistoryEditState,
  SupportHistorySortState,
} from '../../type/support-history/support-history.type';

export class SupportHistoryCollection {
  private static _header = [
    '対応済',
    'サムネイル',
    '登録日時',
    '対応履歴名',
    '媒体',
    'カテゴリ',
    '顧客名',
    '顧客担当者',
    '案件担当者',
    '対応者',
    '対応日',
  ];

  static _editInitialState(user?:User): SupportHistoryEditState {
    return lodash.cloneDeep({
      /** 顧客ID */
      customer_id: NaN,
      /** 案件ID */
      project_id: NaN,
      /** 登録日時 */
      contact_dt: null,
      /** 受付時 */
      contact_hour: NaN,
      /** 受付分 */
      contact_minutes: NaN,
      /** 受付担当店舗 */
      contact_store_id: user?.store_id ?? NaN,
      /** 受付担当担当者 */
      contact_employee_id: user?.id ?? NaN,
      /** 対応履歴名 */
      title: '',
      /** カテゴリ */
      support_category_id: NaN,
      /** 媒体 */
      source_id: NaN,
      /** ファイル名 */
      file_name: [],
      /** ファイル */
      file: [],
      /** 対応内容 */
      support_comment: '',
      /** 対応担当店舗 */
      support_store_id: user?.store_id ?? NaN,
      /** 対応担当担当者 */
      support_employee_id: user?.id ?? NaN,
      /** 対応日 */
      support_dt: null,
      /** 対応済みフラグ 0:未対応 1:対応済 */
      support_flag: 0,
      /** ファイル削除ID */
      delete_file_id: [],
      /** ファイル更新ID */
      update_file_id: [],
      /** 更新ファイル名 */
      update_file_name: [],
      customer_name: '',
      project_name: '',
      file_ids: [],
    });
  }

  static _initialSortState(user?: User): SupportHistorySortState {
    return lodash.cloneDeep({
      /** 受付日 */
      contact_dt: null,
      /** カテゴリ */
      support_id: NaN,
      /** 対応区分
       * 2:指定なし
       * 0:未対応
       * 1:対応済み
       */
      support_flag: 2,
      /** 文字列検索 */
      word: '',
      /** 顧客名 */
      customer_name: '',
      /** 顧客担当店舗 */
      customer_store_id: NaN,
      /** 顧客担当担当者 */
      customer_employee_id: NaN,
      /** 対応日 */
      support_dt: null,
      /** 対応担当店舗 */
      support_store_id: NaN,
      /** 対応担当担当者 */
      support_employee_id: user?.id ?? NaN,
      /** オフセット */
      offset: 0,
      /** 1ページ内の件数 */
      limit: 100,
      /** 並替基準列 */
      sort_by: NaN,
      /** 並替方法 */
      highlow: 1,
      /** 顧客ID */
      customer_id: NaN,
      /** 案件名 */
      project_name: '',
      /** 受付担当店舗 */
      contact_store_id: NaN,
      /** 受付担当担当者 */
      contact_employee_id: NaN,
      /** 件名 */
      title: '',
      /** 媒体 */
      source_id: NaN,
      /** 対応内容 */
      comment: '',
      /** 案件ID */
      project_id: NaN,
      /** キーワード検索 */
      keyword: '',
      /** 受付日_開始 */
      contact_dt_start: null,
      /** 受付日_終了 */
      contact_dt_end: null,
      /** 対応日_開始 */
      support_dt_start: null,
      /** 対応日_終了 */
      support_dt_end: null,
      /** 登録日－期間開始 */
      created_at_start: null,
      /** 登録日－期間終了 */
      created_at_end: null,
      /** 更新日－期間開始 */
      updated_at_start: null,
      /** 更新日－期間終了 */
      updated_at_end: null,
      /** 登録社員 */
      created_employee_id: NaN,
      /** 更新店舗 */
      updated_store_id: NaN,
      /** 更新社員 */
      updated_employee_id: NaN,
      /** 登録店舗 */
      created_store_id: NaN,

    });
  }

  static _initialDetailSortState(user?: User): SupportHistorySortState {
    return lodash.cloneDeep({
      /** 受付日 */
      contact_dt: null,
      /** カテゴリ */
      support_id: NaN,
      /** 対応区分
       * 2:指定なし
       * 0:未対応
       * 1:対応済み
       */
      support_flag: NaN,
      /** 文字列検索 */
      word: '',
      /** 顧客名 */
      customer_name: '',
      /** 顧客担当店舗 */
      customer_store_id: NaN,
      /** 顧客担当担当者 */
      customer_employee_id: NaN,
      /** 対応日 */
      support_dt: null,
      /** 対応担当店舗 */
      support_store_id: NaN,
      /** 対応担当担当者 */
      support_employee_id: user?.id ?? NaN,
      /** オフセット */
      offset: 0,
      /** 1ページ内の件数 */
      limit: 100,
      /** 並替基準列 */
      sort_by: NaN,
      /** 並替方法 */
      highlow: 1,
      /** 顧客ID */
      customer_id: NaN,
      /** 案件名 */
      project_name: '',
      /** 受付担当店舗 */
      contact_store_id: NaN,
      /** 受付担当担当者 */
      contact_employee_id: NaN,
      /** 件名 */
      title: '',
      /** 媒体 */
      source_id: NaN,
      /** 対応内容 */
      comment: '',
      /** 案件ID */
      project_id: NaN,
      /** キーワード検索 */
      keyword: '',
      /** 受付日_開始 */
      contact_dt_start: null,
      /** 受付日_終了 */
      contact_dt_end: null,
      /** 対応日_開始 */
      support_dt_start: null,
      /** 対応日_終了 */
      support_dt_end: null,
      /** 登録日－期間開始 */
      created_at_start: null,
      /** 登録日－期間終了 */
      created_at_end: null,
      /** 更新日－期間開始 */
      updated_at_start: null,
      /** 更新日－期間終了 */
      updated_at_end: null,
      /** 登録社員 */
      created_employee_id: NaN,
      /** 更新店舗 */
      updated_store_id: NaN,
      /** 更新社員 */
      updated_employee_id: NaN,
      /** 登録店舗 */
      created_store_id: NaN,

    });
  }

  static get header() {
    return SupportHistoryCollection._header;
  }

  static get editInitialState() {
    return SupportHistoryCollection._editInitialState();
  }

  static get initialSortState() {
    return SupportHistoryCollection._initialSortState;
  }

  static get initialDetailSortState() {
    return SupportHistoryCollection._initialDetailSortState;
  }
}
