import { useCallback, useEffect, useState } from 'react';
import { isEqual, cloneDeep } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../../../../../../redux/root.reducer';
import { CsvListCustomer } from '../../list/customer/csv-list-customer';
import { CsvSearchBoxCustomer } from '../../search-box/customer/csv-search-box-customer';
import { TableSort, Limit } from '../../../../../ui/table/table-sort/table-sort';
import { CsvActions } from '../../../../../../redux/csv/csv.action';
import { useWillUnMount, useDidMount } from '../../../../../../hooks/life-cycle';
import { CsvCustomerSort } from '../../../../../../type/csv/csv-sort.type';
import { MasterActions } from '../../../../../../redux/master/master.action';
import { DateFormatter as DF } from '../../../../../../utilities/date-formatter';
import { CsvModel } from '../../../../../../model/csv/csv-model';
import { useAppSelector } from '../../../../../../hooks/use-redux';

export type Props = {
  callback: (v: () => void) => void;
  isOpenCallBack: (v: boolean) => void
}

export const CsvCustomer = (props:Props) => {
  const { callback, isOpenCallBack } = props;

  /* Hooks */
  const dispatch = useDispatch();
  const sortState = useSelector((state: State) => (state.csv.customerSort), isEqual);
  const list = useSelector((state: State) => (state.csv.customerList), isEqual);
  const hitCount = useAppSelector((v) => (v.csv.customerCount));

  /* State */
  const [selected, setSelected] = useState<number[]>([]);
  // eslint-disable-next-line
  const [searchIsOpen, setSearchIsOpen] = useState<boolean>(false);

  /* Callback */
  const getList = useCallback((v?: CsvCustomerSort) => {
    const sortData = v || sortState;
    dispatch(CsvActions.api.customer.getList({ ...CsvModel.customerEx(cloneDeep(sortData)) }));
    setSelected([]);
  },
  [sortState]);

  const changePagination = useCallback((offset:number, limit:Limit) => {
    dispatch(CsvActions.setCustomerList(null));
    dispatch(CsvActions.setCustomerSort({ offset, limit }));
    getList({ ...sortState, offset, limit });
  }, [sortState]);

  /* Effect */
  useEffect(() => {
    getList();
  }, [
    sortState.highlow,
    sortState.sort_by,
  ]);

  useEffect(() => {
    isOpenCallBack(searchIsOpen);
  }, [searchIsOpen]);

  return (
    <>
      <CsvSearchBoxCustomer
        openCallback={(e) => {
          setSearchIsOpen(e);
        }}
        callback={(v) => {
          dispatch(CsvActions.setCustomerList(null));
          getList(v);
        }}
      />
      <TableSort
        page={sortState.offset ?? 0}
        limit={sortState.limit as Limit}
        hitCount={hitCount || 0}
        callback={changePagination}
      />
      <CsvListCustomer
        callbackSelected={setSelected}
      />
      <TableSort
        className="bottom"
        page={sortState.offset ?? 0}
        limit={sortState.limit as Limit}
        hitCount={hitCount || 0}
        callback={changePagination}
      />
    </>
  );
};
