import { DisplayElements } from '../../../../../../../type/display-elements.type';
import { AnotherWindow } from '../../../../../../ui/another-window/another-window';
import { Button } from '../../../../../../ui/button/button';
import { Table } from '../../../../../../ui/table/table';
import './sub-window.scss';

type Props = {
  open: boolean;
  title: string;
  header: string[];
  lists: DisplayElements[][];
  callbackClose: () => void;
}

export const formatList = (list: any[]) => {
  const _list = list.sort((a, b) => (a.internal_id < b.internal_id ? -1 : 1));
  return (
    _list.map((v) => ([v.internal_id, v.name]))
  );
};

export const SubWindow = (props: Props) => {
  const {
    open, title, header, lists, callbackClose,
  } = props;
  return (
    <AnotherWindow open={open} title={title} onUnload={callbackClose}>
      <div className="another_window_import">
        <header>{title}</header>
        <div className="table_wrap">
          <div className="table_responsive">
            <Table
              header={header}
              lists={lists}
            />
          </div>
        </div>
        <footer>
          <Button
            size="sm"
            color="dark"
            onClick={callbackClose}
          >
            閉じる
          </Button>

        </footer>
      </div>
    </AnotherWindow>
  );
};
