import { useCallback, useEffect, useState } from 'react';
import { Color, ColorResult, SketchPicker } from 'react-color';
import { IconButton } from '../button/icon-button/icon-button';
import { Input } from '../input/input';
import { ValidationNotEmpty } from '../../../model/validation/validation-not-empty';
import { ErrorPop } from '../error-pop/error-pop';
import { UserAgent } from '../../../utilities/user-agent';
import { Button } from '../button/button';

type Props = {
  touch?: boolean;
  color?: string;
  require?: boolean;
  callbackColor: (v: string) => void;
  title?: string;
  fontAwesomeClass?: string;
  className?: string;
  disabled?: boolean;
  errorPosBottom?: boolean;
}

const cover: globalThis.React.CSSProperties = {
  position: 'fixed',
  top: '0px',
  right: '0px',
  bottom: '0px',
  left: '0px',
};

export const ColorPicker = (props: Props) => {
  const {
    touch: _touch,
    require,
    errorPosBottom,
    color: _color,
    callbackColor,
    title,
    fontAwesomeClass,
    className,
    disabled,
  } = props;

  // eslint-disable-next-line
  const [touch, setTouch] = useState(_touch);
  const [isShow, setIsShow] = useState(false);
  const [color, setColor] = useState<Color>(_color || '');
  const [showColor, setShowColor] = useState<Color>(_color || '');

  const [errorList, setErrorList] = useState<string[]>([]);
  const [changed, setChanged] = useState<boolean>(false);
  const [focus, setFocus] = useState<boolean>(false);
  const error = Boolean((touch || changed) && errorList.length);

  /* Callback */
  const handleChangeColorComplete = useCallback((v: ColorResult) => {
    setShowColor(v.hex);
    callbackColor(v.hex);
    setTouch(!v.hex);
  }, [callbackColor]);

  const handleChangeColor = useCallback((v: ColorResult) => {
    setColor(v.hex);
  }, []);

  const onClickBody = useCallback(() => {
    setIsShow(false);
    setTouch(!color);
    setChanged(true);
    setFocus(false);
  }, []);

  const handleOnBlur = useCallback(() => {
    setFocus(false);
    setTouch(!color);
    setChanged(true);
    setErrorList(
      require ? ValidationNotEmpty
        .filter((v) => !v.run(color ? String(color) : ''))
        .map((v) => v.errorMessage)
        : [],
    );
  }, [isShow, color, errorList]);

  const handleColorDelete = useCallback(() => {
    setColor('');
    setShowColor('');
    callbackColor('');
    setTouch(true);
  }, [color]);

  useEffect(() => {
    if (!_color) return;
    setShowColor(_color);
  }, [_color]);

  return (
    <div style={{ display: 'flex' }} onBlur={handleOnBlur}>
      <Input
        value={showColor}
        require={require}
        touch={touch}
        onClick={() => {
          setChanged(true);
          setFocus(false);
          setIsShow(true);
        }}
        disabled
      />
      <div className="color_box ml_5 mt_5" style={{ backgroundColor: `${showColor}` }} />
      <div className="ml_5">
        <IconButton
          title={title}
          fontAwesomeClass={fontAwesomeClass || 'fas fa-eye-dropper'}
          className={className}
          onClick={() => setIsShow(!isShow)}
          disabled={disabled}
          size="sm"
        />
        <Button
          title={title}
          color="dark"
          className={`${{ className }} ml_5`}
          onClick={handleColorDelete}
          disabled={!disabled && !showColor}
          size="sm"
        >
          クリア
        </Button>
        { isShow ? (
          <div style={{ position: 'absolute', zIndex: 999 }}>
            <div
              style={cover}
              onClick={onClickBody}
            />
            <SketchPicker
              color={color}
              onChangeComplete={handleChangeColorComplete}
              onChange={handleChangeColor}
            />
          </div>
        ) : null }
      </div>
      {error && (UserAgent === 'sp' ? focus : true) && (
        <ErrorPop
          messages={errorList}
          errorPosBottom={errorPosBottom}
        />
      )}
    </div>
  );
};
