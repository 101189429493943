import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cloneDeep, isEqual } from 'lodash';
import '../search-box.pc.scss';
import { Select } from '../../../../ui/select/select';
import { useDidMount } from '../../../../../hooks/life-cycle';
import { EstimateActions } from '../../../../../redux/estimate/estimate.action';
import { MasterActions } from '../../../../../redux/master/master.action';
import { SearchBoxPC } from '../search-box.pc';
import { LeftIconButton } from '../../../../ui/button/left-icon-button/left-icon-button';
import { LeftLabelCheckbox } from '../../../../ui/checkbox/left-label-checkbox/left-label-checkbox';
import { Input } from '../../../../ui/input/input';
import { EstimateSortState } from '../../../../../type/estimate/estimate.type';
import { pulldown } from '../../../../../utilities/pulldown';
import { useAppSelector } from '../../../../../hooks/use-redux';
import { UserAgent } from '../../../../../utilities/user-agent';
import { DatePicker } from '../../../../ui/date-picker/date-picker';
import { DateTermValidation } from '../../../../../model/validation/date/term-date.validation';
import { useDateValidation } from '../../../../../hooks/use-date-validation';

type Props = {
  isLocal?: boolean;
  callback: (v: EstimateSortState) => void;
  isSearch?: boolean;
}

export const EstimateSearchBox = (props: Props) => {
  const {
    // eslint-disable-next-line
    isLocal,
    callback,
    isSearch,
  } = props;

  /* Hooks */
  const dispatch = useDispatch();
  const user = useAppSelector((v) => v.user);
  const sortState = useAppSelector((v) => v.estimate.sort);
  const employeeList = useAppSelector((v) => v.master.employeeList);
  const storeList = useAppSelector((v) => v.master.storeList);
  const isInitSort = useAppSelector((v) => v.estimate.isInitSort);

  /* State */
  const [sort, setSort] = useState({ ...cloneDeep(sortState) });
  const _employeeList = useMemo(() => employeeList.filter((
    v,
  ) => (Number.isNaN(sort.project_store_id) || sort.project_store_id === null
    ? true
    : (v.store_id === sort.project_store_id))),
  [sort.project_store_id, employeeList]);

  const termDate = useDateValidation([
    { start: sort.created_at_start, end: sort.created_at_end },
    { start: sort.updated_at_start, end: sort.updated_at_end },
  ]);

  /* Callback */
  const setState = useCallback((v: Partial<EstimateSortState>) => {
    const data = { ...cloneDeep(sort), ...cloneDeep(v) };
    setSort(data);
  }, [sort, isSearch]);

  const handleClickSearch = useCallback(() => {
    if (termDate.messages.length) {
      termDate.check();
      return;
    }
    if (!isSearch) {
      dispatch(EstimateActions.setSort(sortState));
    }

    callback(cloneDeep(sort));
  }, [callback, sortState, isSearch, termDate, sort]);

  /* Effect */
  useDidMount(() => {
    dispatch(MasterActions.api.store.getList({}));
    dispatch(MasterActions.api.employee.getList({}));
    if (!isSearch && !isInitSort) {
      dispatch(EstimateActions.setIsInitSort(true));
      setState({
        project_store_id: UserAgent === 'sp' ? (sortState.project_store_id || user.store_id) : NaN,
        project_employee_id: !user.view_data || user.view_data.company_id === 1 ? (
          sortState.project_employee_id || user.store_id
        ) : NaN,
      });
    }
  });

  return (
    <SearchBoxPC openCallback={() => {}}>
      <div onKeyPress={(e) => {
        if (e.key === 'Enter') { callback(sortState); }
      }}
      >
        <div className="item_wrap">
          <div className="item_box">
            <div className="item_head">現場名称</div>
            <Input
              value={sort.field_name}
              onChange={(e) => setState({ field_name: e.target.value })}
            />
          </div>
          <div className="item_box">
            <div className="item_head">案件名</div>
            <Input
              className="mr_15"
              value={sort.project_name}
              onChange={(e) => setState({ project_name: e.target.value })}
            />
            <LeftLabelCheckbox
              label="失注案件も含む"
              checked={Boolean(sort.cancel_f)}
              onChange={() => setState({ cancel_f: sort.cancel_f ? 0 : 1 })}
            />
          </div>
          <div className="item_box">
            <div className="item_head">案件営業担当</div>
            <Select
              className="add_text_left"
              label="店舗"
              value={sort?.project_store_id}
              onChange={(v) => {
                setState({
                  project_store_id: Number(v),
                  project_employee_id: Number.isNaN(Number(v))
                  || Number(v) === user.store_id
                    ? user.id
                    : NaN,
                  employee_id: NaN,
                });
              }}
              defaultLabel="全て"
              options={pulldown(storeList)}
            />
            <Select
              className="add_text_left"
              label="案件担当者"
              value={sort?.project_employee_id}
              onChange={(v) => setState({ project_employee_id: Number(v) })}
              defaultLabel="全て"
              options={pulldown(_employeeList)}
            />
            <Select
              className="add_text_left"
              label="見積作業者"
              value={sort?.employee_id}
              onChange={(v) => setState({ employee_id: Number(v) })}
              defaultLabel="全て"
              options={pulldown(_employeeList)}
            />
          </div>
        </div>
        <div className="item_wrap">
          <div className="item_box">
            <div className="item_head">登録者</div>
            <Select
              defaultLabel="全て"
              className="fixed"
              value={sort.created_employee_id}
              onChange={(v) => setState({ created_employee_id: Number(v) })}
              options={pulldown(employeeList)}
            />
          </div>
          <div className="item_box">
            <div className="item_head">登録日</div>
            <DatePicker
              date={sort.created_at_start}
              onChange={(v) => setState({ created_at_start: v })}
              type="YYYY/MM/DD"
              term={DateTermValidation([
                { start: sort.created_at_start, end: sort.created_at_end },
              ])}
            />
            <label className="ml_10">～</label>
            <DatePicker
              date={sort.created_at_end}
              onChange={(v) => setState({ created_at_end: v })}
              type="YYYY/MM/DD"
              term={DateTermValidation([
                { start: sort.created_at_start, end: sort.created_at_end },
              ])}
            />
          </div>
          <div className="item_box">
            <div className="item_head">更新者</div>
            <Select
              defaultLabel="全て"
              className="fixed"
              value={sort.updated_employee_id}
              onChange={(v) => setState({ updated_employee_id: Number(v) })}
              options={pulldown(employeeList)}
            />
          </div>
          <div className="item_box">
            <div className="item_head">更新日</div>
            <DatePicker
              date={sort.updated_at_start}
              onChange={(v) => setState({ updated_at_start: v })}
              type="YYYY/MM/DD"
              term={DateTermValidation([
                { start: sort.updated_at_start, end: sort.updated_at_end },
              ])}
            />
            <label className="ml_10">～</label>
            <DatePicker
              date={sort.updated_at_end}
              onChange={(v) => setState({ updated_at_end: v })}
              type="YYYY/MM/DD"
              term={DateTermValidation([
                { start: sort.updated_at_start, end: sort.updated_at_end },
              ])}
            />
          </div>
          <LeftIconButton
            label="検索"
            fontAwesomeClass="fas fa-search"
            className="btn_search for_detail"
            size="sm"
            color="secondary"
            onClick={handleClickSearch}
          />
        </div>
      </div>
    </SearchBoxPC>
  );
};
