import { cloneDeep, isEqual } from 'lodash';
import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { prefectures, pulldownPrefecture } from '../../../../../collection/prefectures';
import { ProjectCollection } from '../../../../../collection/project/project.collection';
import { ValidationLengthUnder60 } from '../../../../../model/validation/_validation-length-under-60';
import { DialogActions } from '../../../../../redux/dialog/dialog.action';
import { ProjectActions } from '../../../../../redux/project/project.action';
import { State } from '../../../../../redux/root.reducer';
import { TagActions } from '../../../../../redux/tag/tag.action';
import { ProjectEditState } from '../../../../../type/project/project.type';
import { Button } from '../../../../ui/button/button';
import { Required } from '../../../../ui/required/required';
import { Select } from '../../../../ui/select/select';
import { CustomerSearch } from '../../../layout/search-box/customer/customer-search/customer-search';
import { useDidMount } from '../../../../../hooks/life-cycle';
import { LeftLabelInputField } from '../../../../ui/input-field/left-label-input-field/left-label-input-field';
import { MapActions } from '../../../../../redux/map/map.action';
import { Customer, CustomerListType } from '../../../../../type/customer/customer.type';
import { LeftIconButton } from '../../../../ui/button/left-icon-button/left-icon-button';
import { ValidationPostNum1 } from '../../../../../model/validation/validation-post-num1';
import { ValidationPostNum2 } from '../../../../../model/validation/validation-post-num2';
import {
  ValidationLengthUnder255,
  ValidationLengthUnder40,
} from '../../../../../model/validation/validation-length-under';
import { Input } from '../../../../ui/input/input';
import { ProjectAddValidation } from '../../../../../model/validation/project/project.validation';
import { MasterActions } from '../../../../../redux/master/master.action';
import { EditPC } from '../../../../dialogs/edit/edit.pc';
import { ProjectModel } from '../../../../../model/project/project.model';
import { pulldown } from '../../../../../utilities/pulldown';
import { TagCheckBoxex } from '../../../../ui/tag-check-boxex/tag-check-boxex';
import { InputTel } from '../../../../ui/input/input-tel';
import { TagModel } from '../../../../../model/tag/tag';
import { Message } from '../../../../../collection/message.collection';
import { useRegistrationMap } from '../../../../../hooks/common/use-common';
import { SearchPostAddressDialog } from '../../../../ui/search-post-address-dialog/search-post-address-dialog';
import { InputPostNoMulti } from '../../../../ui/input/input-post-no-multi';
import { useAppSelector } from '../../../../../hooks/use-redux';

type Props = {
  customerData?: Customer | CustomerListType;
  callback?: () => void;
}

export const ProjectAdd = (props: Props) => {
  const { customerData, callback } = props;

  /* Hooks */
  const dispatch = useDispatch();
  const user = useAppSelector((v) => v.user);
  const sortState = useSelector((state: State) => state.project.sort, isEqual);
  const partList = useSelector((state: State) => (state.tag.partList), isEqual);
  const {
    employeeList,
    originList,
    storeList,
    projectRankList,
  } = useSelector((state: State) => (state.master), isEqual);
  const registrationMap = useRegistrationMap();

  /* State */
  const [project, setProject] = useState<ProjectEditState>(ProjectCollection.initialEditState);
  const [customerID, setCustomerID] = useState(NaN);
  const [customerName, setCustomerName] = useState('');
  const [customerAddress, setCustomerAddress] = useState('');
  const [touch, setTouch] = useState(false);
  const _employeeList = useMemo(() => employeeList.filter((
    v,
  ) => (Number.isNaN(project.project_store_id) || project.project_store_id === null
    ? true
    : (v.store_id === project.project_store_id))),
  [project.project_store_id, employeeList]);

  /* Callback */
  const setState = useCallback((v: Partial<ProjectEditState>) => {
    setProject({ ...cloneDeep(project), ...cloneDeep(v) });
  }, [setProject, project]);

  const handleClickPost = useCallback(
    () => {
      console.log('project', project);
      if (ProjectAddValidation(project)) {
        dispatch(DialogActions.pushMessage({
          title: '案件情報登録',
          message: Message.postError,
          callback: () => setTouch(true),
        }));
        return;
      }
      /* 保存API */
      const prefe = prefectures[project.field_prefecture].label;
      dispatch(MapActions.api.geocoder({
        isRegist: true,
        param: {
          param: {
            address: `${prefe || ''}${project.field_city}${project.field_address}`,
          },
          noMessage: true,
        },
        callback: (data) => {
          dispatch(ProjectActions.api.project.post({
            param: { data: ProjectModel.postParam({ project, data }) },
            onSuccess: () => callback?.(),
            onError: () => setTouch(true),
          }));
        },
      }));
    },
    [project, sortState, callback],
  );

  const handleClickRegistrationMap = useCallback(() => {
    registrationMap((address) => {
      if (!address) return;
      setState(ProjectModel.registMap(address.components));
    });
  }, [setState]);

  const handleClickCustomerSearch = useCallback(() => {
    dispatch(DialogActions.push({
      title: '顧客検索',
      className: 'max_height_dialog max_width_dialog search_dialog',
      onCloseClick: () => dispatch(DialogActions.clear()),
      element: <CustomerSearch callback={(data) => {
        setState(ProjectModel.setCustomerData(data, user.authority1 ? undefined : user));
        setCustomerID(data.id);
        setCustomerName(data.name || '');
        setCustomerAddress(
          `${data.prefecture_name || NaN}${data.address || ''}${data.building_name || ''}`,
        );
      }}
      />,
    }));
  }, []);

  const searchPostNo = useCallback(() => {
    dispatch(DialogActions.push({
      title: '住所検索',
      element: <SearchPostAddressDialog
        zipcode1={project.field_post_no[0]}
        zipcode2={project.field_post_no[1]}
        jisx0402={project.field_jisx0402_code || ''}
        pref={project.field_prefecture}
        city={project.field_city}
        town={project.field_address}
        calback={(v) => {
          setState({
            field_post_no: [
              v.zipcode1,
              v.zipcode2,
            ],
            field_prefecture: v.pref,
            field_city: v.city,
            field_jisx0402_code: v.jisx0402,
            field_address: v.town,
          });
        }}
      />,
    }));
  },
  [project]);

  const handleClickSearchAddress = useCallback(() => {
    dispatch(MapActions.api.addressSearch({
      param: {
        zipcode1: String(project.field_post_no[0]),
        zipcode2: String(project.field_post_no[1]),
      },
      callback: (address) => setState(ProjectModel.setAddress(address)),
    }));
  },
  [project]);

  /* Effect */
  // マスタ
  useDidMount(() => {
    dispatch(TagActions.api.part.getList());
    dispatch(MasterActions.api.origin.getList({}));
    dispatch(MasterActions.api.store.getList({}));
    dispatch(MasterActions.api.employee.getList({}));
    dispatch(MasterActions.api.projectRank.getList({}));
  });

  useEffect(() => {
    if (project.construction_parts) return;
    if (customerData) {
      setState({
        ...ProjectModel.setCustomerData(customerData),
        construction_parts: new TagModel(partList),
      });
      setCustomerID(customerData.id);
      setCustomerName(customerData.name);
      const place = `〒${customerData.post_no.slice(0, 3)}-${customerData.post_no.slice(3, 7)} ${customerData.prefecture_name}${customerData.city}${customerData.address}${customerData.building_name || ''}`;
      setCustomerAddress(place);
      return;
    }
    dispatch(DialogActions.push({
      title: '顧客検索',
      className: 'max_height_dialog max_width_dialog search_dialog',
      onCloseClick: () => dispatch(DialogActions.clear()),
      element: <CustomerSearch callback={(data) => {
        setState({
          ...ProjectModel.setCustomerData(data, user.authority1 ? undefined : user),
          construction_parts: project.construction_parts || new TagModel(partList),
        });
        setCustomerID(data.id);
        setCustomerName(data.name);
        setCustomerAddress(
          `${data.prefecture_name || ''}${data.address || ''}${data.building_name || ''}`,
        );
      }}
      />,
    }));
  }, []);

  return (
    <EditPC mode="dialog" callback={handleClickPost}>
      <div className="edit_pc_body_inner edit_project">
        <div className="">
          <div className="item_wrap">
            <div className="item_box">
              <div className="item_head">顧客ID<Required /></div>
              <Input
                type="number"
                value={customerID}
                disabled
                require
                touch={touch}
              />
            </div>
            <div className="item_box">
              <div className="item_head">顧客名</div>
              <Input
                value={customerName}
                className="full_width"
                disabled
              />
            </div>
            <div className="item_box">
              {!customerData
              && (
              <LeftIconButton
                label="顧客検索"
                fontAwesomeClass="fas fa-search"
                className="btn_search for_detail"
                size="sm"
                color="secondary"
                onClick={handleClickCustomerSearch}
              />
              )}
            </div>
          </div>
          <div className="item_wrap">
            <div className="item_box large">
              <div className="item_head">顧客住所</div>
              <Input
                value={customerAddress}
                className="large"
                disabled
              />
            </div>
          </div>
          <div className="item_wrap">
            <div className="item_box large">
              <div className="item_head">案件名（工事）<Required /></div>
              <Input
                value={project.name}
                onChange={(e) => { setState({ name: e.target.value }); }}
                className="large"
                require
                touch={touch}
                validationList={ValidationLengthUnder40}
              />
            </div>
          </div>
          <div className="item_wrap">
            <div className="item_box">
              <div className="item_head">案件見込みランク</div>
              <Select
                value={project.project_rank}
                onChange={(v) => setState({ project_rank: Number(v) })}
                defaultLabel="指定無し"
                options={pulldown(projectRankList)}
              />
            </div>
            <div className="item_box">
              <div className="item_head">案件担当</div>
              <Select
                className="add_text_left"
                label="店舗"
                value={project.project_store_id}
                onChange={(v) => setState({
                  project_store_id: Number(v), project_employee_id: NaN,
                })}
                defaultLabel="指定無し"
                options={pulldown(storeList)}
                disabled={!user.authority1}
              />
              <Select
                className="add_text_left"
                label="担当者"
                value={project.project_employee_id}
                onChange={(v) => setState({ project_employee_id: Number(v) })}
                defaultLabel="指定無し"
                options={pulldown(_employeeList)}
                requireLabel
                require
                touch={touch}
                disabled={!user.authority1}
              />
            </div>
          </div>
          <div className="item_wrap">
            <div className="item_box">
              <div className="item_head">発生源</div>
              <Select
                value={project.project_source_id}
                onChange={(v) => setState({ project_source_id: Number(v), source_id: Number(v) })}
                defaultLabel="指定無し"
                options={pulldown(originList)}
              />
            </div>
          </div>
          <div className="frame">
            <div className="item_wrap">
              <div className="item_box large">
                <div className="item_head">現場名称<Required /></div>
                <Input
                  value={project.field_name}
                  onChange={(e) => { setState({ field_name: e.target.value }); }}
                  validationList={ValidationLengthUnder60}
                  className="large"
                  require
                  touch={touch}
                />
              </div>
            </div>
            <div className="item_wrap">
              <div className="item_box">
                <div className="item_head">郵便番号<Required /></div>
                <InputPostNoMulti
                  value={project.field_post_no[0]}
                  touch={touch}
                  require
                  onChange={(v) => {
                    setState({
                      field_post_no: [
                        v,
                        project.field_post_no[1],
                      ],
                    });
                  }}
                  value2={project.field_post_no[1]}
                  touch2={touch}
                  require2
                  onChange2={(v) => setState({
                    field_post_no: [
                      project.field_post_no[0],
                      v,
                    ],
                  })}
                />
                <div className="item_map">
                  <Button
                    size="sm"
                    color="secondary"
                    className="ml_10"
                    onClick={handleClickSearchAddress}
                  >
                    住所入力
                  </Button>

                  <Button
                    size="sm"
                    color="secondary"
                    className="ml_10"
                    onClick={searchPostNo}
                  >
                    詳細住所入力
                  </Button>
                </div>
                <div className="item_map">
                  <Button
                    size="sm"
                    color="secondary"
                    className="ml_10"
                    onClick={handleClickRegistrationMap}
                  >
                    地図から入力
                  </Button>
                </div>
              </div>
            </div>
            <div className="item_wrap">
              <div className="item_box max_width">
                <div className="item_head">住所</div>
                <div className="item_adress">
                  <Select
                    className="add_text_left"
                    label="都道府県"
                    value={project.field_prefecture}
                    onChange={(v) => setState({ field_prefecture: Number(v) })}
                    options={pulldownPrefecture}
                    defaultLabel="指定無し"
                    require
                    touch={touch}
                  />
                  <LeftLabelInputField
                    label="市区町村"
                    value={project.field_city}
                    onChange={(e) => {
                      setState({
                        field_city: e.target.value,
                        field_jisx0402_code: undefined,
                      });
                    }}
                    validationList={ValidationLengthUnder255}
                    className="large"
                    require
                    touch={touch}
                  />
                  <LeftLabelInputField
                    label="地名・番地"
                    value={project.field_address}
                    onChange={(e) => { setState({ field_address: e.target.value }); }}
                    validationList={ValidationLengthUnder255}
                    className="large"
                    require
                    touch={touch}
                  />
                  <LeftLabelInputField
                    label="建物名"
                    value={project.field_building_name}
                    onChange={(e) => {
                      setState({ field_building_name: e.target.value });
                    }}
                    validationList={ValidationLengthUnder255}
                    className="large"
                  />
                </div>
              </div>
            </div>
            <div className="item_wrap">
              <div className="item_box">
                <div className="item_head">現場電話</div>
                <InputTel
                  className="full_width"
                  value={project.field_tel_no}
                  onChange={(v) => setState({ field_tel_no: v })}
                />
              </div>
              <div className="item_box">
                <div className="item_head">現場FAX</div>
                <InputTel
                  className="full_width"
                  value={project.field_fax_no}
                  onChange={(v) => setState({ field_fax_no: v })}
                />
              </div>
            </div>
          </div>
          <div className="item_wrap">
            <TagCheckBoxex
              head="工事部位"
              keys="pTag"
              model={project?.construction_parts}
              onChange={(v) => setState({ construction_parts: v })}
            />
          </div>
        </div>
      </div>
    </EditPC>
  );
};
