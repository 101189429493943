import cloneDeep from 'lodash/cloneDeep';
import { EstimateMeisaiListType } from '../../type/estimate/estimate-meisai.type';
import { EditPriceAreaState } from '../../type/estimate/estimate.type';
import { MathHelper, MathHelper as MH } from '../../utilities/math-helper';
import { MeisaiListXML } from '../../model/estimate/estimate-meisai.type';
import { cancelFigureSeparate } from '../../utilities/format-num';
import { deleteCommaStr } from '../../utilities/delete-comma';

/* 参考
  【見積追加／見積編集】登録情報取得
  EstimateManger.as
  └ getSaveData()

  明細金額再計算
  AddEstimate.mxml
  └ recalc()
*/
export class EstimateCalcModelPC {
  /**
   * 金額計算
   * @param priceList 金額List
   * @returns 金額
   */
  static price(priceList: (number | string)[]) {
    let price = 0;
    priceList.forEach((v) => {
      price = MH.plus(Number(MH.localStrToNum(v)), price);
    });
    return price;
  }

  /**
   * 合計金額計算
   * @param price 金額
   * @param yobiPercent1 現場協力費
   * @param yobiPrice 予備金額
   * @returns 合計金額
   */
  static totalPrice(price: number, genbaPrice: number, yobiPrice: number) {
    return MH.plus(price, genbaPrice, yobiPrice);
  }

  /**
   * 予備・現場金額
   * @param {number} price 金額
   * @param {number} Percent ％
   * @returns 予備現場金額
   */
  static yobiNGenbaPrice(price: number, Percent: number) {
    return MH.rounding(MH.div(MH.times(price, Percent), 100));
  }

  /**
   * 消費税額
   * @param totalPrice 合計金額
   * @param tax 消費税率
   * @returns 消費税額
   */
  static taxPrice(totalPrice: number, tax: number) {
    return MH.rounding(MH.times(totalPrice, tax), 0, 'floor');
  }

  /**
   * 税込合計額
   * @param totalPrice 合計金額
   * @param tax 消費税
   * @returns 税込合計額
   */
  static taxInPrice(totalPrice:number, tax:number) {
    return MH.rounding(MH.plus(totalPrice, tax), 0, 'floor');
  }

  /**
   * 粗利金額
   * @param {number} totalEstimate 見積合計金額
   * @param {number} totalGenka 原価合計金額
   * @returns 粗利金額
   */
  static arariPrice(totalEstimate: number, totalGenka: number) {
    return MH.minus(totalEstimate, totalGenka);
  }

  /**
  * 値引き処理
  *
  * */
  static discountProcess(state: EditPriceAreaState, tax: number):EditPriceAreaState {
    // 税抜き金額 - 調整金額
    const discountTotalAmount = MH.minus(
      state.zeinuki_kin || 0,
      state.tyosei_kin || 0,
    );

    // 値引き後 税抜き見積合計(税抜き見積り + 予備費1 + 予備費2
    const gokei_zeinuki_kin = MH.plus(
      discountTotalAmount,
      state.reserve1_estimate_kin || 0,
      state.reserve2_estimate_kin || 0,
    );

    // 値引き後表示税額
    const estimated_tax = Math.floor(
      MH.times(
        gokei_zeinuki_kin || 0,
        tax,
      ),
    );

    // 税込合計見積金額
    const estimates_the_total_tax = MH.plus(
      gokei_zeinuki_kin || 0,
      estimated_tax || 0,
    );

    return ({
      ...state,
      zeinuki_kin: discountTotalAmount,
      gokei_zeinuki_kin,
      shohizei_kin: estimated_tax,
      gokei_kin: estimates_the_total_tax,
    });
  }

  /**
  * 粗利率の算出(マイナス入力および各部表示対応)
  * @param tempMitsu 見積額
  * @param tempGenka 原価額
  * @param tempArari 粗利額
  *
  * @return ArariRitsu 粗利率：文字列
  **/
  static arariRitsuCal(tempMitsu:number, tempGenka:number, marumeFlag:Boolean = false) {
    const tempArari = MH.minus(tempMitsu, tempGenka);
    const tempRitsu = MH.rounding(MH.times(MH.div(tempArari, tempMitsu), 100), !marumeFlag ? 2 : 1);

    // 0もしくは非数の場合
    if (tempArari === 0 || Number.isNaN(tempRitsu)) {
      return 0;
    } if (!Number.isFinite(tempRitsu)) {
      return 0;
      // FIXME ここどうするか
      // ArariRitsu = '-';
    } if (!Number.isNaN(tempRitsu) && Number.isFinite(tempRitsu) && (tempArari < 0)) {
      // 粗利額がマイナスのときは粗利率はマイナス表示
      return MH.times(tempRitsu);
    } if (!Number.isNaN(tempRitsu) && Number.isFinite(tempRitsu) && (tempArari > 0)) {
      return tempRitsu;
    }
    return 0;
  }

  static arariRitsuCal2(tempMitsu:number, tempGenka:number, marumeFlag:Boolean = false) {
    const tempArari = tempMitsu - tempGenka;
    let tempRitsu = 0;
    if (marumeFlag) {
      tempRitsu = MH.rounding((tempArari / tempMitsu) * 100, 0);
    } else {
      tempRitsu = MH.rounding((tempArari / tempMitsu) * 100, 1); // 一時的な粗利率
    }

    let ArariRitsu:String = '';
    let marume:String = '';

    // 0もしくは非数の場合
    if (tempArari === 0 || Number.isNaN(tempRitsu)) {
      ArariRitsu = '0';
    } if (!Number.isFinite(tempRitsu)) {
      ArariRitsu = '0';
      // ArariRitsu = '-';
    } if (!Number.isNaN(tempRitsu) && Number.isFinite(tempRitsu) && (tempArari < 0)) {
      marume = String(tempRitsu);
      ArariRitsu = String(Number(marume) * (-1));
    } if (!Number.isNaN(tempRitsu) && Number.isFinite(tempRitsu) && (tempArari > 0)) {
      marume = String(tempRitsu);
      ArariRitsu = marume;
    }

    return Number(ArariRitsu);
  }

  /**
   * 見積もりの全体計算
   * @param {EditPriceAreaState} state 編集State
   * @param {EstimateMeisaiListType[]} data 明細リスト
   * @param tax 10% の時は 0.1
   * @returns {EditPriceAreaState} 編集State
   */
  static calc(
    state: EditPriceAreaState,
    data: MeisaiListXML[] = [],
    tax: number,
  ): EditPriceAreaState {
    // - model -
    const model = EstimateCalcModelPC;

    // - 各種値 -
    // -- 見積金額＝リスト内の金額の合計値 --
    const zeinuki_kin = model.price(data.map((v) => v.$.kingaku)) || 0;
    // -- 原価金額＝リスト内の原価金額の合計値 --
    const genka_kin = model.price(data.map((v) => v.$.genka_kei)) || 0;
    // -- 現場協力費(見積)＝見積金額 × 現場協力費 % --
    const reserve1_estimate_kin = model.yobiNGenbaPrice(
      zeinuki_kin, state.reserve1_estimate_percent,
    ) || 0;
    // -- 現場協力費(原価)＝原価金額 × 現場協力費 % --
    const reserve1_genka_kin = model.yobiNGenbaPrice(genka_kin, state.reserve1_genka_percent) || 0;
    // -- 予備原価(見積)＝見積金額 × 予備原価 % --
    const reserve2_estimate_kin = model.yobiNGenbaPrice(
      zeinuki_kin, state.reserve2_estimate_percent,
    ) || 0;
    // -- 予備原価(原価)＝原価金額 × 予備原価 % --
    const reserve2_genka_kin = model.yobiNGenbaPrice(genka_kin, state.reserve2_genka_percent) || 0;
    // -- 見積合計＝見積金額＋現場協力費（見積）＋予備原価（見積）--
    const gokei_zeinuki_kin = model.totalPrice(
      zeinuki_kin, reserve1_estimate_kin, reserve2_estimate_kin,
    ) || 0;
    // -- 原価合計＝原価金額＋現場協力費（原価）＋予備原価（原価） --
    const gokei_zeinuki_genka_kin = model.totalPrice(
      genka_kin, reserve1_genka_kin, reserve2_genka_kin,
    ) || 0;
    // -- 消費税額＝見積合計×消費税％（見積日付によって消費税変更※消費税マスタ参照 --
    const shohizei_kin = model.taxPrice(gokei_zeinuki_kin, tax) || 0;
    // -- 消費税額＝原価合計×消費税％（見積日付によって消費税変更※消費税マスタ参照 --
    const genka_shohizei_kin = model.taxPrice(gokei_zeinuki_genka_kin, tax) || 0;
    // -- 税込合計見積金額＝見積金額＋現場協力費（見積）＋予備原価（見積）＋消費税額 --
    const gokei_kin = model.taxInPrice(gokei_zeinuki_kin, shohizei_kin) || 0;
    // -- 税込合計原価金額＝原価金額＋現場協力費（原価）＋予備原価（原価）＋消費税額 --
    const gokei_genka_kin = model.taxInPrice(
      gokei_zeinuki_genka_kin, genka_shohizei_kin,
    ) || 0;
    // -- 粗利金額＝見積合計金額 - 原価合計金額 --
    const arari_price = 0;
    // -- 粗利率＝((見積金額 - 原価金額) ÷ 見積金額 ) × 100 --
    const arari_percent = 0;

    const _state = model.discountProcess(cloneDeep({
      ...state,
      zeinuki_kin,
      gokei_zeinuki_kin,
      shohizei_kin,
      gokei_kin,
      genka_kin,
      gokei_zeinuki_genka_kin,
      genka_shohizei_kin,
      gokei_genka_kin,
      reserve1_estimate_kin,
      reserve1_genka_kin,
      reserve2_estimate_kin,
      reserve2_genka_kin,
      arari_price,
      arari_percent,
    }), tax);

    return cloneDeep({
      ..._state,
      arari_price: model.arariPrice(
        _state.gokei_kin,
        _state.gokei_genka_kin,
      ) || 0,
      arari_percent: model.arariRitsuCal(
        _state.gokei_kin,
        _state.gokei_genka_kin, true,
      ) || 0,
    });
  }

  /* 受け取った詳細をstateにset */
  static setInfoNum(v: number | undefined | string, decimalLen: number) {
    if (!v) return '0';
    if (!Number(v)) return '0';
    const decimalNum = String(v).split('.')[1];
    if (!decimalNum) return '0';

    let count = 0;
    for (let i = 0; i < decimalNum.length; i += 1) {
      if (decimalNum[i] === '0') {
        count += 1;
      }
    }
    return MathHelper.rounding2Str(Number(v), decimalLen ? decimalLen - count : 0, 'round', true);
  }

  static setNumber(v: string, callback:(val: number)=>string) {
    if (!v) { return '0'; }
    const val = Number(v || 0);
    if (!val) { return '0'; }
    return callback(val);
  }

  static onChangeNum(value: string) {
    const val = value ? deleteCommaStr(value) : '0';
    return Number(val);
  }
}
