import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';
import { RightLabelCheckbox } from '../../../../ui/checkbox/right-label-checkbox/right-label-checkbox';
import { LeftIconButton } from '../../../../ui/button/left-icon-button/left-icon-button';
import { Select } from '../../../../ui/select/select';
import { prefectures } from '../../../../../collection/prefectures';
import { LeftLabelCheckbox } from '../../../../ui/checkbox/left-label-checkbox/left-label-checkbox';
import { TagActions } from '../../../../../redux/tag/tag.action';
import { CustomerListType, CustomerSortState } from '../../../../../type/customer/customer.type';
import { SearchBoxPC } from '../search-box.pc';
import { LeftLabelInputField } from '../../../../ui/input-field/left-label-input-field/left-label-input-field';
import { MasterActions } from '../../../../../redux/master/master.action';
import { CommonCollection } from '../../../../../collection/common/common.collection';
import { Input } from '../../../../ui/input/input';
import { CustomerActions } from '../../../../../redux/customer/customer.action';
import { MapActions } from '../../../../../redux/map/map.action';
import { DatePicker } from '../../../../ui/date-picker/date-picker';
import { pulldown, pulldownRank } from '../../../../../utilities/pulldown';
import { useAppSelector } from '../../../../../hooks/use-redux';
import { TagModel } from '../../../../../model/tag/tag';
import { InputMail } from '../../../../ui/input/input-mail';
import { InputTel } from '../../../../ui/input/input-tel';
import { RightLabelInputField } from '../../../../ui/input-field/right-label-input-field/right-label-input-field';
import { LabelInput } from '../../../../ui/input/label-input';
import { InputNum } from '../../../../ui/input/input-num';
import { MathHelper } from '../../../../../utilities/math-helper';
import { UserAgent } from '../../../../../utilities/user-agent';
import { InputSelect } from '../../../../ui/input-select/input-select';
import { DateTermValidation } from '../../../../../model/validation/date/term-date.validation';
import { DialogActions } from '../../../../../redux/dialog/dialog.action';
import { useDateValidation } from '../../../../../hooks/use-date-validation';

type Props = {
  /** 権限がない時 */
  noAuthority?: boolean;
  initialSort?: CustomerSortState;
  callback?: (v: CustomerListType[]) => void;
  callbackGetList?: (v: CustomerSortState) => void;
  openCallback: (v: boolean) => void;
  salesSet?: boolean;
  isSearch?: boolean;
  type?: 0 | 1 | 2;
  callbackType?: (type: 0 | 1 | 2) => void;
}

export const CustomerSearchBox = (props: Props) => {
  const {
    initialSort, callback, callbackGetList, openCallback,
    noAuthority, salesSet, isSearch, type, callbackType,
  } = props;

  /* Hooks */
  const {
    employeeList,
    areaList,
    storeList,
    originList,
    customerKojiRankList: customerRankList,
    customerRankLastList,
    customerEstimatedRankList,
    buildingCategoryList,
    madoriList,
  } = useAppSelector((v) => (v.master));
  const user = useAppSelector((v) => v.user);
  const { relevantTagList, partList } = useAppSelector((v) => ({
    relevantTagList: v.tag.relevantTagList,
    partList: v.tag.partList,
  }));
  const sortState: CustomerSortState = useAppSelector((v) => (v.customer.sort));
  const [_type, setType] = useState(cloneDeep(type));

  const dispatch = useDispatch();

  /* State */
  const [sort, setSort] = useState(cloneDeep(initialSort || sortState));

  const _employeeList = useMemo(() => employeeList.filter((
    v,
  ) => (Number.isNaN(sort.store_id) || sort.store_id === null
    ? true
    : (v.store_id === sort.store_id))),
  [sort.store_id, employeeList]);

  const termDate = useDateValidation([
    { start: sort.building_age_start, end: sort.building_age_end },
    { start: sort.completion_date_start, end: sort.completion_date_end },
    { start: sort.last_completion_date_start, end: sort.last_completion_date_end },
    { start: sort.wedding_anniversary_start, end: sort.wedding_anniversary_end },
    { start: sort.created_at_start, end: sort.created_at_end },
    { start: sort.updated_at_start, end: sort.updated_at_end },
  ]);

  // DateTermValidation()

  /* Callback */
  const setState = useCallback((v: Partial<CustomerSortState>) => {
    setSort({
      ...cloneDeep(sort),
      ...cloneDeep(v),
    });
  }, [sort, isSearch]);

  const handleClickSearch = useCallback(() => {
    if (termDate.messages.length) {
      termDate.check();
      return;
    }
    if (!isSearch) {
      dispatch(CustomerActions.setSort({
        ...cloneDeep({
          ...sort,
          limit: sortState.limit,
        }),
      }));
    }

    dispatch(MapActions.setGpsStatus('out'));
    if (callbackGetList) {
      callbackGetList(cloneDeep(sort));
    }
    if (_type === undefined) return;
    callbackType?.(_type);
  }, [callbackGetList, sortState, _type, callbackType, sort, isSearch, termDate]);

  /* Effect */
  useEffect(() => {
    dispatch(TagActions.api.part.getList());
    dispatch(TagActions.api.relevantTag.getList());
    dispatch(MasterActions.api.store.getList({}));
    dispatch(MasterActions.api.area.getList({}));
    dispatch(MasterActions.api.origin.getList({}));
    dispatch(MasterActions.api.employee.getList({}));
    dispatch(MasterActions.api.customerKojiRank.getList({}));
    dispatch(MasterActions.api.customerRankLast.getList({}));
    dispatch(MasterActions.api.customerExpectedRank.getList({}));
    dispatch(MasterActions.api.buildingCategory.getList({}));
    dispatch(MasterActions.api.madori.getList({}));
  }, []);

  /* タグ */
  useEffect(() => {
    setState({
      parts: sortState.parts?.data.length
        ? sortState.parts : new TagModel(partList),
      tags: sortState.tags?.data.length
        ? sortState.tags : new TagModel(relevantTagList),
      rank_filter: sort.rank_filter || 1,
      estimated_rank_filter: sort.estimated_rank_filter || 1,
    });
  }, [partList, relevantTagList, sort.rank, sort.estimated_rank]);

  useEffect(() => {
    if (type === undefined) return;
    setState({
      ...cloneDeep(sort),
      ob_flag: type,
    });
  }, [type]);

  return (
    <>
      <SearchBoxPC
        openCallback={openCallback}
        isDetail
      >
        <div
          onKeyPress={(e) => { if (e.key === 'Enter') handleClickSearch(); }}
        >
          <div className="item_wrap">
            <div className="item_box">
              <div className="item_head">営業担当</div>
              <Select
                className="add_text_left"
                label="店舗"
                value={noAuthority ? user.store_id : sort?.store_id}
                onChange={(data) => {
                  if (noAuthority) return;
                  if (Number.isNaN(Number(data))
                  || (employeeList.findIndex((v2) => v2.store_id === data) > -1)) {
                    setState({
                      store_id: Number(data),
                    });
                  } else {
                    setState({
                      store_id: Number(data),
                      employee_id: NaN,
                    });
                  }
                }}
                defaultLabel="全て"
                disabled={noAuthority}
                options={pulldown(storeList)}
              />
              <Select
                className="add_text_left"
                label="担当者"
                defaultLabel="全て"
                value={noAuthority ? user.id : sort?.employee_id}
                onChange={(data) => {
                  if (noAuthority) return;
                  setState({ employee_id: Number(data) });
                }}
                options={pulldown(_employeeList)}
                disabled={noAuthority}
              />
            </div>
          </div>
          <div className="item_wrap">
            <div className="item_box">
              <div className="item_head">顧客名</div>
              <Input
                value={sort?.name}
                onChange={(e) => setState({ name: e.target.value })}
              />
            </div>
            <div className="item_box">
              <div className="item_head">顧客名フリガナ</div>
              <Input
                value={sort?.furigana}
                onChange={(e) => setState({ furigana: e.target.value })}
              />
            </div>
            <div className="item_box">
              <div className="item_head">顧客TEL</div>
              <InputTel
                value={sort?.tel_no}
                onChange={(v) => setState({ tel_no: v })}
              />
            </div>
            {/* <div className="item_box">
              <LeftLabelCheckbox
                label="不備情報のみ"
                checked={sort?.input_comp_flag}
                onChange={() => setState({ input_comp_flag: !sort.input_comp_flag })}
              />
            </div> */}

            <LeftIconButton
              label="検索"
              fontAwesomeClass="fas fa-search"
              className="btn_search for_simple"
              size="sm"
              color="secondary"
              onClick={handleClickSearch}
            />
          </div>

          <div className="search_detail">
            <div className="item_wrap">
              <div className="item_box">
                <div className="item_head">住所</div>
                <LeftLabelInputField
                  className="postal_code_1"
                  label="〒"
                  type="number"
                  value={sort?.post_no[0]}
                  onChange={(e) => {
                    setState({
                      post_no: [
                        e.target.value,
                        sort.post_no[1],
                      ],
                    });
                  }}
                  maxLength={3}
                />
                <LeftLabelInputField
                  className="postal_code_2"
                  label="-"
                  type="number"
                  value={sort?.post_no[1]}
                  onChange={(e) => {
                    setState({

                      post_no: [
                        sort.post_no[0],
                        e.target.value,
                      ],
                    });
                  }}
                  maxLength={4}
                />
              </div>
              <div className="item_box">
                <Select
                  className="add_text_left"
                  value={sort?.prefecture}
                  label="顧客都道府県"
                  onChange={(v) => setState({ prefecture: Number(v) })}
                  defaultLabel="全て"
                  options={
                    prefectures.map((v) => ({
                      text: v.label, value: v.value,
                    }))
                  }
                />
              </div>
              <div className="item_box flex_grow_1">
                <LeftLabelInputField
                  labelPlace="left"
                  className="large"
                  label="顧客住所"
                  value={sort?.address}
                  onChange={(e) => setState({ address: e.target.value })}
                />
              </div>
            </div>
            <div className="item_wrap">
              <div className="item_box">
                <div className="item_head">顧客ランク</div>
                <Select
                  defaultLabel="全て"
                  label=""
                  className="size_adjustment"
                  value={sort?.rank}
                  onChange={(v) => {
                    setState({
                      rank: String(v),
                      rank_filter: !String(v) ? 1 : sort.rank_filter,
                    });
                  }}
                  options={pulldownRank(customerRankList, customerRankLastList)}
                />
                <Select
                  value={sort?.rank_filter}
                  onChange={(v) => setState({ rank_filter: Number(v) })}
                  options={CommonCollection.pullDownFilterList}
                  disabled={!sort?.rank}
                />
              </div>

              <div className="item_box">
                <div className="item_head">顧客見込みランク</div>
                <Select
                  defaultLabel="全て"
                  value={sort?.estimated_rank}
                  onChange={(data) => setState({
                    estimated_rank: Number(data),
                    estimated_rank_filter: !Number(data) ? 1 : sort.estimated_rank_filter,
                  })}
                  options={pulldown(customerEstimatedRankList)}
                  className="size_adjustment"
                />
                <Select
                  value={sort?.estimated_rank_filter}
                  onChange={(data) => setState({ estimated_rank_filter: Number(data) })}
                  options={CommonCollection.pullDownFilterList}
                  disabled={!sort?.estimated_rank}
                />
              </div>

              <div className="item_box">
                <div className="item_head">エリア</div>
                {/* <Select
                  defaultLabel="全て"
                  value={sort?.area_id}
                  onChange={(v) => setState({ area_id: Number(v) })}
                  options={areaList.map((v) => ({ text: v.name, value: v.id }))}
                  className="size_adjustment"
                /> */}
                <InputSelect
                  defaultValue="全て"
                  value={sort?.area_id || NaN}
                  onChange={(v) => setState({ area_id: Number(v) })}
                  options={areaList.map((v) => ({ label: v.name, id: v.id }))}
                  className="react_select"
                />
              </div>

              <div className="item_box">
                <div className="item_head">顧客分類</div>
                <Select
                  value={sort?.ob_flag || NaN}
                  onChange={(data) => setState({ ob_flag: Number(data) as 0 | 1 | 2 })}
                  options={[
                    { text: '全て', value: 0 },
                    { text: 'OB', value: 1 },
                    { text: '見込み', value: 2 },
                  ]}
                />
              </div>
            </div>
            <div className="item_wrap">
              <div className="item_box">
                <div className="item_head">建物分類</div>
                <Select
                  defaultLabel="全て"
                  value={sort?.building_category_id}
                  onChange={(v) => setState({ building_category_id: Number(v) })}
                  options={buildingCategoryList.map((v) => ({ text: v.name, value: v.id }))}
                  className="size_adjustment"
                />
              </div>
              <div className="item_box">
                <div className="item_head">間取り</div>
                <Select
                  value={sort?.madori_id}
                  onChange={(v) => setState({ madori_id: Number(v) })}
                  defaultLabel="全て"
                  options={madoriList.map((v) => ({ text: v.name, value: v.id }))}
                  className="size_adjustment"
                />
              </div>
              <div className="item_box">
                <div className="item_head">築年数</div>
                <DatePicker
                  date={sort?.building_age_start}
                  onChange={(v) => setState({ building_age_start: v })}
                  term={DateTermValidation([
                    { start: sort.building_age_start, end: sort.building_age_end },
                  ])}
                  type="YYYY/MM"
                />
                <label className="ml_10">〜</label>
                <DatePicker
                  date={sort?.building_age_end}
                  term={DateTermValidation([
                    { start: sort.building_age_start, end: sort.building_age_end },
                  ])}
                  onChange={(v) => setState(
                    { building_age_end: v },
                  )}
                  type="YYYY/MM"
                />
              </div>
            </div>
            <div className="item_wrap">
              <div className="item_box">
                <div className="item_head">完工時期</div>
                <DatePicker
                  date={sort.completion_date_start}
                  onChange={(v) => setState({ completion_date_start: v })}
                  type="YYYY/MM"
                  term={DateTermValidation([
                    { start: sort.completion_date_start, end: sort.completion_date_end },
                  ])}
                />
                <label className="ml_10">～</label>
                <DatePicker
                  date={sort.completion_date_end}
                  onChange={(v) => setState({ completion_date_end: v })}
                  type="YYYY/MM"
                  term={DateTermValidation([
                    { start: sort.completion_date_start, end: sort.completion_date_end },
                  ])}
                />
              </div>
              <div className="item_box">
                <div className="item_head">最終完工時期</div>
                <DatePicker
                  date={sort.last_completion_date_start}
                  onChange={(v) => setState({ last_completion_date_start: v })}
                  type="YYYY/MM"
                  term={DateTermValidation([
                    { start: sort.last_completion_date_start, end: sort.last_completion_date_end },
                  ])}
                />
                <label className="ml_10">～</label>
                <DatePicker
                  date={sort.last_completion_date_end}
                  onChange={(v) => setState({ last_completion_date_end: v })}
                  type="YYYY/MM"
                  term={DateTermValidation([
                    { start: sort.last_completion_date_start, end: sort.last_completion_date_end },
                  ])}
                />
              </div>
            </div>
            <div className="item_wrap">
              <div className="item_box">
                <div className="item_head">総工事金額</div>
                <LabelInput pos="Right" label="万円">
                  <InputNum
                    value={sort.total_work_price_min}
                    onChange={(v) => setState({ total_work_price_min: v })}
                    onBlur={(v) => setState({
                      total_work_price_min: v ? Number(v).toLocaleString() : '',
                    })}
                    minus
                    className="size_adjustment width_datepicker"
                  />
                </LabelInput>
                <label className="">〜</label>
                <LabelInput pos="Right" label="万円">
                  <InputNum
                    value={sort.total_work_price_max}
                    onChange={(v) => setState({ total_work_price_max: v })}
                    onBlur={(v) => setState({
                      total_work_price_max: v ? Number(v).toLocaleString() : '',
                    })}
                    minus
                    className="size_adjustment width_datepicker"
                  />
                </LabelInput>
              </div>
              <div className="item_box">
                <div className="item_head">工事回数</div>
                <LabelInput pos="Right" label="回">
                  <InputNum
                    value={sort.work_times_min}
                    onChange={(v) => setState({ work_times_min: v })}
                    onBlur={(v) => setState({
                      work_times_min: v ? Number(v).toLocaleString() : '',
                    })}
                    minus
                    className="size_adjustment width_datepicker"
                  />
                </LabelInput>
                <label className="">〜</label>
                <LabelInput pos="Right" label="回">
                  <InputNum
                    value={sort.work_times_max}
                    onChange={(v) => setState({ work_times_max: v })}
                    onBlur={(v) => setState({
                      work_times_max: v ? Number(v).toLocaleString() : '',
                    })}
                    minus
                    className="size_adjustment width_datepicker"
                  />
                </LabelInput>
              </div>
            </div>
            <div className="item_wrap">
              <div className="item_box">
                <div className="item_head">関連タグ{/* handleChangeSameTag */}</div>
                <div className="flex_wrap_box">
                  {sort?.tags?.data.map((v, i) => (
                    <div key={`rTag${i}`}>
                      <RightLabelCheckbox
                        className=""
                        key={v.id}
                        label={v.label}
                        checked={v.flag}
                        onChange={() => {
                          sort.tags?.changeFlag(v.id);
                          setState({ tags: cloneDeep(sort.tags) });
                        }}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="item_wrap">
              <div className="item_box">
                <div className="item_head">部位{/* handleChangeSameTag */}</div>
                <div className="flex_wrap_box">
                  {/* handleChangeSameTag */}
                  {sort?.parts?.data.map((v, i) => (
                    <div key={`tag${i}`}>
                      <RightLabelCheckbox
                        className=""
                        key={v.id}
                        label={v.label}
                        checked={v.flag}
                        onChange={() => {
                          sort.parts?.changeFlag(v.id);
                          setState({ parts: cloneDeep(sort.parts) });
                        }}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="item_wrap">
              <div className="item_box flex_grow_1">
                <div className="item_head">社内メモ</div>
                <Input
                  labelPlace="left"
                  className="large"
                  label="社内メモ"
                  value={sort.memo}
                  onChange={(e) => setState({ memo: e.target.value })}
                />
              </div>
              <div className="item_box flex_grow_1">
                <div className="item_head">メールアドレス</div>
                <InputMail
                  className="large"
                  value={sort.mail_address}
                  onChange={(v) => setState({ mail_address: v })}
                  noValidation
                />
              </div>
            </div>
            <div className="item_wrap">
              <div className="item_box">
                <div className="item_head">LINE ID</div>
                <Input
                  labelPlace="left"
                  className="fixed"
                  label="LINEID"
                  value={sort.line_id}
                  onChange={(e) => setState({ line_id: e.target.value })}
                />
              </div>
              <div className="item_box">
                <div className="item_head">FaceBook ID</div>
                <Input
                  labelPlace="left"
                  className="fixed"
                  label="FaceBookID"
                  value={sort.facebook_id}
                  onChange={(e) => setState({ facebook_id: e.target.value })}
                />
              </div>
              <div className="item_box">
                <div className="item_head">Twitter ID</div>
                <Input
                  labelPlace="left"
                  className="fixed"
                  label="TwitterID"
                  value={sort.twitter_id}
                  onChange={(e) => setState({ twitter_id: e.target.value })}
                />
              </div>
              <div className="item_box">
                <div className="item_head">Instagram ID</div>
                <Input
                  labelPlace="left"
                  className="fixed"
                  label="InstagramID"
                  value={sort.instagram_id}
                  onChange={(e) => setState({ instagram_id: e.target.value })}
                />
              </div>
            </div>
            <div className="item_wrap">
              <div className="item_box">
                <div className="item_head">発生源</div>
                <Select
                  defaultLabel="全て"
                  value={sort.source_id}
                  onChange={(data) => {
                    setState({ source_id: Number(data) });
                  }}
                  options={pulldown(originList)}
                  className="size_adjustment"
                />
              </div>
              <div className="item_box">
                <div className="item_head">紹介者</div>
                <Input
                  labelPlace="left"
                  className="fixed"
                  label="紹介者"
                  value={sort.introducer}
                  onChange={(e) => setState({ introducer: e.target.value })}
                />
              </div>
              <div className="item_box">
                <div className="item_head">結婚記念日</div>
                <DatePicker
                  date={sort?.wedding_anniversary_start}
                  onChange={(v) => setState(
                    { wedding_anniversary_start: v },
                  )}
                  type="YYYY/MM"
                  term={DateTermValidation([
                    { start: sort.wedding_anniversary_start, end: sort.wedding_anniversary_end },
                  ])}
                />
                <label className="ml_10">〜</label>
                <DatePicker
                  date={sort?.wedding_anniversary_end}
                  onChange={(v) => setState(
                    { wedding_anniversary_end: v },
                  )}
                  type="YYYY/MM"
                  term={DateTermValidation([
                    { start: sort.wedding_anniversary_start, end: sort.wedding_anniversary_end },
                  ])}
                />
              </div>
            </div>
            <div className="item_wrap flex_no_wrap_box">
              <div className="item_box flex_grow_1">
                <div className="item_head">備考</div>
                <Input
                  className="large"
                  label="備考"
                  value={sort?.remarks}
                  onChange={(e) => setState({ remarks: e.target.value })}
                />
              </div>
              {/* <div className="item_box">
                <div className="item_head">不備情報のみ</div>
                <Checkbox
                  checked={sort?.input_comp_flag}
                  onChange={() => setState({ input_comp_flag: !sort.input_comp_flag })}
                />
              </div> */}
            </div>
            <div className="item_wrap">
              <div className="item_box">
                <div className="item_head">登録者</div>
                <Select
                  defaultLabel="全て"
                  className="fixed"
                  value={sort.created_employee_id}
                  onChange={(v) => setState({ created_employee_id: Number(v) })}
                  options={pulldown(employeeList)}
                />
              </div>
              <div className="item_box">
                <div className="item_head">登録日</div>
                <DatePicker
                  date={sort.created_at_start}
                  onChange={(v) => setState({ created_at_start: v })}
                  type="YYYY/MM/DD"
                  term={DateTermValidation([
                    { start: sort.created_at_start, end: sort.created_at_end },
                  ])}
                />
                <label className="ml_10">～</label>
                <DatePicker
                  date={sort.created_at_end}
                  onChange={(v) => setState({ created_at_end: v })}
                  type="YYYY/MM/DD"
                  term={DateTermValidation([
                    { start: sort.created_at_start, end: sort.created_at_end },
                  ])}
                />
              </div>
              <div className="item_box">
                <div className="item_head">更新者</div>
                <Select
                  defaultLabel="全て"
                  className="fixed"
                  value={sort.updated_employee_id}
                  onChange={(v) => setState({ updated_employee_id: Number(v) })}
                  options={pulldown(employeeList)}
                />
              </div>
              <div className="item_box">
                <div className="item_head">更新日</div>
                <DatePicker
                  date={sort.updated_at_start}
                  onChange={(v) => setState({ updated_at_start: v })}
                  type="YYYY/MM/DD"
                  term={DateTermValidation([
                    { start: sort.updated_at_start, end: sort.updated_at_end },
                  ])}
                />
                <label className="ml_10">～</label>
                <DatePicker
                  date={sort.updated_at_end}
                  onChange={(v) => setState({ updated_at_end: v })}
                  type="YYYY/MM/DD"
                  term={DateTermValidation([
                    { start: sort.updated_at_start, end: sort.updated_at_end },
                  ])}
                />
              </div>
              <LeftIconButton
                label="検索"
                fontAwesomeClass="fas fa-search"
                className="btn_search for_detail"
                size="sm"
                color="secondary"
                onClick={handleClickSearch}
              />
            </div>
          </div>
        </div>
      </SearchBoxPC>
    </>
  );
};
