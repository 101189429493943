import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useAppSelector } from './use-redux';
import { ProjectDetailActions } from '../redux/project-detail/project-detail.action';
import { EstimateActions } from '../redux/estimate/estimate.action';
import { EstimateModel } from '../model/estimate/estimate-model';
import { ProjectActions } from '../redux/project/project.action';
import { ProjectModel } from '../model/project/project.model';
import { UserAgent } from '../utilities/user-agent';
import { ProjectCollection } from '../collection/project/project.collection';

export const useOrderPostAfterGetList = () => {
  const location = useLocation();
  const projectId = useAppSelector((v) => v.project.project?.id);
  const user = useAppSelector((v) => v.user);
  const estimateSort = useAppSelector((v) => v.estimate.sort);
  const projectSort = useAppSelector((v) => v.project.sort);
  const estimateDetailSort = useAppSelector((v) => v.projectDetail.estimateSort);

  const dispatch = useDispatch();

  return useCallback(() => {
    /* 見積一覧 */
    if (location.pathname.match('estimate')) {
      dispatch(EstimateActions.api.estimate.getList({
        param: EstimateModel.listParam(estimateSort),
      }));
      return;
    }

    /* 案件詳細内見積リスト */
    if (location.pathname.match('project/detail')) {
      if (!projectId) return;
      dispatch(EstimateActions.api.estimate.getList({
        noLoad: true,
        param: EstimateModel.getListInProject(estimateDetailSort, projectId),
        callback: (v) => dispatch(ProjectDetailActions.setEstimateList(v)),
      }));
      return;
    }

    /* 案件一覧 */
    if (location.pathname.match('project') && location.search.match('list')) {
      const setEmployeeId = (): number | undefined => {
        if (!user.view_data || user.view_data.company_id === 1) {
          return user.id;
        }
        return NaN;
      };
      dispatch(ProjectActions.api.project.getList({
        isLoad: true,
        param: {
          ...ProjectModel.listParam({
            sortState: ProjectCollection.initialSortState(user),
            isSp: UserAgent === 'sp',
          }),
          project_employee_id: setEmployeeId(),
        },
        onSuccess: (v) => {
          if (!v) return;
          dispatch(ProjectActions.setInitSort(user));
          dispatch(ProjectActions.setList(v.data));
          dispatch(ProjectActions.setListCount(v.count));
        },
      }));
    }
  }, [projectId, location, estimateSort, estimateDetailSort, projectSort, user]);
};
