import { cloneDeep, isEqual } from 'lodash';
import {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { DndProvider } from 'react-dnd';
import { TouchBackend } from 'react-dnd-touch-backend';
import { EstimateMeisaiModel } from '../../../../../../model/estimate/estimate-meisai.model';
import { MeisaiListXML } from '../../../../../../model/estimate/estimate-meisai.type';
import { Accordion } from '../../../../../ui/accordion/accodion';
import './estimate-detail-list.scss';
import { EstimateMeisaiAccordionModel } from '../../../../../../model/estimate/estimate-meisai-accordion.model.sp';
import { EstimateDetailCard } from '../../../../../ui/card/estimate/estimate-detail-card';
import { DialogActions } from '../../../../../../redux/dialog/dialog.action';
import { EstimateDetailInfoSP } from '../../../../pages/estimate-detail/info/estimate-detail-info.sp';
import { EstimateEditDialogTitle } from '../../../../pages/estimate/edit/estimate-edit.type.sp';
import { EstimateDetailEditSP } from '../../../../pages/estimate-detail/edit/estimate-detail-edit.sp';
import { CustomerDragLayer } from '../../../../pages/estimate-detail/ghost/customer-drag-layer';
import { ChangePrintNameDialogPC } from '../../../../../pc/pages/estimate/change-print-name-dialog/change-print-name-dialog.pc';

type Props = {
  sortMode: boolean;
  multiMode: boolean;
  model: EstimateMeisaiModel | null;
  callbackModel: (v: EstimateMeisaiModel) => void;
  /** 編集権限 */
  allowEdit?: 0 | 1 | 2;
  dndkey: string;
  callbackSelected: (v: MeisaiListXML[]) => void;
  selected: MeisaiListXML[];
}

export const EstimateDetailListSP = (props: Props) => {
  const {
    sortMode, model, callbackModel, allowEdit, dndkey, multiMode, callbackSelected, selected,
  } = props;

  /* Hooks */
  const dispatch = useDispatch();

  /* State */
  /* 明細アコーディオン */
  const [accordionModel, setAccordionModel] = useState<EstimateMeisaiAccordionModel | null>(null);

  /* どのアイテムをHoverしてるか */
  const [targeDaibunruiIndex, setTargetDaibunruiIndex] = useState(NaN);
  const [targetTyubunruiIndex, setTargetTyubunruiIndex] = useState(NaN);
  const [targetMeisaiIndex, setTargetMeisaiIndex] = useState(NaN);

  const accordionList = useMemo(() => {
    if (!accordionModel || !model) return null;
    const _list = cloneDeep(accordionModel);
    const list = _list.data.daibunruiList.filter((v) => (model.sortPption.daibunrui
      ? Number(v.id) === model.sortPption.daibunrui
      : true));

    list.forEach((v, i) => {
      list[i].tyubunruiList = v.tyubunruiList.filter((v2) => (model.sortPption.tyubunrui
        ? Number(v2.id) === model.sortPption.tyubunrui
        : true));
    });

    return list;
  }, [model, accordionModel]);

  /* Callback */
  const setTarget = (mode?: 'daibunrui' | 'tyubunrui' | 'meisai', index?: number) => {
    setTargetDaibunruiIndex(NaN);
    setTargetTyubunruiIndex(NaN);
    setTargetMeisaiIndex(NaN);
    if (!mode || (!index && index !== 0)) return;
    if (mode === 'daibunrui') setTargetDaibunruiIndex(index);
    if (mode === 'tyubunrui') setTargetTyubunruiIndex(index);
    if (mode === 'meisai') setTargetMeisaiIndex(index);
  };

  /* アコーディオンを開く処理 */
  const onClickOpen = useCallback(() => {
    setTarget();
  }, []);

  /* 明細順番入れ替え */
  const onHover = useCallback((
    mode: 'daibunrui' | 'tyubunrui' | 'meisai',
    dragIndex: number,
    hoverIndex: number,
    daibunrui_index?: number,
    tyubunrui_index?: number,
  ) => {
    if (!accordionModel || allowEdit !== 1) return;
    if (mode === 'daibunrui') setTarget('daibunrui', hoverIndex);
    else if (mode === 'tyubunrui') setTarget('tyubunrui', hoverIndex);
    else setTarget('meisai', hoverIndex);
    accordionModel.sortHover(mode, dragIndex, hoverIndex, daibunrui_index, tyubunrui_index);
    setAccordionModel(cloneDeep(accordionModel));
  }, [accordionModel, allowEdit]);

  /* 明細Drop */
  const onDrop = useCallback(() => {
    if (!accordionModel) return;
    setTarget();
    callbackModel(accordionModel.format());
  }, [accordionModel]);

  /* 明細クリック処理 */
  const handleClickCard = useCallback((data: MeisaiListXML, index: number) => {
    if (!model || sortMode) return;
    setTargetMeisaiIndex(index);
    dispatch(DialogActions.push({
      title: '明細情報',
      element: <EstimateDetailInfoSP
        allowEdit={allowEdit}
        meisai={data}
        isMulti={multiMode}
        onClickEdit={() => {
          dispatch(DialogActions.push({
            title: EstimateEditDialogTitle.update,
            element: <EstimateDetailEditSP
              disabled={allowEdit !== 1}
              data={data}
              callback={(v) => {
                if (v.daibunrui_id !== data.$.daibunrui_id
                  || v.tyubunrui_id !== data.$.tyubunrui_id) {
                  setTargetMeisaiIndex(NaN);
                }
                dispatch(DialogActions.pop());
                dispatch(DialogActions.pop());
                if (!data) {
                  model.add(cloneDeep(v));
                } else {
                  model.edit(cloneDeep(v), data);
                }
                callbackModel(cloneDeep(model));
              }}
              onMasterAdd={(meisais) => {
                dispatch(DialogActions.pop());
                dispatch(DialogActions.pop());
                model.addMaster(meisais);
                callbackModel(cloneDeep(model));
              }}
            />,
          }));
        }}
      />,
    }));
  }, [model, sortMode, allowEdit, callbackModel, multiMode]);

  /* 明細削除 */
  const handleClickRemove = useCallback((data: MeisaiListXML, index: number) => {
    if (!model || allowEdit !== 1) return;
    setTargetMeisaiIndex(index);
    dispatch(DialogActions.pushMessage({
      title: '見積明細削除',
      message: ['削除しますか'],
      isCancel: true,
      callback: () => {
        model.remove([data]);
        callbackModel(cloneDeep(model));
      },
    }));
  }, [model, allowEdit]);

  const handleClickChangePrintName = useCallback((
    dId: string | undefined,
    tId?: string,
  ) => {
    if (!model) return;
    let name = '';
    let printName = '';

    const isTyubunrui = dId && tId;

    // 大分類押した場合
    const list = (model?.list || [])
      .filter(({
        $,
      }) => (
        !isTyubunrui
          ? $.daibunrui_id === dId
          : ($.daibunrui_id === dId
            && $.tyubunrui_id === tId)
      ));
    if (list?.length) {
      name = isTyubunrui ? list[0].$.tyubunrui_name : list[0].$.daibunrui_name;
      printName = isTyubunrui ? list[0].$.tyubunrui_name_out : list[0].$.daibunrui_name_out;
    }
    dispatch(DialogActions.push({
      title: '見積印刷名称設定',
      element: <ChangePrintNameDialogPC
        name={name}
        printName={printName}
        callback={(n, pn) => {
          dispatch(DialogActions.pop());
          if (!list.length) return;
          for (let i = 0; i < (list?.length || 0); i += 1) {
            if (isTyubunrui) {
              list[i].$.tyubunrui_name_out = pn;
            } else {
              list[i].$.daibunrui_name_out = pn;
            }
          }
          if (model) { callbackModel(model); }
        }}
      />,
    }));
  }, [model]);

  const onClickCheck = useCallback((info: MeisaiListXML) => {
    if (selected.findIndex((v) => isEqual(info, v)) > -1) {
      callbackSelected(selected.filter((v) => !isEqual(info, v)));
    } else {
      selected.push(cloneDeep(info));
      callbackSelected(cloneDeep(selected));
    }
  }, [selected]);

  /* アコーディオンのセット */
  useEffect(() => {
    if (!model) {
      setAccordionModel(null);
    } else {
      setAccordionModel(new EstimateMeisaiAccordionModel(model));
    }
  }, [model]);

  /* 並び替え状態の変更処理 */
  useEffect(() => {
    setTarget();
  }, [sortMode]);

  return (
    <DndProvider backend={TouchBackend} key={dndkey}>
      <div className="estimate_detail_list_sp">
        <Accordion
          isOpen
          onClick={onClickOpen}
          label={`全て [${accordionModel?.data.percent ?? '-'}%]`}
          type="daibunrui"
          className="ancestor"
        >
          <>
            {/* 大分類 */}
            {accordionList?.map((v, i) => (
              <Accordion
                key={`daibunrui-${i}`}
                isOpen
                // isOpen={v.isOpen}
                onClick={onClickOpen}
                label={`${v.title} [${v.percent}%]`}
                isDnD={sortMode}
                id={Number(v.id)}
                tab={'　'}
                index={i}
                onHover={(a, b) => onHover('daibunrui', a, b)}
                onDrop={onDrop}
                type="daibunrui"
                isHover={targeDaibunruiIndex === i}
                className="daibunrui"
                onChangePrintName={() => handleClickChangePrintName(v.id)}
                isEdit={allowEdit === 1}
              >
                <>
                  {/* 中分類 */}
                  {v.tyubunruiList?.map((v2, i2) => (
                    <Accordion
                      key={`tyubunrui-${i2}`}
                      isDnD={sortMode}
                      isOpen
                      // isOpen={v2.isOpen}
                      onClick={() => { }}
                      id={Number(v2.id)}
                      tab={'　　'}
                      index={i2}
                      label={`${v2.title} [${v2.percent}%]`}
                      /* FIXME fukada */
                      type="tyubunrui"
                      // type={`tyubunrui${i}`}
                      onHover={(a, b) => onHover('tyubunrui', a, b, i)}
                      isHover={
                        targeDaibunruiIndex === i
                        && targetTyubunruiIndex === i2
                      }
                      className="tyubunrui"
                      onChangePrintName={() => handleClickChangePrintName(v.id, v2.id)}
                      isEdit={allowEdit === 1}
                    >
                      <>
                        {/* 明細 */}
                        {v2.meisaiList.map((v3, i3) => (
                          <EstimateDetailCard
                            allowEdit={allowEdit}
                            key={`meisai-${i3}`}
                            data={v3}
                            className={`${i2 === v2.meisaiList.length - 1 ? 'last_type' : ''}`}
                            onClick={() => handleClickCard(v3, i3)}
                            onRemove={() => handleClickRemove(v3, i3)}
                            isDnd={sortMode}
                            isMultiEdit={multiMode}
                            id={Number(v3.$.id)}
                            index={i3}
                            onHover={(a, b) => onHover('meisai', a, b, i, i2)}
                            onDrop={onDrop}
                            checked={!!selected.find((info) => isEqual(v3, info))}
                            onClickCheck={(info) => onClickCheck(cloneDeep(info))}
                            isActive={
                              targeDaibunruiIndex === i
                              && targetTyubunruiIndex === i2
                              && targetMeisaiIndex === i3
                            }
                          />
                        ))}
                      </>
                    </Accordion>
                  ))}
                </>
              </Accordion>
            ))}
          </>
        </Accordion>
      </div>
      <CustomerDragLayer />
    </DndProvider>
  );
};
