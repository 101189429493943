/**
 * base64からBlobに変換
 * @param base64 base64文字列
 * @returns Blobかnull
 */
export const base64toBlob = (base64: string | undefined, type?:string) => {
  if (!base64) return null;

  const byteString = atob(base64);
  const content = new Uint8Array(byteString.length);
  for (let i = 0, l = byteString.length; i < l; i += 1) {
    content[i] = byteString.charCodeAt(i);
  }
  return new Blob([content], { type: type || undefined });
};
