import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { goBack } from 'connected-react-router';
import { isEqual } from 'lodash';
import { DisplayElements } from '../../../../../../type/display-elements.type';
import { Table } from '../../../../../ui/table/table';
import { RightLabelCheckbox } from '../../../../../ui/checkbox/right-label-checkbox/right-label-checkbox';
import { LeftIconButton } from '../../../../../ui/button/left-icon-button/left-icon-button';
import { Button } from '../../../../../ui/button/button';
import { State } from '../../../../../../redux/root.reducer';
import { Select } from '../../../../../ui/select/select';
import { TableOption } from '../../../../../../type/table.type';
import { MasterIndexType } from '../../../../../../redux/master/api/api-master-index';
import { ApiMasterEmployeeGetListParam } from '../../../../../../redux/master/api/master-employee/api-master-employee.type';
import { MasterGetListParam } from './master-body';
import { InputSelect } from '../../../../../ui/input-select/input-select';
import { useDidMount, useWillUnMount } from '../../../../../../hooks/life-cycle';
import { MasterActions } from '../../../../../../redux/master/master.action';
import { useAppSelector } from '../../../../../../hooks/use-redux';
import { ApiMasterContractedEmployeeGetListParam } from '../../../../../../redux/master/api/master-contracted-employee/api-master-contracted-employee.type';
import { Input } from '../../../../../ui/input/input';
import { TableSort, Limit } from '../../../../../ui/table/table-sort/table-sort';

type Props = {
  header: { key: string; label: string;}[];
  rowDataList: any[];
  list: DisplayElements[][];
  callbackEdit: (v?: any, companyId?: number) => void;
  callbackGetList: (v: ApiMasterContractedEmployeeGetListParam) => void;
  sortIndex?: MasterIndexType;
  defaultOrder: number;
  tableOption?: TableOption;
};

const initContractedCompany = {
  id: -1,
  name: '指定なし',
  mail_address: '',
  password: '',
  tel_no: '',
  post_no: '',
  prefecture: '',
  prefecture_id: NaN,
  city: '',
  address: '',
  building_name: '',
  status: NaN,
  employee_status: NaN,
  accounts: NaN,
  capacity: NaN,
  authority1: 0,
  authority2: 0,
  authority3: 0,
  authority4: 0,
  authority5: 0,
  valid_flag: true,
  total_file_size: NaN,
};

export const MasterBodyForContractedEmployee = (props: Props) => {
  const {
    rowDataList,
    header,
    callbackGetList,
    callbackEdit,
    sortIndex,
    list,
    tableOption,
    defaultOrder,
  } = props;

  const dispatch = useDispatch();
  const { storeList } = useSelector((state: State) => (state.master), isEqual);
  const compList = useAppSelector((v) => v.master.contractedCompanyList);
  const hitCount = useAppSelector((v) => v.master.contractedEmployeeHitCount);
  console.log(hitCount);

  /* State */
  const [selected, setSelected] = useState(NaN);
  const [store, setStore] = useState(NaN);
  const [isMuko, setIsMuko] = useState(false);
  const [storeId, setStoreId] = useState(NaN);
  const [companyId, setCompanyId] = useState(-1);
  const [searchCompanyId, setSearchCompanyId] = useState(-1);
  const [orderSort, setOrderSort] = useState<ApiMasterContractedEmployeeGetListParam>({
    company_id: -1,
    highlow: defaultOrder,
    sort_by: 2,
    limit: 100,
    offset: 0,
  });
  const [isInit, setIsInit] = useState(false);
  const [eMail, setEMail] = useState('');

  /* Callback */
  const changeSortBy = (num: number): number => {
    let returnNum = 1;
    if (num === 0) returnNum = 1;
    if (num === 1) returnNum = 13;
    if (num === 2) returnNum = 2;
    if (num === 3) returnNum = 3;
    if (num === 4) returnNum = 5;
    if (num === 5) returnNum = 12;
    if (num === 6) returnNum = 6;
    if (num === 7) returnNum = 8;
    if (num === 8) returnNum = 9;
    if (num === 9) returnNum = 10;
    return returnNum;
  };

  const getList = useCallback((cId?: number, isSearch?: boolean) => {
    const company_id = cId || companyId;
    if (!company_id) return;
    setSearchCompanyId(company_id);
    console.log(isSearch);

    callbackGetList({
      ...orderSort,
      sort_by: changeSortBy((orderSort.sort_by || 0)),
      offset: isSearch ? 0 : (orderSort.offset ?? 0),
      is_muko: isMuko ? 1 : 0,
      mail_address: eMail,
      company_id: searchCompanyId,
      store_id: storeId,
    });
    if (isSearch) {
      setOrderSort({
        ...orderSort,
        offset: 0,
      });
    }
  },
  [callbackGetList, orderSort, isMuko, storeId, companyId, changeSortBy, searchCompanyId]);

  const handleClickRow = useCallback(
    (row: any) => setSelected(rowDataList.findIndex((v2) => v2.id === row.id)),
    [rowDataList],
  );
  const handleDbClickRow = useCallback(
    (row: any) => callbackEdit(row),
    [callbackEdit],
  );

  /* Effect */
  useEffect(() => {
    if (!companyId) return;
    getList();
  }, [orderSort]);

  useEffect(() => {
    if (!companyId) return;
    setStoreId(NaN);
    dispatch(MasterActions.setStoreList([]));
    dispatch(MasterActions.api.store.getList({
      company_id: companyId,
    }));
  }, [companyId]);

  useEffect(() => {
    if (!compList.length) return;
    if (!isInit) {
      getList(-1);
      setIsInit(true);
    }
  }, [compList]);

  useWillUnMount(() => {
    dispatch(MasterActions.setContractedEmployeeList([]));
    dispatch(MasterActions.setStoreList([]));
  });

  return (
    <div className="main_cnt">
      <div className="search_area only_simple ">
        <div className="item_wrap">
          <div className="item_box">
            <div className="item_head">契約会社</div>
            <InputSelect
              className="add_text_left react_select"
              value={companyId}
              onChange={(v) => {
                setCompanyId(Number(v));
                if (Number(v) === -1) setStoreId(NaN);
              }}
              options={[{ label: '指定無し', id: -1 }].concat(
                compList.map((v) => ({
                  label: v.name, id: v.id,
                })),
              )}
            />
          </div>
          <div className="item_box">
            <div className="item_head">店舗</div>
            <Select
              className="add_text_left"
              value={storeId}
              disabled={companyId === -1}
              onChange={(v) => setStoreId(Number(v))}
              defaultLabel="指定無し"
              options={companyId ? storeList.map((v) => ({
                text: v.name, value: v.id,
              }))
                : []}
            />
          </div>
          <div className="item_box">
            <div className="item_head">メールアドレス</div>
            <Input
              value={eMail}
              onChange={(e) => setEMail(e.target.value)}
              className="width_large"
            />
          </div>
          <div className="item_box">
            <RightLabelCheckbox
              checked={isMuko}
              label="無効情報も含む"
              onChange={() => setIsMuko(!isMuko)}
            />
          </div>
          <LeftIconButton
            label="絞込み"
            fontAwesomeClass="fas fa-filter"
            className="btn_search for_simple"
            size="sm"
            color="secondary"
            onClick={() => getList(undefined, true)}
          />
        </div>
      </div>
      <div className="option_area table_sort">
        <div className="left">
          <div className="count">総件数： &nbsp;<span>{hitCount || 0}</span> 件</div>
        </div>
        <div className="right">
          <LeftIconButton
            label="新規登録"
            size="md"
            disabled={searchCompanyId < 0}
            fontAwesomeClass="fas fa-edit"
            className="btn_search for_detail"
            color="primary"
            onClick={() => callbackEdit(undefined, searchCompanyId)}
          />
        </div>
      </div>
      <section className="result_area list_area">
        <TableSort
          limit={(orderSort.limit ?? 100) as Limit}
          hitCount={hitCount}
          page={orderSort.offset ?? 0}
          callback={(page, limit) => {
            setOrderSort({
              ...orderSort,
              limit,
              offset: page,
            });
          }}
          noAnnotation
          noHitCount
        />
        <div className="inner">
          <div className="table_responsive">
            <Table
              className="table_selectable table_sortable table_sticky table_cell_change"
              header={header.map((v) => v.label)}
              onClickRow={handleClickRow}
              onDbClick={handleDbClickRow}
              selectedTr={[selected]}
              disabledTh={[0]}
              rowDataList={rowDataList}
              isMukoDisp
              sort={{
                index: [],
                onClick: (highlow, sort_by) => setOrderSort({ ...orderSort, highlow, sort_by }),
              }}
              lists={list.map((v:any, i) => ([
                <Button
                  color="secondary"
                  size="md"
                  onClick={(e) => {
                    e.stopPropagation();
                    callbackEdit(rowDataList[i]);
                  }}
                >
                  編集
                </Button>,
                ...v,
              ]))}
              option={tableOption}
            />
          </div>
        </div>
      </section>
      <footer className="btn_area">
        <div className="left_box" />
        <div className="right_box">
          <LeftIconButton
            label="戻る"
            fontAwesomeClass="fas fa-arrow-left"
            size="md"
            color="dark"
            onClick={() => dispatch(goBack())}
          />
        </div>
      </footer>
    </div>
  );
};
