import actionCreatorFactory from 'typescript-fsa';
import { MasterCustomerRankLast } from './api-master-customer-rank-final-complete-date.type';
import { CallbackRequest, RequestBaseParam } from '../../../../type/api.type';
import { RequestBaseCollection } from '../../../../service/api.collection';
import { ApiMasterCustomerRankLastPostParam, ApiMasterCustomerRankLastGetListParam } from '../../../../type/master/master-customer-rank-final-complete-date.type';
import { ApiBase } from '../../../../service/api-base';

export const ActionCreator = actionCreatorFactory('master/api/rank-customer-last');

export const apiMasterCustomerRankLast = {
  get: ActionCreator<CallbackRequest<{id:number}, MasterCustomerRankLast>>('get'),
  post: ActionCreator<CallbackRequest<ApiMasterCustomerRankLastPostParam>>('post'),
  getList: ActionCreator<ApiMasterCustomerRankLastGetListParam>('get/list'),
};

export class ApiMasterCustomerRankLast<T = any> extends ApiBase<T> {
  constructor(param: RequestBaseParam & {id?: number}) {
    const { id } = param;
    super({
      ...RequestBaseCollection(),
      ...param,
      url: `/api/master/rank/customerlast${id ? `/${id}` : ''}`,

    });
  }
}

export class ApiMasterCustomerRankLastGet
  extends ApiMasterCustomerRankLast<MasterCustomerRankLast> {
  constructor(param: {id: number}) {
    const { id } = param;
    super({
      httpMethod: 'GET',
      id,
    });
  }
}

export class ApiMasterCustomerRankLastPost extends ApiMasterCustomerRankLast {
  constructor(param: ApiMasterCustomerRankLastPostParam) {
    const { data, id } = param;
    super({
      httpMethod: 'POST',
      param: data,
      id,
    });
  }
}

export class ApiMasterCustomerRankLastGetList
  extends ApiMasterCustomerRankLast<ApiMasterCustomerRankLastGetListParam> {
  constructor(param: ApiMasterCustomerRankLastGetListParam) {
    super({
      httpMethod: 'GET',
      param,
    });
  }
}
