import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { AuthActions } from './auth.action';

export type AuthState = {
  token: string;
};

const initialState: AuthState = {
  token: '',
};

export const AuthReducer = reducerWithInitialState<AuthState>(initialState)
  .case(AuthActions.resetState, () => initialState)
  .default((state) => state);
