import { cloneDeep } from 'lodash';
import { useState, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Select } from '../../../../../ui/select/select';
import { SearchBoxPC } from '../../../../layout/search-box/search-box.pc';
import { DatePicker } from '../../../../../ui/date-picker/date-picker';
import { CsvActions } from '../../../../../../redux/csv/csv.action';
import { CsvSupportHistorySort } from '../../../../../../type/csv/csv-sort.type';
import { LeftIconButton } from '../../../../../ui/button/left-icon-button/left-icon-button';
import { useDidMount } from '../../../../../../hooks/life-cycle';
import { MasterActions } from '../../../../../../redux/master/master.action';
import { pulldown } from '../../../../../../utilities/pulldown';
import { useAppSelector } from '../../../../../../hooks/use-redux';
import { Radio } from '../../../../../ui/radio/radio';
import { Input } from '../../../../../ui/input/input';
import { useDateValidation } from '../../../../../../hooks/use-date-validation';
import { DateTermValidation } from '../../../../../../model/validation/date/term-date.validation';

type Props = {
  openCallback: (v: boolean) => void;
  callback: (v: CsvSupportHistorySort) => void;
}

export const CsvSearchBoxSupportHistory = (props: Props) => {
  const { callback, openCallback } = props;

  /* Hooks */
  const dispatch = useDispatch();

  const sortState = useAppSelector((v) => v.csv.supportHistorySort);
  const storeList = useAppSelector((v) => v.master.storeList);
  const employeeList = useAppSelector((v) => v.master.employeeList);
  const categoryList = useAppSelector((v) => v.master.supportHistoryList);
  const originList = useAppSelector((v) => (v.master.originList));

  /* State */
  const [sort, setSort] = useState(cloneDeep(sortState));
  const createEmployeeList = useMemo(() => employeeList.filter((
    v,
  ) => (Number.isNaN(sort.created_store_id) || sort.created_store_id === -1
    ? true
    : (v.store_id === sort.created_store_id))), [sort, employeeList]);

  const _employeeList = useMemo(() => employeeList.filter((
    v,
  ) => (Number.isNaN(sort.support_store_id) || sort.support_store_id === null
    ? true
    : (v.store_id === sort.support_store_id))),
  [sort.support_store_id, employeeList]);

  const customerEmployeeList = useMemo(() => employeeList.filter((
    v,
  ) => (Number.isNaN(sort.customer_store_id) || sort.customer_store_id === -1
    ? true
    : (v.store_id === sort.customer_store_id))),
  [sort.customer_store_id, employeeList]);

  const termDate = useDateValidation([
    { start: sort.created_at_start || null, end: sort.created_at_end || null },
    { start: sort.updated_at_start || null, end: sort.updated_at_end || null },
  ]);

  /* Callback */
  const setState = useCallback((v: Partial<CsvSupportHistorySort>) => {
    const data = cloneDeep({
      ...sort,
      ...v,
    });
    setSort(data);
  }, [sort]);

  const handleClickSearch = useCallback(() => {
    if (termDate.messages.length) {
      termDate.check();
      return;
    }
    callback({
      ...cloneDeep(sort),
      limit: sortState.limit,
    });

    dispatch(CsvActions.setSupportHistorySort({
      ...cloneDeep(sort),
      limit: sortState.limit,
    }));
  }, [callback, sort, termDate, sortState]);

  /* Master */
  useDidMount(() => {
    dispatch(MasterActions.api.store.getList({}));
    dispatch(MasterActions.api.employee.getList({}));
    dispatch(MasterActions.api.supportHistory.getList({}));
    dispatch(MasterActions.api.origin.getList({}));
  });

  return (
    <SearchBoxPC
      openCallback={(v) => {
        openCallback(v);
      }}
      defaultOpen
      isDetail
    >
      <div id="csv_support_history">
        <div className="item_wrap">
          <div className="item_box">
            <div className="item_head">登録日</div>
            <DatePicker
              date={sort.created_at_start}
              onChange={(v) => setState({ created_at_start: v })}
              errorPosBottom
              term={DateTermValidation([
                {
                  start: sort.created_at_start,
                  end: sort.created_at_end,
                },
              ])}
            />
            <label className="ml_10">〜</label>
            <DatePicker
              date={sort.created_at_end}
              errorPosBottom
              onChange={(v) => setState({ created_at_end: v })}
              term={DateTermValidation([
                {
                  start: sort.created_at_start,
                  end: sort.created_at_end,
                },
              ])}
            />
          </div>
          <div className="item_box">
            <div className="item_head">登録者</div>
            <Select
              className="add_text_left"
              label="店舗"
              value={sort.created_store_id}
              defaultLabel="全て"
              onChange={(v) => setState({
                created_employee_id: NaN,
                created_store_id: Number(v),
              })}
              options={[{ text: '指定なし', value: -1 }]
                .concat(storeList.map((v) => ({
                  text: v.name, value: v.id,
                })))}
            />
            <Select
              className="add_text_left"
              label="担当者"
              defaultLabel="全て"
              value={sort.created_employee_id}
              onChange={(v) => setState({ created_employee_id: Number(v) })}
              options={[{ text: '指定なし', value: -1 }]
                .concat(createEmployeeList.map((v) => ({
                  text: v.name, value: v.id,
                })))}
            />
          </div>
          <div className="item_box">
            <div className="item_head">媒体</div>
            <Select
              value={sort?.support_id}
              onChange={(data) => setState({ support_id: Number(data) })}
              defaultLabel="全て"
              options={categoryList.map((v) => ({
                text: v.supported, value: v.id,
              }))}
            />
          </div>
          <div className="item_box">
            <div className="item_head">カテゴリ</div>
            <Select
              value={sort?.source_id}
              onChange={(data) => setState({ source_id: Number(data) })}
              defaultLabel="全て"
              options={pulldown(originList)}
            />
          </div>
        </div>
        <div className="item_wrap">
          <div className="item_box">
            <div className="item_head">対応区分</div>
            <Radio
              label="未対応"
              name="kubun"
              value="kubun0"
              checked={sort.support_flag === 0}
              onChange={() => setState({ support_flag: 0 })}
            />
            <Radio
              label="すべて"
              name="kubun"
              value="kubun1"
              checked={sort.support_flag === 2}
              onChange={() => { setState({ support_flag: 2 }); }}
            />

          </div>
          <div className="item_box">
            <div className="item_head">文字列検索</div>
            <Input
              className="mr_10"
              value={sort?.word}
              onChange={(e) => setState({ word: e.target.value })}
            />
            <span className="comment">※検索対象項目：対応履歴名、対応内容</span>
          </div>
          <LeftIconButton
            label="検索"
            fontAwesomeClass="fas fa-search"
            className="btn_search for_simple"
            size="sm"
            color="secondary"
            onClick={handleClickSearch}
          />
        </div>
        <div className="search_detail">
          <div className="item_wrap">
            <div className="item_box">
              <div className="item_head">顧客名</div>
              <Input
                value={sort?.customer_name}
                onChange={(e) => setState({ customer_name: e.target.value })}
              />
            </div>
            <div className="item_box">
              <div className="item_head">顧客担当</div>
              <Select
                className="add_text_left"
                label="店舗"
                value={sort?.customer_store_id}
                onChange={(data) => setState({
                  customer_store_id: Number(data),
                  customer_employee_id: NaN,
                })}
                defaultLabel="全て"
                options={pulldown(storeList)}
              />
              <Select
                className="add_text_left"
                label="担当者"
                value={sort?.customer_employee_id}
                onChange={(data) => setState({ customer_employee_id: Number(data) })}
                defaultLabel="全て"
                options={pulldown(customerEmployeeList)}
              />
            </div>
          </div>
          <div className="item_wrap flex_no_wrap_box">
            <div className="item_box">
              <div className="item_head">対応日</div>
              <DatePicker
                date={sort?.support_dt || null}
                onChange={(v) => setState({ support_dt: v })}
              />
            </div>
            <div className="item_box">
              <div className="item_head">対応担当</div>
              <Select
                className="add_text_left"
                label="店舗"
                value={sort?.support_store_id}
                defaultLabel="全て"
                onChange={(data) => setState({
                  support_store_id: Number(data),
                  support_employee_id: NaN,
                })}
                options={[{ text: '指定なし', value: -1 }]
                  .concat(storeList.map((v) => ({
                    text: v.name, value: v.id,
                  })))}
              />
              <Select
                className="add_text_left"
                label="担当者"
                defaultLabel="全て"
                value={sort?.support_employee_id}
                onChange={(data) => setState({ support_employee_id: Number(data) })}
                options={pulldown(_employeeList)}
              />
            </div>

            {/*
            <div className="item_wrap">
              <div className="item_box">
                <div className="item_head">案件営業担当</div>
                <Select
                  className="add_text_left"
                  label="店舗"
                  value={sort?.support_store_id}
                  onChange={(data) => setState({
                    support_store_id: Number(data),
                    support_employee_id: NaN,
                  })}
                  defaultLabel="全て"
                  options={pulldown(storeList)}
                />
                <Select
                  className="add_text_left"
                  label="担当者"
                  value={sort?.support_employee_id}
                  onChange={(data) => setState({ support_employee_id: Number(data) })}
                  defaultLabel="全て"
                  options={pulldown(_employeeList)}
                />
              </div>
            </div>
*/}
            {/*
            <div className="item_wrap">
              <div className="item_box">
                <div className="item_head">対応日付</div>
                <DatePicker
                  date={sort.support_dt}
                  onChange={(v) => setState({ support_dt: v })}
                />
                <label className="ml_10">〜</label>
                <DatePicker
                  date={sort.support_dt}
                  onChange={(v) => setState({ support_dt: v })}
                />
              </div>
            </div>
*/}
            {/*
            <div className="item_wrap">
              <div className="item_box">
                <div className="item_head">カテゴリ</div>
                {/* <Select
                className="add_text_left"
                value={sort?.category}
                onChange={(data) => setSort({ category: Number(data) })}
                defaultLabel="全て"
                options={supportHistoryList.map((v) => ({
                  text: v.supported, value: v.id,
                }))}
              />
              </div>
*/}
            {/*
              <div className="item_box">
                <div className="item_head">結果</div>
                <Select
                className="add_text_left"
                value={sort?.result}
                onChange={(data) => setSort({  result: Number(data) })}
                defaultLabel="全て"
                options={[
                  { text: '\u3007', value: 1 }, // 〇
                  { text: '\u25b3', value: 2 }, // △
                  { text: '\u2715', value: 3 }, // ✕
                ]}
              />
              </div>
*/}
          </div>
        </div>
        <div className="item_wrap">
          <div className="item_box">
            <div className="item_head">更新者</div>
            <Select
              defaultLabel="全て"
              className="fixed"
              value={sort.updated_employee_id}
              onChange={(v) => setState({ updated_employee_id: Number(v) })}
              options={pulldown(employeeList)}
            />
          </div>
          <div className="item_box">
            <div className="item_head">更新日</div>
            <DatePicker
              date={sort.updated_at_start || null}
              onChange={(v) => setState({ updated_at_start: v })}
              type="YYYY/MM/DD"
              term={DateTermValidation([
                { start: sort.updated_at_start || null, end: sort.updated_at_end || null },
              ])}
            />
            <label className="ml_10">～</label>
            <DatePicker
              date={sort.updated_at_end || null}
              onChange={(v) => setState({ updated_at_end: v })}
              type="YYYY/MM/DD"
              term={DateTermValidation([
                { start: sort.updated_at_start || null, end: sort.updated_at_end || null },
              ])}
            />
          </div>
          <LeftIconButton
            label="検索"
            fontAwesomeClass="fas fa-search"
            className="btn_search for_detail"
            size="sm"
            color="secondary"
            onClick={handleClickSearch}
          />
        </div>
      </div>
    </SearchBoxPC>
  );
};
