import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { CsvActions } from '../../../../../../redux/csv/csv.action';
import { DateFormatter } from '../../../../../../utilities/date-formatter';
import { Limit, TableSort } from '../../../../../ui/table/table-sort/table-sort';
import { CsvListCustomerRank } from '../../list/customer-rank/csv-list-customer-rank';
import { CsvSearchBoxCustomerRank } from '../../search-box/customer-rank/csv-search-box-customer-rank';
import { CsvCustomerRankSort } from '../../../../../../type/csv/csv-sort.type';
import { useAppSelector } from '../../../../../../hooks/use-redux';

export const CsvCustomerRank = () => {
  /* Hooks */
  const dispatch = useDispatch();
  const sortState = useAppSelector((v) => v.csv.customerRankSort);
  const list = useAppSelector((v) => v.csv.customerRankList);
  const hitCount = useAppSelector((v) => (v.csv.customerRankCount));
  /* State */

  /* Callback */
  const getList = useCallback((v?: CsvCustomerRankSort) => {
    const sortData = v || sortState;
    dispatch(CsvActions.api.customerRank.getList({
      ...sortData,
      updated_date_start: DateFormatter.date2str(sortData.updated_date_start, 'YYYYMMDD', '-'),
      updated_date_end: DateFormatter.date2str(sortData.updated_date_end, 'YYYYMMDD', '-'),
    }));
  }, [sortState]);

  const changePagination = useCallback((offset:number, limit:Limit) => {
    dispatch(CsvActions.setCustomerRankList(null));
    dispatch(CsvActions.setCustomerRankSort({ offset, limit }));
    getList({ ...sortState, offset, limit });
  }, [sortState]);

  /* Effect */
  useEffect(() => {
    getList();
  }, [
    sortState.highlow,
    sortState.sort_by,
  ]);

  return (
    <>
      <CsvSearchBoxCustomerRank
        callback={(v) => {
          dispatch(CsvActions.setCustomerRankList(null));
          getList(v);
        }}
      />
      <TableSort
        page={sortState.offset || 0}
        limit={sortState.limit as Limit}
        hitCount={hitCount || 0}
        callback={changePagination}
      />
      <CsvListCustomerRank />
      <TableSort
        className="bottom"
        page={sortState.offset || 0}
        limit={sortState.limit as Limit}
        hitCount={hitCount || 0}
        callback={changePagination}
      />
    </>
  );
};
