import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useMemo, useState } from 'react';
import { MasterActions } from '../../../../../redux/master/master.action';
import { DialogActions } from '../../../../../redux/dialog/dialog.action';
import { State } from '../../../../../redux/root.reducer';
import { MasterCollection } from '../master.collection';
import { EmployeeMasterEditDialog } from '../edit-dialogs/company/emplyee-master-edit-dialog/emplyee-master-edit-dialog';
import { MasterBodyForEmployee } from './body/master-body-for-employee';
import { ApiMasterEmployeeGetListParam } from '../../../../../redux/master/api/master-employee/api-master-employee.type';
import { Button } from '../../../../ui/button/button';
import { AuthActions } from '../../../../../redux/auth/auth.action';
import { useAppSelector } from '../../../../../hooks/use-redux';
import { MasterSortByModel } from './body/lib/master-sort';

export const MasterEmployeeBody = () => {
  /* Hook */
  const list = useSelector((state: State) => state.master.employeeList);
  const user = useAppSelector((v) => v.user);
  const dispatch = useDispatch();

  /* State */
  const [getParam, setGetParam] = useState<ApiMasterEmployeeGetListParam>({});

  /* Callback */
  const getList = useCallback((e: ApiMasterEmployeeGetListParam) => {
    setGetParam(e);
    dispatch(MasterActions.api.employee.getList(
      { ...e, sort_by: MasterSortByModel.employee(e.sort_by) },
    ));
  }, [getParam]);

  // eslint-disable-next-line
  const openEditDialog = useCallback(( id?: number) => {
    dispatch(DialogActions.push({
      title: '社員 編集／追加',
      element: <EmployeeMasterEditDialog id={id} callback={() => getList(getParam)} />,
    }));
  }, [getList]);

  const onClickRetransmission = useCallback((mail_address: string) => {
    dispatch(AuthActions.api.password.sendActivate({
      param: {
        mail_address,
        company_id: user.company_id,
      },
      onSuccess: () => {},
    }));
  }, [user]);

  const tableList = useMemo(() => list.map((v) => {
    const isValid = v.valid_flag ? <i className="far fa-circle" /> : '';

    const invitation = v.status < 2 ? (
      <div><label className="invitation_label">招待中</label>&nbsp;
        <Button
          color="secondary"
          size="md"
          onClick={() => onClickRetransmission(v.mail_address)}
        >
          再送信
        </Button>
      </div>
    ) : isValid;
    return ([
      // v.employee_cd,
      v.store_name,
      // `${v.name || '未設定'}${v.role ? '(管理者)' : ''}`,
      v.name ? <>{v.name}<strong className="strong base_color">{v.role ? '（管理者）' : ''}</strong></> : <><span className="alert">※未設定</span><strong className="strong base_color">{v.role ? '（管理者）' : ''}</strong></>,
      // v.short_name,
      v.furigana || '',
      v.job_title || '',
      // v.sales_target,
      invitation,
      v.authority1 ? <i className="far fa-circle" /> : '',
      v.authority4 ? <i className="far fa-circle" /> : '',
    ]);
  }),
  [list]);

  return (
    <MasterBodyForEmployee
      header={MasterCollection.employeeMasterHeader}
      rowDataList={list}
      list={tableList}
      callbackEdit={openEditDialog}
      callbackGetList={getList}
      defaultOrder={0}
      tableOption={{
        stringWidth: [
          { index: 0, width: 50 }, // 編集
          // { index: 1, width: 50 }, // 店舗名
          // { index: 2, width: 50 }, // 社員_名称
          // { index: 3, width: 50 }, // 社員_略称（削除）
          // { index: 3, width: 50 }, // 社員_フリガナ
          // { index: 4, width: 50 }, // 役職名
          // { index: 5, width: 50 }, // 売上目標（削除）
          // { index: 6, width: 100 }, // 有効フラグ
          // { index: 7, width: 50 }, // 権限1
          // { index: 8, width: 50 }, // 権限2
        ],
        tdAlign: [
          { index: 0, align: 'center' },
          { index: 1, align: 'left' },
          { index: 2, align: 'left' },
          // { index: 3, align: 'left' },
          { index: 3, align: 'left' },
          { index: 4, align: 'center' },
          // { index: 5, align: 'right' },
          { index: 5, align: 'center' },
          { index: 6, align: 'center' },
          { index: 7, align: 'center' },
        ],
      }}
    />
  );
};
