import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTelContact } from '../../../../hooks/support-history/use-support-history';
import { DialogActions } from '../../../../redux/dialog/dialog.action';
import { State } from '../../../../redux/root.reducer';
import { card } from '../../../../selector/card/card-selectors';
import { Family } from '../../../../type/customer/family.type';
import { openTel } from '../../../../utilities/open-tel';
import { SupportHistoryEditSP } from '../../../sp/pages/support-history/edit/support-history-edit.sp';
import { SupportHistoryEditDialogTitle } from '../../../sp/pages/support-history/edit/support-history-edit.type';
import { IconButton } from '../../button/icon-button/icon-button';
import './family-info-card.scss';

type Props = {
  familyInfo: Family,
  isActive: boolean,
  isInDialog?: boolean
}

export const FamilyInfoCard = (props: Props) => {
  /* TODO hori isInDialog 電話アイコンの表示非表示管理が必要なければ削除 */
  /* eslint-disable-next-line */
  const { familyInfo, isActive, isInDialog } = props;
  const customerInfo = useSelector((state: State) => state.customer.customer);
  const dispatch = useDispatch();
  const telContact = useTelContact();

  const handleClickPhone = useCallback(() => {
    telContact({
      type: 'customer',
      data: customerInfo,
      tel: familyInfo.mobile_phone || '',
      isPushDialog: true,
    });
  }, [customerInfo, familyInfo]);

  return (
    <div
      className={`family_info_card ${isActive ? 'active' : ''} ${card}`}
      onClick={() => {}}
    >
      <div className="family_info_card__row row1">
        <div className="row1__col1 card_info_item">
          <div className="card_info_item__head">氏名</div>
          <div className="card_info_item__text">{familyInfo.name || '---'}</div>
        </div>
        <div className="row1__col2 card_info_item">
          <div className="card_info_item__head">続柄</div>
          <div className="card_info_item__text">{familyInfo.relationship || '---'}</div>
        </div>
      </div>
      <div className="family_info_card__row row2">
        <div className="card_info_item item_tel">
          <div className="card_info_item__head">TEL</div>
          <div className="card_info_item__text">{familyInfo.mobile_phone || '---'}</div>
          <IconButton
            fontAwesomeClass="fas fa-phone"
            onClick={handleClickPhone}
            size="md"
            color="secondary"
            disabled={!familyInfo.mobile_phone}
          />
        </div>
      </div>
      <div className="family_info_card__row row_birth">
        <div className="row_birth__col1"><i className="fas fa-birthday-cake" /></div>
        <div className="row_birth__col2">{familyInfo.mobile_phone || '---'}</div>
      </div>
    </div>
  );
};
