import actionCreatorFactory from 'typescript-fsa';
import { User } from '../../type/auth/user.typs';
import { Project, ProjectListType, ProjectSortState } from '../../type/project/project.type';
import { apiProjectId } from './api/id/api-project-id';
import { apiProject } from './api/project/api-project';

const ActionCreator = actionCreatorFactory('project');

export const ProjectActions = {
  api: {
    project: apiProject,
    id: apiProjectId,
  },
  setProject: ActionCreator<Project | null>('set/project'),
  setList: ActionCreator<ProjectListType[] | null>('set/list'),
  setRouteList: ActionCreator<ProjectListType[] | null>('set/route/list'),
  setConcatList: ActionCreator<ProjectListType[] | null>('set/concat/list'),
  setListCount: ActionCreator<number>('set/list/count'),
  setSort: ActionCreator<Partial<ProjectSortState> |null>('set/sort'),
  setDetailSort: ActionCreator<ProjectSortState |null>('set/detail/sort'),
  setInitSort: ActionCreator<User>('set/init/sort'),
  setDetailInitSort: ActionCreator<User>('set/detail/init/sort'),
  resetState: ActionCreator('reset/state'),

};
