import * as lodash from 'lodash';
import { User } from '../../type/auth/user.typs';
import {
  MaintenanceEditState,
  MaintenanceSortState,
} from '../../type/maintenance/maintenance.type';

export class MaintenanceCollection {
  private static _header = [
    '',
    '対応済',
    'メンテナンス日',
    'タイトル',
    '対応日',
    '完工日',
    '顧客名',
    '案件名',
    '案件担当者',
  ];

  /* TODO 初期化時に当てはめる値を対応履歴用に変更 */
  private static _editInitialState(): MaintenanceEditState {
    return lodash.cloneDeep({
      customer_id: NaN,
      project_id: NaN,
      maintenance_date: null,
      supported_date: null,
      title: '',
      detail: '',
      supported_content: '',
      is_fixed: 0,
      is_muko: 0,
      lat: '',
      lng: '',
    });
  }

  static _initialSortState(user?: User): MaintenanceSortState {
    const setDay = new Date();
    setDay.setMonth(setDay.getMonth() + 1);
    return lodash.cloneDeep({
      /** 顧客ID */
      customer_id: NaN,
      /** 顧客名 */
      customer_name: '',
      /** 案件名 */
      project_name: '',
      /** 着工日 */
      construction_date: null,
      /** 完工日 */
      completion_date: null,
      /** 案件担当店舗ID */
      project_store_id: NaN,
      /** 案件担当社員ID */
      project_employee_id: user?.id ?? NaN,
      /** メンテナンス日 */
      maintenance_date: null,
      /** 対応日 */
      supported_date: null,
      /** タイトル */
      title: '',
      /** 緯度 */
      north_lat: '',
      /** 経度 */
      north_lng: '',
      /** 緯度2 */
      south_lat: '',
      /** 経度2 */
      south_lng: '',
      /** 並替基準列
       * (0:メンテナンスID)
       * 1:メンテナンス日オーバー
       * 2:対応済みマーク
       * 3:メンテナンス日
       * 4:タイトル
       * 5:対応日
       * 6:完工日
       * 7:顧客名
       * 8:案件名
       * 9:案件担当者
       */
      sort_by: 2,
      /** 並替方法
       * 0:昇順
       * 1:降順
       */
      highlow: 1,
      /** オフセット */
      offset: 0,
      /** 1ページ内の件数 */
      limit: 100,
      /** 案件ID */
      project_id: NaN,
      /** 対応区分
       * 0:未対応
       * 1:対応済み
       * 2:指定なし（全て）
       */
      supported_kubun: 0,
      /** メンテナンス日_開始 */
      maintenance_date_start: null,
      /** メンテナンス日_終了 */
      maintenance_date_end: setDay,
      /** 完工日_開始 */
      completion_date_start: null,
      /** 完工日_終了 */
      completion_date_end: null,
      /** 無効情報も含む
       * 1:無効情報も含む
       * 0:無効情報を含まない
       */
      is_muko: 0,
      /** 文字列検索 */
      word: '',
      /** キーワード検索 */
      keyword: '',
      /** 登録店舗 */
      created_store_id: NaN,
      /** 登録社員 */
      created_employee_id: NaN,
      /** 更新店舗 */
      updated_store_id: NaN,
      /** 更新社員 */
      updated_employee_id: NaN,
      /** 登録日－期間開始 */
      created_at_start: null,
      /** 登録日－期間終了 */
      created_at_end: null,
      /** 更新日－期間開始 */
      updated_at_start: null,
      /** 更新日－期間終了 */
      updated_at_end: null,
    });
  }

  static get header() {
    return MaintenanceCollection._header;
  }

  static get editInitialState() {
    return MaintenanceCollection._editInitialState();
  }

  static get initialSortState() {
    return MaintenanceCollection._initialSortState();
  }
}
