import { useMemo } from 'react';
import { MasterCustomerRankLast } from '../redux/master/api/master-customer-rank-final-complete-date/api-master-customer-rank-final-complete-date.type';
import { MasterCustomerKojiRank } from '../type/master/master-customer-koji-rank';
import { MasterCustomerRank } from '../type/master/master-customer-rank.type';
import { MasterEmployee } from '../type/master/master-employee.type';

/**
 * 標準プルダウン
 * @param list
 * @returns
 */
export const pulldown = (list: any[]) => useMemo(
  () => list.map((v) => ({
    text: v.name,
    value: v.id,
  })),
  [list],
);

export const pulldownRank = (
  list: MasterCustomerKojiRank[],
  lastList: MasterCustomerRankLast[],
) => useMemo(() => {
  if (!list.length || !lastList.length) return [];
  const res: { text: string; value: string; }[] = [];
  list.forEach((v) => {
    lastList.forEach((v2) => {
      res.push({
        text: v.name + v2.name,
        value: `${v.id}-${v2.id}`,
      });
    });
  });
  return res;
}, [list, lastList]);

export const pulldownCustomerRank = (
  list: MasterCustomerRank[],
) => useMemo(() => {
  if (!list.length) return [];
  const res: { text: string; value: string; }[] = [];
  list.forEach((v) => {
    res.push({
      text: v.name,
      value: v.id,
    });
  });
  return res;
}, [list]);
