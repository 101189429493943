/* eslint-disable no-undef */
import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { cloneDeep } from 'lodash';
import { OrderCollection } from '../../../../collection/order/order.collection';
import { useDidMount } from '../../../../hooks/life-cycle';
import { OrderActions } from '../../../../redux/order/order.action';
import { OrderEditState } from '../../../../type/order/order.type';
import { EditSP } from '../../../dialogs/edit/edit.sp';
import { BottomBorderButton } from '../../../ui/button/bottom-border/bottom-border-button';
import { Button } from '../../../ui/button/button';
import { DatePicker } from '../../../ui/date-picker/date-picker';
import { TopLabelInputField } from '../../../ui/input-field/top-label-input-field/top-label-input-field';
import { Input } from '../../../ui/input/input';
import { } from '../../pages/base.page.sp';
import './order.sp.scss';
import { ValidationLengthUnder500 } from '../../../../model/validation';
import { DialogActions } from '../../../../redux/dialog/dialog.action';
import { EstimateListType } from '../../../../type/estimate/estimate.type';
import { Project, ProjectListType } from '../../../../type/project/project.type';
import { EstimateModel } from '../../../../model/estimate/estimate-model';
import { ProjectSearch } from '../../../pc/layout/search-box/project/project-search/project-search';
import { DateFormatter } from '../../../../utilities/date-formatter';
import { ApiBillGetList } from '../../../../redux/bill/api/bill/api-bill';
import { ApiEstimateGetList } from '../../../../redux/estimate/api/estimate/api-estiamte';
import { ApiProjectGet } from '../../../../redux/project/api/project/api-project';
import { BillListType, Bill } from '../../../../type/bill/bill.type';
import { ResultType } from '../../../../type/api.type';
import { OrderModel } from '../../../../model/order/order.model';
import { OrderValidation } from '../../../../model/validation/order/order.validation';
import { LabelInput } from '../../../ui/input/label-input';
import { InputNum } from '../../../ui/input/input-num';
import { MathHelper } from '../../../../utilities/math-helper';
import { Required } from '../../../ui/required/required';
import { TextArea } from '../../../ui/text-area/text-area';
import { minus0 } from '../../../ui/search-post-address-dialog/search-post-address-dialog';
import { useEstimateAuthority } from '../../../../hooks/use-authority';
import { ValidationDatePicker } from '../../../../model/validation/validation-date-picker';
import { FileActions } from '../../../../redux/file/file.action';
import { FileListType } from '../../../../type/file/file.type';
import { FileListSP } from '../body/list/file/file-list';
import { FileSizeData } from '../../../pc/layout/body/list/file-list/file-list.pc';
import { OrderFileList } from './file-list/order-file-list';
import { Customer } from '../../../../type/customer/customer.type';
import { CustomerActions } from '../../../../redux/customer/customer.action';
import { FileEditSP } from '../../pages/file/edit/file-edit.sp';
import { DialogCssCollection } from '../../../../collection/css/dialog.css.collection';
import { IconButton } from '../../../ui/button/icon-button/icon-button';
import { LeftIconButton } from '../../../ui/button/left-icon-button/left-icon-button';

type OrderSpProps = {
  estimateId?: number,
  project?: Project | ProjectListType;
  callback?: () => void;
  isDirect?: boolean;
  onBack?: () => void;
};

export const OrderSP = (props: OrderSpProps) => {
  const {
    estimateId = NaN, callback, project, isDirect, onBack,
  } = props;
  /* Hooks */
  const dispatch = useDispatch();

  const estimateAuth = useEstimateAuthority();

  /* State */
  const [filedName, setFiledName] = useState('');
  const [projectName, setProjectName] = useState('');
  const headerEle = useRef<HTMLDivElement>(null);
  const [mode, setMode] = useState<0 | 1>(0);
  const [order, setOrder] = useState<OrderEditState>(OrderCollection.editInitialState);
  const [estimateList, setEstimateList] = useState<EstimateListType[]>([]);
  const [stayEstimate, setStayEstimate] = useState<EstimateListType | null>(null);
  const [orderSort, setOrderSort] = useState({ sort_by: 1, highlow: 0 });
  const [selectEstimate, setSelectEstimate] = useState<EstimateListType | null>(null);
  const [touch, setTouch] = useState(false);
  const [billListModel, setBillListModel] = useState(new OrderModel());
  const [projectData, setProjectData] = useState<Project | ProjectListType | null>(null);
  const [isUpdate, setIsUpdate] = useState(false);

  /* ファイル関連 */
  const [customerData, setCustomerData] = useState<Customer | null>(null);

  /* 未割り当て金 */
  const miwariatekin = useMemo(() => billListModel.calcMiwariatekin(order),
    [billListModel, order]);

  /* 請求金額表示 */
  const showPriceList = useMemo<string[]>(() => {
    const list = ['', '', '', '', '', ''];
    if (!billListModel.billList.billing_amount_array.length) return list;
    return billListModel.billList.billing_amount_array.map((v) => (Number.isNaN(v) ? '' : v.toLocaleString()));
  }, [billListModel]);

  /* Callback */
  const setState = useCallback((v: Partial<OrderEditState>) => {
    const data = {
      ...cloneDeep(order),
      ...cloneDeep(v),
    };
    setOrder(data);
  }, [order]);

  /* 保存 */
  const handleClickPost = useCallback(() => {
    const billList = cloneDeep(billListModel);
    if (OrderValidation(order, billList.billList, estimateList, estimateAuth).length) {
      dispatch(DialogActions.pushMessage({
        title: '受注 編集／登録',
        message: OrderValidation(order, billList.billList, estimateList, estimateAuth),
        callback: () => setTouch(true),
      }));
      return;
    }

    dispatch(OrderActions.api.order.post({
      param: OrderModel.postParam({ order, billListModel }),
      onSuccess: callback,
    }));
  }, [order, selectEstimate, billListModel, estimateList, estimateAuth]);

  /* 見積一覧取得 */
  const getEstimateList = useCallback((
    id: number,
  ):Promise<ResultType<EstimateListType>> => new ApiEstimateGetList(EstimateModel
    .listParmInOrder(
      { project_id: id, orderSort },
    )).run(),
  [order, orderSort, project]);

  /* 請求一覧取得 */
  const getBillList = useCallback((
    project_id: number,
  ):Promise<ResultType<BillListType>> => new ApiBillGetList({ project_id }).run(),
  [order, orderSort]);

  /* 案件取得 */
  const getProject = useCallback((
    id: number,
  ):Promise<ResultType<Project>> => new ApiProjectGet({ id }).run(),
  []);

  /* 案件検索 */
  const projectListSearch = useCallback((isNew?: boolean) => {
    dispatch(DialogActions.push({
      title: '案件検索',
      className: 'max_height_dialog max_width_dialog search_dialog',
      onCloseClick: () => { if (isNew) dispatch(DialogActions.pop()); },
      element: <ProjectSearch
        type="order"
        callback={(v) => setProjectData(v)}
        onClose={() => { if (isNew) dispatch(DialogActions.pop()); }}
      />,
    }));
  }, []);

  /* 未割当金を完工金に含める */
  const handleClickInMoney = useCallback(() => {
    setBillListModel(billListModel.mergeMiwariatekinInKankokin(miwariatekin));
  }, [miwariatekin]);

  /* 見積選択変更 */
  const handleClickChangeEstimateSelect = useCallback(() => {
    if (!stayEstimate) return;
    setState(OrderModel.setEstimateData(stayEstimate));
    setSelectEstimate(cloneDeep(stayEstimate));
  }, [stayEstimate]);

  /* 初期処理 */
  useDidMount(() => {
    if (!project) {
      projectListSearch(true);
      return;
    }
    setProjectData(cloneDeep(project));
  });

  /* 案件データに基づく処理 */
  useEffect(() => {
    console.log(projectData?.id);
    if (!projectData) return;
    setFiledName(projectData.field_name);
    setProjectName(projectData.name);

    dispatch(CustomerActions.api.customer.get({
      param: {
        id: projectData?.customer_id,
      },
      callback: (customerInfo) => {
        setCustomerData(cloneDeep(customerInfo));

        (async () => Promise.all([
          getBillList(projectData.id),
          getEstimateList(projectData.id),
          getProject(projectData.id),
        ]))().then(([b, e, p]) => {
          if (!b || !e || !p) return;
          const _bill = b.body.data;
          const _estimate = e.body.data;
          const _project = p.body.data[0];
          const _estimateData = cloneDeep(_estimate.find((v) => v.id === estimateId) || null);
          // if (!_estimateData) return;
          if (_estimate) {
            _estimate.forEach((v) => {
              if (v.order_flag) setIsUpdate(true);
            });
          }
          setBillListModel(new OrderModel(cloneDeep(_bill), _estimateData?.gokei_kin || 0));
          setEstimateList(cloneDeep(_estimate));
          setSelectEstimate(cloneDeep(_estimateData));
          if (estimateAuth) {
            if (_project.contract_date && (_estimate.findIndex((find) => find.saisyu_f) < 0)) {
              dispatch(DialogActions.pushMessage({
                title: 'お知らせ',
                message: ['見積と紐付きのない受注情報があります。', '見積を選択していない状態での更新は行えません。'],
              }));
              setSelectEstimate(null);
              setState({
                ...OrderModel.setProjectData(_project, estimateAuth),
              });
              return;
            }

            setState({
              ...OrderModel.setProjectData(_project),
              ...OrderModel.setEstimateData(_estimateData),
            });
          } else {
            if (_project.contract_date && _estimate.findIndex((find) => find.saisyu_f) > -1) {
              dispatch(DialogActions.pushMessage({
                title: 'お知らせ',
                message: ['見積と紐づいた受注情報があります。', '更新を行うと見積との紐付きが解除されます。'],
              }));
            }

            setState({
              ...OrderModel.setProjectData(_project, !estimateAuth),
            });
          }
        });
        if (!projectData.contract_date) {
          setState({
            contract_date: new Date(),
          });
        }
      },
    }));
    // getBillList(projectData.id);
    // getEstimateList(projectData.id);
    // getProject(projectData.id);
  }, [projectData]);

  const onClickFile = useCallback(() => {
    dispatch(DialogActions.push({
      title: '関連ファイル一覧',
      className: DialogCssCollection.flexHeight,
      element: <OrderFileList
        project={projectData || undefined}
      />,
    }));
  }, [projectData]);

  const addFile = useCallback(() => {
    if (!customerData || !projectData) return;
    dispatch(DialogActions.push({
      title: '関連ファイル登録',
      element: <FileEditSP
        customer={customerData}
        project={projectData}
        isOrder
      />,
    }));
  }, [customerData, projectData]);

  return (
    /* 受注情報 */
    <EditSP
      mode={isUpdate ? 'update' : 'add'}
      callback={handleClickPost}
      isCancel={isDirect}
      cancelLabel="戻る"
      isCancelNoPop={isDirect}
      onClickCancel={onBack}
    >
      <div className="edit_sp_body_inner order_edit_sp">
        <div className="flex_box flex_space_between file_btn_wrap">
          <div>
            <LeftIconButton
              label="ﾌｧｲﾙｱｯﾌﾟﾛｰﾄﾞ"
              fontAwesomeClass="fas fa-cloud-upload-alt"
              className="btn_search for_detail"
              size="sm"
              color="secondary"
              onClick={() => addFile()}
            />
          </div>
          <div>
            <LeftIconButton
              label="関連ﾌｧｲﾙ"
              fontAwesomeClass="fas fa-file"
              className="btn_search for_detail"
              size="sm"
              color="secondary"
              onClick={() => onClickFile()}
            />
          </div>
        </div>
        <div
          id="page_body_header"
          className="page_body_header"
          onClick={(e) => { e.preventDefault(); }}
          ref={headerEle}
          style={{ display: 'flex' }}
        >
          <BottomBorderButton
            label="受注情報"
            onClick={() => {
              setMode(0);
            }}
            selected={!mode}
          />
          <BottomBorderButton
            label="受注金額"
            onClick={() => {
              setMode(1);
            }}
            selected={mode === 1}
          />
        </div>

        {!mode ? (
          <>
            {/* edit_sp_body_inner は各画面の共通用 */}
            <div className="order_edit_sp__body">
              <div className="category_wrap">
                {estimateAuth && (
                <>
                  <div className="item_wrap">
                    <TopLabelInputField
                      label="見積番号"
                      value={selectEstimate?.estimate_no}
                      disabled
                    />
                  </div>
                  <div className="item_wrap ">
                    <LabelInput pos="Top" label="見積金額">
                      <InputNum
                        disabled
                        value={MathHelper.localStr(selectEstimate?.gokei_zeinuki_kin || 0)}
                        onChange={() => {}}
                      />
                    </LabelInput>
                  </div>
                  <div className="item_wrap ">
                    <LabelInput pos="Top" label="見積原価">
                      <InputNum
                        disabled
                        value={MathHelper.localStr(selectEstimate?.gokei_genka_kin || 0)}
                        onChange={() => {}}
                      />
                    </LabelInput>
                  </div>
                  <div className="item_wrap item_date_picker">
                    <div className="item_wrap">
                      <div className="item_label">見積工期</div>
                      <div className="item_body item_schedule">
                        <Input
                          className="item_schedule__form"
                          disabled
                          value={DateFormatter.date2str(selectEstimate?.estimate_kouki_start_dt)}
                        />
                        <div className="item_schedule__tilde">〜</div>
                        <Input
                          className="item_schedule__form"
                          disabled
                          value={DateFormatter.date2str(selectEstimate?.estimate_kouki_end_dt)}
                        />
                      </div>
                    </div>
                  </div>
                </>
                )}
                <div className="item_wrap">
                  <div className="item_label">契約日<Required /></div>
                  <DatePicker
                    touch={touch}
                    date={order?.contract_date || null}
                    onChange={(v) => setState(
                      { contract_date: v },
                    )}
                  />
                </div>
                <div className="item_wrap ">
                  <LabelInput pos="Top" label="受注金額">
                    <InputNum
                      value={MathHelper.localStr(order?.order_price || '')}
                      disabled={estimateAuth}
                      onChange={(v) => {
                        if (estimateAuth) return;
                        setState({ order_price: MathHelper.localStrToNum(v) });
                      }}
                    />
                  </LabelInput>
                </div>
                <div className="item_wrap ">
                  <LabelInput pos="Top" label="受注原価">
                    <InputNum
                      value={MathHelper.localStr(order?.jyutyu_genka || '')}
                      onChange={(v) => {
                        if (estimateAuth) return;
                        setState({ jyutyu_genka: MathHelper.localStrToNum(v) });
                      }}
                      disabled={estimateAuth}
                    />
                  </LabelInput>
                </div>
                <div className="item_wrap">
                  <div className="item_body">
                    <div className="item_label">受注工期</div>
                    <div className="item_body item_schedule">
                      {estimateAuth ? (
                        <>
                          <Input
                            className="item_schedule__form"
                            value={order?.construction_period_start}
                            disabled
                          />
                          <div className="item_schedule__tilde">〜</div>
                          <Input
                            className="item_schedule__form"
                            value={order?.construction_period_end}
                            disabled
                          />
                        </>
                      )
                        : (
                          <>
                            <DatePicker
                              date={DateFormatter.str2date(order?.construction_period_start)}
                              onChange={(v) => setState(
                                { construction_period_start: DateFormatter.date2str(v) },
                              )}
                              validationList={ValidationDatePicker}
                            />
                            <label className="ml_5 mr_5">〜</label>
                            <DatePicker
                              date={DateFormatter.str2date(order?.construction_period_end)}
                              onChange={(v) => setState(
                                { construction_period_end: DateFormatter.date2str(v) },
                              )}
                              validationList={ValidationDatePicker}
                            />

                          </>
                        )}
                    </div>
                  </div>
                  <div className="item_wrap">
                    <div className="item_label">着工予定日<Required /></div>
                    <DatePicker
                      date={order?.construction_start_date || null}
                      onChange={(v) => setState(
                        { construction_start_date: v },
                      )}
                      touch={touch}
                    />
                  </div>
                  <div className="item_wrap">
                    <div className="item_label">完工予定日<Required /></div>
                    <DatePicker
                      date={order?.completion_end_date || null}
                      onChange={(v) => setState(
                        { completion_end_date: v },
                      )}
                      touch={touch}
                    />
                  </div>

                </div>
              </div>

              <div className="category_wrap">
                <div className="item_wrap">
                  <div className="item_label">備考</div>
                  <div className="item_body full_width item_remarks">
                    <TextArea
                      rows={7}
                      value={order?.remarks}
                      onChange={(e) => setState(
                        { remarks: e.target.value },
                      )}
                      validationList={ValidationLengthUnder500}
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
        /* 受注金額 */
          <>
            <div className="order_edit_sp__body">
              <div className="category_wrap">
                <div className="item_wrap ">
                  <LabelInput pos="Top" label="契約金">
                    <InputNum
                      value={showPriceList[0]}
                      onChange={(v) => {
                        const _bill = cloneDeep(billListModel);
                        _bill.billList.billing_amount_array[0] = MathHelper.localStrToNum(v);
                        setBillListModel(_bill);
                      }}
                      minus
                      intLen={11}
                    />
                  </LabelInput>
                </div>
                <div className="item_wrap">
                  <div className="col1">契約金請求日</div>
                  <DatePicker
                    date={
                        billListModel
                          ? DateFormatter.str2date(billListModel.billList.billing_date_array[0])
                          : new Date()
                      }
                    onChange={(v) => {
                      const _bill = cloneDeep(billListModel);
                      _bill.billList.billing_date_array[0] = DateFormatter.date2str(v);
                      setBillListModel(_bill);
                    }}
                  />
                </div>
                <div className="item_wrap">
                  <div className="col1">契約金入金予定日</div>
                  <DatePicker
                    date={
                        billListModel
                          ? DateFormatter.str2date(billListModel.billList.payment_due_date_array[0])
                          : new Date()
                      }
                    onChange={(v) => {
                      const _bill = cloneDeep(billListModel);
                      _bill.billList.payment_due_date_array[0] = DateFormatter.date2str(v);
                      setBillListModel(_bill);
                    }}
                  />
                </div>
              </div>
              <div className="category_wrap">
                <div className="item_wrap ">
                  <LabelInput pos="Top" label="着工金">
                    <InputNum
                      value={showPriceList[1]}
                      onChange={(v) => {
                        const _bill = cloneDeep(billListModel);
                        _bill.billList.billing_amount_array[1] = MathHelper.localStrToNum(v);
                        setBillListModel(_bill);
                      }}
                      minus
                      intLen={11}
                    />
                  </LabelInput>
                </div>
                <div className="item_wrap">
                  <div className="col1">着工金請求日</div>
                  <DatePicker
                    date={
                        billListModel
                          ? DateFormatter.str2date(billListModel.billList.billing_date_array[1])
                          : new Date()
                      }
                    onChange={(v) => {
                      const _bill = cloneDeep(billListModel);
                      _bill.billList.billing_date_array[1] = DateFormatter.date2str(v);
                      setBillListModel(_bill);
                    }}
                  />
                </div>
                <div className="item_wrap">
                  <div className="col1">着工金入金予定日</div>
                  <DatePicker
                    date={
                        billListModel
                          ? DateFormatter.str2date(billListModel.billList.payment_due_date_array[1])
                          : new Date()
                      }
                    onChange={(v) => {
                      const _bill = cloneDeep(billListModel);
                      _bill.billList.payment_due_date_array[1] = DateFormatter.date2str(v);
                      setBillListModel(_bill);
                    }}
                  />
                </div>
              </div>
              <div className="category_wrap">
                <div className="item_wrap ">
                  <LabelInput pos="Top" label="中間金">
                    <InputNum
                      value={showPriceList[2]}
                      onChange={(v) => {
                        const _bill = cloneDeep(billListModel);
                        _bill.billList.billing_amount_array[2] = MathHelper.localStrToNum(v);
                        setBillListModel(_bill);
                      }}
                      minus
                      intLen={11}
                    />
                  </LabelInput>
                </div>
                <div className="item_wrap">
                  <div className="col1">中間金請求日</div>
                  <DatePicker
                    date={
                        billListModel
                          ? DateFormatter.str2date(billListModel.billList.billing_date_array[2])
                          : new Date()
                      }
                    onChange={(v) => {
                      const _bill = cloneDeep(billListModel);
                      _bill.billList.billing_date_array[2] = DateFormatter.date2str(v);
                      setBillListModel(_bill);
                    }}
                  />
                </div>
                <div className="item_wrap">
                  <div className="col1">中間金入金予定日</div>
                  <DatePicker
                    date={
                        billListModel
                          ? DateFormatter.str2date(billListModel.billList.payment_due_date_array[2])
                          : new Date()
                      }
                    onChange={(v) => {
                      const _bill = cloneDeep(billListModel);
                      _bill.billList.payment_due_date_array[2] = DateFormatter.date2str(v);
                      setBillListModel(_bill);
                    }}
                  />
                </div>
              </div>
              <div className="category_wrap">
                <div className="item_wrap ">
                  <LabelInput pos="Top" label="中間金">
                    <InputNum
                      value={showPriceList[3]}
                      onChange={(v) => {
                        const _bill = cloneDeep(billListModel);
                        _bill.billList.billing_amount_array[3] = MathHelper.localStrToNum(v);
                        setBillListModel(_bill);
                      }}
                      minus
                      intLen={11}
                    />
                  </LabelInput>
                </div>
                <div className="item_wrap">
                  <div className="col1">中間金請求日</div>
                  <DatePicker
                    date={
                        billListModel
                          ? DateFormatter.str2date(billListModel.billList.billing_date_array[3])
                          : new Date()
                      }
                    onChange={(v) => {
                      const _bill = cloneDeep(billListModel);
                      _bill.billList.billing_date_array[3] = DateFormatter.date2str(v);
                      setBillListModel(_bill);
                    }}
                  />
                </div>
                <div className="item_wrap">
                  <div className="col1">中間金入金予定日</div>
                  <DatePicker
                    date={
                        billListModel
                          ? DateFormatter.str2date(billListModel.billList.payment_due_date_array[3])
                          : new Date()
                      }
                    onChange={(v) => {
                      const _bill = cloneDeep(billListModel);
                      _bill.billList.payment_due_date_array[3] = DateFormatter.date2str(v);
                      setBillListModel(_bill);
                    }}
                  />
                </div>
              </div>
              <div className="category_wrap">
                <div className="item_wrap ">
                  <LabelInput pos="Top" label="完工金">
                    <InputNum
                      value={showPriceList[4]}
                      onChange={(v) => {
                        const _bill = cloneDeep(billListModel);
                        _bill.billList.billing_amount_array[4] = MathHelper.localStrToNum(v);
                        setBillListModel(_bill);
                      }}
                      minus
                      intLen={11}
                    />
                  </LabelInput>
                </div>
                <div className="item_wrap">
                  <div className="col1">完工金請求日</div>
                  <DatePicker
                    date={
                        billListModel
                          ? DateFormatter.str2date(billListModel.billList.billing_date_array[4])
                          : new Date()
                      }
                    onChange={(v) => {
                      const _bill = cloneDeep(billListModel);
                      _bill.billList.billing_date_array[4] = DateFormatter.date2str(v);
                      setBillListModel(_bill);
                    }}
                  />
                </div>
                <div className="item_wrap">
                  <div className="col1">完工金入金予定日</div>
                  <DatePicker
                    date={
                        billListModel
                          ? DateFormatter.str2date(billListModel.billList.payment_due_date_array[4])
                          : new Date()
                      }
                    onChange={(v) => {
                      const _bill = cloneDeep(billListModel);
                      _bill.billList.payment_due_date_array[4] = DateFormatter.date2str(v);
                      setBillListModel(_bill);
                    }}
                  />
                </div>
                <div className="item_wrap ">
                  <LabelInput pos="Top" label="未割当金">
                    <InputNum
                      value={MathHelper.localStr(miwariatekin)}
                      minus
                      onChange={() => {}}
                      disabled
                    />
                  </LabelInput>
                </div>
                <div className="item_wrap item_map">
                  <Button
                    size="md"
                    color="secondary"
                    onClick={handleClickInMoney}
                  >
                    未割当金を完工金に含める
                  </Button>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </EditSP>
  );
};
