import { put, takeEvery } from 'redux-saga/effects';
import * as lodash from 'lodash';
import { cloneDeep } from 'lodash';
import { SupportHistoryActions } from './support-history.action';
import { Store } from '../store';
import {
  getListHandle, postHandle, getHandle, getError,
} from '../root.saga';
import {
  ApiSupportHistoryGet, ApiSupportHistoryGetList, ApiSupportHistoryPost,
} from './api/api-support-history/api-support-history';
import { ApiSupportHistory } from '../root.type';
import { DialogActions } from '../dialog/dialog.action';
import { SystemActions } from '../system/system.action';
import { ApiUser } from '../user/api/api-user';
import { AuthActions } from '../auth/auth.action';
import { ResponseHeader } from '../../type/api.type';
import { ApiBase } from '../../service/api-base';

function* trySupportHistoryGet(action: ReturnType<
  typeof SupportHistoryActions.api.supportHistory.get>) {
  const { param, callback } = action.payload;
  if (!param.id) {
    yield getError('対応履歴');
    return;
  }
  yield getHandle<ApiSupportHistory.SupportHistory.Response.Get>({
    api: new ApiSupportHistoryGet(param),
    onSuccess: (res) => {
      if (res) {
        // if (res.image) {
        //   const byteString = atob(res.image as string);
        //   const content = new Uint8Array(byteString.length);
        //   for (let i = 0, l = byteString.length; i < l; i += 1) {
        //     content[i] = byteString.charCodeAt(i);
        //   }
        //   const file = new File([content], res.image_name);
        //   res.image = file;
        // }
        if (callback) {
          callback(lodash.cloneDeep(res));
          return;
        }
        Store.dispatch(SupportHistoryActions.setSupportHistory(res));
      }
    },
    * onError() {
      yield getError('対応履歴');
    },
  });
}

function* trySupportHistoryGetList(action: ReturnType<
  typeof SupportHistoryActions.api.supportHistory.getList>) {
  const { param, onSuccess, noLoad } = action.payload;
  yield getListHandle<ApiSupportHistory.SupportHistory.Response.List>({
    api: new ApiSupportHistoryGetList(param),
    noDelay: true,
    noLoad: true,
    onSuccess: (res, hitCount) => {
      if (onSuccess) {
        onSuccess(cloneDeep(res), hitCount);
        return;
      }
      Store.dispatch(SupportHistoryActions.setList(res));
    },
  });
}

function* trySupportHistoryPost(action: ReturnType<
  typeof SupportHistoryActions.api.supportHistory.post>) {
  const {
    param, onSuccess, onError, noMessage, update,
  } = action.payload;
  const api = new ApiSupportHistoryPost(param);
  yield put(SystemActions.isLoading(true));

  try {
    const me = new ApiUser();
    yield me.run();
    yield put(AuthActions.setToken(me.token));
    api.header = {
      ...api.header,
      'X-CSRF-TOKEN': me.token,
    };
    const result: {
      header: ResponseHeader;
      body: {
        data: {
          internal_id: number;
          id: number;
        }
      }
     } = yield api.run();
    if (ApiBase.isSuccess(result)) {
      yield put(SystemActions.isLoading(false));
      if (!noMessage) {
        yield put(DialogActions.pushMessage({
          title: `対応履歴${update ? '更新' : '登録'}完了`,
          message: [`${update ? '更新' : '登録'}が完了しました。`],
          callback: () => { if (result.body) onSuccess?.(result.body.data); },
          callbackClose: () => { if (result.body) onSuccess?.(result.body.data); },
        }));
      } else if (result.body) onSuccess?.(result.body.data);
    } else {
      yield put(SystemActions.isLoading(false));
      yield put(DialogActions.pushMessage({
        title: '',
        message: result.header.messages || [],
        callback: onError,
      }));
    }
  } catch (error) {
    yield put(SystemActions.connectionError({}));
  }
  yield put(SystemActions.isLoading(false));
}

// function* trySupportHistoryDelete(action: ReturnType<
//   typeof SupportHistoryActions.api.supportHistory.delete>) {
//   const api = new ApiSupportHistoryDelete(action.payload);
//   yield postHandle({
//     api,
//     onSuccess: () => {
//       Store.dispatch(SupportHistoryActions.api.supportHistory.getList({}));
//     },
//   });
// }

export function* SupportHistorySaga() {
  yield takeEvery(SupportHistoryActions.api.supportHistory.get, trySupportHistoryGet);
  yield takeEvery(SupportHistoryActions.api.supportHistory.post, trySupportHistoryPost);
  yield takeEvery(SupportHistoryActions.api.supportHistory.getList, trySupportHistoryGetList);
  // yield takeEvery(SupportHistoryActions.api.supportHistory.delete, trySupportHistoryDelete);
}
