import { goBack, push, replace } from 'connected-react-router';
import { isEqual } from 'lodash';
import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  SemanticShorthandItem, Tab, TabPaneProps,
} from 'semantic-ui-react';
import { useWillUnMount, useDidMount } from '../../../../hooks/life-cycle';
import { DialogActions } from '../../../../redux/dialog/dialog.action';
import { FileActions } from '../../../../redux/file/file.action';
import { MaintenanceActions } from '../../../../redux/maintenance/maintenance.action';
import { ProjectActions } from '../../../../redux/project/project.action';
import { State } from '../../../../redux/root.reducer';
import { SupportHistoryActions } from '../../../../redux/support-history/support-history.action';
import { RoutingPath } from '../../../../routes/routing-pass';
import { Button } from '../../../ui/button/button';
import { LeftIconButton } from '../../../ui/button/left-icon-button/left-icon-button';
import { ProjectInfoTabContentsSP } from '../../../ui/tab-contents/project/info/project-info-tab-contents.sp';
import '../../../ui/tab/tab.scss';
import { EstimateListSP } from '../../layout/body/list/estimate/estimate-list';
import { FileListSP } from '../../layout/body/list/file/file-list';
import { MaintenanceListSP } from '../../layout/body/list/maintenance/maintenance-list';
import { SupportHistoryListSP } from '../../layout/body/list/support-history/support-history-list';
import { DetailPageFooterSP } from '../../layout/detail-page-footer/detail-page-footer.sp';
import { SearchBoxDialogTitle } from '../../layout/search-box/search-box.type.sp';
import { BasePageSP } from '../base.page.sp';
import { RefindEstimateSP } from '../common-dialog/refind/refind-estimate.sp';
import { RefindFileSP } from '../common-dialog/refind/refind-file.sp';
import { RefindMaintenanceSP } from '../common-dialog/refind/refind-maintenance.sp';
import { RefindSupportHistorySP } from '../common-dialog/refind/refind-support-history.sp';
import { reFindDialogTitle } from '../common-dialog/refind/refind.sp';
import { EstimateEditSP } from '../estimate/edit/estimate-edit.sp';
import { MaintenanceEditSP } from '../maintenance/edit/maintenance-edit.sp';
import { MaintenanceEditDialogTitle } from '../maintenance/edit/maintenance-edit.type';
import { ProjectEditSP } from '../project/edit/project-edit.sp';
import { ProjectEditDialogTitle } from '../project/edit/project-edit.type.sp';
import { ProjectSearchBoxSP } from '../project/search-box/project-search-box.sp';
import './project-detail.sp.scss';
import { ProjectDetailActions } from '../../../../redux/project-detail/project-detail.action';
import { EstimateActions } from '../../../../redux/estimate/estimate.action';
import { SupportHistorySortState } from '../../../../type/support-history/support-history.type';
import { FileSortState } from '../../../../type/file/file.type';
import { MaintenanceSortState } from '../../../../type/maintenance/maintenance.type';
import { useQuery } from '../../../../hooks/use-query';
import { EstimateModel } from '../../../../model/estimate/estimate-model';
import { MaintenanceModel } from '../../../../model/maintenance/maintenance.model';
import { FileModel } from '../../../../model/file/file.model';
import { SupportHistoryModel } from '../../../../model/support-history/support-history.model';
import { useEditAuthProject, useEstimateAuthority } from '../../../../hooks/use-authority';
import { EstimateSortStateInProject } from '../../../../redux/estimate/api/estimate/api-estimate.type';
import { useAppSelector } from '../../../../hooks/use-redux';

const getActiveIndex = (path: string, auth: boolean) => {
  if (auth) {
    switch (path) {
      case 'project':
        return 0;
      case 'estimate':
        return 1;
      case 'support-history':
        return 2;
      case 'file':
        return 3;
      case 'maintenance':
        return 4;
      default:
        return 0;
    }
  } else {
    switch (path) {
      case 'project':
        return 0;
      case 'support-history':
        return 1;
      case 'file':
        return 2;
      case 'maintenance':
        return 3;
      default:
        return 0;
    }
  }
};

export const ProjectDetailSP = () => {
  /* Hooks */
  const dispatch = useDispatch();
  const query = useQuery('p');
  const estimateAuth = useEstimateAuthority();
  const { projectID } = useParams<{ projectID: string; }>();
  const projectInfo = useSelector((state: State) => state.project.project);
  const stopLoad = useAppSelector((v) => v.projectDetail.stopLoadFile);

  const {
    estimateList, estimateSort,
    supportSort, supportList,
    fileSort, fileList,
    maintenanceSort, maintenanceList,
  } = useSelector((state: State) => state.projectDetail, isEqual);

  /* State */
  const [activeIndex, setActiveIndex] = useState(0);
  const listEle = useRef<HTMLDivElement>(null);
  const [formatList, setFormatList] = useState<string[]>([]);

  const [fileSize, setFileSize] = useState<
  { capacity: number, total: number; }>({ capacity: 0, total: 0 });

  const panes:{
    pane?: SemanticShorthandItem<TabPaneProps>
    menuItem?: any
    render?: () => React.ReactNode
  }[] = useMemo(() => {
    const estimate = { menuItem: '見積', render: () => <Tab.Pane><EstimateListSP data={estimateList} /></Tab.Pane> };
    const list = [
      { menuItem: '案件情報', render: () => <Tab.Pane><ProjectInfoTabContentsSP /></Tab.Pane> },
      { menuItem: '対応履歴', render: () => <Tab.Pane><SupportHistoryListSP data={supportList} /></Tab.Pane> },
      { menuItem: 'ファイル', render: () => <Tab.Pane><FileListSP fileSize={fileSize} data={fileList} /></Tab.Pane> },
      { menuItem: 'メンテナンス', render: () => <Tab.Pane><MaintenanceListSP data={maintenanceList} /></Tab.Pane> },
    ];
    return (!estimateAuth ? list : [list[0]].concat([estimate].concat(list.splice(1))));
  }, [estimateList, supportList, fileList, maintenanceList, estimateAuth]);

  const editAuth = useEditAuthProject(projectInfo);

  /* Callback */
  const getProject = useCallback(() => {
    dispatch(ProjectActions.api.project.get({
      noLoad: !!projectInfo,
      param: { id: Number(projectID) },
    }));
  }, [projectInfo, projectID]);

  const getEstimateList = useCallback((data?:EstimateSortStateInProject) => {
    const sortData = data || estimateSort;
    dispatch(EstimateActions.api.estimate.getList({
      noLoad: true,
      param: {
        ...EstimateModel.getListInProject(sortData, Number(projectID)),
        valid_flag: 1,
      },
      callback: (v) => {
        dispatch(ProjectDetailActions.setEstimateList(v));
      },
    }));
  }, [projectID, estimateSort]);

  const getSupportHistoryList = useCallback((data?:SupportHistorySortState) => {
    const sortState = data || supportSort;
    dispatch(SupportHistoryActions.api.supportHistory.getList({
      noLoad: true,
      param: SupportHistoryModel.listParamInProject({ sortState, project_id: Number(projectID) }),
      onSuccess: (v) => {
        dispatch(ProjectDetailActions.setSupportList(v));
      },
    }));
  }, [projectID, supportSort]);

  const getFileList = useCallback((data?:FileSortState) => {
    const sortData = data || fileSort;
    dispatch(FileActions.api.file.getList({
      noLoad: true,
      param: FileModel.listParamInProject(sortData, Number(projectID)),
      onSuccess: (v, file) => {
        setFileSize({ ...file });
        dispatch(ProjectDetailActions.setFileList(v));
        dispatch(ProjectDetailActions.setStopLoadFile(false));
      },
    }));
  }, [projectID, fileSort]);

  const getMaintenanceList = useCallback((data?:MaintenanceSortState) => {
    const sortData = data || maintenanceSort;
    dispatch(MaintenanceActions.api.maintenance.getList({
      noLoad: true,
      param: MaintenanceModel.listParmInProject({ sortData, project_id: Number(projectID) }),
      callback: (v) => {
        dispatch(ProjectDetailActions.setMaintenanceList(v));
      },
    }));
  }, [projectID, maintenanceSort]);

  const handleClickEdit = useCallback(() => {
    dispatch(DialogActions.push({
      title: ProjectEditDialogTitle.update,
      element: <ProjectEditSP
        id={projectInfo?.id}
        callback={getProject}
      />,
    }));
  }, [projectInfo?.id]);

  const handleClickEstimateEdit = useCallback(() => {
    if (!projectInfo || !editAuth) return;
    dispatch(DialogActions.push({
      title: '見積新規登録',
      className: 'estimate max_height_dialog max_width_dialog',
      element: <EstimateEditSP
        callback={() => {
          getEstimateList();
        }}
        project={projectInfo}
      />,
    }));
  }, [projectInfo, getEstimateList, editAuth]);

  const handleClickMaintenanceEdit = useCallback(() => {
    if (!projectInfo) return;
    dispatch(DialogActions.push({
      title: MaintenanceEditDialogTitle.add,
      element: <MaintenanceEditSP
        projectData={projectInfo}
        callback={() => getMaintenanceList()}
      />,
    }));
  }, [projectInfo, editAuth]);

  const handleClickSort = useCallback((index: number) => {
    let element = <></>;
    if (index === 1) {
      element = (
        <RefindEstimateSP callback={(v) => {
          dispatch(ProjectDetailActions.setEstimateList(null));
          getEstimateList(v);
        }}
        />
      );
    } else if (index === 2) {
      element = (
        <RefindSupportHistorySP
          callback={(v) => {
            dispatch(ProjectDetailActions.setSupportList(null));
            getSupportHistoryList(v);
          }}
          info={projectInfo}
        />
      );
    } else if (index === 3) {
      element = (
        <RefindFileSP
          formatList={formatList}
          callback={(v) => {
            dispatch(ProjectDetailActions.setFileList(null));
            getFileList(v);
          }}
        />
      );
    } else if (index === 4) {
      element = (
        <RefindMaintenanceSP
          callback={(v) => {
            dispatch(ProjectDetailActions.setMaintenanceList(null));
            getMaintenanceList(v);
          }}
          data={projectInfo}
        />
      );
    }
    dispatch(DialogActions.push({ title: '絞込み', element }));
  }, [formatList]);

  const handleTabChange = useCallback((index: number) => {
    const path = `${RoutingPath.projectDetail}/${projectID}?p=`;
    let id = '';
    if (estimateAuth) {
      if (index === 0) id = 'project';
      else if (index === 1) id = 'estimate';
      else if (index === 2) id = 'support-history';
      else if (index === 3) id = 'file';
      else if (index === 4) id = 'maintenance';
    } else if (index === 0) id = 'project';
    else if (index === 1) id = 'support-history';
    else if (index === 2) id = 'file';
    else if (index === 3) id = 'maintenance';
    dispatch(replace(path + id));
    setActiveIndex(index);
  }, [projectID]);

  /* Effect */
  useEffect(() => {
    handleTabChange(getActiveIndex(query || 'project', estimateAuth));
  }, [projectID, query]);

  useDidMount(() => {
    dispatch(ProjectDetailActions.setStopLoadFile(false));
  });

  useEffect(() => {
    if (estimateAuth) {
      if (activeIndex === 0) getProject();
      else if (activeIndex === 1) getEstimateList();
      else if (activeIndex === 2) getSupportHistoryList();
      else if (activeIndex === 3) getFileList();
      else if (activeIndex === 4) getMaintenanceList();
    } else if (activeIndex === 0) getProject();
    else if (activeIndex === 1) getSupportHistoryList();
    else if (activeIndex === 2) getFileList();
    else if (activeIndex === 3) getMaintenanceList();
  }, [projectID, activeIndex]);

  useWillUnMount(() => {
    dispatch(ProjectActions.setProject(null));
    dispatch(ProjectDetailActions.setEstimateList(null));
    dispatch(ProjectDetailActions.setEstimateSort(null));
    dispatch(ProjectDetailActions.setSupportList(null));
    dispatch(ProjectDetailActions.setSupportSort(null));
    dispatch(ProjectDetailActions.setFileList(null));
    dispatch(ProjectDetailActions.setFileSort(null));
    dispatch(ProjectDetailActions.setMaintenanceList(null));
    dispatch(ProjectDetailActions.setMaintenanceSort(null));
  });

  useEffect(() => listEle.current?.scrollTo(0, -10000), [activeIndex]);

  useEffect(() => {
    if (!projectID) return;
    dispatch(FileActions.api.file.getFormatList({
      param: FileModel.listParamInProject(fileSort, Number(projectID)),
      onSuccess: setFormatList,
    }));
  }, [projectID]);

  return (
    <BasePageSP
      searchBoxDialog={{
        title: SearchBoxDialogTitle,
        element: <ProjectSearchBoxSP
          getList={() => {
            dispatch(DialogActions.pop());
            dispatch(push(RoutingPath.project));
          }}
        />,
      }}
      searchCallback={(v) => {
        dispatch(ProjectActions.setSort({ keyword: v }));
        dispatch(push(RoutingPath.project));
      }}
    >
      <div className="detail_wrap">
        <div className="detail_header">
          <div
            className="detail_header_inner"
            onClick={() => dispatch(goBack())}
          >
            <div className="detail_header_inner__back_btn">
              <i className="fas fa-chevron-circle-left" />
            </div>
            <span className="detail_header_inner__label">
              {/* 案件詳細 */}
              {projectInfo?.name || '---'}
            </span>

          </div>

          <div className="detail_header_buttons">
            {activeIndex === 1 && (
              <LeftIconButton
                onClick={handleClickEstimateEdit}
                size="sm"
                color="secondary"
                fontAwesomeClass="fa fa-edit"
                label="見積登録"
                disabled={!editAuth || !!projectInfo?.complete_date}
              />
            )}
            {((estimateAuth && activeIndex === 4) || (!estimateAuth && activeIndex === 3)) && (
              <LeftIconButton
                onClick={handleClickMaintenanceEdit}
                size="sm"
                color="secondary"
                fontAwesomeClass="fa fa-edit"
                label="メンテ登録"
                disabled={!editAuth}
              />
            )}
            {activeIndex !== 0 && <Button size="sm" color="secondary" onClick={() => handleClickSort(activeIndex)}>絞込み</Button>}

            <LeftIconButton
              onClick={handleClickEdit}
              size="sm"
              color="secondary"
              fontAwesomeClass="fa fa-edit"
              label="案件編集"
              disabled={!editAuth}
            />
          </div>
        </div>

        {/* body contents */}
        <div className="detail_body" ref={listEle}>
          <Tab
            onTabChange={(_, data) => handleTabChange(Number(data.activeIndex))}
            panes={panes}
            activeIndex={activeIndex}
          />
        </div>

        <DetailPageFooterSP
          url={`/#${RoutingPath.projectDetail}/${projectID}`}
          tel={projectInfo?.field_tel_no}
          type="project"
          data={projectInfo}
          fileData={{
            getList: getFileList,
            fileSize,
          }}
        />
      </div>
    </BasePageSP>
  );
};
