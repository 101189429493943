import { Validation } from './validation';

export const ValidationPassword: Validation[] = [
  // new Validation(
  //   (v) => (
  //     new RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?\d)[A-Za-z\d]{8,}$/).test(v)
  //   ),
  //   '半角の数字, 英字(小), 英字(大)をすべて含んだ８文字以上で入力してください',
  // ),
  new Validation(
    (v) => (
      new RegExp(/[A-Za-z\d]{8,}$/).test(v)
    ),
    '半角英数字の８文字以上で入力してください',
  ),
];
