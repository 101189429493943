import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './file-search-box.pc.scss';
import { cloneDeep } from 'lodash';
import { State } from '../../../../../redux/root.reducer';
import { DatePicker } from '../../../../ui/date-picker/date-picker';
import { LeftIconButton } from '../../../../ui/button/left-icon-button/left-icon-button';
import { SearchBoxPC } from '../search-box.pc';
import { FileSortState } from '../../../../../type/file/file.type';
import { FileActions } from '../../../../../redux/file/file.action';
import { Input } from '../../../../ui/input/input';
import { useAppSelector } from '../../../../../hooks/use-redux';
import { useDateValidation } from '../../../../../hooks/use-date-validation';
import { DateTermValidation } from '../../../../../model/validation/date/term-date.validation';
import { pulldown } from '../../../../../utilities/pulldown';
import { Select } from '../../../../ui/select/select';
import { useDidMount } from '../../../../../hooks/life-cycle';
import { MasterActions } from '../../../../../redux/master/master.action';

type Props = {
  callback: (v: FileSortState) => void
  isSearch?: boolean;
}

export const FileSearchBoxPC = (props: Props) => {
  const { callback, isSearch } = props;

  /* Hooks */
  const sortState = useAppSelector((v) => (v.file.sort));
  const employeeList = useAppSelector((v) => v.master.employeeList);
  const dispatch = useDispatch();

  /* State */
  const [sort, setSort] = useState(cloneDeep(sortState));

  const termDate = useDateValidation([
    { start: sort.upload_date_start, end: sort.upload_date_end },
    { start: sort.created_at_start, end: sort.created_at_end },
    { start: sort.updated_at_start, end: sort.updated_at_end },
  ]);

  /* Callback */
  const setState = useCallback((v: Partial<FileSortState>) => {
    const data = {
      ...cloneDeep(sort),
      ...cloneDeep(v),
    };
    setSort(data);
  }, [sort, isSearch]);

  const handleClickSearch = useCallback(() => {
    if (termDate.messages.length) {
      termDate.check();
      return;
    }
    callback(sort);
    if (!isSearch) {
      dispatch(FileActions.setSort(cloneDeep(sort)));
    }
  }, [callback, isSearch, termDate, sort, sortState]);

  useDidMount(() => {
    dispatch(MasterActions.api.employee.getList({}));
  });

  return (
    <SearchBoxPC
      openCallback={() => { }}
    >
      <div onKeyPress={(e) => { if (e.key === 'Enter')callback(sortState); }}>
        <div className="item_wrap">
          <div className="item_box">
            <div className="item_head">顧客名</div>
            <Input
              value={sort.customer_name}
              onChange={(e) => setState({ customer_name: e.target.value })}
            />
          </div>
          <div className="item_box">
            <div className="item_head">案件名</div>
            <Input
              value={sort.project_name}
              onChange={(e) => setState({ project_name: e.target.value })}
            />
          </div>
          <div className="item_box">
            <div className="item_head">ファイル名</div>
            <Input
              value={sort.file_name}
              onChange={(e) => setState({ file_name: e.target.value })}
            />
          </div>
          <div className="item_box">
            <div className="item_head">アップロード日</div>
            <DatePicker
              date={sort.upload_date_start}
              onChange={(v) => setState({ upload_date_start: v })}
              term={DateTermValidation([
                { start: sort.upload_date_start, end: sort.upload_date_end },
              ])}
            />
            <label className="ml_10">〜</label>
            <DatePicker
              date={sort.upload_date_end}
              onChange={(v) => setState({ upload_date_end: v })}
              term={DateTermValidation([
                { start: sort.upload_date_start, end: sort.upload_date_end },
              ])}
            />
          </div>
        </div>
        <div className="item_wrap">
          <div className="item_box">
            <div className="item_head">登録者</div>
            <Select
              defaultLabel="全て"
              className="fixed"
              value={sort.created_employee_id}
              onChange={(v) => setState({ created_employee_id: Number(v) })}
              options={pulldown(employeeList)}
            />
          </div>
          <div className="item_box">
            <div className="item_head">登録日</div>
            <DatePicker
              date={sort.created_at_start}
              onChange={(v) => setState({ created_at_start: v })}
              type="YYYY/MM/DD"
              term={DateTermValidation([
                { start: sort.created_at_start, end: sort.created_at_end },
              ])}
            />
            <label className="ml_10">～</label>
            <DatePicker
              date={sort.created_at_end}
              onChange={(v) => setState({ created_at_end: v })}
              type="YYYY/MM/DD"
              term={DateTermValidation([
                { start: sort.created_at_start, end: sort.created_at_end },
              ])}
            />
          </div>
          <div className="item_box">
            <div className="item_head">更新者</div>
            <Select
              defaultLabel="全て"
              className="fixed"
              value={sort.updated_employee_id}
              onChange={(v) => setState({ updated_employee_id: Number(v) })}
              options={pulldown(employeeList)}
            />
          </div>
          <div className="item_box">
            <div className="item_head">更新日</div>
            <DatePicker
              date={sort.updated_at_start}
              onChange={(v) => setState({ updated_at_start: v })}
              type="YYYY/MM/DD"
              term={DateTermValidation([
                { start: sort.updated_at_start, end: sort.updated_at_end },
              ])}
            />
            <label className="ml_10">～</label>
            <DatePicker
              date={sort.updated_at_end}
              onChange={(v) => setState({ updated_at_end: v })}
              type="YYYY/MM/DD"
              term={DateTermValidation([
                { start: sort.updated_at_start, end: sort.updated_at_end },
              ])}
            />
          </div>
          <LeftIconButton
            label="検索"
            fontAwesomeClass="fas fa-search"
            className="btn_search for_detail"
            size="sm"
            color="secondary"
            onClick={handleClickSearch}
          />
        </div>
      </div>
    </SearchBoxPC>
  );
};
