import lodash, { cloneDeep } from 'lodash';
import { EditPriceAreaState, EstimateEditState, EstimateSortState } from '../../type/estimate/estimate.type';
import { User } from '../../type/auth/user.typs';
import { DateHelper } from '../../utilities/date-helper';

export class EstimateCollection {
  private static _estimateHeader: string[] = [
    '',
    '見積番号',
    '見積日',
    '現場名称',
    '案件名',
    '案件担当者',
    '見積作成者',
    '見積金額',
    '消費税額',
    '税込合計見積',
    '原価合計',
    '消費税額',
    '税込合計原価',
    '調整額',
    '受注工期_開始',
    '受注工期_終了',
  ];

  private static _estimateMeisaiHeader: string[] = [
    '工事・部材名称',
    '印刷名称',
    '規格',
    '数量',
    '単位',
    '見積単価',
    '見積金額',
    '原価単価',
    '原価金額',
    '粗利金額',
    '粗利率',
    '備考',
  ];

  private static _estimateSearchHeader: string[] = [
    '大分類',
    '中分類',
    '工事・部材名称',
    '金額',
    '原価金額',
    '印刷名称',
    '規格',
    '数量',
    '単位',
    '見積単価',
    '原価',
  ];

   static _sortCopyInitialState = (user?:User):EstimateSortState => ({
     /** 案件ID */
     project_id: NaN,
     /** 案件名 */
     project_name: '',
     /** 現場名称 */
     field_name: '',
     /** 案件担当（店舗） */
     project_store_id: NaN,
     /** 案件担当（社員） */
     project_employee_id: NaN,
     /** 見積作業者 */
     employee_id: user?.id ?? NaN,
     /** 有効フラグ */
     valid_flag: 1,
     /** 失注除外フラグ */
     cancel_f: NaN,
     /** キーワード */
     keyword: '',
     /** 並替基準列 */
     sort_by: NaN,
     /** 並替方法 */
     highlow: 1,
     offset: 0,
     limit: 100,
     /** 見積番号 */
     estimate_no: '',
     /** 作成日 */
     estimate_dt: null,
     /** 見積金額 */
     gokei_zeinuki_kin: NaN,
     /** 消費税額（見積） */
     shohizei_kin: NaN,
     /** 税込合計見積 */
     gokei_kin: NaN,
     /** 原価合計 */
     gokei_zeinuki_genka_kin: NaN,
     /** 消費税額（原価） */
     genka_shohizei_kin: NaN,
     /** 税込合計原価 */
     gokei_genka_kin: NaN,
     /** 調整額 */
     tyosei_kin: NaN,
     /** 見積工期_開始 */
     estimate_kouki_start_dt: null,
     /** 見積工期_終了 */
     estimate_kouki_end_dt: null,
     /** 顧客名 */
     customer_name: '',
     /** 明細 */
     meisai: '',
     /** 工事部位 */
     construction_parts: null,
     /** 登録店舗 */
     created_store_id: NaN,
     /** 登録社員 */
     created_employee_id: NaN,
     /** 更新店舗 */
     updated_store_id: NaN,
     /** 更新社員 */
     updated_employee_id: NaN,
     /** 登録日－期間開始 */
     created_at_start: null,
     /** 登録日－期間終了 */
     created_at_end: null,
     /** 更新日－期間開始 */
     updated_at_start: null,
     /** 更新日－期間終了 */
     updated_at_end: null,
   });

   static _sortInitialState = (user?:User):EstimateSortState => ({
     /** 案件ID */
     project_id: NaN,
     /** 案件名 */
     project_name: '',
     /** 現場名称 */
     field_name: '',
     /** 案件担当（店舗） */
     project_store_id: NaN,
     /** 案件担当（社員） */
     project_employee_id: user?.id ?? NaN,
     /** 見積作業者 */
     employee_id: NaN,
     /** 有効フラグ */
     valid_flag: 1,
     /** 失注除外フラグ */
     cancel_f: NaN,
     /** キーワード */
     keyword: '',
     /** 並替基準列 */
     sort_by: NaN,
     /** 並替方法 */
     highlow: 1,
     offset: 0,
     limit: 100,
     /** 見積番号 */
     estimate_no: '',
     /** 作成日 */
     estimate_dt: null,
     /** 見積金額 */
     gokei_zeinuki_kin: NaN,
     /** 消費税額（見積） */
     shohizei_kin: NaN,
     /** 税込合計見積 */
     gokei_kin: NaN,
     /** 原価合計 */
     gokei_zeinuki_genka_kin: NaN,
     /** 消費税額（原価） */
     genka_shohizei_kin: NaN,
     /** 税込合計原価 */
     gokei_genka_kin: NaN,
     /** 調整額 */
     tyosei_kin: NaN,
     /** 見積工期_開始 */
     estimate_kouki_start_dt: null,
     /** 見積工期_終了 */
     estimate_kouki_end_dt: null,
     /** 顧客名 */
     customer_name: '',
     /** 明細 */
     meisai: '',
     /** 工事部位 */
     construction_parts: null,
     /** 登録店舗 */
     created_store_id: NaN,
     /** 登録社員 */
     created_employee_id: NaN,
     /** 更新店舗 */
     updated_store_id: NaN,
     /** 更新社員 */
     updated_employee_id: NaN,
     /** 登録日－期間開始 */
     created_at_start: null,
     /** 登録日－期間終了 */
     created_at_end: null,
     /** 更新日－期間開始 */
     updated_at_start: null,
     /** 更新日－期間終了 */
     updated_at_end: null,

   });

  private static _editInitialState: () => EstimateEditState = () => {
    const today = new Date();
    const yukokigen = DateHelper.calcMonth(new Date(), 1) || null;

    return lodash.cloneDeep({
      project_id: NaN,
      customer_id: NaN,
      estimate_dt: today,
      estimate_kouki_start_dt: null,
      estimate_kouki_end_dt: null,
      yukokigen,
      jyutyu_yotei_dt: yukokigen,
      // order_expected_date: afterDate,
      remarks: '',
    });
  }

  private static _priceAreaInitialState: () => EditPriceAreaState = () => lodash.cloneDeep({
    /**  税抜合計金額（見積金額）*/
    zeinuki_kin: 0,
    /**  消費税額*/
    shohizei_kin: 0,
    /**  見積合計金額(税抜)（見積合計）*/
    gokei_zeinuki_kin: 0,
    /**  原価合計  税抜き（原価金額）*/
    genka_kin: 0,
    /**  原価合計金額(税抜)  （原価合計）*/
    gokei_zeinuki_genka_kin: 0,
    /**  原価消費税額*/
    genka_shohizei_kin: 0,
    /**  税込合計原価金額（税込合計原価金額）*/
    gokei_genka_kin: 0,
    /* 粗利 */
    arari_price: 0,
    /* 粗利パーセント */
    arari_percent: 0,
    /**  合計金額（税込合計見積金額）*/
    gokei_kin: 0,
    /**  調整額（値引き）*/
    tyosei_kin: 0,
    /**  予備費1_見積金額（現場協力費 見積金額）*/
    reserve1_estimate_kin: 0,
    /**  予備費1_見積パーセント（現場協力費 見積％）*/
    reserve1_estimate_percent: 0,
    /**  "予備費1_原価金額（現場協力費 原価金額）"*/
    reserve1_genka_kin: 0,
    /**  "予備費1_原価パーセント（現場協力費 原価％）"*/
    reserve1_genka_percent: 0,
    /**  "予備費2_見積金額（予備原価 見積金額）"*/
    reserve2_estimate_kin: 0,
    /**  "予備費2_見積パーセント（予備原価 見積％）"*/
    reserve2_estimate_percent: 0,
    /**  "予備費2_原価金額（予備原価 原価金額）"*/
    reserve2_genka_kin: 0,
    /**  "予備費2_原価パーセント（予備原価 原価％）"*/
    reserve2_genka_percent: 0,
  });

  static get estimateHeader() {
    return EstimateCollection._estimateHeader;
  }

  static get estimateSearchHeader() {
    return EstimateCollection._estimateSearchHeader;
  }

  static get estimateMeisaiHeader() {
    return EstimateCollection._estimateMeisaiHeader;
  }

  static get sortInitialState() {
    return EstimateCollection._sortInitialState();
  }

  static get editInitialState() {
    return EstimateCollection._editInitialState();
  }

  static get priceAreaInitialState():EditPriceAreaState {
    return this._priceAreaInitialState();
  }
}
