import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { FileListType } from '../../../../type/file/file.type';
import './file-card.scss';
import { UserAgent } from '../../../../utilities/user-agent';
import { DateFormatter } from '../../../../utilities/date-formatter';
import { LoadingFileImg } from '../../file-img/loading-file-img';
import { MathHelper } from '../../../../utilities/math-helper';

type Props = {
    stopLoad?: boolean,
    onClick: (id:number)=>void,
    fileData: FileListType,
    className?: string,
    isInCustomerDetail?: boolean;
  }

export const FileCard = (props: Props) => {
  const dispatch = useDispatch();
  const ref = React.createRef();
  const {
    onClick, fileData, className, isInCustomerDetail, stopLoad,
  } = props;

  /* Callback */
  const handleClickCustomerDetail = useCallback(
    () => {
      onClick(fileData.id);
    }, [onClick],
  );

  return (
    <div
      className={`file_card ${UserAgent} card_base ${className || ''}`}
      onClick={(e) => {
        if (e.target === ref.current) {
          return;
        }
        handleClickCustomerDetail();
      }}
    >

      <div className="card_base_row">
        <div className="card_base_row__col_left">
          <div className="card_info_item">
            <div className="card_info_item__text important">
              {`${fileData.file_name || '---'}`}
            </div>
          </div>
          {!isInCustomerDetail
          && (
          <div className="card_info_item">
            <div className="card_info_item__head">
              顧客名
            </div>
            <div className="card_info_item__text">
              {`${fileData.customer_name || '---'}`}{/* ${fileData.furigana ? `（${fileData.furigana}）` : '（---）'}*/}
            </div>
          </div>
          )}

          <div className="card_info_item">
            <div className="card_info_item__head">
              案件名
            </div>
            <div className="card_info_item__text">
              {fileData.project_name || '---'}
            </div>
          </div>

          <div className="card_info_item">
            <div className="card_info_item__head">
              更新日
            </div>
            <div className="card_info_item__text">
              {DateFormatter.date2str(fileData.upload_date, 'YYYYMMDD_HHmm') || '---'}
            </div>
          </div>

          <div className="card_info_item">
            <div className="card_info_item__head">
              更新者
            </div>
            <div className="card_info_item__text">
              {fileData.updater || '---'}
            </div>
          </div>
          <div className="card_info_item">
            <div className="card_info_item__head">
              ﾌｧｲﾙｻｲｽﾞ
            </div>
            <div className="card_info_item__text">
              <strong>
                {`${MathHelper.rounding(
                  MathHelper.div(Number(fileData.size), 1024), 2, 'floor',
                ).toLocaleString()} KB`}
              </strong>
            </div>
          </div>
        </div>

        <div className="card_base_row__col_right">
          <div className="numero">{fileData.internal_id ? `No.${fileData.internal_id}` : '---'}</div>
          <div className="thumb google">
            <LoadingFileImg
              id={fileData.id}
              format={fileData.format}
              stopLoad={stopLoad}
              fileName={fileData.file_name}
            />
          </div>
          <div>
            <span className={`file_${fileData.format.toLocaleLowerCase()}_color file_name_box`}>
              {fileData.format}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
