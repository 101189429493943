import actionCreatorFactory from 'typescript-fsa';
import { ApiBase } from '../../../../service/api-base';
import { CallbackRequest, RequestBaseParam } from '../../../../type/api.type';
import { RequestBaseCollection } from '../../../../service/api.collection';
import { ApiMasterContractedEmployeeGetListParam, ApiMasterContractedEmployeeGetListResponse, ApiMasterContractedEmployeePostParam } from './api-master-contracted-employee.type';
import { ApiMasterContractedCompanyPostParam } from '../master-contracted-company/api-master-contracted-company.type';

export const ActionCreator = actionCreatorFactory('master/contracted/employee');

export const apiMasterContractedEmployee = {
  getList: ActionCreator<ApiMasterContractedEmployeeGetListParam>('get/list'),
  post: ActionCreator<CallbackRequest<ApiMasterContractedEmployeePostParam>>('post'),
};

export class ApiMasterContractedEmployee<T = any> extends ApiBase<T> {
  constructor(param: RequestBaseParam & {id?: number}) {
    const { id } = param;
    super({
      ...RequestBaseCollection(),
      ...param,
      url: `/api/master/company/employee${id ? `/${id}` : ''}`,

    });
  }
}

export class ApiMasterContractedEmployeePost extends ApiMasterContractedEmployee {
  constructor(param: ApiMasterContractedEmployeePostParam) {
    const { data, id } = param;
    super({
      httpMethod: 'POST',
      param: data,
      id,
    });
  }
}

export class ApiMasterContractedEmployeeGetList
  extends ApiMasterContractedEmployee<ApiMasterContractedEmployeeGetListResponse> {
  constructor(param: ApiMasterContractedEmployeeGetListParam) {
    super({
      httpMethod: 'GET',
      param,
    });
  }
}
