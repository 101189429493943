import React, { useEffect, useRef } from 'react';
import NewWindow from 'react-new-window';
import { Button } from '../button/button';

type Props = {
  open: boolean;
  title: string;
  children: globalThis.React.ReactNode;
  onUnload?: () => void;
  refMount?: (ref: React.RefObject<NewWindow>) => void;
  testStr?: any;
}

export const AnotherWindow = (props: Props) => {
  const {
    title, children, open, onUnload, refMount, testStr,
  } = props;
  const ref = useRef<NewWindow>(null);

  console.log(testStr);

  useEffect(() => {
    if (!ref.current) return;
    refMount?.(ref);
  }, [ref.current]);

  return (
    open
      ? (
        <NewWindow
          title={title}
          ref={ref}
          onUnload={() => onUnload?.()}
        >
          {children}
        </NewWindow>
      )
      : <></>
  );
};
