import actionCreatorFactory from 'typescript-fsa';
import { ApiBase } from '../../../../service/api-base';
import { CsvWeddingAnniversaryListType } from '../../../../type/csv/csv.type';

/* Param */
export type ApiCsvWeddingGetListParam = Partial<{
  /** 営業担当（店舗） */
  store_id: number;
  /** 営業担当（担当者） */
  employee_id: number;
  /** 顧客名 */
  name: string;
  /** 結婚記念日（開始年） */
  wedding_anniversary_start_year: string;
  /** 結婚記念日（開始月） */
  wedding_anniversary_start_month: number;
  /** 結婚記念日（終了年） */
  wedding_anniversary_end_year: string;
  /** 結婚記念日（終了月） */
  wedding_anniversary_end_month: number;
  /** 有効フラグ */
  valid_flag: number;
  /** オフセット */
  offset: number;
  /** 1ページ内の件数 */
  limit: number;
  /** 並替基準列
   * "0:顧客ID
1:顧客名
2:結婚記念日
3:郵便番号
4:都道府県
5:住所
6:顧客電話番号
7:営業担当社員名" */
  sort_by: number;
  /** 並替方法 */
  highlow: number;
}>;

export type ApiCsvWeddingDownloadParam = ApiCsvWeddingGetListParam;

/* Response */
export type ApiCsvWeddingGetListResponse = CsvWeddingAnniversaryListType;

export const ActionCreator = actionCreatorFactory('csv/api/wedding');

export const apiCsvWedding = {
  getList: ActionCreator<ApiCsvWeddingGetListParam>('get/list'),
  download: ActionCreator<ApiCsvWeddingDownloadParam>('download'),
};

export class ApiCsvWeddingGetList extends ApiBase<ApiCsvWeddingGetListResponse> {
  constructor(param: ApiCsvWeddingGetListParam) {
    super({
      httpMethod: 'GET',
      param,
      url: '/api/csv/weddinganniversary',
    });
  }
}

export class ApiCsvWeddingDownload extends ApiBase {
  constructor(param: ApiCsvWeddingDownloadParam) {
    super({
      httpMethod: 'GET',
      contents: 'BLOB',
      param,
      url: '/api/csv/download/weddinganniversary',
    });
  }
}
