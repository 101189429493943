import { useEffect, useState, useMemo } from 'react';
import { Pagination } from '../../pagination/pagination';
import { Select } from '../../select/select';
import { Config } from '../../../../config/config';

export type Limit = 10 | 100 | 200 | 300 | 400 | 500 | 9999

type Props = {
  /** 表示件数 100 | 200 | 300 | 400 | 500 */
  limit: Limit;
  /** 全体件数 */
  hitCount?: number;
  page: number;
  className?: string,
  callback: (page: number, limit: Limit) => void;
  isSp?: boolean;
  noAnnotation?: boolean;
  noHitCount?: boolean;
}

export const TableSort = (props: Props) => {
  const {
    limit, hitCount = 0, page, className, callback, isSp, noAnnotation, noHitCount,
  } = props;

  /* State */
  const [limitState, setLimitState] = useState(limit);
  const [pageState, setPageState] = useState(page);

  /* Memo */

  const limitCount = useMemo(() => {
    if (!(hitCount % limit)) {
      return Math.ceil(hitCount / limitState);
    } if (hitCount < limitState) {
      setPageState(0);
      return 1;
    }
    return Math.ceil(hitCount / limitState);
  }, [hitCount, limitState]);

  /* Effect */
  useEffect(() => {
    setLimitState(limit);
  }, [limit]);

  useEffect(() => {
    setPageState(page);
  }, [page]);

  return (
    <div className={`table_sort ${className || ''}`}>
      <div className="left">
        {(!isSp && !noHitCount)
        && (
        <>
          <div className="count">ヒット件数：&nbsp;<span> {hitCount}</span>&emsp;件</div>
            {
              !noAnnotation
              && <div className="comment">※行を『{Config.isMac ? 'optionキー + クリック' : 'ctrlキー + ダブルクリック'}』で別タブ表示されます。</div>
            }
        </>
        )}
      </div>
      <div className="right pagenation">
        {!isSp
        && (
        <Select
          value={limitState}
          onChange={(v) => {
            callback(0, Number(v) as Limit);
            setLimitState(Number(v) as Limit);
          }}
          options={
              (Config.mode === 'dev' || Config.mode === 'local'
                ? [{ text: '10', value: 10 }]
                : []).concat(
                [
                  { text: '100', value: 100 },
                  { text: '200', value: 200 },
                  { text: '300', value: 300 },
                  { text: '400', value: 400 },
                  { text: '500', value: 500 },
                ],
              )
}
        />
        )}
        <Pagination
          page={pageState + 1}
          limitCount={limitCount}
          onChange={(v) => {
            callback(v, limitState);
            setPageState(v);
          }}
        />
      </div>
    </div>
  );
};
