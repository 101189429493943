import React, {
  useCallback, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as lodash from 'lodash';
import { push } from 'connected-react-router';
import { Table } from '../../../../../ui/table/table';
import './estimate-list.pc.scss';
import { State } from '../../../../../../redux/root.reducer';
import { EstimateListType } from '../../../../../../type/estimate/estimate.type';
import { EstimateCollection } from '../../../../../../collection/estimate/estimate.collection';
import { EstimateActions } from '../../../../../../redux/estimate/estimate.action';
import { RoutingPath } from '../../../../../../routes/routing-pass';
import { DateFormatter } from '../../../../../../utilities/date-formatter';
import { MathHelper } from '../../../../../../utilities/math-helper';
import Ordered from '../../../../../../asset/images/icon/ordered.svg';
import { useOpenWindow } from '../../../../../../hooks/use-open-window';

type Props = {
  selectedTr?: number[];
  data?: EstimateListType[];
  handleCardClick?: (estimate: EstimateListType | null) => void;
}

export const EstimateListPC = (props: Props) => {
  const {
    data, handleCardClick, selectedTr,
  } = props;

  /* Hooks */
  const estimateList = useSelector((state: State) => state.estimate.list, lodash.isEqual);
  const dispatch = useDispatch();
  const openWindow = useOpenWindow();

  /* State */
  const [selected, setSelected] = useState<number[]>([]);

  /* List */
  const dataList = useMemo(() => data || estimateList, [estimateList, data]);

  /* Callback */
  const handleDbClick = useCallback((
    v: EstimateListType,
    e?: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
  ) => {
    // if (handleCardClick) return;
    if (!e) return;
    if (!dataList) return;
    setSelected([dataList.findIndex((v2) => v2.id === v.id)]);
    openWindow({
      url: `${RoutingPath.estimateDetail}/${v.id}`,
      e,
    });
  },
  [handleCardClick, dataList]);

  const handleClick = useCallback((row: EstimateListType) => {
    console.log(row);
    if (!dataList) return;

    if (handleCardClick) {
      const findData = dataList?.find((v) => v.id === row.id);
      handleCardClick(findData || null);
    }
    setSelected([dataList?.findIndex((v) => v.id === row.id)]);
  }, [dataList, handleCardClick, dataList]);

  const handleClickHeader = useCallback(
    (highlow: 0 | 1, sort_by: number) => {
      dispatch(EstimateActions.setSort({ highlow, sort_by }));
    }, [],
  );

  // const onClickMulti = useCallback((v: EstimateListType[]) => {
  //   setSelected(v.map((v2) => dataList.findIndex((v3) => v3.id === v2.id)));
  // }, [dataList]);

  return (
    <section className="result_area list_area">
      <div className="inner">
        <div className="table_responsive">
          <Table
            className="table_selectable table_sortable table_sticky table_cell_change"
            header={EstimateCollection.estimateHeader}
            onClickRow={handleClick}
            onDbClick={(v, i, e) => handleDbClick(v, e)}
            sort={{ onClick: handleClickHeader }}
            selectedTr={selectedTr ?? selected}
            rowDataList={dataList || []}
            onClickAlt={(v, e) => {
              if (e && v) {
                openWindow({
                  url: `${RoutingPath.estimateDetail}/${v.id}`,
                  e,
                  callback: () => {},
                });
              }
            }}
            // onClickMulti={onClickMulti}
            lists={dataList ? dataList.map((v) => (
              [
                v.order_flag ? <img src={Ordered} alt="受注見積" title="受注見積" className="icon" /> : '',
                v.estimate_no,
                DateFormatter.date2str(v.estimate_dt),
                v.field_name,
                v.project_name,
                v.project_employee_name,
                v.created_employee_name,
                v.gokei_zeinuki_kin ? `${MathHelper.rounding(v.gokei_zeinuki_kin, 0).toLocaleString()}` : '',
                v.shohizei_kin ? `${MathHelper.rounding(v.shohizei_kin, 0).toLocaleString()}` : '',
                v.gokei_kin ? `${MathHelper.rounding(v.gokei_kin, 0).toLocaleString()}` : '',
                v.gokei_zeinuki_genka_kin ? `${MathHelper.rounding(v.gokei_zeinuki_genka_kin, 0).toLocaleString()}` : '',
                v.genka_shohizei_kin ? `${MathHelper.rounding(v.genka_shohizei_kin, 0).toLocaleString()}` : '',
                v.gokei_genka_kin ? `${MathHelper.rounding(v.gokei_genka_kin, 0).toLocaleString()}` : '',
                v.tyosei_kin ? `${MathHelper.rounding(v.tyosei_kin, 0).toLocaleString()}` : '',
                DateFormatter.date2str(v.estimate_kouki_start_dt),
                DateFormatter.date2str(v.estimate_kouki_end_dt),
              ]
            )) : null}
            option={{
              stringWidth: [
                { index: 0, width: 50 }, // 受注見積
                // { index: 1, width: 100 }, // 見積番号
                // { index: 2, width: 50 }, // 見積日
                // { index: 3, width: 50 }, //  現場名称
                // { index: 4, width: 50 }, // 案件名
                // { index: 5, width: 50 }, // 案件担当者
                // { index: 6, width: 50 }, // 見積作成者
                // { index: 7, width: 50 }, // 見積金額
                // { index: 8, width: 50 }, // 消費税額
                // { index: 9, width: 50 }, // 税込合計見積
                // { index: 10, width: 50 }, // 原価合計
                // { index: 11, width: 50 }, // 消費税額
                // { index: 12, width: 50 }, // 税込合計原価
                // { index: 13, width: 50 }, // 調整額
                // { index: 14, width: 50 }, // 受注工期_開始
                // { index: 15, width: 50 }, // 受注工期_終了
              ],
              tdAlign: [
                { index: 0, align: 'center' },
                { index: 1, align: 'center' },
                { index: 2, align: 'center' },
                { index: 3, align: 'left' },
                { index: 4, align: 'left' },
                { index: 5, align: 'left' },
                { index: 6, align: 'left' },
                { index: 7, align: 'right' },
                { index: 8, align: 'right' },
                { index: 9, align: 'right' },
                { index: 10, align: 'right' },
                { index: 11, align: 'right' },
                { index: 12, align: 'right' },
                { index: 13, align: 'right' },
                { index: 14, align: 'center' },
                { index: 15, align: 'center' },
              ],
            }}
          />
        </div>
      </div>
    </section>
  );
};
