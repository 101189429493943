import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import { isEqual } from 'lodash';
import { Table } from '../../../../ui/table/table';
import { DialogActions } from '../../../../../redux/dialog/dialog.action';
import { ProjectCollection } from '../../../../../collection/project/project.collection';
import { State } from '../../../../../redux/root.reducer';
import { SupportHistoryEditPC } from '../../support-history/edit/support-history-edit.pc';
import { LeftIconButton } from '../../../../ui/button/left-icon-button/left-icon-button';
import Deposite from '../../../../../asset/images/icon/deposite.svg';
import { SupportHistoryActions } from '../../../../../redux/support-history/support-history.action';
import { SupportHistorySortState, SupportHistoryListType } from '../../../../../type/support-history/support-history.type';
import { RefindSupportHistoryTable } from './refind-support-history-table/refind-support-history-table';
import { DateFormatter } from '../../../../../utilities/date-formatter';
import { ProjectDetailActions } from '../../../../../redux/project-detail/project-detail.action';
import { SupportHistoryModel } from '../../../../../model/support-history/support-history.model';
import { useAppSelector } from '../../../../../hooks/use-redux';

type Props = {
  disabled?: boolean;
}

export const SupportHistoryTable = (props: Props) => {
  const { disabled } = props;
  /* Hooks */
  const dispatch = useDispatch();
  const sort = useAppSelector((v) => v.projectDetail.supportSort);
  const list = useAppSelector((v) => v.projectDetail.supportList);
  const project = useAppSelector((v) => v.project.project);

  /* Callback */
  const [selected, setSelected] = useState<number[]>([]);

  /* 一覧取得 */
  const getList = useCallback((data?:SupportHistorySortState) => {
    const sortState = data || sort;
    if (project?.id) {
      dispatch(SupportHistoryActions.api.supportHistory.getList({
        noLoad: true,
        param: SupportHistoryModel.listParamInProject({ sortState, project_id: project.id }),
        onSuccess: (v) => {
          dispatch(ProjectDetailActions.setSupportList(v));
        },
      }));
    }
  }, [project?.id, sort]);

  const handleClickEdit = useCallback((v?:SupportHistoryListType) => {
    if (!project) return;
    dispatch(DialogActions.push({
      title: '対応履歴新規登録',
      className: 'support_history',
      element: <SupportHistoryEditPC
        id={v?.id}
        project={v ? undefined : project}
        callback={getList}
      />,
    }));
  },
  [project, getList]);

  const handleClickRow = useCallback((v:SupportHistoryListType) => {
    if (!list) return;
    const findIndex = list.findIndex((v2) => v2.id === v.id);
    if (findIndex !== -1) {
      setSelected([findIndex]);
    }
  }, [list]);

  const handleDbClickRow = useCallback((v:SupportHistoryListType) => {
    if (!list) return;
    const findIndex = list.findIndex((v2) => v2.id === v.id);
    if (findIndex !== -1) {
      setSelected([findIndex]);
    }
    handleClickEdit(v);
    // dispatch(push(`${RoutingPath.projectDetail}/${v.id}`));
  }, [list, handleClickEdit]);

  const handleClickHeader = useCallback((highlow:0|1, sort_by:number) => {
    dispatch(ProjectDetailActions.setSupportSort({ highlow, sort_by }));
  }, []);

  useEffect(() => {
    getList();
  }, [project?.id, sort.highlow, sort.sort_by]);

  const handleClickSortDialog = useCallback(() => {
    dispatch(DialogActions.push({
      title: '絞込み',
      element: <RefindSupportHistoryTable
        callback={(v) => {
          dispatch(ProjectDetailActions.setSupportList(null));
          getList(v);
        }}
      />,
    }));
  }, [getList, sort]);

  return (
    <div className="detail_table_area">
      <div className="btn_box">
        <LeftIconButton
          label="新規対応履歴登録"
          fontAwesomeClass="fas fa-edit"
          className="btn_search for_detail"
          size="sm"
          color="primary"
          disabled={disabled}
          onClick={() => handleClickEdit()}
        />
        <LeftIconButton
          label="絞込み"
          fontAwesomeClass="fas fa-filter"
          className="btn_search for_detail"
          size="sm"
          color="secondary"
          onClick={handleClickSortDialog}
        />
      </div>
      <div className="table_responsive">
        <Table
          className="table_selectable table_sortable table_sticky table_cell_change"
          header={ProjectCollection.supportHistory}
          selectedTr={selected}
          sort={{
            index: [0, 1, 2, 3, 4, 5],
            onClick: handleClickHeader,
          }}
          rowDataList={list || []}
          onClickRow={handleClickRow}
          onDbClick={handleDbClickRow}
          lists={list ? list.map((v) => (
            [
              v.support_flag ? <img src={Deposite} alt="対応済" title="対応済" className="icon" /> : '',
              DateFormatter.date2str(v.contact_dt, 'YYYYMMDD_HHmm'),
              v.support_name,
              v.title,
              v.support_employee_name,
              DateFormatter.date2str(v.support_dt, 'YYYYMMDD'),
              '',
            ]
          )) : null}
          option={{
            stringWidth: [
              { index: 0, width: 70 }, // 対応済
              // { index: 1, width: 100 }, // 登録日時
              // { index: 2, width: 100 }, // カテゴリ
              // { index: 3, width: 50 }, //  件名
              // { index: 4, width: 50 }, // 対応者
              // { index: 5, width: 100 }, // 対応日
            ],
            tdAlign: [
              { index: 0, align: 'center' },
              { index: 1, align: 'center' },
              { index: 2, align: 'left' },
              { index: 3, align: 'left' },
              { index: 4, align: 'left' },
              { index: 5, align: 'center' },
            ],
          }}
        />
      </div>
    </div>

  );
};
