import { ValidationPassword } from '../validation-password';

export const PassWordSendValidation = (
  password: string,
  confirmPassword: string,
) => (
  !password
  || !confirmPassword
  || ValidationPassword.filter((v) => !v.run(String(password))).length
  || ValidationPassword.filter((v) => !v.run(String(confirmPassword))).length
  || Boolean(password !== confirmPassword)
);
