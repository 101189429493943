import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EditPC } from '../../../../../../dialogs/edit/edit.pc';
import { Input } from '../../../../../../ui/input/input';
import { Required } from '../../../../../../ui/required/required';
import { ValidationLengthUnder10, ValidationLengthUnder255 } from '../../../../../../../model/validation';
import { RightLabelCheckbox } from '../../../../../../ui/checkbox/right-label-checkbox/right-label-checkbox';
import { MasterEditDialogProps } from '../../../master.type';
import { useDidMount } from '../../../../../../../hooks/life-cycle';
import { State } from '../../../../../../../redux/root.reducer';
import { DialogActions } from '../../../../../../../redux/dialog/dialog.action';
import { MasterActions } from '../../../../../../../redux/master/master.action';
import { MasterCustomerRankFinalCompleteDateValidation } from '../../../../../../../model/validation/master/master-customer-rank-final-complete-date.validation';
import { Message } from '../../../../../../../collection/message.collection';
import { ValidationLengthUnder1 } from '../../../../../../../model/validation/_validation-length-under-1';
import { ValidationMax10Billion } from '../../../../../../../model/validation/validation-max-10-billion';
import { InputNum } from '../../../../../../ui/input/input-num';
import { ValidationEisuzi } from '../../../../../../../model/validation/validation-eisuzi';

export const CustomerRankFinalCompleteDateMasterEditDialog = (props: MasterEditDialogProps) => {
  const { id, callback } = props;

  const dispatch = useDispatch();

  // eslint-disable-next-line no-undef
  const list = useSelector((state:State) => state.master.customerRankLastList);

  const [name, setName] = useState('');
  const [abbreviation, setAbbreviation] = useState('');
  const [date, setDate] = useState('');
  const [validFlag, setValidFlag] = useState(true);

  const [touch, setTouch] = useState(false);

  const handleClickPost = useCallback(() => {
    if (MasterCustomerRankFinalCompleteDateValidation(
      name,
      abbreviation,
      date,
    )) {
      dispatch(DialogActions.pushMessage({
        title: '顧客ランク（最終完工日） 編集／追加',
        message: Message.postError,
        callback: () => setTouch(true),
      }));
      return;
    }

    dispatch(MasterActions.api.customerRankLast.post({
      param: {
        data: {
          name,
          abbreviation,
          date: Number(date),
          is_valid: validFlag ? 1 : 0,
        },
        id,
      },
      onSuccess: () => {
        callback();
        dispatch(DialogActions.pop());
      },
      onError: () => {
        setTouch(true);
      },
    }));
  }, [
    validFlag,
    abbreviation,
    name,
    date,
    callback,
  ]);

  useDidMount(() => {
    if (id) {
      const data = list.find((f) => Number(f.id) === id);
      if (!data) return;
      setName(data.name);
      setAbbreviation(data.abbreviation);
      setDate(String(data.date));
      setValidFlag(data.valid_flag);
    }
  });

  return (
    <EditPC mode="dialog" callback={handleClickPost} label={id ? '更新' : '登録'}>
      <div className="edit_pc_body_inner edit_master cusomer_rank_final_complete_date_master">
        <div>
          <div className="item_wrap">
            <div className="item_box">
              <div className="item_head">顧客ランク（最終完工日）<Required /></div>
              <Input
                className=""
                errorPosBottom
                value={name}
                onChange={(e) => setName(e.target.value)}
                require
                touch={touch}
                maxLength={10}
              />
            </div>
          </div>
          <div className="item_wrap">
            <div className="item_box">
              <div className="item_head">略式表示<Required /></div>
              <Input
                className=""
                value={abbreviation}
                onChange={(e) => setAbbreviation(e.target.value)}
                require
                validationList={ValidationEisuzi}
                touch={touch}
                maxLength={1}
              />
            </div>
          </div>
          <div className="item_wrap">
            <div className="item_box">
              <div className="item_head">最終完工日数<Required /></div>
              <InputNum
                className=""
                value={date}
                onChange={setDate}
                require
                touch={touch}
                maxLength={10}
              />
            </div>
          </div>
          <div className="item_wrap">
            <div className="item_box">
              <div className="item_head">有効フラグ</div>
              <RightLabelCheckbox
                label=""
                className="is_valid"
                checked={validFlag}
                onChange={() => setValidFlag(!validFlag)}
              />
            </div>
          </div>
        </div>
      </div>
    </EditPC>
  );
};
