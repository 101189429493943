import { useDispatch } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import { push } from 'connected-react-router';
import { DialogActions } from '../../../../redux/dialog/dialog.action';
import { LeftIconButton } from '../../../ui/button/left-icon-button/left-icon-button';
import { EstimateListSP } from '../../layout/body/list/estimate/estimate-list';
import { BasePageSP } from '../base.page.sp';
import { EstimateEditSP } from './edit/estimate-edit.sp';
import { EstimateSearchBoxSP } from './serch-box/estimate-search-box.sp';
import { useWillUnMount, useDidMount } from '../../../../hooks/life-cycle';
import { EstimateActions } from '../../../../redux/estimate/estimate.action';
import { EstimateSortState, EstimateListType } from '../../../../type/estimate/estimate.type';
import { useAppSelector } from '../../../../hooks/use-redux';
import { EstimateModel } from '../../../../model/estimate/estimate-model';
import { TableSort } from '../../../ui/table/table-sort/table-sort';
import { useEstimateAuthority } from '../../../../hooks/use-authority';

export const EstimateSP = () => {
  const dispatch = useDispatch();
  const sortState = useAppSelector((v) => (v.estimate.sort));
  const user = useAppSelector((v) => v.user);
  const estimateAuth = useEstimateAuthority();
  const [hitCount, setHitCount] = useState(0);

  const [list, setList] = useState<null | EstimateListType[]>(null);

  const getList = useCallback((v?: EstimateSortState) => {
    dispatch(EstimateActions.api.estimate.getList({
      param: { ...EstimateModel.listParam(v || sortState) },
      callback: (res, hit) => {
        setHitCount(hit);
        dispatch(EstimateActions.setList(res));
        setList(res);
      },
    }));
  }, [sortState]);

  const handleClickNew = useCallback(() => {
    dispatch(DialogActions.push({
      title: '見積新規登録',
      className: 'estimate max_height_dialog',
      isCheck: true,
      element: <EstimateEditSP callbackGetList={() => getList()} />,
    }));
  }, [getList]);

  useEffect(() => {
    getList();
  }, [
    sortState.sort_by,
  ]);

  useWillUnMount(() => {
  });

  useDidMount(() => {
    if (!estimateAuth) dispatch(push('/'));
  });

  return (
    <BasePageSP
      className="estimate_sp"
      searchBoxDialog={{
        title: '詳細検索',
        element: <EstimateSearchBoxSP
          callback={(v) => {
            dispatch(EstimateActions.setList(null));
            dispatch(DialogActions.pop());
            getList(v);
          }}
        />,
      }}
      searchValue={sortState.keyword}
      searchCallback={(v) => {
        dispatch(EstimateActions.setList(null));
        dispatch(EstimateActions.setSort({ keyword: v, offset: 0 }));
        getList({ ...sortState, keyword: v, offset: 0 });
      }}
    >
      <div id="estimate_sp_header" className="map_list_header">
        <span>見積一覧</span>
      </div>
      <TableSort
        isSp
        page={sortState.offset ?? 0}
        limit={100}
        hitCount={hitCount || 0}
        callback={(v) => {
          dispatch(EstimateActions.setSort({ offset: v }));
          getList({ ...sortState, offset: v });
        }}
      />
      <div className="map_list_body">
        <EstimateListSP data={list} />
      </div>
      <div id="estimate_sp_footer" className="page_body_footer">
        <LeftIconButton
          fontAwesomeClass="fas fa-edit"
          label="見積新規登録"
          onClick={handleClickNew}
          size="md"
          color="primary"
        />
      </div>
    </BasePageSP>
  );
};
