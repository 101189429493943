import { EstimateMeisaiState, EstimateXML } from '../../../type/estimate/estimate.type';
import {
  ValidationLengthUnder10,
  ValidationLengthUnder13,
  ValidationLengthUnder500,
} from '..';
import { ValidationMax100Million } from '../validation-max-100-million';
import { ValidationMax10Billion } from '../validation-max-10-billion';
import { ValidationLengthUnder255 } from '../validation-length-under';
import { MeisaiListXML } from '../../estimate/estimate-meisai.type';

export const EstimateMeisaiValidation = (data: Partial<MeisaiListXML['$']>, remarks?: string) => {
  window.console.log();
  return (
  // 必須
    !data.daibunrui_id
    || !data.tyubunrui_id
    || !data.name
    || !data.tani_id

    // バリデーション
    || ValidationLengthUnder255.filter((v) => !v.run(String(data.name || ''))).length
    || ValidationLengthUnder255.filter((v) => !v.run(String(data.kikaku || ''))).length
    || ValidationMax100Million.filter((v) => !v.run(String(data.suryou || ''))).length
    || ValidationMax10Billion.filter((v) => !v.run(String(data.shikiri_kakaku || ''))).length
    || ValidationMax10Billion.filter((v) => !v.run(String(data.genka || ''))).length
    || ValidationLengthUnder500.filter((v) => !v.run(String(remarks || ''))).length
  );
};

export const EstimateMeisaiValidation2 = (data: {
  item_kubun: string,
  category: string,
  sub_category: string,
  construction_materials_name: string,
  standard:string,
  quantity: string,
  unit: string,
  quote_unit_price: string,
  prime_cost: string,
  // TODO 任意項目に変える
  remark: '',
}) => {
  window.console.log();
  return (
  // 必須
    !data.category
    || !data.sub_category
    // || !data.category_index
    // || !data.sub_category_index
    || !data.construction_materials_name
    || !data.unit

    // バリデーション
    || ValidationLengthUnder255.filter((v) => !v.run(String(data.construction_materials_name || ''))).length
    || ValidationLengthUnder255.filter((v) => !v.run(String(data.standard || ''))).length
    || ValidationMax100Million.filter((v) => !v.run(String(data.quantity || ''))).length
    || ValidationLengthUnder13.filter((v) => !v.run(String(data.quantity || ''))).length
    || ValidationMax10Billion.filter((v) => !v.run(String(data.quote_unit_price || ''))).length
    || ValidationLengthUnder10.filter((v) => !v.run(String(data.quote_unit_price || ''))).length
    || ValidationMax10Billion.filter((v) => !v.run(String(data.prime_cost || ''))).length
    || ValidationLengthUnder10.filter((v) => !v.run(String(data.prime_cost || ''))).length
    || ValidationLengthUnder500.filter((v) => !v.run(String(data.remark || ''))).length
  );
};
