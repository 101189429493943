/* eslint-disable no-nested-ternary */
import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { cloneDeep } from 'lodash';
import { DialogActions } from '../../../../../redux/dialog/dialog.action';
import { EditSP } from '../../../../dialogs/edit/edit.sp';
import { Button } from '../../../../ui/button/button';
import { TopLabelInputField } from '../../../../ui/input-field/top-label-input-field/top-label-input-field';
import './estimate-detail-edit.sp.scss';
import { useDidMount } from '../../../../../hooks/life-cycle';
import { Required } from '../../../../ui/required/required';
import { ValidationMax100Million } from '../../../../../model/validation/validation-max-100-million';
import { ValidationLengthUnder255, ValidationLengthUnder500 } from '../../../../../model/validation';
import { ValidationMax10Billion } from '../../../../../model/validation/validation-max-10-billion';
import { Select } from '../../../../ui/select/select';
import { MasterActions } from '../../../../../redux/master/master.action';
import { CommonCollection } from '../../../../../collection/common/common.collection';
import { AddMeisaiData, MeisaiListXML } from '../../../../../model/estimate/estimate-meisai.type';
import { useAppSelector } from '../../../../../hooks/use-redux';
import { pulldown } from '../../../../../utilities/pulldown';
import { LabelInput } from '../../../../ui/input/label-input';
import { cancelFigureSeparate } from '../../../../../utilities/format-num';
import { AddMasterMeisaiSP } from '../../estimate/meisai/add-master/add-master-meisai.sp';
import { InputNum } from '../../../../ui/input/input-num';
import { EstimateMeisaiModel } from '../../../../../model/estimate/estimate-meisai.model';
import { MathHelper } from '../../../../../utilities/math-helper';
import { EstimateMeisaiValidation } from '../../../../../model/validation/estimate/estimate-meisai.validation';
import { Message } from '../../../../../collection/message.collection';
import { MasterMiddleCategory } from '../../../../../type/master/master-middle-category.type';

type Props = {
  data?: MeisaiListXML;
  callback: (v: AddMeisaiData) => void;
  onMasterAdd: (v: MeisaiListXML[]) => void;
  disabled?: boolean;
  isCheck?: boolean;
};

export const EstimateDetailEditSP = (props: Props) => {
  const {
    data, callback, onMasterAdd, disabled, isCheck,
  } = props;

  /* Hook */
  const dispatch = useDispatch();
  const daibunruiList = useAppSelector((v) => v.master.largeCategoryList);
  const middleCategoryList = useAppSelector((v) => v.master.middleCategoryList);
  const meisaiList = useAppSelector((v) => v.master.meisaiList);
  const taniList = useAppSelector((v) => v.master.unitList);

  /* State */
  const [shohinCd, setShohinCd] = useState(data?.$.shohin_cd ?? '');
  const [kubun, setKubun] = useState(data?.$.shohin_kubun ?? '1');
  const [daibunrui, setDaibunrui] = useState(data?.$.daibunrui_id ?? '');
  const [printName, setPrintName] = useState(data?.$.print_name ?? '');
  const [tyubunrui, setTyubunrui] = useState(data?.$.tyubunrui_id ?? '');
  const [name, setName] = useState(data?.$.name ?? '');
  const [kikaku, setKikaku] = useState(data?.$.kikaku ?? '');
  const [suryo, setSuryo] = useState(data?.$.suryou
    ? MathHelper.rounding2Str(Number(data.$.suryou), 2, 'round', true)
    : '0');
  const [tani, setTani] = useState(data?.$.tani_id ?? '');
  const [shikirikakaku, setShikiriKakaku] = useState(
    data?.$.shikiri_kakaku
      ? MathHelper.rounding2Str(Number(data.$.shikiri_kakaku), 0, 'round', true)
      : '0',
  );
  const [genka, setGenka] = useState(
    data?.$.genka
      ? MathHelper.rounding2Str(Number(data.$.genka), 0, 'round', true)
      : '0',
  );
  const [remarks, setRemarks] = useState(data?.$.meisai_remark || '');
  const [touch, setTouch] = useState(false);
  const [_tyubunruiList, set_tyubunruiList] = useState<MasterMiddleCategory[]>([]);

  /* Callback */
  const handleClickSave = useCallback(() => {
    if (EstimateMeisaiValidation(
      {
        shohin_kubun: kubun,
        daibunrui_id: daibunrui,
        tyubunrui_id: tyubunrui,
        name,
        suryou: suryo,
        tani_id: tani,
        shikiri_kakaku: shikirikakaku,
        genka,
      },
      remarks,
    )) {
      dispatch(DialogActions.pushMessage({
        title: '見積明細作成',
        message: Message.postError,
        callback: () => setTouch(true),
      }));
      return;
    }
    const daibunrui_name = daibunruiList.find((v) => String(v.id) === daibunrui)?.name || '';
    const tyubunrui_name = middleCategoryList.find((v) => String(v.id) === tyubunrui)?.name || '';
    const tani_name = taniList.find((v) => String(v.id) === tani)?.name || '';
    const shohin_kubun_name = CommonCollection.shohinKubunList.find((v) => String(v.value) === kubun)?.text || '';
    const sendData = {
      shohin_cd: shohinCd,
      daibunrui_name,
      tyubunrui_name,
      shohin_kubun_name,
      shiiresaki_name: '',
      print_name: printName,
      tani_name,
      kikaku,
      suryou: cancelFigureSeparate(suryo),
      genka: cancelFigureSeparate(genka),
      shikiri_kakaku: cancelFigureSeparate(shikirikakaku),
      daibunrui_id: daibunrui,
      tyubunrui_id: tyubunrui,
      shohin_kubun: kubun,
      name,
      tani_id: tani,
      kingaku: shikirikakaku,
      arari_ritu: '0',
      meisai_remark: remarks,
    };
    callback(data ? {
      ...data.$,
      ...sendData,
    } : sendData);
  }, [
    shohinCd,
    data,
    kubun,
    daibunrui,
    tyubunrui,
    name,
    kikaku,
    suryo,
    tani,
    shikirikakaku,
    genka,
    data,
    taniList,
    daibunruiList,
    middleCategoryList,
    printName,
    callback,
    remarks,
  ]);

  const _meisaiList = useMemo(() => meisaiList.filter((v) => (
    !daibunrui
      ? true
      : !tyubunrui
        ? String(v.category_id) === daibunrui
        : String(v.category_id) === daibunrui
        && String(v.subcategory_id) === tyubunrui
  )), [meisaiList, daibunrui, tyubunrui]);

  const changeDaibunrui = useCallback((val: string | number) => {
    const v = String(val);
    if (v === daibunrui) return;
    if (!v) {
      setDaibunrui('');
      setTyubunrui('');
      setShohinCd('');
      set_tyubunruiList(cloneDeep(middleCategoryList));
      return;
    }
    setDaibunrui(String(v));
    setTyubunrui('');
    set_tyubunruiList(middleCategoryList.filter((t) => (String(t.category_id) === v)));
  }, [daibunrui, middleCategoryList]);

  const changeTyubunrui = useCallback((val: string | number) => {
    const v = String(val);
    if (v === tyubunrui) return;
    if (!v) {
      setTyubunrui('');
      setShohinCd('');
      return;
    }
    setTyubunrui(String(v));
    setShohinCd('');
  }, [tyubunrui]);

  const changeShohinCd = useCallback((cd: string) => {
    const find = meisaiList.find((v) => v.shohin_cd === cd);
    setShohinCd(find ? String(cd) : '');
    setKikaku(find ? String(find.standard) : '');
    setTani(find ? String(find.credit_id) : '');
    setName(find ? String(find.name) : '');
    setShikiriKakaku(find ? MathHelper.rounding2Str(Number(find.quote_unit_price), 0, 'round', true) : '');
    setGenka(find ? MathHelper.rounding2Str(Number(find.prime_cost), 0, 'round', true) : '');
    setSuryo(find ? MathHelper.rounding2Str(Number(find.quantity), 2, 'round', true) : '');
  }, [meisaiList]);

  const handleClickSearch = useCallback(() => {
    dispatch(DialogActions.push({
      title: '見積明細検索',
      element: <AddMasterMeisaiSP callback={onMasterAdd} />,
      className: 'add_master_meisai_dialog',
    }));
  }, [data, onMasterAdd]);

  /* Master */
  useDidMount(() => {
    dispatch(MasterActions.api.largeCategory.getList({}));
    dispatch(MasterActions.api.middleCategory.getList({}));
    dispatch(MasterActions.api.unit.getList({}));
    dispatch(MasterActions.api.meisai.getList({}));
  });

  useEffect(() => {
    if (middleCategoryList.length) {
      set_tyubunruiList(middleCategoryList.filter((
        v,
      ) => (!data?.$.daibunrui_id
        ? true
        : String(v.category_id) === data?.$.daibunrui_id
      )));
    }
  }, [middleCategoryList]);

  return (
    <EditSP mode={data ? 'update' : 'add'} disabled={disabled} callback={handleClickSave} isCheck={isCheck}>
      <div className="edit_sp_body_inner estimate_detail_edit_sp">
        <div className="category_wrap">
          <div className="item_wrap">
            <div className="item_label separate">
              <span>商品区分</span>
              {!data && (
                <Button
                  size="md"
                  color="secondary"
                  className="item_btn_right"
                  onClick={handleClickSearch}
                >
                  明細検索
                </Button>
              )}
            </div>
            <div className="item_body item_select full_width">
              <Select
                value={kubun}
                onChange={(v) => setKubun(String(v))}
                defaultLabel="指定無し"
                options={CommonCollection.shohinKubunList}
              />
            </div>
          </div>
          <div className="item_wrap">
            <div className="item_label">大分類<Required /></div>
            <div className="item_body item_select full_width">
              <Select
                value={daibunrui}
                onChange={changeDaibunrui}
                defaultLabel="指定無し"
                options={pulldown(daibunruiList)}
                require
                touch={touch}
              />
            </div>
          </div>
          <div className="item_wrap">
            <div className="item_label">中分類<Required /></div>
            <div className="item_body item_select full_width">
              <Select
                value={tyubunrui}
                onChange={changeTyubunrui}
                defaultLabel="指定無し"
                options={pulldown(_tyubunruiList)}
                require
                touch={touch}
              />
            </div>
          </div>
          <div className="item_wrap">
            {data?.$.shohin_cd
              ? (
                <Select
                  label="工事・資材名"
                  value={shohinCd}
                  onChange={(v) => changeShohinCd(String(v))}
                  defaultLabel="指定無し"
                  options={_meisaiList.map((v) => ({
                    text: v.name, value: v.shohin_cd,
                  }))}
                />
              )
              : (
                <TopLabelInputField
                  label="工事・資材名"
                  className="full_width"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  require
                  validationList={ValidationLengthUnder255}
                  touch={touch}
                />
              )}
          </div>
          <div className="item_wrap">
            <TopLabelInputField
              label="印刷名称"
              value={printName}
              onChange={(e) => setPrintName(e.target.value)}
              className="full_width"
            />
          </div>
          <div className="item_wrap">
            <TopLabelInputField
              label="規格"
              value={kikaku}
              onChange={(e) => setKikaku(e.target.value)}
              validationList={ValidationLengthUnder255}
              className="full_width"
            />
          </div>
          <div className="item_wrap">
            <LabelInput pos="Top" label="数量">
              <InputNum
                value={suryo}
                onBlur={(v) => setSuryo(EstimateMeisaiModel.setSuryo(v))}
                onChange={(v) => setSuryo(String(v))}
                intLen={8}
                decimalLen={2}
                validationList={ValidationMax100Million}
                minus
              />
            </LabelInput>
          </div>
          <div className="item_wrap">
            <div className="item_label">単位<Required /></div>
            <div className="item_body item_select full_width">
              <Select
                value={tani}
                onChange={(v) => setTani(String(v))}
                defaultLabel="指定無し"
                options={pulldown(taniList)}
                require
                touch={touch}
              />
            </div>
          </div>
          <div className="item_wrap">
            <LabelInput pos="Top" label="見積単価">
              <InputNum
                value={shikirikakaku}
                onBlur={(v) => setShikiriKakaku(
                  EstimateMeisaiModel.setNumber(
                    v,
                    (val) => MathHelper.rounding2Str(val, 0, 'round', true),
                  ),
                )}
                onChange={(v) => setShikiriKakaku(String(v))}
                intLen={10}
                validationList={ValidationMax10Billion}
                minus
              />
            </LabelInput>
          </div>
          <div className="item_wrap">
            <LabelInput pos="Top" label="原価単価">
              <InputNum
                value={genka}
                onBlur={(v) => setGenka(
                  EstimateMeisaiModel.setNumber(
                    v,
                    (val) => MathHelper.rounding2Str(val, 0, 'round', true),
                  ),
                )}
                onChange={(v) => setGenka(String(v))}
                intLen={10}
                validationList={ValidationMax10Billion}
                minus
              />
            </LabelInput>
          </div>
          <div className="item_wrap">
            <LabelInput
              pos="Top"
              label="備考"
              type="text"
              value={remarks}
              onChange={(v) => setRemarks(v)}
              className="full_width"
              validationList={ValidationLengthUnder500}
            />
          </div>
        </div>
      </div>
    </EditSP>
  );
};
