import * as lodash from 'lodash';
import { FileEditState, FileSortState } from '../../type/file/file.type';

export class FileCollection {
  private static _header = [
    'No.',
    'サムネイル',
    'ファイル名',
    '形式',
    'サイズ（KB）',
    'アップロード日時',
    '更新者',
    '顧客名',
    '案件名',
    '',
    '',
    '備考',
  ]

  private static _editInitialState(): Required<FileEditState> {
    return (lodash.cloneDeep({
      customer_id: NaN,
      project_id: NaN,
      file_name: [],
      file: [],
      comment: '',
    }));
  }

  private static _initialSortState(): FileSortState {
    return (lodash.cloneDeep({
      internal_id: '',
      min_size: '',
      max_size: '',
      customer_id: NaN,
      project_id: NaN,
      customer_name: '',
      project_name: '',
      file_name: '',
      upload_date_start: null,
      upload_date_end: null,
      word: '',
      no: '',
      format: '',
      size: '',
      updater: NaN,
      comment: '',
      offset: 0,
      limit: 100,
      sort_by: 0,
      highlow: 1,
      /** 登録店舗 */
      created_store_id: NaN,
      /** 登録社員 */
      created_employee_id: NaN,
      /** 更新店舗 */
      updated_store_id: NaN,
      /** 更新社員 */
      updated_employee_id: NaN,
      /** 登録日－期間開始 */
      created_at_start: null,
      /** 登録日－期間終了 */
      created_at_end: null,
      /** 更新日－期間開始 */
      updated_at_start: null,
      /** 更新日－期間終了 */
      updated_at_end: null,

    }));
  }

  static readonly disabledDownloadSP = [
    'avi',
    'doc',
    'docx',
    'zip',
    'pptx',
    'pub',
    '',
  ]

  static get header() {
    return FileCollection._header;
  }

  static get editInitialState() {
    return FileCollection._editInitialState;
  }

  static get initialSortState() {
    return FileCollection._initialSortState;
  }
}
