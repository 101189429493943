import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useMemo, useState } from 'react';
import { MasterActions } from '../../../../../redux/master/master.action';
import { DialogActions } from '../../../../../redux/dialog/dialog.action';
import { State } from '../../../../../redux/root.reducer';
import { Button } from '../../../../ui/button/button';
import { AuthActions } from '../../../../../redux/auth/auth.action';
import { ContractedCompanyMasterEditDialog } from '../../../pages/master/edit-dialogs/contracted-company/contracted-company-master-edit-dialog';
import { MasterCollection } from '../../../pages/master/master.collection';
import { MasterBody, MasterGetListParam } from '../../../pages/master/master-body/body/master-body';
import { ChangeCompanyBody } from './change-company-body';
import { ApiMasterContractedCompanyGetListParam } from '../../../../../redux/master/api/master-contracted-company/api-master-contracted-company.type';

export const MasterContractedChangeCompanyBody = () => {
  /* Hook */
  const list = useSelector((state:State) => state.master.contractedCompanyList);
  const dispatch = useDispatch();

  /* State */
  const [
    orderSort, setOrderSort,
  ] = useState<ApiMasterContractedCompanyGetListParam>({ highlow: 0, sort_by: 0 });
  const [isMuko, setIsMuko] = useState(false);
  const [companyName, setCompanyName] = useState('');
  const [isLoad, setIsLoad] = useState(false);

  /* Callback */
  const getList = useCallback((v: ApiMasterContractedCompanyGetListParam) => {
    setIsLoad(false);
    const sort_by = (() => {
      if (v.sort_by === 12) return 13;
      return v.sort_by ? v.sort_by - 1 : 0;
    })();
    dispatch(MasterActions.api.contractedCompany.getList({
      param: {
        sort_by,
        highlow: v.highlow,
        is_muko: 1,
        company_name: v.company_name,
      },
      onSuccess: () => {
        setIsLoad(true);
      },
    }));
    setOrderSort(v);
  }, [isMuko, companyName]);

  const tableList = useMemo(() => {
    const filter = isMuko ? list : list.filter((v) => v.valid_flag);
    return filter.map((v) => {
      const isValid = v.valid_flag ? <i className="far fa-circle" /> : '';
      const invitation = v.employee_status < 2 ? (
        <div>
          <label className="invitation_label">招待中</label>&nbsp;
        </div>
      ) : isValid;
      return ([
        v.id,
        v.name,
        v.mail_address,
        v.tel_no,
        v.post_no,
        v.prefecture,
        v.city,
        v.address,
        v.building_name,
        v.status === 1 ? '有償' : '無償',
        v.accounts,
        invitation,
      ]);
    });
  }, [list, isMuko]);

  const contractedChangeCompanyMasterHeader = [
    { key: 'checkbox', label: '' },
    { key: 'id', label: 'ID' },
    { key: 'company_name', label: '会社_名称' },
    { key: 'company_email_admin', label: '会社_メールアドレス（管理者用）' },
    { key: 'phone_num', label: '電話番号' },
    { key: 'address_postal_code', label: '住所_郵便番号' },
    { key: 'address_prefecture', label: '住所_都道府県' },
    { key: 'address_city', label: '住所_市区町村' },
    { key: 'address_place', label: '住所_地名・番地' },
    { key: 'address_building_name', label: '住所_建築名等' },
    { key: 'status', label: 'ステータス（有償・無償）' },
    { key: 'account-num', label: 'アカウント数' },
    { key: 'valid_flag', label: '有効フラグ' },
  ];

  return (
    <ChangeCompanyBody
      isLoad={isLoad}
      header={contractedChangeCompanyMasterHeader}
      rowDataList={list}
      list={tableList}
      callbackGetList={getList}
      defaultOrder={0}
      isNoEdit
      callbackIsMuko={setIsMuko}
      callbackCompanyName={setCompanyName}
      tableOption={{
        stringWidth: [
          { index: 0, width: 50 }, // 編集
          // { index: 1, width: 50 }, // ID
          // { index: 1, width: 100 }, // 会社_名称
          // { index: 2, width: 50 }, // 会社_メールアドレス（アドミン用）
          // { index: 3, width: 50 }, // 会社_パスワード（アドミン用）
          // { index: 4, width: 50 }, // 電話番号
          // { index: 5, width: 100 }, // 住所_郵便番号
          // { index: 6, width: 50 }, // 住所_都道府県
          // { index: 7, width: 50 }, // 住所_市区町村
          // { index: 8, width: 50 }, // 住所_地名・番地
          // { index: 9, width: 50 }, // 住所_建築名等
          // { index: 10, width: 50 }, // ステータス（有償・無償）
          // { index: 11, width: 50 }, // アカウント数
          // { index: 12, width: 50 }, // 有効フラグ
        ],
        tdAlign: [
          { index: 0, align: 'center' },
          { index: 1, align: 'center' },
          { index: 2, align: 'left' },
          { index: 3, align: 'left' },
          { index: 4, align: 'left' },
          { index: 5, align: 'left' },
          { index: 6, align: 'left' },
          { index: 7, align: 'left' },
          { index: 8, align: 'left' },
          { index: 9, align: 'left' },
          { index: 10, align: 'center' },
          { index: 11, align: 'center' },
          { index: 12, align: 'center' },
        ],
      }}
    />
  );
};
