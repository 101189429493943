import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { useInView } from 'react-intersection-observer';
import { CustomerCard } from '../../../../../ui/card/customer-card/customer-card';
import { State } from '../../../../../../redux/root.reducer';
import { RoutingPath } from '../../../../../../routes/routing-pass';
import { CustomerListType } from '../../../../../../type/customer/customer.type';
import { DialogActions } from '../../../../../../redux/dialog/dialog.action';
import { CardList } from '../../../../../ui/card/card-list/card-list';
import { useDidMount, useWillUnMount } from '../../../../../../hooks/life-cycle';
import { MasterActions } from '../../../../../../redux/master/master.action';

type Props = {
  type?: 0 | 1 | 2;
  data?: CustomerListType[] | null;
  handleCardClick?: (customer: CustomerListType) => void;
  callbackWillUnmount?: () => void;
}

export const CustomerListSP = (props: Props) => {
  const {
    type, data, handleCardClick, callbackWillUnmount,
  } = props;

  /* Hooks */
  // const customerListData = data || data === null
  //   ? data
  //   : useSelector((state: State) => state.customer.list);
  const customerListData = data;
  const dispatch = useDispatch();

  /* List */
  const customerList = useMemo(() => data, [data]);

  const { ref, inView } = useInView({
    // オプション
    rootMargin: '-50px', // ref要素が現れてから50px過ぎたら
    triggerOnce: true, // 最初の一度だけ実行
  });

  const handleClickCard = useCallback((id: number) => {
    if (handleCardClick) {
      const findData = customerList?.find((v) => v.id === id);
      if (findData) handleCardClick(findData);
      dispatch(DialogActions.pop());
      return;
    }
    dispatch(push(`${RoutingPath.customerDetail}/${id}/customer`));
  }, [customerList]);

  useDidMount(() => {
    dispatch(MasterActions.api.customerRank.getList({}));
  });

  useWillUnMount(() => {
    callbackWillUnmount?.();
  });

  return (
    <CardList isLoading={inView} list={customerList}>
      {customerList?.map((v, i) => (
        <div
          key={`card${i}`}
          className="list_base_card_wrap"
          ref={customerList.length - 1 ? ref : undefined}
        >
          <CustomerCard customerData={v} onClick={handleClickCard} index={i} />
        </div>
      ))}
    </CardList>
  );
};
