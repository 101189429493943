import { ValidationPassword } from '../validation-password';

export const PassWordChangeValidation = (
  password: string,
  confirmPassword: string,
) => {
  const massage = [];
  if (!password) massage.push('パスワードを入力してください');
  if (!confirmPassword) massage.push('確認用パスワードを入力してください');
  if (ValidationPassword.filter((v) => !v.run(String(password))).length) {
    massage.push('パスワードは半角英数字の８文字以上で入力してください');
  }
  if (ValidationPassword.filter((v) => !v.run(String(confirmPassword))).length) {
    massage.push('確認用パスワードは半角英数字の８文字以上で入力してください');
  }
  if (password !== confirmPassword) {
    massage.push('パスワードと確認用パスワードが異なります');
  }
  return massage;
};
