import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { cloneDeep } from 'lodash';
import { User } from '../../type/auth/user.typs';
import { UserActions } from './user.action';

export type UserState = User;

const initialState: UserState = {
  company_id: NaN,
  /** 社員マスタID */
  id: NaN,
  /** 名称 */
  name: '',
  /** 店舗ID */
  store_id: NaN,
  /** 社員CD */
  employee_cd: '',
  /** 都道府県 */
  prefecture: '',
  /** 都道府県ID */
  prefecture_id: NaN,
  /** ma */
  short_name: '',
  /** 社員名_フリガナ */
  furigana: '',
  /** 役職名 */
  job_title: '',
  /** 売上目標 */
  sales_target: '',
  /** メールアドレス */
  mail_address: '',
  /** パスワード */
  hashed_password: NaN,
  /**
   * 権限
   * 1:権限有
   * 0:権限無（自分の担当以外のデータ登録、修正が可能な権限）
   */
  authority1: NaN,
  /**
   * 権限4
   * 1:権限有
   * 0:権限無（マスタ管理の操作が可能な権限）
   */
  authority4: NaN,
  /**
   * 権限4
   * 1:権限有
   * 0:権限無（契約会社操作権限）
   */
  access_11: NaN,
  /** 店舗名 */
  store_name: '',
  /** 会社名 */
  company_name: '',
  /** 所属会社ステータス（有償／無償） */
  company_status: NaN,
  /**
   * 所属会社権限1
   * 1:有償
   * 2:無償
   */
  company_authority1: false,
  /**
   * 所属会社権限2
   * 顧客一覧 ストリートビュー画像表示機能
   * false:なし
   * true:あり
   */
  company_authority2: false,
  /**
   * 所属会社権限3
   * 案件一覧 ストリートビュー画像表示機能
   * false:なし
   * true:あり
   */
  company_authority3: false,
  /**
   * 所属会社権限4
   * 顧客一覧 ルート検索機能
   * false:なし
   * true:あり
   */
  company_authority4: false,
  /** 所属会社権限5
   * 顧客ランク 顧客ランク自動更新機能
   * false:なし
   * true:あり
   */
  company_authority5: false,
  /** 所属会社権限6
   * 見積作成 閲覧機能権限
   * true:あり
   * false:なし
   */
  company_authority6: false,
  /** 有効フラグ */
  valid_flag: false,

  /** 緯度経度 */
  store_lat: NaN,
  /** 緯度経度 */
  store_lng: NaN,
  /** 店舗電話番号 */
  store_tel_no: '',
  /** 店舗FAX番号 */
  store_fax_no: '',
  /** 店舗郵便番号 */
  store_post_no: '',
  /** 店舗市区町村 */
  store_city: '',
  /** 店地名・番地 */
  store_address: '',
  /** 店舗建築名等 */
  store_building_name: '',
  view_data: {
    company_id: NaN,
    id: NaN,
    name: '',
    store_id: NaN,
    employee_cd: '',
    prefecture: '',
    prefecture_id: NaN,
    short_name: '',
    furigana: '',
    job_title: '',
    sales_target: '',
    mail_address: '',
    hashed_password: NaN,
    authority1: NaN,
    authority4: NaN,
    access_11: NaN,
    store_name: '',
    company_name: '',
    company_status: NaN,
    company_authority1: false,
    company_authority2: false,
    company_authority3: false,
    company_authority4: false,
    company_authority5: false,
    company_authority6: false,
    valid_flag: false,
    store_lat: NaN,
    store_lng: NaN,
    store_tel_no: '',
    store_fax_no: '',
    store_post_no: '',
    store_city: '',
    store_address: '',
    store_building_name: '',
  },
};

export const UserReducer = reducerWithInitialState<UserState>(initialState)
  .case(UserActions.setUser, (state, payload) => {
    const data = payload ? cloneDeep(payload) : cloneDeep(initialState);
    return ({
      ...state,
      ...data,
    });
  })
  .case(UserActions.resetState, () => initialState)
  .default((state) => state);
