import actionCreatorFactory from 'typescript-fsa';
import { ApiBase } from '../../../../service/api-base';
import { CsvLostOrderType } from '../../../../type/csv/csv.type';

/* Param */
export type ApiCsvLostOrderGetListParam = Partial<{
  /** 見込みランク */
  project_rank: number;
  /** 見込みランクフィルタ */
  project_rank_filter: number;
  /** 最終見積金額（最小値） */
  estimate_kin_min: number;
  /** 最終見積金額（最大値） */
  estimate_kin_max: number;
  /** 粗利 */
  margin_ritu: number;
  /** 粗利フィルタ */
  margin_ritu_filter: number;
  /** 部位 */
  construction_parts: number[];
  /** 失注日（開始） */
  failure_date_from: string;
  /** 失注日（終了） */
  failure_date_to: string;
  /** 失注理由 */
  failure_cause: number;
  /** 失注フラグ */
  failure_flag: number;
  /** 案件担当（店舗） */
  project_store_id: number;
  /** 案件担当（担当者） */
  project_employee_id: number;
  /** 顧客名 */
  customer_name: string;
  /** 案件名 */
  name: string;
  /** 現場名称 */
  field_name: string;
  /** 現場電話番号 */
  field_tel_no: string;
  /** オフセット */
  offset: number;
  /** 1ページ内の件数 */
  limit: number;
  /** 並替基準列 */
  sort_by: number;
  /** 並替方法 */
  highlow: number;
  /** 登録店舗 */
  created_store_id:number;
  /** 登録社員 */
  created_employee_id:number;
  /** 更新店舗 */
  updated_store_id:number;
  /** 更新社員 */
  updated_employee_id:number;
  /** 登録日－期間開始 */
  created_at_start:string;
  /** 登録日－期間終了 */
  created_at_end:string;
  /** 更新日－期間開始 */
  updated_at_start:string;
  /** 更新日－期間終了 */
  updated_at_end:string;
}>;

export type ApiCsvLostOrderDownloadParam = any;

/* Response */
export type ApiCsvLostOrderGetListResponse = CsvLostOrderType;

export const ActionCreator = actionCreatorFactory('csv/api/lost/order');

export const apiCsvLostOrder = {
  getList: ActionCreator<ApiCsvLostOrderGetListParam>('get/list'),
  download: ActionCreator<ApiCsvLostOrderDownloadParam>('download'),
};

export class ApiCsvLostOrderGetList extends ApiBase<ApiCsvLostOrderGetListResponse> {
  constructor(param: ApiCsvLostOrderGetListParam) {
    super({
      httpMethod: 'GET',
      param,
      url: '/api/csv/lostorder',
    });
  }
}

export class ApiCsvLostOrderDownload extends ApiBase {
  constructor(param: ApiCsvLostOrderDownloadParam) {
    super({
      httpMethod: 'GET',
      contents: 'BLOB',
      param,
      url: '/api/csv/download/lostorder',
    });
  }
}
