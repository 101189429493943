import { useDragLayer, XYCoord } from 'react-dnd';
import { AccordionPreview } from './acordion-preview';
import './ghost.scss';
import { CardPreview } from './card-preview';

export const CustomerDragLayer = () => {
  const {
    isDragging,
    item,
    currentOffset,
    itemType,
  } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }));

  const getItemStyles = (off: XYCoord | null) => {
    if (!off) return { display: 'none' };
    // move position
    const { x, y } = off;
    const transform = `translate(0.5rem, ${y}px) scale(1)`;
    // const transform = `translate(${x}px, ${y}px) scale(1.05)`;
    return {
      WebkitTransform: transform,
      transform,
    };
  };

  const renderItem = () => {
    switch (itemType) {
      case 'daibunrui':
      case 'tyubunrui':
        return (
          <AccordionPreview
            className={itemType}
            tab={item.tab}
            label={item.label}
          />
        );
      case 'detail-card':
        return (
          <CardPreview
            name={item.name}
            shikiri_kakaku={item.shikiri_kakaku}
            tani_name={item.tani_name}
            kingaku={item.kingaku}
            suryou={item.suryou}
          />
        );
      default: return null;
    }
  };

  return (
    isDragging
      ? (
        <div className="draglayer">
          <div style={{ ...getItemStyles(currentOffset), width: 'calc(100% - 1rem)' }}>
            <div>
              {renderItem()}
            </div>
          </div>
        </div>
      )
      : null);
};
