import { memo } from 'react';
import { useDispatch } from 'react-redux';
import { DialogActions } from '../../../../../redux/dialog/dialog.action';
import { Button } from '../../../../ui/button/button';
import './estimate-detail-info.sp.scss';
import { DisplayElements } from '../../../../../type/display-elements.type';
import { MeisaiListXML } from '../../../../../model/estimate/estimate-meisai.type';
import { MathHelper } from '../../../../../utilities/math-helper';

const Item = (props: { title: DisplayElements; value: DisplayElements; }) => {
  const { title, value } = props;
  return (
    <div className="t_row">
      <div className="t_header">{title}</div>
      <div className="t_body">{value}</div>
    </div>
  );
};

type Props = {
  meisai: MeisaiListXML;
  onClickEdit: () => void;
  allowEdit?: 0 | 1 | 2;
  isMulti? : boolean;
}

export const EstimateDetailInfoSP = (props:Props) => {
  const {
    meisai, onClickEdit, allowEdit, isMulti,
  } = props;
  const dispatch = useDispatch();
  return (
    <>
      <div className="estimate_detail_info_sp base_dialog_content_inner_body">
        <div className="row_table_style">
          <Item title="商品区分" value={meisai.$.shohin_kubun_name} />
          <Item title="大分類" value={meisai.$.daibunrui_name_out} />
          <Item title="中分類" value={meisai.$.tyubunrui_name_out} />
          <Item title={<>工事・<br />資材名</>} value={meisai.$.name} />
          <Item title="印刷名称" value={meisai.$.print_name} />
          <Item title="規格" value={meisai.$.kikaku} />
          <Item title="数量" value={meisai.$.suryou} />
          <Item title="単位" value={meisai.$.tani_name} />
          <Item title="見積単価" value={<>¥{(MathHelper.rounding2Str(Number(meisai.$.shikiri_kakaku), 0, 'round', true) ?? '').toLocaleString()}</>} />
          <Item title="原価単価" value={<>¥{(MathHelper.rounding2Str(Number(meisai.$.genka), 0, 'round', true) ?? '').toLocaleString()}</>} />
          <Item title="備考" value={meisai.$.meisai_remark} />
        </div>
      </div>
      <div className="base_dialog_content_inner_footer">
        {(allowEdit === 1 && !isMulti)
        && (
        <Button
          size="md"
          color="secondary"
          className="item_btn_right"
          onClick={onClickEdit}
        >編集
        </Button>
        )}
        <Button
          size="md"
          color="dark"
          className="item_btn_right"
          onClick={() => dispatch(DialogActions.pop())}
        >戻る
        </Button>
      </div>
    </>
  );
};
