import actionCreatorFactory from 'typescript-fsa';
import { ApiBase } from '../../../../service/api-base';
import { CsvCustomerRankType } from '../../../../type/csv/csv.type';

/* Param */

export type ApiCsvMeisaiDownLoadParam = Partial<{
  /** 指定ID */
  ids: number[];
  /* オフセット */
  offset: number,
  /* 1ページ内の件数 */
  limit: number,
  /* 並替基準列 */
  sort_by: number,
  /* 並替方法 */
  highlow: number,
}>;

/* Response */

export const ActionCreator = actionCreatorFactory('csv/api/meisai');

export const apiCsvMeisai = {
  download: ActionCreator<ApiCsvMeisaiDownLoadParam>('download'),
};

export class ApiCsvMeisaiDownload extends ApiBase {
  constructor(param: ApiCsvMeisaiDownLoadParam) {
    super({
      httpMethod: 'GET',
      contents: 'BLOB',
      param,
      url: '/api/master/sizaikoji/detail/download/csv',
    });
  }
}
