/* eslint-disable no-nested-ternary */
import { cloneDeep } from 'lodash';
import {
  useCallback, useState, memo, useEffect, useMemo,
} from 'react';
import { useDispatch } from 'react-redux';
import { EstimateMeisaiModel } from '../../../../../model/estimate/estimate-meisai.model';
import { DialogActions } from '../../../../../redux/dialog/dialog.action';
import { Button } from '../../../../ui/button/button';
import { LeftIconButton } from '../../../../ui/button/left-icon-button/left-icon-button';
import { EstimateDetailListSP } from '../../../layout/body/list/estimate/estimate-detail-list';
import { EstimateDetailEditSP } from '../../estimate-detail/edit/estimate-detail-edit.sp';
import { EstimateEditDialogTitle } from '../edit/estimate-edit.type.sp';
import { useDidMount } from '../../../../../hooks/life-cycle';
import { IconButton } from '../../../../ui/button/icon-button/icon-button';
import './estimate-meisai.sp.scss';
import { MeisaiXML, MeisaiListXML } from '../../../../../model/estimate/estimate-meisai.type';
import { EstimateDetailMultiEditSP } from '../../estimate-detail/edit/estimate-detail-multi-edit.sp';
import { EstimateSearchSP } from '../../../layout/search-box/estimate/estimate-search/estimate-search.sp';
import { CopyPastEstimate } from '../copy-past-estimate/copy-past-estimate.sp';
import { DialogCssCollection } from '../../../../../collection/css/dialog.css.collection';
import { SystemActions } from '../../../../../redux/system/system.action';

type Props = {
  isDetail?: boolean;
  model: EstimateMeisaiModel | null;
  allowEdit: 0 | 1 | 2;
  callback: (v: EstimateMeisaiModel) => void;
  callbackPost: (v: EstimateMeisaiModel) => void;
  callbackCancel?: () => void;
  onChangeMode?: (v: boolean) => void;
}

export const EstimateMeisaiSP = (props: Props) => {
  const {
    isDetail,
    model,
    // model: _model,
    allowEdit,
    callback,
    callbackPost,
    onChangeMode,
    callbackCancel,
  } = props;

  const dispatch = useDispatch();

  /* State */
  const [detailMode, setDetailMode] = useState(isDetail);
  // const [model, setModel] = useState<EstimateMeisaiModel | null>(null);
  const [sortMode, setSortMode] = useState(false);
  const [multiMode, setMultiMode] = useState(false);
  const [cloneModel, setCloneModel] = useState<EstimateMeisaiModel | null>(null);

  const [selected, setSelected] = useState<MeisaiListXML[]>([]);

  /* Callback */
  /* 並び替え開始 */
  const startSort = useCallback(() => {
    if (!model) return;
    setCloneModel(cloneDeep(model));
    setSortMode(true);
  }, [model]);

  /* 並び替えの終了 true: 登録 false: キャンセル */
  const sortEnd = useCallback((isRegister: boolean = false) => {
    setSortMode(false);
    if (isRegister || !cloneModel) return;
    callback(cloneDeep(cloneModel));
    setCloneModel(null);
  }, [cloneModel]);

  /* 新規登録 */
  const add = useCallback(() => {
    if (!model) return;
    dispatch(DialogActions.push({
      title: EstimateEditDialogTitle.update,
      isCheck: true,
      element: <EstimateDetailEditSP
        isCheck
        callback={(v) => {
          dispatch(DialogActions.pop());
          model.add(cloneDeep(v));
          callback(cloneDeep(model));
        }}
        onMasterAdd={(data) => {
          dispatch(DialogActions.pop());
          dispatch(DialogActions.pop());
          model.addMaster(data);
          callback(cloneDeep(model));
        }}
      />,
    }));
  }, [model]);

  const copyPastEstimate = useCallback(() => {
    dispatch(DialogActions.push({
      title: '見積検索',
      element: <CopyPastEstimate
        callback={(v) => {
          if (!model) return;
          v.forEach((v2) => {
            model?.add({ ...cloneDeep(v2.$) });
          });
          callback(cloneDeep(model));
        }}
        allowEdit={allowEdit}
      />,
      // TODO 芳中さん 他箇所でも同様の対応される場合は下記classNameの変数化など扱いやすよう調整お願いいたします。
      className: DialogCssCollection.flexHeight,
    }));
  }, [model, allowEdit]);

  // useEffect(() => {
  //   setModel(_model);
  // }, [_model]);

  const allow = useMemo(() => {
    if (detailMode) return 0;
    return allowEdit;
  }, [allowEdit, detailMode]);

  return (
    <div className="estimate_meisai_sp">
      <div className="estimate_meisai_sp__header">
        <div><span>明細</span></div>
        <div className="estimate_meisai_sp__header__2th">
          {!detailMode && allowEdit === 1 && !sortMode && !multiMode && (
            <i
              className="fas fa-plus fa-2x"
              onClick={add}
            />
          )}
        </div>
        {allowEdit === 1
          ? (
            <div className="estimate_meisai_sp__header__last">
              {!detailMode
                ? !sortMode
                  ? !multiMode ? (
                    <>
                      {/* <Button onClick={startSort} size="md" color="white">
                        並べ替え
                      </Button> */}
                      <IconButton color="dark" onClick={() => copyPastEstimate()} fontAwesomeClass="fas fa-clock" className="copy_past_mode" />
                      <IconButton color="secondary" onClick={() => setMultiMode(true)} fontAwesomeClass="fas fa-tasks" className="multi_mode" />
                      <IconButton onClick={startSort} color="secondary" fontAwesomeClass="fas fa-exchange-alt" style={{ transform: 'rotate(90deg)' }} />
                      {isDetail
                        && (
                          <>
                            <IconButton
                              disabled={false}
                              fontAwesomeClass="fas fa-ban"
                              color="dark"
                              size="md"
                              onClick={() => {
                                dispatch(DialogActions.pushCheck({
                                  callback: () => {
                                    setDetailMode(true);
                                    onChangeMode?.(false);
                                    callbackCancel?.();
                                  },
                                }));
                              }}
                            />

                            <Button
                              color="primary"
                              disabled={false}
                              onClick={() => {
                                if (!model) return;
                                setDetailMode(true);
                                onChangeMode?.(false);
                                callbackPost(cloneDeep(model));
                              }}
                            >
                              更新
                            </Button>
                          </>
                        )}
                    </>
                  ) : (
                    <>
                      <LeftIconButton
                        label="全て選択"
                        fontAwesomeClass="fas fa-check-square"
                        size="sm"
                        color="secondary"
                        className="item_btn_left"
                        onClick={() => setSelected(cloneDeep(model?.list) || [])}
                      />
                      <LeftIconButton
                        label="全て解除"
                        fontAwesomeClass="far fa-square"
                        size="sm"
                        color="dark"
                        className="item_btn_left"
                        onClick={() => setSelected([])}
                      />
                      {!(selected.length < 2) && (
                      <IconButton
                        fontAwesomeClass="fas fa-edit"
                        color="secondary"
                        size="sm"
                        disabled={selected.length < 2}
                        onClick={() => {
                          if (selected.length < 2) return;
                          dispatch(DialogActions.push({
                            title: '見積一括編集',
                            element: <EstimateDetailMultiEditSP
                              callback={(v) => {
                                if (!model) return;
                                model.multiEdit(selected, v);
                                callback(cloneDeep(model));
                                setSelected([]);
                                // console.log(v);
                              }}
                            />,
                            isCheck: true,
                          }));
                        }}
                      />
                      )}
                      {(selected.length < 2) && (
                      <IconButton
                        fontAwesomeClass="fas fa-edit"
                        color="secondary"
                        size="sm"
                        disabled
                        onClick={() => {}}
                      />
                      )}
                      {!(selected.length < 1) && (
                      <IconButton
                        fontAwesomeClass="fas fa-copy"
                        color="secondary"
                        size="sm"
                        disabled={selected.length < 1}
                        onClick={() => {
                          if (!model) return;
                          const copy = (): Promise<void> => new Promise<void>((resolve) => {
                            model?.copyMeisai(selected)
                              .then((v) => {
                                callback(v);
                                setSelected([]);
                                resolve();
                              });
                          });

                          dispatch(DialogActions.pushMessage({
                            title: '明細の複写',
                            message: ['選択した明細を複写します。', 'よろしいですか？'],
                            isCancel: true,
                            cancelRight: true,
                            callback: () => {
                              if (selected.length > 30) {
                                dispatch(SystemActions.isLoading(true));
                                setTimeout(() => {
                                  copy().then(() => {
                                    dispatch(SystemActions.isLoading(false));
                                  });
                                }, 300);
                              } else {
                                copy();
                              }
                            },
                          }));
                        }}
                      />
                      )}
                      {(selected.length < 1) && (
                      <IconButton
                        fontAwesomeClass="fas fa-copy"
                        color="secondary"
                        size="sm"
                        disabled={selected.length < 1}
                        onClick={() => {}}
                      />
                      )}
                      <IconButton
                        fontAwesomeClass="fas fa-ban"
                        color="dark"
                        size="md"
                        onClick={() => {
                          setSelected([]);
                          setMultiMode(false);
                        }}
                      />
                    </>
                  )
                  : (
                    <>
                      <IconButton
                        fontAwesomeClass="fas fa-ban"
                        color="dark"
                        size="md"
                        onClick={() => sortEnd()}
                      />
                      {/* <LeftIconButton
                      label="並び替え登録"
                      size="md"
                      fontAwesomeClass="fas fa-edit"
                      className="btn_search for_detail"
                      color="primary"
                      onClick={() => sortEnd(true)}
                    /> */}
                      <IconButton
                        color="secondary"
                        fontAwesomeClass="fas fa-check"
                        onClick={() => sortEnd(true)}
                      />
                    </>
                  )
                : (
                  <>
                    <Button
                      color="secondary"
                      disabled={false}
                      onClick={() => {
                        setDetailMode((v) => !v);
                        onChangeMode?.(true);
                      }}
                    >
                      編集
                    </Button>
                  </>
                )}
            </div>
          ) : (<></>)}
      </div>
      <EstimateDetailListSP
        dndkey="detail-edit"
        sortMode={sortMode}
        multiMode={multiMode}
        model={model}
        callbackModel={(v) => callback(cloneDeep(v))}
        allowEdit={allow}
        selected={selected}
        callbackSelected={(v) => setSelected(v)}
      />
    </div>
  );
};
