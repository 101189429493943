import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';
import { goBack } from 'connected-react-router';
import { RegistrationAddressMapDialogPC } from '../../components/ui/map/registration-address-map-dialog/pc/registration-address-map-dialog.pc';
import { DialogActions } from '../../redux/dialog/dialog.action';
import { emailTemp, EmailTempProps } from '../../utilities/open-email';
import { openTel } from '../../utilities/open-tel';
import { useAppSelector } from '../use-redux';
import { MapActions } from '../../redux/map/map.action';
import { Address } from '../../type/map/map.type';
import { Message } from '../../collection/message.collection';
import { DialogBtnProps } from '../../type/dialog.type';
import { UserAgent } from '../../utilities/user-agent';
import { SearchPostAddressDialog, SearchPostAddressDialogProps } from '../../components/ui/search-post-address-dialog/search-post-address-dialog';
import { openSMS } from '../../utilities/open-link';
import { useDidMount } from '../life-cycle';
import { MasterActions } from '../../redux/master/master.action';
import { MasterEmployee } from '../../type/master/master-employee.type';

export const useTel = () => {
  const dispatch = useDispatch();

  return useCallback((param: {
    name: string;
    tel: string;
    callback: () => void;
    isSMS?: boolean;
  }) => {
    const {
      name, callback, tel, isSMS,
    } = param;

    dispatch(DialogActions.pushMessage({
      title: `${name}様`,
      message: isSMS ? Message.sms(tel) : Message.tel(tel),
      label: 'はい',
      isCancel: true,
      cancelLabel: 'いいえ',
      callback: () => {
        if (isSMS) {
          openSMS(tel);
        } else {
          openTel({ tel });
        }
        callback();
      },
    }));
  }, []);
};

export const useMail = () => {
  const dispatch = useDispatch();

  return useCallback((param: {
    name: string;
    email: EmailTempProps;
    callback: () => void;
  }) => {
    const { name, email, callback } = param;

    dispatch(DialogActions.pushMessage({
      title: `${name}様`,
      message: Message.mail(email.emailAddress),
      label: 'はい',
      isCancel: true,
      cancelLabel: 'いいえ',
      callback: () => {
        emailTemp(email);
        callback();
      },
    }));
  }, []);
};

/** 地図から入力 */
export const useRegistrationMap = (isNoSearch?: boolean) => {
  const centerPos = useAppSelector((v) => v.map.centerPos);
  const mapAreaPos = useAppSelector((v) => v.map.mapAreaPos);
  const zoomLevel = useAppSelector((v) => v.map.zoomLevel);

  const dispatch = useDispatch();

  return useCallback((callback: (v: Address | null) => void) => {
    const _centerPos = cloneDeep(centerPos);
    const _mapAreaPos = cloneDeep(mapAreaPos);
    const _zoomLevel = cloneDeep(zoomLevel);

    const clear = () => {
      dispatch(MapActions.setCenterPos(_centerPos));
      dispatch(MapActions.setZoomLevel(_zoomLevel));
      if (_mapAreaPos) {
        dispatch(MapActions.setMapAreaPosition(_mapAreaPos));
      }
    };

    dispatch(DialogActions.push({
      title: '住所を登録したい場所をクリックしてください',
      className: 'max_height_dialog map_dialog',
      onCloseClick: clear,
      element: <RegistrationAddressMapDialogPC
        label="登録"
        callback={(address) => {
          clear();
          callback(address);
        }}
      />,
    }));
  }, [centerPos, zoomLevel, mapAreaPos]);
};

/** 詳細に変なID打ち込まれたよう */
export const useDetailError = (id: string | number | undefined) => {
  const dispatch = useDispatch();

  return useCallback((target: string) => {
    if (!Number(id) || Number.isNaN(Number(id))) {
      dispatch(DialogActions.pushMessage({
        title: `${target}情報`,
        message: [`該当の${target}は存在しません`],
        callback: () => {
          dispatch(goBack());
        },
        callbackClose: () => {
          dispatch(goBack());
        },
      }));
      return true;
    }
    return false;
  }, [id]);
};

export const usePushMessage = () => {
  const dispatch = useDispatch();
  return useCallback((props:{
    title: string;
    message: string[];
    isCancel?: true;
    cancelLabel?: string;
    label?: string;
    callbackClose?:() => void;
    callback?:() => void; // ボタンがOKのみのCallback
    option?:{
      btnProps?: DialogBtnProps[];
      beforeClear?: boolean;
    };
      }) => {
    dispatch(DialogActions.pushMessage({ ...props }));
  }, [dispatch]);
};

export const usePostSearch = () => {
  const dispatch = useDispatch();

  return useCallback((param: SearchPostAddressDialogProps) => {
    dispatch(DialogActions.push({
      title: '住所検索',
      className: 'auto_height_dialog',
      element: <SearchPostAddressDialog
        {...param}
        calback={(v) => {
          param.calback(v);
        }}
      />,
    }));
  }, []);
};

export const useEmployeeList = (
  storeId: number,
  employeeList: MasterEmployee[],
) => useMemo(() => employeeList.filter((
  v,
) => (Number.isNaN(storeId) || storeId === null
  ? true
  : (v.store_id === storeId))),
[storeId, employeeList]);
