import { ValidationLengthUnder255 } from '..';

export const MasterAreaValidation = (storeId: number, name: string) => {
  window.console.log();
  return (
  // 必須
    (!storeId
   || !name

  // バリデーション
  || ValidationLengthUnder255.filter((v) => !v.run(String(name || ''))).length)
      ? ['未入力／入力不備項目があります']
      : []
  );
};
