import { ValidationEisuzi } from '../validation-eisuzi';

export const MasterCustomerExpectedRankValidation = (
  name: string, abbreviation: string, backGround: string, textColor: string,
) => {
  window.console.log();
  return (
    // 必須
    !name
    || !abbreviation
    || !backGround
    || !textColor

  // バリデーション
  || ValidationEisuzi.filter((v) => !v.run(String(abbreviation || ''))).length
  );
};
