import { useCallback, useEffect, useState } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { useDispatch } from 'react-redux';
import { EditPriceAreaState, Estimate } from '../../../../../../type/estimate/estimate.type';
import { MeisaiListXML } from '../../../../../../model/estimate/estimate-meisai.type';
import { GetTax } from '../../../../../../redux/master/api/master-tax/api-master-tax';
import { EstimateCalcModelPC as Calc } from '../../../../../../collection/estimate/estimate-calc.model.pc';
import { Input } from '../../../../../ui/input/input';
import { RightLabelInputField } from '../../../../../ui/input-field/right-label-input-field/right-label-input-field';
import { ValidationNumberLengthUnder20, ValidationNumberLengthUnder5 } from '../../../../../../model/validation/validation-number-length-under';
import { LeftLabelInputField } from '../../../../../ui/input-field/left-label-input-field/left-label-input-field';
import { MathHelper as MH } from '../../../../../../utilities/math-helper';
import { LabelInput } from '../../../../../ui/input/label-input';
import { InputNum } from '../../../../../ui/input/input-num';
import { NM } from '../../../../../../model/num-model';
import { EstimateMeisaiModel } from '../../../../../../model/estimate/estimate-meisai.model';
import { ListItem } from '../../../master/master-body/master-quote-fixed-body';
import { ValidationMax10Billion } from '../../../../../../model/validation/validation-max-10-billion';
import { ValidationMax100Thousand } from '../../../../../../model/validation/validation-max-100-thousand';
import { minus0 } from '../../../../../ui/search-post-address-dialog/search-post-address-dialog';
import { MasterActions } from '../../../../../../redux/master/master.action';
import { DateFormatter } from '../../../../../../utilities/date-formatter';

type Props = {
  info: Estimate | null;
  estimateDt: Date | null;
  state: EditPriceAreaState;
  model: EstimateMeisaiModel | null;
  reserve1: ListItem | null;
  reserve2: ListItem | null;
  callback: (v: EditPriceAreaState) => void;
  allowEdit: 0 | 1 | 2;
}

export const EstimatePriceAreaPC = (props: Props) => {
  const {
    info,
    estimateDt,
    state,
    model,
    callback,
    allowEdit = true,
    reserve1,
    reserve2,
  } = props;

  const dispatch = useDispatch();
  const [tax, setTax] = useState(0);

  const [tyoseikin, setTyoseikin] = useState(
    String(state.tyosei_kin),
  );
  const [reserve1EstimatePercent, setReserve1EstimatePercent] = useState(
    String(state.reserve1_estimate_percent),
  );
  const [reserve1GenkaPercent, setReserve1GenkaPercent] = useState(
    String(state.reserve1_genka_percent),
  );
  const [reserve2EstimatePercent, setReserve2EstimatePercent] = useState(
    String(state.reserve2_estimate_percent),
  );
  const [reserve2GenkaPercent, setReserve2GenkaPercent] = useState(
    String(state.reserve2_genka_percent),
  );

  /* 金額計算 */
  const calc = useCallback(() => {
    if (!model) return;
    console.log(tax);
    const res = Calc.calc(state, model.list, tax);
    callback(cloneDeep(res));
  }, [estimateDt, state, model, tax]);

  useEffect(() => {
    // GetTax(estimateDt || new Date()).then(setTax);
    dispatch(MasterActions.api.tax.getSoon({
      param: {
        start_date: DateFormatter.date2str(estimateDt),
      },
      onSuccess: (res) => {
        setTax(res.tax_rate);
      },
    }));
  }, [estimateDt]);

  useEffect(() => {
    calc();
  }, [estimateDt, model, info, tax]);

  useEffect(() => {
    setTyoseikin(Calc.setInfoNum(info?.tyosei_kin, 0));
  }, [info?.tyosei_kin]);
  useEffect(() => {
    if (reserve1)setReserve1EstimatePercent(String(reserve1.$.estimate));
    if (info)setReserve1EstimatePercent(Calc.setInfoNum(info?.reserve1_estimate_percent, 2));
  }, [info?.reserve1_estimate_percent, reserve1]);
  useEffect(() => {
    if (reserve1)setReserve1GenkaPercent(String(reserve1?.$.genka));
    if (info)setReserve1GenkaPercent(Calc.setInfoNum(info?.reserve1_genka_percent, 2));
  }, [info?.reserve1_genka_percent, reserve1]);
  useEffect(() => {
    if (reserve2)setReserve2EstimatePercent(String(reserve2?.$.estimate));
    if (info)setReserve2EstimatePercent(Calc.setInfoNum(info?.reserve2_estimate_percent, 2));
  }, [info?.reserve2_estimate_percent, reserve2]);
  useEffect(() => {
    if (reserve2)setReserve2GenkaPercent(String(reserve2?.$.genka));
    if (info) setReserve2GenkaPercent(Calc.setInfoNum(info?.reserve2_genka_percent, 2));
  }, [info?.reserve2_genka_percent, reserve2]);

  return (
    <div className="EstimatePriceAreaPC">
      <div className="item_wrap flex_no_wrap_box">
        <div className="item_box">
          <div className="item_head">見積金額</div>
          <Input
            value={MH.rounding2Str(state.zeinuki_kin, 0, 'round', true)}
            disabled
            alignRight
          />
        </div>
        <div className="item_box">
          <div className="item_head">見積合計</div>
          <Input
            value={MH.rounding2Str(state.gokei_zeinuki_kin, 0, 'round', true)}
            disabled
            alignRight
          />
        </div>
        <div className="item_box">
          <div className="item_head">消費税額</div>
          <Input
            value={MH.rounding2Str(state.shohizei_kin, 0, 'round', true)}
            disabled
            alignRight
          />
        </div>
        <div className="item_box">
          <div className="item_head">税込合計見積金額</div>
          <Input
            value={MH.rounding2Str(state.gokei_kin, 0, 'round', true)}
            disabled
            alignRight
          />
        </div>
        <div className="item_box">
          <div className="item_head">調整金額（値引き）</div>
          <InputNum
            value={tyoseikin}
            onBlur={(v) => {
              setTyoseikin(NM.setBlurNum(v, 0));
              calc();
            }}
            onChange={(v) => {
              setTyoseikin(v);
              state.tyosei_kin = Calc.onChangeNum(v);
              callback(cloneDeep(state));
            }}
            validationList={ValidationMax10Billion}
            disabled={allowEdit !== 1}
            intLen={10}
            minus
          />
        </div>
      </div>
      <div className="item_wrap flex_no_wrap_box">
        <div className="item_box">
          <div className="item_head">原価金額</div>
          <Input
            value={MH.rounding2Str(state.genka_kin, 0, 'round', true)}
            disabled
            alignRight
          />
        </div>
        <div className="item_box">
          <div className="item_head">原価合計</div>
          <Input
            value={MH.rounding2Str(state.gokei_zeinuki_genka_kin, 0, 'round', true)}
            disabled
            alignRight
          />
        </div>
        <div className="item_box">
          <div className="item_head">消費税額</div>
          <Input
            value={MH.rounding2Str(state.genka_shohizei_kin, 0, 'round', true)}
            disabled
            alignRight
          />
        </div>
        <div className="item_box">
          <div className="item_head">税込合計原価金額</div>
          <Input
            value={MH.rounding2Str(state.gokei_genka_kin, 0, 'round', true)}
            disabled
            alignRight
          />
        </div>
      </div>
      <div className="flex_no_wrap_box">
        <div className="left">
          <div className="item_wrap flex_no_wrap_box">
            <div className="item_box">
              <div className="item_head">粗利金額</div>
              <Input
                label=""
                value={MH.rounding2Str(state.arari_price, 0, 'round', true)}
                disabled
                alignRight
              />
            </div>
            <div className="item_box">
              <div className="item_head">粗利率</div>
              <RightLabelInputField
                label="%"
                type="number"
                value={state.arari_percent}
                disabled
                alignRight
              />
            </div>
          </div>
        </div>
        <div className="right">
          <div className="item_wrap flex_no_wrap_box flex_content_end">
            <div className="item_box">
              <div className="item_head">現場協力費</div>
              <LeftLabelInputField
                className="mr_10 w30"
                label="見積"
                value={MH.rounding2Str(state.reserve1_estimate_kin, 0, 'round', true)}
                disabled
                alignRight
              />
              <LabelInput pos="Right" label="%">
                <InputNum
                  value={reserve1EstimatePercent}
                  onBlur={(v) => {
                    setReserve1EstimatePercent(NM.setBlurNum(v, 2));
                    calc();
                  }}
                  onChange={(v) => {
                    console.log(v);
                    setReserve1EstimatePercent(v);
                    state.reserve1_estimate_percent = Calc.onChangeNum(v);
                    callback(cloneDeep(state));
                  }}
                  maxLength={5}
                  disabled={allowEdit !== 1}
                  minus
                />
              </LabelInput>
              <LeftLabelInputField
                label="原価"
                className="ml_10 mr_10 w30"
                value={MH.rounding2Str(state.reserve1_genka_kin, 0, 'round', true)}
                disabled
                alignRight
              />
              <LabelInput pos="Right" label="%">
                <InputNum
                  value={reserve1GenkaPercent}
                  onBlur={(v) => {
                    setReserve1GenkaPercent(NM.setBlurNum(v, 2));
                    calc();
                  }}
                  onChange={(v) => {
                    setReserve1GenkaPercent(v);
                    state.reserve1_genka_percent = Calc.onChangeNum(v);
                    callback(cloneDeep(state));
                  }}
                  disabled={allowEdit !== 1}
                  maxLength={5}
                  minus
                />
              </LabelInput>
            </div>
          </div>
          <div className="item_wrap flex_no_wrap_box flex_content_end">
            <div className="item_box">
              <div className="item_head">予備原価</div>
              <LeftLabelInputField
                className="mr_10 w30"
                label="見積"
                value={MH.rounding2Str(state.reserve2_estimate_kin, 0, 'round', true)}
                disabled
                alignRight
              />
              <LabelInput pos="Right" label="%">
                <InputNum
                  value={reserve2EstimatePercent}
                  onBlur={(v) => {
                    setReserve2EstimatePercent(NM.setBlurNum(v, 2));
                    calc();
                  }}
                  onChange={(v) => {
                    setReserve2EstimatePercent(v);
                    state.reserve2_estimate_percent = Calc.onChangeNum(v);
                    callback(cloneDeep(state));
                  }}
                  disabled={allowEdit !== 1}
                  maxLength={5}
                  minus
                />
              </LabelInput>
              <LeftLabelInputField
                className="ml_10 mr_10 w30"
                label="原価"
                value={MH.rounding2Str(state.reserve2_genka_kin, 0, 'round', true)}
                disabled
                alignRight
              />
              <LabelInput pos="Right" label="%">
                <InputNum
                  value={reserve2GenkaPercent}
                  onBlur={(v) => {
                    setReserve2GenkaPercent(NM.setBlurNum(v, 2));
                    calc();
                  }}
                  onChange={(v) => {
                    setReserve2GenkaPercent(v);
                    state.reserve2_genka_percent = Calc.onChangeNum(v);
                    callback(cloneDeep(state));
                  }}
                  disabled={allowEdit !== 1}
                  validationList={ValidationMax100Thousand}
                  maxLength={5}
                  minus
                />
              </LabelInput>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
