import lodash from 'lodash';
import { delay, put, takeLatest } from 'redux-saga/effects';
import { goBack, push } from 'connected-react-router';
import { ApiCustomer } from '../root.type';
import { ApiCustomerGet, ApiCustomerGetList, ApiCustomerPost } from './api/customer/api-customer';
import { CustomerActions } from './customer.action';
import { DialogActions } from '../dialog/dialog.action';
import {
  postHandle, getListHandle, getHandle, getError,
} from '../root.saga';
import { Store } from '../store';
import { SystemActions } from '../system/system.action';
import { RoutingPath } from '../../routes/routing-pass';
import { ResultCustomerType, ResultType } from '../../type/api.type';
import { ApiBase } from '../../service/api-base';
import { ApiUser } from '../user/api/api-user';
import { AuthActions } from '../auth/auth.action';
import { Message } from '../../collection/message.collection';
import { Config } from '../../config/config';

type PostParam = {
  isAllClear?: boolean;
  api: ApiBase;
  onSuccess?: (message:string[] | null | undefined, isWarning?: boolean) => void
  onError?: () => void;
  noMessage?: boolean;
  title?: string;
  noLoad?: boolean;
  update?: boolean;
  errorId?: number;
}
/**
 * Post用共通処理
 * @param param
 */
function* customerPostHandle(param:PostParam) {
  const {
    api, onSuccess, onError, isAllClear, /* onError, */ noMessage, title, noLoad, update,
  } = param;
  if (!noLoad) {
    yield put(SystemActions.isLoading(true));
  }
  try {
    const me = new ApiUser();
    const meRun: ResultType = yield me.run();
    if (ApiBase.isSuccess(meRun)) {
      yield put(AuthActions.setToken(me.token));
      yield delay(200);
      api.header = {
        ...api.header,
        'X-CSRF-TOKEN': me.token,
      };

      const result: ResultCustomerType = yield api.run();
      const isWaring = result.header.status === 'WARNING';
      if (ApiBase.isSuccess(result)) {
        if (noMessage) {
          if (isAllClear) yield put(DialogActions.clear());
          if (onSuccess) {
            yield onSuccess([''], isWaring);
          }
          return;
        }
        const _message = update ? Message.postUpdateComplete : Message.postComplete;
        console.log(isWaring ? 'waining' : 'true');
        yield put(DialogActions.pushMessage({
        /* TODO API接続後変更 */
          title: title ? `${title}${update ? '更新' : '登録'}${isWaring ? '確認' : '完了'}` : '',
          isCancel: isWaring ? true : undefined,
          urlIndex: isWaring ? 2 : undefined,
          linkUrl: isWaring ? `${Config.host}/#${RoutingPath.customerDetail}/${result.body.customer_id}` : undefined,
          message: result.header.messages?.length ? result.header.messages
            : _message,
          callback: () => {
            if (isWaring) {
              if (onSuccess) onSuccess([''], isWaring);
              console.log('callback');
            } else {
              if (isAllClear) Store.dispatch(DialogActions.clear());
              if (onSuccess) onSuccess(['']);
            }
          // if (onSuccess) onSuccess(result.header.messages);
          },
          callbackClose: () => {
            if (isWaring) {
              // if (onSuccess) onSuccess([''], isWaring);
              // Store.dispatch(DialogActions.pop());
            } else {
              if (isAllClear) Store.dispatch(DialogActions.clear());
              if (onSuccess) onSuccess(['']);
            }
          },
        }));
      } else {
        yield put(DialogActions.pushMessage({
          title: 'お知らせ',
          message: result.header.messages || [],
          callback: onError,
        }));
        // yield put(SystemActions.errorHandle({ result, title: title ? `${title}登録失敗` : '' }));
      }
    } else {
      yield put(DialogActions.pushMessage({
        title: 'お知らせ',
        message: meRun.header.messages || [],
        callback: () => {
          Store.dispatch(push(RoutingPath.login));
        },
      }));
    }
  } catch (e) {
    yield put(SystemActions.connectionError({
      message: ['インターネット接続の確認後に再度「登録／更新」を行ってください。'],
    }));
  }
  if (!noLoad) { yield put(SystemActions.isLoading(false)); }
}

function* tryCustomerGet(action: ReturnType<typeof CustomerActions.api.customer.get>) {
  const { param, callback, noLoad } = action.payload;
  if (!action.payload.param.id) {
    yield getError('顧客');
    return;
  }
  yield getHandle<ApiCustomer.Customer.Response.Get>({
    noLoad,
    api: new ApiCustomerGet(param),
    * onSuccess(result) {
      yield put(CustomerActions.setCustomer(result));
      if (callback && result) callback(lodash.cloneDeep(result));
    },
    * onError() {
      yield getError('顧客');
    },
  });
}

function* tryCustomerGetList(action: ReturnType<typeof CustomerActions.api.customer.getList>) {
  const {
    param, onSuccess, isLoad, isMapLoad, isRoute,
  } = action.payload;
  yield getListHandle<ApiCustomer.Customer.Response.List>({
    api: new ApiCustomerGetList(param),
    noLoad: !isLoad,
    mapLoad: isMapLoad,
    noDelay: true,
    * onSuccess(result, hitCount) {
      if (onSuccess) {
        onSuccess({
          data: result,
          hitCount,
        });
        return;
      }
      yield put(isRoute ? CustomerActions.setRouteList(result) : CustomerActions.setList(result));
      if (isRoute) return;
      yield put(CustomerActions.setListCount(hitCount));
    },
  });
}

function* tryCustomerGetCallbackList(action: ReturnType<
  typeof CustomerActions.api.customer.getCallbackList>) {
  const { param, onSuccess } = action.payload;
  yield getListHandle<ApiCustomer.Customer.Response.List>({
    noLoad: true,
    noDelay: true,
    api: new ApiCustomerGetList(param),
    * onSuccess(result, hitCount) {
      yield onSuccess(result, hitCount);
    },
  });
}

function* tryCustomerPost(action: ReturnType<typeof CustomerActions.api.customer.post>) {
  const {
    param, onSuccess, onError, update,
  } = action.payload;
  yield put(SystemActions.isLoading(true));
  yield customerPostHandle({
    title: '顧客情報',
    api: new ApiCustomerPost(param),
    update,
    onSuccess: (_, isWarning) => {
      if (isWarning) {
        Store.dispatch(CustomerActions.api.customer.post({
          param: {
            ...param,
            data: {
              ...param.data,
              is_forced: 1,
            },
          },
          onSuccess: () => onSuccess?.(),
        }));
      } else {
        if (onSuccess) onSuccess();
        Store.dispatch(DialogActions.pop());
      }
    },
    onError: () => {
      if (onError)onError();
    },
  });
  yield put(SystemActions.isLoading(false));
}

export function* CustomerSaga() {
  yield takeLatest(CustomerActions.api.customer.get, tryCustomerGet);
  yield takeLatest(CustomerActions.api.customer.post, tryCustomerPost);
  yield takeLatest(CustomerActions.api.customer.getList, tryCustomerGetList);
  yield takeLatest(CustomerActions.api.customer.getCallbackList, tryCustomerGetCallbackList);
}
