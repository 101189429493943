import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './file-list.pc.scss';
import { isEqual } from 'lodash';
import { State } from '../../../../../../redux/root.reducer';
import { DialogActions } from '../../../../../../redux/dialog/dialog.action';
import { Table } from '../../../../../ui/table/table';
import { FileEditPC } from '../../../../pages/file/edit/file-edit.pc';
import { FileActions } from '../../../../../../redux/file/file.action';
import { FileListType } from '../../../../../../type/file/file.type';
import { FileCollection } from '../../../../../../collection/file/file.collection';
import { DateFormatter } from '../../../../../../utilities/date-formatter';
import { LeftIconButton } from '../../../../../ui/button/left-icon-button/left-icon-button';
import { MathHelper } from '../../../../../../utilities/math-helper';
import { FileSize } from '../../../../pages/project-detail/file-table/file-size';
import { LoadingFileImg } from '../../../../../ui/file-img/loading-file-img';
import { Button } from '../../../../../ui/button/button';
import { Customer, CustomerListType } from '../../../../../../type/customer/customer.type';
import { Project, ProjectListType } from '../../../../../../type/project/project.type';
import { TableSort, Limit } from '../../../../../ui/table/table-sort/table-sort';

export type FileSizeData = { capacity: number, total: number; }

type Props = {
  selectId?: number;
  callbackGetList: () => void;
  fileSize?: FileSizeData;
  callbackStop?: (v: boolean) => void;
  sort?: (v: {highlow: number, sort_by: number}) => void,
  stopLoad?: boolean;
  data?: FileListType[] | null;
  orderData?: {
    customer: Customer | null;
    project: Project | ProjectListType | null;
    offset: number;
    limit: number;
    hitCount: number;
    pagerCallback: (limit: number, offset: number) => void;
  }
}

export const FileListPC = (props: Props) => {
  const {
    selectId, callbackGetList, fileSize, stopLoad, callbackStop, data, orderData, sort,
  } = props;

  /* Hooks */
  const isUseData = useMemo(() => (data || data === null), [data]);
  const fileList = isUseData ? data : useSelector((state: State) => state.file.list, isEqual);
  const dispatch = useDispatch();

  /* State */
  const [selected, setSelected] = useState<number[]>([]);

  const totalSize = useMemo(() => {
    if (!fileSize) return '';
    const kb = MathHelper.div(fileSize.total, 1024);
    if (kb > 1024) {
      const mb = MathHelper.div(kb, 1024);

      return `${MathHelper.rounding(mb, 2, 'ceil')}MB`;
    }
    return `${MathHelper.rounding(kb, 2, 'ceil')}KB`;
  }, [fileSize]);
  const percent = useMemo(() => {
    if (!fileSize) return 0;
    const per = (fileSize.total <= 0) ? 0 : MathHelper.rounding(
      MathHelper.times(
        MathHelper.div(fileSize.total, fileSize.capacity),
        100,
      ),
      0,
      'ceil',
    );
    return (per > 100) ? 100 : per;
  }, [fileSize]);

  /* Callback */
  const handleClickRow = useCallback((v: FileListType) => {
    if (!fileList) return;
    setSelected([fileList.findIndex((v2) => v.id === v2.id)]);
  }, [fileList]);

  const handleClickHeader = useCallback((highlow:0 | 1, sort_by: number) => {
    callbackStop?.(true);
    if (sort) {
      sort({ highlow, sort_by });
      return;
    }
    dispatch(FileActions.setSort({ highlow, sort_by }));
  }, [sort]);

  const handleClickDownload = useCallback((v: FileListType) => {
    callbackStop?.(true);
    dispatch(FileActions.api.file.download({
      param: { file_id: v.id },
      fileName: v.file_name || '',
      callback: () => callbackStop?.(false),
    }));
  }, []);

  const handleClickDelete = useCallback((v:FileListType) => {
    dispatch(DialogActions.pushMessage({
      title: 'ファイル削除',
      message: ['削除しますか'],
      isCancel: true,
      callback: () => {
        callbackStop?.(true);
        dispatch(FileActions.api.file.delete({
          param: { id: v.id },
          callback: () => {
            callbackGetList();
            callbackStop?.(false);
          },
        }));
      },
    }));
  }, [callbackGetList]);

  const handleDbClick = useCallback(
    (v: FileListType) => {
      if (!fileList) return;
      setSelected([fileList.findIndex((v2) => v2.id === v.id)]);
      callbackStop?.(true);
      dispatch(DialogActions.push({
        title: 'ファイル情報入力',
        onCloseClick: () => {
          callbackStop?.(false);
        },
        callback: () => callbackStop?.(false),
        element: <FileEditPC
          id={v.id}
          callback={callbackGetList}
          isOrder={!!orderData}
        />,
      }));
    },
    [callbackGetList, fileList, orderData],
  );

  const handleClickAdd = useCallback(() => {
    if (!orderData) return;
    const projectData = orderData.project || undefined;
    const customerData = orderData.customer || undefined;
    dispatch(DialogActions.push({
      title: 'ファイル情報入力',
      onCloseClick: () => callbackStop?.(false),
      callback: () => callbackStop?.(false),
      element: <FileEditPC
        callback={callbackGetList}
        customer={customerData}
        project={projectData}
        isOrder={!!orderData}
      />,
    }));
  }, [callbackGetList, orderData]);

  /* Effect */
  useEffect(() => {
    if (!fileList) return;
    if (!selectId) {
      setSelected([]);
      return;
    }
    setSelected([fileList.findIndex((v) => v.id === selectId)]);
  }, [selectId, fileList]);

  return (
    <section className="result_area list_area">
      <div className="flex_box mt_10 mb_10 mr_15">
        {!!orderData && (
        <div>
          <LeftIconButton
            label="ファイルアップロード"
            fontAwesomeClass="fas fa-cloud-upload-alt"
            className="btn_search for_detail"
            size="sm"
            color="secondary"
            onClick={() => handleClickAdd()}
          />
        </div>
        )}
        <div className="file_info" style={{ marginLeft: 'auto' }}>
          <FileSize
            percent={percent}
            totalSize={totalSize}
            maxSize={fileSize?.capacity || 0}
          />
        </div>
      </div>
      {!!orderData && (
        <div>
          <TableSort
            limit={orderData.limit as Limit}
            hitCount={orderData.hitCount}
            page={orderData.offset}
            callback={(page, limit) => orderData.pagerCallback(limit, page)}
          />
        </div>
      )}
      <div className="inner">
        <div className="table_responsive">
          <Table
            className="table_selectable table_sortable table_sticky table_cell_change"
            header={FileCollection.header}
            onClickRow={handleClickRow}
            sort={{
              index: [0, 1, 2, 3, 4, 5, 6, 7],
              onClick: handleClickHeader,
            }}
            selectedTr={selected}
            rowDataList={fileList || []}
            onDbClick={handleDbClick}
            lists={fileList ? fileList.map((v) => (
              [
                v.internal_id,
                <div>
                  <LoadingFileImg
                    id={v.id}
                    format={v.format}
                    fileName={v.file_name}
                    stopLoad={stopLoad}
                    sizeX={40}
                  />
                </div>,
                v.file_name,
                v.format,
                `${MathHelper.rounding(
                  MathHelper.div(Number(v.size), 1024), 2, 'floor',
                ).toLocaleString()} KB`,
                DateFormatter.date2str(v.upload_date, 'YYYYMMDD_HHmm'),
                v.updater,
                v.customer_name,
                v.project_name,
                <LeftIconButton
                  label="ダウンロード"
                  fontAwesomeClass="fas fa-file-download"
                  className="btn_search for_detail"
                  size="sm"
                  color="secondary"
                  onClick={() => handleClickDownload(v)}
                />,
                <LeftIconButton
                  label="削除"
                  fontAwesomeClass="fas fa-trash-alt"
                  className="btn_search for_detail"
                  size="sm"
                  color="dark"
                  onClick={() => handleClickDelete(v)}
                />,
                v.comment,
              ]
            )) : null}
            option={{
              stringWidth: [
                // { index: 0, width: 80 }, // No.
                // { index: 1, width: 100 }, // サムネイル
                // { index: 2, width: 100 }, // ファイル名
                // { index: 3, width: 50 }, // 形式
                // { index: 4, width: 50 }, //  サイズ（KB）
                // { index: 5, width: 120 }, // アップロード日時
                // { index: 6, width: 50 }, // 更新者
                // { index: 7, width: 50 }, // 顧客名
                // { index: 8, width: 50 }, // 案件名
                { index: 9, width: 100 }, // ダウンロード
                { index: 10, width: 100 }, // 削除
                // { index: 11, width: 100 }, // 備考
              ],
              tdAlign: [
                { index: 0, align: 'center' },
                { index: 1, align: 'center' },
                { index: 2, align: 'center' },
                { index: 3, align: 'center' },
                { index: 4, align: 'right' },
                { index: 5, align: 'center' },
                { index: 6, align: 'center' },
                { index: 7, align: 'center' },
                { index: 8, align: 'center' },
                { index: 9, align: 'center' },
                { index: 10, align: 'center' },
                { index: 11, align: 'left' },
              ],
            }}
          />
        </div>
      </div>
    </section>
  );
};
