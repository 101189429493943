import { cloneDeep } from 'lodash';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CustomerDetailActions } from '../../../../../redux/customer-detail/customer-detail.action';
import { DialogActions } from '../../../../../redux/dialog/dialog.action';
import { SupportHistorySortState } from '../../../../../type/support-history/support-history.type';
import { DatePicker } from '../../../../ui/date-picker/date-picker';
import { Input } from '../../../../ui/input/input';
import { Select } from '../../../../ui/select/select';
import { RefindSP } from './refind.sp';
import { useAppSelector } from '../../../../../hooks/use-redux';
import { MasterActions } from '../../../../../redux/master/master.action';
import { useDidMount } from '../../../../../hooks/life-cycle';
import { changeStore, sortEmployee } from '../../../../../utilities/sort-employee';
import { pulldown } from '../../../../../utilities/pulldown';
import { Project } from '../../../../../type/project/project.type';

type Props = {
  callback: (sort: SupportHistorySortState) => void;
  info?: Project | null
}

export const RefindSupportHistorySP = (props: Props) => {
  const { callback, info } = props;

  /* Hook */
  const dispatch = useDispatch();
  const sort = useAppSelector((v) => v.customerDetail.supportSort);
  const supportHistoryList = useAppSelector((v) => v.master.supportHistoryList);
  const {
    employeeList,
    storeList,
    originList,
  } = useAppSelector((v) => v.master);

  /* State */
  const [sortState, setSortState] = useState(cloneDeep(sort));

  /* Callback */
  const handleClickSearch = useCallback(() => {
    dispatch(DialogActions.pop());
    callback(sortState);
  }, [callback, sortState]);

  const setState = useCallback((v: Partial<SupportHistorySortState>) => {
    const data = {
      ...cloneDeep(sortState),
      ...cloneDeep(v),
    };

    setSortState(data);
    dispatch(CustomerDetailActions.setSupportSort(v));
  }, [dispatch]);

  useDidMount(() => {
    dispatch(MasterActions.api.supportHistory.getList({}));
    dispatch(MasterActions.api.employee.getList({}));
    dispatch(MasterActions.api.store.getList({}));
  });
  return (
    <RefindSP callback={handleClickSearch}>
      <>
        <div className="category_wrap">
          <div className="item_wrap">
            <div className="item_label">対応</div>
            <Select
              className=""
              value={sortState.support_flag}
              defaultLabel="指定なし"
              onChange={(v) => setState({ support_flag: Number(v) })}
              options={[
                { text: '未対応', value: 1 },
                { text: '対応済み', value: 2 },
              ]}
            />
          </div>
          <div className="item_wrap">
            <div className="item_label">案件名</div>
            <Input
              value={!info ? sort.project_name : info.name}
              onChange={(e) => setState({ project_name: e.target.value })}
              disabled={info !== undefined}
            />
          </div>
          <div className="item_wrap">
            <div className="item_label">登録日</div>
            <DatePicker
              date={sort.contact_dt || null}
              onChange={(v) => setState(
                { contact_dt: v },
              )}
            />
          </div>
          <div className="item_wrap">
            <div className="item_label">登録担当店舗</div>
            <Select
              className=""
              value={!info ? sortState.created_store_id : info.created_store_id}
              defaultLabel="指定なし"
              disabled={info !== undefined}
              onChange={(v) => setState(changeStore({
                employeeList,
                storeId: Number(v),
                storeKey: 'store_id',
                employeeKey: 'created_employee_id',
                returnStoreKey: 'created_store_id',
              }))}
              options={pulldown(storeList)}
            />
          </div>
          <div className="item_wrap">
            <div className="item_label">登録担当者</div>
            <Select
              className=""
              value={!info ? sortState.created_employee_id : info.created_employee_id}
              disabled={info !== undefined}
              defaultLabel="指定なし"
              onChange={(v) => setState({ created_employee_id: Number(v) })}
              options={pulldown(sortEmployee({
                employeeList,
                storeId: sortState.created_store_id,
                storeKey: 'store_id',
              }))}
            />
          </div>

          <div className="item_wrap">
            <div className="item_label">カテゴリ</div>
            <Select
              className=""
              value={sortState.support_id}
              defaultLabel="指定なし"
              onChange={(v) => setState({ support_id: Number(v) })}
              options={supportHistoryList.map((v) => ({
                text: v.supported, value: v.id,
              }))}
            />
          </div>
          <div className="item_wrap">
            <div className="item_label">媒体</div>
            <Select
              className=""
              value={sortState.source_id}
              defaultLabel="指定なし"
              onChange={(v) => setState({ source_id: Number(v) })}
              options={pulldown(originList)}
            />
          </div>

          <div className="item_wrap">
            <div className="item_label">対応履歴名</div>
            <Input
              value={sort.title}
              onChange={(e) => setState({ title: e.target.value })}
            />
          </div>

          <div className="item_wrap">
            <div className="item_label">対応内容</div>
            <Input
              value={sort.comment}
              onChange={(v) => setState({ comment: v.target.value })}
            />
          </div>

          <div className="item_wrap">
            <div className="item_label">対応店舗</div>
            <Select
              className=""
              value={sortState.support_store_id}
              defaultLabel="指定なし"
              onChange={(v) => {
                setState(changeStore({
                  employeeList,
                  storeId: Number(v),
                  storeKey: 'store_id',
                  returnStoreKey: 'support_store_id',
                  employeeKey: 'support_employee_id',
                }));
              }}
              options={pulldown(storeList)}
            />
          </div>

          <div className="item_wrap">
            <div className="item_label">対応者</div>
            <Select
              className=""
              value={sortState.support_employee_id}
              defaultLabel="指定なし"
              onChange={(v) => setState({ support_employee_id: Number(v) })}
              options={employeeList.map((v) => ({
                text: v.name, value: v.id,
              }))}
            />
          </div>
          <div className="item_wrap">
            <div className="item_label">対応日</div>
            <DatePicker
              date={sort?.support_dt || null}
              onChange={(v) => setState(
                { support_dt: v },
              )}
            />
          </div>
        </div>
      </>
    </RefindSP>
  );
};
