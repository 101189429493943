import { memo } from 'react';
import { useDispatch } from 'react-redux';
import { SubWindow, formatList } from './sub-window';
import { useDidMount } from '../../../../../../../hooks/life-cycle';
import { DialogActions } from '../../../../../../../redux/dialog/dialog.action';
import { MasterActions } from '../../../../../../../redux/master/master.action';
import { useAppSelector } from '../../../../../../../hooks/use-redux';

type Props = {
  open: boolean;
  callbackClose: () => void;
}

const master = 'part';
const listName = 'partList';

export const Part = memo((props: Props) => {
  const { open, callbackClose } = props;

  const dispatch = useDispatch();
  const list = useAppSelector((v) => v.master[listName]);

  useDidMount(() => dispatch(MasterActions.api[master].getList({})));

  return (
    <SubWindow
      open={open}
      title="部位"
      header={['id', '名前']}
      lists={formatList(list)}
      callbackClose={callbackClose}
    />
  );
});
