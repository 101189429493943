/* eslint-disable no-irregular-whitespace */
import { useState } from 'react';
import { Config } from '../config/config';

type Props = {
  callback: (id: string, pass: string) => void;
};

const account = [
  { name: 'SHIP', id: 'ship@example.com' },
  { name: '萩原のadmin', id: 'admin@example.com' },
  { name: '契約会社1のadmin', id: 'admin2@example.com' },
  { name: '萩原の一般社員', id: 'test0@example.com' },
  { name: '契約会社1の一般社員', id: 'test1@example.com' },
  { name: '見積無効', id: 'kiyabo133@magim.be' },
];
const pass = 'ma123456';

export const Debug = ({ callback }: Props) => {
  const [state, setstate] = useState(NaN);

  return (Config.mode === 'local' ? (
    <div style={{
      border: 'solid 1px black',
      width: '500px',
      margin: '0 auto',
    }}
    >
      <h4 style={{
        textAlign: 'center',
        background: '#74bad4',
      }}
      >Debug Account
      </h4>
      {account.map((v, i) => (
        <div
          key={i}
          style={{
            marginTop: '10px',
            borderTop: 'solid 1px gray',
            backgroundColor: state === i ? 'yellow' : 'white',
            textAlign: 'center',
          }}
          onClick={() => {
            setstate(i);
            callback(v.id, pass);
          }}
        >
          {v.name}
        </div>
      ))}
    </div>
  ) : (
    <></>
  ));
};
