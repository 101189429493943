import { isEqual } from 'lodash';
import { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useWillUnMount } from '../../../../../../hooks/life-cycle';
import { CsvActions } from '../../../../../../redux/csv/csv.action';
import { State } from '../../../../../../redux/root.reducer';
import { Limit, TableSort } from '../../../../../ui/table/table-sort/table-sort';
import { CsvListMaintenance } from '../../list/maintenance/csv-list-maintenance';
import { CsvSearchBoxMaintenance } from '../../search-box/maintenance/csv-search-box-maintenance';
import { CsvMaintenanceSort } from '../../../../../../type/csv/csv-sort.type';
import { DateFormatter } from '../../../../../../utilities/date-formatter';
import { useAppSelector } from '../../../../../../hooks/use-redux';
import { CsvModel } from '../../../../../../model/csv/csv-model';

export const CsvMaintenance = () => {
  /* Hooks */
  const dispatch = useDispatch();
  const sortState = useAppSelector((v) => v.csv.maintenanceSort);
  const list = useAppSelector((v) => v.csv.maintenanceList);
  const hitCount = useAppSelector((v) => (v.csv.maintenanceCount));

  /* Callback */
  const getList = useCallback((v?: CsvMaintenanceSort) => {
    const sortData = v || sortState;
    dispatch(CsvActions.api.maintenance.getList(CsvModel.maintenance(sortData)));
  }, [sortState]);

  const changePagination = useCallback((offset:number, limit:Limit) => {
    dispatch(CsvActions.setMaintenanceList(null));
    dispatch(CsvActions.setMaintenanceSort({ offset, limit }));
    getList({ ...sortState, offset, limit });
  }, [sortState]);

  /* Effect */
  useEffect(() => {
    getList();
  }, [
    sortState.highlow,
    sortState.sort_by,
  ]);

  return (
    <>
      <CsvSearchBoxMaintenance
        callback={(v) => {
          dispatch(CsvActions.setMaintenanceList(null));
          getList(v);
        }}
      />
      <TableSort
        page={sortState.offset || 0}
        limit={sortState.limit as Limit}
        hitCount={hitCount || 0}
        callback={changePagination}
        noAnnotation
      />
      <CsvListMaintenance />
      <TableSort
        className="bottom"
        page={sortState.offset || 0}
        limit={sortState.limit as Limit}
        hitCount={hitCount || 0}
        callback={changePagination}
        noAnnotation
      />
    </>
  );
};
