import { FileEditState } from '../../../type/file/file.type';
import {
  ValidationLengthUnder40,
  ValidationLengthUnder500,
  ValidationLengthUnder60,
} from '..';

export const FileValidation = (
  data: FileEditState,
  customerName: string,
  projectName: string,
  id: number | undefined,
) => {
  const file_name = data.file_name.every((
    v,
  ) => ValidationLengthUnder40.filter((val) => !val.run(String(v || ''))).length);

  return !id ? (
    // 必須
    !data.customer_id
    || !data.file_name.length
    || !data.file.length
    || file_name
  // バリデーション
  || ValidationLengthUnder500.filter((v) => !v.run(String(data.comment || ''))).length
  ) : (
    // 必須
    !data.customer_id
      || !data.file_name.length
      || file_name

  // バリデーション
  || ValidationLengthUnder500.filter((v) => !v.run(String(data.comment || ''))).length
  );
};
