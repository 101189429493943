import { PdfModule } from '../pdf-module';
import template from '../template/print_bill_1.svg';
import { HAGIWARA_LOGO_PNG } from '../template/hagiwara_logo';

const replaceData = [
  { from: '%%printBill1_1_%%', to: 'name' },
  { from: '%%printBill1_2_%%', to: 'no' },
  { from: '%%printBill1_3_%%', to: 'date' },
  { from: '%%printBill1_4_%%', to: 'price' },
  { from: '%%printBill1_5_%%', to: 'excludingTax' },
  { from: '%%printBill1_6_%%', to: 'consumptionTax' },
  { from: '%%printBill1_7_%%', to: 'projectName' },
  { from: '%%printBill1_8_%%', to: 'field1' },
  { from: '%%printBill1_9_%%', to: 'field2' },
  { from: '%%printBill1_10_%%', to: 'bankTitleX' },
  { from: '%%printBill1_11_%%', to: 'bankTitleLineX' },
  { from: '%%printBill1_12_%%', to: 'bankNameX' },
  { from: '%%printBill1_13_%%', to: 'shopNameX' },
  { from: '%%printBill1_14_%{i}_%%', to: 'banks0' },
  { from: '%%printBill1_15_%{i}_%%', to: 'banks1' },
  { from: '%%printBill1_16_%{i}_%%', to: 'banks2' },
  { from: '%%printBill1_17_%{i}_%%', to: 'banks3' },
  { from: '%%printBill1_30_%%', to: 'icon' },
  { from: '%%printBill1_31_%%', to: 'company' },
  { from: '%%printBill1_32_%%', to: 'company2' },
  { from: '%%printBill1_33_%%', to: 'post' },
  { from: '%%printBill1_34_%%', to: 'address' },
  { from: '%%printBill1_35_%%', to: 'tel' },
  { from: '%%printBill1_36_%%', to: 'fax' },
  { from: '%%printBill1_37_%%', to: 'freeDial' },
  { from: '%%printBill1_38_%%', to: 'directorName' },
  { from: '%%printBill1_39_%%', to: 'nameCharge' },
  { from: '%%printBill1_40_%%', to: 'customerTel' },
  { from: '%%printBill1_41_%%', to: 'remarks' },
  { from: '%%printBill1_42_%%', to: 'iconTransform' },
  { from: '%%printBill1_43_%%', to: 'project_store_holder' },
  { from: '%%printBill1_44_%%', to: 'field3' },
  { from: '%%printBill1_45_%%', to: 'adjustProjectName' },
];

export type BanksParam = {
  // 銀行名
  banks0: string,
  // 支店名
  banks1: string,
  // 普通預金
  banks2: string,
  // ナンバー
  banks3: string,
}
export type BillParam = {
  // 顧客名
  name: string,
  // 文字サイズ調整用
  adjustName?: number,
  // No.
  no: string,
  // 日付
  date: string,
  // 金額
  price: string,
  // 税抜き請求金額
  excludingTax: string,
  // 消費税額
  consumptionTax: string,
  // 工事名称
  projectName: string,
  // 工事場所1
  field1: string,
  // 工事場所2
  field2: string,
  // 顧客電話番号
  customerTel: string,
  // 備考
  remarks: string,
  // 振込先 (max : 6)
  banks: BanksParam[],
  /** 案件担当店舗-口座名義 */
  project_store_holder: string;
  // 振込先のX座標
  bankTitleX: number, // 振込先
  bankTitleLineX: number, // 振込先左右のライン
  bankNameX: number, // 銀行名
  shopNameX: number, // 支店名
  // アイコン
  icon: string,
  /** ロゴ画像の縦幅 */
  iconHeight: number,
  /** ロゴ画像の横幅 */
  iconWidth: number,
  /** ロゴ画像のスケール、位置調整 */
  iconTransform: string,
  // 会社名
  company: string,
  // 会社名2
  company2: string,
  // 郵便番号
  post: string,
  // 住所
  address: string,
  // 電話番号
  tel: string,
  // FAX
  fax: string,
  // フリーダイヤル
  freeDial: string,
  // 代表取締役名
  directorName: string,
  // 担当者名
  nameCharge: string,
};

const defaultStr = '---';

const defaultProps = {
  // 顧客名
  name: defaultStr,
  adjustNumber: undefined,
  // No.
  no: defaultStr,
  // 日付
  date: defaultStr,
  // 金額
  price: defaultStr,
  // 税抜き請求金額
  excludingTax: defaultStr,
  // 消費税額
  consumptionTax: defaultStr,
  // 工事名称
  projectName: defaultStr,
  // 工事場所1
  field1: defaultStr,
  // 工事場所2
  field2: defaultStr,
  // 顧客電話番号
  customerTel: defaultStr,
  // 備考
  remarks: defaultStr,
  // 振込先の座標
  bankTitleX: 0,
  bankTitleLineX: 0,
  bankNameX: 0,
  shopNameX: 0,
  // 振込先 (max : 5)
  banks: [
    {
      banks0: '銀行名',
      banks1: '支店名',
      banks2: '普通預金',
      banks3: 'No.0000000',
    },
    {
      banks0: '銀行名',
      banks1: '支店名',
      banks2: '普通預金',
      banks3: 'No.0000000',
    },
    {
      banks0: '銀行名',
      banks1: '支店名',
      banks2: '普通預金',
      banks3: 'No.0000000',
    },
  ],
  /** 案件担当店舗-口座名義 */
  project_store_holder: defaultStr,
  // アイコン
  icon: HAGIWARA_LOGO_PNG,
  /** ロゴ画像の縦幅 */
  iconHeight: 0,
  /** ロゴ画像の横幅 */
  iconWidth: 0,
  /** ロゴ画像のスケール、位置調整 */
  iconTransform: '',
  // 会社名
  company: defaultStr,
  // 会社名2
  company2: defaultStr,
  // 郵便番号
  post: defaultStr,
  // 住所
  address: defaultStr,
  // 電話番号
  tel: defaultStr,
  // FAX
  fax: defaultStr,
  // フリーダイヤル
  freeDial: defaultStr,
  // 代表取締役名
  directorName: defaultStr,
  // 担当者名
  nameCharge: defaultStr,
};

/**
 * 請求書
 */
export class BillPdfModule extends PdfModule<BillParam> {
  constructor() {
    super(
      [
        template,
      ],
      replaceData,
    );
  }

  createSvg(
    _param: BillParam,
    templateSvgStrList: string[] = this.templateSvgStrCollection,
  ): string[] {
    const { iconHeight, iconWidth } = _param;

    const height = 19;
    const maxLength = 6;
    // 1ページ分しか出力しない
    const maxPage = 1;
    const templateSvgStr = templateSvgStrList[0] || '';
    // 動的に座標調整処理 (振込先)
    const bankPosInfos = this.getAdjustBankLineWidth(_param.banks);
    const bankTitleLineEndX = 792.019;
    const param: BillParam = {
      ..._param,
      bankNameX: bankPosInfos.bankNameX,
      shopNameX: bankPosInfos.shopNameX,
      bankTitleX: bankTitleLineEndX - bankPosInfos.bankInfoWidth / 2,
      bankTitleLineX: bankTitleLineEndX - bankPosInfos.bankInfoWidth,
      iconTransform: this.getLogoTransform({ height: iconHeight, width: iconWidth }),
    };
    // SVG上の変数置換処理
    const replacedTemplateSvgStrList = [...new Array(maxPage)].fill(templateSvgStr)
      .map((page: string, pageIndex: number) => {
        let replacedPage = page;
        for (let i = 0; i < maxLength; i += 1) {
          if (pageIndex * maxLength + i < param.banks.length) {
            replacedPage = this.duplicateElement(
              replacedPage,
              'repeat_i',
              { x: 0, y: height * i },
              pageIndex * maxLength + i,
              'i',
            );
          }
        }
        return replacedPage;
      });
    // return super.createSvg(param, replacedTemplateSvgStrList, 13);
    return super.createSvg(param, replacedTemplateSvgStrList, undefined, param.adjustName ? [
      {
        key: '<text id="change_size_1_1" transform="translate(61.06 118.99)" style="font-size: 25px">',
        change: `<text id="change_size_1_1" transform="translate(61.06 118.99)" style="font-size: ${param.adjustName}px">`,
      },
    ] : []);
  }

  async createSvgList(param: BillParam = defaultProps): Promise<string[]> {
    return super.createSvgList(param, undefined, param.adjustName ? [
      {
        key: '<text id="change_size_1_1" transform="translate(61.06 118.99)" style="font-size: 25px">',
        change: `<text id="change_size_1_1" transform="translate(61.06 118.99)" style="font-size: ${param.adjustName}px">`,
      },
    ] : []);
  }

  /**
   * 位置の自動調整機能
   * @param banks 振込先の情報一式
   * @returns
   */
  private getAdjustBankLineWidth(
    banks: BanksParam[],
  ): { bankNameX: number, shopNameX: number, bankInfoWidth: number } {
    // フォントのサイズ 文字1つでずらす位置の数値
    const fontSize = 9;
    // 最低限確保する幅の文字数換算の値
    const singleBankOrShopMini = 6;
    // 銀行名、支店名の合計の最大値、最低値
    const sumBankAndShop = { mini: 12, max: 24 };
    // 基準とする初期位置
    const initPos = { bankNameX: 580.309, shopNameX: 644.592 };
    const fixedWidth = 104.8;
    const defaultWidth = 202.8;
    // 返却値用の値を格納する変数
    let { bankNameX, shopNameX } = initPos;
    let bankInfoWidth = defaultWidth; // 振込先ヘッダ部分のラインの長さ
    // 銀行名、支店名のそれぞれの最大の文字数を補完するための変数
    const maxes = { bank: 0, shop: 0 };
    // 銀行名、支店名のそれぞれの最大の文字数を取得
    banks.forEach((bank) => {
      if (maxes.bank < bank.banks0.length) {
        maxes.bank = bank.banks0.length;
      }
      if (maxes.shop < bank.banks1.length) {
        maxes.shop = bank.banks1.length;
      }
    });

    // 支店名の位置決定
    // 支店名の長さによって、銀行名の位置へ影響があるので、先に調整
    if (maxes.shop > singleBankOrShopMini) {
      const shopMax = (() => {
        // 支店名、銀行名の合計文字数が規定値以下なら、最大の文字数の幅を確保する
        if (maxes.shop + maxes.bank <= sumBankAndShop.max || maxes.shop <= 10) {
          return maxes.shop;
        }
        // 許容する文字数は銀行名の文字数によって多少変動
        if (maxes.bank === 13) {
          return 11;
        }
        if (maxes.bank >= 14) {
          return 10;
        }
        return 12;
      })();
      shopNameX -= ((shopMax - singleBankOrShopMini) * fontSize);
      maxes.shop = shopMax;
    }
    // 銀行名の位置決定
    if (maxes.shop > singleBankOrShopMini) {
      bankNameX -= (maxes.shop - singleBankOrShopMini) * fontSize;
    }
    if (maxes.bank > singleBankOrShopMini) {
      // 支店名、銀行名の合計文字数が規定値以下なら、最大の文字数の幅を確保する
      const bankMax = (() => {
        if (maxes.shop + maxes.bank <= sumBankAndShop.max) {
          return maxes.bank;
        }
        const limit = sumBankAndShop.max - maxes.shop;
        return maxes.bank > limit ? limit : maxes.bank;
      })();
      bankNameX -= (bankMax - singleBankOrShopMini) * fontSize;
      maxes.bank = bankMax;
    }
    // 全体の幅の計算
    const bankWidth = maxes.bank < singleBankOrShopMini ? singleBankOrShopMini : maxes.bank;
    const shopWidth = maxes.shop < singleBankOrShopMini ? singleBankOrShopMini : maxes.shop;
    const fontWidth = (bankWidth + shopWidth) * fontSize;
    const width = fontWidth + fixedWidth;
    bankInfoWidth = width > defaultWidth ? width : defaultWidth;
    return { bankNameX, shopNameX, bankInfoWidth };
  }
}
