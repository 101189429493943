/* eslint-disable no-irregular-whitespace */
import './change-password-error.pc.scss';
import { Link } from 'react-router-dom';
import React from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { RoutingPath } from '../../../../../routes/routing-pass';
import { Button } from '../../../../ui/button/button';
import Logo from '../../../../../asset/images/reformcloud_vertical.png';

type Props = {
  isActivate?: boolean;
}

export const ChangePasswordErrorPC = (props: Props) => {
  const { isActivate } = props;
  console.log('isActivate', isActivate);
  const message = `${isActivate ? '' : '再'}設定`;
  const dispatch = useDispatch();
  return (
    <div className="loginPC">
      <div className="loginPC__body">
        <div className="loginPC__logo">
          <img src={Logo} alt="リフォームクラウド" />
        </div>
        <div className="loginPC__title">{message}の有効期限切れ</div>
        <div className="text_box">
          パスワード{message}用のURLの有効期限が切れています。<br />
          URLの有効期限は、発行から24時間です。<br />
          {!isActivate && <>再度、パスワード{message}の手続きをお願いします。<br /></>}
          <br />
          ※ご不明点等ございましたら、<br />
          　システム管理者へお問い合わせください。
        </div>
        {!isActivate && (
        <div style={{ margin: '0 0 30px' }}>
          <Button
            type="submit"
            onClick={() => dispatch(push(RoutingPath.sendPassword))}
          >パスワード再設定へ
          </Button>
        </div>
        )}
        <div className="link_box">
          <Link to={RoutingPath.login}><i className="fas fa-arrow-left" />&nbsp;ログイン画面に戻る</Link>
        </div>
      </div>
    </div>
  );
};
