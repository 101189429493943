import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { DialogActions } from '../../../../redux/dialog/dialog.action';
import { FileActions } from '../../../../redux/file/file.action';
import { LeftIconButton } from '../../../ui/button/left-icon-button/left-icon-button';
import { FileListSP } from '../../layout/body/list/file/file-list';
import { BasePageSP } from '../base.page.sp';
import { FileEditSP } from './edit/file-edit.sp';
import { FileEditDialogTitle } from './edit/file-edit.type';
import './file.sp.scss';
import { FileSearchBoxSP } from './search-box/file-search-box.sp';
import { useDidMount, useWillUnMount } from '../../../../hooks/life-cycle';
import { useAppSelector } from '../../../../hooks/use-redux';
import { FileModel } from '../../../../model/file/file.model';
import { FileSortState } from '../../../../type/file/file.type';
import { TableSort } from '../../../ui/table/table-sort/table-sort';

export const FileSP = () => {
  const sortState = useAppSelector((v) => (v.file.sort));
  const fileSize = useAppSelector((v) => v.file.fileSize);
  const stopLoad = useAppSelector((v) => v.file.stopLoad);
  const dispatch = useDispatch();
  const [hitCount, setHitCount] = useState(0);
  // const [stopLoad, setStopLoad] = useState(false);

  /* 一覧取得 */
  const getList = useCallback((data?: FileSortState) => {
    dispatch(FileActions.setList(null));
    dispatch(FileActions.api.file.getList({
      param: FileModel.listParam(data || sortState),
      onSuccess: (v, file, hit) => {
        dispatch(FileActions.setStopLoad(false));
        dispatch(FileActions.setList(v));
        dispatch(FileActions.setFileSize({ ...file }));
        setHitCount(hit as number);
      },
    }));
  }, [sortState]);

  const add = useCallback(() => {
    dispatch(FileActions.setStopLoad(true));
    dispatch(DialogActions.push({
      title: FileEditDialogTitle.add,
      onCloseClick: () => dispatch(FileActions.setStopLoad(false)),
      element: <FileEditSP callback={() => getList()} />,
    }));
  }, [getList]);

  useEffect(() => {
    getList();
  }, []);

  useDidMount(() => {
    dispatch(FileActions.setStopLoad(false));
  });

  useWillUnMount(() => {
  });

  return (
    <BasePageSP
      className="file_sp"
      searchBoxDialog={{
        title: '詳細検索',
        element: <FileSearchBoxSP callback={(v) => {
          dispatch(DialogActions.pop());
          dispatch(FileActions.setSort({ offset: 0 }));
          getList({ ...v, offset: 0 });
        }}
        />,
      }}
      searchValue={sortState.word}
      searchCallback={(v) => {
        dispatch(FileActions.setSort({ word: v, offset: 0 }));
        getList({ ...sortState, word: v, offset: 0 });
      }}
      onOpenDialog={(v) => dispatch(FileActions.setStopLoad(v))}
    >
      <div className="map_list_header file_sp__header">
        <span>ファイル一覧</span>
      </div>
      <>
        <TableSort
          isSp
          page={sortState.offset ?? 0}
          limit={100}
          hitCount={hitCount || 0}
          callback={(v) => {
            dispatch(FileActions.setSort({ offset: v }));
            getList({ ...sortState, offset: v });
          }}
        />
      </>
      <div className="map_list_body">
        <>
          {/*
          <TableSort
            isSp
            page={sortState.offset ?? 0}
            limit={100}
            hitCount={hitCount || 0}
            callback={(v) => {
              dispatch(FileActions.setSort({ offset: v }));
              getList({ ...sortState, offset: v });
            }}
          />
          */}
          <FileListSP fileSize={fileSize} stopLoad={stopLoad} />
        </>
      </div>
      <div className="page_body_footer file_sp__footer">
        <LeftIconButton
          fontAwesomeClass="fas fa-edit"
          label="ファイル新規登録"
          onClick={() => add()}
          size="md"
          color="primary"
        />
      </div>
    </BasePageSP>
  );
};
