import {
  ReactElement, useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import {
  DropTargetMonitor, useDrag, useDrop, XYCoord,
} from 'react-dnd';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import './accodion.scss';
import { UserAgent } from '../../../utilities/user-agent';
import { Button } from '../button/button';

type Props = {
  label: string;
  isOpen: boolean;
  onClick: () => void;
  children: ReactElement;
  id?: number;
  index?: number;
  onHover?: (draggedId: number, id: number) => void;
  onDrop?: (draggedId: number, id: number) => void;
  onChangePrintName?: (v: number | undefined) => void;
  isDnD?: boolean;
  type: string;
  isHover?: boolean;
  className?: string;
  tab?: string;
  isEdit?: boolean;
}

type DragItem = {
  index: number;
  id: number;
  type: string;
}

export const Accordion = (props: Props) => {
  const {
    children,
    label,
    isOpen,
    onClick,
    id,
    className,
    index,
    onHover,
    onDrop,
    isDnD,
    type,
    isHover,
    tab,
    isEdit,
    onChangePrintName,
  } = props;

  const refs = useRef<HTMLDivElement>(null);
  const ref = useRef<HTMLDivElement>(null);

  const [draggingId, setDraggingId] = useState(0);

  const [, drag] = useDrag({
    type,
    item: {
      id, index, label, type, className, tab,
    },
    collect: (monitor) => {
      setDraggingId(monitor.getItem()?.id || 0);
      return ({
        isDragging: monitor.isDragging(),
        draggingId: monitor.didDrop(),
      });
    },
    end: () => {
      if (onDrop) onDrop(NaN, NaN);
    },
  });

  const [handlerId, drop] = useDrop({
    accept: type,
    collect: (monitor) => ({
      hndlreID: monitor.getHandlerId(),
      isOverCurrent: monitor.isOver({ shallow: true }),
    }),
    hover: (item: DragItem, monitor: DropTargetMonitor) => {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if ((dragIndex === undefined || hoverIndex === undefined)
        || (dragIndex === hoverIndex)) return;

      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      if ((dragIndex < hoverIndex && hoverClientY < hoverMiddleY)
        || (dragIndex > hoverIndex && hoverClientY > hoverMiddleY)) return;

      if (onHover) onHover(dragIndex, hoverIndex);
      // eslint-disable-next-line no-param-reassign
      item.index = hoverIndex;
    },

    drop(item: DragItem, monitor: DropTargetMonitor) {
      setDraggingId(0);
      const dragIndex = item.index;
      const hoverIndex = index || NaN;
      if (onDrop) onDrop(dragIndex, hoverIndex);
    },
  });

  const [isActive, setIsActive] = useState(isOpen);

  const onClickTab = useCallback(() => {
    setIsActive(!isActive);
    onClick();
  }, [isActive]);

  useEffect(() => {
    drag(drop(isDnD ? ref : null));
  }, [isDnD]);

  return (
    <div
      key="accordion"
      className={`accordion ${UserAgent}`}
      ref={refs}
    >
      <div
        key="childAccordion"
        onClick={onClickTab}
        className={`accordion__label ${className || ''}${draggingId === id ? ' dnd_target' : ''}`}
      >
        {tab || ''}
        {isDnD
          ? (
            <>
              <div
                ref={ref}
                data-handler-id={handlerId}
                className="dnd_handler"
              />
              <div className="accordion__label__dnd">
                <DragHandleIcon />&nbsp;{label}
              </div>
            </>
          )
          : <><div className={`triangle_icon${isActive ? '' : ' right'}`} />&nbsp;<div className="label ellipsis">{label}</div></>}
        {isEdit && !!onChangePrintName
          && (
          <Button
            size="sm"
            color="white"
            onClick={() => onChangePrintName(id)}
            className="change_print_name"
          >
            印刷名称変更
          </Button>
          )}
      </div>
      {isActive && children}
    </div>
  );
};
