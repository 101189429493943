import { cloneDeep } from 'lodash';
import { ApiCsvCustomerGetListParam } from '../../redux/csv/api/customer/api-csv-customer';
import {
  CsvCustomerSort, CsvProjectSort, CsvOrderSort,
  CsvNonOrderSort, CsvLostOrderSort, CsvSupportHistorySort,
  CsvMaintenanceSort,
  CsvCustomerRankSort,
  CsvBirthdaySort,
  CsvWeddingSort,
} from '../../type/csv/csv-sort.type';
import { DateFormatter, DateFormatter as DF } from '../../utilities/date-formatter';
import { ApiCsvProjectGetListParam } from '../../redux/csv/api/project/api-csv-project';
import { ApiCsvOrderGetListParam } from '../../redux/csv/api/order/api-csv-order';
import { ApiCsvNonOrderGetListParam } from '../../redux/csv/api/non-order/api-csv-non-order';
import { ApiCsvLostOrderGetListParam } from '../../redux/csv/api/lost-order/api-csv-lost-order';
import { ApiCsvSupportHistoryGetListParam } from '../../redux/csv/api/support-history/api-csv-support-history';

import { ApiCsvMaintenanceGetListResponse, ApiCsvMaintenanceGetListParam } from '../../redux/csv/api/maintenance/api-csv-maintenance';

import { ApiCsvCustomerRankGetListParam } from '../../redux/csv/api/customer-rank/api-customer-rank';

import { ApiCsvBirthdayGetListParam } from '../../redux/csv/api/birhday/api-csv-birthday';

import { ApiCsvWeddingGetListParam } from '../../redux/csv/api/wedding/api-csv-wedding';

export class CsvModel {
  public static customerEx(param: CsvCustomerSort): ApiCsvCustomerGetListParam {
    const { sort_by: _sort_by, input_comp_flag: _input_comp_flag } = param;
    let sort_by = 0;
    const afterSort = [3, 4, 9, 7, 8, 1, 11, 17, 18, 19, 20, 2];
    for (let i = 0; i < afterSort.length; i += 1) {
      if (_sort_by === i) {
        sort_by = afterSort[i];
      }
    }
    const __input_comp_flag = _input_comp_flag?.getSendData();
    let input_comp_flag = NaN;
    if (!__input_comp_flag?.includes(1) && __input_comp_flag?.length) {
      input_comp_flag = __input_comp_flag[0] === 2 ? 0 : 1;
    }

    return ({
      ...param,
      completion_date_start: DF.date2str(param.completion_date_start, 'YYYYMM', '-'),
      completion_date_end: DF.date2str(param.completion_date_end, 'YYYYMM', '-'),
      last_completion_date_start: DF.date2str(param.last_completion_date_start, 'YYYYMM', '-'),
      last_completion_date_end: DF.date2str(param.last_completion_date_end, 'YYYYMM', '-'),
      tags: param?.tags?.getSendData(),
      parts: param?.parts?.getSendData(),
      construction_status: param?.construction_status?.getSendData(),
      post_no: param.post_no1 && param.post_no2 ? `${param.post_no1}${param.post_no2}` : '',
      offset: param.offset,
      sort_by,
      input_comp_flag,
      limit: param.limit,
      highlow: param.highlow,
      created_at_start: DateFormatter.date2str(param.created_at_start, 'YYYYMMDD', '-'),
      created_at_end: DateFormatter.date2str(param.created_at_end, 'YYYYMMDD', '-'),
      updated_at_start: DateFormatter.date2str(param.updated_at_start, 'YYYYMMDD', '-'),
      updated_at_end: DateFormatter.date2str(param.updated_at_end, 'YYYYMMDD', '-'),
    });
  }

  public static projectEx(param: CsvProjectSort): ApiCsvProjectGetListParam {
    const { sort_by: _sort_by } = param;
    let sort_by = 0;
    const afterSort = [2, 6, 23, 8, 7, 4, 16, 9, 11, 10, 12, 17, 21, 22, 24];
    for (let i = 0; i < afterSort.length; i += 1) {
      if (_sort_by === i) {
        sort_by = afterSort[i];
      }
    }
    const result = {
      ...param,
      contract_date_from: DateFormatter.date2str(param.contract_start_date, 'YYYYMMDD', '-'),
      contract_date_to: DateFormatter.date2str(param.contract_end_date, 'YYYYMMDD', '-'),
      completion_date_from: DateFormatter.date2str(param.completion_start_date, 'YYYYMMDD', '-'),
      completion_date_to: DateFormatter.date2str(param.completion_end_date, 'YYYYMMDD', '-'),
      construction_status: param?.construction_status?.getSendData(),
      offset: param.offset,
      sort_by,
      limit: param.limit,
      highlow: param.highlow,
      created_at_start: DateFormatter.date2str(param.created_at_start, 'YYYYMMDD', '-'),
      created_at_end: DateFormatter.date2str(param.created_at_end, 'YYYYMMDD', '-'),
      updated_at_start: DateFormatter.date2str(param.updated_at_start, 'YYYYMMDD', '-'),
      updated_at_end: DateFormatter.date2str(param.updated_at_end, 'YYYYMMDD', '-'),
    };
    delete result.contract_start_date;
    delete result.contract_end_date;
    delete result.completion_start_date;
    delete result.completion_end_date;
    return result;
  }

  public static orderEx(param: CsvOrderSort): ApiCsvOrderGetListParam {
    const { sort_by: _sort_by } = param;
    let sort_by = 0;
    const afterSort = [2, 6, 23, 8, 7, 4, 16, 10, 12, 17, 18, 25];
    for (let i = 0; i < afterSort.length; i += 1) {
      if (_sort_by === i) {
        sort_by = afterSort[i];
      }
    }

    return ({
      ...param,
      construction_parts: param?.construction_parts?.getSendData(),
      margin_ritu_filter: param?.margin_ritu !== undefined ? param.margin_ritu_filter : NaN,

      offset: param.offset,
      sort_by,
      limit: param.limit,
      highlow: param.highlow,
      created_at_start: DateFormatter.date2str(param.created_at_start, 'YYYYMMDD', '-'),
      created_at_end: DateFormatter.date2str(param.created_at_end, 'YYYYMMDD', '-'),
      updated_at_start: DateFormatter.date2str(param.updated_at_start, 'YYYYMMDD', '-'),
      updated_at_end: DateFormatter.date2str(param.updated_at_end, 'YYYYMMDD', '-'),

    });
  }

  public static wedding(param: CsvWeddingSort): ApiCsvWeddingGetListParam {
    return ({
      ...param,
    });
  }

  public static birthday(param: CsvBirthdaySort): ApiCsvBirthdayGetListParam {
    return ({
      ...param,
    });
  }

  public static nonOrderEx(param: CsvNonOrderSort): ApiCsvNonOrderGetListParam {
    const { sort_by: _sort_by } = param;
    let sort_by = 0;
    const afterSort = [2, 6, 23, 8, 7, 4, 16, 9, 10, 12, 24];
    for (let i = 0; i < afterSort.length; i += 1) {
      if (_sort_by === i) {
        sort_by = afterSort[i];
      }
    }

    return ({
      ...param,
      estimate_dt_from: DateFormatter.date2str(param.estimate_dt_from),
      estimate_dt_to: DateFormatter.date2str(param.estimate_dt_to),
      construction_parts: param?.construction_parts?.getSendData(),
      margin_ritu_filter: param.margin_ritu !== undefined ? param.margin_ritu_filter : NaN,
      offset: param.offset,
      sort_by,
      limit: param.limit,
      highlow: param.highlow,
      created_at_start: DateFormatter.date2str(param.created_at_start, 'YYYYMMDD', '-'),
      created_at_end: DateFormatter.date2str(param.created_at_end, 'YYYYMMDD', '-'),
      updated_at_start: DateFormatter.date2str(param.updated_at_start, 'YYYYMMDD', '-'),
      updated_at_end: DateFormatter.date2str(param.updated_at_end, 'YYYYMMDD', '-'),

    });
  }

  public static lostOrderEx(param: CsvLostOrderSort): ApiCsvLostOrderGetListParam {
    const { sort_by: _sort_by } = param;
    let sort_by = 0;
    const afterSort = [2, 6, 23, 8, 7, 4, 16, 26, 27, 24];
    for (let i = 0; i < afterSort.length; i += 1) {
      if (_sort_by === i) {
        sort_by = afterSort[i];
      }
    }

    return ({
      ...param,
      construction_parts: param?.construction_parts?.getSendData(),
      failure_date_from: DateFormatter.date2str(param.failure_date_from),
      failure_date_to: DateFormatter.date2str(param.failure_date_to),
      project_rank_filter: param.project_rank ? param.project_rank_filter : NaN,
      margin_ritu_filter: param.margin_ritu !== undefined ? param.margin_ritu_filter : NaN,

      offset: param.offset,
      sort_by,
      limit: param.limit,
      highlow: param.highlow,
      created_at_start: DateFormatter.date2str(param.created_at_start, 'YYYYMMDD', '-'),
      created_at_end: DateFormatter.date2str(param.created_at_end, 'YYYYMMDD', '-'),
      updated_at_start: DateFormatter.date2str(param.updated_at_start, 'YYYYMMDD', '-'),
      updated_at_end: DateFormatter.date2str(param.updated_at_end, 'YYYYMMDD', '-'),

    });
  }

  public static support(param: CsvSupportHistorySort): ApiCsvSupportHistoryGetListParam {
    const { sort_by: _sort_by } = param;
    let sort_by = 0;
    const sorts = [1, 8, 2, 11, 3, 14, 5, 6, 7];

    for (let i = 0; i < sorts.length; i += 1) {
      if (_sort_by === i) {
        sort_by = sorts[i];
      }
    }

    return ({
      ...param,
      contact_dt: DateFormatter.date2str(param.contact_dt, 'YYYYMMDD', '-'),
      support_dt: DateFormatter.date2str(param.support_dt, 'YYYYMMDD', '-'),
      created_at_start: DateFormatter.date2str(param.created_at_start, 'YYYYMMDD', '-'),
      created_at_end: DateFormatter.date2str(param.created_at_end, 'YYYYMMDD', '-'),
      offset: param.offset,
      sort_by,
      limit: param.limit,
      highlow: param.highlow,
      updated_at_start: DateFormatter.date2str(param.updated_at_start, 'YYYYMMDD', '-'),
      updated_at_end: DateFormatter.date2str(param.updated_at_end, 'YYYYMMDD', '-'),
    });
  }

  public static maintenance(param: CsvMaintenanceSort): ApiCsvMaintenanceGetListParam {
    const { support_flag: _support_kubun } = cloneDeep(param);
    let support_flag = _support_kubun;
    if (_support_kubun === 2) {
      support_flag = NaN;
    }
    const sort_by_exchange = [
      { client: 0, server: 0 }, // ID
      { client: 1, server: 2 }, // 対応済
      { client: 2, server: 3 }, // メンテナンス日
      { client: 3, server: 4 }, // タイトル
      { client: 4, server: 5 }, // 対応日
      { client: 5, server: 6 }, // 完工日
      { client: 6, server: 7 }, // 顧客名
      { client: 7, server: 8 }, // 案件名
      { client: 8, server: 9 }, // 案件担当者
    ];

    const sort_by = sort_by_exchange.find((v) => v.client === param.sort_by)?.server ?? 3;

    return ({
      ...param,
      sort_by,
      support_flag,
      maintenance_date_start: DateFormatter.date2str(param.maintenance_date_start, 'YYYYMMDD', '-'),
      maintenance_date_end: DateFormatter.date2str(param.maintenance_date_end, 'YYYYMMDD', '-'),
      completion_date_start: DateFormatter.date2str(param.completion_date_start, 'YYYYMMDD', '-'),
      completion_date_end: DateFormatter.date2str(param.completion_date_end, 'YYYYMMDD', '-'),
      created_at_start: DateFormatter.date2str(param.created_at_start, 'YYYYMMDD', '-'),
      created_at_end: DateFormatter.date2str(param.created_at_end, 'YYYYMMDD', '-'),
      updated_at_start: DateFormatter.date2str(param.updated_at_start, 'YYYYMMDD', '-'),
      updated_at_end: DateFormatter.date2str(param.updated_at_end, 'YYYYMMDD', '-'),
    });
  }

  public static customerRank(param: CsvCustomerRankSort): ApiCsvCustomerRankGetListParam {
    return ({
      ...param,
      updated_date_start: DateFormatter.date2str(param.updated_date_start, 'YYYYMMDD', '-'),
      updated_date_end: DateFormatter.date2str(param.updated_date_end, 'YYYYMMDD', '-'),
    });
  }
}
