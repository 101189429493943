// import {ApiLogin} from '../../api/auth/api-login';
import actionCreatorFactory from 'typescript-fsa';
import { ProjectListType, ProjectSortState } from '../../type/project/project.type';
import { FileSortState, FileListType } from '../../type/file/file.type';
import { SupportHistorySortState, SupportHistoryListType } from '../../type/support-history/support-history.type';
import { MaintenanceSortState, MaintenanceList } from '../../type/maintenance/maintenance.type';

const ActionCreator = actionCreatorFactory('customer/detail');

export const CustomerDetailActions = {
  setProjectSort: ActionCreator<Partial<ProjectSortState> | null>('set/project/sort'),
  setSupportSort: ActionCreator<Partial<SupportHistorySortState> | null>('set/support/sort'),
  setFileSort: ActionCreator<Partial<FileSortState> | null>('set/file/sort'),
  setMaintenanceSort: ActionCreator<MaintenanceSortState | null>('set/maintenance/sort'),
  setProjectList: ActionCreator<ProjectListType[] | null>('set/project/list'),
  setConcatProjectList: ActionCreator<ProjectListType[] | null>('set/concat/project/list'),
  setFileList: ActionCreator<FileListType[] | null>('set/file/list'),
  setConcatFileList: ActionCreator<FileListType[] | null>('set/concat/file/list'),
  setMaintenanceList: ActionCreator<MaintenanceList[] | null>('set/maintenance/list'),
  setConcatMaintenanceList: ActionCreator<MaintenanceList[] | null>('set/concat/maintenance/list'),
  setSupportList: ActionCreator<SupportHistoryListType[] | null>('set/support/list'),
  setConcatSupportList: ActionCreator<SupportHistoryListType[] | null>('set/concat/support/list'),
  setStopLoadFile: ActionCreator<boolean>('file/stop-load'),
  resetState: ActionCreator('reset/state'),
};
