import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { Table } from '../../../../../ui/table/table';
import { CustomerCollection } from '../../../../../../collection/customer/customer.collection';
import { FileSortState, FileListType } from '../../../../../../type/file/file.type';
import { DialogActions } from '../../../../../../redux/dialog/dialog.action';
import { LeftIconButton } from '../../../../../ui/button/left-icon-button/left-icon-button';
import { FileEditPC } from '../../../file/edit/file-edit.pc';
import { FileActions } from '../../../../../../redux/file/file.action';
import { CustomerDetailActions } from '../../../../../../redux/customer-detail/customer-detail.action';
import { RefindFileTable } from './refind-file-table/refind-file-table';
import { DateFormatter } from '../../../../../../utilities/date-formatter';
import { FileModel } from '../../../../../../model/file/file.model';
import { useAppSelector } from '../../../../../../hooks/use-redux';
import { useDidMount } from '../../../../../../hooks/life-cycle';
import { MathHelper } from '../../../../../../utilities/math-helper';
import { FileSize } from '../../../project-detail/file-table/file-size';

export const FileTable = () => {
  /* Hook */
  const dispatch = useDispatch();
  const sort = useAppSelector((v) => v.customerDetail.fileSort);
  const list = useAppSelector((v) => v.customerDetail.fileList);
  const customer = useAppSelector((v) => v.customer.customer);
  const user = useAppSelector((v) => v.user);

  /* State */
  const [selected, setSelected] = useState<number[]>([]);
  const [disabled, setDisabled] = useState(false);
  const [formatList, setFormatList] = useState<string[]>([]);
  const [fileSize, setFileSize] = useState<
  { capacity: number, total: number; }>({ capacity: 0, total: 0 });

  /* Memo */
  const totalSize = useMemo(() => {
    const kb = MathHelper.div(fileSize.total, 1024);
    if (kb > 1024) {
      const mb = MathHelper.div(kb, 1024);

      return `${MathHelper.rounding(mb, 2, 'ceil')}MB`;
    }
    return `${MathHelper.rounding(kb, 2, 'ceil')}KB`;
  }, [fileSize]);

  const percent = useMemo(() => {
    const per = (fileSize.total <= 0) ? 0 : MathHelper.rounding(
      MathHelper.times(
        MathHelper.div(fileSize.total, fileSize.capacity),
        100,
      ),
      0,
      'ceil',
    );
    return (per > 100) ? 100 : per;
  }, [fileSize]);

  /* Callback */
  /* 一覧取得 */
  const getList = useCallback((data?:FileSortState) => {
    const sortData = data || sort;
    if (!customer?.id) return;
    dispatch(FileActions.api.file.getList({
      noLoad: true,
      param: FileModel.listParamInCustomer(sortData, customer.id),
      onSuccess: (v, file) => {
        dispatch(CustomerDetailActions.setFileList(v));
        setFileSize({ ...file });
      },
    }));
  }, [customer?.id, sort]);

  /* 編集 */
  const edit = useCallback((v?:FileListType) => {
    if (!customer) return;
    dispatch(DialogActions.push({
      title: 'ファイルアップロード',
      element: <FileEditPC
        callback={getList}
        id={v?.id}
        customer={v ? undefined : customer}
      />,
    }));
  }, [customer, getList]);

  const handleClickRow = useCallback((v:FileListType) => {
    if (!list) return;
    const findIndex = list.findIndex((v2) => v2.id === v.id);
    if (findIndex !== -1) {
      setSelected([findIndex]);
    }
  }, [list]);

  const handleDbClickRow = useCallback((v:FileListType) => {
    if (!list) return;
    const findIndex = list.findIndex((v2) => v2.id === v.id);
    if (findIndex !== -1) setSelected([findIndex]);
    edit(v);
  }, [list, edit]);

  /* ヘッダーソート */
  const headerSort = useCallback((highlow: 0 | 1, sort_by:number) => {
    dispatch(CustomerDetailActions.setFileSort({ highlow, sort_by }));
  }, []);

  /* 絞り込みダイアログ */
  const openSort = useCallback(() => {
    dispatch(DialogActions.push({
      title: '絞込み',
      element: <RefindFileTable
        formatList={formatList}
        callback={(v) => {
          dispatch(CustomerDetailActions.setFileList(null));
          getList(v);
        }}
      />,
    }));
  }, [getList, formatList]);

  /* ファイルダウンロード */
  const download = useCallback((v:FileListType) => {
    dispatch(FileActions.api.file.download({
      param: { file_id: v.id },
      fileName: v.file_name || '',
    }));
  }, []);

  /* ファイル削除 */
  const deleteFile = useCallback((v:FileListType) => {
    dispatch(DialogActions.pushMessage({
      title: 'ファイル削除',
      message: ['削除しますか'],
      isCancel: true,
      callback: () => {
        dispatch(FileActions.api.file.delete({
          param: { id: v.id },
          callback: getList,
        }));
      },
    }));
  }, [getList]);

  /* 一覧取得 */
  useEffect(() => {
    getList();
  }, [customer?.id, sort.highlow, sort.sort_by]);

  /* 権限適用 */
  useEffect(() => {
    if (!user.id) return;
    setDisabled(!!(user?.id !== customer?.employee_id && !user?.authority1));
  }, [customer, user]);

  useEffect(() => {
    if (!customer?.id) return;
    dispatch(FileActions.api.file.getFormatList({
      param: FileModel.listParamInCustomer(sort, customer.id),
      onSuccess: setFormatList,
    }));
  }, [customer?.id]);

  return (
    <div className="detail_table_area">
      <div className="btn_box">
        <LeftIconButton
          label="新規ファイル登録"
          fontAwesomeClass="fas fa-edit"
          className="btn_search for_detail"
          size="sm"
          color="primary"
          onClick={() => edit()}
        />
        <div className="right_box">
          <FileSize
            percent={percent}
            totalSize={totalSize}
            maxSize={fileSize.capacity}
          />
          <LeftIconButton
            label="絞込み"
            fontAwesomeClass="fas fa-filter"
            className="btn_search for_detail"
            size="sm"
            color="secondary"
            onClick={openSort}
          />

        </div>
      </div>
      <div className="table_responsive">
        <Table
          className="table_selectable table_sortable table_sticky table_cell_change"
          header={CustomerCollection.fileHeader}
          onClickRow={handleClickRow}
          onDbClick={handleDbClickRow}
          sort={{ onClick: headerSort }}
          rowDataList={list || []}
          selectedTr={selected}
          lists={list ? list.map((v) => (
            [
              v.internal_id,
              v.file_name,
              // <FileName id={1} name="原価管理表" img="xls" />,
              v.format,
              `${MathHelper.rounding(
                MathHelper.div(Number(v.size), 1024), 2, 'floor',
              ).toLocaleString()} KB`,
              DateFormatter.date2str(v.upload_date),
              v.updater,
              v.comment,
              <LeftIconButton
                label="ダウンロード"
                fontAwesomeClass="fas fa-file-download"
                className="btn_search for_detail"
                size="sm"
                color="secondary"
                onClick={(e) => {
                  e.stopPropagation();
                  download(v);
                }}
              />,
              <LeftIconButton
                label="削除"
                fontAwesomeClass="fas fa-trash-alt"
                className="btn_search for_detail"
                size="sm"
                color="dark"
                onClick={(e) => {
                  e.stopPropagation();
                  deleteFile(v);
                }}
              />,
            ]
          )) : null}
          option={{
            stringWidth: [
              // { index: 0, width: 50 }, // No.
              // { index: 1, width: 100 }, // ファイル名
              // { index: 2, width: 50 }, // 形式
              // { index: 3, width: 50 }, //  サイズ（KB）
              // { index: 4, width: 150 }, // アップロード日時
              // { index: 5, width: 150 }, // 更新者
              // { index: 6, width: 50 }, // コメント
              { index: 7, width: 100 }, // ダウンロード
              { index: 8, width: 100 }, // 削除
            ],
            tdAlign: [
              { index: 0, align: 'center' },
              { index: 1, align: 'left' },
              { index: 2, align: 'center' },
              { index: 3, align: 'center' },
              { index: 4, align: 'center' },
              { index: 5, align: 'left' },
              { index: 6, align: 'left' },
              { index: 7, align: 'center' },
              { index: 8, align: 'center' },
            ],
          }}
        />
      </div>
    </div>
  );
};
