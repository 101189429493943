import * as xml2js from 'xml2js';

export class XmlParser {
  parse<T = any>(source: string): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      xml2js.parseString(source, ((err, result) => {
        if (err) {
          reject(err);
        }
        resolve(result);
      }));
    });
  }

  build<T = any>(source: T): string {
    const builder = new xml2js.Builder();
    const xml = builder.buildObject(source);
    return xml;
  }
}
