import { push } from 'connected-react-router';
import React, {
  useState, useMemo, useCallback, useEffect, /* , useEffect, */
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';
import { ProjectCollection } from '../../../../../../collection/project/project.collection';
import { State } from '../../../../../../redux/root.reducer';
import { RoutingPath } from '../../../../../../routes/routing-pass';
import { DisplayElements } from '../../../../../../type/display-elements.type';
import { ProjectListType } from '../../../../../../type/project/project.type';
import { Table } from '../../../../../ui/table/table';
import Ordered from '../../../../../../asset/images/icon/ordered.svg';
import Alert from '../../../../../../asset/images/icon/alert.svg';
import './project-list.pc.scss';
import { ProjectActions } from '../../../../../../redux/project/project.action';
import { DateFormatter } from '../../../../../../utilities/date-formatter';
import { useDidMount } from '../../../../../../hooks/life-cycle';
import { MasterActions } from '../../../../../../redux/master/master.action';
import { useAppSelector } from '../../../../../../hooks/use-redux';
import { useOpenWindow } from '../../../../../../hooks/use-open-window';

type Props = {
  type?: 0 | 1 | 2 | 3;
  data?: ProjectListType[];
  handleCardClick?: (project: ProjectListType) => void;
  callbackSelect?: (projects: ProjectListType[]) => void;
  callbackSelected?: (v: number[]) => void;
  callbackList?: (v?: ProjectListType[] | null) => void;
  parentSelect?: number[];
  isMulti?: boolean;
}

export const ProjectListPC = (props: Props) => {
  const {
    type, data, handleCardClick, isMulti, callbackSelect, parentSelect,
    callbackSelected, callbackList,
  } = props;

  /* Hooks */
  const customerRankList = useAppSelector((v) => v.master.customerRankList);
  const projectRankList = useAppSelector((v) => v.master.projectRankList);
  const projectListData = useAppSelector((v) => v.project.list);
  const dispatch = useDispatch();

  /* State */
  const [projectHeader] = useState<DisplayElements[]>(ProjectCollection.header);
  const [selected, setSelected] = useState<number[]>([]);

  /* List */
  const projectList = useMemo(() => (
    !type ? projectListData : projectListData?.filter(
      (v) => v.project_rank === type,
    )), [type, projectListData]);

  const dataList = useMemo(() => {
    callbackList?.(data || projectList);
    return data || projectList;
  }, [projectListData, data]);

  const openWindow = useOpenWindow();

  const handleClickCard = useCallback((row: ProjectListType) => {
    if (!dataList) return;
    if (handleCardClick) {
      const findData = data?.find((v) => v.id === row.id);
      if (findData) {
        handleCardClick(cloneDeep(findData));
      }
    }
    if (callbackSelect) {
      callbackSelect(cloneDeep([row]));
    }
    setSelected([dataList.findIndex((v) => v.id === row.id)]);
    callbackSelected?.([dataList.findIndex((v) => v.id === row.id)]);
  }, [data, handleCardClick, dataList]);

  const handleDbClick = useCallback(
    (v: ProjectListType,
      e?: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
      if (!e) return;
      if (handleCardClick) return;
      openWindow({
        url: `${RoutingPath.projectDetail}/${v.id}`,
        e,
      });
    },
    [handleCardClick],
  );

  const handleClickHeader = useCallback((highlow:0 | 1, sort_by:number) => {
    dispatch(ProjectActions.setSort({ highlow, sort_by }));
  }, []);

  useEffect(() => {
    if (!parentSelect) return;
    if (!parentSelect.length) setSelected([]);
  }, [parentSelect]);

  useDidMount(() => {
    dispatch(MasterActions.api.customerRank.getList({}));
    dispatch(MasterActions.api.projectRank.getList({}));
  });

  return (
    <section className="result_area list_area">
      <div className="inner">
        <div className="table_responsive">
          <Table
            className="table_selectable table_sortable table_sticky table_cell_change"
            header={projectHeader}
            selectedTr={selected}
            rowDataList={dataList || []}
            onClickRow={(v:ProjectListType) => handleClickCard(v)}
            onDbClick={(v:ProjectListType, i, e) => handleDbClick(v, e)}
            onClickMulti={isMulti ? (v: ProjectListType[]) => {
              if (!dataList) return;
              if (callbackSelect) {
                callbackSelect(cloneDeep(v));
              }
              setSelected(v.map((v2) => dataList.findIndex((v3) => v3.id === v2.id)));
              callbackSelected?.(v.map((v2) => dataList.findIndex((v3) => v3.id === v2.id)));
            } : undefined}
            onClickAlt={(v, e) => {
              if (e && v) {
                openWindow({
                  url: `${RoutingPath.projectDetail}/${v.id}`,
                  e,
                  callback: () => { },
                });
              }
            }}
            sort={{
              onClick: (highlow, sort_by) => {
                handleClickHeader(highlow, sort_by);
                setSelected([]);
                callbackSelect?.([]);
              },
            }}
            lists={dataList ? dataList.map((v) => (
              [
                v.order_flag ? <img src={Ordered} alt="受注された案件" title="受注された案件" className="icon" /> : '',
                v.alert_flag ? <img src={Alert} alt="案件作成から1週間以上経過" title="案件作成から1週間以上経過" className="icon" /> : '',
                v.internal_id,
                v.field_name,
                (() => {
                  const rank = customerRankList.find((
                    v2,
                  ) => String(v2.id) === String(v.customer_rank));
                  return <div className="rank_label" style={{ backgroundColor: rank?.background_color, color: rank?.text_color }}>{v.customer_rank_name}</div>;
                })(),
                (() => {
                  const rank = projectRankList.find((
                    v2,
                  ) => Number(v2.id) === Number(v.project_rank));
                  return <div className="rank_label" style={{ backgroundColor: rank?.background_color, color: rank?.text_color }}>{v.project_rank_name}</div>;
                })(),
                v.name,
                v.field_tel_no,
                v.field_place,
                DateFormatter.date2str(v.construction_start_date),
                DateFormatter.date2str(v.completion_end_date),
                DateFormatter.date2str(v.construction_date),
                DateFormatter.date2str(v.completion_date),
                v.contract_no,
                v.source_name,
                v.remarks,
                v.project_employee_name,
                DateFormatter.date2str(v.contract_date),
              ]
            )) : null}
            option={{
              stringWidth: [
                { index: 0, width: 50 }, // 受注された案件
                { index: 1, width: 50 }, // 案件作成から1週間以上経過
                // { index: 2, width: 80 }, // 案件ID
                // { index: 3, width: 100 }, //  現場名称
                // { index: 4, width: 100 }, // 顧客ランク
                // { index: 5, width: 100 }, // 見込みランク
                // { index: 6, width: 100 }, // 案件名
                // { index: 7, width: 100 }, // 現場電話番号
                // { index: 8, width: 50 }, // 現場住所
                // { index: 9, width: 80 }, // 着工予定日
                // { index: 10, width: 80 }, // 完工予定日
                // { index: 11, width: 80 }, // 着工日
                // { index: 12, width: 80 }, // 完工日
                // { index: 13, width: 50 }, // 契約番号
                // { index: 14, width: 50 }, // 発生源
                // { index: 15, width: 50 }, // 備考
                // { index: 16, width: 50 }, // 担当名
                // { index: 17, width: 50 }, // 契約日
              ],
              tdAlign: [
                { index: 0, align: 'center' },
                { index: 1, align: 'center' },
                { index: 2, align: 'center' },
                { index: 3, align: 'left' },
                { index: 4, align: 'center' },
                { index: 5, align: 'center' },
                { index: 6, align: 'left' },
                { index: 7, align: 'left' },
                { index: 8, align: 'left' },
                { index: 9, align: 'left' },
                { index: 10, align: 'left' },
                { index: 11, align: 'left' },
                { index: 12, align: 'left' },
                { index: 13, align: 'left' },
                { index: 14, align: 'center' },
                { index: 15, align: 'left' },
                { index: 16, align: 'left' },
                { index: 17, align: 'left' },
              ],
            }}
          />
        </div>
      </div>
    </section>

  );
};
