import { goBack, replace } from 'connected-react-router';
import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Tab } from 'semantic-ui-react';
import { isEqual } from 'lodash';
import { useWillUnMount, useDidMount } from '../../../../hooks/life-cycle';
import { ProjectActions } from '../../../../redux/project/project.action';
import { RoutingPath } from '../../../../routes/routing-pass';
import { BasePagePC } from '../base.page.pc';
import './project-detail.pc.scss';
import { EstimateTable } from './estimate-table/estimate-table';
import { SupportHistoryTable } from './support-history-table/support-history-table';
import { FileTable } from './file-table/file-table';
import { MaintenanceTable } from './maintenance-table/maintenance-table';
import { ProjectEdit } from '../project/edit/project-edit';
import { useQuery } from '../../../../hooks/use-query';
import { LeftIconButton } from '../../../ui/button/left-icon-button/left-icon-button';
import { ProjectDetailActions } from '../../../../redux/project-detail/project-detail.action';
import { BillTable } from './bill-table/bill-table';
import { Resize } from '../../../ui/resize/resize';
import { State } from '../../../../redux/root.reducer';
import { BillActions } from '../../../../redux/bill/bill.action';
import { useAppSelector } from '../../../../hooks/use-redux';
import { useEditAuthProject, useEstimateAuthority } from '../../../../hooks/use-authority';
import { EstimateSortStateInProject } from '../../../../redux/estimate/api/estimate/api-estimate.type';
import { EstimateListType } from '../../../../type/estimate/estimate.type';
import { IconButton } from '../../../ui/button/icon-button/icon-button';
import { Button } from '../../../ui/button/button';
import { FileSortState } from '../../../../type/file/file.type';
import { FileActions } from '../../../../redux/file/file.action';
import { FileModel } from '../../../../model/file/file.model';

const getActiveIndex = (path: string, isAuth: boolean) => {
  const returnNum = (v: number): number => (v - (isAuth ? 0 : 1));
  switch (path) {
    case 'estimate':
      return 0;
    case 'bill':
      return returnNum(1);
    case 'support-history':
      return returnNum(2);
    case 'file':
      return returnNum(3);
    case 'maintenance':
      return returnNum(4);
    default:
      return 0;
  }
};

export const ProjectDetailPC = () => {
  const dispatch = useDispatch();
  const project = useSelector((state: State) => (state.project.project), isEqual);
  const estimateSortState = useSelector(
    (state: State) => state.projectDetail.estimateSort, isEqual,
  );

  const {
    authority1: _authority1,
    store_id,
    id: employee_id,
  } = useSelector((state: State) => (state.user), isEqual);
  const editAuth = useEditAuthProject(project);
  const estimateAuth = useEstimateAuthority();

  const jutyuFlag = Boolean(project?.contract_date && project.contract_no);
  const [estimateSort, setEstimateSort] = useState<EstimateSortStateInProject | null>(null);
  const [estimateList, setEstimateList] = useState<EstimateListType[] | null>(null);
  const [resizeHeight, setResizeHeight] = useState(65);

  /* ファイル関係 */
  const fileSort = useAppSelector((v) => v.projectDetail.fileSort);
  const [fileSize, setFileSize] = useState<
  { capacity: number, total: number; }>({ capacity: 0, total: 0 });

  const getListFileList = useCallback((data?: FileSortState) => {
    const sortData = data || fileSort;
    if (!project?.id) return;
    dispatch(FileActions.api.file.getList({
      noLoad: true,
      param: FileModel.listParamInProject(sortData, project.id),
      onSuccess: (v, file) => {
        dispatch(ProjectDetailActions.setFileList(v));
        setFileSize({ ...file });
      },
    }));
  }, [fileSort, project]);

  // const _panes = [
  //   {
  //     menuItem: '見積情報',
  //     render: () => (
  //       <Tab.Pane>
  //         <EstimateTable
  //           disabled={!editAuth || !!project?.complete_date}
  //           callbackSort={(v) => setEstimateSort(v)}
  //           parentList={estimateList || undefined}
  //         />
  //       </Tab.Pane>
  //     ),
  //   },
  //   { menuItem: '請求書', render: () => <Tab.Pane>
  // <BillTable disabled={!editAuth || !jutyuFlag} /></Tab.Pane> },
  //   { menuItem: '対応履歴', render: () => <Tab.Pane><SupportHistoryTable /></Tab.Pane> },
  //   { menuItem: 'ファイル', render: () => <Tab.Pane><FileTable /></Tab.Pane> },
  //   { menuItem: 'メンテナンス',
  // render: () => <Tab.Pane><MaintenanceTable disabled={!editAuth} /></Tab.Pane> },
  // ];

  const panes = useMemo(() => {
    const estimate = {
      menuItem: '見積情報',
      render: () => (
        <Tab.Pane>
          <EstimateTable
            disabled={!editAuth || !!project?.complete_date}
            callbackSort={(v) => setEstimateSort(v)}
            parentList={estimateList || undefined}
          />
        </Tab.Pane>
      ),
    };

    const list = [
      { menuItem: '請求書', render: () => <Tab.Pane><BillTable disabled={!editAuth || !jutyuFlag} /></Tab.Pane> },
      { menuItem: '対応履歴', render: () => <Tab.Pane><SupportHistoryTable /></Tab.Pane> },
      {
        menuItem: 'ファイル',
        render: () => (
          <Tab.Pane>
            <FileTable getList={(v) => getListFileList(v)} fileSize={fileSize} />
          </Tab.Pane>
        ),
      },
      { menuItem: 'メンテナンス', render: () => <Tab.Pane><MaintenanceTable disabled={!editAuth} /></Tab.Pane> },
    ];

    return (estimateAuth ? [estimate] : []).concat(list);
  }, [editAuth, project, estimateList, jutyuFlag, estimateAuth, getListFileList, fileSize]);

  const listQuery = useQuery('p');

  const { projectID } = useParams<{ projectID: string; }>();

  /* State */
  const [activeIndex, setActiveIndex] = useState(0);

  const handleTabChange = useCallback(
    (index: number) => {
      const path = `${RoutingPath.projectDetail}/${projectID}?p=`;
      let id = '';
      switch (index + (estimateAuth ? 0 : 1)) {
        case 0:
          id = 'estimate';
          break;
        case 1:
          id = 'bill';
          break;
        case 2:
          id = 'support-history';
          break;
        case 3:
          id = 'file';
          break;
        case 4:
          id = 'maintenance';
          break;
        default:
          break;
      }
      dispatch(replace(path + id));
      setActiveIndex(index);
    },
    [projectID],
  );

  /* Effect */
  useEffect(() => {
    const index = getActiveIndex(listQuery || (estimateAuth ? 'estimate' : 'bill'), estimateAuth);
    handleTabChange(index);
  }, [projectID, listQuery]);

  useDidMount(() => {
    if (project?.id) {
      dispatch(BillActions.api.bill.getList({
        param: { project_id: project.id },
      }));
    }
  });

  useWillUnMount(() => {
    dispatch(ProjectActions.setProject(null));
    dispatch(ProjectDetailActions.setEstimateList(null));
    dispatch(ProjectDetailActions.setEstimateSort(null));
    dispatch(ProjectDetailActions.setSupportList(null));
    dispatch(ProjectDetailActions.setSupportSort(null));
    dispatch(ProjectDetailActions.setFileList(null));
    dispatch(ProjectDetailActions.setFileSort(null));
    dispatch(ProjectDetailActions.setMaintenanceList(null));
    dispatch(ProjectDetailActions.setMaintenanceSort(null));
  });

  return (
    <BasePagePC>
      <div id="project" className="cnt_area detail">
        <div className="inner">
          <Resize
            enabled={{ bottom: true }}
            size={`${resizeHeight}%`}
            // maxHeight={`${resizeHeight}%`}
            // minHeight={`${resizeHeight}%`}
          >
            <ProjectEdit
              id={Number(projectID)}
              estimateSort={estimateSort || estimateSortState}
              callback={() => {}}
              callbackList={(v) => {
                setEstimateList(v);
              }}
              file={{
                getList: getListFileList,
                fileSize,
              }}
            />
          </Resize>
          <div className="tab_area">
            <Tab
              onTabChange={(_, data) => handleTabChange(Number(data.activeIndex))}
              panes={panes}
              activeIndex={activeIndex}
            />
            <div className="btn_box">
              <IconButton
                fontAwesomeClass="fas fa-arrow-up"
                className=""
                size="sm"
                color="secondary"
                onClick={() => setResizeHeight(0)}
              />
              <Button
                size="sm"
                color="secondary"
                onClick={() => setResizeHeight(65)}
              >初期表示
              </Button>
              <IconButton
                fontAwesomeClass="fas fa-arrow-down"
                className=""
                size="sm"
                color="secondary"
                onClick={() => setResizeHeight(92)}
              />
            </div>
          </div>
        </div>
      </div>
      <footer className="btn_area">
        <div className="left_box" />
        <div className="right_box">
          <LeftIconButton
            label="戻る"
            fontAwesomeClass="fas fa-arrow-left"
            size="md"
            color="dark"
            onClick={() => dispatch(goBack())}
          />
        </div>
      </footer>
    </BasePagePC>
  );
};
