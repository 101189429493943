import { cloneDeep } from 'lodash';
import { useCallback, memo, useMemo } from 'react';
import { TagModel } from '../../../model/tag/tag';
import { RightLabelCheckbox } from '../checkbox/right-label-checkbox/right-label-checkbox';
import { UserAgent } from '../../../utilities/user-agent';
import { useEstimateAuthority } from '../../../hooks/use-authority';

type Props = {
  head: string;
  keys: string;
  model: TagModel | null;
  disabled?: boolean;
  onChange: (v: TagModel | null) => void;
}

export const TagCheckBoxex = (props: Props) => {
  const {
    head, keys, model, disabled, onChange: _onChange,
  } = props;

  const estimateAuth = useEstimateAuthority();

  const isOutEstimateSearch = useMemo(() => ((head === '工事状況' || head === '案件状況') && !estimateAuth), [estimateAuth, head]);

  /* Callback */
  const onChange = useCallback((id: number) => {
    model?.changeFlag(id);
    _onChange(cloneDeep(model));
  }, [model, _onChange]);

  return (
    UserAgent === 'pc' ? (
      <div className="item_box max_width">
        <div className="item_head">{head}</div>
        <div className="flex_wrap_box flex_grow_1">
          {model?.data
            .filter((v) => (!isOutEstimateSearch ? true : v.id !== 2))
            .map((v, i) => (
              <div className="same_width_checkbox" key={`${keys}${head}${i}`}>
                <RightLabelCheckbox
                  checked={v.flag}
                  label={v.label}
                  disabled={disabled}
                  onChange={() => onChange(v.id)}
                />
              </div>
            ))}
        </div>
      </div>
    ) : (
      <>
        <div className="item_label">{head}</div>
        <div className="item_body item_checkbox">
          {model?.data
            .filter((v) => (!isOutEstimateSearch ? true : v.id !== 2))
            .map((v, i) => (
              <div key={`${keys}${head}${i}`}>
                <RightLabelCheckbox
                  checked={v.flag}
                  label={v.label}
                  disabled={disabled}
                  onChange={() => onChange(v.id)}
                />
              </div>
            ))}
        </div>
      </>
    )
  );
};
