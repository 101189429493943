import actionCreatorFactory from 'typescript-fsa';
import { ApiBase } from '../../../../service/api-base';
import { ResponseHeader } from '../../../../type/api.type';
import { MasterCustomerRank } from '../../../../type/master/master-customer-rank.type';
import { CustomerImportPostData, ImportFileUploadResult } from './api-customer-import.type';

export const ActionCreator = actionCreatorFactory('master/api/customer-import');

export const apiCustomerImport = {
  getTemplate: ActionCreator('get/template'),
  uploadCsv: ActionCreator<{
    param: {
      filedata: File;
    },
    onSuccess:(v: ImportFileUploadResult) => void;
      }>('customer/upload/csv'),
  post: ActionCreator<{
    param: CustomerImportPostData[];
    onApi:(v: ApiImportPost) => void;
    onSuccess:() => void;
    onError: (index: number) => void;
    token: string;
      }>('post'),
};
export class ApiCustomerImport extends ApiBase {
  constructor() {
    super({
      httpMethod: 'GET',
      url: '/api/master/rank/customer',
    });
  }
}

export class ApiCustomerTemplate extends ApiBase {
  constructor() {
    super({
      httpMethod: 'GET',
      contents: 'BLOB',
      url: '/api/master/customer/template/download',
    });
  }
}

export class ApiImportUploadCSV extends ApiBase<{
    header: ResponseHeader;
    body: ImportFileUploadResult
  }> {
  constructor(param:{filedata: File}) {
    super({
      httpMethod: 'POST',
      url: '/api/master/customer/upload/csv',
      param,
    });
  }
}

export class ApiImportPost extends ApiBase {
  constructor(param:CustomerImportPostData) {
    super({
      httpMethod: 'POST',
      url: '/api/master/customer',
      param,
    });
  }
}
