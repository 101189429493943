/* eslint-disable no-irregular-whitespace */
import { goBack } from 'connected-react-router';
import { cloneDeep } from 'lodash';
import {
  useState, useCallback, useEffect,
} from 'react';
import { useDispatch } from 'react-redux';
import { EstimateCollection } from '../../../../../collection/estimate/estimate.collection';
import { useWillUnMount } from '../../../../../hooks/life-cycle';
import { EstimateMeisaiModel } from '../../../../../model/estimate/estimate-meisai.model';
import { ValidationLengthUnder500 } from '../../../../../model/validation';
import { EstimateValidation } from '../../../../../model/validation/estimate/estimate.validation';
import { ValidationDatePicker } from '../../../../../model/validation/validation-date-picker';
import { XmlParser } from '../../../../../parser/xml-parser';
import { DialogActions } from '../../../../../redux/dialog/dialog.action';
import { EstimateActions } from '../../../../../redux/estimate/estimate.action';
import { Project } from '../../../../../type/project/project.type';
import { constructionPeriodCalculation } from '../../../../../utilities/construction-period-calculation';
import { EditPC } from '../../../../dialogs/edit/edit.pc';
import { Button } from '../../../../ui/button/button';
import { LeftIconButton } from '../../../../ui/button/left-icon-button/left-icon-button';
import { DatePicker } from '../../../../ui/date-picker/date-picker';
import { RightLabelInputField } from '../../../../ui/input-field/right-label-input-field/right-label-input-field';
import { Input } from '../../../../ui/input/input';
import { Required } from '../../../../ui/required/required';
import { TextArea } from '../../../../ui/text-area/text-area';
import { ProjectSearch } from '../../../layout/search-box/project/project-search/project-search';
import { EstimateCopyDialog } from '../copy/estimate-copy-dialog';
import { ExplorePC } from '../explore/estimate-explore.pc';
import './estimate-edit.pc.scss';
import { EstimatePriceAreaCollectionPC } from './price-area/estimate-price-area.collection.pc';
import { EstimatePriceAreaPC } from './price-area/estimate-price-area.pc';
import { EstimateModel } from '../../../../../model/estimate/estimate-model';
import { useAppSelector } from '../../../../../hooks/use-redux';
import { getTermDay } from '../../../../../utilities/get-term-day';
import { Estimate } from '../../../../../type/estimate/estimate.type';
import { Message } from '../../../../../collection/message.collection';
import { MasterActions } from '../../../../../redux/master/master.action';
import { ListItem, XMLType } from '../../master/master-body/master-quote-fixed-body';
import { DateHelper } from '../../../../../utilities/date-helper';
import { PrintPreview, PrintType } from '../../../../dialogs/print-preview/print-preview';
import { useEditAuthEstimate } from '../../../../../hooks/use-authority';
import { DateFormatter } from '../../../../../utilities/date-formatter';

type Props = {
  id?: number;
  project?: Project;
  callback?: () => void;
}

export const EstimateEditPC = (props: Props) => {
  const {
    id: _id, project, callback,
  } = props;

  const dispatch = useDispatch();
  const user = useAppSelector((v) => (v.user));

  const [info, setInfo] = useState<Estimate | null>(null);

  /* 自動編集Parameter (編集不可) */
  const [id, setId] = useState(_id);
  const [genbaName, setGenbaName] = useState('');
  const [projectName, setProjectName] = useState('');
  const [estimateCreator, setEstimateCreator] = useState('');
  const [creatorId, setCreatorId] = useState(NaN);
  const [estimateNo, setEstimateNo] = useState('');
  // 0:編集不可, 1:通常編集, 2:受注変更ボタン
  const [allowEdit, setAllowEdit] = useState<0 | 1 | 2>(1);
  const editAuth = useEditAuthEstimate(info);
  /* 編集Parameter */
  const [editState, setEditState] = useState(EstimateCollection.editInitialState);
  const [meisaiModel, setMeisaiModel] = useState<EstimateMeisaiModel | null>(null);
  const [priceModel, setPriceModel] = useState(EstimatePriceAreaCollectionPC.initialEditState);
  const [isChangeJutyu, setIsChangeJutyu] = useState(false);

  /**  予備費1_名前*/
  const [reserve1, setReserve1] = useState<ListItem | null>(null);
  /**  予備費2_名前*/
  const [reserve2, setReserve2] = useState<ListItem | null>(null);

  // -- 編集可能かどうかを制御するためのやつ --
  const [termDay, setTermDay] = useState<number | undefined>(undefined);
  const [touch, setTouch] = useState(false);

  const post = useCallback(() => {
    if (allowEdit !== 1) return;
    const errorMessageList = EstimateValidation(editState, meisaiModel, priceModel);
    /* エラーメッセージが一つでもあれば登録しない */
    if (errorMessageList.length) {
      dispatch(DialogActions.pushMessage({
        title: `案件情報${id ? '更新' : '登録'}`,
        // message: Message.postError,
        message: errorMessageList,
        callback: () => setTouch(true),
      }));
      return;
    }
    dispatch(EstimateActions.api.estimate.post({
      param: {
        id,
        data: EstimateModel.postParm({
          editState,
          meisaiModel,
          priceModel,
          employee_id: isChangeJutyu ? user.id : creatorId,
          reserve1Name: reserve1?.$.name || '',
          reserve2Name: reserve2?.$.name || '',
        }),
      },
      update: !!id,
      onSuccess: (res) => {
        callback?.();
        if (!res) return;
        dispatch(EstimateActions.api.estimate.get({
          param: { id: res.id },
          callback: (v) => {
            setEstimateCreator(v.created_employee_name);
            setProjectName(v.project_name);
            setGenbaName(v.field_name);
            setEstimateNo(String(v.estimate_no));
            setId(v.id);
            setInfo(cloneDeep(v));
            setEditState({
              ...v,
              estimate_dt: v.estimate_dt ? new Date(v.estimate_dt) : null,
              estimate_kouki_start_dt: v.estimate_kouki_start_dt
                ? new Date(v.estimate_kouki_start_dt) : null,
              estimate_kouki_end_dt: v.estimate_kouki_end_dt
                ? new Date(v.estimate_kouki_end_dt) : null,
              yukokigen: v.yukokigen ? new Date(v.yukokigen) : null,
              jyutyu_yotei_dt: v.jyutyu_yotei_dt ? new Date(v.jyutyu_yotei_dt) : null,
            });
            if (v.meisai) {
              new XmlParser().parse(v.meisai).then((xml) => {
                setMeisaiModel(new EstimateMeisaiModel(xml));
              });
            } else {
              setMeisaiModel(new EstimateMeisaiModel());
            }
            setPriceModel({
              ...EstimateModel.setPriceArea(v),
            });
          },
        }));
      },
    }));
  },
  [
    editState, meisaiModel, priceModel, creatorId,
    id, reserve1, reserve2, allowEdit, callback, isChangeJutyu,
  ]);

  const onClickCopy = useCallback(() => {
    if (!meisaiModel || allowEdit !== 1) return;
    dispatch(DialogActions.push({
      title: '見積検索',
      className: 'max_height_dialog max_width_dialog',
      element: <EstimateCopyDialog
        callback={(v) => {
          dispatch(DialogActions.pop());
          meisaiModel.addMaster(v);
          setMeisaiModel(cloneDeep(meisaiModel));
        }}
      />,
    }));
  }, [meisaiModel, allowEdit]);

  const projectSearch = useCallback((yukokigen?:Date | null, isAdd?:boolean) => {
    if (allowEdit !== 1) return;
    dispatch(DialogActions.push({
      title: '案件検索',
      className: 'max_height_dialog max_width_dialog search_dialog',
      onCloseClick: () => { if (!isAdd)dispatch(DialogActions.clear()); },
      element: <ProjectSearch
        type="estimate"
        callback={(v) => {
          setGenbaName(v.field_name);
          setProjectName(v.name);
          setEditState({
            ...editState,
            customer_id: v.customer_id,
            project_id: v.id,
            yukokigen: yukokigen || editState.yukokigen,
          });
        }}
        onClose={() => { if (!isAdd) dispatch(DialogActions.clear()); }}
      />,
    }));
  }, [editState, allowEdit]);

  const onClickJutyu = useCallback(() => {
    // if (allowEdit !== 1) return;
    // this.jyutyu_change_flg = true;
    // this._addEstimate.keiyaku_flag = false;
    setEstimateNo('');
    setId(undefined);
    setIsChangeJutyu(true);
    setAllowEdit(1);
  }, [allowEdit]);

  /* 権限のセット */
  const setAuthority = useCallback((v: Estimate) => {
    let auth = false;
    if (!v) auth = false;
    if (!v.created_employee_id) auth = true;
    if (user.authority1) auth = true;
    if (user.id === v.created_employee_id) auth = true;

    if (!auth) {
      setAllowEdit(0);
      return;
    }
    setAllowEdit(1);
    if (v.contract_date && v.contract_no && v.saisyu_f) {
      setAllowEdit(0);
      if (!v.completion_date && !v.complete_date) {
        setAllowEdit(2);
      }
    }
  }, [user, editAuth]);

  const handleClickPrint = useCallback((type: PrintType) => {
    if (!info) return;
    dispatch(DialogActions.push({
      title: type === 'estimateIn' ? '見積書印刷プレビュー(社内用)' : '見積書印刷プレビュー',
      className: 'auto_height_dialog',
      element: <PrintPreview
        type={type}
        estimate={info}
      />,
    }));
  }, [info]);

  // 初期表示 -------------------------
  useEffect(() => {
    dispatch(MasterActions.api.quoteFixed.getList({
      param: {},
      callback: (qf) => {
        new XmlParser()
          .parse(qf.xml_format)
          .then((teikeiMaster: XMLType) => {
            const [genba, yobi] = teikeiMaster.list.item;

            if (_id !== undefined) {
              dispatch(EstimateActions.api.estimate.get({
                param: { id: _id },
                callback: (v) => {
                  setEstimateCreator(v.created_employee_name);
                  setProjectName(v.project_name);
                  setGenbaName(v.field_name);
                  setEstimateNo(String(v.estimate_no));
                  setAuthority(v);
                  setInfo(cloneDeep(v));
                  setEditState({
                    ...v,
                    estimate_dt: v.estimate_dt ? new Date(v.estimate_dt) : null,
                    estimate_kouki_start_dt: v.estimate_kouki_start_dt
                      ? new Date(v.estimate_kouki_start_dt) : null,
                    estimate_kouki_end_dt: v.estimate_kouki_end_dt
                      ? new Date(v.estimate_kouki_end_dt) : null,
                    yukokigen: v.yukokigen ? new Date(v.yukokigen) : null,
                    jyutyu_yotei_dt: v.jyutyu_yotei_dt ? new Date(v.jyutyu_yotei_dt) : null,
                  });
                  if (v.meisai) {
                    new XmlParser().parse(v.meisai).then((res) => {
                      setMeisaiModel(new EstimateMeisaiModel(res));
                    });
                  } else {
                    setMeisaiModel(new EstimateMeisaiModel());
                  }
                  setPriceModel({
                    ...EstimateModel.setPriceArea(v),
                  });
                },
              }));
              return;
            }
            setReserve1(genba);
            setReserve2(yobi);

            const yukokigen = DateHelper.calcMonth(new Date(), 1) || null;

            setPriceModel({
              ...priceModel,
              reserve1_estimate_percent: Number(genba.$.estimate),
              reserve1_genka_percent: Number(genba.$.genka),
              reserve2_estimate_percent: Number(yobi.$.estimate),
              reserve2_genka_percent: Number(yobi.$.genka),
            });

            /* 案件から新規登録 */
            if (project && user.id) {
              setEstimateCreator(user.name);
              setCreatorId(user.id);
              setMeisaiModel(new EstimateMeisaiModel());
              setGenbaName(project.field_name);
              setProjectName(project.name);
              setEditState({
                ...editState,
                project_id: project.id,
                customer_id: project.customer_id,
                yukokigen,
              });
              return;
            }

            if (!_id && !project && user.id) {
              setCreatorId(user.id);
              setEstimateCreator(user.name);
              setMeisaiModel(new EstimateMeisaiModel());
              projectSearch(yukokigen, true);
            }
          });
      },
    }));
  }, [user, _id]);

  useWillUnMount(() => {
    dispatch(EstimateActions.setEstimate(null));
  });

  useEffect(() => {
    setTermDay(getTermDay(
      editState.estimate_kouki_start_dt,
      editState.estimate_kouki_end_dt,
    ));
  }, [
    editState.estimate_kouki_start_dt,
    editState.estimate_kouki_end_dt,
  ]);

  return (
    <EditPC
      mode="dialog"
      className="sepalate"
      buttonArea={(
        <>
          <div className="left_box">
            <LeftIconButton
              label="過去見積から明細をコピー"
              fontAwesomeClass="fas fa-copy"
              size="md"
              color="primary"
              onClick={onClickCopy}
              disabled={allowEdit !== 1}
            />
            {info
              && (
              <>
                <LeftIconButton
                  label="見積書印刷"
                  fontAwesomeClass="fas fa-calculator"
                  size="md"
                  color="secondary"
                  onClick={() => handleClickPrint('estimate')}
                />
                <LeftIconButton
                  label="見積書印刷（社内用）"
                  fontAwesomeClass="fas fa-calculator"
                  size="md"
                  color="secondary"
                  onClick={() => handleClickPrint('estimateIn')}
                />
              </>
              )}
          </div>
          <div className="center_boxs">
            {/* <Button
              size="md"
              color="secondary"
              onClick={() => {}}
            >請求書印刷（簡易版）
            </Button>
            <Button
              size="md"
              color="secondary"
              onClick={() => {}}
            >請求書印刷（明細付）
            </Button>
            <Button
              size="md"
              color="secondary"
              onClick={() => {}}
            >請求登録
            </Button> */}
          </div>
          <div className="right_box">
            {allowEdit === 1
            && (
            <Button color="primary" size="md" onClick={post} disabled={allowEdit !== 1}>
              {!id || isChangeJutyu ? '登録' : '更新'}
            </Button>
            )}
            <LeftIconButton
              label={_id ? '戻る' : '閉じる'}
              fontAwesomeClass={_id ? 'fas fa-arrow-left' : 'fas fa-times'}
              size="md"
              color="dark"
              onClick={() => {
                dispatch(DialogActions.pushCheck({
                  callback: () => {
                    if (_id) {
                      dispatch(goBack());
                      return;
                    }
                    dispatch(DialogActions.pop());
                  },
                }));
              }}
            />
            {allowEdit === 2 && (
            <Button color="primary" size="md" onClick={onClickJutyu} disabled={!editAuth}>
              受注変更
            </Button>
            )}
          </div>
        </>
      )}
    >
      <div className="EstimateEditPC">
        <section className="estimate_detail">
          <div className="left_box">
            <div className="item_wrap">
              <div className="item_box">
                <div className="item_head">現場名称<Required /></div>
                <Input
                  value={genbaName}
                  require
                  disabled
                  touch={touch}
                  errorPosBottom
                />
              </div>
              <div className="item_box">
                <div className="item_head">案件名<Required /></div>
                <Input
                  value={projectName}
                  require
                  disabled
                  touch={touch}
                  errorPosBottom
                />
                {(!project && !id) && (
                <Button
                  size="sm"
                  color="secondary"
                  className="ml_10"
                  onClick={() => projectSearch()}
                  disabled={allowEdit !== 1}
                >案件参照
                </Button>
                )}
              </div>
              <div className="item_box">
                <div className="item_head">見積作成者</div>
                <Input
                  value={estimateCreator}
                  disabled
                />
              </div>
              <div className="item_box">
                <div className="item_head">見積日付<Required /></div>
                <DatePicker
                  date={editState.estimate_dt}
                  label="見積日付"
                  onChange={(v) => setEditState({ ...editState, estimate_dt: v })}
                  require
                  validationList={ValidationDatePicker}
                  touch={touch}
                  disabled={allowEdit !== 1}
                  errorPosBottom
                />
              </div>
              <div className="item_box">
                <div className="item_head">見積番号</div>
                <Input
                  value={estimateNo}
                  disabled
                />
              </div>
              <div className="item_box">
                <div className="item_head">工期<Required /></div>
                <DatePicker
                  date={editState.estimate_kouki_start_dt}
                  onChange={(v) => setEditState({ ...editState, estimate_kouki_start_dt: v })}
                  require
                  validationList={ValidationDatePicker}
                  touch={touch}
                  disabled={allowEdit !== 1}
                />
                <label className="ml_10">〜</label>
                <DatePicker
                  date={editState.estimate_kouki_end_dt}
                  onChange={(v) => setEditState({ ...editState, estimate_kouki_end_dt: v })}
                  require
                  validationList={ValidationDatePicker}
                  touch={touch}
                  disabled={allowEdit !== 1}
                />
                <RightLabelInputField
                  label="日間"
                  value={termDay}
                  maxLength={5}
                  onBlur={() => {
                    if (editState.estimate_kouki_start_dt) {
                      setEditState({
                        ...editState,
                        estimate_kouki_end_dt: constructionPeriodCalculation(
                          editState.estimate_kouki_start_dt,
                          editState.estimate_kouki_end_dt,
                          Number(termDay),
                        ),
                      });
                    } else {
                      setEditState({
                        ...editState,
                        estimate_kouki_start_dt: constructionPeriodCalculation(
                          editState.estimate_kouki_start_dt,
                          editState.estimate_kouki_end_dt,
                          Number(termDay),
                          true,
                        ),
                      });
                    }
                  }}
                  disabled={allowEdit !== 1}
                  onChange={(e) => {
                    setTermDay(Number(e.target.value) || undefined);
                  }}
                  className="during ml_10 align_r"
                />
              </div>
              <div className="item_wrap">
                <div className="item_box">
                  <div className="item_head">見積有効期限<Required /></div>
                  <DatePicker
                    date={editState.yukokigen}
                    onChange={(v) => setEditState({ ...editState, yukokigen: v })}
                    require
                    validationList={ValidationDatePicker}
                    touch={touch}
                    disabled={allowEdit !== 1}
                  />
                </div>
                <div className="item_box">
                  <div className="item_head">受注予定日<Required /></div>
                  <DatePicker
                    date={editState.jyutyu_yotei_dt}
                    onChange={(v) => setEditState({ ...editState, jyutyu_yotei_dt: v })}
                    require
                    validationList={ValidationDatePicker}
                    touch={touch}
                    disabled={allowEdit !== 1}
                  />
                </div>
                <div className="item_box">
                  <div className="item_head">登録日</div>
                  <Input
                    value={DateFormatter.date2str(info?.created_at, 'YYYYMMDD_HHmm')}
                    className="size_datepicker add_time"
                    disabled
                  />
                </div>
                <div className="item_box">
                  <div className="item_head">更新日</div>
                  <Input
                    value={DateFormatter.date2str(info?.updated_at, 'YYYYMMDD_HHmm')}
                    className="size_datepicker add_time"
                    disabled
                  />
                </div>
                <div className="item_box">
                  <div className="item_head">更新者</div>
                  <Input
                    value={info?.updated_employee_name || ''}
                    className="size_datepicker"
                    disabled
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="right_box">

            <div className="item_wrap">
              <div className="item_box">
                <div className="item_head">備考</div>
                <TextArea
                  rows={4}
                  className="large"
                  value={editState.remarks}
                  onChange={(e) => setEditState({ ...editState, remarks: e.target.value })}
                  validationList={ValidationLengthUnder500}
                  disabled={allowEdit !== 1}
                  errorPosBottom
                />
              </div>
            </div>
          </div>
        </section>
        <section className="estimate_explore">
          <ExplorePC
            priceModel={priceModel}
            model={meisaiModel}
            callback={(v) => setMeisaiModel(cloneDeep(v))}
            allowEdit={allowEdit}
          />
        </section>
        <section className="estimate_price_area">
          <EstimatePriceAreaPC
            // isEdit={Boolean(id)}
            reserve1={reserve1}
            reserve2={reserve2}
            info={info}
            estimateDt={editState.estimate_dt}
            model={meisaiModel}
            state={priceModel}
            callback={setPriceModel}
            allowEdit={allowEdit}
          />
        </section>
      </div>
    </EditPC>
  );
};
