import { cloneDeep, update } from 'lodash';
import {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { EstimateCollection } from '../../../../../collection/estimate/estimate.collection';
import { useDidMount } from '../../../../../hooks/life-cycle';
import { useAppSelector } from '../../../../../hooks/use-redux';
import { EstimateMeisaiModel } from '../../../../../model/estimate/estimate-meisai.model';
import { EstimateModel } from '../../../../../model/estimate/estimate-model';
import { ValidationLengthUnder500 } from '../../../../../model/validation';
import { EstimateValidation } from '../../../../../model/validation/estimate/estimate.validation';
import { ValidationDatePicker } from '../../../../../model/validation/validation-date-picker';
import { XmlParser } from '../../../../../parser/xml-parser';
import { DialogActions } from '../../../../../redux/dialog/dialog.action';
import { EstimateActions } from '../../../../../redux/estimate/estimate.action';
import { Project } from '../../../../../type/project/project.type';
import { constructionPeriodCalculation } from '../../../../../utilities/construction-period-calculation';
import { getTermDay } from '../../../../../utilities/get-term-day';
import { MathHelper } from '../../../../../utilities/math-helper';
import { EstimatePriceAreaCollectionPC } from '../../../../pc/pages/estimate/edit/price-area/estimate-price-area.collection.pc';
import { Button } from '../../../../ui/button/button';
import { DatePicker } from '../../../../ui/date-picker/date-picker';
import { TopLabelInputField } from '../../../../ui/input-field/top-label-input-field/top-label-input-field';
import { Input } from '../../../../ui/input/input';
import { Required } from '../../../../ui/required/required';
import { TextArea } from '../../../../ui/text-area/text-area';
import './estimate-edit.sp.scss';
import { ProjectSearchSP } from '../../../layout/search-box/project/project-search/project-search.sp';
import { EstimateMeisaiSP } from '../meisai/estimate-meisai.sp';
import { EstimateSearchSP } from '../../../layout/search-box/estimate/estimate-search/estimate-search.sp';
import { DetailPriceEditSP } from '../../estimate-detail/edit/detail-price-edit.sp';
import { RoutingPath } from '../../../../../routes/routing-pass';
import { Estimate } from '../../../../../type/estimate/estimate.type';
import { Message } from '../../../../../collection/message.collection';
import { MasterActions } from '../../../../../redux/master/master.action';
import { ListItem, XMLType } from '../../../../pc/pages/master/master-body/master-quote-fixed-body';
import { DateHelper } from '../../../../../utilities/date-helper';
import { useEditAuthEstimate } from '../../../../../hooks/use-authority';
import { LabelInput } from '../../../../ui/input/label-input';

type Props = {
  /** 複写して新規作成用ID */
  isCopy?: boolean;
  isChange?: boolean;
  id?: number;
  project?: Project;
  callback?: (id?: number) => void;
  callbackGetList?: () => void;
}

/** 見積編集 SP */
export const EstimateEditSP = (props: Props) => {
  const {
    isCopy, id: _id, project, callback, callbackGetList, isChange,
  } = props;

  /* Hooks */
  const footerEle = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const user = useAppSelector((v) => (v.user));

  /* State */
  /**  予備費1_名前*/
  const [reserve1, setReserve1] = useState<ListItem | null>(null);
  /**  予備費2_名前*/
  const [reserve2, setReserve2] = useState<ListItem | null>(null);

  /* 自動編集Parameter (編集不可) */
  const [id, setId] = useState(_id);
  const [info, setInfo] = useState<Estimate | null>(null);
  const [genbaName, setGenbaName] = useState('');
  const [projectName, setProjectName] = useState('');
  const [estimateCreator, setEstimateCreator] = useState('');
  const [creatorId, setCreatorId] = useState(NaN);
  const [isChangeJutyu, setIsChangeJutyu] = useState(false);

  // 0:編集不可, 1:通常編集, 2:受注変更ボタン
  const [allowEdit, setAllowEdit] = useState<0 | 1 | 2>(1);
  const editAuth = useEditAuthEstimate(info);

  /* 編集Parameter */
  const [editState, setEditState] = useState(EstimateCollection.editInitialState);
  const [meisaiModel, setMeisaiModel] = useState<EstimateMeisaiModel | null>(null);
  const [priceModel, setPriceModel] = useState(EstimatePriceAreaCollectionPC.initialEditState);
  const [termDay, setTermDay] = useState<number | undefined>(undefined);
  const [touch, setTouch] = useState(false);

  /* 保存 */
  const post = useCallback(() => {
    if (allowEdit !== 1) return;
    const errorMessageList = EstimateValidation(editState, meisaiModel, priceModel);
    if (errorMessageList.length) {
      dispatch(DialogActions.pushMessage({
        title: `見積情報${id ? '更新' : '登録'}`,
        // message: Message.postError,
        message: errorMessageList,
        callback: () => setTouch(true),
      }));
      return;
    }
    dispatch(EstimateActions.api.estimate.post({
      param: {
        id: isCopy ? undefined : id,
        data: EstimateModel.postParm({
          editState,
          meisaiModel,
          priceModel,
          employee_id: isChangeJutyu ? user.id : creatorId,
          reserve1Name: reserve1?.$.name || '',
          reserve2Name: reserve2?.$.name || '',
        }),
      },
      update: !!id,
      onSuccess: (res) => {
        callbackGetList?.();
        callback?.(res?.id);
        if (!res) return;
        dispatch(EstimateActions.api.estimate.get({
          param: { id: res.id },
          callback: (v) => {
            setEstimateCreator(v.created_employee_name);
            setProjectName(v.project_name);
            setGenbaName(v.field_name);
            // setEstimateNo(String(v.internal_id));
            setId(v.id);
            setInfo(cloneDeep(v));
            setEditState({
              ...v,
              estimate_dt: v.estimate_dt ? new Date(v.estimate_dt) : null,
              estimate_kouki_start_dt: v.estimate_kouki_start_dt
                ? new Date(v.estimate_kouki_start_dt) : null,
              estimate_kouki_end_dt: v.estimate_kouki_end_dt
                ? new Date(v.estimate_kouki_end_dt) : null,
              yukokigen: v.yukokigen ? new Date(v.yukokigen) : null,
              jyutyu_yotei_dt: v.jyutyu_yotei_dt ? new Date(v.jyutyu_yotei_dt) : null,
            });
            if (v.meisai) {
              new XmlParser().parse(v.meisai).then((xml) => {
                setMeisaiModel(new EstimateMeisaiModel(xml));
              });
            } else {
              setMeisaiModel(new EstimateMeisaiModel());
            }
            setPriceModel({
              ...EstimateModel.setPriceArea(v),
            });
          },
        }));
      },
    }));
  },
  [
    callbackGetList,
    editState,
    callback,
    meisaiModel,
    priceModel,
    creatorId,
    isCopy,
    reserve1,
    reserve2,
    allowEdit,
    id,
  ]);

  /* 案件検索 */
  const projectSearch = useCallback((isAdd?:boolean, yukokigen?:Date | null) => {
    if (allowEdit !== 1) return;
    dispatch(DialogActions.push({
      title: '案件検索',
      onCloseClick: () => { if (isAdd) dispatch(DialogActions.clear()); },
      element: <ProjectSearchSP
        type="estimate"
        callback={(v) => {
          setGenbaName(v.field_name);
          setProjectName(v.name);
          setEditState({
            ...editState,
            customer_id: v.customer_id,
            project_id: v.id,
            yukokigen: yukokigen || editState.yukokigen,
          });
        }}
      />,
    }));
  }, [editState, allowEdit]);

  /* 左下にあるボタン */
  /* コピーか複写 */
  const handleClickLeftBtn = useCallback(() => {
    if (!meisaiModel) return;
    if (!id || isCopy) {
      if (allowEdit !== 1) return;
      // 過去見積もりから明細コピー
      dispatch(DialogActions.push({
        title: '見積検索',
        element: <EstimateSearchSP callback={(v) => {
          dispatch(DialogActions.pop());
          meisaiModel.addMaster(v);
          setMeisaiModel(cloneDeep(meisaiModel));
        }}
        />,
      }));
      return;
    }
    dispatch(DialogActions.pop());
    // 複写して新規作成
    dispatch(DialogActions.push({
      title: isChange ? '受注変更' : '複写して新規作成',
      element: <EstimateEditSP
        id={Number(id)}
        isCopy
        callback={() => {
          dispatch(push(RoutingPath.estimate));
        }}
      />,
    }));
  }, [meisaiModel, id, isCopy, allowEdit, isChange]);

  const onClickJutyu = useCallback(() => {
    handleClickLeftBtn();
    // if (allowEdit !== 1) return;
    // this.jyutyu_change_flg = true;
    // this._addEstimate.keiyaku_flag = false;
    // setId(undefined);
    setIsChangeJutyu(true);
    // setAllowEdit(1);
  }, [allowEdit, handleClickLeftBtn]);

  /* 権限のセット */
  const setAuthority = useCallback((v:Estimate) => {
    if (!editAuth) {
      setAllowEdit(0);
      return;
    }
    setAllowEdit(1);
    if (v.contract_date && v.contract_no && v.saisyu_f) {
      setAllowEdit(0);
      if (!v.completion_date && !v.complete_date) setAllowEdit(2);
    }
  }, [user, editAuth]);

  useEffect(() => {
    dispatch(MasterActions.api.quoteFixed.getList({
      param: {},
      callback: (qf) => {
        new XmlParser()
          .parse(qf.xml_format)
          .then((teikeiMaster: XMLType) => {
            const [genba, yobi] = teikeiMaster.list.item;

            /* 編集の場合 */
            if (_id !== undefined && user.id) {
              dispatch(EstimateActions.api.estimate.get({
                param: { id: _id },
                callback: (v) => {
                  setInfo(cloneDeep(v));
                  if (isCopy) {
                    setCreatorId(user.id);
                  } else {
                    setAuthority(v);
                  }
                  setProjectName(v.project_name);
                  setGenbaName(v.field_name);
                  setEstimateCreator(v.created_employee_name);
                  setCreatorId(v.employee_id);
                  setEditState({
                    ...v,
                    estimate_dt: v.estimate_dt ? new Date(v.estimate_dt) : null,
                    estimate_kouki_start_dt: v.estimate_kouki_start_dt
                      ? new Date(v.estimate_kouki_start_dt) : null,
                    estimate_kouki_end_dt: v.estimate_kouki_end_dt
                      ? new Date(v.estimate_kouki_end_dt) : null,
                    yukokigen: v.yukokigen ? new Date(v.yukokigen) : null,
                    jyutyu_yotei_dt: v.jyutyu_yotei_dt ? new Date(v.jyutyu_yotei_dt) : null,
                  });

                  /* 明細のセット */
                  if (v.meisai) {
                    new XmlParser().parse(v.meisai).then((res) => {
                      setMeisaiModel(new EstimateMeisaiModel(res));
                    });
                  } else {
                    setMeisaiModel(new EstimateMeisaiModel());
                  }

                  /* 金額のセット */
                  setPriceModel({
                    ...EstimatePriceAreaCollectionPC.initialEditState,
                    ...v,
                  });
                },
              }));
              return;
            }

            setReserve1(genba);
            setReserve2(yobi);

            const yukokigen = DateHelper.calcMonth(new Date(), 1) || null;

            setPriceModel({
              ...priceModel,
              reserve1_estimate_percent: genba.$.estimate,
              reserve1_genka_percent: genba.$.genka,
              reserve2_estimate_percent: yobi.$.estimate,
              reserve2_genka_percent: yobi.$.genka,
            });

            /* 案件から登録する場合 */
            if (project) {
              setEstimateCreator(user.name);
              setCreatorId(user.id);
              setMeisaiModel(new EstimateMeisaiModel());
              setGenbaName(project.field_name);
              setProjectName(project.name);
              setEditState({
                ...editState,
                customer_id: project.customer_id,
                project_id: project.id,
                yukokigen,
              });
            }
          });
      },
    }));
  }, []);

  /* 初期表示 */
  useEffect(() => {
    /* 新規登録で案件から登録でない場合 */
    if (!_id && !project) {
      const yukokigen = DateHelper.calcMonth(new Date(), 1) || null;
      setEstimateCreator(user.name);
      setCreatorId(user.id);
      setMeisaiModel(new EstimateMeisaiModel());
      projectSearch(true, yukokigen);
    }
  }, [_id, project]);

  /* 日間計算 */
  useEffect(() => {
    setTermDay(getTermDay(
      editState.estimate_kouki_start_dt,
      editState.estimate_kouki_end_dt,
    ));
  }, [
    editState.estimate_kouki_start_dt,
    editState.estimate_kouki_end_dt,
  ]);

  console.log('test2');

  return (
    <>
      <div className="edit_sp_body estimate_edit_sp">
        {/* edit_sp_body_inner は各画面の共通用 */}
        <div className="edit_sp_body_inner estimate_edit_sp__contents">

          <div className="estimate_edit_sp__contents__up_side">
            <div className="category_wrap">
              <div className="item_wrap item_site">
                <div className="item_label">
                  <span>現場名称</span>
                  {(!project && !id)
                  && (
                  <Button
                    onClick={() => projectSearch()}
                    size="md"
                    color="secondary"
                    disabled={allowEdit !== 1}
                  >
                    案件検索
                  </Button>
                  )}
                </div>
                <Input
                  value={genbaName}
                  require
                  disabled
                />
              </div>
              <div className="item_wrap">
                <TopLabelInputField
                  className="full_width"
                  label="案件名"
                  value={projectName}
                  require
                  disabled
                />
              </div>
              <div className="item_wrap">
                <TopLabelInputField
                  label="見積作成者"
                  value={estimateCreator}
                  disabled
                  className="full_width"
                />
              </div>
            </div>

            <div className="category_wrap">
              <div className="item_wrap item_date_picker">
                <div className="item_label">見積日付<Required /></div>
                <div className="item_body">
                  <DatePicker
                    date={editState.estimate_dt}
                    onChange={(v) => setEditState({ ...editState, estimate_dt: v })}
                    touch={touch}
                    disabled={allowEdit !== 1}
                    require
                  />
                </div>
              </div>
              <div className="item_wrap item_date_picker item_construction_period">
                <div className="item_label" style={{ alignItems: 'center' }}>
                  <div className="col1">工期<Required /></div>
                  <div className="col2">
                    <LabelInput pos="Right" label="日間">
                      <Input
                        value={termDay}
                        onBlur={() => {
                          if (editState.estimate_kouki_start_dt) {
                            setEditState({
                              ...editState,
                              estimate_kouki_end_dt: constructionPeriodCalculation(
                                editState.estimate_kouki_start_dt,
                                editState.estimate_kouki_end_dt,
                                Number(termDay),
                              ),
                            });
                          } else {
                            setEditState({
                              ...editState,
                              estimate_kouki_start_dt: constructionPeriodCalculation(
                                editState.estimate_kouki_start_dt,
                                editState.estimate_kouki_end_dt,
                                Number(termDay),
                                true,
                              ),
                            });
                          }
                        }}
                        onChange={(e) => {
                          setTermDay(Number(e.target.value) || undefined);
                        }}
                        className="during"
                        disabled={allowEdit !== 1}
                        maxLength={5}
                      />
                    </LabelInput>
                  </div>
                </div>
                <div className="item_body item_schedule wrap">
                  <div className="item_schedule__form">
                    <DatePicker
                      date={editState.estimate_kouki_start_dt}
                      onChange={(v) => setEditState({ ...editState, estimate_kouki_start_dt: v })}
                      touch={touch}
                      disabled={allowEdit !== 1}
                      require
                    />
                  </div>
                  <div className="item_schedule__form flex_box flex_align_center mt_10">
                    <div className="item_schedule__tilde">〜</div>
                    <DatePicker
                      date={editState.estimate_kouki_end_dt}
                      onChange={(v) => setEditState({ ...editState, estimate_kouki_end_dt: v })}
                      touch={touch}
                      disabled={allowEdit !== 1}
                      require
                    />
                  </div>
                </div>
              </div>
              <div className="item_wrap item_date_picker">
                <div className="item_label">見積有効期限<Required /></div>
                <div className="item_body">
                  <DatePicker
                    date={editState.yukokigen}
                    onChange={(v) => setEditState({ ...editState, yukokigen: v })}
                    touch={touch}
                    disabled={allowEdit !== 1}
                    require
                  />
                </div>
              </div>
              <div className="item_wrap item_date_picker">
                <div className="item_label">受注予定日<Required /></div>
                <div className="item_body">
                  <DatePicker
                    date={editState.jyutyu_yotei_dt}
                    onChange={(v) => setEditState({ ...editState, jyutyu_yotei_dt: v })}
                    touch={touch}
                    disabled={allowEdit !== 1}
                    require
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="estimate_edit_sp__secondary_contents">
            <EstimateMeisaiSP
              model={meisaiModel}
              callback={(v) => {
                console.log('kotti');
                setMeisaiModel(cloneDeep(v));
              }}
              allowEdit={allowEdit}
              callbackPost={(v) => setMeisaiModel(cloneDeep(v))}
            />
            <DetailPriceEditSP
              info={info}
              reserve1={reserve1}
              reserve2={reserve2}
              estimateDt={editState.estimate_dt}
              model={meisaiModel}
              state={priceModel}
              callback={setPriceModel}
              allowEdit={allowEdit}
            />
            <div className="estimate_edit_sp__secondary_contents__remarks">
              <div>備考</div>
              <TextArea
                value={editState.remarks}
                rows={10}
                onChange={(e) => setEditState({ ...editState, remarks: e.target.value })}
                validationList={ValidationLengthUnder500}
                disabled={allowEdit !== 1}
              />
            </div>
          </div>
        </div>

        <div className="estimate_edit_sp__secondary_footer">
          <div className="estimate_edit_sp__secondary_footer__wrap">
            <div className="row">
              <div className="row__col1">小計:</div>
              <div className="row__col2">&yen;&nbsp;{MathHelper.rounding2Str(priceModel.gokei_zeinuki_kin, 0, 'floor', true)}</div>
            </div>
            <div className="row">
              <div className="row__col1">消費税:</div>
              <div className="row__col2">&yen;&nbsp;{MathHelper.rounding2Str(priceModel.shohizei_kin, 0, 'floor', true)}</div>
            </div>
            <div className="row">
              <div className="row__col1">総合計:</div>
              <div className="row__col2">&yen;&nbsp;{MathHelper.rounding2Str(priceModel.gokei_kin, 0, 'floor', true)}</div>
            </div>
          </div>
        </div>
      </div>

      <div className="edit_sp_footer estimate_edit_sp_footer" ref={footerEle}>
        <Button
          size="md"
          color="primary"
          onClick={handleClickLeftBtn}
          disabled={allowEdit !== 1}
        >
          {!id || isCopy ? '過去見積コピー' : '複写して新規作成'}
        </Button>
        {allowEdit === 1
        && (
        <Button
          size="md"
          color="primary"
          onClick={post}
          disabled={allowEdit !== 1}
        >
          {id && !isCopy ? '更新' : '登録'}
        </Button>
        )}
        <Button
          size="md"
          color="dark"
          onClick={() => {
            dispatch(DialogActions.pushCheck({
              callback: () => {
                dispatch(DialogActions.pop());
              },
            }));
          }}
        >キャンセル
        </Button>
        {allowEdit === 2 && (
        <Button color="primary" size="md" onClick={onClickJutyu} disabled={!editAuth}>
          受注変更
        </Button>
        )}
      </div>
    </>
  );
};
