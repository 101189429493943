/* eslint-disable no-irregular-whitespace */
import { push } from 'connected-react-router';
import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import CopyToClipboard from 'react-copy-to-clipboard';
import { DialogActions } from '../../../../../redux/dialog/dialog.action';
import { MapActions } from '../../../../../redux/map/map.action';
import { RoutingPath } from '../../../../../routes/routing-pass';
import { MaintenanceList } from '../../../../../type/maintenance/maintenance.type';
import { joinStr } from '../../../../../utilities/join-str';
import { noPinch } from '../../../../../utilities/no-pinch';
import { openLink, openSMS } from '../../../../../utilities/open-link';
import { openTel } from '../../../../../utilities/open-tel';
import { MaintenanceEditSP } from '../../../../sp/pages/maintenance/edit/maintenance-edit.sp';
import { Button } from '../../../button/button';
import { RectLabel } from '../../../label/rect-label/rect-label';
import maintenanceCompleted from '../../../../../asset/images/pin/maintenance_completed.svg';
import maintenanceStarted from '../../../../../asset/images/pin/maintenance_started.svg';
import { ShowTypeLabel } from '../../../label/show-type/show-type-label';
import './maintenance-info-window.sp.scss';
import { MaintenanceEditDialogTitle } from '../../../../sp/pages/maintenance/edit/maintenance-edit.type';
import { IconButton } from '../../../button/icon-button/icon-button';
import { RouteDialog } from '../../../../dialogs/route-dialog/route-dialog';
import { useAppSelector } from '../../../../../hooks/use-redux';
import { isMobilePhone } from '../../../../../utilities/check-mobile-phone';
import { DateFormatter as DF } from '../../../../../utilities/date-formatter';
import { useMailContact, useSNSContact, useTelContact } from '../../../../../hooks/support-history/use-support-history';

type maintenance_info_window_spProps = {
  maintenance: MaintenanceList;
  callBack: () => void;
  index: number;
  callbackGetList?: () => void;
};

export const MaintenanceInfoWindowSP = (props: maintenance_info_window_spProps) => {
  const {
    maintenance, callBack, index, callbackGetList,
  } = props;

  const [detailFlag, setDetailFlag] = useState(false);
  const routeAuth = useAppSelector((v) => v.user.company_authority3);

  const validDate = useMemo(() => {
    const today = new Date();
    const mDate = DF.str2date(maintenance.maintenance_date);
    if (!mDate) return ' alert';
    if (today.getFullYear() === mDate.getFullYear()
    && today.getMonth() === mDate.getMonth()
    && today.getDate() === mDate.getDate()
    && !maintenance.supported_kubun) {
      return ' alert';
    }

    if (maintenance.maintenance_past_flag && !maintenance.supported_kubun) {
      return ' alert';
    }
    return '';
  }, [maintenance]);

  const ele = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();

  const telContact = useTelContact();
  const mailContact = useMailContact();
  const contactSNS = useSNSContact();

  const handleClickArrow = useCallback(() => {
    if (detailFlag) {
      setDetailFlag(false);
      // callBack();
    } else {
      setDetailFlag(true);
    }
  }, [detailFlag]);

  const handleClickTel = useCallback(() => {
    telContact({
      type: 'maintenance',
      data: maintenance,
      isPushDialog: true,
    });
    // openTel({ tel: maintenance.tel_no });
    // openTel({ tel: maintenance.field_tel_no });
  }, [maintenance]);

  const handleClickSMS = useCallback(() => {
    telContact({
      type: 'maintenance',
      data: maintenance,
      isPushDialog: true,
      isSMS: true,
    });
  }, [maintenance]);

  const handleClickLine = useCallback(() => {
    if (!maintenance || !maintenance.line_id) return;
    contactSNS({
      mode: 'LINE',
      name: maintenance.customer_name,
      customerId: maintenance.customer_id,
      id: maintenance.line_id,
    });
    // openLineMessage(`/#${RoutingPath.maintenanceDetail}/${maintenance.id}`);
  }, [maintenance.id]);

  const handeleClickHere = useCallback(() => {
    if (!routeAuth) return;
    dispatch(MapActions.setGpsStatus('out'));
    dispatch(DialogActions.push({
      title: 'ルート設定',
      className: 'max_height_dialog for_route',
      element: <RouteDialog
        type="customer"
        destination={
          `${maintenance.field_prefecture_name || ''}
        ${maintenance.field_city || ''}
        ${maintenance.field_address || ''}
        ${maintenance.field_building_name || ''}`
        }
        callback={() => { }}
      />,
    }));
  }, [maintenance, routeAuth]);

  useEffect(() => {
    const pinchCallback = noPinch(ele.current);
    return pinchCallback;
  }, [ele]);

  /* TODO 後で消す */
  let cRankColor = '#1451a1';
  // let pRankColor = '#1451a1';
  let status = '';
  let img = '';
  let pClassName = '';
  switch ('プラチナ') {
    case 'プラチナ':
    default:
      cRankColor = '#d06d8c';
      break;
  }
  switch (maintenance.supported_kubun) {
    case true:
    default:
      // pRankColor = '#1451a1';
      status = '完工';
      img = maintenanceCompleted;
      pClassName = 'maintenance_completed';
      break;
    case false:
      // pRankColor = '#0A7B24';
      status = '工事中';
      img = maintenanceStarted;
      pClassName = 'maintenance_started';
      break;
  }

  const row2Contents = (
    <>
      <Button
        color="secondary"
        size="md"
        onClick={() => dispatch(push(`${RoutingPath.maintenanceDetail}/${maintenance.id}`))}
      >メンテ詳細
      </Button>

      <Button
        color="secondary"
        size="md"
        onClick={() => dispatch(DialogActions.push({
          title: MaintenanceEditDialogTitle.update,
          element: <MaintenanceEditSP
            id={maintenance.id}
            callbackGetList={() => callbackGetList?.()}
          />,
        }))}
      >メンテ編集
      </Button>
    </>
  );

  return (
    <div className="maintenance_info_window_sp info_window" ref={ele}>
      <div className="info_window_arrow_btn" onClick={handleClickArrow}>
        <i className={`fas fa-angle-double-${!detailFlag ? 'up' : 'down'}`} />
      </div>
      <IconButton
        title="閉じる"
        fontAwesomeClass="fas fa-times"
        className="default info_window_close_btn"
        onClick={callBack}
      />

      {detailFlag ? (

        /* ===================== 詳細表示 ===================== */
        <div className="info_window_info detail">

          <div className="info_window_info_row row1">
            <div className="row1_col1">
              <div className="important">
                {maintenance.title}
              </div>
              <div className="row_table_style">
                <div className="t_row">
                  <div className="t_header"><i className="fas fa-user" title="顧客名" /></div>
                  <div className="t_body">
                    {maintenance.customer_name} {/* `(${maintenance.furigana})`*/}
                  </div>
                </div>
                <div className="t_row">
                  <div className="t_header"><i className="fas fa-map-marker-alt" title="住所" /></div>
                  <div className="t_body">
                    {`〒${joinStr(maintenance.post_no, 3, '-') || '---'}`}<br />
                    {maintenance.field_prefecture_name || ''}
                    {maintenance.field_city || ''}
                    {maintenance.field_address || ''}<br />
                    {maintenance.field_building_name || ''}
                  </div>
                </div>
                <div className="t_row">
                  <div className="t_header"><i className="fas fa-wrench" title="現場名称" /></div>
                  <div className="t_body">{maintenance.project_field_name || '---'}</div>
                </div>

                <div className="t_row">
                  <div className="t_header"><i className="fas fa-phone" title="TEL" /></div>
                  <div className="t_body">{maintenance?.tel_no || '---'}</div>
                  <div>
                    {/*
                    <IconButton
                      fontAwesomeClass="fas fa-phone"
                      onClick={handleClickTel}
                      size="md"
                      color="secondary"
                      disabled={!maintenance?.tel_no}
                    />
                    */}
                    {/*
                    <IconButton
                      color="secondary"
                      size="md"
                      onClick={handleClickSMS}
                      disabled={!maintenance.tel_no || !isMobilePhone(maintenance.tel_no)}
                      fontAwesomeClass="fas fa-sms"
                      className="ml_5"
                    />
                    */}
                  </div>
                </div>

                <div className="t_row">
                  <div className="t_header"><i className="fas fa-fax" /></div>
                  <div className="t_body">{maintenance.field_fax_no || '---'}</div>
                </div>

                <div className="t_row">
                  <div className="t_header"><i className="fas fa-tools" title="工事部位" /></div>
                  <div className="t_body">{maintenance.project_construction_parts_name?.join('/') || '---'}</div>
                </div>

                <div className="t_row">
                  <div className="t_header"><i className="far fa-building" title="担当店舗" /></div>
                  <div className="t_body">{maintenance.project_store_name || '---'}</div>
                </div>

                <div className="t_row">
                  <div className="t_header"><i className="fas fa-user-circle" title="担当者" /></div>
                  <div className="t_body">{maintenance.project_employee_name || '---'}</div>
                </div>

              </div>
            </div>

            <div className="row1_col2 ">
              <ShowTypeLabel
                label={maintenance.supported_kubun === false ? '未対応' : '対応済'}
                showTypeImg={
                  maintenance.supported_kubun
                    === false ? maintenanceStarted : maintenanceCompleted
                }
                className={
                  maintenance.supported_kubun === false ? 'maintenance_started' : 'maintenance_completed'
                }
              />
              {/* <RectLabel label={status} bgColor={pRankColor} />*/}
              {/* eslint-disable-next-line no-constant-condition */}
              <RectLabel label={maintenance.rank_name || 'ランクなし'} bgColor={maintenance.background_color || 'gray'} />
            </div>
          </div>
          {/*
          <div className="info_window_info_row row2">
            {row2Contents}
          </div>
          */}
        </div>
      ) : (
        /* ===================== 簡易表示 ===================== */
        <div className="info_window_info simple">
          <div className="info_window_info_row row1">
            <div className="row1_col1">
              <div className="important">
                {maintenance.title}
              </div>
              <div className="row_table_style">
                <div className="t_row">
                  <div className="t_header"><i className="fas fa-user" title="顧客名" /></div>
                  <div className="t_body">
                    {maintenance.customer_name} {/* `(${maintenance.furigana})`*/}
                  </div>
                </div>
                <div className="t_row">
                  <div className="t_header"><i className="fas fa-map-marker-alt" title="住所" /></div>
                  <div className="t_body">
                    {`〒${joinStr(maintenance.post_no, 3, '-') || '---'}`}<br />
                    {maintenance.field_prefecture_name || ''}
                    {maintenance.field_city || ''}
                    {maintenance.field_address || ''}<br />
                    {maintenance.field_building_name || ''}
                  </div>
                </div>

                <div className="t_row">
                  <div className="t_header"><i className="fas fa-phone" title="TEL" /></div>
                  <div className="t_body">{maintenance.tel_no || '---'}</div>
                  <div>
                    {/*
                    <IconButton
                      fontAwesomeClass="fas fa-phone"
                      onClick={handleClickTel}
                      size="md"
                      color="secondary"
                      disabled={!maintenance?.tel_no}
                    />
                    */}
                    {/*
                    <IconButton
                      color="secondary"
                      size="md"
                      onClick={handleClickSMS}
                      disabled={!maintenance.tel_no || !isMobilePhone(maintenance.tel_no)}
                      fontAwesomeClass="fas fa-sms"
                    />
                    */}
                  </div>
                </div>

                <div className="t_row">
                  <div className="t_header"><i className="fas fa-user-circle" title="担当者" /></div>
                  <div className="t_body">{maintenance.project_employee_name || '---'}</div>
                </div>
              </div>

            </div>

            <div className="row1_col2 ">
              <ShowTypeLabel
                label={maintenance.supported_kubun === false ? '未対応' : '対応済'}
                showTypeImg={
                  maintenance.supported_kubun
                    === false ? maintenanceStarted : maintenanceCompleted
                }
                className={
                  maintenance.supported_kubun === false ? 'maintenance_started' : 'maintenance_completed'
                }
              />
              {/* <RectLabel label={status} bgColor={pRankColor} />*/}
              {/*  eslint-disable-next-line no-constant-condition */}
              <RectLabel label={maintenance.rank_name || 'ランクなし'} bgColor={maintenance.background_color || 'gray'} />
              <table className={`maintenance_date${validDate}`}>
                <tr>
                  <th>
                    ﾒﾝﾃﾅﾝｽ日
                  </th>
                </tr>
                <tr>
                  <td>
                    {DF.date2str(maintenance.maintenance_date) || ''}
                  </td>
                </tr>
              </table>
            </div>
          </div>
          {/*
          <div className="info_window_info_row row2">
            {row2Contents}
          </div>
*/}
        </div>
      )}

      <div className="info_window_footer scroll">
        {row2Contents}
        <Button className="icon_btn" color="primary" size="sm" onClick={handleClickTel} disabled={!maintenance.tel_no}>
          <i className="fas fa-phone" />
        </Button>
        <Button className="icon_btn" color="primary" size="sm" onClick={handleClickSMS} disabled={!maintenance.tel_no || !isMobilePhone(maintenance.tel_no)}>
          <i className="fas fa-sms" />
        </Button>
        {/* FIXME LINEIDの追加 */}
        <CopyToClipboard text="">
          <Button className="icon_btn" color="primary" size="sm" onClick={handleClickLine} disabled={!maintenance.line_id}>
            <i className="fab fa-line" />
          </Button>
        </CopyToClipboard>
        <Button color="primary" size="md" onClick={handeleClickHere} disabled={!routeAuth}>ここへ行く</Button>
      </div>
    </div>
  );
};
