/* eslint-disable no-irregular-whitespace */
import {
  useCallback, useMemo,
} from 'react';
import './maintenance-info-window.pc.scss';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { IconButton } from '../../../button/icon-button/icon-button';
import { Button } from '../../../button/button';
import { MaintenanceList } from '../../../../../type/maintenance/maintenance.type';
import { MapActions } from '../../../../../redux/map/map.action';
import { joinStr } from '../../../../../utilities/join-str';
import { RectLabel } from '../../../label/rect-label/rect-label';
import maintenance_completed from '../../../../../asset/images/icon_maintenance_completed.svg';
import maintenance_started from '../../../../../asset/images/icon_maintenance_started.svg';
import { ShowTypeLabel } from '../../../label/show-type/show-type-label';
import { DialogActions } from '../../../../../redux/dialog/dialog.action';
import { MaintenanceEditPC } from '../../../../pc/pages/maintenance/edit/maintenance-edit.pc';
import { RoutingPath } from '../../../../../routes/routing-pass';
import { RouteDialog } from '../../../../dialogs/route-dialog/route-dialog';
import { useAppSelector } from '../../../../../hooks/use-redux';
// import { StreetViewImg } from '../../../street-view-img/street-view-img';
import { DateFormatter as DF } from '../../../../../utilities/date-formatter';

type MaintenanceInfoWindowProps = {
  maintenance: MaintenanceList;
  callbackRegist?: (v: MaintenanceList) => void;
  callbackClose: () => void;
  callbackGetList?: () => void;
  index: number;
};

export const MaintenanceInfoWindowPC = (props: MaintenanceInfoWindowProps) => {
  const {
    maintenance, callbackRegist, callbackClose, index, callbackGetList,
  } = props;

  const dispatch = useDispatch();
  const routeAuth = useAppSelector((v) => v.user.company_authority3);

  const validDate = useMemo(() => {
    const today = new Date();
    const mDate = DF.str2date(maintenance.maintenance_date);
    if (!mDate) return ' alert';
    if (today.getFullYear() === mDate.getFullYear()
    && today.getMonth() === mDate.getMonth()
    && today.getDate() === mDate.getDate()
    && !maintenance.supported_kubun) {
      return ' alert';
    }

    if (maintenance.maintenance_past_flag && !maintenance.supported_kubun) {
      return ' alert';
    }
    return '';
  }, [maintenance]);

  const handleClickDetail = useCallback(() => {
    dispatch(DialogActions.push({
      title: 'メンテナンス情報入力',
      className: 'maintenance',
      element: <MaintenanceEditPC
        id={maintenance.id}
        callbackGetList={() => callbackGetList?.()}
      />,
    }));
  }, [maintenance.id]);

  const handleClickHere = useCallback(() => {
    if (!routeAuth) return;
    dispatch(MapActions.setGpsStatus('out'));
    dispatch(DialogActions.push({
      title: 'ルート設定',
      element: <RouteDialog
        type="customer"
        destination={`${maintenance.field_prefecture_name || ''}
        ${maintenance.field_city || ''}
        ${maintenance.field_address || ''}
        ${maintenance.field_building_name || ''}`}
        callback={() => { }}
      />,
    }));
  }, [maintenance, routeAuth]);

  return (
    <div className="card">
      <div className="name">{maintenance.title || '---'}</div>
      <div className="card_base_row">
        <div className="col">
          <div className="sub_name">
            <i className="fas fa-user" title="顧客名" />
            <Link to={`${RoutingPath.customerDetail}/${maintenance.customer_id}`}>
              {maintenance.customer_name || '---'}{/* （{maintenance.furigana || '---'}）*/}
              <i className="fas fa-link ml_10" title={maintenance.customer_name || '---'} />
            </Link>
          </div>
          <div className="address_box">
            <i className="fas fa-map-marker-alt" title="住所" />
            <div>
              <div className="post_no">
                〒{joinStr(maintenance.post_no, 3, '-') || '---'}
                <IconButton
                  title="ルートを表示する"
                  fontAwesomeClass="fas fa-route"
                  className="secondary"
                  disabled={!routeAuth}
                  onClick={handleClickHere}
                />
              </div>
              <div className="address">
                {maintenance.field_prefecture_name || ''}
                {maintenance.field_city || ''}
                {maintenance.field_address || ''}<br />
                {maintenance.field_building_name || ''}
              </div>
            </div>
          </div>
          <div className="tell"><i className="fas fa-phone" title="電話番号" />{maintenance.tel_no || '---'}</div>
          <div className="sales_contact"><i className="fas fa-user-circle" title="案件担当者" />{maintenance.project_employee_name || '---'}</div>
        </div>
        <div className="label_box">
          <ShowTypeLabel
            label={maintenance.supported_kubun === false ? '未対応' : '対応済'}
            showTypeImg={
              maintenance.supported_kubun
                === false ? maintenance_started : maintenance_completed
            }
            className={
              maintenance.supported_kubun === false ? 'maintenance_started' : 'maintenance_completed'
            }
          />
          {/* <RectLabel label={status} bgColor={pRankColor} />*/}

          <RectLabel
            label={maintenance.rank_name || 'ランクなし'}/* ★案件見込みランク略称abbreviation */
            // eslint-disable-next-line no-constant-condition
            bgColor={maintenance.rank_name ? `${maintenance.background_color}` : 'gray'}
            // eslint-disable-next-line no-constant-condition
            color={maintenance.rank_name ? `${maintenance.text_color}` : '#FFF'}
          />
          <table className={`maintenance_date${validDate}`}>
            <tr>
              <th>ﾒﾝﾃﾅﾝｽ日</th>
            </tr>
            <tr>
              <td>{DF.date2str(maintenance.maintenance_date) || ''}</td>
            </tr>
          </table>
          <div className={`google ${index}`}>
            {/* FIXME 仮 */}
            {/* <StreetViewImg isShow={index < 3} lat={maintenance.lat} lng={maintenance.lng} />*/}
          </div>
        </div>
      </div>
      <div className="btn_box">
        {!callbackRegist
          && (
            <>
              <Button className="sm primary" onClick={handleClickDetail}>メンテナンス詳細</Button>
            </>
          )}
      </div>
      <IconButton
        title="閉じる"
        fontAwesomeClass="fas fa-times"
        className="default close"
        onClick={callbackClose}
      />
    </div>
  );
};
