import { useCallback, useRef, useState } from 'react';
import { cloneDeep } from 'lodash';
import { ValidationPostNum1 } from '../../../model/validation';
import { Input } from './input';
import { LabelInput } from './label-input';
import { ValidationPostNum2 } from '../../../model/validation/validation-post-num2';
import { InputPostNo } from './input-post-no';

type Props = {
  value: string | undefined;
  value2: string | undefined;
  touch?: boolean;
  touch2?: boolean;
  require?: boolean;
  require2?: boolean;
  onChange: (v: string) => void;
  onChange2: (v: string) => void;
  disabled?: boolean;
  disabled2?: boolean;
  isSp?: boolean;
}

export const InputPostNoMulti = (props: Props) => {
  const {
    value,
    value2,
    touch,
    touch2,
    require,
    require2,
    onChange,
    onChange2,
    disabled,
    disabled2,
    isSp,

  } = props;
  const [isFocus, setFocus] = useState(false);
  const [Comp1, setComp1] = useState(false);
  const [Comp2, setComp2] = useState(false);
  const [touch1_2, setTouch1] = useState(false);
  const [touch2_2, setTouch2] = useState(false);

  const changeComp = useCallback((v: boolean, mode: '1' | '2') => {
    if (mode === '1') {
      setComp1(v);
      setTouch1(v);
    } else {
      setComp2(v);
      setTouch2(v);
    }
  }, []);

  return !isSp ? (
    <>
      <LabelInput
        pos="Left"
        label="〒"
      >
        <Input
          className="postal_code_1"
          inputMode="numeric"
          type="number"
          value={value}
          onCompositionStart={() => changeComp(true, '1')}
          onCompositionEnd={() => changeComp(false, '1')}
          onClick={() => changeComp(false, '1')}
          onBlur={() => changeComp(false, '1')}
          onEnterKeyPress={() => changeComp(false, '1')}
          anotherMessage={['半角数字で入力してください']}
          anotherTouch={touch1_2}
          onKeyDown={(e) => {
            if (Comp1 && e.key !== 'Enter') {
              setTouch1(true);
            }
            if (e.key === 'Enter') {
              setComp1(false);
              setTouch1(false);
            }
          }}
          maxLength={3}
          touch={touch}
          require={require}
          onChange={(e) => {
            if (Comp1) return;
            onChange(e.target.value);
            if (e.target.value.length === 3) setFocus(true);
          }}
          validationList={ValidationPostNum1}
          disabled={disabled}
        />
      </LabelInput>
      <LabelInput
        pos="Left"
        label="-"
      >
        <Input
          className="postal_code_2"
          type="number"
          onKeyDown={(e) => {
            if (Comp2 && e.key !== 'Enter') {
              setTouch2(true);
            }
            if (e.key === 'Enter') {
              setComp2(false);
              setTouch2(false);
            }
          }}
          inputMode="numeric"
          value={value2}
          maxLength={4}
          touch={touch2}
          require={require2}
          onCompositionStart={() => changeComp(true, '2')}
          onCompositionEnd={() => changeComp(false, '2')}
          onClick={() => changeComp(false, '2')}
          anotherMessage={['半角数字で入力してください']}
          anotherTouch={touch2_2}
          onBlur={() => {
            changeComp(false, '2');
            setFocus(false);
          }}
          onEnterKeyPress={() => {
            changeComp(false, '2');
          }}
          onFocus={() => setFocus(false)}
          onChange={(e) => {
            if (Comp2) return;
            onChange2(e.target.value);
          }}
          validationList={ValidationPostNum2 || '半角数字で入力してください'}
          disabled={disabled2}
          isFocus={isFocus}
        />
      </LabelInput>
    </>

  ) : (
    <>
      <div>〒&nbsp;
        <InputPostNo
          value={value}
          len={3}
          touch={touch}
          onKeyDown={(e) => {
            if (Comp1 && e.key !== 'Enter') {
              setTouch1(true);
            }
            if (e.key === 'Enter') {
              setComp1(false);
              setTouch1(false);
            }
          }}
          onCompositionStart={() => changeComp(true, '1')}
          onCompositionEnd={() => changeComp(false, '1')}
          onClick={() => changeComp(false, '1')}
          onBlur={() => changeComp(false, '1')}
          onEnterKeyPress={() => changeComp(false, '1')}
          anotherTouch={touch1_2}
          require={require}
          onChange={(v) => {
            onChange(v);
            if (v.length === 3) setFocus(true);
          }}
          disabled={disabled}
        />
      </div>
      <div className="hyphen ml_0">-</div>
      <div>
        <InputPostNo
          value={value2}
          len={4}
          touch={touch}
          anotherTouch={touch2_2}
          require={require2}
          onCompositionStart={() => changeComp(true, '2')}
          onCompositionEnd={() => changeComp(false, '2')}
          onClick={() => changeComp(false, '2')}
          onBlur={() => {
            changeComp(false, '2');
            setFocus(false);
          }}
          onEnterKeyPress={() => {
            changeComp(false, '2');
          }}
          onChange={(v) => {
            if (Comp2) return;
            onChange2(v);
          }}
          disabled={disabled2}
          isFocus={isFocus}
        />
      </div>
    </>
  );
};
