import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';
import { DatePicker } from '../../../../ui/date-picker/date-picker';
import { RightLabelCheckbox } from '../../../../ui/checkbox/right-label-checkbox/right-label-checkbox';
import { LeftIconButton } from '../../../../ui/button/left-icon-button/left-icon-button';
import '../search-box.pc.scss';
import { Select } from '../../../../ui/select/select';
import { prefectures } from '../../../../../collection/prefectures';
import { TagModel } from '../../../../../model/tag/tag';
import { TagActions } from '../../../../../redux/tag/tag.action';
import { ProjectSortState } from '../../../../../type/project/project.type';
import { ProjectCollection } from '../../../../../collection/project/project.collection';
import { SearchBoxPC } from '../search-box.pc';
import { ProjectActions } from '../../../../../redux/project/project.action';
import { useDidMount } from '../../../../../hooks/life-cycle';
import { MasterActions } from '../../../../../redux/master/master.action';
import { CommonCollection } from '../../../../../collection/common/common.collection';
import { Input } from '../../../../ui/input/input';
import { MapActions } from '../../../../../redux/map/map.action';
import { pulldown } from '../../../../../utilities/pulldown';
import { useAppSelector } from '../../../../../hooks/use-redux';
import { InputTel } from '../../../../ui/input/input-tel';
import { LabelInput } from '../../../../ui/input/label-input';
import { InputNum } from '../../../../ui/input/input-num';
import { Customer } from '../../../../../type/customer/customer.type';
import { OrderCollection } from '../../../../../collection/order/order.collection';
import { UserAgent } from '../../../../../utilities/user-agent';
import { useDateValidation } from '../../../../../hooks/use-date-validation';
import { DateTermValidation } from '../../../../../model/validation/date/term-date.validation';
import { useEstimateAuthority } from '../../../../../hooks/use-authority';

type Props = {
  /** 権限がない時 */
  noAuthority?: boolean;
  initialSort?: ProjectSortState;
  customerData?: Customer;
  callback: (v:ProjectSortState) => void;
  openCallback: (v: boolean) => void;
  isSeach?: boolean;
  type?: 'estimate' | 'order' | 'maintenance' | 'support' | 'file';
  constructionStatus?: TagModel | null;
}

export const ProjectSearchBox = (props: Props) => {
  const {
    type, initialSort, callback, openCallback,
    noAuthority, customerData, isSeach, constructionStatus,
  } = props;

  /* Hooks */
  const user = useAppSelector((v) => v.user);
  const sortState = useAppSelector((v) => v.project.sort);
  const partList = useAppSelector((v) => v.tag.partList);
  const {
    projectRankList, storeList, employeeList, originList,
  } = useAppSelector((v) => v.master);
  const estimateAuth = useEstimateAuthority();

  const dispatch = useDispatch();

  /* State */
  const [sort, setSort] = useState<ProjectSortState>(cloneDeep(initialSort || sortState));

  const _employeeList = useMemo(() => employeeList.filter((
    v,
  ) => (Number.isNaN(sort.project_store_id) || sort.project_store_id === null
    ? true
    : (v.store_id === sort.project_store_id))),
  [sort.project_store_id, employeeList]);

  const termDate = useDateValidation([
    { start: sort.contract_date_from, end: sort.contract_date_to },
    { start: sort.completion_date_from, end: sort.completion_date_to },
    { start: sort.complete_date_from, end: sort.complete_date_to },
    { start: sort.failure_date_from, end: sort.failure_date_to },
    { start: sort.cancel_date_from, end: sort.cancel_date_to },
    { start: sort.created_at_start, end: sort.created_at_end },
    { start: sort.updated_at_start, end: sort.updated_at_end },
  ]);

  /* Callback */
  const setState = useCallback((v: Partial<ProjectSortState>) => {
    setSort({ ...cloneDeep(sort), ...cloneDeep(v) });
  }, [sort, callback]);

  const handleClickSearch = useCallback(() => {
    if (termDate.messages.length) {
      termDate.check();
      return;
    }
    dispatch(MapActions.setGpsStatus('out'));
    callback(cloneDeep(sort));
    if (!isSeach) {
      dispatch(ProjectActions.setSort({
        ...cloneDeep(sort),
        limit: sortState.limit,
      }));
    }
  }, [callback, sortState, isSeach, sort, termDate]);

  /* Effect */
  useDidMount(() => {
    dispatch(TagActions.api.part.getList());
    dispatch(MasterActions.api.projectRank.getList({}));
    dispatch(MasterActions.api.employee.getList({}));
    dispatch(MasterActions.api.store.getList({}));
    dispatch(MasterActions.api.origin.getList({}));
  });

  // useEffect(() => {
  //   setState({
  //     construction_parts: sortState?.construction_parts?.data.length
  //       ? sortState?.construction_parts : new TagModel(partList),
  //   });
  // }, [partList, type]);

  useEffect(() => {
    if (constructionStatus) {
      setState({
        construction_status: cloneDeep(constructionStatus),
      });
    }
  }, [constructionStatus]);

  useEffect(() => {
    let status: number[] = [];
    if (type === 'estimate' || type === 'order') status = [10, 3];
    if (type === 'maintenance') status = [3, 4, 6];
    setState({
      construction_status:
        sortState.construction_status
        || new TagModel(
          type
            ? ProjectCollection.projectStatusList
            : ProjectCollection.constructionStatusList,
          type
            ? status
            : [],
        ),
      construction_parts: sortState?.construction_parts?.data.length
        ? sortState?.construction_parts : new TagModel(partList),
    });
  }, [partList, type]);

  return (
    <SearchBoxPC openCallback={openCallback} isDetail>
      <div onKeyPress={(e) => { if (e.key === 'Enter') handleClickSearch(); }}>
        <div className="item_wrap">
          <div className="item_box">
            <div className="item_head">営業担当</div>
            <Select
              className="add_text_left"
              label="店舗"
              value={noAuthority ? user.store_id : sort.project_store_id}
              onChange={(data) => setState({
                project_store_id: Number(data),
                project_employee_id: Number.isNaN(Number(data))
                || Number(data) === user.store_id
                  ? user.id
                  : NaN,
              })}
              defaultLabel="全て"
              disabled={noAuthority}
              options={pulldown(storeList)}
            />
            <Select
              className="add_text_left"
              label="担当者"
              value={noAuthority ? user.id : sort.project_employee_id}
              onChange={(data) => setState({ project_employee_id: Number(data) })}
              defaultLabel="全て"
              disabled={noAuthority}
              options={pulldown(_employeeList)}
            />
          </div>
          <div className="item_box">
            <div className="item_head">案件名</div>
            <Input
              value={sort.name}
              onChange={(e) => setState({ name: e.target.value })}
            />
          </div>
          <div className="item_box">
            <div className="item_head">現場名称</div>
            <Input
              value={sort.field_name}
              onChange={(e) => setState({ field_name: e.target.value })}
            />
          </div>
          <div className="item_box">
            <div className="item_head">現場電話番号</div>
            <InputTel
              value={sort.field_tel_no}
              onChange={(v) => setState({ field_tel_no: v })}
            />
          </div>
          <div className="item_box">
            <div className="item_head">顧客名</div>
            <Input
              value={customerData ? customerData.name : sort.customer_name}
              onChange={(e) => setState({ customer_name: e.target.value })}
              disabled={!!customerData}
            />
          </div>

          <div className="item_box">
            <div className="item_head">{type ? '案件状態' : '工事状況'}</div>
            <div className="flex_wrap_box">
              {sort.construction_status?.data
                .filter((v) => (estimateAuth ? true : v.id !== 2))
                .map((v, i) => (
                  <div key={`rTag${i}`}>
                    <RightLabelCheckbox
                      className=""
                      key={v.id}
                      label={v.label}
                      checked={v.flag}
                      disabled={type === 'estimate' || type === 'order'}
                      onChange={() => {
                        sort.construction_status?.changeFlag(v.id);
                        setState({ construction_status: sort.construction_status });
                      }}
                    />
                  </div>
                ))}
            </div>
          </div>
          <LeftIconButton
            label="検索"
            fontAwesomeClass="fas fa-search"
            className="btn_search for_simple"
            size="sm"
            color="secondary"
            onClick={handleClickSearch}
          />
        </div>
        <div className="search_detail">
          <div className="item_wrap">
            <div className="item_box">
              <div className="item_head">顧客都道府県</div>
              <Select
                value={sort.field_prefectures}
                onChange={(v) => setState({ field_prefectures: Number(v) })}
                defaultLabel="全て"
                options={prefectures.map((v) => ({ text: v.label, value: v.value }))}
              />
            </div>
            <div className="item_box">
              <div className="item_head">現場住所</div>
              <Input
                value={sort?.field_address}
                onChange={(e) => setState({ field_address: e.target.value })}
              />
            </div>
          </div>
          <div className="item_wrap">
            <div className="item_box">
              <div className="item_head">案件見込みランク</div>
              <Select
                value={sort.project_rank}
                onChange={(v) => setState({ project_rank: Number(v) })}
                defaultLabel="全て"
                options={projectRankList.map((v) => ({ text: v.name, value: v.id }))}
                className="size_adjustment"
              />
              <Select
                value={sort.project_rank_filter}
                onChange={(v) => setState({ project_rank_filter: Number(v) })}
                options={CommonCollection.pullDownFilterList}
              />
            </div>
            <div className="item_box">
              <div className="item_head">発生源</div>
              <Select
                defaultLabel="全て"
                value={sort?.source_id}
                onChange={(v) => { setState({ source_id: Number(v) }); }}
                options={pulldown(originList)}
                className="size_adjustment"
              />
            </div>
          </div>
          <div className="item_wrap flex_no_wrap_box">
            <div className="item_box max_width">
              <div className="item_head">工事部位</div>
              <div className="flex_wrap_box">
                {sort.construction_parts?.data.map((v, i) => (
                  <div key={`tag${i}`}>
                    <RightLabelCheckbox
                      className="customerPC__body__inner__checkbox"
                      key={v.id}
                      label={v.label}
                      checked={v.flag}
                      onChange={() => {
                        sort.construction_parts?.changeFlag(v.id);
                        setState({ construction_parts: sort.construction_parts });
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="item_wrap">
            <div className="item_box">
              <div className="item_head">見込み金額</div>
              <LabelInput pos="Right" label="円">
                <InputNum
                  value={sort.mitumori_kin_min}
                  onChange={(v) => { setState({ mitumori_kin_min: v }); }}
                  onBlur={(v) => setState({
                    mitumori_kin_min: v ? Number(v).toLocaleString() : '',
                  })}
                  minus
                />
              </LabelInput>
              <label className="">〜</label>
              <LabelInput pos="Right" label="円">
                <InputNum
                  value={sort.mitumori_kin_max}
                  onChange={(v) => { setState({ mitumori_kin_max: v }); }}
                  onBlur={(v) => setState({
                    mitumori_kin_max: v ? Number(v).toLocaleString() : '',
                  })}
                  minus
                />
              </LabelInput>
            </div>
            <div className="item_box">
              <div className="item_head">受注金額</div>
              <LabelInput pos="Right" label="円">
                <InputNum
                  value={sort.order_price_min}
                  onChange={(v) => { setState({ order_price_min: v }); }}
                  onBlur={(v) => setState({
                    order_price_min: v ? Number(v).toLocaleString() : '',
                  })}
                  minus
                />
              </LabelInput>
              <label className="">〜</label>
              <LabelInput pos="Right" label="円">
                <InputNum
                  value={sort.order_price_max}
                  onChange={(v) => { setState({ order_price_max: v }); }}
                  onBlur={(v) => setState({
                    order_price_max: v ? Number(v).toLocaleString() : '',
                  })}
                  minus
                />
              </LabelInput>
            </div>
          </div>
          <div className="item_wrap">
            <div className="item_box">
              <div className="item_head">契約日</div>
              <DatePicker
                date={sort.contract_date_from}
                onChange={(v) => setState({ contract_date_from: v })}
                term={DateTermValidation([
                  { start: sort.contract_date_from, end: sort.contract_date_to },
                ])}
              />
              <label className="ml_10">〜</label>
              <DatePicker
                date={sort.contract_date_to}
                onChange={(v) => setState({ contract_date_to: v })}
                term={DateTermValidation([
                  { start: sort.contract_date_from, end: sort.contract_date_to },
                ])}
              />
            </div>
            <div className="item_box">
              <div className="item_head">完工日</div>
              <DatePicker
                date={sort.completion_date_from}
                onChange={(v) => setState({ completion_date_from: v })}
                term={DateTermValidation([
                  { start: sort.completion_date_from, end: sort.completion_date_to },
                ])}
              />
              <label className="ml_10">〜</label>
              <DatePicker
                date={sort.completion_date_to}
                onChange={(v) => setState({ completion_date_to: v })}
                term={DateTermValidation([
                  { start: sort.completion_date_from, end: sort.completion_date_to },
                ])}
              />
            </div>
            <div className="item_box">
              <div className="item_head">完了日</div>
              <DatePicker
                date={sort.complete_date_from}
                onChange={(v) => setState({ complete_date_from: v })}
                term={DateTermValidation([
                  { start: sort.complete_date_from, end: sort.complete_date_to },
                ])}
              />
              <label className="ml_10">〜</label>
              <DatePicker
                date={sort.complete_date_to}
                onChange={(v) => setState({ complete_date_to: v })}
                term={DateTermValidation([
                  { start: sort.complete_date_from, end: sort.complete_date_to },
                ])}
              />
            </div>
            <div className="item_box">
              <div className="item_head">失注日</div>
              <DatePicker
                date={sort.failure_date_from}
                onChange={(v) => setState({ failure_date_from: v })}
                term={DateTermValidation([
                  { start: sort.failure_date_from, end: sort.failure_date_to },
                ])}
              />
              <label className="ml_10">〜</label>
              <DatePicker
                date={sort.failure_date_to}
                onChange={(v) => setState({ failure_date_to: v })}
                term={DateTermValidation([
                  { start: sort.failure_date_from, end: sort.failure_date_to },
                ])}
              />
            </div>
            <div className="item_box">
              <div className="item_head">キャンセル日</div>
              <DatePicker
                date={sort.cancel_date_from}
                onChange={(v) => setState({ cancel_date_from: v })}
                term={DateTermValidation([
                  { start: sort.cancel_date_from, end: sort.cancel_date_to },
                ])}
              />
              <label className="ml_10">〜</label>
              <DatePicker
                date={sort.cancel_date_to}
                onChange={(v) => setState({ cancel_date_to: v })}
                term={DateTermValidation([
                  { start: sort.cancel_date_from, end: sort.cancel_date_to },
                ])}
              />
            </div>
          </div>
          <div className="item_wrap">
            <div className="item_box">
              <div className="item_head">登録者</div>
              <Select
                defaultLabel="全て"
                className="fixed"
                value={sort.created_employee_id}
                onChange={(v) => setState({ created_employee_id: Number(v) })}
                options={pulldown(employeeList)}
              />
            </div>
            <div className="item_box">
              <div className="item_head">登録日</div>
              <DatePicker
                date={sort.created_at_start}
                onChange={(v) => setState({ created_at_start: v })}
                type="YYYY/MM/DD"
                term={DateTermValidation([
                  { start: sort.created_at_start, end: sort.created_at_end },
                ])}
              />
              <label className="ml_10">～</label>
              <DatePicker
                date={sort.created_at_end}
                onChange={(v) => setState({ created_at_end: v })}
                type="YYYY/MM/DD"
                term={DateTermValidation([
                  { start: sort.created_at_start, end: sort.created_at_end },
                ])}
              />
            </div>
            <div className="item_box">
              <div className="item_head">更新者</div>
              <Select
                defaultLabel="全て"
                className="fixed"
                value={sort.updated_employee_id}
                onChange={(v) => setState({ updated_employee_id: Number(v) })}
                options={pulldown(employeeList)}
              />
            </div>
            <div className="item_box">
              <div className="item_head">更新日</div>
              <DatePicker
                date={sort.updated_at_start}
                onChange={(v) => setState({ updated_at_start: v })}
                type="YYYY/MM/DD"
                term={DateTermValidation([
                  { start: sort.updated_at_start, end: sort.updated_at_end },
                ])}
              />
              <label className="ml_10">～</label>
              <DatePicker
                date={sort.updated_at_end}
                onChange={(v) => setState({ updated_at_end: v })}
                type="YYYY/MM/DD"
                term={DateTermValidation([
                  { start: sort.updated_at_start, end: sort.updated_at_end },
                ])}
              />
            </div>
            <LeftIconButton
              label="検索"
              fontAwesomeClass="fas fa-search"
              className="btn_search for_detail"
              size="sm"
              color="secondary"
              onClick={handleClickSearch}
            />
          </div>
        </div>
      </div>
    </SearchBoxPC>
  );
};
