import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import cloneDeep from 'lodash/cloneDeep';
import { DisplayElements } from '../../../../../../type/display-elements.type';
import { Table } from '../../../../../ui/table/table';
import { CustomerCollection } from '../../../../../../collection/customer/customer.collection';
import './customer-list.pc.scss';
import { State } from '../../../../../../redux/root.reducer';
import { CustomerListType } from '../../../../../../type/customer/customer.type';
import { RoutingPath } from '../../../../../../routes/routing-pass';
import { joinStr } from '../../../../../../utilities/join-str';
import { useWillUnMount, useDidMount } from '../../../../../../hooks/life-cycle';
import { CustomerActions } from '../../../../../../redux/customer/customer.action';
import Alert from '../../../../../../asset/images/icon/alert.svg';
import { useAppSelector } from '../../../../../../hooks/use-redux';
import { MasterActions } from '../../../../../../redux/master/master.action';
import { Tikunengetsu } from '../../../../../ui/tikunengetsu/tikunengetsu';
import { useOpenWindow } from '../../../../../../hooks/use-open-window';
import { Config } from '../../../../../../config/config';

type Props = {
  type?: 0 | 1 | 2;
  data?: CustomerListType[];
  isSearch?: boolean;
  handleCardClick?: (customer: CustomerListType,
    e?: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
    ) => void;
  callbackSort?: (order: number, index: number) => void;
  callbackHitCount?: (v: number | null) => void;
  callbackSelect?: (v: number[]) => void;
  callbackList?: (v?: CustomerListType[] | null) => void;
  parentSelect?: number[];
}

export const CustomerListPC = (props: Props) => {
  const {
    type, data, handleCardClick, callbackSort, callbackList,
    isSearch, callbackHitCount, callbackSelect, parentSelect,
  } = props;

  /* Hooks */
  const customerKojiRankList = useAppSelector((v) => v.master.customerKojiRankList);
  const customerRankList = useAppSelector((v) => v.master.customerRankList);
  const customerEstimatedRankList = useAppSelector((v) => v.master.customerEstimatedRankList);
  const customerListData = useSelector((state: State) => state.customer.list);
  const dispatch = useDispatch();
  const openWindow = useOpenWindow();

  /* State */
  const [customerHeader] = useState<DisplayElements[]>(CustomerCollection.customerHeader);
  const [selected, setSelected] = useState<number[]>(parentSelect || []);

  /* List */
  const customerList = useMemo(() => (
    !type ? customerListData : customerListData?.filter(
      (v) => v.ob_flag === type,
    )), [type, customerListData]);

  const dataList = useMemo(() => {
    callbackList?.(data || customerList);
    return data || customerList;
  }, [customerList, data]);

  const handleClickCard = useCallback((
    id: number,
  ) => {
    if (!dataList) return;
    if (handleCardClick) {
      const findData = dataList?.find((v) => v.id === id);
      if (findData) {
        handleCardClick(findData);
      }
    }
    setSelected([dataList.findIndex((v) => v.id === id)]);
    callbackSelect?.([dataList.findIndex((v) => v.id === id)]);
  }, [handleCardClick, dataList]);

  const handleDbClick = useCallback((v: CustomerListType, i,
    e?: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
    if (!e) return;
    if (handleCardClick) {
      handleCardClick(cloneDeep(v), e);
      return;
    }
    openWindow({
      url: `${RoutingPath.customerDetail}/${v.id}`,
      e,
    });
  },
  [handleCardClick]);

  useDidMount(() => {
    dispatch(MasterActions.api.customerKojiRank.getList({}));
    dispatch(MasterActions.api.customerExpectedRank.getList({}));
    dispatch(MasterActions.api.customerRank.getList({}));
  });

  useEffect(() => {
    callbackHitCount?.(dataList ? dataList.length : 0);
  }, [dataList, callbackHitCount]);

  useEffect(() => {
    if (!parentSelect) return;
    if (!parentSelect.length) setSelected([]);
  }, [parentSelect]);

  return (
    <section className="result_area list_area">
      <div className="inner">
        <div className="table_responsive">
          {/* <Table /> */}
          <Table
            className="table_selectable table_sortable table_sticky table_cell_change"
            header={customerHeader}
            onDbClick={(v, i, e) => handleDbClick(v, i, e)}
            selectedTr={selected}
            rowDataList={dataList || []}
            onClickRow={(v:CustomerListType, i, j) => { handleClickCard(Number(v.id)); }}
            onClickMulti={isSearch ? undefined : (v: CustomerListType[]) => {
              if (!dataList) return;
              setSelected(v.map((v2) => dataList.findIndex((v3) => v3.id === v2.id)));
              callbackSelect?.(v.map((v2) => dataList.findIndex((v3) => v3.id === v2.id)));
            }}
            onClickAlt={(v, e) => {
              if (e && v) {
                openWindow({
                  url: `${RoutingPath.customerDetail}/${v.id}`,
                  e,
                  callback: () => {},
                });
              }
            }}
            sort={{
              index: [],
              onClick: (order, index) => {
                if (callbackSort) callbackSort(order, index);
                setSelected([]);
                callbackSelect?.([]);
              },
            }}
            lists={dataList ? dataList.map((v) => (
              [
                v.ob_flag === 1 ? 'OB' : '見込',
                v.employee_name,
                v.internal_id,
                v.name,
                v.furigana,
                joinStr(v.post_no, 3, '-'),
                v.prefecture_name,
                v.city + v.address,
                v.tel_no,
                (() => {
                  const rank = customerEstimatedRankList.find((
                    v2,
                  ) => Number(v2.id) === Number(v.estimated_rank));
                  return <div className="rank_label" style={{ backgroundColor: rank?.background_color, color: rank?.text_color }}>{rank?.abbreviation}</div>;
                })(),
                (() => {
                  const rank = customerRankList.find((
                    v2,
                  ) => String(v2.id) === String(v.rank));
                  return (
                    <div
                      className="rank_label"
                      style={{ backgroundColor: rank?.background_color, color: rank?.text_color }}
                    >{rank ? `${rank?.abbreviation}${rank?.abbreviation2}` : ''}
                    </div>
                  );
                })(),
                v.area_name,
                v.building_category_name,
                v.madori_name,
                <Tikunengetsu
                  date={
                    v.construction_info
                      ? new Date(v.construction_info)
                      : null
                  }
                  isList
                />,
                v.remarks,
              ]
            )) : null}
            option={{
              stringWidth: [
                // { index: 0, width: 80 }, // 入力不備（表示しない）
                // { index: 0, width: 100 }, // OBフラグ
                // { index: 1, width: 50 }, // 顧客営業担当
                // { index: 2, width: 50 }, //  顧客ID
                // { index: 3, width: 50 }, // 顧客名
                // { index: 4, width: 100 }, // フリガナ
                // { index: 5, width: 50 }, // 郵便番号
                // { index: 6, width: 50 }, // 都道府県
                // { index: 7, width: 50 }, // 住所
                // { index: 8, width: 50 }, // TEL
                // { index: 9, width: 50 }, // 見込みランク
                // { index: 10, width: 50 }, // 顧客ランク
                // { index: 11, width: 50 }, // エリア
                // { index: 12, width: 50 }, // 建物分類
                // { index: 13, width: 50 }, // 間取り
                // { index: 14, width: 50 }, // 築年数
                // { index: 15, width: 50 }, // 備考
              ],
              tdAlign: [
                // { index: 0, align: 'center' },
                { index: 0, align: 'center' },
                { index: 1, align: 'left' },
                { index: 2, align: 'left' },
                { index: 3, align: 'left' },
                { index: 4, align: 'left' },
                { index: 5, align: 'left' },
                { index: 6, align: 'left' },
                { index: 7, align: 'left' },
                { index: 8, align: 'left' },
                { index: 9, align: 'center' },
                { index: 10, align: 'center' },
                { index: 11, align: 'left' },
                { index: 12, align: 'center' },
                { index: 13, align: 'center' },
                { index: 14, align: 'right' },
                { index: 15, align: 'left' },
              ],
            }}
          />
        </div>
      </div>
    </section>
  );
};
