/**
 * 数値文字列のカンマ編集
 *
 * @param val :数値文字列（カンマ編集なし）
 * @return カンマ編集あり文字列
 *
 **/
export const setFigureSeparate = (val: String) => {
  if (!val) return val;
  let ret = val.toLocaleString();
  if (ret.substr(0, 1) === '.') ret = `0${ret}`;
  return ret;
};

/**
 * 数値文字列のカンマ編集を解除する
 *
 * @param val :数値文字列（カンマ編集あり）
 * @return カンマ編集なし文字列
 *
 **/
export const cancelFigureSeparate = (val: String) => {
  const ptr: RegExp = /,/g;
  return val.replace(ptr, '');
};
