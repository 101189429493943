import React, {
  memo, useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import {
  Input as SInput,
  InputOnChangeData,
  InputProps as SInputProps,
} from 'semantic-ui-react';
import cloneDeep from 'lodash/cloneDeep';
import { Validation } from '../../../model/validation/validation';
import { ValidationNotEmpty } from '../../../model/validation/validation-not-empty';
import { ErrorPop } from '../error-pop/error-pop';
import './input.scss';
import { UserAgent } from '../../../utilities/user-agent';
import { MathHelper } from '../../../utilities/math-helper';
import { deleteComma } from '../../../utilities/delete-comma';
import { useDidMount } from '../../../hooks/life-cycle';

export type InputProps = {
  validationList?: Validation[];
  alignRight?: boolean;
  onKeyPress?: (e: React.KeyboardEvent<HTMLDivElement>) => void;
  onEnterKeyPress?: () => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLDivElement>) => void;
  callBackEnterKeyPress?: (e: React.KeyboardEvent<HTMLDivElement>) => void;
  /**  触る前からエラー出すかどうか */
  touch?: boolean;
  require?: boolean;
  id?: string;
  name?: string;
  onBlur?: () => void;
  /** 任意文字列の場合string Valueの場合true */
  title?: string | boolean;
  noRequireLabel?: boolean;
  /** 半角英数字のみ対応 */
  maxLength?:number;
  onChangedBlur?: () => void;
  errorPosBottom?: boolean;
  inputMode?: string;
  onBlurCapture?: () => void,
  setIsFocus?: (isFocus: boolean) => void;
  onClick?: () => void
  onFocus?: () => void
  firstFocus?: boolean
  isLogin?: boolean;
  isFocus?: boolean;
  anotherTouch?: boolean;
  anotherMessage?: string[];
  errorMessage?: string[];
  isEnterBlur?: boolean;
} & SInputProps

const InputComponent = (props: InputProps) => {
  const {
    value = '',
    validationList,
    disabled,
    onEnterKeyPress,
    callBackEnterKeyPress,
    onKeyPress,
    onKeyDown,
    onChange,
    placeholder,
    className,
    touch,
    alignRight,
    type,
    require,
    error,
    name,
    id,
    title,
    onBlur,
    maxLength,
    onChangedBlur,
    onBlurCapture,
    errorPosBottom,
    inputMode,
    setIsFocus,
    onClick,
    onFocus,
    firstFocus,
    isLogin,
    isFocus,
    onCompositionStart,
    onCompositionEnd,
    anotherTouch,
    anotherMessage,
    errorMessage,
    isEnterBlur,
  } = props;

  const ref = useRef<any>(null);

  /* State */
  const [errorList, setErrorList] = useState<string[]>([]);
  const [changed, setChanged] = useState<boolean>(false);
  const [focus, setFocus] = useState<boolean>(false);

  /* Memo */
  const isError = useMemo(() => (
    Boolean((touch || changed || anotherTouch) && errorList.length)
  ), [touch, changed, errorList, anotherTouch, errorMessage]);

  const tip = useMemo(() => {
    if (typeof title === 'string') {
      return title;
    }
    return title ? value : undefined;
  }, [title, value]);

  const now = useMemo(() => new Date().getTime(), []);

  /* Callback */
  const handleOnChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
      if (disabled) return;
      setChanged(true);

      if (onChange) {
        if (maxLength) {
          if (event.target.value.length > maxLength) {
            return;
          }
        }
        onChange(event, data);
      }
    },
    [changed, onChange, value, maxLength, disabled],
  );

  const handleOnBlur = useCallback(() => {
    if (disabled) return;
    setChanged(true);
    setFocus(false);
    if (!validationList) {
      setErrorList(
        require ? ValidationNotEmpty
          .filter((v) => !v.run(value ? String(value) : ''))
          .map((v) => v.errorMessage)
          : [],
      );
    } else {
      setErrorList(
        require ? [...ValidationNotEmpty, ...validationList]
          .filter((v) => !v.run(value ? String(value) : ''))
          .map((v) => v.errorMessage)
          : validationList
            .filter((v) => !v.run(value ? String(value) : ''))
            .map((v) => v.errorMessage),
      );
    }
    if (onBlur) onBlur();
    if (touch && onChangedBlur) onChangedBlur();
  },
  [value, onBlur, disabled]);

  const handleOnKeyDown = useCallback((e:React.KeyboardEvent<HTMLDivElement>) => {
    if (disabled) return;
    onKeyDown?.(e);
    if (type !== 'number') return;
    if (e.key === 'e' /* || e.key === '.'  */|| e.key === '+' || e.key === '-') e.preventDefault();
  }, [type, disabled]);

  useEffect(() => {
    if (anotherTouch) {
      if (anotherMessage) {
        setErrorList(cloneDeep(anotherMessage));
      }
    } else if (touch) {
      if (!validationList) {
        setErrorList(
          require ? ValidationNotEmpty
            .filter((v) => !v.run(value ? String(value) : ''))
            .map((v) => v.errorMessage)
            : [],
        );
      } else {
        setErrorList(
          require ? [...ValidationNotEmpty, ...validationList]
            .filter((v) => !v.run(value ? String(value) : ''))
            .map((v) => v.errorMessage)
            : validationList
              .filter((v) => !v || !v.run(value ? String(value) : ''))
              .map((v) => v.errorMessage),
        );
      }
    } else if (!anotherTouch) {
      setErrorList([]);
    }
  }, [touch, anotherTouch]);

  useEffect(() => {
    if (setIsFocus) {
      setIsFocus(focus);
    }
  }, [focus]);

  useEffect(() => {
    if (isFocus) {
      ref.current.focus();
    }
  }, [isFocus]);

  useDidMount(() => {
    if (firstFocus && ref.current) ref.current.focus();
  });

  return (
    <div
      className={`
      base_input ${className || ''}
      ${UserAgent === 'pc'
        ? ` error_focus${anotherTouch ? ' always' : ''}`
        : ''}
      `}
      onKeyPress={(e) => {
        onKeyPress?.(e);
        if (e.key === 'Enter' && onEnterKeyPress) { onEnterKeyPress(); }
        if (e.key === 'Enter' && callBackEnterKeyPress) { callBackEnterKeyPress(e); }
        if (e.key === 'Enter' && isEnterBlur) ref?.current?.inputRef?.current?.blur();
      }}
      onBlur={handleOnBlur}
      onBlurCapture={(e) => onBlurCapture?.()}
      title={tip}
      onFocus={() => setFocus(true)}
      onClick={(e) => {
        if (onClick)onClick();
        e.stopPropagation();
      }}
      onDoubleClick={(e) => e.stopPropagation()}
      onKeyDown={handleOnKeyDown}
    >
      <SInput
        ref={ref}
        onCompositionStart={() => {
          onCompositionStart?.();
        }}
        onCompositionEnd={() => {
          onCompositionEnd?.();
        }}
        onKeyDown={onkeydown}
        className={`${alignRight ? 'align_r' : ''}`}
        error={isError || error || !!errorMessage?.length}
        value={value || value === 0 ? String(value) : ''}
        disabled={disabled}
        inputMode={inputMode}
        onChange={(e, data) => handleOnChange(e, data)}
        placeholder={placeholder}
        onFocus={() => onFocus?.()}
        type={type}
        name={name || now}
        id={id || now}
        // inputMode="decimal"
        input={{
          // autoComplete: name || now,
          autoComplete: isLogin ? name || now : 'new-password',
          // inputMode: inputMode || (type === 'number' &&
          // UserAgent === 'sp' ? 'numeric' : undefined),
          pattern: type === 'number' && UserAgent === 'sp' ? '\\d*' : undefined,
        }}
      />
      {/* Error Message */}
      {(isError || !!errorMessage?.length) && (UserAgent === 'sp' ? focus : true) && (
        <ErrorPop messages={errorMessage || errorList} errorPosBottom={errorPosBottom} />
      )}
      <input
        placeholder=""
        type="text"
        autoComplete="on"
        value=""
        style={{
          display: 'none', opacity: 0, position: 'absolute', left: '-100000px',
        }}
        readOnly
      />
    </div>
  );
};

export const Input = InputComponent;

type InputMoneyProps = {
  decimalPlace: number;
  callbackBlur: (v: string | number) => void;
} & InputProps

export const InputMoney = (props: InputMoneyProps) => {
  const {
    value: _value,
    decimalPlace,
    callbackBlur,
    validationList,
    disabled,
    require,
    touch,
    errorPosBottom,
  } = props;

  const [value, setValue] = useState(
    _value ? MathHelper.rounding(deleteComma(_value), decimalPlace, 'round') : 0,
  );
  const [showValue, setShowValue] = useState(
    MathHelper.rounding2Str(deleteComma(_value), decimalPlace, 'round', true),
  );

  const onFocusOut = useCallback(() => {
    if (!value) {
      callbackBlur(0);
      setValue(0);
      setShowValue('0');
    }
    setValue(MathHelper.rounding(deleteComma(value), decimalPlace, 'round'));
    setShowValue(MathHelper.rounding2Str(deleteComma(value), decimalPlace, 'round', true));
    callbackBlur(MathHelper.rounding(deleteComma(value), decimalPlace, 'round'));
  }, [value, decimalPlace]);

  const onChange = useCallback((e) => {
    const val: string = e.target.value;

    if (val === '') {
      setValue(Number(val));
      setShowValue(val);
      return;
    }

    setValue(Number(String(deleteComma(val)).replace(/[^0-9.]+/g, '')));
    setShowValue(String(deleteComma(val)).replace(/[^0-9.]+/g, ''));
  }, []);

  // const showValue = useMemo(() => {
  //   if (value === 0 || value === '') {
  //     return value;
  //   }
  //   return MathHelper.rounding2Str(value, decimalPlace, 'round', true);
  // }, [value, decimalPlace]);

  return (
    <Input
      value={showValue}
      disabled={disabled}
      onChange={(e) => onChange(e)}
      onBlur={onFocusOut}
      validationList={validationList}
      alignRight
      require={require}
      errorPosBottom={errorPosBottom}
      touch={touch}
    />
  );
};
