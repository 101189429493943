import { CsvCustomerCollection } from '../csv/customer/csv-customer-collection';
import { TagModel } from '../../model/tag/tag';
import {
  CsvBirthdaySort,
  CsvCustomerRankSort,
  CsvCustomerSort,
  CsvLostOrderSort,
  CsvMaintenanceSort,
  CsvNonOrderSort,
  CsvOrderSort,
  CsvProjectSort,
  CsvSupportHistorySort,
  CsvWeddingSort,
} from '../../type/csv/csv-sort.type';
import { ProjectCollection } from '../project/project.collection';

export class ExportCsvSortCollection {
  static customer = (): CsvCustomerSort => ({
    /** 営業担当（店舗） */
    store_id: NaN,
    /** 営業担当（担当者） */
    employee_id: NaN,
    /** 顧客名 */
    name: '',
    /** 顧客TEL */
    tel_no: '',
    /** エリア */
    area_id: NaN,
    /** 顧客ランク */
    rank: '',
    /** 顧客ランクフィルタ */
    rank_filter: NaN,
    /** 顧客見込みランク */
    estimated_rank: NaN,
    /** 顧客見込みランクフィルタ */
    estimated_rank_filter: NaN,
    /** 顧客分類 */
    ob_flag: 0,
    /** 工事状況 */
    construction_status: null,
    /** 顧客名フリガナ */
    furigana: '',
    /** メールアドレス */
    mail_address: '',
    /** 郵便番号 */
    post_no: '',
    /** 都道府県 */
    prefecture: NaN,
    /** 顧客住所 */
    address: '',
    /** 建物分類 */
    building_category_id: NaN,
    /** 間取り */
    madori_id: NaN,
    /** 築年数－範囲開始 */
    building_age_start: NaN,
    /** 築年数－範囲終了 */
    building_age_end: NaN,
    /** 築年月－範囲開始 */
    construction_info_start: '',
    /** 築年月－範囲終了 */
    construction_info_end: '',
    /** 完工時期－範囲開始 */
    completion_date_start: null,
    /** 完工時期－範囲終了 */
    completion_date_end: null,
    /** 最終完工時期－範囲開始 */
    last_completion_date_start: null,
    /** 最終完工時期－範囲終了 */
    last_completion_date_end: null,
    /** 総工事金額（最小値） */
    total_work_price_min: '',
    /** 総工事金額（最大値） */
    total_work_price_max: '',
    /** 工事回数（最小値） */
    work_times_min: '',
    /** 工事回数（最大値） */
    work_times_max: '',
    /** 関連タグ */
    tags: null,
    /** 部位 */
    parts: null,
    /** 不備情報のみ */
    input_comp_flag: new TagModel(CsvCustomerCollection.deficiencies),
    /** 備考 */
    remarks: '',
    /** 有効フラグ */
    valid_flag: 1,
    /** オフセット */
    offset: 0,
    /** 1ページ内の件数 */
    limit: 100,
    /** 並替基準列 */
    sort_by: 0,
    /** 並替方法 */
    highlow: 1,
    /** 登録店舗 */
    created_store_id: NaN,
    /** 登録社員 */
    created_employee_id: NaN,
    /** 更新店舗 */
    updated_store_id: NaN,
    /** 更新社員 */
    updated_employee_id: NaN,
    /** 登録日－期間開始 */
    created_at_start: null,
    /** 登録日－期間終了 */
    created_at_end: null,
    /** 更新日－期間開始 */
    updated_at_start: null,
    /** 更新日－期間終了 */
    updated_at_end: null,

  });

  static wedding = (): CsvWeddingSort => ({
    /** 営業担当（店舗） */
    store_id: NaN,
    /** 営業担当（担当者） */
    employee_id: NaN,
    /** 顧客名 */
    name: '',
    /** 結婚記念日（開始年） */
    wedding_anniversary_start_year: '',
    /** 結婚記念日（開始月） */
    wedding_anniversary_start_month: NaN,
    /** 結婚記念日（終了年） */
    wedding_anniversary_end_year: '',
    /** 結婚記念日（終了月） */
    wedding_anniversary_end_month: NaN,
    /** 有効フラグ */
    valid_flag: 1,
    /** オフセット */
    offset: 0,
    /** 1ページ内の件数 */
    limit: 100,
    /** 並替基準列
       * "0:顧客ID
    1:顧客名
    2:結婚記念日
    3:郵便番号
    4:都道府県
    5:住所
    6:顧客電話番号
    7:営業担当社員名" */
    sort_by: 0,
    /** 並替方法 */
    highlow: 1,
  });

  static birthday = (): CsvBirthdaySort => ({
    /** 営業担当（店舗） */
    store_id: NaN,
    /** 営業担当（担当者） */
    employee_id: NaN,
    /** 顧客登録名 */
    name: '',
    /** 家族お名前 */
    family_name: '',
    /** 続柄 */
    relationship: '',
    /** 携帯番号 */
    mobile_phone: '',
    /** 生年月（開始） */
    birth_month_start: '',
    /** 生年日（開始） */
    birth_day_start: '',
    /** 生年月（終了） */
    birth_month_end: '',
    /** 生年日（終了） */
    birth_day_end: '',
    /** 有効フラグ */
    valid_flag: 1,
    /** オフセット */
    offset: 0,
    /** 1ページ内の件数 */
    limit: 100,
    /** 並替基準列 "0:顧客ID
1:顧客名
2:家族お名前
3:家族生年月日
4:続柄
5:携帯番号
6:郵便番号
7:都道府県
8住所
9:顧客電話番号
10:営業担当者名" */
    sort_by: 0,
    /** 並替方法 */
    highlow: 1,
  });

  static project = (): CsvProjectSort => ({
    /* 営業担当（店舗） */
    project_store_id: NaN,
    /* 営業担当（担当者） */
    project_employee_id: NaN,
    /* 契約日（開始） */
    contract_start_date: null,
    /* 契約日（終了） */
    contract_end_date: null,
    /* 完工日（開始） */
    completion_start_date: null,
    /* 完工日（終了） */
    completion_end_date: null,
    /* 顧客名 */
    customer_name: '',
    /* 案件名 */
    name: '',
    /* 現場名称 */
    field_name: '',
    /* 現場電話番号 */
    field_tel_no: '',
    /* 工事状況 "配列形式
0:案件無
1:案件化
2:見積中
3:工事中
4:完工
5:未入金
6:完了
7:失注
8:ｷｬﾝｾﾙ" */
    construction_status: new TagModel(ProjectCollection.constructionStatusList),
    /* オフセット */
    offset: 0,
    /* 1ページ内の件数 */
    limit: 100,
    /* 並替基準列 "2:案件ID
6:案件名
23:顧客名
8:現場住所
7:現場電話番号
4:顧客ランク
16:担当者名
9:着工予定日
11:着工日
10:完工予定日
12:完工日
17:契約日
21:失注日
22:キャンセル日
24:見積金額" */
    sort_by: 0,
    /* 並替方法 */
    highlow: 1,
    /** 登録店舗 */
    created_store_id: NaN,
    /** 登録社員 */
    created_employee_id: NaN,
    /** 更新店舗 */
    updated_store_id: NaN,
    /** 更新社員 */
    updated_employee_id: NaN,
    /** 登録日－期間開始 */
    created_at_start: null,
    /** 登録日－期間終了 */
    created_at_end: null,
    /** 更新日－期間開始 */
    updated_at_start: null,
    /** 更新日－期間終了 */
    updated_at_end: null,
    /** 有効フラグ */
    valid_flag: 1,
  });

  static lostOrder = (): CsvLostOrderSort => ({
    /** 見込みランク */
    project_rank: NaN,
    /** 見込みランクフィルタ */
    project_rank_filter: 1,
    /** 最終見積金額（最小値） */
    estimate_kin_min: undefined,
    /** 最終見積金額（最大値） */
    estimate_kin_max: undefined,
    /** 粗利 */
    margin_ritu: undefined,
    /** 粗利フィルタ */
    margin_ritu_filter: 1,
    /** 部位 */
    construction_parts: null,
    /** 失注日（開始） */
    failure_date_from: null,
    /** 失注日（終了） */
    failure_date_to: null,
    /** 失注理由 */
    failure_cause: NaN,
    /** 失注フラグ */
    failure_flag: 1,
    /** 案件担当（店舗） */
    project_store_id: NaN,
    /** 案件担当（担当者） */
    project_employee_id: NaN,
    /** 顧客名 */
    customer_name: '',
    /** 案件名 */
    name: '',
    /** 現場名称 */
    field_name: '',
    /** 現場電話番号 */
    field_tel_no: '',
    /** オフセット */
    offset: 0,
    /** 1ページ内の件数 */
    limit: 100,
    /** 並替基準列 */
    sort_by: 0,
    /** 並替方法 */
    highlow: 1,
    /** 登録店舗 */
    created_store_id: NaN,
    /** 登録社員 */
    created_employee_id: NaN,
    /** 更新店舗 */
    updated_store_id: NaN,
    /** 更新社員 */
    updated_employee_id: NaN,
    /** 登録日－期間開始 */
    created_at_start: null,
    /** 登録日－期間終了 */
    created_at_end: null,
    /** 更新日－期間開始 */
    updated_at_start: null,
    /** 更新日－期間終了 */
    updated_at_end: null,
  });

  static nonOrder = (): CsvNonOrderSort => ({
    /** 最終見積作成日（開始） */
    estimate_dt_from: null,
    /** 最終見積作成日（終了） */
    estimate_dt_to: null,
    /** 最終見積金額（最小値） */
    estimate_kin_min: undefined,
    /** 最終見積金額（最大値） */
    estimate_kin_max: undefined,
    /** 粗利 */
    margin_ritu: undefined,
    /** 粗利フィルタ */
    margin_ritu_filter: 1,
    /** 部位 */
    construction_parts: null,
    /** 契約フラグ */
    contract_flag: 0,
    /** 案件担当（店舗） */
    project_store_id: NaN,
    /** 案件担当（担当者） */
    project_employee_id: NaN,
    /** 顧客名 */
    customer_name: '',
    /** 案件名 */
    name: '',
    /** 現場名称 */
    field_name: '',
    /** 現場電話番号 */
    field_tel_no: '',
    /** オフセット */
    offset: 0,
    /** 1ページ内の件数 */
    limit: 100,
    /** 並替基準列 */
    sort_by: 0,
    /** 並替方法 */
    highlow: 1,
  });

  static order = (): CsvOrderSort => ({
  /** 完工日（開始年） */
    completion_date_from_year: NaN,
    /** 完工日（開始月） */
    completion_date_from_month: NaN,
    /** 完工日（終了年） */
    completion_date_to_year: NaN,
    /** 完工日（終了月） */
    completion_date_to_month: NaN,
    /** 受注金額（最小値） */
    order_price_min: undefined,
    /** 受注金額（最大値） */
    order_price_max: undefined,
    /** 粗利 */
    margin_ritu: undefined,
    /** 粗利フィルタ */
    margin_ritu_filter: 1,
    /** 部位 */
    construction_parts: null,
    /** 入金 */
    payment: NaN,
    /** 契約フラグ */
    contract_flag: 1,
    /** 案件担当（店舗） */
    project_store_id: NaN,
    /** 案件担当（担当者） */
    project_employee_id: NaN,
    /** 顧客名 */
    customer_name: '',
    /** 案件名 */
    name: '',
    /** 現場名称 */
    field_name: '',
    /** 現場電話番号 */
    field_tel_no: '',
    /** オフセット */
    offset: 0,
    /** 1ページ内の件数 */
    limit: 100,
    /** 並替基準列 */
    sort_by: 0,
    /** 並替方法 */
    highlow: 1,
  });

  static maintenance = (): CsvMaintenanceSort => ({
    /** メンテナンス日_開始 */
    maintenance_date_start: null,
    /** メンテナンス日_終了 */
    maintenance_date_end: null,
    /** 案件営業担当店舗 */
    project_store_id: NaN,
    /** 案件営業担当担当者 */
    project_employee_id: NaN,
    /** 完工日_開始 */
    completion_date_start: null,
    /** 完工日_終了 */
    completion_date_end: null,
    /** 案件名 */
    project_name: '',
    /** 無効情報も含む */
    is_muko: 1,
    /** 対応区分 */
    supported_kubun: 2,
    /** 文字列検索 */
    word: '',
    /** オフセット */
    offset: 0,
    /** 1ページ内の件数 */
    limit: 100,
    /** 並替基準列 */
    sort_by: 0,
    /** 並替方法 */
    highlow: 1,
    support_flag: NaN,
    /** 登録店舗 */
    created_store_id: NaN,
    /** 登録社員 */
    created_employee_id: NaN,
    /** 更新店舗 */
    updated_store_id: NaN,
    /** 更新社員 */
    updated_employee_id: NaN,
    /** 登録日－期間開始 */
    created_at_start: null,
    /** 登録日－期間終了 */
    created_at_end: null,
    /** 更新日－期間開始 */
    updated_at_start: null,
    /** 更新日－期間終了 */
    updated_at_end: null,

  });

  static customerRank = (): CsvCustomerRankSort => ({
    /* 更新日（開始） */
    updated_date_start: null,
    /* 更新日（終了） */
    updated_date_end: null,
    /* オフセット */
    offset: 0,
    /* 1ページ内の件数 */
    limit: 100,
    /* 並替基準列 */
    sort_by: 0,
    /* 並替方法 */
    highlow: 1,
  });

  static supportHistory = (): CsvSupportHistorySort => ({
  /** 受付日 */
    contact_dt: null,
    /** カテゴリ */
    support_id: NaN,
    source_id: NaN,
    /** 対応区分 */
    support_flag: 2,
    /** 文字列検索 */
    word: '',
    /** 顧客名 */
    customer_name: '',
    /** 顧客担当店舗 */
    customer_store_id: NaN,
    /** 顧客担当者 */
    customer_employee_id: NaN,
    /** 対応日 */
    support_dt: null,
    /** 対応担当店舗 */
    support_store_id: NaN,
    /** 対応担当者 */
    support_employee_id: NaN,
    /** オフセット */
    offset: 0,
    /** 1ページ内の件数 */
    limit: 100,
    /** 並替基準列 */
    sort_by: 0,
    /** 並替方法 */
    highlow: 1,
    /** 登録店舗 */
    created_store_id: NaN,
    /** 登録社員 */
    created_employee_id: NaN,
    /** 更新店舗 */
    updated_store_id: NaN,
    /** 更新社員 */
    updated_employee_id: NaN,
    /** 登録日－期間開始 */
    created_at_start: null,
    /** 登録日－期間終了 */
    created_at_end: null,
    /** 更新日－期間開始 */
    updated_at_start: null,
    /** 更新日－期間終了 */
    updated_at_end: null,
  });
}
