import { goBack, push } from 'connected-react-router';
import { cloneDeep } from 'lodash';
import isEqual from 'lodash/isEqual';
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { EstimateCalcModelPC } from '../../../../collection/estimate/estimate-calc.model.pc';
import { EstimateCollection } from '../../../../collection/estimate/estimate.collection';
import { EstimateMeisaiModel } from '../../../../model/estimate/estimate-meisai.model';
import { XmlParser } from '../../../../parser/xml-parser';
import { DialogActions } from '../../../../redux/dialog/dialog.action';
import { EstimateActions } from '../../../../redux/estimate/estimate.action';
import { GetTax } from '../../../../redux/master/api/master-tax/api-master-tax';
import { ProjectActions } from '../../../../redux/project/project.action';
import { State } from '../../../../redux/root.reducer';
import { EditPriceAreaState, Estimate, EstimateEditState } from '../../../../type/estimate/estimate.type';
import { DateFormatter } from '../../../../utilities/date-formatter';
import { getTermDay } from '../../../../utilities/get-term-day';
import { MathHelper } from '../../../../utilities/math-helper';
import { openEmail } from '../../../../utilities/open-email';
import { EstimatePriceAreaCollectionPC } from '../../../pc/pages/estimate/edit/price-area/estimate-price-area.collection.pc';
import { Button } from '../../../ui/button/button';
import { IconButton } from '../../../ui/button/icon-button/icon-button';
import { LeftIconButton } from '../../../ui/button/left-icon-button/left-icon-button';
import { OrderSP } from '../../layout/order/order.sp';
import { BasePageSP } from '../base.page.sp';
import { EstimateEditSP } from '../estimate/edit/estimate-edit.sp';
import { EstimateEditDialogTitle } from '../estimate/edit/estimate-edit.type.sp';
import { EstimateSearchBoxSP } from '../estimate/serch-box/estimate-search-box.sp';
import { DetailPriceEditSP } from './edit/detail-price-edit.sp';
import './estimate-detail.sp.scss';
import { EstimatePriceInfoSp } from './info/price/estimate-price-info.sp';
import { useAppSelector } from '../../../../hooks/use-redux';
import { EstimateMeisaiSP } from '../estimate/meisai/estimate-meisai.sp';
import { DisplayElements } from '../../../../type/display-elements.type';
import { RoutingPath } from '../../../../routes/routing-pass';
import { EstimateInfoEditSP } from './edit/info/info-edit.sp';
import { EstimateValidation } from '../../../../model/validation/estimate/estimate.validation';
import { EstimateModel } from '../../../../model/estimate/estimate-model';
import { ApiEstimatePostParam } from '../../../../redux/estimate/api/estimate/api-estimate.type';
import { Project } from '../../../../type/project/project.type';
import { Message } from '../../../../collection/message.collection';
import { useWillUnMount, useDidMount } from '../../../../hooks/life-cycle';
import { EstimateDetailListSP } from '../../layout/body/list/estimate/estimate-detail-list';
import { MasterActions } from '../../../../redux/master/master.action';
import { XMLType } from '../../../pc/pages/master/master-body/master-quote-fixed-body';
import { useEditAuthEstimate, useEstimateAuthority } from '../../../../hooks/use-authority';
import { BreakLine } from '../../../ui/break-line/break-line';
import { PrintPreview } from '../../../dialogs/print-preview/print-preview';

const Row = (props: { title: string; value: DisplayElements; className?:string}) => {
  const { title, value, className } = props;
  const dispatch = useDispatch();
  const estimateAuth = useEstimateAuthority();

  useDidMount(() => {
    if (!estimateAuth) dispatch(push('/'));
  });
  return (
    <div className="row">
      <div className="row__col1">{title}</div>
      <div className="row__col2">：</div>
      <div className={`row__col3 ${className || ''}`}>{value}</div>
    </div>
  );
};

/** 見積詳細 SP */
export const EstimateDetailSP = () => {
  const { id: _id } = useParams<{ id: string; }>();

  /* Hooks */
  const priceDetailEditButton = React.createRef();

  const dispatch = useDispatch();
  const user = useAppSelector((v) => (v.user));

  const [info, setInfo] = useState<Estimate | null>(null);
  const [id, setId] = useState(_id);

  /* State */
  /**  予備費1_名前*/
  const [reserve1Name, setReserve1Name] = useState('');
  /**  予備費2_名前*/
  const [reserve2Name, setReserve2Name] = useState('');

  /* 編集Parameter */
  const [editState, setEditState] = useState(EstimateCollection.editInitialState);
  const [meisaiModel, setMeisaiModel] = useState<EstimateMeisaiModel | null>(null);
  const [editMeisaiModel, setEditMeisaiModel] = useState<EstimateMeisaiModel | null>(null);
  const [isEditMeisai, setIsEditMeisai] = useState(false);
  const [priceArea, setPriceArea] = useState<EditPriceAreaState>(
    EstimateCollection.priceAreaInitialState,
  );

  const [isSort, setIsSort] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [isOpenPriceArea, setIsOpenPriceArea] = useState(true);
  // 0:編集不可, 1:通常編集, 2:受注変更ボタン
  const [allowEdit, setAllowEdit] = useState<0 | 1 | 2>(1);
  const [disabledOrder, setDisabledOrder] = useState(true);
  const editAuth = useEditAuthEstimate(info);

  /* 権限のセット */
  const setAuthority = useCallback((v: Estimate) => {
    let auth = false;
    if (!v) auth = false;
    if (!v.created_employee_id) auth = true;
    if (user.authority1) auth = true;
    if (user.id === v.created_employee_id) auth = true;

    if (!auth) {
      setAllowEdit(0);
      return;
    }
    setAllowEdit(1);
    if (v.contract_date && v.contract_no && v.saisyu_f) {
      setAllowEdit(0);
      if (!v.completion_date && !v.complete_date) setAllowEdit(2);
    }
  }, [user, editAuth]);

  /* 見積情報取得 */
  const getInfo = useCallback(() => {
    if (allowEdit !== 1) return;
    dispatch(MasterActions.api.quoteFixed.getList({
      param: {},
      callback: (qf) => {
        new XmlParser()
          .parse(qf.xml_format)
          .then((teikeiMaster: XMLType) => {
            const [genba, yobi] = teikeiMaster.list.item;
            setReserve1Name(genba.$.name);
            setReserve2Name(yobi.$.name);
            dispatch(EstimateActions.api.estimate.get({
              param: { id: Number(id) },
              callback: (v) => {
                setAuthority(v);
                setInfo(cloneDeep(v));
                setEditState({
                  ...v,
                  estimate_dt: v.estimate_dt ? new Date(v.estimate_dt) : null,
                  estimate_kouki_start_dt: v.estimate_kouki_start_dt
                    ? new Date(v.estimate_kouki_start_dt) : null,
                  estimate_kouki_end_dt: v.estimate_kouki_end_dt
                    ? new Date(v.estimate_kouki_end_dt) : null,
                  yukokigen: v.yukokigen ? new Date(v.yukokigen) : null,
                  jyutyu_yotei_dt: v.jyutyu_yotei_dt ? new Date(v.jyutyu_yotei_dt) : null,
                });
                if (v.meisai) {
                  new XmlParser().parse(v.meisai).then((res) => {
                    setMeisaiModel(new EstimateMeisaiModel(res));
                    setEditMeisaiModel(new EstimateMeisaiModel(res));
                  });
                } else {
                  setMeisaiModel(new EstimateMeisaiModel());
                  setEditMeisaiModel(new EstimateMeisaiModel());
                }
                setPriceArea({
                  ...EstimatePriceAreaCollectionPC.initialEditState,
                  ...v,
                });
              },
            }));
          });
      },
    }));
  }, [id, editAuth, allowEdit]);

  const post = useCallback((param: {
    data: EstimateEditState,
    meisai: EstimateMeisaiModel | null,
    priceModel: EditPriceAreaState,
  }) => {
    if (!info || allowEdit !== 1) return;
    const errorMessageList = EstimateValidation(param.data, param.meisai, param.priceModel);
    if (errorMessageList.length) {
      dispatch(DialogActions.pushMessage({
        title: '見積情報更新',
        message: errorMessageList,
        callback: () => {},
      }));
      return;
    }
    dispatch(EstimateActions.api.estimate.post({
      param: {
        id: Number(id),
        data: EstimateModel.postParm({
          editState: param.data,
          meisaiModel: param.meisai,
          priceModel: param.priceModel,
          employee_id: info.employee_id,
          reserve1Name,
          reserve2Name,
        }),
      },
      update: !!id,
      onSuccess: () => {
        dispatch(DialogActions.pop());
        getInfo();
      },
    }));
  },
  [editState, id, meisaiModel, priceArea, info?.employee_id, allowEdit]);

  /* 詳細編集 */
  const editInfo = useCallback(() => {
    if (!info || allowEdit !== 1) return;
    dispatch(DialogActions.push({
      title: EstimateEditDialogTitle.update,
      isCheck: true,
      element: <EstimateInfoEditSP
        editState={editState}
        genbaName={info?.field_name || ''}
        projectName={info?.project_name || ''}
        estimateCreator={info?.employee_name || ''}
        callback={(v) => {
          setEditState(v);
          post({
            data: v,
            meisai: meisaiModel,
            priceModel: priceArea,
          });
        }}
      />,
      // element: <EstimateEditSP id={Number(id)} />,
    }));
  }, [editState, info, priceArea, meisaiModel, reserve1Name, reserve2Name, allowEdit]);

  /* 明細詳細更新 */
  const updateMeisai = useCallback((v:EstimateMeisaiModel) => {
    if (!info || allowEdit !== 1) return;
    GetTax(info.estimate_dt || new Date()).then((tax) => {
      const res = EstimateCalcModelPC.calc(priceArea, v.list || [], tax);
      post({
        data: editState,
        meisai: v,
        priceModel: res,
      });
    });
  }, [priceArea, editState, meisaiModel, info, reserve1Name, reserve2Name, allowEdit]);

  /* 金額編集 */
  const editPrice = useCallback(() => {
    if (!info || allowEdit !== 1) return;
    dispatch(DialogActions.push({
      title: '金額詳細編集',
      isCheck: true,
      element: <DetailPriceEditSP
        info={info}
        reserve1={null}
        reserve2={null}
        isEdit
        estimateDt={editState.estimate_dt}
        state={priceArea}
        model={meisaiModel}
        allowEdit={1}
        callback={(v) => {
          setPriceArea(v);
          post({
            data: editState,
            meisai: meisaiModel,
            priceModel: v,
          });
        }}
      />,
    }));
  }, [priceArea, editState, meisaiModel, priceArea, info, reserve1Name, reserve2Name, allowEdit]);

  /* 複写して新規作成 */
  const copyAdd = useCallback(() => {
    dispatch(DialogActions.push({
      title: '複写して新規作成',
      element: <EstimateEditSP
        id={Number(id)}
        isCopy
        callback={() => dispatch(push(RoutingPath.estimate))}
      />,
    }));
  }, [id]);

  /* メール送信 */
  const handleClickMail = useCallback(() => {
    openEmail({
      address: '',
      subject: 'テスト用',
      body: ['Test'],
    });
  }, [info]);

  /* 案件情報取得 */
  const getProject = useCallback((callback: (res: Project) => void) => {
    if (!info) return;
    dispatch(ProjectActions.api.project.get({
      param: { id: info?.project_id },
      callback: (res) => {
        if (res.project_employee_id !== user.id) {
          setDisabledOrder(true);
        } else {
          setDisabledOrder(Boolean(cloneDeep(res.completion_date) || cloneDeep(res.complete_date)));
        }
        callback?.(res);
      },
    }));
  }, [editState, info, id]);

  /* 受注編集 */
  const editOrder = useCallback(() => {
    if (!info || allowEdit !== 1) return;
    getProject((res) => {
      dispatch(DialogActions.push({
        title: '受注登録',
        element: <OrderSP estimateId={Number(id)} project={res} />,
      }));
    });
  }, [getProject, id, allowEdit]);

  useEffect(() => {
    if (!info?.project_id) return;
    getProject((res) => {
      if (res.project_employee_id !== user.id) {
        setDisabledOrder(true);
      } else {
        setDisabledOrder(Boolean(cloneDeep(res.completion_date) || cloneDeep(res.complete_date)));
      }
    });
  }, [info?.project_id]);

  const onClickJutyu = useCallback(() => {
    dispatch(DialogActions.push({
      title: '受注変更',
      element: <EstimateEditSP
        id={Number(id)}
        isCopy
        callback={(__id) => {
          if (!__id) {
            dispatch(push(RoutingPath.estimate));
            return;
          }
          setId(String(__id));
          dispatch(push(`${RoutingPath.estimateDetail}/${__id}`));
          setAllowEdit(1);
          getInfo();
        }}
      />,
    }));
    // if (allowEdit !== 1) return;
    // this.jyutyu_change_flg = true;
    // this._addEstimate.keiyaku_flag = false;
    // setId(undefined);
    // setIsChangeJutyu(true);
    setAllowEdit(1);
  }, [allowEdit, id, getInfo, _id]);

  const handleClickDownload = useCallback(() => {
    if (!info) return;
    dispatch(DialogActions.push({
      title: '見積PDFダウンロード',
      // className: 'auto_height_dialog',
      element: <PrintPreview
        type="estimateIn"
        estimate={info}
      />,
    }));
  }, [info]);

  const handlerClickMeisaiUp = useCallback(() => {
  //   if (!meisaiList[selectedIndex] || !meisaiList[selectedIndex - 1]) {
  //     return;
  //   }
  //   const temp = meisaiList[selectedIndex - 1];
  //   meisaiList[selectedIndex - 1] = meisaiList[selectedIndex];
  //   meisaiList[selectedIndex] = temp;
  //   dispatch(EstimateActions.setMeisaiList(meisaiList));
  //   setSelectedIndex(selectedIndex - 1);
  // }, [meisaiList, selectedIndex]);
  // const handlerClickMeisaiDown = useCallback(() => {
  //   if (!meisaiList[selectedIndex] || !meisaiList[selectedIndex + 1]) {
  //     return;
  //   }
  //   const temp = meisaiList[selectedIndex + 1];
  //   meisaiList[selectedIndex + 1] = meisaiList[selectedIndex];
  //   meisaiList[selectedIndex] = temp;
  //   dispatch(EstimateActions.setMeisaiList(meisaiList));
  //   setSelectedIndex(selectedIndex + 1);
  }, [selectedIndex]);

  /* Effect */
  /* APIデータのセット */
  useEffect(() => {
    getInfo();
  }, [id]);

  useWillUnMount(() => {
    dispatch(EstimateActions.setEstimate(null));
  });

  /*  */
  const termDay = useMemo(() => {
    if (!info?.estimate_kouki_start_dt || !info?.estimate_kouki_end_dt) { return ''; }
    return getTermDay(
      new Date(info.estimate_kouki_start_dt),
      new Date(info.estimate_kouki_end_dt),
    );
  }, [
    info?.estimate_kouki_start_dt,
    info?.estimate_kouki_end_dt,
  ]);

  return (
    <BasePageSP
      className="estimateDetail estimate_detail_sp"
      searchBoxDialog={{
        title: '詳細検索',
        element: <EstimateSearchBoxSP
          callback={() => {
            dispatch(DialogActions.pop());
            dispatch(push(RoutingPath.estimate));
          }}
        />,
      }}
      searchCallback={(v) => {
        dispatch(EstimateActions.setSort({ keyword: v }));
        dispatch(push(RoutingPath.estimate));
      }}
    >
      <div className="detail_wrap">
        <div className="detail_header estimate_detail_sp__header">
          <div
            className="detail_header_inner estimate_detail_sp__header__inner"
            onClick={() => {
              if (allowEdit !== 2) {
                dispatch(DialogActions.pushCheck({
                  callback: () => {
                    dispatch(goBack());
                  },
                }));
              } else {
                dispatch(goBack());
              }
            }}
          >
            <div
              className="detail_header_inner__back_btn"
            >
              <i className="fas fa-chevron-circle-left" />
            </div>
            <span>
              見積詳細
            </span>
          </div>
        </div>

        <div className="estimate_detail_sp__container">
          <div className="estimate_detail_sp__container__info">
            <div className="estimate_detail_sp__container__info__top">
              <div className="col1">
                <Row
                  title="見積番号"
                  value={<>{info?.estimate_no || '---'}</>}
                />
                <Row
                  title="案件名"
                  value={<>{info?.project_name || '---'}</>}
                />
                <Row
                  title="顧客名"
                  value={<>{info?.customer_name || '---'}</>}
                />
                <Row
                  title="現場名称"
                  value={<>{info?.field_name || '---'}</>}
                />
                <Row
                  title="見積作成者"
                  value={<>{info?.created_employee_name || '---'}</>}
                />
                <Row
                  title="見積金額"
                  value={(
                    <>&yen;&nbsp;{
                      !isEditMeisai
                        ? MathHelper.localStr(Number(info?.gokei_zeinuki_kin)) || '---'
                        : '---'
                      }
                    </>
                  )}
                  className="important"
                />
              </div>
              <div className="col2">
                {allowEdit === 1 && !isEditMeisai
                && (
                <Button
                  onClick={editInfo}
                  size="md"
                  color="secondary"
                  className="estimateDetail-content__btn"
                  disabled={allowEdit !== 1}
                >
                  編集
                </Button>
                )}
              </div>
            </div>
            <div className="estimate_detail_sp__container__info__bottom">
              <Row
                title="見積日付"
                value={<>{DateFormatter.date2str(info?.estimate_dt)}</>}
              />
              <Row
                title="工期"
                value={(
                  <>{DateFormatter.date2str(info?.estimate_kouki_start_dt)}
                    ～{DateFormatter.date2str(info?.estimate_kouki_end_dt)}{termDay ? `（${termDay}日間）` : ''}
                  </>
                )}
              />
              <Row
                title="見積有効期限"
                value={<>{DateFormatter.date2str(info?.yukokigen)}</>}
              />
              <Row
                title="受注予定日"
                value={<>{DateFormatter.date2str(info?.jyutyu_yotei_dt)}</>}
              />
            </div>
          </div>

          {/* <div className="detail_header estimate_detail_sp__header secondary"> */}
          {/* <div className="estimate_detail_sp__header__inner">
              <div>明細</div> */}
          {/* {allowEdit === 1
              && (
              <div className="" onClick={handleClickEdit}>
                {isSort ? (<div />) : (
                  <i className="fas fa-plus-circle fa-2x" />
                )}
              </div>
              )}
              {allowEdit === 1
              && (
              <div>
                {isSort ? (
                  <>
                    <Button
                      size="md"
                      color="secondary"
                      className="estimateDetail-header__center__btn"
                      onClick={handlerClickMeisaiUp}
                    >
                      <i className="fas fa-arrow-up" />
                    </Button>
                    <Button
                      size="md"
                      color="secondary"
                      className="estimateDetail-header__center__btn"
                      onClick={() => {}}
                    >
                      <i className="fas fa-arrow-down" />
                    </Button>
                  </>
                ) : (<></>)}
                  {allowEdit === 1
                && (
                <LeftIconButton
                  size="md"
                  color={isSort ? 'dark' : 'secondary'}
                  className="estimateDetail-header__center__btn"
                  onClick={handlerChangeSortMode}
                  fontAwesomeClass={`fas fa-${isSort ? 'list' : 'sort'}`}
                  label={`${isSort ? '終了' : '並べ替え'}`}
                />
                )}
              </div>
              )}
            </div> */}
          {/* {allowEdit === 1 && (
              <Button
                color="secondary"
                disabled={false}
                onClick={editMeisai}
              >
                編集
              </Button>
              )} */}
          {/* </div> */}
          {/* </div> */}

          <div className="estimate_detail_sp__container__list">
            {/* <EstimateDetailListSP
              dndkey="detail"
              sortMode={false}
              model={meisaiModel}
              callbackModel={() => {}}
              allowEdit={0}
            /> */}
            <EstimateMeisaiSP
              isDetail
              model={editMeisaiModel}
              callback={(v) => setEditMeisaiModel(cloneDeep(v))}
              callbackPost={(v) => updateMeisai(v)}
              callbackCancel={() => {
                setEditMeisaiModel(cloneDeep(meisaiModel));
              }}
              onChangeMode={setIsEditMeisai}
              allowEdit={allowEdit}
            />
          </div>

          {/* price area */}
          <div className="detail_header estimate_detail_sp__header secondary">
            <div className="estimate_detail_sp__header__inner">
              <div
                className="estimate_detail_sp__container__price_detail__label"
                onClick={(e) => {
                  if (e.target === priceDetailEditButton.current) return;
                  setIsOpenPriceArea(!isOpenPriceArea);
                }}
              >
                <span>{isOpenPriceArea ? '▼' : '▶︎'}</span>
                <span>金額詳細</span>
                {allowEdit === 1 && !isEditMeisai && (
                <Button
                  color="secondary"
                  disabled={false}
                  onClick={editPrice}
                  ref={priceDetailEditButton}
                >
                  編集
                </Button>
                )}
              </div>
            </div>

            {/* 金額表示 */}
            {!isEditMeisai
            && (
            <EstimatePriceInfoSp
              isOpen={isOpenPriceArea}
              info={info}
            />
            )}
          </div>

          <div className="estimate_detail_sp__container__remarks">
            <div className="estimate_detail_sp__container__remarks__label">備考</div>
            <div className="estimate_detail_sp__container__remarks__content">
              <BreakLine text={info?.remarks} />
            </div>
          </div>
        </div>

        <div className="estimate_detail_sp__footer_secondary">
          <div className="estimate_detail_sp__footer_secondary__wrap">
            <div className="row">
              <div className="row__col1">小計:</div>
              <div className="row__col2">&yen;&nbsp;{
                !isEditMeisai
                  ? MathHelper.rounding2Str(info?.gokei_zeinuki_kin
                    ? Number(info.gokei_zeinuki_kin)
                    : 0, 0, 'floor', true)
                  : '---'
              }
              </div>
            </div>
            <div className="row">
              <div className="row__col1">消費税:</div>
              <div className="row__col2">&yen;&nbsp;{
                !isEditMeisai
                  ? MathHelper.rounding2Str(info?.shohizei_kin
                    ? Number(info.shohizei_kin)
                    : 0, 0, 'floor', true)
                  : '---'
              }
              </div>
            </div>
            <div className="row">
              <div className="row__col1">総合計:</div>
              <div className="row__col2">&yen;&nbsp;{
                !isEditMeisai
                  ? MathHelper.rounding2Str(info?.gokei_kin
                    ? Number(info.gokei_kin)
                    : 0, 0, 'floor', true)
                  : '---'
              }
              </div>
            </div>
          </div>

          <div className="page_body_footer estimate_detail_sp__footer">
            <div>
              <LeftIconButton
                size="md"
                color="primary"
                fontAwesomeClass="fas fa-copy"
                onClick={copyAdd}
                label="複写して新規作成"
              />
            </div>
            <div>
              <LeftIconButton
                size="md"
                color="primary"
                fontAwesomeClass="fas fa-file-download"
                onClick={handleClickDownload}
                label="見積ﾀﾞｳﾝﾛｰﾄﾞ"
              />
            </div>
            <div>
              {/*
              <IconButton
                className="email"
                fontAwesomeClass="fas fa-envelope"
                size="md"
                color="primary"
                onClick={handleClickMail} />
              */}
              <LeftIconButton
                size="md"
                color="primary"
                fontAwesomeClass="fas fa-pen-square"
                disabled={disabledOrder || (allowEdit === 2)}
                onClick={editOrder}
                label="受注"
              />
              {allowEdit === 2 && (
              <LeftIconButton
                size="md"
                color="primary"
                fontAwesomeClass="fas fa-sync-alt"
                onClick={() => onClickJutyu()}
                label="受注変更"
              />
              )}
            </div>
          </div>
        </div>
      </div>
    </BasePageSP>
  );
};
