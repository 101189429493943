import { isEmpty } from 'lodash';
import {
  memo, useCallback, useEffect, useMemo, useState,
} from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { deleteCommaStr } from '../../../utilities/delete-comma';
import { Input } from './input';
import { deleteHyphen } from '../../../utilities/delete-str';
import { Validation } from '../../../model/validation/validation';
import { MathHelper } from '../../../utilities/math-helper';

export type InputNumberProps = {
  className?: string;
  value: string | undefined;
  /** 少数の桁数 */
  decimalLen?: number;
  /** 整数の桁数 */
  intLen?: number;
  maxLength?:number;
  /** マイナス値の許容 */
  minus?: boolean;
  disabled?: boolean;
  validationList?: Validation[];
  touch?: boolean;
  require?: boolean;
  /** 任意文字列の場合string Valueの場合true */
  title?: string | boolean;
  noRequireLabel?: boolean;
  errorPosBottom?: boolean;
  firstFocus?: boolean
  onChange: (v: string) => void;
  onBlur?: (v: string) => void;
  onBlurCapture?: (v: string) => void,
  setIsFocus?: (isFocus: boolean) => void;
  isEmpty?: boolean;
  errorMessage?: string[];
  placeholder?: string;
  alignRight?: boolean;
};

export const InputNum = memo((props: InputNumberProps) => {
  const {
    value,
    decimalLen,
    intLen,
    minus,
    onChange: _onChange,
    onBlur: _onBlur,
    maxLength,
    isEmpty: _isEmpty,
    errorMessage,
    onBlurCapture,
    placeholder,
    alignRight = true,
  } = props;

  const [onComposition, setOnComposition] = useState(false);
  const [anotherTouch, setAnotherTouch] = useState(false);

  const onChange = useCallback((v: string) => {
    if (onComposition) return;
    // 空かどうか
    if (isEmpty(v)) {
      if (!_isEmpty) {
        _onChange('');
        return;
      }
    }

    // マイナスを一回、先頭のみ許容
    const minusLen = (v.match(/-/g))?.length || 0;
    if (minus && ((v[0] === '-' && minusLen >= 2) || (v[0] !== '-' && minusLen))) return;

    // 小数点以下何個でもOKかどうか
    const noDecimal = decimalLen === undefined && !maxLength;

    // ドットを一回のみ許容
    const dotLen = v.toString().match(/\./g)?.length || 0;

    // 小数点許容しない
    if (noDecimal && dotLen) return;

    // ドットが１個以上打てないようにする
    if (dotLen > 1) return;

    // 数値かどうか
    const isMatch = minus
      ? v.match(/[^0-9.\-,]+/g)
      : v.match(/[^0-9.,]+/g);

    // 数値だった場合
    if (isMatch) return;

    // 先頭文字対応
    // 先頭カンマ・ドット
    if (v[0] === ',' || v[0] === '.') return;

    // 先頭0,次が.じゃないとき
    if (v.length > 1 && (v[0] === '0' && v[1] !== '.')) {
      const text = cloneDeep(v);
      if (v[1] === '0') {
        _onChange(text);
      } else {
        _onChange(text.substring(1));
      }
      return;
    }

    // [0]整数 [1]少数
    const splitNum = v.toString().split('.');

    // 整数制限
    if (intLen && !maxLength) {
      const val = String(deleteCommaStr(deleteHyphen(splitNum[0])));
      if (val.length > intLen) return;
    }

    // 小数制限場合
    if (decimalLen && !maxLength) {
      const val = String(deleteCommaStr(splitNum[1]));
      if (val.length > decimalLen) return;
    }

    _onChange(v);
  }, [decimalLen, intLen, minus, _onChange, maxLength, onComposition]);

  const onBlur = useCallback(() => {
    const val = value ? deleteCommaStr(value) : '';
    if (_onBlur) { _onBlur(val); }
  }, [value, _onBlur]);

  const changeOnComposition = useCallback((v: boolean) => {
    setOnComposition(v);
    setAnotherTouch(v);
  }, []);

  return (
    <Input
      {...props}
      value={value}
      onBlurCapture={() => onBlurCapture?.(value ? deleteCommaStr(value) : '')}
      onChange={(e) => {
        const text = cloneDeep(e.target.value);
        onChange(deleteCommaStr(text));
      }}
      onBlur={() => {
        onBlur?.();
        changeOnComposition(false);
      }}
      onClick={() => changeOnComposition(false)}
      onEnterKeyPress={() => changeOnComposition(false)}
      onKeyDown={(e) => {
        if (e.key === ',') {
          e.preventDefault();
        }
        if (onComposition && e.key === 'Enter') {
          setAnotherTouch(true);
        }
        if (e.key === 'Enter') {
          setAnotherTouch(false);
          changeOnComposition(false);
        }
      }}
      inputMode={decimalLen ? 'decimal' : 'numeric'}
      alignRight={alignRight}
      type="tel"
      onFocus={() => {
        _onChange(deleteCommaStr(value));
      }}
      placeholder={placeholder}
      error={!!errorMessage?.length}
      anotherTouch={anotherTouch}
      anotherMessage={errorMessage || ['半角数字で入力してください']}
      onCompositionStart={() => changeOnComposition(true)}
      onCompositionEnd={() => changeOnComposition(false)}
    />
  );
});

export const InputNumByNum = memo((props: InputNumberProps) => {
  const {
    value: _value,
    decimalLen,
    intLen,
    minus,
    onChange: __onChange,
    onBlur: _onBlur,
    maxLength,
    isEmpty: _isEmpty,
    errorMessage,
    onBlurCapture,
    placeholder,
    alignRight = true,
  } = props;

  const [onComposition, setOnComposition] = useState(false);
  const [anotherTouch, setAnotherTouch] = useState(false);
  const [value, setValue] = useState(_value);

  const _onChange = useCallback((v: string) => {
    __onChange(v);
    setValue(v);
  }, [__onChange]);

  const onChange = useCallback((v: string) => {
    if (onComposition) return;
    // 空かどうか
    if (isEmpty(v)) {
      if (!_isEmpty) {
        _onChange('');
        return;
      }
    }

    // マイナスを一回、先頭のみ許容
    const minusLen = (v.match(/-/g))?.length || 0;
    if (minus && ((v[0] === '-' && minusLen >= 2) || (v[0] !== '-' && minusLen))) return;

    // 小数点以下何個でもOKかどうか
    const noDecimal = decimalLen === undefined && !maxLength;

    // ドットを一回のみ許容
    const dotLen = v.toString().match(/\./g)?.length || 0;

    // 小数点許容しない
    if (noDecimal && dotLen) return;

    // ドットが１個以上打てないようにする
    if (dotLen > 1) return;

    // 数値かどうか
    const isMatch = minus
      ? v.match(/[^0-9.\-,]+/g)
      : v.match(/[^0-9.,]+/g);

    // 数値だった場合
    if (isMatch) return;

    // 先頭文字対応
    // 先頭カンマ・ドット
    if (v[0] === ',' || v[0] === '.') return;

    // 先頭0,次が.じゃないとき
    if (v.length > 1 && (v[0] === '0' && v[1] !== '.')) {
      const text = cloneDeep(v);
      if (v[1] === '0') {
        _onChange(text);
      } else {
        _onChange(text.substring(1));
      }
      return;
    }

    // [0]整数 [1]少数
    const splitNum = v.toString().split('.');

    // 整数制限
    if (intLen && !maxLength) {
      const val = String(deleteCommaStr(deleteHyphen(splitNum[0])));
      if (val.length > intLen) return;
    }

    // 小数制限場合
    if (decimalLen && !maxLength) {
      const val = String(deleteCommaStr(splitNum[1]));
      if (val.length > decimalLen) return;
    }

    _onChange(v);
  }, [decimalLen, intLen, minus, _onChange, maxLength, onComposition]);

  const onBlur = useCallback(() => {
    const val = value ? deleteCommaStr(value) : '';
    setValue(value ? MathHelper.localStr(value) : '');
    if (_onBlur) { _onBlur(val); }
  }, [value, _onBlur]);

  const changeOnComposition = useCallback((v: boolean) => {
    setOnComposition(v);
    setAnotherTouch(v);
  }, []);

  return (
    <Input
      {...props}
      value={value}
      onBlurCapture={() => onBlurCapture?.(value ? deleteCommaStr(value) : '')}
      onChange={(e) => {
        const text = cloneDeep(e.target.value);
        onChange(deleteCommaStr(text));
      }}
      onBlur={() => {
        onBlur?.();
        changeOnComposition(false);
      }}
      onClick={() => changeOnComposition(false)}
      onEnterKeyPress={() => changeOnComposition(false)}
      onKeyDown={(e) => {
        if (e.key === ',') {
          e.preventDefault();
        }
        if (onComposition && e.key === 'Enter') {
          setAnotherTouch(true);
        }
        if (e.key === 'Enter') {
          setAnotherTouch(false);
          changeOnComposition(false);
        }
      }}
      inputMode={decimalLen ? 'decimal' : 'numeric'}
      alignRight={alignRight}
      type="tel"
      onFocus={() => {
        _onChange(deleteCommaStr(value));
      }}
      placeholder={placeholder}
      error={!!errorMessage?.length}
      anotherTouch={anotherTouch}
      anotherMessage={errorMessage || ['半角数字で入力してください']}
      onCompositionStart={() => changeOnComposition(true)}
      onCompositionEnd={() => changeOnComposition(false)}
    />
  );
});
