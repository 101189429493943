import { memo, useMemo } from 'react';
import { getTermDate } from '../../../utilities/get-term-day';

export const Tikunengetsu = memo((props: { date: Date | null, isList?:boolean}) => {
  const { date, isList } = props;

  const termDate = useMemo(() => getTermDate(date, new Date()), [date]);

  const showYear = useMemo(() => (
    !termDate?.year
      || termDate.year < 0 ? 0
      : termDate.year),
  [termDate?.year]);

  const showMonth = useMemo(() => (
    (termDate?.year || termDate?.year === 0)
      && termDate?.year < 0
      ? 0
      : termDate?.month),
  [termDate]);

  return (
    <div>
      {isList
        ? `${date ? showYear : '--'}年${date ? showMonth : '--'}ヶ月`
        : `（築年月数：${date ? showYear : '--'}年${date ? showMonth : '--'}ヶ月）`}
      {/* {termDate ? (
        <>
          ({termDate?.year || 0}年{termDate?.month || 0}ヶ月)
        </>
      ) : (
        <>
          ({termDate?.year || 0}年{termDate?.month || 0}ヶ月)
        </>
      )} */}
    </div>
  );
});
