import {
  useState, useCallback, useMemo,
} from 'react';
import { useDispatch } from 'react-redux';
import { cloneDeep } from 'lodash';
import { RightLabelCheckbox } from '../../../../ui/checkbox/right-label-checkbox/right-label-checkbox';
import { TopLabelInputField } from '../../../../ui/input-field/top-label-input-field/top-label-input-field';
import { SearchBox } from '../../../layout/search-box/search-box.sp';
import './support-history-search-box.sp.scss';
import { SupportHistorySortState } from '../../../../../type/support-history/support-history.type';
import { SupportHistoryActions } from '../../../../../redux/support-history/support-history.action';
import { useAppSelector } from '../../../../../hooks/use-redux';
import { useDidMount } from '../../../../../hooks/life-cycle';
import { MasterActions } from '../../../../../redux/master/master.action';
import { Select } from '../../../../ui/select/select';
import { DatePicker } from '../../../../ui/date-picker/date-picker';
import { LabelInput } from '../../../../ui/input/label-input';
import { pulldown } from '../../../../../utilities/pulldown';
import { Button } from '../../../../ui/button/button';
import { SupportHistoryCollection } from '../../../../../collection/support-history/support-history.collection';
import { useEmployeeList } from '../../../../../hooks/common/use-common';
import { useDateValidation } from '../../../../../hooks/use-date-validation';
import { DateTermValidation } from '../../../../../model/validation/date/term-date.validation';

type Props = {
  callback: (data: SupportHistorySortState) => void
  isSearch?: boolean;
}

export const SearchBoxSupportHistorySP = (props:Props) => {
  const { callback, isSearch } = props;
  /* Hooks */
  const dispatch = useDispatch();
  const sortState = useAppSelector((v) => (v.supportHistory.sort));
  const supportHistoryList = useAppSelector((v) => v.master.supportHistoryList);
  const employeeList = useAppSelector((v) => v.master.employeeList);
  const storeList = useAppSelector((v) => v.master.storeList);
  const user = useAppSelector((v) => v.user);
  const originList = useAppSelector((v) => (v.master.originList));

  /* State */
  const [sort, setSort] = useState(cloneDeep(sortState));
  /* 顧客従業員リスト */
  const customerEmployeeList = useEmployeeList(sort.customer_store_id, employeeList);
  /* 受付担当従業員リスト */
  const contactEmployeeList = useEmployeeList(sort.contact_store_id, employeeList);
  /* 対応担当従業員リスト */
  const supportedEmployeeList = useEmployeeList(sort.contact_store_id, employeeList);

  const termDate = useDateValidation([
    { start: sort.contact_dt_start, end: sort.contact_dt_end },
    { start: sort.support_dt_start, end: sort.support_dt_end },
    { start: sort.created_at_start, end: sort.created_at_end },
    { start: sort.updated_at_start, end: sort.updated_at_end },
  ]);

  /* Callback */
  const handleClickSearch = useCallback(() => {
    if (termDate.messages.length) {
      termDate.check();
      return;
    }
    callback(sort);
    if (!isSearch) {
      dispatch(SupportHistoryActions.setSort(sort));
    }
  }, [sort, isSearch, termDate]);

  const setState = useCallback((v: Partial<SupportHistorySortState>) => {
    const data = {
      ...cloneDeep(sort),
      ...cloneDeep(v),
    };
    setSort(data);
  }, [sort, isSearch]);

  useDidMount(() => {
    dispatch(MasterActions.api.store.getList({}));
    dispatch(MasterActions.api.employee.getList({}));
    dispatch(MasterActions.api.supportHistory.getList({}));
    dispatch(MasterActions.api.origin.getList({}));
  });

  return (
    <SearchBox
      callback={handleClickSearch}
      callbackClear={() => setState(SupportHistoryCollection._initialSortState(user))}
    >
      {/* search_box_body_inner は各画面の共通用 */}
      <div className="search_box_sp_body_inner support_history_search_box_sp">
        <div className="category_wrap">
          <div className="item_wrap">
            <TopLabelInputField
              label="顧客名"
              value={sort?.customer_name}
              onChange={(e) => setState({ customer_name: e.target.value })}
              className="full_width"
            />
          </div>
          <div className="item_wrap">
            <div className="item_label">顧客担当店舗</div>
            <div className="item_body">
              <Select
                value={sort?.customer_store_id}
                onChange={(data) => setState({
                  customer_store_id: Number(data),
                  customer_employee_id: NaN,
                })}
                defaultLabel="全て"
                options={[{ text: '指定なし', value: -1 }].concat(storeList.map((v) => ({
                  text: v.name, value: v.id,
                })))}
              />
            </div>
            <div className="item_wrap">
              <div className="item_label">顧客担当者</div>
              <Select
                className="add_text_left"
                defaultLabel="全て"
                value={sort?.customer_employee_id}
                onChange={(data) => setState({ customer_employee_id: Number(data) })}
                options={[{ text: '指定なし', value: -1 }]
                  .concat(pulldown(customerEmployeeList))}
              />
            </div>
          </div>
          <div className="item_wrap">
            <TopLabelInputField
              label="案件名"
              value={sort?.project_name}
              onChange={(e) => setState({ project_name: e.target.value })}
              className="full_width"
            />
          </div>
        </div>

        <div className="category_wrap">
          <div className="item_wrap">
            <div className="item_label">登録日</div>
            <div className="item_body item_schedule wrap">
              <div className="item_schedule__form">
                <DatePicker
                  date={sort?.contact_dt_start}
                  onChange={(v) => setState({ contact_dt_start: v })}
                  term={DateTermValidation([{
                    start: sort.contact_dt_start, end: sort.contact_dt_end,
                  }])}
                />
              </div>
              <div className="item_schedule__form flex_box flex_align_center mt_10">
                <div className="item_schedule__tilde">〜</div>
                <DatePicker
                  date={sort?.contact_dt_end}
                  onChange={(v) => setState({ contact_dt_end: v })}
                  term={DateTermValidation([{
                    start: sort.contact_dt_start, end: sort.contact_dt_end,
                  }])}
                />
              </div>
            </div>
          </div>

          <div className="item_wrap">
            <div className="item_label">登録店舗</div>
            <div className="item_body">
              <Select
                value={sort?.contact_store_id}
                onChange={(data) => setState({
                  contact_store_id: Number(data),
                  contact_employee_id: NaN,
                })}
                defaultLabel="全て"
                options={[{ text: '指定なし', value: -1 }]
                  .concat(storeList.map((v) => ({
                    text: v.name, value: v.id,
                  })))}
              />
            </div>
          </div>
          <div className="item_wrap">
            <div className="item_label">登録者</div>
            <div className="item_body">
              <Select
                className="add_text_left"
                defaultLabel="全て"
                value={sort?.contact_employee_id}
                onChange={(data) => setState({
                  contact_employee_id: Number(data),
                })}
                options={[{ text: '指定なし', value: -1 }]
                  .concat(pulldown(contactEmployeeList))}
              />
            </div>
          </div>

        </div>

        <div className="category_wrap">
          <div className="item_wrap">
            <div className="item_label">媒体</div>
            <div className="item_body">
              <Select
                value={sort?.support_id}
                onChange={(data) => setState({ support_id: Number(data) })}
                defaultLabel="全て"
                options={supportHistoryList.map((v) => ({
                  text: v.supported, value: v.id,
                }))}
              />
            </div>
          </div>
          <div className="item_wrap">
            <div className="item_head">カテゴリ</div>
            <div className="item_body">
              <Select
                value={sort?.source_id}
                onChange={(data) => setState({ source_id: Number(data) })}
                defaultLabel="全て"
                options={pulldown(originList)}
              />
            </div>
          </div>

          <div className="item_wrap">
            <TopLabelInputField
              label="対応履歴名"
              value={sort?.title}
              onChange={(e) => setState({ title: e.target.value })}
              className="full_width"
            />
          </div>

        </div>

        <div className="category_wrap">
          <div className="item_wrap">
            <div className="item_label">対応日</div>
            <div className="item_body item_schedule wrap">
              <div className="item_schedule__form">
                <DatePicker
                  date={sort?.support_dt_start}
                  onChange={(v) => setState({ support_dt_start: v })}
                  term={DateTermValidation([{
                    start: sort.support_dt_start, end: sort.support_dt_end,
                  }])}
                />
              </div>
              <div className="item_schedule__form flex_box flex_align_center mt_10">
                <div className="item_schedule__tilde">〜</div>
                <DatePicker
                  date={sort?.support_dt_end}
                  onChange={(v) => setState({ support_dt_end: v })}
                  term={DateTermValidation([{
                    start: sort.support_dt_start, end: sort.support_dt_end,
                  }])}
                />
              </div>
            </div>
          </div>
          <div className="item_wrap">
            <div className="item_label">対応店舗</div>
            <div className="item_body">
              <Select
                value={sort?.support_store_id}
                onChange={(data) => setState({
                  support_store_id: Number(data),
                  support_employee_id: NaN,
                })}
                defaultLabel="全て"
                options={[{ text: '指定なし', value: -1 }].concat(storeList.map((v) => ({
                  text: v.name, value: v.id,
                })))}
              />
            </div>
            <div className="item_wrap">
              <div className="item_label">対応者</div>
              <Select
                className="add_text_left"
                defaultLabel="全て"
                value={sort?.support_employee_id}
                onChange={(data) => setState({ support_employee_id: Number(data) })}
                options={[{ text: '指定なし', value: -1 }]
                  .concat(pulldown(supportedEmployeeList))}
              />
            </div>
          </div>
          <div className="item_wrap tags_form">
            <div className="item_body item_checkbox">
              <RightLabelCheckbox
                checked={sort?.support_flag === 1}
                label="対応済みフラグ"
                onChange={() => {
                  setState({ support_flag: !sort?.support_flag ? 1 : 0 });
                }}
                className="single_column"
              />
            </div>
          </div>
        </div>
        <div className="category_wrap">
          <div className="item_wrap">
            <div className="item_label">登録者</div>
            <div className="item_body item_madori">
              <Select
                defaultLabel="全て"
                className="fixed"
                value={sort.created_employee_id}
                onChange={(v) => setState({ created_employee_id: Number(v) })}
                options={pulldown(employeeList)}
              />
            </div>
          </div>
          <div className="item_wrap">
            <div className="item_label">登録日</div>
            <div className="item_body item_schedule wrap">
              <div className="item_schedule__form">
                <DatePicker
                  date={sort.created_at_start}
                  onChange={(v) => setState({ created_at_start: v })}
                  type="YYYY/MM/DD"
                  term={DateTermValidation([
                    { start: sort.created_at_start, end: sort.created_at_end },
                  ])}
                />
              </div>
              <div className="item_schedule__form flex_box flex_align_center mt_10">
                <div className="item_schedule__tilde">〜</div>
                <DatePicker
                  date={sort.created_at_end}
                  onChange={(v) => setState({ created_at_end: v })}
                  type="YYYY/MM/DD"
                  term={DateTermValidation([
                    { start: sort.created_at_start, end: sort.created_at_end },
                  ])}
                />
              </div>
            </div>
          </div>
          <div className="item_wrap">
            <div className="item_label">更新者</div>
            <div className="item_body item_madori">
              <Select
                defaultLabel="全て"
                className="fixed"
                value={sort.updated_employee_id}
                onChange={(v) => setState({ updated_employee_id: Number(v) })}
                options={pulldown(employeeList)}
              />
            </div>
          </div>
          <div className="item_wrap">
            <div className="item_label">更新日</div>
            <div className="item_body item_schedule wrap">
              <div className="item_schedule__form">
                <DatePicker
                  date={sort.updated_at_start}
                  onChange={(v) => setState({ updated_at_start: v })}
                  type="YYYY/MM/DD"
                  term={DateTermValidation([
                    { start: sort.updated_at_start, end: sort.updated_at_end },
                  ])}
                />
              </div>
              <div className="item_schedule__form flex_box flex_align_center mt_10">
                <div className="item_schedule__tilde">〜</div>
                <DatePicker
                  date={sort.updated_at_end}
                  onChange={(v) => setState({ updated_at_end: v })}
                  type="YYYY/MM/DD"
                  term={DateTermValidation([
                    { start: sort.updated_at_start, end: sort.updated_at_end },
                  ])}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </SearchBox>
  );
};
