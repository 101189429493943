import { cloneDeep } from 'lodash';
import { Bill, BillListType } from '../../type/bill/bill.type';
import { BillCollection } from '../../collection/bill/bill.collection';
import { OrderEditState } from '../../type/order/order.type';
import { MathHelper } from '../../utilities/math-helper';
import { Project, ProjectListType } from '../../type/project/project.type';
import { EstimateListType, Estimate } from '../../type/estimate/estimate.type';
import { ApiOrderPostParam } from '../../redux/order/api/order/api-order.type';
import { DateFormatter } from '../../utilities/date-formatter';
import { DisplayElements } from '../../type/display-elements.type';

const df = (v: Date | null | string | undefined) => DateFormatter.date2str(v, 'YYYYMMDD', '-');

type BillList = {
  billing_item_id_array: number[];
  /** 請求項目名称 */
  billing_item_name_array: string[];
  /** 請求日 */
  billing_date_array: string[];
  /** 請求金額 */
  billing_amount_array: number[];
  /** 入金予定日 */
  payment_due_date_array: string[];
  /** 請求ID */
  billing_id_array: number[];
}

export class OrderModel {
  public bill: BillListType[] = [];

  public billList: BillList = {
    billing_item_id_array: [1, 2, 3, 4, 5],
    billing_item_name_array: ['契約金', '着工金', '中間金', '中間金', '完工金'],
    billing_date_array: ['', '', '', '', ''],
    billing_amount_array: [NaN, NaN, NaN, NaN, NaN],
    payment_due_date_array: ['', '', '', '', ''],
    billing_id_array: [NaN, NaN, NaN, NaN, NaN],
  };

  private jutyu_kin: number = 0;

  constructor(billList?: BillListType[], jutyu_kin?: number) {
    if (jutyu_kin) {
      this.jutyu_kin = jutyu_kin;
    }
    if (billList) {
      this.bill = cloneDeep(billList);
      this.createBillList();
    }
  }

  /* 請求リストの作成 */
  public createBillList = () => {
    if (this.bill.length) {
      this.createUpdateBillList();
    } else {
      this.createRegisterBillList();
    }
  };

  /* 請求リストの作成(更新時) */
  private createUpdateBillList = () => {
    BillCollection.itemList.forEach((v, i) => {
      const _bill = this.bill.find((v2) => v2.billing_items_id === v.value);
      this.billList.billing_date_array[i] = (
        _bill ? _bill.billing_date ?? '' : ''
      );
      this.billList.billing_amount_array[i] = (
        _bill ? Number(_bill.billing_amount) ?? NaN : NaN
      );
      this.billList.payment_due_date_array[i] = (
        _bill ? _bill.payment_due_date ?? '' : ''
      );
      this.billList.billing_id_array[i] = (
        _bill ? Number(_bill.id) ?? NaN : NaN
      );
    });
  };

  /* 請求リストの作成(登録時) */
  private createRegisterBillList = () => {
    const all = Number(this.jutyu_kin) ?? 0;
    this.billList.billing_amount_array[4] = cloneDeep(all);
    this.billList.billing_id_array = [];
  };

  /** 未割り当て金の計算 */
  public calcMiwariatekin(_order: OrderEditState | null) {
    const order = cloneDeep(_order);
    return MathHelper.minus(
      order?.order_price || 0,
      MathHelper.plus(
        this.billList.billing_amount_array[0] || 0,
        this.billList.billing_amount_array[1] || 0,
        this.billList.billing_amount_array[2] || 0,
        this.billList.billing_amount_array[3] || 0,
        this.billList.billing_amount_array[4] || 0,
      ),
    );
  }

  /* 未割当金を完工金に含める */
  public mergeMiwariatekinInKankokin(miwariatekin: number) {
    const miwariate = Number.isNaN(miwariatekin) ? 0 : miwariatekin;
    this.billList.billing_amount_array[4] = MathHelper.plus(
      miwariate, this.billList.billing_amount_array[4],
    );
    return cloneDeep(this);
  }

  /** 案件情報の適用 */
  static setProjectData(v: Project, noAuth?: boolean): Partial<OrderEditState> {
    const result = {
      completion_end_date: DateFormatter.str2date(v.completion_end_date),
      construction_period_end: v.construction_period_end,
      construction_period_start: v.construction_period_start,
      construction_start_date: DateFormatter.str2date(v.construction_start_date),
      contract_date: v.contract_date ? DateFormatter.str2date(v.contract_date) : new Date(),
      customer_id: v.customer_id,
      jyutyu_kin_1: v.jyutyu_kin_1,
      jyutyu_kin_2: v.jyutyu_kin_2,
      jyutyu_kin_all: v.jyutyu_kin_all,
      jyutyu_sorieki: v.jyutyu_sorieki,
      project_id: v.id,
      remarks: v.remarks,
      saisyu_sorieki: v.saisyu_sorieki,
    };
    if (noAuth) {
      Object.assign(result, {
        order_price: v.order_price,
        jyutyu_genka: v.jyutyu_genka,
      });
    }
    return result;
  }

  /** 見積情報の適用 */
  static setEstimateData(v: EstimateListType | null): Partial<OrderEditState> {
    if (!v) return {};
    return ({
      quote_id: v.id,
      customer_id: v.customer_id,
      project_id: v.project_id,
      jyutyu_genka: v.gokei_genka_kin,
      order_price: v.gokei_kin,
      construction_period_end: v.estimate_kouki_end_dt,
      construction_period_start: v.estimate_kouki_start_dt,
    });
  }

  /* 最新見積の取得 */
  static setLatestEstimateData(v: EstimateListType[]): EstimateListType | null {
    const _estimate = v.find((v2) => v2.order_flag);
    if (_estimate) {
      return _estimate;
    }

    const _new = v.find(
      (v2) => v2.id === Math.max(
        ...v.map((v3) => v3.id),
      ),
    );
    return _new || null;
  }

  /** 受注保存 */
  static postParam(param: {
    order: OrderEditState;
    billListModel: OrderModel;
  }): ApiOrderPostParam {
    const { order, billListModel } = param;
    const billing_item_id_array = billListModel.billList.billing_item_id_array.map((v) => (Number.isNaN(v) || v === 0 ? '' : String(v)));
    const billing_amount_array = billListModel.billList.billing_amount_array.map((v) => (Number.isNaN(v) || v === 0 ? '0' : String(v)));
    const billing_id_array = billListModel.billList.billing_id_array.map((v) => (Number.isNaN(v) || v === 0 ? '' : String(v)));
    return ({
      project_id: order.project_id,
      quote_id: order.quote_id,
      customer_id: order.customer_id,
      construction_period_start: df(order.construction_period_start),
      construction_period_end: df(order.construction_period_end),
      construction_start_date: df(order.construction_start_date),
      completion_end_date: df(order.completion_end_date),
      contract_date: df(order.contract_date),
      order_price: order.order_price,
      jyutyu_kin_1: 0,
      jyutyu_kin_2: 0,
      jyutyu_kin_all: order.order_price,
      jyutyu_genka: order.jyutyu_genka,
      jyutyu_sorieki: MathHelper.minus((order.order_price || 0), (order.jyutyu_genka || 0)),
      saisyu_sorieki: order.order_price || 0,
      construction_execution_date: order.construction_execution_date,
      completion_execution_date: order.completion_execution_date,
      saisyu_meisai: order.saisyu_meisai,
      remarks: order.remarks,
      billing_item_id_array,
      billing_item_name_array: billListModel.billList.billing_item_name_array,
      billing_date_array: billListModel.billList.billing_date_array,
      billing_amount_array,
      payment_due_date_array: billListModel.billList.payment_due_date_array,
      billing_id_array: billing_id_array.length ? billing_id_array : undefined,
    });
  }

  /* 受注工期から工期から取得 */
  static getJutyuKoki(param: {
    order: OrderEditState;
    selectEstimate: EstimateListType;
  }): Partial<OrderEditState> {
    const { order, selectEstimate } = param;
    return ({
      construction_start_date: selectEstimate.estimate_kouki_start_dt
        ? new Date(selectEstimate.estimate_kouki_start_dt) : order.construction_start_date,
      completion_end_date: selectEstimate.estimate_kouki_end_dt
        ? new Date(selectEstimate.estimate_kouki_end_dt) : order.completion_end_date,
    });
  }

  /* 受注登録内 見積一覧ソート */
  static tableSort(param: {
    _list: DisplayElements[][],
    highlow: number,
    sort: number,
  }): DisplayElements[][] {
    let nullValue: string | number = 0;
    const { _list, highlow, sort } = param;
    const list = cloneDeep(_list);
    list.sort((a, b) => {
      let _a = a[sort || 1];
      let _b = b[sort || 1];
      if (!(typeof _a === 'string' || typeof _a !== 'number')) _a = '';
      if (!(typeof _b === 'string' || typeof _b !== 'number')) _b = '';

      nullValue = typeof a[sort] === 'string' ? '' : 0;
      if (typeof _a === 'string' && typeof _b === 'string') {
        if (_a.match(/,/) || _b.match(/,/)) {
          return (
            ((MathHelper.localStrToNum(_a) || nullValue)
              < (MathHelper.localStrToNum(_b) || nullValue)) ? 1 : -1);
        }
        if (_a.match(/%/) && _b.match(/%/)) {
          return ((Number(_a.replace('%', '')) || nullValue) < (Number(_b.replace('%', '')) || nullValue)) ? 1 : -1;
        }
      }
      return ((_a || nullValue) < (_b || nullValue)) ? 1 : -1;
    });
    return highlow ? list.reverse() : list;
  }
}
