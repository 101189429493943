import { deleteComma } from '../../utilities/delete-comma';
import { Validation } from './validation';
import { deleteHyphen } from '../../utilities/delete-str';

export const ValidationMax100Million: Validation[] = [
  new Validation(
    (v) => Number(deleteComma(deleteHyphen(v))) <= 99999999.99,
    `${99999999.99}以下で入力してください。`,
  ),
];
