import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useMemo, useState } from 'react';
import { MasterBody, MasterGetListParam } from './body/master-body';
import { MasterActions } from '../../../../../redux/master/master.action';
import { DialogActions } from '../../../../../redux/dialog/dialog.action';
import { MasterCollection } from '../master.collection';
import { PartMasterEditDialog } from '../edit-dialogs/relevant-tag/part-master-edit-dialog/part-master-edit-dialog';
import { State } from '../../../../../redux/root.reducer';

export const MasterPartBody = () => {
  /* Hook */
  const list = useSelector((state:State) => state.master.partList);
  const dispatch = useDispatch();

  /* State */
  const [isSort, setIsSort] = useState(false);
  const [isMuko, setIsMuko] = useState(false);
  const [orderSort, setOrderSort] = useState<MasterGetListParam>({ highlow: 0, sort_by: 0 });

  /* Callback */
  const getList = useCallback((v: MasterGetListParam) => {
    const sort_by = v.sort_by ? v.sort_by - 1 : 0;
    dispatch(MasterActions.api.part.getList({ sort_by, highlow: v.highlow, is_muko: 1 }));
    setOrderSort(v);
  }, []);

  // eslint-disable-next-line
  const openEditDialog = useCallback((id?: number) => {
    dispatch(DialogActions.push({
      title: '部位 編集／追加',
      className: 'fix_width_500_dialog',
      element: <PartMasterEditDialog id={id} callback={() => getList(orderSort)} />,
    }));
  }, [getList, orderSort]);

  const tableList = useMemo(() => {
    const filter = (isMuko || isSort) ? list : list.filter((v) => v.valid_flag);
    return filter.map((v) => ([
      v.order,
      v.internal_id,
      v.name,
      v.valid_flag ? <i className="far fa-circle" /> : '',
    ]));
  }, [list, isMuko, isSort]);

  return (
    <MasterBody
      header={MasterCollection.partMasterHeader}
      rowDataList={list}
      list={tableList}
      callbackEdit={openEditDialog}
      callbackGetList={getList}
      callbackIsMuko={setIsMuko}
      callbackIsSort={setIsSort}
      sortIndex={16}
      defaultOrder={0}
      tableOption={{
        stringWidth: [
          { index: 0, width: 50 }, // 編集
          // { index: 1, width: 50 }, // 表示順
          // { index: 2, width: 50 }, // ID
          // { index: 3, width: 50 }, // 部位名称
          // { index: 4, width: 100 }, // 有効フラグ
        ],
        tdAlign: [
          { index: 0, align: 'center' },
          { index: 1, align: 'center' },
          { index: 2, align: 'center' },
          { index: 3, align: 'left' },
          { index: 4, align: 'center' },
        ],
      }}
    />
  );
};
