import { useDispatch, useSelector } from 'react-redux';
import { cloneDeep, isEqual } from 'lodash';
import { useState, useCallback } from 'react';
import { Button } from '../../../../../ui/button/button';
import { DialogActions } from '../../../../../../redux/dialog/dialog.action';
import { EstimateSortState } from '../../../../../../type/estimate/estimate.type';
import { State } from '../../../../../../redux/root.reducer';
import { ProjectDetailActions } from '../../../../../../redux/project-detail/project-detail.action';
import { Input } from '../../../../../ui/input/input';
import { DatePicker } from '../../../../../ui/date-picker/date-picker';
import { EstimateSortStateInProject } from '../../../../../../redux/estimate/api/estimate/api-estimate.type';
import { Select } from '../../../../../ui/select/select';
import { useDidMount } from '../../../../../../hooks/life-cycle';
import { MasterActions } from '../../../../../../redux/master/master.action';
import { useDateValidation } from '../../../../../../hooks/use-date-validation';
import { DateTermValidation } from '../../../../../../model/validation/date/term-date.validation';

type Props = {
  callback: (sort:EstimateSortStateInProject) => void;
}

export const RefindEstimateTable = (props:Props) => {
  const { callback } = props;
  const dispatch = useDispatch();
  const sort = useSelector((state: State) => state.projectDetail.estimateSort, isEqual);
  const employeeList = useSelector((state: State) => state.master.employeeList, isEqual);

  const [sortState, setSortState] = useState(cloneDeep(sort));

  const termDate = useDateValidation([{
    start: sortState.estimate_kouki_start_dt || null, end: sortState.estimate_kouki_end_dt || null,
  }]);

  /* Callback */
  const handleClickSearch = useCallback(() => {
    if (termDate.messages.length) {
      termDate.check();
      return;
    }
    dispatch(DialogActions.pop());
    callback(sortState);
    dispatch(ProjectDetailActions.setEstimateSort(cloneDeep(sortState)));
  }, [callback, sortState, termDate]);

  // eslint-disable-next-line
  const setState = useCallback((v: EstimateSortStateInProject) => {
    setSortState(cloneDeep(v));
  }, [dispatch]);

  useDidMount(() => {
    dispatch(MasterActions.api.employee.getList({}));
  });

  return (
    <div className="refind_wrap">
      <div className="refind_body">
        <div className="item_wrap">
          <div className="item_box">
            <div className="item_head">見積番号</div>
            <Input
              className=""
              value={sortState.estimate_no}
              onChange={(e) => setState({
                ...sortState, estimate_no: e.target.value,
              })}
            />
          </div>
          <div className="item_box">
            <div className="item_head">見積日</div>
            <DatePicker
              date={sortState.estimate_dt || null}
              onChange={(v) => setState(
                { ...sortState, estimate_dt: v },
              )}
            />
          </div>
          <div className="item_box">
            <div className="item_head">見積作成者</div>
            <Select
              className="add_text_left"
              value={sortState.created_employee_id}
              onChange={(data) => setState({ ...sort, created_employee_id: Number(data) })}
              defaultLabel="全て"
              options={employeeList.map((v) => ({
                text: v.name, value: v.id,
              }))}
            />
          </div>
        </div>
        <div className="item_wrap">
          <div className="item_box">
            <div className="item_head">見積金額</div>
            <Input
              className=""
              type="number"
              value={sortState.gokei_zeinuki_kin}
              onChange={(e) => {
                console.log(e.target.value);

                setState({
                  ...sortState, gokei_zeinuki_kin: Number(e.target.value || NaN),
                });
              }}
            />
          </div>
        </div>
        <div className="item_wrap">
          <div className="item_box">
            <div className="item_head">消費税額（見積）</div>
            <Input
              className=""
              type="number"
              value={sortState.shohizei_kin}
              onChange={(e) => setState({
                ...sortState, shohizei_kin: Number(e.target.value || NaN),
              })}
            />
          </div>
          <div className="item_box">
            <div className="item_head">税込合計見積</div>
            <Input
              className=""
              type="number"
              value={sortState.gokei_kin}
              onChange={(e) => setState({
                ...sortState, gokei_kin: Number(e.target.value || NaN),
              })}
            />
          </div>
          <div className="item_box">
            <div className="item_head">原価合計</div>
            <Input
              className=""
              type="number"
              value={sortState.gokei_zeinuki_genka_kin}
              onChange={(e) => setState({
                ...sortState, gokei_zeinuki_genka_kin: Number(e.target.value || NaN),
              })}
            />
          </div>
        </div>
        <div className="item_wrap">
          <div className="item_box">
            <div className="item_head">消費税額（原価）</div>
            <Input
              className=""
              type="number"
              value={sortState.genka_shohizei_kin}
              onChange={(e) => setState({
                ...sortState, genka_shohizei_kin: Number(e.target.value || NaN),
              })}
            />
          </div>
          <div className="item_box">
            <div className="item_head">税込合計原価</div>
            <Input
              className=""
              type="number"
              value={sortState.gokei_genka_kin}
              onChange={(e) => setState({
                ...sortState, gokei_genka_kin: Number(e.target.value || NaN),
              })}
            />
          </div>
          <div className="item_box">
            <div className="item_head">調整額</div>
            <Input
              className=""
              type="number"
              value={sortState.tyosei_kin}
              onChange={(e) => setState({
                ...sortState, tyosei_kin: Number(e.target.value || NaN),
              })}
            />
          </div>
        </div>
        <div className="item_wrap">
          <div className="item_box">
            <div className="item_head">見積工期</div>
            <DatePicker
              date={sortState.estimate_kouki_start_dt || null}
              onChange={(v) => setState(
                { ...sortState, estimate_kouki_start_dt: v },
              )}
              term={DateTermValidation([{
                start: sortState.estimate_kouki_start_dt || null,
                end: sortState.estimate_kouki_end_dt || null,
              }])}
            />
            <label className="ml_5 mr_5">〜</label>
            <DatePicker
              date={sortState.estimate_kouki_end_dt || null}
              onChange={(v) => setState(
                { ...sortState, estimate_kouki_end_dt: v },
              )}
              term={DateTermValidation([{
                start: sortState.estimate_kouki_start_dt || null,
                end: sortState.estimate_kouki_end_dt || null,
              }])}
            />
          </div>
        </div>
      </div>
      <footer className="base_footer refind_footer">
        <Button
          size="md"
          color="primary"
          onClick={handleClickSearch}
        >絞込み
        </Button>
        <Button
          size="md"
          color="dark"
          onClick={() => dispatch(DialogActions.pop())}
        >キャンセル
        </Button>
      </footer>
    </div>
  );
};
