import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { CsvCustomerSort } from '../../type/csv/csv-sort.type';
import { CsvActions } from '../../redux/csv/csv.action';
import { useAppSelector } from '../use-redux';
import { CsvModel } from '../../model/csv/csv-model';

type Props = {
  type: number;
}

export const useCsvEx = () => {
  console.log();
  const dispatch = useDispatch();
  const {
    customerSort,
    birthdaySort,
    weddingSort,
    projectSort,
    orderSort,
    nonOrderSort,
    lostOrderSort,
    maintenanceSort,
    customerRankSort,
    supportHistorySort,
  } = useAppSelector((v) => v.csv);
  return useCallback((props: Props) => {
    const { type } = props;
    if (!type) dispatch(CsvActions.api.customer.download(CsvModel.customerEx(customerSort)));
    if (type === 1) dispatch(CsvActions.api.birthday.download(birthdaySort));
    if (type === 2) dispatch(CsvActions.api.wedding.download(weddingSort));
    if (type === 3) dispatch(CsvActions.api.project.download(CsvModel.projectEx(projectSort)));
    if (type === 4) dispatch(CsvActions.api.order.download(CsvModel.orderEx(orderSort)));
    if (type === 5) dispatch(CsvActions.api.nonOrder.download(CsvModel.nonOrderEx(nonOrderSort)));
    if (type === 6) {
      dispatch(CsvActions.api.lostOrder.download(
        CsvModel.lostOrderEx(lostOrderSort),
      ));
    }
    if (type === 7) {
      dispatch(CsvActions.api.maintenance.download(
        CsvModel.maintenance(maintenanceSort),
      ));
    }
    if (type === 8) {
      dispatch(CsvActions.api.customerRank.download(
        CsvModel.customerRank(customerRankSort),
      ));
    }
    if (type === 9) {
      dispatch(
        CsvActions.api.supportHistory.download(CsvModel.support(supportHistorySort)),
      );
    }
  }, [
    customerSort,
    birthdaySort,
    weddingSort,
    projectSort,
    orderSort,
    nonOrderSort,
    maintenanceSort,
    customerRankSort,
    supportHistorySort,

  ]);
};

// CsvModel.customerEx(customerSort)
