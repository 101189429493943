/* eslint-disable no-nested-ternary */
import { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { prefectures } from '../../../../../../collection/prefectures';
import { DialogActions } from '../../../../../../redux/dialog/dialog.action';
import { MasterEditDialogProps } from '../../master.type';
import { Input } from '../../../../../ui/input/input';
import { Required } from '../../../../../ui/required/required';
import { Checkbox } from '../../../../../ui/checkbox/checkbox';
import { LeftLabelInputField } from '../../../../../ui/input-field/left-label-input-field/left-label-input-field';
import { Button } from '../../../../../ui/button/button';
import { Select } from '../../../../../ui/select/select';
import { Radio } from '../../../../../ui/radio/radio';
import './contracted-company-master-edit-dialog.scss';
import { MasterContractedCompanyValidation } from '../../../../../../model/validation/master/master-contracted-company.validation';
import { MasterActions } from '../../../../../../redux/master/master.action';
import { EditPC } from '../../../../../dialogs/edit/edit.pc';
import {
  ValidationLengthUnder255, ValidationPostNum1, ValidationPostNum2,
} from '../../../../../../model/validation';
import { ValidationMailAddress } from '../../../../../../model/validation/validation-mail-address';
import { MapActions } from '../../../../../../redux/map/map.action';
import { useDidMount } from '../../../../../../hooks/life-cycle';
import { changeString } from '../../../../../../utilities/change-string';
import { getPrefectureByNumber } from '../../../../../../utilities/getPrefecture';
import { useAppSelector } from '../../../../../../hooks/use-redux';
import { InputTel } from '../../../../../ui/input/input-tel';
import { Message } from '../../../../../../collection/message.collection';
import { FileSize } from '../../../project-detail/file-table/file-size';
import { MathHelper } from '../../../../../../utilities/math-helper';
import { InputNum } from '../../../../../ui/input/input-num';
import { ValidationLengthUnder3 } from '../../../../../../model/validation/_validation-length-under-3';
import { UserActions } from '../../../../../../redux/user/user.action';
import { ValidationPassword } from '../../../../../../model/validation/validation-password';
import { InputPostNoMulti } from '../../../../../ui/input/input-post-no-multi';
import { ValidationFileSize } from '../../../../../../model/validation/_validation-file-max';
import { Validation } from '../../../../../../model/validation/validation';
import { ValidationAccounts } from '../../../../../../model/validation/_validation-accounts';

export const ContractedCompanyMasterEditDialog = (props: MasterEditDialogProps) => {
  const { id, callback } = props;

  const dispatch = useDispatch();

  const dataList = useAppSelector((state) => state.master.contractedCompanyList);

  const [name, setName] = useState('');
  const [mailAddress, setMailAddress] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [telNo, setTelNo] = useState('');
  const [postalCode1, setPostalCode1] = useState('');
  const [postalCode2, setPostalCode2] = useState('');
  const [prefecture, setPrefecture] = useState('');
  const [prefectureId, setPrefectureId] = useState(NaN);
  const [city, setCity] = useState('');
  const [address, setAddress] = useState('');
  const [buildingName, setBuildingName] = useState('');
  const [status, setStatus] = useState<'無償' | '有償'>('無償');
  const [accounts, setAccounts] = useState('10');
  const [validFlag, setValidFlag] = useState(true);
  const [isValid1, setIsValid1] = useState(false);
  const [isValid2, setIsValid2] = useState(false);
  const [isValid3, setIsValid3] = useState(false);
  const [isValid4, setIsValid4] = useState(false);
  const [isValid5, setIsValid5] = useState(false);
  const [isValid6, setIsValid6] = useState(false);
  const [capacity, setCapacity] = useState('2');
  const [capaTouch, setCapaTouch] = useState(false);
  const [accountsTouch, setAccountsTouch] = useState(false);
  const [accountsCount, setAccountsCount] = useState<null | number>(null);

  const isPaid = useMemo(() => status === '有償', [status]);
  // const [barCapacity, setBarCapacity] = useState(1024);
  const [_totalSize, setTotalSize] = useState(0);

  /* Memo */
  const barCapacity = useMemo(() => (
    MathHelper.times(Number(capacity), 1024, 1024, 1024)
  ), [capacity]);

  const totalSize = useMemo(() => {
    const kb = MathHelper.div(_totalSize, 1024);
    if (kb > 1024) {
      const mb = MathHelper.div(kb, 1024);
      return `${MathHelper.rounding(mb, 2, 'ceil')}MB`;
    }
    return `${MathHelper.rounding(kb, 2, 'ceil')}KB`;
  }, [_totalSize]);

  const percent = useMemo(() => {
    const per = (_totalSize <= 0) ? 0 : MathHelper.rounding(
      MathHelper.times(
        MathHelper.div(_totalSize, Number(barCapacity)),
        100,
      ),
      0,
      'ceil',
    );
    return per;
  }, [_totalSize, barCapacity, status]);

  const isAlert = useMemo(() => percent > 100, [percent, status]);

  const [touch, setTouch] = useState(false);

  const isEdit = useMemo(() => {
    if (id) {
      const data = dataList.find((v) => v.id === id);
      if (!data) return false;
      if (data.employee_status !== 1) {
        return true;
      }
      return false;
    }
    return false;
  }, []);

  const handleClickSearchAddress = useCallback(
    () => {
      dispatch(MapActions.api.addressSearch({
        param: {
          zipcode1: String(postalCode1),
          zipcode2: String(postalCode2),
        },
        callback: (searchedAddress) => {
          setPrefecture(getPrefectureByNumber(Number(searchedAddress.prefcode)));
          setPrefectureId(Number(searchedAddress.prefcode));
          setCity(searchedAddress.ja.address1);
          setAddress(searchedAddress.ja.address2);
          setBuildingName('');
        },
      }));
    },
    [postalCode1, postalCode2],
  );

  const handleClickPost = useCallback(() => {
    if (password !== confirmPassword) {
      dispatch(DialogActions.pushMessage({
        title: '契約会社 編集／追加',
        message: ['パスワードが一致しません'],
        callback: () => setTouch(true),
      }));
      return;
    }

    if (MasterContractedCompanyValidation(
      name,
      mailAddress,
      password,
      confirmPassword,
      telNo,
      postalCode1,
      postalCode2,
      city,
      address,
      buildingName,
      accounts,
      capacity,
    )) {
      dispatch(DialogActions.pushMessage({
        title: '契約会社 編集／追加',
        message: Message.postError,
        callback: () => setTouch(true),
      }));
      return;
    }

    dispatch(MasterActions.api.contractedCompany.post({
      param: {
        data: {
          name,
          mail_address: mailAddress,
          password,
          confirm_password: confirmPassword,
          tel_no: telNo,
          post_no: postalCode1 && postalCode2 ? `${postalCode1}${postalCode2}` : '',
          prefecture: prefectureId,
          city,
          address,
          building_name: buildingName,
          status: status === '有償' ? 1 : 2,
          accounts: Number(accounts),
          capacity: Number(capacity),
          is_valid: validFlag ? 1 : 0,
          authority1: !isPaid ? 0 : (isValid1 ? 1 : 0),
          authority2: !isPaid ? 0 : (isValid2 ? 1 : 0),
          authority3: !isPaid ? 0 : (isValid3 ? 1 : 0),
          authority4: !isPaid ? 0 : (isValid4 ? 1 : 0),
          authority5: !isPaid ? 0 : (isValid5 ? 1 : 0),
          authority6: !isPaid ? 0 : (isValid6 ? 1 : 0),
        },
        id,
      },
      onSuccess: () => {
        callback();
        dispatch(UserActions.getUser({
          callback: () => {},
        }));
        dispatch(DialogActions.pop());
      },
      onError: () => {
        setTouch(true);
      },
    }));
  }, [
    name,
    mailAddress,
    password,
    confirmPassword,
    telNo,
    postalCode1,
    postalCode2,
    prefecture,
    city,
    capacity,
    address,
    buildingName,
    status,
    accounts,
    validFlag,
    isValid1,
    isValid2,
    isValid3,
    isValid4,
    isValid5,
    isValid6,
    isPaid,
    callback,
  ]);

  useDidMount(() => {
    if (id) {
      const data = dataList.find((v) => v.id === id);
      if (!data) return;
      setName(data.name);
      setMailAddress(data.mail_address);
      setTelNo(data.tel_no);
      setPostalCode1(changeString(data.post_no, '').slice(0, 3));
      setPostalCode2(changeString(data.post_no, '').slice(3, 7));
      setPrefecture(data.prefecture);
      setPrefectureId(data.prefecture_id);
      setCapacity(String(MathHelper.div(Number(data.capacity), 1024, 1024, 1024)));
      // setBarCapacity(data.capacity);
      setTotalSize(data.total_file_size);
      setCity(data.city);
      setAddress(data.address);
      setBuildingName(data.building_name);
      setStatus(data.status === 1 ? '有償' : '無償');
      setAccounts(String(data.accounts));
      setValidFlag(data.valid_flag);
      setIsValid1(Boolean(data.authority1));
      setIsValid2(Boolean(data.authority2));
      setIsValid3(Boolean(data.authority3));
      setIsValid4(Boolean(data.authority4));
      setIsValid5(Boolean(data.authority5));
      setIsValid6(Boolean(data.authority6));
      setAccountsCount(data.employee_count ?? 10);
    }
  });
  return (
    <EditPC mode="dialog" callback={handleClickPost} label={id ? '更新' : '登録'}>
      <div className="edit_pc_body_inner edit_master">
        <div>
          <div className="item_wrap">
            <div className="item_box">
              <div className="item_head">会社名<Required /></div>
              <Input
                className="medium"
                errorPosBottom
                require
                value={name}
                onChange={(e) => setName(e.target.value)}
                validationList={ValidationLengthUnder255}
                touch={touch}
              />
            </div>
          </div>
          <div className="item_wrap">
            <div className="item_box max_width">
              <div className="item_head">メールアドレス<Required /></div>
              <Input
                className="large"
                value={mailAddress}
                type="email"
                onChange={(e) => setMailAddress(e.target.value)}
                validationList={ValidationMailAddress}
                require
                touch={touch}
              />
            </div>
          </div>
          {isEdit && (
            <>
              <div className="item_wrap">
                <div className="item_box">
                  <div className="item_head">パスワード</div>
                  <div className="password">
                    <Input
                      className="mt_10"
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      validationList={ValidationPassword}
                    />
                  </div>
                </div>
              </div>
              <div className="item_wrap">
                <div className="item_box">
                  <div className="item_head">確認パスワード</div>
                  <div className="password">
                    <Input
                      className="mt_10"
                      type="password"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      validationList={ValidationPassword}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
          <div className="item_wrap">
            <div className="item_box">
              <div className="item_head">電話番号</div>
              <InputTel
                className="tel_no"
                value={telNo}
                onChange={setTelNo}
                touch={touch}
              />
            </div>
          </div>
          <div className="item_wrap">
            <div className="item_box max_width">
              <div className="item_head">住所</div>
              <div>
                <div className="item_postal">
                  <InputPostNoMulti
                    value={postalCode1}
                    touch={touch}
                    // require
                    onChange={(v) => setPostalCode1(v)}
                    value2={postalCode2}
                    touch2={touch}
                    // require2
                    onChange2={(v) => setPostalCode2(v)}
                  />
                  <Button
                    size="sm"
                    color="secondary"
                    className="ml_10"
                    onClick={handleClickSearchAddress}
                  >住所入力
                  </Button>
                </div>
                <div className="item_adress">
                  <Select
                    className="add_text_left mt_10"
                    label="都道府県"
                    value={prefectureId ?? NaN}
                    onChange={(v) => {
                      setPrefectureId(Number(v));
                      setPrefecture(getPrefectureByNumber(Number(v)));
                    }}
                    defaultLabel=""
                    options={[
                      ...prefectures.map((v) => ({
                        text: v.label, value: v.value,
                      })),
                    ]}
                  />
                  <LeftLabelInputField
                    className="city"
                    label="市区町村"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                    validationList={ValidationLengthUnder255}
                    touch={touch}
                  />
                  <LeftLabelInputField
                    className="address"
                    label="地名・番地"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    validationList={ValidationLengthUnder255}
                    touch={touch}
                  />
                  <LeftLabelInputField
                    className="building_name"
                    label="建物名"
                    value={buildingName}
                    onChange={(e) => setBuildingName(e.target.value)}
                    validationList={ValidationLengthUnder255}
                    touch={touch}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="item_wrap">
            <div className="item_box">
              <div className="item_head">設定</div>
              <Radio
                label="無償"
                name="bank_account"
                checked={status === '無償'}
                onChange={() => {
                  setCapaTouch(true);
                  setAccountsTouch(true);
                  setStatus('無償');
                  setAccounts('10');
                  setCapacity('2');
                }}
              />
              <Radio
                label="有償"
                name="bank_account"
                checked={status === '有償'}
                onChange={() => {
                  setCapaTouch(true);
                  setAccountsTouch(true);
                  setStatus('有償');
                  setAccounts('10');
                  setCapacity('2');
                }}
              />
              <div className="valid_box">
                <Checkbox
                  label="顧客一覧 ストリートビュー画像表示"
                  className=""
                  disabled={!isPaid}
                  checked={isValid1 && isPaid}
                  onChange={() => setIsValid1(!isValid1)}
                />
                <Checkbox
                  label="案件一覧 ストリートビュー画像表示"
                  className=""
                  checked={isValid2 && isPaid}
                  disabled={!isPaid}
                  onChange={() => setIsValid2(!isValid2)}
                />
                <Checkbox
                  label="顧客一覧 ルート検索"
                  className=""
                  checked={isValid3 && isPaid}
                  disabled={!isPaid}
                  onChange={() => setIsValid3(!isValid3)}
                />
                <Checkbox
                  label="顧客ランク 顧客ランク自動更新"
                  className=""
                  checked={isValid4 && isPaid}
                  disabled={!isPaid}
                  onChange={() => setIsValid4(!isValid4)}
                />
                <Checkbox
                  label="顧客ランク 顧客ランク自動更新ログ"
                  className=""
                  checked={isValid5 && isPaid}
                  disabled={!isPaid}
                  onChange={() => setIsValid5(!isValid5)}
                />
                <Checkbox
                  label="⾒積機能"
                  className=""
                  checked={isValid6 && isPaid}
                  disabled={!isPaid}
                  onChange={() => setIsValid6(!isValid6)}
                />
              </div>
            </div>
          </div>
          <div className="item_wrap">
            <div className="item_box">
              <div className="item_head">アカウント数</div>
              <InputNum
                className="small"
                value={String(accounts)}
                onChange={(status === '無償') ? () => setAccounts('10') : setAccounts}
                touch={accountsTouch}
                validationList={[
                  ...ValidationLengthUnder3,
                  ...ValidationAccounts(accountsCount, Number(accounts || 0)),
                ]}
                intLen={3}
                disabled={(status === '無償')}
              />
            </div>
          </div>
          <div className="item_wrap">
            <div className="item_box">
              <div className="item_head">ファイル容量</div>
              <div className="flex_box" style={{ flexDirection: 'column' }}>
                <div className="flex_box">
                  <InputNum
                    className="small"
                    value={capacity}
                    onChange={(status === '無償') ? () => setCapacity('2') : setCapacity}
                    onBlurCapture={(e) => {
                      setCapaTouch(false);
                      if ((e === '') && (status === '有償')) {
                        setCapacity('1');
                      }
                    }}
                    touch={capaTouch}
                    validationList={[...ValidationFileSize(percent)]}
                    intLen={3}
                    disabled={(status === '無償')}
                  />
                  <label className="RightLabelInputField_pc__label">GB</label>
                </div>
                {(id !== undefined) && (
                <div className="file_info mt_10">
                  <FileSize
                    totalSize={totalSize}
                    percent={percent}
                    maxSize={Number(capacity)}
                    isNoChangeMax
                  />
                </div>
                )}
              </div>
            </div>
          </div>
          <div className="item_wrap">
            <div className="item_box">
              <div className="item_head">有効フラグ</div>
              <Checkbox
                label=""
                className="is_valid"
                checked={validFlag}
                disabled={!isEdit}
                onChange={() => setValidFlag(!validFlag)}
              />
            </div>
          </div>
        </div>
      </div>
    </EditPC>
  );
};
