import { ApiBase } from '../../../service/api-base';
import { User } from '../../../type/auth/user.typs';
import { Jisx0402ListType } from '../../../type/zip-code/zip-code.type';

export type ApiJisx0402GetListResponse = Jisx0402ListType;

export type ApiJisx0402GetListParam = Partial<{
  /** 都道府県 */
  pref: string;
  /** 市区町村 */
  city: string;
  /** JISX0402 */
  code: string;
  /** オフセット */
  offset: number;
  /** 1ページ内の件数 */
  limit: number;
}>;

export class ApiJisx0402GetList extends ApiBase<ApiJisx0402GetListResponse> {
  constructor(param: ApiJisx0402GetListParam) {
    super({
      credentials: 'include',
      httpMethod: 'GET',
      url: '/api/zipcode/jisx0402',
      param,
    });
  }
}
