import {
  useEffect, useRef, useCallback, useState, useMemo,
} from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { replace } from 'connected-react-router';
import { cloneDeep } from 'lodash';
import { CustomerActions } from '../../../../redux/customer/customer.action';
import { SetSelectedClass } from '../../../../utilities/set-selected-class';
import { BottomBorderButton } from '../../../ui/button/bottom-border/bottom-border-button';
import { LeftIconButton } from '../../../ui/button/left-icon-button/left-icon-button';
import { MapListToggleButton } from '../../../ui/button/map-list-toggle/map-list-toggle-button';
import { MapBase } from '../../../ui/map/map-base';
import { CustomerListSP } from '../../layout/body/list/customer-list/customer-list.sp';
import { SearchBoxDialogTitle } from '../../layout/search-box/search-box.type.sp';
import { BasePageSP } from '../base.page.sp';
import { SearchBoxCustomerSP } from './serch-box/customer-search-box.sp';
import { useAppSelector } from '../../../../hooks/use-redux';
import { DialogActions } from '../../../../redux/dialog/dialog.action';
import { CustomerEditDialogTitle } from './edit/customer-edit.type';
import { CustomerEditSP } from './edit/customer-edit.sp';
import { CustomerListType, CustomerSortState } from '../../../../type/customer/customer.type';
import { useQuery } from '../../../../hooks/use-query';
import { CustomerModel } from '../../../../model/customer/customer-model';
import { RoutingPath } from '../../../../routes/routing-pass';
import { useWillUnMount, useDidMount } from '../../../../hooks/life-cycle';
import { MapActions } from '../../../../redux/map/map.action';
import { usePushMessage } from '../../../../hooks/common/use-common';
import { TableSort } from '../../../ui/table/table-sort/table-sort';
import { MapCollection } from '../../../../collection/map/map.collection';
import { MapAreaPosition } from '../../../../type/map/map.type';

export const CustomerSP = () => {
  const dispatch = useDispatch();
  const messageDialog = usePushMessage();
  const sort = useAppSelector((v) => v.customer.sort);
  const areaBtnDisabled = useAppSelector((v) => (v.map.areaBtnDisabled));
  const zoom = useAppSelector((v) => v.map.zoomLevel);

  const shoType = useQuery('type');
  const [showType, setShowType] = useState<'map' | 'list'>('map');
  const [isSearch, setIsSearch] = useState(false);
  const _customerList = useAppSelector((v) => (v.customer.list));
  const sortState = useAppSelector((v) => (v.customer.sort));
  const mapAreaPos = useAppSelector((v) => (v.map.mapAreaPos));
  const gpsStatus = useAppSelector((v) => (v.map.gpsStatus));
  const dialogLen = useAppSelector((v) => (v.dialog.dialogs.length));
  const user = useAppSelector((v) => v.user);
  const map = useAppSelector((v) => v.map.map);
  const flickLoad = useAppSelector((v) => v.map.flickLoad);
  const humanPos = useAppSelector((v) => (v.map.humanPos));

  const [init, setInit] = useState<boolean>(true);
  const locationState = Boolean(useLocation().state);
  const headerEle = useRef<HTMLDivElement>(null);
  const footerEle = useRef<HTMLDivElement>(null);
  const listEle = useRef<HTMLDivElement>(null);

  const [customerShowType, setCustomerShowType] = useState<0 | 1 |2>(0);
  const [mapList, setMapList] = useState<CustomerListType[] | null>(null);
  const [hitCount, setHitCount] = useState(0);
  const [isSearchDialog, setIsSearchDialog] = useState<boolean>(false);
  const ref1 = useRef<HTMLDivElement>(null);
  const ref2 = useRef<any>(null);
  const ref3 = useRef<any>(null);
  /* エリア検索 */
  const [zoomSearch, setZoomSearch] = useState(false);

  /* 一覧取得 - リスト */
  const getListInList = useCallback((param: {
    data?: CustomerSortState;
    offset?: number;
  }) => {
    const { data, offset } = param;
    setMapList(null);
    dispatch(CustomerActions.api.customer.getCallbackList({
      param: {
        ...CustomerModel.listParam({
          sortState: data || sortState,
          isMap: showType === 'map',
          searchBox: true,
          isMapList: true,
        }),
        limit: 100,
        offset: offset || sortState.offset,
      },
      onSuccess: (v, hit) => {
        if (!v) return;
        setHitCount(hit);
        setMapList(v);
      },
    }));
  }, [sortState, showType]);

  /* 一覧取得 - map */
  const getListInMap = useCallback((param: {
    data?: CustomerSortState;
    searchBox?: boolean;
    mapPos?: MapAreaPosition;
  }) => {
    const { data, searchBox, mapPos } = param;
    const isMap = showType === 'map';
    dispatch(CustomerActions.api.customer.getList({
      isLoad: isMap,
      isMapLoad: true,
      param: CustomerModel.listParam({
        sortState: data || sortState,
        isMap,
        mapAreaPos: mapPos || mapAreaPos,
        isSp: true,
      }),
      onSuccess: (v) => {
        if (!v) return;
        if (flickLoad) setMapList(v.data);
        if (isMap) {
          dispatch(CustomerActions.setList(v.data));
        } else {
          dispatch(CustomerActions.setList(v.data));
        }
        dispatch(CustomerActions.setListCount(v.hitCount));
        if (!v.data.length && isMap) {
          dispatch(DialogActions.pushMessage({
            title: '検索',
            message: ['領域内に該当データがありません'],
          }));
        }
      },
    }));
  }, [showType, sortState, mapAreaPos, flickLoad]);

  // const getList = useCallback((data?: CustomerSortState, searchBox?:boolean) => {
  //   const isMap = showType === 'map';
  //   if (searchBox) {
  //     setMapList(null);
  //     dispatch(CustomerActions.api.customer.getCallbackList({
  //       param: {
  //         ...CustomerModel.listParam({
  //           sortState: data || sortState,
  //           isMap,
  //           searchBox: true,
  //           isMapList: true,
  //         }),
  //         limit: 100,
  //       },
  //       onSuccess: (v, hit) => {
  //         if (!v) return;
  //         setHitCount(hit);
  //         setMapList(v);
  //       },
  //     }));
  //   }
  //   dispatch(CustomerActions.api.customer.getList({
  //     isLoad: isMap,
  //     param: CustomerModel.listParam({
  //       sortState: data || sortState, isMap, mapAreaPos, isSp: true, searchBox,
  //     }),
  //     onSuccess: (v) => {
  //       if (!v) return;
  //       if (flickLoad) {
  //         setMapList(v.data);
  //       }
  //       if (isMap) {
  //         dispatch(CustomerActions.setList(v.data));
  //       } else {
  //         dispatch(CustomerActions.setList(v.data));
  //       }
  //       dispatch(CustomerActions.setListCount(v.hitCount));
  //       if (!v.data.length && isMap && searchBox) {
  //         dispatch(DialogActions.pushMessage({
  //           title: '検索',
  //           message: ['領域内に該当データがありません'],
  //         }));
  //       }
  //     },
  //   }));
  // }, [sortState, showType, gpsStatus, mapAreaPos, user.id, map, flickLoad]);

  const changeMap = useCallback(() => {
    if (flickLoad) setMapList(null);
    setInit(true);
    const path = `${RoutingPath.customer}?type=${showType === 'map' ? 'list' : 'map'}`;
    dispatch(CustomerActions.setList(null));
    dispatch(replace(path));
    setShowType(showType === 'map' ? 'list' : 'map');
  }, [showType, flickLoad]);

  /* エリア検索ボタン押下 */
  const hClickSearchArea = useCallback((data?: CustomerSortState) => {
    dispatch(MapActions.setAreaBtnDisabled(true));
    if (flickLoad) setMapList(null);
    if (zoom >= MapCollection.searchZoomLevel && mapAreaPos) {
      getListInMap({ data });
    } else {
      setZoomSearch(true);
      dispatch(MapActions.setZoomLevel(MapCollection.searchZoomLevel));
    }
  }, [zoom, mapAreaPos, getListInMap, flickLoad]);

  const handleClickRegistration = useCallback(() => {
    dispatch(DialogActions.push({
      title: CustomerEditDialogTitle.add,
      element: <CustomerEditSP
        callback={() => {
          getListInList({});
          getListInMap({});
        }}
      />,
    }));
  }, [dispatch, getListInList, getListInMap]);

  /* ズーム終了時アクション */
  const zoomEnd = useCallback((pos: MapAreaPosition) => {
    if (!zoomSearch) {
      dispatch(MapActions.setAreaBtnDisabled(false));
      return;
    }
    setZoomSearch(false);
    getListInMap({ mapPos: pos });
  }, [zoomSearch, getListInMap, flickLoad]);

  useEffect(() => {
    listEle.current?.scrollTo(0, -10000);
  }, [customerShowType]);

  useEffect(() => {
    if (dialogLen || (showType === 'map' && !mapAreaPos)) return;
    getListInList({});
  }, [
    showType,
  ]);

  useEffect(() => {
    if (init && humanPos && mapAreaPos) {
      setInit(false);
      hClickSearchArea();
    }
  }, [humanPos, mapAreaPos]);

  useEffect(() => {
    const mapType = (shoType || 'map');
    const path = `${RoutingPath.customer}?type=`;
    dispatch(replace(path + mapType));
    setShowType(mapType === 'map' ? 'map' : 'list');
    // if (mapType === 'list') {
    //   setIsSearch(false);
    // }
  }, [shoType]);

  useWillUnMount(() => {
    dispatch(CustomerActions.setList(null));
    dispatch(MapActions.setFlickLoad(true));
  });

  /* 検索ボタン押下処理 */
  const hClickSearchBtn = useCallback((v: CustomerSortState) => {
    dispatch(DialogActions.pop());
    if (showType !== 'map') { dispatch(CustomerActions.setList(null)); }
    setIsSearch(true);
    setMapList(null);
    dispatch(MapActions.setFlickLoad(false));
    dispatch(CustomerActions.setSort({ offset: 0 }));
    if (showType === 'map') { hClickSearchArea(v); }
    getListInList({ data: { ...v, offset: 0 }, offset: 0 });
  }, [shoType, getListInList, showType, hClickSearchArea, sortState]);

  /* フリーワード検索処理 */
  const hBlurFree = useCallback((v: string) => {
    if (showType !== 'map') { dispatch(CustomerActions.setList(null)); }
    setIsSearchDialog(true);
    dispatch(CustomerActions.setSort({ keyword: v, offset: 0 }));
    if (showType === 'map') { hClickSearchArea({ ...sortState, keyword: v }); }
    getListInList({ data: { ...sortState, keyword: v }, offset: 0 });
  }, [shoType, getListInList, showType, hClickSearchArea]);

  /* ページネーション */
  const hClickPagination = useCallback((offset: number) => {
    dispatch(CustomerActions.setSort({ offset }));
    getListInList({ ...sortState, offset });
  }, [sortState, getListInList]);

  return (
    <BasePageSP
      className="customer_sp"
      searchBoxDialog={{
        title: SearchBoxDialogTitle,
        element: <SearchBoxCustomerSP
          // isSearch={isSearch}
          type={customerShowType}
          callbackType={(v) => setCustomerShowType(v)}
          getList={hClickSearchBtn}
        />,
      }}
      menuOpen={locationState}
      searchValue={sort.keyword}
      searchCallback={hBlurFree}
    >
      <div
        id="customer_sp_header"
        className="map_list_header"
        onClick={(e) => { e.preventDefault(); }}
        ref={headerEle}
      >
        <BottomBorderButton
          label="すべて"
          ref={ref1}
          onClick={(e) => {
            setCustomerShowType(0);
            dispatch(CustomerActions.setList(null));
            dispatch(CustomerActions.setSort({
              ...cloneDeep(sortState),
              ob_flag: 0,
            }));
            hClickSearchBtn({
              ...cloneDeep(sortState),
              ob_flag: 0,
            });
            SetSelectedClass(e.currentTarget, headerEle.current);
          }}
          selected={!customerShowType}
        />
        <BottomBorderButton
          label="OBのみ"
          ref={ref2}
          onClick={(e) => {
            setCustomerShowType(1);
            dispatch(CustomerActions.setList(null));
            dispatch(CustomerActions.setSort({
              ...cloneDeep(sortState),
              ob_flag: 1,
            }));
            hClickSearchBtn({
              ...cloneDeep(sortState),
              ob_flag: 1,
            });
            SetSelectedClass(e.currentTarget, headerEle.current);
          }}
          selected={customerShowType === 1}
        />
        <BottomBorderButton
          label="見込みのみ"
          ref={ref3}
          onClick={(e) => {
            dispatch(CustomerActions.setList(null));
            setCustomerShowType(2);
            dispatch(CustomerActions.setSort({
              ...cloneDeep(sortState),
              ob_flag: 2,
            }));
            hClickSearchBtn({
              ...cloneDeep(sortState),
              ob_flag: 2,
            });
            SetSelectedClass(e.currentTarget, headerEle.current);
          }}
          selected={customerShowType === 2}
        />
      </div>
      {showType === 'map'
        ? (
          <>
          </>
        )
        : (
          <>
            <TableSort
              isSp
              page={sortState.offset ?? 0}
              limit={100}
              hitCount={hitCount || 0}
              callback={hClickPagination}
            />
          </>
        )}
      <div className="map_list_body" ref={listEle}>
        {!areaBtnDisabled && showType === 'map'
          && (
          <LeftIconButton
            label="このエリアを検索"
            size="md"
            fontAwesomeClass="fas fa-search"
            className="for_area"
            color="secondary"
            onClick={() => hClickSearchArea()}
            disabled={areaBtnDisabled}
          />
          )}
        {showType === 'map'
          ? (
            <MapBase
              customerOption={{ type: customerShowType }}
              isNowPoint
              searchOption={{}}
              callbackZoomEnd={zoomEnd}
              type="customer"
            />
          )
          : (
            <>
              {/*
              <TableSort
                isSp
                page={sortState.offset ?? 0}
                limit={100}
                hitCount={hitCount || 0}
                callback={hClickPagination}
              />
            */}
              <CustomerListSP data={mapList} type={customerShowType} />
            </>
          )}
      </div>

      <div
        className="page_body_footer space_between"
        ref={footerEle}
      >
        <LeftIconButton
          label="顧客新規登録"
          fontAwesomeClass="far fa-edit"
          onClick={handleClickRegistration}
          size="md"
          color="primary"
        />
        <MapListToggleButton
          showType={showType}
          onClick={changeMap}
        />
      </div>
    </BasePageSP>
  );
};
