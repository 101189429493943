import { push } from 'connected-react-router';
import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useInView } from 'react-intersection-observer';
import { useDispatch, useSelector } from 'react-redux';
import { useDidMount } from '../../../../../../hooks/life-cycle';
import { DialogActions } from '../../../../../../redux/dialog/dialog.action';
import { State } from '../../../../../../redux/root.reducer';
import { RoutingPath } from '../../../../../../routes/routing-pass';
import { MaintenanceList } from '../../../../../../type/maintenance/maintenance.type';
import { CardList } from '../../../../../ui/card/card-list/card-list';
import { MaintenanceCard } from '../../../../../ui/card/maintenance/maintenance-card';

type Props = {
  showType?:'map' | 'list' | 'date',
  data?: MaintenanceList[] | null;
  type?: 0 | 1 | 2;
  handleCardClick?: (maintenance: MaintenanceList) => void;
  selectDay?: Date;
}

export const MaintenanceListSP = (props: Props) => {
  const {
    data, handleCardClick, selectDay, showType, type,
  } = props;

  const { ref, inView } = useInView({
    // オプション
    rootMargin: '-50px', // ref要素が現れてから50px過ぎたら
    triggerOnce: true, // 最初の一度だけ実行
  });

  /* Hooks */
  const list = useSelector((state: State) => state.maintenance.list);

  const maintenanceListData = data || data === null
    ? data
    : list;

  const dispatch = useDispatch();
  const [isInCustomerDetail, setIsInCustomerDetail] = useState(false);

  /* Effect */
  // useEffect(() => {
  // }, []);

  /* List */
  const maintenanceList = useMemo(() => {
    console.log(maintenanceListData);
    console.log(type);
    const dateCalc = (d: string) => {
      const day1 = new Date(d);
      return day1.getFullYear() === selectDay?.getFullYear()
        && day1.getMonth() === selectDay?.getMonth()
        && day1.getDay() === selectDay.getDay();
    };
    if (showType === 'date') {
      return (maintenanceListData?.filter((v) => dateCalc(v.maintenance_date)));
    }

    return type === 2 ? maintenanceListData : maintenanceListData?.filter((v) => {
      if (type) {
        return v.supported_kubun;
      }
      return !v.supported_kubun;
    });
  }, [maintenanceListData, selectDay, showType, list, data, type]);

  const handleClickCard = useCallback((id: number) => {
    if (handleCardClick) {
      const findData = data?.find((v) => v.id === id);
      if (findData) handleCardClick(findData);
      dispatch(DialogActions.pop());
      dispatch(DialogActions.pop());
      return;
    }
    dispatch(push(`${RoutingPath.maintenanceDetail}/${id}`));
  }, [data]);

  useDidMount(() => {
    const arr = window.location.href.split('customer/detail');
    setIsInCustomerDetail(arr.length >= 2);
  });

  return (
    <CardList isLoading={inView} list={maintenanceList}>
      {maintenanceList?.map((v, i) => (
        <div
          key={`card${i}`}
          className="list_base_card_wrap"
          ref={maintenanceList.length - 1 ? ref : undefined}
        >
          <MaintenanceCard
            maintenanceData={v}
            onClick={handleClickCard}
            isInCustomerDetail={isInCustomerDetail}
          />
        </div>
      ))}
    </CardList>
  );
};
