import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useDidMount } from '../../../../../../hooks/life-cycle';
import { FamilyValidation } from '../../../../../../model/validation/customer/family.validation';
import { ValidationLengthUnder255, ValidationLengthUnder5, ValidationLengthUnder60 } from '../../../../../../model/validation/validation-length-under';
import { DialogActions } from '../../../../../../redux/dialog/dialog.action';
import { DateFormatter } from '../../../../../../utilities/date-formatter';
import { EditSP } from '../../../../../dialogs/edit/edit.sp';
import { DatePicker } from '../../../../../ui/date-picker/date-picker';
import { Input } from '../../../../../ui/input/input';
import { Required } from '../../../../../ui/required/required';
import { ListFamily } from '../../../../../../model/customer/family-list.model';
import { Message } from '../../../../../../collection/message.collection';
import { InputTel } from '../../../../../ui/input/input-tel';

type Props = {
  data?: any;
  isEdit?: boolean;
  id: number;
  callbackPost: (v:ListFamily) => void;
}

export const FamilyEditDialog = (props: Props) => {
  const {
    isEdit,
    data,
    id,
    callbackPost,
  } = props;

  /* Hooks */
  const dispatch = useDispatch();

  /* State */
  const [touch, setTouch] = useState(false);
  const [name, setName] = useState('');
  const [relationship, setRelationship] = useState('');
  const [mobilePhone, setMobilePhone] = useState('');
  const [birthDate, setBirthDate] = useState<Date | null>(null);

  /* Callback */
  const handleClickPost = useCallback(
    () => {
      if (FamilyValidation(
        name,
        relationship,
        mobilePhone,
        birthDate,
      )) {
        dispatch(DialogActions.pushMessage({
          title: 'ご家族情報登録',
          message: Message.postError,
          callback: () => setTouch(true),
        }));
        return;
      }
      callbackPost({
        id,
        name,
        relationship,
        customer_id: data?.customer_id ?? NaN,
        email: data?.email ?? '',
        mobile_phone: mobilePhone,
        birth_date: DateFormatter.date2str(birthDate),
        index: isEdit ? data?.index : undefined,
      });
    },
    [name, relationship, mobilePhone, birthDate, id, data],
  );

  useDidMount(() => {
    if (isEdit && data) {
      // dispatch(CustomerActions.api.family.get({
      //   param: { id, family_id: data },
      //   callback: (data) => {
      setName(data.name);
      setRelationship(data.relationship);
      setMobilePhone(data.mobile_phone);
      if (data.birth_date) { setBirthDate(new Date(data.birth_date)); }
      //  },
      // }));
    }
  });

  return (
    <EditSP mode="add" callback={handleClickPost}>
      <div className="edit_sp_body_inner family_edit_dialog_sp">
        <div className="category_wrap">

          <div className="item_wrap">
            <div className="item_label">
              氏名<Required />
            </div>
            <div className="item_body">
              <Input
                value={name}
                onChange={(e) => setName(e.target.value)}
                validationList={ValidationLengthUnder255}
                touch={touch}
                require
                errorPosBottom
              />
            </div>
          </div>

          <div className="item_wrap">
            <div className="item_label">
              続柄<Required />
            </div>
            <div className="item_body item_family_relation">
              <Input
                value={relationship}
                onChange={(e) => setRelationship(e.target.value)}
                validationList={ValidationLengthUnder255}
                touch={touch}
                require
              />
            </div>

          </div>

          <div className="item_wrap">
            <div className="item_label">
              携帯電話
            </div>
            <div className="item_body item_tel_no">
              <InputTel
                touch={mobilePhone ? touch : undefined}
                value={mobilePhone}
                onChange={(v) => setMobilePhone(v)}
              />
            </div>
          </div>

          <div className="item_wrap">
            <div className="item_label">生年月日</div>
            <DatePicker
              date={birthDate}
              onChange={(v) => setBirthDate(v)}
            />
          </div>
        </div>
      </div>
    </EditSP>

  );
};
