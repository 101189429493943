class MessageCollection {
  /** 登録エラー */
  static postError = ['未入力／入力不備項目があります'];

  static getListError = ['該当する結果が見つかりません'];

  /** 登録確認 */
  static postConfirm = ['登録しますか'];

  /** 更新確認 */
  static postUpdateConfirm = ['入力した内容で更新します。', 'よろしいですか？'];

  /** 登録完了 */
  static postComplete = ['登録が完了しました。']

  /** 登録完了 */
  static postUpdateComplete = ['更新が完了しました。']

  /** ファイルアップロード説明 */
  static fileInfo = [
    '【1回でアップロード可能なデータサイズ】',
    '100MB',
    '　',
    '【アップロード可能な拡張子】',
    'xlsx, xls, doc, docx, ppt, pptx, pdf,',
    'jpg, png, jpeg, png, gif, svg,',
    'mp4, m4a, avi, mov, wmv, flv, mkv, mpg, mpg, webm,',
    'wave, aif, mp3, aac, flac,',
    'zip, pub',
  ];

  static master = {
    /** マスタ管理操作 */
    editKanri: ['マスタ管理の操作を許可します。'],
    /** マスタ管理操作 */
    authKanri: ['自分の担当以外のデータ登録、修正を許可します。'],
    /** マスタ管理操作 */
    logo: ['【アップ可能なサイズ】10MB', '【アップロード可能な拡張子】 jpg,png'],
  };

  static csv = {
    /** 不備情報の対象項目 */
    incompleteInfo: [
      '【不備情報の対象項目】',
      '名前、名前かな、',
      '郵便番号、都道府県、市区町村、地名・番地、',
      'エリア、電話番号',
      'がどれかひとつでも未設定であれば',
      '『不備あり』として判別されます。',
    ],
  };

  /** 電話メッセージ */
  static tel = (str: string) => ([
    `${str}`,
    'に電話をかけます',
  ])

  /** メールメッセージ */
  static sms = (str: string) => ([
    `${str}`,
    'にSMSを送ります',
  ])

  /** メールメッセージ */
  static mail = (str: string) => ([
    `${str}`,
    'にメールを送ります',
  ])

  /** セッション切れ */
  static session: string[] = [
    'セッションが切れました。',
    '再度ログインをお願いします。',
    '',
    '再ログインができない場合、',
    'ご利用中のアカウントの設定が',
    '変更された可能性がございます。',
    '管理者にご確認ください。',
  ]
}

export const Message = MessageCollection;
