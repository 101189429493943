import './customer.pc.scss';
import { useDispatch } from 'react-redux';
import {
  memo, useCallback, useEffect, useMemo, useState,
} from 'react';
import { replace } from 'connected-react-router';
import cloneDeep from 'lodash/cloneDeep';
import { BasePagePC } from '../base.page.pc';
import { CustomerSearchBox } from '../../layout/search-box/customer/customer-search-box';
import { CustomerMapListPC } from '../../layout/body/map/map-list/customer/customer-map-list';
import { CustomerListPC } from '../../layout/body/list/customer-list/customer-list.pc';
import { DialogActions } from '../../../../redux/dialog/dialog.action';
import { LeftIconButton } from '../../../ui/button/left-icon-button/left-icon-button';
import { CustomerActions } from '../../../../redux/customer/customer.action';
import { TableSort, Limit } from '../../../ui/table/table-sort/table-sort';
import { MapActions } from '../../../../redux/map/map.action';
import { useQuery } from '../../../../hooks/use-query';
import { useAppSelector } from '../../../../hooks/use-redux';
import { CustomerListType, CustomerSortState } from '../../../../type/customer/customer.type';
import { CustomerEditDialogTitle } from '../../../sp/pages/customer/edit/customer-edit.type';
import { CustomerEdit } from './edit/customer-edit';
import { RoutingPath } from '../../../../routes/routing-pass';
import { useWillUnMount, useDidMount } from '../../../../hooks/life-cycle';
import { CustomerModel } from '../../../../model/customer/customer-model';
import { CsvActions } from '../../../../redux/csv/csv.action';
import { CsvModel } from '../../../../model/csv/csv-model';
import { MapAreaPosition } from '../../../../type/map/map.type';
import { MapCollection } from '../../../../collection/map/map.collection';
import { initialState } from '../customer-detail/tables/project-information-table/project-information-table-state';
import { Store } from '../../../../redux/store';

export const CustomerPC = () => {
  const shoType = useQuery('type');
  const dispatch = useDispatch();
  const listHitCount = useAppSelector((v) => (v.customer.listHitCount));
  const areaBtnDisabled = useAppSelector((v) => (v.map.areaBtnDisabled));
  // const humanPos = useAppSelector((v) => (v.map.humanPos));
  const sortState = useAppSelector((v) => (v.customer.sort));
  // const mapAreaPos = useAppSelector((v) => (v.map.mapAreaPos));
  const gpsStatus = useAppSelector((v) => (v.map.gpsStatus));
  const dialogLen = useAppSelector((v) => (v.dialog.dialogs.length));
  const user = useAppSelector((v) => v.user);
  const map = useAppSelector((v) => v.map.map);
  // const zoom = useAppSelector((v) => v.map.zoomLevel);
  const flickLoad = useAppSelector((v) => v.map.flickLoad);
  const [showType, setShowType] = useState<'map' | 'list'>('map');
  const [customerShowType, setCustomerShowType] = useState<0 | 1 |2>(0);
  const [searchIsOpen, setSearchIsOpen] = useState<boolean>(false);
  const [init, setInit] = useState<boolean>(true);

  /* MapList用変数 */
  const [hitCount, setHitCount] = useState(0);
  const [page, setPage] = useState(0);
  const [mapList, setMapList] = useState<CustomerListType[] | null>([]);
  const [isSearch, setIsSearch] = useState(true);
  /* エリア検索 */
  const [zoomSearch, setZoomSearch] = useState(false);
  /* カードクリック検索 */
  const [cardSearch, setCardSearch] = useState<boolean>(false);

  const [select, setSelect] = useState<number[]>([]);
  const [listList, setListList] = useState<CustomerListType[] | null | undefined>(null);
  const [isInitMap, setIsInitMap] = useState(false);

  const customerList = useMemo(() => (
    !customerShowType ? mapList : mapList?.filter(
      (v) => v.ob_flag === customerShowType,
    )), [customerShowType, mapList]);

  /* カードリストの一覧取得のみ */
  const getListCardList = useCallback((param: {
    data?: CustomerSortState;
    offset?: number;
  }) => {
    const { data, offset } = param;
    setPage(offset || 0);
    setMapList(null);
    dispatch(CustomerActions.api.customer.getCallbackList({
      param: {
        ...CustomerModel.listParam({
          sortState: data || sortState,
          isMap: true,
          searchBox: true,
          isMapList: true,
        }),
        limit: 100,
        offset: offset || sortState.offset,
      },
      onSuccess: (v, hit) => {
        if (!v) return;
        setMapList(v);
        setHitCount(hit);
      },
    }));
  }, [sortState]);

  /* マップ一覧取得 */
  const getListMapList = useCallback((param: {
    data?: CustomerSortState;
    mapPos?: MapAreaPosition;
    searchBox?: boolean;
    isInit?: boolean;
  }) => {
    const { data, mapPos, searchBox } = param;
    const isMap = showType === 'map';
    dispatch(CustomerActions.api.customer.getList({
      isLoad: isMap,
      isMapLoad: true,
      param: CustomerModel.listParam({
        isMap,
        sortState: data || sortState,
        mapAreaPos: mapPos || Store.getState().map.mapAreaPos,
      }),
      onSuccess: (v) => {
        if (!v) return;
        // if (flickLoad) setMapList(v.data);
        dispatch(CustomerActions.setList(v.data));
        dispatch(MapActions.setAreaBtnDisabled(true));
        dispatch(CustomerActions.setListCount(v.hitCount));
        if (v.data.length || showType !== 'map' || flickLoad) return;
        dispatch(DialogActions.pushMessage({
          title: '検索',
          message: ['領域内に該当データがありません'],
        }));
      },
    }));
  }, [showType, flickLoad, sortState]);

  /* 一覧取得 */
  // const getList = useCallback((
  //   data?: CustomerSortState,
  //   searchBox?: boolean,
  //   mapListOffset?: number,
  //   mapPos?: MapAreaPosition,
  // ) => {
  //   const isMap = showType === 'map';
  //   // マップリスト左側の一覧
  //   if (searchBox || mapListOffset || mapListOffset === 0) {
  //     setPage(mapListOffset || 0);
  //     setMapList(null);
  //     dispatch(CustomerActions.api.customer.getCallbackList({
  //       param: {
  //         ...CustomerModel.listParam({
  //           sortState: data || sortState,
  //           isMap,
  //           searchBox: true,
  //           isMapList: true,
  //         }),
  //         limit: 100,
  //         offset: mapListOffset || 0,
  //       },
  //       onSuccess: (v, hit) => {
  //         if (!v) return;
  //         setMapList(v);
  //         setHitCount(hit);
  //       },
  //     }));
  //   }

  //   // マップとリストの一覧
  //   dispatch(CustomerActions.api.customer.getList({
  //     isLoad: isMap,
  //     param: CustomerModel.listParam({
  //       sortState: data || sortState, isMap, mapAreaPos: mapPos || mapAreaPos,
  //     }),
  //     onSuccess: (v) => {
  //       if (!v) return;
  //       if (flickLoad) {
  //         setMapList(v.data);
  //       }
  //       if (isMap) {
  //         dispatch(CustomerActions.setList(v.data));
  //       } else {
  //         dispatch(CustomerActions.setList(v.data));
  //       }
  //       dispatch(CustomerActions.setListCount(v.hitCount));
  //       if (!v.data.length) {
  //         if (!searchBox || showType !== 'map') return;
  //         dispatch(DialogActions.pushMessage({
  //           title: '検索',
  //           message: ['領域内に該当データがありません'],
  //         }));
  //       }
  //     },
  //   }));
  // }, [sortState, showType, gpsStatus, mapAreaPos, user.id, map, flickLoad]);

  /* 新規登録 */
  const handleClickRegistration = useCallback(() => {
    dispatch(DialogActions.push({
      title: '顧客登録',
      className: 'max_height_dialog max_width_dialog customer',
      element: <CustomerEdit callback={() => {
        if (showType === 'list') {
          getListMapList({});
        } else {
          if (!flickLoad) {
            getListCardList({});
          }
          getListMapList({});
        }
      }}
      />,
    }));
  }, [dispatch, getListCardList, getListMapList, flickLoad, showType]);

  /* 検索後のカードクリックアフターアクション */
  const centerChanged = useCallback((pos: MapAreaPosition) => {
    if (!cardSearch) return;
    setCardSearch(false);
    getListMapList({ mapPos: pos });
    // getList(undefined, false, undefined, pos);
  }, [cardSearch, getListMapList]);

  /* マップ⇔リスト */
  const changeMap = useCallback(() => {
    const path = `${RoutingPath.customer}?type=${showType === 'map' ? 'list' : 'map'}`;
    dispatch(replace(path));
    setShowType(showType === 'map' ? 'list' : 'map');
  }, [showType]);

  /* カードクリック挙動 */
  const hClickCard = useCallback(() => {
    if (flickLoad) return;
    if (
      Store.getState().map.zoomLevel >= MapCollection.searchZoomLevel
      && Store.getState().map.mapAreaPos
    ) {
      setCardSearch(true);
    } else {
      setZoomSearch(true);
    }
  }, [flickLoad]);

  /* リストページャーソート */
  const pagerSort = useCallback((offset: number, limit: Limit) => {
    dispatch(CustomerActions.setList(null));
    dispatch(CustomerActions.setSort({ offset, limit }));
    getListMapList({
      data: { ...sortState, offset, limit },
    });
    // getList({ ...sortState, offset, limit });
  }, [sortState, getListMapList]);

  /* TableHeaderのソート */
  const headerSort = useCallback((highlow: number, sort_by: number) => {
    dispatch(CustomerActions.setSort({ highlow, sort_by }));
  }, []);

  /* ズーム終了時アクション */
  const zoomEnd = useCallback((pos: MapAreaPosition) => {
    if (!zoomSearch) {
      dispatch(MapActions.setAreaBtnDisabled(false));
      return;
    }
    setZoomSearch(false);
    // if (!flickLoad) { getListCardList({}); }
    getListMapList({ mapPos: pos });
    // getList(undefined, false, undefined, pos);
  }, [zoomSearch, getListCardList, getListMapList, flickLoad]);
  /* エリア検索ボタン押下 */
  const hClickSearchArea = useCallback((isInit?: boolean, v?: CustomerSortState) => {
    if (!isInit) {
      dispatch(MapActions.setAreaBtnDisabled(true));
    }
    if (flickLoad) setMapList(null);
    if (
      Store.getState().map.zoomLevel >= MapCollection.searchZoomLevel
      && Store.getState().map.mapAreaPos
    ) {
      getListMapList({ data: v });
    } else {
      setZoomSearch(true);
      dispatch(MapActions.setZoomLevel(cloneDeep(MapCollection.searchZoomLevel)));
    }
  }, [getListMapList, flickLoad, sortState]);

  /* 検索ボタン押下処理 */
  const hClickSearchBtn = useCallback((v: CustomerSortState, isInit?: boolean) => {
    if (showType !== 'map') { dispatch(CustomerActions.setList(null)); }
    setSelect([]);
    setIsSearch(true);
    setMapList(null);
    dispatch(MapActions.setFlickLoad(false));
    dispatch(CustomerActions.setSort({ offset: 0 }));
    if (showType === 'map') {
      hClickSearchArea(isInit, v);
    } else {
      getListMapList({ data: { ...v } });
    }
    getListCardList({ data: { ...v, offset: 0 }, offset: 0 });
  }, [shoType, getListCardList, getListMapList, showType, hClickSearchArea]);

  /* タイプ押下 */
  const hClickType = useCallback((type: 0 | 1| 2) => {
    setCustomerShowType(type);
    // dispatch(MapActions.setZoomLevel(15));
  }, [shoType]);

  /* MapList内部ページネーション */
  const hClickPagination = useCallback((offset: number) => {
    setMapList(null);
    getListCardList({ offset });
    // getList(undefined, undefined, offset);
  }, [getListMapList]);

  /* リスト・マップ変更ボタン押下 */
  const hClickShowType = useCallback(() => {
    dispatch(MapActions.setAreaBtnDisabled(false));
    if (!cardSearch && !zoomSearch) {
      setMapList(showType === 'map' ? null : []);
      setInit(true);
      dispatch(CustomerActions.setList(null));
    }
    if (!flickLoad && Store.getState().map.mapAreaPos) {
      getListCardList({});
    }
    changeMap();
  }, [changeMap, flickLoad, hClickSearchArea, showType, zoomSearch]);

  const initMapSearch = useCallback((onGps: boolean) => {
    const promise = () => new Promise<void>((resolve, reject) => {
      if (onGps) {
        if (!Store.getState().map.mapAreaPos || !Store.getState().map.humanPos) {
          reject();
        } else {
          resolve();
        }
      } else if (!Store.getState().map.mapAreaPos) {
        reject();
      } else {
        resolve();
      }
    });
    const run = () => {
      setInit(false);
      if (onGps) {
        hClickSearchBtn(sortState, init);
        dispatch(MapActions.setFlickLoad(false));
      } else {
        dispatch(MapActions.setFlickLoad(false));
        hClickSearchBtn(sortState, init);
      }
    };
    promise()
      .then(() => {
        run();
      })
      .catch(() => {
        window.setTimeout(() => {
          initMapSearch(onGps);
        }, 200);
      });
  }, [sortState, init]);

  /* effect */
  useEffect(() => {
    if (dialogLen || (showType === 'map')) return;
    getListMapList({});
  }, [
    sortState.highlow,
    sortState.sort_by,
    showType,
  ]);

  // useEffect(() => {
  //   if (init && humanPos && mapAreaPos) {
  //     setInit(false);
  //     // hClickSearchArea();
  //     hClickSearchBtn(sortState, init);
  //     dispatch(MapActions.setFlickLoad(false));
  //   } else if (init && mapAreaPos) {
  //     dispatch(MapActions.setFlickLoad(false));
  //     hClickSearchBtn(sortState, init);
  //     setInit(false);
  //   }
  // }, [humanPos, mapAreaPos]);

  /* マップ⇔リスト */
  useEffect(() => {
    const mapType = (shoType || 'list');
    const path = `${RoutingPath.customer}?type=`;
    dispatch(replace(path + mapType));
    setShowType(mapType === 'map' ? 'map' : 'list');
  }, [shoType]);

  useWillUnMount(() => {
    dispatch(CustomerActions.setList(null));
    dispatch(MapActions.setFlickLoad(true));
  });

  return (
    <BasePagePC>
      <div id="customer" className={`cnt_area ${searchIsOpen ? 'detail_on' : ''}`}>
        <div className="inner">
          <CustomerSearchBox
            openCallback={setSearchIsOpen}
            salesSet
            callbackGetList={hClickSearchBtn}
            type={customerShowType}
            // callbackType={setCustomerShowType}
          />
          <div className="MapPC__body__map" />
          {!areaBtnDisabled && showType === 'map'
          && (
          <LeftIconButton
            label="このエリアを検索"
            size="md"
            fontAwesomeClass="fas fa-search"
            className="for_area"
            color="secondary"
            onClick={() => hClickSearchArea()}
            disabled={areaBtnDisabled}
          />
          )}
          {showType === 'map'
            ? (
              <CustomerMapListPC
                onClickCard={hClickCard}
                list={mapList}
                page={page}
                isSearch={isSearch}
                hitCount={hitCount}
                type={customerShowType}
                callback={(v) => {
                  setCustomerShowType(v);
                  dispatch(CustomerActions.setSort({
                    ...sortState,
                    ob_flag: v,
                  }));
                  hClickSearchBtn({
                    ...sortState,
                    ob_flag: v,
                  });
                }}
                callbackPagination={hClickPagination}
                callbackZoomEnd={zoomEnd}
                callbackCenterChanged={centerChanged}
                onGetGps={(v) => initMapSearch(v)}
              />
            )
            : (
              <>
                <TableSort
                  page={sortState.offset ?? 0}
                  limit={sortState.limit as Limit}
                  hitCount={listHitCount}
                  callback={pagerSort}
                />
                <CustomerListPC
                  type={customerShowType}
                  callbackSort={headerSort}
                  callbackHitCount={((v) => setHitCount(v || 0))}
                  parentSelect={select}
                  callbackSelect={setSelect}
                  callbackList={setListList}
                />
                <TableSort
                  className="bottom"
                  page={sortState.offset ?? 0}
                  limit={sortState.limit as Limit}
                  hitCount={listHitCount}
                  callback={pagerSort}
                />
              </>
            )}
        </div>
      </div>
      <footer className="btn_area">
        <div className="left_box">
          <LeftIconButton
            label="顧客新規登録"
            size="md"
            fontAwesomeClass="fas fa-edit"
            className="btn_search for_detail"
            color="primary"
            onClick={handleClickRegistration}
          />
          <LeftIconButton
            label="CSV出力"
            size="md"
            fontAwesomeClass="fas fa-file-download"
            className="btn_search for_detail"
            color="primary"
            onClick={() => {
              const isMap = shoType === 'map';
              if (!isMap) {
                if (!listList || !listList.length) return;
                const csvParam = select.length
                  ? {
                    ids: select.map((v) => listList[v].id),
                  } : {
                    ...CustomerModel.listParam({ sortState }),
                  };
                if (!csvParam) return;
                Object.assign(csvParam, {
                  highlow: sortState.highlow,
                });
                dispatch(CsvActions.api.customer.download(csvParam));
              } else {
                if (!customerList || !customerList.length) return;
                const csvParam = {
                  ...CustomerModel.listParam({ sortState }),
                };
                dispatch(CsvActions.api.customer.download(csvParam));
              }
            }}
          />
        </div>
        <div className="right_box">
          <LeftIconButton
            fontAwesomeClass={`${showType === 'map' ? 'fas fa-list' : 'fas fa-map'}`}
            label={`${showType === 'map' ? 'リスト表示' : '地図表示'}`}
            size="md"
            className="btn_search for_detail"
            color="primary"
            onClick={hClickShowType}
          />
        </div>
      </footer>
    </BasePagePC>
  );
};
