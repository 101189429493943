import DragHandleIcon from '@mui/icons-material/DragHandle';
import './ghost.scss';
import { MathHelper as MH } from '../../../../../utilities/math-helper';

type Props = {
  name: string;
  shikiri_kakaku: string;
  tani_name: string;
  kingaku: string;
  suryou: string;
}

export const CardPreview = ({
  name,
  shikiri_kakaku,
  suryou,
  tani_name,
  kingaku,
}: Props) => (
  <div className="estimate_detail_card_sp card_preview">
    <div className="col1"><DragHandleIcon /></div>
    <div className="col2">
      <div className="col2__row1">{name || '---'}</div>
      <div className="col2__row2">
        <div className="col2__row2__con1">
          <span className="nowrap">
            &yen;&nbsp;
            {MH.rounding2Str(shikiri_kakaku ? Number(shikiri_kakaku) : 0, 0, 'round', true)}
          </span>
          &nbsp;×&nbsp;
          <span className="nowrap">{MH.rounding2Str(suryou ? Number(suryou) : 0, 2, 'round', true)}
            {tani_name}
          </span>
        </div>
        <div className="col2__row2__con2">
          <span className="nowrap">&yen;&nbsp;
            {MH.rounding2Str(kingaku ? Number(kingaku) : 0, 0, 'round', true)}
          </span>
        </div>
      </div>
    </div>
  </div>
);
