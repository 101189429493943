import { Validation } from './validation';

export const ValidationAccounts = (count: number | null, limitCount: number): Validation[] => [
  new Validation(
    (v) => {
      if (count === null) return true;
      return count <= limitCount;
    },
    `既にアカウント制限値を超えています。(現在の社員数：${count}人)`,
  )];
