import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DialogActions } from '../../../../../../../redux/dialog/dialog.action';
import { MasterEditDialogProps } from '../../../master.type';
import { Input } from '../../../../../../ui/input/input';
import { Required } from '../../../../../../ui/required/required';
import { Checkbox } from '../../../../../../ui/checkbox/checkbox';
import './support-history-master-edit-dialog.scss';
import { useDidMount } from '../../../../../../../hooks/life-cycle';
import { MasterActions } from '../../../../../../../redux/master/master.action';
import { MasterSupportHistoryValidation } from '../../../../../../../model/validation/master/master-support-history.validation';
import { EditPC } from '../../../../../../dialogs/edit/edit.pc';
import { State } from '../../../../../../../redux/root.reducer';
import { Message } from '../../../../../../../collection/message.collection';
import { ValidationLengthUnder255 } from '../../../../../../../model/validation';

export const SupportHistoryMasterEditDialog = (props: MasterEditDialogProps) => {
  const { id, callback } = props;

  const dispatch = useDispatch();

  const list = useSelector((state:State) => state.master.supportHistoryList);

  const [supported, setSupported] = useState('');
  const [validFlag, setValidFlag] = useState(true);

  const [touch, setTouch] = useState(false);

  const handleClickPost = useCallback(() => {
    if (MasterSupportHistoryValidation(
      supported,
    )) {
      dispatch(DialogActions.pushMessage({
        title: '対応履歴 媒体 編集／追加',
        message: Message.postError,
        callback: () => setTouch(true),
      }));
      return;
    }

    dispatch(MasterActions.api.supportHistory.post({
      param: {
        data: {
          supported,
          is_valid: validFlag ? 1 : 0,
        },
        id,
      },
      onSuccess: () => {
        callback();
        dispatch(DialogActions.pop());
      },
      onError: () => {
        setTouch(true);
      },
    }));
  }, [
    supported,
    validFlag,
    callback,
  ]);

  useDidMount(() => {
    if (id) {
      const data = list.find((f) => Number(f.id) === id);
      if (!data) return;
      setSupported(data.supported);
      setValidFlag(data.valid_flag);

      // dispatch(MasterActions.api.supportHistory.get({
      //   param: { id },
      //   onSuccess: (data) => {
      //     if (!data) return;
      //     setSupported(data.supported);
      //     setValidFlag(data.valid_flag);
      //   },
      // }));
    }
  });

  return (
    <EditPC mode="dialog" callback={handleClickPost} label={id ? '更新' : '登録'}>
      <div className="edit_pc_body_inner edit_master">
        <div>
          <div className="item_wrap">
            <div className="item_box">
              <div className="item_head">カテゴリ<Required /></div>
              <Input
                className=""
                errorPosBottom
                require
                value={supported}
                onChange={(e) => setSupported(e.target.value)}
                validationList={ValidationLengthUnder255}
                touch={touch}
              />
            </div>
          </div>
          <div className="item_wrap">
            <div className="item_box">
              <div className="item_head">有効フラグ</div>
              <Checkbox
                label=""
                className="is_valid"
                checked={!!validFlag}
                onChange={() => setValidFlag(!validFlag)}
              />
            </div>
          </div>
        </div>
      </div>
    </EditPC>
  );
};
