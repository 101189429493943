/* eslint-disable no-undef */
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../../../../redux/root.reducer';
import {
  openTwitter, openInstagram, openFacebook, openLink,
} from '../../../../utilities/open-link';
import { IconButton } from '../../button/icon-button/icon-button';
import '../info-tab-contents.sp.scss';
import { DateFormatter as DF } from '../../../../utilities/date-formatter';
import { splitStr } from '../../../../utilities/split-str';
import { DialogActions } from '../../../../redux/dialog/dialog.action';
import { Item, SnsItem, TelItem } from './item';
import { FamilyInfoCard } from '../../card/family-info/family-info-card';
import { PetInfoCard } from '../../card/family-info/pet-info-card';
import { useMailContact, useTelContact, useSNSContact } from '../../../../hooks/support-history/use-support-history';
import { useEditAuthCustomer } from '../../../../hooks/use-authority';
import { Tikunengetsu } from '../../tikunengetsu/tikunengetsu';

type Props = {
  callbackSNS?: () => void;
  callbackPostEnd?: () => void;
}

export const CustomerInfoTabContents = (props: Props) => {
  const { callbackSNS, callbackPostEnd } = props;

  /* Hooks */
  const customerInfo = useSelector((state:State) => state.customer.customer);
  const familyList = useSelector((state:State) => state.customer.familyList);
  const petList = useSelector((state:State) => state.customer.petList);
  const dispatch = useDispatch();
  const telContact = useTelContact();
  const emailContact = useMailContact();
  const snsContact = useSNSContact();

  /* Callback */
  const handleClickMail = useCallback((mail: string | undefined) => {
    if (!customerInfo) return;
    emailContact({
      type: 'customer',
      data: customerInfo,
      mailAddress: mail,
      isPushDialog: true,
      callbackPostEnd: () => {
        callbackSNS?.();
        callbackPostEnd?.();
      },
    });
  }, [customerInfo, callbackPostEnd, callbackSNS]);

  const handleClickSMS = useCallback((tel: string | undefined) => {
    if (!customerInfo) return;
    telContact({
      type: 'customer',
      data: customerInfo,
      tel,
      isSMS: true,
      isPushDialog: true,
      callbackPostEnd: () => {
        callbackSNS?.();
        callbackPostEnd?.();
      },
    });
  }, [customerInfo]);

  const handleClickPhone = useCallback((tel: string | undefined) => {
    if (!customerInfo) return;
    telContact({
      type: 'customer',
      data: customerInfo,
      tel,
      isPushDialog: true,
      callbackPostEnd: () => {
        callbackSNS?.();
        callbackPostEnd?.();
      },
    });
  }, [customerInfo]);

  const handleClickLine = useCallback(() => {
    if (!customerInfo || !customerInfo.line_id) return;
    snsContact({
      mode: 'LINE',
      name: customerInfo.name,
      customerId: customerInfo.id,
      id: customerInfo.line_id,
      callbackPostEnd: () => {
        callbackSNS?.();
        callbackPostEnd?.();
      },
    });
  }, [customerInfo, callbackSNS, callbackPostEnd]);

  return (
    <div className="detail_info_table row_table_style">
      <Item title="顧客名" value={customerInfo?.name ? `${customerInfo?.name} 様` : '---'} />
      <Item title="顧客名（カナ）" value={customerInfo?.furigana} />
      <Item title="顧客ID" value={customerInfo?.internal_id} />
      <Item
        title="住所"
        value={(
          <>
            {customerInfo?.post_no ? `〒 ${customerInfo?.post_no}` : '---'}<br />
            {customerInfo?.prefecture_name}{customerInfo?.city}{customerInfo?.address}
            {customerInfo?.building_name}
          </>
        )}
      />
      <TelItem title="電話番号" value={customerInfo?.tel_no} callback={() => handleClickPhone(customerInfo?.tel_no)} callbackSMS={() => handleClickSMS(customerInfo?.tel_no)} />
      <TelItem title="電話番号２" value={customerInfo?.tel_no2} callback={() => handleClickPhone(customerInfo?.tel_no2)} callbackSMS={() => handleClickSMS(customerInfo?.tel_no2)} />
      <TelItem title="電話番号３" value={customerInfo?.tel_no3} callback={() => handleClickPhone(customerInfo?.tel_no3)} callbackSMS={() => handleClickSMS(customerInfo?.tel_no3)} />
      <Item
        title="Email"
        className="email flex_box flex_space_between flex_align_center"
        value={(
          <>
            <div>{customerInfo?.mail_address || '---'}</div>
            <IconButton
              fontAwesomeClass="fas fa-envelope"
              onClick={() => handleClickMail(customerInfo?.mail_address)}
              size="md"
              color="secondary"
              disabled={!customerInfo?.mail_address}
            />
          </>
        )}
      />

      <div className="t_row">
        <div className="t_header">Email２</div>
        <div className="t_body">
          {customerInfo?.mail_address2 || '---'}
        </div>
        <div>
          <IconButton
            fontAwesomeClass="fas fa-envelope"
            onClick={() => handleClickMail(customerInfo?.mail_address2)}
            size="md"
            color="secondary"
            disabled={!customerInfo?.mail_address2}
          />
        </div>
      </div>

      <div className="t_row">
        <div className="t_header">Email３</div>
        <div className="t_body">
          {customerInfo?.mail_address3 || '---'}
        </div>
        <div>
          <IconButton
            fontAwesomeClass="fas fa-envelope"
            onClick={() => handleClickMail(customerInfo?.mail_address3)}
            size="md"
            color="secondary"
            disabled={!customerInfo?.mail_address3}
          />
        </div>
      </div>
      <Item title="FAX" value={customerInfo?.fax_no} />
      <Item title="営業担当店舗" value={customerInfo?.store_name} />
      <Item title="営業担当者" value={customerInfo?.employee_name} />
      <SnsItem
        title="LINE ID"
        value={customerInfo?.line_id}
        callback={handleClickLine}
      />
      <SnsItem
        title="FaceBook ID"
        value={customerInfo?.facebook_id}
        callback={() => {
          if (!customerInfo || !customerInfo.facebook_id) return;
          snsContact({
            mode: 'Facebook',
            name: customerInfo.name,
            customerId: customerInfo.id,
            id: customerInfo.facebook_id,
            callbackPostEnd: () => {
              callbackSNS?.();
              callbackPostEnd?.();
            },
          });
        }}
      />
      <SnsItem
        title="Twitter ID"
        value={customerInfo?.twitter_id}
        callback={() => {
          if (!customerInfo || !customerInfo.twitter_id) return;
          snsContact({
            mode: 'Twitter',
            name: customerInfo.name,
            customerId: customerInfo.id,
            id: customerInfo.twitter_id,
            callbackPostEnd: () => {
              callbackSNS?.();
              callbackPostEnd?.();
            },
          });
        }}
      />
      <SnsItem
        title="Instagram ID"
        value={customerInfo?.instagram_id}
        callback={() => {
          if (!customerInfo || !customerInfo.twitter_id) return;
          snsContact({
            mode: 'Instagram',
            name: customerInfo.name,
            customerId: customerInfo.id,
            id: customerInfo.twitter_id,
            callbackPostEnd: () => {
              callbackSNS?.();
              callbackPostEnd?.();
            },
          });
        }}
      />
      <Item title="顧客ランク" value={customerInfo?.rank_name} />
      <Item title="見込みランク" value={customerInfo?.estimated_rank_name} />
      <Item title="発生源" value={customerInfo?.source_name} />
      <Item title="エリア" value={customerInfo?.area_name} />
      <Item title="見込み部位" value={splitStr(customerInfo?.expected_part_list_name)} />
      <Item title="部位" value={splitStr(customerInfo?.part_list_name)} />
      <Item title="建物分類" value={customerInfo?.building_category_name} />
      <Item title="間取り" value={customerInfo?.madori_name} />
      <div className="t_row">
        <div className="t_header">築年数</div>
        <div className="t_body">
          <Tikunengetsu
            date={
                    customerInfo?.construction_info
                      ? new Date(customerInfo.construction_info)
                      : null
                  }
            isList
          />
        </div>
      </div>
      <Item title="備考" value={customerInfo?.remarks} />
      <Item title="メモ１" value={customerInfo?.memo1} />
      <Item title="メモ２" value={customerInfo?.memo2} />
      <Item title="マイカー種別" value={splitStr(customerInfo?.my_car_type_name)} />
      <Item title={<>マイカー種別<br />（その他）</>} value={customerInfo?.my_car_type_other} />
      <Item title="関連タグ" value={splitStr(customerInfo?.tag_list_name)} />
      <Item title={<>関連タグ<br />（その他）</>} value={customerInfo?.tag_list_other} />
      <Item title="紹介者" value={customerInfo?.introducer} />
      <Item title="結婚記念日" value={DF.date2str(customerInfo?.wedding_anniversary)} />
      <div className={`t_row ${customerInfo?.familylist.length ? 'dir_column' : ''}`}>
        <div className="t_header">ご家族情報</div>
        <div className="t_body">
          {customerInfo?.familylist.length ? customerInfo?.familylist.map((v) => <FamilyInfoCard familyInfo={v} isActive={false} />) : '未入力'}
        </div>
      </div>
      <div className={`t_row ${customerInfo?.petlist.length ? 'dir_column' : ''}`}>
        <div className="t_header">ペット情報</div>
        <div className="t_body">
          {customerInfo?.petlist.length ? customerInfo?.petlist.map((v) => <PetInfoCard petInfo={v} isActive={false} />) : '未入力'}
        </div>
      </div>
      <Item title="登録日" value={DF.date2str(customerInfo?.created_at, 'YYYYMMDD_HHmm', '/')} />
      <Item title="登録者" value={customerInfo?.created_employee_name} />
      <Item title="更新日" value={DF.date2str(customerInfo?.updated_at, 'YYYYMMDD_HHmm', '/')} />
      <Item title="最終更新者" value={customerInfo?.updated_employee_name} />
    </div>
  );
};
