import {
  useState, useEffect,
  useCallback,
  useMemo,
} from 'react';
import { useDispatch } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';
import { RightLabelCheckbox } from '../../../../ui/checkbox/right-label-checkbox/right-label-checkbox';
import { TopLabelInputField } from '../../../../ui/input-field/top-label-input-field/top-label-input-field';
import { Input } from '../../../../ui/input/input';
import { SearchBox } from '../../../layout/search-box/search-box.sp';
import './customer-search-box.sp.scss';
import { prefectures } from '../../../../../collection/prefectures';
import { TagActions } from '../../../../../redux/tag/tag.action';
import { CustomerActions } from '../../../../../redux/customer/customer.action';
import { TagModel } from '../../../../../model/tag/tag';
import { CustomerSortState } from '../../../../../type/customer/customer.type';
import { Select } from '../../../../ui/select/select';
import { TextArea } from '../../../../ui/text-area/text-area';
import { MasterActions } from '../../../../../redux/master/master.action';
import { CommonCollection } from '../../../../../collection/common/common.collection';
import { MapActions } from '../../../../../redux/map/map.action';
import { DatePicker } from '../../../../ui/date-picker/date-picker';
import { useAppSelector } from '../../../../../hooks/use-redux';
import { LabelInput } from '../../../../ui/input/label-input';
import { pulldown, pulldownRank } from '../../../../../utilities/pulldown';
import { TagCheckBoxex } from '../../../../ui/tag-check-boxex/tag-check-boxex';
import { DialogActions } from '../../../../../redux/dialog/dialog.action';
import { InputTel } from '../../../../ui/input/input-tel';
import { RightLabelInputField } from '../../../../ui/input-field/right-label-input-field/right-label-input-field';
import { InputNum } from '../../../../ui/input/input-num';
import { Button } from '../../../../ui/button/button';
import { CustomerCollection } from '../../../../../collection/customer/customer.collection';
import { sortEmployee, changeStore } from '../../../../../utilities/sort-employee';
import { InputPostNoMulti } from '../../../../ui/input/input-post-no-multi';
import { InputSelect } from '../../../../ui/input-select/input-select';
import { useDateValidation } from '../../../../../hooks/use-date-validation';
import { DateTermValidation } from '../../../../../model/validation/date/term-date.validation';

type Props = {
  getList?: (data: CustomerSortState, isSearch: boolean) => void;
  isSearch?: boolean;
  type?: 0 | 1 | 2;
  freeAuth?: boolean;
  noAuthority?: boolean;
  callbackType?: (type: 0 | 1 | 2) => void;
}

export const SearchBoxCustomerSP = (props:Props) => {
  const {
    getList, isSearch, type, callbackType, freeAuth, noAuthority,
  } = props;

  /* Hooks */
  const user = useAppSelector((v) => v.user);
  const { relevantTagList, partList, sortState } = useAppSelector((v) => ({
    relevantTagList: v.tag.relevantTagList,
    partList: v.tag.partList,
    sortState: v.customer.sort,
  }));
  const {
    employeeList,
    areaList,
    storeList,
    customerKojiRankList: customerRankList,
    customerRankLastList,
    originList,
    customerEstimatedRankList: customerEstimatedRank,
    buildingCategoryList,
    madoriList,
  } = useAppSelector((v) => (v.master));

  const dispatch = useDispatch();

  /* State */
  const [sort, setSort] = useState<CustomerSortState>(isSearch
    ? CustomerCollection._customerSortInitialState(user)
    : cloneDeep(sortState));
  // const _employeeList = useMemo(() => employeeList.filter((
  //   v,
  // ) => (Number.isNaN(sort.store_id) || sort.store_id === null
  //   ? true
  //   : (v.store_id === sort.store_id))),
  // [sort.store_id, employeeList]);

  const termDate = useDateValidation([
    { start: sort.building_age_start, end: sort.building_age_end },
    { start: sort.completion_date_start, end: sort.completion_date_end },
    { start: sort.last_completion_date_start, end: sort.last_completion_date_end },
    { start: sort.wedding_anniversary_start, end: sort.wedding_anniversary_end },
    { start: sort.created_at_start, end: sort.created_at_end },
    { start: sort.updated_at_start, end: sort.updated_at_end },
  ]);

  const [_type, setType] = useState(cloneDeep(type));

  /* Callback */
  const handleClickSearch = useCallback(() => {
    if (termDate.messages.length) {
      termDate.check();
      return;
    }
    dispatch(MapActions.setGpsStatus('out'));
    getList?.(sort, true);
    if (!isSearch) {
      dispatch(CustomerActions.setSort(cloneDeep(sort)));
    }

    if (_type === undefined) return;
    callbackType?.(_type);
  }, [getList, sort, _type, callbackType, termDate]);

  const setState = useCallback((v: Partial<CustomerSortState>) => {
    const data = { ...cloneDeep(sort), ...cloneDeep(v) };
    setSort(data);
  }, [sort]);

  // const _employeeList = useMemo(() => {
  //   const filter = ((
  //     Number.isNaN(sort.store_id) || sort.store_id === null
  //   ) ? employeeList
  //     : employeeList.filter((v) => v.store_id === sort.store_id));
  //   if (
  //     (!Number.isNaN(sort.store_id) && sort.store_id !== null)
  //   ) {
  //     console.log('koko');
  //     setState({ employee_id: NaN });
  //   }
  //   return filter;
  // },
  // [employeeList, sort]);

  /* Effect */
  useEffect(() => {
    dispatch(TagActions.api.part.getList());
    dispatch(TagActions.api.relevantTag.getList());
    dispatch(MasterActions.api.store.getList({}));
    dispatch(MasterActions.api.area.getList({}));
    dispatch(MasterActions.api.employee.getList({}));
    dispatch(MasterActions.api.origin.getList({}));
    dispatch(MasterActions.api.customerKojiRank.getList({}));
    dispatch(MasterActions.api.customerRankLast.getList({}));
    dispatch(MasterActions.api.customerExpectedRank.getList({}));
    dispatch(MasterActions.api.buildingCategory.getList({}));
    dispatch(MasterActions.api.madori.getList({}));
  }, []);

  /* タグ */
  useEffect(() => {
    setState({
      parts: sortState.parts?.data.length
        ? sortState.parts : new TagModel(partList),
      tags: sortState.tags?.data.length
        ? sortState.tags : new TagModel(relevantTagList),
      rank_filter: sort.rank_filter || 1,
      estimated_rank_filter: sort.estimated_rank_filter || 1,
    });
  }, [partList, relevantTagList, sort.rank, sort.estimated_rank]);

  return (
    <SearchBox
      callback={handleClickSearch}
      callbackClear={() => {
        setState({
          ...CustomerCollection._customerSortInitialState(user),
          parts: new TagModel(partList),
          tags: new TagModel(relevantTagList),
        });
        setType(0);
      }}
    >
      {/* search_box_body_inner は各画面の共通用 */}
      <div className="search_box_sp_body_inner search_box_customer_sp">
        <div className="category_wrap">
          <div className="item_wrap">
            <TopLabelInputField
              label="顧客名"
              value={sort?.name}
              maxLength={60}
              onChange={(e) => setState({ name: e.target.value })}
              className="full_width"
            />
          </div>
          <div className="item_wrap">
            <TopLabelInputField
              label="顧客名（フリガナ）"
              value={sort?.furigana}
              maxLength={60}
              onChange={(e) => setState({ furigana: e.target.value })}
              className="full_width"
            />
          </div>
          <div className="item_wrap">
            <LabelInput pos="Top" label="顧客TEL">
              <InputTel
                value={sort?.tel_no}
                onChange={(v) => setState({ tel_no: v })}
                className="full_width"
              />
            </LabelInput>
          </div>
          <div className="item_wrap">
            <div className="item_label">郵便番号</div>
            <div className="item_body item_postal">
              <InputPostNoMulti
                value={sort?.post_no[0]}
                onChange={(v) => setState({
                  post_no: [
                    v,
                    sort.post_no[1],
                  ],
                })}
                value2={sort?.post_no[1]}
                onChange2={(v) => setState({
                  post_no: [
                    sort.post_no[0],
                    v,
                  ],
                })}
                isSp
              />
            </div>
          </div>
          <div className="item_wrap">
            <div className="item_label">顧客都道府県</div>
            <div className="item_body item_select full_width">
              <Select
                value={sort?.prefecture}
                onChange={(data) => setState({ prefecture: Number(data) })}
                options={[
                  { text: '全て', value: NaN },
                  ...prefectures.map((v) => (
                    { text: v.label, value: v.value }
                  )),
                ]}
              />
            </div>
          </div>
          <div className="item_wrap">
            <TopLabelInputField
              label="顧客住所"
              value={sort?.address}
              maxLength={161}
              onChange={(e) => setState({ address: e.target.value })}
              className="full_width"
            />
          </div>
        </div>
        <div className="category_wrap">
          <div className="item_wrap">
            <div className="item_label">顧客ランク</div>
            <div className="item_body item_customerRank">
              <Select
                value={sort?.rank}
                onChange={(data) => setState({ rank: String(data) })}
                defaultLabel="全て"
                options={pulldownRank(customerRankList, customerRankLastList)}
              />
              <Select
                value={sort?.rank_filter}
                onChange={(v) => {
                  setState({
                    rank: String(v),
                    rank_filter: !String(v) ? 1 : sort.rank_filter,
                  });
                }}
                options={CommonCollection.pullDownFilterList}
                disabled={!sort?.rank}
              />
            </div>
          </div>
          <div className="item_wrap">
            <div className="item_label">顧客見込みランク</div>
            <div className="item_body item_customerRank">
              <Select
                value={sort?.estimated_rank}
                onChange={(data) => setState({

                  estimated_rank: Number(data),
                })}
                defaultLabel="全て"
                options={customerEstimatedRank.map((v) => ({
                  text: v.name, value: v.id,
                }))}
              />
              <Select
                value={sort?.estimated_rank_filter}
                onChange={(data) => setState({
                  estimated_rank: Number(data),
                  estimated_rank_filter: !Number(data) ? 1 : sort.estimated_rank_filter,
                })}
                options={CommonCollection.pullDownFilterList}
                disabled={!sort?.estimated_rank_filter}
              />
            </div>
          </div>
          <div className="item_wrap">
            <div className="item_label">エリア</div>
            <div className="item_body item_area">
              {/* <Select
                value={sort?.area_id}
                onChange={(data) => setState({ area_id: Number(data) })}
                defaultLabel="全て"
                options={areaList.map((v) => ({ text: v.name, value: v.id }))}
              /> */}
              <InputSelect
                value={sort?.area_id}
                onChange={(data) => setState({ area_id: Number(data) })}
                defaultValue="全て"
                options={areaList.map((v) => ({ label: v.name, id: v.id }))}
                className="react_select"
              />
            </div>
          </div>
          <div className="item_wrap">
            <div className="item_label">店舗</div>
            <div className="item_body full_width">
              <Select
                value={noAuthority ? user.store_id : sort?.store_id}
                onChange={(data) => {
                  setState(
                    changeStore({
                      employeeList,
                      storeId: Number(data),
                      storeKey: 'store_id',
                      returnStoreKey: 'store_id',
                      employeeKey: 'employee_id',
                    }),
                  );
                  // setState({
                  //   store_id: Number(data),
                  //   employee_id: Number.isNaN(Number(data))
                  //     || Number(data) === user.store_id
                  //     ? user.id
                  //     : NaN,
                  // });
                }}
                defaultLabel="全て"
                disabled={noAuthority}
                options={pulldown(storeList)}
              />
            </div>
          </div>
          <div className="item_wrap">
            <div className="item_label">担当者</div>
            <div className="item_body full_width">
              <Select
                value={noAuthority ? user.id : sort?.employee_id}
                onChange={(data) => setState({ employee_id: Number(data) })}
                defaultLabel="全て"
                options={pulldown(sortEmployee({
                  employeeList,
                  storeId: sort?.store_id,
                  storeKey: 'store_id',
                }))}
                disabled={noAuthority}
              />
            </div>
          </div>
        </div>

        <div className="category_wrap">
          <div className="item_wrap">
            <div className="item_label">建物分類</div>
            <div className="item_body full_width">
              <Select
                value={sort?.building_category_id}
                onChange={(data) => setState({ building_category_id: Number(data) })}
                defaultLabel="全て"
                options={buildingCategoryList.map((v) => ({ text: v.name, value: v.id }))}
              />
            </div>
          </div>
          <div className="item_wrap">
            <div className="item_label">間取り</div>
            <div className="item_body item_madori">
              <Select
                value={sort?.madori_id}
                onChange={(data) => setState({ madori_id: Number(data) })}
                defaultLabel="全て"
                options={madoriList.map((v) => ({ text: v.name, value: v.id }))}
              />
              <div className="dummy" />
            </div>
          </div>
          <div className="item_wrap">
            <div className="item_label">築年数</div>
            <div className="item_body item_schedule wrap">
              <div className="item_schedule__form">
                <DatePicker
                  date={sort?.building_age_start}
                  onChange={(v) => setState({ building_age_start: v })}
                  type="YYYY/MM"
                  term={DateTermValidation([{
                    start: sort.building_age_start, end: sort.building_age_end,
                  }])}
                />
              </div>
              <div className="item_schedule__form flex_box flex_align_center mt_10">
                <div className="item_schedule__tilde">〜</div>
                <DatePicker
                  date={sort?.building_age_end}
                  onChange={(v) => setState({ building_age_end: v })}
                  type="YYYY/MM"
                  term={DateTermValidation([{
                    start: sort.building_age_start, end: sort.building_age_end,
                  }])}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="category_wrap">
          <div className="item_wrap">
            <div className="item_label">完工時期</div>
            <div className="item_body item_schedule wrap">
              <div className="item_schedule__form">
                <DatePicker
                  date={sort.completion_date_start}
                  onChange={(v) => setState({ completion_date_start: v })}
                  type="YYYY/MM"
                  term={DateTermValidation([{
                    start: sort.completion_date_start, end: sort.completion_date_end,
                  }])}
                />
              </div>
              <div className="item_schedule__form flex_box flex_align_center mt_10">
                <div className="item_schedule__tilde">〜</div>
                <DatePicker
                  date={sort.completion_date_end}
                  onChange={(v) => setState({ completion_date_end: v })}
                  type="YYYY/MM"
                  term={DateTermValidation([{
                    start: sort.completion_date_start, end: sort.completion_date_end,
                  }])}
                />
              </div>
            </div>
          </div>
          <div className="item_wrap">
            <div className="item_label">最終完工時期</div>
            <div className="item_body item_schedule wrap">
              <div className="item_schedule__form">
                <DatePicker
                  date={sort.last_completion_date_start}
                  onChange={(v) => setState({ last_completion_date_start: v })}
                  type="YYYY/MM"
                  term={DateTermValidation([{
                    start: sort.last_completion_date_start, end: sort.last_completion_date_end,
                  }])}
                />
              </div>
              <div className="item_schedule__form flex_box flex_align_center mt_10">
                <div className="item_schedule__tilde">〜</div>
                <DatePicker
                  date={sort.last_completion_date_end}
                  onChange={(v) => setState({ last_completion_date_end: v })}
                  type="YYYY/MM"
                  term={DateTermValidation([{
                    start: sort.last_completion_date_start, end: sort.last_completion_date_end,
                  }])}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="category_wrap">
          <div className="item_wrap">
            <div className="item_label">総工事金額</div>
            <LabelInput pos="Right" label="万円">
              <InputNum
                value={sort.total_work_price_min}
                onChange={(v) => setState({ total_work_price_min: v })}
                onBlur={(v) => setState({
                  total_work_price_min: v ? Number(v).toLocaleString() : '',
                })}
                minus
              />
            </LabelInput>
            <div className="flex_box flex_align_center mt_10">
              <label className="ml_5 mr_5">〜</label>
              <LabelInput pos="Right" label="万円">
                <InputNum
                  value={sort.total_work_price_max}
                  onChange={(v) => setState({ total_work_price_max: v })}
                  onBlur={(v) => setState({
                    total_work_price_max: v ? Number(v).toLocaleString() : '',
                  })}
                  minus
                />
              </LabelInput>
            </div>
          </div>
        </div>
        <div className="category_wrap">
          <div className="item_wrap">
            <div className="item_label">工事回数</div>
            <LabelInput pos="Right" label="回">
              <InputNum
                value={sort.work_times_min}
                onChange={(v) => setState({ work_times_min: v })}
                onBlur={(v) => setState({
                  work_times_min: v ? Number(v).toLocaleString() : '',
                })}
                minus
              />
            </LabelInput>
            <div className="flex_box flex_align_center mt_10">
              <label className="ml_5 mr_5">〜</label>
              <LabelInput pos="Right" label="回">
                <InputNum
                  value={sort.work_times_max}
                  onChange={(v) => setState({ work_times_max: v })}
                  onBlur={(v) => setState({
                    work_times_max: v ? Number(v).toLocaleString() : '',
                  })}
                  minus
                />
              </LabelInput>
            </div>
          </div>
        </div>
        <div className="category_wrap">
          <div className="item_wrap tags_form">
            <TagCheckBoxex
              head="関連タグ"
              keys="rTag"
              model={sort?.tags}
              onChange={(v) => setState({ tags: v })}
            />
          </div>
          <div className="item_wrap tags_form">
            <TagCheckBoxex
              head="部位"
              keys="pTag"
              model={sort?.parts}
              onChange={(v) => setState({ parts: v })}
            />
          </div>
          <div className="item_wrap">
            <TopLabelInputField
              label="社内メモ"
              value={sort.memo}
              onChange={(e) => setState({ memo: e.target.value })}
              className="full_width"
            />
          </div>
          <div className="item_wrap">
            <LabelInput
              pos="Top"
              className="full_width"
              type="email"
              label="メールアドレス"
              value={sort.mail_address}
              onChange={(v) => setState({ mail_address: v })}
              noValidation
            />
          </div>
          <div className="item_wrap">
            <TopLabelInputField
              label="LINE ID"
              value={sort.line_id}
              onChange={(e) => setState({ line_id: e.target.value })}
              className="full_width"
            />
          </div>
          <div className="item_wrap">
            <TopLabelInputField
              label="FaceBook ID"
              value={sort.facebook_id}
              onChange={(e) => setState({ facebook_id: e.target.value })}
              className="full_width"
            />
          </div>
          <div className="item_wrap">
            <TopLabelInputField
              label="Twitter ID"
              value={sort.twitter_id}
              onChange={(e) => setState({ twitter_id: e.target.value })}
              className="full_width"
            />
          </div>
          <div className="item_wrap">
            <TopLabelInputField
              label="Instagram ID"
              value={sort.instagram_id}
              onChange={(e) => setState({ instagram_id: e.target.value })}
              className="full_width"
            />
          </div>
          <div className="item_wrap">
            <div className="item_label">発生源</div>
            <div className="item_body item_madori">
              <Select
                value={sort.source_id}
                onChange={(v) => setState({ source_id: Number(v) })}
                defaultLabel="全て"
                options={pulldown(originList)}
              />
              <div className="dummy" />
            </div>
          </div>
          <div className="item_wrap">
            <TopLabelInputField
              label="紹介者"
              value={sort.introducer}
              onChange={(e) => setState({ introducer: e.target.value })}
              className="full_width"
            />
          </div>
          <div className="item_wrap">
            <div className="item_label">結婚記念日</div>
            <div className="item_body item_schedule wrap">
              <div className="item_schedule__form">
                <DatePicker
                  date={sort?.wedding_anniversary_start}
                  onChange={(v) => setState(
                    { wedding_anniversary_start: v },
                  )}
                  type="YYYY/MM"
                  term={DateTermValidation([{
                    start: sort.wedding_anniversary_start, end: sort.wedding_anniversary_end,
                  }])}
                />
              </div>
              <div className="item_schedule__form flex_box flex_align_center mt_10">
                <div className="item_schedule__tilde">〜</div>
                <DatePicker
                  date={sort?.wedding_anniversary_end}
                  onChange={(v) => setState(
                    { wedding_anniversary_end: v },
                  )}
                  type="YYYY/MM"
                  term={DateTermValidation([{
                    start: sort.wedding_anniversary_start, end: sort.wedding_anniversary_end,
                  }])}
                />
              </div>
            </div>
          </div>
          <div className="item_wrap">
            <div className="item_label">顧客分類</div>
            <div className="item_body item_madori">
              <Select
                value={_type === undefined ? 0 : _type}
                onChange={(v) => setType(Number(v) as 0 | 1 | 2)}
                options={[
                  { text: '全て', value: 0 },
                  { text: 'OB', value: 1 },
                  { text: '見込み', value: 2 },
                ]}
              />
              <div className="dummy" />
            </div>
          </div>
          <div className="item_wrap">
            <div className="item_label">備考</div>
            <div className="item_body full_width">
              <TextArea
                rows={4}
                value={sort?.remarks}
                onChange={(e) => setState(
                  { remarks: e.target.value },
                )}
              />
            </div>
          </div>
          {/* <div className="item_wrap tags_form">
            <div className="item_label">不備情報</div>
            <div className="item_body item_checkbox">
              <RightLabelCheckbox
                checked={sort?.input_comp_flag}
                label="不備情報のみ"
                onChange={() => {
                  setState({ input_comp_flag: !sort.input_comp_flag });
                }}
                className="single_column"
              />
            </div>
          </div> */}
        </div>
        <div className="category_wrap">
          <div className="item_wrap">
            <div className="item_label">登録者</div>
            <div className="item_body item_madori">
              <Select
                defaultLabel="全て"
                className="fixed"
                value={sort.created_employee_id}
                onChange={(v) => setState({ created_employee_id: Number(v) })}
                options={pulldown(employeeList)}
              />

            </div>
          </div>
          <div className="item_wrap">
            <div className="item_label">登録日</div>
            <div className="item_body item_schedule wrap">
              <div className="item_schedule__form">
                <DatePicker
                  date={sort.created_at_start}
                  onChange={(v) => setState({ created_at_start: v })}
                  type="YYYY/MM/DD"
                  term={DateTermValidation([
                    { start: sort.created_at_start, end: sort.created_at_end },
                  ])}
                />
              </div>
              <div className="item_schedule__form flex_box flex_align_center mt_10">
                <div className="item_schedule__tilde">〜</div>
                <DatePicker
                  date={sort.created_at_end}
                  onChange={(v) => setState({ created_at_end: v })}
                  type="YYYY/MM/DD"
                  term={DateTermValidation([
                    { start: sort.created_at_start, end: sort.created_at_end },
                  ])}
                />
              </div>
            </div>
          </div>
          <div className="item_wrap">
            <div className="item_label">更新者</div>
            <div className="item_body item_madori">
              <Select
                defaultLabel="全て"
                className="fixed"
                value={sort.updated_employee_id}
                onChange={(v) => setState({ updated_employee_id: Number(v) })}
                options={pulldown(employeeList)}
              />
            </div>
          </div>
          <div className="item_wrap">
            <div className="item_label">更新日</div>
            <div className="item_body item_schedule wrap">
              <div className="item_schedule__form">
                <DatePicker
                  date={sort.updated_at_start}
                  onChange={(v) => setState({ updated_at_start: v })}
                  type="YYYY/MM/DD"
                  term={DateTermValidation([
                    { start: sort.updated_at_start, end: sort.updated_at_end },
                  ])}
                />
              </div>
              <div className="item_schedule__form flex_box flex_align_center mt_10">
                <div className="item_schedule__tilde">〜</div>
                <DatePicker
                  date={sort.updated_at_end}
                  onChange={(v) => setState({ updated_at_end: v })}
                  type="YYYY/MM/DD"
                  term={DateTermValidation([
                    { start: sort.updated_at_start, end: sort.updated_at_end },
                  ])}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </SearchBox>
  );
};
