import {
  ValidationLengthUnder10, ValidationLengthUnder255, ValidationLengthUnder60,
} from '..';
import { ValidationNumberLengthUnder3 } from '../validation-number-length-under';

export const PetValidation = (param: {
  name: string;
  type: string;
  age: number;
}) => {
  const {
    name, type, age,
  } = param;
  return (Boolean(
  // 必須
    !name

  // バリデーション
  || ValidationLengthUnder255.filter((v) => !v.run(String(name || ''))).length
  || ValidationLengthUnder10.filter((v) => !v.run(String(type || ''))).length
  || ValidationNumberLengthUnder3.filter((v) => !v.run(String(age || ''))).length,
  ));
};
