/* eslint-disable no-nested-ternary */
import './export-csv.scss';
import {
  useState, useCallback, useEffect, useMemo,
} from 'react';
import { useDispatch } from 'react-redux';
import { goBack } from 'connected-react-router';
import { BasePagePC } from '../base.page.pc';
import { SideMenu } from '../master/side-menu/side-menu';
import { ExportCsvCollection } from '../../../../collection/export-csv/export-csv.collection';
import { LeftIconButton } from '../../../ui/button/left-icon-button/left-icon-button';
import { DialogActions } from '../../../../redux/dialog/dialog.action';
import { CsvCustomer } from './body/customer/csv-customer';
import { CsvBirthday } from './body/birthday/csv-birthday';
import { CsvWedding } from './body/wedding/csv-wedding';
import { CsvProject } from './body/project/csv-project';
import { CsvOrder } from './body/order/csv-order';
import { CsvNonOrder } from './body/non-order/csv-non-order';
import { CsvLostOrder } from './body/lost-order/csv-lost-order';
import { CsvMaintenance } from './body/maintenance/csv-maintenance';
import { CsvCustomerRank } from './body/customer-rank/csv-customer-rank';
import { CsvSupportHistory } from './body/support-history/csv-support-history';
import { useAppSelector } from '../../../../hooks/use-redux';
import { CsvActions } from '../../../../redux/csv/csv.action';
import { useCsvEx } from '../../../../hooks/csv-export/use-cv.ex';

export const ExportCsv = () => {
  const dispatch = useDispatch();
  const useCsv = useCsvEx();
  const pageType = useAppSelector((v) => v.csv.pageType);

  /* State */
  const [searchIsOpen, setSearchIsOpen] = useState(false);
  const [searchIsInitOpen, setSearchIsInitOpen] = useState(true);

  const isInitOpen = useMemo(() => {
    if (pageType === 9) {
      return searchIsInitOpen ? 'detail_on' : '';
    }
    return searchIsOpen ? 'detail_on' : '';
  }, [pageType, searchIsInitOpen, searchIsOpen]);

  /* useCallback */
  const listClear = useCallback(() => {
    dispatch(CsvActions.setCustomerSort(null));
  }, []);

  const handleClickSideMenu = useCallback(
    (type: number) => {
      if (pageType === type) return;
      dispatch(CsvActions.setPageType(type));
      dispatch(CsvActions.resetState());
      listClear();
    },
    [pageType],
  );

  return (
    <BasePagePC>
      <div
        id="export_csv"
        className={`cnt_area ${isInitOpen}`}
      >
        <div className="inner">
          <SideMenu
            list={ExportCsvCollection.sideMenu}
            callback={handleClickSideMenu}
            activeId={pageType}
          />
          <div className="main_cnt">
            {!pageType && (
            <CsvCustomer
              callback={() => { }}
              isOpenCallBack={(v) => setSearchIsOpen(v)}
            />
            )}
            {pageType === 1 && <CsvBirthday callback={() => {}} />}
            {pageType === 2 && <CsvWedding callback={() => {}} />}
            {pageType === 3 && <CsvProject callback={() => {}} />}
            {pageType === 4 && <CsvOrder callback={() => {}} />}
            {pageType === 5 && <CsvNonOrder callback={() => {}} />}
            {pageType === 6 && <CsvLostOrder callback={() => {}} />}
            {pageType === 7 && <CsvMaintenance />}
            {pageType === 8 && <CsvCustomerRank />}
            {pageType === 9 && (
            <CsvSupportHistory
              callback={() => { }}
              isOpenCallBack={(v) => {
                setSearchIsInitOpen(v);
              }}
            />
            )}
          </div>
        </div>
      </div>
      <footer className="btn_area">
        <div className="left_box">
          <LeftIconButton
            label="CSV出力"
            size="md"
            fontAwesomeClass="fas fa-file-csv"
            className="btn_search for_detail"
            color="primary"
            onClick={() => useCsv({ type: pageType })}
          />
        </div>
        <div className="right_box">
          <LeftIconButton
            label="戻る"
            fontAwesomeClass="fas fa-arrow-left"
            size="md"
            color="dark"
            onClick={() => dispatch(goBack())}
          />
        </div>
      </footer>
    </BasePagePC>
  );
};
