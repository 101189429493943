import * as lodash from 'lodash';
import { cloneDeep } from 'lodash';
import update from 'immutability-helper';
import { EstimateMeisaiModel } from './estimate-meisai.model';
import { MeisaiListXML } from './estimate-meisai.type';
import { EstimateCalcModelPC } from '../../collection/estimate/estimate-calc.model.pc';

type Common = {
  id: string;
  index: number;
  isOpen: boolean;
  title: string;
  percent: number;
};

type AccordionType<T> = T & Common;

export type TyubunruiAccordion = {
  meisai: MeisaiListXML[];
} & Common;

export type DaibunruiAccordion = {
  meisai: MeisaiListXML[];
} & Common;

export type AccordionData = {
  daibunruiList: AccordionType<{
    tyubunruiList: AccordionType<{
      meisaiList: MeisaiListXML[];
    }>[]
  }>[]
} & Omit<Common, 'id' | 'index'>;

export class EstimateMeisaiAccordionModel {
  public data: AccordionData;

  constructor(_data: EstimateMeisaiModel) {
    this.data = {
      isOpen: true,
      title: '全て',
      percent: 0,
      daibunruiList: [],
    };

    /* 大分類割当 */
    this.data.daibunruiList = this.createDaibunruiList(_data);

    /* 中分類割当 */
    this.data.daibunruiList.forEach((v, i) => {
      const tyuBunruiList = this.createTyubunruiList(_data, v);
      const daibunrui = this.data.daibunruiList[i];
      daibunrui.tyubunruiList = tyuBunruiList;

      /* 明細割当 */
      tyuBunruiList.forEach((_, i2) => {
        const tyubunrui = daibunrui.tyubunruiList[i2];
        tyubunrui.meisaiList = this.createMeisaiList(
          _data, daibunrui, tyubunrui,
        );
      });
    });
    this.setArariPercent(_data);
  }

  setArariPercent(_data: EstimateMeisaiModel) {
    const model = EstimateCalcModelPC;
    // 大分類の合計
    let sDaikingaku = 0;
    let sDaiGenkaKei = 0;

    this.data.daibunruiList.forEach((dv, di) => {
      // 中分類の合計
      let sTyukingaku = 0;
      let sTyuGenkaKei = 0;

      dv.tyubunruiList.forEach((tv, ti) => {
        const { tyuKingaku, tyuGenka_kei } = _data.list.filter((
          f,
        ) => f.$.daibunrui_id === dv.id
          && f.$.tyubunrui_id === tv.id)
          .map((v) => ({
            tyuKingaku: v.$.kingaku, tyuGenka_kei: v.$.genka_kei,
          }))
          .reduce((sum, next) => ({
            tyuKingaku: Number(sum.tyuKingaku) + Number(next.tyuKingaku),
            tyuGenka_kei: Number(sum.tyuGenka_kei) + Number(next.tyuGenka_kei),
          }), { tyuKingaku: 0, tyuGenka_kei: 0 });

        // 中分類粗利率
        this.data.daibunruiList[di].tyubunruiList[ti].percent = model.arariRitsuCal2(
          tyuKingaku, tyuGenka_kei,
        );

        sTyukingaku += tyuKingaku;
        sTyuGenkaKei += tyuGenka_kei;
      });

      sDaikingaku += sTyukingaku;
      sDaiGenkaKei += sTyuGenkaKei;

      // 大分類粗利率
      this.data.daibunruiList[di].percent = model.arariRitsuCal2(sTyukingaku, sTyuGenkaKei);
    });

    // 全ての粗利率
    this.data.percent = model.arariRitsuCal2(sDaikingaku, sDaiGenkaKei);
  }

  /* 大分類リストの生成 */
  private createDaibunruiList(data: EstimateMeisaiModel) {
    const styDaibunrui:string[] = [];
    return data.list
      .filter((v) => {
        const result = !styDaibunrui.includes(v.$.daibunrui_id);
        styDaibunrui.push(v.$.daibunrui_id);
        return result;
      }).map((v, i) => ({
        index: i + 1,
        id: v.$.daibunrui_id,
        isOpen: false,
        title: v.$.daibunrui_name_out,
        percent: 0,
        tyubunruiList: [],
      }));
  }

  /* 中分類リストの生成 */
  private createTyubunruiList(data: EstimateMeisaiModel, daibunrui: AccordionData['daibunruiList'][0]) {
    const styTyubunrui: string[] = [];
    return data.list
      .filter((v) => {
        const result = v.$.daibunrui_id === daibunrui.id
        && !styTyubunrui.includes(v.$.tyubunrui_id);
        styTyubunrui.push(v.$.tyubunrui_id);
        return result;
      })
      .map((v2, i) => ({
        index: i + 1,
        id: v2.$.tyubunrui_id,
        isOpen: false,
        title: v2.$.tyubunrui_name_out,
        percent: 0,
        meisaiList: [],
      }));
  }

  /* 明細リストの生成 */
  private createMeisaiList(
    data: EstimateMeisaiModel,
    daibunrui: AccordionData['daibunruiList'][0],
    tyubunrui: AccordionData['daibunruiList'][0]['tyubunruiList'][0],
  ) {
    return data.list
      .filter((v2) => v2.$.daibunrui_id === daibunrui.id
        && v2.$.tyubunrui_id === tyubunrui.id);
  }

  sortHover(
    mode: 'daibunrui' | 'tyubunrui' | 'meisai',
    dragIndex: number,
    hoverIndex: number,
    daibunrui_index?: number,
    tyubunrui_index?: number,
  ) {
    if (!this) return;
    if (mode === 'daibunrui') {
      const dragCard = this.data.daibunruiList[dragIndex];
      this.data.daibunruiList = update(this.data.daibunruiList, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragCard],
        ],
      });
    } else if (mode === 'tyubunrui' && (daibunrui_index || daibunrui_index === 0)) {
      const dragCard = this.data.daibunruiList[daibunrui_index]
        .tyubunruiList[dragIndex];
      this.data.daibunruiList[daibunrui_index]
        .tyubunruiList = update(
          this.data.daibunruiList[daibunrui_index].tyubunruiList, {
            $splice: [
              [dragIndex, 1],
              [hoverIndex, 0, dragCard],
            ],
          },
        );
    } else if (
      mode === 'meisai'
      && (daibunrui_index || daibunrui_index === 0)
      && (tyubunrui_index || tyubunrui_index === 0)
    ) {
      const dragCard = this.data.daibunruiList[daibunrui_index]
        .tyubunruiList[tyubunrui_index]
        .meisaiList[dragIndex];

      this.data.daibunruiList[daibunrui_index]
        .tyubunruiList[tyubunrui_index]
        .meisaiList = update(
          this
            .data.daibunruiList[daibunrui_index]
            .tyubunruiList[tyubunrui_index].meisaiList, {
            $splice: [
              [dragIndex, 1],
              [hoverIndex, 0, dragCard],
            ],
          },
        );
    }
  }

  format() {
    const meisaiList: MeisaiListXML[] = [];
    this.data.daibunruiList.forEach((v) => {
      v.tyubunruiList.forEach((vv) => {
        vv.meisaiList.forEach((vvv) => {
          meisaiList.push(vvv);
        });
      });
    });

    return new EstimateMeisaiModel({
      meisai: {
        mei: meisaiList,
      },
    });
  }
}
