import { cloneDeep, isEqual } from 'lodash';
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EstimateCollection } from '../../../../../collection/estimate/estimate.collection';
import { useDidMount } from '../../../../../hooks/life-cycle';
import { EstimateActions } from '../../../../../redux/estimate/estimate.action';
import { MasterActions } from '../../../../../redux/master/master.action';
import { State } from '../../../../../redux/root.reducer';
import { LeftLabelCheckbox } from '../../../../ui/checkbox/left-label-checkbox/left-label-checkbox';
import { Input } from '../../../../ui/input/input';
import { Select } from '../../../../ui/select/select';
import { SearchBox } from '../../../layout/search-box/search-box.sp';
import './estimate-search-box.sp.scss';
import { EstimateSortState } from '../../../../../type/estimate/estimate.type';
import { useAppSelector } from '../../../../../hooks/use-redux';
import { pulldown } from '../../../../../utilities/pulldown';
import { DialogActions } from '../../../../../redux/dialog/dialog.action';
import { Button } from '../../../../ui/button/button';
import { DatePicker } from '../../../../ui/date-picker/date-picker';
import { TopLabelInputField } from '../../../../ui/input-field/top-label-input-field/top-label-input-field';
import { useDateValidation } from '../../../../../hooks/use-date-validation';
import { DateTermValidation } from '../../../../../model/validation/date/term-date.validation';

type Props = {
  isLocal?: boolean;
  callback: (v:EstimateSortState) => void;
}

export const EstimateSearchBoxSP = (props: Props) => {
  const {
    // eslint-disable-next-line
    isLocal,
    callback,
  } = props;

  /* Hooks */
  const dispatch = useDispatch();
  const user = useAppSelector((v) => v.user);
  const sortState = useAppSelector((v) => v.estimate.sort);
  const employeeList = useAppSelector((v) => v.master.employeeList);
  const storeList = useAppSelector((v) => v.master.storeList);

  /* State */
  const [sort, setSort] = useState(cloneDeep(sortState));
  const _employeeList = useMemo(() => employeeList.filter((
    v,
  ) => (Number.isNaN(sort.project_store_id) || sort.project_store_id === null
    ? true
    : (v.store_id === sort.project_store_id))),
  [sort.project_store_id, employeeList]);

  const termDate = useDateValidation([
    { start: sort.created_at_start, end: sort.created_at_end },
    { start: sort.updated_at_start, end: sort.updated_at_end },
  ]);

  /* Callback */
  const setState = useCallback((v: Partial<EstimateSortState>) => {
    const data = { ...cloneDeep(sort), ...cloneDeep(v) };
    setSort(data);
  }, [sort]);

  const handleClickSearch = useCallback(() => {
    if (termDate.messages.length) {
      termDate.check();
      return;
    }

    callback(cloneDeep(sort));
    dispatch(EstimateActions.setSort(sort));
  }, [callback, sort, termDate]);

  /* Effect */
  useDidMount(() => {
    dispatch(MasterActions.api.store.getList({}));
    dispatch(MasterActions.api.employee.getList({}));
  });

  useEffect(() => {
    setState({
      project_store_id: sortState.project_store_id || user.store_id,
      project_employee_id: sortState.project_employee_id || user.store_id,
    });
  }, [user]);

  return (
    <SearchBox
      callback={handleClickSearch}
      callbackClear={() => setState(EstimateCollection._sortInitialState(user))}
    >
      <div className="search_box_sp_body_inner">
        <div className="category_wrap">
          <div className="item_wrap">
            <div className="item_label">現場名称</div>
            <Input
              value={sort.field_name}
              onChange={(e) => setState({ field_name: e.target.value })}
            />
          </div>
          <div className="item_wrap">
            <div className="item_label">案件名</div>
            <Input
              value={sort.project_name}
              onChange={(e) => setState({ project_name: e.target.value })}
            />
          </div>
          <div className="item_wrap">
            <LeftLabelCheckbox
              label="失注案件も含む"
              checked={Boolean(sort.cancel_f)}
              onChange={() => setState({ cancel_f: sort.cancel_f ? 0 : 1 })}
            />
          </div>
        </div>
        <div className="category_wrap ">
          <div className="item_wrap">
            <div className="item_label">営業担当店舗</div>
            <Select
              className="add_text_left"
              value={sort?.project_store_id}
              onChange={(v) => setState({
                project_store_id: Number(v),
                project_employee_id: Number.isNaN(Number(v))
                || Number(v) === user.store_id
                  ? user.id
                  : NaN,
                employee_id: NaN,
              })}
              defaultLabel="全て"
              options={pulldown(storeList)}
            />
          </div>

          <div className="item_wrap">
            <div className="item_label">案件担当者</div>
            <Select
              className="add_text_left"
              value={sort?.project_employee_id}
              onChange={(v) => setState({ project_employee_id: Number(v) })}
              defaultLabel="全て"
              options={pulldown(_employeeList)}
            />
          </div>

          <div className="item_wrap">
            <div className="item_label">見積作業者</div>
            <Select
              className="add_text_left"
              value={sort?.employee_id}
              onChange={(v) => setState({ employee_id: Number(v) })}
              defaultLabel="全て"
              options={pulldown(_employeeList)}
            />
          </div>
        </div>
        <div className="category_wrap">
          <div className="item_wrap">
            <div className="item_label">登録者</div>
            <div className="item_body item_madori">
              <Select
                defaultLabel="全て"
                className="fixed"
                value={sort.created_employee_id}
                onChange={(v) => setState({ created_employee_id: Number(v) })}
                options={pulldown(employeeList)}
              />
            </div>
          </div>
          <div className="item_wrap">
            <div className="item_label">登録日</div>
            <div className="item_body item_schedule wrap">
              <div className="item_schedule__form">
                <DatePicker
                  date={sort.created_at_start}
                  onChange={(v) => setState({ created_at_start: v })}
                  type="YYYY/MM/DD"
                  term={DateTermValidation([
                    { start: sort.created_at_start, end: sort.created_at_end },
                  ])}
                />
              </div>
              <div className="item_schedule__form flex_box flex_align_center mt_10">
                <div className="item_schedule__tilde">〜</div>
                <DatePicker
                  date={sort.created_at_end}
                  onChange={(v) => setState({ created_at_end: v })}
                  type="YYYY/MM/DD"
                  term={DateTermValidation([
                    { start: sort.created_at_start, end: sort.created_at_end },
                  ])}
                />
              </div>
            </div>
          </div>
          <div className="item_wrap">
            <div className="item_label">更新者</div>
            <div className="item_body item_madori">
              <Select
                defaultLabel="全て"
                className="fixed"
                value={sort.updated_employee_id}
                onChange={(v) => setState({ updated_employee_id: Number(v) })}
                options={pulldown(employeeList)}
              />
            </div>
          </div>
          <div className="item_wrap">
            <div className="item_label">更新日</div>
            <div className="item_body item_schedule wrap">
              <div className="item_schedule__form">
                <DatePicker
                  date={sort.updated_at_start}
                  onChange={(v) => setState({ updated_at_start: v })}
                  type="YYYY/MM/DD"
                  term={DateTermValidation([
                    { start: sort.updated_at_start, end: sort.updated_at_end },
                  ])}
                />
              </div>
              <div className="item_schedule__form flex_box flex_align_center mt_10">
                <div className="item_schedule__tilde">〜</div>
                <DatePicker
                  date={sort.updated_at_end}
                  onChange={(v) => setState({ updated_at_end: v })}
                  type="YYYY/MM/DD"
                  term={DateTermValidation([
                    { start: sort.updated_at_start, end: sort.updated_at_end },
                  ])}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </SearchBox>
  );
};
