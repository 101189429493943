type ProcessEnv = globalThis.NodeJS.ProcessEnv & Partial<{
  REACT_APP_MODE: 'dev' | 'prod' | 'local' |'';
  REACT_APP_END_POINT: string;
  REACT_APP_VERSION_UNIX: string;
  REACT_APP_VERSION_WIN: string;
  REACT_APP_API_KEY: string;
  REACT_APP_REDIRECT_POINT: string;
}>;

const ENV: ProcessEnv = process.env;

export class Config {
  static mode: 'dev' | 'prod' | 'local' | '' = ENV.REACT_APP_MODE || 'dev';

  static host = ENV.REACT_APP_END_POINT || 'https://ship-customer.marietta.dev';

  static version = process.env.REACT_APP_VERSION_WIN === '%npm_package_version%'
    ? process.env.REACT_APP_VERSION_UNIX
    : process.env.REACT_APP_VERSION_WIN

  static apiKey = ENV.REACT_APP_API_KEY || ''

  static readonly redirectURL = ENV.REACT_APP_REDIRECT_POINT || '';

  static readonly cssPath = '/fonts.css'

  static readonly svgFont = 'IPA Mincho'

  static readonly isMac = window.navigator.userAgent.toLowerCase().indexOf('mac os x') !== -1;
}
