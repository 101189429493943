/* eslint-disable no-nested-ternary */
import React, { memo, useEffect } from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';
import { UserAgent } from '../../../../utilities/user-agent';
import { NoDataGuidance } from '../../no-data-guidance/no-data-guidance';
import './card-list.scss';

type Props = {
  /** 無限スクロールロード用  */
  isLoading: boolean;
  /** カードリスト配列本体  */
  list: any[] | null | undefined;
  /** カードリストElement  */
  children: globalThis.React.ReactNode;
}

export const CardList = memo((props: Props) => {
  const { isLoading, list, children } = props;

  return (
    <div className={`${UserAgent === 'sp' ? 'list_base' : 'list_box'} ${UserAgent}`}>
      {
        list || list === undefined
          ? list?.length
            ? children // カードリスト
            : <NoDataGuidance /> // リスト結果0件
          : ( // リストロード中
            <Dimmer inverted active>
              <Loader size="medium" />
            </Dimmer>
          )
      }
      {/* 無限ロード取得用 */}
      {false && (
        <div className="list_base__get_loading">
          <Dimmer inverted active><Loader size={UserAgent === 'sp' ? 'small' : 'medium'} content="Get Loading" /></Dimmer>
        </div>
      )}
    </div>
  );
});
