import { cloneDeep, isEqual } from 'lodash';
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDidMount } from '../../../../../hooks/life-cycle';
import { DialogActions } from '../../../../../redux/dialog/dialog.action';
import { EstimateSortStateInProject } from '../../../../../redux/estimate/api/estimate/api-estimate.type';
import { MasterActions } from '../../../../../redux/master/master.action';
import { ProjectDetailActions } from '../../../../../redux/project-detail/project-detail.action';
import { State } from '../../../../../redux/root.reducer';
import { EstimateSortState } from '../../../../../type/estimate/estimate.type';
import { DatePicker } from '../../../../ui/date-picker/date-picker';
import { Input } from '../../../../ui/input/input';
import { Select } from '../../../../ui/select/select';
import { RefindSP } from './refind.sp';

type Props = {
  callback: (sort:EstimateSortStateInProject) => void;
}

export const RefindEstimateSP = (props:Props) => {
  const { callback } = props;
  const dispatch = useDispatch();
  const sort = useSelector((state: State) => state.projectDetail.estimateSort, isEqual);
  const employeeList = useSelector((state: State) => state.master.employeeList, isEqual);
  const [sortState, setSortState] = useState(cloneDeep(sort));

  /* Callback */
  const handleClickSearch = useCallback(() => {
    dispatch(DialogActions.pop());
    callback(sortState);
  }, [callback, sortState]);

  const setState = useCallback((v: EstimateSortStateInProject) => {
    setSortState(cloneDeep(v));
    dispatch(ProjectDetailActions.setEstimateSort(v));
  }, [dispatch]);

  useDidMount(() => {
    dispatch(MasterActions.api.employee.getList({}));
  });

  return (
    <RefindSP callback={handleClickSearch}>
      <>
        <div className="category_wrap">
          <div className="item_wrap">
            <div className="item_label">見積番号</div>
            <Input
              className=""
              value={sortState.estimate_no}
              onChange={(e) => setState({
                ...sortState, estimate_no: e.target.value,
              })}
            />
          </div>
          <div className="item_wrap">
            <div className="item_label">見積日</div>
            <DatePicker
              date={sortState.estimate_dt || null}
              onChange={(v) => setState(
                { ...sortState, estimate_dt: v },
              )}
            />
          </div>
          <div className="item_wrap">
            <div className="item_label">見積作成者</div>
            <Select
              className="add_text_left"
              value={sortState.created_employee_id}
              onChange={(data) => setState({ ...sort, created_employee_id: Number(data) })}
              defaultLabel="全て"
              options={employeeList.map((v) => ({
                text: v.name, value: v.id,
              }))}
            />
          </div>
          <div className="item_wrap">
            <div className="item_label">見積金額</div>
            <Input
              className=""
              type="number"
              value={sortState.gokei_zeinuki_kin}
              onChange={(e) => setState({
                ...sortState, gokei_zeinuki_kin: Number(e.target.value),
              })}
            />
          </div>
          <div className="item_wrap">
            <div className="item_label">消費税額（見積）</div>
            <Input
              className=""
              type="number"
              value={sortState.shohizei_kin}
              onChange={(e) => setState({
                ...sortState, shohizei_kin: Number(e.target.value),
              })}
            />
          </div>
          <div className="item_wrap">
            <div className="item_label">税込合計見積</div>
            <Input
              className=""
              type="number"
              value={sortState.gokei_kin}
              onChange={(e) => setState({
                ...sortState, gokei_kin: Number(e.target.value),
              })}
            />
          </div>
          <div className="item_wrap">
            <div className="item_label">原価合計</div>
            <Input
              className=""
              type="number"
              value={sortState.gokei_zeinuki_genka_kin}
              onChange={(e) => setState({
                ...sortState, gokei_zeinuki_genka_kin: Number(e.target.value),
              })}
            />
          </div>
          <div className="item_wrap">
            <div className="item_label">消費税額（原価）</div>
            <Input
              className=""
              type="number"
              value={sortState.genka_shohizei_kin}
              onChange={(e) => setState({
                ...sortState, genka_shohizei_kin: Number(e.target.value),
              })}
            />
          </div>
          <div className="item_wrap">
            <div className="item_label">税込合計原価</div>
            <Input
              className=""
              type="number"
              value={sortState.gokei_genka_kin}
              onChange={(e) => setState({
                ...sortState, gokei_genka_kin: Number(e.target.value),
              })}
            />
          </div>
          <div className="item_wrap">
            <div className="item_label">調整額</div>
            <Input
              className=""
              type="number"
              value={sortState.tyosei_kin}
              onChange={(e) => setState({
                ...sortState, tyosei_kin: Number(e.target.value),
              })}
            />
          </div>
          <div className="item_wrap">
            <div className="item_label">見積工期</div>
            <DatePicker
              date={sortState.estimate_kouki_start_dt || null}
              onChange={(v) => setState(
                { ...sortState, estimate_kouki_start_dt: v },
              )}
            />
            <div className="flex_box flex_align_center mt_10">
              <label className="ml_5 mr_5">〜</label>
              <DatePicker
                date={sortState.estimate_kouki_end_dt || null}
                onChange={(v) => setState(
                  { ...sortState, estimate_kouki_end_dt: v },
                )}
              />
            </div>
          </div>
        </div>
      </>
    </RefindSP>
  );
};
