import { ValidationLengthUnder10 } from '../_validation-length-under-10';
import { ValidationLengthUnder2 } from '../-validation-length-under-2';
import { ValidationEisuzi } from '../validation-eisuzi';

type ValidationType = {
  name: string;
  abbreviation: string;
  backGround: string;
  textColor: string;
}

export const MasterProjectRankValidation = (data: ValidationType) => {
  const {
    name, abbreviation, backGround, textColor,
  } = data;
  return (
  // 必須
    !name
    || !abbreviation
    || !backGround
    || !textColor

    // バリデーション
    || ValidationEisuzi.filter((v) => !v.run(String(abbreviation || ''))).length
  );
};
