// import {ApiLogin} from '../../api/auth/api-login';
import actionCreatorFactory from 'typescript-fsa';
import { DialogProps, DialogBtnProps } from '../../type/dialog.type';

const ActionCreator = actionCreatorFactory('dialog');

export const DialogActions = {
  push: ActionCreator<DialogProps>('push'),
  pushReady: ActionCreator('push/ready'),
  pushMessage: ActionCreator<{
    title: string;
    message: string[];
    isCancel?: true;
    cancelRight?: boolean;
    cancelLabel?: string;
    label?: string;
    callbackClose?:() => void;
    callback?:() => void; // ボタンがOKのみのCallback
    option?:{
      btnProps?: DialogBtnProps[];
      beforeClear?: boolean;
    };
    urlIndex?: number;
    linkUrl?: string;
    isInvisibleClose?: boolean;
      }>('push/message'),
  pop: ActionCreator('pop'),
  clear: ActionCreator('clear'),
  isLoading: ActionCreator<boolean>('is/loading'),
  setIsLoading: ActionCreator<boolean>('set/is/loading'),
  resetState: ActionCreator('reset/state'),
  pushCheck: ActionCreator<{
    callback?:() => void;
    label?: string[],
      }>('check'),
  setIs404: ActionCreator<boolean>('set/is404'),
  changeTitle: ActionCreator<{index: number, title: string}>('change/title'),
};
