import { replace } from 'connected-react-router';
import cloneDeep from 'lodash/cloneDeep';
import {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { useDidMount, useWillUnMount } from '../../../../hooks/life-cycle';
import { useQuery } from '../../../../hooks/use-query';
import { useAppSelector } from '../../../../hooks/use-redux';
import { DialogActions } from '../../../../redux/dialog/dialog.action';
import { MaintenanceActions } from '../../../../redux/maintenance/maintenance.action';
import { RoutingPath } from '../../../../routes/routing-pass';
import { SetSelectedClass } from '../../../../utilities/set-selected-class';
import { BottomBorderButton } from '../../../ui/button/bottom-border/bottom-border-button';
import { LeftIconButton } from '../../../ui/button/left-icon-button/left-icon-button';
import { MapListToggleButton } from '../../../ui/button/map-list-toggle/map-list-toggle-button';
import { HorizontalCalendar } from '../../../ui/calender/horizontal/horizontal-calendar';
import { MapBase } from '../../../ui/map/map-base';
import { MaintenanceListSP } from '../../layout/body/list/maintenance/maintenance-list';
import { BasePageSP } from '../base.page.sp';
import { MaintenanceEditSP } from './edit/maintenance-edit.sp';
import { MaintenanceEditDialogTitle } from './edit/maintenance-edit.type';
import './maintenance.sp.scss';
import { MaintenanceSearchBoxSP } from './search-box/maintenance-search-box.sp';
import { MaintenanceModel } from '../../../../model/maintenance/maintenance.model';
import { MaintenanceSortState, MaintenanceList } from '../../../../type/maintenance/maintenance.type';
import { Message } from '../../../../collection/message.collection';
import { MapActions } from '../../../../redux/map/map.action';
import { TableSort } from '../../../ui/table/table-sort/table-sort';
import { MapAreaPosition } from '../../../../type/map/map.type';
import { MapCollection } from '../../../../collection/map/map.collection';
import { MaintenanceCollection } from '../../../../collection/maintenance/maintenance.collection';

export const MaintenanceSP = () => {
  /* Hooks */
  const shoType = useQuery('type');
  const dispatch = useDispatch();
  const sortState = useAppSelector((v) => (v.maintenance.sort));
  const mapAreaPos = useAppSelector((v) => (v.map.mapAreaPos));
  const gpsStatus = useAppSelector((v) => (v.map.gpsStatus));
  const dialogLen = useAppSelector((v) => (v.dialog.dialogs.length));
  const user = useAppSelector((v) => v.user);
  const map = useAppSelector((v) => v.map.map);
  const flickLoad = useAppSelector((v) => v.map.flickLoad);
  const areaBtnDisabled = useAppSelector((v) => (v.map.areaBtnDisabled));
  const humanPos = useAppSelector((v) => (v.map.humanPos));
  const zoom = useAppSelector((v) => v.map.zoomLevel);

  /* State */
  const [showType, setShowType] = useState<'map' | 'list' | 'date'>('map');
  const [maintenanceShowType, setMaintenanceShowType] = useState<0 | 1 |2>(0);
  const [footerHeight, setFooterHeight] = useState<number>(0);
  const [secondaryHeaderHeight, setSecondaryHeaderHeight] = useState<number>(0);
  const [selectDay, setSelectDay] = useState(new Date());
  const [mapList, setMapList] = useState<MaintenanceList[] | null>(null);
  const [hitCount, setHitCount] = useState(0);
  const [isDetail, setIsDetail] = useState(false);
  const [sort, setSort] = useState<MaintenanceSortState>(
    MaintenanceCollection._initialSortState(user),
  );
  /* エリア検索 */
  const [zoomSearch, setZoomSearch] = useState(false);
  const [init, setInit] = useState<boolean>(true);
  const [isSearch, setIsSearch] = useState(false);

  /* Ref */
  const headerEle = useRef<HTMLDivElement>(null);
  const footerEle = useRef<HTMLDivElement>(null);
  const secondaryHeaderEle = useRef<HTMLDivElement>(null);
  const listEle = useRef<HTMLDivElement>(null);

  const calendarScrollEle = document.getElementsByClassName('react-datepicker__month');
  const calendarDayEle = document.getElementsByClassName('react-datepicker__day--today')[0];

  /* Callback */

  /* 一覧取得 - リスト */
  const getListInList = useCallback((param: {
    data?: MaintenanceSortState;
    offset?: number;
  }) => {
    const { data, offset } = param;
    if (data) setSort(cloneDeep(data));
    setIsDetail(true);
    setMaintenanceShowType((data || sortState).supported_kubun as 0 | 1 | 2);
    setMapList(null);
    dispatch(MaintenanceActions.api.maintenance.getCallbackList({
      param: MaintenanceModel.listParm({
        sortData: data || sortState,
        searchBox: true,
        showType,
        selectDay,
        isMapList: true,
      }),
      onSuccess: (v, hit) => {
        if (!v) return;
        setHitCount(hit);
        setMapList(v);
      },
    }));
  }, [sortState, showType]);

  /* 一覧取得 - map */
  const getListInMap = useCallback((param: {
    data?: MaintenanceSortState;
    searchBox?: boolean;
    mapPos?: MapAreaPosition;
    resetList?: boolean;
  }) => {
    const {
      data, searchBox, mapPos, resetList,
    } = param;
    const isMap = showType === 'map';
    if (resetList) {
      dispatch(MaintenanceActions.setList(null));
    }
    dispatch(MaintenanceActions.api.maintenance.getList({
      isLoad: isMap,
      isMapLoad: true,
      param: MaintenanceModel.listParm({
        sortData: data || sortState,
        showType,
        mapAreaPos: mapPos || mapAreaPos,
        selectDay,
      }),
      callback: (v) => {
        if (!v) return;
        if (flickLoad) setMapList(v);
        if (isMap) {
          dispatch(MaintenanceActions.setList(v));
        } else {
          dispatch(MaintenanceActions.setList(v));
        }
        if (!v.length && isMap) {
          dispatch(DialogActions.pushMessage({
            title: '検索',
            message: ['領域内に該当データがありません'],
          }));
        }
      },
    }));
  }, [showType, sortState, mapAreaPos, flickLoad, selectDay]);

  // /* 一覧取得 */
  // const getList = useCallback((data?: MaintenanceSortState, searchBox?:boolean) => {
  //   const isMap = showType === 'map';

  //   const sort = data || sortState;
  //   if (searchBox) {
  //     setIsDetail(true);
  //     setMaintenanceShowType((data || sortState).supported_kubun as 0 | 1 | 2);
  //     setMapList(null);
  //     dispatch(MaintenanceActions.api.maintenance.getCallbackList({
  //       param: MaintenanceModel.listParm({
  //         sortData: data || sortState,
  //         searchBox: true,
  //         showType,
  //         selectDay,
  //         isMapList: true,
  //       }),
  //       onSuccess: (v, hit) => {
  //         console.log(v);
  //         if (!v) return;
  //         setHitCount(hit);
  //         setMapList(v);
  //       },
  //     }));
  //   }

  //   dispatch(MaintenanceActions.api.maintenance.getList({
  //     isLoad: showType === 'map',
  //     param: MaintenanceModel.listParm({
  //       sortData: data || sortState,
  //       mapAreaPos,
  //       showType,
  //       searchBox,
  //       selectDay,
  //     }),
  //     callback: (v) => {
  //       if (!v) return;
  //       if (showType === 'list') setMapList(v);
  //       if (flickLoad) {
  //         setMapList(v);
  //       }
  //       if (isMap) {
  //         dispatch(MaintenanceActions.setList(v));
  //       } else {
  //         dispatch(MaintenanceActions.setList(v));
  //       }
  //       if (!v.length && isMap && searchBox) {
  //         dispatch(DialogActions.pushMessage({
  //           title: '',
  //           message: Message.getListError,
  //         }));
  //       }
  //     },
  //   }));
  // }, [sortState, showType, mapAreaPos, user, map, selectDay, flickLoad, maintenanceShowType]);

  /* エリア検索ボタン押下 */
  const hClickSearchArea = useCallback((data?: MaintenanceSortState) => {
    dispatch(MapActions.setAreaBtnDisabled(true));
    if (flickLoad) setMapList(null);
    if (zoom >= MapCollection.searchZoomLevel && mapAreaPos) {
      getListInMap({ data });
    } else {
      setZoomSearch(true);
      dispatch(MapActions.setZoomLevel(MapCollection.searchZoomLevel));
    }
  }, [zoom, mapAreaPos, getListInMap, flickLoad]);

  /* 新規登録 */
  const add = useCallback(() => {
    dispatch(DialogActions.push({
      title: MaintenanceEditDialogTitle.add,
      element: <MaintenanceEditSP
        callback={() => {
          getListInList({});
          getListInMap({});
        }}
      />,
    }));
  }, [getListInList, getListInMap]);

  /* ズーム終了時アクション */
  const zoomEnd = useCallback((pos: MapAreaPosition) => {
    if (!zoomSearch) {
      dispatch(MapActions.setAreaBtnDisabled(false));
      return;
    }
    setZoomSearch(false);
    getListInMap({ mapPos: pos });
  }, [zoomSearch, getListInMap, flickLoad]);

  /* 検索ボタン押下処理 */
  const hClickSearchBtn = useCallback((v: MaintenanceSortState) => {
    dispatch(DialogActions.pop());
    if (showType !== 'map') { dispatch(MaintenanceActions.setList(null)); }
    setIsSearch(true);
    setMapList(null);
    dispatch(MapActions.setFlickLoad(false));
    dispatch(MaintenanceActions.setSort({ ...v, offset: 0 }));
    if (showType === 'map') { hClickSearchArea(v); }
    getListInList({ data: { ...v, offset: 0 }, offset: 0 });
  }, [shoType, getListInList, showType, hClickSearchArea, sortState]);

  /* フリーワード検索処理 */
  const hBlurFree = useCallback((v: string) => {
    if (showType !== 'map') { dispatch(MaintenanceActions.setList(null)); }
    // setIsSearchDialog(true);
    dispatch(MaintenanceActions.setSort({ keyword: v, offset: 0 }));
    if (showType === 'map') { hClickSearchArea({ ...sortState, keyword: v }); }
    getListInList({ data: { ...sortState, keyword: v }, offset: 0 });
  }, [shoType, getListInList, showType, hClickSearchArea]);

  /* ページネーション */
  const hClickPagination = useCallback((offset: number) => {
    dispatch(MaintenanceActions.setSort({ offset }));
    getListInList({ ...sortState, offset });
  }, [sortState, getListInList]);

  /* Effect */
  /* CSS関係 */
  useEffect(() => {
    setFooterHeight(footerEle.current?.getBoundingClientRect().height || 0);
  }, [footerEle, footerHeight]);

  useEffect(() => {
    setSecondaryHeaderHeight(secondaryHeaderEle.current?.getBoundingClientRect().height || 0);
  }, [secondaryHeaderEle, secondaryHeaderHeight, showType]);

  /* effect */
  useEffect(() => {
    if (!isDetail) {
      if (showType === 'date') return;
      if (dialogLen || (showType === 'map' && !mapAreaPos)) return;
      getListInList({});
    }
    setIsDetail(false);
  }, [
    showType,
  ]);

  useEffect(() => {
    if (init && humanPos && mapAreaPos) {
      setInit(false);
      hClickSearchArea();
    }
  }, [humanPos, mapAreaPos]);

  useEffect(() => {
    if (showType === 'date') getListInMap({});
  }, [selectDay]);

  useDidMount(() => {
    dispatch(MapActions.setFlickLoad(true));
  });

  useWillUnMount(() => {
    // dispatch(MaintenanceActions.setInitSort(user));
  });

  useEffect(() => {
    const mapType = (shoType || 'map');
    const path = `${RoutingPath.maintenance}?type=`;
    dispatch(replace(path + mapType));
    switch (mapType) {
      case 'map':
        setShowType('map');
        break;
      case 'list':
        setShowType('list');
        break;
      case 'date':
        setShowType('date');
        break;
      default:
        break;
    }
  }, [shoType]);

  useEffect(() => {
    if (calendarScrollEle.length !== 0 && calendarDayEle) {
      const today = new Date();
      const thisDate = today.getDate();
      const thisYear = today.getFullYear().toString();
      const thisMonth = today.getMonth().toString();
      const totalDays = new Date(parseInt(thisYear, 10), parseInt(thisMonth, 10), 0).getDate();
      const relativePos = totalDays / thisDate;
      const scrollPos = (
        (calendarScrollEle[0].scrollWidth / relativePos)
        - (window.innerWidth / 2)
        - calendarDayEle.clientWidth
      );
      calendarScrollEle[0].scrollTo({ left: scrollPos });
    }
  }, [calendarScrollEle, showType, calendarDayEle]);

  useEffect(() => {
    listEle.current?.scrollTo(0, -10000);
  }, [maintenanceShowType]);

  useWillUnMount(() => {
    dispatch(MaintenanceActions.setList(null));
  });

  return (
    <BasePageSP
      className="maintenance_sp"
      searchBoxDialog={{
        title: '詳細検索',
        element: <MaintenanceSearchBoxSP
          getList={(v) => hClickSearchBtn(v)}
        />,
      }}
      searchValue={sortState.keyword}
      searchCallback={hBlurFree}

    >
      <div id="map_list_header" className="map_list_header" onClick={(e) => { e.preventDefault(); }} ref={headerEle}>
        <BottomBorderButton
          label="すべて"
          onClick={(e) => {
            dispatch(MaintenanceActions.setSort({ ...sortState, supported_kubun: 2 }));
            setMaintenanceShowType(2);
            SetSelectedClass(e.currentTarget, headerEle.current);
            if (showType !== 'map') {
              dispatch(MaintenanceActions.setList(null));
            } else {
              dispatch(MapActions.setAreaBtnDisabled(false));
            }
            getListInList({ data: { ...sort, supported_kubun: 2 } });
          }}
          selected={sortState.supported_kubun === 2}
        />
        <BottomBorderButton
          label="未対応"
          onClick={(e) => {
            dispatch(MaintenanceActions.setSort({ ...sortState, supported_kubun: 0 }));
            setMaintenanceShowType(0);
            SetSelectedClass(e.currentTarget, headerEle.current);
            if (showType !== 'map') {
              dispatch(MaintenanceActions.setList(null));
            } else {
              dispatch(MapActions.setAreaBtnDisabled(false));
            }
            getListInList({ data: { ...sort, supported_kubun: 0 } });
          }}
          selected={sortState.supported_kubun === 0}
        />
        <BottomBorderButton
          label="対応済"
          onClick={(e) => {
            dispatch(MaintenanceActions.setSort({ ...sortState, supported_kubun: 1 }));
            setMaintenanceShowType(1);
            SetSelectedClass(e.currentTarget, headerEle.current);
            if (showType !== 'map') {
              dispatch(MaintenanceActions.setList(null));
            } else {
              dispatch(MapActions.setAreaBtnDisabled(false));
            }
            getListInList({ data: { ...sort, supported_kubun: 1 } });
          }}
          selected={sortState.supported_kubun === 1}
        />
      </div>
      <>
        {showType === 'list' && (
        <TableSort
          isSp
          page={sortState.offset ?? 0}
          limit={100}
          hitCount={hitCount || 0}
          callback={hClickPagination}
        />
        )}
      </>
      <div className="map_list_body" ref={listEle}>
        {!areaBtnDisabled && showType === 'map'
          && (
          <LeftIconButton
            label="このエリアを検索"
            size="md"
            fontAwesomeClass="fas fa-search"
            className="for_area"
            color="secondary"
            onClick={() => hClickSearchArea()}
            disabled={areaBtnDisabled}
          />
          )}
        {showType === 'map' && (
          <MapBase
            type="customer"
            maintenanceOption={{
              type: maintenanceShowType,
            }}
            isNowPoint
            searchOption={{}}
            callbackZoomEnd={zoomEnd}
            callbackGetList={() => {
              getListInMap({ resetList: true });
            }}
          />
        )}

        {showType === 'list' && (
          <>
            {/*
            <TableSort
              isSp
              page={sortState.offset ?? 0}
              limit={100}
              hitCount={hitCount || 0}
              callback={hClickPagination}
            />
            */}
            <MaintenanceListSP data={mapList} showType={showType} type={maintenanceShowType} />
          </>
        )}

        {showType === 'date' && (
        <div className="maintenance_sp__list">
          <div className="maintenance_sp__list__header" ref={secondaryHeaderEle}>
            <HorizontalCalendar
              value={selectDay}
              onChange={setSelectDay}
            />
          </div>
          <div className="maintenance_sp__list__body">
            <MaintenanceListSP
              selectDay={selectDay}
              showType={showType}
            />
          </div>
        </div>
        )}
      </div>

      <div className="page_body_footer space_between" ref={footerEle}>
        <LeftIconButton
          label="メンテナンス登録"
          fontAwesomeClass="far fa-edit"
          className="btn regist"
          onClick={add}
          size="md"
          color="primary"
        />
        <MapListToggleButton
          showType={showType}
          onClick={() => {
            let newShowType = showType;
            switch (showType) {
              case 'map':
                newShowType = 'list';
                break;
              case 'list':
                newShowType = 'date';
                break;
              case 'date':
                newShowType = 'map';
                break;
              default:
                break;
            }
            const path = `${RoutingPath.maintenance}?type=${newShowType}`;
            if (newShowType === 'map') {
              setInit(true);
            }
            if (flickLoad) setMapList(null);
            dispatch(replace(path));
            dispatch(MaintenanceActions.setList(null));
            setShowType(newShowType);
          }}
          typeNum={3}
        />
      </div>
    </BasePageSP>
  );
};
