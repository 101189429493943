import { memo } from 'react';
import { Estimate } from '../../../../../../type/estimate/estimate.type';
import { MathHelper } from '../../../../../../utilities/math-helper';
import { EstimateCalcModelPC as Calc } from '../../../../../../collection/estimate/estimate-calc.model.pc';

type Props = {
  info: Estimate | null;
  isOpen?: boolean;
}

export const EstimatePriceInfoSp = memo((props: Props) => {
  const {
    info, isOpen,
  } = props;

  return (
    <div className={`estimate_detail_sp__container__price_detail__body ${isOpen ? 'opened' : ''}`}>
      <div className="row_table_style">
        <div className="t_row">
          <div className="t_header">見積金額</div>
          <div className="t_body">
            &yen;&nbsp;{MathHelper.rounding2Str(info?.zeinuki_kin ? Number(info.zeinuki_kin) : 0, 0, 'round', true)}
          </div>
        </div>
        <div className="t_row">
          <div className="t_header">見積合計</div>
          <div className="t_body">
            &yen;&nbsp;{MathHelper.rounding2Str(info?.gokei_zeinuki_kin ? Number(info.gokei_zeinuki_kin) : 0, 0, 'round', true)}
          </div>
        </div>
        <div className="t_row">
          <div className="t_header">消費税額</div>
          <div className="t_body">&yen;&nbsp;{MathHelper.rounding2Str(info?.shohizei_kin ? Number(info.shohizei_kin) : 0, 0, 'round', true)}</div>
        </div>
        <div className="t_row">
          <div className="t_header">税込合計<br />積金額</div>
          <div className="t_body">&yen;&nbsp;{MathHelper.rounding2Str(info?.gokei_kin ? Number(info.gokei_kin) : 0, 0, 'round', true)}</div>
        </div>
        <div className="t_row">
          <div className="t_header">調整金額<br />（値引き）</div>
          <div className="t_body">&yen;&nbsp;{MathHelper.rounding2Str(info?.tyosei_kin ? Number(info.tyosei_kin) : 0, 0, 'round', true)}</div>
        </div>
        <div className="t_row">
          <div className="t_header">原価金額</div>
          <div className="t_body">&yen;&nbsp;{MathHelper.rounding2Str(info?.genka_kin ? Number(info.genka_kin) : 0, 0, 'round', true)}</div>
        </div>
        <div className="t_row">
          <div className="t_header">原価合計</div>
          <div className="t_body">&yen;&nbsp;{MathHelper.rounding2Str(info?.gokei_zeinuki_genka_kin ? Number(info.gokei_zeinuki_genka_kin) : 0, 0, 'round', true)}</div>
        </div>
        <div className="t_row">
          <div className="t_header">消費税額</div>
          <div className="t_body">&yen;&nbsp;{MathHelper.rounding2Str(info?.genka_shohizei_kin ? Number(info.genka_shohizei_kin) : 0, 0, 'round', true)}</div>
        </div>
        <div className="t_row">
          <div className="t_header">税込合計<br />原価金額</div>
          <div className="t_body">&yen;&nbsp;{MathHelper.rounding2Str(info?.gokei_genka_kin ? Number(info.gokei_genka_kin) : 0, 0, 'round', true)}</div>
        </div>
        <div className="t_row">
          <div className="t_header">粗利金額</div>
          <div className="t_body">&yen;&nbsp;{MathHelper.rounding2Str(Calc.arariPrice(info?.gokei_kin || 0, info?.gokei_genka_kin || 0), 0, 'round', true)}</div>
        </div>
        <div className="t_row">
          <div className="t_header">粗利率</div>
          <div className="t_body">{MathHelper.rounding2Str(Calc.arariRitsuCal(info?.gokei_kin || 0, info?.gokei_genka_kin || 0), 1, 'round', true)}%</div>
        </div>
        <div className="t_row">
          <div className="t_header">現場協力費：見積</div>
          <div className="t_body">
            &yen;&nbsp;{MathHelper.rounding2Str(info?.reserve1_estimate_kin ? Number(info.reserve1_estimate_kin) : 0, 0, 'round', true)} ({Calc.setInfoNum(info?.reserve1_estimate_percent, 2)}%)<br />
          </div>
        </div>
        <div className="t_row">
          <div className="t_header">現場協力費：原価</div>
          <div className="t_body">
            &yen;&nbsp;{MathHelper.rounding2Str(info?.reserve1_genka_kin ? Number(info.reserve1_genka_kin) : 0, 0, 'round', true)} ({Calc.setInfoNum(info?.reserve1_genka_percent, 2)}%)
          </div>
        </div>
        <div className="t_row">
          <div className="t_header">予備原価：見積</div>
          <div className="t_body">
            &yen;&nbsp;{MathHelper.rounding2Str(info?.reserve2_estimate_kin ? Number(info.reserve2_estimate_kin) : 0, 0, 'round', true)} ({Calc.setInfoNum(info?.reserve2_estimate_percent, 2)}%)<br />
          </div>
        </div>
        <div className="t_row">
          <div className="t_header">予備原価：原価</div>
          <div className="t_body">
            &yen;&nbsp;{MathHelper.rounding2Str(info?.reserve2_genka_kin ? Number(info.reserve2_genka_kin) : 0, 0, 'round', true)} ({Calc.setInfoNum(info?.reserve2_genka_percent, 2)}%)
          </div>
        </div>
      </div>
    </div>
  );
});
