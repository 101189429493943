import { takeEvery } from 'redux-saga/effects';
import { CallDBActions } from './call-db.action';
import { ApiCallDBGet } from './api/api-call-db';
import { ApiBase } from '../../service/api-base';

function* tryGetCallDB(action: ReturnType<typeof CallDBActions.api.callDb.get>) {
  const api = new ApiCallDBGet(action.payload.param);
  // yield api.run()
  //   .then((res) => {
  //     console.group();
  //     console.log(`[${res.header.status}]`);
  //     console.log(`[${res.header.status_code}]`);
  //     console.log('header', res.header);
  //     console.log('body', res.body);
  //     console.groupEnd();
  //   });
  try {
    const result: {header:any, body: any} = yield api.run();
    if (ApiBase.isSuccess(result)) {
      console.log(result);
    }
  } catch (e) {
    console.log('failed');
  }
}

export function* CallDBSaga() {
  yield takeEvery(CallDBActions.api.callDb.get, tryGetCallDB);
}
