import { push } from 'connected-react-router';
import { useCallback, useMemo, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useDispatch, useSelector } from 'react-redux';
import { useDidMount } from '../../../../../../hooks/life-cycle';
import { DialogActions } from '../../../../../../redux/dialog/dialog.action';
import { State } from '../../../../../../redux/root.reducer';
import { RoutingPath } from '../../../../../../routes/routing-pass';
import { ProjectListType, ProjectShowType } from '../../../../../../type/project/project.type';
import { CardList } from '../../../../../ui/card/card-list/card-list';
import { ProjectCard } from '../../../../../ui/card/project/project-card';

type Props = {
  type?: ProjectShowType;
  data?: ProjectListType[] | null;
  handleCardClick?:(project: ProjectListType) => void;
  isOrder?: boolean;
}

export const ProjectListSP = (props: Props) => {
  const {
    type, data, handleCardClick, isOrder,
  } = props;

  const { ref, inView } = useInView({
    // オプション
    rootMargin: '-50px', // ref要素が現れてから50px過ぎたら
    triggerOnce: true, // 最初の一度だけ実行
  });

  /* Hooks */
  const projectListData = data || data === null
    ? data
    : useSelector((state: State) => state.project.list);
  const dispatch = useDispatch();
  const [isInCustomerDetail, setIsInCustomerDetail] = useState(false);

  /* List */
  const projectList = useMemo(() => (type
    ? projectListData?.filter((v) => v.construction_flag === type)
    : projectListData
  ), [type, projectListData, data]);

  const handleClickCard = useCallback((id: number) => {
    if (handleCardClick) {
      const findData = data?.find((v) => v.id === id);
      if (findData) handleCardClick(findData);
      if (!isOrder) dispatch(DialogActions.pop());
      return;
    }
    dispatch(push(`${RoutingPath.projectDetail}/${id}/project`));
  }, [data]);

  useDidMount(() => {
    const arr = window.location.href.split('customer/detail');
    setIsInCustomerDetail(arr.length >= 2);
  });

  return (
    <CardList isLoading={inView} list={projectList}>
      {projectList?.map((v, i) => (
        <div
          key={`card${i}`}
          className="list_base_card_wrap"
          ref={projectList.length - 1 ? ref : undefined}
        >
          <ProjectCard
            projectData={v}
            onClick={handleClickCard}
            isInCustomerDetail={isInCustomerDetail}
            index={i}
          />
        </div>
      ))}
    </CardList>

  );
};
