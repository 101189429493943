import actionCreatorFactory from 'typescript-fsa';
import { apiCsvCustomerRank } from './api/customer-rank/api-customer-rank';
import { apiCsvMaintenance } from './api/maintenance/api-csv-maintenance';
import { apiCsvOrder } from './api/order/api-csv-order';
import { apiCsvNonOrder } from './api/non-order/api-csv-non-order';
import { apiCsvProject } from './api/project/api-csv-project';
import { apiCsvWedding } from './api/wedding/api-csv-wedding';
import { apiCsvBirthday } from './api/birhday/api-csv-birthday';
import { apiCsvCustomer, ApiCsvCustomerGetListResponse } from './api/customer/api-csv-customer';
import { apiCsvLostOrder } from './api/lost-order/api-csv-lost-order';
import { apiCsvSupportHistory } from './api/support-history/api-csv-support-history';
import {
  CsvBirthdaySort,
  CsvCustomerRankSort,
  CsvCustomerSort,
  CsvLostOrderSort,
  CsvMaintenanceSort,
  CsvNonOrderSort,
  CsvOrderSort,
  CsvProjectSort,
  CsvSupportHistorySort,
  CsvWeddingSort,
} from '../../type/csv/csv-sort.type';
import {
  CsvCustomerListType,
  CsvBirthdayListType,
  CsvWeddingAnniversaryListType,
  CsvProjectListType,
  CsvLostOrderType,
  CsvNotOrderType,
  CsvOrderListType,
  CsvMaintenanceType,
  CsvSupportHistoryType,
  CsvCustomerRankType,
} from '../../type/csv/csv.type';
import { apiCsvMeisai } from './api/meisai/api-csv-meisai';

const ActionCreator = actionCreatorFactory('project');

export const CsvActions = {
  api: {
    customer: apiCsvCustomer,
    birthday: apiCsvBirthday,
    wedding: apiCsvWedding,
    project: apiCsvProject,
    lostOrder: apiCsvLostOrder,
    nonOrder: apiCsvNonOrder,
    order: apiCsvOrder,
    maintenance: apiCsvMaintenance,
    supportHistory: apiCsvSupportHistory,
    customerRank: apiCsvCustomerRank,
    meisai: apiCsvMeisai,
  },
  setCustomerSort: ActionCreator<CsvCustomerSort | null>('set/customer/sort'),
  setCustomerCount: ActionCreator<number>('set/customer/count'),
  setCustomerList: ActionCreator<CsvCustomerListType[] | null>('set/customer/list'),
  setBirthdayList: ActionCreator<CsvBirthdayListType[] | null>('set/birthday/list'),
  setBirthdayCount: ActionCreator<number>('set/birthday/count'),
  setBirthdaySort: ActionCreator<Partial<CsvBirthdaySort> | null>('set/birthday/sort'),
  setWeddingList: ActionCreator<CsvWeddingAnniversaryListType[] | null>(
    'set/wedding/list',
  ),
  setWeddingCount: ActionCreator<number>('set/wedding/count'),
  setWeddingSort: ActionCreator<CsvWeddingSort | null>('set/wedding/sort'),
  setProjectList: ActionCreator<CsvProjectListType[] | null>('set/project/list'),
  setProjectCount: ActionCreator<number>('set/project/count'),
  setProjectSort: ActionCreator<CsvProjectSort | null>('set/project/sort'),
  setLostOrderList: ActionCreator<CsvLostOrderType[] | null>('set/lost/order/list'),
  setLostOrderCount: ActionCreator<number>('set/lost/order/count'),
  setLostOrderSort: ActionCreator<CsvLostOrderSort | null>('set/lost/order/sort'),
  setNonOrderList: ActionCreator<CsvNotOrderType[] | null>('set/non/order/list'),
  setNonOrderCount: ActionCreator<number>('set/non/order/count'),
  setNonOrderSort: ActionCreator<CsvNonOrderSort | null>('set/non/order/sort'),
  setOrderList: ActionCreator<CsvOrderListType[] | null>('set/order/list'),
  setOrderCount: ActionCreator<number>('set/order/count'),
  setOrderSort: ActionCreator<CsvOrderSort | null>('set/order/sort'),
  setMaintenanceList: ActionCreator<CsvMaintenanceType[] | null>(
    'set/maintenance/list',
  ),
  setMaintenanceCount: ActionCreator<number>('set/maintenance/count'),
  setMaintenanceSort: ActionCreator<Partial<CsvMaintenanceSort> | null>('set/maintenance/sort'),
  setSupportHistoryList: ActionCreator<CsvSupportHistoryType[] | null>(
    'set/support/history/list',
  ),
  setSupportHistoryCount: ActionCreator<number>('set/support/history/count'),
  setSupportHistorySort: ActionCreator<Partial<CsvSupportHistorySort> | null>('set/support/history/sort'),
  setCustomerRankList: ActionCreator<CsvCustomerRankType[] | null>(
    'set/customer/rank/list',
  ),
  setCustomerRankCount: ActionCreator<number>('set/customer/rank/count'),
  setCustomerRankSort: ActionCreator<Partial<CsvCustomerRankSort> | null>('set/customer/rank/sort'),
  setPageType: ActionCreator<number>('set/page-type'),
  resetState: ActionCreator('reset/state'),
  resetPageType: ActionCreator('reset/page-type'),
};
