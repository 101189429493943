import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { cloneDeep } from 'lodash';
import { useAppSelector } from '../../../../../hooks/use-redux';
import { EstimateListType, EstimateSortState } from '../../../../../type/estimate/estimate.type';
import { EstimateCollection } from '../../../../../collection/estimate/estimate.collection';
import { EstimateActions } from '../../../../../redux/estimate/estimate.action';
import { EstimateModel } from '../../../../../model/estimate/estimate-model';
import { DialogActions } from '../../../../../redux/dialog/dialog.action';
import { XmlParser } from '../../../../../parser/xml-parser';
import { MeisaiXML, MeisaiListXML } from '../../../../../model/estimate/estimate-meisai.type';
import { EstimateCopySearchBoxSP } from '../serch-box/estimate-copy-search-box.sp';
import { TableSort } from '../../../../ui/table/table-sort/table-sort';
import { EstimateListSP } from '../../../layout/body/list/estimate/estimate-list';
import { Button } from '../../../../ui/button/button';
import { useDidMount } from '../../../../../hooks/life-cycle';
import { MasterActions } from '../../../../../redux/master/master.action';
import { EstimateDetailListSP } from '../../../layout/body/list/estimate/estimate-detail-list';
import { EstimateMeisaiModel } from '../../../../../model/estimate/estimate-meisai.model';
import { MeisaiSortDialog } from '../meisai-sort-dialog.sp/meisai-sort.dialog.sp';
import { IconButton } from '../../../../ui/button/icon-button/icon-button';
import { LeftIconButton } from '../../../../ui/button/left-icon-button/left-icon-button';

type Props = {
    allowEdit?: 0 | 1 | 2;
    callback?: (v: MeisaiListXML[]) => void;
}

export const CopyPastEstimate = (props: Props) => {
  const { callback, allowEdit } = props;

  const dispatch = useDispatch();
  const user = useAppSelector((v) => v.user);
  const [list, setList] = useState<EstimateListType[] | null>(null);
  const [hitCount, setHitCount] = useState(0);
  const [isSearch, setIsSearch] = useState(false);
  const [selected, setSelected] = useState<EstimateListType | null>(null);
  const [sortState, setSortState] = useState({
    ...EstimateCollection._sortCopyInitialState(user),
    created_employee_id: user.id,
  });
  const [isMeisai, setIsMeisai] = useState(false);
  const [model, setModel] = useState<null | EstimateMeisaiModel>(null);
  const [selectList, setSelectList] = useState<MeisaiListXML[]>([]);

  const getList = useCallback((v: EstimateSortState) => {
    console.log(v);
    setIsSearch(true);
    setList(null);
    dispatch(EstimateActions.api.estimate.getList({
      param: {
        ...EstimateModel.listParamInCopy(v),
      },
      callback: (data, hit) => {
        if (!data.length) {
          dispatch(DialogActions.pushMessage({
            title: '',
            message: ['該当する結果が見つかりません'],
          }));
          return;
        }
        setList(cloneDeep(data));
        setHitCount(hit || 0);
      },
    }));
  }, [sortState]);

  const handleCardClick = useCallback((v: EstimateListType) => {
    // if (!selected) return;
    new XmlParser().parse<MeisaiXML>(v.meisai).then((res) => {
      setModel(new EstimateMeisaiModel(res));
    });
  }, [selected]);

  const handleClickSortSearch = useCallback(() => {
    dispatch(DialogActions.push({
      title: '明細検索',
      element: <MeisaiSortDialog
        model={model}
        callback={(v) => {
          setModel(cloneDeep(v));
          setSelectList([]);
        }}
      />,
    }));
  }, [model]);

  useEffect(() => {
    if (!isSearch) return;
    getList(sortState);
  }, [
    sortState.offset,
  ]);

  return (
    !isSearch
      ? (
        <EstimateCopySearchBoxSP
          sort={sortState}
          callback={(v) => {
            setSortState(cloneDeep(v));
            getList(v);
          }}
          callbackClear={(v) => {
            setSortState(cloneDeep(v));
          }}
        />
      )
      : (
        <>
          {!isMeisai
            ? (
              <>
                <TableSort
                  isSp
                  page={sortState.offset ?? 0}
                  limit={100}
                  hitCount={hitCount || 0}
                  callback={(v) => {
                    setSortState({ ...sortState, offset: v });
                  }}
                  className="mt_5 mb_0"
                />
                <hr className="hr_style hr_search_result" />
                <EstimateListSP
                  data={list}
                  handleCardClick={(v) => {
                    setSelected(v);
                    setIsMeisai(true);
                    handleCardClick(v);
                  }}
                  isCopy
                />
                <div className="search_box_footer base_dialog_content_inner_footer">
                  <Button
                    size="md"
                    color="dark"
                    className="item_btn_right"
                    onClick={() => {
                      setSelected(null);
                      setList(null);
                      setIsSearch(false);
                    }}
                  >
                    戻る
                  </Button>
                </div>
              </>
            ) : (
              <>
                <div className="flex_box dialog_head_btn_wrap">
                  <LeftIconButton
                    label="全て選択"
                    fontAwesomeClass="fas fa-check-square"
                    size="sm"
                    color="secondary"
                    className="item_btn_left"
                    onClick={() => setSelectList(cloneDeep(model?.list || []))}
                  />
                  <LeftIconButton
                    label="全て解除"
                    fontAwesomeClass="far fa-square"
                    size="sm"
                    color="dark"
                    className="item_btn_left"
                    onClick={() => setSelectList([])}
                  />
                  <Button
                    size="sm"
                    color="secondary"
                    className="item_btn_left ml_auto"
                    onClick={() => handleClickSortSearch()}
                  >
                    絞り込み
                  </Button>
                </div>
                <EstimateDetailListSP
                  dndkey="detail-edit"
                  sortMode={false}
                  multiMode
                  model={model}
                  callbackModel={(v) => setModel(cloneDeep(v))}
                  allowEdit={allowEdit}
                  selected={selectList}
                  callbackSelected={(v) => setSelectList(v)}
                />
                <div className="base_dialog_content_inner_footer">
                  <Button
                    size="md"
                    color="dark"
                    className="item_btn_left"
                    onClick={() => {
                      setSelected(null);
                      setIsMeisai(false);
                    }}
                  >
                    戻る
                  </Button>
                  <Button
                    size="md"
                    color="secondary"
                    className="item_btn_right"
                    disabled={!selectList.length}
                    onClick={() => {
                      callback?.(cloneDeep(selectList));
                      dispatch(DialogActions.pop());
                    }}
                  >
                    選択明細追加
                  </Button>

                </div>
              </>
            )}
        </>
      )
  );
};
