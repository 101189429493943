import { SupportHistoryEditState } from '../../../type/support-history/support-history.type';
import {
  ValidationLengthUnder40,
  ValidationLengthUnder500,
  ValidationLengthUnder60,
} from '..';
import { ValidationDatePicker } from '../validation-date-picker';

export const SupportHistoryValidation = (data: SupportHistoryEditState) => {
  window.console.log();
  return (
    // 必須
    !data.customer_id
    || !data.contact_dt
    || !data.contact_store_id
    || !data.contact_employee_id
    || !data.customer_name

  // バリデーション
  || ValidationDatePicker.filter((v) => !v.run(String(data.contact_dt || ''))).length
  || ValidationLengthUnder60.filter((v) => !v.run(String(data.title || ''))).length
  || ValidationLengthUnder500.filter((v) => !v.run(String(data.support_comment || ''))).length
  || ValidationDatePicker.filter((v) => !v.run(String(data.support_dt || ''))).length
  );
};
