import { takeEvery, delay, put } from 'redux-saga/effects';
import * as lodash from 'lodash';
import { cloneDeep } from 'lodash';
import { ResultFileType, ResultType } from '../../type/api.type';
import { FileActions } from './file.action';
import { Store } from '../store';
import {
  ApiFilePost, ApiFileDelete, ApiFileGet, ApiFileGetList,
} from './api/file/api-file';
import {
  downloadHandle, getListHandle, postHandle, getHandle, deleteHandle, getError,
} from '../root.saga';
import { ApiFileDownload } from './api/download/api-file-download';
import { ApiFile } from '../root.type';
import { DialogActions } from '../dialog/dialog.action';
import { SystemActions } from '../system/system.action';
import { ApiBase } from '../../service/api-base';

import { FileListType } from '../../type/file/file.type';
import { ApiUser } from '../user/api/api-user';
import { AuthActions } from '../auth/auth.action';

export type Param<T = any> = {
  noLoad?: boolean;
  noDelay?: boolean;
  api: ApiBase;
  onSuccess: (result: T[], file:{capacity: number, total:number}, hit_count: number) => void;
}

/**
 * getList用共通処理
 * @param param
 */
export function* getFileListHandle(param:Param<FileListType>) {
  const {
    api, onSuccess, noLoad, noDelay,
  } = param;
  if (!noLoad) {
    yield put(SystemActions.isLoading(true));
  } else if (!Store.getState().router.location.pathname.match('master') && !noDelay) {
    yield delay(500);
  }
  try {
    const result: ResultFileType = yield api.run();
    if (ApiBase.isSuccess(result)) {
      yield onSuccess(lodash.cloneDeep(result.body.data || []),
        {
          capacity: result.body.capacity,
          total: result.body.total_file_size,
        }, result.body.hit_count || 0);
    } else {
      yield put(SystemActions.errorHandle({ result, title: '' }));
    }
  } catch (e) {
    yield put(SystemActions.connectionError({}));
  } finally {
    if (!noLoad) {
      yield put(SystemActions.isLoading(false));
    }
  }
}

function* tryFileGet(action: ReturnType<typeof FileActions.api.file.get>) {
  const { param, callback } = action.payload;
  if (!param.id) {
    yield getError('ファイル');
    return;
  }
  const api = new ApiFileGet(param);
  yield getHandle<ApiFile.Response.Get>({
    api,
    onSuccess: (res) => {
      if (!res) return;
      if (callback) {
        callback(lodash.cloneDeep(res));
        return;
      }
      Store.dispatch(FileActions.setFile(res));
    },
    * onError() {
      yield getError('見積');
    },
  });
}

function* tryFileGetFormatList(action: ReturnType<typeof FileActions.api.file.getFormatList>) {
  const { param, onSuccess } = action.payload;
  yield getListHandle<ApiFile.Response.List>({
    api: new ApiFileGetList(param),
    noDelay: true,
    noLoad: true,
    onSuccess: (res) => {
      if (onSuccess) {
        const list: string[] = [];
        res.forEach((v) => {
          const find = list.find((v2) => v2 === v.format);
          if (!find)list.push(v.format);
        });
        onSuccess(cloneDeep(list));
      }
    },
  });
}
function* tryFileGetList(action: ReturnType<typeof FileActions.api.file.getList>) {
  const { param, onSuccess, noLoad } = action.payload;
  yield getFileListHandle({
    api: new ApiFileGetList(param),
    noDelay: true,
    noLoad: true,
    * onSuccess(res, file, hitCount) {
      if (onSuccess) {
        onSuccess(cloneDeep(res), cloneDeep(file), hitCount);
        return;
      }
      yield Store.dispatch(FileActions.setFileSize({ ...file }));
      yield Store.dispatch(FileActions.setList(res));
    },
  });
}

function* tryFilePost(action: ReturnType<typeof FileActions.api.file.post>) {
  const { param, onSuccess, onError } = action.payload;
  const api = new ApiFilePost(param);
  yield put(SystemActions.isLoading(true));
  try {
    const me = new ApiUser();
    yield me.run();
    yield put(AuthActions.setToken(me.token));
    api.header = {
      ...api.header,
      'X-CSRF-TOKEN': me.token,
    };
    const result: ResultType = yield api.run();
    if (ApiBase.isSuccess(result)) {
      yield put(SystemActions.isLoading(false));
      yield put(DialogActions.pushMessage({
        title: `ファイル情報${param.id ? '更新' : '登録'}完了`,
        message: [`ファイル情報${param.id ? '更新' : '登録'}完了`],
        callback: () => {
          Store.dispatch(DialogActions.pop());
          if (onSuccess)onSuccess();
        },
        callbackClose: () => {
          Store.dispatch(DialogActions.pop());
          if (onSuccess)onSuccess();
        },
      }));
    } else {
      yield put(SystemActions.isLoading(false));
      yield put(DialogActions.pushMessage({
        title: 'お知らせ',
        message: result.header.messages || [],
        callback: onError,
      }));
    }
  } catch (error) {
    yield put(SystemActions.isLoading(false));
    yield put(SystemActions.connectionError({
      message: ['インターネット接続の確認後に再度「登録／更新」を行ってください。'],
    }));
  }
}

function* tryFileDownload(action: ReturnType<typeof FileActions.api.file.download>) {
  const { isPreview, callback } = action.payload;
  yield delay(500);
  yield downloadHandle({
    api: new ApiFileDownload(action.payload.param),
    fileName: action.payload.fileName,
    isPreview,
    callback: (url) => callback?.(url),
  });
}

function* tryFileDelete(action: ReturnType<typeof FileActions.api.file.delete>) {
  const { param, callback } = action.payload;
  const api = new ApiFileDelete(param);
  yield deleteHandle({
    api,
    onSuccess: () => {
      callback();
      // Store.dispatch(DialogActions.pop());
    },
  });
}

export function* FileSaga() {
  yield takeEvery(FileActions.api.file.get, tryFileGet);
  yield takeEvery(FileActions.api.file.post, tryFilePost);
  yield takeEvery(FileActions.api.file.getList, tryFileGetList);
  yield takeEvery(FileActions.api.file.getFormatList, tryFileGetFormatList);
  yield takeEvery(FileActions.api.file.download, tryFileDownload);
  yield takeEvery(FileActions.api.file.delete, tryFileDelete);
}
