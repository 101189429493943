import actionCreatorFactory from 'typescript-fsa';
import { Maintenance, MaintenanceList, MaintenanceSortState } from '../../type/maintenance/maintenance.type';
import { apiMaintenance } from './api/maintenance/api-maintenance';
import { User } from '../../type/auth/user.typs';

const ActionCreator = actionCreatorFactory('maintenance');

export const MaintenanceActions = {
  api: {
    maintenance: apiMaintenance,
  },
  setMaintenance: ActionCreator<Maintenance | null>('set/maintenance'),
  setList: ActionCreator<MaintenanceList[] | null>('set/list'),
  setConcatList: ActionCreator<MaintenanceList[] | null>('set/concat/list'),
  setSort: ActionCreator<Partial<MaintenanceSortState> | null>('set/sort'),
  setInitSort: ActionCreator<User>('set/init/sort'),
  setIsInitSort: ActionCreator<boolean>('set/is-init'),
  resetState: ActionCreator('reset/state'),
};
