import { MathHelper as MH } from '../utilities/math-helper';

export class NumModel {
  /**
   * いらない小数点を省く
   * @static
   * @param {(number | undefined | string)} v 数字
   * @param {number} decimalLen 少数の数
   * @param {('round' | 'floor' | 'ceil')} [mode='round'] 捜査対象
   * @returns {string} カンマ付きValue
   */
  static setBlurNum(
    v: number | undefined | string,
    decimalLen: number,
    mode: 'round' | 'floor' | 'ceil' = 'round',
  ):string {
    if (!v) return '0';
    if (!Number(v)) return '0';
    if (String(v).split('.').length === 1) {
      return MH.rounding2Str(
        Number(v), 0, mode, true,
      );
    }
    const decimalNum = String(v).split('.')[1];
    if (decimalNum.length < decimalLen) {
      return MH.rounding2Str(
        Number(v), decimalLen ? decimalLen - decimalNum.length : 0, mode, true,
      );
    }
    let count = 0;
    for (let i = 0; i < decimalNum.length; i += 1) {
      if (decimalNum[i] === '0') {
        count += 1;
      }
    }
    return MH.rounding2Str(
      Number(v), decimalLen ? decimalLen - count : 0, mode, true,
    );
  }
}

export const NM = NumModel;
