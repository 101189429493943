import { cloneDeep, isEqual } from 'lodash';
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Input as SInput } from 'semantic-ui-react';
import { useDidMount } from '../../../../../hooks/life-cycle';
import { useAppSelector } from '../../../../../hooks/use-redux';
import { CustomerDetailActions } from '../../../../../redux/customer-detail/customer-detail.action';
import { DialogActions } from '../../../../../redux/dialog/dialog.action';
import { MasterActions } from '../../../../../redux/master/master.action';
import { State } from '../../../../../redux/root.reducer';
import { FileSortState } from '../../../../../type/file/file.type';
import { pulldown } from '../../../../../utilities/pulldown';
import { DatePicker } from '../../../../ui/date-picker/date-picker';
import { Input } from '../../../../ui/input/input';
import { Select } from '../../../../ui/select/select';
import { RefindSP } from './refind.sp';
import { Project } from '../../../../../type/project/project.type';
import { useDateValidation } from '../../../../../hooks/use-date-validation';
import { DateTermValidation } from '../../../../../model/validation/date/term-date.validation';

type Props = {
  formatList: string[];
  callback: (sort: FileSortState) => void;
  info?: Project | null;
}

export const RefindFileSP = (props:Props) => {
  const { callback, formatList, info } = props;

  /* Hook */
  const dispatch = useDispatch();
  const sort = useSelector((state: State) => state.customerDetail.fileSort, isEqual);
  const employeeList = useAppSelector((v) => v.master.employeeList);

  const [sortState, setSortState] = useState(cloneDeep(sort));

  const termDate = useDateValidation([{
    start: sortState.upload_date_start, end: sortState.upload_date_end,
  }]);

  /* Callback */
  const handleClickSearch = useCallback(() => {
    if (termDate.messages.length) {
      termDate.check();
      return;
    }
    dispatch(DialogActions.pop());
    callback(sortState);
    dispatch(CustomerDetailActions.setFileSort(sortState));
  }, [callback, sort, sortState, termDate]);

  const setState = useCallback((v: FileSortState) => {
    setSortState({ ...cloneDeep(sortState), ...cloneDeep(v) });
  }, [dispatch, sortState]);

  useDidMount(() => {
    dispatch(MasterActions.api.employee.getList({}));
  });

  return (
    <RefindSP callback={handleClickSearch}>
      <>
        <div className="category_wrap">
          <div className="item_wrap">
            <div className="item_label">No</div>
            <SInput
              className=""
              type="number"
              value={sortState.internal_id}
              onChange={(e) => setState({
                ...sortState, internal_id: e.target.value,
              })}
            />
          </div>
        </div>
        <div className="category_wrap">
          <div className="item_wrap">
            <div className="item_label">案件名</div>
            <Input
              className=""
              disabled={info !== undefined}
              value={!info ? sortState.project_name : info.name}
              onChange={(e) => setState({ ...sortState, project_name: e.target.value })}
            />
          </div>
          <div className="item_wrap">
            <div className="item_label">ファイル名</div>
            <Input
              className=""
              value={sortState.file_name || null}
              onChange={(e) => setState(
                { ...sortState, file_name: e.target.value },
              )}
            />
          </div>
          <div className="item_wrap">
            <div className="item_label">形式</div>
            <Select
              value={sortState.format}
              onChange={(v) => setState({ ...sortState, format: String(v) })}
              defaultLabel="全て"
              options={formatList.map((v) => ({ text: v, value: v }))}
            />
          </div>
          <div className="item_wrap">
            <div className="item_label">アップロード日</div>
            <div className="item_body item_schedule wrap">
              <div className="item_schedule__form">
                <DatePicker
                  date={sortState.upload_date_start}
                  onChange={(v) => setState({ ...sortState, upload_date_start: v })}
                  term={DateTermValidation([{
                    start: sortState.upload_date_start, end: sortState.upload_date_end,
                  }])}
                />
              </div>
              <div className="item_schedule__form flex_box flex_align_center mt_10">
                <div className="item_schedule__tilde">〜</div>
                <DatePicker
                  date={sortState.upload_date_end}
                  onChange={(v) => setState({ ...sortState, upload_date_end: v })}
                  term={DateTermValidation([{
                    start: sortState.upload_date_start, end: sortState.upload_date_end,
                  }])}
                />
              </div>
            </div>
          </div>
          <div className="item_wrap">
            <div className="item_label">サイズ</div>
            <SInput
              className=""
              type="number"
              value={sortState.min_size}
              onChange={(e) => setState(
                { ...sortState, min_size: e.target.value },
              )}
            />
            <div className="flex_box flex_align_center mt_10">
              <label className="ml_5 mr_5">〜</label>
              <SInput
                className=""
                type="number"
                value={sortState.max_size}
                onChange={(e) => setState(
                  { ...sortState, max_size: e.target.value },
                )}
              />
            </div>
          </div>
        </div>
        <div className="category_wrap">
          <div className="item_wrap">
            <div className="item_label">更新者</div>
            <Select
              value={sortState.updater}
              onChange={(v) => setState({ ...sortState, updater: Number(v) })}
              defaultLabel="全て"
              options={pulldown(employeeList)}
            />
          </div>
          <div className="item_wrap">
            <div className="item_label">コメント</div>
            <Input
              className=""
              value={sortState.comment}
              onChange={(e) => setState({
                ...sortState, comment: e.target.value,
              })}
            />
          </div>
        </div>
      </>
    </RefindSP>
  );
};
