/* eslint-disable no-nested-ternary */
import { useState, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cloneDeep, isEqual } from 'lodash';
import { Select } from '../../../../../ui/select/select';
import { LeftIconButton } from '../../../../../ui/button/left-icon-button/left-icon-button';
import { SearchBoxPC } from '../../../../layout/search-box/search-box.pc';
import { Input } from '../../../../../ui/input/input';
import { CsvActions } from '../../../../../../redux/csv/csv.action';
import { State } from '../../../../../../redux/root.reducer';
import { CsvBirthdaySort } from '../../../../../../type/csv/csv-sort.type';
import { MasterActions } from '../../../../../../redux/master/master.action';
import { useDidMount } from '../../../../../../hooks/life-cycle';
import { LabelInput } from '../../../../../ui/input/label-input';
import { InputNum } from '../../../../../ui/input/input-num';
import { InputTel } from '../../../../../ui/input/input-tel';
import { DialogActions } from '../../../../../../redux/dialog/dialog.action';

type Props = {
  callback: (v: CsvBirthdaySort) => void;
}

export const CsvSearchBoxBirthday = (props: Props) => {
  const { callback } = props;

  /* Hooks */
  const dispatch = useDispatch();
  const sortState = useSelector((state: State) => state.csv.birthdaySort);
  const {
    storeList,
    employeeList,
  } = useSelector((state: State) => (state.master), isEqual);

  /* State */
  const [sort, setSort] = useState(cloneDeep(sortState));
  const [isDateValid, setIsDateValid] = useState(false);

  const _employeeList = useMemo(() => employeeList.filter((
    v,
  ) => (Number.isNaN(sort.store_id) || sort.store_id === null
    ? true
    : (v.store_id === sort.store_id))),
  [sort.store_id, employeeList]);

  const birthValidate = useMemo(() => {
    const start = Number(sort.birth_month_start);
    const end = Number(sort.birth_month_end);
    if (!end || !start) return true;
    if (start > end) return false;
    if (start === end) {
      if (!sort.birth_day_start || !sort.birth_day_end) return true;
      if (Number(sort.birth_day_start)
        > Number(sort.birth_day_end)) return false;
    }
    return true;
  }, [sort]);

  const dateValidation = useMemo(() => (
    (!!sort.birth_day_start && !sort.birth_month_start)
    || (!!sort.birth_day_end && !sort.birth_month_end)
  ), [sort]);

  /* Callback */
  const setState = useCallback((v: CsvBirthdaySort) => {
    setSort(cloneDeep(v));
  }, [sort]);

  const handleClickSearch = useCallback(() => {
    if (!birthValidate) {
      dispatch(DialogActions.pushMessage({
        title: 'お知らせ',
        message: ['入力された検索項目は無効です。'],
      }));
      return;
    }

    if (dateValidation) {
      dispatch(DialogActions.pushMessage({
        title: 'お知らせ',
        message: ['日付のみの入力は無効です'],
      }));
      return;
    }
    callback({
      ...cloneDeep(sort),
      limit: sortState.limit,
    });
    dispatch(CsvActions.setBirthdaySort({
      ...cloneDeep(sort),
      limit: sortState.limit,
    }));
  }, [callback, sort, birthValidate, dateValidation, sortState]);

  /* Master */
  useDidMount(() => {
    dispatch(MasterActions.api.store.getList({}));
    dispatch(MasterActions.api.employee.getList({}));
  });

  return (
    <>
      <SearchBoxPC
        openCallback={() => {}}
      >
        <div id="csv_birthday">
          <div className="item_wrap">
            <div className="item_box">
              <div className="item_head">営業担当</div>
              <Select
                className="add_text_left"
                label="店舗"
                value={sort?.store_id}
                onChange={(data) => setSort({
                  ...sort,
                  store_id: Number(data),
                  employee_id: NaN,
                })}
                defaultLabel="全て"
                options={storeList.map((v) => ({
                  text: v.name, value: v.id,
                }))}
              />
              <Select
                className="add_text_left"
                label="担当者"
                value={sort?.employee_id}
                onChange={(data) => setSort({ ...sort, employee_id: Number(data) })}
                defaultLabel="全て"
                options={_employeeList.map((v) => ({
                  text: v.name, value: v.id,
                }))}
              />
            </div>
          </div>
          <div className="item_wrap">
            <div className="item_box">
              <div className="item_head">顧客登録名</div>
              <Input
                className=""
                value={sort?.name}
                onChange={(e) => setState({ ...sort, name: e.target.value })}
              />
            </div>
            <div className="item_box">
              <div className="item_head">家族お名前</div>
              <Input
                className=""
                value={sort?.family_name}
                onChange={(e) => setState({ ...sort, family_name: e.target.value })}
              />
            </div>
            <div className="item_box">
              <div className="item_head">続柄</div>
              <Input
                className="small"
                value={sort?.relationship}
                onChange={(e) => setState({ ...sort, relationship: e.target.value })}
              />
            </div>
            <div className="item_box">
              <div className="item_head">携帯番号</div>
              <InputTel
                value={sort?.mobile_phone}
                onChange={(v) => setState({ ...sort, mobile_phone: v })}
              />
            </div>
          </div>
          <div className="item_wrap">
            <div className="item_box">
              <div className="item_head">生年月日</div>
              <LabelInput
                pos="Right"
                label="月"
                className="small"
              >
                <InputNum
                  value={sort?.birth_month_start}
                  intLen={2}
                  onBlur={() => setIsDateValid(!birthValidate)}
                  errorMessage={isDateValid ? ['正しい日付範囲で入力してください。'] : undefined}
                  onChange={(v) => {
                    setState({
                      ...sort,
                      birth_month_start: v,
                    });
                  }}
                />
              </LabelInput>
              <LabelInput
                pos="Right"
                label="日〜"
                className="small"
              >
                <InputNum
                  value={sort?.birth_day_start}
                  intLen={2}
                  onBlur={() => setIsDateValid(!birthValidate)}
                  errorMessage={
                    isDateValid
                      ? ['正しい日付範囲で入力してください。']
                      : (!!sort.birth_day_start && !sort.birth_month_start)
                        ? ['日付のみの入力は無効です']
                        : undefined
                  }
                  onChange={(v) => {
                    setState({
                      ...sort,
                      birth_day_start: v,
                    });
                  }}
                />
              </LabelInput>
              <LabelInput
                pos="Right"
                label="月"
                className="small"
              >
                <InputNum
                  value={sort?.birth_month_end}
                  intLen={2}
                  onBlur={() => setIsDateValid(!birthValidate)}
                  errorMessage={isDateValid ? ['正しい日付範囲で入力してください。'] : undefined}
                  onChange={(v) => {
                    setState({
                      ...sort,
                      birth_month_end: v,
                    });
                  }}
                />
              </LabelInput>
              <LabelInput
                pos="Right"
                label="日"
                className="small"
              >
                <InputNum
                  value={sort?.birth_day_end}
                  intLen={2}
                  onBlur={() => setIsDateValid(!birthValidate)}
                  errorMessage={
                    isDateValid
                      ? ['正しい日付範囲で入力してください。']
                      : (!!sort.birth_day_end && !sort.birth_month_end)
                        ? ['日付のみの入力は無効です']
                        : undefined
                  }
                  onChange={(v) => {
                    setState({
                      ...sort,
                      birth_day_end: v,
                    });
                  }}
                />
              </LabelInput>
            </div>
            <LeftIconButton
              label="検索"
              fontAwesomeClass="fas fa-search"
              className="btn_search for_simple"
              size="sm"
              color="secondary"
              onClick={handleClickSearch}
            />
          </div>
        </div>
      </SearchBoxPC>
    </>
  );
};
