import {
  all, delay, fork, put,
} from 'redux-saga/effects';
import * as lodash from 'lodash';
import { goBack, push } from 'connected-react-router';
import { ApiBase } from '../service/api-base';
import { ResFileType, ResultType } from '../type/api.type';
import { AuthSaga } from './auth/auth.saga';
import { CustomerSaga } from './customer/customer.saga';
import { DialogSaga } from './dialog/dialog.saga';
import { MapSaga } from './map/map.saga';
import { MasterSaga } from './master/master.saga';
import { ProjectSaga } from './project/project.saga';
import { SystemActions } from './system/system.action';
import { SystemSaga } from './system/system.saga';
import { TestSaga } from './test/test.saga';
import { TagSaga } from './tag/tag.saga';
import { Store } from './store';
import { DialogActions } from './dialog/dialog.action';
import { FileSaga } from './file/file.saga';
import { EstimateSaga } from './estimate/estimate.saga';
import { SupportHistorySaga } from './support-history/support-history.saga';
import { MaintenanceSaga } from './maintenance/maintenance.saga';
import { OrderSaga } from './order/order.saga';
import { CsvSaga } from './csv/csv.saga';
import { BillSaga } from './bill/bill.saga';
import { AuthActions } from './auth/auth.action';
import { ApiUser } from './user/api/api-user';
import { UserSaga } from './user/user.saga';
import { Message } from '../collection/message.collection';
import { ZipCodeSaga } from './zip-code/zip-code.saga';
import { CallDBSaga } from './call-db/call-db.saga';
import { RoutingPath } from '../routes/routing-pass';
import { ChangeCompanySaga } from './changecompany/changecompany.saga';

export function* getError(target: string) {
  // eslint-disable-next-line no-alert
  window.alert(`該当の${target}は存在しません`);
  yield put(goBack());

  // yield Store.dispatch(DialogActions.pushMessage({
  //   title: `${target}情報`,
  //   message: [`該当の${target}は存在しません`],
  //   callback: () => {
  //     Store.dispatch(DialogActions.pop());
  //     Store.dispatch(goBack());
  //   },
  //   callbackClose: () => {
  //     Store.dispatch(DialogActions.pop());
  //     Store.dispatch(goBack());
  //   },
  // }));
}

export type GetParam<T = any> = {
  noLoad?: boolean;
  api: ApiBase;
  onSuccess: (result: T | null) => void;
  onError?: () => void;
  onErrorResult?: (result?: any) => void;
}

/**
 * getList用共通処理
 * @param param
 */
export function* getHandle<T = any>(param:GetParam<T>) {
  const {
    api, onSuccess, noLoad, onError, onErrorResult,
  } = param;
  if (!noLoad) {
    yield put(SystemActions.isLoading(true));
  }
  try {
    const result: ResultType<T> = yield api.run();

    if (result.header.status_code === 401) {
      if (Store.getState().dialog.is404) return;
      yield put(DialogActions.setIs404(true));
      if (localStorage.getItem('persist:user')) {
        yield put(DialogActions.pushMessage({
          title: 'お知らせ',
          message: Message.session,
          label: '再ログイン',
          callback: () => {
            Store.dispatch(DialogActions.setIs404(false));
            Store.dispatch(push(RoutingPath.login));
          },
          isInvisibleClose: true,
        }));
        return;
      }

      yield put(push(RoutingPath.login));
      return;
    }

    if (ApiBase.isSuccess(result)) {
      if (!result.body.data.length) {
        yield onError?.();
        yield onErrorResult?.(result);
        return;
      }
      yield onSuccess(lodash.cloneDeep(result.body.data ? result.body.data[0] : null));
    } else {
      yield onError?.();
      yield onErrorResult?.(result);
      yield put(SystemActions.errorHandle({ result, title: '' }));
    }
  } catch (e) {
    yield onError?.();
    yield put(SystemActions.connectionError({}));
  } finally {
    if (!noLoad) {
      yield put(SystemActions.isLoading(false));
    }
  }
}

export type Param<T = any> = {
  noLoad?: boolean;
  mapLoad?: boolean;
  noDelay?: boolean;
  api: ApiBase;
  onSuccess: (result: T[], hit_count: number) => void;
}

/**
 * getList用共通処理
 * @param param
 */
export function* getListHandle<T = any>(param:Param<T>) {
  const {
    api, onSuccess, noLoad, noDelay, mapLoad,
  } = param;

  if (mapLoad) {
    yield put(SystemActions.isMapLoading(true));
  } else if (!noLoad) {
    yield put(SystemActions.isLoading(true));
  } else if (!Store.getState().router.location.pathname.match('master') && !noDelay) {
    yield delay(500);
  }
  try {
    const result: ResultType<T> = yield api.run();
    if (result.header.status_code === 401) {
      if (Store.getState().dialog.is404) return;
      yield put(DialogActions.setIs404(true));
      if (localStorage.getItem('persist:user')) {
        yield put(DialogActions.pushMessage({
          title: 'お知らせ',
          message: Message.session,
          label: '再ログイン',
          callback: () => {
            Store.dispatch(DialogActions.setIs404(false));
            Store.dispatch(push(RoutingPath.login));
          },
          isInvisibleClose: true,
        }));
        return;
      }

      yield put(push(RoutingPath.login));
      return;
    }
    if (ApiBase.isSuccess(result)) {
      yield onSuccess(lodash.cloneDeep(result.body.data || []), result.body.hit_count || 0);
    } else {
      yield put(SystemActions.errorHandle({ result, title: '' }));
    }
  } catch (e) {
    yield put(SystemActions.connectionError({}));
  } finally {
    if (mapLoad) {
      yield put(SystemActions.isMapLoading(false));
    } else if (!noLoad) {
      yield put(SystemActions.isLoading(false));
    }
  }
}

export type PostParam = {
  isAllClear?: boolean;
  api: ApiBase;
  onSuccess?: (message:string[] | null | undefined, isWarning?: boolean) => void
  onError?: () => void;
  noMessage?: boolean;
  title?: string;
  editableTitle?: string,
  popMessage?: string[],
  noLoad?: boolean;
  update?: boolean;
  isNoComment?: boolean;
}
/**
 * Post用共通処理
 * @param param
 */
export function* postHandle(param:PostParam) {
  const {
    api, onSuccess, onError, isAllClear,
    /* onError, */ noMessage, title, noLoad, update, isNoComment, popMessage, editableTitle,
  } = param;
  if (!noLoad) {
    yield put(SystemActions.isLoading(true));
  }
  try {
    const me = new ApiUser();
    const meRun: ResultType = yield me.run();
    if (ApiBase.isSuccess(meRun)) {
      yield put(AuthActions.setToken(me.token));
      yield delay(200);
      api.header = {
        ...api.header,
        'X-CSRF-TOKEN': me.token,
      };

      const result: ResultType = yield api.run();

      if (result.header.status_code === 401) {
        if (Store.getState().dialog.is404) return;
        yield put(DialogActions.setIs404(true));
        if (localStorage.getItem('persist:user')) {
          yield put(DialogActions.pushMessage({
            title: 'お知らせ',
            message: Message.session,
            label: '再ログイン',
            callback: () => {
              Store.dispatch(DialogActions.setIs404(false));
              Store.dispatch(push(RoutingPath.login));
            },
            isInvisibleClose: true,
          }));
          return;
        }

        yield put(push(RoutingPath.login));
        return;
      }

      const isWaring = result.header.status === 'WARNING';
      if (ApiBase.isSuccess(result)) {
        if (noMessage) {
          if (isAllClear) yield put(DialogActions.clear());
          if (onSuccess) {
            yield onSuccess([''], isWaring);
          }
          return;
        }

        const _message = popMessage ?? (update ? Message.postUpdateComplete : Message.postComplete);
        yield put(DialogActions.pushMessage({
        /* TODO API接続後変更 */
          title: editableTitle ?? (title ? `${title}${update ? '更新' : '登録'}完了` : ''),
          isCancel: isWaring ? true : undefined,
          message: result.header.messages?.length ? result.header.messages
            : _message,
          callback: () => {
            if (isWaring) {
              if (onSuccess) onSuccess([''], isWaring);
            } else {
              if (isAllClear) Store.dispatch(DialogActions.clear());
              if (onSuccess) onSuccess(['']);
            }
          // if (onSuccess) onSuccess(result.header.messages);
          },
          callbackClose: () => {
            if (isWaring) {
              if (onSuccess) onSuccess([''], isWaring);
            } else {
              if (isAllClear) Store.dispatch(DialogActions.clear());
              if (onSuccess) onSuccess(['']);
            }
          },
        }));
      } else {
        yield put(DialogActions.pushMessage({
          title: 'お知らせ',
          message: result.header.messages || [],
          callback: onError,
        }));
        // yield put(SystemActions.errorHandle({ result, title: title ? `${title}登録失敗` : '' }));
      }
    } else {
      yield put(DialogActions.pushMessage({
        title: 'お知らせ',
        message: meRun.header.messages || [],
        callback: () => {
          Store.dispatch(push(RoutingPath.login));
        },
      }));
    }
  } catch (e) {
    yield put(SystemActions.connectionError({
      message: !isNoComment ? ['インターネット接続の確認後に再度「登録／更新」を行ってください。'] : [],
    }));
  }
  if (!noLoad) { yield put(SystemActions.isLoading(false)); }
}

/**
 * Delete用共通処理
 * @param param
 */
export function* deleteHandle(param:PostParam) {
  const {
    api, onSuccess, isAllClear, onError, noMessage, title, noLoad,
  } = param;
  if (!noLoad) {
    yield put(SystemActions.isLoading(true));
  }
  try {
    const me = new ApiUser();
    yield me.run();
    yield put(AuthActions.setToken(me.token));
    api.header = {
      ...api.header,
      'X-CSRF-TOKEN': me.token,
    };
    const result: ResultType = yield api.run();
    if (result) {
      if (noMessage) {
        if (isAllClear) yield put(DialogActions.clear());
        if (onSuccess) yield onSuccess(['']);
        return;
      }
      yield put(DialogActions.pushMessage({
        /* TODO API接続後変更 */
        title: title ? `${title}削除完了` : '',
        message: ['削除が完了しました。'],
        callback: () => {
          if (isAllClear) Store.dispatch(DialogActions.clear());
          if (onSuccess) onSuccess(['']);
          // if (onSuccess) onSuccess(result.header.messages);
        },
        callbackClose: () => {
          if (isAllClear) Store.dispatch(DialogActions.clear());
          if (onSuccess) onSuccess(['']);
          // if (onSuccess) onSuccess(result.header.messages);
        },
      }));
    } else {
      yield put(DialogActions.pushMessage({
        /* TODO API接続後変更 */
        title: title ? `${title}削除失敗` : '',
        message: ['削除に失敗しました'],
        callback: () => {
          if (onError) onError();
        },
        callbackClose: () => {
          if (onError) onError();
        },
      }));
    }
  } catch (e) {
    yield put(SystemActions.connectionError({}));
  } finally {
    if (!noLoad) {
      yield put(SystemActions.isLoading(false));
    }
  }
}

export type DownloadParam = {
  api: ApiBase;
  fileName?: string;
  isPreview?: boolean;
  callback?: (url: string) => void;
};

/**
 * Download用共通処理
 * @param param
 */
export function* downloadHandle(param:DownloadParam) {
  const {
    api, fileName, isPreview, callback,
  } = param;
  yield put(SystemActions.isLoading(true));
  try {
    const me = new ApiUser();
    yield me.run();
    yield put(AuthActions.setToken(me.token));
    api.header = {
      ...api.header,
      'X-CSRF-TOKEN': me.token,
    };
    const result: ResFileType = yield api.run();
    if (ApiBase.isSuccess(result)) {
      const url = URL.createObjectURL(result.file);
      const a: HTMLAnchorElement = document.createElement('a');
      a.href = url;
      a.target = '_blank';
      if (!isPreview) {
        a.setAttribute('download', fileName || result.file.name);
      }
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
      callback?.(url);
    } else {
      yield put(DialogActions.pushMessage({
        title: '',
        // message: [result.header.messages],
        message: ['ダウンロードに失敗しました'],
      }));
      callback?.('');
      // yield errorHandle(result);
    }
  } catch (e) {
    yield put(SystemActions.connectionError({}));
    callback?.('');
  } finally {
    yield put(SystemActions.isLoading(false));
    callback?.('');
  }
}

export const RootSaga = function* root() {
  yield all([
    fork(TestSaga),
    fork(CallDBSaga),
    fork(AuthSaga),
    fork(UserSaga),
    fork(DialogSaga),
    fork(SystemSaga),
    fork(MapSaga),
    fork(CustomerSaga),
    fork(SupportHistorySaga),
    fork(MasterSaga),
    fork(ProjectSaga),
    fork(TagSaga),
    fork(FileSaga),
    fork(EstimateSaga),
    fork(MaintenanceSaga),
    fork(OrderSaga),
    fork(CsvSaga),
    fork(BillSaga),
    fork(ZipCodeSaga),
    fork(ChangeCompanySaga),
  ]);
};
