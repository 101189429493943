import { useMemo } from 'react';
// import { MapCollection } from '../../../collection/map/map.collection';
import { Config } from '../../../config/config';
import NoImage from '../../../asset/images/noimage.svg';
import { MapCollection } from '../../../collection/map/map.collection';

type Props = {
  isShow: boolean;
  lat: string | number;
  lng: string | number;
  sizeX?: number;
  sizeY?: number;
  shadow?: boolean;
}

export const StreetViewImg = (props: Props) => {
  const {
    lat, lng, sizeX, sizeY, isShow, shadow,
  } = props;

  const path = useMemo(() => {
    if (Config.mode === 'local') return NoImage;
    return (isShow
      ? `https://maps.googleapis.com/maps/api/streetview?size=${sizeX}x${sizeY}&location=${lat},${lng}&fov=80&heading=70&pitch=0&key=${Config.apiKey}`
      : NoImage);
  },
  [lat, lng, sizeX, sizeY, isShow, Config.mode]);

  return <img src={path} alt="" className={`${shadow ? '' : 'no_shadow'}`} />;
};

StreetViewImg.defaultProps = {
  sizeX: 100,
  sizeY: 100,
  shadow: false,
};
