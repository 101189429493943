/* eslint-disable no-irregular-whitespace */
import React, {
  useCallback, useMemo,
} from 'react';
import './project-info-window.pc.scss';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { IconButton } from '../../../button/icon-button/icon-button';
import { Button } from '../../../button/button';
import { ProjectListType } from '../../../../../type/project/project.type';
import { RoutingPath } from '../../../../../routes/routing-pass';
import { MapActions } from '../../../../../redux/map/map.action';
import { joinStr } from '../../../../../utilities/join-str';
import { RectLabel } from '../../../label/rect-label/rect-label';
import projectUncontracted from '../../../../../asset/images/icon_project_uncontracted.svg';
import projectConstruction from '../../../../../asset/images/icon_project_construction.svg';
import projectEtc from '../../../../../asset/images/icon_project_etc.svg';
import projectOb from '../../../../../asset/images/icon_project_ob.svg';
import { ShowTypeLabel } from '../../../label/show-type/show-type-label';
import { StreetViewImg } from '../../../street-view-img/street-view-img';
import { DialogActions } from '../../../../../redux/dialog/dialog.action';
import { RouteDialog } from '../../../../dialogs/route-dialog/route-dialog';
import { useAppSelector } from '../../../../../hooks/use-redux';
import { useProjectView } from '../../../../../hooks/use-authority';
import { Config } from '../../../../../config/config';

type ProjectInfoWindowProps = {
  project: ProjectListType;
  callbackRegist?: (v: ProjectListType) => void;
  callbackClose: () => void;
  index: number;
  label?: string;
};

export const ProjectInfoWindowPC = (props: ProjectInfoWindowProps) => {
  const {
    project, callbackRegist, callbackClose, index, label,
  } = props;

  const dispatch = useDispatch();
  const projectRankList = useAppSelector((v) => v.master.projectRankList);
  const routeAuth = useAppSelector((v) => v.user.company_authority3);
  const streetViewAuth = useProjectView();

  const handleClickDetail = useCallback((
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    isDouble?: boolean,
  ) => {
    const url = `${RoutingPath.projectDetail}/${project.id}/project`;

    if (e.ctrlKey) {
      if (isDouble) {
        window.open(`${Config.host}/#${url}`);
      }
    } else if (Config.isMac && e.altKey) {
      window.open(`${Config.host}/#${url}`);
    } else {
      dispatch(push(url));
    }
  }, [project.id]);

  const handleClickHere = useCallback(() => {
    if (!routeAuth) return;
    dispatch(MapActions.setGpsStatus('out'));
    dispatch(DialogActions.push({
      title: 'ルート設定',
      element: <RouteDialog
        type="project"
        destination={project.field_place}
        callback={() => { }}
      />,
    }));
  }, [project, routeAuth]);

  const statuses = useMemo(() => {
    let status = '';
    let img = '';
    let pClassName = '';
    switch (project.construction_flag) {
      case 3:
        status = '完工';
        img = projectOb;
        pClassName = 'project_ob';
        break;
      case 2:
        status = '工事中';
        img = projectConstruction;
        pClassName = 'project_construction';
        break;
      case 1:
        status = '未契約';
        img = projectUncontracted;
        pClassName = 'project_uncontracted';
        break;
      default:
        status = 'その他';
        img = projectEtc;
        pClassName = 'project_etc';
        break;
    }
    return ({ status, img, pClassName });
  }, [project.construction_flag]);

  const handleClickSupportHistory = useCallback(() => {
    if (callbackRegist) {
      callbackRegist(project);
    }
  }, [callbackRegist]);

  const rankData = useMemo(() => (
    projectRankList.find((v) => Number(v.id) === Number(project.project_rank))
  ), [projectRankList, project.project_rank]);

  return (
    <div className="card">
      <div className="name">{project.name || '---'}</div>
      <div className="card_base_row">
        <div className="col">
          <div className="sub_name">
            <i className="fas fa-user" title="顧客名" />
            <Link to={`${RoutingPath.customerDetail}/${project.customer_id}`}>
              {project.customer_name || '---'}{/* （{project.furigana || '---'}）*/}
              <i className="fas fa-link ml_10" title={project.customer_name || '---'} />
            </Link>
          </div>
          <div className="address_box">
            <i className="fas fa-map-marker-alt" title="住所" />
            <div>
              <div className="post_no">
                〒{joinStr(project.field_post_no, 3, '-') || '---'}
                <IconButton
                  title="ルートを表示する"
                  fontAwesomeClass="fas fa-route"
                  className="secondary"
                  onClick={handleClickHere}
                  disabled={!routeAuth}
                />
              </div>
              <div className="address">{project.field_place || '---'}</div>
            </div>
          </div>
          <div className="tell"><i className="fas fa-phone" title="TEL" />{project.field_tel_no || '---'}</div>
          <div className="sales_contact"><i className="fas fa-user-circle" title="案件担当者" />{project.project_employee_name || '---'}</div>
        </div>
        <div className="label_box">
          <ShowTypeLabel
            label={statuses.status}
            showTypeImg={statuses.img}
            className={statuses.pClassName}
          />

          <RectLabel
            label={rankData?.abbreviation || 'ランクなし'}/* ★案件見込みランク略称abbreviation */
            bgColor={rankData?.background_color}
            color={rankData?.text_color}
          />
          <div className="google">
            <StreetViewImg
              isShow={streetViewAuth(index)}
              lat={project.lat}
              lng={project.lng}
            />
          </div>
        </div>
      </div>
      <div className="btn_box">
        {!callbackRegist
          ? (
            <Button
              className="sm primary"
              onClick={(e) => handleClickDetail(e)}
              onDoubleClick={(e) => handleClickDetail(e, true)}
            >
              案件詳細
            </Button>
          )
          : <Button className="sm primary" onClick={handleClickSupportHistory}>{label || '対応登録'}</Button>}
      </div>
      <IconButton
        title="閉じる"
        fontAwesomeClass="fas fa-times"
        className="default close"
        onClick={callbackClose}
      />
    </div>
  );
};
