import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { Input } from '../../ui/input/input';
import { Button } from '../../ui/button/button';
import { MathHelper } from '../../../utilities/math-helper';
import { MasterActions } from '../../../redux/master/master.action';
import { BillCollection } from '../../../collection/bill/bill.collection';
import { DateFormatter } from '../../../utilities/date-formatter';
import { TextArea } from '../../ui/text-area/text-area';
import { ValidationLengthUnder500 } from '../../../model/validation';
import { DialogActions } from '../../../redux/dialog/dialog.action';
import { useDidMount } from '../../../hooks/life-cycle';
import { EstimateListType } from '../../../type/estimate/estimate.type';
import { BillValidation } from '../../../model/validation/bill/biil.validation';
import { BillListType, Bill } from '../../../type/bill/bill.type';
import { BillActions } from '../../../redux/bill/bill.action';
import { useAppSelector } from '../../../hooks/use-redux';
import { DatePicker } from '../../ui/date-picker/date-picker';
import { Required } from '../../ui/required/required';
import { ValidationDatePicker } from '../../../model/validation/validation-date-picker';
import { Select } from '../../ui/select/select';
import { InputNum } from '../../ui/input/input-num';
import { Message } from '../../../collection/message.collection';
import { MasterTax } from '../../../type/master/master-tax.type';
import { TaxMaster } from '../../pc/pages/master/master.type';
import { MasterCollection } from '../../pc/pages/master/master.collection';

type Props = {
  mode: 'add' | 'edit';
  data?: BillListType;
  estimate?: EstimateListType;
  id?: number;
  callback: () => void;
}

export const BillEdit = (props: Props) => {
  // eslint-disable-next-line
  const { estimate, callback, data, mode, id } = props;

  /* Hooks */
  const dispatch = useDispatch();
  const project = useAppSelector((state) => state.project.project);
  const tax = useAppSelector((state) => state.master.soonTax);

  /* State */
  // eslint-disable-next-line
  const [projectId, setProjectId] = useState(NaN);
  const [fieldName, setFieldName] = useState('');
  const [projectName, setProjectName] = useState('');
  const [price, setPrice] = useState(0);
  const [payment, setPayment] = useState(0);
  const [paymentMethod, setPaymentMethod] = useState('');
  const [priceTaxIn, setPriceTaxIn] = useState(0);
  const [remarks, setRemarks] = useState('');
  const [billDate, setBillDate] = useState<Date | null>(null);
  const [paymentDate, setPaymentDate] = useState<Date | null>(null);
  const [itemId, setItemId] = useState(NaN);

  const [touch, setTouch] = useState(false);

  /* Memo */
  const item = useMemo(() => (itemId
    ? BillCollection.itemList.find((v) => v.value === itemId)?.text : ''), [itemId]);

  // const taxAdjust = useMemo(() => {

  // })

  /* Callback */
  const getList = useCallback(() => {
    if (project?.id) {
      dispatch(BillActions.api.bill.getList({
        param: { project_id: project.id },
      }));
    }
  }, [project]);

  const onBlurPrice = useCallback(() => {
    if (!tax) return;
    const _payment = MathHelper.rounding(MathHelper.times(price, tax.tax_rate), 0, 'floor');
    const _priceTaxIn = MathHelper.plus(price, _payment);
    setPriceTaxIn(_priceTaxIn);
    setPayment(_payment);
  }, [tax, price]);

  const onBlurPriceTaxIn = useCallback((resTax?: MasterTax, didPrice?: number) => {
    if (!tax && !resTax) return;
    const _tax = (tax ?? resTax)!;
    // const taxIn = Number.isNaN(priceTaxIn) ? didPrice ?? 0 : priceTaxIn;
    const taxIn = priceTaxIn || (didPrice ?? 0);
    const _price = MathHelper.rounding(MathHelper.div(
      taxIn, MathHelper.plus(_tax.tax_rate, 1),
    ),
    0,
    'ceil');
    const _payment = MathHelper.minus(taxIn, _price);
    setPrice(_price);
    setPayment(_payment);
  }, [tax, priceTaxIn]);

  const post = useCallback(() => {
    const billing_date = DateFormatter.date2str(billDate, 'YYYYMMDD', '-');
    const payment_due_date = DateFormatter.date2str(paymentDate, 'YYYYMMDD', '-');
    if (BillValidation({
      fieldName,
      projectName,
      price,
      payment,
      priceTaxIn,
      remarks,
      billing_date,
      payment_due_date,
      billing_items_id: itemId,
      payment_method: paymentMethod,
    })) {
      dispatch(DialogActions.pushMessage({
        title: `顧客情報${id ? '更新' : '登録'}`,
        message: Message.postError,
        callback: () => {
          setTouch(true);
        },
      }));
      return;
    }
    dispatch(BillActions.api.bill.post({
      param: {
        data: {
          project_id: project ? project.id : NaN,
          customer_id: project ? project.customer_id : NaN,
          billing_items_id: itemId,
          billing_items: item || '',
          billing_date,
          billing_amount: priceTaxIn,
          payment_due_date,
          payment_method: paymentMethod,
          remarks,
        },
        id,
      },
      onSuccess: () => {
        getList();
      },
    }));

    dispatch(DialogActions.pop());
  }, [
    projectId,
    estimate,
    fieldName,
    projectName,
    price,
    payment,
    priceTaxIn,
    remarks,
    billDate,
    paymentDate,
    itemId,
    project,
    item,
    paymentMethod,
    remarks,
  ]);

  const getTaxSoon = useCallback((
    e: Date | null,
    dateStr?: string,
    taxCallback?: (res: MasterTax, didPrice?: number) => void,
    didPrice?: number,
    noSetDate? : boolean,
  ) => {
    if (!noSetDate) {
      setBillDate(e || DateFormatter.str2date(dateStr || ''));
    }
    const date = dateStr || DateFormatter.date2str(e, 'YYYYMMDD', '-');
    if (!date) {
      dispatch(MasterActions.setTaxSoon(MasterCollection.initTax));
      if (taxCallback) {
        taxCallback(MasterCollection.initTax, didPrice);
      }
      return;
    }
    dispatch(MasterActions.api.tax.getSoon({
      param: {
        start_date: date,
      },
      onSuccess: (res) => {
        if (taxCallback) {
          taxCallback(res, didPrice);
        }
      },
    }));
  }, [price, tax, priceTaxIn, onBlurPrice, onBlurPriceTaxIn]);

  /* Effect */
  useDidMount(() => {
    if (data) {
      dispatch(BillActions.api.bill.get({
        param: { id: data?.id },
        callback: (res) => {
          setBillDate(DateFormatter.str2date(res.billing_date));
          setPaymentDate(DateFormatter.str2date(res.payment_due_date));
          setPriceTaxIn(Number(res.billing_amount));
          setItemId(res.billing_items_id);
          setPaymentMethod(res.payment_method || '');
          setPaymentMethod(res.payment_method);
          setRemarks(res.remarks);
        },
      }));
      getTaxSoon(
        null,
        data ? data.billing_date : undefined,
        onBlurPriceTaxIn,
        data ? data.billing_amount : undefined,
      );
    } else {
      getTaxSoon(null, undefined, undefined, undefined, true);
    }
  });

  // useEffect(() => {
  //   if (!tax) return;
  //   setPriceTaxIn(price + price * tax.tax_rate);
  //   setPayment(price * tax.tax_rate);
  // }, [tax]);

  return (
    <div className="editPc_wrap">
      <div className="editPc_body show_all">
        <div className="editPC_body_inner FamilyEditDialog">
          <div className="item_wrap">
            <div className="item_box">
              <div className="item_head">現場名称</div>
              <Input
                className=""
                value={project?.field_name}
                disabled
                onChange={(e) => setFieldName(e.target.value)}
              />
            </div>
            <div className="item_box">
              <div className="item_head">案件名</div>
              <Input
                className=""
                value={project?.name}
                disabled
                onChange={(e) => setProjectName(e.target.value)}
              />
            </div>
          </div>
          {/* ★19日以降 要対応★ */}
          <div className="item_wrap">
            <div className="item_box">
              <div
                className="item_head"
              >請求日<Required />
              </div>
              <DatePicker
                date={billDate}
                onChange={(v) => {
                  setBillDate(v);
                  getTaxSoon(v);
                }}
                validationList={ValidationDatePicker}
                require
              />
            </div>
            <div className="item_box">
              <div className="item_head">請求項目<Required /></div>
              <Select
                value={itemId}
                defaultLabel=" "
                onChange={(v) => setItemId(Number(v))}
                options={BillCollection.itemList}
                require
              />
            </div>
          </div>
          <div className="item_wrap">
            <div className="item_box">
              <div className="item_head">入金予定日<Required /></div>
              <DatePicker
                date={paymentDate}
                onChange={setPaymentDate}
                validationList={ValidationDatePicker}
                require
              />
            </div>
            <div className="item_box">
              <div className="item_head">入金方法</div>
              <Input
                className=""
                value={paymentMethod}
                onChange={(e) => setPaymentMethod(e.target.value)}
                validationList={ValidationLengthUnder500}
              />
            </div>
          </div>

          <div className="item_wrap">
            <div className="item_box">
              <div className="item_head">金額</div>
              <InputNum
                value={MathHelper.localStr(price)}
                onChange={(v) => setPrice(MathHelper.localStrToNum(v))}
                decimalLen={2}
                onBlur={onBlurPrice}
              />
            </div>
            <div className="item_box">
              <div className="item_head">消費税額</div>
              <InputNum
                value={MathHelper.localStr(payment)}
                onChange={() => {}}
                decimalLen={2}
                disabled
              />
            </div>
            <div className="item_box">
              <div className="item_head">税込み金額</div>
              <InputNum
                value={MathHelper.localStr(priceTaxIn)}
                onChange={(v) => setPriceTaxIn(MathHelper.localStrToNum(v))}
                onBlur={() => onBlurPriceTaxIn()}
              />
            </div>
          </div>
          <div className="item_wrap">
            <div className="item_box large">
              <div className="item_head">備考</div>
              <TextArea
                rows={4}
                className="large"
                value={remarks}
                touch={touch}
                onChange={(e) => setRemarks(e.target.value)}
                validationList={ValidationLengthUnder500}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="editPc_footer base_footer">
        <Button
          size="md"
          color="primary"
          onClick={post}
        >
          {id ? '更新' : '登録'}
        </Button>
        <Button
          size="md"
          color="dark"
          onClick={() => dispatch(DialogActions.pop())}
        > 閉じる
        </Button>
      </div>
    </div>
  );
};
