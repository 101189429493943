import lodash, { isEqual } from 'lodash';
import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';
import { State } from '../../../../../redux/root.reducer';
import { TopLabelInputField } from '../../../../ui/input-field/top-label-input-field/top-label-input-field';
import { SearchBox } from '../../../layout/search-box/search-box.sp';
import { prefectures } from '../../../../../collection/prefectures';
import { ProjectSortState } from '../../../../../type/project/project.type';
import { ProjectActions } from '../../../../../redux/project/project.action';
import { TagActions } from '../../../../../redux/tag/tag.action';
import { ProjectCollection } from '../../../../../collection/project/project.collection';
import { TagModel } from '../../../../../model/tag/tag';
import { Select } from '../../../../ui/select/select';
import { MasterActions } from '../../../../../redux/master/master.action';
import { CommonCollection } from '../../../../../collection/common/common.collection';
import { MapActions } from '../../../../../redux/map/map.action';
import { Customer, CustomerListType } from '../../../../../type/customer/customer.type';
import { useDidMount } from '../../../../../hooks/life-cycle';
import { pulldown } from '../../../../../utilities/pulldown';
import { LabelInput } from '../../../../ui/input/label-input';
import { useAppSelector } from '../../../../../hooks/use-redux';
import { TagCheckBoxex } from '../../../../ui/tag-check-boxex/tag-check-boxex';
import { InputNum } from '../../../../ui/input/input-num';
import { DatePicker } from '../../../../ui/date-picker/date-picker';
import { useDateValidation } from '../../../../../hooks/use-date-validation';
import { DateTermValidation } from '../../../../../model/validation/date/term-date.validation';

type Props = {
  type?: 'estimate' | 'order' | 'maintenance' | 'support' | 'file';
  customerData?: Customer | CustomerListType,
  getList?: (data: ProjectSortState) => void,
  isSearch?: boolean;
  initialSort?: ProjectSortState;
  noAuthority?: boolean;
}

export const ProjectSearchBoxSP = (props: Props) => {
  const {
    type, getList, customerData, isSearch, initialSort, noAuthority,
  } = props;
  /* Hooks */
  const user = useAppSelector((v) => v.user);
  const sortState = useSelector((state: State) => (state.project.sort), isEqual);
  const partList = useSelector((state: State) => (state.tag.partList), isEqual);
  const {
    projectRankList,
    storeList,
    employeeList,
    originList,
  } = useSelector((state: State) => (state.master), isEqual);
  const dispatch = useDispatch();

  /* State */
  const [sort, setSort] = useState<ProjectSortState>(
    cloneDeep(initialSort) || cloneDeep(sortState),
  );

  const _employeeList = useMemo(() => employeeList.filter((
    v,
  ) => (Number.isNaN(sort?.project_store_id) || sort.project_store_id === null
    ? true
    : (v.store_id === sort?.project_store_id))),
  [sort?.project_store_id, employeeList]);

  const termDate = useDateValidation([
    { start: sort.contract_date_from, end: sort.contract_date_to },
    { start: sort.completion_date_from, end: sort.completion_date_to },
    { start: sort.complete_date_from, end: sort.complete_date_to },
    { start: sort.failure_date_from, end: sort.failure_date_to },
    { start: sort.cancel_date_from, end: sort.cancel_date_to },
    { start: sort.created_at_start, end: sort.created_at_end },
    { start: sort.updated_at_start, end: sort.updated_at_end },
  ]);

  /* Callback */
  const setState = useCallback((v: Partial<ProjectSortState>) => {
    const data = {
      ...cloneDeep(sort),
      ...cloneDeep(v),
    };
    setSort(data);
  }, [sortState, isSearch, sort]);

  const handleClickSearch = useCallback(() => {
    if (termDate.messages.length) {
      termDate.check();
      return;
    }
    dispatch(MapActions.setGpsStatus('out'));
    getList?.(sort);
    if (!isSearch) {
      dispatch(ProjectActions.setSort(cloneDeep(sort)));
    }
  }, [sort, isSearch, sortState, getList]);

  useDidMount(() => {
    dispatch(TagActions.api.part.getList());
    dispatch(MasterActions.api.projectRank.getList({}));
    dispatch(MasterActions.api.employee.getList({}));
    dispatch(MasterActions.api.store.getList({}));
    dispatch(MasterActions.api.origin.getList({}));
  });

  useEffect(() => {
    let status: number[] = [];
    if (type === 'estimate' || type === 'order') status = [10, 3];
    if (type === 'maintenance') status = [3, 4, 6];
    setState({
      construction_parts: sortState?.construction_parts?.data.length
        ? sortState?.construction_parts : new TagModel(partList),
      construction_status: sortState.construction_status
      || new TagModel(
        type
          ? ProjectCollection.projectStatusList
          : ProjectCollection.constructionStatusList,
        type
          ? status
          : [],
      ),

    });
  }, [partList, type]);

  return (
    <SearchBox
      callback={handleClickSearch}
      callbackClear={() => setState({
        ...ProjectCollection._initialSortState(user),
        construction_parts: new TagModel(partList),
        construction_status: new TagModel(
          type
            ? ProjectCollection.projectStatusList
            : ProjectCollection.constructionStatusList,
        ),
      })}
    >
      {/* search_box_body_inner は各画面の共通用 */}
      <div className="search_box_sp_body_inner project_search_box_sp">
        <div className="category_wrap">
          <div className="item_wrap">
            <div className="item_label">店舗</div>
            <div className="item_body full_width">
              <Select
                value={noAuthority ? user.store_id : sort.project_store_id}
                onChange={(v) => setState({
                  project_store_id: Number(v),
                  project_employee_id: Number.isNaN(Number(v))
                  || Number(v) === user.store_id
                    ? user.id
                    : NaN,
                })}
                defaultLabel="全て"
                options={pulldown(storeList)}
                disabled={noAuthority}
              />
            </div>
          </div>
          <div className="item_wrap">
            <div className="item_label">担当者</div>
            <div className="item_body full_width">
              <Select
                value={noAuthority ? user.id : sort.project_employee_id}
                onChange={(v) => setState({ project_employee_id: Number(v) })}
                defaultLabel="全て"
                options={pulldown(_employeeList)}
                disabled={noAuthority}
              />
            </div>
          </div>
        </div>

        <div className="category_wrap">
          <div className="item_wrap">
            <TopLabelInputField
              label="顧客ID"
              value={customerData ? customerData.internal_id : sort?.customer_id}
              type="number"
              onChange={(e) => { setState({ customer_id: Number(e.target.value) }); }}
              className="full_width"
              disabled={!!customerData}
            />
          </div>
          <div className="item_wrap">
            <TopLabelInputField
              label="顧客名"
              value={customerData ? customerData.name : sort?.customer_name}
              onChange={(e) => setState({ customer_name: e.target.value })}
              disabled={!!customerData}
              className="full_width"
            />
          </div>
          <div className="item_wrap">
            <TopLabelInputField
              label="案件名"
              value={sort?.name}
              onChange={(e) => setState({ name: e.target.value })}
              className="full_width"
            />
          </div>
          <div className="item_wrap">
            <TopLabelInputField
              label="案件現場名称"
              value={sort?.field_name}
              onChange={(e) => setState({ field_name: e.target.value })}
              className="full_width"
            />
          </div>
          <div className="item_wrap">
            <LabelInput
              pos="Top"
              value={sort.field_tel_no}
              className="full_width"
              label="案件現場電話番号"
              type="tel"
              onChange={(v) => setState({ field_tel_no: v })}
            />
          </div>
          <div className="item_wrap">
            <div className="item_label">案件現場都道府県</div>
            <div className="item_body item_select full_width">
              <Select
                value={sort?.field_prefectures}
                onChange={(v) => setState({ field_prefectures: Number(v) })}
                defaultLabel="全て"
                options={prefectures.map((v) => ({ text: v.label, value: v.value }))}
              />
            </div>
          </div>
          <div className="item_wrap">
            <TopLabelInputField
              label="案件現場住所"
              value={sort?.field_address}
              onChange={(e) => setState({ field_address: e.target.value })}
              className="full_width"
            />
          </div>
        </div>

        <div className="category_wrap">
          <div className="item_wrap">
            <div className="item_label">案件見込みランク</div>
            <div className="item_body item_projectRank">
              <Select
                value={sort?.project_rank}
                onChange={(v) => setState({ project_rank: Number(v) })}
                defaultLabel="全て"
                options={pulldown(projectRankList)}
              />
              <Select
                value={sort?.project_rank_filter}
                onChange={(v) => setState({ project_rank_filter: Number(v) })}
                options={CommonCollection.pullDownFilterList}
              />
            </div>
          </div>
          <div className="item_wrap">
            <div className="item_label">発生源</div>
            <div className="item_body">
              <Select
                defaultLabel="全て"
                value={sort?.source_id}
                onChange={(v) => { setState({ source_id: Number(v) }); }}
                options={pulldown(originList)}
              />
            </div>
          </div>
        </div>
        <div className="category_wrap">
          <div className="item_wrap">
            <div className="item_label">見込み金額</div>
            <LabelInput pos="Right" label="円">
              <InputNum
                value={sort.mitumori_kin_min}
                onChange={(v) => { setState({ mitumori_kin_min: v }); }}
                onBlur={(v) => setState({
                  mitumori_kin_min: v ? Number(v).toLocaleString() : '',
                })}
                minus
              />
            </LabelInput>
            <div className="flex_box flex_align_center mt_10">
              <label className="ml_5 mr_5">〜</label>
              <LabelInput pos="Right" label="円">
                <InputNum
                  value={sort.mitumori_kin_max}
                  onChange={(v) => { setState({ mitumori_kin_max: v }); }}
                  onBlur={(v) => setState({
                    mitumori_kin_max: v ? Number(v).toLocaleString() : '',
                  })}
                  minus
                />
              </LabelInput>
            </div>
          </div>
          <div className="item_wrap">
            <div className="item_label">受注金額</div>
            <LabelInput pos="Right" label="円">
              <InputNum
                value={sort.order_price_min}
                onChange={(v) => { setState({ order_price_min: v }); }}
                onBlur={(v) => setState({
                  order_price_min: v ? Number(v).toLocaleString() : '',
                })}
                minus
              />
            </LabelInput>
            <div className="flex_box flex_align_center mt_10">
              <label className="ml_5 mr_5">〜</label>
              <LabelInput pos="Right" label="円">
                <InputNum
                  value={sort.order_price_max}
                  onChange={(v) => { setState({ order_price_max: v }); }}
                  onBlur={(v) => setState({
                    order_price_max: v ? Number(v).toLocaleString() : '',
                  })}
                  minus
                />
              </LabelInput>
            </div>
          </div>
        </div>
        <div className="category_wrap">
          <div className="item_wrap tags_form">
            <TagCheckBoxex
              head="工事部位"
              keys="cTag"
              model={sort?.construction_parts}
              onChange={(v) => setState({ construction_parts: v })}
            />
          </div>
          <div className="item_wrap tags_form">
            <TagCheckBoxex
              head={type ? '案件状態' : '工事状況'}
              keys="csTag"
              disabled={type === 'estimate' || type === 'order'}
              model={sort?.construction_status}
              onChange={(v) => setState({ construction_status: v })}
            />
          </div>
        </div>
        <div className="category_wrap">
          <div className="item_wrap">
            <div className="item_label">契約日</div>
            <div className="item_body item_schedule wrap">
              <div className="item_schedule__form">
                <DatePicker
                  date={sort.contract_date_from}
                  onChange={(v) => setState({ contract_date_from: v })}
                  term={DateTermValidation([{
                    start: sort.contract_date_from, end: sort.contract_date_to,
                  }])}
                />
              </div>
              <div className="item_schedule__form flex_box flex_align_center mt_10">
                <div className="item_schedule__tilde">〜</div>
                <DatePicker
                  date={sort.contract_date_to}
                  onChange={(v) => setState({ contract_date_to: v })}
                  term={DateTermValidation([{
                    start: sort.contract_date_from, end: sort.contract_date_to,
                  }])}
                />
              </div>
            </div>
          </div>
          <div className="item_wrap">
            <div className="item_label">完工日</div>
            <div className="item_body item_schedule wrap">
              <div className="item_schedule__form">
                <DatePicker
                  date={sort.completion_date_from}
                  onChange={(v) => setState({ completion_date_from: v })}
                  term={DateTermValidation([{
                    start: sort.completion_date_from, end: sort.completion_date_to,
                  }])}
                />
              </div>
              <div className="item_schedule__form flex_box flex_align_center mt_10">
                <div className="item_schedule__tilde">〜</div>
                <DatePicker
                  date={sort.completion_date_to}
                  onChange={(v) => setState({ completion_date_to: v })}
                  term={DateTermValidation([{
                    start: sort.completion_date_from, end: sort.completion_date_to,
                  }])}
                />
              </div>
            </div>
          </div>
          <div className="item_wrap">
            <div className="item_label">完了日</div>
            <div className="item_body item_schedule wrap">
              <div className="item_schedule__form">
                <DatePicker
                  date={sort.complete_date_from}
                  onChange={(v) => setState({ complete_date_from: v })}
                  term={DateTermValidation([{
                    start: sort.complete_date_from, end: sort.complete_date_to,
                  }])}
                />
              </div>
              <div className="item_schedule__form flex_box flex_align_center mt_10">
                <div className="item_schedule__tilde">〜</div>
                <DatePicker
                  date={sort.complete_date_to}
                  onChange={(v) => setState({ complete_date_to: v })}
                  term={DateTermValidation([{
                    start: sort.complete_date_from, end: sort.complete_date_to,
                  }])}
                />
              </div>
            </div>
          </div>
          <div className="item_wrap">
            <div className="item_label">失注日</div>
            <div className="item_body item_schedule wrap">
              <div className="item_schedule__form">
                <DatePicker
                  date={sort.failure_date_from}
                  onChange={(v) => setState({ failure_date_from: v })}
                  term={DateTermValidation([{
                    start: sort.failure_date_from, end: sort.failure_date_to,
                  }])}
                />
              </div>
              <div className="item_schedule__form flex_box flex_align_center mt_10">
                <div className="item_schedule__tilde">〜</div>
                <DatePicker
                  date={sort.failure_date_to}
                  onChange={(v) => setState({ failure_date_to: v })}
                  term={DateTermValidation([{
                    start: sort.failure_date_from, end: sort.failure_date_to,
                  }])}
                />
              </div>
            </div>
          </div>
          <div className="item_wrap">
            <div className="item_label">キャンセル日</div>
            <div className="item_body item_schedule wrap">
              <div className="item_schedule__form">
                <DatePicker
                  date={sort.cancel_date_from}
                  onChange={(v) => setState({ cancel_date_from: v })}
                  term={DateTermValidation([{
                    start: sort.cancel_date_from, end: sort.cancel_date_to,
                  }])}
                />
              </div>
              <div className="item_schedule__form flex_box flex_align_center mt_10">
                <div className="item_schedule__tilde">〜</div>
                <DatePicker
                  date={sort.cancel_date_to}
                  onChange={(v) => setState({ cancel_date_to: v })}
                  term={DateTermValidation([{
                    start: sort.cancel_date_from, end: sort.cancel_date_to,
                  }])}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="category_wrap">
          <div className="item_wrap">
            <div className="item_label">登録者</div>
            <div className="item_body item_madori">
              <Select
                defaultLabel="全て"
                className="fixed"
                value={sort.created_employee_id}
                onChange={(v) => setState({ created_employee_id: Number(v) })}
                options={pulldown(employeeList)}
              />

            </div>
          </div>
          <div className="item_wrap">
            <div className="item_label">登録日</div>
            <div className="item_body item_schedule wrap">
              <div className="item_schedule__form">
                <DatePicker
                  date={sort.created_at_start}
                  onChange={(v) => setState({ created_at_start: v })}
                  type="YYYY/MM/DD"
                  term={DateTermValidation([
                    { start: sort.created_at_start, end: sort.created_at_end },
                  ])}
                />
              </div>
              <div className="item_schedule__form flex_box flex_align_center mt_10">
                <div className="item_schedule__tilde">〜</div>
                <DatePicker
                  date={sort.created_at_end}
                  onChange={(v) => setState({ created_at_end: v })}
                  type="YYYY/MM/DD"
                  term={DateTermValidation([
                    { start: sort.created_at_start, end: sort.created_at_end },
                  ])}
                />
              </div>
            </div>
          </div>
          <div className="item_wrap">
            <div className="item_label">更新者</div>
            <div className="item_body item_madori">
              <Select
                defaultLabel="全て"
                className="fixed"
                value={sort.updated_employee_id}
                onChange={(v) => setState({ updated_employee_id: Number(v) })}
                options={pulldown(employeeList)}
              />
            </div>
          </div>
          <div className="item_wrap">
            <div className="item_label">更新日</div>
            <div className="item_body item_schedule wrap">
              <div className="item_schedule__form">
                <DatePicker
                  date={sort.updated_at_start}
                  onChange={(v) => setState({ updated_at_start: v })}
                  type="YYYY/MM/DD"
                  term={DateTermValidation([
                    { start: sort.updated_at_start, end: sort.updated_at_end },
                  ])}
                />
              </div>
              <div className="item_schedule__form flex_box flex_align_center mt_10">
                <div className="item_schedule__tilde">〜</div>
                <DatePicker
                  date={sort.updated_at_end}
                  onChange={(v) => setState({ updated_at_end: v })}
                  type="YYYY/MM/DD"
                  term={DateTermValidation([
                    { start: sort.updated_at_start, end: sort.updated_at_end },
                  ])}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </SearchBox>
  );
};
