import { put, takeEvery } from 'redux-saga/effects';
import { getListHandle } from '../root.saga';
import { ApiJisx0401GetList, ApiJisx0401GetListResponse } from './api/api-jisx0401';
import { ApiJisx0402GetList, ApiJisx0402GetListResponse } from './api/api-jisx0402';
import { ZipCodeActions } from './zip-code.action';
import { ApiZipCodeGetListResponse, ApiZipCodeGetList } from './api/api-zipcode';

function* tryJisx0401GetList(action: ReturnType<typeof ZipCodeActions.api.jisx0401.getList>) {
  yield getListHandle<ApiJisx0401GetListResponse>({
    api: new ApiJisx0401GetList(action.payload),
    noLoad: true,
    * onSuccess(result) {
      yield put(ZipCodeActions.setJisx0401List(result));
    },
  });
}

function* tryJisx0402GetList(action: ReturnType<typeof ZipCodeActions.api.jisx0402.getList>) {
  yield getListHandle<ApiJisx0402GetListResponse>({
    api: new ApiJisx0402GetList(action.payload),
    noLoad: true,
    * onSuccess(result) {
      yield put(ZipCodeActions.setJisx0402List(result));
    },
  });
}

function* tryZipCodeGetList(action: ReturnType<typeof ZipCodeActions.api.zipCode.getList>) {
  const { param, onSuccess } = action.payload;
  yield getListHandle<ApiZipCodeGetListResponse>({
    api: new ApiZipCodeGetList(param),
    noLoad: true,
    * onSuccess(result) {
      yield onSuccess(result);
      // yield put(ZipCodeActions.setZipCodeList(result));
    },
  });
}

export function* ZipCodeSaga() {
  yield takeEvery(ZipCodeActions.api.jisx0401.getList, tryJisx0401GetList);
  yield takeEvery(ZipCodeActions.api.jisx0402.getList, tryJisx0402GetList);
  yield takeEvery(ZipCodeActions.api.zipCode.getList, tryZipCodeGetList);
}
