import {
  useCallback, useEffect, useState, memo,
} from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { useDispatch } from 'react-redux';
import { EstimateEditState } from '../../../../../../type/estimate/estimate.type';
import { Button } from '../../../../../ui/button/button';
import { Input } from '../../../../../ui/input/input';
import { TopLabelInputField } from '../../../../../ui/input-field/top-label-input-field/top-label-input-field';
import { DatePicker } from '../../../../../ui/date-picker/date-picker';
import { Required } from '../../../../../ui/required/required';
import { ValidationDatePicker } from '../../../../../../model/validation/validation-date-picker';
import { EstimateInfoValidation } from '../../../../../../model/validation/estimate/estimate.validation';
import { DialogActions } from '../../../../../../redux/dialog/dialog.action';
import { constructionPeriodCalculation } from '../../../../../../utilities/construction-period-calculation';
import { getTermDay } from '../../../../../../utilities/get-term-day';
import { TextArea } from '../../../../../ui/text-area/text-area';
import { ValidationLengthUnder500 } from '../../../../../../model/validation';
import { Message } from '../../../../../../collection/message.collection';
import { LabelInput } from '../../../../../ui/input/label-input';
import { useDidMount } from '../../../../../../hooks/life-cycle';

type Props = {
  editState: EstimateEditState;
  genbaName: string;
  projectName: string;
  estimateCreator: string;
  callback: (v:EstimateEditState) => void;
}

/** 見積基本情報編集 SP */
export const EstimateInfoEditSP = (props: Props) => {
  const {
    editState: _editState,
    genbaName,
    projectName,
    estimateCreator,
    callback,
  } = props;

  const dispatch = useDispatch();

  /* State */
  const [editState, setEditState] = useState(cloneDeep(_editState));
  const [termDay, setTermDay] = useState<number | undefined>(undefined);
  const [touch, setTouch] = useState(false);

  /* Callback */
  const post = useCallback(() => {
    if (EstimateInfoValidation(editState)) {
      dispatch(DialogActions.pushMessage({
        title: '見積情報登録',
        message: Message.postError,
        callback: () => setTouch(true),
      }));
      return;
    }
    callback(cloneDeep(editState));
  }, [editState, callback]);

  /* 日間計算 */
  useEffect(() => {
    setTermDay(getTermDay(
      editState.estimate_kouki_start_dt,
      editState.estimate_kouki_end_dt,
    ));
  }, [
    editState.estimate_kouki_start_dt,
    editState.estimate_kouki_end_dt,
  ]);

  useDidMount(() => {
    console.log('did');
  });

  return (
    <div className="estimate_edit_sp__contents__up_side">
      <div className="category_wrap">
        <div className="item_wrap item_site">
          <div className="item_label">
            <span>現場名称</span>
          </div>
          <Input
            value={genbaName}
            disabled
          />
        </div>
        <div className="item_wrap">
          <TopLabelInputField
            className="full_width"
            label="案件名"
            value={projectName}
            disabled
          />
        </div>
        <div className="item_wrap">
          <TopLabelInputField
            label="見積作成者"
            value={estimateCreator}
            disabled
            className="full_width"
          />
        </div>
      </div>

      <div className="category_wrap">
        <div className="item_wrap item_date_picker">
          <div className="item_label">見積日付<Required /></div>
          <div className="item_body">
            <DatePicker
              date={editState.estimate_dt}
              onChange={(v) => setEditState({ ...editState, estimate_dt: v })}
              validationList={ValidationDatePicker}
              touch={touch}
              require
            />
          </div>
        </div>
        <div className="item_wrap item_date_picker item_construction_period">
          <div className="item_label" style={{ alignItems: 'center' }}>
            <div className="col1">工期<Required /></div>
            <div className="col2">
              <LabelInput pos="Right" label="日間">
                <Input
                  value={termDay}
                  onBlur={() => {
                    if (editState.estimate_kouki_start_dt) {
                      setEditState({
                        ...editState,
                        estimate_kouki_end_dt: constructionPeriodCalculation(
                          editState.estimate_kouki_start_dt,
                          editState.estimate_kouki_end_dt,
                          Number(termDay),
                        ),
                      });
                    } else {
                      setEditState({
                        ...editState,
                        estimate_kouki_start_dt: constructionPeriodCalculation(
                          editState.estimate_kouki_start_dt,
                          editState.estimate_kouki_end_dt,
                          Number(termDay),
                          true,
                        ),
                      });
                    }
                  }}
                  onChange={(e) => {
                    setTermDay(Number(e.target.value) || undefined);
                  }}
                  className="during"
                  maxLength={5}
                />
              </LabelInput>
            </div>
          </div>
          <div className="item_body item_schedule wrap">
            <div className="item_schedule__form">
              <DatePicker
                date={editState.estimate_kouki_start_dt}
                onChange={(v) => setEditState({ ...editState, estimate_kouki_start_dt: v })}
                validationList={ValidationDatePicker}
                touch={touch}
                require
              />
            </div>
            <div className="item_schedule__form flex_box flex_align_center mt_10">
              <div className="item_schedule__tilde">〜</div>
              <DatePicker
                date={editState.estimate_kouki_end_dt}
                onChange={(v) => setEditState({ ...editState, estimate_kouki_end_dt: v })}
                validationList={ValidationDatePicker}
                touch={touch}
                require
              />
            </div>
          </div>
        </div>
        <div className="item_wrap item_date_picker">
          <div className="item_label">見積有効期限<Required /></div>
          <div className="item_body">
            <DatePicker
              date={editState.yukokigen}
              onChange={(v) => setEditState({ ...editState, yukokigen: v })}
              validationList={ValidationDatePicker}
              touch={touch}
              require
            />
          </div>
        </div>
        <div className="item_wrap item_date_picker">
          <div className="item_label">受注予定日<Required /></div>
          <div className="item_body">
            <DatePicker
              date={editState.jyutyu_yotei_dt}
              onChange={(v) => setEditState({ ...editState, jyutyu_yotei_dt: v })}
              validationList={ValidationDatePicker}
              touch={touch}
              require
            />
          </div>
        </div>
      </div>

      <div className="estimate_edit_sp__secondary_contents">
        <div className="estimate_edit_sp__secondary_contents__remarks">
          <div>備考</div>
          <TextArea
            value={editState.remarks}
            rows={10}
            onChange={(e) => setEditState({ ...editState, remarks: e.target.value })}
            validationList={ValidationLengthUnder500}
          />
        </div>
      </div>

      <div className="base_dialog_content_inner_footer">
        <Button
          size="md"
          color="secondary"
          className="item_btn_right"
          onClick={post}
        >
          更新
        </Button>

        <Button
          size="md"
          color="dark"
          className="item_btn_right"
          onClick={() => {
            dispatch(DialogActions.pushCheck({
              callback: () => {
                dispatch(DialogActions.pop());
              },
            }));
          }}
        >
          戻る
        </Button>
      </div>
    </div>
  );
};
