import { cloneDeep, isEqual } from 'lodash';
import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useWillUnMount, useDidMount } from '../../../../../hooks/life-cycle';
import { dummy } from '../../../../../model/estimate/dummy';
import { EstimateMeisaiModel } from '../../../../../model/estimate/estimate-meisai.model';
import { MeisaiListXML, MeisaiXML } from '../../../../../model/estimate/estimate-meisai.type';
import { XmlParser } from '../../../../../parser/xml-parser';
import { MasterActions } from '../../../../../redux/master/master.action';
import { State } from '../../../../../redux/root.reducer';
import { pulldown } from '../../../../../utilities/pulldown';
import { tableSort } from '../../../../../utilities/table-sort';
import { EditPC } from '../../../../dialogs/edit/edit.pc';
import { LeftIconButton } from '../../../../ui/button/left-icon-button/left-icon-button';
import { LeftLabelCheckbox } from '../../../../ui/checkbox/left-label-checkbox/left-label-checkbox';
import { Input } from '../../../../ui/input/input';
import { Select } from '../../../../ui/select/select';
import { Table } from '../../../../ui/table/table';
import { EstimateSampleXML } from '../edit/demo-data';
import { SelectTreeData } from '../explore/estimate-explore.pc';
import { EstimateTree } from '../explore/tree/estimate-tree';
import { useAppSelector } from '../../../../../hooks/use-redux';
import { MasterMeisai } from '../../../../../type/master/master-meisai.type';
import { Resize } from '../../../../ui/resize/resize';

type Props = {
  callback: (v:MeisaiListXML[]) => void;
}

/* マスタから明細登録 */
export const AddMasterMeisai = (props:Props) => {
  const { callback } = props;

  /* Hooks */
  const dispatch = useDispatch();

  /* MasterList */
  const meisaiList = useAppSelector((v) => v.master.meisaiList);
  const daibunruiList = useAppSelector((v) => v.master.largeCategoryList);
  const tyubunruiList = useAppSelector((v) => v.master.middleCategoryList);

  /* State */
  const [model, setModel] = useState<EstimateMeisaiModel | null>(null);
  const [reset, setReset] = useState<number>(0);
  const [list, setList] = useState<MeisaiListXML[]>([]);
  const [selectTree, setSelectTree] = useState<SelectTreeData | null>(null);
  const [selected, setSelected] = useState<number[]>(cloneDeep([]));
  const [daibunrui, setDaibunrui] = useState(NaN);
  const [tyubunrui, setTyubunrui] = useState(NaN);
  const [meisai, setMeisai] = useState('');
  const [allCheck, setAllCheck] = useState(false);
  const [selectData, setSelectData] = useState<MeisaiListXML[]>([]);

  /* 登録 */
  const onClickRegist = useCallback(() => {
    callback(selectData);
  },
  [selectData]);

  /* リストクリック */
  const onClickRow = useCallback((v: MeisaiListXML) => {
    if (!model) return;
    if (!Object.keys(v).length) {
      setSelectData([]);
      setSelected([]);
      return;
    }
    setSelectData([cloneDeep(v)]);
    const findIndex = list.findIndex((v2) => v2.$.id === v.$.id);
    if (findIndex || findIndex === 0 || findIndex !== -1) {
      setSelected([findIndex]);
      return;
    }
    setSelectData([]);
    setSelected([]);
  }, [list]);

  /* マルチクリック */
  const onClickMulti = useCallback((v:MeisaiListXML[]) => {
    if (!model) return;
    setSelected(v.map((v2) => model?.list.findIndex((v3) => v3.$.id === v2.$.id)));
    const sList:MeisaiListXML[] = [];
    v.forEach((v2) => {
      const findData = model?.list.find((v3) => v2.$.id === v3.$.id);
      if (findData) {
        sList.push(findData);
      }
    });
    setSelectData(sList);
  }, [list]);

  const onChangeAllCheck = useCallback(() => {
    setAllCheck(!allCheck);
    if (allCheck) {
      setSelectData([]);
      setSelected([]);
    } else {
      setSelectData(cloneDeep(list));
      setSelected(list.map((_, i) => i));
    }
  }, [allCheck, list]);

  const onChangeDaiburui = useCallback((v: string | number) => {
    setDaibunrui(Number(v));
    if (daibunrui) {
      dispatch(MasterActions.api.middleCategory.getList({
        category_id: Number(v),
      }));
      setTyubunrui(NaN);
    } else {
      dispatch(MasterActions.setMiddleCategoryList([]));
    }
  }, [daibunrui]);

  const onTableSort = useCallback((order: 0 | 1, index: number) => {
    let key: keyof MeisaiListXML['$'] = 'name';

    switch (index) {
      case 0:
        key = 'name';
        break;
      case 1:
        key = 'kikaku';
        break;
      case 2:
        key = 'suryou';
        break;
      case 3:
        key = 'tani_id';
        break;
      case 4:
        key = 'kingaku';
        break;
      case 5:
        key = 'genka';
        break;
      case 6:
        key = 'daibunrui_id';
        break;
      case 7:
        key = 'tyubunrui_id';
        break;
      default:
        break;
    }

    const _list = list.map((v) => v.$);
    const resList = tableSort(
      _list,
      !order ? 'asc' : 'desc',
      key,
    );
    setList(resList.map((v) => ({ $: { ...v } })));
  },
  [list]);

  const onSearch = useCallback(() => {
    let searchList: MeisaiListXML[] = [];
    if (daibunrui) {
      searchList = list.filter((v) => v.$.daibunrui_id === String(daibunrui));
    }
    if (tyubunrui) {
      searchList = list.filter((v) => v.$.tyubunrui_id === String(tyubunrui));
    }
    if (meisai) {
      searchList = list.filter((v) => v.$.name.match(meisai));
    }
    setSelectTree(null);
    setSelectData([]);
    setSelected([]);
    setList(cloneDeep(searchList));
  }, [daibunrui, tyubunrui, meisai]);

  /* Effect */
  useDidMount(() => {
    dispatch(MasterActions.api.meisai.getList({}));
    dispatch(MasterActions.api.largeCategory.getList({}));
    dispatch(MasterActions.setMiddleCategoryList([]));
  });

  /* 明細取得モデル当てはめ */
  useEffect(() => {
    if (!meisaiList.length) return;
    const parser = new XmlParser();
    const build = parser.build<{ meisai: { mei: MeisaiListXML; }[]; }>(
      EstimateMeisaiModel.addMasterFormat(meisaiList),
    );
    parser.parse(build).then((res) => {
      setModel(new EstimateMeisaiModel(res));
    });
  }, [meisaiList]);

  useEffect(() => {
    if (!model) return;
    setList(model.list.filter((v) => {
      const { daibunrui_id, tyubunrui_id } = v.$;
      if (selectTree?.tyubunrui_id) {
        return daibunrui_id === selectTree.daibunrui_id && tyubunrui_id === selectTree.tyubunrui_id;
      }
      if (selectTree?.daibunrui_id) {
        return daibunrui_id === selectTree.daibunrui_id;
      }
      return true;
    }));
  }, [model?.list, selectTree]);

  useEffect(() => {
    setReset((v) => v + 1);
  }, [selectTree]);

  useWillUnMount(() => dispatch(MasterActions.setMiddleCategoryList([])));

  return (
    <EditPC
      mode="dialog"
      disabled={!selectData.length}
      callback={onClickRegist}
    >
      <div className="AddMasterMeisai">
        <section>
          <div className="item_wrap">
            <div className="item_box">
              <div className="item_head">大分類</div>
              <Select
                value={daibunrui}
                onChange={onChangeDaiburui}
                defaultLabel="全て"
                options={pulldown(daibunruiList)}
              />
            </div>
            <div className="item_box">
              <div className="item_head">中分類</div>
              <Select
                value={tyubunrui}
                label="中分類"
                onChange={(v) => setTyubunrui(Number(v))}
                defaultLabel="全て"
                options={pulldown(tyubunruiList)}
              />
            </div>
            <div className="item_box flex_grow_1">
              <div className="item_head">明細</div>
              <Input
                className="large"
                value={meisai}
                onChange={(e) => setMeisai(e.target.value)}
                onEnterKeyPress={onSearch}
              />
            </div>
            <LeftIconButton
              label="絞込み"
              fontAwesomeClass="fas fa-search"
              className="btn_search for_simple"
              size="sm"
              color="secondary"
              onClick={onSearch}
            />
          </div>
        </section>
        <section className="estimate_explore">
          <strong style={{ marginBottom: '5px' }}>明細情報</strong>
          <div className="estimate_box">
            <Resize enabled={{ right: true }}>
              <EstimateTree
                masterAdd
                meisaiModel={model}
                callback={setSelectTree}
                callbackModel={setModel}
              />
            </Resize>
            <div className="result_area list_area">
              <div className="inner">
                <div className="table_responsive">
                  <Table
                    className="table_selectable table_sortable table_sticky table_cell_change"
                    header={[
                      '工事・部材名称',
                      '規格',
                      '数量',
                      '単位',
                      '見積単価',
                      '原価単価',
                      '大分類',
                      '中分類',
                    ]}
                    sort={{ onClick: onTableSort }}
                    selectedTr={selected}
                    rowDataList={list}
                    onClickMulti={onClickMulti}
                    onClickRow={onClickRow}
                    unDispNoData
                    lists={list.map((v) => ([
                      v.$.name,
                      v.$.kikaku,
                      Number(v.$.suryou).toLocaleString(),
                      v.$.tani_name,
                      Number(v.$.shikiri_kakaku).toLocaleString(),
                      Number(v.$.genka).toLocaleString(),
                      v.$.daibunrui_name,
                      v.$.tyubunrui_name,
                    ]))}
                    headerReset={reset}
                    option={{
                      stringWidth: [
                        // { index: 2, width: 80 }, // 数量
                        // { index: 3, width: 50 }, // 単位
                        // { index: 4, width: 120 }, // 見積単価
                        // { index: 5, width: 120 }, // 原価単価
                      ],
                      tdAlign: [
                        { index: 0, align: 'left' },
                        { index: 1, align: 'left' },
                        { index: 2, align: 'right' },
                        { index: 3, align: 'left' },
                        { index: 4, align: 'right' },
                        { index: 5, align: 'right' },
                        { index: 6, align: 'left' },
                        { index: 7, align: 'left' },
                      ],
                    }}
                  />
                </div>
              </div>
              <div className="all_select item_wrap">
                <LeftLabelCheckbox
                  label="すべて選択"
                  checked={allCheck}
                  onChange={onChangeAllCheck}
                />
                <span className="comment">
                  ※Ctrl＋クリック：複数選択可能&emsp;Shift＋クリック：範囲指定選択可能
                </span>
              </div>
            </div>
          </div>
        </section>
      </div>
    </EditPC>
  );
};
