import { cloneDeep } from 'lodash';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { FileActions } from '../../../../../redux/file/file.action';
import { FileListType } from '../../../../../type/file/file.type';
import { Project, ProjectListType } from '../../../../../type/project/project.type';
import { FileSizeData } from '../../../../pc/layout/body/list/file-list/file-list.pc';
import { FileListSP } from '../../body/list/file/file-list';
import { useDidMount } from '../../../../../hooks/life-cycle';
import { FileDetailSP } from '../../../pages/file-detail/file-detail.sp';
import { Customer, CustomerListType } from '../../../../../type/customer/customer.type';
import { TableSort, Limit } from '../../../../ui/table/table-sort/table-sort';

  type PageParam = {
    offset: number;
    limit: number;
  }

  type FileGetListParam = {
    project?: ProjectListType | Project | null;
    sort?: PageParam;
}

type Props = {
    project?: ProjectListType | Project;
  }

export const OrderFileList = (props: Props) => {
  const { project } = props;
  const dispatch = useDispatch();

  const [fileData, setFileData] = useState<FileListType[] | null>(null);
  const [fileSize, setFileSize] = useState<FileSizeData>({ capacity: 0, total: 0 });
  const [selectFile, setSelectFile] = useState<FileListType | null>(null);
  const [hitCount, setHitCount] = useState(0);
  const [stopLoad, setStopLoad] = useState(false);
  const [pageParam, setPageParam] = useState<PageParam>({ offset: 0, limit: 100 });

  /* ファイル一覧GetList */
  const getList = useCallback((v: FileGetListParam) => {
    const { project: _project, sort } = v;
    const p = _project || project;
    const s = sort || pageParam;
    if (sort) setPageParam(cloneDeep(sort));
    if (!p) return;
    setStopLoad(true);
    setFileData(null);
    dispatch(FileActions.api.file.getList({
      param: {
        project_id: p?.id,
        customer_id: p?.customer_id,
        limit: s.limit,
        offset: s.offset,
        // order_file: true,
      },
      onSuccess: (data, file, hit) => {
        setStopLoad(false);
        setFileData(cloneDeep(data));
        setFileSize(cloneDeep(file));
        if (hit) setHitCount(hit);
      },
    }));
  }, [project, pageParam]);

  useDidMount(() => {
    getList({});
  });

  return (
    <>
      {!selectFile ? (
        <div className="order_file_list">
          <TableSort
            isSp
            page={pageParam.offset ?? 0}
            limit={pageParam.limit as Limit}
            hitCount={hitCount || 0}
            callback={(offset, limit) => {
              getList({ sort: { limit, offset } });
            }}
          />
          <FileListSP
            data={fileData}
            fileSize={fileSize}
            stopLoad={stopLoad}
            onClickCardInOrder={(v) => {
              setStopLoad(true);
              setSelectFile(cloneDeep(v));
            }}
          />
        </div>
      )
        : (
          <div>
            <FileDetailSP
              id={selectFile?.id}
              isOrder
              isDialog
              onBack={() => {
                setStopLoad(false);
                setSelectFile(null);
                getList({
                  sort: { offset: 0, limit: 100 },
                });
              }}
            />
          </div>
        )}
    </>
  );
};
