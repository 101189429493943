import { ApiBase } from '../../../service/api-base';
import { User } from '../../../type/auth/user.typs';

export type ApiUserResponse = User;

export class ApiUser extends ApiBase {
  constructor() {
    super({
      header: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      httpMethod: 'GET',
      url: '/api/auth/me',
    });
  }
}
