import actionCreatorFactory from 'typescript-fsa';
import { ApiBase } from '../../../../service/api-base';
import { CsvCustomerListType } from '../../../../type/csv/csv.type';

/* Param */
export type ApiCsvCustomerGetListParam = Partial<{
  /** 営業担当（店舗） */
  store_id: number;
  /** 営業担当（担当者） */
  employee_id: number;
  /** 顧客名 */
  name: string;
  /** 顧客TEL */
  tel_no: string;
  /** エリア */
  area_id: number;
  /** 顧客ランク */
  rank: string;
  /** 顧客ランクフィルタ */
  rank_filter: number;
  /** 顧客見込みランク */
  estimated_rank: number;
  /** 顧客見込みランクフィルタ */
  estimated_rank_filter: number;
  /** 顧客分類 */
  ob_flag: number;
  /** 工事状況 */
  construction_status: number[];
  /** 顧客名フリガナ */
  furigana: string;
  /** メールアドレス */
  mail_address: string;
  /** 郵便番号 */
  post_no: string;
  /** 都道府県 */
  prefecture: number;
  /** 顧客住所 */
  address: string;
  /** 建物分類 */
  building_category_id: number;
  /** 間取り */
  madori_id: number;
  /** 築年数－範囲開始 */
  building_age_start: number;
  /** 築年数－範囲終了 */
  building_age_end: number;
  /** 築年月－範囲開始 */
  construction_info_start: string;
  /** 築年月－範囲終了 */
  construction_info_end: string;
  /** 完工時期－範囲開始 */
  completion_date_start: string;
  /** 完工時期－範囲終了 */
  completion_date_end: string;
  /** 最終完工時期－範囲開始 */
  last_completion_date_start: string;
  /** 最終完工時期－範囲終了 */
  last_completion_date_end: string;
  /** 総工事金額（最小値） */
  total_work_price_min: string;
  /** 総工事金額（最大値） */
  total_work_price_max: string;
  /** 工事回数（最小値） */
  work_times_min: string;
  /** 工事回数（最大値） */
  work_times_max: string;
  /** 関連タグ */
  tags: number[];
  /** 部位 */
  parts: number[];
  /** 不備情報のみ */
  input_comp_flag: number | null;
  /** 備考 */
  remarks: string;
  /** 有効フラグ */
  valid_flag: number;
  /** オフセット */
  offset: number;
  /** 1ページ内の件数 */
  limit: number;
  /** 並替基準列 */
  sort_by: number;
  /** 並替方法 */
  highlow: number;
  /** 登録店舗 */
  created_store_id:number;
  /** 登録社員 */
  created_employee_id:number;
  /** 更新店舗 */
  updated_store_id:number;
  /** 更新社員 */
  updated_employee_id:number;
  /** 登録日－期間開始 */
  created_at_start:string;
  /** 登録日－期間終了 */
  created_at_end:string;
  /** 更新日－期間開始 */
  updated_at_start:string;
  /** 更新日－期間終了 */
  updated_at_end:string;
  /** 指定選択用(これを仕様する際は他のparamを送らない) */
  ids: number[];
}>;

export type ApiCsvCustomerDownloadParam = ApiCsvCustomerGetListParam;

/* Response */
export type ApiCsvCustomerGetListResponse = CsvCustomerListType;

export const ActionCreator = actionCreatorFactory('csv/api/customer');

export const apiCsvCustomer = {
  getList: ActionCreator<ApiCsvCustomerGetListParam>('get/list'),
  download: ActionCreator<ApiCsvCustomerDownloadParam>('download'),
};

export class ApiCsvCustomerGetList extends ApiBase<ApiCsvCustomerGetListResponse> {
  constructor(param: ApiCsvCustomerGetListParam) {
    super({
      httpMethod: 'GET',
      param,
      url: '/api/csv/customer',
    });
  }
}

export class ApiCsvCustomerDownload extends ApiBase {
  constructor(param: ApiCsvCustomerDownloadParam) {
    super({
      httpMethod: 'GET',
      contents: 'BLOB',
      param,
      url: '/api/csv/download/customer',
    });
  }
}
