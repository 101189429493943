import React, {
  useCallback, useEffect, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { Table } from '../../../../../ui/table/table';
import './csv-list-customer.scss';
import { State } from '../../../../../../redux/root.reducer';
import { RoutingPath } from '../../../../../../routes/routing-pass';
import { ExportCsvCollection } from '../../../../../../collection/export-csv/export-csv.collection';
import { CsvActions } from '../../../../../../redux/csv/csv.action';
import { ApiCsvCustomerGetListResponse } from '../../../../../../redux/csv/api/customer/api-csv-customer';
import { useAppSelector } from '../../../../../../hooks/use-redux';
import { useDidMount } from '../../../../../../hooks/life-cycle';
import { MasterActions } from '../../../../../../redux/master/master.action';
import { DateFormatter as DF } from '../../../../../../utilities/date-formatter';
import { MathHelper as MH } from '../../../../../../utilities/math-helper';
import { useOpenWindow } from '../../../../../../hooks/use-open-window';

type Props = {
  callbackSelected: (selected: number[]) => void;
}

export const CsvListCustomer = (props: Props) => {
  const { callbackSelected } = props;

  /* Hooks */
  const list = useSelector((state: State) => state.csv.customerList);
  const rankList = useAppSelector((v) => v.master.customerRankList);
  const dispatch = useDispatch();
  const windowOpen = useOpenWindow();

  /* State */
  const [selected, setSelected] = useState<number[]>([]);

  /* Callback */
  const handleClick = useCallback((row: ApiCsvCustomerGetListResponse) => {
    if (!list) return;
    setSelected([list.findIndex((v) => v.id === row.id)]);
  }, [list]);

  const handleClickHeader = useCallback(
    (highlow:0 | 1, sort_by: number) => {
      dispatch(CsvActions.setCustomerSort({ highlow, sort_by }));
    }, [],
  );

  const handleDbClick = useCallback((
    row: ApiCsvCustomerGetListResponse,
    e?: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
  ) => {
    if (!list) return;
    if (!e) return;
    windowOpen({
      url: `${RoutingPath.customerDetail}/${row.id}`,
      e,
    });
    setSelected([list.findIndex((v) => v.id === row.id)]);
  },
  [list]);

  useEffect(() => {
    callbackSelected(selected);
  }, [selected]);

  return (
    <section className="result_area list_area">
      <div className="inner">
        <div className="table_responsive">
          <Table
            className="table_selectable table_sortable table_sticky table_cell_change"
            header={ExportCsvCollection.customerInformationHeader}
            onClickRow={handleClick}
            onDbClick={(v, i, e) => handleDbClick(v, e)}
            sort={{ onClick: handleClickHeader }}
            onClickMulti={(v: ApiCsvCustomerGetListResponse[]) => {
              if (!list) return;
              setSelected(v.map((v2) => list.findIndex((v3) => v3.id === v2.id)));
            }}
            onClickAlt={(v, e) => {
              if (e && v) {
                windowOpen({
                  url: `${RoutingPath.customerDetail}/${v.id}`,
                  e,
                  callback: () => {},
                });
              }
            }}
            rowDataList={list || []}
            selectedTr={selected}
            option={ExportCsvCollection.getTableOption('customer')}
            lists={(list ? list.map((v) => {
              const rank = rankList.find((v2) => v2.id === v.rank);
              const rankName = rank ? rank.abbreviation + rank.abbreviation2 : '';
              return (
                [
                  v.internal_id,
                  v.name,
                  v.tel_no || '',
                  v.prefecture_name || '',
                  `${v.city || ''}${v.address || ''}${v.building_name || ''}`,
                  v.ob_flag === 1 ? <i className="far fa-circle" /> : '',
                  <div className="rank_label" style={{ backgroundColor: rank?.background_color, color: rank?.text_color }}>{rankName}</div>,
                  DF.date2str(v.last_completion_date, 'YYYYMMDD', '/'),
                  MH.localStr(v.koji_kin),
                  v.koji_count || '',
                  v.status_name || '',
                  v.employee_name || '',
                ]
              );
            }) : null)}
          />
        </div>
      </div>
    </section>
  );
};
