import {
  ReactElement, useEffect, useRef,
} from 'react';
import { noPinch } from '../../../utilities/no-pinch';
import { HeaderSP, SearchBoxDialogProps } from '../layout/header/header.sp';
import './base.page.sp.scss';
import '../../ui/info-window/info-window.sp.scss';
import { Config } from '../../../config/config';

export type BaseProps = {
  searchBoxDialog?: SearchBoxDialogProps;
  className?: string;
  children?: ReactElement | ReactElement[];
  menuOpen?: boolean;
  searchCallback?: (val:string)=>void,
  searchValue?: string,
  onOpenDialog?: (v: boolean) => void;
};

export const BasePageSP = (props: BaseProps) => {
  const headerEle = useRef<HTMLElement>(null);
  const footerEle = useRef<HTMLElement>(null);
  const {
    searchBoxDialog, className, children, menuOpen, searchCallback, searchValue, onOpenDialog,
  } = props;

  /* effect */
  useEffect(() => {
    const target = headerEle.current;
    const pinchCallback = noPinch(target);
    return pinchCallback;
  }, [headerEle]);

  return (
    <div className={`base_page_sp ${className}`}>
      {/* - header - */}
      <HeaderSP
        headerRef={headerEle}
        searchBoxDialog={searchBoxDialog}
        menuOpen={menuOpen}
        searchCallback={searchCallback}
        searchValue={searchValue}
        onOpenDialog={onOpenDialog}
      />

      {/* - body - */}
      <div
        className="base_page_sp_body"
      >
        {children}
      </div>
      <footer className="base_page_sp_footer" ref={footerEle}>
        Copyright&nbsp;&copy;&nbsp;2021 SHIP Inc All Rights Reserved.
        <span className="version">v{Config.version}</span>
      </footer>
    </div>
  );
};
