import { ApiBase } from '../../../service/api-base';
import { User } from '../../../type/auth/user.typs';
import { Jisx0401ListType } from '../../../type/zip-code/zip-code.type';

export type ApiJisx0401GetListResponse = Jisx0401ListType;

export type ApiJisx0401GetListParam = Partial<{
  /** 都道府県 */
  pref: string;
  /** JISX0402 */
  code: string;
  /** オフセット */
  offset: number;
  /** 1ページ内の件数 */
  limit: number;
}>;

export class ApiJisx0401GetList extends ApiBase<ApiJisx0401GetListResponse> {
  constructor(param: ApiJisx0401GetListParam) {
    super({
      credentials: 'include',
      httpMethod: 'GET',
      url: '/api/zipcode/jisx0401',
      param,
    });
  }
}
