import { cloneDeep } from 'lodash';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from '../../../../../../ui/button/button';
import { EstimateDetailSearchSecondCard } from '../../../../../../ui/card/estimate/estimate-detail-search-second-card';
import { EstimateMeisaiModel } from '../../../../../../../model/estimate/estimate-meisai.model';
import { MeisaiListXML } from '../../../../../../../model/estimate/estimate-meisai.type';
import { DialogActions } from '../../../../../../../redux/dialog/dialog.action';

type Props = {
  model: EstimateMeisaiModel;
  callback: (v:MeisaiListXML[]) => void;
  callbackCancel: () => void;
}

export const AddMasterResultListSP = (props:Props) => {
  const { model, callback, callbackCancel } = props;

  /* Hook */
  const dispatch = useDispatch();

  /* State */
  const [selectList, setSelectList] = useState<MeisaiListXML[]>(model.list);
  const [isAllCheck, setIsAllCheck] = useState([true]);

  /* Callback */
  const handleClickRegistration = useCallback(() => {
    if (!selectList.length) {
      dispatch(DialogActions.pushMessage({
        title: '',
        message: ['該当する結果が見つかりません'],
      }));
      return;
    }
    callback(selectList);
  }, [callback, selectList]);

  const handleClickList = useCallback((val: MeisaiListXML) => {
    let previousList = cloneDeep(selectList);
    const isFind = previousList.find((v) => v.$.id === val.$.id);

    if (!isFind) {
      previousList.push(cloneDeep(val));
    } else {
      previousList = cloneDeep(previousList.filter((v) => v.$.id !== val.$.id));
    }

    setSelectList(cloneDeep(previousList));
  },
  [selectList]);

  const handleAllSelect = useCallback(() => {
    setIsAllCheck(cloneDeep([true]));
    setSelectList(cloneDeep(model.list));
  }, [model]);

  const handleResetSelect = useCallback(() => {
    setIsAllCheck(cloneDeep([false]));
    setSelectList([]);
  }, []);

  return (
    <>
      <div id="detail_estimate_list" className="list_base base_dialog_content_inner_body">
        {model.list.map((v, i) => (
          <div key={`card${i}`} className="list_base_card_wrap">
            <EstimateDetailSearchSecondCard
              data={v}
              callback={handleClickList}
              isAllCheck={isAllCheck}
            />
          </div>
        ))}
      </div>
      <div className="detail_estimate_list_footer">
        <div className="flex_box flex_content_center mt_10">
          <Button size="md" color="secondary" className="item_btn mr_10" onClick={handleAllSelect}>一括選択</Button>
          <Button size="md" color="dark" className="item_btn" onClick={handleResetSelect}>選択解除</Button>
        </div>
        <div className="base_dialog_content_inner_footer">
          <Button size="md" color="primary" className="item_btn" onClick={handleClickRegistration}>明細登録</Button>
          <Button
            size="md"
            color="dark"
            onClick={callbackCancel}
          >
            戻る
          </Button>
        </div>
      </div>
    </>
  );
};
