/* eslint-disable no-shadow */
/**
 *
 * ## WebFontLoader でできること
 * - 任意のタイミングでウェブフォントをダウンロードできる
 * - ウェブフォントのダウンロード完了・失敗等を検知できる
 * - 読み込んだウェブフォントはシステムフォントのように使用できる
 *   - DOM/SVG/Canvas どこでも使える
 *
 * ## 使い方
 *
 * ### 1. npm モジュールのインストール
 * - `$ npm i webfontloader`
 * - `$ npm i -D @types/webfontloader`
 *
 * ### 2. サーバへのフォントの設置
 * - CORS 検証等を行っていないので index.html と同じ場所推奨
 *
 * ### 3. フォント読み込み用 css の作成
 * 1. fonts.css (ファイル名は任意) を作成
 * 2. fonts.css 内に以下を記述する
 * 3. fonts.css をサーバへ設置 (index.html と同じ場所推奨)
 * 4. url('○○○') の中にはサーバに設置したフォントのURLを記載する (fonts.css からの相対パスも可能)
 * ```
 *   @font-face {font-family: 'A-OTF ゴシック MB101 Pr5';src: url('A-OTF-GothicMB101Pr5-Reg.otf');}
 *   @font-face {font-family: 'A-OTF A1 明朝 Std';src: url('A-OTF-A1MinchoStd-Bold.otf');}
 * ```
 *
 * ### 4. WebFontLoader でフォントを読み込み
 *
 */

import WebFont from 'webfontloader';

type Param = {
  /** font 情報が記載された css の URL */
  css: string,
};

export class WebFontLoader {
  private readonly css: string;

  constructor(param: Param) {
    this.css = param.css;
  }

  load(param: {
    familyName: string,
    onLoad?: () => void,
    onError?: () => void,
  }) {
    const defaultParam = {
      onLoad: () => {
      },
      onError: () => {
      },
    };
    const { familyName, onLoad, onError } = { ...defaultParam, ...param };
    WebFont.load({
      custom: {
        families: [
          familyName,
        ],
        urls: [
          this.css,
        ],
      },
      active: () => {
        onLoad();
      },
      fontloading: (familyName: string, fvd: string) => {
        // console.log('loading ... : ', familyName);
      },
      fontactive: (familyName: string, fvd: string) => {
        // console.log('active : ', familyName);
      },
      fontinactive: (familyName: string, fvd: string) => {
        onError();
      },
    });
  }

  loadAsync(param: { familyName: string }) {
    const { familyName } = param;
    return new Promise<void>((resolve, reject) => {
      this.load({
        familyName,
        onLoad: () => resolve(),
        onError: () => reject(),
      });
    });
  }
}
