import { reducerWithInitialState } from 'typescript-fsa-reducers';
import * as lodash from 'lodash';
import {
  FileListType, FileSortState, FileType, FileSizeType,
} from '../../type/file/file.type';
import { FileActions } from './file.action';
import { FileCollection } from '../../collection/file/file.collection';
import { concatList } from '../root.reducer';

export type FileState = {
  file: FileType | null;
  list: FileListType[] | null;
  sort: FileSortState;
  fileSize: FileSizeType;
  isDelay: boolean;
  stopLoad: boolean;
};

const initialState: FileState = {
  file: null,
  list: null,
  sort: FileCollection.initialSortState(),
  fileSize: { capacity: 0, total: 0 },
  isDelay: false,
  stopLoad: false,
};

export const FileReducer = reducerWithInitialState<FileState>(initialState)
  .case(FileActions.setFile, (state, payload) => ({
    ...state,
    file: lodash.cloneDeep(payload),
  }))
  .case(FileActions.setList, (state, payload) => ({
    ...state,
    list: lodash.cloneDeep(payload),
  }))
  .case(FileActions.setConcatList, (state, payload) => ({
    ...state,
    list: lodash.cloneDeep(concatList(state.list, payload)),
  }))
  .case(FileActions.setSort, (state, payload) => ({
    ...state,
    sort: payload ? lodash.cloneDeep({
      ...state.sort,
      ...payload,
    }) : FileCollection.initialSortState(),
  }))
  .case(FileActions.setFileSize, (state, payload) => ({
    ...state,
    fileSize: lodash.cloneDeep(payload),
  }))
  .case(FileActions.setIsDelay, (state, payload) => ({
    ...state,
    isDelay: payload,
  }))
  .case(FileActions.setStopLoad, (state, payload) => ({
    ...state,
    stopLoad: payload,
  }))
  .case(FileActions.resetState, () => initialState)
  .default((state) => state);
