import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'lodash';
import { DialogActions } from '../../../../../../../redux/dialog/dialog.action';
import { MasterEditDialogProps, MasterMeisaiEditDialogProps } from '../../../master.type';
import { Input } from '../../../../../../ui/input/input';
import { Required } from '../../../../../../ui/required/required';
import { Button } from '../../../../../../ui/button/button';
import './meisai-master-edit-dialog.scss';
import { useDidMount } from '../../../../../../../hooks/life-cycle';
import { MasterActions } from '../../../../../../../redux/master/master.action';
import { ValidationLengthUnder13, ValidationLengthUnder255 } from '../../../../../../../model/validation';
import { ValidationMax100Million } from '../../../../../../../model/validation/validation-max-100-million';
import { MasterMeisaiCategoryValidation } from '../../../../../../../model/validation/master/master-meisai-category.validation';
import { RightLabelCheckbox } from '../../../../../../ui/checkbox/right-label-checkbox/right-label-checkbox';
import { EditPC } from '../../../../../../dialogs/edit/edit.pc';
import { State } from '../../../../../../../redux/root.reducer';
import { Select } from '../../../../../../ui/select/select';
import { CommonCollection } from '../../../../../../../collection/common/common.collection';
import { Message } from '../../../../../../../collection/message.collection';
import { InputNum } from '../../../../../../ui/input/input-num';
import { MathHelper } from '../../../../../../../utilities/math-helper';

export const MeisaiMasterEditDialog = (props: MasterMeisaiEditDialogProps) => {
  const { id, callback } = props;

  const dispatch = useDispatch();
  const largeCategoryList = useSelector((state:State) => state.master.largeCategoryList, isEqual);
  const middleCategoryList = useSelector((state:State) => state.master.middleCategoryList, isEqual);
  const unitList = useSelector((state:State) => state.master.unitList, isEqual);
  const list = useSelector((state:State) => state.master.meisaiList);

  const [itemKubun, setItemKubun] = useState('');
  const [largeCategoryId, setLargeCategoryId] = useState(NaN);
  const [middleCategoryId, setMiddleCategoryId] = useState(NaN);
  const [name, setName] = useState('');
  const [standard, setStandard] = useState('');
  const [quantity, setQuantity] = useState('');
  const [creditId, setCreditId] = useState(NaN);
  const [quoteUnitPrice, setQuoteUnitPrice] = useState('');
  const [primeCost, setPrimeCost] = useState('');
  // const [image, setImage] = useState('');
  const [validFlag, setValidFlag] = useState(true);

  const [touch, setTouch] = useState(false);

  const kubunId = useMemo(() => CommonCollection.shohinKubunList.findIndex(
    (v) => v.text === itemKubun,
  ) + 1, [itemKubun]);

  const getKubunLabel = useCallback((v: number) => {
    const value = CommonCollection.shohinKubunList.find((v2) => v === Number(v2.value));
    setItemKubun(value ? value.text : '');
  }, [itemKubun]);

  const handleClickPost = useCallback(() => {
    if (MasterMeisaiCategoryValidation(
      kubunId,
      largeCategoryId,
      middleCategoryId,
      name,
      standard,
      quantity,
      creditId,
      quoteUnitPrice,
      primeCost,
    )) {
      dispatch(DialogActions.pushMessage({
        title: '明細 編集／追加',
        message: Message.postError,
        callback: () => setTouch(true),
      }));
      return;
    }

    dispatch(MasterActions.api.meisai.post({
      param: {
        data: {
          product_kubun: kubunId,
          category_id: largeCategoryId,
          subcategory_id: middleCategoryId,
          name,
          standard,
          quantity,
          credit_id: creditId,
          quote_unit_price: String(MathHelper.localStrToNum(quoteUnitPrice)),
          prime_cost: String(MathHelper.localStrToNum(primeCost)),
          // image,
          valid_flag: validFlag ? 1 : 0,
        },
        id,
      },
      onSuccess: () => {
        callback({});
        dispatch(DialogActions.pop());
      },
      onError: () => {
        setTouch(true);
      },
    }));
  }, [
    itemKubun,
    largeCategoryId,
    middleCategoryId,
    name,
    standard,
    quantity,
    creditId,
    quoteUnitPrice,
    primeCost,
    // image,
    validFlag,
  ]);

  const onBlur = useCallback(() => {
    const split = quantity.split('.');
    if (split[1] === undefined) {
      setQuantity(`${Number(split[0])}.00`);
    } else if (!split.length) {
      setQuantity(`${Number(split[0])}.00`);
    } else {
      const decimal = (`${split[1]}00`).slice(0, 2);
      setQuantity(`${Number(split[0])}.${decimal}`);
    }
  }, [quantity]);

  const showMiddleCategoryList = useMemo(() => {
    const filter = middleCategoryList.filter((f) => Number(f.category_id) === largeCategoryId);
    const find = filter.find((f) => middleCategoryId === f.id);
    if (!find) setMiddleCategoryId(NaN);
    return filter;
  }, [middleCategoryList, largeCategoryId, middleCategoryId]);

  useDidMount(() => {
    if (id) {
      const data = list.find((f) => Number(f.id) === id);
      if (!data) return;
      setItemKubun(data.product_kubun);
      setLargeCategoryId(data.category_id);
      setMiddleCategoryId(data.subcategory_id);
      setName(data.name);
      setStandard(data.standard);
      setQuantity(data.quantity);
      setCreditId(data.credit_id);
      setQuoteUnitPrice(MathHelper.localStr(data.quote_unit_price));
      setPrimeCost(MathHelper.localStr(data.prime_cost));
      setValidFlag(data.valid_flag);
    }

    dispatch(MasterActions.api.unit.getList({ is_muko: 1 }));
    dispatch(MasterActions.api.largeCategory.getList({ is_muko: 1 }));
    dispatch(MasterActions.api.middleCategory.getList({ is_muko: 1 }));
  });

  return (
    <EditPC mode="dialog" callback={handleClickPost} label={id ? '更新' : '登録'}>
      <div className="edit_pc_body_inner edit_master">
        <div>
          <div className="item_wrap">
            <div className="item_box">
              <div className="item_head">商品区分<Required /></div>
              <Select
                className=""
                errorPosBottom
                value={kubunId}
                onChange={(v) => getKubunLabel(Number(v))}
                defaultLabel="指定無し"
                options={
                  CommonCollection.shohinKubunList.map((v) => ({
                    text: v.text, value: v.value,
                  }))
                }
                require
                touch={touch}
              />
            </div>
          </div>
          <div className="item_wrap">
            <div className="item_box">
              <div className="item_head">大分類名称<Required /></div>
              <Select
                className=""
                value={largeCategoryId}
                onChange={(v) => setLargeCategoryId(Number(v))}
                defaultLabel="指定無し"
                options={largeCategoryList.map((v) => ({
                  text: v.name, value: v.id,
                }))}
                require
                touch={touch}
              />
            </div>
          </div>
          <div className="item_wrap">
            <div className="item_box">
              <div className="item_head">中分類名称<Required /></div>
              <Select
                className=""
                value={middleCategoryId}
                // value={100}
                onChange={(v) => setMiddleCategoryId(Number(v))}
                defaultLabel="指定無し"
                options={showMiddleCategoryList.map((v) => ({
                  text: v.name, value: v.id,
                }))}
                require
                touch={touch}
              />
            </div>
          </div>
          <div className="item_wrap">
            <div className="item_box">
              <div className="item_head">名前<Required /></div>
              <Input
                className="medium"
                require
                label=""
                value={name}
                onChange={(e) => setName(e.target.value)}
                validationList={ValidationLengthUnder255}
                touch={touch}
              />
            </div>
          </div>
          <div className="item_wrap">
            <div className="item_box">
              <div className="item_head">規格</div>
              <Input
                className="medium"
                label=""
                value={standard}
                onChange={(e) => setStandard(e.target.value)}
                validationList={ValidationLengthUnder255}
              />
            </div>
          </div>
          <div className="item_wrap">
            <div className="item_box">
              <div className="item_head">数量<Required /></div>
              <InputNum
                className="small"
                require
                value={quantity}
                onBlur={onBlur}
                onChange={setQuantity}
                validationList={ValidationMax100Million}
                touch={touch}
                intLen={8}
                decimalLen={2}
              />
            </div>
          </div>
          <div className="item_wrap">
            <div className="item_box">
              <div className="item_head">単位<Required /></div>
              <Select
                className=""
                value={creditId}
                onChange={(v) => setCreditId(Number(v))}
                defaultLabel="指定無し"
                options={unitList.map((v) => ({
                  text: v.name, value: v.id,
                }))}
                require
                touch={touch}
              />
            </div>
          </div>
          <div className="item_wrap">
            <div className="item_box">
              <div className="item_head">見積単価<Required /></div>
              <InputNum
                require
                value={quoteUnitPrice}
                onChange={setQuoteUnitPrice}
                validationList={ValidationMax100Million}
                touch={touch}
                intLen={8}
                decimalLen={2}
              />
            </div>
          </div>
          <div className="item_wrap">
            <div className="item_box">
              <div className="item_head">原価単価<Required /></div>
              <InputNum
                require
                value={primeCost}
                onChange={setPrimeCost}
                validationList={ValidationMax100Million}
                touch={touch}
                intLen={8}
                decimalLen={2}
              />
            </div>
          </div>
          <div className="item_wrap">
            <div className="item_box">
              <div className="item_head">有効フラグ</div>
              <RightLabelCheckbox
                label=""
                className="is_valid"
                checked={validFlag}
                onChange={() => setValidFlag(!validFlag)}
              />
            </div>
          </div>
        </div>
      </div>
    </EditPC>
  );
};
