import {
  useCallback, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as lodash from 'lodash';
import { cloneDeep } from 'lodash';
import { useInView } from 'react-intersection-observer';
import { State } from '../../../../../../../redux/root.reducer';
import { MaintenanceList } from '../../../../../../../type/maintenance/maintenance.type';
import { Button } from '../../../../../../ui/button/button';
import { LeftIconButton } from '../../../../../../ui/button/left-icon-button/left-icon-button';
import { MapBase } from '../../../../../../ui/map/map-base';
import './maintenance-map-list.scss';
import { MapActions } from '../../../../../../../redux/map/map.action';
import { DialogActions } from '../../../../../../../redux/dialog/dialog.action';
import { LeftLabelInputField } from '../../../../../../ui/input-field/left-label-input-field/left-label-input-field';
import { MaintenanceEditPC } from '../../../../../pages/maintenance/edit/maintenance-edit.pc';
import { MaintenanceCard } from '../../../../../../ui/card/maintenance/maintenance-card';
import { useDidMount, useWillUnMount } from '../../../../../../../hooks/life-cycle';
import { InputField } from '../../../../../../ui/input-field/input-field';
import { RouteDialog } from '../../../../../../dialogs/route-dialog/route-dialog';
import { useAppSelector } from '../../../../../../../hooks/use-redux';
import { MaintenanceActions } from '../../../../../../../redux/maintenance/maintenance.action';
import { CardList } from '../../../../../../ui/card/card-list/card-list';
import { TableSort } from '../../../../../../ui/table/table-sort/table-sort';
import { MapAreaPosition } from '../../../../../../../type/map/map.type';
import { MapCollection } from '../../../../../../../collection/map/map.collection';

type Props = {
  list: MaintenanceList[] | null;
  page: number;
  isSearch: boolean;
  hitCount: number;
  type: 0 | 1 | 2;
  onClickCard: () => void;
  callback: (type: 0 | 1 | 2) => void;
  callbackGetList: () => void;
  callbackPagination: (offset: number) => void;
  callbackZoomEnd: (mapPos: MapAreaPosition) => void;
  callbackCenterChanged: (mapPos: MapAreaPosition) => void;
  callbackType?: (v: number) => void;
  onGetGps?: (onGps: boolean) => void;
}

export const MaintenanceMapListPC = (props: Props) => {
  const {
    callbackType,
    type,
    list,
    page,
    isSearch,
    hitCount,
    onClickCard,
    callback,
    callbackGetList,
    callbackPagination,
    callbackZoomEnd,
    callbackCenterChanged,
    onGetGps,
  } = props;

  const { ref, inView } = useInView({
    // オプション
    rootMargin: '-50px', // ref要素が現れてから50px過ぎたら
    triggerOnce: true, // 最初の一度だけ実行
  });

  /* Hooks */
  const routeAuth = useAppSelector((v) => v.user.company_authority3);
  const humanPos = useAppSelector((v) => v.map.humanPos);
  const routeInfo = useAppSelector((v) => v.map.routeInfo);
  const dispatch = useDispatch();

  /* State */
  const [maintenance, setMaintenance] = useState<MaintenanceList | null>(null);
  const [maintenanceIndex, setMaintenanceIndex] = useState(NaN);
  const [searchValue, setSearchValue] = useState('');
  const [searchValue2, setSearchValue2] = useState('');
  const [active, setActive] = useState(NaN);

  /* List */
  const maintenanceList = useMemo(() => (list), [type, list]);

  /* Callback */
  const handleClickCard = useCallback((v: MaintenanceList) => {
    if (v.lat !== null && v.lng !== null) {
      dispatch(MapActions.setCenterPos({ lat: Number(v.lat), lng: Number(v.lng) }));
      dispatch(MapActions.setZoomLevel(MapCollection.clusterMaxZoom + 1));
      setActive(v.id);
      onClickCard();
    }
    setMaintenance(lodash.cloneDeep(v));
  }, [onClickCard]);

  const handleClickSearch = useCallback(
    (inMap?: boolean) => {
      dispatch(MapActions.setGpsStatus('out'));
      dispatch(MapActions.api.geocoder({
        param: { param: { address: inMap ? searchValue2 : searchValue } },
        callback: () => {
          dispatch(DialogActions.pop());
          dispatch(MapActions.setZoomLevel(null));
        },
      }));
    },
    [searchValue, searchValue2],
  );

  const handleClickRegistration = useCallback(() => {
    dispatch(DialogActions.push({
      title: 'メンテナンス情報入力',
      className: 'maintenance',
      element: <MaintenanceEditPC callback={callbackGetList} />,
    }));
  }, [callbackGetList]);

  const handleClickRouteSearch = useCallback(() => {
    if (!routeAuth) return;
    dispatch(MapActions.setGpsStatus('out'));
    dispatch(DialogActions.push({
      title: 'ルート設定',
      element: <RouteDialog
        type="project"
        destination={maintenance?.field_prefecture_name}
        callback={() => { }}
      />,
    }));
  }, [maintenance, routeAuth]);

  useDidMount(() => {
    if (humanPos) {
      dispatch(MapActions.setCenterPos(cloneDeep(humanPos)));
    }
  });

  useWillUnMount(() => {
    dispatch(MapActions.setRouteInfo(null));
  });

  return (
    <section className="result_area">
      <div className="inner">
        <div className="list_wrap">
          {/* リスト側の場所検索は使用しないため非表示 */}
          <div className="search_box item_wrap display_none">
            <LeftLabelInputField
              onEnterKeyPress={handleClickSearch}
              className="item_box"
              label="住所または場所を検索"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
            <LeftIconButton
              label="住所検索"
              fontAwesomeClass="fas fa-search"
              className="btn_search for_detail"
              size="sm"
              color="secondary"
              onClick={() => handleClickSearch()}
            />
          </div>
          <div className="list_box_sort">
            <Button
              className={`md primary maintenance_all ${type === 2 ? 'active' : ''}`}
              onClick={() => {
                dispatch(MaintenanceActions.setSort({ supported_kubun: 2 }));
                callback(2);
                callbackType?.(2);
              }}
            >すべて
            </Button>
            <Button
              className={`md primary maintenance_started  ${type === 0 ? 'active' : ''}`}
              onClick={() => {
                dispatch(MaintenanceActions.setSort({ supported_kubun: 0 }));
                callbackType?.(0);
                callback(0);
              }}
            >未対応
            </Button>
            <Button
              className={`md primary maintenance_completed  ${type === 1 ? 'active' : ''}`}
              onClick={() => {
                // dispatch(MaintenanceActions.setSort({ supported_kubun: 1 }));
                callbackType?.(1);
                callback(1);
              }}
            >対応済
            </Button>
            {/*
            <Button
              className="md primary edit"
              onClick={handleClickRegistration}
            >新規登録
            </Button>
            */}
          </div>
          {isSearch
          && (
          <TableSort
            isSp
            page={page}
            limit={100}
            hitCount={hitCount}
            callback={callbackPagination}
          />
          )}
          <CardList isLoading={inView} list={maintenanceList}>
            {maintenanceList?.map((v, i) => (
              <div
                key={`card${i}`}
                className={`card_wrapper ${active === v.id ? 'active' : ''}`}
                ref={maintenanceList.length - 1 ? ref : undefined}
              >
                <MaintenanceCard
                  className="list_card"
                  maintenanceData={v}
                  onClick={() => {
                    setMaintenanceIndex(i);
                    handleClickCard(v);
                  }}
                />
              </div>
            ))}
          </CardList>
        </div>
        <div className="map_area">
          <MapBase
            maintenanceOption={{
              type,
              selectInfo: maintenance,
              selectIndex: maintenanceIndex,
              callbackActiveMarker: setActive,
            }}
            callbackGetList={() => {
              callbackGetList();
            }}
            searchOption={{}}
            isNowPoint
            callbackZoomEnd={callbackZoomEnd}
            callbackChangePosEnd={callbackCenterChanged}
            callbackGetGps={onGetGps}
          />
          <div className="map_search_box">
            <div className="search_box item_wrap">
              <InputField
                onEnterKeyPress={() => handleClickSearch(true)}
                className="item_box"
                // labelPlace="left"
                // label="場所を検索"
                value={searchValue2}
                placeholder="住所または場所を検索"
                onChange={(e) => setSearchValue2(e.target.value)}
              />
              <LeftIconButton
                label="住所検索"
                fontAwesomeClass="fas fa-search"
                className="btn_search"
                size="sm"
                color="secondary"
                onClick={() => handleClickSearch(true)}
              />
              <LeftIconButton
                label="ルート検索"
                fontAwesomeClass="fas fa-route"
                className="btn_search"
                size="sm"
                color="secondary"
                onClick={handleClickRouteSearch}
                disabled={!routeAuth}
              />
              {routeInfo && (
                <Button
                  size="sm"
                  color="secondary"
                  onClick={() => dispatch(MapActions.setRouteInfo(null))}
                >
                  ルート終了
                </Button>
              )}
            </div>
          </div>

        </div>
      </div>
    </section>

  );
};
