import { UserAgent } from '../../../utilities/user-agent';
import './no-data-guidance.scss';

type NoDataGuidanceProps = {
  message?: string,
}

export const NoDataGuidance = (props: NoDataGuidanceProps) => {
  const { message = '該当するデータがありません' } = props;

  return (
    <div className={`no_data_guidance ${UserAgent}`}>{message}</div>
  );
};
