import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Table } from '../../../../../ui/table/table';
import './csv-list-customer-rank.scss';
import { ExportCsvCollection } from '../../../../../../collection/export-csv/export-csv.collection';
import { CsvActions } from '../../../../../../redux/csv/csv.action';
import { CsvCustomerRankType } from '../../../../../../type/csv/csv.type';
import { useAppSelector } from '../../../../../../hooks/use-redux';
import { ApiCsvCustomerRankGetListResponse } from '../../../../../../redux/csv/api/customer-rank/api-customer-rank';
import { MathHelper as MH } from '../../../../../../utilities/math-helper';
import { DateFormatter as DF } from '../../../../../../utilities/date-formatter';
import { useOpenWindow } from '../../../../../../hooks/use-open-window';
import { RoutingPath } from '../../../../../../routes/routing-pass';

export const CsvListCustomerRank = () => {
  /* Hooks */
  const dispatch = useDispatch();
  const windowOpen = useOpenWindow();

  const list = useAppSelector((v) => v.csv.customerRankList);
  const rankList = useAppSelector((v) => v.master.customerRankList);
  const hitCount = useAppSelector((v) => (v.csv.customerCount));

  /* State */
  const [selected, setSelected] = useState<number[]>([]);

  /* Callback */
  const handleClick = useCallback((row: ApiCsvCustomerRankGetListResponse) => {
    if (!list) return;
    setSelected([list.findIndex((v) => v.customer_id === row.customer_id)]);
  }, [list]);

  const handleClickHeader = useCallback((highlow:0 | 1, sort_by: number) => {
    dispatch(CsvActions.setCustomerRankSort({ highlow, sort_by }));
  }, []);

  const handleDbClick = useCallback((
    row: ApiCsvCustomerRankGetListResponse,
    e?: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
  ) => {
    if (!list) return;
    if (!e) return;

    windowOpen({
      url: `${RoutingPath.customerDetail}/${row.customer_id}`,
      e,
    });
    setSelected([list.findIndex((v) => v.customer_id === row.customer_id)]);
  }, [list]);

  return (
    <section className="result_area list_area">
      <div className="inner">
        <div className="table_responsive">
          <Table
            className="table_selectable table_sortable table_sticky table_cell_change"
            header={ExportCsvCollection.customerRankHeader}
            onClickRow={handleClick}
            onDbClick={(v, i, e) => handleDbClick(v, e)}
            sort={{ onClick: handleClickHeader }}
            option={ExportCsvCollection.getTableOption('rankUpdate')}
            rowDataList={list || []}
            selectedTr={selected}
            onClickAlt={(v, e) => {
              if (e && v) {
                windowOpen({
                  url: `${RoutingPath.customerDetail}/${v.customer_id}`,
                  e,
                  callback: () => {},
                });
              }
            }}
            lists={list ? list.map((v) => (
              [
                v.internal_id,
                v.customer_internal_id,
                v.customer_name,
                v.sales_contact,
                v.customer_rank_before_change,
                v.customer_rank_after_change,
                MH.localStr(v.total_work_price),
                v.total_work_times,
                DF.date2str(v.last_completion_date),
                DF.date2str(v.updated_date),
              ]
            )) : null}
          />
        </div>
      </div>
    </section>
  );
};
