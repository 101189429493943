import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { DatePicker } from '../../../../../ui/date-picker/date-picker';
import { useDidMount } from '../../../../../../hooks/life-cycle';
import { EditPC } from '../../../../../dialogs/edit/edit.pc';
import { Required } from '../../../../../ui/required/required';
import { ValidationLengthUnder255, ValidationLengthUnder5, ValidationLengthUnder60 } from '../../../../../../model/validation/validation-length-under';
import { Input } from '../../../../../ui/input/input';
import { FamilyValidation } from '../../../../../../model/validation/customer/family.validation';
import { ListFamily } from '../../../../../../model/customer/family-list.model';
import { DateFormatter } from '../../../../../../utilities/date-formatter';
import { InputTel } from '../../../../../ui/input/input-tel';
import { DialogActions } from '../../../../../../redux/dialog/dialog.action';
import { ItemWrap } from '../../../../../ui/item/item-wrap';
import { Message } from '../../../../../../collection/message.collection';

type Props = {
  data?: ListFamily;
  isEdit?: boolean;
  id: number;
  callbackPost: (v:ListFamily) => void;
}

export const FamilyEditDialog = (props: Props) => {
  const {
    data,
    isEdit,
    id,
    callbackPost,
  } = props;

  /* Hooks */
  const dispatch = useDispatch();

  /* State */
  const [touch, setTouch] = useState(false);
  const [name, setName] = useState('');
  const [relationship, setRelationship] = useState('');
  const [mobilePhone, setMobilePhone] = useState('');
  const [birthDate, setBirthDate] = useState<Date | null>(null);

  /* Callback */
  const handleClickPost = useCallback(() => {
    if (FamilyValidation(
      name,
      relationship,
      mobilePhone,
      birthDate,
    )) {
      dispatch(DialogActions.pushMessage({
        title: 'ご家族情報登録',
        message: Message.postError,
        callback: () => setTouch(true),
        callbackClose: () => setTouch(true),
      }));
      return;
    }
    callbackPost({
      id,
      name,
      relationship,
      customer_id: data?.customer_id ?? NaN,
      email: data?.email ?? '',
      mobile_phone: mobilePhone,
      birth_date: DateFormatter.date2str(birthDate),
      index: isEdit ? data?.index : undefined,
    });
  },
  [name, relationship, mobilePhone, birthDate, id, data]);

  useDidMount(() => {
    if (isEdit && data) {
      setName(data.name);
      setRelationship(data.relationship);
      setMobilePhone(data?.mobile_phone || '');
      if (data.birth_date) { setBirthDate(new Date(data.birth_date)); }
    }
  });

  return (
    <EditPC mode="dialog" callback={handleClickPost}>
      <div className="editPC_body_inner FamilyEditDialog">
        <ItemWrap title={<>氏名<Required /></>}>
          <Input
            value={name}
            onChange={(e) => setName(e.target.value)}
            validationList={ValidationLengthUnder255}
            require
            errorPosBottom
            touch={touch}
          />
        </ItemWrap>
        <ItemWrap title={<>続柄<Required /></>}>
          <Input
            value={relationship}
            onChange={(e) => setRelationship(e.target.value)}
            validationList={ValidationLengthUnder255}
            require
            touch={touch}
          />
        </ItemWrap>
        <ItemWrap title="携帯電話">
          <InputTel
            value={mobilePhone}
            onChange={setMobilePhone}
          />
        </ItemWrap>
        <ItemWrap title="生年月日">
          <div className="birth_date">
            <DatePicker
              date={birthDate}
              onChange={(v) => setBirthDate(v)}
            />
          </div>
        </ItemWrap>
      </div>
    </EditPC>
  );
};
