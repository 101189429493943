import actionCreatorFactory from 'typescript-fsa';
import { apiCallDB } from './api/api-call-db';

const ActionCreator = actionCreatorFactory('call-db');

export const CallDBActions = {
  api: {
    callDb: apiCallDB,
  },
};
