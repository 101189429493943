import './test-page.pc.scss';
import { useDispatch } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import Select from 'react-select';
import { Table } from '../../../ui/table/table';
import { Input } from '../../../ui/input/input';
import { TableSort } from '../../../ui/table/table-sort/table-sort';
import { Button } from '../../../ui/button/button';
import { DialogActions } from '../../../../redux/dialog/dialog.action';
import { InputSelect } from '../../../ui/input-select/input-select';
import { TagType } from '../../../../type/tag/tag.type';
import { useDidMount } from '../../../../hooks/life-cycle';
import { InputNumByNum } from '../../../ui/input/input-num';
import { UserAgent } from '../../../../utilities/user-agent';
import { ChangePasswordErrorPC } from '../password/change-password-error/change-password-error.pc';
import { ChangePasswordErrorSP } from '../../../sp/pages/password/change-password-error/change-password-error.sp';

const SampleData: TagType[] = [
  { label: 'aa', id: 1 },
  { label: 'ab', id: 2 },
  { label: 'ac', id: 3 },
  { label: 'ad', id: 4 },
  { label: 'ae', id: 5 },
];

export const TestPagePC = () => {
  const dispatch = useDispatch();
  // const [list, setlist] = useState<string[] | null>(null);
  const [list, setlist] = useState<string[] | null>(null);
  // const [list, setlist] = useState<string[]>(new Array(20).fill(''));
  const [text, setText] = useState('');
  const [value, setValue] = useState(NaN);

  const [activate, setActivate] = useState(false);

  const onClick = useCallback(() => {
    dispatch(DialogActions.push({
      title: 'test',
      element: <div> DIALOG TEST</div>,
      onCloseClick: () => console.log('close'),
      isCheck: true,
    }));
  }, []);

  useDidMount(() => {
    setValue(3);
  });

  const onClickSingle = () => {
    console.log('single');
  };
  const onClickDouble = () => {
    console.log('double');
  };

  const [num, setNum] = useState('');

  return (
    <>
      <Button onClick={() => setActivate(true)} color="secondary">アクティベート</Button>
      <Button onClick={() => setActivate(false)} color="primary">パスワード変更</Button>
      <div>現在のモード:{ activate ? 'アクティベート' : 'パスワード変更'}</div>
      {UserAgent === 'pc'
        ? <ChangePasswordErrorPC isActivate={activate} />
        : <ChangePasswordErrorSP isActivate={activate} />}
    </>
  );
};
