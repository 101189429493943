import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './support-history-list.pc.scss';
import { cloneDeep, isEqual } from 'lodash';
import { State } from '../../../../../../redux/root.reducer';
import { DialogActions } from '../../../../../../redux/dialog/dialog.action';
import { SupportHistoryListType } from '../../../../../../type/support-history/support-history.type';
import { SupportHistoryActions } from '../../../../../../redux/support-history/support-history.action';
import { Table } from '../../../../../ui/table/table';
import { SupportHistoryCollection } from '../../../../../../collection/support-history/support-history.collection';
import { SupportHistoryEditPC } from '../../../../pages/support-history/edit/support-history-edit.pc';
import { DateFormatter } from '../../../../../../utilities/date-formatter';
import Deposite from '../../../../../../asset/images/icon/deposite.svg';
import { LoadingFileImg } from '../../../../../ui/file-img/loading-file-img';

type Props = {
  data?: SupportHistoryListType[];
  handleCardClick?: (supportHistory: SupportHistoryListType) => void;
  callbackSelected?: (selected: number[]) => void;
  selectId?: number;
  getList?: () => void;
  callbackSelect?: (v: number[]) => void;
  callbackList?: (v?: SupportHistoryListType[] | null) => void;
  callbackStopLoad?: (v: boolean) => void,
  parentSelect?: number[];
  stopLoad?: boolean;
}

export const SupportHistoryListPC = (props: Props) => {
  const {
    data, handleCardClick, callbackSelected, selectId, getList,
    callbackSelect, callbackList, parentSelect, stopLoad, callbackStopLoad,
  } = props;

  /* Hooks */
  const supportHistoryList = useSelector(
    (state: State) => state.supportHistory.list, isEqual,
  );
  const dispatch = useDispatch();

  /* State */
  const [selected, setSelected] = useState<number[]>(cloneDeep([]));

  /* List */
  const dataList = useMemo(() => {
    callbackList?.(data || supportHistoryList);
    return data || supportHistoryList;
  }, [supportHistoryList, data]);

  const handleClickCard = useCallback((id: number) => {
    if (!dataList) return;
    if (handleCardClick) {
      const findData = data?.find((v) => v.id === id);
      if (findData) {
        handleCardClick(findData);
      }
    }
    setSelected([dataList.findIndex((v) => v.id === id)]);
    callbackSelect?.([dataList.findIndex((v) => v.id === id)]);
  }, [data, handleCardClick, dataList]);

  const handleClickHeader = useCallback(
    (highlow:0 | 1, sort_by: number) => {
      dispatch(SupportHistoryActions.setSort({ highlow, sort_by }));
    }, [],
  );

  const handleDbClick = useCallback(
    (v:SupportHistoryListType) => {
      if (!dataList) return;
      if (handleCardClick) return;
      setSelected([dataList.findIndex((v2) => v2.id === v.id)]);
      callbackSelect?.([dataList.findIndex((v2) => v2.id === v.id)]);
      callbackStopLoad?.(true);
      dispatch(DialogActions.push({
        title: '対応履歴編集',
        className: 'support_history',
        onCloseClick: () => callbackStopLoad?.(false),
        element: <SupportHistoryEditPC
          id={v.id}
          callback={getList}
        />,
      }));
    },
    [handleCardClick, dataList],
  );

  /* Effect */

  useEffect(() => {
    if (callbackSelected) { callbackSelected(selected); }
  }, [selected]);

  useEffect(() => {
    if (!dataList) return;
    if (!selectId) {
      setSelected([]);
      return;
    }
    setSelected([dataList.findIndex((v) => v.id === selectId)]);
    callbackSelect?.([dataList.findIndex((v) => v.id === selectId)]);
  }, [selectId, dataList]);

  useEffect(() => {
    if (!parentSelect) return;
    if (!parentSelect.length) setSelected([]);
  }, [parentSelect]);

  return (
    <section className="result_area list_area">
      <div className="inner">
        <div className="table_responsive">
          <Table
            className="table_selectable table_sortable table_sticky table_cell_change"
            header={SupportHistoryCollection.header}
            onClickRow={(v) => { handleClickCard(Number(v.id)); }}
            sort={{
              onClick: (highlow, sort_by) => {
                handleClickHeader(highlow, sort_by);
                callbackSelect?.([]);
              },
            }}
            onClickMulti={(v: SupportHistoryListType[]) => {
              if (!dataList) return;
              setSelected(v.map((v2) => dataList.findIndex((v3) => v3.id === v2.id)));
              callbackSelect?.(v.map((v2) => dataList.findIndex((v3) => v3.id === v2.id)));
            }}
            selectedTr={selected}
            onDbClick={handleDbClick}
            rowDataList={dataList || []}
            lists={dataList ? dataList.map((v) => (
              [
                v.support_flag
                  ? (
                    <img src={Deposite} alt="対応済" title="対応済" className="icon" />
                  )
                  : '',
                <div>
                  <LoadingFileImg
                    id={v.icon_thumbnail_id}
                    format={v.icon_thumbnail_format}
                    fileName={v.icon_thumbnail_file_name}
                    sizeX={40}
                    stopLoad={stopLoad}
                  />
                </div>,
                DateFormatter.date2str(v.contact_dt, 'YYYYMMDD_HHmm'),
                v.title,
                v.support_name,
                v.source_name,
                v.customer_name,
                v.customer_employee_name,
                v.project_employee_name,
                v.support_employee_name,
                DateFormatter.date2str(v.support_dt, 'YYYYMMDD'),
              ]
            )) : null}
            option={{
              stringWidth: [
                { index: 0, width: 80 }, // 対応済
                // { index: 1, width: 100 }, // 登録日時
                // { index: 2, width: 50 }, // カテゴリ
                // { index: 3, width: 50 }, //  顧客名
                // { index: 4, width: 50 }, // 案件担当者
                // { index: 5, width: 50 }, // 対応者
                // { index: 6, width: 50 }, // 対応日
              ],
              tdAlign: [
                { index: 0, align: 'center' },
                { index: 1, align: 'center' },
                { index: 2, align: 'center' },
                { index: 3, align: 'center' },
                { index: 4, align: 'center' },
                { index: 5, align: 'center' },
                { index: 6, align: 'center' },
                { index: 7, align: 'center' },
              ],
            }}
          />
        </div>
      </div>
    </section>
  );
};
