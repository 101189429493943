import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { cloneDeep, isEqual } from 'lodash';
import { MeisaiListXML, MeisaiXML } from '../../../../../model/estimate/estimate-meisai.type';
import { useDidMount, useWillUnMount } from '../../../../../hooks/life-cycle';
import { EditPC } from '../../../../dialogs/edit/edit.pc';
import { Button } from '../../../../ui/button/button';
import { DialogActions } from '../../../../../redux/dialog/dialog.action';
import { EstimateSearch } from '../../../layout/dialogs/estimate-search-dialog/search/estimate-search';
import { DateFormatter } from '../../../../../utilities/date-formatter';
import { MathHelper as MH } from '../../../../../utilities/math-helper';
import { Table } from '../../../../ui/table/table';
import { MeisaiCopyList } from './meisai-copy-list';
import { EstimateMeisaiModel } from '../../../../../model/estimate/estimate-meisai.model';
import { tableSort } from '../../../../../utilities/table-sort';
import { MasterActions } from '../../../../../redux/master/master.action';
import { EstimateActions } from '../../../../../redux/estimate/estimate.action';
import { useAppSelector } from '../../../../../hooks/use-redux';
import { EstimateModel } from '../../../../../model/estimate/estimate-model';
import {
  EstimateListType, EstimateSortState,
} from '../../../../../type/estimate/estimate.type';
import { XmlParser } from '../../../../../parser/xml-parser';
import { EstimateCollection } from '../../../../../collection/estimate/estimate.collection';
import { TagModel } from '../../../../../model/tag/tag';
import { TagActions } from '../../../../../redux/tag/tag.action';
import { Limit, TableSort } from '../../../../ui/table/table-sort/table-sort';

const numFormat = (v: string | number | undefined) => (v ? MH.rounding(Number(v), 0).toLocaleString() : '');
const arari = (v: EstimateListType) => {
  const genka = Number(v.gokei_zeinuki_genka_kin);
  const gokei = Number(v.gokei_kin);
  if (!genka || !gokei) return 0;
  return MH.rounding(
    MH.times(MH.minus(1, MH.div(genka, gokei)), 100),
    0,
    'ceil',
  );
};
const dateFormat = (v: string | undefined) => (v ? DateFormatter.date2str(v) : '');
const sortKey: (keyof EstimateListType)[] = [
  'estimate_no',
  'estimate_dt',
  'field_name',
  'project_name',
  'employee_name',
  'gokei_kin',
];

type Props = {
  callback: (v: MeisaiListXML[]) => void;
}

/** 過去見積から明細Copy */
export const EstimateCopyDialog = (props: Props) => {
  const { callback } = props;
  const sampleData = [];

  const dispatch = useDispatch();
  const user = useAppSelector((v) => v.user);
  const partList = useAppSelector((v) => v.tag.partList);

  const [resetKey, setResetKey] = useState(0);
  const [estimateList, setEstimateList] = useState<EstimateListType[] | null>(null);
  const [selectEstimate, setSelectEstimate] = useState<EstimateListType | null>(null);

  /* 明細一覧 */
  const [meisaiModel, setMeisaiModel] = useState<EstimateMeisaiModel | null>(null);
  const [sortMeisaiList, setSortMeisaiList] = useState<MeisaiListXML[]>([]);
  const [selectsMeisai, setSelectsMeisai] = useState<MeisaiListXML[]>([]);
  const [sortState, setSortState] = useState(EstimateCollection._sortCopyInitialState(user));
  const [hitCount, setHitCount] = useState(0);

  const selected = useMemo(() => {
    if (!selectEstimate || !estimateList) return [NaN];
    return [estimateList.findIndex((v) => v.id === selectEstimate.id)];
  }, [selectEstimate, estimateList]);

  const selectRow = useMemo(() => {
    const _list = cloneDeep(sortMeisaiList);
    const selectList = cloneDeep(selectsMeisai);
    const filter = _list.filter((v) => selectList.filter((v2) => isEqual(v, v2)).length);
    return filter;
  }, [sortMeisaiList, selectsMeisai]);

  const getList = useCallback((v?: EstimateSortState, isSort?: boolean) => {
    setSortState(cloneDeep(v) || sortState);
    if (!isSort) {
      setEstimateList(null);
    }
    setSelectEstimate(null);
    setSortMeisaiList([]);
    setSelectsMeisai([]);
    setMeisaiModel(null);
    dispatch(EstimateActions.api.estimate.getList({
      param: {
        ...EstimateModel.listParamInCopy(v || sortState),
      },
      callback: (data, hit) => {
        setEstimateList(data);
        setHitCount(hit);
      },
    }));
  }, [sortState]);

  const onSort = useCallback((highlow: 0 | 1, sort_by: number) => {
    setSortState({ ...sortState, highlow, sort_by });
  }, [sortState]);

  const onClickRow = useCallback((v:EstimateListType) => {
    setSelectEstimate(cloneDeep(v));
    setResetKey((v2) => v2 + 1);
    const setModel = (respose?: MeisaiXML) => {
      setMeisaiModel(cloneDeep(new EstimateMeisaiModel(respose)));
    };

    if (v.meisai) {
      new XmlParser().parse<MeisaiXML>(v.meisai).then((res) => {
        setModel(res.meisai ? res : undefined);
      });
    } else {
      setModel();
    }
  }, []);

  const addSelectMeisai = useCallback(() => {
    callback(selectRow);
  }, [selectEstimate, selectsMeisai, sortMeisaiList, selectRow]);

  const addAllMeisai = useCallback(() => {
    if (!selectEstimate || !meisaiModel) return;
    (async () => {
      const a = await meisaiModel;
      if (!a) return;
      callback(a.list);
    })();
  }, [selectEstimate, meisaiModel]);

  useEffect(() => {
    getList(undefined, true);
  }, [sortState.highlow, sortState.sort_by]);

  useDidMount(() => {
    dispatch(MasterActions.api.employee.getList({}));
    // dispatch(TagActions.api.part.getList());
  });

  // useEffect(() => {
  //   setSortState({
  //     ...sortState,
  //     construction_parts: new TagModel(partList),
  //   });
  // }, [partList]);

  useEffect(() => {
    getList();
  }, [sortState.offset]);

  return (
    <EditPC
      mode="dialog"
      buttonArea={(
        <>
          <div className="left_box">
            <Button
              size="md"
              color="secondary"
              onClick={addAllMeisai}
              disabled={!selectEstimate}
            >
              明細を複写して見積一括追加
            </Button>
          </div>
          <div className="center_box">
            <Button
              size="md"
              color="dark"
              onClick={() => dispatch(DialogActions.pop())}
            >
              閉じる
            </Button>
          </div>
          <div className="right_box">
            <Button
              size="md"
              color="secondary"
              disabled={!selectRow.length}
              onClick={addSelectMeisai}
            >
              選択明細追加
            </Button>
          </div>
        </>
    )}
    >
      <div className="EstimateSearchDialog">
        <section className="left_box">
          <EstimateSearch
            isSearch
            isCopy
            callback={(v) => {
              setResetKey((v2) => v2 + 1);
              getList(v);
            }}
          />
          <TableSort
            page={sortState.offset}
            limit={sortState.limit as Limit}
            hitCount={hitCount}
            callback={(page, limit) => {
              setSortState({
                ...sortState,
                limit,
                offset: page,
              });
            }}
          />
          <div className="result_area">
            <div className="inner">
              <div className="table_responsive">
                <Table
                  headerReset={resetKey}
                  className="table_selectable table_sortable table_sticky table_cell_change"
                  header={[
                    '見積番号',
                    '見積日',
                    '現場名称',
                    '案件名',
                    '見積作成者',
                    '見積金額',
                    '粗利率',
                  ]}
                  selectedTr={selected}
                  disabledTh={[6]}
                  rowDataList={estimateList || []}
                  onClickRow={onClickRow}
                  sort={{ onClick: onSort }}
                  lists={estimateList
                    ? estimateList.map((v) => ([
                      v.estimate_no,
                      dateFormat(v.estimate_dt),
                      v.field_name,
                      v.project_name,
                      v.created_employee_name,
                      numFormat(v.gokei_kin),
                      `${arari(v) || ''}%`,
                    ]))
                    : null}
                  option={{
                    stringWidth: [
                      // { index: 0, width: 100 }, // 見積番号
                      // { index: 1, width: 100 }, // 見積日
                      // { index: 2, width: 80 }, // 現場名称
                      // { index: 3, width: 50 }, // 案件名
                      // { index: 4, width: 150 }, // 見積作成者
                      // { index: 5, width: 150 }, // 見積金額
                      // { index: 6, width: 100 }, // 粗利率
                    ],
                    tdAlign: [
                      { index: 0, align: 'center' },
                      { index: 1, align: 'center' },
                      { index: 2, align: 'center' },
                      { index: 3, align: 'center' },
                      { index: 4, align: 'center' },
                      { index: 5, align: 'right' },
                      { index: 6, align: 'right' },
                    ],
                  }}
                />
              </div>
            </div>
          </div>
        </section>
        <MeisaiCopyList
          meisaiModel={meisaiModel}
          callback={setSelectsMeisai}
          callbackList={setSortMeisaiList}
        />
      </div>
    </EditPC>
  );
};
