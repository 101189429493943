import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CsvActions } from '../../../../../../redux/csv/csv.action';
import { Limit, TableSort } from '../../../../../ui/table/table-sort/table-sort';
import { CsvListSupportHistory } from '../../list/support-history/csv-list-support-history';
import { CsvSearchBoxSupportHistory } from '../../search-box/support-history/csv-search-box-support-history';
import { CsvSupportHistorySort } from '../../../../../../type/csv/csv-sort.type';
import { DateFormatter } from '../../../../../../utilities/date-formatter';
import { useAppSelector } from '../../../../../../hooks/use-redux';
import { CsvModel } from '../../../../../../model/csv/csv-model';

export type Props = {
  callback: (v: () => void) => void;
  isOpenCallBack: (v: boolean) => void
}

export const CsvSupportHistory = (props: Props) => {
  const { isOpenCallBack } = props;
  /* Hooks */
  const dispatch = useDispatch();
  const sortState = useAppSelector((v) => v.csv.supportHistorySort);
  const list = useAppSelector((v) => v.csv.supportHistoryList);
  const hitCount = useAppSelector((v) => (v.csv.supportHistoryCount));
  const [searchIsOpen, setSearchIsOpen] = useState<boolean>(true);

  /* Callback */
  const getList = useCallback((v?: CsvSupportHistorySort) => {
    const sortData = v || sortState;
    dispatch(CsvActions.api.supportHistory.getList(
      CsvModel.support(sortData),
    ));
  }, [sortState]);

  const changePagination = useCallback((offset:number, limit:Limit) => {
    dispatch(CsvActions.setSupportHistoryList(null));
    dispatch(CsvActions.setSupportHistorySort({ offset, limit }));
    getList({ ...sortState, offset, limit });
  }, [sortState]);

  /* Effect */
  useEffect(() => {
    getList();
  }, [
    sortState.highlow,
    sortState.sort_by,
  ]);

  useEffect(() => {
    isOpenCallBack(searchIsOpen);
  }, [searchIsOpen]);

  return (
    <>
      <CsvSearchBoxSupportHistory
        openCallback={(v) => setSearchIsOpen(v)}
        callback={(v) => {
          dispatch(CsvActions.setSupportHistoryList(null));
          getList(v);
        }}
      />
      <TableSort
        page={sortState.offset || 0}
        limit={sortState.limit as Limit}
        hitCount={hitCount || 0}
        callback={changePagination}
        noAnnotation
      />
      <CsvListSupportHistory />
      <TableSort
        className="bottom"
        page={sortState.offset || 0}
        limit={sortState.limit as Limit}
        hitCount={hitCount || 0}
        callback={changePagination}
        noAnnotation
      />
    </>
  );
};
