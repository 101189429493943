import actionCreatorFactory from 'typescript-fsa';
import { ApiBase } from '../../../../service/api-base';
import { CsvMaintenanceType } from '../../../../type/csv/csv.type';

/* Param */
export type ApiCsvMaintenanceGetListParam = Partial<{
  /** メンテナンス日_開始 */
  maintenance_date_start: string;
  /** メンテナンス日_終了 */
  maintenance_date_end: string;
  /** 案件営業担当店舗 */
  project_store_id: number;
  /** 案件営業担当担当者 */
  project_employee_id: number;
  /** 完工日_開始 */
  completion_date_start: string;
  /** 完工日_終了 */
  completion_date_end: string;
  /** 案件名 */
  project_name: string;
  /** 無効情報も含む */
  is_muko: number;
  /** 対応区分 */
  supported_kubun: number;
  /** 文字列検索 */
  word: string;
  /** オフセット */
  offset: number;
  /** 1ページ内の件数 */
  limit: number;
  /** 並替基準列 */
  sort_by: number;
  /** 並替方法 */
  highlow: number;
  support_flag: number;
  /** 登録店舗 */
  created_store_id:number;
  /** 登録社員 */
  created_employee_id:number;
  /** 更新店舗 */
  updated_store_id:number;
  /** 更新社員 */
  updated_employee_id:number;
  /** 登録日－期間開始 */
  created_at_start:string;
  /** 登録日－期間終了 */
  created_at_end:string;
  /** 更新日－期間開始 */
  updated_at_start:string;
  /** 更新日－期間終了 */
  updated_at_end:string;
}>;

export type ApiCsvMaintenanceDownloadParam = any;

/* Response */
export type ApiCsvMaintenanceGetListResponse = CsvMaintenanceType;

export const ActionCreator = actionCreatorFactory('csv/api/maintenance');

export const apiCsvMaintenance = {
  getList: ActionCreator<ApiCsvMaintenanceGetListParam>('get/list'),
  download: ActionCreator<ApiCsvMaintenanceDownloadParam>('download'),
};

export class ApiCsvMaintenanceGetList extends ApiBase<ApiCsvMaintenanceGetListResponse> {
  constructor(param: ApiCsvMaintenanceGetListParam) {
    super({
      httpMethod: 'GET',
      param,
      url: '/api/csv/maintenance',
    });
  }
}

export class ApiCsvMaintenanceDownload extends ApiBase {
  constructor(param: ApiCsvMaintenanceDownloadParam) {
    super({
      httpMethod: 'GET',
      contents: 'BLOB',
      param,
      url: '/api/csv/download/maintenance',
    });
  }
}
