import { useDispatch } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import { cloneDeep } from 'lodash';
import { Button } from '../../../../../../ui/button/button';
import { DialogActions } from '../../../../../../../redux/dialog/dialog.action';
import { DatePicker } from '../../../../../../ui/date-picker/date-picker';
import { Select } from '../../../../../../ui/select/select';
import { Input } from '../../../../../../ui/input/input';
import { useDidMount } from '../../../../../../../hooks/life-cycle';
import { MasterActions } from '../../../../../../../redux/master/master.action';
import { CustomerDetailActions } from '../../../../../../../redux/customer-detail/customer-detail.action';
import { ProjectSortState } from '../../../../../../../type/project/project.type';
import { pulldown } from '../../../../../../../utilities/pulldown';
import { useAppSelector } from '../../../../../../../hooks/use-redux';
import { InputNumber } from '../../../../../../ui/input/input-number';
import { InputNum } from '../../../../../../ui/input/input-num';
import { sortEmployee, changeStore } from '../../../../../../../utilities/sort-employee';

type Props = {
  callback: (sort:ProjectSortState) => void;
}

export const RefindProjectInformationTable = (props: Props) => {
  const { callback } = props;

  /* Hook */
  const dispatch = useDispatch();
  const sort = useAppSelector((v) => v.customerDetail.projectSort);
  const user = useAppSelector((v) => v.user);

  /* State */
  const [sortState, setSortState] = useState(cloneDeep(sort));
  const {
    originList,
    storeList,
    employeeList,
  } = useAppSelector((v) => v.master);

  /* Callback */
  const handleClickSearch = useCallback(() => {
    dispatch(DialogActions.pop());
    callback(sortState);
  }, [callback, sortState]);

  const setState = useCallback((v: Partial<ProjectSortState>) => {
    const data = {
      ...cloneDeep(sortState),
      ...cloneDeep(v),
    };
    setSortState(data);
    dispatch(CustomerDetailActions.setProjectSort(data));
  }, [sortState]);

  /* Master */
  useDidMount(() => {
    dispatch(MasterActions.api.origin.getList({}));
    dispatch(MasterActions.api.employee.getList({}));
  });

  useEffect(() => {
    if (!user.id) return;
    setState({
      project_employee_id: sortState.project_employee_id || user.id,
    });
  }, [user]);

  return (
    <div className="refind_wrap" onKeyPress={(e) => { if (e.key === 'Enter') handleClickSearch(); }}>
      <div className="refind_body">
        <div className="item_wrap">
          <div className="item_box">
            <div className="item_head">案件名</div>
            <Input
              value={sortState.name}
              onChange={(e) => setState({ name: e.target.value })}
            />
          </div>
          <div className="item_box">
            <div className="item_head">案件担当店舗</div>
            <Select
              value={sortState.project_store_id}
              onChange={(v) => {
                setState(
                  changeStore({
                    employeeList,
                    storeId: Number(v),
                    storeKey: 'store_id',
                    returnStoreKey: 'project_store_id',
                    employeeKey: 'project_employee_id',
                  }),
                );
              }}
              defaultLabel="全て"
              options={pulldown(storeList)}
            />
          </div>
          <div className="item_box">
            <div className="item_head">案件担当者</div>
            <Select
              value={sortState.project_employee_id}
              onChange={(v) => setState({ project_employee_id: Number(v) })}
              defaultLabel="全て"
              options={pulldown(sortEmployee({
                employeeList,
                storeId: sortState.project_store_id,
                storeKey: 'store_id',
              }))}
            />
          </div>
        </div>
        <div className="item_wrap">
          <div className="item_box">
            <div className="item_head">登録日</div>
            <DatePicker
              date={sortState.entry_dt}
              onChange={(v) => setState({ entry_dt: v })}
            />
          </div>
          <div className="item_box">
            <div className="item_head">受注金額（契約金額）</div>
            <InputNum
              value={sortState.order_price}
              onChange={(v) => { setState({ order_price: v }); }}
              onBlur={(v) => setState({
                order_price: v ? Number(v).toLocaleString() : '',
              })}
              minus
            />
          </div>
        </div>
        <div className="item_wrap">
          <div className="item_box">
            <div className="item_head">着工日</div>
            <DatePicker
              date={sortState.construction_date}
              onChange={(v) => setState({ construction_date: v })}
            />
          </div>
          <div className="item_box">
            <div className="item_head">完工日</div>
            <DatePicker
              date={sortState.completion_date}
              onChange={(v) => setState({ completion_date: v })}
            />
          </div>
          <div className="item_box">
            <div className="item_head">完了日</div>
            <DatePicker
              date={sortState.complete_date}
              onChange={(v) => setState({ complete_date: v })}
            />
          </div>
        </div>
        <div className="item_wrap">
          <div className="item_box">
            <div className="item_head">契約日</div>
            <DatePicker
              date={sortState.contract_date}
              onChange={(v) => setState({ contract_date: v })}
            />
          </div>
          <div className="item_box">
            <div className="item_head">失注日</div>
            <DatePicker
              date={sortState.failure_date}
              onChange={(v) => setState({ failure_date: v })}
            />
          </div>
          <div className="item_box">
            <div className="item_head">キャンセル日</div>
            <DatePicker
              date={sortState.cancel_date}
              onChange={(v) => setState({ cancel_date: v })}
            />
          </div>
        </div>
        <div className="item_wrap">
          <div className="item_box">
            <div className="item_head">発生源</div>
            <Select
              value={sortState.source_id}
              defaultLabel="指定無し"
              options={pulldown(originList)}
              onChange={(v) => setState({ source_id: Number(v) })}
            />
          </div>
          <div className="item_box">
            <div className="item_head">備考</div>
            <Input
              value={sortState.remarks}
              onChange={(e) => setState({ remarks: e.target.value })}
            />
          </div>
        </div>
      </div>
      <footer className="base_footer refind_footer">
        <Button
          size="md"
          color="primary"
          onClick={handleClickSearch}
        >絞込み
        </Button>
        <Button
          size="md"
          color="dark"
          onClick={() => dispatch(DialogActions.pop())}
        >キャンセル
        </Button>
      </footer>
    </div>
  );
};
