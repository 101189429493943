import React, {
  useCallback, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cloneDeep, isEqual } from 'lodash';
import * as DateFns from 'date-fns';
import { MaintenanceCollection } from '../../../../../collection/maintenance/maintenance.collection';
import { useDidMount } from '../../../../../hooks/life-cycle';
import { DialogActions } from '../../../../../redux/dialog/dialog.action';
import { MaintenanceActions } from '../../../../../redux/maintenance/maintenance.action';
import { Maintenance, MaintenanceEditState } from '../../../../../type/maintenance/maintenance.type';
import { Project, ProjectListType } from '../../../../../type/project/project.type';
import { DateFormatter } from '../../../../../utilities/date-formatter';
import { EditSP, EditType } from '../../../../dialogs/edit/edit.sp';
import { Button } from '../../../../ui/button/button';
import { RightLabelCheckbox } from '../../../../ui/checkbox/right-label-checkbox/right-label-checkbox';
import { DatePicker } from '../../../../ui/date-picker/date-picker';
import { Input } from '../../../../ui/input/input';
import { Required } from '../../../../ui/required/required';
import './maintenance-edit.sp.scss';
import { State } from '../../../../../redux/root.reducer';
import { TextArea } from '../../../../ui/text-area/text-area';
import { ValidationLengthUnder255, ValidationLengthUnder500, ValidationLengthUnder60 } from '../../../../../model/validation';
import { MaintenanceValidation } from '../../../../../model/validation/maintenance/maintenance.validation';
import { ValidationDatePicker } from '../../../../../model/validation/validation-date-picker';
import { Customer } from '../../../../../type/customer/customer.type';
import { MaintenanceModel } from '../../../../../model/maintenance/maintenance.model';
import { ProjectSearchSP } from '../../../layout/search-box/project/project-search/project-search.sp';
import { Message } from '../../../../../collection/message.collection';
import { useEditAuthMaintenance } from '../../../../../hooks/use-authority';

export type Props = {
  id?: number;
  projectData?: Project;
  customerData?: Customer;
  callback?: () => void;
  callbackGetList?: () => void;
}

export const MaintenanceEditSP = (props: Props) => {
  const {
    id, projectData, customerData, callback, callbackGetList,
  } = props;

  /* Hooks */
  const dispatch = useDispatch();
  const sortState = useSelector((state: State) => (state.maintenance.sort), isEqual);

  /* State */
  const [maintenance, setMaintenance] = useState(MaintenanceCollection.editInitialState);
  const [customerName, setCustomerName] = useState('');
  const [projectName, setProjectName] = useState('');
  const [creatorName, setCreatorName] = useState('');
  const [constructionDate, setConstructionDate] = useState('');
  // eslint-disable-next-line
  const [contractedDate, setContractedDate] = useState('');
  const [completionDate, setCompletionDate] = useState('');
  const [touch, setTouch] = useState(false);
  const [dateBtnIndex, setDateBtnIndex] = useState(-1);
  const [info, setInfo] = useState<Maintenance | null>(null);

  const disabled = !useEditAuthMaintenance(info);

  /* Memo */
  const today = useMemo(() => new Date(), []);

  /* Callback */
  const setState = useCallback(
    (v: Partial<MaintenanceEditState>) => {
      setMaintenance({
        ...maintenance,
        ...v,
      });
    }, [maintenance],
  );

  const setProjectData = useCallback((v: Project | ProjectListType) => {
    setCustomerName(v.customer_name);
    setCreatorName('テスト');
    setProjectName(v.name);
    setConstructionDate(DateFormatter.date2str(v.construction_date));
    setCompletionDate(DateFormatter.date2str(v.completion_date));
    setContractedDate(DateFormatter.date2str(v.contract_date));
    setState({
      customer_id: v.customer_id,
      project_id: v.id,
    });
  }, [setState]);

  const dateCalc = useCallback((ym: 'y' | 'm', after: number) => {
    let afterDate = cloneDeep(today);
    if (ym === 'm') {
      afterDate = DateFns.addMonths(afterDate, after);
    } else {
      afterDate = DateFns.addYears(afterDate, after);
    }
    setMaintenance({
      ...maintenance,
      title: `${customerName}様<${projectName}> ${after}${ym === 'y' ? '年' : 'ヶ月'}後メンテ`,
      maintenance_date: afterDate,
    });
  }, [today, customerName, projectName, maintenance]);

  const handleClickPost = useCallback(
    () => {
      if (disabled) return;
      if (MaintenanceValidation(maintenance)) {
        dispatch(DialogActions.pushMessage({
          title: 'メンテナンス情報入力',
          message: Message.postError,
          callback: () => setTouch(true),
        }));
        return;
      }

      dispatch(MaintenanceActions.api.maintenance.post({
        param: {
          data: MaintenanceModel.postParam({ maintenance }),
          id,
        },
        update: !!id,
        onSuccess: () => {
          if (callbackGetList) {
            callbackGetList();
            return;
          }
          if (callback) {
            callback();
            return;
          }
          dispatch(MaintenanceActions.api.maintenance.getList({
            param: {
              ...sortState,
              maintenance_date: DateFormatter.date2str(sortState.maintenance_date),
              maintenance_date_start: DateFormatter.date2str(sortState.maintenance_date_start),
              maintenance_date_end: DateFormatter.date2str(sortState.maintenance_date_end),
              completion_date_start: DateFormatter.date2str(sortState.completion_date_start),
              completion_date_end: DateFormatter.date2str(sortState.completion_date_end),
              construction_date: DateFormatter.date2str(sortState.construction_date),
              completion_date: DateFormatter.date2str(sortState.completion_date),
              supported_date: DateFormatter.date2str(sortState.supported_date),
              created_at_start: DateFormatter.date2str(sortState.created_at_start, 'YYYYMMDD', '-'),
              created_at_end: DateFormatter.date2str(sortState.created_at_end, 'YYYYMMDD', '-'),
              updated_at_start: DateFormatter.date2str(sortState.updated_at_start, 'YYYYMMDD', '-'),
              updated_at_end: DateFormatter.date2str(sortState.updated_at_end, 'YYYYMMDD', '-'),
              is_muko: sortState.is_muko ? 1 : 0,
            },
          }));
        },
        onError: () => {
          setTouch(true);
        },
      }));
    },
    [maintenance, sortState, id],
  );

  const handleClickProjectSearch = useCallback((customer?: Customer) => {
    dispatch(DialogActions.push({
      title: '案件検索',
      onCloseClick: () => dispatch(DialogActions.clear()),
      element: <ProjectSearchSP
        type="maintenance"
        customerData={customer}
        callback={setProjectData}
      />,
    }));
  }, [setProjectData]);

  /* Effect */
  useDidMount(() => {
    if (!id && projectData) {
      setProjectData(projectData);
      return;
    }
    if (!id && customerData) {
      handleClickProjectSearch(customerData);
      return;
    }
    if (!id) {
      handleClickProjectSearch();
      return;
    }
    if (id !== undefined) {
      dispatch(MaintenanceActions.api.maintenance.get({
        param: { id },
        callback: (v) => {
          setInfo(cloneDeep(v));
          setConstructionDate(v.construction_date || '');
          setCompletionDate(v.completion_date || '');
          setContractedDate(v.contract_date || '');
          setProjectName(v.project_name);
          setCustomerName(v.customer_name);
          setMaintenance({
            ...v,
            maintenance_date: v.maintenance_date ? new Date(v.maintenance_date) : null,
            supported_date: v.supported_date ? new Date(v.supported_date) : null,
            supported_kubun: v.supported_kubun ? 1 : 0,
            is_muko: v.is_valid ? 0 : 1,
          });
        },
      }));
    }
  });

  return (
    <EditSP
      mode={!id ? 'add' : 'update'}
      callback={handleClickPost}
      disabled={disabled}
    >
      {/* edit_sp_body_inner は各画面の共通用 */}
      <div className="edit_sp_body_inner maintenance_edit_sp">
        <div className="category_wrap">

          <div className="item_wrap">
            <div className="item_label">
              顧客名<Required />
              {!id
                && (
                  <Button
                    size="md"
                    color="secondary"
                    onClick={() => handleClickProjectSearch()}
                  >
                    案件検索
                  </Button>
                )}
            </div>
            <div className="item_body">
              <Input
                value={customerName}
                disabled
                require
              />
            </div>
          </div>

          <div className="item_wrap">
            <div className="item_body item_maintenance">
              <div className="item_label">案件名<Required /></div>
              <Input
                value={projectName}
                disabled
                require
              />
            </div>
          </div>

          <div className="item_wrap">
            <div className="item_label">契約日</div>
            <div className="item_body item_schedule">
              <Input
                value={contractedDate}
                disabled
                className="item_schedule__form"
              />
            </div>
          </div>
          <div className="item_wrap">
            <div className="item_label">
              <div style={{ flex: 1 }}>着工日</div>
              <div className="dummy_tilde_space" />
              <div style={{ flex: 1 }}>完工日</div>
            </div>
            <div className="item_body item_schedule">
              <Input
                value={constructionDate}
                disabled
                className="item_schedule__form"
              />
              <div className="item_schedule__tilde">〜</div>
              <Input
                value={completionDate}
                disabled
                require
                className="item_schedule__form"
              />
            </div>
          </div>
        </div>

        <div className="category_wrap">
          <div className="item_wrap">
            <div className="item_label">メンテナンス日<Required /></div>
            <div className="item_body maintenance_date">
              <DatePicker
                date={maintenance.maintenance_date}
                onChange={(v) => setState({ maintenance_date: v })}
                touch={touch}
                disabled={disabled}
                require
              />
              <div className="maintenance_date__buttons">
                <div
                  className={
                    `maintenance_date__button
                    ${dateBtnIndex === 0 ? ' selected' : ''}
                    ${disabled ? '' : ''}
                    `
                  }
                  onClick={() => {
                    if (disabled) return;
                    dateCalc('m', 1);
                    setDateBtnIndex(0);
                  }}
                >
                  1ヶ月
                </div>

                <div
                  className={
                    `maintenance_date__button
                    ${dateBtnIndex === 1 ? ' selected' : ''}
                    ${disabled ? '' : ''}
                    `
                  }
                  onClick={() => {
                    if (disabled) return;
                    dateCalc('m', 3);
                    setDateBtnIndex(1);
                  }}
                >
                  3ヶ月
                </div>

                <div
                  className={
                    `maintenance_date__button
                    ${dateBtnIndex === 2 ? ' selected' : ''}
                    ${disabled ? '' : ''}
                    `
                  }
                  onClick={() => {
                    if (disabled) return;
                    dateCalc('m', 6);
                    setDateBtnIndex(2);
                  }}
                >
                  6ヶ月
                </div>

                <div
                  className={
                    `maintenance_date__button
                    ${dateBtnIndex === 3 ? ' selected' : ''}
                    ${disabled ? '' : ''}
                    `
}
                  onClick={() => {
                    if (disabled) return;
                    dateCalc('y', 1);
                    setDateBtnIndex(3);
                  }}
                >
                  1年
                </div>

                <div
                  className={
                    `maintenance_date__button
                    ${dateBtnIndex === 4 ? ' selected' : ''}
                    ${disabled ? '' : ''}
                    `
}
                  onClick={() => {
                    if (disabled) return;
                    dateCalc('y', 2);
                    setDateBtnIndex(4);
                  }}
                >
                  2年
                </div>

                <div
                  className={
                    `maintenance_date__button
                    ${dateBtnIndex === 5 ? ' selected' : ''}
                    ${disabled ? '' : ''}
                    `
}
                  onClick={() => {
                    if (disabled) return;
                    dateCalc('y', 5);
                    setDateBtnIndex(5);
                  }}
                >
                  5年
                </div>

              </div>
            </div>
          </div>

          <div className="item_wrap">
            <div className="item_label">タイトル<Required /></div>
            <div className="item_body">
              <Input
                value={maintenance?.title}
                onChange={(e) => setState({ title: e.target.value })}
                require
                validationList={ValidationLengthUnder60}
                touch={touch}
                disabled={disabled}
              />
            </div>
          </div>

          <div className="item_wrap">
            <div className="item_label">詳細内容</div>
            <div className="item_body full_width item_remarks">
              <TextArea
                rows={7}
                value={maintenance?.detail}
                onChange={(e) => setState({ detail: e.target.value })}
                validationList={ValidationLengthUnder500}
                touch={touch}
                disabled={disabled}
              />
            </div>
          </div>

          <div className="item_wrap">
            <div className="item_label">対応日</div>
            <DatePicker
              date={maintenance.supported_date}
              onChange={(v) => setState({ supported_date: v })}
              disabled={disabled}
            />
          </div>

          <div className="item_wrap">
            <div className="item_label">対応内容</div>
            <div className="item_body full_width item_remarks">
              <TextArea
                rows={7}
                value={maintenance?.supported_content}
                onChange={(e) => setState({ supported_content: e.target.value })}
                validationList={ValidationLengthUnder500}
                touch={touch}
                disabled={disabled}
              />
            </div>
          </div>
        </div>

        {/* TODO 編集時に入れたis_handlingの値が変わらない */}
        <div className="category_wrap">
          <div className="item_wrap tags_form">
            <div className="item_body item_checkbox">
              <RightLabelCheckbox
                checked={Boolean(maintenance.supported_kubun)}
                label="対応済みフラグ"
                onChange={() => setState({ supported_kubun: maintenance.supported_kubun ? 0 : 1 })}
                className="single_column"
                disabled={disabled}
              />
            </div>
          </div>
          {/* TODO 編集時に入れたis_handlingの値が変わらない */}
          <div className="item_wrap tags_form">
            <div className="item_body item_checkbox">
              <RightLabelCheckbox
                checked={Boolean(maintenance?.is_muko)}
                label="無効フラグ"
                onChange={() => setState({ is_muko: maintenance.is_muko === 1 ? 0 : 1 })}
                className="single_column"
                disabled={disabled}
              />
            </div>
          </div>
        </div>
      </div>
    </EditSP>
  );
};
