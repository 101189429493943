/* eslint-disable no-nested-ternary */
import { useDispatch } from 'react-redux';
import { Route, RouteProps } from 'react-router-dom';
import { useState } from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';
import { useLocation } from 'react-router';
import { push } from 'connected-react-router';
import { useDidMount } from '../hooks/life-cycle';
import { UserActions } from '../redux/user/user.action';
import { RoutingPath } from '../routes/routing-pass';
import { UserAgent } from '../utilities/user-agent';
import { ChangeCompanyActions } from '../redux/changecompany/changecompany-action';

export const AuthRoute = (props:globalThis.React.PropsWithChildren<RouteProps>) => {
  const dispatch = useDispatch();
  const [status, setStatus] = useState<boolean | null>(false);
  const location = useLocation().pathname;

  useDidMount(() => {
    dispatch(UserActions.getUser({
      location,
      callback: (v) => {
        if (UserAgent === 'sp' && (v?.view_data?.company_id && v.view_data.company_id !== v.company_id)) {
          dispatch(ChangeCompanyActions.api.contractedChangeCompany.post({
            param: {
              chg_company_id: v.company_id,
            },
            ignorePop: true,
            onSuccess: () => {
              window.location.reload();
            },
          }));
          return;
        }
        if (v) {
          setTimeout(() => {
            setStatus(true);
          }, 500);
          return;
        }
        // if (location === '/') {
        //   dispatch(push(RoutingPath.login));
        //   return;
        // }
        setStatus(null);
      },
    }));
  });

  return status
    ? <Route {...props} />
    : status === null
      ? <></>
      : (
        <Dimmer active style={{ zIndex: 10000000 }}>
          <Loader size="massive" inverted content="Loading" />
        </Dimmer>
      );
};
