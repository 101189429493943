import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { cloneDeep } from 'lodash';
import { useDispatch } from 'react-redux';
import { MapBase } from '../../map-base';
import './registration-address-map-dialog.pc.scss';
import { MapActions } from '../../../../../redux/map/map.action';
import { DialogActions } from '../../../../../redux/dialog/dialog.action';
import { Address, MapAreaPosition } from '../../../../../type/map/map.type';
import { InputField } from '../../../input-field/input-field';
import { LeftIconButton } from '../../../button/left-icon-button/left-icon-button';
import { CustomerListType } from '../../../../../type/customer/customer.type';
import { MaintenanceList } from '../../../../../type/maintenance/maintenance.type';
import { ProjectListType } from '../../../../../type/project/project.type';
import { useDidMount, useWillUnMount } from '../../../../../hooks/life-cycle';
import { CustomerActions } from '../../../../../redux/customer/customer.action';
import { ProjectActions } from '../../../../../redux/project/project.action';
import { MaintenanceActions } from '../../../../../redux/maintenance/maintenance.action';
import { CustomerModel } from '../../../../../model/customer/customer-model';
import { CustomerCollection } from '../../../../../collection/customer/customer.collection';
import { useAppSelector } from '../../../../../hooks/use-redux';
import { MapCollection } from '../../../../../collection/map/map.collection';
import { ProjectModel } from '../../../../../model/project/project.model';
import { ProjectCollection } from '../../../../../collection/project/project.collection';

export type Props = {
  type?: 'customer' | 'project' | 'maintenance'
  callbackData?: (data: CustomerListType | MaintenanceList | ProjectListType) => void;
  callback?: (address: Address | null) => void;
  label?: string;
};

export const RegistrationAddressMapDialogPC = (props:Props) => {
  const {
    callback, type, callbackData, label,
  } = props;

  /* Hooks */
  const dispatch = useDispatch();
  const routeMapAreaPos = useAppSelector((v) => (v.map.routeMapAreaPos));
  const zoom = useAppSelector((v) => v.map.routeZoomLevel);
  const humanPos = useAppSelector((v) => v.map.humanPos);
  const areaDisabled = useAppSelector((v) => v.map.routeAreaBtnDisabled);
  const cList = useAppSelector((v) => v.customer.routeList);
  const pList = useAppSelector((v) => v.project.routeList);

  /* State */
  const [searchValue, setSearchValue] = useState('');
  const [zoomSearch, setZoomSearch] = useState(false);
  const [isInit, setIsInit] = useState(true);
  const [humanInit, setHumanInit] = useState(true);
  const [memoryName, setMemoryName] = useState('');
  const [name, setName] = useState('');

  const mapLoading = useMemo(() => {
    let list: CustomerListType[] | ProjectListType[] | null = null;
    if (type === 'customer') list = cloneDeep(cList);
    else if (type === 'project') list = cloneDeep(pList);
    else list = [];
    return list;
  }, [cList, pList, type]);

  const typeLabel = useMemo(() => {
    let _label = '';
    if (type === 'customer') _label = '顧客';
    if (type === 'project') _label = '案件';
    return _label;
  }, []);

  /* Callback */
  const handleClickSearch = useCallback(() => {
    dispatch(MapActions.setGpsStatus('out'));
    dispatch(MapActions.api.geocoder({
      param: {
        param: {
          address: searchValue,
        },
      },
      callback: () => dispatch(MapActions.setRouteZoomLevel(null)),
    }));
  },
  [searchValue]);

  const searchOption = useMemo(() => {
    switch (type) {
      case 'customer':
        return ({
          customerOption: {
            type: 0,
            callbackMapRegist: callbackData,
          },
          label: label || '登録',
        });
      case 'project':
        return ({
          projectOption: {
            type: 0,
            callbackMapRegist: callbackData,
          },
          label: label || '登録',
        });
      case 'maintenance':
        return ({
          maintenanceOption: {
            type: 0,
            callbackMapRegist: callbackData,
          },
          label: label || '登録',
        });
      default:
        return ({
          searchOption: {
            isRegistrationAddress: {
              callback: (address:Address | null) => {
                if (callback) callback(address);
                dispatch(DialogActions.pop());
              },
              label: label || '登録',
            },
          },
        });
    }
  }, [type, callbackData, label]);

  const getList = useCallback((v?: MapAreaPosition, searchName: string = name) => {
    if (!type) return;
    if (type === 'customer') {
      dispatch(CustomerActions.setRouteList(null));
      dispatch(CustomerActions.api.customer.getList(
        {
          param: CustomerModel.listParam({
            sortState: {
              ...CustomerCollection.customerSortInitialState,
              name: searchName,
            },
            isMap: true,
            mapAreaPos: v || routeMapAreaPos,
          }),
          isRoute: true,
          onSuccess: (data) => {
            dispatch(CustomerActions.setRouteList(cloneDeep(data?.data || [])));
            if (!data?.data.length) {
              dispatch(DialogActions.pushMessage({
                title: '検索',
                message: ['領域内に該当データがありません'],
              }));
            }
          },
        },
      ));
    }

    if (type === 'project') {
      dispatch(ProjectActions.setRouteList(cloneDeep(null)));
      dispatch(ProjectActions.api.project.getList(
        {
          param: ProjectModel.listParam({
            sortState: {
              ...ProjectCollection.initialSortState(),
              name: searchName,
            },
            isMap: true,
            mapAreaPos: v || routeMapAreaPos,
          }),
          isRoute: true,
          onSuccess: (data) => {
            dispatch(ProjectActions.setRouteList(cloneDeep(data?.data || [])));
            if (!data?.data.length) {
              dispatch(DialogActions.pushMessage({
                title: '検索',
                message: ['領域内に該当データがありません'],
              }));
            }
          },
        },
      ));
    }
    dispatch(MapActions.setRouteAreaBtnDisabled(true));
  }, [routeMapAreaPos, type, name]);

  /* Effect */
  useEffect(() => () => {
    dispatch(MapActions.setGpsStatus('out'));
    dispatch(MapActions.setSearchPos(null));
    dispatch(MapActions.setSearchAddress(null));
  }, []);

  const onClickAreaSearch = useCallback((v?: MapAreaPosition, searchName?: string) => {
    if (searchName !== undefined) setName(searchName);
    setZoomSearch(true);
    if (zoom >= MapCollection.searchRouteZoomLevel && routeMapAreaPos) {
      getList(v, searchName);
      setZoomSearch(false);
    } else {
      dispatch(MapActions.setRouteZoomLevel(cloneDeep(MapCollection.searchRouteZoomLevel)));
    }
  }, [getList, zoom, routeMapAreaPos, zoomSearch]);

  useEffect(() => {
    if (isInit && routeMapAreaPos) {
      onClickAreaSearch(routeMapAreaPos);
      setIsInit(false);
    }
  }, [routeMapAreaPos]);

  useWillUnMount(() => {
    dispatch(MapActions.setRouteZoomLevel(null));
    dispatch(MapActions.setRouteMapAreaPosition(null));
    dispatch(MapActions.setRouteAreaBtnDisabled(true));
  });

  return (
    <div className="registrationAddressMapDialog">

      <MapBase
        {...searchOption}
        mapLoad={!mapLoading}
        noGps
        isRoute
        callbackZoomEnd={(v) => {
          if (!zoomSearch) {
            dispatch(MapActions.setRouteAreaBtnDisabled(false));
            return;
          } if (zoom >= MapCollection.searchRouteZoomLevel) {
            getList(v, name);
            setZoomSearch(false);
          }
        }}
      />
      <div className="map_search_box">
        <div className="search_box item_wrap">
          <div className="item_box">
            <InputField
              onEnterKeyPress={handleClickSearch}
              // labelPlace="left"
              // label="場所を検索"
              className="item_box"
              value={searchValue}
              placeholder="住所または場所を検索"
              onChange={(e) => setSearchValue(e.target.value)}
            />
            <LeftIconButton
              label="住所検索"
              fontAwesomeClass="fas fa-search"
              className="ml_10"
              size="sm"
              color="secondary"
              onClick={handleClickSearch}
            />
          </div>
        </div>
      </div>
      {!!type && (
      <div className="project_search_box">
        <div className="search_box item_wrap">
          <div className="item_box">
            <InputField
              onEnterKeyPress={() => onClickAreaSearch(undefined, memoryName)}
              className="item_box"
              value={memoryName}
              placeholder={`${typeLabel}名を検索`}
              onChange={(e) => setMemoryName(e.target.value)}
            />
            <LeftIconButton
              label={`${typeLabel}検索`}
              fontAwesomeClass="fas fa-search"
              className="ml_10"
              size="sm"
              color="secondary"
              onClick={() => onClickAreaSearch(undefined, memoryName)}
            />
          </div>
        </div>
      </div>
      )}
      {(!areaDisabled && !!type) && (
      <LeftIconButton
        label="このエリアを検索"
        size="md"
        fontAwesomeClass="fas fa-search"
        className="for_area for_registration"
        color="secondary"
        onClick={() => {
          dispatch(MapActions.setRouteAreaBtnDisabled(true));
          onClickAreaSearch();
        }}
      />
      )}
    </div>

  );
};
