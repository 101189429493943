import { useLocation } from 'react-router-dom';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import { Config } from '../config/config';
import { useDidMount } from './life-cycle';

export const Redirect = () => {
  const location = useLocation().pathname;
  const dispatch = useDispatch();
  useDidMount(() => {
    if (window.location.href.split('/')[3] !== '#' && Config.mode !== 'local') {
    // if (window.location.href.split('/')[3] !== '#') {
      if (window.location.href.split('/')[3] === undefined) {
        // window.location.href = Config.redirectURL;
      } else {
        const { host } = Config;
        window.location.href = `${host}/#/`;
      }
    }
  });
  return <></>;
};
