import { ValidationLengthUnder255 } from '..';
import { Message } from '../../../collection/message.collection';
import { ValidationMailAddress } from '../validation-mail-address';
import { ValidationNumberLengthUnder14 } from '../validation-number-length-under';
import { ValidationPassword } from '../validation-password';

export const MasterEmployeeValidation = (
  newPassword: string,
  passwordCheck: string,
  store: number,
  name: string,
  shortName: string,
  furigana: string,
  jobTitle: string,
  mailAddress: string,
  salesTarget: string,
): string[] | null => {
  if (
    // 必須
    !store
    || !name
    || !mailAddress

    // バリデーション
    || ValidationLengthUnder255.filter((v) => !v.run(String(name || ''))).length
    || ValidationLengthUnder255.filter((v) => !v.run(String(shortName || ''))).length
    || ValidationLengthUnder255.filter((v) => !v.run(String(furigana || ''))).length
    || ValidationLengthUnder255.filter((v) => !v.run(String(jobTitle || ''))).length
    || ValidationMailAddress.filter((v) => !v.run(String(mailAddress || ''))).length
    || (newPassword && ValidationPassword.filter((v) => !v.run(String(newPassword || ''))).length)
    || (passwordCheck && ValidationPassword.filter((v) => !v.run(String(passwordCheck || ''))).length)
    || ValidationNumberLengthUnder14.filter((v) => !v.run(String(salesTarget || 0))).length
  ) {
    return Message.postError;
  }

  if (newPassword !== passwordCheck) {
    return ['パスワードが一致しません'];
  }

  return null;
};
