import { getTimeTermValidation } from '../../../utilities/get-term-day';

export type TermParam = {
  start: Date | null;
  end: Date | null;
};

export const DateTermValidation = (data: TermParam[]): string[] => {
  const validationList: boolean[] = [];
  data.forEach((v) => {
    const {
      start,
      end,
    } = v;
    if (start || end) {
      if (getTimeTermValidation(start, end)) {
        validationList.push(true);
      } else {
        validationList.push(false);
      }
    } else {
      validationList.push(true);
    }
  });

  return validationList.includes(false) ? ['正しい日付範囲を入力してください'] : [];
};
