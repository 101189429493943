import { cloneDeep } from 'lodash';
import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { TopLabelInputField } from '../../../../ui/input-field/top-label-input-field/top-label-input-field';
import { SearchBox } from '../../../layout/search-box/search-box.sp';
import { Select } from '../../../../ui/select/select';
import { MaintenanceSortState } from '../../../../../type/maintenance/maintenance.type';
import { MaintenanceActions } from '../../../../../redux/maintenance/maintenance.action';
import { DatePicker } from '../../../../ui/date-picker/date-picker';
import { RightLabelCheckbox } from '../../../../ui/checkbox/right-label-checkbox/right-label-checkbox';
import { useDidMount, useWillUnMount } from '../../../../../hooks/life-cycle';
import { MasterActions } from '../../../../../redux/master/master.action';
import { Radio } from '../../../../ui/radio/radio';
import { MapActions } from '../../../../../redux/map/map.action';
import { useAppSelector } from '../../../../../hooks/use-redux';
import { pulldown } from '../../../../../utilities/pulldown';
import { Button } from '../../../../ui/button/button';
import { MaintenanceCollection } from '../../../../../collection/maintenance/maintenance.collection';
import { useDateValidation } from '../../../../../hooks/use-date-validation';
import { DateTermValidation } from '../../../../../model/validation/date/term-date.validation';

type Props = {
  getList: (sortState: MaintenanceSortState) => void;
  isSearch?: boolean;
}

export const MaintenanceSearchBoxSP = (props:Props) => {
  const { getList, isSearch } = props;

  /* Hooks */
  const dispatch = useDispatch();
  const user = useAppSelector((v) => v.user);
  const storeList = useAppSelector((v) => v.master.storeList);
  const employeeList = useAppSelector((v) => v.master.employeeList);
  const sortState = useAppSelector((v) => (v.maintenance.sort));
  const isInit = useAppSelector((v) => v.maintenance.isSortInit);

  /* State */
  const [sort, setSort] = useState(cloneDeep(sortState));
  const _employeeList = useMemo(() => employeeList.filter((
    v,
  ) => (Number.isNaN(sort.project_store_id) || sort.project_store_id === null
    ? true
    : (v.store_id === sort.project_store_id))),
  [sort.project_store_id, employeeList]);

  const termDate = useDateValidation([
    { start: sort.maintenance_date_start, end: sort.maintenance_date_end },
    { start: sort.completion_date_start, end: sort.completion_date_end },
    { start: sort.created_at_start, end: sort.created_at_end },
    { start: sort.updated_at_start, end: sort.updated_at_end },
  ]);

  /* Callback */
  const setState = useCallback((v: Partial<MaintenanceSortState>) => {
    const data = {
      ...cloneDeep(sort),
      ...cloneDeep(v),
    };
    setSort(data);
    // if (!isSearch) {
    // }
  }, [sort, isSearch, getList]);

  const handleClickSearch = useCallback(() => {
    if (termDate.messages.length) {
      termDate.check();
      return;
    }
    dispatch(MapActions.setGpsStatus('out'));
    getList?.(sort);
    dispatch(MaintenanceActions.setSort(cloneDeep(sort)));
  }, [getList, sort, termDate]);

  /* Effect */
  useDidMount(() => {
    dispatch(MasterActions.api.store.getList({}));
    dispatch(MasterActions.api.employee.getList({}));
  });

  useEffect(() => {
    if (isInit) return;
    setState({
      project_store_id: sortState.project_store_id || user.store_id,
      project_employee_id: sortState.project_employee_id || user.id,
    });
    dispatch(MaintenanceActions.setIsInitSort(true));
  }, [user]);

  return (
    <SearchBox
      callback={handleClickSearch}
      callbackClear={() => setState(MaintenanceCollection._initialSortState(user))}
    >
      {/* search_box_body_inner は各画面の共通用 */}
      <div className="search_box_sp_body_inner maintenanceSearchBoxSP">
        <div className="category_wrap">
          <div className="item_wrap">
            <div className="item_label">店舗</div>
            <div className="item_body full_width">
              <Select
                value={sort?.project_store_id}
                onChange={(v) => setState({
                  project_store_id: Number(v),
                  project_employee_id: Number.isNaN(Number(v))
                  || Number(v) === user.store_id
                    ? user.id
                    : NaN,
                })}
                defaultLabel="全て"
                options={pulldown(storeList)}
              />
            </div>
          </div>

          <div className="item_wrap">
            <div className="item_label">担当者</div>
            <div className="item_body full_width">
              <Select
                value={sort?.project_employee_id}
                onChange={(v) => setState({ project_employee_id: Number(v) })}
                defaultLabel="全て"
                options={pulldown(_employeeList)}
              />
            </div>
          </div>
        </div>

        <div className="category_wrap">
          <div className="item_wrap">
            <div className="item_wrap item_date_picker">
              <div className="item_label">メンテナンス日</div>
              <div className="item_body item_schedule wrap">
                <div className="item_schedule__form">
                  <DatePicker
                    date={sort?.maintenance_date_start}
                    onChange={(v) => setState({ maintenance_date_start: v })}
                    term={DateTermValidation([{
                      start: sort.maintenance_date_start, end: sort.maintenance_date_end,
                    }])}
                  />
                </div>
                <div className="item_schedule__form flex_box flex_align_center mt_10">
                  <div className="item_schedule__tilde">〜</div>
                  <div className="item_schedule__form">
                    <DatePicker
                      date={sort?.maintenance_date_end}
                      onChange={(v) => setState({ maintenance_date_end: v })}
                      term={DateTermValidation([{
                        start: sort.maintenance_date_start, end: sort.maintenance_date_end,
                      }])}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="item_wrap item_date_picker item_construction_period">
              <div className="item_label">完工日</div>
              <div className="item_body item_schedule wrap">
                <div className="item_schedule__form">
                  <DatePicker
                    date={sort?.completion_date_start}
                    onChange={(v) => setState({ completion_date_start: v })}
                    term={DateTermValidation([{
                      start: sort.completion_date_start, end: sort.completion_date_end,
                    }])}
                  />
                </div>
                <div className="item_schedule__form flex_box flex_align_center mt_10">
                  <div className="item_schedule__tilde">〜</div>
                  <div className="item_schedule__form">
                    <DatePicker
                      date={sort?.completion_date_end}
                      onChange={(v) => setState({ completion_date_end: v })}
                      term={DateTermValidation([{
                        start: sort.completion_date_start, end: sort.completion_date_end,
                      }])}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="category_wrap">
          <div className="item_wrap">
            <TopLabelInputField
              label="案件名"
              value={sort?.project_name}
              onChange={(e) => setState({ project_name: e.target.value })}
              className="full_width"
            />
          </div>
          <div className="item_wrap">
            <TopLabelInputField
              label="顧客名"
              value={sort?.customer_name}
              onChange={(e) => setState({ customer_name: e.target.value })}
              className="full_width"
            />
          </div>
          <div className="item_wrap tags_form">
            <div className="item_body item_checkbox">
              <RightLabelCheckbox
                checked={!!sort?.is_muko}
                label="無効情報も含む"
                onChange={() => {
                  setState({ is_muko: sort?.is_muko ? 0 : 1 });
                }}
                className="single_column"
              />
            </div>
          </div>

          <div className="item_wrap">
            <div className="item_label">対応区分</div>
            <div className="item_body item_category">
              <Radio
                label="すべて"
                name="supported_kubun"
                checked={sort?.supported_kubun === 2}
                onChange={() => { setState({ supported_kubun: 2 }); }}
              />
              <Radio
                label="未対応"
                name="supported_kubun"
                checked={!sort?.supported_kubun}
                onChange={() => { setState({ supported_kubun: 0 }); }}
              />
              <Radio
                label="対応済"
                name="supported_kubun"
                checked={sort?.supported_kubun === 1}
                onChange={() => { setState({ supported_kubun: 1 }); }}
              />
            </div>
          </div>
          <div className="item_wrap">
            <TopLabelInputField
              label="文字列検索"
              value={sort?.word}
              onChange={(e) => setState({ word: e.target.value })}
              className="full_width"
            />
          </div>
        </div>
        <div className="category_wrap">
          <div className="item_wrap">
            <div className="item_label">登録者</div>
            <div className="item_body item_madori">
              <Select
                defaultLabel="全て"
                className="fixed"
                value={sort.created_employee_id}
                onChange={(v) => setState({ created_employee_id: Number(v) })}
                options={pulldown(employeeList)}
              />

            </div>
          </div>
          <div className="item_wrap">
            <div className="item_label">登録日</div>
            <div className="item_body item_schedule wrap">
              <div className="item_schedule__form">
                <DatePicker
                  date={sort.created_at_start}
                  onChange={(v) => setState({ created_at_start: v })}
                  type="YYYY/MM/DD"
                  term={DateTermValidation([
                    { start: sort.created_at_start, end: sort.created_at_end },
                  ])}
                />
              </div>
              <div className="item_schedule__form flex_box flex_align_center mt_10">
                <div className="item_schedule__tilde">〜</div>
                <DatePicker
                  date={sort.created_at_end}
                  onChange={(v) => setState({ created_at_end: v })}
                  type="YYYY/MM/DD"
                  term={DateTermValidation([
                    { start: sort.created_at_start, end: sort.created_at_end },
                  ])}
                />
              </div>
            </div>
          </div>
          <div className="item_wrap">
            <div className="item_label">更新者</div>
            <div className="item_body item_madori">
              <Select
                defaultLabel="全て"
                className="fixed"
                value={sort.updated_employee_id}
                onChange={(v) => setState({ updated_employee_id: Number(v) })}
                options={pulldown(employeeList)}
              />
            </div>
          </div>
          <div className="item_wrap">
            <div className="item_label">更新日</div>
            <div className="item_body item_schedule wrap">
              <div className="item_schedule__form">
                <DatePicker
                  date={sort.updated_at_start}
                  onChange={(v) => setState({ updated_at_start: v })}
                  type="YYYY/MM/DD"
                  term={DateTermValidation([
                    { start: sort.updated_at_start, end: sort.updated_at_end },
                  ])}
                />
              </div>
              <div className="item_schedule__form flex_box flex_align_center mt_10">
                <div className="item_schedule__tilde">〜</div>
                <DatePicker
                  date={sort.updated_at_end}
                  onChange={(v) => setState({ updated_at_end: v })}
                  type="YYYY/MM/DD"
                  term={DateTermValidation([
                    { start: sort.updated_at_start, end: sort.updated_at_end },
                  ])}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </SearchBox>
  );
};
