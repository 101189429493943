import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useMemo, useState } from 'react';
import { MasterBody, MasterGetListParam } from './body/master-body';
import { MasterActions } from '../../../../../redux/master/master.action';
import { DialogActions } from '../../../../../redux/dialog/dialog.action';
import { State } from '../../../../../redux/root.reducer';
import { MasterCollection } from '../master.collection';
import { CustomerRankMasterEditDialog } from '../edit-dialogs/rank/customer-rank-master-edit-dialog/customer-rank-master-edit-dialog';

export const MasterCustomerRankBody = () => {
  /* Hook */
  const list = useSelector((state:State) => state.master.customerKojiRankList);
  const dispatch = useDispatch();

  /* State */
  const [isSort, setIsSort] = useState(false);
  const [isMuko, setIsMuko] = useState(false);
  const [orderSort, setOrderSort] = useState<MasterGetListParam>({ highlow: 0, sort_by: 0 });

  /* Callback */
  const getList = useCallback((v: MasterGetListParam) => {
    const sort_by = v.sort_by ? v.sort_by - 1 : 0;
    dispatch(MasterActions.api.customerKojiRank.getList(
      { sort_by, highlow: v.highlow, is_muko: 1 },
    ));
    setOrderSort(v);
  }, []);

  const openEditDialog = useCallback((id?: number) => {
    dispatch(DialogActions.push({
      title: '顧客ランク（工事金額） 編集／追加',
      element: <CustomerRankMasterEditDialog
        id={id}
        callback={() => {
          getList(orderSort);
          console.log('kokokokokokokokoo');
        }}
      />,
    }));
  }, [getList, orderSort]);

  const tableList = useMemo(() => {
    const filter = (isMuko || isSort) ? list : list.filter((v) => v.valid_flag);
    return filter.map((v) => ([
      v.order,
      v.internal_id,
      v.name,
      v.abbreviation,
      v.amount.toLocaleString(),
      <>{v.background_color} <div className="color_box ml_5" style={{ backgroundColor: v.background_color }} /></>,
      <>{v.text_color} <div className="color_box ml_5" style={{ backgroundColor: v.text_color }} /></>,
      v.valid_flag ? <i className="far fa-circle" /> : '',
    ]));
  }, [list, isMuko, isSort]);

  return (
    <MasterBody
      header={MasterCollection.customerRankMasterHeader}
      rowDataList={list}
      list={tableList}
      callbackEdit={openEditDialog}
      callbackGetList={getList}
      callbackIsMuko={setIsMuko}
      callbackIsSort={setIsSort}
      sortIndex={10}
      defaultOrder={0}
      isNoEdit
      tableOption={{
        stringWidth: [
          { index: 0, width: 50 }, // 編集
          // { index: 1, width: 50 }, // 順位
          // { index: 2, width: 50 }, // ID
          // { index: 3, width: 50 }, // 顧客ランク（工事金額）名
          // { index: 4, width: 50 }, // 略称表示
          // { index: 5, width: 100 }, // 工事金額
          // { index: 6, width: 100 }, // 背景色
          // { index: 7, width: 100 }, // 文字色
          // { index: 8, width: 100 }, // 有効フラグ
        ],
        tdAlign: [
          { index: 0, align: 'center' },
          { index: 1, align: 'center' },
          { index: 2, align: 'center' },
          { index: 3, align: 'center' },
          { index: 4, align: 'center' },
          { index: 5, align: 'right' },
          { index: 6, align: 'center' },
          { index: 7, align: 'center' },
          { index: 8, align: 'center' },
        ],
      }}
    />
  );
};
