import { useDispatch, useSelector } from 'react-redux';
import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { MasterBody, MasterGetListParam } from './body/master-body';
import { MasterActions } from '../../../../../redux/master/master.action';
import { DialogActions } from '../../../../../redux/dialog/dialog.action';
import { State } from '../../../../../redux/root.reducer';
import { MasterCollection } from '../master.collection';
import { TaxMasterEditDialog } from '../edit-dialogs/company/tax-master-edit-dialog/tax-master-edit-dialog';

export const MasterTaxBody = () => {
  /* Hook */
  const list = useSelector((state:State) => state.master.taxList);
  const dispatch = useDispatch();
  /* State */
  const [orderSort, setOrderSort] = useState<MasterGetListParam>({ highlow: 0, sort_by: 0 });
  const [isMuko, setIsMuko] = useState(false);

  /* Callback */
  const getList = useCallback((v: MasterGetListParam) => {
    dispatch(MasterActions.api.tax.getList({ ...v, is_muko: 1 }));
    setOrderSort(v);
  }, [isMuko]);

  // eslint-disable-next-line
  const openEditDialog = useCallback((id?: number) => {
    dispatch(DialogActions.push({
      title: '消費税 編集／追加',
      element: <TaxMasterEditDialog id={id} callback={() => getList(orderSort)} />,
    }));
  }, [getList, orderSort]);

  const tableList = useMemo(() => {
    const filter = (isMuko) ? list : list.filter((v) => v.valid_flag);
    console.log([isMuko, filter]);

    return filter.map((v) => ([
      v.internal_id,
      v.start_date,
      v.tax_rate,
      v.valid_flag ? <i className="far fa-circle" /> : '',
    ]));
  }, [list, isMuko]);

  return (
    <MasterBody
      header={MasterCollection.taxMasterHeader}
      rowDataList={list}
      list={tableList}
      callbackEdit={openEditDialog}
      callbackGetList={getList}
      callbackIsMuko={(v) => setIsMuko(v)}
      defaultOrder={0}
      isNoEdit
      tableOption={{
        stringWidth: [
          { index: 0, width: 50 }, // 編集
          // { index: 1, width: 50 }, // ID
          // { index: 2, width: 50 }, // 適用開始日
          // { index: 3, width: 50 }, // 消費税率
          // { index: 4, width: 100 }, // 有効フラグ
        ],
        tdAlign: [
          { index: 0, align: 'center' },
          { index: 1, align: 'center' },
          { index: 2, align: 'center' },
          { index: 3, align: 'center' },
          { index: 4, align: 'center' },
        ],
      }}
    />
  );
};
