import {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { cloneDeep } from 'lodash';
import NewWindow from 'react-new-window';
import { useDispatch, useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';
import { UserAgent } from '../../../utilities/user-agent';
import { DefaultButton, DefaultInput, SearchBtn } from './route-dialog.component';
import { Button } from '../../ui/button/button';
import { SystemActions } from '../../../redux/system/system.action';
import { RouteDialogCollection as RDC } from './route-dialog.collection';
import { DialogActions } from '../../../redux/dialog/dialog.action';
import { StepPreviewDialog } from './step-preview-dialog/step-preview-dialog';
import { RouteModel } from '../../../model/map/route.mode';
import { MapActions } from '../../../redux/map/map.action';
import { State } from '../../../redux/root.reducer';
import { RouteInfo } from '../../../type/map/map.type';
import './route-dialog.scss';
import { BottomBorderButton } from '../../ui/button/bottom-border/bottom-border-button';
import { SetSelectedClass } from '../../../utilities/set-selected-class';
import iconBicycle, { ReactComponent as IconBicycle } from '../../../asset/images/icon/bicycle-solid.svg';
import iconCar, { ReactComponent as IconCar } from '../../../asset/images/icon/car-solid.svg';
import iconWalking, { ReactComponent as IconWalking } from '../../../asset/images/icon/walking-solid.svg';
import iconExchange from '../../../asset/images/icon/exchange-alt-solid.svg';
import { RightLabelCheckbox } from '../../ui/checkbox/right-label-checkbox/right-label-checkbox';
import Right from '../../../asset/images/maneuver/roundabout-right.svg';
import { AnotherWindow } from '../../ui/another-window/another-window';
import { LeftIconButton } from '../../ui/button/left-icon-button/left-icon-button';
import { MapCollection } from '../../../collection/map/map.collection';

type Props = {
  type: 'customer' | 'project';
  start?: string;
  destination?: string;
  callback: () => void;
}

export const RouteDialog = (props: Props) => {
  const {
    // eslint-disable-next-line
    type,
    start: _start,
    destination: _destination,
    callback,
  } = props;

  // eslint-disable-next-line
  const iconList = [iconWalking, iconBicycle, iconCar];
  const iconComponentList = [<IconWalking />, <IconBicycle />, <IconCar />];
  const dispatch = useDispatch();
  const routeInfo = useSelector((state: State) => state.map.routeInfo, isEqual);
  const humanPos = useSelector((state: State) => state.map.humanPos, isEqual);

  /* State */
  const [mode, setMode] = useState<globalThis.google.maps.TravelMode>(
    globalThis.google.maps.TravelMode.WALKING,
  );
  const [previewDisabled, setPreviewDisabled] = useState(true);
  const [legsData, setLegsData] = useState<globalThis.google.maps.DirectionsLeg[]>([]);
  const [start, setStart] = useState('');
  const [showStart, setShowStart] = useState('');
  const [wayPoints, setWayPoints] = useState(['', '', '', '', '', '']);
  const [destination, setDestination] = useState('');
  const [activeIndex, setActiveIndex] = useState(0);
  // テスト用
  // const [start, setStart] = useState('東京都台東区浅草橋');
  // const [wayPoints, setWayPoints] = useState(['スカイツリー', '', '', '', '', '']);
  // const [destination, setDestination] = useState('大阪府大阪市北区西天満2-3-5');

  // eslint-disable-next-line
  const [startAnaly, setStartAnaly] = useState(RDC.initialTotalAnaly());
  const [wayPointsAnaly, setWayPointsAnaly] = useState(new Array(6).fill(RDC.initialAnaly()));
  const [totalAnaly, setTotalAnaly] = useState(RDC.initialTotalAnaly());
  const [optimize, setOptimize] = useState(false);
  const [anotherRef, setAnotherRef] = useState<any>(undefined);

  /* Ref */
  const headerEle = useRef<HTMLDivElement>(null);

  const directionRef = useRef<HTMLImageElement>(null);

  const right = useMemo(() => (
    <img src={Right} alt="right" ref={directionRef} />
  ), [Right]);

  /* Callback */
  /* 初期化 */
  const initialize = useCallback(() => {
    setLegsData([]);
    setStartAnaly(RDC.initialTotalAnaly());
    setWayPointsAnaly(cloneDeep(new Array(6).fill(RDC.initialAnaly())));
    setTotalAnaly(RDC.initialTotalAnaly());
  }, []);

  /* 経由地Input */
  const changePoints = useCallback((v:string, index: number) => {
    wayPoints[index] = v;
    setWayPoints(cloneDeep(wayPoints));
    initialize();
  }, [wayPoints, initialize]);

  /* 再計算 */
  const reCalc = useCallback((
    param?:RouteInfo, _mode?: globalThis.google.maps.TravelMode,
  ) => {
    initialize();

    // 送るWayPoints
    const sendPoint: { location: string; }[] = [];
    wayPoints.forEach((v) => {
      if (v) {
        sendPoint.push({ location: v });
      }
    });

    // API処理
    // console.log(param);

    if (!param) {
      try {
        dispatch(SystemActions.isLoading(true));
        new globalThis.google.maps.DirectionsService().route({
          origin: start,
          destination,
          travelMode: _mode ?? mode,
          optimizeWaypoints: optimize,
          waypoints: sendPoint.length
            ? sendPoint
            : undefined,
        }, ((res, status) => {
          // 成功結果判定
          if (status === globalThis.google.maps.DirectionsStatus.OK && res) {
            const { routes } = res;
            const route = cloneDeep(routes[0]);
            const { legs } = route;
            if (!legs) {
              dispatch(SystemActions.isLoading(false));
              return;
            }

            setLegsData(cloneDeep(legs));

            // 経由地があった場合
            if (legs.length > 1) {
              legs.forEach((v, i) => {
                if (i) {
                  wayPointsAnaly[i - 1] = cloneDeep({
                    duration: v.duration?.text || '',
                    distance: v.distance?.text || '',
                  });
                } else {
                  setStartAnaly(cloneDeep({
                    duration: v.duration?.text || '',
                    distance: v.distance?.text || '',
                  }));
                }
              });
              setWayPointsAnaly(cloneDeep(wayPointsAnaly));
              setTotalAnaly(RouteModel.totalDurationDistance(legs));
              if (optimize) {
                const calcList = cloneDeep(legs.map(
                  (v) => {
                    const split = v.end_address.split(' ');
                    const result = split.filter((_, i) => i !== 0).join(' ');
                    return result;
                  },
                )).filter((_, i) => i !== legs.length - 1);
                const emptyList = ['', '', '', '', '', ''].splice(
                  0, 6 - calcList.length,
                );
                setWayPoints(calcList.concat(emptyList));
                // console.log(calcList.concat(emptyList));
              }
            } else {
              setStartAnaly(cloneDeep({
                duration: legs[0].duration?.text || '',
                distance: legs[0].distance?.text || '',
              }));
              setTotalAnaly(RouteModel.totalDurationDistance(legs));
            }
            setPreviewDisabled(false);
            dispatch(SystemActions.isLoading(false));
          } else {
            const _res = cloneDeep(res) as any;
            if (
              _res?.request?.travelMode === 'BICYCLING'
              && _res?.status === 'ZERO_RESULTS'
            ) {
              dispatch(DialogActions.pushMessage({
                title: 'ルート検索',
                message: ['以下の地域以外は、自転車のルート表示に対応していません。', '', ...MapCollection.unSearchPrefectures],
                callback: () => {
                  if (_mode) {
                    setMode(globalThis.google.maps.TravelMode.WALKING);
                    setActiveIndex(0);
                    if (!isEqual(totalAnaly, RDC.initialTotalAnaly())) {
                      reCalc(undefined, globalThis.google.maps.TravelMode.WALKING);
                    }
                  }
                },
              }));
              return;
            }

            dispatch(DialogActions.pushMessage({
              title: 'ルート検索',
              message: ['ルートの検索に失敗しました'],
            }));
          }
        }));
      } catch (error) {
        dispatch(DialogActions.pushMessage({
          title: 'ルート検索',
          message: ['ルートの検索に失敗しました'],
        }));
      } finally {
        dispatch(SystemActions.isLoading(false));
      }
      return;
    }

    const legs = param?.legs || [];
    setLegsData(cloneDeep(legs));
    if (legs.length > 1) {
      legs.forEach((v, i) => {
        if (i) {
          wayPointsAnaly[i - 1] = cloneDeep({
            duration: v.duration?.text || '',
            distance: v.distance?.text || '',
          });
        } else {
          setStartAnaly(cloneDeep({
            duration: v.duration?.text || '',
            distance: v.distance?.text || '',
          }));
        }
      });
      setWayPointsAnaly(cloneDeep(wayPointsAnaly));
      setTotalAnaly(RouteModel.totalDurationDistance(legs));
      setTotalAnaly({
        duration: legs[0].duration?.text || '',
        distance: legs[0].distance?.text || '',
      });
      setPreviewDisabled(false);
    } else if (legs.length === 1) {
      setStartAnaly(cloneDeep({
        duration: legs[0].duration?.text || '',
        distance: legs[0].distance?.text || '',
      }));
      setTotalAnaly({
        duration: legs[0].duration?.text || '',
        distance: legs[0].distance?.text || '',
      });
      setPreviewDisabled(false);
    }
  }, [
    mode,
    start,
    wayPoints,
    destination,
    wayPointsAnaly,
    totalAnaly,
    optimize,
    headerEle,
  ]);

  /* モードの変更 */
  const changeMode = useCallback((i:number) => {
    switch (i) {
      case 0:
        setMode(globalThis.google.maps.TravelMode.WALKING);
        if (!isEqual(totalAnaly, RDC.initialTotalAnaly())) {
          reCalc(undefined, globalThis.google.maps.TravelMode.WALKING);
        }
        break;
      case 1:
        setMode(globalThis.google.maps.TravelMode.BICYCLING);
        if (!isEqual(totalAnaly, RDC.initialTotalAnaly())) {
          reCalc(undefined, globalThis.google.maps.TravelMode.BICYCLING);
        }
        break;
      case 2:
        setMode(globalThis.google.maps.TravelMode.DRIVING);
        if (!isEqual(totalAnaly, RDC.initialTotalAnaly())) {
          reCalc(undefined, globalThis.google.maps.TravelMode.DRIVING);
        }
        break;
      default:
        break;
    }
  }, [totalAnaly, reCalc]);

  /* Input Value 入れ替え */
  const changeUpnDown = useCallback((i: number) => {
    const stay = wayPoints[i];
    wayPoints[i] = wayPoints[i + 1];
    wayPoints[i + 1] = stay;
    setWayPoints(cloneDeep(wayPoints));
  }, [wayPoints]);

  /* 道順 */
  const stepPreview = useCallback((legIndex: number) => {
    const startV = legIndex ? wayPoints[legIndex - 1] : start;

    let endV = '';
    if (!legIndex) {
      if (wayPoints[0]) {
        // eslint-disable-next-line prefer-destructuring
        endV = wayPoints[0];
      } else if (destination) {
        endV = destination;
      }
    } else if (wayPoints[legIndex]) {
      endV = wayPoints[legIndex];
    } else if (destination) {
      endV = destination;
    }

    dispatch(DialogActions.push({
      title: '道順',
      element: <StepPreviewDialog
        startValue={startV}
        endValue={endV}
        data={legsData[legIndex]}
        legIndex={legIndex}
      />,
    }));
  }, [legsData, wayPoints, start, destination]);

  const handleClickShow = useCallback(() => {
    const sendPoint:string[] = [];
    wayPoints.forEach((v) => {
      if (v) {
        sendPoint.push(v);
      }
    });
    dispatch(MapActions.setRouteInfo({
      travelMode: mode,
      start,
      wayPoints: cloneDeep(sendPoint),
      destination,
      legs: cloneDeep(legsData),
    }));
    // dispatch(DialogActions.pop());
    callback();
  }, [callback, start, wayPoints, destination, legsData, mode, optimize]);

  /* State */
  useEffect(() => {
    if (routeInfo) {
      setStart(routeInfo.start);
      setLegsData(cloneDeep(routeInfo.legs));
      setDestination(routeInfo.destination);
      routeInfo.wayPoints.forEach((v, i) => {
        wayPoints[i] = v;
      });
      setWayPoints(cloneDeep(wayPoints));
      setMode(routeInfo.travelMode);
      switch (routeInfo.travelMode) {
        case globalThis.google.maps.TravelMode.WALKING:
          setActiveIndex(0);
          break;
        case globalThis.google.maps.TravelMode.BICYCLING:
          setActiveIndex(1);
          break;
        case globalThis.google.maps.TravelMode.DRIVING:
          setActiveIndex(2);
          break;
        default: setActiveIndex(0);
      }
      reCalc(routeInfo);
      const startPoint = routeInfo.start.split(', ').map((v) => Number(v));
      if (!Number.isNaN(startPoint[0]) && !Number.isNaN(startPoint[1])) {
        setShowStart('出発地');
      }
      dispatch(MapActions.setRouteInfo(null));
      return;
    }

    // 出発地点
    if (_start) {
      setShowStart(_start);
      setStart('現在地');
    } else if (humanPos) {
      setStart(
        `${humanPos.lat}, ${humanPos.lng}`,
      );
      setShowStart('現在地');
    }

    // 目的地
    if (_destination) {
      setDestination(_destination);
    }
  }, []);

  return (
    UserAgent === 'pc'
      ? (
        /* ============================= PC側 ============================= */
        <div className={`route_dialog ${UserAgent} flex_box`}>
          <div className="route_dialog__body flex_box">

            <div className="route_dialog__body__header flex_box">
              {/* 方法選択 */}
              <div
                className="ui attached tabular menu"
                ref={headerEle}
              >
                {RDC.travelMode.map((v, i) => (
                  <button
                    className={`item ${i === activeIndex ? 'selected' : ''}`}
                    key={v + i}
                    onClick={(e) => {
                      changeMode(i);
                      setActiveIndex(i);
                      // SetSelectedClass(e.currentTarget, headerEle.current);
                    }}
                  >
                    <>
                      {iconComponentList[i]}
                      <span>{v}</span>
                    </>
                  </button>
                ))}

                <div className="route_dialog__body__header__time flex_box">
                  <div>
                    総移の合計時間（総距離）：
                    <span className="strong base_color">{totalAnaly.duration}（{totalAnaly.distance}）</span>
                  </div>
                  <DefaultButton
                    label="計算"
                    onClick={() => reCalc()}
                    disabled={!start || !destination}
                    icon={<i className="far fa-clock" />}
                    className="icon_btn"
                    size="sm"
                  />
                  <RightLabelCheckbox
                    label="ルートを最適化する"
                    className="ml_20"
                    checked={optimize}
                    onChange={() => setOptimize(!optimize)}
                  />
                </div>
              </div>
            </div>

            <div className="route_dialog__body__contents">
              {/* 出発地 */}
              <div className="item_wrap">
                <div className="item_box">
                  <div className="item_head">出発地</div>
                  <DefaultInput
                    label=""
                    value={showStart || start}
                    justChange={() => {
                      initialize();
                      setPreviewDisabled(true);
                    }}
                    onChange={(v) => {
                      if (showStart) {
                        setStart('');
                        setShowStart('');
                      } else {
                        setStart(v);
                      }
                    }}
                  />
                  <SearchBtn
                    type="customer"
                    onClick={(v) => {
                      setShowStart('');
                      setStart(v);
                    }}
                    size="sm"
                  />
                  <SearchBtn
                    type="project"
                    onClick={(v) => {
                      setShowStart('');
                      setStart(v);
                    }}
                    size="sm"
                  />
                  <SearchBtn
                    type="address"
                    onClick={(v) => {
                      setShowStart('');
                      setStart(v);
                    }}
                    size="sm"
                  />
                </div>

                <div className="item_box">
                  <Button
                    disabled
                    onClick={() => {}}
                    className="invisible"
                    size="sm"
                  >
                    <img src={iconExchange} alt="" />
                  </Button>
                  <DefaultButton
                    size="sm"
                    label="道順"
                    onClick={() => stepPreview(0)}
                    disabled={
                    ((!start || !wayPoints[0])
                      && (!start || !destination))
                    || previewDisabled
                  }
                    color="white"
                    className="route"
                  />
                  {/* <AnotherWindow
                    open={st}
                    testStr={directionRef?.current || 'nai'}
                    title="test"
                    refMount={(e: any) => {
                      console.log(e);
                      setAnotherRef(e);
                    }}
                  >
                    <StepPreviewDialog
                      data={legsData[0]}
                      img={directionRef?.current?.innerHTML}
                      on={() => anotherRef.current?.window.alert('test')}
                    />
                  </AnotherWindow> */}
                  <div className="time_distance">
                    {`${startAnaly.duration}（${startAnaly.distance}）`}
                  </div>
                </div>
              </div>

              {/* 経由地 */}
              {wayPoints.map((v, i, ary) => {
                // input・search
                const disabled = Boolean(i && !ary[i - 1]);
                // 上下ボタン
                const upnDownDisabled = !v || !ary[i + 1];
                // 道順
                const preDisabled = !((v && ary[i + 1]) || (v && destination));
                return (
                  <div className="item_wrap" key={v + i}>
                    <div className="item_box">
                      <DefaultInput
                        label={`経由地${i + 1}`}
                        value={v}
                        onChange={(val) => {
                          changePoints(val, i);
                        }}
                        justChange={() => {
                          initialize();
                          setPreviewDisabled(true);
                        }}
                        disabled={disabled}
                      />
                      <SearchBtn
                        type="customer"
                        disabled={disabled}
                        onClick={(val) => changePoints(val, i)}
                        size="sm"
                      />
                      <SearchBtn
                        type="project"
                        disabled={disabled}
                        onClick={(val) => changePoints(val, i)}
                        size="sm"
                      />
                      <SearchBtn
                        type="address"
                        onClick={(val) => changePoints(val, i)}
                        size="sm"
                        disabled={disabled}
                      />
                    </div>

                    <div className="item_box">
                      <Button
                        disabled={upnDownDisabled}
                        onClick={() => changeUpnDown(i)}
                        className="exchange"
                        size="sm"
                      >
                        <img src={iconExchange} alt="" />
                      </Button>
                      <DefaultButton
                        label="道順"
                        onClick={() => stepPreview(i + 1)}
                        disabled={preDisabled || previewDisabled}
                        color="white"
                        size="sm"
                        className="route"
                      />
                      <div className="time_distance">
                        {`${wayPointsAnaly[i].duration}（${wayPointsAnaly[i].distance}）`}
                      </div>
                    </div>
                  </div>
                );
              })}

              {/* 目的地 */}
              <div className="item_wrap">
                <div className="item_box">
                  <DefaultInput
                    label="目的地"
                    value={destination}
                    onChange={setDestination}
                    justChange={() => {
                      initialize();
                      setPreviewDisabled(true);
                    }}
                  />
                  <SearchBtn
                    type="customer"
                    onClick={setDestination}
                    size="sm"
                  />
                  <SearchBtn
                    type="project"
                    onClick={setDestination}
                    size="sm"
                  />
                  <SearchBtn
                    type="address"
                    onClick={setDestination}
                    size="sm"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="base_footer">
            <LeftIconButton
              label="ルート表示"
              fontAwesomeClass="fas fa-route"
              className="ml_10"
              size="sm"
              color="primary"
              onClick={handleClickShow}
              disabled={!start || !destination}
            />
          </div>
        </div>
      ) : (
        /* ============================= SP側 ============================= */
        <div className={`route_dialog ${UserAgent}`}>

          {/* 移動手段 */}
          <div
            className="route_dialog__header"
            ref={headerEle}
          >
            {RDC.travelMode.map((v, i) => (
              <BottomBorderButton
                key={v + i}
                label={v}
                icon={<img src={iconList[i]} alt="" />}
                onClick={(e) => {
                  changeMode(i);
                  setActiveIndex(i);
                  // SetSelectedClass(e.currentTarget, headerEle.current);
                }}
                selected={i === activeIndex}
              />
            ))}
          </div>

          {/* 出発地 */}
          <div className="route_dialog__body">
            <div className="route_dialog__body__route start">
              <div className="route_dialog__body__route__header">
                <span>出発地</span>
                <SearchBtn
                  type="customer"
                  onClick={(v) => {
                    setShowStart('');
                    setStart(v);
                  }}
                  icon={<i className="fas fa-search" />}
                  className="icon_btn"
                  isSP
                />
                <SearchBtn
                  type="project"
                  onClick={(v) => {
                    setShowStart('');
                    setStart(v);
                  }}
                  icon={<i className="fas fa-search" />}
                  className="icon_btn"
                  isSP
                />
                <SearchBtn
                  type="address"
                  onClick={(v) => {
                    setShowStart('');
                    setStart(v);
                  }}
                  icon={<i className="fa fa-map-marked-alt" />}
                  className="map_search"
                  isSP
                />
              </div>

              <div className="route_dialog__body__route__body">
                <DefaultInput
                  value={showStart || start}
                  onChange={setStart}
                  justChange={() => {
                    initialize();
                    setPreviewDisabled(true);
                  }}
                />
                <div>
                  <Button
                    disabled
                    onClick={() => {}}
                    className="invisible"
                  >
                    <img src={iconExchange} alt="" />
                  </Button>
                  <DefaultButton
                    label="道順"
                    onClick={() => stepPreview(0)}
                    disabled={
                    ((!start || !wayPoints[0])
                      && (!start || !destination))
                    || previewDisabled
                  }
                    color="white"
                    className="route"
                  />
                  <div className="dot_border" />
                  <div>
                    {`${startAnaly.duration}（${startAnaly.distance}）`}
                  </div>
                </div>
              </div>
            </div>

            {/* 経由地 */}
            {wayPoints.map((v, i, ary) => {
            // input・search
              const disabled = Boolean(i && !ary[i - 1]);
              // 上下ボタン
              const upnDownDisabled = !v || !ary[i + 1];
              // 道順
              const preDisabled = !((v && ary[i + 1]) || (v && destination));
              return (
                <div className="route_dialog__body__route" key={v + i}>
                  <div className="route_dialog__body__route__header">
                    <span>{`経由地${i + 1}`}</span>
                    <SearchBtn
                      type="customer"
                      disabled={disabled}
                      onClick={(val) => changePoints(val, i)}
                      icon={<i className="fas fa-search" />}
                      className="icon_btn"
                      isSP
                    />
                    <SearchBtn
                      type="project"
                      disabled={disabled}
                      onClick={(val) => changePoints(val, i)}
                      icon={<i className="fas fa-search" />}
                      className="icon_btn"
                      isSP
                    />
                    <SearchBtn
                      type="address"
                      onClick={(val) => changePoints(val, i)}
                      icon={<i className="fa fa-map-marked-alt" />}
                      className="map_search"
                      disabled={disabled}
                      isSP
                    />
                  </div>

                  <div className="route_dialog__body__route__body">
                    <DefaultInput
                      value={v}
                      onChange={(val) => changePoints(val, i)}
                      disabled={disabled}
                      justChange={() => {
                        initialize();
                        setPreviewDisabled(true);
                      }}
                    />
                    <div>
                      <Button
                        disabled={upnDownDisabled}
                        onClick={() => changeUpnDown(i)}
                        className="exchange"
                      >
                        <img src={iconExchange} alt="" />
                      </Button>
                      <div>
                        <DefaultButton
                          label="道順"
                          onClick={() => stepPreview(i + 1)}
                          disabled={preDisabled || previewDisabled}
                          color="white"
                          className="route"
                        />
                      </div>
                      <div className="dot_border" />
                      <div>
                        {`${wayPointsAnaly[i].duration}（${wayPointsAnaly[i].distance}）`}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}

            {/* 目的地 */}
            <div className="route_dialog__body__route end">
              <div className="route_dialog__body__route__header">
                <span>目的地</span>
                <SearchBtn
                  type={type}
                  onClick={setDestination}
                  icon={<i className="fas fa-search" />}
                  className="icon_btn"
                  isSP
                />
                <SearchBtn
                  type="project"
                  onClick={setDestination}
                  icon={<i className="fas fa-search" />}
                  className="icon_btn"
                  isSP
                />
                <SearchBtn
                  type="address"
                  onClick={setDestination}
                  icon={<i className="fa fa-map-marked-alt" />}
                  className="map_search"
                  isSP
                />
              </div>
              <div className="route_dialog__body__route__body">
                <DefaultInput
                  value={destination}
                  onChange={setDestination}
                  justChange={() => {
                    initialize();
                    setPreviewDisabled(true);
                  }}
                />
              </div>
            </div>
          </div>

          <div className="route_dialog__footer">
            <div>
              <div>移動の合計時間：<span className="strong base_color">{totalAnaly.duration}</span></div>
              <div>移動の総距離：<span className="strong base_color">{totalAnaly.distance}</span></div>
              <RightLabelCheckbox
                label="ルートを最適化する"
                checked={optimize}
                onChange={() => setOptimize(!optimize)}
              />
            </div>

            <div>
              <DefaultButton
                label="計算"
                onClick={() => reCalc()}
                disabled={!start || !destination}
                icon={<i className="far fa-clock" />}
                className="icon_btn"
              />
              <LeftIconButton
                label="ﾙｰﾄ表示"
                fontAwesomeClass="fas fa-route"
                onClick={() => handleClickShow()}
                disabled={!start || !destination}
                color="primary"
              />
            </div>
          </div>
        </div>
      )
  );
};
