import { reducerWithInitialState } from 'typescript-fsa-reducers';
import * as lodash from 'lodash';
import { cloneDeep } from 'lodash';
import { ProjectActions } from './project.action';
import { Project, ProjectListType, ProjectSortState } from '../../type/project/project.type';
import { ProjectCollection } from '../../collection/project/project.collection';
import { concatList } from '../root.reducer';

export type ProjectState = {
  project: Project | null;
  list: ProjectListType[] | null;
  routeList: ProjectListType[] | null;
  listHitCount: number;
  sort: ProjectSortState ;
  detailSort: ProjectSortState ;
};

const initialState: ProjectState = {
  project: null,
  list: null,
  routeList: null,
  listHitCount: 0,
  sort: ProjectCollection.initialSortState(),
  detailSort: ProjectCollection.initialSortState(),
};

export const ProjectReducer = reducerWithInitialState<ProjectState>(initialState)
  .case(ProjectActions.setProject, (state, payload) => ({
    ...state,
    project: lodash.cloneDeep(payload),
  }))
  .case(ProjectActions.setList, (state, payload) => ({
    ...state,
    list: lodash.cloneDeep(payload),
  }))
  .case(ProjectActions.setRouteList, (state, payload) => ({
    ...state,
    routeList: lodash.cloneDeep(payload),
  }))
  .case(ProjectActions.setConcatList, (state, payload) => ({
    ...state,
    list: cloneDeep(concatList(state.list, payload)),
  }))
  .case(ProjectActions.setListCount, (state, payload) => ({
    ...state,
    listHitCount: lodash.cloneDeep(payload),
  }))
  .case(ProjectActions.setSort, (state, payload) => ({
    ...state,
    sort: payload ? lodash.cloneDeep({
      ...state.sort,
      ...payload,
    }) : ProjectCollection.initialSortState(),
  }))
  .case(ProjectActions.setDetailSort, (state, payload) => ({
    ...state,
    detailSort: payload ? lodash.cloneDeep(payload) : ProjectCollection.initialSortState(),
  }))
  .case(ProjectActions.setInitSort, (state, payload) => ({
    ...state,
    sort: ProjectCollection.initialSortState(payload),
  }))
  .case(ProjectActions.setDetailInitSort, (state, payload) => ({
    ...state,
    detailSort: ProjectCollection.initialSortState(payload),
  }))
  .case(ProjectActions.resetState, () => initialState)
  .default((state) => state);
