/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { takeEvery } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { ChangeCompanyActions } from './changecompany-action';
import { ApiContractedChangeCompanyPost } from './api-contracted-changecompany';
import { Store } from '../store';
import { DialogActions } from '../dialog/dialog.action';
import { postHandle } from '../root.saga';
import { RoutingPath } from '../../routes/routing-pass';

function* tryContractedChangeCompanyPost(
  action: ReturnType<typeof ChangeCompanyActions.api.contractedChangeCompany.post>,
) {
  const {
    param, onSuccess, onError, ignorePop,
  } = action.payload;
  yield postHandle({
    // title: '参照する契約会社の変更完了',
    api: new ApiContractedChangeCompanyPost(param),
    noMessage: ignorePop,
    editableTitle: '参照する契約会社の変更完了',
    popMessage: ['参照する契約会社を変更しました。'],
    onSuccess: () => {
      if (onSuccess) onSuccess();
      Store.dispatch(DialogActions.pop());
    },
    onError: () => {
      if (onError)onError();
    },
  });
}

export function* ChangeCompanySaga() {
  yield takeEvery(
    ChangeCompanyActions.api.contractedChangeCompany.post, tryContractedChangeCompanyPost,
  );
}
