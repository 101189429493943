import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { goBack, push } from 'connected-react-router';
import { DialogActions } from '../../../../redux/dialog/dialog.action';
import { MaintenanceActions } from '../../../../redux/maintenance/maintenance.action';
import { State } from '../../../../redux/root.reducer';
import { DateFormatter } from '../../../../utilities/date-formatter';
import { joinStr } from '../../../../utilities/join-str';
import { LeftIconButton } from '../../../ui/button/left-icon-button/left-icon-button';
import { BasePageSP } from '../base.page.sp';
import { MaintenanceEditSP } from '../maintenance/edit/maintenance-edit.sp';
import { MaintenanceEditDialogTitle } from '../maintenance/edit/maintenance-edit.type';
import { MaintenanceSearchBoxSP } from '../maintenance/search-box/maintenance-search-box.sp';
import './maintenance-detail.sp.scss';
import { IconButton } from '../../../ui/button/icon-button/icon-button';
import { openTel } from '../../../../utilities/open-tel';
import { RoutingPath } from '../../../../routes/routing-pass';
import { useTel } from '../../../../hooks/common/use-common';
import { useAppSelector } from '../../../../hooks/use-redux';
import { SupportHistoryActions } from '../../../../redux/support-history/support-history.action';
import { BreakLine } from '../../../ui/break-line/break-line';
import { Button } from '../../../ui/button/button';
import { isMobilePhone } from '../../../../utilities/check-mobile-phone';
import { openSMS } from '../../../../utilities/open-link';
import { useEditAuthMaintenance } from '../../../../hooks/use-authority';

export const MaintenanceDetailSP = () => {
  /* Hooks */
  const { id } = useParams<{ id: string; }>();
  const maintenanceInfo = useSelector((state: State) => state.maintenance.maintenance);
  const { store_id, id: employee_id } = useAppSelector((v) => v.user);
  const dispatch = useDispatch();
  const disabled = !useEditAuthMaintenance(maintenanceInfo);

  /* Callback */
  const get = useCallback(() => {
    dispatch(MaintenanceActions.api.maintenance.get({
      param: {
        id: Number(id),
      },
    }));
  }, [id]);

  const handleClickEdit = useCallback(() => {
    if (disabled) return;
    dispatch(DialogActions.push({
      title: MaintenanceEditDialogTitle.update,
      element: <MaintenanceEditSP
        id={Number(id)}
        callback={get}
      />,
    }));
  }, [id]);

  /* Effect */
  useEffect(() => {
    get();
  }, [id]);

  const callTel = useTel();

  const handleClickSMS = useCallback(() => {
    openSMS(maintenanceInfo?.tel_no || '');
  }, [maintenanceInfo]);

  const handleClickPhone = useCallback((tel: string | undefined) => {
    if (!maintenanceInfo || !tel) return;
    callTel({
      name: maintenanceInfo.customer_name,
      tel,
      callback: () => {
        const date = new Date();
        const datas = {
          contact_dt: DateFormatter.date2str(date, 'YYYYMMDD', '-'),
          contact_hour: date.getHours(),
          contact_minutes: date.getMinutes(),
          contact_store_id: store_id,
          contact_employee_id: employee_id,
        };
        dispatch(SupportHistoryActions.api.supportHistory.post({
          param: {
            data: {
              customer_id: maintenanceInfo.customer_id,
              project_id: maintenanceInfo.project_id,
              ...datas,
            },
          },
          noMessage: true,
          onSuccess: (res) => {
            if (!res) return;
            dispatch(push(`${RoutingPath.supportHistoryDetail}/${res.id}`));
          },
        }));
      },
    });
  }, [maintenanceInfo, store_id, employee_id]);

  return (
    <BasePageSP
      searchBoxDialog={{
        title: '詳細検索',
        element: <MaintenanceSearchBoxSP
          getList={() => {
            dispatch(DialogActions.pop());
            dispatch(push(RoutingPath.maintenance));
          }}
        />,
      }}
      searchCallback={(v) => {
        dispatch(MaintenanceActions.setSort({ keyword: v }));
        dispatch(push(RoutingPath.maintenance));
      }}
      className="maintenance_detail_sp"
    >
      <div className="detail_wrap">
        <div className="detail_header">
          <div
            className="detail_header_inner"
            onClick={() => dispatch(goBack())}
          >
            <div
              className="detail_header_inner__back_btn"
            >
              <i className="fas fa-chevron-circle-left" />
            </div>

            <span>
              メンテナンス詳細
            </span>
          </div>

          <div className="detail_header_buttons">
            <LeftIconButton
              color="secondary"
              size="md"
              onClick={handleClickEdit}
              fontAwesomeClass="fa fa-edit"
              label="メンテナンス詳細編集"
              disabled={disabled}
            />
          </div>
        </div>

        <div className="detail_body maintenance_detail_sp__body">

          <div className="row_table_style maintenance_detail_sp__body__table">
            <div className="t_row">
              <div className="t_header">メンテナンス日</div>
              <div className="t_body">{DateFormatter.date2str(maintenanceInfo?.maintenance_date) || '---'}</div>
            </div>

            <div className="t_row">
              <div className="t_header">対応状況</div>
              <div className="t_body">{maintenanceInfo?.supported_kubun ? '対応済' : '未対応'}</div>
            </div>

            <div className="t_row">
              <div className="t_header">タイトル</div>
              <div className="t_body">{maintenanceInfo?.title || '---'}</div>
            </div>

            {/*
            <div className="t_row">
              <div className="t_header">案件ID</div>
              <div className="t_body">{maintenanceInfo?.project_id}</div>
            </div>
            */}
            <div className="t_row">
              <div className="t_header">案件名</div>
              <div className="t_body">{maintenanceInfo?.project_name || '---'}</div>
            </div>
            {/*
            <div className="t_row">
              <div className="t_header">顧客ID</div>
              <div className="t_body">{maintenanceInfo?.customer_id}</div>
            </div>
            */}
            <div className="t_row">
              <div className="t_header">顧客名</div>
              <div className="t_body">{maintenanceInfo?.customer_name ? `${maintenanceInfo?.customer_name} 様` : '---'}</div>
            </div>

            <div className="t_row">
              <div className="t_header">詳細内容</div>
              <div className="t_body">
                <BreakLine text={maintenanceInfo?.detail || '---'} />
              </div>
            </div>

            <div className="t_row">
              <div className="t_header">対応日</div>
              <div className="t_body">{DateFormatter.date2str(maintenanceInfo?.supported_date) || '---'}</div>
            </div>

            <div className="t_row">
              <div className="t_header">対応内容</div>
              <div className="t_body">
                <BreakLine text={maintenanceInfo?.supported_content || '---'} />
              </div>
            </div>

            <div className="t_row">
              <div className="t_header">顧客住所</div>
              <div className="t_body">
                {maintenanceInfo?.post_no ? `〒${joinStr(maintenanceInfo?.post_no, 3, '-')}` : '---'} <br />
                {maintenanceInfo?.prefecture_name || ''}
                {maintenanceInfo?.city || ''}
                {maintenanceInfo?.address || ''}
                {maintenanceInfo?.building_name || ''}
              </div>
            </div>

            <div className="t_row">
              <div className="t_header">電話番号</div>
              <div className="t_body">{maintenanceInfo?.tel_no || '---'}</div>
              <div>
                <IconButton
                  fontAwesomeClass="fas fa-phone"
                  onClick={() => handleClickPhone(maintenanceInfo?.tel_no)}
                  size="md"
                  color="secondary"
                  disabled={!maintenanceInfo?.tel_no}
                />
                <IconButton
                  color="secondary"
                  size="md"
                  onClick={handleClickSMS}
                  disabled={!maintenanceInfo?.tel_no || !isMobilePhone(maintenanceInfo?.tel_no)}
                  fontAwesomeClass="fas fa-sms"
                  className="ml_5"
                />
              </div>
            </div>

            <div className="t_row">
              <div className="t_header">顧客ランク</div>
              <div className="t_body">{maintenanceInfo?.rank_name || '---'}</div>
            </div>

            <div className="t_row">
              <div className="t_header">現場名称</div>
              <div className="t_body">{maintenanceInfo?.project_field_name || '---'}</div>
            </div>

            <div className="t_row">
              <div className="t_header">契約日</div>
              <div className="t_body">{DateFormatter.date2str(maintenanceInfo?.contract_date) || '---'}</div>
            </div>
            <div className="t_row">
              <div className="t_header">着工日</div>
              <div className="t_body">{DateFormatter.date2str(maintenanceInfo?.construction_date) || '---'}</div>
            </div>

            <div className="t_row">
              <div className="t_header">完工日</div>
              <div className="t_body">{DateFormatter.date2str(maintenanceInfo?.completion_date) || '---'}</div>
            </div>

            <div className="t_row">
              <div className="t_header">担当店舗</div>
              <div className="t_body">{maintenanceInfo?.project_store_name || '---'}</div>
            </div>

            <div className="t_row">
              <div className="t_header">担当者</div>
              <div className="t_body">{maintenanceInfo?.project_employee_name || '---'}</div>
            </div>
            <div className="t_row">
              <div className="t_header">登録日</div>
              <div className="t_body">{DateFormatter.date2str(maintenanceInfo?.created_at, 'YYYYMMDD_HHmm') || '---'}</div>
            </div>
            <div className="t_row">
              <div className="t_header">登録者</div>
              <div className="t_body">{maintenanceInfo?.created_employee_name || '---'}</div>
            </div>
            <div className="t_row">
              <div className="t_header">更新日</div>
              <div className="t_body">{DateFormatter.date2str(maintenanceInfo?.updated_at, 'YYYYMMDD_HHmm') || '---'}</div>
            </div>
            <div className="t_row">
              <div className="t_header">更新者</div>
              <div className="t_body">{maintenanceInfo?.updated_employee_name || '---'}</div>
            </div>
          </div>
        </div>
      </div>
    </BasePageSP>
  );
};
