import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { DialogActions } from '../../../redux/dialog/dialog.action';
import { CustomerListType } from '../../../type/customer/customer.type';
import { ProjectListType } from '../../../type/project/project.type';
import { UserAgent } from '../../../utilities/user-agent';
import { Button } from '../../ui/button/button';
import { Input } from '../../ui/input/input';
import { RegistrationAddressMapDialogPC } from '../../ui/map/registration-address-map-dialog/pc/registration-address-map-dialog.pc';
import { useRegistrationMap, useRegistrationMapCustomer, useRegistrationMapProject } from '../../../hooks/map/use-map';

type DefaultButtonProps = {
  label: string;
  onClick: () => void;

  size?: 'md' | 'sm' | 'lg';
  color?: 'primary' | 'secondary' | 'white' | 'default' | 'dark';
  disabled?: boolean;
  icon?: globalThis.JSX.Element;
  className?: string;
}

export const DefaultButton = (props:DefaultButtonProps) => {
  const {
    size, color, onClick, disabled, label, icon, className,
  } = props;
  return (
    <Button
      size={size || 'md'}
      color={color || 'secondary'}
      onClick={onClick}
      disabled={disabled}
      className={className || ''}
    >
      <>
        {icon && icon}
        {label}
      </>
    </Button>
  );
};

// ------------------------------------------------------------------------

type DefaultInputProps = {
  value: string;
  label?: string;
  onChange: (v:string) => void;
  justChange?: () => void;

  disabled?: boolean;
  labelClassName?: string;
  inputClassName?: string;
}

export const DefaultInput = (props:DefaultInputProps) => {
  const {
    value: _value,
    onChange: _onChange,
    disabled,
    label,
    labelClassName,
    inputClassName,
    justChange,
  } = props;

  const [value, setValue] = useState(_value);

  const onChange = useCallback((e:globalThis.React.ChangeEvent<HTMLInputElement>) => {
    justChange?.();
    setValue(e.target.value);
    // _onChange(e.target.value);
  }, [value, justChange, _value, _onChange]);

  useEffect(() => {
    setValue(_value);
  }, [_value]);

  return (
    <>
      {label && <div className={labelClassName || 'item_head'}>{label}</div>}
      <Input
        onBlur={() => _onChange(value)}
        className={inputClassName || 'small'}
        value={value}
        disabled={disabled}
        onChange={(e) => onChange(e)}
      />
    </>
  );
};

// ------------------------------------------------------------------------
type CustomerSearchBtnProps = {
  type: 'customer' | 'project' | 'address';
  onClick: (v: string) => void;
  disabled?: boolean;
  icon?: globalThis.JSX.Element;
  className?: string;
  size?: 'md' | 'sm' | 'lg';
  isSP?: boolean;
}

export const SearchBtn = (props:CustomerSearchBtnProps) => {
  const {
    onClick: _onClick, type, disabled, icon, className, size, isSP,
  } = props;

  const dispatch = useDispatch();
  const regiCustomer = useRegistrationMapCustomer(isSP ? 'sp' : undefined);
  const regiProject = useRegistrationMapProject(isSP ? 'sp' : undefined);
  const regiMap = useRegistrationMap(isSP ? 'sp' : undefined);

  const label = useMemo(() => {
    switch (type) {
      case 'customer':
        return UserAgent === 'pc' ? '顧客検索' : '顧客';
      case 'project':
        return UserAgent === 'pc' ? '案件検索' : '案件';
      case 'address':
        return UserAgent === 'pc' ? '地図から検索' : '';
      default:
        return '';
    }
  }, [type]);

  /* Callback */
  const mapSearch = useCallback(() => {
    if (type === 'customer') {
      regiCustomer(
        (v) => {
          _onClick(`${v.prefecture_name}${v.city}${v.address || ''}${v.building_name || ''}`);
          dispatch(DialogActions.pop());
        },
        (v) => _onClick(v),
      );
      return;
    }

    if (type === 'project') {
      regiProject(
        (v) => {
          _onClick(v.field_place);
          dispatch(DialogActions.pop());
        },
        (v) => _onClick(v),
      );
    }

    if (type === 'address') {
      regiMap(
        (v) => {
          console.log((v?.formattedAddress || '').split(' ').splice(1));
          const _address = (v?.formattedAddress || '').split(' ').splice(1);
          const address = _address.length ? _address.join(' ') : v?.formattedAddress.replace('日本、', '') || '';
          _onClick(address);
        },
      );
    }
  },
  [type, _onClick]);

  return (
    <DefaultButton
      label={label}
      onClick={mapSearch}
      disabled={disabled}
      icon={icon}
      className={className}
      size={size}
    />
  );
};
