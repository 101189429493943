import * as lodash from 'lodash';
import { TagType } from '../../type/tag/tag.type';
import { User } from '../../type/auth/user.typs';
import {
  ProjectEditState,
  ProjectSortState,
} from '../../type/project/project.type';

export class ProjectCollection {
  private static _rank = [{ id: 1, label: 'A' }];

  private static _constructionStatusList: TagType[] = [
    { id: 1, label: '案件化' },
    { id: 2, label: '見積中' },
    { id: 3, label: '工事中' },
    { id: 4, label: '完工' },
    { id: 6, label: '完了' },
    { id: 7, label: '失注' },
    { id: 8, label: 'キャンセル' },
  ];

   static projectStatusList: TagType[] = [
     { id: 10, label: '未契約' },
     { id: 3, label: '未完工' },
     { id: 4, label: '完工' },
     { id: 6, label: '完了' },
     { id: 7, label: '失注' },
     { id: 8, label: 'キャンセル' },
   ];

  private static _estimateHeader = [
    '契約',
    '実行予算',
    '最終原価',
    '完工',
    '対比',
  ];

  private static _estimateSideHeader = [
    '受注金額',
    '追加１',
    '追加２',
    '原価',
    '粗利益',
    '粗利率',
  ];

  private static _estimateListHeader = [
    '',
    '見積番号',
    '作成日',
    '見積作成者',
    '見積金額',
    '消費税額',
    '税込合計見積',
    '原価合計',
    '消費税額',
    '税込合計原価',
    '調整額',
    '見積工期_開始',
    '見積工期_終了',
  ];

  private static _supportHistory = [
    '対応済',
    '登録日時',
    'カテゴリ',
    '件名',
    '対応者',
    '対応日',
    '',
  ];

  private static _file = [
    'No.',
    'ファイル名',
    '形式',
    'サイズ（KB）',
    'アップロード日時',
    '更新者',
    'コメント',
    '',
    '',
  ];

  private static _maintenance = [
    '',
    '対応済',
    'メンテナンス日',
    'タイトル',
    '対応日',
    '',
  ];

  private static _header = [
    '',
    '',
    '案件ID',
    '現場名称',
    '顧客ランク',
    '見込みランク',
    '案件名',
    '現場電話番号',
    '現場住所',
    '着工予定日',
    '完工予定日',
    '着工日',
    '完工日',
    '契約番号',
    '発生源',
    '備考',
    '担当名',
    '契約日',
  ];

  private static _editInitialState(): ProjectEditState {
    return lodash.cloneDeep({
      /** 顧客ID */
      customer_id: NaN,
      /** 案件名 */
      name: '',
      /** 発生源 */
      project_source_id: NaN,
      /** 案件ランク（見込みランク） */
      project_rank: NaN,
      /** 案件担当店舗 */
      project_store_id: NaN,
      /** 案件担当担当者 */
      project_employee_id: NaN,
      /** 現場名称 */
      field_name: '',
      /** 現場郵便番号 */
      field_post_no: ['', ''],
      /** JISX0401コード */
      field_jisx0401_code: '',
      /** JISX0402コード */
      field_jisx0402_code: '',
      /** 現場都道府県 */
      field_prefecture: NaN,
      /** 現場市区町村 */
      field_city: '',
      /** 現場地名、番地 */
      field_address: '',
      /** 現場建物名等 */
      field_building_name: '',
      /** 現場電話番号 */
      field_tel_no: '',
      /** 現場FAX */
      field_fax_no: '',
      /** 工事部位 */
      construction_parts: null,
      /** 見込み金額 */
      mitumori_kin: NaN,
      /** 契約番号 */
      contract_no: '',
      /** 契約日 */
      contract_date: null,
      /** 受注工期_開始 */
      construction_period_start: null,
      /** 受注工期_終了 */
      construction_period_end: null,
      /** 着工予定日 */
      construction_start_date: null,
      /** 完工予定日 */
      completion_end_date: null,
      /** 着工日 */
      construction_date: null,
      /** 完工日 */
      completion_date: null,
      /** 完了日 */
      complete_date: null,
      /** 備考 */
      remarks: '',
      /** 失注日 */
      failure_date: null,
      /** 失注理由 */
      failure_cause: NaN,
      /** 失注備考 */
      failure_remarks: '',
      /** キャンセル日 */
      cancel_date: null,
      /** キャンセル理由 */
      cancel_reason: '',
      /** 有効フラグ */
      valid_flag: 1,
      /** 緯度 */
      lat: '',
      /** 経度 */
      lng: '',
      source_id: NaN,
    });
  }

  static _initialSortState(user?:User): ProjectSortState {
    return {
      /** 顧客ID */
      customer_id: NaN,
      /** 案件担当（店舗） */
      project_store_id: NaN,
      /** 案件担当（担当者） */
      project_employee_id: user?.id ?? NaN,
      /** 案件名 */
      name: '',
      /** 現場名称 */
      field_name: '',
      /** 現場電話番号 */
      field_tel_no: '',
      /** 顧客名 */
      customer_name: '',
      /** 有効フラグ */
      valid_flag: 1,
      /** 現場都道府県 */
      field_prefectures: NaN,
      /** 現場住所 */
      field_address: '',
      /** 案件ランク（見込みランク） */
      project_rank: NaN,
      /** 案件ランクフィルタ */
      project_rank_filter: NaN,
      /** 見込み金額 */
      mitumori_kin: '',
      mitumori_kin_min: '',
      mitumori_kin_max: '',
      /** 緯度 */
      north_lat: '',
      /** 経度 */
      north_lng: '',
      /** 緯度2 */
      south_lat: '',
      /** 経度2 */
      south_lng: '',
      /** 工事状態フラグ */
      construction_flag: NaN,
      /** 受注金額（契約金額） */
      order_price: '',
      order_price_min: '',
      order_price_max: '',
      /** 発生源 */
      source_id: NaN,
      /** 備考 */
      remarks: '',
      /** キーワード */
      keyword: '',
      /** 顧客ID */
      customer_internal_id: NaN,
      /** オフセット */
      offset: 0,
      /** 1ページ内の件数 */
      limit: 100,
      /** 並替基準列 */
      sort_by: 2,
      /** 並替方法 */
      highlow: 1,
      /** 工事部位 */
      construction_parts: null,
      /** 工事状況 */
      construction_status: null,
      /** 発生源 */
      source_ids: null,
      /** 契約日ー期間開始 */
      contract_date_from: null,
      /** 契約日ー期間終了 */
      contract_date_to: null,
      /** 受注工期（開始）ー期間開始 */
      construction_period_start_from: null,
      /** 受注工期（開始）ー期間終了 */
      construction_period_start_to: null,
      /** 受注工期（終了）ー期間開始 */
      construction_period_end_from: null,
      /** 受注工期（終了）ー期間終了 */
      construction_period_end_to: null,
      /** 着工予定日ー期間開始 */
      construction_start_date_from: null,
      /** 着工予定日ー期間終了 */
      construction_start_date_to: null,
      /** 着工日ー期間開始 */
      construction_date_from: null,
      /** 着工日ー期間終了 */
      construction_date_to: null,
      /** 完工予定日ー期間開始 */
      completion_end_date_from: null,
      /** 完工予定日ー期間終了 */
      completion_end_date_to: null,
      /** 完工日ー期間開始 */
      completion_date_from: null,
      /** 完工日ー期間終了 */
      completion_date_to: null,
      /** 着工式ー期間開始 */
      construction_execution_date_from: null,
      /** 着工式ー期間終了 */
      construction_execution_date_to: null,
      /** 完工式ー期間開始 */
      completion_execution_date_from: null,
      /** 完工式ー期間終了 */
      completion_execution_date_to: null,
      /** 完了日ー期間開始 */
      complete_date_from: null,
      /** 完了日ー期間終了 */
      complete_date_to: null,
      /** 失注日ー期間開始 */
      failure_date_from: null,
      /** 失注日ー期間終了 */
      failure_date_to: null,
      /** キャンセル日ー期間開始 */
      cancel_date_from: null,
      /** キャンセル日ー期間終了 */
      cancel_date_to: null,
      /** 登録日 */
      entry_dt: null,
      /** 着工日 */
      construction_date: null,
      /** 完工日 */
      completion_date: null,
      /** 完了日 */
      complete_date: null,
      /** 契約日 */
      contract_date: null,
      /** 失注日 */
      failure_date: null,
      /** キャンセル日 */
      cancel_date: null,
      /** 登録店舗 */
      created_store_id: NaN,
      /** 登録社員 */
      created_employee_id: NaN,
      /** 更新店舗 */
      updated_store_id: NaN,
      /** 更新社員 */
      updated_employee_id: NaN,
      /** 登録日－期間開始 */
      created_at_start: null,
      /** 登録日－期間終了 */
      created_at_end: null,
      /** 更新日－期間開始 */
      updated_at_start: null,
      /** 更新日－期間終了 */
      updated_at_end: null,

    };
  }

  static get initialEditState() {
    return ProjectCollection._editInitialState();
  }

  static get rank() {
    return ProjectCollection._rank;
  }

  static get constructionStatusList() {
    return ProjectCollection._constructionStatusList;
  }

  static get estimateHeader() {
    return ProjectCollection._estimateHeader;
  }

  static get estimateSideHeader() {
    return ProjectCollection._estimateSideHeader;
  }

  static get estimateListHeader() {
    return ProjectCollection._estimateListHeader;
  }

  static get supportHistory() {
    return ProjectCollection._supportHistory;
  }

  static get file() {
    return ProjectCollection._file;
  }

  static get maintenance() {
    return ProjectCollection._maintenance;
  }

  static get header() {
    return ProjectCollection._header;
  }

  static get editInitialState() {
    return ProjectCollection._editInitialState;
  }

  static get initialSortState() {
    return ProjectCollection._initialSortState;
  }
}
