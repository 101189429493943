import React, {
  useCallback, useMemo, useState,
} from 'react';
import { cloneDeep } from 'lodash';
import { useDispatch } from 'react-redux';
import { DialogActions } from '../../../../../redux/dialog/dialog.action';
import { EditSP } from '../../../../dialogs/edit/edit.sp';
import { Button } from '../../../../ui/button/button';
import { RightLabelCheckbox } from '../../../../ui/checkbox/right-label-checkbox/right-label-checkbox';
import { DatePicker } from '../../../../ui/date-picker/date-picker';
import { TopLabelInputField } from '../../../../ui/input-field/top-label-input-field/top-label-input-field';
import { Select } from '../../../../ui/select/select';
import './support-history-edit.sp.scss';
import { SupportHistoryEditState } from '../../../../../type/support-history/support-history.type';
import { SupportHistoryCollection } from '../../../../../collection/support-history/support-history.collection';
import { RegistrationAddressMapDialog } from '../../../../ui/map/registration-address-map-dialog/registration-address-map-dialog';
import { Input } from '../../../../ui/input/input';
import { SupportHistoryActions } from '../../../../../redux/support-history/support-history.action';
import { useDidMount } from '../../../../../hooks/life-cycle';
import { IconButton } from '../../../../ui/button/icon-button/icon-button';
import { SupportHistoryValidation } from '../../../../../model/validation/support-history/support-history.validation';
import {
  ValidationLengthUnder40,
  ValidationLengthUnder500,
  ValidationLengthUnder60,
} from '../../../../../model/validation';
import { TextArea } from '../../../../ui/text-area/text-area';
import { Customer, CustomerListType } from '../../../../../type/customer/customer.type';
import { Project, ProjectListType } from '../../../../../type/project/project.type';
import { MasterActions } from '../../../../../redux/master/master.action';
import { FileUploadButton } from '../../../../ui/file-upload/file-upload-button';
import { ValidationDatePicker } from '../../../../../model/validation/validation-date-picker';
import { Required } from '../../../../ui/required/required';
import { hours, minutes } from '../../../../../collection/time';
import { getNowDate } from '../../../../../utilities/get-now-time';
import { CustomerSearchSP } from '../../../layout/search-box/customer/customer-search/customer-search.sp';
import { SupportHistoryModel as SM, FilesType } from '../../../../../model/support-history/support-history.model';
import { pulldown } from '../../../../../utilities/pulldown';
import { useAppSelector } from '../../../../../hooks/use-redux';
import { ProjectSearchSP } from '../../../layout/search-box/project/project-search/project-search.sp';
import { FileActions } from '../../../../../redux/file/file.action';
import { Message } from '../../../../../collection/message.collection';
import { CommonCollection } from '../../../../../collection/common/common.collection';
import { InfoButton } from '../../../../ui/button/info-button/info-button';
import { useRegistrationMapCustomer, useRegistrationMapProject } from '../../../../../hooks/map/use-map';
import { CustomerActions } from '../../../../../redux/customer/customer.action';

type Props = {
  id?: number;
  customer?: Customer | CustomerListType;
  project?: Project | ProjectListType;
  callback?: () => void;
  callbackPostEnd?: () => void;
}

export const SupportHistoryEditSP = (props: Props) => {
  const {
    id, customer, project, callback, callbackPostEnd,
  } = props;

  /* Hooks */
  const dispatch = useDispatch();
  const regiMapCustomer = useRegistrationMapCustomer('sp');
  const regiMapProject = useRegistrationMapProject('sp');

  /* State */
  const storeList = useAppSelector((v) => (v.master.storeList));
  const employeeList = useAppSelector((v) => (v.master.employeeList));
  const originList = useAppSelector((v) => (v.master.originList));
  const supportHistoryList = useAppSelector((v) => (v.master.supportHistoryList));
  const largeCategoryList = useAppSelector((v) => (v.master.largeCategoryList));
  const user = useAppSelector((v) => (v.user));
  const [customerData, setCustomerData] = useState<
    undefined
    | CustomerListType
    | Customer
    >(customer || undefined);

  /* State */
  const [editState, setEditState] = useState(SupportHistoryCollection._editInitialState(user));
  const [preFiles, setPreFiles] = useState<FilesType[]>([]);
  const [files, setFiles] = useState<FilesType[]>([]);
  const [touch, setTouch] = useState(false);

  const contactEmployeeList = useMemo(() => SM.setEditEmployeeList({
    editState, employeeList, type: 'contract',
  }),
  [editState.contact_store_id, employeeList]);

  const supportedEmployeeList = useMemo(() => SM.setEditEmployeeList({
    editState, employeeList, type: 'support',
  }),
  [editState.support_store_id, employeeList]);

  /* Callback */
  const setState = useCallback((v: Partial<SupportHistoryEditState>) => {
    setEditState({ ...cloneDeep(editState), ...cloneDeep(v) });
  }, [editState]);

  /* 保存 */
  const post = useCallback(() => {
    if (SupportHistoryValidation(editState)) {
      dispatch(DialogActions.pushMessage({
        title: `対応履歴${id ? '更新' : '新規登録'}`,
        message: Message.postError,
        callback: () => setTouch(true),
      }));
      return;
    }
    dispatch(SupportHistoryActions.api.supportHistory.post({
      param: {
        id,
        data: SM.postParam({
          files, preFiles, id, editState,
        }),
      },
      update: !!id,
      onSuccess: () => {
        dispatch(DialogActions.pop());
        callback?.();
        callbackPostEnd?.();
      },
    }));
  },
  [editState, callback, files, preFiles, id, callbackPostEnd]);

  /* 地図から登録 */
  const mapSearch = useCallback((type: 'customer' | 'project') => {
    if (type === 'customer') {
      regiMapCustomer((v) => {
        dispatch(DialogActions.pop());
        setState(SM.setCustomer({ customer: v, editState }));
      });
      return;
    }
    regiMapProject((v) => {
      dispatch(DialogActions.pop());
      setState(SM.setProject({ project: v }));
    });
  }, [editState]);

  const customerSearch = useCallback((initData?: Partial<SupportHistoryEditState>) => {
    dispatch(DialogActions.push({
      title: '顧客検索',
      element: <CustomerSearchSP
        callback={(data) => {
          setState({
            ...SM.setCustomer({ editState, customer: data }),
            ...initData,
          });
          setCustomerData(cloneDeep(data));
        }}
      />,
    }));
  }, [editState]);

  /* 案件検索 */
  const projectSearch = useCallback(() => {
    dispatch(DialogActions.push({
      title: '案件検索',
      element: <ProjectSearchSP
        type="support"
        customerData={customerData}
        callback={(data) => setState(SM.setProject({ project: data }))}
      />,
    }));
  }, [editState, customerData]);

  /* ファイルの読み込み */
  const onFileInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = e.currentTarget.files;
    if (!fileList?.length) return;
    const res = SM.setInputFileList({ fileList, files });
    setFiles(res);
  }, [files]);

  /* ファイルダウンロード */
  const downloadFile = useCallback((fileId: number, name:string) => {
    dispatch(FileActions.api.file.download({
      param: { file_id: fileId },
      fileName: name || '',
    }));
  }, []);

  /* ファイル削除 */
  const deleteFile = useCallback((index: number) => {
    files.splice(index, 1);
    setFiles(cloneDeep(files));
  }, [files]);

  /* Effect */
  useDidMount(() => {
    dispatch(MasterActions.api.store.getList({}));
    dispatch(MasterActions.api.employee.getList({}));
    dispatch(MasterActions.api.supportHistory.getList({}));
    dispatch(MasterActions.api.largeCategory.getList({}));
    dispatch(MasterActions.api.origin.getList({}));

    const { date, hour, min } = getNowDate();

    /* 今日の日付 */
    const d: Partial<SupportHistoryEditState> = {
      contact_dt: date,
      contact_hour: hour,
      contact_minutes: min,
    };

    /* 顧客から登録 */
    if (customer) {
      setState({ ...SM.setCustomer({ customer, editState }), ...d });
      return;
    }

    /* 案件から登録 */
    if (project) {
      setState({ ...SM.setProject({ project }), ...d });
      return;
    }

    /* 編集 */
    if (id !== undefined) {
      dispatch(SupportHistoryActions.api.supportHistory.get({
        param: { id },
        callback: (data) => {
          const _files = () => data.file_list.map((v) => ({
            id: v.file_id,
            name: v.file_name,
            file: null,
            format: v.format,
          }));
          setPreFiles(_files());
          setFiles(_files());
          setState(SM.setGetData({ data }));
          dispatch(CustomerActions.api.customer.get({
            param: {
              id: data.customer_id,
            },
            callback: (data2) => {
              setCustomerData(data2);
            },
          }));
        },
      }));
      return;
    }

    /* 新規 */
    customerSearch(d);
  });

  return (
    <EditSP mode={!id ? 'add' : 'update'} callback={post}>
      <div className="edit_sp_body_inner support_history_edit_sp">
        <div className="category_wrap">
          <div className="item_wrap">
            <div className="item_label">
              <span>顧客名<Required /></span>
              <Button
                size="md"
                color="secondary"
                onClick={() => customerSearch()}
              >
                顧客検索
              </Button>
              <IconButton
                size="md"
                color="secondary"
                onClick={() => mapSearch('customer')}
                fontAwesomeClass="fa fa-map-marked-alt"
              />
            </div>
            <div className="item_body">
              <Input
                disabled
                value={editState?.customer_name}
                onChange={(e) => setState({ customer_name: e.target.value })}
                require
                touch={touch}
              />
            </div>
          </div>
          <div className="item_wrap">
            <div className="item_label">
              <span>案件名</span>
              <Button
                size="md"
                color="secondary"
                onClick={projectSearch}
              >
                案件検索
              </Button>
              <IconButton
                size="md"
                color="secondary"
                onClick={() => mapSearch('project')}
                fontAwesomeClass="fa fa-map-marked-alt"
              />
            </div>
            <div className="item_body">
              <Input
                value={editState?.project_name}
                disabled
              />
            </div>
          </div>
        </div>

        <div className="category_wrap">
          <div className="item_wrap">
            <div className="item_label">登録日時<Required /></div>
            <DatePicker
              date={editState.contact_dt}
              onChange={(v) => setState(SM.setContactDt({ contact_dt: v }))}
              touch={touch}
              require
              errorPosBottom
            />
          </div>
          <div className="item_wrap">
            <div className="item_box flex_box flex_align_center">
              <Select
                value={editState.contact_hour}
                onChange={(v) => setState({ contact_hour: Number(v) })}
                defaultLabel="指定無し"
                options={pulldown(hours)}
              />
              <div className="item_label ml_10 mr_10">時</div>
              <Select
                value={editState.contact_minutes}
                onChange={(v) => setState({ contact_minutes: Number(v) })}
                defaultLabel="指定無し"
                options={pulldown(minutes)}
              />
              <div className="item_label ml_10">分</div>
            </div>
          </div>

          <div className="item_wrap">
            <div className="item_label">登録店舗<Required /></div>
            <div className="item_body">
              <Select
                value={editState?.contact_store_id}
                defaultLabel="指定無し"
                options={pulldown(storeList)}
                onChange={(v) => setState({ contact_store_id: Number(v) })}
                require
              />
            </div>
          </div>

          <div className="item_wrap">
            <div className="item_label">登録者<Required /></div>
            <div className="item_body">
              <Select
                value={editState?.contact_employee_id}
                defaultLabel="指定無し"
                options={pulldown(contactEmployeeList)}
                onChange={(v) => setState({ contact_employee_id: Number(v) })}
                require
              />
            </div>
          </div>

          <div className="item_wrap">
            <div className="item_body">
              <TopLabelInputField
                label="対応履歴名"
                value={editState?.title}
                onChange={(e) => setState({ title: e.target.value })}
                validationList={ValidationLengthUnder60}
              />
            </div>
          </div>

          <div className="item_wrap">
            <div className="item_label">媒体</div>
            <div className="item_body">
              <Select
                value={editState?.support_category_id}
                defaultLabel="指定無し"
                options={supportHistoryList.map((v) => ({
                  text: v.supported, value: v.id,
                }))}
                onChange={(v) => setState({ support_category_id: Number(v) })}
              />
            </div>
          </div>

          <div className="item_wrap">
            <div className="item_label">カテゴリ</div>
            <div className="item_body">
              <Select
                value={editState?.source_id}
                defaultLabel="指定無し"
                options={pulldown(originList)}
                onChange={(v) => setState({ source_id: Number(v) })}
              />
            </div>
          </div>

          <div className="item_wrap">
            <div className="item_label">
              ファイル
            </div>
            <div className="item_body item_file_upload">
              {Boolean(files.length) && (
                files.map((v, i) => (
                  <div className="flex_box flex_space_between file_box mb_5" key={i}>
                    <Input
                      className=""
                      value={v.name}
                      onChange={(e) => {
                        files[i].name = e.target.value;
                        setFiles(cloneDeep(files));
                      }}
                    />
                    <div className="extension">{files[i].format}</div>
                    {id && Boolean(v.id)
                      && (
                      <Button
                        className="ml_10"
                        size="sm"
                        color="secondary"
                        onClick={() => downloadFile(v.id, v.name)}
                      >
                        ダウンロード
                      </Button>
                      )}
                    <Button
                      className="ml_10"
                      size="sm"
                      color="dark"
                      onClick={() => deleteFile(i)}
                    >
                      削除
                    </Button>
                  </div>
                ))
              )}
              <div className="item_file_upload__buttons">
                <FileUploadButton
                  onChange={onFileInputChange}
                  accept={CommonCollection.acceptFile}
                  multiple
                />
                <InfoButton title={Message.fileInfo} />
              </div>
            </div>
          </div>

          <div className="item_wrap">
            <div className="item_label">対応内容</div>
            <div className="item_body">
              <TextArea
                rows={7}
                value={editState?.support_comment}
                onChange={(e) => setState({ support_comment: e.target.value })}
                validationList={ValidationLengthUnder500}
              />
            </div>
          </div>

          <div className="item_wrap">
            <div className="item_label">対応店舗</div>
            <div className="item_body">
              <Select
                value={editState?.support_store_id}
                defaultLabel="指定無し"
                onChange={(v) => setState({ support_store_id: Number(v) })}
                options={pulldown(storeList)}
              />
            </div>
          </div>

          <div className="item_wrap">
            <div className="item_label">対応者</div>
            <div className="item_body">
              <Select
                value={editState?.support_employee_id}
                defaultLabel="指定無し"
                options={pulldown(supportedEmployeeList)}
                onChange={(v) => setState({ support_employee_id: Number(v) })}
              />
            </div>
          </div>

          <div className="item_wrap">
            <div className="item_label">対応日</div>
            <DatePicker
              date={editState.support_dt || null}
              onChange={(v) => setState({ support_dt: v })}
            />
          </div>

          <div className="item_wrap tags_form">
            <div className="item_body item_checkbox">
              <RightLabelCheckbox
                className="single_column"
                checked={Boolean(editState?.support_flag)}
                label="対応済みフラグ"
                onChange={() => setState({ support_flag: editState?.support_flag ? 0 : 1 })}
              />
            </div>
          </div>
        </div>
      </div>
    </EditSP>
  );
};
