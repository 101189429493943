import actionCreatorFactory from 'typescript-fsa';
import { ApiBase } from '../../../../service/api-base';
import { CsvBirthdayListType } from '../../../../type/csv/csv.type';

/* Param */
export type ApiCsvBirthdayGetListParam = Partial<{
  /** 営業担当（店舗） */
  store_id: number;
  /** 営業担当（担当者） */
  employee_id: number;
  /** 顧客登録名 */
  name: string;
  /** 家族お名前 */
  family_name: string;
  /** 続柄 */
  relationship: string;
  /** 携帯番号 */
  mobile_phone: string;
  /** 生年月（開始） */
  birth_month_start: string;
  /** 生年日（開始） */
  birth_day_start: string;
  /** 生年月（終了） */
  birth_month_end: string;
  /** 生年日（終了） */
  birth_day_end: string;
  /** 有効フラグ */
  valid_flag: number;
  /** オフセット */
  offset: number;
  /** 1ページ内の件数 */
  limit: number;
  /** 並替基準列 "0:顧客ID
1:顧客名
2:家族お名前
3:家族生年月日
4:続柄
5:携帯番号
6:郵便番号
7:都道府県
8住所
9:顧客電話番号
10:営業担当者名" */
  sort_by: number;
  /** 並替方法 */
  highlow: number;
}>;

export type ApiCsvBirthdayDownloadParam = ApiCsvBirthdayGetListParam;

/* Response */
export type ApiCsvBirthdayGetListResponse = CsvBirthdayListType;

export const ActionCreator = actionCreatorFactory('csv/api/birthday');

export const apiCsvBirthday = {
  getList: ActionCreator<ApiCsvBirthdayGetListParam>('get/list'),
  download: ActionCreator<ApiCsvBirthdayDownloadParam>('download'),
};

export class ApiCsvBirthdayGetList extends ApiBase<ApiCsvBirthdayGetListResponse> {
  constructor(param: ApiCsvBirthdayGetListParam) {
    super({
      httpMethod: 'GET',
      param,
      url: '/api/csv/birthday',
    });
  }
}

export class ApiCsvBirthdayDownload extends ApiBase {
  constructor(param: ApiCsvBirthdayDownloadParam) {
    super({
      httpMethod: 'GET',
      contents: 'BLOB',
      param,
      url: '/api/csv/download/birthday',
    });
  }
}
