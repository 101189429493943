import { takeEvery } from 'redux-saga/effects';
import { Store } from '../store';
import { getListHandle, downloadHandle } from '../root.saga';
import { CsvActions } from './csv.action';
import { ApiCsvCustomerGetList, ApiCsvCustomerGetListResponse, ApiCsvCustomerDownload } from './api/customer/api-csv-customer';
import { ApiCsvBirthdayGetListResponse, ApiCsvBirthdayGetList, ApiCsvBirthdayDownload } from './api/birhday/api-csv-birthday';
import { ApiCsvWeddingGetListResponse, ApiCsvWeddingGetList, ApiCsvWeddingDownload } from './api/wedding/api-csv-wedding';
import { ApiCsvProjectGetListResponse, ApiCsvProjectGetList, ApiCsvProjectDownload } from './api/project/api-csv-project';
import { ApiCsvLostOrderGetListResponse, ApiCsvLostOrderGetList, ApiCsvLostOrderDownload } from './api/lost-order/api-csv-lost-order';
import { ApiCsvNonOrderGetListResponse, ApiCsvNonOrderGetList, ApiCsvNonOrderDownload } from './api/non-order/api-csv-non-order';
import { ApiCsvOrderGetListResponse, ApiCsvOrderGetList, ApiCsvOrderDownload } from './api/order/api-csv-order';
import { ApiCsvMaintenanceGetListResponse, ApiCsvMaintenanceGetList, ApiCsvMaintenanceDownload } from './api/maintenance/api-csv-maintenance';
import { ApiCsvSupportHistoryGetListResponse, ApiCsvSupportHistoryGetList, ApiCsvSupportHistoryDownload } from './api/support-history/api-csv-support-history';
import { ApiCsvCustomerRankGetListResponse, ApiCsvCustomerRankGetList, ApiCsvCustomerRankDownload } from './api/customer-rank/api-customer-rank';
import { ApiCsvMeisaiDownload } from './api/meisai/api-csv-meisai';

function* tryCustomerGetList(action: ReturnType<typeof CsvActions.api.customer.getList>) {
  yield getListHandle<ApiCsvCustomerGetListResponse>({
    noDelay: true,
    noLoad: true,
    api: new ApiCsvCustomerGetList(action.payload),
    onSuccess: (res, count) => {
      Store.dispatch(CsvActions.setCustomerList(res));
      Store.dispatch(CsvActions.setCustomerCount(count));
    },
  });
}
function* tryCustomerDownload(action: ReturnType<typeof CsvActions.api.customer.download>) {
  yield downloadHandle({
    api: new ApiCsvCustomerDownload(action.payload),
    fileName: '顧客情報.csv',
  });
}
function* tryBirthdayGetList(action: ReturnType<typeof CsvActions.api.birthday.getList>) {
  yield getListHandle<ApiCsvBirthdayGetListResponse>({
    noDelay: true,
    noLoad: true,
    api: new ApiCsvBirthdayGetList(action.payload),
    onSuccess: (res, count) => {
      Store.dispatch(CsvActions.setBirthdayList(res));
      Store.dispatch(CsvActions.setBirthdayCount(count));
    },
  });
}
function* tryBirthdayDownload(action: ReturnType<typeof CsvActions.api.birthday.download>) {
  yield downloadHandle({
    api: new ApiCsvBirthdayDownload(action.payload),
    fileName: '誕生日リスト.csv',
  });
}
function* tryWeddingGetList(action: ReturnType<typeof CsvActions.api.wedding.getList>) {
  yield getListHandle<ApiCsvWeddingGetListResponse>({
    noDelay: true,
    noLoad: true,
    api: new ApiCsvWeddingGetList(action.payload),
    onSuccess: (res, count) => {
      Store.dispatch(CsvActions.setWeddingList(res));
      Store.dispatch(CsvActions.setWeddingCount(count));
    },
  });
}
function* tryWeddingDownload(action: ReturnType<typeof CsvActions.api.wedding.download>) {
  yield downloadHandle({
    api: new ApiCsvWeddingDownload(action.payload),
    fileName: '結婚記念日リスト.csv',
  });
}
function* tryProjectGetList(action: ReturnType<typeof CsvActions.api.project.getList>) {
  yield getListHandle<ApiCsvProjectGetListResponse>({
    noDelay: true,
    noLoad: true,
    api: new ApiCsvProjectGetList(action.payload),
    onSuccess: (res, count) => {
      Store.dispatch(CsvActions.setProjectList(res));
      Store.dispatch(CsvActions.setProjectCount(count));
    },
  });
}
function* tryProjectDownload(action: ReturnType<typeof CsvActions.api.project.download>) {
  yield downloadHandle({
    api: new ApiCsvProjectDownload(action.payload),
    fileName: '案件情報.csv',
  });
}
function* tryOrderGetList(action: ReturnType<typeof CsvActions.api.order.getList>) {
  yield getListHandle<ApiCsvOrderGetListResponse>({
    noDelay: true,
    noLoad: true,
    api: new ApiCsvOrderGetList(action.payload),
    onSuccess: (res, count) => {
      Store.dispatch(CsvActions.setOrderList(res));
      Store.dispatch(CsvActions.setOrderCount(count));
    },
  });
}
function* tryOrderDownload(action: ReturnType<typeof CsvActions.api.order.download>) {
  yield downloadHandle({
    api: new ApiCsvOrderDownload(action.payload),
    fileName: '受注案件.csv',
  });
}
function* tryNonOrderGetList(action: ReturnType<typeof CsvActions.api.nonOrder.getList>) {
  yield getListHandle<ApiCsvNonOrderGetListResponse>({
    noDelay: true,
    noLoad: true,
    api: new ApiCsvNonOrderGetList(action.payload),
    onSuccess: (res, count) => {
      Store.dispatch(CsvActions.setNonOrderList(res));
      Store.dispatch(CsvActions.setNonOrderCount(count));
    },
  });
}
function* tryNonOrderDownload(action: ReturnType<typeof CsvActions.api.nonOrder.download>) {
  yield downloadHandle({
    api: new ApiCsvNonOrderDownload(action.payload),
    fileName: '未受注案件.csv',
  });
}
function* tryLostOrderGetList(action: ReturnType<typeof CsvActions.api.lostOrder.getList>) {
  yield getListHandle<ApiCsvLostOrderGetListResponse>({
    noDelay: true,
    noLoad: true,
    api: new ApiCsvLostOrderGetList(action.payload),
    onSuccess: (res, count) => {
      Store.dispatch(CsvActions.setLostOrderList(res));
      Store.dispatch(CsvActions.setLostOrderCount(count));
    },
  });
}
function* tryLostOrderDownload(action: ReturnType<typeof CsvActions.api.lostOrder.download>) {
  yield downloadHandle({
    api: new ApiCsvLostOrderDownload(action.payload),
    fileName: '失注案件.csv',
  });
}
function* tryMaintenanceGetList(action: ReturnType<typeof CsvActions.api.maintenance.getList>) {
  yield getListHandle<ApiCsvMaintenanceGetListResponse>({
    noDelay: true,
    noLoad: true,
    api: new ApiCsvMaintenanceGetList(action.payload),
    onSuccess: (res, count) => {
      Store.dispatch(CsvActions.setMaintenanceList(res));
      Store.dispatch(CsvActions.setMaintenanceCount(count));
    },
  });
}
function* tryMaintenanceDownload(action: ReturnType<typeof CsvActions.api.maintenance.download>) {
  yield downloadHandle({
    api: new ApiCsvMaintenanceDownload(action.payload),
    fileName: 'メンテナンス情報.csv',
  });
}
function* tryCustomerRankGetList(action:
  ReturnType<typeof CsvActions.api.customerRank.getList>) {
  yield getListHandle<ApiCsvCustomerRankGetListResponse>({
    noDelay: true,
    noLoad: true,
    api: new ApiCsvCustomerRankGetList(action.payload),
    onSuccess: (res, count) => {
      Store.dispatch(CsvActions.setCustomerRankList(res));
      Store.dispatch(CsvActions.setCustomerRankCount(count));
    },
  });
}
function* tryCustomerRankDownload(action: ReturnType<typeof CsvActions.api.customerRank.download>) {
  yield downloadHandle({
    api: new ApiCsvCustomerRankDownload(action.payload),
    fileName: '顧客ランク更新ログ.csv',
  });
}
function* trySupportHistoryGetList(action:
  ReturnType<typeof CsvActions.api.supportHistory.getList>) {
  yield getListHandle<ApiCsvSupportHistoryGetListResponse>({
    noDelay: true,
    noLoad: true,
    api: new ApiCsvSupportHistoryGetList(action.payload),
    onSuccess: (res, count) => {
      Store.dispatch(CsvActions.setSupportHistoryList(res));
      Store.dispatch(CsvActions.setSupportHistoryCount(count));
    },
  });
}
function* trySupportHistoryDownload(
  action: ReturnType<typeof CsvActions.api.supportHistory.download>,
) {
  yield downloadHandle({
    api: new ApiCsvSupportHistoryDownload(action.payload),
    fileName: '対応履歴情報.csv',
  });
}

function* tryMeisaiDownload(
  action: ReturnType<typeof CsvActions.api.meisai.download>,
) {
  yield downloadHandle({
    api: new ApiCsvMeisaiDownload(action.payload),
    fileName: '明細マスタ情報.csv',
  });
}

export function* CsvSaga() {
  yield takeEvery(CsvActions.api.customer.getList, tryCustomerGetList);
  yield takeEvery(CsvActions.api.customer.download, tryCustomerDownload);
  yield takeEvery(CsvActions.api.birthday.getList, tryBirthdayGetList);
  yield takeEvery(CsvActions.api.birthday.download, tryBirthdayDownload);
  yield takeEvery(CsvActions.api.wedding.getList, tryWeddingGetList);
  yield takeEvery(CsvActions.api.wedding.download, tryWeddingDownload);
  yield takeEvery(CsvActions.api.project.getList, tryProjectGetList);
  yield takeEvery(CsvActions.api.project.download, tryProjectDownload);
  yield takeEvery(CsvActions.api.order.getList, tryOrderGetList);
  yield takeEvery(CsvActions.api.order.download, tryOrderDownload);
  yield takeEvery(CsvActions.api.nonOrder.getList, tryNonOrderGetList);
  yield takeEvery(CsvActions.api.nonOrder.download, tryNonOrderDownload);
  yield takeEvery(CsvActions.api.lostOrder.getList, tryLostOrderGetList);
  yield takeEvery(CsvActions.api.lostOrder.download, tryLostOrderDownload);
  yield takeEvery(CsvActions.api.maintenance.getList, tryMaintenanceGetList);
  yield takeEvery(CsvActions.api.maintenance.download, tryMaintenanceDownload);
  yield takeEvery(CsvActions.api.customerRank.getList, tryCustomerRankGetList);
  yield takeEvery(CsvActions.api.customerRank.download, tryCustomerRankDownload);
  yield takeEvery(CsvActions.api.supportHistory.getList, trySupportHistoryGetList);
  yield takeEvery(CsvActions.api.supportHistory.download, trySupportHistoryDownload);
  yield takeEvery(CsvActions.api.meisai.download, tryMeisaiDownload);
}
