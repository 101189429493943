import { ApiBase } from '../../../service/api-base';

export type MasterIndexType =
  /** 社員 */
  1
  /** 店舗 */
  | 2
  /** 大分類 */
  | 3
  /** 中分類 */
  | 4
  /** 消費税 */
  | 5
  /** 単位 */
  | 6
  /** エリア */
  | 7
  /** 建物分類 */
  | 8
  /** 顧客見込みランク */
  | 9
  /** 顧客ランク(工事金額) */
  | 10
  /** 顧客ランク(最終完工日) */
  | 11
  /** 明細 */
  | 12
  /** 失注理由 */
  | 13
  /** 間取り */
  | 14
  /** マイカータイプ */
  | 15
  /** 部位 */
  | 16
  /** 案件見込みランク */
  | 17
  /** 対応履歴(媒体) */
  | 18
  /** 対応履歴(カテゴリ) */
  | 19
  /** 関連タグ */
  | 20;

export type ApiMasterIndexParam = {
  type: MasterIndexType;
  ids: number[];
};

export class ApiMasterIndex extends ApiBase {
  constructor(param: ApiMasterIndexParam) {
    const { type } = param;
    super({
      httpMethod: 'POST',
      url: `/api/master/common/index/${type}`,
      param,
    });
  }

  createBody() {
    const formData = new FormData();
    const ary = this.param.ids as number[];
    if (!ary.length) return formData;
    ary.forEach((v, i) => {
      formData.append(`index[${v}]`, String(i + 1));
    });
    return formData;
  }
}
