import lodash, { cloneDeep, isEqual } from 'lodash';
import { useState, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SearchBoxPC } from '../../../../layout/search-box/search-box.pc';
import { Select } from '../../../../../ui/select/select';
import { LeftIconButton } from '../../../../../ui/button/left-icon-button/left-icon-button';
import { DatePicker } from '../../../../../ui/date-picker/date-picker';
import { CsvActions } from '../../../../../../redux/csv/csv.action';
import { State } from '../../../../../../redux/root.reducer';
import { CsvProjectSort } from '../../../../../../type/csv/csv-sort.type';
import { Input } from '../../../../../ui/input/input';
import { RightLabelCheckbox } from '../../../../../ui/checkbox/right-label-checkbox/right-label-checkbox';
import { useDidMount } from '../../../../../../hooks/life-cycle';
import { MasterActions } from '../../../../../../redux/master/master.action';
import { TagModel } from '../../../../../../model/tag/tag';
import { ProjectCollection } from '../../../../../../collection/project/project.collection';
import { InputTel } from '../../../../../ui/input/input-tel';
import { useDateValidation } from '../../../../../../hooks/use-date-validation';
import { DateTermValidation } from '../../../../../../model/validation/date/term-date.validation';
import { pulldown } from '../../../../../../utilities/pulldown';
import { useEstimateAuthority } from '../../../../../../hooks/use-authority';

type Props = {
  callback: (v: CsvProjectSort) => void;
}

export const CsvSearchBoxProject = (props: Props) => {
  const { callback } = props;

  /* Hooks */
  const dispatch = useDispatch();
  const sortState = useSelector((state: State) => state.csv.projectSort);
  const {
    storeList,
    employeeList,
  } = useSelector((state: State) => (state.master), isEqual);
  const estimateAuth = useEstimateAuthority();

  /* State */
  const [sort, setSort] = useState(cloneDeep(sortState));

  const _employeeList = useMemo(() => employeeList.filter((
    v,
  ) => (Number.isNaN(sort.project_store_id) || sort.project_store_id === null
    ? true
    : (v.store_id === sort.project_store_id))),
  [sort.project_store_id, employeeList]);

  const termDate = useDateValidation([
    { start: sort.completion_start_date || null, end: sort.completion_end_date || null },
    { start: sort.contract_start_date || null, end: sort.contract_end_date || null },
    { start: sort.created_at_start || null, end: sort.created_at_end || null },
    { start: sort.updated_at_start || null, end: sort.updated_at_end || null },
  ]);

  /* Callback */
  const setState = useCallback((v: CsvProjectSort) => {
    setSort({
      ...cloneDeep(sort),
      ...cloneDeep(v),
    });
  }, [sort]);

  const handleClickSearch = useCallback(() => {
    if (termDate.messages.length) {
      termDate.check();
    }
    callback({
      ...cloneDeep(sort),
      limit: sortState.limit,
    });
    dispatch(CsvActions.setProjectSort({
      ...cloneDeep(sort),
      limit: sortState.limit,
    }));
  }, [callback, sort, termDate, sortState]);

  /* Master */
  useDidMount(() => {
    dispatch(MasterActions.api.store.getList({}));
    dispatch(MasterActions.api.employee.getList({}));
    dispatch(MasterActions.api.customerRank.getList({}));
  });

  return (
    <>
      <SearchBoxPC
        openCallback={() => {}}
      >
        <div id="csv_project">
          <div className="item_wrap">
            <div className="item_box">
              <div className="item_head">営業担当</div>
              <Select
                className="add_text_left"
                label="店舗"
                value={sort?.project_store_id}
                onChange={(data) => setSort({ ...sort, project_store_id: Number(data) })}
                defaultLabel="全て"
                options={storeList.map((v) => ({
                  text: v.name, value: v.id,
                }))}
              />
              <Select
                className="add_text_left"
                label="担当者"
                value={sort?.project_employee_id}
                onChange={(data) => setSort({ ...sort, project_employee_id: Number(data) })}
                defaultLabel="全て"
                options={_employeeList.map((v) => ({
                  text: v.name, value: v.id,
                }))}
              />
            </div>
          </div>
          <div className="item_wrap">
            <div className="item_box">
              <div className="item_head">契約日</div>
              <DatePicker
                date={sort.contract_start_date || null}
                onChange={(v) => setState(
                  { ...sort, contract_start_date: v },
                )}
                term={DateTermValidation([
                  {
                    start: sort.contract_start_date || null,
                    end: sort.contract_end_date || null,
                  },
                ])}
              />
              <label className="ml_10">〜</label>
              <DatePicker
                date={sort.contract_end_date || null}
                onChange={(v) => setState(
                  { ...sort, contract_end_date: v },
                )}
                term={DateTermValidation([
                  {
                    start: sort.contract_start_date || null,
                    end: sort.contract_end_date || null,
                  },
                ])}
              />
            </div>
            <div className="item_box">
              <div className="item_head">完工日</div>
              <DatePicker
                date={sort.completion_start_date || null}
                onChange={(v) => setState(
                  { ...sort, completion_start_date: v },
                )}
                term={DateTermValidation([
                  {
                    start: sort.completion_start_date || null,
                    end: sort.completion_end_date || null,
                  },
                ])}
              />
              <label className="ml_10">〜</label>
              <DatePicker
                date={sort.completion_end_date || null}
                onChange={(v) => setState(
                  { ...sort, completion_end_date: v },
                )}
                term={DateTermValidation([
                  {
                    start: sort.completion_start_date || null,
                    end: sort.completion_end_date || null,
                  },
                ])}
              />
            </div>
          </div>
          <div className="item_wrap">
            <div className="item_box">
              <div className="item_head">顧客名</div>
              <Input
                className=""
                value={sort?.customer_name}
                onChange={(e) => setState({ ...sort, customer_name: e.target.value })}
              />
            </div>
            <div className="item_box">
              <div className="item_head">案件名</div>
              <Input
                className=""
                value={sort?.name}
                onChange={(e) => setState({ ...sort, name: e.target.value })}
              />
            </div>
            <div className="item_box">
              <div className="item_head">現場名称</div>
              <Input
                className=""
                value={sort?.field_name}
                onChange={(e) => setState({ ...sort, field_name: e.target.value })}
              />
            </div>
            <div className="item_box">
              <div className="item_head">現場電話番号</div>
              <InputTel
                value={sort?.field_tel_no}
                onChange={(v) => setState({ ...sort, field_tel_no: v })}
              />
            </div>
          </div>
          <div className="item_wrap">
            <div className="item_box">
              <div className="item_head">工事状況</div>
              <div className="flex_wrap_box">
                {sort?.construction_status?.data
                  .filter((v) => (estimateAuth ? true : v.id !== 2))
                  .map((v, i) => (
                    <div key={`rTag${i}`}>
                      <RightLabelCheckbox
                        className=""
                        key={v.id}
                        label={v.label}
                        checked={v.flag}
                        onChange={() => {
                          sort.construction_status?.changeFlag(v.id);
                          setState({
                            ...sort,
                            construction_status: lodash.cloneDeep(sort.construction_status),
                          });
                        }}
                      />
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <div className="item_wrap">
            <div className="item_box">
              <div className="item_head">登録者</div>
              <Select
                defaultLabel="全て"
                className="fixed"
                value={sort.created_employee_id}
                onChange={(v) => setState({ created_employee_id: Number(v) })}
                options={pulldown(employeeList)}
              />
            </div>
            <div className="item_box">
              <DatePicker
                date={sort.created_at_start || null}
                onChange={(v) => setState({ created_at_start: v })}
                type="YYYY/MM/DD"
                term={DateTermValidation([
                  { start: sort.created_at_start || null, end: sort.created_at_end || null },
                ])}
              />
              <label className="ml_10">～</label>
              <DatePicker
                date={sort.created_at_end || null}
                onChange={(v) => setState({ created_at_end: v })}
                type="YYYY/MM/DD"
                term={DateTermValidation([
                  { start: sort.created_at_start || null, end: sort.created_at_end || null },
                ])}
              />
            </div>
            <div className="item_box">
              <div className="item_head">更新者</div>
              <Select
                defaultLabel="全て"
                className="fixed"
                value={sort.updated_employee_id}
                onChange={(v) => setState({ updated_employee_id: Number(v) })}
                options={pulldown(employeeList)}
              />
            </div>
            <div className="item_box">
              <div className="item_head">更新日</div>
              <DatePicker
                date={sort.updated_at_start || null}
                onChange={(v) => setState({ updated_at_start: v })}
                type="YYYY/MM/DD"
                term={DateTermValidation([
                  { start: sort.updated_at_start || null, end: sort.updated_at_end || null },
                ])}
              />
              <label className="ml_10">～</label>
              <DatePicker
                date={sort.updated_at_end || null}
                onChange={(v) => setState({ updated_at_end: v })}
                type="YYYY/MM/DD"
                term={DateTermValidation([
                  { start: sort.updated_at_start || null, end: sort.updated_at_end || null },
                ])}
              />
            </div>
            <LeftIconButton
              label="検索"
              fontAwesomeClass="fas fa-search"
              className="btn_search for_detail"
              size="sm"
              color="secondary"
              onClick={handleClickSearch}
            />
          </div>
        </div>
      </SearchBoxPC>
    </>
  );
};
