/* eslint-disable no-irregular-whitespace */
import { push } from 'connected-react-router';
import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import CopyToClipboard from 'react-copy-to-clipboard';
import { DialogActions } from '../../../../../redux/dialog/dialog.action';
import { MapActions } from '../../../../../redux/map/map.action';
import { RoutingPath } from '../../../../../routes/routing-pass';
import { ProjectListType } from '../../../../../type/project/project.type';
import { joinStr } from '../../../../../utilities/join-str';
import { noPinch } from '../../../../../utilities/no-pinch';
import { openLink } from '../../../../../utilities/open-link';
import { openTel } from '../../../../../utilities/open-tel';
import { ProjectEditSP } from '../../../../sp/pages/project/edit/project-edit.sp';
import { ProjectEditDialogTitle } from '../../../../sp/pages/project/edit/project-edit.type.sp';
import { Button } from '../../../button/button';
import { RectLabel } from '../../../label/rect-label/rect-label';
import projectUncontracted from '../../../../../asset/images/icon_project_uncontracted.svg';
import projectConstruction from '../../../../../asset/images/icon_project_construction.svg';
import projectEtc from '../../../../../asset/images/icon_project_etc.svg';
import projectOb from '../../../../../asset/images/icon_project_ob.svg';
import { ShowTypeLabel } from '../../../label/show-type/show-type-label';
import { IconButton } from '../../../button/icon-button/icon-button';
import './project-info-window.sp.scss';
import { StreetViewImg } from '../../../street-view-img/street-view-img';
import { SupportHistoryEditDialogTitle } from '../../../../sp/pages/support-history/edit/support-history-edit.type';
import { SupportHistoryEditSP } from '../../../../sp/pages/support-history/edit/support-history-edit.sp';
import { RouteDialog } from '../../../../dialogs/route-dialog/route-dialog';
import { useAppSelector } from '../../../../../hooks/use-redux';
import { useProjectView } from '../../../../../hooks/use-authority';
import { useMailContact, useSNSContact, useTelContact } from '../../../../../hooks/support-history/use-support-history';
import { isMobilePhone } from '../../../../../utilities/check-mobile-phone';

type project_info_window_spProps = {
  project: ProjectListType;
  callBack: () => void;
  callbackRegist?: (v: ProjectListType) => void;
  index: number;
  label?: string;
};

export const ProjectInfoWindowSP = (props: project_info_window_spProps) => {
  const {
    project, callBack, callbackRegist, index, label,
  } = props;
  const [detailFlag, setDetailFlag] = useState(false);
  const projectRankList = useAppSelector((v) => v.master.projectRankList);
  const routeAuth = useAppSelector((v) => v.user.company_authority3);
  const streetViewAuth = useProjectView();

  const ele = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const telContact = useTelContact();
  const contactSNS = useSNSContact();

  const handleClickArrow = useCallback(() => {
    if (detailFlag) {
      setDetailFlag(false);
      // callBack();
    } else {
      setDetailFlag(true);
    }
  }, [detailFlag]);

  const handleClickSupportHistory = useCallback(() => {
    if (callbackRegist) {
      callbackRegist(project);
    }
  }, [dispatch, callbackRegist, project]);

  const handleClickTel = useCallback(() => {
    telContact({
      type: 'project',
      data: project,
      isPushDialog: true,
    });
  }, [project]);

  const handleClickSMS = useCallback(() => {
    telContact({
      type: 'project',
      data: project,
      isSMS: true,
      isPushDialog: true,
    });
  }, [project]);

  const handleClickLine = useCallback(() => {
    if (!project || !project.line_id) return;
    contactSNS({
      mode: 'LINE',
      name: project.customer_name,
      customerId: project.customer_id,
      id: project.line_id,
    });
  }, [project]);

  const handeleClickHere = useCallback(() => {
    if (!routeAuth) return;
    dispatch(MapActions.setGpsStatus('out'));
    dispatch(DialogActions.push({
      title: 'ルート設定',
      className: 'max_height_dialog for_route',
      element: <RouteDialog
        type="customer"
        destination={project.field_place}
        callback={() => { }}
      />,
    }));
  }, [project.field_place, routeAuth]);

  useEffect(() => {
    const pinchCallback = noPinch(ele.current);
    return pinchCallback;
  }, [ele]);

  const statuses = useMemo(() => {
    let status = '';
    let img = '';
    let pClassName = '';
    switch (project.construction_flag) {
      case 3:
        status = '完工';
        img = projectOb;
        pClassName = 'project_ob';
        break;
      case 2:
        status = '工事中';
        img = projectConstruction;
        pClassName = 'project_construction';
        break;
      case 1:
        status = '未契約';
        img = projectUncontracted;
        pClassName = 'project_uncontracted';
        break;
      default:
        status = 'その他';
        img = projectEtc;
        pClassName = 'project_etc';
        break;
    }
    return ({ status, img, pClassName });
  }, [project.construction_flag]);

  const rankData = useMemo(() => (
    projectRankList.find((v) => Number(v.id) === Number(project.project_rank))
  ), [projectRankList, project.project_rank]);

  return (
    <div className={`project_info_window_sp info_window ${callbackRegist ? 'in_dialog' : ''}`} ref={ele}>
      <>
        <div className="info_window_arrow_btn" onClick={handleClickArrow}>
          <i className={`fas fa-angle-double-${!detailFlag ? 'up' : 'down'}`} />
        </div>
        <IconButton
          title="閉じる"
          fontAwesomeClass="fas fa-times"
          className="default info_window_close_btn"
          onClick={callBack}
        />
      </>
      {detailFlag ? (

        /* ===================== 詳細表示 ===================== */
        <div className="info_window_info detail">

          <div className="info_window_info_row row1">
            <div className="row1_col1">
              <div className="important">
                {project.name}
              </div>
              <div className="row_table_style">
                <div className="t_row">
                  <div className="t_header"><i className="fas fa-user" title="顧客名" /></div>
                  <div className="t_body">
                    {project.customer_name || '---'}{/* （{project.furigana || '---'}）*/}
                  </div>
                </div>
                <div className="t_row">
                  <div className="t_header"><i className="fas fa-map-marker-alt" title="住所" /></div>
                  <div className="t_body">
                    〒{joinStr(project.post_no, 3, '-') || '---'}<br />
                    {project.field_place || '---'}
                  </div>
                </div>
                <div className="t_row">
                  <div className="t_header"><i className="fas fa-wrench" title="現場名称" /></div>
                  <div className="t_body">{project.name || '---'}</div>
                </div>
                <div className="t_row">
                  <div className="t_header"><i className="fas fa-phone" title="TEL" /></div>
                  <div className="t_body">{project.field_tel_no || '---'}</div>
                  {/*
                  <div>
                    <IconButton
                      fontAwesomeClass="fas fa-phone"
                      onClick={handleClickTel}
                      size="md"
                      color="secondary"
                      disabled={!project.field_tel_no}
                    />
                    */}
                  {/*
                    <IconButton
                      color="secondary"
                      size="md"
                      onClick={handleClickSMS}
                      disabled={!project.field_tel_no || !isMobilePhone(project.field_tel_no)}
                      fontAwesomeClass="fas fa-sms"
                    />
                    */}
                </div>

                <div className="t_row">
                  <div className="t_header"><i className="fas fa-fax" /></div>
                  <div className="t_body">{project.field_fax_no || '---'}</div>
                </div>

                <div className="t_row">
                  <div className="t_header"><i className="far fa-building" title="担当店舗" /></div>
                  <div className="t_body">{project.project_store_name || '---'}</div>
                </div>

                <div className="t_row">
                  <div className="t_header"><i className="fas fa-user-circle" title="担当者" /></div>
                  <div className="t_body">{project.project_employee_name || '---'}</div>
                </div>

              </div>
            </div>

            <div className="row1_col2">
              <div className="thumb">
                <StreetViewImg
                  isShow={streetViewAuth(index)}
                  lat={project.lat}
                  lng={project.lng}
                />
              </div>
              <ShowTypeLabel
                label={statuses.status}
                showTypeImg={statuses.img}
                className={statuses.pClassName}
              />
              <RectLabel
                label={rankData?.abbreviation || 'ランクなし'}/* ★案件見込みランク略称abbreviation */
                bgColor={rankData?.background_color}
                color={rankData?.text_color}
              />
            </div>
          </div>
          {/* </div>// <div className="info_window_info_row row2">
      //   <Button
      //     color="secondary"
      //     size="md"
      //     onClick={() => {
      //       dispatch(push(`${RoutingPath.projectDetail}/${project.id}`));
      //     }}
      //   >案件詳細
      //   </Button>

      //   <Button
      //     color="secondary"
      //     size="md"
      //     onClick={() => dispatch(DialogActions.push({
      //       title: ProjectEditDialogTitle.update,
      //       element: <ProjectEditSP id={project.id} />,
      //     }))}
      //   >案件編集
      //   </Button>
      // </div>
      // </div>
                  */}
        </div>

      ) : (
        /* ===================== 簡易表示 ===================== */
        <div className="info_window_info simple" style={callbackRegist && { paddingTop: 0 }}>
          <div className="info_window_info_row row1">
            <div className="row1_col1" style={callbackRegist && { paddingTop: '0.75rem' }}>
              <div className="important">
                {project.name}
              </div>
              <div className="row_table_style">
                <div className="t_row">
                  <div className="t_header"><i className="fas fa-user" title="顧客名" /></div>
                  <div className="t_body">
                    {project.customer_name} {/* `(${project.furigana})`*/}
                  </div>
                </div>
                <div className="t_row">
                  <div className="t_header"><i className="fas fa-map-marker-alt" title="住所" /></div>
                  <div className="t_body">
                    〒{joinStr(project.post_no, 3, '-') || '---'}<br />
                    {project.field_place || '---'}
                  </div>
                </div>
                <div className="t_row">
                  <div className="t_header"><i className="fas fa-phone" title="TEL" /></div>
                  <div className="t_body">{project.field_tel_no || '---'}</div>
                  {/*
                  <div>
                    <IconButton
                      fontAwesomeClass="fas fa-phone"
                      onClick={handleClickTel}
                      size="md"
                      color="secondary"
                      disabled={!project.field_tel_no}
                    />
                    */}
                  {/*
                    <IconButton
                      color="secondary"
                      size="md"
                      onClick={handleClickSMS}
                      disabled={!project.field_tel_no || !isMobilePhone(project.field_tel_no)}
                      fontAwesomeClass="fas fa-sms"
                    />
                    */}
                </div>
                <div className="t_row">
                  <div className="t_header"><i className="fas fa-user-circle" title="案件担当者" /></div>
                  <div className="t_body">{project.project_employee_name || '---'}</div>
                </div>
              </div>
            </div>

            <div className="row1_col2" style={callbackRegist && { paddingTop: '0.75rem' }}>
              <div className="thumb">
                <StreetViewImg
                  isShow={streetViewAuth(index)}
                  lat={project.lat}
                  lng={project.lng}
                />
              </div>
              <ShowTypeLabel
                label={statuses.status}
                showTypeImg={statuses.img}
                className={statuses.pClassName}
              />
              <RectLabel
                label={rankData?.abbreviation || 'ランクなし'}/* ★案件見込みランク略称abbreviation */
                bgColor={rankData?.background_color}
                color={rankData?.text_color}
              />
            </div>
          </div>
        </div>

      )}

      <div className="info_window_footer scroll">
        {!callbackRegist
          && (
            <>
              <Button
                color="secondary"
                size="md"
                onClick={() => dispatch(push(`${RoutingPath.projectDetail}/${project.id}`))}
              >案件詳細
              </Button>

              <Button
                color="secondary"
                size="md"
                onClick={() => dispatch(DialogActions.push({
                  title: ProjectEditDialogTitle.update,
                  element: <ProjectEditSP id={project.id} />,
                }))}
              >案件編集
              </Button>
              <Button className="icon_btn" color="primary" size="sm" onClick={handleClickTel} disabled={!project.field_tel_no}>
                <i className="fas fa-phone" />
              </Button>
              <Button className="icon_btn" color="primary" size="sm" onClick={handleClickSMS} disabled={!project.field_tel_no || !isMobilePhone(project.field_tel_no)}>
                <i className="fas fa-sms" />
              </Button>
              <CopyToClipboard text={project.line_id || ''}>
                <Button className="icon_btn" color="primary" size="sm" onClick={handleClickLine} disabled={!project.line_id}>
                  <i className="fab fa-line" />
                </Button>
              </CopyToClipboard>
              <Button color="primary" size="md" onClick={handeleClickHere} disabled={!routeAuth}>ここへ行く</Button>
            </>
          )}
        {callbackRegist
          && <Button color="primary" size="md" onClick={handleClickSupportHistory}>{label || '対応登録'}</Button>}
      </div>
    </div>
  );
};
