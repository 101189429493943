import { CustomerImportPostData, ImportFileUploadResult } from '../../../../../../redux/master/api/customer-import/api-customer-import.type';
import { MathHelper } from '../../../../../../utilities/math-helper';

export class MasterCustomerInfoImportCollection {
  static successListHeader = [
    '行番号',
    '顧客名',
    '郵便番号',
    '都道府県',
    '市区町村',
    '地名・番地',
  ]

  static postParam(param:ImportFileUploadResult['data'][0]):CustomerImportPostData {
    return ({
      name: param.name || '',
      furigana: param.furigana || '',
      tel_no: param.tel_no || '',
      tel_no2: param.tel_no2 || '',
      tel_no3: param.tel_no3 || '',
      post_no: param.post_no || '',
      prefecture: param.prefecture || '',
      city: param.city || '',
      address: param.address || '',
      building_name: param.building_name || '',
      mail_address: param.mail_address || '',
      mail_address2: param.mail_address2 || '',
      mail_address3: param.mail_address3 || '',
      area_id: param.area_id || '',
      rank: param.rank || '',
      estimated_rank: param.estimated_rank || '',
      tag_list: param.tag_list || '',
      tag_list_other: param.tag_list_other || '',
      line_id: param.line_id || '',
      facebook_id: param.facebook_id || '',
      twitter_id: param.twitter_id || '',
      instagram_id: param.instagram_id || '',
      building_category_id: param.building_category_id || '',
      madori_id: param.madori_id || '',
      construction_info: param.construction_info || '',
      introducer: param.introducer || '',
      wedding_anniversary: param.wedding_anniversary || '',
      source_id: param.source_id || '',
      expected_part_list: param.expected_part_list || '',
      part_list: param.part_list || '',
      employee_mail_address: param.employee_mail_address || '',
      remarks: param.remarks || '',
      memo1: param.memo1 || '',
      memo2: param.memo2 || '',
      lat: param.lat ? `${MathHelper.rounding(Number(param.lat), 6)}` : '',
      lng: param.lng ? `${MathHelper.rounding(Number(param.lng), 6)}` : '',
    });
  }
}
