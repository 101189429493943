/* eslint-disable */
import { useCallback, useState } from 'react';
// import { useSelector } from 'react-redux';
// import { State } from '../../../../../../redux/root.reducer';
// import { FamilyInfoCard } from '../../../../../ui/card/family-info/family-info-card';
import { CustomerCollection } from '../../../../../../collection/customer/customer.collection';
import { Table } from '../../../../../ui/table/table';
import { DateFormatter } from '../../../../../../utilities/date-formatter';

type Props = {
  list: any[];
  callback: (id: number) => void;
  callbackSort?: (order: number, index: number) => void;
  // isInDialog?: boolean;
}

export const FamilyInfoListSP = (props:Props) => {
  const {
    list,
    callback,
    callbackSort,
    // isInDialog,
  } = props;

  /* Hooks */
  // const { familyList } = useSelector((state: State) => ({
  //   familyList: state.customer.familyList,
  // }));

  const [isActive, setIsActive] = useState(NaN);

  const handleClickCard = useCallback(
    (index:number) => {
      callback(index);
      setIsActive(list.findIndex((v) => v.index === index));
      // console.log(isInDialog);
    },
    [list],
  );

  return (
    // <div className="list_base family_info_list_sp">
    //   {list.map((v, i) => (
    //     <div
    //       key={`fcard${i}`}
    //       className="list_base_card_wrap"
    //       onClick={() => {
    //         if (isInDialog) {
    //           handleClickCard(v.family_id);
    //         }
    //       }}
    //     >
    //       <FamilyInfoCard
    //         familyInfo={v}
    //         isActive={v.family_id === isActive}
    //       />
    //     </div>
    //   ))}
    // </div>
    <section className="list_area">
      <div className="inner">
        <div className="table_responsive">
          <Table
            className="table_selectable table_sticky"
            header={CustomerCollection.familyHeader}
            rowDataList={list}
            selectedTr={[isActive]}
            sort={{
              index: [],
              onClick: (order, index) => {
                if (callbackSort)callbackSort(order, index);
              },
            }}
            onClickRow={(v) => { handleClickCard(Number(v.index)); }}
            lists={list.map((v) => (
              [
                v.name,
                v.relationship,
                v.mobile_phone,
                DateFormatter.date2str(v.birth_date),
              ]
            ))}
            option={{
              stringWidth: [
                { index: 1, width: 50 }, // 続柄
                { index: 3, width: 100 }, // 生年月日
              ],
              tdAlign: [
                { index: 0, align: 'center' },
                { index: 1, align: 'center' },
                { index: 2, align: 'center' },
                { index: 3, align: 'center' },
              ],
            }}
          />
        </div>
      </div>
    </section>
  );
};

FamilyInfoListSP.defaultProp = { isInDialog: false };
