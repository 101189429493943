import cloneDeep from 'lodash/cloneDeep';
import { EditPriceAreaState } from '../../../../../../type/estimate/estimate.type';

export class EstimatePriceAreaCollectionPC {
  // private static _initialEditState() {
  //   return ({
  //     estimate_price: 0,
  //     estimate_total_price: 0,
  //     estimate_tax: 0,
  //     genka_price: 0,
  //     genka_total_price: 0,
  //     genka_tax: 0,
  //     arari_price: 0,
  //     arari_percent: 0,
  //     estimate_total_price_tax_in: 0,
  //     genka_total_price_tax_in: 0,
  //     adjustment_amount: 0,
  //     genba_estimate_price: 0,
  //     field_cost_quote: 5,
  //     genba_genka_price: 0,
  //     field_cost: 0,
  //     yobi_estimate_price: 0,
  //     call_cost_quote: 0,
  //     yobi_genka_price: 0,
  //     call_cost: 2.2,
  //   });
  // }

  private static _initialEditState() {
    return ({
      /**  税抜合計金額（見積金額）*/
      zeinuki_kin: 0,
      /**  消費税額*/
      shohizei_kin: 0,
      /**  見積合計金額(税抜)（見積合計）*/
      gokei_zeinuki_kin: 0,
      /**  原価合計  税抜き（原価金額）*/
      genka_kin: 0,
      /**  原価合計金額(税抜)  （原価合計）*/
      gokei_zeinuki_genka_kin: 0,
      /**  原価消費税額*/
      genka_shohizei_kin: 0,
      /**  税込合計原価金額（税込合計原価金額）*/
      gokei_genka_kin: 0,
      /* 粗利 */
      arari_price: 0,
      /* 粗利パーセント */
      arari_percent: 0,
      /**  合計金額（税込合計見積金額）*/
      gokei_kin: 0,
      /**  調整額（値引き）*/
      tyosei_kin: 0,
      /**  予備費1_見積金額（現場協力費 見積金額）*/
      reserve1_estimate_kin: 0,
      /**  予備費1_見積パーセント（現場協力費 見積％）*/
      reserve1_estimate_percent: 5,
      /**  "予備費1_原価金額（現場協力費 原価金額）"*/
      reserve1_genka_kin: 0,
      /**  "予備費1_原価パーセント（現場協力費 原価％）"*/
      reserve1_genka_percent: 0,
      /**  "予備費2_見積金額（予備原価 見積金額）"*/
      reserve2_estimate_kin: 0,
      /**  "予備費2_見積パーセント（予備原価 見積％）"*/
      reserve2_estimate_percent: 0,
      /**  "予備費2_原価金額（予備原価 原価金額）"*/
      reserve2_genka_kin: 0,
      /**  "予備費2_原価パーセント（予備原価 原価％）"*/
      reserve2_genka_percent: 2.2,
    });
  }

  static get initialEditState():EditPriceAreaState {
    return cloneDeep(EstimatePriceAreaCollectionPC._initialEditState());
  }
}
