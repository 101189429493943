import actionCreatorFactory from 'typescript-fsa';
import { ApiBase } from '../../../../service/api-base';
import { CsvSupportHistoryType } from '../../../../type/csv/csv.type';

/* Param */
export type ApiCsvSupportHistoryGetListParam = Partial<{
  /** 受付日 */
  contact_dt: string;
  /** カテゴリ */
  support_id: number;
  /** 媒体 */
  source_id: number;
  /** 対応区分 */
  support_flag: number;
  /** 文字列検索 */
  word: string;
  /** 顧客名 */
  customer_name: string;
  /** 顧客担当店舗 */
  customer_store_id: number;
  /** 顧客担当者 */
  customer_employee_id: number;
  /** 対応日 */
  support_dt: string;
  /** 対応担当店舗 */
  support_store_id: number;
  /** 対応担当者 */
  support_employee_id: number;
  /** オフセット */
  offset: number;
  /** 1ページ内の件数 */
  limit: number;
  /** 並替基準列 */
  sort_by: number;
  /** 並替方法 */
  highlow: number;
  /** 登録店舗 */
  created_store_id:number;
  /** 登録社員 */
  created_employee_id:number;
  /** 更新店舗 */
  updated_store_id:number;
  /** 更新社員 */
  updated_employee_id:number;
  /** 登録日－期間開始 */
  created_at_start:string;
  /** 登録日－期間終了 */
  created_at_end:string;
  /** 更新日－期間開始 */
  updated_at_start:string;
  /** 更新日－期間終了 */
  updated_at_end:string;
}>;

export type ApiCsvSupportHistoryDownloadParam = any;

/* Response */
export type ApiCsvSupportHistoryGetListResponse = CsvSupportHistoryType;

export const ActionCreator = actionCreatorFactory('csv/api/support/history');

export const apiCsvSupportHistory = {
  getList: ActionCreator<ApiCsvSupportHistoryGetListParam>('get/list'),
  download: ActionCreator<ApiCsvSupportHistoryDownloadParam>('download'),
};

export class ApiCsvSupportHistoryGetList extends ApiBase<ApiCsvSupportHistoryGetListResponse> {
  constructor(param: ApiCsvSupportHistoryGetListParam) {
    super({
      httpMethod: 'GET',
      param,
      url: '/api/csv/supported',
    });
  }
}

export class ApiCsvSupportHistoryDownload extends ApiBase {
  constructor(param: ApiCsvSupportHistoryDownloadParam) {
    super({
      httpMethod: 'GET',
      contents: 'BLOB',
      param,
      url: '/api/csv/download/supported',
    });
  }
}
