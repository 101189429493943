/* eslint-disable no-nested-ternary */
import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  DropTargetMonitor, useDrag, useDrop, XYCoord,
} from 'react-dnd';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import { DialogActions } from '../../../../redux/dialog/dialog.action';
import { EstimateActions } from '../../../../redux/estimate/estimate.action';
import { EstimateMeisaiListType } from '../../../../type/estimate/estimate-meisai.type';
import { MathHelper as MH } from '../../../../utilities/math-helper';
import { UserAgent } from '../../../../utilities/user-agent';
import { EstimateDetailInfoSP } from '../../../sp/pages/estimate-detail/info/estimate-detail-info.sp';
import { AngleIcon } from '../../angle-icon/angle-icon';
import './estimate-detail-card.scss';
import { MeisaiListXML } from '../../../../model/estimate/estimate-meisai.type';
import { Button } from '../../button/button';
import { Checkbox } from '../../checkbox/checkbox';

type Props = {
  isDnd?: boolean;
  className?: string;
  data: MeisaiListXML;
  id?: number;
  index?: number;
  onClick: () => void;
  onRemove: () => void;
  onHover?: (draggedId: number, id: number) => void;
  onDrop?: (draggedId: number, id: number) => void;
  allowEdit?: 0 | 1 | 2;
  isActive?: boolean;
  isMultiEdit?: boolean;
  checked?: boolean;
  onClickCheck?: (v: MeisaiListXML) => void;
  isCopy?: boolean;
}

type DragItem = {
  index: number
  id: number
  type: string
}

export const EstimateDetailCard = (props: Props) => {
  const {
    isDnd,
    data,
    className,
    onClick,
    onRemove,
    onHover,
    onDrop,
    index,
    id,
    allowEdit,
    isActive,
    isMultiEdit,
    checked,
    onClickCheck,
    isCopy,
  } = props;

  /* Hooks */
  const baseClassName = 'estimate_detail_card';
  const defaultClassName = UserAgent === 'sp' ? `${baseClassName}_sp` : `${baseClassName}_pc`;

  /* callback */
  const handleClickInfo = useCallback(() => {
    onClick();
  }, [onClick, isMultiEdit]);

  const handleClickRemove = useCallback((e: globalThis.React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    onRemove();
  }, [onRemove]);

  const ref = useRef<HTMLDivElement>(null);

  const [draggingId, setDraggingId] = useState(0);
  const [, drag] = useDrag({
    type: 'detail-card',
    item: {
      id,
      index,
      name: data.$.name,
      shikiri_kakaku: data.$.shikiri_kakaku,
      tani_name: data.$.tani_name,
      kingaku: data.$.kingaku,
      suryou: data.$.suryou,
    },
    collect: (monitor) => {
      setDraggingId(monitor.getItem()?.id || 0);
      return ({
        isDragging: monitor.isDragging(),
        draggingId: monitor.didDrop(),
      });
    },
    end: () => {
      if (onDrop) onDrop(NaN, NaN);
    },
  });

  const [handlerId, drop] = useDrop({
    accept: 'detail-card',
    collect: (monitor) => ({
      hndlreID: monitor.getHandlerId(),
      isOverCurrent: monitor.isOver({ shallow: true }),
    }),
    hover: (item: DragItem, monitor: DropTargetMonitor) => {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if ((dragIndex === undefined || hoverIndex === undefined)
        || (dragIndex === hoverIndex)) return;

      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      if ((dragIndex < hoverIndex && hoverClientY < hoverMiddleY)
        || (dragIndex > hoverIndex && hoverClientY > hoverMiddleY)) return;

      if (onHover) onHover(dragIndex, hoverIndex);
      // eslint-disable-next-line no-param-reassign
      item.index = hoverIndex;
    },

    drop(item: DragItem, monitor: DropTargetMonitor) {
      setDraggingId(0);
      const dragIndex = item.index;
      const hoverIndex = index || NaN;
      if (onDrop) onDrop(dragIndex, hoverIndex);
    },
  });

  useEffect(() => {
    drag(drop(isDnd ? ref : null));
  }, [isDnd]);

  return (
    <div
      // data-handler-id={handlerId}
      className={`${defaultClassName} ${className || ''}${isDnd ? ' dnd' : ''}${draggingId === id ? ' dnd_target' : ''}`}
      onClick={() => handleClickInfo()}
      style={isActive ? { backgroundColor: '#FFF9D9' } : {}}
    >
      {/* TODO row1, row2 の日付と顧客名の切り替えについて確認。条件分岐？DOMを分ける？両方記載していいか？ */}
      {allowEdit !== 1 ? (<div className="col1" />) : (
        !isDnd
          ? !isMultiEdit
            ? (
              <div className="col1">
                <i
                  className="fas fa-minus-circle fa-lg"
                  onClick={handleClickRemove}
                />
              </div>
            ) : (
              <div className="col1">
                <Checkbox
                  checked={checked}
                  onClick={(e) => {
                    e.stopPropagation();
                    onClickCheck?.(data);
                  }}
                  isStop
                />
              </div>
            ) : (
              <>
                <div
                  ref={ref}
                  data-handler-id={handlerId}
                  className="dnd_handler"
                />
                <div className="col1">
                  <DragHandleIcon />
                </div>
              </>
          )
      )}
      <div className="col2">
        <div className="col2__row1">{data.$.name || '---'}</div>
        <div className="col2__row2">
          <div className="col2__row2__con1">
            <span className="nowrap">
              &yen;&nbsp;
              {MH.rounding2Str(data.$.shikiri_kakaku ? Number(data.$.shikiri_kakaku) : 0, 0, 'round', true)}
            </span>
            &nbsp;×&nbsp;
            <span className="nowrap">
              {MH.rounding2Str(data.$.suryou ? Number(data.$.suryou) : 0, 2, 'round', true)}
              {data.$.tani_name}
            </span>
          </div>
          <div className="col2__row2__con2">
            <span className="nowrap">
              &yen;&nbsp;
              {MH.rounding2Str(data.$.kingaku ? Number(data.$.kingaku) : 0, 0, 'round', true)}
            </span>
          </div>
        </div>
      </div>
      {allowEdit === 1
        ? isDnd ? (<></>)
          : (
            <div className="col3">
              <AngleIcon direction="right" />
            </div>
          )
        : <></>}
    </div>
  );
};
