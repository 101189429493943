import { cloneDeep } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { EstimateCalcModelPC as Calc } from '../../../../../collection/estimate/estimate-calc.model.pc';
import { NM } from '../../../../../model/num-model';
import { ValidationNumberLengthUnder20, ValidationNumberLengthUnder5 } from '../../../../../model/validation/validation-number-length-under';
import { DialogActions } from '../../../../../redux/dialog/dialog.action';
import { GetTax } from '../../../../../redux/master/api/master-tax/api-master-tax';
import { EditPriceAreaState, Estimate } from '../../../../../type/estimate/estimate.type';
import { MathHelper as MH } from '../../../../../utilities/math-helper';
import { Button } from '../../../../ui/button/button';
import { RightLabelInputField } from '../../../../ui/input-field/right-label-input-field/right-label-input-field';
import { Input } from '../../../../ui/input/input';
import { InputNum } from '../../../../ui/input/input-num';
import './detail-price-edit.sp.scss';
import { EstimateMeisaiModel } from '../../../../../model/estimate/estimate-meisai.model';
import { ListItem } from '../../../../pc/pages/master/master-body/master-quote-fixed-body';
import { ValidationMax100Thousand } from '../../../../../model/validation/validation-max-100-thousand';
import { ValidationMax10Billion } from '../../../../../model/validation/validation-max-10-billion';
import { MasterActions } from '../../../../../redux/master/master.action';
import { DateFormatter } from '../../../../../utilities/date-formatter';

type Props = {
  info: Estimate | null;
  isEdit?: boolean;
  estimateDt: Date | null;
  state: EditPriceAreaState;
  model: EstimateMeisaiModel | null;
  callback: (v: EditPriceAreaState) => void;
  allowEdit: 0 | 1 | 2;
  reserve1: ListItem | null;
  reserve2: ListItem | null;
}

/** 見積金額編集 SP */
export const DetailPriceEditSP = (props:Props) => {
  const {
    info,
    estimateDt,
    state: priceModel,
    model,
    callback,
    allowEdit = true,
    reserve1,
    reserve2,
    isEdit,
  } = props;

  const dispatch = useDispatch();
  const [state, setState] = useState(cloneDeep(priceModel));
  const [tax, setTax] = useState(0);
  const [tyoseikin, setTyoseikin] = useState(
    String(state.tyosei_kin),
  );
  const [reserve1EstimatePercent, setReserve1EstimatePercent] = useState(
    String(state.reserve1_estimate_percent),
  );
  const [reserve1GenkaPercent, setReserve1GenkaPercent] = useState(
    String(state.reserve1_genka_percent),
  );
  const [reserve2EstimatePercent, setReserve2EstimatePercent] = useState(
    String(state.reserve2_estimate_percent),
  );
  const [reserve2GenkaPercent, setReserve2GenkaPercent] = useState(
    String(state.reserve2_genka_percent),
  );

  /* 金額計算 */
  const calc = useCallback(() => {
    if (!model) return;

    const res = Calc.calc(state, model.list, tax);
    if (!isEdit) { callback(cloneDeep(res)); }

    setState(cloneDeep(res));
  }, [estimateDt, state, model, tax, isEdit, priceModel]);

  /* 更新 */
  useEffect(() => {
    dispatch(MasterActions.api.tax.getSoon({
      param: {
        start_date: DateFormatter.date2str(estimateDt),
      },
      onSuccess: (res) => {
        setTax(res.tax_rate);
      },
    }));
  }, [estimateDt]);

  const update = useCallback(() => {
    dispatch(DialogActions.pop());
    callback(cloneDeep(state));
  }, [state]);

  // useEffect(() => {
  //   if (isEdit) return;
  //   setState(cloneDeep(priceModel));
  // }, [priceModel, model, info, tax]);

  // useEffect(() => {
  //   if (!isEdit) return;
  //   setState(cloneDeep(priceModel));
  // }, [priceModel, tax]);

  useEffect(() => {
    calc();
  }, [estimateDt, model, info, tax]);

  useEffect(() => {
    if (isEdit) return;
    console.log('priceModel', priceModel);

    setState(cloneDeep(priceModel));
  }, [priceModel]);

  useEffect(() => {
    setTyoseikin(Calc.setInfoNum(info?.tyosei_kin, 0));
  }, [info?.tyosei_kin]);
  useEffect(() => {
    if (reserve1)setReserve1EstimatePercent(String(reserve1.$.estimate));
    if (info)setReserve1EstimatePercent(Calc.setInfoNum(info?.reserve1_estimate_percent, 2));
  }, [info?.reserve1_estimate_percent, reserve1]);
  useEffect(() => {
    if (reserve1)setReserve1GenkaPercent(String(reserve1?.$.genka));
    if (info)setReserve1GenkaPercent(Calc.setInfoNum(info?.reserve1_genka_percent, 2));
  }, [info?.reserve1_genka_percent, reserve1]);
  useEffect(() => {
    if (reserve2)setReserve2EstimatePercent(String(reserve2?.$.estimate));
    if (info)setReserve2EstimatePercent(Calc.setInfoNum(info?.reserve2_estimate_percent, 2));
  }, [info?.reserve2_estimate_percent, reserve2]);
  useEffect(() => {
    if (reserve2)setReserve2GenkaPercent(String(reserve2?.$.genka));
    if (info) setReserve2GenkaPercent(Calc.setInfoNum(info?.reserve2_genka_percent, 2));
  }, [info?.reserve2_genka_percent, reserve2]);

  return (
    <>
      <div className="base_dialog_content_inner_body detail_price_edit_sp">
        <div className="row_table_style">
          <div className="t_row">
            <div className="t_header">見積金額</div>
            <div className="t_body">
              <Input
                value={MH.rounding2Str(state.zeinuki_kin, 0, 'round', true)}
                disabled
                alignRight
              />
            </div>
          </div>
          <div className="t_row">
            <div className="t_header">見積合計</div>
            <div className="t_body">
              <Input
                value={MH.rounding2Str(state.gokei_zeinuki_kin, 0, 'round', true)}
                disabled
                alignRight
              />
            </div>
          </div>
          <div className="t_row">
            <div className="t_header">消費税額</div>
            <div className="t_body">
              <Input
                value={MH.rounding2Str(state.shohizei_kin, 0, 'round', true)}
                disabled
                alignRight
              />
            </div>
          </div>
          <div className="t_row">
            <div className="t_header">税込合計<br />見積金額</div>
            <div className="t_body">
              <Input
                value={MH.rounding2Str(state.gokei_kin, 0, 'round', true)}
                disabled
                alignRight
              />
            </div>
          </div>
          <div className="t_row">
            <div className="t_header">調整金額<br />(値引き)</div>
            <div className="t_body">
              <InputNum
                value={tyoseikin}
                onBlur={(v) => {
                  setTyoseikin(NM.setBlurNum(v, 0));
                  calc();
                }}
                onChange={(v) => {
                  setTyoseikin(v);
                  state.tyosei_kin = Calc.onChangeNum(v);
                  setState(cloneDeep(state));
                }}
                validationList={ValidationMax10Billion}
                disabled={allowEdit !== 1}
                intLen={10}
                minus
              />
            </div>
          </div>
          <div className="t_row">
            <div className="t_header">原価金額</div>
            <div className="t_body">
              <Input
                value={MH.rounding2Str(state.genka_kin, 0, 'round', true)}
                disabled
                alignRight
              />
            </div>
          </div>
          <div className="t_row">
            <div className="t_header">原価合計</div>
            <div className="t_body">
              <Input
                value={MH.rounding2Str(state.gokei_zeinuki_genka_kin, 0, 'round', true)}
                disabled
                alignRight
              />
            </div>
          </div>
          <div className="t_row">
            <div className="t_header">消費税額</div>
            <div className="t_body">
              <Input
                value={MH.rounding2Str(state.genka_shohizei_kin, 0, 'round', true)}
                disabled
                alignRight
              />
            </div>
          </div>
          <div className="t_row">
            <div className="t_header">税込合計<br />原価金額</div>
            <div className="t_body">
              <Input
                value={MH.rounding2Str(state.gokei_genka_kin, 0, 'round', true)}
                disabled
                alignRight
              />
            </div>
          </div>
          <div className="t_row">
            <div className="t_header">粗利金額</div>
            <div className="t_body">
              <Input
                label=""
                value={MH.rounding2Str(state.arari_price, 0, 'round', true)}
                disabled
                alignRight
              />
            </div>
          </div>
          <div className="t_row">
            <div className="t_header">粗利率</div>
            <div className="t_body">
              <RightLabelInputField
                label="%"
                type="number"
                value={state.arari_percent.toLocaleString()}
                className="small"
                disabled
                alignRight
              />
            </div>
          </div>
          <div className="t_row genba_support_price">
            <div className="t_header">現場協力費</div>
            <div className="t_body">
              <div>
                <span>見積</span>
                <Input
                  value={MH.rounding2Str(state.reserve1_estimate_kin, 0, 'round', true)}
                  disabled
                  alignRight
                />
                <InputNum
                  value={reserve1EstimatePercent}
                  onBlur={(v) => {
                    setReserve1EstimatePercent(NM.setBlurNum(v, 2));
                    calc();
                  }}
                  onChange={(v) => {
                    console.log(v);
                    setReserve1EstimatePercent(v);
                    state.reserve1_estimate_percent = Calc.onChangeNum(v);
                    setState(cloneDeep(state));
                  }}
                  maxLength={5}
                  disabled={allowEdit !== 1}
                  minus
                />
                <span>％</span>
              </div>
              <div>
                <span>原価</span>
                <Input
                  value={MH.rounding2Str(state.reserve1_genka_kin, 0, 'round', true)}
                  disabled
                  alignRight
                />
                <InputNum
                  value={reserve1GenkaPercent}
                  onBlur={(v) => {
                    setReserve1GenkaPercent(NM.setBlurNum(v, 2));
                    calc();
                  }}
                  onChange={(v) => {
                    setReserve1GenkaPercent(v);
                    state.reserve1_genka_percent = Calc.onChangeNum(v);
                    setState(cloneDeep(state));
                  }}
                  disabled={allowEdit !== 1}
                  maxLength={5}
                  minus
                />
                <span>％</span>
              </div>
            </div>
          </div>
          <div className="t_row yobi_genka">
            <div className="t_header">予備原価</div>
            <div className="t_body">
              <div>
                <span>見積</span>
                <Input
                  value={MH.rounding2Str(state.reserve2_estimate_kin, 0, 'round', true)}
                  disabled
                  alignRight
                />
                <InputNum
                  value={reserve2EstimatePercent}
                  onBlur={(v) => {
                    setReserve2EstimatePercent(NM.setBlurNum(v, 2));
                    calc();
                  }}
                  onChange={(v) => {
                    setReserve2EstimatePercent(v);
                    state.reserve2_estimate_percent = Calc.onChangeNum(v);
                    setState(cloneDeep(state));
                  }}
                  disabled={allowEdit !== 1}
                  maxLength={5}
                  minus
                />
                <span>％</span>
              </div>
              <div>
                <span>原価</span>
                <Input
                  value={MH.rounding2Str(state.reserve2_genka_kin, 0, 'round', true)}
                  disabled
                  alignRight
                />
                <InputNum
                  value={reserve2GenkaPercent}
                  onBlur={(v) => {
                    setReserve2GenkaPercent(NM.setBlurNum(v, 2));
                    calc();
                  }}
                  onChange={(v) => {
                    setReserve2GenkaPercent(v);
                    state.reserve2_genka_percent = Calc.onChangeNum(v);
                    setState(cloneDeep(state));
                  }}
                  validationList={ValidationMax100Thousand}
                  disabled={allowEdit !== 1}
                  maxLength={5}
                  minus
                />
                <span>％</span>
              </div>
            </div>
          </div>

        </div>
      </div>
      {isEdit
      && (
      <div className="base_dialog_content_inner_footer">
        <Button
          size="md"
          color="secondary"
          className="item_btn_right"
          onClick={update}
        >
          更新
        </Button>

        <Button
          size="md"
          color="dark"
          className="item_btn_right"
          onClick={() => dispatch(DialogActions.pushCheck({
            callback: () => {
              dispatch(DialogActions.pop());
            },
          }))}
        >
          戻る
        </Button>
      </div>
      )}
    </>
  );
};
