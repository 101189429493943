import {
  memo, useCallback, useEffect, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { Dimmer, Loader } from 'semantic-ui-react';
import cloneDeep from 'lodash/cloneDeep';
import NoImage from '../../../asset/images/noimage.svg';
import { DialogActions } from '../../../redux/dialog/dialog.action';
import { ShowImageDialog } from '../../dialogs/show-image-dialog/show-image-dialog';
import { ApiFileGetImg } from '../../../redux/file/api/img/api-file-img';
import { Thumbnails } from './thumbanils';
import { FileActions } from '../../../redux/file/file.action';
import { useAppSelector } from '../../../hooks/use-redux';
import { ApiFileDownload } from '../../../redux/file/api/download/api-file-download';

export const ShowThumType = [
  'png',
  'jpg',
  'jpeg',
  'PNG',
  'JPG',
  'JPEG',
];

type Props = {
  id: number | undefined;
  format: string | undefined;
  fileName: string;
  stopLoad?: boolean;
  sizeX?: number;
  sizeY?: number;
  shadow?: boolean;
}

export const LoadingFileImg = (props: Props) => {
  const {
    id,
    sizeX,
    sizeY = 100,
    shadow = false,
    format,
    stopLoad,
    fileName,
  } = props;

  const [img, setImg] = useState<string | null>(null);
  const [apis, setApis] = useState<ApiFileGetImg | null>(null);
  const [pdf, setPdf] = useState<string | null>(null);
  const [init, setInit] = useState(false);
  const [isReload, setIsReload] = useState(false);

  const dispatch = useDispatch();

  const clickImg = useCallback(() => {
    if (!img) return;
    if (format && ShowThumType.includes(format)) {
      dispatch(DialogActions.push({
        title: fileName || '',
        element: <ShowImageDialog src={img} />,
        outPop: true,
      }));
    } else if (format?.toLocaleLowerCase() === 'pdf') {
      if (!id || !pdf) return;
      window.open(pdf);
    }
  }, [img, pdf, stopLoad]);

  const setThumbnail = useCallback(() => {
    const find = Thumbnails.find((v) => v.name === format)?.thumb;
    setImg(find || NoImage);
    setIsReload(false);
  }, [format]);

  const checkImg = useCallback(() => {
    if (!format) return true;
    return ShowThumType.includes(format);
  }, [format]);

  useEffect(() => {
    setIsReload(true);
    let url = '';
    let time: globalThis.NodeJS.Timeout | null = null;
    // IDがそもそもない
    if (!id) {
      setImg('');
      return () => { };
    }

    /* サムネイルを表示する形式かチェック */
    if (!checkImg()) {
      if (time) clearTimeout(time);
      setThumbnail();
      if (format?.toLocaleLowerCase() !== 'pdf') {
        return () => { };
      }
    }
    const api = (format?.toLocaleLowerCase() !== 'pdf')
      ? new ApiFileGetImg({ id }) : new ApiFileDownload({ file_id: id });
    if (!stopLoad || !init) {
      setInit(true);
      setApis(api);
      time = setTimeout(() => {
        if (format?.toLocaleLowerCase() !== 'pdf') {
          api.run()
            .then((v) => {
              if (time) clearTimeout(time);
              if (!v?.header) {
                url = URL.createObjectURL(v.file);
                setImg(url);
                setIsReload(false);
              }
            })
            .catch(() => {
              if (time) clearTimeout(time);
              setImg('');
            });
        } else if (format?.toLocaleLowerCase() === 'pdf') {
          api.run()
            .then((v) => {
              if (time) clearTimeout(time);
              if (!v?.header) {
                url = URL.createObjectURL(v.file);
                setPdf(url);
                setIsReload(false);
              }
            })
            .catch(() => {
              if (time) clearTimeout(time);
            });
        }
      }, 500);
    }
    return () => {
      if (time) clearTimeout(time);
      setImg('');
      URL.revokeObjectURL(url);
      api.controller?.abort();
    };
  }, [id, format, stopLoad]);

  useEffect(() => {
    if (stopLoad) {
      // setImg('');
      apis?.controller?.abort();
    }
  }, [stopLoad]);

  return (
    <div onClick={(e) => {
      e.stopPropagation();
      clickImg();
    }}
    >
      {img !== null
        ? (
          <img
            src={img || NoImage}
            alt=""
            className={`${shadow ? '' : 'no_shadow'}`}
            style={sizeX ? { width: sizeX } : undefined}
          />
        )
        : (
          <div className="dimmer_wrap">
            <Dimmer active>
              <Loader size="mini" />
            </Dimmer>
          </div>
        )}
    </div>
  );
};
