import { cloneDeep } from 'lodash';
import {
  EditPriceAreaState,
  Estimate,
  EstimateEditState,
  EstimateSortState,
} from '../../type/estimate/estimate.type';
import { DateFormatter } from '../../utilities/date-formatter';
import { EstimateMeisaiModel } from './estimate-meisai.model';
import {
  ApiEstimatePostParam,
  ApiEstimateGetListParam, EstimateSortStateInProject, ApiEstimateGetListInProject,
  ApiEstimateGetParam,
} from '../../redux/estimate/api/estimate/api-estimate.type';
import { Project, ProjectListType } from '../../type/project/project.type';
import { OrderEditState } from '../../type/order/order.type';
import { changeString } from '../../utilities/change-string';
import { joinStr } from '../../utilities/join-str';
import { BillListType, BillPrintInfoType } from '../../type/bill/bill.type';
import { MasterTax } from '../../type/master/master-tax.type';
import { CalcExTax } from '../../utilities/calcTax';
import { limitStringValue, splitStringByLimitedValue } from '../../utilities/split-string-by-limited-value';
import { MathHelper } from '../../utilities/math-helper';
import {
  adjustString, countString, adjustStringAry, someLineString, countStringDetail,
} from '../../utilities/adjust-string';

type SendData = {
  editState: EstimateEditState;
  meisaiModel: EstimateMeisaiModel | null;
  priceModel: EditPriceAreaState;
  employee_id: number;
  reserve1Name: string;
  reserve2Name: string;
};

const df = (v: Date | null | undefined) => DateFormatter.date2str(v, 'YYYYMMDD', '-');

type meisaiType = {
  // No.
  largeNumber: string,
  // 工事名称 大項目
  largeName: string,
  mediumList: [{
    // 工事名称 小項目
    mediumName: string,
    smallList: [{
      // 工事名称 小項目
      smallName: string,
      // 数量
      count: string,
      // 単位
      unit: string,
      // 単価
      uPrice: string,
      // 金額
      price: string,
      // 原価
      genka: string,
      // 原価金額
      genkaPrice: string,
      // 粗利率
      arari: string,
      // 備考
      memo: string,
    }],
    // 数量
    count: string,
    // 単位
    unit: string,
    // 金額
    smallPrice: string,
    // 原価
    smallGenka: string,
    // 粗利率
    arari: string,
    // 備考
    memo: string,
  }],
  // 数量
  count: string,
  // 単位
  unit: string,
  // 金額
  mediumPrice: string,
  // 原価
  mediumGenka: string,
  // 粗利率
  arari: string,
  // 備考
  memo: string,
};

type BanksParam = {
  bank0: string,
  bank1: string,
  bank2: string,
  bank3: string,
};

export class EstimateModel {
  static setPriceArea(v: Estimate): any {
    return (
      {
        /**  税抜合計金額（見積金額）*/
        zeinuki_kin: v.zeinuki_kin,
        /**  消費税額*/
        shohizei_kin: v.shohizei_kin,
        /**  見積合計金額(税抜)（見積合計）*/
        gokei_zeinuki_kin: v.gokei_zeinuki_kin,
        /**  原価合計  税抜き（原価金額）*/
        genka_kin: v.genka_kin,
        /**  原価合計金額(税抜)  （原価合計）*/
        gokei_zeinuki_genka_kin: v.gokei_zeinuki_genka_kin,
        /**  原価消費税額*/
        genka_shohizei_kin: v.genka_shohizei_kin,
        /**  税込合計原価金額（税込合計原価金額）*/
        gokei_genka_kin: v.gokei_genka_kin,
        /* 粗利 */
        arari_price: 0,
        /* 粗利パーセント */
        arari_percent: 0,
        /**  合計金額（税込合計見積金額）*/
        gokei_kin: v.gokei_kin,
        /**  調整額（値引き）*/
        tyosei_kin: v.tyosei_kin,
        /**  予備費1_見積金額（現場協力費 見積金額）*/
        reserve1_estimate_kin: v.reserve1_estimate_kin,
        /**  予備費1_見積パーセント（現場協力費 見積％）*/
        reserve1_estimate_percent: v.reserve1_estimate_percent,
        /**  "予備費1_原価金額（現場協力費 原価金額）"*/
        reserve1_genka_kin: v.reserve1_genka_kin,
        /**  "予備費1_原価パーセント（現場協力費 原価％）"*/
        reserve1_genka_percent: v.reserve1_genka_percent,
        /**  "予備費2_見積金額（予備原価 見積金額）"*/
        reserve2_estimate_kin: v.reserve2_estimate_kin,
        /**  "予備費2_見積パーセント（予備原価 見積％）"*/
        reserve2_estimate_percent: v.reserve2_estimate_percent,
        /**  "予備費2_原価金額（予備原価 原価金額）"*/
        reserve2_genka_kin: v.reserve2_genka_kin,
        /**  "予備費2_原価パーセント（予備原価 原価％）"*/
        reserve2_genka_percent: v.reserve2_genka_percent,
      }
    );
  }

  static postParm({
    editState,
    meisaiModel: meisai,
    priceModel: price,
    employee_id,
    reserve1Name,
    reserve2Name,
  }: SendData): ApiEstimatePostParam['data'] {
    return {
      ...{
        ...price,
        /* 粗利 */
        arari_price: NaN,
        /* 粗利パーセント */
        arari_percent: NaN,
      },
      project_id: editState.project_id,
      customer_id: editState.customer_id,
      /**  見積発行日（作成日）*/
      estimate_dt: df(editState.estimate_dt),
      /**  有効期限*/
      yukokigen: df(editState.yukokigen),
      /**  支払い条件*/
      // shiharaijyoken: string,
      /**  工事内容*/
      // subject: string,
      /**  見積作成社員ID*/
      employee_id,
      /**  調整前税抜合計金額*/
      before_zeinuki_kin: 0,
      /**  工期_開始*/
      estimate_kouki_start_dt: df(editState.estimate_kouki_start_dt),
      jyutyu_yotei_dt: df(editState.jyutyu_yotei_dt),
      /**  工期_終了*/
      estimate_kouki_end_dt: df(editState.estimate_kouki_end_dt),
      /**  備考*/
      remarks: editState.remarks,
      /**  見積明細（XML）*/
      meisai: meisai?.obj2Xml() || '',
      /**  予備費1_名前*/
      reserve1_name: reserve1Name,
      /**  予備費2_名前*/
      reserve2_name: reserve2Name,
      /**  バージョン情報*/
      ver: 1,
      valid_flag: 1,
    };
  }

  static listParam(sortState: EstimateSortState): ApiEstimateGetListParam {
    let sort_by = 0;
    if (sortState.sort_by === 0) sort_by = 1;
    else if (sortState.sort_by === 1) sort_by = 2;
    else if (sortState.sort_by === 2) sort_by = 3;
    else if (sortState.sort_by === 3) sort_by = 4;
    else if (sortState.sort_by === 4) sort_by = 5;
    else if (sortState.sort_by === 5) sort_by = 6;
    else if (sortState.sort_by === 6) sort_by = 7;
    else if (sortState.sort_by === 7) sort_by = 8;
    else if (sortState.sort_by === 8) sort_by = 9;
    else if (sortState.sort_by === 9) sort_by = 10;
    else if (sortState.sort_by === 10) sort_by = 11;
    else if (sortState.sort_by === 11) sort_by = 12;
    else if (sortState.sort_by === 12) sort_by = 13;
    else if (sortState.sort_by === 13) sort_by = 14;
    else if (sortState.sort_by === 14) sort_by = 15;
    else if (sortState.sort_by === 15) sort_by = 16;
    else if (sortState.sort_by === 16) sort_by = 17;
    return ({
      ...sortState,
      estimate_dt: df(sortState.estimate_dt),
      estimate_kouki_start_dt: df(sortState.estimate_kouki_start_dt),
      estimate_kouki_end_dt: df(sortState.estimate_kouki_end_dt),
      construction_parts: sortState?.construction_parts?.getSendData(),
      valid_flag: 1,
      sort_by: Number.isNaN(sortState.sort_by) ? 0 : sortState.sort_by + 1,
      created_at_start: DateFormatter.date2str(sortState.created_at_start, 'YYYYMMDD', '-'),
      created_at_end: DateFormatter.date2str(sortState.created_at_end, 'YYYYMMDD', '-'),
      updated_at_start: DateFormatter.date2str(sortState.updated_at_start, 'YYYYMMDD', '-'),
      updated_at_end: DateFormatter.date2str(sortState.updated_at_end, 'YYYYMMDD', '-'),
    });
  }

  static listParamInCopy(sortState: EstimateSortState): ApiEstimateGetListParam {
    let sort_by = 0;
    if (!sortState.sort_by) sort_by = 2;
    else if (sortState.sort_by === 1) sort_by = 3;
    else if (sortState.sort_by === 2) sort_by = 4;
    else if (sortState.sort_by === 3) sort_by = 5;
    else if (sortState.sort_by === 4) sort_by = 7;
    else if (sortState.sort_by === 5) sort_by = 10;
    // FIXME ↓APIきたら変える↓
    else if (sortState.sort_by === 6) sort_by = 10;

    return ({
      customer_name: sortState.customer_name,
      project_name: sortState.project_name,
      meisai: sortState.meisai,
      // employee_id: sortState.employee_id,
      construction_parts: sortState?.construction_parts?.getSendData(),
      created_employee_id: sortState?.created_employee_id,
      sort_by,
      limit: sortState.limit,
      highlow: sortState.highlow,
      offset: sortState.offset,
      valid_flag: 1,
    });
  }

  //  Table in ProjectDetail
  static getListInProject(
    sortData: EstimateSortStateInProject,
    project_id: number,
  ): ApiEstimateGetListInProject &{ valid_flag:1} {
    let sort_by = 0;
    if (sortData.sort_by === 0) sort_by = 1;
    else if (sortData.sort_by === 1) sort_by = 2;
    else if (sortData.sort_by === 2) sort_by = 3;
    else if (sortData.sort_by === 3) sort_by = 7;
    else if (sortData.sort_by === 4) sort_by = 8;
    else if (sortData.sort_by === 5) sort_by = 9;
    else if (sortData.sort_by === 6) sort_by = 10;
    else if (sortData.sort_by === 7) sort_by = 11;
    else if (sortData.sort_by === 8) sort_by = 12;
    else if (sortData.sort_by === 9) sort_by = 13;
    else if (sortData.sort_by === 10) sort_by = 14;
    else if (sortData.sort_by === 11) sort_by = 15;
    else if (sortData.sort_by === 12) sort_by = 16;

    return ({
      project_id,
      estimate_no: sortData.estimate_no,
      estimate_dt: df(sortData.estimate_dt),
      employee_id: sortData.employee_id,
      gokei_zeinuki_kin: sortData.gokei_zeinuki_kin,
      shohizei_kin: sortData.shohizei_kin,
      gokei_kin: sortData.gokei_kin,
      gokei_zeinuki_genka_kin: sortData.gokei_zeinuki_genka_kin,
      genka_shohizei_kin: sortData.genka_shohizei_kin,
      gokei_genka_kin: sortData.gokei_genka_kin,
      tyosei_kin: sortData.tyosei_kin,
      estimate_kouki_start_dt: df(sortData.estimate_kouki_start_dt),
      estimate_kouki_end_dt: df(sortData.estimate_kouki_end_dt),
      highlow: sortData.highlow,
      valid_flag: 1,
      sort_by,
    });
  }

  setEditState(v: Estimate) {
    return ({
      meisai: '',
    });
  }

  /** 見積、見積(社内) のSVG用データ生成 */
  static setSVGDataEstimate(
    type: 'estimate' | 'estimateIn',
    estimate: Estimate,
    meisai: EstimateMeisaiModel,
  ) {
    const meisaiList = this.createMeisaiList(meisai);
    // const arariSum = (() => {
    //   let sum = 0;
    //   meisaiList.forEach((m) => {
    //     sum += Number(m.arari.replace('%', ''));
    //   });
    //   return sum;
    // })();

    const address = this.getAddressArray(
      this.setAddressHyphen(estimate.project_field_post_no),
      [
        estimate.project_field_prefecture_name || '',
        estimate.project_field_city || '',
        estimate.project_field_address || '',
        estimate.project_field_building_name || '',
      ],
    );

    const remarksList = splitStringByLimitedValue(estimate.remarks || '', 46);

    let remarksStr: string = '';
    remarksList.forEach((v, i) => {
      const br = (i > 3) ? '' : '\n';
      if (i < 5) { remarksStr += `${v}${br}`; }
    });

    let adjustName: number = 25;
    if (countString(estimate.field_name || '') >= 55) adjustName = 9.7;
    else if (countString(estimate.field_name || '') >= 50) adjustName = 10.7;
    else if (countString(estimate.field_name || '') >= 45) adjustName = 11.8;
    else if (countString(estimate.field_name || '') >= 40) adjustName = 13.2;
    else if (countString(estimate.field_name || '') >= 35) adjustName = 14.8;
    else if (countString(estimate.field_name || '') >= 30) adjustName = 17;
    else if (countString(estimate.field_name || '') >= 27) adjustName = 21.5;
    else if (countString(estimate.field_name || '') >= 24) adjustName = 22.4;
    else if (countString(estimate.field_name || '') >= 23) adjustName = 25;

    return ({
      estimate: {
        // 顧客名
        name: estimate.field_name || '',
        adjustName,
        // No.
        no: estimate.estimate_no || '',
        // 日付
        date: changeString(estimate.estimate_dt || '', '/'),
        // 金額
        price: `\\${Math.floor(Number(estimate.gokei_kin)).toLocaleString('ja-JP')}` || '0',
        // 税抜き請求金額
        excludingTax: `\\${Math.floor(Number(estimate.gokei_zeinuki_kin)).toLocaleString('ja-JP')}` || '0',
        // 消費税額
        consumptionTax: `\\${Math.floor(Number(estimate.shohizei_kin)).toLocaleString('ja-JP')}` || '0',
        // 工事名称
        projectName: estimate.project_name || '',
        // 工事場所1
        field1: address[0],
        // 工事場所2
        field2: address[1],
        field3: address[2],
        // 備考
        remarks: remarksStr,
        // 工事電話番号
        customerTel: estimate.project_field_tel_no || '',
        // 見積有効期限
        expirationDate: changeString(estimate.yukokigen || '', '/'),
        // アイコン
        icon: estimate.project_store_logo ? `data:image/${this.getExtension(estimate.project_store_format)};base64,${estimate.project_store_logo}` : '',
        /** ロゴ画像の縦幅 */
        iconHeight: estimate.project_store_logo_height,
        /** ロゴ画像の横幅 */
        iconWidth: estimate.project_store_logo_width,
        // 会社名
        company: estimate.company_name || '',
        // 会社名2 (店舗名)
        company2: `${estimate.project_store_name ?? ''}`,
        // 郵便番号
        post: `〒${this.setAddressHyphen(estimate.project_store_post_no || '')}`,
        // 住所
        address: `${estimate.project_store_prefecture || ''}${estimate.project_store_city || ''}${estimate.project_store_address || ''}${estimate.project_store_building_name || ''}` || '',
        // 電話番号
        tel: estimate.project_store_tel_no || '',
        // FAX
        fax: estimate.project_store_fax_no || '',
        // フリーダイヤル
        freeDial: estimate.project_store_free_dial || '',
        // 役職名 + 役職者
        directorName: (estimate.director_type !== null && estimate.director_name !== null) ? `${estimate.director_type} ${estimate.director_name}` : '', // 仮
        // 担当者名
        nameCharge: estimate.project_employee_name || '',
        // 振込先
        banks: new Array(6).fill({} as BanksParam).map((_, i) => ({
          // 銀行名
          banks0: (estimate as any)[`project_store_bank_name${i !== 0 ? i + 1 : ''}`] || '',
          // 支店名
          banks1: (estimate as any)[`project_store_bank_store_name${i !== 0 ? i + 1 : ''}`] || '',
          // 普通預金
          banks2: (estimate as any)[`project_store_account${i !== 0 ? i + 1 : ''}_name`] || '',
          // ナンバー
          banks3: (estimate as any)[`project_store_bank_account_no${i !== 0 ? i + 1 : ''}`] || '',
        })),
      },
      meisai: {
        largeList: meisaiList.map((m) => ({
          ...m,
          mediumList: [],
        })),
        // 予備費関係末の合計 金額
        yobiGokeiGokei: Math.floor(Number(estimate.zeinuki_kin) + Number(estimate.tyosei_kin)).toLocaleString('ja-JP'),
        // 予備費関係末の合計 原価金額
        yobiGokeiGenka: Math.floor(Number(estimate.genka_kin)).toLocaleString('ja-JP'),
        // 現場協力費原価 金額
        FiledHelperGenkaGokei: Math.floor(Number(estimate.reserve1_estimate_kin)).toLocaleString('ja-JP'),
        // 現場協力費原価 原価金額
        FiledHelperGenkaGenka: Math.floor(Number(estimate.reserve1_genka_kin)).toLocaleString('ja-JP'),
        // 予備原価 金額
        yobiGenkaGokei: Math.floor(Number(estimate.reserve2_estimate_kin)).toLocaleString('ja-JP'),
        // 予備原価 原価金額
        yobiGenkaGenka: Math.floor(Number(estimate.reserve2_genka_kin)).toLocaleString('ja-JP'),
        // 合計 金額
        largePrice: type === 'estimateIn'
          ? Math.floor(Number(estimate.zeinuki_kin) + Number(estimate.tyosei_kin) + Number(estimate.reserve1_estimate_kin) + Number(estimate.reserve2_estimate_kin)).toLocaleString('ja-JP')
          : Math.floor(Number(estimate.zeinuki_kin) + Number(estimate.tyosei_kin)).toLocaleString('ja-JP'),
        // 合計 原価金額
        largePriceGenka: type === 'estimateIn'
          ? Math.floor(Number(estimate.genka_kin) + Number(estimate.reserve1_genka_kin) + Number(estimate.reserve2_genka_kin)).toLocaleString('ja-JP')
          : Math.floor(Number(estimate.genka_kin)).toLocaleString('ja-JP'),
        // 調整金額 金額
        tyouseiPrice: Math.floor(-Number(estimate.tyosei_kin)).toLocaleString('ja-JP'),
        // 調整金額 原価金額
        tyouseiPriceGenka: '-0',
        // 税抜金額
        zeinukiPrice: Math.floor(Number(estimate.gokei_zeinuki_kin)).toLocaleString('ja-JP'),
        // 税抜金額 原価金額
        zeinukiPriceGenka: Math.floor(Number(estimate.gokei_zeinuki_genka_kin)).toLocaleString('ja-JP'),
        // 消費税
        syouhizeiPrice: Math.floor(Number(estimate.shohizei_kin)).toLocaleString('ja-JP'),
        // 消費税 原価金額
        syouhizeiPriceGenka: Math.floor(Number(estimate.genka_shohizei_kin)).toLocaleString('ja-JP'),
        // 税込金額
        zeikomiPrice: Math.floor(Number(estimate.gokei_kin)).toLocaleString('ja-JP'),
        // 税込金額 原価金額
        zeikomiPriceGenka: Math.floor(Number(estimate.gokei_genka_kin)).toLocaleString('ja-JP'),
        // 粗利率
        largeArari: `${Math.round(((Number(estimate.gokei_kin) - Number(estimate.gokei_genka_kin)) / Number(estimate.gokei_kin)) * 100)}%`,
      },
      detail: {
        largeList: meisaiList.map((m) => ({
          ...m,
          mediumList: m.mediumList.map((s) => ({
            ...s,
            smallList: [],
          })),
        })),
        // 予備費関係末の合計 金額
        yobiGokeiGokei: Math.floor(Number(estimate.zeinuki_kin) + Number(estimate.tyosei_kin)).toLocaleString('ja-JP'),
        // 予備費関係末の合計 原価金額
        yobiGokeiGenka: Math.floor(Number(estimate.genka_kin)).toLocaleString('ja-JP'),
        // 現場協力費原価 金額
        FiledHelperGenkaGokei: Math.floor(Number(estimate.reserve1_estimate_kin)).toLocaleString('ja-JP'),
        // 現場協力費原価 原価金額
        FiledHelperGenkaGenka: Math.floor(Number(estimate.reserve1_genka_kin)).toLocaleString('ja-JP'),
        // 予備原価 金額
        yobiGenkaGokei: Math.floor(Number(estimate.reserve2_estimate_kin)).toLocaleString('ja-JP'),
        // 予備原価 原価金額
        yobiGenkaGenka: Math.floor(Number(estimate.reserve2_genka_kin)).toLocaleString('ja-JP'),
        // 合計 金額
        largePrice: type === 'estimateIn'
          ? Math.floor(Number(estimate.zeinuki_kin) + Number(estimate.tyosei_kin) + Number(estimate.reserve1_estimate_kin) + Number(estimate.reserve2_estimate_kin)).toLocaleString('ja-JP')
          : Math.floor(Number(estimate.zeinuki_kin) + Number(estimate.tyosei_kin)).toLocaleString('ja-JP'),
        // 合計 原価金額
        largePriceGenka: type === 'estimateIn'
          ? Math.floor(Number(estimate.genka_kin) + Number(estimate.reserve1_genka_kin) + Number(estimate.reserve2_genka_kin)).toLocaleString('ja-JP')
          : Math.floor(Number(estimate.genka_kin)).toLocaleString('ja-JP'),
        // 調整金額 金額
        tyouseiPrice: Math.floor(-Number(estimate.tyosei_kin)).toLocaleString('ja-JP'),
        // 調整金額 原価金額
        tyouseiPriceGenka: '-0',
        // 税抜金額
        zeinukiPrice: Math.floor(Number(estimate.gokei_zeinuki_kin)).toLocaleString('ja-JP'),
        // 税抜金額 原価金額
        zeinukiPriceGenka: Math.floor(Number(estimate.gokei_zeinuki_genka_kin)).toLocaleString('ja-JP'),
        // 消費税
        syouhizeiPrice: Math.floor(Number(estimate.shohizei_kin)).toLocaleString('ja-JP'),
        // 消費税 原価金額
        syouhizeiPriceGenka: Math.floor(Number(estimate.genka_shohizei_kin)).toLocaleString('ja-JP'),
        // 税込金額
        zeikomiPrice: Math.floor(Number(estimate.gokei_kin)).toLocaleString('ja-JP'),
        // 税込金額 原価金額
        zeikomiPriceGenka: Math.floor(Number(estimate.gokei_genka_kin)).toLocaleString('ja-JP'),
        // 粗利率
        largeArari: `${Math.round(((Number(estimate.gokei_kin) - Number(estimate.gokei_genka_kin)) / Number(estimate.gokei_kin)) * 100)}%`,
      },
      detail2: {
        largeList: meisaiList,
        // 予備費関係末の合計 金額
        yobiGokeiGokei: Math.floor(Number(estimate.zeinuki_kin) + Number(estimate.tyosei_kin)).toLocaleString('ja-JP'),
        // 予備費関係末の合計 原価金額
        yobiGokeiGenka: Math.floor(Number(estimate.genka_kin)).toLocaleString('ja-JP'),
        // 現場協力費原価 金額
        FiledHelperGenkaGokei: Math.floor(Number(estimate.reserve1_estimate_kin)).toLocaleString('ja-JP'),
        // 現場協力費原価 原価金額
        FiledHelperGenkaGenka: Math.floor(Number(estimate.reserve1_genka_kin)).toLocaleString('ja-JP'),
        // 予備原価 金額
        yobiGenkaGokei: Math.floor(Number(estimate.reserve2_estimate_kin)).toLocaleString('ja-JP'),
        // 予備原価 原価金額
        yobiGenkaGenka: Math.floor(Number(estimate.reserve2_genka_kin)).toLocaleString('ja-JP'),
        // 合計 金額
        largePrice: type === 'estimateIn'
          ? Math.floor(Number(estimate.zeinuki_kin) + Number(estimate.tyosei_kin) + Number(estimate.reserve1_estimate_kin) + Number(estimate.reserve2_estimate_kin)).toLocaleString('ja-JP')
          : Math.floor(Number(estimate.zeinuki_kin) + Number(estimate.tyosei_kin)).toLocaleString('ja-JP'),
        // 合計 原価金額
        largePriceGenka: type === 'estimateIn'
          ? Math.floor(Number(estimate.genka_kin) + Number(estimate.reserve1_genka_kin) + Number(estimate.reserve2_genka_kin)).toLocaleString('ja-JP')
          : Math.floor(Number(estimate.genka_kin)).toLocaleString('ja-JP'),
        // 調整金額 金額
        tyouseiPrice: Math.floor(-Number(estimate.tyosei_kin)).toLocaleString('ja-JP'),
        // 調整金額 原価金額
        tyouseiPriceGenka: '-0',
        // 税抜金額
        zeinukiPrice: Math.floor(Number(estimate.gokei_zeinuki_kin)).toLocaleString('ja-JP'),
        // 税抜金額 原価金額
        zeinukiPriceGenka: Math.floor(Number(estimate.gokei_zeinuki_genka_kin)).toLocaleString('ja-JP'),
        // 消費税
        syouhizeiPrice: Math.floor(Number(estimate.shohizei_kin)).toLocaleString('ja-JP'),
        // 消費税 原価金額
        syouhizeiPriceGenka: Math.floor(Number(estimate.genka_shohizei_kin)).toLocaleString('ja-JP'),
        // 税込金額
        zeikomiPrice: Math.floor(Number(estimate.gokei_kin)).toLocaleString('ja-JP'),
        // 税込金額 原価金額
        zeikomiPriceGenka: Math.floor(Number(estimate.gokei_genka_kin)).toLocaleString('ja-JP'),
        // 粗利率
        largeArari: `${Math.round(((Number(estimate.gokei_kin) - Number(estimate.gokei_genka_kin)) / Number(estimate.gokei_kin)) * 100)}%`,
      },
    });
  }

  /** 請求書 のSVG用データ生成 */
  static setSVGDataClaim(
    estimate: BillPrintInfoType,
    meisai: EstimateMeisaiModel,
    claimListData: BillListType,
    taxData?: MasterTax,
  ) {
    const meisaiList = this.createMeisaiList(meisai);
    const arariSum = (() => {
      let sum = 0;
      meisaiList.forEach((m) => {
        sum += Number(m.arari.replace('%', ''));
      });
      return sum / meisaiList.length;
    })();

    const address = this.getAddressArray(
      this.setAddressHyphen(estimate.field_post_no),
      [
        estimate.field_prefecture_name || '',
        estimate.field_city || '',
        estimate.field_address || '',
        estimate.field_building_name || '',
      ],
      { adjustNum: 23 },
    );

    const zeinukiPrice = CalcExTax({
      amount: claimListData.billing_amount,
      taxRate: taxData?.tax_rate ?? 0,
    });
    console.log(zeinukiPrice);
    const shohizeiPrice = claimListData.billing_amount - zeinukiPrice;
    const remarksList = splitStringByLimitedValue(estimate.remarks || '', 25);
    // const remarksList = splitStringByLimitedValue('総額\\4,950,000、お支払い済\\4,000,000、残金
    // \\950, 000、乾太くん本体費用\\147, 730\n今回ご請求分（\\950, 000－\\147, 730＝\\802, 270）' || '', 19);

    let remarksStr: string = '';
    remarksList.forEach((v, _) => {
      remarksStr += `${v}\n`;
    });

    let adjustName: number | undefined = 25;
    if (countString(estimate.field_name || '') >= 55) adjustName = 9.7;
    else if (countString(estimate.field_name || '') >= 50) adjustName = 10.7;
    else if (countString(estimate.field_name || '') >= 45) adjustName = 11.8;
    else if (countString(estimate.field_name || '') >= 40) adjustName = 13.2;
    else if (countString(estimate.field_name || '') >= 35) adjustName = 14.8;
    else if (countString(estimate.field_name || '') >= 30) adjustName = 17;
    else if (countString(estimate.field_name || '') >= 27) adjustName = 21.5;
    else if (countString(estimate.field_name || '') >= 24) adjustName = 22.4;
    else if (countString(estimate.field_name || '') >= 23) adjustName = 25;

    let adjustProjectName: number | undefined = 16.5;
    if (countString(estimate.project_name || '') >= 37) adjustProjectName = 9.6;
    else if (countString(estimate.project_name || '') >= 35) adjustProjectName = 10.6;
    else if (countString(estimate.project_name || '') >= 33) adjustProjectName = 11.25;
    else if (countString(estimate.project_name || '') >= 30) adjustProjectName = 11.9;
    else if (countString(estimate.project_name || '') >= 27) adjustProjectName = 13.2;
    else if (countString(estimate.project_name || '') >= 24) adjustProjectName = 14.6;
    else if (countString(estimate.project_name || '') >= 23) adjustProjectName = 16.5;

    return ({
      claim: {
        // 顧客名
        name: `${estimate.field_name || ''}`,
        // 文字サイズ調整用
        adjustName,
        // No.
        no: estimate.contract_no || '',
        // 日付
        date: changeString(estimate.billing_date || '', '/'),
        // 金額
        price: `\\${claimListData.billing_amount ? `${Math.floor(Number(claimListData.billing_amount)).toLocaleString('ja-JP')}` : ''}` || '0',
        // 税抜き請求金額
        excludingTax: `\\${zeinukiPrice !== undefined ? Math.floor(Number(zeinukiPrice)).toLocaleString('ja-JP') : ''}` || '0',
        // 消費税額
        consumptionTax: `\\${shohizeiPrice !== undefined ? Math.floor(Number(shohizeiPrice)).toLocaleString('ja-JP') : ''}` || '0',
        // 工事名称
        // projectName: adjustString(estimate.project_name, 23),
        // projectName: estimate.project_name || '',
        projectName: estimate.project_name || '',
        adjustProjectName,
        // 工事場所1
        field1: address[0],
        // 工事場所2
        field2: address[1],
        field3: address[2] || '',
        // 備考
        remarks: remarksStr,
        // remarks: estimate.remarks,
        // 工事電話番号
        customerTel: estimate.field_tel_no || '',
        // アイコン
        icon: estimate.project_store_logo ? `data:image/${this.getExtension(estimate.project_store_format)};base64,${estimate.project_store_logo}` : '',
        /** ロゴ画像の縦幅 */
        iconHeight: estimate.project_store_logo_height,
        /** ロゴ画像の横幅 */
        iconWidth: estimate.project_store_logo_width,
        // 会社名
        company: estimate.company_name || '',
        // 会社名2 (店舗名)
        company2: `${estimate.project_store_name ?? ''}`,
        // 郵便番号
        post: `〒${this.setAddressHyphen(estimate.project_store_post_no || '')}`,
        // 住所
        address: `${estimate.project_store_prefecture || ''}${estimate.project_store_city || ''}${estimate.project_store_address || ''}${estimate.project_store_building_name || ''}` || '',
        // 電話番号
        tel: estimate.project_store_tel_no || '',
        // FAX
        fax: estimate.project_store_fax_no || '',
        // フリーダイヤル
        freeDial: estimate.project_store_free_dial || '',
        // 役職名 + 役職者
        directorName: (estimate.director_type !== null && estimate.director_name !== null) ? `${estimate.director_type} ${estimate.director_name}` : '', // 仮
        // 担当者名
        nameCharge: estimate.project_employee_name || '',
        // 振込先
        banks: new Array(6).fill({} as BanksParam).map((_, i) => ({
          // 銀行名
          banks0: (estimate as any)[`project_store_bank_name${i !== 0 ? i + 1 : ''}`] || '',
          // 支店名
          banks1: (estimate as any)[`project_store_bank_store_name${i !== 0 ? i + 1 : ''}`] || '',
          // 普通/当座
          banks2: (estimate as any)[`project_store_account${i !== 0 ? i + 1 : ''}_name`] ? `${(estimate as any)[`project_store_account${i !== 0 ? i + 1 : ''}_name`]}預金` : '',
          // ナンバー
          banks3: (estimate as any)[`project_store_bank_account_no${i !== 0 ? i + 1 : ''}`] as string,
        })),
        /** 案件担当店舗-口座名義 */
        project_store_holder: estimate.project_store_holder || '',
        adjustCompany: 0,
        adjustCompany2: 0,
      },
      meisai: {
        largeList: meisaiList.map((m) => ({
          ...m,
          mediumList: [],
        })),
        // 予備費関係末の合計 金額
        yobiGokeiGokei: (
          Math.floor(Number(estimate.reserve1_estimate_kin)
            + Number(estimate.reserve2_estimate_kin))
        ).toLocaleString('ja-JP'),
        // 予備費関係末の合計 原価金額
        yobiGokeiGenka: (
          Math.floor(Number(estimate.reserve1_genka_kin) + Number(estimate.reserve2_genka_kin))
        ).toLocaleString('ja-JP'),
        // 予備原価 金額
        yobiGenkaGokei: Math.floor(Number(estimate.reserve2_estimate_kin)).toLocaleString('ja-JP'),
        // 予備原価 原価金額
        yobiGenkaGenka: Math.floor(Number(estimate.reserve2_genka_kin)).toLocaleString('ja-JP'),
        // 合計 金額
        largePrice: Math.floor(Number(estimate.zeinuki_kin) + Number(estimate.tyosei_kin)).toLocaleString('ja-JP'),
        // 合計 原価金額
        largePriceGenka: Math.floor(Number(estimate.genka_kin)).toLocaleString('ja-JP'),
        // 調整金額 金額
        tyouseiPrice: Math.floor(-Number(estimate.tyosei_kin)).toLocaleString('ja-JP'),
        // 調整金額 原価金額
        tyouseiPriceGenka: '-0',
        // 税抜金額
        zeinukiPrice: Math.floor(Number(estimate.gokei_zeinuki_kin)).toLocaleString('ja-JP'),
        // 税抜金額 原価金額
        zeinukiPriceGenka: Math.floor(Number(estimate.gokei_zeinuki_genka_kin)).toLocaleString('ja-JP'),
        // 消費税
        syouhizeiPrice: Math.floor(Number(estimate.shohizei_kin)).toLocaleString('ja-JP'),
        // 消費税 原価金額
        syouhizeiPriceGenka: Math.floor(Number(estimate.genka_shohizei_kin)).toLocaleString('ja-JP'),
        // 税込金額
        zeikomiPrice: Math.floor(Number(estimate.gokei_kin)).toLocaleString('ja-JP'),
        // 税込金額 原価金額
        zeikomiPriceGenka: Math.floor(Number(estimate.gokei_genka_kin)).toLocaleString('ja-JP'),
        // 粗利率
        largeArari: `${arariSum}%`,
      },
      detail: {
        largeList: meisaiList.map((m) => ({
          ...m,
          mediumList: m.mediumList.map((s) => ({
            ...s,
            smallList: [],
          })),
        })),
        // 予備費関係末の合計 金額
        yobiGokeiGokei: (
          Math.floor(Number(estimate.reserve1_estimate_kin)
            + Number(estimate.reserve2_estimate_kin))
        ).toLocaleString('ja-JP'),
        // 予備費関係末の合計 原価金額
        yobiGokeiGenka: (
          Math.floor(Number(estimate.reserve1_genka_kin) + Number(estimate.reserve2_genka_kin))
        ).toLocaleString('ja-JP'),
        // 予備原価 金額
        yobiGenkaGokei: Math.floor(Number(estimate.reserve2_estimate_kin)).toLocaleString('ja-JP'),
        // 予備原価 原価金額
        yobiGenkaGenka: Math.floor(Number(estimate.reserve2_genka_kin)).toLocaleString('ja-JP'),
        // 合計 金額
        largePrice: Math.floor(Number(estimate.zeinuki_kin) + Number(estimate.tyosei_kin)).toLocaleString('ja-JP'),
        // 合計 原価金額
        largePriceGenka: Math.floor(Number(estimate.genka_kin)).toLocaleString('ja-JP'),
        // 調整金額 金額
        tyouseiPrice: Math.floor(-Number(estimate.tyosei_kin)).toLocaleString('ja-JP'),
        // 調整金額 原価金額
        tyouseiPriceGenka: '-0',
        // 税抜金額
        zeinukiPrice: Math.floor(Number(estimate.gokei_zeinuki_kin)).toLocaleString('ja-JP'),
        // 税抜金額 原価金額
        zeinukiPriceGenka: Math.floor(Number(estimate.gokei_zeinuki_genka_kin)).toLocaleString('ja-JP'),
        // 消費税
        syouhizeiPrice: Math.floor(Number(estimate.shohizei_kin)).toLocaleString('ja-JP'),
        // 消費税 原価金額
        syouhizeiPriceGenka: Math.floor(Number(estimate.genka_shohizei_kin)).toLocaleString('ja-JP'),
        // 税込金額
        zeikomiPrice: Math.floor(Number(estimate.gokei_kin)).toLocaleString('ja-JP'),
        // 税込金額 原価金額
        zeikomiPriceGenka: Math.floor(Number(estimate.gokei_genka_kin)).toLocaleString('ja-JP'),
        // 粗利率
        largeArari: `${arariSum}%`,
      },
      detail2: {
        largeList: meisaiList,
        // 予備費関係末の合計 金額
        yobiGokeiGokei: (
          Math.floor(Number(estimate.reserve1_estimate_kin)
            + Number(estimate.reserve2_estimate_kin))
        ).toLocaleString('ja-JP'),
        // 予備費関係末の合計 原価金額
        yobiGokeiGenka: (
          Math.floor(Number(estimate.reserve1_genka_kin) + Number(estimate.reserve2_genka_kin))
        ).toLocaleString('ja-JP'),
        // 予備原価 金額
        yobiGenkaGokei: Math.floor(Number(estimate.reserve2_estimate_kin)).toLocaleString('ja-JP'),
        // 予備原価 原価金額
        yobiGenkaGenka: Math.floor(Number(estimate.reserve2_genka_kin)).toLocaleString('ja-JP'),
        // 合計 金額
        largePrice: Math.floor(Number(estimate.zeinuki_kin) + Number(estimate.tyosei_kin)).toLocaleString('ja-JP'),
        // 合計 原価金額
        largePriceGenka: Math.floor(Number(estimate.genka_kin)).toLocaleString('ja-JP'),
        // 調整金額 金額
        tyouseiPrice: Math.floor(-Number(estimate.tyosei_kin)).toLocaleString('ja-JP'),
        // 調整金額 原価金額
        tyouseiPriceGenka: '-0',
        // 税抜金額
        zeinukiPrice: Math.floor(Number(estimate.gokei_zeinuki_kin)).toLocaleString('ja-JP'),
        // 税抜金額 原価金額
        zeinukiPriceGenka: Math.floor(Number(estimate.gokei_zeinuki_genka_kin)).toLocaleString('ja-JP'),
        // 消費税
        syouhizeiPrice: Math.floor(Number(estimate.shohizei_kin)).toLocaleString('ja-JP'),
        // 消費税 原価金額
        syouhizeiPriceGenka: Math.floor(Number(estimate.genka_shohizei_kin)).toLocaleString('ja-JP'),
        // 税込金額
        zeikomiPrice: Math.floor(Number(estimate.gokei_kin)).toLocaleString('ja-JP'),
        // 税込金額 原価金額
        zeikomiPriceGenka: Math.floor(Number(estimate.gokei_genka_kin)).toLocaleString('ja-JP'),
        // 粗利率
        largeArari: `${arariSum}%`,
      },
    });
  }

  /** 工事現場の住所用 */
  static getAddressArray(
    postNo: string, otherAddress: string[], option?: {adjustNum?: number},
  ): string[] {
    const adjustNum = option?.adjustNum || 19;
    const tmp: string[] = [
      `〒${postNo || ''} `,
      '',
    ];
    let index = 0;
    otherAddress.forEach((v, i) => {
      if (index === 0) {
        const strCount = countString(tmp[0]) + countString(v);
        if (strCount > 19) index += 1;
      }
      tmp[index] += v;
    });
    const ad = [tmp[0], ...adjustStringAry(tmp[1], adjustNum)];
    return ad;
  }

  /** 郵便番号用のハイフン追加処理 */
  static setAddressHyphen(address: string) {
    return joinStr(address, 3, '-');
  }

  /** ロゴデータ用 Base64に当てはめるファイル形式の文字列 */
  static getExtension(type: string | null): string {
    switch (type) {
      case 'jpg': return 'jpeg';
      case null: return 'png';
      default: return type;
    }
  }

  /** 請求書(簡易)のSVG用データ取得 */
  static setSVGDataClaimIn(
    estimate: BillPrintInfoType,
    claimListData: BillListType,
    taxData?: MasterTax,
  ) {
    const zeinukiPrice = CalcExTax({
      amount: claimListData.billing_amount,
      taxRate: taxData?.tax_rate ?? 0,
    });
    console.log(claimListData.billing_amount, taxData?.tax_rate ?? 0);
    const shohizeiPrice = claimListData.billing_amount - zeinukiPrice;

    // 8.7 : 10
    let adjustName = 10;
    // const countName = countStringDetail(estimate.field_name || '');
    const projectNameAry = adjustStringAry(estimate.field_name || '', 30);

    projectNameAry.forEach((v, i) => {
      if (i === 0) {
        const countName = countStringDetail(v || '', 0.5769);
        const countDefault = countStringDetail(v || '');
        if (countDefault.count >= 30) {
          const adjust = Number((30 / countName.count).toFixed(3));
          adjustName = 10 * adjust;
        } else if (countDefault.count < 18) {
          // const adjust = Number((17 / countName.count).toFixed(3));
          adjustName = 17.5;
          // console.log(adjust, 1);
        } else {
          const forCase = [
            { count: 30, size: 10.3 },
            { count: 29, size: 10.63 },
            { count: 28, size: 11 },
            { count: 27, size: 11.4 },
            { count: 26, size: 11.8 },
            { count: 25, size: 12.3 },
            { count: 24, size: 12.8 },
            { count: 23, size: 13.42 },
            { count: 22, size: 14.2 },
            { count: 21, size: 14.9 },
            { count: 20, size: 15.6 },
            { count: 19, size: 16.5 },
          ];
          const adjust = Number((countDefault.count / countName.count).toFixed(3));
          forCase.forEach((v2) => {
            if (countDefault.count < v2.count) {
              adjustName = v2.size * adjust;
            }
          });
        }
      }
    });

    let adjustCompanyName = 15;
    if (countString(estimate.company_name) >= 26) adjustCompanyName = 8;
    else if (countString(estimate.company_name) >= 25) adjustCompanyName = 8.4;
    else if (countString(estimate.company_name) >= 24) adjustCompanyName = 8.77;
    else if (countString(estimate.company_name) >= 23) adjustCompanyName = 9.2;
    else if (countString(estimate.company_name) >= 22) adjustCompanyName = 9.6;
    else if (countString(estimate.company_name) >= 21) adjustCompanyName = 10;
    else if (countString(estimate.company_name) >= 20) adjustCompanyName = 10.4;
    else if (countString(estimate.company_name) >= 19) adjustCompanyName = 10.8;
    else if (countString(estimate.company_name) >= 18) adjustCompanyName = 11.3;
    else if (countString(estimate.company_name) >= 17) adjustCompanyName = 12;
    else if (countString(estimate.company_name) >= 16) adjustCompanyName = 13;
    else if (countString(estimate.company_name) >= 15) adjustCompanyName = 14;

    const _adjustCompany = (): number => {
      const nullStr = (str: string | null): string => {
        if (!str) return '';
        return str;
      };
      const tmpTrans = this.getLogoTransform({
        width: Number(estimate.project_store_logo_width),
        height: Number(estimate.project_store_logo_height),
      });

      if (tmpTrans.width >= 212) return 0;
      console.log(adjustCompanyName);
      const companyName = countString(nullStr(estimate.company_name)) * adjustName;
      const storeName = countString(nullStr(estimate.project_store_name));
      const postNum = countString(`〒 ${nullStr(this.setAddressHyphen(estimate.project_store_post_no))}`);
      const field = nullStr(`${estimate.project_store_prefecture || ''}${estimate.project_store_city || ''}${estimate.project_store_address || ''}${estimate.project_store_building_name || ''}`);
      const fieldName = countString(nullStr(field));
      const telNum = countString(`TEL: ${nullStr(estimate.project_store_tel_no)}`);
      const faxNum = countString(`FAX: ${estimate.project_store_fax_no}`);
      const freeDial = countString(`お客様フリーダイヤル: ${nullStr(estimate.project_store_free_dial)}`);
      const list: number[] = [storeName, postNum, fieldName, telNum, faxNum, freeDial];
      console.log(list);
      const max = Math.max(...list);
      console.log(max, companyName);
      if (max > 21 || companyName > 212) {
        return 0;
      }

      if (countString(nullStr(estimate.company_name)) * adjustCompanyName >= tmpTrans.width
      && countString(nullStr(estimate.company_name)) * adjustCompanyName
      >= MathHelper.times(max, 10)
      ) {
        console.log('aaaiii');
        return MathHelper.minus(21, companyName);
      }

      const maxPx = MathHelper.times(max, 10);
      console.log('aaa');
      return maxPx >= tmpTrans.width
        ? MathHelper.times(MathHelper.minus(21, max), 10)
        : MathHelper.minus(212, tmpTrans.width);
    };

    const adjustCompany = ():number => (_adjustCompany() < 0 ? 0 : _adjustCompany());

    return ({
      claim: {
        // 顧客名
        name: someLineString(estimate.field_name || '', 31, 2),
        adjustName,
        // 日付
        date: changeString(estimate.billing_date || '', '/'),
        // 税込み合計金額
        zeikomiPrice: claimListData.billing_amount ? `${Math.floor(Number(claimListData.billing_amount)).toLocaleString('ja-JP')}` : '0',
        // 工事名称
        // projectName: estimate.project_name,
        projectName: someLineString(estimate.project_name, 21, 2),
        adjustProjectName: 13.979999542236328,
        // 工事金額合計
        price: zeinukiPrice !== undefined ? Math.floor(Number(zeinukiPrice)).toLocaleString('ja-JP') : '0',
        // 消費税額合計
        totalSyouhizeiPrice: shohizeiPrice !== undefined ? Math.floor(Number(shohizeiPrice)).toLocaleString('ja-JP') : '0',
        // 振込先
        banks: new Array(6).fill({} as BanksParam).map((_, i) => ({
          // 銀行名
          banks0: (estimate as any)[`project_store_bank_name${i !== 0 ? i + 1 : ''}`] as string || '',
          // 支店名
          banks1: (estimate as any)[`project_store_bank_store_name${i !== 0 ? i + 1 : ''}`] as string || '',
          // 普通預金
          banks2: (estimate as any)[`project_store_account${i !== 0 ? i + 1 : ''}_name`] ? `${(estimate as any)[`project_store_account${i !== 0 ? i + 1 : ''}_name`]}預金` : '',
          // ナンバー
          banks3: (estimate as any)[`project_store_bank_account_no${i !== 0 ? i + 1 : ''}`] as string,
        })),
        // アイコン
        icon: estimate.project_store_logo ? `data:image/${this.getExtension(estimate.project_store_format)};base64,${estimate.project_store_logo}` : '',
        /** ロゴ画像の縦幅 */
        iconHeight: Number(estimate.project_store_logo_height),
        /** ロゴ画像の横幅 */
        iconWidth: Number(estimate.project_store_logo_width),
        /** ロゴ画像のスケール、位置調整 ダミーデータ */
        iconTransform: this.getLogoTransform({
          width: Number(estimate.project_store_logo_width),
          height: Number(estimate.project_store_logo_height),
        },
        adjustCompany()).trans,
        // 会社名
        // company: estimate.company_name || '',
        company: someLineString(estimate.company_name || '', 26, 2),
        adjustCompanyName,
        // companyNameLinePos: MathHelper.minus(143.655 - 8),
        companyNameLinePos: MathHelper.minus(
          143.655, (countString(estimate.company_name)) > 26 ? 8 : 0,
        ),
        // company: 'システムショップ萩原株式会社',
        // 会社名2
        companyShiten: `${estimate.project_store_name ?? ''}`,
        // 郵便番号
        post: this.setAddressHyphen(estimate.project_store_post_no || ''),
        // 住所
        address: `${estimate.project_store_prefecture || ''}${estimate.project_store_city || ''}${estimate.project_store_address || ''}${estimate.project_store_building_name || ''}` || '',
        // 電話番号
        tel: estimate.project_store_tel_no || '',
        // FAX
        fax: estimate.project_store_fax_no || '',
        // フリーダイヤル
        freeDial: estimate.project_store_free_dial || '',
        // 内訳一覧
        breakdownList: new Array(1).fill('dummy').map(() => ({
          // 契約日
          contract: changeString(estimate.contract_date || '', '/'),
          // 工事内訳
          construction: claimListData.billing_items || '',
          // 数量
          count: '1.00',
          // 単位
          unit: '式',
          // 工事金額
          constructionPrice: zeinukiPrice
            ? Math.floor(Number(zeinukiPrice)).toLocaleString('ja-JP') : '0',
          // 消費税
          syouhizeiPrice: shohizeiPrice ? Math.floor(Number(shohizeiPrice)).toLocaleString('ja-JP') : '0',
        })),
        /** 案件担当店舗-口座名義 */
        project_store_holder: estimate.project_store_holder || '',
        /** 会社情報調整用 */
        adjustCompany: adjustCompany(),
        adjustCompany2: adjustCompany(),
      },
    });
  }

  /** 明細データのリスト化 */
  static createMeisaiList(meisai: EstimateMeisaiModel) {
    const { list } = meisai;
    const copyList = cloneDeep(list);
    const _list = copyList.map((v) => {
      // eslint-disable-next-line no-nested-ternary
      const suryou = v.$.suryou.indexOf('.') > 0
        ? v.$.suryou.split('.')[1].length === 1
          ? `${v.$.suryou}0`
          : v.$.suryou
        : `${v.$.suryou}.00`;
      return ({
        $: {
          ...v.$,
          suryou,
        },
      });
    });
    const meisaiList: Array<meisaiType> = [];
    _list.forEach((large, i) => {
      const isLargeAlready = meisaiList.length > 0
        ? meisaiList.filter((m) => m.largeName === large.$.daibunrui_name_out).length > 0
        : false;
      if (!isLargeAlready) {
        meisaiList.push({
          // No.
          largeNumber: String(meisaiList.length + 1),
          // 工事名称 大項目
          largeName: large.$.daibunrui_name_out,
          mediumList: [{
            // 工事名称 小項目
            mediumName: large.$.tyubunrui_name_out,
            smallList: [{
              // 工事名称 小項目
              smallName: `${large.$.print_name} ${large.$.kikaku || ''}`,
              // 数量
              count: large.$.suryou === '0' ? '0.00' : large.$.suryou,
              // 単位
              unit: large.$.tani_name,
              // 単価
              uPrice: Number(large.$.shikiri_kakaku).toLocaleString('ja-JP', { maximumSignificantDigits: 20 }),
              // 金額
              price: Number(large.$.kingaku).toLocaleString('ja-JP'),
              // 原価
              genka: Number(large.$.genka_kei).toLocaleString('ja-JP'),
              // 原価金額
              genkaPrice: Number(large.$.genka).toLocaleString('ja-JP'),
              // 粗利率
              arari: `${large.$.arari_ritu}%`,
              // 備考
              memo: large.$.meisai_remark,
            }],
            // 数量
            count: '1.00',
            // 単位
            unit: '式',
            // 金額
            smallPrice: large.$.kingaku,
            // 原価
            smallGenka: large.$.genka_kei,
            // 粗利率
            arari: `${large.$.arari_ritu}%`,
            // 備考
            memo: '',
          }],
          // 数量
          count: '1.00',
          // 単位
          unit: '式',
          // 金額
          mediumPrice: large.$.kingaku,
          // 原価
          mediumGenka: large.$.genka_kei,
          // 粗利率
          arari: `${large.$.arari_ritu}%`,
          // 備考
          memo: '',
        });
        return;
      }
      const mediumList = meisaiList.find(
        (m) => m.largeName === large.$.daibunrui_name_out,
      )?.mediumList;
      const index = meisaiList.findIndex((m) => m.largeName === large.$.daibunrui_name_out);
      if (index < 0) {
        throw Error('処理を確認してください。');
      }
      const isMediumAlready = mediumList
        ? mediumList.filter((m) => m.mediumName === large.$.tyubunrui_name_out).length > 0
        : false;
      if (!isMediumAlready) {
        if (index < 0) {
          throw Error('処理を確認してください。');
        }
        meisaiList[index].mediumList.push({
          // 工事名称 小項目
          mediumName: large.$.tyubunrui_name_out,
          smallList: [{
            // 工事名称 小項目
            smallName: `${large.$.print_name} ${large.$.kikaku || ''}`,
            // 数量
            count: large.$.suryou === '0' ? '0.00' : large.$.suryou,
            // 単位
            unit: large.$.tani_name,
            // 単価
            uPrice: Number(large.$.shikiri_kakaku).toLocaleString('ja-JP', { maximumSignificantDigits: 20 }),
            // 金額
            price: Number(large.$.kingaku).toLocaleString('ja-JP'),
            // 原価
            genka: Number(large.$.genka_kei).toLocaleString('ja-JP'),
            // 原価金額
            genkaPrice: Number(large.$.genka).toLocaleString('ja-JP'),
            // 粗利率
            arari: `${large.$.arari_ritu}%`,
            // 備考
            memo: large.$.meisai_remark,
          }],
          // 数量
          count: '1.00',
          // 単位
          unit: '式',
          // 金額
          smallPrice: large.$.kingaku,
          // 原価
          smallGenka: large.$.genka_kei,
          // 粗利率
          arari: `${large.$.arari_ritu}%`,
          // 備考
          memo: '',
        });
      } else {
        const smallList = mediumList?.find(
          (l) => l.mediumName === large.$.tyubunrui_name_out,
        )?.smallList;
        smallList?.push({
          // 工事名称 小項目
          smallName: `${large.$.print_name} ${large.$.kikaku || ''}`,
          // 数量
          count: large.$.suryou === '0' ? '0.00' : large.$.suryou,
          // 単位
          unit: large.$.tani_name,
          // 単価
          uPrice: Number(large.$.shikiri_kakaku).toLocaleString('ja-JP', { maximumSignificantDigits: 20 }),
          // 金額
          price: Number(large.$.kingaku).toLocaleString('ja-JP'),
          // 原価
          genka: Number(large.$.genka_kei).toLocaleString('ja-JP'),
          // 原価金額
          genkaPrice: Number(large.$.genka).toLocaleString('ja-JP'),
          // 粗利率
          arari: `${large.$.arari_ritu}%`,
          // 備考
          memo: large.$.meisai_remark,
        });
        const mediumIndex = mediumList?.findIndex(
          (l) => l.mediumName === large.$.tyubunrui_name_out,
        );
        if (mediumIndex !== undefined && mediumIndex >= 0) {
          /** 中項目の合計 */
          const mediumItem = meisaiList[index].mediumList[mediumIndex];
          mediumItem.smallPrice = String(
            Number(mediumItem.smallPrice)
            + Number(large.$.kingaku),
          );
          mediumItem.smallGenka = String(
            Number(mediumItem.smallGenka)
            + Number(large.$.genka_kei),
          );
          mediumItem.arari = `${
            Math.round(((Number(mediumItem.smallPrice) - Number(mediumItem.smallGenka))
              / Number(mediumItem.smallPrice)) * 100)
          }%`;
        }
      }
      /** 大項目の合計 */
      meisaiList[index].mediumPrice = String(
        Number(meisaiList[index].mediumPrice)
        + Number(large.$.kingaku),
      );
      meisaiList[index].mediumGenka = String(
        Number(meisaiList[index].mediumGenka)
        + Number(large.$.genka_kei),
      );
      meisaiList[index].arari = `${
        Math.round(((Number(meisaiList[index].mediumPrice) - Number(meisaiList[index].mediumGenka))
          / Number(meisaiList[index].mediumPrice)) * 100)
      }%`;
    });

    return meisaiList.map((l) => ({
      ...l,
      mediumPrice: Number(l.mediumPrice).toLocaleString('ja-JP'),
      mediumGenka: Number(l.mediumGenka).toLocaleString('ja-JP'),
      mediumList: l.mediumList.map((m) => ({
        ...m,
        smallPrice: Number(m.smallPrice).toLocaleString('ja-JP'),
        smallGenka: Number(m.smallGenka).toLocaleString('ja-JP'),
      })),
    }));
  }

  /** 粗利計算用. String を Number にして% を外す */
  static setNumberWithDeletePar(str: string): number {
    return Number(str.replace('%', ''));
  }

  /**
   *
   * 編集可否チェック
   * @static
   * @param {boolean} auth 権限
   * @param {} result 取得データ
   * @returns {(0 | 1 | 2)} 0: 編集不可, 1:通常編集, 2:受注変更ボタン
   */
  static checkAuthority(
    auth: boolean,
    result: {
      keiyaku_dt: string;
      keiyaku_no: string;
      saisyu_f: boolean;
      kankou_dt: string;
      end_dt: string;
    },
  ): 0 | 1 | 2 {
    if (!auth) return 0;

    // 編集可否の制御
    // 受注登録の場合
    if (result.keiyaku_dt && result.keiyaku_no && result.saisyu_f) {
      // 受注登録後、未完工
      if (!result.kankou_dt && !result.end_dt && !result.saisyu_f) {
        return 2;
      }
      return 0;
    }
    return 1;
  }

  /** 受注内一覧取得  */
  static listParmInOrder(param: {
    orderSort: {
      sort_by: number;
      highlow: number;
    },
    project_id: number;
  }): ApiEstimateGetListParam {
    const { orderSort, project_id } = param;
    return ({
      project_id,
      sort_by: orderSort.sort_by,
      highlow: orderSort.highlow,
      valid_flag: 1,
    });
  }

  static getLogoTransform(
    size: { width: number, height: number },
    pos: number = 0,
    initSize = { height: 31.68, width: 172.08 },
  ): {
    width: number;
    x: number;
    trans: string;
  } {
    const w = size.width / initSize.width;
    const h = size.height / initSize.height;
    const isBigW = w > h;
    const scale = (() => {
      const s = isBigW ? initSize.width / (size.width) : initSize.height / (size.height);
      if (Number.isNaN(s)) return 0;
      if (s === Infinity) return 0;
      if (!s) return 0;
      return s;
    })();
    const _x = (initSize.width - size.width * scale) / 2;
    const _y = (initSize.height - size.height * scale) / 2;
    const x = Number.isNaN(_x) ? 0 : _x;
    const y = Number.isNaN(_y) ? 0 : _y;
    const width = MathHelper.times(size.width, scale);
    const trans = `translate(${pos ?? 0} ${isBigW ? (y) : 0}) scale(${scale})`;
    return {
      width,
      x,
      trans,
    };
  }
}
