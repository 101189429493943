import { memo } from 'react';
import { useDispatch } from 'react-redux';
import { DialogActions } from '../../../../../../../redux/dialog/dialog.action';
import { Button } from '../../../../../../ui/button/button';
import './result-confirm.sp.scss';

type Props = {
  onReSearch: () => void;
}

export const AddMasterResultConfirmSP = memo((props:Props) => {
  const { onReSearch } = props;

  /* Hook */
  const dispatch = useDispatch();

  return (
    <div className="registration_block">
      <div className="registration_wrap">
        <div className="registration_font">明細を登録しました。</div>
      </div>
      <div className="estimate_detail_search_footer">
        <Button size="md" color="primary" className="item_btn" onClick={onReSearch}>続けて検索</Button>
        <Button size="md" color="dark" className="item_btn" onClick={() => dispatch(DialogActions.pop())}>見積詳細に戻る</Button>
      </div>
    </div>
  );
});
