import { ProjectEditState } from '../../../type/project/project.type';
import {
  ValidationLengthUnder255,
  ValidationLengthUnder40,
  ValidationLengthUnder500,
  ValidationLengthUnder60,
  ValidationPostNum1,
  ValidationPostNum2,
  ValidationTel,
} from '..';
import { ValidationDatePicker } from '../validation-date-picker';

/* 新規登録 */
export const ProjectAddValidation = (data: ProjectEditState) => (
  // 必須
  !data.customer_id
  || !data.name
  || !data.field_name
  || !data.field_post_no[0]
  || !data.field_post_no[1]
  || !data.field_prefecture
  || !data.field_city
  || !data.field_address

  // バリデーション
  || ValidationLengthUnder60.filter((v) => !v.run(String(data.name || ''))).length
  || ValidationLengthUnder60.filter((v) => !v.run(String(data.field_name || ''))).length
  || ValidationPostNum1.filter((v) => !v.run(String(data.field_post_no[0] || ''))).length
  || ValidationPostNum2.filter((v) => !v.run(String(data.field_post_no[1] || ''))).length
  || ValidationLengthUnder255.filter((v) => !v.run(String(data.field_city || ''))).length
  || ValidationLengthUnder255.filter((v) => !v.run(String(data.field_address || ''))).length
  || ValidationLengthUnder255.filter((v) => !v.run(String(data.field_building_name || ''))).length
  || ValidationTel.filter((v) => !v.run(String(data.field_tel_no || ''))).length
  || ValidationTel.filter((v) => !v.run(String(data.field_fax_no || ''))).length
);

/* 編集 */
export const ProjectValidation = (data: ProjectEditState) => (
  // 必須
  !data.customer_id
  || !data.name
  || !data.project_employee_id
  || !data.field_name
  || !data.field_post_no[0]
  || !data.field_post_no[1]
  || !data.field_prefecture
  || !data.field_city
  || !data.field_address

  // バリデーション
  || ValidationLengthUnder60.filter((v) => !v.run(String(data.name || ''))).length
  || ValidationLengthUnder60.filter((v) => !v.run(String(data.field_name || ''))).length
  || ValidationPostNum1.filter((v) => !v.run(String(data.field_post_no[0] || ''))).length
  || ValidationPostNum2.filter((v) => !v.run(String(data.field_post_no[1] || ''))).length
  || ValidationLengthUnder255.filter((v) => !v.run(String(data.field_city || ''))).length
  || ValidationLengthUnder255.filter((v) => !v.run(String(data.field_address || ''))).length
  || ValidationLengthUnder255.filter((v) => !v.run(String(data.field_building_name || ''))).length
  || ValidationTel.filter((v) => !v.run(String(data.field_tel_no || ''))).length
  || ValidationTel.filter((v) => !v.run(String(data.field_fax_no || ''))).length
  || ValidationDatePicker.filter((v) => !v.run(String(data.complete_date || ''))).length
  || ValidationLengthUnder500.filter((v) => !v.run(String(data.remarks || ''))).length
  || ValidationDatePicker.filter((v) => !v.run(String(data.failure_date || ''))).length
  || ValidationLengthUnder500.filter((v) => !v.run(String(data.failure_remarks || ''))).length
  || ValidationDatePicker.filter((v) => !v.run(String(data.cancel_date || ''))).length
  || ValidationLengthUnder255.filter((v) => !v.run(String(data.cancel_reason || ''))).length

);
