import { useCallback } from 'react';
import { Button } from '../../../ui/button/button';

export const OutPage = () => {
  const onClick = useCallback(() => {
    window.location.href = 'https://ship-customer.marietta.dev/#/login';
  }, []);
  return (
    <Button color="primary" onClick={onClick}>ログインページへ</Button>
  );
};
