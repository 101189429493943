import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CustomerCollection } from '../../../../../../collection/customer/customer.collection';
import { useDidMount } from '../../../../../../hooks/life-cycle';
import { ListPet } from '../../../../../../model/customer/pet-list.model';
import { ValidationLengthUnder10, ValidationLengthUnder255, ValidationLengthUnder60 } from '../../../../../../model/validation/validation-length-under';
import { ValidationNumberLengthUnder3 } from '../../../../../../model/validation/validation-number-length-under';
import { EditSP } from '../../../../../dialogs/edit/edit.sp';
import { Input } from '../../../../../ui/input/input';
import { Required } from '../../../../../ui/required/required';
import { Select } from '../../../../../ui/select/select';
import { pulldown } from '../../../../../../utilities/pulldown';
import { PetValidation } from '../../../../../../model/validation/customer/pet.validation';
import { DialogActions } from '../../../../../../redux/dialog/dialog.action';
import { Message } from '../../../../../../collection/message.collection';

type Props = {
  data?: ListPet;
  isEdit?: boolean;
  id: number;
  callbackPost: (v:ListPet) => void;
}

export const PetEditDialog = (props: Props) => {
  const {
    data,
    isEdit,
    id,
    callbackPost,
  } = props;

  /* Hooks */
  const dispatch = useDispatch();

  /* State */
  const [touch, setTouch] = useState(false);
  const [name, setName] = useState('');
  const [type, setType] = useState('');
  const [sex, setSex] = useState(1);
  const [age, setAge] = useState('');
  const [pet_id, setPet_id] = useState(NaN);

  /* Callback */
  const handleClickPost = useCallback(() => {
    if (PetValidation({
      name,
      type,
      age: age === '' ? NaN : Number(age),
    })) {
      dispatch(DialogActions.pushMessage({
        title: 'ペット情報登録',
        message: Message.postError,
        callback: () => setTouch(true),
        callbackClose: () => setTouch(true),
      }));
      return;
    }
    callbackPost({
      id,
      customer_id: data?.customer_id ?? NaN,
      name,
      type,
      sex,
      age: age === '' ? NaN : Number(age),
      index: isEdit ? data?.index : undefined,
    });
  },
  [name, type, sex, age, id, pet_id, data]);

  useDidMount(() => {
    if (isEdit && data) {
      setName(data.name);
      setType(data.type || '');
      setSex(data.sex);
      setAge(String(data.age) || '');
      setPet_id(data.id ?? NaN);
    }
  });

  return (
    <EditSP mode="add" callback={handleClickPost}>
      <div className="edit_sp_body_inner pet_edit_dialog_sp">
        <div className="category_wrap">

          <div className="item_wrap">
            <div className="item_label">
              名前<Required />
            </div>
            <div className="item_body">
              <Input
                value={name}
                onChange={(e) => setName(e.target.value)}
                validationList={ValidationLengthUnder255}
                touch={touch}
                require
                errorPosBottom
              />
            </div>
          </div>

          <div className="item_wrap">
            <div className="item_label">
              種別
            </div>
            <div className="item_body">
              <Input
                value={type}
                onChange={(e) => setType(e.target.value)}
                validationList={ValidationLengthUnder10}
              />
            </div>
          </div>

          <div className="item_wrap">
            <div className="item_label">
              性別
            </div>
            <div className="item_body item_gender">
              <Select
                value={sex}
                className="full_width"
                options={pulldown(CustomerCollection.petSexualOption)}
                onChange={(v) => setSex(Number(v))}
              />
            </div>
          </div>

          <div className="item_wrap">
            <div className="item_label">
              年齢
            </div>
            <div className="item_body item_age">
              <Input
                type="number"
                value={age}
                onChange={(e) => {
                  setAge(e.target.value);
                }}
                validationList={ValidationNumberLengthUnder3}
                maxLength={3}
              />才
            </div>
          </div>
        </div>
      </div>
    </EditSP>
  );
};
