import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { cloneDeep } from 'lodash';
import { Table } from '../../../../../ui/table/table';
import './order-list.pc.scss';
import { EstimateListType } from '../../../../../../type/estimate/estimate.type';
import { OrderCollection } from '../../../../../../collection/order/order.collection';
import { DateFormatter } from '../../../../../../utilities/date-formatter';
import Ordered from '../../../../../../asset/images/icon/ordered.svg';
import { useAppSelector } from '../../../../../../hooks/use-redux';
import { MathHelper } from '../../../../../../utilities/math-helper';
import { EstimateCalcModelPC } from '../../../../../../collection/estimate/estimate-calc.model.pc';
import { OrderModel } from '../../../../../../model/order/order.model';
import { DisplayElements } from '../../../../../../type/display-elements.type';

type Props = {
  list: EstimateListType[] | null;
  selectEstimate: EstimateListType | null;
  callback: (estimate: EstimateListType | null) => void;
  callbackSort: (estimate: { sort_by: number, highlow: number }) => void;
}

export const OrderListPC = (props: Props) => {
  const {
    list, callback, callbackSort, selectEstimate,
  } = props;
  /* Hook */
  const bills = useAppSelector((state) => state.bill.list);

  /* State */
  const [selected, setSelected] = useState<number[]>([]);
  const [orderSort, setOrderSort] = useState<
    { sort: number, highlow: number; }>({ highlow: 0, sort: 0 });

  /* Memo */
  const showEstimateList = useMemo(() => {
    if (!list) return null;
    const _list: DisplayElements[][] = cloneDeep(list).map((v) => (
      [
        /* 選択アイコン */
        // selectEstimate?.id === v.id
        //   ? <img src={Ordered} alt="見積選択" title="見積選択" className="icon" />
        //   : '',
        v.id,
        /* 見積番号 */
        v.estimate_no, /* v.quote_no, */
        /* 見積日 */
        DateFormatter.date2str(v.estimate_dt), /*  DateFormatter.date2str(v.quote_date) */
        /* 見積作成者 */
        v.employee_name, /* v.quote_creator, */
        /* 税込合計見積 */
        MathHelper.localStr(v.gokei_kin || 0),
        /* 税込合計原価 */
        MathHelper.localStr(v.gokei_genka_kin || 0),
        /* 粗利額 */
        MathHelper.localStr(MathHelper.minus(
          v.gokei_zeinuki_kin, v.gokei_zeinuki_genka_kin,
        )),
        /* 粗利率 */
        `${EstimateCalcModelPC.arariRitsuCal(
          v.gokei_zeinuki_kin,
          v.gokei_zeinuki_genka_kin,
          true,
        )}%`,
        /* 備考 */
        v.remark || '',
      ]
    ));
    const showList = OrderModel.tableSort({ _list, ...orderSort });
    showList.forEach((v, i) => {
      if (v[0] !== undefined) {
        const icon = selectEstimate?.id === v[0]
          ? <img src={Ordered} alt="見積選択" title="見積選択" className="icon" />
          : '';
        // eslint-disable-next-line no-param-reassign
        v[0] = icon;
      }
    });
    return showList;
  }, [list, selectEstimate, orderSort]);

  const sendDataList = useMemo(() => {
    if (!showEstimateList) return null;
    if (!list) return null;
    const _list = showEstimateList.map((v) => {
      const value = list.find((v2) => v2.estimate_no === v[1])!;
      return value;
    });

    if (selectEstimate) {
      const findIndex = _list.findIndex((v2) => v2.id === selectEstimate.id);
      if (findIndex !== -1) {
        setSelected([findIndex]);
      }
    }

    return _list;
  }, [showEstimateList, list, selectEstimate]);

  /* Callback */
  const handleClickRow = useCallback((v: EstimateListType) => {
    if (!sendDataList) return;
    if (!v.id) {
      setSelected([]);
      callback(null);
      return;
    }
    callback(cloneDeep(v));
    const findIndex = sendDataList.findIndex((v2) => v2.id === v.id);
    if (findIndex !== -1) {
      setSelected([findIndex]);
    }
  },
  [callback, list, sendDataList]);

  return (
    <section className="list_area">
      <div className="inner">
        <div className="table_responsive">
          <Table
            className="table_selectable table_sortable table_sticky table_cell_change"
            header={OrderCollection.orderHeader}
            onClickRow={handleClickRow}
            sort={{ onClick: (highlow, order) => setOrderSort({ highlow, sort: order }) }}
            selectedTr={selected}
            rowDataList={sendDataList || []}
            lists={showEstimateList}
            option={{
              stringWidth: [
                // { index: 0, width: 100 }, // 見積選択
                // { index: 1, width: 100 }, // 見積番号
                // { index: 2, width: 50 }, // 作成日
                // { index: 3, width: 50 }, //  見積作成者
                // { index: 4, width: 150 }, // 見積金額
                // { index: 5, width: 150 }, // 消費税額
                // { index: 6, width: 150 }, // 税込合計見積
                // { index: 7, width: 150 }, // 原価合計
                // { index: 8, width: 150 }, // 消費税額
                // { index: 9, width: 150 }, // 税込合計原価
                // { index: 10, width: 150 }, // 調整額
                // { index: 11, width: 150 }, // 見積工期_開始
                // { index: 12, width: 150 }, // 見積工期_終了
              ],
              tdAlign: [
                { index: 0, align: 'center' },
                { index: 1, align: 'left' },
                { index: 2, align: 'left' },
                { index: 3, align: 'left' },
                { index: 4, align: 'right' },
                { index: 5, align: 'right' },
                { index: 6, align: 'right' },
                { index: 7, align: 'right' },
                { index: 8, align: 'right' },
                { index: 9, align: 'right' },
                { index: 10, align: 'right' },
                { index: 11, align: 'center' },
                { index: 12, align: 'center' },
              ],
            }}
          />
        </div>
      </div>
    </section>
  );
};
