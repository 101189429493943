import { useDispatch, useSelector } from 'react-redux';
import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { MasterBody, MasterGetListParam } from './body/master-body';
import { MasterActions } from '../../../../../redux/master/master.action';
import { DialogActions } from '../../../../../redux/dialog/dialog.action';
import { State } from '../../../../../redux/root.reducer';
import { MasterCollection } from '../master.collection';
import { ProjectRankMasterEditDialog } from '../edit-dialogs/rank/project-rank-master-edit-dialog/project-rank-master-edit-dialog';

export const MasterProjectRankBody = () => {
  /* Hook */
  const list = useSelector((state:State) => state.master.projectRankList);
  const dispatch = useDispatch();

  /* State */
  const [isSort, setIsSort] = useState(false);
  const [isMuko, setIsMuko] = useState(false);
  const [orderSort, setOrderSort] = useState<MasterGetListParam>({ highlow: 0, sort_by: 0 });

  /* Callback */
  const getList = useCallback((v: MasterGetListParam) => {
    const sort_by = v.sort_by ? v.sort_by - 1 : 0;
    dispatch(MasterActions.api.projectRank.getList({ sort_by, highlow: v.highlow, is_muko: 1 }));
    setOrderSort(v);
  }, []);

  const openEditDialog = useCallback((v?) => {
    dispatch(DialogActions.push({
      title: '案件見込みランク 編集／追加',
      element: <ProjectRankMasterEditDialog id={v} callback={() => getList(orderSort)} />,
    }));
  }, [getList, orderSort]);

  const tableList = useMemo(() => {
    const filter = (isMuko || isSort) ? list : list.filter((v) => v.valid_flag);
    return filter.map((v) => ([
      v.order,
      v.internal_id,
      v.name,
      v.abbreviation,
      <>{v.background_color} <div className="color_box ml_5" style={{ backgroundColor: v.background_color }} /></>,
      <>{v.text_color} <div className="color_box ml_5" style={{ backgroundColor: v.text_color }} /></>,
      v.valid_flag ? <i className="far fa-circle" /> : '',
    ]));
  }, [list, isMuko, isSort]);

  return (
    <MasterBody
      header={MasterCollection.projectRankMasterHeader}
      rowDataList={list}
      list={tableList}
      callbackEdit={openEditDialog}
      callbackGetList={getList}
      callbackIsMuko={setIsMuko}
      callbackIsSort={setIsSort}
      sortIndex={17}
      defaultOrder={0}
      tableOption={{
        stringWidth: [
          { index: 0, width: 50 }, // 編集
          // { index: 1, width: 50 }, // 順位
          // { index: 2, width: 50 }, // ID
          // { index: 3, width: 50 }, // 案件見込みランク名
          // { index: 4, width: 50 }, // 略称表示
          // { index: 5, width: 100 }, // 背景色
          // { index: 6, width: 100 }, // 文字色
          // { index: 7, width: 100 }, // 有効フラグ
        ],
        tdAlign: [
          { index: 0, align: 'center' },
          { index: 1, align: 'center' },
          { index: 2, align: 'center' },
          { index: 3, align: 'center' },
          { index: 4, align: 'center' },
          { index: 5, align: 'center' },
          { index: 6, align: 'center' },
          { index: 7, align: 'center' },
        ],
      }}
    />
  );
};
