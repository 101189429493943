import { ValidationEisuzi } from '../validation-eisuzi';
import { ValidationMax10Billion } from '../validation-max-10-billion';
import { ValidationLengthUnder1 } from '../_validation-length-under-1';

export const MasterCustomerRankValidation = (
  name: string,
  abbreviation: string,
  backGround: string,
  textColor: string,
  amount: string,
) => {
  window.console.log();
  return (
    // 必須
    !name
    || !abbreviation
    || !amount
    || !backGround
    || !textColor

  // バリデーション
  || ValidationEisuzi.filter((v) => !v.run(String(abbreviation || ''))).length
  );
};
