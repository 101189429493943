import { ApiBase } from '../../../../service/api-base';

export type ApiEstimatePrintParam = {
  id: number;
  data: {
    svg_file: string[]; // svg の base64 データ
    preset_type: 1 | 2; // 印刷方向 1: A4横, 2: A4縦
    file_name: string; // ダウンロード時のデフォルトのファイル名 (クライアント側で使用)
    type: 'download' | 'preview';
  }
}

export type ApiEstimatePrintResponse = {
  id: number;
  data: {}
}

export class ApiEstimatePrint extends ApiBase<ApiEstimatePrintResponse> {
  constructor(param:ApiEstimatePrintParam) {
    const {
      id, data,
    } = param;
    super({
      contents: 'PDF',
      httpMethod: 'POST',
      url: '/api/pdf',
      param: data,
    });
  }
}
