import { reducerWithInitialState } from 'typescript-fsa-reducers';
import * as lodash from 'lodash';
import cloneDeep from 'lodash/cloneDeep';
import { CustomerActions } from './customer.action';
import { CustomerListType, Customer, CustomerSortState } from '../../type/customer/customer.type';
import { Pet } from '../../type/customer/pet.type';
import { FamilyList } from '../../type/customer/family.type';
import { CustomerCollection } from '../../collection/customer/customer.collection';
import { concatList } from '../root.reducer';

export type CustomerState = {
  customer: Customer | null;
  list: CustomerListType[] | null;
  routeList: CustomerListType[] | null;
  listHitCount: number;
  petList: Pet[];
  petListHitCount: number;
  familyList: FamilyList[];
  familyListHitCount: number;
  sort: CustomerSortState;
};

const initialState: CustomerState = {
  customer: null,
  list: null,
  routeList: null,
  listHitCount: 0,
  petList: [],
  petListHitCount: 0,
  familyList: [],
  familyListHitCount: 0,
  sort: CustomerCollection.customerSortInitialState,
};

export const CustomerReducer = reducerWithInitialState<CustomerState>(initialState)
  .case(CustomerActions.setCustomer, (state, payload) => ({
    ...state,
    customer: lodash.cloneDeep(payload),
  }))
  .case(CustomerActions.setList, (state, payload) => ({
    ...state,
    list: cloneDeep(payload),
  }))
  .case(CustomerActions.setRouteList, (state, payload) => ({
    ...state,
    routeList: cloneDeep(payload),
  }))
  .case(CustomerActions.setConcatList, (state, payload) => ({
    ...state,
    list: cloneDeep(concatList(state.list, payload)),
  }))
  .case(CustomerActions.setListCount, (state, payload) => ({
    ...state,
    listHitCount: lodash.cloneDeep(payload),
  }))
  .case(CustomerActions.setPetListCount, (state, payload) => ({
    ...state,
    petListHitCount: lodash.cloneDeep(payload),
  }))
  .case(CustomerActions.setFamilyListCount, (state, payload) => ({
    ...state,
    familyListHitCount: lodash.cloneDeep(payload),
  }))
  .case(CustomerActions.setSort, (state, payload) => ({
    ...state,
    sort: payload ? lodash.cloneDeep({
      ...(state.sort),
      ...(payload),
    }) : ({
      ...CustomerCollection.customerSortInitialState,
    }),
  }))
  .case(CustomerActions.setInitSort, (state, payload) => ({
    ...state,
    sort: CustomerCollection._customerSortInitialState(payload),
  }))
  .case(CustomerActions.resetState, () => initialState)
  .default((state) => state);
