import { ClickAwayListener, IconButton, Tooltip } from '@material-ui/core';
import HelpIcon from '@mui/icons-material/Help';
import { memo, useState } from 'react';
import { Message } from '../../../../collection/message.collection';

type Props = {
  title: boolean
  | globalThis.React.ReactChild
  | globalThis.React.ReactFragment
  | globalThis.React.ReactPortal;
}

export const InfoButton = ({ title }:Props) => {
  /* State */
  const [open, setOpen] = useState(false);

  const _title = Array.isArray(title)
    ? (
      <div>
        {title.map((v) => <>{v}<br /></>)}
      </div>
    )
    : title;

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <Tooltip
        PopperProps={{
          disablePortal: true,
        }}
        onClose={() => setOpen(false)}
        open={open}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        title={_title}
        placement="top"
        arrow
      >
        <IconButton
          className="icon_button btn_tips"
          onClick={() => setOpen((v) => !v)}
        >
          <i className="fas fa-question-circle" />
        </IconButton>
      </Tooltip>
    </ClickAwayListener>
  );
};
