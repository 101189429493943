type Props = {
  text: string | undefined;
}

export const BreakLine = (props: Props) => {
  const { text = '' } = props;
  return (
    text
      ? (
        <div>
          {text.split('\n').map((v) => (
            <div>{v}</div>
          ))}
        </div>
      )
      : <></>
  );
};
