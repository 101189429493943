import { replace } from 'connected-react-router';
/**
 * 引数のstrをlimitで指定した長さで区切った配列を返す
 * 半角を0.5, 全角を1として文字列の長さを計算
 * @param 分割したい文字列
 * @param 一行に収まる全角での文字数
 */
export const splitStringByLimitedValue = (str: string, limit: number): string[] => {
  if (!str) return [];
  let len = 0;
  const strArr = [];
  let preSliceNum = 0;
  let strNum = 0;
  for (let i = 0; i < str.length; i += 1) {
    if (str[i] !== '\r') {
      if (str[i].match(/[ -~ｦ-ﾟ]/)) {
        strNum += 1;
        len += 0.5;
      } else {
        strNum += 1;
        len += 1;
      }
      // 次の文字が空でpush
      if (!(str[i + 1])) {
        strArr.push(str.substr(preSliceNum, strNum));
        break;
      }
      // (limit - 0.5)で次の文字が全角ならpush
      if (len === (limit - 0.5) && !(str[i + 1].match(/[ -~]/))) {
        strArr.push(str.substr(preSliceNum, strNum));
        len = 0;
        preSliceNum = i + 1;
        strNum = 0;
      }
      // limitでpush
      if (len === limit) {
        strArr.push(str.substr(preSliceNum, strNum));
        len = 0;
        preSliceNum = i + 1;
        strNum = 0;
      }
      // 改行コードでpush
      if (str[i] === '\n') {
        strArr.push(str.substr(preSliceNum, strNum));
        len = 0;
        preSliceNum = i + 1;
        strNum = 0;
      }
    }
  }
  // console.log(strArr);
  const repStrArr = strArr.map((v) => v.replace('\r', '').replace('\n', ''));
  repStrArr.forEach((v, i) => {
    if (i !== 0 && (v[0] === '）' || v[0] === ')')) {
      // eslint-disable-next-line no-multi-assign
      repStrArr[i - 1] = repStrArr[i - 1] += v[0];
      repStrArr[i] = repStrArr[i].substring(1);
    }
  });
  // console.log(repStrArr);
  return repStrArr;
};

export const limitStringValue = (value: string, strLimit: number, lineLimit?: number): string => {
  const _str = value || '';
  const split = _str.split('\r');
  // console.log(split);
  const returnValue: string[] = [];
  split.forEach((v, i) => {
    let count = 0;
    let str = '';
    const originStr = v.replace('\n', '').split('');
    if (originStr.length) {
      originStr.forEach((v2, j) => {
        if (strLimit >= count) {
          if (v2.match(/[ -~ｦ-ﾟ]/)) {
            count += 0.5;
          } else if (v2 !== '\n') {
            count += 1;
          }
          str += v2;
          if (j === v.replace('\n', '').length - 1) {
            returnValue.push(str);
            count = 0;
            str = '';
          }
        } else {
          returnValue.push(str);
          count = 0;
          str = '';
        }
      });
    } else {
      returnValue.push('');
    }
  });
  // console.log('\n\n\n\n'.split('\n'));

  return returnValue.slice(0, lineLimit || returnValue.length).join('\n');
};
