import { cloneDeep } from 'lodash';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { RegistrationAddressMapDialogPC } from '../../components/ui/map/registration-address-map-dialog/pc/registration-address-map-dialog.pc';
import { RegistrationAddressMapDialog } from '../../components/ui/map/registration-address-map-dialog/registration-address-map-dialog';
import { DialogActions } from '../../redux/dialog/dialog.action';
import { MapActions } from '../../redux/map/map.action';
import { Address } from '../../type/map/map.type';
import { useAppSelector } from '../use-redux';
import { CustomerListType } from '../../type/customer/customer.type';
import { MaintenanceList } from '../../type/maintenance/maintenance.type';
import { ProjectListType } from '../../type/project/project.type';
import { CustomerActions } from '../../redux/customer/customer.action';
import { CustomerModel } from '../../model/customer/customer-model';
import { ProjectActions } from '../../redux/project/project.action';
import { ProjectModel } from '../../model/project/project.model';

/** 地図から入力 */
export const useRegistrationMap = (isSP?:'sp', isRoute?: boolean, type?: 'customer' | 'project' | 'maintenance', isNoSearch?: boolean) => {
  const centerPos = useAppSelector((v) => v.map.centerPos);
  const mapAreaPos = useAppSelector((v) => v.map.mapAreaPos);
  const zoomLevel = useAppSelector((v) => v.map.zoomLevel);

  const dispatch = useDispatch();

  return useCallback((callback: (v: Address | null) => void) => {
    const _centerPos = cloneDeep(centerPos);
    const _mapAreaPos = cloneDeep(mapAreaPos);
    const _zoomLevel = cloneDeep(zoomLevel);

    const clear = () => {
      dispatch(MapActions.setCenterPos(_centerPos));
      dispatch(MapActions.setZoomLevel(_zoomLevel));
      if (_mapAreaPos) {
        dispatch(MapActions.setMapAreaPosition(_mapAreaPos));
      }
    };

    dispatch(DialogActions.push({
      title: '住所を登録したい場所をクリックしてください',
      className: isSP
        ? ''
        : 'max_height_dialog map_dialog',
      onCloseClick: clear,
      element: isSP
        ? (
          <RegistrationAddressMapDialog
            type={type}
            isNoSearch={isNoSearch}
            label="登録"
            callback={(address) => {
              clear();
              callback(address);
            }}
          />
        )
        : (
          <RegistrationAddressMapDialogPC
            label="登録"
            callback={(address) => {
              clear();
              callback(address);
            }}
          />
        ),
    }));
  }, [centerPos, zoomLevel, mapAreaPos, isSP]);
};

/** 地図から顧客登録 */
export const useRegistrationMapCustomer = (isSP?:'sp') => {
  const centerPos = useAppSelector((v) => v.map.centerPos);
  const mapAreaPos = useAppSelector((v) => v.map.mapAreaPos);
  const zoomLevel = useAppSelector((v) => v.map.zoomLevel);
  const sort = useAppSelector((v) => v.customer.sort);

  const dispatch = useDispatch();

  return useCallback((
    callback: (v: CustomerListType) => void,
    callbackAddress?: (v: string) => void,
  ) => {
    const _centerPos = cloneDeep(centerPos);
    const _mapAreaPos = cloneDeep(mapAreaPos);
    const _zoomLevel = cloneDeep(zoomLevel);

    const clear = () => {
      dispatch(MapActions.setCenterPos(_centerPos));
      dispatch(MapActions.setZoomLevel(_zoomLevel));
      if (_mapAreaPos) {
        dispatch(MapActions.setMapAreaPosition(_mapAreaPos));
      }
      dispatch(CustomerActions.api.customer.getList({
        param: CustomerModel.listParam({
          sortState: sort,
          isMap: true,
          isSp: !!isSP,
          mapAreaPos: _mapAreaPos,
        }),
      }));
    };

    const hCallbackAddress = (d: Address | null) => {
      clear();
      callbackAddress?.(d?.formattedAddress || '');
    };

    const getData = (d: CustomerListType | MaintenanceList | ProjectListType) => {
      clear();
      callback(d as CustomerListType);
    };

    dispatch(DialogActions.push({
      title: '登録したい顧客をクリックしてください',
      className: isSP
        ? ''
        : 'max_height_dialog map_dialog',
      onCloseClick: clear,
      element: isSP
        ? (
          <RegistrationAddressMapDialog
            type="customer"
            label="登録"
            callbackData={getData}
            callback={hCallbackAddress}
          />
        )
        : (
          <RegistrationAddressMapDialogPC
            label="登録"
            type="customer"
            callbackData={getData}
            callback={hCallbackAddress}
          />
        ),
    }));
  }, [centerPos, zoomLevel, mapAreaPos, isSP, sort]);
};

/** 地図から案件登録 */
export const useRegistrationMapProject = (isSP?:'sp') => {
  const centerPos = useAppSelector((v) => v.map.centerPos);
  const mapAreaPos = useAppSelector((v) => v.map.mapAreaPos);
  const zoomLevel = useAppSelector((v) => v.map.zoomLevel);
  const sort = useAppSelector((v) => v.project.sort);
  const user = useAppSelector((v) => v.user);

  const dispatch = useDispatch();

  return useCallback((
    callback: (v: ProjectListType) => void,
    callbackAddress?: (v: string) => void,
  ) => {
    const _centerPos = cloneDeep(centerPos);
    const _mapAreaPos = cloneDeep(mapAreaPos);
    const _zoomLevel = cloneDeep(zoomLevel);

    const clear = () => {
      const setEmployeeId = (): number | undefined => {
        if (!user.view_data || user.view_data.company_id === 1) {
          return user.id;
        }
        return NaN;
      };
      dispatch(MapActions.setCenterPos(_centerPos));
      dispatch(MapActions.setZoomLevel(_zoomLevel));
      if (_mapAreaPos) {
        dispatch(MapActions.setMapAreaPosition(_mapAreaPos));
      }
      dispatch(ProjectActions.api.project.getList({
        param: {
          ...ProjectModel.listParam({
            sortState: sort,
            isMap: true,
            isSp: !!isSP,
            mapAreaPos: _mapAreaPos,
          }),
          project_employee_id: setEmployeeId(),
        },
      }));
    };

    const hCallbackAddress = (d: Address | null) => {
      clear();
      callbackAddress?.(d?.formattedAddress || '');
    };

    const getData = (d: CustomerListType | MaintenanceList | ProjectListType) => {
      clear();
      callback(d as ProjectListType);
    };

    dispatch(DialogActions.push({
      title: '登録したい案件をクリックしてください',
      className: isSP
        ? ''
        : 'max_height_dialog map_dialog',
      onCloseClick: clear,
      element: isSP
        ? (
          <RegistrationAddressMapDialog
            label="登録"
            type="project"
            callbackData={getData}
            callback={hCallbackAddress}
          />
        )
        : (
          <RegistrationAddressMapDialogPC
            label="登録"
            type="project"
            callbackData={getData}
            callback={hCallbackAddress}
          />
        ),
    }));
  }, [centerPos, zoomLevel, mapAreaPos, isSP, sort]);
};
