import { useCallback, useMemo, useState } from 'react';
import { cloneDeep } from 'lodash';
import { EstimateMeisaiModel } from '../../../../../model/estimate/estimate-meisai.model';
import { Resize } from '../../../../ui/resize/resize';
import { EstimateMeisaiListPC } from './list/estimate-meisai-list.pc';
import { EstimateTree } from './tree/estimate-tree';
import { EditPriceAreaState } from '../../../../../type/estimate/estimate.type';
import { MathHelper } from '../../../../../utilities/math-helper';
import { EstimateCalcModelPC } from '../../../../../collection/estimate/estimate-calc.model.pc';

export type SelectTreeData = {
  daibunrui_id?: string;
  tyubunrui_id?: string;
}

type Props = {
  priceModel: EditPriceAreaState;
  model: EstimateMeisaiModel | null;
  allowEdit: 0 | 1 | 2;
  callback: (v:EstimateMeisaiModel) => void;
}

const Calc = EstimateCalcModelPC;

export const ExplorePC = (props: Props) => {
  const {
    priceModel,
    model,
    allowEdit,
    callback,
  } = props;

  const [selectTree, setSelectTree] = useState<SelectTreeData | null>(null);

  const changeSelectTree = useCallback((v: SelectTreeData) => {
    setSelectTree(cloneDeep(v));
  }, []);

  const showPrice = useMemo(() => {
    if (!model) {
      return ({
        zeinuki_kin: 0,
        genka_kin: 0,
      });
    }
    if (!selectTree) {
      const fil = model.list
        .map((v) => ({
          zeinuki_kin: v.$.kingaku,
          genka_kin: v.$.genka_kei,
        }));
      const zeinuki_kin = Calc.price(fil.map((v) => v.zeinuki_kin)) || 0;
      const genka_kin = Calc.price(fil.map((v) => v.genka_kin)) || 0;
      return ({
        zeinuki_kin,
        genka_kin,
      });
    }
    if (selectTree?.daibunrui_id === undefined && selectTree.daibunrui_id === undefined) {
      return ({
        zeinuki_kin: MathHelper.plus(priceModel.zeinuki_kin, priceModel.tyosei_kin),
        genka_kin: priceModel.genka_kin,
      });
    }
    if (selectTree.tyubunrui_id) {
      const fil = model.list
        .filter((v) => v.$.tyubunrui_id === selectTree.tyubunrui_id
          && v.$.daibunrui_id === selectTree.daibunrui_id)
        .map((v) => ({
          zeinuki_kin: v.$.kingaku,
          genka_kin: v.$.genka_kei,
        }));
      const zeinuki_kin = Calc.price(fil.map((v) => v.zeinuki_kin)) || 0;
      const genka_kin = Calc.price(fil.map((v) => v.genka_kin)) || 0;
      return ({
        zeinuki_kin,
        genka_kin,
      });
    }
    const fil = model.list
      .filter((v) => v.$.daibunrui_id === selectTree.daibunrui_id)
      .map((v) => ({
        zeinuki_kin: v.$.kingaku,
        genka_kin: v.$.genka_kei,
      }));
    const zeinuki_kin = Calc.price(fil.map((v) => v.zeinuki_kin)) || 0;
    const genka_kin = Calc.price(fil.map((v) => v.genka_kin)) || 0;
    console.log(MathHelper.minus(zeinuki_kin, genka_kin), 0, 'floor', true);
    return ({
      zeinuki_kin,
      genka_kin,
    });
  }, [selectTree, model, priceModel.tyosei_kin, model?.list]);

  return (
    <>
      <div className="price_box">
        <div>見積金額合計 : <strong>¥{MathHelper.rounding2Str(MathHelper.plus(showPrice.zeinuki_kin), 0, 'floor', true)}</strong></div>
        <div>原価金額合計 : <strong>¥{MathHelper.rounding2Str(showPrice.genka_kin, 0, 'floor', true)}</strong></div>
        <div>粗利額 : <strong>¥{MathHelper.rounding2Str(MathHelper.minus(showPrice.zeinuki_kin, showPrice.genka_kin), 0, 'floor', true)}</strong></div>
        <div>粗利率 :
          <strong> {
            EstimateCalcModelPC.arariRitsuCal(
              showPrice.zeinuki_kin,
              showPrice.genka_kin,
              true,
            )
          }%
          </strong>
        </div>
      </div>
      <div className="estimate_box">
        <Resize enabled={{ right: true }}>
          <EstimateTree
            meisaiModel={model}
            allowEdit={allowEdit}
            callback={changeSelectTree}
            callbackModel={callback}
          />
        </Resize>
        <EstimateMeisaiListPC
          model={model}
          treeData={selectTree}
          callbackModel={callback}
          callback={() => {}}
          allowEdit={allowEdit}
        />
      </div>
    </>
  );
};
