import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { DialogActions } from '../../../../../redux/dialog/dialog.action';
import { FileListType, FileSortState } from '../../../../../type/file/file.type';
import { Table } from '../../../../ui/table/table';
import { ProjectCollection } from '../../../../../collection/project/project.collection';
import { FileEditPC } from '../../file/edit/file-edit.pc';
import { LeftIconButton } from '../../../../ui/button/left-icon-button/left-icon-button';
import { FileActions } from '../../../../../redux/file/file.action';
import { RefindFileTable } from './refind-file-table/refind-file-table';
import { DateFormatter } from '../../../../../utilities/date-formatter';
import { ProjectDetailActions } from '../../../../../redux/project-detail/project-detail.action';
import { useAppSelector } from '../../../../../hooks/use-redux';
import { FileModel } from '../../../../../model/file/file.model';
import { useDidMount } from '../../../../../hooks/life-cycle';
import { MathHelper } from '../../../../../utilities/math-helper';
import { FileSize } from './file-size';

type Props = {
  disabled?: boolean;
  fileSize: { capacity: number, total: number; };
  getList: (data?:FileSortState) => void;
}

export const FileTable = (props: Props) => {
  const { disabled, fileSize, getList } = props;
  /* Hook */
  const dispatch = useDispatch();
  const sort = useAppSelector((v) => v.projectDetail.fileSort);
  const list = useAppSelector((v) => v.projectDetail.fileList);
  const project = useAppSelector((v) => v.project.project);

  /* State */
  const [selected, setSelected] = useState<number[]>([]);
  const [formatList, setFormatList] = useState<string[]>([]);
  // const [fileSize, setFileSize] = useState<
  //   { capacity: number, total: number; }>({ capacity: 0, total: 0 });

  /* Memo */
  const totalSize = useMemo(() => {
    const kb = MathHelper.div(fileSize.total, 1024);
    if (kb > 1024) {
      const mb = MathHelper.div(kb, 1024);

      return `${MathHelper.rounding(mb, 2, 'ceil')}MB`;
    }
    return `${MathHelper.rounding(kb, 2, 'ceil')}KB`;
  }, [fileSize]);
  const percent = useMemo(() => {
    const per = (fileSize.total <= 0) ? 0 : MathHelper.rounding(
      MathHelper.times(
        MathHelper.div(fileSize.total, fileSize.capacity),
        100,
      ),
      0,
      'ceil',
    );
    return (per > 100) ? 100 : per;
  }, [fileSize]);

  /* Callback */
  /* 一覧取得 */
  // const getList = useCallback((data?:FileSortState) => {
  //   const sortData = data || sort;
  //   if (!project?.id) return;
  //   dispatch(FileActions.api.file.getList({
  //     noLoad: true,
  //     param: FileModel.listParamInProject(sortData, project.id),
  //     onSuccess: (v, file) => {
  //       dispatch(ProjectDetailActions.setFileList(v));
  //       setFileSize({ ...file });
  //     },
  //   }));
  // }, [project?.id, sort]);

  /* 編集 */
  const edit = useCallback((v?:FileListType) => {
    if (!project) return;
    dispatch(DialogActions.push({
      title: 'ファイルアップロード',
      element: <FileEditPC
        id={v?.id}
        callback={getList}
        project={v ? undefined : project}
      />,
    }));
  },
  [project, getList]);

  const handleClickRow = useCallback((v:FileListType) => {
    if (!list) return;
    const findIndex = list.findIndex((v2) => v2.id === v.id);
    if (findIndex !== -1) {
      setSelected([findIndex]);
    }
  }, [list]);

  const handleDbClickRow = useCallback((v:FileListType) => {
    if (!list) return;
    const findIndex = list.findIndex((v2) => v2.id === v.id);
    if (findIndex !== -1) {
      setSelected([findIndex]);
    }
    edit(v);
  }, [list, edit]);

  useEffect(() => {
    getList();
  }, [project?.id, sort.highlow, sort.sort_by]);

  const handleClickHeader = useCallback((highlow:0|1, sort_by:number) => {
    dispatch(ProjectDetailActions.setFileSort({ highlow, sort_by }));
  }, []);

  const openSort = useCallback(() => {
    dispatch(DialogActions.push({
      title: '絞込み',
      element: <RefindFileTable
        formatList={formatList}
        callback={(v) => {
          dispatch(ProjectDetailActions.setFileList(null));
          getList(v);
        }}
      />,
    }));
  }, [getList, formatList]);

  /* ファイルダウンロード */
  const downloadFile = useCallback((v:FileListType) => {
    dispatch(FileActions.api.file.download({
      param: { file_id: v.id },
      fileName: v.file_name || '',
    }));
  }, []);

  /* ファイル削除 */
  const deleteFile = useCallback((v:FileListType) => {
    dispatch(DialogActions.pushMessage({
      title: 'ファイル削除',
      message: ['削除しますか'],
      isCancel: true,
      callback: () => {
        dispatch(FileActions.api.file.delete({
          param: { id: v.id },
          callback: getList,
        }));
      },
    }));
  }, [getList]);

  useEffect(() => {
    if (!project?.id) return;
    dispatch(FileActions.api.file.getFormatList({
      param: FileModel.listParamInProject(sort, project.id),
      onSuccess: setFormatList,
    }));
  }, [project?.id]);

  return (
    <div className="detail_table_area">
      <div className="btn_box">
        <LeftIconButton
          label="新規ファイル登録"
          fontAwesomeClass="fas fa-edit"
          className="btn_search for_detail"
          size="sm"
          color="primary"
          disabled={disabled}
          onClick={() => edit()}
        />
        <div className="right_box">
          {/* <div className="file_info mr_20">
            <div className="file_capacity mr_10">
              <div className="inner" style={{ width: '50%' }} />
              <div className="text_box">
                {percent}
              </div>
            </div>
            <div className="total_box">
              サイズ合計:<strong>{totalSize}</strong>
            </div>
          </div> */}
          <FileSize
            percent={percent}
            totalSize={totalSize}
            maxSize={fileSize.capacity}
          />
          <LeftIconButton
            label="絞込み"
            fontAwesomeClass="fas fa-filter"
            className="btn_search for_detail"
            size="sm"
            color="secondary"
            onClick={openSort}
          />
        </div>
      </div>
      <div className="table_responsive">
        <Table
          className="table_selectable table_sortable table_sticky table_cell_change"
          header={ProjectCollection.file}
          onClickRow={handleClickRow}
          onDbClick={handleDbClickRow}
          sort={{
            index: [0, 1, 2, 3, 4, 5, 6, 7, 8],
            onClick: handleClickHeader,
          }}
          selectedTr={selected}
          rowDataList={list || []}
          lists={list ? list.map((v) => (
            [
              v.internal_id,
              v.file_name,
              // <FileName id={1} name="原価管理表" img="xls" />,
              v.format,
              `${MathHelper.rounding(
                MathHelper.div(Number(v.size), 1024), 2, 'floor',
              ).toLocaleString()} KB`,
              DateFormatter.date2str(v.upload_date),
              v.updater,
              v.comment,
              <LeftIconButton
                label="ダウンロード"
                fontAwesomeClass="fas fa-file-download"
                className="btn_search for_detail"
                size="sm"
                color="secondary"
                onClick={(e) => {
                  e.stopPropagation();
                  downloadFile(v);
                }}
              />,
              <LeftIconButton
                label="削除"
                fontAwesomeClass="fas fa-trash-alt"
                className="btn_search for_detail"
                size="sm"
                color="dark"
                onClick={(e) => {
                  e.stopPropagation();
                  deleteFile(v);
                }}
              />,
            ]
          )) : null}
          option={{
            stringWidth: [
              { index: 0, width: 50 }, // No.
              // { index: 1, width: 100 }, // ファイル名
              // { index: 2, width: 50 }, // 形式
              // { index: 3, width: 50 }, //  サイズ（KB）
              // { index: 4, width: 150 }, // アップロード日時
              // { index: 5, width: 150 }, // 更新者
              // { index: 6, width: 50 }, // コメント
              { index: 7, width: 100 }, // ダウンロード
              { index: 8, width: 100 }, // 削除
            ],
            tdAlign: [
              { index: 0, align: 'center' },
              { index: 1, align: 'left' },
              { index: 2, align: 'center' },
              { index: 3, align: 'right' },
              { index: 4, align: 'center' },
              { index: 5, align: 'left' },
              { index: 6, align: 'left' },
              { index: 7, align: 'center' },
              { index: 8, align: 'center' },
            ],
          }}
        />
      </div>
    </div>
  );
};
