import { cloneDeep } from 'lodash';
import {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { EstimateMeisaiModel } from '../../../../../../model/estimate/estimate-meisai.model';
import { DialogActions } from '../../../../../../redux/dialog/dialog.action';
import { Button } from '../../../../../ui/button/button';
import { ChangePrintNameDialogPC } from '../../change-print-name-dialog/change-print-name-dialog.pc';
import { EstimateMeisaiTreeModel } from '../../../../../../model/estimate/estimate-meisai-tree.model.pc';
import './estimate-tree.scss';
import { SelectTreeData } from '../estimate-explore.pc';

const FolderIcon = (props: { isOpen?: boolean; callback: () => void;}) => {
  const { isOpen, callback } = props;
  return (
    <span
      onClick={(e) => {
        e.stopPropagation();
        callback();
      }}
    >
      <i className={`far ${isOpen ? 'fa-folder-open' : 'fa-folder'}`} />
    </span>
  );
};

export type Props = {
  meisaiModel: EstimateMeisaiModel | null;
  masterAdd?: boolean;
  allowEdit?: 0 | 1 | 2;
  callback: (v:SelectTreeData) => void;
  callbackModel: (v:EstimateMeisaiModel) => void;
  callbackMove?: (type:'up' | 'down', v:SelectFolder) => void;
}

export type SelectFolder = {
  daibunrui?: string;
  chubunrui?: string;
  isAll?: boolean;
}

export const EstimateTree = (props:Props) => {
  const {
    meisaiModel,
    allowEdit,
    masterAdd,
    callback,
    callbackModel,
    callbackMove,
  } = props;

  const dispatch = useDispatch();

  const [tree, setTree] = useState<EstimateMeisaiTreeModel | null>(null);
  const [selectFolder, setSelectFolder] = useState<SelectFolder | null>({ isAll: true });

  const disabledChangePrint = useMemo(() => {
    if (!selectFolder) {
      return true;
    }
    if (selectFolder.isAll) {
      return true;
    }
    return false;
  }, [selectFolder]);

  const handleClickFolder = useCallback(
    (param: { daibunrui?: string, chubunrui?: string; isAll?: boolean;}) => {
      setSelectFolder(cloneDeep(param));
      callback({
        daibunrui_id: param.daibunrui,
        tyubunrui_id: param.chubunrui,
      });
    },
    [callback],
  );

  const handleClickFolderIcon = useCallback(
    (param: {
      isAll?: boolean;
      daibunrui?: string;
      chubunrui?: string;
    }) => {
      tree?.changeOpen(param);
      setTree(cloneDeep(tree));
      handleClickFolder(param);
    },
    [tree, handleClickFolder],
  );

  const handleClickMoveFolder = useCallback(
    (isUp: boolean) => {
      if (selectFolder?.daibunrui === undefined || !meisaiModel) return;
      const isChnaged = meisaiModel?.moveTree(isUp
        ? 'up' : 'down', selectFolder?.daibunrui, selectFolder?.chubunrui);
      if (isChnaged) {
        callbackModel(cloneDeep(meisaiModel));
        setTree(cloneDeep(tree));
      }
      if (selectFolder && callbackMove) { callbackMove(isUp ? 'up' : 'down', selectFolder); }
    },
    [tree, selectFolder, meisaiModel, callbackMove],
  );

  const handleClickChangePrintName = useCallback(() => {
    if (!selectFolder && !meisaiModel) return;
    let name = '';
    let printName = '';

    const isTyubunrui = selectFolder?.daibunrui && selectFolder.chubunrui;

    // 大分類押した場合
    const list = (meisaiModel?.list || []).filter(({ $ }) => (!isTyubunrui
      ? $.daibunrui_id === selectFolder?.daibunrui
      : ($.daibunrui_id === selectFolder?.daibunrui
          && $.tyubunrui_id === selectFolder.chubunrui)));
    if (list?.length) {
      name = isTyubunrui ? list[0].$.tyubunrui_name : list[0].$.daibunrui_name;
      printName = isTyubunrui ? list[0].$.tyubunrui_name_out : list[0].$.daibunrui_name_out;
    }
    dispatch(DialogActions.push({
      title: '見積印刷名称設定',
      element: <ChangePrintNameDialogPC
        name={name}
        printName={printName}
        callback={(n, pn) => {
          dispatch(DialogActions.pop());
          if (!list.length) return;
          for (let i = 0; i < (list?.length || 0); i += 1) {
            if (isTyubunrui) {
              list[i].$.tyubunrui_name_out = pn;
            } else {
              list[i].$.daibunrui_name_out = pn;
            }
          }
          if (!selectFolder?.daibunrui) return;
          tree?.changeName({
            daibunrui: selectFolder.daibunrui,
            chuBunrui: selectFolder.chubunrui,
          }, pn);
          setTree(cloneDeep(tree));
          if (meisaiModel) { callbackModel(meisaiModel); }
        }}
      />,
    }));
  }, [selectFolder, meisaiModel, tree, meisaiModel]);

  useEffect(() => {
    if (meisaiModel) { setTree(new EstimateMeisaiTreeModel(meisaiModel)); }
  }, [meisaiModel]);

  return (
    <section className="tree_box">
      <div
        className="all"
      >
        {/* ALL */}
        {tree?.data.daibunrui.length
          ? (
            <div
              className={`tree_item ${selectFolder?.isAll ? 'is_select' : ''}`}
              onClick={() => handleClickFolderIcon({
                isAll: true,
                daibunrui: undefined,
                chubunrui: undefined,
              })}
            >
              <FolderIcon
                isOpen={tree?.data.isOpen}
                callback={() => {}}
              />
              {tree?.data.title}&nbsp;{ !masterAdd && <>[{tree?.data.percent}%]</>}
            </div>
          ) : <></>}

        {/* 大分類 */}
        {tree?.data.isOpen
        && (
        <div className="daibunrui">
          {tree?.data.daibunrui.map((v) => (
            <div key={v.id + v.title}>
              <div
                onClick={() => handleClickFolderIcon({
                  isAll: false,
                  daibunrui: v.id,
                  chubunrui: undefined,
                })}
                className={`tree_item ${selectFolder?.daibunrui === v.id && selectFolder.chubunrui === undefined ? 'is_select' : ''}`}
              >
                <FolderIcon
                  isOpen={v.isOpen}
                  callback={() => {}}
                />
                {v.title}&nbsp;{ !masterAdd && <>[{v.percent}%]</>}
              </div>
              {v.isOpen
              && (
              <div className="chubunrui_wrap">
                {v.chubunrui.map((v2) => (
                  <div
                    key={v2.title + v2.id}
                    className={`chubunrui tree_item ${selectFolder?.daibunrui === v.id && selectFolder.chubunrui === v2.id ? 'is_select' : ''}`}
                    onClick={() => handleClickFolder({
                      isAll: false,
                      daibunrui: v.id,
                      chubunrui: v2.id,
                    })}
                  >
                    <i className="far fa-folder" />{v2.title}&nbsp;{ !masterAdd && <>[{v2.percent}%]</>}
                  </div>
                ))}
              </div>
              )}
            </div>
          ))}
        </div>
        )}
      </div>
      {!masterAdd
      && (
      <div className="btn_box">
        { allowEdit === 1 && (
          <>
            <Button size="sm" color="secondary" onClick={() => handleClickMoveFolder(true)} disabled={!selectFolder?.daibunrui && !selectFolder?.chubunrui}>移動 ▲</Button>
            <Button size="sm" color="secondary" onClick={() => handleClickMoveFolder(false)} disabled={!selectFolder?.daibunrui && !selectFolder?.chubunrui}>移動 ▼</Button>
            <Button size="sm" color="secondary" disabled={disabledChangePrint} onClick={handleClickChangePrintName}>印刷名称変更</Button>
          </>
        ) }
      </div>
      )}
    </section>
  );
};
