import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { Input } from 'semantic-ui-react';
import { MapActions } from '../../../../redux/map/map.action';
import { DialogActions } from '../../../../redux/dialog/dialog.action';
import { Button } from '../../button/button';
import { noPinch } from '../../../../utilities/no-pinch';
import { useAppSelector } from '../../../../hooks/use-redux';
import { RouteDialog } from '../../../dialogs/route-dialog/route-dialog';
import { LeftIconButton } from '../../button/left-icon-button/left-icon-button';

type Props = {
  isRoute?: boolean;
  type?: 'customer' | 'project';
}

export const SearchAddressDialog = (props: Props) => {
  const { isRoute, type } = props;
  const dispatch = useDispatch();
  const routeAuth = useAppSelector((v) => v.user.company_authority3);
  const routeInfo = useAppSelector((v) => v.map.routeInfo);

  const [searchValue, setSearchValue] = useState('');
  const [adjustHight, setAdjustHight] = useState(0);
  const searchRef = useRef<any | null>(null);
  const ele = useRef<HTMLDivElement>(null);

  const handleClickSearch = useCallback(
    () => {
      dispatch(MapActions.setAreaBtnDisabled(false));
      dispatch(MapActions.setGpsStatus('out'));
      dispatch(MapActions.api.geocoder({
        param: { param: { address: searchValue } },
        callback: () => {
          dispatch(DialogActions.pop());
          dispatch(MapActions.setZoomLevel(null));
        },
      }));
    },
    [searchValue],
  );

  const handleClickRoute = useCallback(
    () => {
      if (!type) return;
      dispatch(DialogActions.push({
        title: 'ルート設定',
        className: 'max_height_dialog for_route',
        isCheck: true,
        checkLabel: ['ルート検索を終了します。'],
        adjustHight,
        element: <RouteDialog
          type={type}
          destination={searchValue}
          callback={() => {
            dispatch(DialogActions.pop());
          }}
        />,
      }));
    },
    [searchValue, adjustHight],
  );

  const handleClickRouteEnd = useCallback(
    () => {
      dispatch(DialogActions.pushMessage({
        isCancel: true,
        title: '確認',
        message: [
          'ルート検索を終了します。',
        ],
        callback: () => {
          dispatch(MapActions.setRouteInfo(null));
          dispatch(DialogActions.pop());
        },
      }));
    }, [],
  );

  useEffect(() => searchRef?.current.focus(), [searchRef]);

  useEffect(() => {
    const hight = document.body.clientHeight * 0.8;
    setAdjustHight(hight);
  }, []);

  useEffect(() => {
    const pinchCallback = noPinch(ele.current);
    return pinchCallback;
  }, [ele]);

  return (
    <div className="googlg_map_search" style={{ textAlign: 'center', padding: '10px 20px' }} ref={ele}>
      <div style={{ marginTop: '40px' }}>
        <div style={{ marginBottom: '10px' }}>場所または住所を入力してください</div>
        <div onKeyPress={(e) => { if (e.key === 'Enter') handleClickSearch(); }}>
          <Input onChange={(e) => setSearchValue(e.target.value)} ref={searchRef} />
        </div>
      </div>

      <div className="base_dialog_content_inner_footer" style={{ marginTop: '30px' }}>
        <LeftIconButton
          label="検索"
          fontAwesomeClass="fas fa-search"
          size="md"
          color="primary"
          onClick={handleClickSearch}
        />
        {
        (!isRoute && routeAuth)
        && (
          <LeftIconButton
            label="ﾙｰﾄ検索"
            fontAwesomeClass="fas fa-route"
            size="sm"
            color="secondary"
            onClick={handleClickRoute}
          />
        )
      }
        {(!isRoute && routeAuth && routeInfo
        && (
          <LeftIconButton
            label="ﾙｰﾄ検索終了"
            fontAwesomeClass="fas fa-times-circle"
            size="sm"
            color="dark"
            onClick={handleClickRouteEnd}
          />
        )
        )}
      </div>
    </div>
  );
};
