import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CustomerCollection } from '../../../../../../collection/customer/customer.collection';
import { Select } from '../../../../../ui/select/select';
import { useDidMount } from '../../../../../../hooks/life-cycle';
import { EditPC } from '../../../../../dialogs/edit/edit.pc';
import { Required } from '../../../../../ui/required/required';
import { ValidationLengthUnder10, ValidationLengthUnder255 } from '../../../../../../model/validation/validation-length-under';
import { ValidationNumberLengthUnder3 } from '../../../../../../model/validation/validation-number-length-under';
import { Input } from '../../../../../ui/input/input';
import { ListPet } from '../../../../../../model/customer/pet-list.model';
import { ItemWrap } from '../../../../../ui/item/item-wrap';
import { PetValidation } from '../../../../../../model/validation/customer/pet.validation';
import { DialogActions } from '../../../../../../redux/dialog/dialog.action';
import { pulldown } from '../../../../../../utilities/pulldown';
import { Message } from '../../../../../../collection/message.collection';

type Props = {
  data?: ListPet;
  isEdit?: boolean;
  id: number;
  callbackPost: (v:ListPet) => void;
}

export const PetEditDialog = (props: Props) => {
  const {
    data,
    isEdit,
    id,
    callbackPost,
  } = props;

  const dispatch = useDispatch();

  /* State */
  const [touch, setTouch] = useState(false);
  const [name, setName] = useState('');
  const [type, setType] = useState('');
  const [sex, setSex] = useState(1);
  const [age, setAge] = useState('');
  const [pet_id, setPet_id] = useState(NaN);

  /* Callback */
  const handleClickPost = useCallback(() => {
    if (PetValidation({
      name,
      type,
      age: age === '' ? NaN : Number(age),
    })) {
      dispatch(DialogActions.pushMessage({
        title: 'ペット情報登録',
        message: Message.postError,
        callback: () => setTouch(true),
        callbackClose: () => setTouch(true),
      }));
      return;
    }
    callbackPost({
      id,
      customer_id: data?.customer_id ?? NaN,
      name,
      type,
      sex,
      age: age === '' ? NaN : Number(age),
      index: isEdit ? data?.index : undefined,
    });
  },
  [name, type, sex, age, id, pet_id, data]);

  useDidMount(() => {
    if (isEdit && data) {
      setName(data.name);
      setType(data?.type || '');
      setSex(data.sex);
      setAge(String(data.age) || '');
      setPet_id(data?.id ?? NaN);
    }
  });

  return (
    <EditPC mode="dialog" callback={handleClickPost}>
      <div className="editPC_body_inner PetEditDialog">
        <ItemWrap title={<>名前<Required /></>}>
          <Input
            value={name}
            onChange={(e) => setName(e.target.value)}
            validationList={ValidationLengthUnder255}
            require
            touch={touch}
            errorPosBottom
          />
        </ItemWrap>
        <ItemWrap title="種別">
          <Input
            value={type}
            onChange={(e) => setType(e.target.value)}
            validationList={ValidationLengthUnder10}
          />
        </ItemWrap>
        <ItemWrap title="性別">
          <Select
            value={sex}
            className="full_width"
            options={pulldown(CustomerCollection.petSexualOption)}
            onChange={(v) => setSex(Number(v))}
          />
        </ItemWrap>
        <ItemWrap title="才">
          <Input
            type="number"
            label="才"
            value={age}
            onChange={(e) => {
              setAge(e.target.value);
            }}
            validationList={ValidationNumberLengthUnder3}
            maxLength={3}
          />
        </ItemWrap>
      </div>
    </EditPC>
  );
};
