import aac from '../../../asset/images/thum/aac.svg';
import aif from '../../../asset/images/thum/aif.svg';
import avi from '../../../asset/images/thum/avi.svg';
import doc from '../../../asset/images/thum/doc.svg';
import docx from '../../../asset/images/thum/docx.svg';
import flac from '../../../asset/images/thum/flac.svg';
import flv from '../../../asset/images/thum/flv.svg';
import gif from '../../../asset/images/thum/gif.svg';
import jpeg from '../../../asset/images/thum/jpeg.svg';
import jpg from '../../../asset/images/thum/jpg.svg';
import m4a from '../../../asset/images/thum/m4a.svg';
import mkv from '../../../asset/images/thum/mkv.svg';
import mov from '../../../asset/images/thum/mov.svg';
import mp3 from '../../../asset/images/thum/mp3.svg';
import mp4 from '../../../asset/images/thum/mp4.svg';
import mpg from '../../../asset/images/thum/mpg.svg';
import pdf from '../../../asset/images/thum/pdf.svg';
import png from '../../../asset/images/thum/png.svg';
import ppt from '../../../asset/images/thum/ppt.svg';
import pptx from '../../../asset/images/thum/pptx.svg';
import svg from '../../../asset/images/thum/svg.svg';
import wave from '../../../asset/images/thum/wave.svg';
import webm from '../../../asset/images/thum/webm.svg';
import wmv from '../../../asset/images/thum/wmv.svg';
import xls from '../../../asset/images/thum/xls.svg';
import xlsx from '../../../asset/images/thum/xlsx.svg';
import zip from '../../../asset/images/thum/zip.svg';

export const Thumbnails = [
  { name: 'aac', thumb: aac },
  { name: 'aif', thumb: aif },
  { name: 'avi', thumb: avi },
  { name: 'doc', thumb: doc },
  { name: 'docx', thumb: docx },
  { name: 'flac', thumb: flac },
  { name: 'flv', thumb: flv },
  { name: 'gif', thumb: gif },
  { name: 'jpeg', thumb: jpeg },
  { name: 'jpg', thumb: jpg },
  { name: 'm4a', thumb: m4a },
  { name: 'mkv', thumb: mkv },
  { name: 'mov', thumb: mov },
  { name: 'mp3', thumb: mp3 },
  { name: 'mp4', thumb: mp4 },
  { name: 'mpg', thumb: mpg },
  { name: 'pdf', thumb: pdf },
  { name: 'png', thumb: png },
  { name: 'ppt', thumb: ppt },
  { name: 'pptx', thumb: pptx },
  { name: 'svg', thumb: svg },
  { name: 'wave', thumb: wave },
  { name: 'webm', thumb: webm },
  { name: 'wmv', thumb: wmv },
  { name: 'xls', thumb: xls },
  { name: 'xlsx', thumb: xlsx },
  { name: 'zip', thumb: zip },
];
