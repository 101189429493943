import { useCallback, useState } from 'react';
import { EditPC } from '../../../../dialogs/edit/edit.pc';
import { Input } from '../../../../ui/input/input';

type Props = {
  name: string;
  printName: string;
  callback: (name: string, printName:string) => void;
}

export const ChangePrintNameDialogPC = (props: Props) => {
  const {
    name, printName, callback,
  } = props;

  /* State */
  const [afterPrintName, setAfterPrintName] = useState(printName);

  /* Callback */
  const handleClickSave = useCallback(
    () => {
      callback(name, afterPrintName);
    },
    [afterPrintName],
  );

  return (
    <EditPC
      mode="dialog"
      callback={handleClickSave}
    >
      <div className="ChangePrintNameDialogPC">
        <div className="category_wrap">
          <div className="item_wrap">
            <div className="item_box">
              <div className="item_head">表示名</div>
              <Input
                label=""
                value={name}
                disabled
              />
            </div>
          </div>
          <div className="item_wrap">
            <div className="item_box">
              <div className="item_head">印刷名</div>
              <Input
                onEnterKeyPress={handleClickSave}
                label=""
                value={afterPrintName}
                onChange={(e) => setAfterPrintName(e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>
    </EditPC>
  );
};
