import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';
import { useWillUnMount } from '../../../../../../hooks/life-cycle';
import { useAppSelector } from '../../../../../../hooks/use-redux';
import { CustomerModel } from '../../../../../../model/customer/customer-model';
import { CustomerActions } from '../../../../../../redux/customer/customer.action';
import { CustomerListType, CustomerSortState } from '../../../../../../type/customer/customer.type';
import { SearchBoxCustomerSP } from '../../../../pages/customer/serch-box/customer-search-box.sp';
import { DialogActions } from '../../../../../../redux/dialog/dialog.action';
import { CustomerListSP } from '../../../body/list/customer-list/customer-list.sp';
import { TableSort } from '../../../../../ui/table/table-sort/table-sort';
import { CustomerCollection } from '../../../../../../collection/customer/customer.collection';
import { SystemActions } from '../../../../../../redux/system/system.action';

type Props = {
  callback: (v: CustomerListType) => void;
  freeAuth?: boolean;
}

export const CustomerSearchSP = (props: Props) => {
  const { callback, freeAuth } = props;

  const dispatch = useDispatch();
  const user = useAppSelector((v) => (v.user));
  const [list, setList] = useState<CustomerListType[] | null>(null);
  const [hitCount, setHitCount] = useState(0);
  const [isSearch, setIsSearch] = useState(false);
  const [sort, setSort] = useState(CustomerCollection._customerSortInitialState(user));

  const getList = useCallback((v?: CustomerSortState) => {
    const sortData = v || sort;
    dispatch(SystemActions.isLoading(true));

    setList(null);
    dispatch(CustomerActions.api.customer.getList({
      param: {
        ...CustomerModel.listParam({ sortState: v || sort }),
        store_id: (user.authority1 || freeAuth) ? sortData.store_id : user.store_id,
        employee_id: (user.authority1 || freeAuth) ? sortData.employee_id : user.id,
      },
      onSuccess: (data, hit) => {
        dispatch(SystemActions.isLoading(false));
        if (!data) return;
        if (!data.data.length) {
          dispatch(DialogActions.pushMessage({
            title: '',
            message: ['該当する結果が見つかりません'],
          }));
          return;
        }
        setIsSearch(true);
        setHitCount(data.hitCount || 0);
        setList(data.data);
      },
      onError: () => dispatch(SystemActions.isLoading(false)),
    }));
  }, [sort]);

  const handleCardClick = useCallback((v: CustomerListType) => {
    callback(v);
  }, []);

  useWillUnMount(() => {
    dispatch(CustomerActions.setInitSort(user));
  });

  useEffect(() => {
    if (!isSearch) return;
    getList(sort);
  }, [
    sort.offset,
  ]);

  return (
    !isSearch
      ? (
        <SearchBoxCustomerSP
          noAuthority={freeAuth ? false : !user.authority1}
          isSearch
          getList={(v) => {
            setSort(cloneDeep(v));
            getList(v);
          }}
        />
      )
      : (
        <>
          <TableSort
            isSp
            page={sort.offset}
            limit={100}
            hitCount={hitCount || 0}
            callback={(offset, limit) => {
              dispatch(CustomerActions.setSort({ offset, limit }));
              setSort({ ...cloneDeep(sort), offset, limit });
            }}
            className="mt_5 mb_0"
          />
          <hr className="hr_style hr_search_result" />
          <CustomerListSP
            data={list}
            handleCardClick={handleCardClick}
            callbackWillUnmount={() => {
              setIsSearch(false);
            }}
          />
        </>
      )
  );
};
