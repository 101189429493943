/* eslint-disable no-nested-ternary */
import { push } from 'connected-react-router';
import { useCallback, useMemo, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useDispatch, useSelector } from 'react-redux';
import { useDidMount } from '../../../../../../hooks/life-cycle';
import { DialogActions } from '../../../../../../redux/dialog/dialog.action';
import { State } from '../../../../../../redux/root.reducer';
import { RoutingPath } from '../../../../../../routes/routing-pass';
import { FileListType } from '../../../../../../type/file/file.type';
import { MathHelper } from '../../../../../../utilities/math-helper';
import { FileSize as FileSizeSP } from '../../../../../pc/pages/project-detail/file-table/file-size';
import { CardList } from '../../../../../ui/card/card-list/card-list';
import { FileCard } from '../../../../../ui/card/file/file-card';

type Props = {
  stopLoad?: boolean;
  fileSize: { capacity: number, total: number; };
  data?: FileListType[] | null;
  handleCardClick?: (file: FileListType) => void;
  onClickCardInOrder?: (file: FileListType) => void;
  isOrder?: boolean;
}

export const FileListSP = (props: Props) => {
  const {
    data, handleCardClick, fileSize, stopLoad, isOrder, onClickCardInOrder,
  } = props;

  const { ref, inView } = useInView({
    // オプション
    rootMargin: '-50px', // ref要素が現れてから50px過ぎたら
    triggerOnce: true, // 最初の一度だけ実行
  });

  const totalSize = useMemo(() => {
    const kb = MathHelper.div(fileSize.total, 1024);
    if (kb > 1024) {
      const mb = MathHelper.div(kb, 1024);

      return `${MathHelper.rounding(mb, 2, 'ceil')}MB`;
    }
    return `${MathHelper.rounding(kb, 2, 'ceil')}KB`;
  }, [fileSize]);

  const percent = useMemo(() => {
    const per = (fileSize.total <= 0) ? 0 : MathHelper.rounding(
      MathHelper.times(
        MathHelper.div(fileSize.total, fileSize.capacity),
        100,
      ),
      0,
      'ceil',
    );
    return (per > 100) ? 100 : per;
  }, [fileSize]);

  /* Hooks */
  const fileListData = useSelector((state: State) => state.file.list);
  const dispatch = useDispatch();
  const [isInCustomerDetail, setIsInCustomerDetail] = useState(false);

  const handleClickCard = useCallback((id: number) => {
    const findData = data?.find((v) => v.id === id);
    if (onClickCardInOrder && findData) {
      onClickCardInOrder?.(findData);
      return;
    }
    if (handleCardClick) {
      if (findData) handleCardClick(findData);
      dispatch(DialogActions.pop());
      dispatch(DialogActions.pop());
      return;
    }
    dispatch(push(`${RoutingPath.fileDetail}/${id}/file`));
  }, [data]);

  useDidMount(() => {
    const arr = window.location.href.split('customer/detail');
    setIsInCustomerDetail(arr.length >= 2);
  });

  return (
    <>
      <div>
        <FileSizeSP
          percent={percent}
          totalSize={totalSize}
          maxSize={fileSize.capacity}
        />
      </div>
      <CardList isLoading={inView} list={data || data === null ? data : fileListData}>
        {data || data === null ? data?.map((v, i) => (
          <div
            key={`card${i}`}
            className="list_base_card_wrap"
            ref={data.length - 1 ? ref : undefined}
          >
            <FileCard
              stopLoad={stopLoad}
              fileData={v}
              onClick={handleClickCard}
              isInCustomerDetail={isInCustomerDetail}
            />
          </div>
        )) : fileListData ? fileListData.map((v, i) => (
          <div
            key={`card${i}`}
            className="list_base_card_wrap"
            ref={fileListData.length - 1 ? ref : undefined}
          >
            <FileCard
              fileData={v}
              onClick={handleClickCard}
              stopLoad={stopLoad}
              isInCustomerDetail={isInCustomerDetail}
            />
          </div>
        )) : null}
      </CardList>
    </>
  );
};
