import { useMemo } from 'react';
import { MathHelper } from '../../../../../utilities/math-helper';

type Props = {
  percent: number;
  totalSize: string;
  maxSize: number;
  isNoChangeMax?: boolean;
}

export const FileSize = (props: Props) => {
  const {
    percent, totalSize, maxSize, isNoChangeMax,
  } = props;

  const isAlert = useMemo(() => percent > 100, [percent]);

  const viewPercent = useMemo(() => {
    if (percent === Infinity) {
      return '';
    }
    return percent;
  }, [percent]);
  const max = useMemo(() => (isNoChangeMax ? maxSize
    : MathHelper.rounding(
      MathHelper.div(maxSize, 1024, 1024, 1024),
      2,
      'ceil',
    )),
  [maxSize]);

  return (
    <div className="file_info mr_20">
      <div className="file_capacity mr_10">
        <div className="inner" style={{ width: `${percent}%` }} />
        <div className="text_box" style={{ color: isAlert ? '#ff0000' : '' }}>
          {`使用容量：${viewPercent}%`}
        </div>
      </div>
      <div className="total_box">
        サイズ合計:<strong>{totalSize}</strong> (最大:<strong>{max}GB</strong>まで)
      </div>
    </div>
  );
};
