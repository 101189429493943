// import {ApiLogin} from '../../api/auth/api-login';
import actionCreatorFactory from 'typescript-fsa';
import { FileSortState, FileListType } from '../../type/file/file.type';
import { SupportHistorySortState, SupportHistoryListType } from '../../type/support-history/support-history.type';
import { MaintenanceSortState, MaintenanceList } from '../../type/maintenance/maintenance.type';
import { EstimateListType, EstimateSortState } from '../../type/estimate/estimate.type';
import { BillListType, BillSortState } from '../../type/bill/bill.type';

const ActionCreator = actionCreatorFactory('project/detail');

export const ProjectDetailActions = {
  setEstimateSort: ActionCreator<Partial<EstimateSortState> | null>('set/estimate/sort'),
  setBillSort: ActionCreator<BillSortState | null>('set/bill/sort'),
  setFileSort: ActionCreator<Partial<FileSortState> | null>('set/file/sort'),
  setSupportSort: ActionCreator<Partial<SupportHistorySortState> | null>('set/support/sort'),
  setMaintenanceSort: ActionCreator<MaintenanceSortState | null>('set/maintenance/sort'),
  setEstimateList: ActionCreator<EstimateListType[] | null>('set/estimate/list'),
  setConcatEstimateList: ActionCreator<EstimateListType[] | null>('set/concat/estimate/list'),
  setFileList: ActionCreator<FileListType[] | null>('set/file/list'),
  setConcatFileList: ActionCreator<FileListType[] | null>('set/concat/file/list'),
  setMaintenanceList: ActionCreator<MaintenanceList[] | null>('set/maintenance/list'),
  setConcatMaintenanceList: ActionCreator<MaintenanceList[] | null>('set/concat/maintenance/list'),
  setSupportList: ActionCreator<SupportHistoryListType[] | null>('set/support/list'),
  setConcatSupportList: ActionCreator<SupportHistoryListType[] | null>('set/concat/support/list'),
  setBillList: ActionCreator<BillListType[] | null>('set/bill/list'),
  setConcatBillList: ActionCreator<BillListType[] | null>('set/concat/bill/list'),
  setStopLoadFile: ActionCreator<boolean>('file/stop-load'),
  resetState: ActionCreator('reset/state'),
};
