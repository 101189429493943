import {
  useEffect, useMemo, useRef, useState,
} from 'react';
import Select, { InputProps, components } from 'react-select';
import { TagType } from '../../../type/tag/tag.type';

type SelectType = {
  value: number;
  label: string;
}

type Props = {
  value: number;
  options: TagType[];
  defaultValue?: string;
  onChange: (id: number, label: string) => void;
  disabled?: boolean;
  className?: string;
}

const Input = (props: InputProps<SelectType, false>) => (
  <components.Input {...props} autoComplete="new-password" />
);

const changeType = (data: TagType): SelectType => ({
  value: data.id,
  label: data.label,
});

export const InputSelect = (props: Props) => {
  const {
    options, defaultValue, onChange, value, disabled, className,
  } = props;

  const defaultV: SelectType = {
    value: NaN,
    label: defaultValue || '',
  };

  const _options = useMemo(() => (defaultValue ? [
    defaultV,
    ...options.map((v) => changeType(v)),
  ]
    : [...options.map((v) => changeType(v))]),
  [defaultV, options]);

  return (
    <Select
      value={_options.find((v) => v.value === value)}
      className={className}
      defaultValue={defaultV}
      noOptionsMessage={() => ('候補がありません')}
      onChange={(v) => {
        if (!v) return;
        onChange(v.value, v.label);
      }}
      isDisabled={disabled}
      options={_options}
      components={{ Input }}
    />
  );
};
