import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useWillUnMount } from '../../../../../../hooks/life-cycle';
import { MasterActions } from '../../../../../../redux/master/master.action';
import { EstimateMeisaiModel } from '../../../../../../model/estimate/estimate-meisai.model';
import { MeisaiListXML } from '../../../../../../model/estimate/estimate-meisai.type';
import { XmlParser } from '../../../../../../parser/xml-parser';
import { SearchMeisaiSP } from '../../edit/search/search-meisai.sp';
import { ApiMasterMeisaiGetListParam } from '../../../../../../redux/master/api/master-meisai/api-master-meisai.type';
import { MasterMeisai } from '../../../../../../type/master/master-meisai.type';
import { DialogActions } from '../../../../../../redux/dialog/dialog.action';
import { AddMasterResultListSP } from './list/result-list.sp';
import { AddMasterResultConfirmSP } from './confirm/result-confirm.sp';

type Props = {
  callback: (v: MeisaiListXML[]) => void;
}

export const AddMasterMeisaiSP = (props:Props) => {
  const { callback } = props;

  /* Hook */
  const dispatch = useDispatch();

  /* State */
  const [model, setModel] = useState<EstimateMeisaiModel | null>(null);
  const [showResult, setShowResult] = useState(false);

  /* Callback */
  /* 明細モデルにセットする */
  const setMeisaiModel = useCallback((v: MasterMeisai[]) => {
    const parser = new XmlParser();
    const build = parser.build<{ meisai: { mei: MeisaiListXML; }[]; }>(
      EstimateMeisaiModel.addMasterFormat(v),
    );
    parser.parse(build).then((res) => {
      setModel(new EstimateMeisaiModel(res));
    });
  }, []);

  /** 検索 */
  const getList = useCallback((param: ApiMasterMeisaiGetListParam) => {
    dispatch(MasterActions.api.meisai.getCallbackList({
      param,
      callback: (v) => {
        if (!v.length) {
          dispatch(DialogActions.pushMessage({
            title: '',
            message: ['該当する結果が見つかりません'],
          }));
          return;
        }
        setMeisaiModel(v);
      },
    }));
  }, []);

  /* 明細登録 */
  const addMeisai = useCallback((v:MeisaiListXML[]) => {
    callback(v);
    setShowResult(true);
  }, [callback]);

  /* 明細登録キャンセル */
  const cancelAddMeisai = useCallback(() => {
    setModel(null);
    dispatch(MasterActions.setMiddleCategoryList([]));
  }, []);

  /* 明細登録キャンセル */
  const reSearch = useCallback(() => {
    cancelAddMeisai();
    setShowResult(false);
  }, []);

  useWillUnMount(() => dispatch(MasterActions.setMiddleCategoryList([])));

  return (
    <>
      {/* 明細検索 */}
      {!model && <SearchMeisaiSP callback={getList} />}

      {/* 検索結果 */}
      {model && !showResult && (
      <AddMasterResultListSP
        model={model}
        callback={addMeisai}
        callbackCancel={cancelAddMeisai}
      />
      )}

      {/* リザルト画面 */}
      {model && showResult && (
        <AddMasterResultConfirmSP onReSearch={reSearch} />
      )}
    </>
  );
};
