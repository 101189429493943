import {
  useCallback, useMemo, useState, memo, useEffect,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as lodash from 'lodash';
import { cloneDeep } from 'lodash';
import { Placeholder } from 'semantic-ui-react';
import { Skeleton } from '@material-ui/lab';
import { CircularProgress } from '@material-ui/core';
import { useInView } from 'react-intersection-observer';
import { State } from '../../../../../../../redux/root.reducer';
import { CustomerListType } from '../../../../../../../type/customer/customer.type';
import { Button } from '../../../../../../ui/button/button';
import { LeftIconButton } from '../../../../../../ui/button/left-icon-button/left-icon-button';
import { CustomerCard } from '../../../../../../ui/card/customer-card/customer-card';
import { InputField } from '../../../../../../ui/input-field/input-field';
import { MapBase } from '../../../../../../ui/map/map-base';
import './customer-map-list.scss';
import { MapActions } from '../../../../../../../redux/map/map.action';
import { DialogActions } from '../../../../../../../redux/dialog/dialog.action';
import { CustomerEdit } from '../../../../../pages/customer/edit/customer-edit';
import { CustomerActions } from '../../../../../../../redux/customer/customer.action';
import { useDidMount, useWillUnMount } from '../../../../../../../hooks/life-cycle';
import { RouteDialog } from '../../../../../../dialogs/route-dialog/route-dialog';
import { CustomerModel } from '../../../../../../../model/customer/customer-model';
import { useAppSelector } from '../../../../../../../hooks/use-redux';
import { CardList } from '../../../../../../ui/card/card-list/card-list';
import { TableSort } from '../../../../../../ui/table/table-sort/table-sort';
import { MapAreaPosition } from '../../../../../../../type/map/map.type';
import { MapCollection } from '../../../../../../../collection/map/map.collection';

type Props = {
  type: 0 | 1 | 2;
  list: CustomerListType[] | null;
  page: number;
  isSearch: boolean;
  hitCount: number;
  onClickCard: () => void;
  callback: (type: 0 | 1 | 2) => void;
  callbackPagination: (offset: number) => void;
  callbackZoomEnd: (mapPos: MapAreaPosition, isGpsLoad: boolean) => void;
  callbackCenterChanged: (mapPos: MapAreaPosition) => void;
  onGetGps?: (onGps: boolean) => void;
}

export const CustomerMapListPC = memo((props: Props) => {
  const {
    type, callback, isSearch, list, callbackPagination, page, hitCount,
    callbackZoomEnd, callbackCenterChanged, onClickCard, onGetGps,
  } = props;

  const { ref, inView } = useInView({
    // オプション
    rootMargin: '-50px', // ref要素が現れてから50px過ぎたら
    triggerOnce: true, // 最初の一度だけ実行
  });

  /* Hooks */
  const routeAuth = useAppSelector((v) => v.user.company_authority3);
  const humanPos = useSelector((state: State) => state.map.humanPos);
  const sortState = useSelector((state: State) => state.customer.sort);
  const routeInfo = useSelector((state: State) => state.map.routeInfo);
  const zoom = useAppSelector((v) => v.map.zoomLevel);
  const dispatch = useDispatch();

  /* State */
  const [customer, setCustomer] = useState<CustomerListType | null>(null);
  const [customerIndex, setCustomerIndex] = useState(NaN);

  const [searchValue, setSearchValue] = useState('');
  const [searchValue2, setSearchValue2] = useState('');
  const [active, setActive] = useState(NaN);

  /* List */
  const customerList = useMemo(() => list, [type, list]);

  // const hitCount = useMemo(() => {
  //   if (!customerList) return 0;
  //   return customerList.length;
  // }, [customerList]);

  /* Callback */
  const handleClickCard = useCallback((v: CustomerListType) => {
    if (v.lat !== null && v.lng !== null) {
      dispatch(MapActions.setCenterPos({ lat: Number(v.lat), lng: Number(v.lng) }));
      dispatch(MapActions.setZoomLevel(MapCollection.clusterMaxZoom + 1));
      onClickCard();
    }
    setActive(v.id);
    setCustomer(lodash.cloneDeep(v));
  }, [onClickCard]);

  const handleClickSearch = useCallback(
    (inMap?: boolean) => {
      dispatch(MapActions.setGpsStatus('out'));
      dispatch(MapActions.api.geocoder({
        param: { param: { address: inMap ? searchValue2 : searchValue } },
        callback: () => {
          dispatch(MapActions.setAreaBtnDisabled(false));
          dispatch(DialogActions.pop());
          dispatch(MapActions.setZoomLevel(null));
        },
      }));
    },
    [searchValue, searchValue2],
  );

  const handleClickRouteSearch = useCallback(() => {
    if (!routeAuth) return;
    dispatch(MapActions.setGpsStatus('out'));
    dispatch(DialogActions.push({
      title: 'ルート設定',
      element: <RouteDialog
        type="customer"
        destination={searchValue2}
        callback={() => { }}
      />,
    }));
  }, [searchValue2, routeAuth]);

  const handleClickRegistration = useCallback(() => {
    dispatch(DialogActions.push({
      title: '顧客登録',
      className: 'max_height_dialog max_width_dialog',
      element: <CustomerEdit
        callback={() => {
          dispatch(CustomerActions.api.customer.getList({
            param: CustomerModel.listParam({ sortState }),
          }));
        }}
      />,
    }));
  }, [sortState]);

  /* Effect */
  useDidMount(() => {
    if (humanPos) {
      dispatch(MapActions.setCenterPos(cloneDeep(humanPos)));
    }
  });

  useWillUnMount(() => {
    dispatch(MapActions.setRouteInfo(null));
  });

  return (
    <section className="result_area">
      <div className="inner">
        <div className="list_wrap">
          {/* リスト側の場所検索は使用しないため非表示 */}
          <div className="search_box item_wrap display_none">
            <InputField
              onEnterKeyPress={handleClickSearch}
              labelPlace="left"
              className="item_box"
              label="住所または場所を検索"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
            <LeftIconButton
              label="住所検索"
              fontAwesomeClass="fas fa-search"
              className="btn_search for_detail"
              size="sm"
              color="secondary"
              onClick={() => handleClickSearch()}
            />
          </div>
          <div className="list_box_sort">
            <Button
              className={`md primary customer_all ${sortState.ob_flag === 0 ? 'active' : ''}`}
              onClick={() => callback(0)}
            >すべて
            </Button>
            <Button
              className={`md primary customer_prospect  ${sortState.ob_flag === 2 ? 'active' : ''}`}
              onClick={() => callback(2)}
            >見込み
            </Button>
            <Button
              className={`md primary customer_ob focus  ${sortState.ob_flag === 1 ? 'active' : ''}`}
              onClick={() => callback(1)}
            >OB
            </Button>
            <Button
              className="md primary edit"
              onClick={handleClickRegistration}
            ><>顧客<br />新規登録</>
            </Button>
          </div>
          {isSearch
          && (
          <TableSort
            isSp
            page={page}
            limit={100}
            hitCount={hitCount}
            callback={callbackPagination}
          />
          )}
          <CardList isLoading={inView} list={customerList}>
            {customerList?.map((v, i) => (
              <div
                key={`card${i}`}
                className={`card_wrapper ${active === v.id ? 'active' : ''}`}
                ref={customerList.length - 1 ? ref : undefined}
              >
                <CustomerCard
                  className="list_card"
                  customerData={v}
                  onClick={() => {
                    setCustomerIndex(i);
                    handleClickCard(v);
                  }}
                  index={i}
                />
              </div>
            ))}
          </CardList>
        </div>
        <div className="map_area">
          <MapBase
            customerOption={{
              type,
              selectInfo: customer,
              selectIndex: customerIndex,
              callbackActiveMarker: setActive,
            }}
            searchOption={{}}
            isNowPoint
            callbackZoomEnd={callbackZoomEnd}
            callbackChangePosEnd={callbackCenterChanged}
            callbackGetGps={onGetGps}
          />
          <div className="map_search_box">
            <div className="search_box item_wrap">
              <InputField
                onEnterKeyPress={() => handleClickSearch(true)}
                className="item_box"
                value={searchValue2}
                placeholder="住所または場所を検索"
                onChange={(e) => setSearchValue2(e.target.value)}
              />
              <LeftIconButton
                label="住所検索"
                fontAwesomeClass="fas fa-search"
                className="btn_search"
                size="sm"
                color="secondary"
                onClick={() => handleClickSearch(true)}
              />
              <div className="root_btn_box">
                <LeftIconButton
                  label="ルート検索"
                  fontAwesomeClass="fas fa-route"
                  className="btn_search"
                  size="sm"
                  color="secondary"
                  onClick={handleClickRouteSearch}
                  disabled={!routeAuth}
                />
                {routeInfo && (
                <LeftIconButton
                  label="ルート検索終了"
                  fontAwesomeClass="fas fa-times-circle"
                  className="btn_search ml_0"
                  size="sm"
                  color="dark"
                  onClick={() => dispatch(MapActions.setRouteInfo(null))}
                />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  );
});
