/* eslint-disable no-irregular-whitespace */
import { Divider, MenuItem } from '@material-ui/core';
import {
  useCallback, useEffect, useState, memo,
} from 'react';
import LogoutIcon from '@mui/icons-material/Logout';
import { ListItemIcon } from '@mui/material';
import PasswordIcon from '@mui/icons-material/Password';
import { User } from '../../../type/auth/user.typs';
import { LeftIconButton } from '../button/left-icon-button/left-icon-button';

type Props = {
  user: User;
  changePass: () => void;
  logout: (v: any) => void;
  className?: string;
}

export const Profile = memo((props: Props) => {
  const {
    user, logout, changePass, className,
  } = props;

  return (
    <>
      <div className={className}>
        <div className="inner">
          <h2>ユーザー情報</h2>
          <div className="item_wrap">
            <div className="item_box">
              <div className="item_head">会社名</div>
              <div>{user.company_name}{/* (略称：{user.short_name})*/}</div>
            </div>
          </div>
          <div className="item_wrap flex_grow_1">
            <div className="item_box">
              <div className="item_head">店舗名</div>
              <div>{user.store_name}</div>
            </div>
          </div>
          <div className="item_wrap">
            <div className="item_box">
              <div className="item_head">名称</div>
              <div>{user.name}{/* (略称：{user.short_name})*/}</div>
            </div>
          </div>
          <div className="item_wrap">
            <div className="item_box">
              <div className="item_head">フリガナ</div>
              <div>{user.furigana}</div>
            </div>
          </div>
          <div className="item_wrap">
            <div className="item_box">
              <div className="item_head">役職</div>
              <div>{user.job_title}</div>
            </div>
          </div>
          <div className="item_wrap">
            <div className="item_box">
              <div className="item_head">メールアドレス</div>
              <div>{user.mail_address}</div>
            </div>
          </div>
        </div>
        <Divider />
        <div className="btn_box">
          <LeftIconButton
            label="パスワード変更"
            fontAwesomeClass="fas fa-key"
            className=""
            size="sm"
            color="secondary"
            onClick={changePass}
          />
          <LeftIconButton
            label="ログアウト"
            fontAwesomeClass="fas fa-sign-out-alt"
            className=""
            size="sm"
            color="dark"
            onClick={logout}
          />
        </div>
      </div>
    </>
  );
});
