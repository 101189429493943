import { cloneDeep } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { MasterActions } from '../../../../../redux/master/master.action';
import { State } from '../../../../../redux/root.reducer';
import { MasterCollection } from '../master.collection';
import { MasterBodyDirectInput } from './body/master-body-direct-input';
import { XmlParser } from '../../../../../parser/xml-parser';
import { Input } from '../../../../ui/input/input';

export type InnerItems = {
  estimate: number,
  genka: number,
  name: string,
  type: string,
}

export type ListItem = {
  $: InnerItems
}
export type XMLType = {
    list: {
      item: ListItem[]
    }
}

export const MasterQuoteFixedBody = () => {
  /* Hook */
  const list = useSelector((state:State) => state.master.quoteFixedList);
  const dispatch = useDispatch();

  /* State */
  const [XMLList, setXmlList] = useState<XMLType>(null!);
  const [tableI, setTableI] = useState(NaN);
  const [tableJ, setTableJ] = useState(NaN);
  const [isKeyPressEnter, setIsKeyPressEnter] = useState(false);
  const [listLength, setListLength] = useState(0);
  const [dispNameList, setDispNameList] = useState<string[]>([]);
  const [dispGenkaList, setDispGenkaList] = useState<number[]>([]);
  const [dispEstimateList, setDispEstimateList] = useState<number[]>([]);

  /* Callback */
  const getList = useCallback(() => {
    dispatch(MasterActions.api.quoteFixed.getList({ param: {}, callback: () => {} }));
  }, []);

  const handleClickCell = useCallback((i: number, j: number) => {
    setTableI(i);
    setTableJ(j);
  }, []);

  const handleEnterKeyPress = useCallback(
    (
      e: React.KeyboardEvent<HTMLDivElement>,
      ary: Array<string | number>,
      key: keyof InnerItems,
    ) => {
      setIsKeyPressEnter(true);
      const xml = cloneDeep(XMLList);
      const itemList = xml.list.item.map((v, _i) => ({
        $: {
          ...v.$,
          [key]: ary[_i],
        },
      }));
      xml.list.item = itemList;
      setXmlList(xml);

      if (!e.shiftKey && listLength - 1 > tableI) {
        setTableI(tableI + 1);
      } else {
        setIsKeyPressEnter(false);
      }
      if (e.shiftKey && tableI) {
        setTableI(tableI - 1);
      } else {
        setIsKeyPressEnter(false);
      }
    }, [listLength, tableI, tableJ, isKeyPressEnter, dispNameList, XMLList],
  );

  const postList = useCallback(() => {
    // if (MasterQuoteFixedValidation(
    //   dispNameList,
    //   dispGenkaList,
    //   dispEstimateList,
    // )) {
    //   dispatch(DialogActions.pushMessage({
    //     title: '見積定型 編集／追加',
    //     message: Message.postError,
    //     callback: () => setTouch(true),
    //   }));
    //   return;
    // }

    dispatch(MasterActions.api.quoteFixed.post({
      param: {
        data: {
          xml_format: new XmlParser().build(XMLList),
          valid_flag: 1,
        },
        // id,
      },
      callback: () => getList(),
      // onSuccess: () => {
      //   // callback(),
      //   getList();
      //   dispatch(DialogActions.pop());
      // },
      // onError: () => {
      //   setTouch(true);
      // },
    }));
  }, [XMLList, list]);

  const setXMLState = useCallback((key: keyof InnerItems, ary: Array<string | number>) => {
    const xml = cloneDeep(XMLList);
    const itemList = xml.list.item.map((v, _i) => ({
      $: {
        ...v.$,
        [key]: ary[_i],
      },
    }));
    xml.list.item = itemList;
    setXmlList(xml);
    setTableI(isKeyPressEnter ? tableI : NaN);
    setTableJ(isKeyPressEnter ? tableJ : NaN);
  }, [XMLList, dispNameList, isKeyPressEnter, tableI, tableJ]);

  const tableList = useMemo(() => {
    const lis = (XMLList ? cloneDeep(XMLList).list.item.map((f, i) => ([
      f.$.type,
      (i === tableI && tableJ === 1)
        ? (
          <Input
            value={dispNameList[i]}
            onChange={(e) => {
              const li = cloneDeep(dispNameList);
              li[i] = e.target.value;
              setDispNameList(li);
            }}
            onBlur={() => setXMLState('name', dispNameList)}
            callBackEnterKeyPress={(e) => handleEnterKeyPress(e, dispNameList, 'name')}
            onFocus={() => setIsKeyPressEnter(false)}
            firstFocus={i === tableI && tableJ === 1}
            maxLength={255}
          />
        )
        : f.$.name,
      (i === tableI && tableJ === 2)
        ? (
          <Input
            type="number"
            value={dispEstimateList[i]}
            onChange={(e) => {
              const li = cloneDeep(dispEstimateList);
              li[i] = Number(e.target.value) <= 100 ? Number(e.target.value) : 100;
              setDispEstimateList(li);
            }}
            onBlur={() => setXMLState('estimate', dispEstimateList)}
            callBackEnterKeyPress={(e) => handleEnterKeyPress(e, dispEstimateList, 'estimate')}
            onFocus={() => setIsKeyPressEnter(false)}
            firstFocus={i === tableI && tableJ === 2}
            maxLength={5}
          />
        )
        : f.$.estimate,
      (i === tableI && tableJ === 3)
        ? (
          <Input
            type="number"
            value={dispGenkaList[i]}
            onChange={(e) => {
              const li = cloneDeep(dispGenkaList);
              li[i] = Number(e.target.value) <= 100 ? Number(e.target.value) : 100;
              setDispGenkaList(li);
            }}
            onBlur={() => setXMLState('genka', dispGenkaList)}
            callBackEnterKeyPress={(e) => handleEnterKeyPress(e, dispGenkaList, 'genka')}
            onFocus={() => setIsKeyPressEnter(false)}
            firstFocus={i === tableI && tableJ === 3}
            maxLength={5}
          />
        )
        : f.$.genka,
    ]))
      : []
    );
    setListLength(lis.length);
    return lis;
  },
  [XMLList,
    tableI,
    tableJ,
    dispNameList,
    dispEstimateList,
    dispGenkaList,
    isKeyPressEnter,
  ]);

  useEffect(() => {
    if (!list.length) return;
    new XmlParser().parse(list[0].xml_format)
      .then((res: XMLType) => {
        setXmlList(res);
      });
  }, [list]);

  useEffect(() => {
    if (!XMLList) return;
    const name: string[] = [];
    const estimate: number[] = [];
    const genka: number[] = [];
    XMLList.list.item.forEach((f) => {
      name.push(f.$.name);
      estimate.push(f.$.estimate);
      genka.push(f.$.genka);
    });
    setDispNameList(name);
    setDispGenkaList(genka);
    setDispEstimateList(estimate);
  }, [XMLList]);

  return (
    <MasterBodyDirectInput
      header={MasterCollection.quoteFixedMasterHeader}
      rowDataList={XMLList?.list.item}
      list={tableList}
      disabledTr={[1, 2, 3]}
      callbackPost={postList}
      callbackGetList={getList}
      defaultOrder={14}
      onClickCell={handleClickCell}
    />
  );
};
