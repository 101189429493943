import actionCreatorFactory from 'typescript-fsa';
import { ApiBase } from '../../../../service/api-base';
import { RequestBaseCollection } from '../../../../service/api.collection';
import { CallbackRequest, RequestBaseParam } from '../../../../type/api.type';
import {
  ApiBillGetPrintInfoResponse, ApiBillGetPrintInfoParam,
  ApiBillGetParam,
  ApiBillGetResponse,
  ApiBillGetListParam,
  ApiBillGetListResponse,
  ApiBillPostParam,
} from './api-bill.type';

export const ActionCreator = actionCreatorFactory('bill/api');

export const apiBill = {
  get: ActionCreator<{
    param: ApiBillGetParam;
    callback?:(data:ApiBillGetResponse)=>void;
      }>('get'),
  getPrint: ActionCreator<CallbackRequest<ApiBillGetPrintInfoParam>>('get/print'),
  post: ActionCreator<CallbackRequest<ApiBillPostParam>>('post'),
  delete: ActionCreator<{ param: {id:number}; callback:() => void;}>('delete'),
  getList: ActionCreator<CallbackRequest<ApiBillGetListParam, ApiBillGetListResponse[]> & { noLoad?: boolean }>('get/list'),
};

class ApiBill<T = any> extends ApiBase<T> {
  constructor(param: RequestBaseParam & {id?: number}) {
    super({
      ...RequestBaseCollection(),
      ...param,
      url: `/api/billing${param.id ? `/${param.id}` : ''}`,

    });
  }
}

export class ApiBillGet extends ApiBill<ApiBillGetResponse> {
  constructor(param: ApiBillGetParam) {
    const { id } = param;
    super({
      httpMethod: 'GET',
      id,
    });
  }
}

export class ApiBillPost extends ApiBill {
  constructor(param: ApiBillPostParam) {
    const { data, id } = param;
    super({
      httpMethod: 'POST',
      param: data,
      id,
    });
  }
}

export class ApiBillDelete extends ApiBill {
  constructor(param: {id:number}) {
    const { id } = param;
    super({
      httpMethod: 'DELETE',
      id,
    });
  }
}

export class ApiBillGetList extends ApiBill {
  constructor(param: ApiBillGetListParam) {
    super({
      httpMethod: 'GET',
      param,
    });
  }
}

export class ApiBillPrintInfoGet extends ApiBill<ApiBillGetPrintInfoResponse> {
  constructor(param: ApiBillGetPrintInfoParam) {
    const { id, data } = param;
    super({
      httpMethod: 'GET',
      param: {
        ...data,
      },
    });
    this.url = `/api/billing/print${id ? `/${id}` : ''}`;
  }
}
