import './project.pc.scss';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { goBack, replace } from 'connected-react-router';
import { cloneDeep } from 'lodash';
import { BasePagePC } from '../base.page.pc';
import { DialogActions } from '../../../../redux/dialog/dialog.action';
import { LeftIconButton } from '../../../ui/button/left-icon-button/left-icon-button';
import { ProjectSearchBox } from '../../layout/search-box/project/project-search-box';
import { ProjectMapListPC } from '../../layout/body/map/map-list/project/project-map-list';
import { ProjectListPC } from '../../layout/body/list/project-list/project-list.pc';
import { RoutingPath } from '../../../../routes/routing-pass';
import { useQuery } from '../../../../hooks/use-query';
import { useWillUnMount, useDidMount } from '../../../../hooks/life-cycle';
import { ProjectActions } from '../../../../redux/project/project.action';
import { ProjectAdd } from './add/project-add';
import { TableSort, Limit } from '../../../ui/table/table-sort/table-sort';
import { MapActions } from '../../../../redux/map/map.action';
import { ProjectModel } from '../../../../model/project/project.model';
import { useAppSelector } from '../../../../hooks/use-redux';
import { MasterActions } from '../../../../redux/master/master.action';
import { ProjectListType, ProjectSortState } from '../../../../type/project/project.type';
import { CsvActions } from '../../../../redux/csv/csv.action';
import { MapAreaPosition } from '../../../../type/map/map.type';
import { MapCollection } from '../../../../collection/map/map.collection';
import { TagModel } from '../../../../model/tag/tag';
import { Store } from '../../../../redux/store';

export const ProjectPC = () => {
  const shoType = useQuery('type');
  const dispatch = useDispatch();
  const list = useAppSelector((v) => (v.project.list));
  const areaBtnDisabled = useAppSelector((v) => (v.map.areaBtnDisabled));
  const listHitCount = useAppSelector((v) => (v.project.listHitCount));
  const sortState = useAppSelector((v) => v.project.sort);
  // const mapAreaPos = useAppSelector((v) => (v.map.mapAreaPos));
  const gpsStatus = useAppSelector((v) => (v.map.gpsStatus));
  const user = useAppSelector((v) => v.user);
  // const humanPos = useAppSelector((v) => (v.map.humanPos));
  const dialogLen = useAppSelector((v) => (v.dialog.dialogs.length));
  const map = useAppSelector((v) => v.map.map);
  // const zoom = useAppSelector((v) => v.map.zoomLevel);
  const flickLoad = useAppSelector((v) => v.map.flickLoad);
  const employeeList = useAppSelector((v) => v.master.employeeList);
  const [showType, setShowType] = useState<'map' | 'list'>('map');
  const [status, setStatus] = useState<TagModel | null>(null);

  /* MapList用変数 */
  const [init, setInit] = useState<boolean>(true);
  const [hitCount, setHitCount] = useState(0);
  const [page, setPage] = useState(0);
  const [mapList, setMapList] = useState<ProjectListType[] | null>([]);
  const [isSearch, setIsSearch] = useState(true);
  /* エリア検索 */
  const [zoomSearch, setZoomSearch] = useState(false);
  /* カードクリック検索 */
  const [cardSearch, setCardSearch] = useState<boolean>(false);

  /** 1:未契約 2:工事中 3:完工 */
  const [projectShowType, setProjectShowType] = useState<0 | 1 | 2 | 3>(0);
  const [searchIsOpen, setSearchIsOpen] = useState<boolean>(false);

  const [select, setSelect] = useState<number[]>([]);
  const [listList, setListList] = useState<ProjectListType[] | null | undefined>(null);

  const projectList = !projectShowType ? mapList : mapList?.filter(
    (v) => v.construction_flag === projectShowType,
  );

  /* カードリストの一覧取得のみ */
  const getListCardList = useCallback((param: {
      data?: ProjectSortState;
      offset?: number;
    }) => {
    const { data, offset } = param;
    const _data = data || sortState;
    const setProjectId = () => {
      if (
        user.view_data
        && user.view_data.company_id !== 1
        && _data.project_employee_id === user.id
      ) {
        return NaN;
      }
      return _data.project_employee_id;
    };
    setPage(offset || 0);
    setMapList(null);
    dispatch(ProjectActions.api.project.getCallbackList({
      param: {
        ...ProjectModel.listParam({
          sortState: data || sortState,
          isMap: true,
          searchBox: true,
          isMapList: true,
        }),
        limit: 100,
        offset: offset || sortState.offset,
        project_employee_id: setProjectId(),
      },
      onSuccess: (v, hit) => {
        if (!v) return;
        setMapList(v);
        setHitCount(hit);
      },
    }));
  }, [sortState, user, employeeList]);

  /* マップ一覧取得 */
  const getListMapList = useCallback((param: {
    data?: ProjectSortState;
    mapPos?: MapAreaPosition;
    searchBox?: boolean;
    isInit?: boolean;
  }) => {
    const { data, mapPos, searchBox } = param;
    const isMap = showType === 'map';
    const _data = data || sortState;
    const setProjectId = () => {
      if (
        user.view_data
        && user.view_data.company_id !== 1
        && _data.project_employee_id === user.id
      ) {
        return NaN;
      }
      return _data.project_employee_id;
    };
    dispatch(ProjectActions.api.project.getList({
      isLoad: isMap,
      isMapLoad: true,
      param: {
        ...ProjectModel.listParam({
          isMap,
          sortState: data || sortState,
          mapAreaPos: mapPos || Store.getState().map.mapAreaPos,
        }),
        project_employee_id: setProjectId(),
      },
      onSuccess: (v) => {
        if (!v) return;
        // if (flickLoad) setMapList(v.data);
        dispatch(ProjectActions.setList(v.data));
        dispatch(MapActions.setAreaBtnDisabled(true));
        dispatch(ProjectActions.setListCount(v.count));
        if (v.data.length || showType !== 'map' || flickLoad) return;
        dispatch(DialogActions.pushMessage({
          title: '検索',
          message: ['領域内に該当データがありません'],
        }));
      },
    }));
  }, [showType, flickLoad, sortState, user, employeeList]);

  /* 新規登録 */
  const handleClickRegistration = useCallback(() => {
    dispatch(DialogActions.push({
      title: '案件登録',
      className: 'max_height_dialog',
      element: <ProjectAdd callback={() => {
        if (!flickLoad) {
          getListCardList({});
        }
        getListMapList({});
      }}
      />,
    }));
  }, [dispatch, getListCardList, getListMapList, flickLoad]);

  /* 検索後のカードクリックアフターアクション */
  const centerChanged = useCallback((pos: MapAreaPosition) => {
    if (!cardSearch) return;
    setCardSearch(false);
    getListMapList({ mapPos: pos });
    // getList(undefined, false, undefined, pos);
  }, [cardSearch, getListMapList]);

  /* カードクリック挙動 */
  const hClickCard = useCallback(() => {
    if (flickLoad) return;
    if (
      Store.getState().map.zoomLevel >= MapCollection.searchZoomLevel
      && Store.getState().map.mapAreaPos
    ) {
      setCardSearch(true);
    } else {
      setZoomSearch(true);
    }
  }, [flickLoad]);

  /* リストページャーソート */
  const pagerSort = useCallback((offset: number, limit: Limit) => {
    dispatch(ProjectActions.setList(null));
    dispatch(ProjectActions.setSort({ offset, limit }));
    getListMapList({
      data: { ...sortState, offset, limit },
    });
    // getList({ ...sortState, offset, limit });
  }, [sortState, getListMapList]);

  /* TableHeaderのソート */
  const headerSort = useCallback((highlow: number, sort_by: number) => {
    dispatch(ProjectActions.setSort({ highlow, sort_by }));
  }, []);

  /* ズーム終了時アクション */
  const zoomEnd = useCallback((pos: MapAreaPosition) => {
    if (!zoomSearch) {
      dispatch(MapActions.setAreaBtnDisabled(false));
      return;
    }
    setZoomSearch(false);
    // if (!flickLoad) { getListCardList({}); }
    getListMapList({ mapPos: pos });
    // getList(undefined, false, undefined, pos);
  }, [zoomSearch, getListCardList, getListMapList, flickLoad]);

  /* エリア検索ボタン押下 */
  const hClickSearchArea = useCallback((isInit?: boolean, v?: ProjectSortState) => {
    if (!isInit) {
      dispatch(MapActions.setAreaBtnDisabled(true));
    }
    if (flickLoad) setMapList(null);
    if (
      Store.getState().map.zoomLevel >= MapCollection.searchZoomLevel
      && Store.getState().map.mapAreaPos
    ) {
      getListMapList({ data: v });
    } else {
      setZoomSearch(true);
      dispatch(MapActions.setZoomLevel(MapCollection.searchZoomLevel));
    }
  }, [getListMapList, flickLoad]);

  /* タイプ押下 */
  const hClickType = useCallback((type: 0 | 1| 2 | 3) => {
    setProjectShowType(type);
    dispatch(MapActions.setZoomLevel(15));
  }, [shoType]);

  /* マップ⇔リスト */
  const changeMap = useCallback(() => {
    const path = `${RoutingPath.project}?type=${showType === 'map' ? 'list' : 'map'}`;
    dispatch(replace(path));
    setShowType(showType === 'map' ? 'list' : 'map');
  }, [showType]);

  /* 検索ボタン押下処理 */
  const hClickSearchBtn = useCallback((v: ProjectSortState, isInit?: boolean) => {
    setStatus(cloneDeep(v.construction_status));
    if (showType !== 'map') { dispatch(ProjectActions.setList(null)); }
    setSelect([]);
    setIsSearch(true);
    setMapList(null);
    dispatch(MapActions.setFlickLoad(false));
    dispatch(ProjectActions.setSort({ offset: 0 }));
    if (showType === 'map') {
      hClickSearchArea(isInit, v);
    } else {
      getListMapList({ data: { ...v } });
    }
    getListCardList({ data: { ...v, offset: 0 }, offset: 0 });
  }, [shoType, getListCardList, getListMapList, showType, hClickSearchArea]);

  /* リスト・マップ変更ボタン押下 */
  const hClickShowType = useCallback(() => {
    dispatch(MapActions.setAreaBtnDisabled(false));
    if (!cardSearch && !zoomSearch) {
      setMapList(showType === 'map' ? null : []);
      setInit(true);
      dispatch(ProjectActions.setList(null));
    }
    if (!flickLoad && Store.getState().map.mapAreaPos) {
      getListCardList({});
    }
    changeMap();
  }, [changeMap, flickLoad, hClickSearchArea, showType, zoomSearch]);

  const initMapSearch = useCallback((onGps: boolean) => {
    const promise = () => new Promise<void>((resolve, reject) => {
      if (onGps) {
        if (!Store.getState().map.mapAreaPos || !Store.getState().map.humanPos) {
          reject();
        } else {
          resolve();
        }
      } else if (!Store.getState().map.mapAreaPos) {
        reject();
      } else {
        resolve();
      }
    });
    const run = () => {
      setInit(false);
      if (onGps) {
        hClickSearchBtn(sortState, init);
        dispatch(MapActions.setFlickLoad(false));
      } else {
        dispatch(MapActions.setFlickLoad(false));
        hClickSearchBtn(sortState, init);
      }
    };
    promise()
      .then(() => {
        run();
      })
      .catch(() => {
        window.setTimeout(() => {
          initMapSearch(onGps);
        }, 200);
      });
  }, [sortState, init]);

  /* MapList内部ページネーション */
  const hClickPagination = useCallback((offset: number) => {
    setMapList(null);
    getListCardList({ offset });
    // getList(undefined, undefined, offset);
  }, [getListMapList]);

  /* effect */
  useEffect(() => {
    if (dialogLen || (showType === 'map')) return;
    getListMapList({});
  }, [
    sortState.highlow,
    sortState.sort_by,
    showType,
  ]);

  // useEffect(() => {
  //   if (init && humanPos && mapAreaPos) {
  //     dispatch(MapActions.setFlickLoad(false));
  //     hClickSearchBtn(sortState, init);
  //     setInit(false);
  //   } else if (init && mapAreaPos) {
  //     dispatch(MapActions.setFlickLoad(false));
  //     hClickSearchBtn(sortState, init);
  //     setInit(false);
  //   }
  // }, [humanPos, mapAreaPos]);

  useEffect(() => {
    const mapType = (shoType || 'list');
    const path = `${RoutingPath.project}?type=`;
    dispatch(replace(path + mapType));
    setShowType(mapType === 'map' ? 'map' : 'list');
  }, [shoType]);

  useWillUnMount(() => {
    dispatch(ProjectActions.setList(null));
    dispatch(MasterActions.resetState());
  });

  useDidMount(() => {
    dispatch(MasterActions.api.projectRank.getList({}));
    dispatch(MapActions.setFlickLoad(true));
  });

  return (
    <BasePagePC>
      <div id="project" className={`cnt_area ${searchIsOpen ? 'detail_on' : ''}`}>
        <div className="inner">
          <ProjectSearchBox
            openCallback={setSearchIsOpen}
            callback={hClickSearchBtn}
            constructionStatus={sortState.construction_status}
          />
          <div className="MapPC__body__map" />
          {!areaBtnDisabled && showType === 'map'
          && (
          <LeftIconButton
            label="このエリアを検索"
            size="md"
            fontAwesomeClass="fas fa-search"
            className="for_area"
            color="secondary"
            onClick={() => hClickSearchArea()}
            disabled={areaBtnDisabled}
          />
          )}
          {showType === 'map'
            ? (
              <ProjectMapListPC
                list={mapList}
                page={page}
                isSearch={isSearch}
                hitCount={hitCount}
                type={projectShowType}
                onClickCard={hClickCard}
                onGetGps={initMapSearch}
                callbackGetList={() => {
                  if (!flickLoad) {
                    getListCardList({});
                  }
                  getListMapList({});
                }}
                callback={(mode) => {
                  dispatch(ProjectActions.setSort({
                    ...sortState,
                    construction_status: cloneDeep(ProjectModel.changeMode({
                      mode,
                    })),
                  }));
                  hClickSearchBtn({
                    ...sortState,
                    construction_status: cloneDeep(ProjectModel.changeMode({
                      mode,
                    })),
                  });
                }}
                callbackPagination={hClickPagination}
                callbackZoomEnd={zoomEnd}
                callbackCenterChanged={centerChanged}
              />
            )
            : (
              <>
                <TableSort
                  page={sortState.offset ?? 0}
                  limit={sortState.limit as Limit}
                  hitCount={listHitCount}
                  callback={pagerSort}
                />
                <ProjectListPC
                  parentSelect={select}
                  isMulti
                  callbackSelected={setSelect}
                  callbackList={setListList}
                  callbackSelect={() => {}}
                />
                <TableSort
                  className="bottom"
                  page={sortState.offset ?? 0}
                  limit={sortState.limit as Limit}
                  hitCount={listHitCount}
                  callback={pagerSort}
                />
              </>
            )}
          <div />
        </div>
      </div>
      <footer className="btn_area">
        <div className="left_box">
          <LeftIconButton
            label="案件新規登録"
            size="md"
            fontAwesomeClass="fas fa-edit"
            className="btn_search for_detail"
            color="primary"
            onClick={handleClickRegistration}
          />
          <LeftIconButton
            label="CSV出力"
            size="md"
            fontAwesomeClass="fas fa-file-csv"
            className="btn_search for_detail"
            color="primary"
            onClick={() => {
              const isMap = shoType === 'map';
              if (!isMap) {
                if (!listList || !listList.length) return;
                const csvParam = select.length
                  ? {
                    ids: select.map((v) => listList[v].id),
                  } : {
                    ...ProjectModel.listParam({ sortState }),
                  };
                if (!csvParam) return;
                Object.assign(csvParam, {
                  highlow: sortState.highlow,
                });
                dispatch(CsvActions.api.project.download(csvParam));
              } else {
                if (!projectList || !projectList.length) return;
                const csvParam = {
                  ...ProjectModel.listParam({ sortState }),
                };
                dispatch(CsvActions.api.project.download(csvParam));
              }
            }}
          />
        </div>
        <div className="right_box">
          <LeftIconButton
            fontAwesomeClass={`${showType === 'map' ? 'fas fa-list' : 'fas fa-map'}`}
            label={showType === 'map' ? 'リスト表示' : '地図表示'}
            size="md"
            color="primary"
            className="btn_search for_detail"
            onClick={hClickShowType}
          />
          <LeftIconButton
            label="戻る"
            fontAwesomeClass="fas fa-arrow-left"
            size="md"
            color="dark"
            onClick={() => dispatch(goBack())}
          />
        </div>
      </footer>
    </BasePagePC>
  );
};
