import { useDispatch } from 'react-redux';
import { cloneDeep } from 'lodash';
import { useState, useCallback } from 'react';
import { Button } from '../../../../../../ui/button/button';
import { DialogActions } from '../../../../../../../redux/dialog/dialog.action';
import { DatePicker } from '../../../../../../ui/date-picker/date-picker';
import { Select } from '../../../../../../ui/select/select';
import { SupportHistorySortState } from '../../../../../../../type/support-history/support-history.type';
import { useDidMount } from '../../../../../../../hooks/life-cycle';
import { CustomerDetailActions } from '../../../../../../../redux/customer-detail/customer-detail.action';
import { MasterActions } from '../../../../../../../redux/master/master.action';
import { Input } from '../../../../../../ui/input/input';
import { useAppSelector } from '../../../../../../../hooks/use-redux';
import { pulldown } from '../../../../../../../utilities/pulldown';
import { sortEmployee, changeStore } from '../../../../../../../utilities/sort-employee';

type Props = {
  callback: (sort:SupportHistorySortState) => void;
}

export const RefindSupportHistoryTable = (props: Props) => {
  const { callback } = props;
  /* Hook */
  const dispatch = useDispatch();
  const sort = useAppSelector((v) => v.customerDetail.supportSort);
  const supportHistoryList = useAppSelector((v) => v.master.supportHistoryList);
  const {
    employeeList,
    storeList,
    originList,
  } = useAppSelector((v) => v.master);

  /* State */
  const [sortState, setSortState] = useState(cloneDeep(sort));

  /* Callback */
  const handleClickSearch = useCallback(() => {
    dispatch(DialogActions.pop());
    callback(sortState);
  }, [callback, sortState]);

  const setState = useCallback((v: Partial<SupportHistorySortState>) => {
    const data = {
      ...cloneDeep(sortState),
      ...cloneDeep(v),
    };
    setSortState(data);
    dispatch(CustomerDetailActions.setSupportSort(data));
  }, [sortState]);

  /* Master */
  useDidMount(() => {
    dispatch(MasterActions.api.supportHistory.getList({}));
    dispatch(MasterActions.api.employee.getList({}));
  });

  return (
    <div className="refind_wrap" onKeyPress={(e) => { if (e.key === 'Enter') handleClickSearch(); }}>
      <div className="refind_body">
        <div className="item_wrap">
          <div className="item_box">
            <div className="item_head">対応</div>
            <Select
              className=""
              value={sortState.support_flag}
              defaultLabel="指定なし"
              onChange={(v) => setState({ support_flag: Number(v) })}
              options={[
                { text: '未対応', value: 1 },
                { text: '対応済み', value: 2 },
              ]}
            />
          </div>
        </div>
        <div className="item_wrap">
          <div className="item_box">
            <div className="item_head">案件名</div>
            <Input
              value={sort.project_name}
              onChange={(e) => setState({ project_name: e.target.value })}
            />
          </div>
          <div className="item_box">
            <div className="item_head">登録日</div>
            <DatePicker
              date={sort.contact_dt || null}
              onChange={(v) => setState(
                { contact_dt: v },
              )}
            />
          </div>
        </div>
        <div className="item_wrap">
          <div className="item_box">
            <div className="item_head">登録担当店舗</div>
            <Select
              className=""
              value={sortState.created_store_id}
              defaultLabel="指定なし"
              onChange={(v) => setState(changeStore({
                employeeList,
                storeId: Number(v),
                storeKey: 'store_id',
                employeeKey: 'created_employee_id',
                returnStoreKey: 'created_store_id',
              }))}
              options={pulldown(storeList)}
            />
          </div>
          <div className="item_box">
            <div className="item_head">登録担当者</div>
            <Select
              className=""
              value={sortState.created_employee_id}
              defaultLabel="指定なし"
              onChange={(v) => setState({ created_employee_id: Number(v) })}
              options={pulldown(sortEmployee({
                employeeList,
                storeId: sortState.created_store_id,
                storeKey: 'store_id',
              }))}
            />
          </div>
        </div>
        <div className="item_wrap">
          <div className="item_box">
            <div className="item_head">カテゴリ</div>
            <Select
              className=""
              value={sortState.support_id}
              defaultLabel="指定なし"
              onChange={(v) => setState({ support_id: Number(v) })}
              options={supportHistoryList.map((v) => ({
                text: v.supported, value: v.id,
              }))}
            />
          </div>
          <div className="item_box">
            <div className="item_head">媒体</div>
            <Select
              className=""
              value={sortState.source_id}
              defaultLabel="指定なし"
              onChange={(v) => setState({ source_id: Number(v) })}
              options={pulldown(originList)}
            />
          </div>
        </div>
        <div className="item_wrap">
          <div className="item_box">
            <div className="item_head">対応履歴名</div>
            <Input
              value={sort.title}
              onChange={(e) => setState({ title: e.target.value })}
            />
          </div>
        </div>
        <div className="item_wrap">
          <div className="item_box">
            <div className="item_head">対応内容</div>
            <Input
              value={sort.comment}
              onChange={(v) => setState({ comment: v.target.value })}
            />
          </div>
        </div>
        <div className="item_wrap">
          <div className="item_box">
            <div className="item_head">対応店舗</div>
            <Select
              className=""
              value={sortState.support_store_id}
              defaultLabel="指定なし"
              onChange={(v) => {
                setState(changeStore({
                  employeeList,
                  storeId: Number(v),
                  storeKey: 'store_id',
                  returnStoreKey: 'support_store_id',
                  employeeKey: 'support_employee_id',
                }));
              }}
              options={pulldown(storeList)}
            />
          </div>
          <div className="item_box">
            <div className="item_head">対応者</div>
            <Select
              className=""
              value={sortState.support_employee_id}
              defaultLabel="指定なし"
              onChange={(v) => setState({ support_employee_id: Number(v) })}
              options={pulldown(sortEmployee({
                employeeList,
                storeId: sortState.support_store_id,
                storeKey: 'store_id',
              }))}
            />
          </div>
          <div className="item_box">
            <div className="item_head">対応日</div>
            <DatePicker
              date={sort?.support_dt || null}
              onChange={(v) => setState(
                { support_dt: v },
              )}
            />
          </div>
        </div>
      </div>
      <footer className="base_footer refind_footer">
        <Button
          size="md"
          color="primary"
          onClick={handleClickSearch}
        >絞込み
        </Button>
        <Button
          size="md"
          color="dark"
          onClick={() => dispatch(DialogActions.pop())}
        >キャンセル
        </Button>
      </footer>
    </div>
  );
};
