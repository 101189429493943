import actionCreatorFactory from 'typescript-fsa';
import { User } from '../../type/auth/user.typs';

export const ActionCreator = actionCreatorFactory('user');

export const UserActions = {
  getUser: ActionCreator<{ location?: string; callback:(v: User | null) => void;}>('get/user'),
  setUser: ActionCreator<User | null>('set/user'),
  resetState: ActionCreator('reset/state'),
};
